import { getIsLifeADDContributionBenefitType, benefitTypesSorter } from "shared/types/BenefitTypes";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import type { EmployeeClass } from "shared/types/EmployeeClass";

export function getLifeADDContributionsHeader(employeeClass: EmployeeClass) {
  const b = employeeClass.employeeClassPlans
    .map((p) => p.plan.benefitType)
    .filter(getIsLifeADDContributionBenefitType)
    .sort(benefitTypesSorter)
    .map((b) => benefitTypeToCoverage[b]);

  const title = b.join(" + ");

  return title;
}
