import { Tooltip } from "../Tooltip/Tooltip";

import * as styles from "./clientBadge.module.less";

export const ClientDraftBadge = ({ showTooltip = false }) =>
  showTooltip ? (
    <Tooltip
      title="This client is currently unavailable to view. Someone from Sun Life will reach out with more details."
      placement="bottom"
    >
      <span className={styles.clientBadge}>Draft</span>
    </Tooltip>
  ) : (
    <span className={styles.clientBadge}>Draft</span>
  );
