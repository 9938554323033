import { useReducer } from "react";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";

type TransitionState = "collapsed" | "expanding" | "expanded" | "collapsing";

export function useExpandCollapseTransitionReducer() {
  const [transitionState, setTransitionState] = useReducer<
    React.Reducer<TransitionState, "toggle" | "end">
  >((state, action) => {
    if (action === "toggle") {
      const stateMachine: Record<TransitionState, TransitionState> = {
        collapsed: "expanding",
        expanding: "collapsing",
        expanded: "collapsing",
        collapsing: "expanding",
      };
      return stateMachine[state];
    } else if (action === "end") {
      const stateMachine: Record<TransitionState, TransitionState> = {
        collapsed: "collapsed",
        expanding: "expanded",
        expanded: "expanded",
        collapsing: "collapsed",
      };
      return stateMachine[state];
    } else {
      exhaustiveCheckFail(action);
    }
  }, "collapsed");

  return { transitionState, setTransitionState };
}
