import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EIFAddASubsidiary } from "client/src/domain/EIF/CompanyDetails/EIFAddASubsidiary";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useSlobId } from "client/src/hooks/useSlobId";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { LocationStateData } from "shared/data/LocationState";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { listFormat } from "shared/utils/format";
import { hasNonNullValues } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { subsidiaryValidationSchema } from "shared/validation/subsidiary";
import { getEIFSubStepMap } from "../../../../../shared/types/EIF";
import { subsidiaryClientValidationSchema } from "../../../../../shared/validation/client";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body2, Body3, Eyebrow } from "../../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../../hooks/useGetEIFPreviousAndNextLink";
import { EIFBottomNavButtons } from "../EIFBottomNavButtons";
import type { Client } from "../../../../../shared/types/Client";
import type { ElementClickedOptions } from "../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { CreateSubsidiaryFunc, UpdateSubsidiaryFunc } from "client/src/hooks/subsidiary";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { DisabilityStateCode, Location } from "shared/types/Location";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";

type EIFSubsidiariesProps = {
  client: Client;
  subsidiaries: Subsidiary[];
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: (options: ElementClickedOptions) => void;
  authUser: UserData | null;
  updateClient: UpdateClientFunc;
  createSubsidiary: CreateSubsidiaryFunc;
  updateSubsidiary: UpdateSubsidiaryFunc;
  plans: Plan[];
};

export const combinedSubsidiaryValidationSchema = subsidiaryClientValidationSchema.concat(
  subsidiaryValidationSchema,
);

export const EIFSubsidiaries = (props: EIFSubsidiariesProps) => {
  const {
    client,
    subsidiaries,
    authUser,
    trackElementClicked,
    updateClient,
    createSubsidiary,
    updateSubsidiary,
    plans,
    changeSnapshot,
  } = props;
  const eifSubStepId = "subsidiaries-and-affiliates";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
        subsidiaries,
        clientPlans: plans,
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const [navigateNextOnSave, setNavigateNextOnSave] = useState<boolean>(false);
  const [subsidiaryToEdit, setSubsidiaryToEdit] = useState<Partial<Subsidiary> | null>(null);

  const benefitTypes = plans.map((plan) => plan.benefitType);

  const relevantDisabilityStates: DisabilityStateCode[] =
    getRelevantDisabilityStatesFromPlans(plans);

  const hasRelevantDisabilityStates = relevantDisabilityStates.length > 0;

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      hasSubsidiary: client.hasSubsidiary ?? null,
      subsidiaryEEsInNYNJOrHI: !hasRelevantDisabilityStates
        ? "NO"
        : client.subsidiaryEEsInNYNJOrHI ?? null,
      mainPolicyHolderEEsInNYNJOrHI: !hasRelevantDisabilityStates
        ? "NO"
        : client.mainPolicyHolderEEsInNYNJOrHI ?? null,
      mainPolicyHolderEEStates: !hasRelevantDisabilityStates
        ? null
        : client.mainPolicyHolderEEStates,
      companyName: subsidiaryToEdit?.companyName ? subsidiaryToEdit.companyName : null,
      taxId: subsidiaryToEdit?.taxId ? subsidiaryToEdit.taxId : null,
      employeeStates: subsidiaryToEdit?.employeeStates ? subsidiaryToEdit.employeeStates : null,
      location: getInitialLocationValue(
        subsidiaryToEdit?.location,
        benefitTypes.includes("DENTAL_ASO"),
      ),
    },
    validationContext: { prefill: true, benefitTypes },
    validationSchema: combinedSubsidiaryValidationSchema,
    onSubmit: async () => {
      const { companyName, taxId, employeeStates, location, ...subsidiaryClientValues } =
        formik.values;
      const { isSuccess: isSuccessClient } = await updateClient({
        data: {
          ...subsidiaryClientValues,
        },
        params: { clientId: client.id },
        query: { prefill: true },
      });

      const emptySubsidiary =
        !companyName &&
        !taxId &&
        (!employeeStates || employeeStates.length === 0) &&
        (benefitTypes.includes("DENTAL_ASO")
          ? !location ||
            (!location.address1 && !location.city && !location.state && location.zipCode)
          : true);

      const { isSuccess: isSuccessSubsidiary } = subsidiaryToEdit?.id
        ? await updateSubsidiary({
            data: { companyName, taxId, employeeStates, location },
            params: { clientId: client.id, subsidiaryId: subsidiaryToEdit.id },
          })
        : !emptySubsidiary
        ? await createSubsidiary({
            data: { companyName, taxId, employeeStates, location },
            params: { clientId: client.id },
          })
        : { isSuccess: true };

      if (isSuccessClient && isSuccessSubsidiary) {
        void slobMessage.success("Subsidiaries & affiliates updated");
        void formik.resetForm();
        setSubsidiaryToEdit(null);
        track("Save");
        if (nextSubStepLink && navigateNextOnSave) {
          navigate(nextSubStepLink);
        }
      }
    },
  });

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>([
      "hasSubsidiary",
      "subsidiaryEEsInNYNJOrHI",
      "mainPolicyHolderEEsInNYNJOrHI",
      "mainPolicyHolderEEStates",
    ])(client),
  );

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, subsidiaryClientValidationSchema, {
        prefill: false,
      })
    : {};

  const mainPolicyHolderEEStateErrorIdString = useSlobId({
    prefix: "main-policy-holder-ee-states__errormessage",
  });
  const mainPolicyHolderEEStateErrorId =
    (formik.errors.mainPolicyHolderEEStates && formik.touched.mainPolicyHolderEEStates) ||
    strictErrors.mainPolicyHolderEEStates
      ? mainPolicyHolderEEStateErrorIdString
      : undefined;

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    plans,
  });

  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const getMainPolicyHolderCopy = () => {
    const stateLongNames = relevantDisabilityStates.map(
      (state) => LocationStateData[state].displayName,
    );
    const stateList = listFormat(stateLongNames, { type: "disjunction", style: "long" });
    return `Does the main policy holder have employees who work in ${stateList}?`;
  };

  return (
    <>
      <StackY dist={40}>
        <h2 className="mb-40">{subStepName}</h2>
        <StackY dist={32}>
          <HubCard>
            <form onSubmit={formik.handleSubmit}>
              <StackY dist={32}>
                <StackY dist={8}>
                  <RadioGroup
                    name="hasSubsidiary"
                    label={
                      <StackY dist={8}>
                        <Body2 as="div">
                          Does your company have any subsidiaries or affiliates who's employees are
                          to be covered by this policy?
                        </Body2>
                        <Body3 as="div">
                          A subsidiary or affiliate is a separate firm owned or controlled by your
                          company.
                        </Body3>
                      </StackY>
                    }
                    onChange={formik.handleChange}
                    value={formik.values.hasSubsidiary}
                    disabled={formik.isSubmitting}
                    touched={formik.touched.hasSubsidiary || !!strictErrors.hasSubsidiary}
                    error={formik.errors.hasSubsidiary || strictErrors.hasSubsidiary}
                    options={[
                      { value: "YES", label: "Yes" },
                      { value: "NO", label: "No" },
                    ]}
                    direction="vertical"
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.hasSubsidiary]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "hasSubsidiary",
                      client,
                      formik,
                    })}
                  />
                </StackY>
                {hasRelevantDisabilityStates && formik.values.hasSubsidiary === "YES" && (
                  <StackY dist={8}>
                    <RadioGroup
                      name="subsidiaryEEsInNYNJOrHI"
                      label={
                        <StackY dist={8}>
                          <Body2 as="div">
                            Do the subsidiaries or affiliates have employees who work in Hawaii, New
                            Jersey, or New York?
                          </Body2>
                          <Body3 as="div">
                            If you have an subsidiary or affiliate companies whose employees are
                            also part of this application and whose employees work in Hawaii, New
                            Jersey or New York, we will need their tax ID number as well.
                          </Body3>
                        </StackY>
                      }
                      onChange={formik.handleChange}
                      value={formik.values.subsidiaryEEsInNYNJOrHI}
                      disabled={formik.isSubmitting}
                      touched={
                        formik.touched.subsidiaryEEsInNYNJOrHI ||
                        !!strictErrors.subsidiaryEEsInNYNJOrHI
                      }
                      error={
                        formik.errors.subsidiaryEEsInNYNJOrHI ||
                        strictErrors.subsidiaryEEsInNYNJOrHI
                      }
                      options={[
                        { value: "YES", label: "Yes" },
                        { value: "NO", label: "No" },
                      ]}
                      direction="vertical"
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.Client.subsidiaryEEsInNYNJOrHI]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "subsidiaryEEsInNYNJOrHI",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                )}
                {hasRelevantDisabilityStates &&
                  formik.values.hasSubsidiary === "YES" &&
                  formik.values.subsidiaryEEsInNYNJOrHI === "YES" && (
                    <StackY dist={8}>
                      <RadioGroup
                        name="mainPolicyHolderEEsInNYNJOrHI"
                        label={<Body2 as="div">{getMainPolicyHolderCopy()}</Body2>}
                        onChange={formik.handleChange}
                        value={formik.values.mainPolicyHolderEEsInNYNJOrHI}
                        disabled={formik.isSubmitting}
                        touched={
                          formik.touched.mainPolicyHolderEEsInNYNJOrHI ||
                          !!strictErrors.mainPolicyHolderEEsInNYNJOrHI
                        }
                        error={
                          formik.errors.mainPolicyHolderEEsInNYNJOrHI ||
                          strictErrors.mainPolicyHolderEEsInNYNJOrHI
                        }
                        options={[
                          {
                            value: "YES",
                            label: (
                              <StackY dist={8}>
                                <div className="mb-16">
                                  <Body3>Yes</Body3>
                                </div>
                                {formik.values.mainPolicyHolderEEsInNYNJOrHI === "YES" &&
                                  relevantDisabilityStates.map((state) => {
                                    return (
                                      <div key={state}>
                                        <Checkbox
                                          id={`mainPolicyHolderEEStates_${state}`}
                                          name={`mainPolicyHolderEEStates`}
                                          value={state}
                                          label={getMainPolicyHolderCheckboxLabel(state, plans)}
                                          checked={
                                            formik.values.mainPolicyHolderEEStates?.includes(
                                              state,
                                            ) ?? false
                                          }
                                          disabled={formik.isSubmitting}
                                          errorId={mainPolicyHolderEEStateErrorId}
                                          onChange={formik.handleChange}
                                        />
                                      </div>
                                    );
                                  })}
                                <div aria-live="assertive">
                                  {((formik.errors.mainPolicyHolderEEStates &&
                                    formik.touched.mainPolicyHolderEEStates) ||
                                    strictErrors.mainPolicyHolderEEStates) && (
                                    <InputErrorMessage
                                      id={mainPolicyHolderEEStateErrorId}
                                      error={
                                        formik.touched.mainPolicyHolderEEStates &&
                                        formik.errors.mainPolicyHolderEEStates
                                          ? formik.errors.mainPolicyHolderEEStates
                                          : strictErrors.mainPolicyHolderEEStates
                                          ? strictErrors.mainPolicyHolderEEStates
                                          : undefined
                                      }
                                    />
                                  )}
                                </div>
                              </StackY>
                            ),
                          },
                          { value: "NO", label: "No" },
                        ]}
                        direction="vertical"
                      />
                      <EditedFieldMsg
                        changeDetailInfoList={[
                          changeSnapshot.Client.mainPolicyHolderEEsInNYNJOrHI,
                          changeSnapshot.Client.mainPolicyHolderEEStates,
                        ]}
                        client={client}
                        authUser={authUser}
                        hasPendingEdit={
                          getHasPendingEdit({
                            field: "mainPolicyHolderEEsInNYNJOrHI",
                            client,
                            formik,
                          }) ||
                          getHasPendingEdit({
                            field: "mainPolicyHolderEEStates",
                            client,
                            formik,
                          })
                        }
                      />
                    </StackY>
                  )}
              </StackY>
            </form>
          </HubCard>
          {formik.values.hasSubsidiary === "YES" &&
            (formik.values.subsidiaryEEsInNYNJOrHI === "YES" ||
              benefitTypes.includes("DENTAL_ASO")) && (
              <EIFAddASubsidiary
                client={client}
                plans={plans}
                track={track}
                subsidiaries={subsidiaries}
                relevantDisabilityStates={relevantDisabilityStates}
                changeSnapshot={changeSnapshot}
                authUser={authUser}
                subsidiaryToEdit={subsidiaryToEdit}
                setSubsidiaryToEdit={setSubsidiaryToEdit}
                formik={formik}
              />
            )}
          <EIFBottomNavButtons
            previousLink={previousSubStepLink}
            previousButtonDisabled={formik.isSubmitting}
            nextButtonDisabled={formik.isSubmitting}
            onSubmitClick={() => {
              setNavigateNextOnSave(true);
              formik.handleSubmit();
            }}
          />
        </StackY>

        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};

export const getMainPolicyHolderCheckboxLabel = (state: DisabilityStateCode, plans: Plan[]) => {
  switch (state) {
    case "HI":
      return (
        <>
          <Body3 as="div">{LocationStateData[state].displayName}</Body3>
          <Eyebrow>These employees will be covered by Hawaii Temporary Disability</Eyebrow>
        </>
      );
    case "NJ":
      return (
        <>
          <Body3 as="div">{LocationStateData[state].displayName}</Body3>
          <Eyebrow>These employees will be covered by New Jersey Temporary Disability</Eyebrow>
        </>
      );
    case "NY": {
      const conditionalCopy = plans.some((plan) => plan.benefitType === "PFL")
        ? "New York Disability and New York Paid Family Leave"
        : "New York Disability";
      return (
        <>
          <Body3 as="div">{LocationStateData[state].displayName}</Body3>
          <Eyebrow>These employees will be covered by {conditionalCopy}</Eyebrow>
        </>
      );
    }
    default:
      exhaustiveCheck(state);
      break;
  }
};

export const getRelevantDisabilityStatesFromPlans = (plans: Plan[]) => {
  const benefitTypes = plans?.map((plan) => plan.benefitType);

  const disabilityStates: Set<DisabilityStateCode> = new Set();
  benefitTypes?.forEach((benefitType) => {
    if (benefitType === "TDI") {
      disabilityStates.add("HI");
    }
    if (benefitType === "TDB") {
      disabilityStates.add("NJ");
    }
    if (benefitType === "DBL" || benefitType === "PFL") {
      disabilityStates.add("NY");
    }
  });

  return Array.from(disabilityStates).sort();
};

const getInitialLocationValue = (location: Location | null | undefined, hasDentalASO: boolean) => {
  return location
    ? {
        name: location.name,
        address1: location.address1,
        address2: location.address2,
        city: location.city,
        state: location.state,
        zipCode: location.zipCode,
        locationType: "SUBSIDIARY" as const,
      }
    : hasDentalASO
    ? {
        name: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        locationType: "SUBSIDIARY" as const,
      }
    : null;
};
