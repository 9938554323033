import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { useEmbeddedViewAction } from "./onboardingForms";
import { useClientHubParams } from "./useClientHubParams";

export const useDSIFrameEventMessage = (cleanUp: () => void, preventNavigation: boolean) => {
  const navigate = useNavigate();
  const { clientId } = useClientHubParams();

  useEffect(() => {
    if (!clientId) {
      return;
    }

    if (!preventNavigation) {
      navigate(RouteData.clientTaskDetail.getPath(clientId, "onboarding-forms"));
    }

    const handleIframeMessage = (messageEvent: MessageEvent) => {
      const expectedOrigin = window.location.origin;
      const expectedAction = "closeDocusignIframe";
      if (
        messageEvent.isTrusted &&
        messageEvent.origin === expectedOrigin &&
        messageEvent.data === expectedAction
      ) {
        cleanUp();
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => window.removeEventListener("message", handleIframeMessage);
  }, [clientId, navigate, cleanUp, preventNavigation]);
};

export const useDSEmitEventMessage = () => {
  const location = useLocation();
  const { clientId, onboardingFormId, recipientId } = useClientHubParams([
    "clientId",
    "onboardingFormId",
    "recipientId",
  ]);
  const {
    mutate: updateEmbeddedViewAction,
    isSuccess,
    isPending,
    isError,
    error,
  } = useEmbeddedViewAction(clientId, onboardingFormId, recipientId);
  const queryParameters = new URLSearchParams(location.search);
  const eventAction = queryParameters.get("event");

  useEffect(() => {
    if (
      !clientId ||
      !onboardingFormId ||
      !recipientId ||
      !eventAction ||
      !updateEmbeddedViewAction
    ) {
      return;
    }
    updateEmbeddedViewAction({ data: { event: eventAction } });
  }, [clientId, onboardingFormId, recipientId, eventAction, updateEmbeddedViewAction]);

  useEffect(() => {
    if (isSuccess) {
      window.parent.postMessage("closeDocusignIframe", window.location.origin);
    }
  }, [isSuccess]);

  return {
    isError,
    isPending,
    error,
  };
};
