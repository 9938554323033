import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { UnsavedChangesModal } from "../../domain/Client/UnsavedChangesModal";
import { useDSIFrameEventMessage } from "../../hooks/docusign";
import { useClientHubParams } from "../../hooks/useClientHubParams";

import * as styles from "./formPage.module.less";
import type { Location } from "react-router-dom";

export type FormPageLocationState = {
  trigger?: string;
  recipientViewUrl?: string;
};

interface FormPageLocation extends Location {
  state: FormPageLocationState | undefined;
}

export const FormPage = () => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const location = useLocation() as FormPageLocation;
  const { clientId } = useClientHubParams(["clientId", "onboardingFormId", "recipientId"]);

  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);

  const unsavedChangesBeforeUnloadListener = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    return (e.returnValue = "");
  };

  const cleanUpAfterDoneInIframe = () => {
    // remove event listener that UnsavedChangesModal uses to prevent browser refreshes
    window.removeEventListener("beforeunload", unsavedChangesBeforeUnloadListener);
    // stop UnsavedChangesModal from showing before navigation
    setPreventNavigation(false);
  };

  useDSIFrameEventMessage(cleanUpAfterDoneInIframe, preventNavigation);

  if (
    location.state == null ||
    location.state?.trigger !== "onboardingFormTileClick" ||
    location.state?.recipientViewUrl == null
  ) {
    return (
      <Navigate to={RouteData.clientTaskDetail.getPath(clientId, "onboarding-forms")} replace />
    );
  }

  const extraUnsavedChangesText = `To save your changes and finish later, close this modal and click "Other Actions" - "Finish Later" in DocuSign.`;

  return (
    <>
      <UnsavedChangesModal
        title="You may have unsaved changes"
        hasChanges={preventNavigation}
        extraDescription={extraUnsavedChangesText}
        isSubmitting={false}
      />
      <iframe
        data-testid="docusignIframe"
        title="Benefits Document Envelope"
        className={styles.layoutWithHeader}
        src={location.state?.recipientViewUrl}
      />
    </>
  );
};
