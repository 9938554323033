import * as Yup from "yup";
import "./email";

export const templateLookupValidation = Yup.object({
  docusignTemplateId: Yup.string().required(),
});

export const reassignRecipientValidationV2 = Yup.object({
  previousRecipientId: Yup.string().required("Previous signer is required"),
  newSignerType: Yup.mixed<"EXISTING_ONBOARD_USER" | "NEW_OUTSIDE_USER">()
    .oneOf(["EXISTING_ONBOARD_USER", "NEW_OUTSIDE_USER"])
    .required("Signer type is required"),
  name: Yup.string().when("newSignerType", {
    is: "NEW_OUTSIDE_USER",
    then: (schema) => schema.required("Signer name is required"),
  }),
  email: Yup.string()
    .emailV1()
    .when("newSignerType", {
      is: "NEW_OUTSIDE_USER",
      then: (schema) => schema.emailV1().required("Signer email is required"),
    }),
  newSignerOnboardId: Yup.string().when("newSignerType", {
    is: "EXISTING_ONBOARD_USER",
    then: (schema) => schema.required("New signer is required"),
  }),
});

export const addRecipientValidation = Yup.object({
  newSignerOnboardId: Yup.string().required("New signer is required"),
  roleName: Yup.string().required("Signer role is required"),
});

export const signerEmailValidator = Yup.object({
  emails: Yup.array().of(Yup.string().emailV1().required()).min(1),
});

export const updateSignerTypeValidator = Yup.object({
  recipientId: Yup.string().required("Signer is required"),
  updateSigner: Yup.boolean()
    .oneOf([true], "The change must be accepted")
    .required("The change must be accepted"),
});
