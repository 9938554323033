import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import moment from "moment";

import { RouteData } from "../../../../../shared/config/routeData";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import { Body1, Body3, H1Large, H2 } from "../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../i18n";
import { useExplorerTrackElementClicked } from "../../../utils/analytics";
import { timezoneAdjustedMoment } from "../../../utils/moment";

import * as styles from "./clientPage.module.less";

import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";

export const DataTestId = {
  CountdownWrapper: "countdown-wrapper",
};

type ExplorerCountdownProps = {
  explorerPage: PublicExplorerPage;
  benefitsCount?: number;
};
export const ExplorerCountdown = ({ explorerPage, benefitsCount }: ExplorerCountdownProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerCountdown" });
  const isMobile = !useBreakpoint().md;
  const trackElementClicked = useExplorerTrackElementClicked(explorerPage);

  if (!explorerPage.openEnrollmentStart || !explorerPage.openEnrollmentEnd) return null;

  const isFuture = moment().isBefore(
    timezoneAdjustedMoment(moment(explorerPage.openEnrollmentStart)),
  );
  const isPast = moment().isAfter(
    timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd).add({ day: 1 })),
  );

  const daysUntil =
    timezoneAdjustedMoment(moment(explorerPage.openEnrollmentStart)).diff(moment(), "days") + 1;

  const daysUntilEnd =
    timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd)).diff(moment(), "days") + 1;

  const useExternalFormsLink = explorerPage.benAdminPlatform?.name === "I don’t use a platform";

  const isPageExpiresInFuture = explorerPage?.expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
      ? true
      : false
    : true;

  const newSiteMessageContext = isPageExpiresInFuture ? "future" : "past";

  return (
    <div
      className={clsx(styles.countdown, isMobile && styles.mobile)}
      data-testid={DataTestId.CountdownWrapper}
    >
      <Row align="middle" justify="center" className={styles.container}>
        <Col>
          {explorerPage.newExplorerPageId ? (
            <>
              <H2>
                <Trans t={t} i18nKey={"header"} tOptions={{ context: "past" }} />
              </H2>
              <Trans
                t={t}
                i18nKey={"newSiteMessage"}
                values={{
                  date: timezoneAdjustedMoment(moment()).toDate(),
                }}
                tOptions={{ context: newSiteMessageContext }}
              >
                <Body1 as="p"></Body1>
              </Trans>
              {explorerPage.benAdminPlatformUrl && (
                <div className="mt-12">
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={explorerPage.benAdminPlatformUrl}
                    target="_blank"
                  >
                    {benefitsCount === 0
                      ? t("callToAction", { context: "nobenefits" })
                      : useExternalFormsLink
                      ? t("callToAction", { context: "externalLink" })
                      : t("callToAction", { context: "past" })}
                  </InternalLinkButton>
                </div>
              )}
              {!isPageExpiresInFuture && (
                <div className="mt-12">
                  <Button
                    type="secondary"
                    size="middle"
                    to={RouteData.benefitsExplorer.getPath(explorerPage.newExplorerPageId)}
                  >
                    {t("callToAction_NewSite")}
                  </Button>
                </div>
              )}
            </>
          ) : isPast ? (
            <>
              <H2>
                <Trans t={t} i18nKey={"header"} tOptions={{ context: "past" }} />
              </H2>
              <Trans
                t={t}
                i18nKey={"subheader"}
                values={{
                  date: timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd)).toDate(),
                }}
                tOptions={{ context: "past" }}
              >
                <Body1 as="p"></Body1>
              </Trans>
              {explorerPage.benAdminPlatformUrl && (
                <div className="mt-12">
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={explorerPage.benAdminPlatformUrl}
                    target="_blank"
                  >
                    {benefitsCount === 0
                      ? t("callToAction", { context: "nobenefits" })
                      : useExternalFormsLink
                      ? t("callToAction", { context: "externalLink" })
                      : t("callToAction", { context: "past" })}
                  </InternalLinkButton>
                </div>
              )}
            </>
          ) : isFuture ? (
            <>
              <Row justify="center" className="mb-12">
                {daysUntil
                  .toString()
                  .split("")
                  .map((digit, i) => (
                    <Col
                      key={`digit-${i}`}
                      className={clsx(styles.digit, "px-24", "mx-8", "pt-12")}
                    >
                      <H1Large>{digit}</H1Large>
                    </Col>
                  ))}
              </Row>
              <H2>
                <Trans t={t} i18nKey={"header"} tOptions={{ context: "future" }} />
              </H2>
              {explorerPage.notificationLink && (
                <>
                  <div className="mb-12">
                    <Body3>{t("subheader", { context: "future" })}</Body3>
                  </div>
                  <Button
                    href={explorerPage.notificationLink}
                    size="middle"
                    type="secondary"
                    newTab
                    onClick={() =>
                      trackElementClicked({
                        module: "Explorer Countdown",
                        buttonLabel: "Text Notification",
                        daysUntilOE: daysUntil,
                      })
                    }
                  >
                    {t("callToAction", { context: "future" })}
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <H2>{t("header")}</H2>
              <Body1>
                <Trans
                  t={t}
                  i18nKey={"subheader"}
                  values={{
                    date: timezoneAdjustedMoment(moment(explorerPage.openEnrollmentEnd)).toDate(),
                  }}
                />
              </Body1>
              {explorerPage.benAdminPlatformUrl && (
                <div className="mt-12">
                  <Button
                    href={explorerPage.benAdminPlatformUrl}
                    size="middle"
                    type="secondary"
                    newTab
                    onClick={() =>
                      trackElementClicked({
                        module: "Explorer Countdown",
                        buttonLabel: "Enroll",
                        daysLeftInOE: daysUntilEnd,
                      })
                    }
                  >
                    {benefitsCount === 0
                      ? t("callToAction", { context: "nobenefits" })
                      : useExternalFormsLink
                      ? t("callToAction", { context: "externalLink" })
                      : t("callToAction", { context: "past" })}
                  </Button>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
