import { useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { HorizontalDivider } from "../../../../components/HorizontalDivider/HorizontalDivider";
import { Loading } from "../../../../components/Loading/Loading";
import { OrderedList, OrderedListItem } from "../../../../components/OrderedList/OrderedList";
import { StackY } from "../../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../../components/Typography/Typography";
import {
  EmailErrorUser,
  toClientUser,
} from "../../../../domain/Client/ClientsList/ClientsTable/EmailError/EmailErrorDrawer";
import { EmailErrorModal } from "../../../../domain/Client/ClientsList/ClientsTable/EmailError/EmailErrorModal";
import {
  useCreateActivationLink,
  useGetUsers,
  useUpdateEmailAndSendActivationEmail,
} from "../../../../hooks/user";
import type { ClientError } from "@prisma/client";
import type { FC } from "react";
import type { UserId } from "shared/types/User";

type WelcomeEmailDetailsProps = {
  data: ClientError[];
  clientId: string;
};

export const WelcomeEmailDetails: FC<WelcomeEmailDetailsProps> = ({ data, clientId }) => {
  const [resendUserIds, setResendToUserIds] = useState<UserId[]>([]);
  const [showEditEmailModal, setShowEditEmailModal] = useState<boolean>(false);
  const { data: emailErrorUsers } = useGetUsers({
    userIds: data
      .filter((clientError) => clientError.modelType === "USER" && clientError.modelId)
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- filter above ensures proper value
      .map((error) => error.modelId as UserId),
  });
  const { mutateAsync: createActivationLink, isPending: isPendingCreateActivationLink } =
    useCreateActivationLink();
  const { mutateAsync: updateEmailAndSendActivationEmail } = useUpdateEmailAndSendActivationEmail();

  if (!emailErrorUsers) {
    return <Loading />;
  }

  const shouldShowCheckbox = emailErrorUsers.length > 1;
  const usersEditOrActivate =
    emailErrorUsers?.filter((user) => !shouldShowCheckbox || resendUserIds.includes(user.id)) ?? [];
  const disableUpdateAndResendButton =
    (emailErrorUsers ?? []).length > 1 ? resendUserIds.length === 0 : false;

  const handleResendCheckbox = (userId: UserId) => {
    const selected = resendUserIds.includes(userId);
    const updatedIds = selected
      ? resendUserIds.filter((id) => id !== userId)
      : [...resendUserIds, userId];
    setResendToUserIds(updatedIds);
  };

  return (
    <StackY dist={32}>
      <div className="ml-32">
        <Body2>Do this first:</Body2>
      </div>
      <OrderedList spacing="large">
        <OrderedListItem>
          <StackY dist={24} className="w-full">
            <Body3>
              The following users failed to receive their welcome email. Check to ensure the email
              address is correct and then resend the email from here in the portal.
            </Body3>

            {emailErrorUsers?.map((user, index) => {
              const isUserChecked = resendUserIds.includes(user.id);
              return (
                <div key={user.id}>
                  {index > 0 && <HorizontalDivider size={16} />}
                  <EmailErrorUser
                    createActivationLink={createActivationLink}
                    isPendingCreateActivationLink={isPendingCreateActivationLink}
                    type={"resend"}
                    clientUser={toClientUser(user)}
                    checked={isUserChecked}
                    handleResendCheckbox={handleResendCheckbox}
                    shouldShowCheckbox={shouldShowCheckbox}
                    asAlert
                  />
                </div>
              );
            })}

            <div className="ml-24">
              <Button
                type="tertiary"
                disabled={disableUpdateAndResendButton}
                onClick={() => setShowEditEmailModal(true)}
              >
                Update & resend email
              </Button>
            </div>

            <Body2>If step 1 doesn't resolve the issue, please follow the steps below.</Body2>
          </StackY>
        </OrderedListItem>

        <OrderedListItem>
          <StackY dist={24} className="w-full">
            <Body3>
              Copy the user specific welcome email activation link and email it to the intended
              recipient directly from your own email. If we record a login from them, we will remove
              the error from the email log.
            </Body3>
            {emailErrorUsers?.map((user, index) => (
              <div key={user.id}>
                {index > 0 && <HorizontalDivider size={16} />}

                <EmailErrorUser
                  createActivationLink={createActivationLink}
                  isPendingCreateActivationLink={isPendingCreateActivationLink}
                  type={"activationLink"}
                  clientUser={toClientUser(user)}
                  handleResendCheckbox={handleResendCheckbox}
                  asAlert
                />
              </div>
            ))}
          </StackY>
        </OrderedListItem>

        <OrderedListItem>
          <StackY dist={24} className="w-full">
            <Body3>
              Ask the client to make sure our IP address is added to their spam filter "allow-list."
              Our IP address is <b>198.2.180.107</b>. Submit a support ticket after reaching out to
              the client
            </Body3>
            <Body2>
              If these troubleshooting steps do not resolve this issue, please submit a Jira ticket
              to engineering for a follow up.
            </Body2>
          </StackY>
        </OrderedListItem>
      </OrderedList>
      {showEditEmailModal && (
        <EmailErrorModal
          showModal
          clientId={clientId}
          onClose={() => setShowEditEmailModal(false)}
          users={usersEditOrActivate.map(toClientUser)}
          updateEmailAndSendActivationEmail={updateEmailAndSendActivationEmail}
        />
      )}
    </StackY>
  );
};
