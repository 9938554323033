import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

export const getEmployeeClassPlansByBenefitType = (employeeClass: EmployeeClass) => {
  return employeeClass.employeeClassPlans.reduce<
    Partial<Record<BenefitTypeEIF, EmployeeClassPlanWithPlan[]>>
  >((acc, employeeClassPlan) => {
    const benefitType = employeeClassPlan.plan.benefitType;
    if (benefitType in acc) {
      const currentPlans = acc[benefitType] ?? [];
      currentPlans.push(employeeClassPlan);
      acc[benefitType] = currentPlans;
    } else {
      acc[benefitType] = [employeeClassPlan];
    }

    return acc;
  }, {});
};
