import { eifStepIdMap } from "shared/types/EIF";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import type { EIFSubStepViewMode } from "shared/types/Client";
import type { EIFStepId } from "shared/types/EIF";

export function EIFStepHeader({
  viewMode,
  eifStepId,
}: {
  viewMode: EIFSubStepViewMode;
  eifStepId: EIFStepId;
}) {
  if (viewMode === "pre-sign") {
    return (
      <>
        <h2>Review your information</h2>

        <p>Please review the information you entered below and make sure there are no mistakes.</p>
      </>
    );
  } else if (viewMode === "post-sign-with-edit") {
    return null;
  } else if (viewMode === "review-page") {
    return <h2>{eifStepIdMap[eifStepId]}</h2>;
  } else {
    exhaustiveCheckFail(viewMode);
  }
}
