import { formatLocation } from "shared/utils/location";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import { Body3 } from "../components/Typography/Typography";
import type { Location } from "shared/types/Location";
import type { ValidationError } from "yup";

export const getLocation = (location: Location | null, requireLocationName = true) => {
  const relevantValidation = requireLocationName
    ? locationInputValidation
    : locationInputValidation.omit(["name"]);
  const errors = getValidationErrors(relevantValidation, location, {
    prefill: false,
  });

  if (!location) {
    return (
      <Body3 as="div" redMedium>
        Please enter a location
      </Body3>
    );
  }

  if (errors.length === 0 && location) {
    return <Body3 as="div">{formatLocation(location, requireLocationName)}</Body3>;
  }

  const locationErrorsByKey = errors.reduce((acc: Record<string, ValidationError>, error) => {
    return {
      ...acc,
      [error.path ? error.path : "unknownPath"]: error,
    };
  }, {});

  return (
    <div>
      {requireLocationName && (
        <Body3 as="div" redMedium={locationErrorsByKey.name ? true : false}>
          {locationErrorsByKey.name ? locationErrorsByKey.name.message : location?.name}
        </Body3>
      )}
      <Body3 as="div" redMedium={locationErrorsByKey.address1 ? true : false}>
        {locationErrorsByKey.address1 ? locationErrorsByKey.address1.message : location?.address1}
      </Body3>
      {location?.address2 && <Body3 as="div">{location?.address2}</Body3>}
      <Body3 as="div" redMedium={locationErrorsByKey.city ? true : false}>
        {locationErrorsByKey.city ? locationErrorsByKey.city.message : location?.city}
      </Body3>
      <Body3 as="div" redMedium={locationErrorsByKey.state ? true : false}>
        {locationErrorsByKey.state ? locationErrorsByKey.state.message : location?.state}
      </Body3>
      <Body3 as="div" redMedium={locationErrorsByKey.zipCode ? true : false}>
        {locationErrorsByKey.zipCode ? locationErrorsByKey.zipCode.message : location?.zipCode}
      </Body3>
    </div>
  );
};
