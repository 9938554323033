import { Upload as AntUpload } from "antd";
import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { ReactComponent as ShieldIcon } from "../../components/Icons/ShieldIcon.svg";
import { StoredSecurelyText } from "../../domain/Document/StoredSecurelyText";
import { Body1, Body3, H2 } from "../Typography/Typography";

import * as styles from "./upload.module.less";

import type { UploadProps as AntUploadProps } from "antd";
import type AjaxUploader from "rc-upload/es/AjaxUploader";
import type AntRcUpload from "rc-upload/es/Upload";
import type { Component, DragEvent as ReactDragEvent } from "react";

/**
 * @internal
 */
interface AntUploadRef {
  onBatchStart: unknown;
  onSuccess: unknown;
  onProgress: unknown;
  onError: unknown;
  fileList: unknown;

  upload: Omit<AntRcUpload, "uploader" | keyof Component> & {
    uploader?: Omit<AjaxUploader, "fileInput" | keyof Component> & { fileInput?: HTMLInputElement };
  };
}

export interface UploadRef {
  getDelimitedDropzone?: () => HTMLElement | null | undefined;
  onFileDrop?: (e: DragEvent) => void;
}

export interface UploadProps extends AntUploadProps {
  track: (buttonLabel: string) => void;
  uploading: boolean;
  dragEnabled?: boolean;
  maxSizeInMB?: number;
}

export const Upload = forwardRef<UploadRef, UploadProps>((props, ref) => {
  const antUploaderRef = useRef<AntUploadRef | null>(null);

  const isDragging = useIsDragging(props.dragEnabled ?? true);

  useImperativeHandle(ref, () => {
    return {
      getDelimitedDropzone() {
        return antUploaderRef.current?.upload?.uploader?.fileInput?.parentElement;
      },
      onFileDrop(e) {
        const onFileDrop = antUploaderRef.current?.upload?.uploader?.onFileDrop;

        if (!onFileDrop) {
          throw new Error(
            "Document body as dropzone not working as expected. Perhaps Ant Design's Upload components API changed ?",
          );
        }

        return onFileDrop(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- this seems to be working
          e as unknown as ReactDragEvent<HTMLDivElement>,
        );
      },
    };
  });

  const dropperText = !props.multiple ? "Drop a single file here" : "Drop your files here";

  const maxSizeInMB = props.maxSizeInMB ?? 20;
  let maxSizeText: string;
  if (maxSizeInMB > 1000) {
    const maxSizeInGB = Number((maxSizeInMB / 1000).toFixed(1));
    maxSizeText = `${maxSizeInGB} GB`;
  } else {
    maxSizeText = `${maxSizeInMB} MB`;
  }

  return (
    <div
      className={clsx([
        "text-center",
        styles.uploadContainer,
        isDragging && styles.dragging,
        props.uploading && styles.uploading,
      ])}
    >
      <div>
        {isDragging && (
          <H2 blueMedium as="div">
            {dropperText}
          </H2>
        )}

        <div className={clsx(isDragging && styles.hide)}>
          <Body1>
            {dropperText} or{" "}
            <AntUpload
              disabled={props.uploading}
              {...props}
              ref={antUploaderRef}
              aria-label="browse"
            >
              <span className={styles.cta}>browse</span>
            </AntUpload>
          </Body1>
        </div>

        <Body3 as="div" colorSecondary>
          Various file types such as .pdf, .doc, .xlsx, .zip, etc. are supported. <br />
          Maximum file size is {maxSizeText}.
        </Body3>

        {isDragging ? (
          <div className={clsx("mt-24", styles.shield)}>
            <ShieldIcon />
          </div>
        ) : (
          <div className="mt-40">
            <StoredSecurelyText track={props.track} />
          </div>
        )}
      </div>
    </div>
  );
});

export function useDocumentBodyAsFileDropZone(visible: boolean) {
  const draggerRef = useRef<UploadRef | null>(null);

  useEffect(() => {
    function onFileDrop(e: DragEvent) {
      e.preventDefault();

      const delimitedDropzone = draggerRef.current?.getDelimitedDropzone?.();
      const eventPath = e.composedPath();
      const droppedInDelimitedDropzone = eventPath.some((el) => el === delimitedDropzone);

      if (!droppedInDelimitedDropzone) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- disable
        draggerRef.current?.onFileDrop?.(e);
      }
    }

    if (visible) {
      document.body.addEventListener("drop", onFileDrop);
      document.body.addEventListener("dragover", onFileDrop);
      document.body.addEventListener("dragleave", onFileDrop);
    }

    return () => {
      if (visible) {
        document.body.removeEventListener("drop", onFileDrop);
        document.body.removeEventListener("dragover", onFileDrop);
        document.body.removeEventListener("dragleave", onFileDrop);
      }
    };
  }, [visible]);

  return draggerRef;
}

function useIsDragging(enabled: boolean) {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    let elementsBeingDraggedOver = 0;

    function dragenter() {
      elementsBeingDraggedOver++;
      setIsDragging(elementsBeingDraggedOver > 0);
    }
    function dragleave() {
      elementsBeingDraggedOver--;
      setIsDragging(elementsBeingDraggedOver > 0);
    }
    function drop() {
      elementsBeingDraggedOver = 0;
      setIsDragging(false);
    }

    if (enabled) {
      window.addEventListener("dragenter", dragenter);
      window.addEventListener("dragleave", dragleave);
      window.addEventListener("drop", drop);
    }

    return () => {
      if (enabled) {
        window.removeEventListener("dragenter", dragenter);
        window.removeEventListener("dragleave", dragleave);
        window.removeEventListener("drop", drop);
      }
    };
  }, [enabled]);

  return isDragging;
}
