import { Skeleton, Card } from "antd";
import { Button } from "client/src/components/Button/Button";
import { EmployeeEnrollmentResourcesCard } from "client/src/components/EmployeeEnrollmentResourcesCard/EmployeeEnrollmentResourcesCard";
import { LoadingError } from "client/src/components/Error/LoadingError";
import { Row, Col } from "client/src/components/Grid/Grid";
import { ClientInternalErrorBanner } from "client/src/domain/Client/ClientErrors/ClientInternalErrorBanner";
import { getOnboardingFormBadgeMessage } from "client/src/domain/OnboardingForm/utils/onboardingFormUtils";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { SupportInformationV2 } from "client/src/pages/Hub/SupportInformationV2";
import clsx from "clsx";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import {
  getOngoingServiceTeamContacts,
  getBenefitClips,
  getBasicEnrollmentFlyers,
} from "shared/data/Client";
import { TaskOrder } from "shared/data/Tasks";
import { getIsInternalUser } from "shared/rbac/rbac";
import { isAddCoverageClient } from "shared/utils/client";
import { getIsCoverageActive } from "shared/utils/date";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { Anchor } from "../../components/Anchor/Anchor";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubHeader } from "../../components/HubHeader/HubHeader";
import { HubSection } from "../../components/HubSection/HubSection";
import { StackY } from "../../components/Spacing/Spacing";
import { Body1, Body2, Body3, Body5 } from "../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../components/UnorderedList/UnorderedList";
import {
  eifNIGOMessage,
  getBillsAndCertificatesNIGOMessage,
  getCensusEnrollmentElectionsBadgeMessage,
} from "../../domain/Task/nigoMessage";
import { useSlobAuth } from "../../hooks/auth";
import { useGetClientByID, useWelcomeFlow } from "../../hooks/client";
import { useGetNewDocumentNotices, useMarkNewDocumentNoticesAsSeen } from "../../hooks/document";
import { triggerError } from "../../hooks/generalError";
import { tasksToTaskDetails } from "../../hooks/task";
import { useClientHubParams } from "../../hooks/useClientHubParams";

import * as styles from "./hub.module.less";

import type { TaskDetail } from "../../hooks/task";
import type { TaskStatus, PolicyTask } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export const Hub = () => {
  const { clientId } = useClientHubParams(["clientId"]);
  const {
    data: clientData,
    error: clientError,
    isFetching: clientIsFetching,
  } = useGetClientByID(clientId);
  const {
    data: welcomeFlowData,
    error: welcomeFlowError,
    isLoading: welcomeFlowIsLoading,
  } = useWelcomeFlow(clientId);
  const {
    data: newDocumentNoticesData,
    error: newDocumentNoticesError,
    isLoading: newDocumentNoticesIsLoading,
  } = useGetNewDocumentNotices(clientId);
  const {
    isLoading: isLoadingFTs,
    isLoadingError: isLoadingErrorFTs,
    isError: isErrorFTs,
    error: errorFTs,
    data: featureToggles,
  } = useGetFeatureToggles(clientId);

  useMarkNewDocumentNoticesAsSeen(clientId);

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const shouldShowLoadingState =
    clientIsFetching || welcomeFlowIsLoading || newDocumentNoticesIsLoading || isLoadingFTs;

  if (shouldShowLoadingState) {
    return <HubPageSkeleton />;
  }

  if (clientError) {
    return triggerError(clientError);
  }
  if (welcomeFlowError) {
    return triggerError(welcomeFlowError);
  }
  if (newDocumentNoticesError) {
    return triggerError(newDocumentNoticesError);
  }
  if (isErrorFTs || isLoadingErrorFTs || !featureToggles) {
    return triggerError(errorFTs);
  }

  if (!clientData) {
    return <LoadingError type="page" />;
  }

  if (!welcomeFlowData) {
    return triggerError("Couldn't get welcome flow!");
  }

  if (welcomeFlowData.welcomeFlowStatus === "WELCOME") {
    return <Navigate to={RouteData.welcome.getPath(clientId ?? "")} replace />;
  }

  if (clientData.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const usingDEIF = Boolean(clientData.digitalEIF);

  const { toDoTasks, inReviewTasks, completedTasks, upcomingTasks, allTasks } = getHubTasks({
    clientData,
    usingDEIF,
    usingOnboardNIGO: true,
  });

  const isOnboardingComplete = Boolean(clientData.releaseStatus === "LIVE");
  const isCoverageActive = clientData.policies.every((policy) =>
    getIsCoverageActive(policy.policyEffective),
  );
  const policyEffectiveDates = !isCoverageActive
    ? clientData.policies.map((policy) => ({
        effective: policy.policyEffective,
        active: getIsCoverageActive(policy.policyEffective),
      }))
    : [];

  const { contacts: ongoingServiceTeamContacts } = getOngoingServiceTeamContacts(clientData, {
    useHealthcareProfessionalsSegment: Boolean(featureToggles.ONBOARD_CRE_HEALTHCARE_PROFESSIONALS),
  });
  const dentalCoverages = new Set<SlfCoverageLongName>(["Dental", "Dental (DHMO)", "ASO Dental"]);
  const hasDental = clientData.allPoliciesSlfCoverages?.some((c) => dentalCoverages.has(c));

  const newDocumentsPerCategory =
    newDocumentNoticesData?.reduce<Partial<Record<DocumentCategory, number>>>((sum, notice) => {
      const { category } = notice.document;
      sum[category] = (sum[category] || 0) + 1;
      return sum;
    }, {}) ?? {};

  const benefitClips = getBenefitClips(clientData);
  const basicEnrollmentFlyers = getBasicEnrollmentFlyers(clientData);

  const hasClientErrors = Boolean(clientData.clientErrors?.length);

  return (
    <Col
      className={clsx(
        styles.hubPageOuterContainer,
        isOnboardingComplete && styles.hubPageOuterContainer__isOnboardingComplete,
      )}
    >
      <Col span={24} className="mb-48">
        <HubHeader
          isCoverageActive={isCoverageActive}
          isOnboardingComplete={isOnboardingComplete}
          client={clientData}
          policyEffectiveDates={policyEffectiveDates}
        />
      </Col>

      <Row className={styles.hubPageContainer}>
        {hasClientErrors && isInternalUser && (
          <Col span={24} className="mb-48">
            <ClientInternalErrorBanner client={clientData} />
          </Col>
        )}
        {!isOnboardingComplete && (
          <div className="mb-40 w-full">
            <EmployeeEnrollmentResourcesCard
              benefits={clientData.allPoliciesSlfCoverages || []}
              benefitsReady={clientData.allPoliciesSlfCoveragesReady || []}
            />
          </div>
        )}

        <Col span={17}>
          {isOnboardingComplete ? (
            <>
              <StackY dist={16}>
                <HubCard padding="medium">
                  <h2>Sun Life Connect is your destination for managing your benefits ongoing</h2>

                  <UnorderedList columns={2}>
                    <UnorderedListItem>Manage your profile & settings</UnorderedListItem>
                    <UnorderedListItem>Manage billing & payments</UnorderedListItem>
                    <UnorderedListItem>Manage claims & benefits</UnorderedListItem>
                    <UnorderedListItem>View policy documents</UnorderedListItem>
                    {clientData.eoiRequired && (
                      <UnorderedListItem>View Evidence of Insurability (EOI)</UnorderedListItem>
                    )}
                    {hasDental ? (
                      <UnorderedListItem>Get employee dental ID cards, and more</UnorderedListItem>
                    ) : (
                      <UnorderedListItem>And more</UnorderedListItem>
                    )}
                  </UnorderedList>

                  <p>
                    And, when your employees need to manage their benefits online or file a claim,
                    it’s easy to create an account with Sun Life.
                  </p>

                  <hr />

                  <div className={styles.buttonLinkGrid}>
                    <StackY dist={8}>
                      <Button
                        type="primary"
                        size="large"
                        href="https://login.sunlifeconnect.com/commonlogin/#/login/10"
                        newTab
                      >
                        Sign in to Sun Life Connect
                      </Button>

                      {clientData.baRegisteredSLConnect && (
                        <Body5 as="div">
                          You have one or more web administrators registered with Sun Life Connect.
                        </Body5>
                      )}
                    </StackY>

                    <Anchor
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403016014099"
                      target="_blank"
                      newTabIcon
                    >
                      Read more about managing your <br />
                      benefits on Sun Life Connect
                    </Anchor>
                  </div>
                </HubCard>
                <HubCard padding="medium">
                  <h2>Meet your ongoing service team</h2>

                  <Body1 as="p">
                    Contact your ongoing service team as your guide to navigating your relationship
                    with Sun Life throughout the year.
                  </Body1>

                  {ongoingServiceTeamContacts?.role === "Client Relationship Executive" && (
                    <Row gutter={80}>
                      <Col span={12}>
                        <Body2>Client Relationship Executive</Body2>

                        <p>
                          Your Client Relationship Executive will be checking in and providing
                          beneficial education and updates regarding your plans.
                        </p>

                        <StackY dist={16}>
                          {ongoingServiceTeamContacts?.contacts.map((contact) => (
                            <StackY key={contact.email} dist={12}>
                              <StackY dist={8}>
                                <Body2 as="div">{contact.name}</Body2>
                                <Body3 as="div">{ongoingServiceTeamContacts.role}</Body3>
                              </StackY>
                              {contact.phoneNumber && (
                                <div>
                                  <Anchor href={`tel:${contact.phoneNumber}`}>
                                    {contact.phoneNumber}
                                  </Anchor>
                                </div>
                              )}
                              {contact.email && (
                                <div>
                                  <Anchor
                                    href={`mailto:${contact.email}`}
                                    newTabIcon
                                    target="_blank"
                                  >
                                    {contact.email}
                                  </Anchor>
                                </div>
                              )}
                            </StackY>
                          ))}
                        </StackY>
                      </Col>

                      <Col span={12}>
                        <Body2>Client Advocate</Body2>

                        <p>
                          Your Client Advocate is an expert in day to day plan administration and
                          your liaison to our internal departments. If you need assistance
                          navigating our system or have questions about our process, your Client
                          Advocate is here to guide you.
                        </p>
                      </Col>
                    </Row>
                  )}
                  {ongoingServiceTeamContacts?.role === "Client Loyalty Manager" && (
                    <>
                      <Body2>Client Loyalty Manager</Body2>

                      <p>
                        Your Client Loyalty Manager will work with you for ongoing service, and will
                        periodically check in with you to help ensure you and your employees are
                        having a positive experience with Sun Life.
                      </p>

                      {ongoingServiceTeamContacts?.contacts.map((contact) => (
                        <Body3 as="div" key={contact.email}>
                          {contact.email && (
                            <Anchor href={`mailto:${contact.email}`} newTabIcon target="_blank">
                              {contact.email}
                            </Anchor>
                          )}
                        </Body3>
                      ))}
                    </>
                  )}
                  {ongoingServiceTeamContacts?.role === "Client Services Support Team" && (
                    <>
                      <Body2>Client Services Support Team</Body2>

                      <p>
                        Your Client Services Support Team are experts in day to day policy
                        administration. If you need assistance navigating our system or have
                        questions, your Client Services Support Team are here to guide you.
                      </p>

                      {ongoingServiceTeamContacts?.contacts.map((contact) => (
                        <Body3 as="div" key={contact.email}>
                          {contact.phoneNumber && (
                            <div>
                              <Anchor href={`tel:${contact.phoneNumber}`}>
                                {contact.phoneNumber}
                              </Anchor>
                            </div>
                          )}
                          {contact.email && (
                            <div>
                              <Anchor href={`mailto:${contact.email}`} newTabIcon target="_blank">
                                {contact.email}
                              </Anchor>
                            </div>
                          )}
                        </Body3>
                      ))}
                    </>
                  )}
                </HubCard>
                <EmployeeEnrollmentResourcesCard
                  benefits={clientData.allPoliciesSlfCoverages || []}
                  benefitsReady={clientData.allPoliciesSlfCoveragesReady || []}
                  isOnboardingComplete={isOnboardingComplete}
                />
              </StackY>

              <hr className="mt-40 mb-32" />

              <HubSection
                title="Task history"
                subTitle="You can easily review any of your past tasks here. There’s no action required for any tasks that weren’t completed during Onboard."
                tasks={allTasks}
                client={clientData}
                isCollapsable
                initialIsCollapsed={false}
                newDocumentsPerCategory={newDocumentsPerCategory}
                isOnboardingComplete={isOnboardingComplete}
              />
            </>
          ) : (
            <>
              <StackY dist={40}>
                {toDoTasks.length > 0 && (
                  <HubSection
                    title="Things to do"
                    subTitle="Complete these tasks on time to stay on track."
                    tasks={toDoTasks}
                    client={clientData}
                    newDocumentsPerCategory={newDocumentsPerCategory}
                  />
                )}
                {inReviewTasks.length > 0 && (
                  <HubSection
                    title="In Review"
                    subTitle="Sun Life will review and let you know if anything else is required from you."
                    tasks={inReviewTasks}
                    client={clientData}
                    newDocumentsPerCategory={newDocumentsPerCategory}
                  />
                )}
                {upcomingTasks.length > 0 && (
                  <HubSection
                    title="Upcoming"
                    subTitle="We’ll notify you when these tasks become available to work on."
                    tasks={upcomingTasks}
                    client={clientData}
                    newDocumentsPerCategory={newDocumentsPerCategory}
                  />
                )}
                {completedTasks.length > 0 && (
                  <HubSection
                    title="Completed"
                    tasks={completedTasks}
                    client={clientData}
                    isCollapsable={true}
                    newDocumentsPerCategory={newDocumentsPerCategory}
                  />
                )}
              </StackY>
            </>
          )}
        </Col>

        <Col span={6} offset={1}>
          <SupportInformationV2
            client={clientData}
            showExpertRequestMsg={clientData.enrollmentNeeds != null}
            videoClipsCount={benefitClips.length}
            flyersCount={basicEnrollmentFlyers.length}
          />
        </Col>
      </Row>
    </Col>
  );
};

const addNIGODetailToTaskDetail = (taskDetail: TaskDetail, client: Client, policies: Policy[]) => {
  const policyWithNIGOStatus = policies.find(({ ticketStatus }) => ticketStatus === "NIGO");
  const policyId = policies.length > 1 ? policyWithNIGOStatus?.id : undefined;

  const taskId = taskDetail.id;
  switch (taskId) {
    case "onboarding-forms":
      return {
        ...taskDetail,
        policyId,
        customBannerMessage: getOnboardingFormBadgeMessage("nigo", client.NIGOReasons),
      };

    case "eif-submit-company-information":
      return {
        ...taskDetail,
        policyId,
        customBannerMessage: eifNIGOMessage,
      };

    case "prior-carrier-bills":
    case "prior-carrier-certificates":
      return {
        ...taskDetail,
        policyId,
        customBannerMessage: getBillsAndCertificatesNIGOMessage(taskId),
      };

    case "enrollment-elections":
      return {
        ...taskDetail,
        policyId,
        customBannerMessage: getCensusEnrollmentElectionsBadgeMessage(client.NIGOReasons),
      };

    case "kickoff-call":
    case "ongoing-service-call":
    case "sun-life-connect":
    case "company-profile":
    case "pfml":
    case "pfml-spd":
    case "sunadvisor-spd":
    case "enrollment-resources":
    case "deductible-maximum-report":
      return taskDetail;

    case "dental-rollover":
      return taskDetail;

    default:
      exhaustiveCheck(taskId);
      return taskDetail;
  }
};

type GetHubTasksArgs = {
  clientData: Client;
  usingDEIF: boolean;
  usingOnboardNIGO: boolean;
};

export function getHubTasks({ clientData, usingDEIF, usingOnboardNIGO }: GetHubTasksArgs) {
  const isAddCoverage = isAddCoverageClient(clientData);
  const policyTasks = clientData.policies
    .map((policy) => policy.policyTasks)
    .flat()
    .filter((t): t is PolicyTask => Boolean(t));

  const clientTaskDetails = tasksToTaskDetails({
    tasks: clientData.tasks || [],
    usingDEIF,
    isAddCoverageClient: isAddCoverage,
  });

  const policyTaskDetails = tasksToTaskDetails({
    tasks: policyTasks,
    isMaxwellClient: clientData.isMaxwell,
    isAddCoverageClient: isAddCoverage,
  });

  const taskDetailsFiltered = clientTaskDetails
    .concat(policyTaskDetails)
    .sort((taskA, taskB) => {
      const order = TaskOrder.indexOf(taskA.id) - TaskOrder.indexOf(taskB.id);
      return order;
    })
    // enrollment resources are handled separately
    .filter((s) => s.id !== "enrollment-resources");

  const upcoming: TaskStatus[] = ["Upcoming"];
  const upcomingTasks = taskDetailsFiltered.filter((s) => upcoming.includes(s.status));

  const toDo: TaskStatus[] = ["Not Started", "In Progress", "Voided", "Declined"];
  if (usingOnboardNIGO) {
    toDo.push("NIGO");
  }
  const toDoTasks = taskDetailsFiltered.reduce<TaskDetail[]>((tasks, currentTask) => {
    if (toDo.includes(currentTask.status)) {
      if (
        usingOnboardNIGO &&
        (currentTask.id === "onboarding-forms" ||
          currentTask.id === "eif-submit-company-information" ||
          currentTask.id === "enrollment-elections" ||
          currentTask.id === "prior-carrier-certificates" ||
          currentTask.id === "prior-carrier-bills") &&
        currentTask.status === "NIGO"
      ) {
        // TODO: with FP-3693 in place, we shouldn't need " || currentTask" anymore
        currentTask =
          addNIGODetailToTaskDetail(currentTask, clientData, clientData.policies) || currentTask;
      }

      tasks.push(currentTask);
    }

    return tasks;
  }, []);

  const inReview: TaskStatus[] = ["In Review"];
  if (!usingOnboardNIGO) {
    inReview.push("NIGO");
  }
  const inReviewTasks = taskDetailsFiltered.filter((s) => inReview.includes(s.status));

  const completed: TaskStatus[] = ["Done"];
  const completedTasks = taskDetailsFiltered.filter((s) => completed.includes(s.status));
  return { toDoTasks, inReviewTasks, completedTasks, upcomingTasks, allTasks: taskDetailsFiltered };
}

export function hasRemainingHubTasks(clientData: Client) {
  const { toDoTasks, inReviewTasks } = getHubTasks({
    clientData,
    usingDEIF: false,
    usingOnboardNIGO: false,
  });
  return toDoTasks.length + inReviewTasks.length > 0;
}

function HubPageSkeleton() {
  return (
    <StackY dist={40} className={clsx(styles.hubPageOuterContainer)}>
      <div style={{ height: 232, background: "white" }}>
        <div
          className={styles.hubPageContainer}
          style={{
            display: "grid",
            alignItems: "center",
            height: "100%",
          }}
        >
          <header>
            <Skeleton active />
            <br />
            <Skeleton active paragraph={false} />
          </header>
        </div>
      </div>

      <StackY dist={40} wrap={false} className={styles.hubPageContainer}>
        <HubCard>
          <Skeleton active />
          <br />
          <Skeleton.Button active size="large" style={{ width: 170 }} />
        </HubCard>

        <Row>
          <Col span={17}>
            <StackY dist={24}>
              <Skeleton active paragraph={false} title={{ width: 250 }} />

              <StackY dist={24}>
                {Array(9)
                  .fill(null)
                  .map((_, i) => (
                    <HubCard key={i}>
                      <Skeleton active />
                    </HubCard>
                  ))}
              </StackY>
            </StackY>
          </Col>

          <Col span={6} offset={1}>
            <div className={styles.supportInformation}>
              <Card>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Card>
            </div>
          </Col>
        </Row>
      </StackY>
    </StackY>
  );
}
