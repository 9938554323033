import { StackY } from "client/src/components/Spacing/Spacing";
import { Body5, Body3 } from "client/src/components/Typography/Typography";
import { getDefaultBillNames } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { listFormat } from "shared/utils/format";
import { formatLocation } from "shared/utils/location";
import type { BillTiming, BillingAdministrationType } from "@prisma/client";
import type { FormikErrors } from "formik";
import type { Bill } from "shared/types/Bill";
import type { BillFormValues } from "shared/validation/bill";

type Props = {
  bills: Array<{ billName: string | null; billTiming?: BillTiming | null }>;
  bill: Pick<
    Bill,
    | "billName"
    | "billTiming"
    | "slfCoverages"
    | "categorizeEmployees"
    | "employeeCategorizationType"
    | "categoriesByLocation"
    | "categoriesByTags"
  >;
  prefillErrors: FormikErrors<
    Pick<
      BillFormValues,
      | "billName"
      | "slfCoverages"
      | "categorizeEmployees"
      | "employeeCategorizationType"
      | "categorizeByLocationIds"
      | "categoriesByTags"
    >
  >;
  billingAdministrationType: BillingAdministrationType | null | undefined;
  showDetails: boolean;
  withBilName?: boolean;
};

export function BillExclusiveProps(props: Props) {
  const {
    bills,
    bill,
    prefillErrors: e,
    billingAdministrationType,
    showDetails,
    withBilName,
  } = props;

  const defaultBillNames = getDefaultBillNames(bills);
  const index = bills.indexOf(bill);

  const billName = bill.billName
    ? `${bill.billName} - ${bill.billTiming} bill`
    : defaultBillNames[index];

  const showPrefillErrors = Object.keys(e).length > 0;

  return (
    <StackY dist={16} wrap={false}>
      {withBilName && (
        <div data-testid="bill-title" className="stack-x-8">
          <span className="h5" style={{ margin: 0 }}>
            {billName}
          </span>

          {e.billName && <Body3 redMedium> {e.billName}</Body3>}
        </div>
      )}

      {bill.billTiming && (
        <div>
          <Body5 as="div">Bill timing</Body5>
          <Body3 as="div">{bill.billTiming}</Body3>
        </div>
      )}

      <div>
        <Body5 as="div">Benefits included</Body5>
        {!!bill.slfCoverages?.length && <Body3 as="div">{listFormat(bill.slfCoverages)}</Body3>}

        {e.slfCoverages && <Body3 redMedium>{e.slfCoverages}</Body3>}
        {(bill.slfCoverages == null || bill.slfCoverages.length === 0) && !e.slfCoverages && (
          <Body3>-</Body3>
        )}
      </div>

      {showDetails && (
        <>
          {billingAdministrationType === "LIST" && (
            <>
              {!showPrefillErrors &&
                bill.categorizeEmployees == null &&
                bill.employeeCategorizationType == null &&
                !bill.categoriesByLocation?.length &&
                !bill.categoriesByTags?.length && (
                  <div>
                    <Body5 as="div">Sort employees by</Body5>
                    <Body3 as="div">-</Body3>
                  </div>
                )}

              {showPrefillErrors && e.categorizeEmployees && (
                <div>
                  <Body5 as="div">Sort employees by</Body5>
                  <Body3 as="div" redMedium>
                    {e.categorizeEmployees}
                  </Body3>
                </div>
              )}

              {bill.categorizeEmployees === "NO" && (
                <div>
                  <Body5 as="div">Sort employees by</Body5>
                  <Body3 as="div">Last name, alphabetically</Body3>
                </div>
              )}

              {bill.categorizeEmployees === "YES" &&
                bill.employeeCategorizationType === "LOCATION" &&
                bill.categoriesByLocation?.map((locations, index) => (
                  <div key={index}>
                    <Body5 as="div">Sort employees by</Body5>
                    {locations.map((location) => (
                      <Body3 as="div" key={location.id}>
                        {formatLocation(location)}
                      </Body3>
                    ))}

                    {e.categorizeByLocationIds && (
                      <Body3 as="div" redMedium>
                        {e.categorizeByLocationIds}
                      </Body3>
                    )}
                  </div>
                ))}

              {bill.categorizeEmployees === "YES" &&
                bill.employeeCategorizationType === "TAGS" &&
                bill.categoriesByTags?.map((tags, index) => (
                  <div key={index}>
                    <Body5 as="div">Sort employees by</Body5>
                    <Body3>{listFormat(tags)}</Body3>

                    {e.categoriesByTags && (
                      <Body3 as="div" redMedium>
                        {e.categoriesByTags}
                      </Body3>
                    )}
                  </div>
                ))}
            </>
          )}
        </>
      )}
    </StackY>
  );
}
