import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { ProgressBar } from "client/src/components/ProgressBar/ProgressBar";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { ClassMappedSummaryItem } from "client/src/domain/EIF/QPS/ClassMappedSummaryItemV2";
import { type AssignBenefitsToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepClassMapping";
import { PlanMappedSummaryItem } from "client/src/domain/EIF/QPS/PlanMappedSummaryItem";
import { parseQPSSyncErrorResponse } from "client/src/domain/EIF/QPS/QPSErrorBanner";
import { ResponseError, errorToMessage } from "client/src/hooks/query";
import { useToggler } from "client/src/hooks/useToggler";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import {
  getAdminClassId,
  isQPSBasicClass,
  isQPSNonClassMapInput,
  type QPSClassMapInput,
} from "shared/types/QPSClass";
import { getUniquePFMLSections } from "shared/utils/EIF/nonClassBenefitsPreferences";
import * as styles from "./eifPushToQPS.module.less";
import type { AssignPlansToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepPlanMapping";
import type { MapAndSyncQPSClassesFunc } from "client/src/hooks/qps";
import type { Dispatch, SetStateAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { QPSBasicClass, QPSNonClassMapInput } from "shared/types/QPSClass";

type Props = {
  client: Client;
  employeeClasses: EmployeeClass[];
  plans: Plan[];
  qpsBasicClasses: QPSBasicClass[];
  classMapping: AssignBenefitsToAdminClassesMapping[];
  planMapping: AssignPlansToAdminClassesMapping | null;
  onPrevious: () => void;
  mapAndSyncQPSClasses: MapAndSyncQPSClassesFunc;
  isPendingMapAndSyncQPSClasses: boolean;
  authUser: UserData;
  setEmployeeClassIndex: Dispatch<SetStateAction<number>>;
};

export function EIFPushToQPSStepReview(props: Props) {
  const navigate = useNavigate();
  const {
    client,
    classMapping,
    planMapping,
    plans,
    qpsBasicClasses,
    onPrevious,
    isPendingMapAndSyncQPSClasses,
    mapAndSyncQPSClasses,
    employeeClasses,
    authUser,
    setEmployeeClassIndex,
  } = props;
  const [showSyncModal, toggleSyncModal] = useToggler();
  const [syncGenericErrorMsg, setSyncGenericErrorMsg] = useState<string | null>(null);

  const onMapAndSyncWithQPS = async () => {
    const employeeClassesMap = classMapping.map((mapInput) =>
      transformToQPSClassMapInput(mapInput, qpsBasicClasses),
    );
    const nonClassMap = transformToNonClassMapInput(plans, planMapping, qpsBasicClasses);

    try {
      const { data, isSuccess } = await mapAndSyncQPSClasses({
        params: { clientId: client.id },
        data: {
          employeeClassesMap,
          nonClassMap,
        },
      });

      navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"), {
        state: {
          isSuccess,
          qpsSyncErrorDetails: getQPSSyncErrors(data),
        },
      });
    } catch (error) {
      if (ResponseError.isResponseError(error)) {
        navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"), {
          state: {
            isSuccess: false,
            qpsSyncErrorDetails: getQPSSyncErrors(error),
          },
        });
        return;
      }
      const errorMsg = errorToMessage(error);
      setSyncGenericErrorMsg(errorMsg);
    }
  };

  return (
    <>
      <h1>Review this section</h1>

      <Body3 as="p">
        Please review the information you entered below and make sure there are no mistakes. The
        values in QPS will be overwritten by the values in Onboard.
      </Body3>

      <StackY dist={24}>
        {classMapping.map((mappedQPSClass, index) => (
          <ClassMappedSummaryItem
            key={mappedQPSClass.employeeClassId}
            authUser={authUser}
            client={client}
            employeeClasses={employeeClasses}
            qpsBasicClasses={qpsBasicClasses}
            mappedQPSClass={mappedQPSClass}
            index={index}
            editButtonAction={() => {
              setEmployeeClassIndex(index);
              onPrevious();
              window.scrollTo({ top: 150, behavior: "smooth" });
            }}
          />
        ))}

        {planMapping != null && (
          <PlanMappedSummaryItem
            plans={plans}
            qpsBasicClasses={qpsBasicClasses}
            mappedQPSPlan={planMapping}
            editButtonAction={() => {
              onPrevious();
              window.scrollTo({ top: 150, behavior: "smooth" });
            }}
          />
        )}

        <BulbMessage>
          <Body3 as="p">
            In addition to the Plan Configuration & Eligibility, you will be pushing the Onboard
            values for the following sections of the digital smart form:
          </Body3>
          <UnorderedList color="black" tight>
            <UnorderedListItem>
              <Body2>Company Details</Body2>
            </UnorderedListItem>
            <UnorderedListItem>
              <Body2>Plan Administrators & Billing</Body2>
            </UnorderedListItem>
            <UnorderedListItem>
              <Body2>Other Contract Details</Body2>
            </UnorderedListItem>
          </UnorderedList>
          <Body3 as="p">No mapping is required for these sections.</Body3>
        </BulbMessage>
      </StackY>

      <Row justify="space-between" className="mt-32 mb-48">
        <Col>
          <Button
            onClick={onPrevious}
            type="text-only"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            size="large"
            htmlType="button"
          >
            Previous
          </Button>
        </Col>
        <Col>
          <Button onClick={toggleSyncModal} type="primary">
            Send To QPS
          </Button>
        </Col>
      </Row>
      <Modal
        maskClosable={false}
        title="Send to QPS"
        open={showSyncModal}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={toggleSyncModal}
      >
        {isPendingMapAndSyncQPSClasses ? (
          <div className={styles.progressBarContainer}>
            <ProgressBar id="qps-progress-bar" bottomText="" />
            <Body2 as="div">Syncing...</Body2>
            <Body5 as="div">This might take a few seconds</Body5>
          </div>
        ) : (
          <>
            <Body3 as="p">Are you sure?</Body3>
            <Body3 as="p">The values in QPS will be overwritten by the values in Onboard.</Body3>
            {syncGenericErrorMsg && <ErrorMessage>{syncGenericErrorMsg}</ErrorMessage>}
          </>
        )}
        <Row justify="end">
          <Col>
            <StackX dist={24}>
              <Button
                type="text-only"
                onClick={toggleSyncModal}
                size="middle"
                disabled={isPendingMapAndSyncQPSClasses}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onMapAndSyncWithQPS}
                size="middle"
                disabled={isPendingMapAndSyncQPSClasses}
              >
                Yes, send to QPS
              </Button>
            </StackX>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

const transformToQPSClassMapInput = (
  mapping: AssignBenefitsToAdminClassesMapping,
  qpsBasicClasses: QPSBasicClass[],
): QPSClassMapInput => {
  return {
    employeeClassId: mapping.employeeClassId,
    qpsBasicClasses: Object.values(mapping.formValues.adminClassesId)
      .flat()
      .map((adminClassId) => {
        const selectedQpsClasses = qpsBasicClasses.find(
          (qpsClass) => getAdminClassId(qpsClass) === adminClassId,
        );
        return selectedQpsClasses
          ? {
              ...selectedQpsClasses,
              eligibleEmployeeDefinition: mapping.formValues.eligibleEmployeeDefinition,
            }
          : {};
      })
      .filter(isQPSBasicClass),
  };
};

const transformToNonClassMapInput = (
  plans: Plan[],
  mapping: AssignPlansToAdminClassesMapping | null,
  qpsBasicClasses: QPSBasicClass[],
): QPSNonClassMapInput[] => {
  if (!mapping) {
    return [];
  }

  return getUniquePFMLSections(plans)
    .map((pfmlSection) => {
      const selectedQPSClassesId = mapping[pfmlSection.sectionType] ?? [];
      const selectedQpsClasses = qpsBasicClasses.filter((qpsClass) =>
        selectedQPSClassesId.includes(getAdminClassId(qpsClass)),
      );
      const [plan] = pfmlSection.plans; // all the classes have the same contribution values

      if (!plan || selectedQpsClasses.length === 0) {
        return null;
      }

      return {
        nonClassPlanId: plan.id,
        qpsBasicClasses: selectedQpsClasses,
      };
    })
    .filter(isQPSNonClassMapInput);
};

const getQPSSyncErrors = (error: unknown) => {
  return ResponseError.isResponseError(error) ? parseQPSSyncErrorResponse(error) : [];
};
