import React from "react";

import { Eyebrow } from "../Typography/Typography";

import * as styles from "./progressBar.module.less";
import type { ReactNode } from "react";

type ProgressBarProps = {
  id: string;
  value?: number;
  max?: number;
  bottomText?: ReactNode;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  id,
  value,
  max = 100,
  bottomText = "LOADING...",
}) => {
  return (
    <div className={styles.progressBarWrapper}>
      <progress id={id} value={value} max={max} />
      <Eyebrow as="label" htmlFor={id}>
        {bottomText}
      </Eyebrow>
    </div>
  );
};
