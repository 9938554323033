import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { formatDateFullMonthWithYear } from "shared/utils/format";

import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body5, H5 } from "../../../components/Typography/Typography";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { getBillsAndCertificatesNIGOMessage } from "../../../domain/Task/nigoMessage";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import type { PolicyTask } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";

type PriorCarrierBillsProps = {
  client: Client;
  policyTask: PolicyTask;
  policy: Policy;
};

export function PriorCarrierBillsPage({ client, policyTask, policy }: PriorCarrierBillsProps) {
  useTitle("Prior Carrier Bills");
  const trackElementClicked = useTrackElementClicked(client, "Prior Carrier Bills", policy.id);

  const remainingTasks = hasRemainingHubTasks(client);

  const shouldDisplayDueDate = getShouldDisplayDueDate(policyTask);

  const policyEffective = policy.policyEffective
    ? formatDateFullMonthWithYear(policy.policyEffective)
    : "";

  const policyNumber = policy.slfPolicyNumber || "";

  const subTitle =
    !policyEffective || client.policies.length < 2
      ? undefined
      : `For benefits effective ${policyEffective}${
          policyNumber ? `, policy #${policyNumber}` : ""
        }`;

  return (
    <ChildPageLayout
      topNavTitle="Prior Carrier Bills"
      task={policyTask}
      aside={
        <>
          <h1>Prior Carrier Bills</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={policyTask} />
              <br />
            </>
          )}

          <p>
            Having your prior carrier bills enables our team to set up your plans to match how they
            were being administered with your previous carrier.
          </p>

          <p>
            This takes some work off your plate as we are able to use this to customize your new
            plans with Sun Life based on your preferences.
          </p>

          <p>This task is complete after we receive and verify your documents.</p>
        </>
      }
      contents={
        <>
          <AddFilesCard
            clientId={client.id}
            hideButton={policyTask.status === "Done"}
            taskStatus={policyTask.status}
            taskId={policyTask.id}
            category="prior-carrier-bills"
            policyId={policy.id}
            policies={client.policies}
            multiPolicyFiles
            title={<H5 as="h2">Upload your files</H5>}
            subTitle={subTitle}
            message="Sun Life will review your prior carrier bills and let you know if anything else is required from you. Review typically takes 2-3 days. You can continue to add more files if needed."
            taskCompletedTitle="Your files have been submitted for review!"
            actionRequiredMessage={getBillsAndCertificatesNIGOMessage("prior-carrier-bills")}
            remainingTasks={remainingTasks}
            footer={
              policyTask.status === "Done"
                ? (toggleVisible, track) => (
                    <div className="text-center">
                      <Body5 as="p">
                        Missed something?{" "}
                        <ButtonOld type="link-inline" size="small" onClick={toggleVisible}>
                          Add more files
                        </ButtonOld>
                      </Body5>

                      <StoredSecurelyText track={track} />
                    </div>
                  )
                : undefined
            }
            trackElementClicked={trackElementClicked}
          />

          <h3 className="mt-40">Tips for completing this task</h3>

          <StackY dist={24}>
            <div>
              <p>
                <b className="body2">What should you upload?</b>
                <br />
                If you have benefits with multiple carriers, be sure to upload a bill from each
                carrier and that the bill contains all of your benefit lines with that carrier. We
                will need bills for all benefits that you’ll have with Sun Life. You can check what
                these are by returning to your Dashboard or reaching out to your IC to confirm.
              </p>

              <p>
                If your benefits include volumes (such as a Voluntary Life plan) or tier elections
                (such as employee + spouse for a Dental plan), ensure that your bill contains this
                information for each of your employees that have that election.
              </p>

              <p>If you self-administer your billing, your bill may not include the above.</p>
            </div>

            <div>
              <p>
                <b className="body2">Don’t upload too soon</b>
                <br />A copy of your most recent bill (no earlier than one month before your new
                effective date. So if your new effective date is 8/1, the bill for July would be the
                one to provide) is best to ensure that we have captured accurate billing for your
                benefits and your employees’ enrollments. Make sure to submit this bill on the due
                date specified on the left.
              </p>
            </div>
          </StackY>
        </>
      }
    />
  );
}
