import { useListParams } from "client/src/hooks/useListParams";
import { SlobList } from "../../../components/SlobList/SlobList";
import { CLIENTS_LIST_PAGE_SIZE, useGetUserClients } from "../../../hooks/client";

import { InternalBrokerClientsTable } from "./ClientsTable/InternalBrokerClientsTable";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type InternalBrokerClientsListProps = {
  extraSearchParams: { userId: string };
  featureToggles?: ClientFeatureToggles;
};

export const InternalBrokerClientsList = ({
  extraSearchParams,
  featureToggles,
}: InternalBrokerClientsListProps) => {
  const listParams = useListParams({
    initialPageSize: CLIENTS_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "name",
    },
  });

  const query = useGetUserClients({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...extraSearchParams,
  });

  return (
    <SlobList
      TableComponent={InternalBrokerClientsTable}
      query={query}
      listParams={listParams}
      showSearch={false}
      featureToggles={featureToggles}
    />
  );
};
