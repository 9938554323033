import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useState } from "react";
import { Link } from "react-router-dom";

import { isSunlifeCarrier } from "../../../../../shared/types/ExplorerPageBenefit";
import { Body5, H2, H4 } from "../../../components/Typography/Typography";
import { useEmployeeLocation } from "../../../hooks/useSlobLocalStorage";
import { Trans, useTranslation } from "../../../i18n";
import { copyKey, useCopyKeyExists, useCopyKeySwitch } from "../utils";

import { getAlternateName } from "./Benefit/ExplorerBenefit";

import type { ExplorerPage } from "../../../../../shared/types/ExplorerPage";
import type { LocationStateCode } from "../../../../../shared/types/Location";
import type { CopySwitch } from "../utils";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ContentFlag, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  expandedContent: "expanded-content",
};

type CopyContentStructure = {
  VAS?: string;
  VAS_1?: string;
  VAS_2?: string;
  content: string;
  link?: string;
};

export type ExplorerDisclaimersCopyStructure = {
  [key in Exclude<BenefitTypeBenEx, "MEDICAL">]?: {
    header: string;
    header_add?: string;
    disclaimer?: CopySwitch<
      "Sun Life",
      CopySwitch<PlanType | "MULTI_CHOICE", CopySwitch<LocationStateCode, CopyContentStructure>>
    >;
    exclusionsAndLimitations?: CopySwitch<
      "Sun Life",
      CopySwitch<PlanType | "MULTI_CHOICE", CopySwitch<LocationStateCode, CopyContentStructure>>
    >;
  };
} & {
  header: string;
  disclaimer_collapsed: string;
  disclaimer_expanded: string;
  exclusionsAndLimitations_collapsed: string;
  exclusionsAndLimitations_expanded: string;
  disclaimer_prefix: string;
  exclusionsAndLimitations_prefix: string;
};

type ExplorerDisclaimersProps = {
  page: ExplorerPage;
  vasUpdateFeatureToggle: boolean;
};
export const ExplorerDisclaimers = ({ page, vasUpdateFeatureToggle }: ExplorerDisclaimersProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerDisclaimers" });
  const { t: alternameNameT } = useTranslation({ keyPrefix: "AlternateNames" });
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();

  if (!page.benefits) return null;

  const benefits = page.benefits.reduce<Omit<LegalCollapseProps, "type">[]>((acc, val) => {
    const existing = acc.find((m) => m.benefitType === val.benefitType);
    if (existing) {
      if (val.planType) {
        if (!existing.planType) existing.planType = val.planType;
        else {
          existing.planType = "MULTI_CHOICE";
          if (isSunlifeCarrier(val.carrierId)) existing.prepaidSitusState = val.prepaidSitusState;
        }
      }
      val.contentFlags?.forEach((flag) => {
        if (!existing.contentFlags.includes(flag))
          existing.contentFlags = existing.contentFlags.concat([flag]);
      });

      if (isSunlifeCarrier(val.carrierId)) existing.isSunLife = true;

      return acc;
    }

    acc.push({
      benefitType: val.benefitType,
      planType: val.planType,
      contentFlags: val.contentFlags ?? [],
      situsState: page.employerState,
      prepaidSitusState: val.prepaidSitusState,
      employeeLocation: employeePrepaidAndChatLocation,
      alternateName: getAlternateName(
        alternameNameT,
        val.benefitType,
        page.employerState,
        page.benefits ?? [],
        employeePrepaidAndChatLocation,
      ),
      isSunLife: isSunlifeCarrier(val.carrierId),
      vasUpdateFeatureToggle,
    });
    return acc;
  }, []);

  return (
    <>
      <div className="mb-32">
        <H2>{t("header")}</H2>
      </div>
      {benefits.map((benefit) => (
        <LegalSection key={`disclaimer_${benefit.benefitType}`} {...benefit} />
      ))}
    </>
  );
};

const LegalSection = (benefit: Omit<LegalCollapseProps, "type">) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerDisclaimers" });
  const exclusionsState = useLegalCollapseState({ ...benefit, type: "exclusionsAndLimitations" });
  const disclaimerState = useLegalCollapseState({ ...benefit, type: "disclaimer" });

  if (!exclusionsState.hasCopy && !disclaimerState.hasCopy) return null;

  return (
    <div className="my-32">
      <H4>
        {t(copyKey(benefit.benefitType, "header"), {
          alternateName: benefit.alternateName,
          context: benefit.contentFlags.includes("AD&D") ? "add" : undefined,
        })}
        {<LegalCollapse {...exclusionsState} />}
        {<LegalCollapse {...disclaimerState} />}
      </H4>
    </div>
  );
};

type LegalCollapseProps = {
  benefitType: BenefitTypeBenEx;
  planType: PlanType | "MULTI_CHOICE" | null;
  contentFlags: ContentFlag[];
  situsState: LocationStateCode;
  prepaidSitusState: LocationStateCode | null;
  employeeLocation: LocationStateCode | null;
  type: "disclaimer" | "exclusionsAndLimitations";
  alternateName: string;
  isSunLife: boolean;
  vasUpdateFeatureToggle: boolean;
};

const useLegalCollapseState = ({
  benefitType,
  planType,
  contentFlags,
  situsState,
  prepaidSitusState,
  employeeLocation,
  type,
  alternateName,
  isSunLife,
  vasUpdateFeatureToggle,
}: LegalCollapseProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerDisclaimers" });
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const carrierKey = useCopyKeySwitch(t, benefitType, type, isSunLife ? "Sun Life" : null);
  const planTypeKey = useCopyKeySwitch(t, benefitType, type, carrierKey, planType);
  const situsStateKey = useCopyKeySwitch(
    t,
    benefitType,
    type,
    carrierKey,
    planTypeKey,
    prepaidSitusState && prepaidSitusState === employeeLocation ? prepaidSitusState : situsState,
  );

  const baseKey = [benefitType, type, carrierKey, planTypeKey, situsStateKey];
  const hasCopy = useCopyKeyExists(t, ...baseKey);

  const hasVAS_1 = vasUpdateFeatureToggle
    ? contentFlags.includes("VAS_1")
    : benefitType !== "ACCIDENT"
    ? contentFlags.includes("VAS_1")
    : false;
  const hasVAS_2 = contentFlags.includes("VAS_2");

  return {
    expanded,
    toggleExpanded,
    hasVAS_1,
    hasVAS_2,
    baseKey,
    hasCopy,
    alternateName,
    type,
  };
};

const LegalCollapse = ({
  expanded,
  toggleExpanded,
  hasVAS_1,
  hasVAS_2,
  baseKey,
  hasCopy,
  alternateName,
  type,
}: ReturnType<typeof useLegalCollapseState>) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerDisclaimers" });

  if (!hasCopy) return null;

  return (
    <>
      <Body5 as="div">
        {expanded && (
          <div data-testid={DataTestId.expandedContent} className="my-16">
            <Body5>
              <div className="mb-8">
                <Trans t={t} i18nKey={type} tOptions={{ context: "prefix" }} />
              </div>
              <Trans
                t={t}
                i18nKey={copyKey(...baseKey, "content")}
                values={{
                  alternateName,
                  VAS:
                    hasVAS_1 || hasVAS_2
                      ? t(copyKey(...baseKey, "VAS"), {
                          VAS_1: hasVAS_1 ? t(copyKey(...baseKey, "VAS_1")) : undefined,
                          VAS_2: hasVAS_2 ? t(copyKey(...baseKey, "VAS_2")) : undefined,
                        })
                      : undefined,
                }}
              >
                <Link
                  to={t(copyKey(...baseKey, "link"), { defaultValue: "" }) ?? ""}
                  target="_blank"
                ></Link>
              </Trans>
            </Body5>
          </div>
        )}
        <div className="mb-4">
          <ButtonOld type="link-inline" onClick={toggleExpanded}>
            {t(type, { context: expanded ? "expanded" : "collapsed" })}
          </ButtonOld>
        </div>
      </Body5>
    </>
  );
};
