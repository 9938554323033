import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { StackY } from "client/src/components/Spacing/Spacing";
import { H3, Body3, H5, Body2 } from "client/src/components/Typography/Typography";
import { EIFNonClassBenefitsContributionsCompletedItem } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsCompletedItem";
import { EIFNonClassBenefitsReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsReadOnlyContribution";
import { EIFStatutoryReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFStatutoryReadOnlyContribution";
import { getNonClassBenefitsContributionsDetailInfoList } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/getNonClassBenefitsContributionsDetailInfoList";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { Fragment } from "react";
import { RouteData } from "shared/config/routeData";
import { getIsPFMLBenefitType } from "shared/types/BenefitTypes";
import { getEIFSubStepMap } from "shared/types/EIF";
import {
  getIsPFMLSectionType,
  getIsStatutorySectionType,
  getUniquePFMLAndStatutorySections,
} from "shared/utils/EIF/nonClassBenefitsPreferences";
import { getShowValidationErrorsInSummary } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode } from "shared/types/Client";
import type { EIFSubStepId } from "shared/types/EIF";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  isReviewOnly: boolean;
  viewMode: EIFSubStepViewMode;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  readonly: boolean;
  signerMode: SignerMode;
  featureToggles: ClientFeatureToggles;
};

export function EIFNonClassBenefitsContributionsReview(props: Props) {
  const {
    client,
    clientPlans,
    isReviewOnly,
    viewMode,
    changeSnapshot,
    authUser,
    readonly,
    signerMode,
    featureToggles,
  } = props;

  const eifSubStepId: EIFSubStepId = "non-class-benefits-preferences";
  const subStepName = getEIFSubStepMap({ eifSubStepId, clientPlans, featureToggles });

  const uniqueSections = getUniquePFMLAndStatutorySections(client, clientPlans);

  const pfmlClientPlans = clientPlans.filter((clientPlan) =>
    getIsPFMLBenefitType(clientPlan.benefitType),
  );

  const showEditBanner = getShowEditBanner(viewMode);

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const hasInProgress =
    getEIFSubStepStatus({
      client,
      eifSubStepId: "non-class-benefits-preferences",
      clientPlans: clientPlans,
    }) === "In Progress";

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode}>
      <StackY dist={32}>
        <StackY dist={16}>
          <Row>
            <Col flex={1}>
              <H3>{subStepName}</H3>
            </Col>
            {!readonly && (
              <Col>
                <InternalLinkButton
                  to={RouteData.eifSubStepDetail.getPath(
                    client.id,
                    "plan-configuration-&-eligibility",
                    "non-class-benefits-preferences",
                  )}
                  type="link-inline-bold"
                >
                  Edit
                </InternalLinkButton>
              </Col>
            )}
          </Row>
          {showEditBanner && (
            <EditBanner
              data-testid="edit-banner-non-class-benefits-preferences-item"
              navigateLink={RouteData.eifSubStepDetail.getPath(
                client.id,
                "plan-configuration-&-eligibility",
                "non-class-benefits-preferences",
              )}
              changeDetailInfoList={getNonClassBenefitsContributionsDetailInfoList(
                changeSnapshot,
                pfmlClientPlans,
              )}
              client={client}
              authUser={authUser}
            />
          )}
          {hasInProgress && !suppressErrorsPostSigning && (
            <Row gutter={[32, 18]} className="mb-16">
              <Col flex="1">
                <AlertBanner
                  variant="error"
                  message={
                    <Body3>
                      Click <Body2>Edit</Body2> to complete this section.
                    </Body3>
                  }
                />
              </Col>
            </Row>
          )}
          <StackY dist={4}>
            <Body3 colorSecondary>Eligibility</Body3>
            <Body3>All employees are eligible under applicable state law.</Body3>
          </StackY>
          <StackY dist={4}>
            <Body3 colorSecondary>Waiting periods</Body3>
            <Body3>There is no eligibility waiting period for new hires.</Body3>
          </StackY>
        </StackY>

        <HorizontalDivider />

        <H5>Contributions</H5>

        {uniqueSections.map((section) => {
          const { sectionType, label, plans, readOnly, status, state, isPFML } = section;
          const dynamicLabel = isPFML ? `${label} Paid Family Medical Leave` : label;

          if (readOnly) {
            if (getIsPFMLSectionType(sectionType)) {
              assertIsDefined(state, "state");
              return (
                <Fragment key={sectionType}>
                  <EIFNonClassBenefitsReadOnlyContribution
                    key={sectionType}
                    sectionState={state}
                    isReviewOnly={isReviewOnly}
                  />
                </Fragment>
              );
            } else if (getIsStatutorySectionType(sectionType)) {
              return (
                <Fragment key={sectionType}>
                  <EIFStatutoryReadOnlyContribution
                    sectionType={sectionType}
                    label={label}
                    isReviewOnly={isReviewOnly}
                  />
                </Fragment>
              );
            }
          }

          if (status === "Not Started") {
            return (
              <Fragment key={sectionType}>
                <Row align="middle">
                  <Col flex={1}>
                    <Body2>{dynamicLabel}</Body2>
                    <Row>
                      <Body3>Contributions not yet selected</Body3>
                    </Row>
                  </Col>
                </Row>
              </Fragment>
            );
          }

          return (
            <Fragment key={label}>
              <Row align="middle">
                <Col flex={1}>
                  <Body2>{dynamicLabel}</Body2>
                  <EIFNonClassBenefitsContributionsCompletedItem
                    plans={plans}
                    suppressErrorsPostSigning={suppressErrorsPostSigning}
                  />
                </Col>
              </Row>
            </Fragment>
          );
        })}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}
