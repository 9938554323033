import { Body3 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderBenefitList } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderBenefitList";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getBenefitChangeDetailInfoList } from "client/src/domain/EIF/common/utils/getBenefitChangeDetailInfoList";
import { getEmployeeClassBenefitsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export function EIFClassBuilderItemBenefits(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser } = props;

  const benefitChangeDetailInfoList = getBenefitChangeDetailInfoList(
    changeSnapshot,
    employeeClass.id,
  );

  const benefitsStatus = getEmployeeClassBenefitsCompletionStatus(employeeClass);

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <div data-testid="benefits-class-builder-item">
      <h5>Benefits</h5>

      <EditedFieldMsg
        changeDetailInfoList={benefitChangeDetailInfoList}
        client={client}
        authUser={authUser}
      />
      <div className="mt-12">
        <EIFClassBuilderBenefitList
          employeeClassPlans={employeeClass.employeeClassPlans}
          spacing={"compact"}
        />
      </div>

      {benefitsStatus === "Not Started" && !suppressErrorsPostSigning && (
        <Body3 as="div" redMedium>
          Please select at least one benefit type
        </Body3>
      )}

      {benefitsStatus === "Not Started" && suppressErrorsPostSigning && <Body3 as="div">-</Body3>}
    </div>
  );
}
