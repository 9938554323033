import { faChevronLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";

import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { ReactComponent as BlueRightArrow } from "client/src/components/Icons/BlueRightArrow.svg";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { EIFNonClassBenefitsContributionsCompletedItem } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsCompletedItem";
import { EIFNonClassBenefitsReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsReadOnlyContribution";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { getIn } from "formik";
import {
  categoryCodeToBenefitType,
  categoryCodeToPFMLSection,
  getAdminClassId,
  isBenefitMappingToQPSSupported,
} from "shared/types/QPSClass";
import { getUniquePFMLSections } from "shared/utils/EIF/nonClassBenefitsPreferences";
import { assertIsDefined, unique } from "shared/utils/utils";
import * as Yup from "yup";
import * as styles from "./eifPushToQPS.module.less";
import type { Client } from "shared/types/Client";
import type { PFMLStatutoryContributionSections, Plan, PlanId } from "shared/types/Plan";
import type { QPSBasicClass } from "shared/types/QPSClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const SKIP_THIS_BENEFIT = "__SKIP_THIS_BENEFIT__";

export type AssignPlansToAdminClassesMapping = Record<
  PlanId,
  (string | typeof SKIP_THIS_BENEFIT)[]
>;

type EIFPushToQPSStepPlanMappingProps = {
  client: Client;
  mapping: AssignPlansToAdminClassesMapping | null;
  plans: Plan[];
  qpsBasicClasses: QPSBasicClass[];
  featureToggles: ClientFeatureToggles;
  onPrevious: () => void;
  onNext: (mapping: AssignPlansToAdminClassesMapping | null) => void;
};

export function EIFPushToQPSStepPlanMapping(props: EIFPushToQPSStepPlanMappingProps) {
  const { client, mapping, plans, qpsBasicClasses, featureToggles, onPrevious, onNext } = props;

  return (
    <div className="my-64 stack-y-20">
      <h1>Assign benefits to PFML Preference</h1>

      <p>
        Assign the benefits for each eligible employee group to its corresponding Admin Class in
        QPS.The values in QPS will be overwritten by the values in Onboard.
      </p>

      <EIFPushToQPSStepPlanMappingContent
        client={client}
        qpsBasicClasses={qpsBasicClasses}
        initialValues={mapping}
        plans={plans}
        featureToggles={featureToggles}
        onPrevious={onPrevious}
        onNext={onNext}
      />
    </div>
  );
}

type EIFPushToQPSStepPlanMappingContentProps = {
  client: Client;
  qpsBasicClasses: QPSBasicClass[];
  initialValues: AssignPlansToAdminClassesMapping | null;
  featureToggles: ClientFeatureToggles;
  plans: Plan[];
  onPrevious: () => void;
  onNext: (formValues: AssignPlansToAdminClassesMapping | null) => void;
};

function EIFPushToQPSStepPlanMappingContent(props: EIFPushToQPSStepPlanMappingContentProps) {
  const { client, plans, qpsBasicClasses, initialValues, featureToggles, onPrevious, onNext } =
    props;

  const uniquePFMLSections = getUniquePFMLSections(plans);

  const benefitTypeToQPSBasicClassMap = getQPSClassesPerBenefitTypeMap(
    qpsBasicClasses,
    featureToggles,
  );

  const emptyFormValue = uniquePFMLSections.reduce<AssignPlansToAdminClassesMapping>(
    (acc, pfmlSection) => {
      acc[pfmlSection.sectionType] = [];
      return acc;
    },
    {},
  );

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      planMapping: Yup.lazy((values) => {
        const validationSpec = Yup.array()
          .of(Yup.string().strict().required())
          .required("Please provide a response")
          .min(1, "Please provide a response");
        const spec = Object.entries(values).reduce<Record<string, typeof validationSpec>>(
          (acc, [key]) => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
            const contributionSection = key as PFMLStatutoryContributionSections;
            const qpsBasicClassesForThisBenefitType = getQPSClassByBenefitTypePlans(
              benefitTypeToQPSBasicClassMap,
              contributionSection,
            );
            if (qpsBasicClassesForThisBenefitType.length === 0) {
              return acc;
            }

            acc[contributionSection] = validationSpec;
            return acc;
          },
          {},
        );
        return Yup.object(spec).required();
      }),
    }),
    initialValues: { planMapping: initialValues || emptyFormValue },
    enableReinitialize: true,
    onSubmit: ({ planMapping }) => {
      onNext(planMapping);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="stack-y-20">
      {uniquePFMLSections.map((pfmlSection) => {
        const qpsBasicClassesForThisBenefitType = getQPSClassByBenefitTypePlans(
          benefitTypeToQPSBasicClassMap,
          pfmlSection.sectionType,
        );

        const planNames = unique(qpsBasicClassesForThisBenefitType.map((q) => q.planDesignName));

        const showQPSOptions = planNames.length > 0;
        const checkboxIndex = `planMapping.${pfmlSection.sectionType}`;
        const currentValue: string[] = getIn(formik.values, checkboxIndex) ?? [];
        const isSkipBenefitSelected = currentValue.includes(SKIP_THIS_BENEFIT);
        const disabledSkipBenefit = Boolean(
          currentValue.length && !currentValue.includes(SKIP_THIS_BENEFIT),
        );

        const isAllAdminClassesSelected = planNames.every((planName) => {
          const qpsClassesForThisPlanName = qpsBasicClassesForThisBenefitType.filter(
            (q) => q.planDesignName === planName,
          );

          return qpsClassesForThisPlanName.every((qpsBasicClass) => {
            const adminClassId = getAdminClassId(qpsBasicClass);
            const isChecked = currentValue.includes(adminClassId);
            return isChecked;
          });
        });

        const selectAllAction = async () => {
          if (isAllAdminClassesSelected) {
            await formik.setFieldValue(checkboxIndex, []);
            return;
          }

          const newValues = planNames.reduce<string[]>((acc, planName) => {
            const qpsClassesForThisPlanName = qpsBasicClassesForThisBenefitType.filter(
              (q) => q.planDesignName === planName,
            );

            return qpsClassesForThisPlanName.reduce<string[]>((acc, qpsBasicClass) => {
              const adminClassId = getAdminClassId(qpsBasicClass);
              return acc.concat(adminClassId);
            }, acc);
          }, []);

          await formik.setFieldValue(checkboxIndex, newValues);
        };

        return (
          <HubCard key={pfmlSection.sectionType}>
            <Body2 as="p">{pfmlSection.label} PFML</Body2>

            <Row>
              <Col span={10}>
                <StackY dist={24}>
                  <Row justify="space-between">
                    <Col>
                      <Body2 as="p">Onboard</Body2>
                    </Col>
                  </Row>

                  <div>
                    <Body5 as="div">Waiting period</Body5>
                    <Body3 as="div">There is no waiting period for eligible employees.</Body3>
                  </div>

                  <div>
                    <Body5 as="div">Contributions</Body5>
                    {pfmlSection.readOnly && pfmlSection.state ? (
                      <EIFNonClassBenefitsReadOnlyContribution
                        key={pfmlSection.sectionType}
                        sectionState={pfmlSection.state}
                        isReviewOnly
                        showSectionState={false}
                      />
                    ) : (
                      <EIFNonClassBenefitsContributionsCompletedItem
                        plans={pfmlSection.plans}
                        suppressErrorsPostSigning
                      />
                    )}
                  </div>
                </StackY>
              </Col>

              <Col span={1}>
                <BlueRightArrow />
              </Col>
              <Col span={2} className={styles.middleColumn}>
                <div className={styles.verticalLine} />
              </Col>

              <Col offset={1} span={10}>
                {showQPSOptions ? (
                  <>
                    <div className="mb-16">
                      <StackY dist={12}>
                        <Body2 as="div">QPS case {client.caseId}</Body2>
                        <Body5 as="div">
                          The Admin Class you select below will have it's information overwritten by
                          the information from Onboard
                        </Body5>
                        <Button
                          type="text-only"
                          size="middle"
                          onClick={selectAllAction}
                          disabled={isSkipBenefitSelected}
                        >
                          {isAllAdminClassesSelected ? "Deselect all" : "Select all"}
                        </Button>
                      </StackY>
                    </div>

                    <StackY dist={24} wrap={false}>
                      {planNames.map((planName, planIndex) => {
                        const qpsClassesForThisPlanName = qpsBasicClassesForThisBenefitType.filter(
                          (q) => q.planDesignName === planName,
                        );
                        const isLastPlan = planNames.length - 1 === planIndex;

                        return (
                          <StackY
                            dist={8}
                            key={planName}
                            data-testid={`mapper-checkboxGroup="${planName}"`}
                          >
                            <Body2 as="div">{planName}</Body2>

                            {qpsClassesForThisPlanName.map((qpsBasicClass) => {
                              const adminClassId = getAdminClassId(qpsBasicClass);

                              const isDisabled = isSkipBenefitSelected;
                              const isChecked = currentValue.includes(adminClassId);

                              return (
                                <Checkbox
                                  key={pfmlSection.sectionType}
                                  name={checkboxIndex}
                                  value={adminClassId}
                                  label={
                                    <>
                                      <Body3 as="div" greyMedium={isDisabled}>
                                        {qpsBasicClass.adminClassName}
                                      </Body3>
                                    </>
                                  }
                                  checked={isChecked}
                                  errorId={
                                    getIn(formik.errors, checkboxIndex) &&
                                    getIn(formik.touched, checkboxIndex)
                                  }
                                  onChange={formik.handleChange}
                                  disabled={isDisabled}
                                />
                              );
                            })}

                            {isLastPlan && (
                              <>
                                <Body5 as="div">or</Body5>
                                <Checkbox
                                  key={`${SKIP_THIS_BENEFIT}`}
                                  name={checkboxIndex}
                                  value={SKIP_THIS_BENEFIT}
                                  label="Skip this benefit"
                                  checked={isSkipBenefitSelected}
                                  errorId={
                                    getIn(formik.errors, checkboxIndex) &&
                                    getIn(formik.touched, checkboxIndex)
                                  }
                                  error={
                                    getIn(formik.touched, checkboxIndex) &&
                                    getIn(formik.errors, checkboxIndex)
                                  }
                                  onChange={formik.handleChange}
                                  disabled={disabledSkipBenefit}
                                />
                              </>
                            )}
                          </StackY>
                        );
                      })}
                    </StackY>
                  </>
                ) : (
                  <>
                    <Body3 darkYellow as="div">
                      <StackX dist={12} style={{ alignItems: "flex-start" }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                        There is no admin class in QPS that matches this benefit type.
                      </StackX>
                    </Body3>
                  </>
                )}
              </Col>
            </Row>
          </HubCard>
        );
      })}

      <Row justify="space-between" className="mt-32 mb-48">
        <Col>
          <Button
            onClick={onPrevious}
            type="text-only"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            size="large"
            htmlType="button"
          >
            Previous
          </Button>
        </Col>

        <Col>
          <Button type="primary" size="large" htmlType="submit">
            Review assignments
          </Button>
        </Col>
      </Row>
    </form>
  );
}

function getQPSClassesPerBenefitTypeMap(
  qpsBasicClasses: QPSBasicClass[],
  featureToggles: ClientFeatureToggles,
) {
  const qpsClassesPerBenefitTypeMap = qpsBasicClasses.reduce<
    Map<PFMLStatutoryContributionSections, QPSBasicClass[] | undefined>
  >((map, q) => {
    const basicClassBenefitType = categoryCodeToBenefitType[q.benefitCode][0];
    assertIsDefined(basicClassBenefitType, "basicClassBenefitType");
    if (!isBenefitMappingToQPSSupported(basicClassBenefitType, featureToggles)) {
      return map;
    }

    const benefitSection = categoryCodeToPFMLSection[q.benefitCode];
    if (!benefitSection) {
      return map;
    }
    const key = benefitSection;
    const item = (map.get(key) || []).concat(q);
    map.set(key, item);
    return map;
  }, new Map());

  return qpsClassesPerBenefitTypeMap;
}

const getQPSClassByBenefitTypePlans = (
  benefitTypeMap: Map<PFMLStatutoryContributionSections, QPSBasicClass[] | undefined>,
  benefitSection: PFMLStatutoryContributionSections,
): QPSBasicClass[] => {
  const qpsClasses = benefitTypeMap.get(benefitSection);
  if (qpsClasses && qpsClasses.length > 0) {
    return qpsClasses;
  }

  return [];
};
