import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { BillsList } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillsList";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import type { BillSplitType } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { PolicyBillingPreferences } from "shared/validation/bill";

type Props = {
  billsInThisPolicy: Bill[];
  billPreviews: BillPreview[];
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billSplitType: BillSplitType | null | undefined;
  policyBillingPreferences: PolicyBillingPreferences;
  disabled: boolean;
  onEdit?: (bill1Index: number, bill2Index?: number) => void;
  onDelete?: (bill1Index: number, bill2Index?: number) => void;
};

export function YourBillsCard(props: Props) {
  const {
    billsInThisPolicy,
    billPreviews,
    client,
    policy,
    featureToggles,
    changeSnapshot,
    authUser,
    billSplitType,
    policyBillingPreferences,
    disabled,
    onEdit,
    onDelete,
  } = props;

  const billsDeletedInPreview = client.eifSignedAt
    ? billsInThisPolicy.filter(
        (bill) => !billPreviews.some((billPreview) => billPreview.id === bill.id),
      )
    : [];
  const initialChangeDetailInfo = billsDeletedInPreview.map(() => ({
    user: { firstName: authUser.name, lastName: "" },
    date: new Date(),
    status: "pending" as const,
  }));

  return (
    <HubCard disabled={disabled}>
      <h3>{billSplitType === "NONE" && billPreviews.length === 1 ? "Your bill" : "Your bills"}</h3>

      {billPreviews.length === 0 ? (
        <Body3>You have not created any bills yet.</Body3>
      ) : (
        <StackY dist={24} wrap={false}>
          <EIFEntityDeletedBy
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            client={client}
            entity="Bill"
            policyId={policy.id}
            pluralizeWord="bill"
            initialChangeDetailInfo={initialChangeDetailInfo}
          />

          <BillsList
            bills={billPreviews}
            client={client}
            policy={policy}
            featureToggles={featureToggles}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            billSplitType={billSplitType}
            policyBillingPreferences={policyBillingPreferences}
            disabled={disabled}
            onEdit={onEdit}
            onDelete={onDelete}
            signerMode="inside"
          />
        </StackY>
      )}
    </HubCard>
  );
}
