import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body5, H5 } from "../../../components/Typography/Typography";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { getBillsAndCertificatesNIGOMessage } from "../../../domain/Task/nigoMessage";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";

type PriorCarrierCertificatesProps = {
  client: Client;
  task: Task;
};

export function PriorCarrierCertificatesPage({ client, task }: PriorCarrierCertificatesProps) {
  useTitle("Prior Carrier Certificates");
  const trackElementClicked = useTrackElementClicked(client, "Prior Carrier Certificates");

  const remainingTasks = hasRemainingHubTasks(client);

  const shouldDisplayDueDate = getShouldDisplayDueDate(task);

  return (
    <ChildPageLayout
      topNavTitle="Prior Carrier Certificates"
      task={task}
      aside={
        <>
          <h1>Prior Carrier Certificates</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={task} />
              <br />
            </>
          )}

          <p>
            Having your prior carrier documents enables our team to set up your plans to match how
            they were being administered with your previous carrier. We encourage you to submit
            these as soon as you possibly can as we collectively work together to have your benefits
            ready for your employees to access on Day 1.
          </p>

          <p>This task is complete after we receive and verify your documents.</p>
        </>
      }
      contents={
        <>
          <h2>What is a prior carrier certificate?</h2>

          <p>
            Your prior carrier certificates are statements of coverage issued by your carrier. It
            serves as verification that your business is insured. This is different from benefit
            summaries or rate sheets.
          </p>

          <div className="my-40">
            <AddFilesCard
              clientId={client.id}
              taskStatus={task.status}
              category="prior-carrier-cert"
              title={<H5 as="h2">Upload your Prior Carrier Certificates</H5>}
              modalTitle="Upload your Prior Carrier Certificates"
              hideButton={task.status === "Done"}
              message="Sun Life will review your prior carrier certificates and let you know if anything else is required from you. Review typically takes 2-3 days. You can continue to add more files if needed."
              taskCompletedTitle="Your files have been submitted for review!"
              actionRequiredMessage={getBillsAndCertificatesNIGOMessage(
                "prior-carrier-certificates",
              )}
              remainingTasks={remainingTasks}
              footer={
                task.status === "Done"
                  ? (toggleVisible, track) => (
                      <div className="text-center">
                        <Body5 as="p">
                          Missed something?{" "}
                          <ButtonOld type="link-inline" size="small" onClick={toggleVisible}>
                            Add more files
                          </ButtonOld>
                        </Body5>

                        <StoredSecurelyText track={track} />
                      </div>
                    )
                  : undefined
              }
              trackElementClicked={trackElementClicked}
            />
          </div>

          <h3>Tips for completing this task</h3>

          <StackY dist={24}>
            <div>
              <p>
                <b className="body2">What is in a certificate?</b> <br />
                Certificates of insurance typically are multiple pages and are different from
                benefit summaries. A certificate of insurance describes fully how your insurance
                plan works.
              </p>
            </div>

            <div>
              <p>
                <b className="body2">
                  Include all benefit lines and/or classes (if applicable) for your benefits with
                  Sun Life
                </b>
                <br />
                You can double check which benefit lines you’ll have with Sun Life by returning to
                the Dashboard page or feel free to reach out to your Implementation Consultant to
                confirm. Your prior carrier may have separate certificates for all of your benefit
                lines and if applicable, your classes (how you organize your employee population).
                If that’s the case, you’ll need to upload documents for each. For example, if you
                have a Short Term Disability benefit and the benefit differs based on whether you
                are in the Management or All Other Employees class.
              </p>
            </div>

            <div>
              <p>
                <b className="body2">Check for amendments</b> <br />
                If you made amendments (otherwise known as changes) with your prior carrier, they
                may have separate files for those amendments as well.
              </p>
            </div>

            <div>
              <p>
                <b className="body2">Certificate alternate names</b> <br />
                If you’re having trouble finding these with your previous carrier(s), be aware that
                the documents may be called either a Certificate of Insurance (COI), Proof of
                Insurance, or Employee Booklets.
              </p>
            </div>
          </StackY>
        </>
      }
    />
  );
}
