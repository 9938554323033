import { Button } from "antd";
import * as Yup from "yup";
import { useSlobFormik } from "../../hooks/useSlobFormik";
import { RadioGroup } from "../Form/RadioGroup";
import { TextArea } from "../Form/TextArea";
import { StackY } from "../Spacing/Spacing";
import { slobMessage } from "../slobMessage/slobMessage";

type OnboardFeebackFormProps = {
  sendNativeFeedbackForm: (feedback: string, contact: "YES" | "NO") => void;
  closeFeedbackForm: () => void;
};

const giveFeedbackFormValidation = Yup.object({
  feedback: Yup.string().required("This field is required"),
  contact: Yup.string().oneOf(["YES", "NO"]).required("Please select an option"),
});

export function OnboardFeebackForm(props: OnboardFeebackFormProps) {
  const { sendNativeFeedbackForm, closeFeedbackForm } = props;

  const formik = useSlobFormik({
    initialValues: {
      feedback: "",
      contact: "",
    },
    validationSchema: giveFeedbackFormValidation,
    onSubmit({ feedback, contact }) {
      sendNativeFeedbackForm(feedback, contact);
      void slobMessage.success("Thanks! Your feedback has been recorded.");
      formik.resetForm();
      closeFeedbackForm();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <p>
        Have feedback? We'd love to hear it! If you have questions about onboarding tasks, reach out
        to our Support Team at{" "}
        <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>, contact your
        Implementation Consultant, or explore our{" "}
        <a rel="noreferrer" target="_blank" href="https://onboard-help.sunlifeconnect.com/hc/en-us">
          Help Center
          <span className="sr-only">(opens in a new tab)</span>
        </a>
        .
      </p>

      <StackY dist={24}>
        <TextArea
          name="feedback"
          label="Please provide your feedback:"
          value={formik.values.feedback}
          onChange={formik.handleChange}
          error={formik.errors.feedback}
          touched={formik.touched.feedback}
          disabled={formik.isSubmitting}
          maxLength={1000}
        />

        <RadioGroup
          name="contact"
          label="Would you like Sun Life to contact you about your feedback?"
          onChange={formik.handleChange}
          value={formik.values.contact}
          disabled={formik.isSubmitting}
          touched={formik.touched.contact}
          error={formik.errors.contact}
          options={[
            { value: "YES", label: "Yes" },
            { value: "NO", label: "No" },
          ]}
        />

        <Button type="primary" htmlType="submit">
          Send Feedback
        </Button>
      </StackY>
    </form>
  );
}
