import clsx from "clsx";

import * as styles from "./unorderedList.module.less";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  variant?: "default" | "checkmark";
  columns?: number;
  color?: "black" | "white";
  tight?: boolean;
  tighter?: boolean;
  markerColor?: "yellow" | "gray" | "black" | "white";
  content?: "body3" | "body5";
  noMargin?: boolean;
};

export function UnorderedList(props: Props) {
  const {
    columns = 1,
    variant = "default",
    markerColor = "yellow",
    color = "black",
    content = "body3",
    tight,
    tighter,
    noMargin,
    children,
  } = props;

  return (
    <ul
      className={clsx(
        styles.unorderedList,
        variant === "checkmark" && styles.checkmarkVariant,
        color === "white" && styles.white,
        content === "body3" && styles.contentBody3,
        content === "body5" && styles.contentBody5,
        tight && styles.tight,
        tighter && styles.tighter,
        markerColor === "gray" && styles.markerColorGray,
        markerColor === "black" && styles.markerColorBlack,
        markerColor === "white" && styles.markerColorWhite,
        noMargin && styles.noMargin,
      )}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- disable
      // @ts-ignore
      style={{ "--columns": columns }}
    >
      {children}
    </ul>
  );
}

export function UnorderedListItem(props: Props) {
  const { children } = props;
  return <li>{children}</li>;
}
