import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { GenericErrorCopy } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import { OrderedList, OrderedListItem } from "../../../components/OrderedList/OrderedList";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3, Body5, H5 } from "../../../components/Typography/Typography";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { useGetDocuments } from "../../../hooks/document";

import type { Task } from "../../../../../shared/data/Tasks";
import type { Client } from "../../../../../shared/types/Client";
import type { DocumentCategory } from "../../../../../shared/types/Document";
import type { ElementClickedOptions } from "../../../utils/analytics";

type PFMLUploadCardProps = {
  client: Client;
  task: Task;
  stateList: string;
  trackElementClicked: ({ buttonLabel, module, moduleState }: ElementClickedOptions) => void;
};

const category: DocumentCategory = "plan-exemption-document-pfml";

export const PFMLUploadCard = ({
  client,
  task,
  stateList,
  trackElementClicked,
}: PFMLUploadCardProps) => {
  const { error: getDocumentsError } = useGetDocuments({
    clientId: client.id,
    categories: [category],
  });

  return (
    <AddFilesCard
      clientId={client.id}
      hideButton={!(task.status && ["Not Started", "In Review"].includes(task.status))}
      readonly={task.status === "Done"}
      taskStatus={task.status}
      category={category}
      title={<H5 as="h3">Upload your approved Private Plan Exemption Documents for {stateList}</H5>}
      message="Sun Life will review this and let you know if anything else is required from you. Make sure approvals for all states are uploaded."
      actionRequiredMessage={null}
      taskCompletedTitle={`Make sure that Private Plan Exemption Document approvals are uploaded for all states (${stateList}) in order to complete PFML.`}
      remainingTasks={false}
      description={
        task.status === "Not Started" && (
          <StackY dist={16}>
            <OrderedList>
              <OrderedListItem>
                Work directly with your respective state(s) to obtain an approved Private Plan
                Exemption Document.
              </OrderedListItem>
              <OrderedListItem>
                Upload your approved Private Plan Exemption Documents here. Make sure approvals for
                all states are uploaded.
              </OrderedListItem>
              <OrderedListItem>
                The Sun Life team and your Implementation Consultant will review the approval(s).
              </OrderedListItem>
            </OrderedList>

            {getDocumentsError && (
              <LoadingError type="component" error={getDocumentsError} title={GenericErrorCopy} />
            )}
          </StackY>
        )
      }
      bottomDescription={
        task.status === "In Review" && (
          <AlertBanner
            variant="info"
            message={
              <Body3>
                Make sure that Private Plan Exemption Document approvals are uploaded for all states
                ({stateList}) in order to complete PFML.
              </Body3>
            }
          />
        )
      }
      footer={
        task.status === "Done"
          ? (toggleVisible, track) => (
              <div className={`text-center`}>
                <Body5 as="p">
                  Missed something?{" "}
                  <ButtonOld
                    type="link-inline"
                    size="small"
                    onClick={() => {
                      track("Add more files");
                      toggleVisible();
                    }}
                  >
                    Add more files
                  </ButtonOld>{" "}
                </Body5>

                <StoredSecurelyText track={track} />
              </div>
            )
          : undefined
      }
      trackElementClicked={trackElementClicked}
    />
  );
};
