import { formatLocation } from "shared/utils/location";
import { Body3 } from "../components/Typography/Typography";
import type { Contact } from "shared/types/Contact";
import type { ValidationError } from "yup";

export const getContactLocation = (
  contact: Contact,
  errors: ValidationError[],
  requireLocationName = true,
) => {
  if (errors.length === 0 && contact.location !== null) {
    return <Body3 as="div">{formatLocation(contact.location, requireLocationName)}</Body3>;
  }

  if (errors.length === 0 && contact.address1 && contact.city && contact.state && contact.zipCode) {
    return (
      <Body3 as="div">
        {formatLocation(
          {
            name: null,
            address1: contact.address1,
            address2: contact.address2,
            city: contact.city,
            state: contact.state,
            zipCode: contact.zipCode,
          },
          requireLocationName,
        )}
      </Body3>
    );
  }

  const locationErrorsByKey = errors.reduce((acc: Record<string, ValidationError>, error) => {
    return {
      ...acc,
      [error.path ? error.path : "unknownPath"]: error,
    };
  }, {});

  const useLegacyContactInfo = contact.location === null;

  return (
    <div>
      {requireLocationName && (
        <Body3 as="div" redMedium={locationErrorsByKey.name ? true : false}>
          {locationErrorsByKey.name ? "Location name is required" : contact.location?.name}
        </Body3>
      )}
      <Body3 as="div" redMedium={locationErrorsByKey.address1 ? true : false}>
        {locationErrorsByKey.address1
          ? "Address is required"
          : useLegacyContactInfo
          ? contact.address1
          : contact.location?.address1}
      </Body3>
      {contact.location?.address2 && <Body3 as="div">{contact.location?.address2}</Body3>}
      <Body3 as="div" redMedium={locationErrorsByKey.city ? true : false}>
        {locationErrorsByKey.city
          ? "City is required"
          : useLegacyContactInfo
          ? contact.city
          : contact.location?.city}
      </Body3>
      <Body3 as="div" redMedium={locationErrorsByKey.state ? true : false}>
        {locationErrorsByKey.state
          ? "State is required"
          : useLegacyContactInfo
          ? contact.state
          : contact.location?.state}
      </Body3>
      <Body3 as="div" redMedium={locationErrorsByKey.zipCode ? true : false}>
        {locationErrorsByKey.zipCode
          ? "Zip code is required"
          : useLegacyContactInfo
          ? contact.zipCode
          : contact.location?.zipCode}
      </Body3>
    </div>
  );
};
