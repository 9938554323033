import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { EIFNonClassBenefitsContributionsCTA } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsCTA";
import { EIFNonClassBenefitsContributionsCompletedItem } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsCompletedItem";
import { EIFNonClassBenefitsContributionsEditable } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsEditable";
import { EIFNonClassBenefitsReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsReadOnlyContribution";
import { EIFStatutoryReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFStatutoryReadOnlyContribution";
import { getNonClassBenefitsContributionsDetailInfoList } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/getNonClassBenefitsContributionsDetailInfoList";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { useQueryString } from "client/src/hooks/useQueryString";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { pfmlContributionSections, statutoryContributionSection } from "shared/types/Plan";
import {
  getIsPFMLSectionType,
  getIsStatutorySectionType,
  getUniquePFMLAndStatutorySections,
} from "shared/utils/EIF/nonClassBenefitsPreferences";
import { getShowValidationErrorsInSummary } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode } from "shared/types/Client";
import type { PFMLStatutoryContributionSections, Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  updatePlansQuery: UpdatePlansQuery;
  clientPlanForEveryPFMLAndStatutoryCoverage: boolean;
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  featureToggles: ClientFeatureToggles;
  trackElementClicked: TrackElementClickedFunc;
};

export function EIFNonClassBenefitsContributions(props: Props) {
  const {
    client,
    clientPlans,
    updatePlansQuery,
    clientPlanForEveryPFMLAndStatutoryCoverage,
    authUser,
    changeSnapshot,
    viewMode,
    featureToggles,
    trackElementClicked,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const onSave = () => {
    navigate(
      RouteData.eifSubStepDetail.getPath(
        client.id,
        "plan-configuration-&-eligibility",
        "non-class-benefits-preferences",
      ),
    );
  };

  const queryString = useQueryString();
  const activeSection = getSectionFromQS(queryString);

  const uniqueSections = getUniquePFMLAndStatutorySections(client, clientPlans);

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <>
      {uniqueSections.map((section, index) => {
        const { sectionType, label, plans, readOnly, status, state, isPFML } = section;
        const isActive = sectionType === activeSection;
        const showHorizontalDivider = index + 1 !== uniqueSections.length;

        const inactiveAndEmpty = !isActive && status === "Not Started";
        const showEditBanner = getShowEditBanner(viewMode);

        if (readOnly) {
          if (getIsPFMLSectionType(sectionType)) {
            assertIsDefined(state, "state");
            return (
              <Fragment key={sectionType}>
                <EIFNonClassBenefitsReadOnlyContribution
                  key={sectionType}
                  sectionState={state}
                  isReviewOnly={false}
                />

                {showHorizontalDivider && <HorizontalDivider />}
              </Fragment>
            );
          } else if (getIsStatutorySectionType(sectionType)) {
            return (
              <Fragment key={sectionType}>
                <EIFStatutoryReadOnlyContribution
                  sectionType={sectionType}
                  label={label}
                  isReviewOnly={false}
                />
                {showHorizontalDivider && <HorizontalDivider />}
              </Fragment>
            );
          }
        }
        if (!isActive) {
          return (
            <Fragment key={sectionType}>
              <EIFNonClassBenefitsContributionsCTA
                key={sectionType}
                location={location}
                inactiveAndEmpty={inactiveAndEmpty}
                sectionLabel={label}
                sectionQS={sectionType}
                disabled={
                  (activeSection && !isActive) || !clientPlanForEveryPFMLAndStatutoryCoverage
                }
                status={status}
                isPFML={isPFML}
              >
                {showEditBanner && (
                  <EditedFieldMsg
                    changeDetailInfoList={getNonClassBenefitsContributionsDetailInfoList(
                      changeSnapshot,
                      plans,
                    )}
                    client={client}
                    authUser={authUser}
                  />
                )}
                {status === "In Progress" && (
                  <Row gutter={[32, 18]} className="mb-16">
                    <Col flex="1">
                      <AlertBanner
                        variant="error"
                        message={
                          <Body3>
                            Click <Body2>Edit</Body2> to complete this section.
                          </Body3>
                        }
                      />
                    </Col>
                  </Row>
                )}
                {status !== "Not Started" && (
                  <EIFNonClassBenefitsContributionsCompletedItem
                    plans={plans}
                    suppressErrorsPostSigning={suppressErrorsPostSigning}
                  />
                )}
              </EIFNonClassBenefitsContributionsCTA>

              {showHorizontalDivider && <HorizontalDivider />}
            </Fragment>
          );
        }

        return (
          <Fragment key={sectionType}>
            <EIFNonClassBenefitsContributionsEditable
              key={sectionType}
              client={client}
              sectionPlans={plans}
              sectionLabel={label}
              updatePlansQuery={updatePlansQuery}
              onSave={onSave}
              authUser={authUser}
              changeSnapshot={changeSnapshot}
              featureToggles={featureToggles}
              status={status}
              trackElementClicked={trackElementClicked}
              isPFML={isPFML}
            />
            {showHorizontalDivider && <HorizontalDivider />}
          </Fragment>
        );
      })}
    </>
  );
}

function getSectionFromQS(qs: URLSearchParams): PFMLStatutoryContributionSections | undefined {
  const activeSection = qs.get("section");
  return isSection(activeSection) ? activeSection : undefined;
}

function isSection(str: string | null | undefined): str is PFMLStatutoryContributionSections {
  const isSection = [...pfmlContributionSections, ...statutoryContributionSection].includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    str as PFMLStatutoryContributionSections,
  );
  return isSection;
}
