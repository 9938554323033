import {
  billingAdministrationTypes,
  billingStructureTypes,
  billSplitTypes,
} from "shared/types/Client";
import { slfCoverageNameToLongName } from "shared/types/SlfCoverages";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { assertIsDefined, mapValues } from "shared/utils/utils";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import { employeeCategorizationTypes, categorizeEmployees, billTimings } from "../types/Bill";
import type {
  BillSplitType,
  BillTiming,
  BillingAdministrationType,
  BillingStructureType,
  EmployeeCategorizationType,
  YesNo,
} from "@prisma/client";
import type { Policy } from "shared/types/Client";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { AdvanceOrArrearsCoverage, SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import type { billingPreferencesSchema } from "shared/validation/policy";

const categorizeEmployeeValidationSchema = Yup.mixed<YesNo>().oneOf(categorizeEmployees);

const employeeCategorizationTypeValidationSchema = Yup.mixed<EmployeeCategorizationType>().oneOf(
  employeeCategorizationTypes,
);

const notMixedTiming = (
  advanceOrArrears: AdvanceOrArrearsSelection,
  billSplitType: BillSplitType,
  policy: Policy | undefined,
) => {
  // If we're splitting by benefit,  we never ask
  // for a secondary employee grouping categorization
  // so we can say this is valid even when there's no value
  if (billSplitType === "BENEFIT") return true;

  assertIsDefined(advanceOrArrears, "advanceOrArrears");

  assertIsDefined(policy, "policy");
  const { hasMixedTiming } = getAdvanceAndArrearsCoveragesBreakdown(policy, advanceOrArrears);
  return !hasMixedTiming;
};

export const billDetailsSpec = {
  policyId: Yup.string().required("Please provide a response"),

  advanceOrArrears: Yup.lazy(
    (value: Partial<Record<AdvanceOrArrearsCoverage, BillTiming>> | undefined) => {
      const spec = mapValues(value || {}, () => {
        const billTimingSchema = Yup.mixed<BillTiming>()
          .oneOf(billTimings, "Please make a selection")
          .nullable();
        return billTimingSchema;
      });
      const objectSchema = Yup.object(spec).test(nullNotAllowedTestConfig());
      return objectSchema;
    },
  ),

  billingAdministrationType: Yup.mixed<BillingAdministrationType>()
    .oneOf(billingAdministrationTypes, "Please provide a response")
    .nullable()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.test(nullNotAllowedTestConfig()),
    }),
  billingStructureType: Yup.mixed<BillingStructureType>()
    .oneOf(billingStructureTypes, "Please provide a response")
    .nullable()
    .when("billingAdministrationType", {
      is: (val: BillingAdministrationType | null) => val === "SELF" || val === "TPA",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  billSplitType: Yup.mixed<BillSplitType>()
    .oneOf(billSplitTypes, "Please make a selection")
    .nullable()
    .when("billingAdministrationType", {
      is: "LIST",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  billName: Yup.string()
    .trim()
    .defined()
    .nullable()
    .when(["billingAdministrationType", "billSplitType", "billingStructureType"], {
      is: (
        billingAdministrationType: BillingAdministrationType,
        billSplitType: BillSplitType,
        billingStructureType: BillingStructureType,
      ) =>
        (billingAdministrationType === "LIST" && billSplitType !== "NONE") ||
        ((billingAdministrationType === "SELF" || billingAdministrationType === "TPA") &&
          billingStructureType === "MULTIPLE"),
      then: (schema) => schema.required("Please provide a name for this bill"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  groupByLocationIds: Yup.array()
    .of(Yup.string().required())
    .defined()
    .nullable()
    .when("billSplitType", {
      is: "LOCATION",
      then: (schema) =>
        schema.required("Select at least one location").min(1, "Select at least one location"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  splitTags: Yup.array()
    .of(Yup.string().required())
    .defined()
    .nullable()
    .when("billSplitType", {
      is: "TAGS",
      then: (schema) =>
        schema
          .required("Please create at least one category")
          .min(1, "Please create at least one category"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  slfCoverages: Yup.array()
    .of(Yup.mixed<SlfCoverageLongName>().oneOf(Object.values(slfCoverageNameToLongName)).required())
    .strict()
    .defined()
    .nullable()
    .when("billSplitType", {
      is: "BENEFIT",
      then: (schema) =>
        schema.required("Select at least one benefit").min(1, "Select at least one benefit"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),

  contactId: Yup.string()
    .nullable()
    .defined()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required("Please provide a response"),
    }),
  hasDifferentMailingAddress: Yup.boolean()
    .required("Please provide a response")
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  locationId: Yup.string()
    .nullable()
    .defined()
    .when("hasDifferentMailingAddress", {
      is: true,
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  numberOfEmployees: Yup.number()
    .typeError("Invalid response")
    .positive("Must be a positive number")
    .integer("Must be a whole number")
    .max(16777215, "Can't be larger than 16777215")
    .nullable()
    .defined()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) =>
        schema.test(nullNotAllowedTestConfig()).required("Please provide a response"),
    }),

  categorizeEmployees: categorizeEmployeeValidationSchema
    .defined()
    .nullable()
    .when(["billingAdministrationType"], {
      is: (billingAdministrationType: BillingAdministrationType) =>
        billingAdministrationType === "LIST",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  employeeCategorizationType: employeeCategorizationTypeValidationSchema
    .defined()
    .nullable()
    .when("categorizeEmployees", {
      is: "YES",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  categorizeByLocationIds: Yup.array()
    .of(Yup.array().of(Yup.string().required()).required())
    .defined()
    .nullable()
    .when("employeeCategorizationType", {
      is: "LOCATION",
      then: (schema) =>
        schema.required("Please provide a response").min(2, "Please create at least 2 categories"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  categoriesByTags: Yup.array()
    .of(Yup.array().of(Yup.string().required()).required())
    .defined()
    .nullable()
    .when("employeeCategorizationType", {
      is: "TAGS",
      then: (schema) =>
        schema.required("Please provide a response").min(2, "Please create at least 2 categories"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),

  categorizeEmployees_secondary: categorizeEmployeeValidationSchema
    .defined()
    .nullable()
    .when(["billingAdministrationType"], {
      is: (billingAdministrationType: BillingAdministrationType) =>
        billingAdministrationType === "LIST",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["advanceOrArrears", "billSplitType", "$policy"], {
      is: notMixedTiming,
      then: (schema) => schema.notRequired(),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  employeeCategorizationType_secondary: employeeCategorizationTypeValidationSchema
    .defined()
    .nullable()
    .when("categorizeEmployees_secondary", {
      is: "YES",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["advanceOrArrears", "billSplitType", "$policy"], {
      is: notMixedTiming,
      then: (schema) => schema.notRequired(),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  categorizeByLocationIds_secondary: Yup.array()
    .of(Yup.array().of(Yup.string().required()).required())
    .defined()
    .nullable()
    .when("employeeCategorizationType_secondary", {
      is: "LOCATION",
      then: (schema) =>
        schema.required("Please provide a response").min(2, "Please create at least 2 categories"),
    })
    .when(["advanceOrArrears", "billSplitType", "$policy"], {
      is: notMixedTiming,
      then: (schema) => schema.notRequired().min(0),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  categoriesByTags_secondary: Yup.array()
    .of(Yup.array().of(Yup.string().required()).required())
    .defined()
    .nullable()
    .when("employeeCategorizationType_secondary", {
      is: "TAGS",
      then: (schema) =>
        schema.required("Please provide a response").min(2, "Please create at least 2 categories"),
    })
    .when(["advanceOrArrears", "billSplitType", "$policy"], {
      is: notMixedTiming,
      then: (schema) => schema.notRequired().min(0),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
};

export type BillingPreferencesFormValues = ValuesForValidationSchema<
  typeof billingPreferencesSchema
>;

export type BillFormValues = BillingPreferencesFormValues["bills"][0];

export type PolicyBillingPreferences = Pick<
  BillingPreferencesFormValues,
  | "billSplitType"
  | "billingAdministrationType"
  | "billingStructureType"
  | "billingSummaryStatementType"
  | "billPayrollCycles"
  | "billPayrollCyclesOther"
  | "billPayrollCyclesExplanation"
  | "whoSubmitsClaims"
  | "tpaContact"
>;
