import { Row, Col } from "client/src/components/Grid/Grid";
import { useCallback } from "react";

import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { GenericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../components/Typography/Typography";

import type { ResponseError } from "../../hooks/query";
import type { ElementClickedOptions } from "../../utils/analytics";
import type { Client } from "shared/types/Client";

type InstallCallCardProps = {
  client: Client;
  contactType: string;
  toggleEditing: () => void;
  handleNo: () => Promise<void>;
  isLoading: boolean;
  trackElementClicked: (options: ElementClickedOptions) => void;
  error: ResponseError | null;
  completedMessage: {
    yes: {
      heading: string;
      body: string;
    };
    no: {
      heading: string;
      body: string;
    };
  };
};

const isFutureInstallCall = (client: Client): boolean => {
  const task = client.tasks?.find((task) => task.id === "ongoing-service-call");
  return task?.status === "Upcoming";
};

export const InstallCallCard = ({
  client,
  contactType,
  toggleEditing,
  handleNo,
  isLoading,
  trackElementClicked,
  error,
  completedMessage,
}: InstallCallCardProps) => {
  const {
    installCallSelection,
    installCallSchedule,
    installCallTopics,
    installCallPeople,
    installCallUpdatedByUser,
    installCallUpdatedAt,
  } = client;

  const addDetailsClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Not Started",
      buttonLabel: "Add Details",
    });
    toggleEditing();
  }, [toggleEditing, trackElementClicked]);
  const noThanksClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Not Started",
      buttonLabel: "Don't schedule a call",
    });
    return handleNo();
  }, [handleNo, trackElementClicked]);
  const nevermindClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Completed",
      buttonLabel: "Schedule a call",
    });
    return toggleEditing();
  }, [toggleEditing, trackElementClicked]);
  const editClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Completed",
      buttonLabel: "Edit",
    });
    return toggleEditing();
  }, [toggleEditing, trackElementClicked]);

  if (isFutureInstallCall(client)) {
    return (
      <HubCard>
        <HubCardHeader
          title="Tell us about your needs"
          badge={
            <Badge
              srOnlyLabel="Task Status"
              variant="info"
              status="Not Started"
              message="Nothing to do just yet. Sun Life will notify you when it’s time to complete this task."
            />
          }
          hasDivider
        />
        <div className="mt-8">
          <Button type="primary" size="middle" disabled>
            Add details
          </Button>
        </div>
      </HubCard>
    );
  }

  switch (installCallSelection) {
    case null:
    case undefined: {
      return (
        <HubCard>
          <HubCardHeader
            title="Tell us about your needs"
            description={
              <p>
                Your {contactType} will reach out to you to schedule. You can help us coordinate
                your call by leaving your preferred dates and times, entering any specific topics
                you’d like to cover, and who you would like to include.
              </p>
            }
            badge={<Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />}
            hasDivider
          />
          <StackY dist={16}>
            {error && <LoadingError type="component" error={error} title={GenericErrorCopy2} />}

            <StackX dist={24} className="mt-8">
              <Button type="primary" size="middle" onClick={addDetailsClick} disabled={isLoading}>
                Add Details
              </Button>
              <Button loading={isLoading} size="middle" type="text-only" onClick={noThanksClick}>
                No thanks, but I’d still like to meet my service team.
              </Button>
            </StackX>
          </StackY>
        </HubCard>
      );
    }
    case "NO": {
      return (
        <HubCard>
          <HubCardHeader
            title="Tell us about your needs"
            description={
              <p>
                Your {contactType} will reach out to you to schedule. You can help us coordinate
                your call by leaving your preferred dates and times, entering any specific topics
                you’d like to cover, and who you would like to include.
              </p>
            }
            badge={
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="completed"
                updatedAt={installCallUpdatedAt}
                updatedBy={installCallUpdatedByUser}
              />
            }
            hasDivider
          ></HubCardHeader>
          <StackY dist={24}>
            <p>{completedMessage.no.body}</p>
            <div>
              <Button size="middle" type="text-only" onClick={nevermindClick}>
                Nevermind, I'd like to schedule a call
              </Button>
            </div>
          </StackY>
        </HubCard>
      );
    }
    case "YES": {
      return (
        <HubCard>
          <HubCardHeader
            hasDivider
            title="Tell us about your needs"
            badge={
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="completed"
                updatedAt={installCallUpdatedAt}
                updatedBy={installCallUpdatedByUser}
                message={completedMessage.yes.body}
              />
            }
          />

          <StackY dist={32}>
            <StackY dist={16} className="w-full">
              {[
                {
                  title: "Requested Date(s)",
                  data: installCallSchedule?.trim() ? installCallSchedule : "-",
                },
                {
                  title: "Requested Topic(s)",
                  data: installCallTopics?.trim() ? installCallTopics : "-",
                },
                {
                  title: "Invited Guests",
                  data: installCallPeople?.trim() ? installCallPeople : "-",
                },
              ].map((d) => (
                <Row justify="start" align="top" key={d.title}>
                  <Col span={10}>
                    <Body2>{d.title}</Body2>
                  </Col>

                  <Col span={12}>
                    <Body3>{d.data}</Body3>
                  </Col>
                </Row>
              ))}
            </StackY>
            <Button size="middle" onClick={editClick}>
              Edit
            </Button>
          </StackY>
        </HubCard>
      );
    }
  }
};
