import tinycolor from "tinycolor2";
import type { ColorInput } from "tinycolor2";

/**
 * Returns a color based on a numeric scale where 500 is the given color  0 is white and 1000 is black.
 * @see {@link https://www.dreamten.com/insights/mastering-design-system-colors}
 */
export function getColor(color: ColorInput, scale: number) {
  if (scale > 500 && scale <= 1000) {
    return tinycolor.mix(color, "#000", ((scale - 500) / 500) * 100).toHexString();
  }
  if (scale < 500 && scale >= 0) {
    return tinycolor.mix(color, "#fff", ((500 - scale) / 500) * 100).toHexString();
  }
  return tinycolor(color).toHexString();
}
