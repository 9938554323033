import { Typography } from "antd";
import { StackY } from "client/src/components/Spacing/Spacing";
import { BrokerUpdateValidationSchema } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { Body3, Body2, H2 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useUpdateBroker } from "../../../hooks/user";

import { UserForm } from "./UserForm";
import * as styles from "./form.module.less";
import type { UpdateOnboardingFormRecipientEmailOutput } from "../../../../../shared/types/OnboardingForm";

import type { Broker } from "shared/types/User";

const { Text } = Typography;

type BrokerCreateFormPresentationProps = {
  handleClose: () => void;
  broker: Broker;
  updateBroker: ReturnType<typeof useUpdateBroker>["updateBroker"];
  formRecipientEmailData: UpdateOnboardingFormRecipientEmailOutput | null;
  onChange?: () => void;
};

export const BrokerUpdateFormPresentation = ({
  handleClose,
  broker,
  updateBroker,
  formRecipientEmailData,
  onChange,
}: BrokerCreateFormPresentationProps) => {
  const formik = useSlobFormik({
    initialValues: broker,
    validationSchema: BrokerUpdateValidationSchema,
    async onSubmit(values) {
      try {
        const { updateUserMutation, updateFormRecipientEmailMutation } = await updateBroker({
          data: values,
          params: { userId: broker.id },
        });
        if (updateUserMutation.isError) {
          formik.setStatus("An error trying to update the broker");
          return;
        }

        if (updateFormRecipientEmailMutation?.isError) {
          formik.setStatus("An error trying to update the broker");
          return;
        }

        if (!updateFormRecipientEmailMutation) {
          void slobMessage.success("Successfully updated broker");
          onChange?.();
          handleClose();
          return;
        }

        const formRecipientUpdate = updateFormRecipientEmailMutation.data;
        if (formRecipientUpdate.success.length || formRecipientUpdate.failures.length) {
          return;
        }

        void slobMessage.success("Successfully updated broker");
        onChange?.();
        handleClose();
      } catch (error) {
        const errorMsg = ResponseError.getUserFacingErrorMessage(
          error,
          "An error trying to update the broker",
        );
        formik.setStatus(errorMsg);
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <H2 as="h1">Edit Broker</H2>

      {formRecipientEmailData ? (
        <StackY dist={24}>
          {Boolean(formRecipientEmailData.success.length) && (
            <>
              <Body2>Updated Successfully</Body2>
              <ul>
                {formRecipientEmailData.success.map((item) => (
                  <li key={item.onboardingFormRecipientId}>
                    <Body3>
                      This user's email address has been updated in DocuSign for client{" "}
                      {item.clientName}
                    </Body3>
                  </li>
                ))}
              </ul>
            </>
          )}
          {Boolean(formRecipientEmailData.failures.length) && (
            <>
              <Body2>Form Action Required</Body2>
              <ul>
                {formRecipientEmailData.failures.map((item) => (
                  <li key={item.onboardingFormRecipientId}>
                    <Body3>
                      Update the email address in DocuSign or make this user an Outside Signer for
                      client {item.clientName}
                    </Body3>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div className={styles.footer}>
            <Button type="default" htmlType="button" onClick={handleClose}>
              Close
            </Button>
          </div>
        </StackY>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <StackY dist={24}>
            <UserForm formik={formik} />
            <div className={styles.footer}>
              <Button
                type="primary"
                htmlType="submit"
                loading={formik.isSubmitting}
                disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                Save
              </Button>
              <Button
                type="default"
                htmlType="button"
                onClick={handleClose}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </div>
            {formik.status && <Text type="danger">{formik.status}</Text>}
          </StackY>
        </form>
      )}
    </div>
  );
};

type BrokerCreateFormProps = {
  handleClose: () => void;
  broker: Broker;
  onChange?: () => void;
};

export const BrokerUpdateForm = ({ handleClose, broker, onChange }: BrokerCreateFormProps) => {
  const { updateBroker, updateFormRecipientEmailData } = useUpdateBroker(broker);

  return (
    <BrokerUpdateFormPresentation
      broker={broker}
      handleClose={handleClose}
      updateBroker={updateBroker}
      onChange={onChange}
      formRecipientEmailData={updateFormRecipientEmailData?.data ?? null}
    />
  );
};
