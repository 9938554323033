import {
  getIsContributionBenefitType,
  getIsWaitingPeriodBenefitType,
  getSalaryBasedClassPlans,
} from "shared/types/BenefitTypes";
import type { BenefitTypeEIF } from "@prisma/client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EIFClassBuilderSection } from "shared/utils/EIF/getEIFStepCompleteStatus";

export function getAvailableEIFClassBuilderSections(
  singleClassBuilderMode: boolean,
  clientPlansBenefitTypes: Set<BenefitTypeEIF>,
  employeeClass: EmployeeClass | undefined,
) {
  const salaryBasedClassPlans = getSalaryBasedClassPlans(employeeClass);

  const hasWaitingPeriodBenefitTypes = employeeClass?.employeeClassPlans.some((ecp) =>
    getIsWaitingPeriodBenefitType(ecp.plan.benefitType),
  );
  const showWaitingPeriods =
    !employeeClass || employeeClass.employeeClassPlans.length === 0 || hasWaitingPeriodBenefitTypes;

  const hasContributionBenefitPlans = employeeClass?.employeeClassPlans.some((ecp) =>
    getIsContributionBenefitType(ecp.plan.benefitType),
  );
  const showContributions =
    !employeeClass || employeeClass.employeeClassPlans.length === 0 || hasContributionBenefitPlans;

  const hasSalaryBasedClassPlans = salaryBasedClassPlans.length > 0;

  const availableSections: EIFClassBuilderSection[] = [
    ...(!singleClassBuilderMode ? ["Eligibility" as const] : []),

    "Details",

    ...(clientPlansBenefitTypes.size > 1 ? ["Benefits" as const] : []),

    ...(showWaitingPeriods ? ["Waiting Periods" as const] : []),

    ...(showContributions ? ["Contributions" as const] : []),

    ...(hasSalaryBasedClassPlans ? ["Earnings definition" as const] : []),
  ];

  return availableSections;
}
