import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { useState } from "react";
import { assertIsDefined } from "shared/utils/utils";
import { Anchor } from "../../../../components/Anchor/Anchor";
import { BulbMessage } from "../../../../components/BulbMessage/BulbMessage";
import { InputErrorMessage } from "../../../../components/Form/InputErrorMessage";
import { SlobSelectMultiple } from "../../../../components/MultiSelect/SlobSelectMultiple";
import { TagList } from "../../../../components/TagList/TagList";
import { Body2, Body3, Eyebrow } from "../../../../components/Typography/Typography";
import { useSlobId } from "../../../../hooks/useSlobId";
import { LocationModalProvider } from "../../../Location/LocationModalProvider";
import { getLocationMultiSelectOptionsAndValueForCategorization } from "../../../Location/getLocationMultiSelectProps";
import * as styles from "./BillCategorizationField.module.less";
import type { EmployeeCategorizationType } from "@prisma/client";
import type { Client } from "shared/types/Client";
import type { Location } from "shared/types/Location";

export type BillCategorizationFieldProps = {
  name: string;
  value: string[][];
  onChange: (val: string[][]) => void;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  clientId: Client["id"];
  employeeCategorizationType: EmployeeCategorizationType;
  locations: Location[];
};

export const BillCategorizationField = ({
  name,
  clientId,
  employeeCategorizationType,
  locations,
  value,
  onChange,
  touched,
  error,
  disabled,
}: BillCategorizationFieldProps) => {
  const [internalValue, setInternalValue] = useState<string[][]>(value.length ? value : [[]]);
  const lastInternalValueItem = internalValue[internalValue.length - 1];
  assertIsDefined(lastInternalValueItem, "lastInternalValueItem");

  const id = useSlobId({ prefix: name });
  const errorId = touched && Boolean(error) ? `${id}__errormessage` : undefined;

  return (
    <LocationModalProvider clientId={clientId}>
      {({ showLocationModal, locationModalVisible }) => (
        <div>
          <Body2 as="p">Create employee groups</Body2>
          <div
            className={styles.locationCategoriesWrapper}
            aria-invalid={!!errorId}
            aria-errormessage={errorId}
          >
            <div className="m-24">
              <BulbMessage>
                <Body3>
                  This must match the employee data in your census file. This is captured in the{" "}
                  <strong>Billing Location</strong> (column E) in your Sun Life-provided template.{" "}
                  <Anchor
                    newTabIcon
                    target="_blank"
                    href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4830223463059-Completing-your-Census-File"
                  >
                    Learn more about the Sun Life census template file
                  </Anchor>
                </Body3>
              </BulbMessage>
            </div>

            {employeeCategorizationType === "LOCATION" ? (
              <>
                {internalValue.map((category, index) => (
                  <div key={`location-group-${index}`} className="m-24">
                    <Body2>{`Employee group ${index + 1}`}</Body2>
                    <Eyebrow as="p">
                      Employees from the selected locations will be grouped together.
                    </Eyebrow>
                    <SlobSelectMultiple
                      aria-label={`Select location(s) for employee group ${index + 1}`}
                      placeholder="Select location(s)"
                      name={name}
                      maxTagTextLength={50}
                      touched={touched}
                      disabled={disabled}
                      errorId={errorId}
                      hasExternalErrorStyles
                      open={locationModalVisible ? false : undefined}
                      {...getLocationMultiSelectOptionsAndValueForCategorization({
                        locations,
                        unassignedLocations: locations,
                        selectedLocationIds: category,
                        onEditLocation: showLocationModal,
                        onAddLocation: showLocationModal,
                      })}
                      onChange={(val) => {
                        setInternalValue((internalValue) => {
                          internalValue.splice(
                            index,
                            1,
                            val.map((v) => v.value.toString()),
                          );
                          const next = internalValue.slice();
                          onChange(next);
                          return next;
                        });
                      }}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {internalValue.map((category, index) => (
                  <div key={`tag-group-${index}`} className="m-24">
                    <Body2>{`Employee group ${index + 1}`}</Body2>
                    <Eyebrow as="p">
                      Employees will be grouped together based on what is entered.
                    </Eyebrow>
                    <TagList
                      name={name}
                      placeholder="Add a grouping and press enter"
                      value={category}
                      errorId={errorId}
                      hasExternalErrorStyles
                      onChange={({ target: { value } }) => {
                        setInternalValue((internalValue) => {
                          internalValue.splice(index, 1, value);
                          const next = internalValue.slice();
                          onChange(next);
                          return next;
                        });
                      }}
                    />
                  </div>
                ))}
              </>
            )}

            <div className="m-24">
              <Button
                type="text-only"
                size="middle"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                disabled={disabled || lastInternalValueItem.length === 0}
                onClick={() => setInternalValue((prev) => prev.concat([[]]))}
              >
                Create another group
              </Button>
            </div>
          </div>
          <div aria-live="assertive">
            {touched && Boolean(error) && <InputErrorMessage id={errorId} error={error} />}
          </div>
        </div>
      )}
    </LocationModalProvider>
  );
};
