import { Button } from "client/src/components/Button/Button";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { Body5, H5 } from "../../../components/Typography/Typography";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import type { PolicyTask } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type DentalASODeductibleProps = {
  client: Client;
  policyTask: PolicyTask;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
};

export function DentalASODeductible({
  client,
  policyTask,
  policy,
  featureToggles,
}: DentalASODeductibleProps) {
  useTitle("Deductible Maximum Report");
  const trackElementClicked = useTrackElementClicked(client, "Deductible Maximum Report");

  const ONBOARD_ASO_DENTAL = featureToggles.ONBOARD_ASO_DENTAL;

  if (!ONBOARD_ASO_DENTAL) {
    return <Navigate to={RouteData.homeHub.getPath(client.id)} replace />;
  }

  const remainingTasks = hasRemainingHubTasks(client);

  const shouldDisplayDueDate = getShouldDisplayDueDate(policyTask);
  const subTitle =
    policyTask.status === "Not Started" || policyTask.status === "Upcoming"
      ? "Upload your completed file and submit it to Sun Life for review."
      : null;

  return (
    <ChildPageLayout
      topNavTitle="Deductible Maximum Report"
      task={policyTask}
      aside={
        <>
          <h1>Deductible Maximum Report</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={policyTask} />
              <br />
            </>
          )}

          <p>
            Your dental plan includes Dental ASO. Please upload a report to carry over your current
            maximum and deductible amounts.
          </p>
        </>
      }
      contents={
        <>
          <div className="my-40">
            <AddFilesCard
              clientId={client.id}
              taskStatus={policyTask.status}
              category="deductible-maximum-report"
              title={<H5 as="h2">Submit Dental ASO Deductible and Maximum Report</H5>}
              modalTitle="Upload file"
              hideButton={policyTask.status === "Done"}
              message="Sun Life will review your previous dental information and let you know if anything else is required from you. Review typically takes 2-3 days. You can continue to add more files if needed."
              taskCompletedTitle="Your files have been submitted for review!"
              actionRequiredMessage={null}
              remainingTasks={remainingTasks}
              subTitle={subTitle}
              footer={
                policyTask.status === "Done"
                  ? (toggleVisible, track) => (
                      <div className="text-center">
                        <Body5 as="p">
                          Missed something?{" "}
                          <Button type="text-only-light" size="small" onClick={toggleVisible}>
                            Add more files
                          </Button>
                        </Body5>

                        <StoredSecurelyText track={track} />
                      </div>
                    )
                  : undefined
              }
              trackElementClicked={trackElementClicked}
              policies={client.policies}
              policyId={policy.id}
            />
          </div>
        </>
      }
    />
  );
}
