import { Input } from "antd";
import clsx from "clsx";

import { useSlobId } from "../../hooks/useSlobId";
import { Body2, Body3 } from "../Typography/Typography";
import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type { CommonFormInputsProps } from "client/src/components/Form/formTypes";

export type TextAreaProps = CommonFormInputsProps & {
  value: string | null | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  labelBottomText?: string;
  placeholder?: string;
  style?: React.CSSProperties;
};

export const TextArea = ({
  label,
  name,
  onChange,
  value,
  labelBottomText,
  disabled = false,
  maxLength = 1000,
  touched,
  error,
  placeholder,
  style,
  "aria-label": ariaLabel,
}: TextAreaProps) => {
  const shouldShowError = error && touched;
  const inputClass = clsx({ [styles.inputError]: shouldShowError });

  const id = useSlobId({ prefix: name });

  const errorId = shouldShowError ? `${id}__errormessage` : undefined;

  const props = {
    id,
    name: name,
    onChange: onChange,
    maxLength: maxLength,
    disabled: disabled,
    value: value == null ? undefined : value,
    className: inputClass,
    placeholder,
    style,
    "aria-invalid": Boolean(shouldShowError),
    "aria-errormessage": shouldShowError ? errorId : undefined,
    "aria-label": ariaLabel,
  };

  return (
    <div className={styles.textArea}>
      {(label || labelBottomText) && (
        <label htmlFor={id}>
          {label && <Body2 as="p">{label}</Body2>}
          {labelBottomText && <Body3 as="p">{labelBottomText}</Body3>}
        </label>
      )}

      <Input.TextArea {...props} />

      <div aria-live="assertive">
        {shouldShowError && <InputErrorMessage id={errorId} error={error} />}
      </div>
    </div>
  );
};
