// @TODO Move Task to shared/types and write a test for this
import { useSlobQuery } from "../query";

import type { TicketMeta } from "../../../../shared/types/ExplorerPageBenefit";

export const useGetTaskById = (ticketId: string) =>
  useSlobQuery<TicketMeta>({
    method: "get",
    path: `/api/tickets/${ticketId}/meta`,
    map: (response) => ({
      ...response,
      benefits: response.benefits.map((benefit) => ({
        ...benefit,
        financialFSAMoneyRolloverDate:
          benefit.financialFSAMoneyRolloverDate != null
            ? new Date(benefit.financialFSAMoneyRolloverDate)
            : null,
      })),
    }),
    options: {
      enabled: !!ticketId,
    },
  });
