import { Anchor } from "client/src/components/Anchor/Anchor";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { Navigate } from "react-router-dom";
import { valueAddedServicesOptionsText } from "shared/types/Client";
import { getEIFSubStepMap } from "shared/types/EIF";
import {
  getIsSubStepApplicable,
  shouldShowValueAddedServicesLifeCoverage,
} from "shared/utils/EIF/getIsSubStepApplicable";
import { valueAddedServicesSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormEventHandler } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

const formKeyProps: (keyof Client)[] = [
  "valueAddedServicesOptions",
  "valueAddedServicesStepViewed",
];

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
};

export const formTestId = "valued-added-services-form";

export function EIFValueAddedServices(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const eifSubStepId = "value-added-services";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: valueAddedServicesSchema,
    validationContext: { client },
    type: subStepName,
    track,
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
  });

  const hasSitusStateVT = client.policies.some(({ issuedStateSitus }) =>
    issuedStateSitus?.includes("VT"),
  );

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const emergencyTravelAssistanceSelected =
    formik.values.valueAddedServicesOptions &&
    formik.values.valueAddedServicesOptions === "EMERGENCY_TRAVEL_ASSISTANCE";
  const hasAccidentCoverage = client.allPoliciesSlfCoverages?.includes("Accident");
  const showAccidentReadOnly = hasAccidentCoverage && !emergencyTravelAssistanceSelected;
  const showCriticalIllnessReadOnly = client.allPoliciesSlfCoverages?.includes("Critical Illness");
  const showValueAddedServicesLifeCoverage = shouldShowValueAddedServicesLifeCoverage(client);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await formik.setFieldValue("valueAddedServicesStepViewed", true, true);
    formik.handleSubmit();
  };

  return (
    <>
      <form onSubmit={handleSubmit} data-testid={formTestId}>
        <StackY dist={24}>
          <StackY dist={12}>
            <h2>{subStepName}</h2>
            <Body5>
              These services are not insurance. If employees also have access to an employee
              assistance program (EAP), be careful to avoid duplicating services when selecting a
              value-added service option.
            </Body5>
            <Anchor variant="bold" target="_blank" href="/Value-Added Services Information.pdf">
              Learn more about the value-added service options
            </Anchor>
          </StackY>

          <StackY dist={40}>
            <StackY dist={32}>
              {showValueAddedServicesLifeCoverage && (
                <HubCard>
                  <StackY dist={8}>
                    <RadioGroup
                      name="valueAddedServicesOptions"
                      label={
                        <StackY dist={8}>
                          <H3 as="p">Life</H3>
                          <Body5 as="p">
                            Because you purchased a Life benefit for your employees, you are
                            eligible to receive one of the following value-added services offered by
                            Sun Life.
                          </Body5>
                          <Body2 as="p">
                            Which value-added service would you like to include for your employees?
                          </Body2>
                        </StackY>
                      }
                      direction="vertical"
                      disabled={formik.isSubmitting}
                      touched={formik.touched.valueAddedServicesOptions}
                      error={formik.errors.valueAddedServicesOptions}
                      options={[
                        ...(!hasSitusStateVT
                          ? [
                              {
                                value: "SELF_CARE_PLUS",
                                label: (
                                  <StackY dist={8}>
                                    <StackY dist={2}>
                                      <Body3>
                                        {valueAddedServicesOptionsText["SELF_CARE_PLUS"]}
                                      </Body3>
                                      <UnorderedList markerColor="gray">
                                        <UnorderedListItem>
                                          <Body5>
                                            Provides 24/7 access to digital tools like mindfulness
                                            activities, guided journals and meditations to help
                                            employees and their families (13+) build resilience and
                                            improve their mental health.
                                          </Body5>
                                        </UnorderedListItem>
                                      </UnorderedList>
                                    </StackY>
                                  </StackY>
                                ),
                              },
                            ]
                          : []),
                        {
                          value: "EMERGENCY_TRAVEL_ASSISTANCE",
                          label: (
                            <StackY dist={8}>
                              <StackY dist={2}>
                                <Body3>
                                  {valueAddedServicesOptionsText["EMERGENCY_TRAVEL_ASSISTANCE"]}
                                </Body3>
                                <UnorderedList markerColor="gray" tighter>
                                  <UnorderedListItem>
                                    <Body5>
                                      Connects employees and dependents with medical, dental or
                                      personal emergency services when traveling 100+ miles from
                                      home.
                                    </Body5>
                                  </UnorderedListItem>
                                  <UnorderedListItem>
                                    <Body5>
                                      Offers 24/7 access to identity theft prevention and resolution
                                      services to safeguard against fraud.
                                    </Body5>
                                  </UnorderedListItem>
                                </UnorderedList>
                              </StackY>
                            </StackY>
                          ),
                        },
                        {
                          value: "ONLINE_WILL_PREPARATION",
                          label: (
                            <>
                              <StackX dist={8}>
                                <Body3>
                                  {valueAddedServicesOptionsText["ONLINE_WILL_PREPARATION"]}
                                </Body3>
                              </StackX>
                              <UnorderedList markerColor="gray" tighter>
                                <UnorderedListItem>
                                  <Body5>
                                    Provides step-by-step guidance online to create a legally
                                    binding will for employees and spouses.
                                  </Body5>
                                </UnorderedListItem>
                                <UnorderedListItem>
                                  <Body5>
                                    Employee assistance program (EAP) that connects claimants and
                                    beneficiaries to professional grief, financial or legal
                                    counseling.
                                  </Body5>
                                </UnorderedListItem>
                              </UnorderedList>
                            </>
                          ),
                        },
                      ]}
                      value={formik.values.valueAddedServicesOptions}
                      onChange={formik.handleChange}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.Client.valueAddedServicesOptions]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "valueAddedServicesOptions",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                </HubCard>
              )}

              {showAccidentReadOnly && (
                <HubCard>
                  <H3>Accident</H3>
                  <Body5>
                    Because you purchased an Accident benefit for your employees, you will be
                    receiving Emergency Travel Assistance and Identity Theft Protection as
                    value-added services. No further action is needed from your end.
                  </Body5>
                </HubCard>
              )}
              {showCriticalIllnessReadOnly && (
                <HubCard>
                  <H3>Critical Illness</H3>
                  <StackY dist={8}>
                    <Body5>
                      Because you purchased a Critical Illness benefit for your employees, you will
                      be receiving the Health Navigator Helpline through Pinnacle Care. No further
                      action is needed from your end.
                    </Body5>
                    <Anchor
                      href="https://p1.aprimocdn.net/sunlife/566395a7-825c-485e-8292-b0cf00f2458b/slfl-2106-hlthnav-helpline-overview-original-file.pdf"
                      newTabIcon={true}
                      target={"_blank"}
                    >
                      Learn more about the Health Navigator Helpline
                    </Anchor>
                  </StackY>
                </HubCard>
              )}

              <EIFBottomNavButtons
                previousLink={previousSubStepLink}
                previousButtonDisabled={formik.isSubmitting}
                nextButtonDisabled={formik.isSubmitting}
              />
            </StackY>
          </StackY>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
