import { SearchOutlined } from "@ant-design/icons";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { FormInput } from "client/src/components/Form/Input";
import { Modal } from "client/src/components/Modal/Modal";
import { SlobTable } from "client/src/components/SlobTable/SlobTable";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body3, Eyebrow } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { CompactProgressTrackerPolicy } from "client/src/domain/Client/ClientsList/ClientsTable/CompactProgressTrackerPolicy";
import { MultiCellValue } from "client/src/domain/Client/ClientsList/ClientsTable/MultiCellValue";
import { useGetClientByID, useGetClients, useLinkToClient } from "client/src/hooks/client";
import { useDebouncedValue } from "client/src/hooks/useDebouncedValue";
import { useFocusControl } from "client/src/hooks/useFocusControl";
import { useGetBenAdmins } from "client/src/hooks/user";
import { getPoliciesEffectiveDates } from "client/src/utils/sort";
import { compact } from "lodash";
import { useEffect, useState } from "react";

import { formatFullName } from "shared/utils/format";
import * as styles from "./cardLinkClient.module.less";
import type { SlobColumnsType } from "client/src/components/SlobTable/SlobTable";

import type { Client, ClientSetupStepId, ClientSetupStatus } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenAdmin } from "shared/types/User";

type CardLinkClientProps = {
  client: Client;
  linkClientStatus: ClientSetupStatus;
  handleCompletedStep: (stepId: ClientSetupStepId) => Promise<void>;
  featureToggles: ClientFeatureToggles;
};

const isNotOnboardComplete = (client: Client | null) =>
  client?.policies.some((policy) => policy.phaseId !== "ONBOARDING_COMPLETE") ?? true;

const exactMatchFilter = ({ clients, name }: { clients: Client[]; name: string }) =>
  clients.filter((d) => d.name === name) ?? [];
const getColumns = (): SlobColumnsType<ClientWithBAs> => [
  {
    title: "Client",
    dataIndex: "name",
    render: (clientName: string, client) => {
      const portalTypes = compact([
        client.isOnboard ? "Onboard" : null,
        client.isBenefitsExplorer ? "Benefits Explorer" : null,
      ]).join(", ");

      return (
        <>
          {clientName}
          <br />
          <Eyebrow>{portalTypes}</Eyebrow>
        </>
      );
    },
  },
  {
    title: "Case ID",
    dataIndex: "caseId",
  },

  {
    title: "BA Contact(s)",
    dataIndex: "contacts",
    render: (BAs: BenAdmin[]) => {
      return (
        <>
          {BAs?.filter((b) => formatFullName(b) !== "").map((BA) => (
            <Body3>
              {formatFullName(BA)}
              <br />
            </Body3>
          ))}
        </>
      );
    },
  },
  {
    title: "Onboarding Phase",
    render: (client: Client) => {
      return (
        <>
          {client.policies.map((policy) => {
            return (
              <div key={policy.slfPolicyNumber}>
                {`Policy #${policy.slfPolicyNumber}`}
                <CompactProgressTrackerPolicy key={policy.id} client={client} policy={policy} />
              </div>
            );
          })}
        </>
      );
    },
  },
  {
    title: "Effective Date",
    dataIndex: "firstPolicyEffective",
    className: MultiCellValue.tdClassName,
    render: (_, client: Client) => {
      const dates = getPoliciesEffectiveDates(client.policies);
      return (
        <MultiCellValue>
          {dates.map((date, i) => (
            <div key={`${date}_${i}`}>{date}</div>
          ))}
        </MultiCellValue>
      );
    },
  },
];

export const CardLinkClient = ({
  client,
  linkClientStatus,
  handleCompletedStep,
  featureToggles,
}: CardLinkClientProps) => {
  if (linkClientStatus === "Done") {
    if (client.baseClientId === null || client.id === client.baseClientId) {
      return <p>This client has not used Onboard before</p>;
    }
    return (
      <>
        <Body3>Merged with:</Body3>
        <CardLinkClientTable clientId={client.baseClientId} featureToggles={featureToggles} />
        <Body3>
          The Benefits Administrator and Broker information associated with this previous client
          instance have been copied to this client instance.
        </Body3>
      </>
    );
  }

  return (
    <NotStarted
      client={client}
      handleCompletedStep={handleCompletedStep}
      featureToggles={featureToggles}
    />
  );
};

type ClientWithBAs = Client & {
  contacts: BenAdmin[];
};
const CardLinkClientTable = ({
  clientId,
  featureToggles,
}: {
  clientId: string;
  featureToggles: ClientFeatureToggles;
}) => {
  const { data: linkedClient, isLoading: isLoadingLinkedClient } = useGetClientByID(clientId);

  const benAdminQueryParams = {
    page: 1,
    pageSize: 5,
    search: "",
    clientId,
  };

  const { data: benAdmins, isLoading: isLoadingBA } = useGetBenAdmins(benAdminQueryParams);

  const data = linkedClient
    ? [
        {
          ...linkedClient,
          contacts: benAdmins?.data.filter((b) => b.clientIds.includes(clientId)) ?? [],
        },
      ]
    : [];

  return (
    <SlobTable
      columns={getColumns()}
      isLoading={isLoadingBA || isLoadingLinkedClient}
      currentPage={1}
      onChange={() => null}
      totalItems={1}
      pageSize={1}
      data={data}
      showFooter={false}
      featureToggles={featureToggles}
    />
  );
};

const NotStarted = ({
  client,
  handleCompletedStep,
  featureToggles,
}: {
  client: Client;
  handleCompletedStep: (stepId: ClientSetupStepId) => Promise<void>;
  featureToggles: ClientFeatureToggles;
}) => {
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [showModalConfirmMerge, setShowMergeModal] = useState(false);
  const [showModalConfirmNoLinkedClient, setShowNoClientModal] = useState(false);
  const [showClientSearch, setClickedShowClientSearch] = useState(false);
  const [isUpdatingLinkClient, setIsUpdatingLinkClient] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [clientToLink, setClientToLink] = useState<Client | null>(null);

  const search = useDebouncedValue(searchText, 300);

  const { mutateAsync: linkClient } = useLinkToClient();

  const onLinkClient = async () => {
    if (!clientToLink || clientToLink.id === client.id) {
      void slobMessage.error("Failed Merging Client");
      setShowMergeModal(false);
      return;
    }
    try {
      setIsUpdatingLinkClient(true);
      const { isError } = await linkClient({
        data: {
          baseClientId: clientToLink.id,
        },
        params: {
          clientId: client.id,
        },
      });
      if (isError) {
        void slobMessage.error("Failed Merging Client");
        return;
      }
      await handleCompletedStep("MERGE_CLIENT_INSTANCES");
      void slobMessage.success("Completed Merging Client");
    } catch (error) {
      void slobMessage.error("Failed Merging Client");
    } finally {
      setShowMergeModal(false);
      setIsUpdatingLinkClient(false);
    }
  };

  const { data: searchedClients } = useGetClients({
    isOnboard: true,
    search: client.name ?? "",
    page: 1,
    pageSize: 5,
  });

  const onSearch = ({ text }: { text: string }) => {
    setSearchText(text.toLowerCase());
  };

  const onNoClient = async () => {
    await handleCompletedStep("MERGE_CLIENT_INSTANCES");
    setShowNoClientModal(false);
  };

  const modalConfirmMerge = () => {
    return (
      <Modal
        title={"Are you sure you want to merge these two?"}
        open={true}
        footer={
          <StackX dist={8} style={{ float: "right" }}>
            <Button
              type="text"
              disabled={isUpdatingLinkClient}
              onClick={() => {
                setShowMergeModal(false);
              }}
            >
              Cancel
            </Button>

            <Button
              disabled={isUpdatingLinkClient}
              loading={isUpdatingLinkClient}
              type="primary"
              onClick={onLinkClient}
            >
              Yes, merge these instances
            </Button>
          </StackX>
        }
        onCancel={() => {
          setShowMergeModal(false);
        }}
      >
        <p>
          Once merged, the previous instance of the client will no longer be accessible via Onboard.
          Please make sure this is the correct client instance before merging.
          <br />
          <br />
          Merging will copy the Benefits Administrator and Broker information from the previous
          instance to this client instance.
        </p>
      </Modal>
    );
  };
  const modalConfirmNoLinkedClient = () => {
    return (
      <Modal
        title={"Are you sure this client has not used Onboard before?"}
        open={true}
        footer={
          <StackX dist={8} style={{ float: "right" }}>
            <Button
              type="text"
              onClick={() => {
                setShowNoClientModal(false);
              }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={onNoClient}>
              Yes, I'm sure this client hasn't used Onboard before
            </Button>
          </StackX>
        }
        onCancel={() => {
          setShowMergeModal(false);
        }}
      >
        <p>
          If you indicate that this client has not used Onboard before, you will start a new
          instance of this client in Onboard, and cannot change this after the fact.
        </p>
        <p>
          If a previous instance of the client exists, the users will still be able to access links
          to their previous implementation and there may be confusion about where to direct them
          when they log in.
        </p>
      </Modal>
    );
  };

  const clients = searchedClients?.data.filter((c) => c.id !== client.id) ?? [];

  const potentialClientsToLink = exactMatchFilter({ clients, name: client.name ?? "" });
  if (!showClientSearch && (potentialClientsToLink.length === 1 || clientToLink)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
    const potentialClientToLink = clientToLink ?? potentialClientsToLink[0]!;
    return (
      <div className={styles.table}>
        {showModalConfirmMerge && modalConfirmMerge()}
        <StackY dist={8}>
          {isNotOnboardComplete(potentialClientToLink) && (
            <AlertBanner
              variant="info"
              message={
                <Body3>
                  This client has not completed onboarding.
                  <br />
                  If this is the same client, use the multi-policy workflow to add a policy to this
                  case instead of merging.
                </Body3>
              }
            />
          )}
          <Body3>
            If this client used Onboard previously, please merge their previous instance with this
            case. This ensure that Clients and Brokers are interacting with the correct case.
          </Body3>
          <Body3>Based on the Client's name, we found the following possible Client match:</Body3>
        </StackY>
        <CardLinkClientTable clientId={potentialClientToLink.id} featureToggles={featureToggles} />
        <div className={styles.footer}>
          <StackX dist={8}>
            {!isNotOnboardComplete(potentialClientToLink) && (
              <div className={styles.buttonComplete}>
                <Button
                  onClick={() => {
                    setClientToLink(potentialClientToLink);
                    setShowMergeModal(true);
                  }}
                >
                  Merge with this client
                </Button>
              </div>
            )}

            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCircleMinus} />}
              onClick={() => {
                setSearchText("");
                setClickedShowClientSearch(true);
                setClientToLink(null);
              }}
            >
              This is not the same client
            </Button>
          </StackX>
        </div>
      </div>
    );
  }
  const error = "";
  return (
    <>
      {showModalConfirmNoLinkedClient && modalConfirmNoLinkedClient()}
      <p>
        If this client used Onboard previously, please merge their previous instance with this case.
        This ensures that clients and Brokers are interacting with the correct case.
      </p>
      <p>Search for a previous instance of this Client:</p>
      <StackY dist={16}>
        <div className={styles.searchContainer}>
          <FormInput
            label="Search by name or case ID"
            name="searchText"
            value={searchText}
            onChange={(e) => onSearch({ text: e.target.value })}
            maxLength={50}
            disabled={false}
            touched={false}
            error={error}
            autoComplete="off"
            onFocus={() => setIsInputFocus(true)}
            onBlur={() => setIsInputFocus(false)}
            prefix={<SearchOutlined />}
          />
          {(search?.length || 0) >= 2 && (searchText?.length || 0) >= 2 && (
            <ClientSearchOptions
              clientId={client.id}
              isInputFocus={isInputFocus}
              search={search}
              onAssign={(client: Client) => {
                setClientToLink(client);
                setClickedShowClientSearch(false);
              }}
            />
          )}
        </div>
        <Button
          size="xtra-small"
          type="danger-tertiary"
          onClick={() => {
            setShowNoClientModal(true);
          }}
          disabled={isUpdatingLinkClient}
          loading={isUpdatingLinkClient}
        >
          This client has not used Onboard before
        </Button>
      </StackY>
    </>
  );
};

type ClientSearchOptionsProps = {
  onAssign: (client: Client) => void;
  isInputFocus: boolean;
  search: string;
  clientId: string;
};

const ClientSearchOptions = ({
  onAssign,
  isInputFocus,
  search,
  clientId,
}: ClientSearchOptionsProps) => {
  const { shouldHide, setShouldHide, wrapperRef } = useFocusControl();

  const {
    data: searchedClients,
    isFetching, // returns true during initial load and when refetching
    isFetched,
    isError,
  } = useGetClients({
    isOnboard: true,
    search,
    page: 1,
    pageSize: 5,
  });

  const clients = searchedClients?.data.filter((c) => c.id !== clientId) ?? [];

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (search) setShouldHide(false);
  }, [search, setShouldHide]);

  return (
    <div
      ref={wrapperRef}
      className={styles.searchFloatingContainer}
      style={{ display: shouldHide && !isInputFocus ? "none" : "" }}
    >
      {isFetching && !isError && <Body3>Searching...</Body3>}
      {!isFetching && isFetched && !clients.length && !isError && (
        <div className={styles.searchResultRow}>
          <Body3>No matching clients found</Body3>
        </div>
      )}
      {isError && (
        <Body3 redMedium as="p">
          Problem searching clients
        </Body3>
      )}
      {!isFetching &&
        isFetched &&
        clients.map((client: Client) => (
          <div className={styles.searchResultRow} key={client.id}>
            <button className={styles.button} onClick={() => onAssign(client)}>
              <Body3>
                <div className={styles.flex}>
                  <div className={styles.box}>Case ID {client.caseId}</div>
                  <div>{client.name}</div>
                </div>
              </Body3>
            </button>
          </div>
        ))}
    </div>
  );
};
