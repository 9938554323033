import { StackY } from "client/src/components/Spacing/Spacing";
import { FormInput } from "../../../components/Form/Input";

import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type {
  BenAdminCreateValidationSchema,
  BenAdminUpdateValidationSchema,
  BrokerCreateValidationSchema,
  BrokerUpdateValidationSchema,
} from "shared/validation/user";

type UserFormProps = {
  formik:
    | FormikTypeFromValidator<typeof BenAdminCreateValidationSchema>
    | FormikTypeFromValidator<typeof BrokerCreateValidationSchema>
    | FormikTypeFromValidator<typeof BenAdminUpdateValidationSchema>
    | FormikTypeFromValidator<typeof BrokerUpdateValidationSchema>;
};

export const UserForm = ({ formik }: UserFormProps) => {
  return (
    <StackY dist={16}>
      <FormInput
        label="First Name"
        name="firstName"
        value={formik.values.firstName}
        onChange={formik.handleChange}
        maxLength={191}
        onBlur={formik.handleBlur}
        touched={formik.touched.firstName}
        error={formik.errors.firstName}
        showRequired={true}
      />
      <FormInput
        label="Last Name"
        name="lastName"
        value={formik.values.lastName}
        onChange={formik.handleChange}
        maxLength={191}
        onBlur={formik.handleBlur}
        touched={formik.touched.lastName}
        error={formik.errors.lastName}
        showRequired={true}
      />
      <FormInput
        label="Email"
        name="email"
        value={formik.values.email}
        onChange={(e) => {
          formik.setStatus("");
          formik.handleChange(e);
        }}
        maxLength={191}
        onBlur={formik.handleBlur}
        touched={formik.touched.email}
        error={formik.errors.email}
        showRequired={true}
      />
    </StackY>
  );
};
