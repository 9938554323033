import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { useCallback } from "react";

import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { GenericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { ReactComponent as ListProgressCompleted } from "../../components/Icons/listProgressCompleted.svg";
import { StackX, StackY } from "../../components/Spacing/Spacing";

import * as styles from "./kickoffCallCard.module.less";

import type { ResponseError } from "../../hooks/query";
import type { ElementClickedOptions } from "../../utils/analytics";
import type { Client } from "shared/types/Client";

type KickoffCallCardProps = {
  client: Client;
  toggleEditing: () => void;
  handleNo: () => Promise<void>;
  isLoading: boolean;
  trackElementClicked: (options: ElementClickedOptions) => void;
  error: ResponseError | null;
  completedMessage: {
    yes: {
      heading: string;
      body: string;
    };
    no: {
      heading: string;
      body: string;
    };
  };
};
export const KickoffCallCard = ({
  client,
  toggleEditing,
  handleNo,
  isLoading,
  trackElementClicked,
  error,
  completedMessage,
}: KickoffCallCardProps) => {
  const {
    kickoffCallSelection,
    kickoffCallSchedule,
    kickoffCallTopics,
    kickoffCallPeople,
    kickoffCallUpdatedByUser,
    kickoffCallUpdatedAt,
  } = client;
  const requestACallClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Not Started",
      buttonLabel: "Request a Call",
    });
    toggleEditing();
  }, [toggleEditing, trackElementClicked]);
  const noThanksClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Not Started",
      buttonLabel: "No thanks, maybe another time",
    });
    return handleNo();
  }, [handleNo, trackElementClicked]);
  const nevermindClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Completed",
      buttonLabel: "Never mind, I'd like to request a call",
    });
    return toggleEditing();
  }, [toggleEditing, trackElementClicked]);
  const editClick = useCallback(() => {
    trackElementClicked({
      moduleState: "Completed",
      buttonLabel: "Edit",
    });
    return toggleEditing();
  }, [toggleEditing, trackElementClicked]);

  switch (kickoffCallSelection) {
    case null:
    case undefined: {
      return (
        <HubCard>
          <HubCardHeader
            title="Ready to schedule your kickoff call?"
            description="Provide your preferred dates and times, any specific topics you’d like to cover, and who you would like to include. Your Implementation Consultant will reach out to you to schedule."
            badge={<Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />}
            hasDivider
          />

          <StackY dist={16}>
            {error && <LoadingError type="component" error={error} title={GenericErrorCopy2} />}

            <StackX dist={8} className="mt-8">
              <Button type="primary" size="middle" onClick={requestACallClick} disabled={isLoading}>
                Request a Call
              </Button>
              <Button loading={isLoading} size="middle" type="text" onClick={noThanksClick}>
                No thanks, maybe another time
              </Button>
            </StackX>
          </StackY>
        </HubCard>
      );
    }
    case "NO": {
      return (
        <HubCard>
          <HubCardHeader
            title="Ready to schedule your kickoff call?"
            description="Provide your preferred dates and times, any specific topics you’d like to cover, and who you would like to include. Your Implementation Consultant will reach out to you to schedule."
            hasDivider
            badge={
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="completed"
                updatedAt={kickoffCallUpdatedAt}
                updatedBy={kickoffCallUpdatedByUser}
              />
            }
          ></HubCardHeader>
          <StackY dist={24}>
            <div className={clsx("body3", styles.completed)}>
              <ListProgressCompleted />
              {completedMessage.no.body}
            </div>
            <div>
              <Button size="middle" type="text-only" onClick={nevermindClick}>
                Never mind, I'd like to request a call
              </Button>
            </div>
          </StackY>
        </HubCard>
      );
    }
    case "YES": {
      return (
        <HubCard>
          <HubCardHeader
            title="Your Kickoff Call has been requested"
            hasDivider
            badge={
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="completed"
                message={completedMessage.yes.body}
                updatedAt={kickoffCallUpdatedAt}
                updatedBy={kickoffCallUpdatedByUser}
              />
            }
          />

          <StackY dist={32}>
            <StackY dist={16}>
              {[
                {
                  title: "Requested Date(s)",
                  data: kickoffCallSchedule?.trim() ? kickoffCallSchedule : "-",
                },
                {
                  title: "Requested Topic(s)",
                  data: kickoffCallTopics?.trim() ? kickoffCallTopics : "-",
                },
                {
                  title: "Requested By",
                  data: kickoffCallUpdatedByUser ? (
                    <>
                      {kickoffCallUpdatedByUser.firstName} {kickoffCallUpdatedByUser.lastName}{" "}
                      <br />
                      {kickoffCallUpdatedByUser.email}
                    </>
                  ) : (
                    "-"
                  ),
                },
                {
                  title: "Invited Guests",
                  data: kickoffCallPeople?.trim() ? kickoffCallPeople : "-",
                },
              ].map((d) => (
                <Row key={d.title} wrap={false}>
                  <Col flex="250px" className="body2">
                    {d.title}
                  </Col>

                  <Col flex="1" className="body3" style={{ wordBreak: "break-word" }}>
                    {d.data}
                  </Col>
                </Row>
              ))}
            </StackY>
            <Button size="middle" onClick={editClick}>
              Edit
            </Button>
          </StackY>
        </HubCard>
      );
    }
  }
};
