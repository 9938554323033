import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Body3 } from "client/src/components/Typography/Typography";
import pluralize from "pluralize";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import type { Client } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";

type Props = {
  relevantPlans: Plan[];
  showSuccess?: boolean;
} & (
  | {
      relevantSubStep: "claims-check-mailing-locations";
    }
  | {
      relevantSubStep: "monthly-claims-reports-and-eobs";
      client: Client;
    }
);

export function MissingMonthlyClaimsAlert(props: Props) {
  const { relevantPlans, showSuccess, relevantSubStep } = props;

  const relevantPlansThatWereSetup =
    relevantSubStep === "claims-check-mailing-locations"
      ? relevantPlans.filter((plan) => plan.sendClaimsCheckTo != null)
      : relevantPlans.filter((plan) => {
          const wasSetup = props.client.monthlyClaimsReportMailingLocations?.some((ml) =>
            ml.slfCoverages?.includes(benefitTypeToCoverage[plan.benefitType]),
          );
          return wasSetup;
        });

  const sectionCopy =
    relevantSubStep === "claims-check-mailing-locations"
      ? "a claims check mailing location"
      : "to a claims report and EOB mailing location";

  const missingBenefitsCount = relevantPlans.length - relevantPlansThatWereSetup.length;

  return (
    <>
      {missingBenefitsCount > 0 && (
        <AlertBanner
          variant="warning"
          message={
            <Body3>
              You have {missingBenefitsCount} {pluralize("benefit", missingBenefitsCount)} that{" "}
              {pluralize("need", missingBenefitsCount - 1 ? 1 : 2)} to be assigned {sectionCopy}.
            </Body3>
          }
        />
      )}

      {showSuccess && missingBenefitsCount === 0 && (
        <AlertBanner
          variant="success"
          message={
            relevantSubStep === "claims-check-mailing-locations" ? (
              <Body3>All benefits have been assigned a claims check mailing location.</Body3>
            ) : (
              <Body3>
                All benefits have been assigned to a mailing location. You can create additional
                mailing locations if you need a benefits claim report sent to more than one
                location.
              </Body3>
            )
          }
        />
      )}
    </>
  );
}
