import { Skeleton } from "antd";

import { Badge } from "client/src/components/Badge/Badge";
import { CollapsePanel } from "client/src/components/Collapse/CollapsePanel";
import { Loading } from "client/src/components/Loading/Loading";
import { H3 } from "client/src/components/Typography/Typography";
import { useClientSetup, useLogClientSetupStepStatus } from "client/src/hooks/client";
import { triggerError } from "client/src/hooks/generalError";
import { CardBenAdmins } from "client/src/pages/Clients/SetupCards/CardBenAdmins";
import { CardBrokers } from "client/src/pages/Clients/SetupCards/CardBrokers";
import { CardCensusTemplates } from "client/src/pages/Clients/SetupCards/CardCensusTemplates";
import { CardCompleteSetup } from "client/src/pages/Clients/SetupCards/CardCompleteSetup";
import { CardConfigureSubmitCompanyInformation } from "client/src/pages/Clients/SetupCards/CardConfigureSubmitCompanyInformation";
import { CardCustomEnrollmentResources } from "client/src/pages/Clients/SetupCards/CardCustomEnrollmentResources";
import { CardDocusignV2 } from "client/src/pages/Clients/SetupCards/CardDocusign";
import { CardJira } from "client/src/pages/Clients/SetupCards/CardJira";
import { CardLinkClient } from "client/src/pages/Clients/SetupCards/CardLinkClient";
import { CardPrefillCompanyInformation } from "client/src/pages/Clients/SetupCards/CardPrefillCompanyInformation";
import { CardWelcomeEmail } from "client/src/pages/Clients/SetupCards/CardWelcomeEmail";
import { useState, type ReactNode, useEffect, useCallback } from "react";
import { isAddCoverageClient } from "shared/utils/client";
import { rejectNullableValues } from "shared/utils/utils";
import {
  clientSetupStepID,
  type Client,
  type ClientSetupStepId,
} from "../../../../shared/types/Client";
import * as styles from "./clientDetail.module.less";
import type { Variant } from "client/src/components/Badge/Badge";
import type { ClientSetup as ClientSetupType, ClientSetupStatus } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type ClientSetupProps = {
  client: Client;
  featureToggles: ClientFeatureToggles;
};

type GridCard = {
  header: string;
  status: ClientSetupStatus;
  stepId: ClientSetupStepId;
  isLoadingStatus: boolean;
  content: ReactNode;
};

type CollapseGridProps = {
  activeKeys: ClientSetupStepId[];
  setActiveKeys: (activeKey: ClientSetupStepId[]) => void;
  cards: GridCard[];
  handlePanelChangeState: (stepIds: ClientSetupStepId[]) => void;
};

export const setupStatusToVariant: Record<NonNullable<ClientSetupStatus>, Variant> = {
  "Not Started": "info",
  "Needs Review": "warning",
  "In Progress": "info",
  "In Review": "warning",
  Done: "success",
};

export const setupStatusToLabel: Record<NonNullable<ClientSetupStatus>, string> = {
  "Not Started": "Not Started",
  "Needs Review": "Needs Review",
  "In Progress": "In Progress",
  "In Review": "In Review",
  Done: "Completed",
};

const getStepsControlStates = () => {
  return clientSetupStepID.reduce<Record<ClientSetupStepId, boolean>>((ac, step) => {
    return { ...ac, [step]: false };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  }, {} as Record<ClientSetupStepId, boolean>);
};

const CollapseGrid = ({
  activeKeys,
  setActiveKeys,
  handlePanelChangeState,
  cards,
}: CollapseGridProps) => {
  return (
    <CollapsePanel
      variant="default"
      activeKey={activeKeys}
      onChange={(stepIds) => {
        // These are the stepIds of all panels that are OPEN
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- can't type onChange event earlier
        const ids = stepIds as ClientSetupStepId[];
        handlePanelChangeState(ids);
        setActiveKeys(ids);
      }}
      content={cards.map((c, index) => {
        const variant = c.status ? setupStatusToVariant[c.status] : "info";
        const statusLabel = c.status ? setupStatusToLabel[c.status] : "Not Started";

        return {
          key: c.stepId,
          title: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {index + 1}. {c.header}
              </div>
              {c.isLoadingStatus ? (
                <Skeleton.Button active />
              ) : (
                <Badge
                  srOnlyLabel="Task Status"
                  variant={variant}
                  status={statusLabel}
                  showStaticSpinner={statusLabel === "In Progress"}
                />
              )}
            </div>
          ),
          description: c.content,
        };
      })}
    />
  );
};

type LogStepControl = {
  stepId: ClientSetupStepId;
  status: "COMPLETED" | "VIEWED";
};

const hasPendingLogStep = (
  stepId: ClientSetupStepId,
  status: "COMPLETED" | "VIEWED",
  pendingLogStep: LogStepControl[],
) => {
  return pendingLogStep.find((log) => log.stepId === stepId && log.status === status);
};

export const ClientSetup = ({ client, featureToggles }: ClientSetupProps) => {
  const [setupSteps, setSetupSteps] = useState<ClientSetupType>();
  const [pendingLogStep, setPendingLogStep] = useState<LogStepControl[]>([]);
  const [processingLogStep, setProcessingLogStep] = useState<LogStepControl[]>([]);
  const [activeKeys, setActiveKeys] = useState<ClientSetupStepId[]>([]);
  const [stepsLoadingStatus, setStepsLoadingStatus] = useState(getStepsControlStates());
  const [stepsLoadingContent, setStepsLoadingContent] = useState(getStepsControlStates());
  const [openCards, setOpenCards] = useState(getStepsControlStates());

  const {
    data: initialData,
    error,
    refetch,
    isLoading: isLoadingSetup,
  } = useClientSetup(client.id);
  const { mutateAsync: logSetupStepStatus } = useLogClientSetupStepStatus();

  const isAddCoverage: boolean = isAddCoverageClient(client);
  const usingDEIF = client.digitalEIF;

  const setLoadingContent = useCallback((stepId: ClientSetupStepId, state: boolean) => {
    setStepsLoadingContent((n) => ({ ...n, [stepId]: state }));
  }, []);

  const refetchData = useCallback(
    async (stepId?: ClientSetupStepId) => {
      if (stepId) setLoadingContent(stepId, true);

      const { data: newData, error } = await refetch();

      if (error) {
        return triggerError("Error fetching new data");
      }
      if (newData) setSetupSteps(newData);
      if (stepId) setLoadingContent(stepId, false);
    },
    [setSetupSteps, refetch, setLoadingContent],
  );

  const updateStepToViewedStatus = useCallback(
    async (processingItem: LogStepControl) => {
      setProcessingLogStep([...processingLogStep, { ...processingItem }]);

      await logSetupStepStatus({
        params: { clientId: client.id, stepId: processingItem.stepId },
        data: { stepStatus: "VIEWED" },
      });

      await refetchData();

      setProcessingLogStep((previous) =>
        previous.filter((l) =>
          l.stepId === processingItem.stepId ? l.status !== processingItem.status : true,
        ),
      );
      setPendingLogStep((previous) =>
        previous.filter((l) =>
          l.stepId === processingItem.stepId ? l.status !== processingItem.status : true,
        ),
      );
    },
    [processingLogStep, client.id, logSetupStepStatus, refetchData],
  );

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (initialData) setSetupSteps(initialData);
  }, [initialData]);

  // This works like a "queue" that process next pending log step (marking step as viewed)
  // This solution was created because AntD onChange event in Collapse panel is called in the parent returning all opened panels, always.
  // As we may have requests that are still in process when user clicks same card multiple times this will make sure
  // only one request is called (no duplicate calls)
  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    // get pending log that is not already processing
    const validLogs = pendingLogStep.filter(
      (p) => !processingLogStep.find((l) => l.status === p.status && l.stepId === p.stepId),
    );
    if (!validLogs.length || !validLogs[0]) return;
    const processingItem = validLogs[0];
    updateStepToViewedStatus(processingItem).catch(triggerError);
  }, [pendingLogStep, processingLogStep, updateStepToViewedStatus]);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    const loadingStates = getStepsControlStates();
    processingLogStep.forEach((l) => (loadingStates[l.stepId] = true));
    setStepsLoadingStatus(loadingStates);
  }, [processingLogStep]);

  if (error) {
    return triggerError(error);
  }

  if (isLoadingSetup || !setupSteps) {
    return <Loading />;
  }

  if (!initialData) {
    return triggerError("No Client Setup information found");
  }

  const handleCompletedSetupStep = async (stepId: ClientSetupStepId) => {
    setLoadingContent(stepId, true);
    await logSetupStepStatus({
      params: { clientId: client.id, stepId },
      data: { stepStatus: "COMPLETED" },
    });
    await refetchData("MERGE_CLIENT_INSTANCES");
    setLoadingContent(stepId, false);
  };

  const handleViewSetupStep = (stepIds: ClientSetupStepId[]) => {
    // We only care about this for add coverage clients
    if (!isAddCoverage) {
      return;
    }

    // We only care about this currently for user steps
    const VALID_STEPS = ["ADD_BROKER", "ADD_BEN_ADMIN"];

    const filteredSteps = stepIds.filter((step) => {
      if (!VALID_STEPS.includes(step)) return false;

      // If the link client step has not been started, we don't want to continue
      if (step === "ADD_BROKER" && setupSteps.mergeClient.status === "Not Started") return false;
      if (step === "ADD_BEN_ADMIN" && setupSteps.mergeClient.status === "Not Started") return false;

      // Conditions to exclude specific steps based on status.
      if (step === "ADD_BROKER" && setupSteps.broker.status === "Done") return false;
      if (step === "ADD_BEN_ADMIN" && setupSteps.benAdmin.status === "Done") return false;

      // if step is not already in "processing queue", set it to process next
      return !hasPendingLogStep(step, "VIEWED", pendingLogStep);
    });

    filteredSteps.forEach((step) => {
      if (openCards[step]) return;
      setPendingLogStep([...pendingLogStep, { stepId: step, status: "VIEWED" }]);
    });

    setOpenCards(() => {
      const newOpenCards = getStepsControlStates();
      stepIds.forEach((step) => (newOpenCards[step] = true));
      return newOpenCards;
    });
  };

  const cards: (GridCard | null)[] = [
    (isAddCoverage && {
      header: "Merge Client Instances",
      status: setupSteps.mergeClient.status,
      stepId: "MERGE_CLIENT_INSTANCES",
      isLoadingStatus: stepsLoadingStatus["MERGE_CLIENT_INSTANCES"],
      content: (
        <CardLinkClient
          client={client}
          linkClientStatus={setupSteps.mergeClient.status}
          handleCompletedStep={handleCompletedSetupStep}
          featureToggles={featureToggles}
        />
      ),
    }) ||
      null,
    {
      header: "Add Broker",
      status: setupSteps.broker.status,
      stepId: "ADD_BROKER",
      isLoadingStatus: stepsLoadingStatus["ADD_BROKER"],
      content: (
        <CardBrokers
          client={client}
          isLoading={stepsLoadingContent["ADD_BROKER"]}
          brokersStatus={setupSteps.broker.status}
          brokers={setupSteps.broker.data}
          refetchData={refetchData}
        />
      ),
    },
    {
      header: "Add Benefits Administrator",
      status: setupSteps.benAdmin.status,
      stepId: "ADD_BEN_ADMIN",
      isLoadingStatus: stepsLoadingStatus["ADD_BEN_ADMIN"],
      content: (
        <CardBenAdmins
          client={client}
          isLoading={stepsLoadingContent["ADD_BEN_ADMIN"]}
          benAdminsStatus={setupSteps.benAdmin.status}
          benAdmins={setupSteps.benAdmin.data}
          ONBOARD_BROKER_SIGN_DEIF={featureToggles.ONBOARD_BROKER_SIGN_DEIF ?? false}
          refetchData={refetchData}
        />
      ),
    },
    {
      header: featureToggles.ONBOARD_ASO_DENTAL ? "Task Settings" : "Add census template",
      status: setupSteps.censusTemplate.status,
      stepId: "ADD_CENSUS_TEMPLATE",
      isLoadingStatus: stepsLoadingStatus["ADD_CENSUS_TEMPLATE"],
      content: client && (
        <CardCensusTemplates
          client={client}
          isLoading={stepsLoadingContent["ADD_CENSUS_TEMPLATE"]}
          category={"census-template"}
          censusTemplatesStatus={setupSteps.censusTemplate.status}
          featureToggles={featureToggles}
        />
      ),
    },
    {
      header: "Add DocuSign envelope",
      status: setupSteps.docusignEnvelop.status,
      stepId: "ADD_DOCUSIGN",
      isLoadingStatus: stepsLoadingStatus["ADD_DOCUSIGN"],
      content: (
        <CardDocusignV2
          client={client}
          isFetchingOnboardingForms={false}
          isLoadingOnboardingForms={stepsLoadingContent["ADD_DOCUSIGN"]}
          onboardingForms={setupSteps.docusignEnvelop.data}
          refetchData={refetchData}
          status={setupSteps.docusignEnvelop.status}
        />
      ),
    },
    (usingDEIF && {
      header: "Configure Submit Company Information",
      status: setupSteps.configCompanyInfo.status,
      isLoadingStatus: stepsLoadingStatus["CONFIGURE_COMPANY"],
      stepId: "CONFIGURE_COMPANY",
      content: (
        <CardConfigureSubmitCompanyInformation
          client={client}
          plans={setupSteps.configCompanyInfo.data ?? []}
          benAdmins={setupSteps.benAdmin.data ?? []}
          brokers={setupSteps.broker.data ?? []}
          featureToggles={featureToggles}
          onSave={async () => {
            await refetchData("CONFIGURE_COMPANY");
            setActiveKeys((currentActiveKey) =>
              currentActiveKey.filter((activeKey) => activeKey !== "CONFIGURE_COMPANY"),
            );
          }}
        />
      ),
    }) ||
      null,
    (usingDEIF && {
      header: "Pre-fill Company Information",
      status: setupSteps.prefillCompanyInfo.status,
      isLoadingStatus: stepsLoadingStatus["PREFILL_COMPANY"],
      stepId: "PREFILL_COMPANY",
      content: (
        <CardPrefillCompanyInformation
          client={client}
          featureToggles={featureToggles}
          refetchData={refetchData}
        />
      ),
    }) ||
      null,
    {
      header: "Add enrollment resources",
      isLoadingStatus: stepsLoadingStatus["ADD_RESOURCES"],
      status: setupSteps.enrollmentResources.status,
      stepId: "ADD_RESOURCES",
      content: client && (
        <CardCustomEnrollmentResources
          client={client}
          isLoading={stepsLoadingContent["ADD_RESOURCES"]}
          customEnrollmentResourcessStatus={setupSteps.enrollmentResources.status}
        />
      ),
    },
    {
      header: "Update Jira ticket status",
      status: setupSteps.updateJiraTicket.status,
      isLoadingStatus: stepsLoadingStatus["UPDATE_JIRA"],
      stepId: "UPDATE_JIRA",
      content: <CardJira client={client} status={setupSteps.updateJiraTicket.status} />,
    },
    {
      header: "Send welcome email",
      status: setupSteps.welcomeEmail.status,
      stepId: "SEND_WELCOME_EMAIL",
      isLoadingStatus: stepsLoadingStatus["SEND_WELCOME_EMAIL"],
      content: (
        <CardWelcomeEmail
          client={client}
          isLoading={stepsLoadingContent["SEND_WELCOME_EMAIL"]}
          brokers={setupSteps.broker.data}
          benAdmins={setupSteps.benAdmin.data}
          welcomeEmailStatus={setupSteps.welcomeEmail.status}
          refetchData={refetchData}
        />
      ),
    },
    {
      header: "Confirm setup is complete",
      status: setupSteps.completeSetup.status,
      stepId: "CONFIRM_SETUP",
      isLoadingStatus: stepsLoadingStatus["CONFIRM_SETUP"],
      content: (
        <CardCompleteSetup
          client={client}
          status={setupSteps.completeSetup.status}
          featureToggles={featureToggles}
          docuSignEnvelopeData={setupSteps.docusignEnvelop.data}
          refetchData={refetchData}
        />
      ),
    },
  ];

  const handleCards = (cards: (GridCard | null)[]) => {
    const cardList = cards.filter(rejectNullableValues);
    if (isAddCoverage && setupSteps?.mergeClient.status !== "Done") {
      const updatedList = cardList
        .filter((c) => c.stepId !== "MERGE_CLIENT_INSTANCES")
        .map((card) => {
          return {
            header: card.header,
            status: card.status,
            stepId: card.stepId,
            isLoadingStatus: card.isLoadingStatus,
            content: <p>You must complete step 1 first.</p>,
          };
        });
      return [cardList.find((c) => c.stepId === "MERGE_CLIENT_INSTANCES"), ...updatedList].filter(
        rejectNullableValues,
      );
    }
    return cardList.filter(rejectNullableValues);
  };

  return (
    <div className={styles.clientSetup}>
      <H3 as="p">Steps required for Client setup</H3>
      <p>
        Complete the steps below to prepare the Client’s setup and welcome them to begin working in
        Onboard.
      </p>
      <CollapseGrid
        activeKeys={activeKeys}
        setActiveKeys={setActiveKeys}
        handlePanelChangeState={handleViewSetupStep}
        cards={handleCards(cards)}
      />
    </div>
  );
};
