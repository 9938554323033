import { faChevronLeft, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, H2 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { ClaimsLocationReviewCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/ClaimsLocationReviewCard";
import {
  EIFClaimsCheckMailingLocationSelection,
  claimsCheckMailingLocationValidationSchema,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFClaimsCheckMailingLocationSelection";
import { MissingMonthlyClaimsAlert } from "client/src/domain/EIF/PlanAdministratorsAndBilling/MissingMonthlyClaimsAlert";
import {
  ClaimSentToHomeAddressMsg,
  getClaimsCheckRelevantPlansAndChanges,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/claimsCheckMailingLocations";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { Fragment, useState } from "react";
import { Navigate } from "react-router-dom";
import { type Client } from "shared/types/Client";
import { getEIFSubStepMap } from "shared/types/EIF";
import {
  getClaimsCheckMailingLocationKey,
  getClientCoveragesThatAreAlwaysSentToEmployeesHome,
  type Plan,
} from "shared/types/Plan";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getClaimsCheckMailingLocationsInitialFormValues } from "shared/utils/plan";
import { assertIsDefined } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import * as Yup from "yup";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { CreateContactQuery } from "client/src/hooks/contact";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Contact } from "shared/types/Contact";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  featureToggles: ClientFeatureToggles;
  updateClientQuery: UpdateClientQuery;
  updatePlansQuery: UpdatePlansQuery;
  createContactQuery: CreateContactQuery;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
};

export const EIFClaimsCheckMailingLocations = (props: Props) => {
  const {
    client,
    clientPlans,
    contacts,
    featureToggles,
    updateClientQuery,
    updatePlansQuery,
    createContactQuery,
    changeSnapshot,
    authUser,
  } = props;

  const [editPreferences, setEditPreferences] = useState(false);
  const [confirmAcceptDefaultsVisible, toggleConfirmAcceptDefaultsVisible] = useToggler();
  const [editingType, setEditingType] = useState<string | null>(null);
  const navigate = useNavigateIfMounted();

  const {
    mutateAsync: updateClient,
    isPending: isUpdatingClient,
    error: updateClientError,
  } = updateClientQuery;

  const eifSubStepId = "claims-check-mailing-locations";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const clientCoveragesThatAreAlwaysSentToEmployeesHome =
    getClientCoveragesThatAreAlwaysSentToEmployeesHome(client);

  const { relevantPlans, changesForRelevantPlans } = getClaimsCheckRelevantPlansAndChanges(
    clientPlans,
    changeSnapshot,
  );

  const plansByCheckLocationKey = relevantPlans.reduce<Record<string, Plan[]>>((acc, plan) => {
    if (plan.sendClaimsCheckTo === null) {
      // plan does not have a claims check mailing location yet, so skip it
      return acc;
    }

    const locationKey = getClaimsCheckMailingLocationKey(plan);

    acc[locationKey] = (acc[locationKey] || []).concat(plan);
    return acc;
  }, {});

  const checkLocationKeys = Object.keys(plansByCheckLocationKey);

  const unassignedPlans = relevantPlans.filter((p) => p.sendClaimsCheckTo === null);

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      acceptedDefaults: Yup.boolean().nullable(),
    }),
    initialValues: {
      acceptedDefaults: client.claimsCheckPreferencesDefaults === "ACCEPTED_DEFAULTS",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.acceptedDefaults) {
        if (client.claimsCheckPreferencesDefaults !== "ACCEPTED_DEFAULTS") {
          await updateClient({
            params: { clientId: client.id },
            data: {
              claimsCheckPreferencesDefaults: "ACCEPTED_DEFAULTS",
            },
          });
          await updatePlansQuery.mutateAsync({
            params: { clientId: client.id },
            data: {
              plans: relevantPlans.map((plan) => {
                return {
                  id: plan.id,
                  clientId: client.id,
                  sendClaimsCheckTo: null,
                };
              }),
            },
          });
        }
        formik.resetForm();
      }
    },
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    plans: clientPlans,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const isClaimsCheckPlanSentToEEOnly =
    relevantPlans.length === 0 && clientCoveragesThatAreAlwaysSentToEmployeesHome.length > 0;

  if (isClaimsCheckPlanSentToEEOnly) {
    return (
      <>
        <StackY dist={16} wrap={false}>
          <H2>{subStepName}</H2>
          <HubCard>
            <StackY dist={16} wrap={false}>
              <ClaimSentToHomeAddressMsg
                client={client}
                clientPlans={clientPlans}
                variant="default"
              />

              <Body3 as="div">There is nothing further you need to do for this step.</Body3>

              {updateClientError && <ErrorMessage>{GenericErrorCopy2}</ErrorMessage>}
            </StackY>
          </HubCard>
          <Row justify="space-between" align="middle">
            <Col>
              {previousSubStepLink && (
                <Button
                  to={previousSubStepLink}
                  type="text-only"
                  size="middle"
                  disabled={isUpdatingClient || updatePlansQuery.isPending}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
                  Previous
                </Button>
              )}
            </Col>

            <Col>
              <Row align="middle" gutter={24}>
                <Col>
                  <Button
                    type="primary"
                    size="middle"
                    disabled={isUpdatingClient || updatePlansQuery.isPending}
                    onClick={async () => {
                      let allowNavigation = true;
                      if (!client.claimsCheckLTDOnlyStepViewed) {
                        const { isSuccess } = await updateClient({
                          params: {
                            clientId: client.id,
                          },
                          data: {
                            claimsCheckLTDOnlyStepViewed: true,
                          },
                        });
                        allowNavigation = isSuccess;
                      }
                      if (allowNavigation) {
                        navigate(nextSubStepLink);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </StackY>
      </>
    );
  }

  const notEditingAndNoSelectionsMade = !editPreferences && checkLocationKeys.length === 0;
  const editingALocationForFirstTime = editPreferences && !editingType;

  let tellUsMessage = "Tell us where claim checks should be sent for your salary based benefits.";
  if (relevantPlans.length === 1) {
    // check for a sparsely populated array
    assertIsDefined(relevantPlans[0], "relevantPlans[0]");
    tellUsMessage = `Tell us where claim checks should be sent for ${
      benefitTypeToCoverage[relevantPlans[0].benefitType]
    }.`;
  }

  const showTellUsMessage =
    // actively editing or have edited and created 1 or more locations
    editPreferences ||
    (client.claimsCheckPreferencesDefaults === "EDITED_DEFAULTS" &&
      Object.keys(plansByCheckLocationKey).length > 0);

  const status = getEIFSubStepStatus({
    client,
    eifSubStepId: "claims-check-mailing-locations",
    featureToggles,
    clientPlans: relevantPlans,
    contacts,
  });
  const isSubStepStarted = status !== "Not Started";

  return (
    <>
      <StackY dist={16} wrap={false}>
        <H2>{subStepName}</H2>
        {showTellUsMessage && <Body3 as="p">{tellUsMessage}</Body3>}
        <HubCard disabled={formik.isSubmitting || updatePlansQuery.isPending}>
          <StackY dist={16}>
            {notEditingAndNoSelectionsMade ? (
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <StackY dist={16}>
                    <p>Claim checks are sent directly to the employee’s home address by default.</p>

                    <p>
                      Accept the default settings to continue or change your settings by editing
                      preferences below.
                    </p>

                    <Body2>This applies to:</Body2>
                    <UnorderedList markerColor="gray">
                      {relevantPlans.map((plan, index) => (
                        <UnorderedListItem key={index}>
                          {benefitTypeToCoverage[plan.benefitType]}
                        </UnorderedListItem>
                      ))}
                    </UnorderedList>

                    <ClaimSentToHomeAddressMsg
                      client={client}
                      clientPlans={clientPlans}
                      variant="default"
                    />

                    {client.claimsCheckPreferencesDefaults === "ACCEPTED_DEFAULTS" ? (
                      <AlertBanner
                        variant="success"
                        message={
                          <>
                            <Body3>
                              You have accepted the default. Checks will be sent to the employee’s
                              home address.{" "}
                              <Button
                                type="text-only"
                                size="middle"
                                onClick={async () => {
                                  await formik.setFieldValue("acceptedDefaults", false);
                                  setEditPreferences(true);
                                }}
                                disabled={formik.isSubmitting}
                              >
                                Edit preferences
                              </Button>
                            </Body3>
                          </>
                        }
                      />
                    ) : (
                      <Row justify="end">
                        <Col>
                          <Row align="middle" gutter={24}>
                            <Col>
                              <Button
                                type="text-only"
                                size="middle"
                                onClick={async () => {
                                  await formik.setFieldValue("acceptedDefaults", false);
                                  setEditPreferences(true);
                                }}
                                disabled={formik.isSubmitting}
                              >
                                Edit preferences
                              </Button>
                            </Col>

                            <Col>
                              <Button
                                type="secondary"
                                size="middle"
                                disabled={formik.isSubmitting}
                                onClick={async () => {
                                  await formik.setFieldValue("acceptedDefaults", true);
                                  await formik.submitForm();
                                }}
                              >
                                Accept default
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </StackY>
                </div>
              </form>
            ) : (
              <>
                {Object.entries(plansByCheckLocationKey).map(
                  (plansByCheckLocationKeyEntry, index) => {
                    const plansForCheckLocation = plansByCheckLocationKeyEntry[1];
                    const claimsCheckLocationKey = plansByCheckLocationKeyEntry[0];
                    const initialFormValuesForThisClaimsCheckMailingLocation =
                      getClaimsCheckMailingLocationsInitialFormValues(
                        plansForCheckLocation,
                        contacts,
                      )[0];
                    assertIsDefined(
                      initialFormValuesForThisClaimsCheckMailingLocation,
                      "initialFormValuesForThisClaimsCheckMailingLocation",
                    );

                    const claimsCheckPrefillErrors =
                      isSubStepStarted && !formik.isSubmitting
                        ? getFormikErrors(
                            initialFormValuesForThisClaimsCheckMailingLocation,
                            claimsCheckMailingLocationValidationSchema.omit([
                              "selectedBenefitTypes",
                            ]),
                            {
                              prefill: false,
                            },
                          )
                        : {};

                    if (editingType === claimsCheckLocationKey) {
                      return (
                        <Fragment key={index}>
                          <EIFClaimsCheckMailingLocationSelection
                            client={client}
                            contacts={contacts}
                            relevantPlans={relevantPlans}
                            initialPlans={plansForCheckLocation}
                            updateClient={updateClient}
                            updatePlansQuery={updatePlansQuery}
                            createContactQuery={createContactQuery}
                            editingType={editingType}
                            setEditPreferences={setEditPreferences}
                            setEditingType={setEditingType}
                            index={index}
                            authUser={authUser}
                            changeSnapshot={changeSnapshot}
                            clientPlans={clientPlans}
                            featureToggles={featureToggles}
                            claimsCheckPrefillErrors={claimsCheckPrefillErrors}
                          />
                        </Fragment>
                      );
                    }

                    return (
                      <div key={index} data-testid={`ClaimsLocationReviewCard__${index + 1}`}>
                        <ClaimsLocationReviewCard
                          index={index + 1}
                          client={client}
                          plans={plansForCheckLocation}
                          featureToggles={featureToggles}
                          onEdit={() => {
                            setEditingType(claimsCheckLocationKey);
                            setEditPreferences(true);
                          }}
                          onDelete={async () => {
                            const plansToUpdate = plansForCheckLocation.map((plan) => ({
                              id: plan.id,
                              clientId: plan.clientId,
                              sendClaimsCheckTo: null,
                              claimsCheckPayee: null,
                              planAdminPayeeContactId: null,
                              someoneElsePayeeContactId: null,
                            }));
                            await updatePlansQuery.mutateAsync({
                              params: { clientId: client.id },
                              data: { plans: plansToUpdate },
                            });
                          }}
                          disabled={editPreferences || updatePlansQuery.isPending}
                          contacts={contacts}
                          claimsCheckPrefillErrors={claimsCheckPrefillErrors}
                          changeSnapshot={changeSnapshot}
                        />
                      </div>
                    );
                  },
                )}
                {!editPreferences && (
                  <ClaimSentToHomeAddressMsg
                    client={client}
                    clientPlans={clientPlans}
                    variant="default"
                  />
                )}
              </>
            )}
            {editingALocationForFirstTime && (
              <EIFClaimsCheckMailingLocationSelection
                client={client}
                contacts={contacts}
                relevantPlans={relevantPlans}
                initialPlans={[]}
                updateClient={updateClient}
                updatePlansQuery={updatePlansQuery}
                createContactQuery={createContactQuery}
                setEditPreferences={setEditPreferences}
                setEditingType={setEditingType}
                index={checkLocationKeys.length}
                authUser={authUser}
                changeSnapshot={changeSnapshot}
                clientPlans={clientPlans}
                featureToggles={featureToggles}
                claimsCheckPrefillErrors={{}}
              />
            )}
            <EditedFieldMsg
              changeDetailInfoList={changesForRelevantPlans}
              client={client}
              authUser={authUser}
            />
          </StackY>
        </HubCard>
        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>

        <StackY dist={16}>
          {checkLocationKeys.length > 0 && (
            <MissingMonthlyClaimsAlert
              relevantPlans={relevantPlans}
              showSuccess
              relevantSubStep="claims-check-mailing-locations"
            />
          )}
          {unassignedPlans.length !== 0 &&
            !editPreferences &&
            client.claimsCheckPreferencesDefaults !== "ACCEPTED_DEFAULTS" && (
              <Button
                type="text-only"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => {
                  setEditPreferences(true);
                  setEditingType(null);
                }}
                size="middle"
                disabled={formik.isSubmitting || updatePlansQuery.isPending}
              >
                Create another mailing location
              </Button>
            )}
        </StackY>

        <Row justify="space-between" align="middle">
          <Col>
            {previousSubStepLink && (
              <Button
                to={previousSubStepLink}
                type="text-only"
                size="middle"
                disabled={formik.isSubmitting || updatePlansQuery.isPending}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
                Previous
              </Button>
            )}
          </Col>

          <Col>
            <Row align="middle" gutter={24}>
              {client.claimsCheckPreferencesDefaults === "EDITED_DEFAULTS" ||
                (editPreferences && (
                  <Col>
                    <Button
                      type="text-only"
                      size="middle"
                      disabled={formik.isSubmitting || updatePlansQuery.isPending}
                      onClick={toggleConfirmAcceptDefaultsVisible}
                    >
                      Accept default
                    </Button>
                  </Col>
                ))}
              <Col>
                <Button
                  to={nextSubStepLink}
                  type="primary"
                  size="middle"
                  disabled={
                    formik.isSubmitting ||
                    (unassignedPlans.length > 0 &&
                      (client.claimsCheckPreferencesDefaults === "EDITED_DEFAULTS" ||
                        formik.values.acceptedDefaults === false)) ||
                    updatePlansQuery.isPending
                  }
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </StackY>
      <ConfirmDialog
        isVisible={confirmAcceptDefaultsVisible}
        isLoading={formik.isSubmitting}
        title="Reset preferences to default"
        confirmActionText="Accept default"
        onConfirm={async () => {
          setEditPreferences(false);
          setEditingType(null);
          await formik.setFieldValue("acceptedDefaults", true);
          await formik.submitForm();
          toggleConfirmAcceptDefaultsVisible();
        }}
        onCancel={toggleConfirmAcceptDefaultsVisible}
      >
        <p>Claim checks are sent directly to the employee’s home address by default.</p>

        <p>If you accept the default, any preference changes you have made will be lost.</p>
      </ConfirmDialog>
    </>
  );
};
