import type { EIFSubStepId } from "shared/types/EIF";

export const shouldShowDivider = <T extends EIFSubStepId>(
  subStep: T,
  isLastSubStep: Record<T, boolean>,
  isLastStep: boolean,
): boolean => {
  const showDivider = isLastStep ? !isLastSubStep[subStep] : true;

  return showDivider;
};
