import { Row, Col } from "client/src/components/Grid/Grid";

import { assertIsDefined } from "shared/utils/utils";
import { Body1, H2 } from "../../../../components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "../../../../components/UnorderedList/UnorderedList";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey } from "../../utils";

import type { LocationStateCode } from "../../../../../../shared/types/Location";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ContentFlag } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  WhyNeedIt: "why-need-it",
};

export type ExplorerBenefitWhyNeedItCopyStructure = {
  [benefitType in Exclude<BenefitTypeBenEx, "OTHER" | "FINANCIAL">]: {
    description: string;
    "AD&D"?: string;
    bulletPoints?: string[];
    bulletPoints_DC?: string[];
    bulletPoints_NY?: string[];
    bulletPoints_NJ?: string[];
    bulletPoints_ID?: string[];
  };
} & { header: string };

type ExplorerBenefitWhyNeedItProps = {
  benefitType: BenefitTypeBenEx;
  alternateName: string;
  employerState: LocationStateCode;
  contentFlags?: ContentFlag[];
};

export const ExplorerBenefitWhyNeedIt = ({
  benefitType,
  alternateName,
  contentFlags = [],
  employerState,
}: ExplorerBenefitWhyNeedItProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitWhyNeedIt" });

  if (benefitType === "FINANCIAL" || benefitType === "OTHER") return null;

  const bulletPoints: string[] = t(copyKey(benefitType, "bulletPoints"), {
    returnObjects: true,
    defaultValue: [],
    context: employerState,
  });

  const chunkedPoints = bulletPoints.reduce<string[][]>((acc, val, i) => {
    if (i % 2 === 0) {
      acc.push([]);
    }

    const arrayAt = acc[acc.length - 1];
    assertIsDefined(arrayAt, "arrayAt");
    arrayAt.push(val);
    return acc;
  }, []);

  const includesADD = contentFlags.includes("AD&D");

  return (
    <div data-testid={DataTestId.WhyNeedIt}>
      <H2>{t("header")}</H2>
      <Body1>
        <Trans
          t={t}
          i18nKey={copyKey(benefitType, "description")}
          values={{
            alternateName: `${alternateName}`,
            add: includesADD ? t(copyKey(benefitType, "AD&D")) : undefined,
          }}
        ></Trans>
      </Body1>
      {!!chunkedPoints.length && (
        <Row gutter={24} className="mt-16">
          {chunkedPoints.map((chunk, c) => (
            <Col key={String(c)}>
              <UnorderedList>
                {chunk.map((point, p) => (
                  <UnorderedListItem key={`${point}-${p}`}>{point}</UnorderedListItem>
                ))}
              </UnorderedList>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};
