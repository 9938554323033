import { faPhone, faCommentSms } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Body3 } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import { useState } from "react";
import { Anchor } from "../../components/Anchor/Anchor";
import { Button } from "../../components/Button/Button";
import { FormInput } from "../../components/Form/Input";
import { RadioGroup } from "../../components/Form/RadioGroup";

import { StackY } from "../../components/Spacing/Spacing";
import * as styles from "./login.module.less";
import type { Factor } from "../../../src/auth/oktaAuth";
import type { RadioChangeEvent } from "antd";

const PLATFORM_DATA = {
  GOOGLE: {
    APPLE: "https://itunes.apple.com/us/app/google-authenticator/id388497605",
    ANDROID: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  },
  STORE: {
    APPLE: "App Store",
    ANDROID: "Google Play Store",
  },
};

const factorTypeToName: Record<Factor["factorType"], string> = {
  call: "Voice Call Authentication",
  sms: "SMS Authentication",
  "token:software:totp": "Google Authenticator",
};

const getFactorIcon = (factorType: Factor["factorType"]): React.ReactNode => {
  switch (factorType) {
    case "token:software:totp":
      return (
        <img
          width="70"
          className={styles.appLogo}
          src="/GoogleAuth_70x70.png"
          alt="Google Authenticator Icon"
        />
      );
    case "call":
      return (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faPhone} />
        </div>
      );
    case "sms":
      return (
        <div className={styles.iconContainer}>
          <FontAwesomeIcon icon={faCommentSms} />
        </div>
      );
  }
};

export const GoogleAuthenticatorHelper = ({
  next,
  back,
}: {
  next: () => void;
  back: () => void;
}) => {
  const [platform, setPlatform] = useState<undefined | "APPLE" | "ANDROID">(undefined);

  const onChange = (e: RadioChangeEvent) => {
    setPlatform(e.target.value);
  };

  return (
    <div className={styles.loginWindow}>
      <StackY dist={24}>
        <h2>Setup Google Authenticator</h2>
        <RadioGroup
          name="download"
          label=""
          onChange={onChange}
          disabled={false}
          direction="vertical"
          error=""
          value={platform}
          options={[
            { value: "APPLE", label: "iPhone" },
            { value: "ANDROID", label: "Android" },
          ]}
        />
        {platform && (
          <div className={styles.appDownloadContainer}>
            <img
              width="70"
              className={styles.appLogo}
              src="/GoogleAuth_70x70.png"
              alt="Google Authenticator Icon"
            />
            <div className="ml-12">
              Download{" "}
              <Anchor href={PLATFORM_DATA.GOOGLE[platform]} target="_blank" newTabIcon>
                Google Authenticator from {PLATFORM_DATA.STORE[platform]}
              </Anchor>{" "}
              onto your mobile device.
            </div>
          </div>
        )}
        <Button type="primary" disabled={!platform} size="middle" block onClick={next}>
          Next
        </Button>
        <div className={styles.backLink}>
          <Button type="text-only" onClick={back}>
            Back to Sign In
          </Button>
        </div>
      </StackY>
    </div>
  );
};

export const FactorEnrollmentSelection = ({
  next,
  back,
  MFAFactorsToEnroll,
  onFactorSelect,
  isLoading,
}: {
  next: (factorType: Factor["factorType"]) => void;
  back: () => void;
  MFAFactorsToEnroll: Factor[] | undefined;
  onFactorSelect: (factorType: Factor["factorType"]) => void;
  isLoading: boolean;
  processError?: React.ReactNode;
}) => {
  const [factorType, setFactorType] = useState<undefined | Factor["factorType"]>(undefined);

  const onChange = (e: RadioChangeEvent) => {
    setFactorType(e.target.value);
    onFactorSelect(e.target.value);
  };

  return (
    <div className={clsx(styles.loginWindow, styles.enrollSelection)}>
      <StackY dist={24}>
        <h2>Setup multifactor authentication</h2>
        <div>
          <RadioGroup
            name="enroll"
            label=""
            onChange={onChange}
            disabled={false}
            direction="vertical"
            error=""
            value={factorType}
            options={
              MFAFactorsToEnroll?.map((factor) => ({
                value: factor.factorType,
                label: (
                  <div className={styles.enrollFactor}>
                    <span>{factorTypeToName[factor.factorType]}</span>
                    {getFactorIcon(factor.factorType)}
                  </div>
                ),
              })) || []
            }
          />
        </div>
        <Button
          type="primary"
          size="middle"
          disabled={!factorType}
          loading={isLoading}
          block
          onClick={() => factorType && next(factorType)}
        >
          Next
        </Button>
        <div className={styles.backLink}>
          <Button type="text-only" onClick={back}>
            Back to Sign In
          </Button>
        </div>
      </StackY>
    </div>
  );
};

export const FactorPhoneSetup = ({
  next,
  back,
  isLoading,
}: {
  next: (phoneNumber: string) => void;
  back: () => void;
  isLoading: boolean;
  processError?: React.ReactNode;
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <div className={clsx(styles.loginWindow)}>
      <StackY dist={24}>
        <h2>Setup phone authentication</h2>
        <Body3 as="p">
          Tell us the phone number you'd like to use to receive your text messages to authenticate.
          <br />
          <br />
          You'll receive a text message with a one-time passcode each time you sign in to Onboard.
        </Body3>
        <div>
          <FormInput
            value={phoneNumber}
            maxLength={22}
            label={"Phone Number"}
            name={"phonenumber"}
            disabled={isLoading}
            onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9+-\s]/g, ""))}
            autoComplete="Phone"
            touched={undefined}
            error={undefined}
          />
        </div>
        <Button
          type="primary"
          size="middle"
          disabled={!phoneNumber}
          loading={isLoading}
          block
          onClick={() => {
            next(phoneNumber);
          }}
        >
          Next
        </Button>
        <div className={styles.backLink}>
          <Button type="text-only" onClick={back}>
            Back to Sign In
          </Button>
        </div>
      </StackY>
    </div>
  );
};
