import { BillDetailsInputs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillDetailsInputs";
import { CopySettingsFromAnotherBillModal } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/CopySettingsFromAnotherBillModal";
import {
  getBillsFromBillGroup,
  getBillGroupFormValuesFromBills,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { type AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getBillTuples, getIsAutogeneratedASODentalBill } from "shared/utils/bill";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import { billDetailsSpec } from "shared/validation/bill";
import * as Yup from "yup";
import type { BillSplitType, BillingAdministrationType } from ".prisma/client";
import type { useFormik } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BillingPreferencesFormValues } from "shared/validation/bill";

const validationSchema = Yup.object({
  policyId: billDetailsSpec.policyId,

  advanceOrArrears: billDetailsSpec.advanceOrArrears,

  billingAdministrationType: billDetailsSpec.billingAdministrationType,
  billingStructureType: billDetailsSpec.billingStructureType,
  billSplitType: billDetailsSpec.billSplitType,

  billName: billDetailsSpec.billName,

  groupByLocationIds: billDetailsSpec.groupByLocationIds,
  splitTags: billDetailsSpec.splitTags,
  slfCoverages: billDetailsSpec.slfCoverages,
  contactId: billDetailsSpec.contactId,
  hasDifferentMailingAddress: billDetailsSpec.hasDifferentMailingAddress,
  locationId: billDetailsSpec.locationId,
  numberOfEmployees: billDetailsSpec.numberOfEmployees,

  categorizeEmployees: billDetailsSpec.categorizeEmployees,
  employeeCategorizationType: billDetailsSpec.employeeCategorizationType,
  categorizeByLocationIds: billDetailsSpec.categorizeByLocationIds,
  categoriesByTags: billDetailsSpec.categoriesByTags,

  categorizeEmployees_secondary: billDetailsSpec.categorizeEmployees_secondary,
  employeeCategorizationType_secondary: billDetailsSpec.employeeCategorizationType_secondary,
  categorizeByLocationIds_secondary: billDetailsSpec.categorizeByLocationIds_secondary,
  categoriesByTags_secondary: billDetailsSpec.categoriesByTags_secondary,
});

type BillNoSplitFormProps = {
  client: Client;
  policy: Policy;
  locations: Location[];
  admins: Contact[];
  bills: Bill[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billPreviews: BillPreview[];
  formik: ReturnType<typeof useFormik<BillingPreferencesFormValues>>;
  isSubStepStarted: boolean;
  billSplitType: BillSplitType | null;
  billingAdministrationType: BillingAdministrationType;
  disabled: boolean;
  copyModalIsOpen: boolean;
  toggleCopyModalIsOpen: () => void;
  featureToggles: ClientFeatureToggles;
};

export type BillNoSplitFormRef = { submitForm: () => Promise<void> };

export const BillNoSplitForm = forwardRef<BillNoSplitFormRef, BillNoSplitFormProps>(
  (props, ref) => {
    const {
      client,
      policy,
      locations,
      admins,
      bills,
      changeSnapshot,
      authUser,
      billPreviews: billPreviews_,
      formik,
      isSubStepStarted,
      billSplitType,
      billingAdministrationType,
      disabled,
      copyModalIsOpen,
      toggleCopyModalIsOpen,
      featureToggles,
    } = props;

    // Put autogenerated bills at the end
    const billPreviews = billPreviews_.slice().sort((a, b) => {
      const aIsAutogeneratedASODental = getIsAutogeneratedASODentalBill(a);
      const bIsAutogeneratedASODental = getIsAutogeneratedASODentalBill(b);

      if (aIsAutogeneratedASODental && !bIsAutogeneratedASODental) {
        return 1;
      }

      if (!aIsAutogeneratedASODental && bIsAutogeneratedASODental) {
        return -1;
      }

      return 0;
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    const [bill1, bill2] = getBillTuples(billSplitType, billPreviews.slice(0, 2)).flat() as [
      BillPreview | undefined,
      BillPreview | undefined,
    ];

    const billGroup = getBillGroupFormValuesFromBills(bill1, bill2);

    const temporaryFormik = useSlobFormik({
      validationSchema,
      validationContext: {
        client,
        policy,
        featureToggles,
        prefill: true,
      },
      initialValues: {
        policyId: policy.id,
        advanceOrArrears: formik.values.advanceOrArrears,
        billingAdministrationType,
        billingStructureType: formik.values.billingStructureType,
        billSplitType,

        billName: bill1?.billName ?? null,

        ...billGroup,
      },
      onSubmit: () => {
        // no op
      },
    });

    useImperativeHandle(ref, () => ({ submitForm: temporaryFormik.submitForm }));

    useSyncFormikValues({
      formik,
      temporaryFormik,
      client,
      policy,
      featureToggles,
      billSplitType,
      billPreviews,
      advanceOrArrears: formik.values.advanceOrArrears,
    });

    const billIds = [bill1?.id, bill2?.id].filter(rejectNullableValues);

    const prefillErrors =
      isSubStepStarted && !(formik.isSubmitting || disabled)
        ? getFormikErrors(temporaryFormik.values, validationSchema, {
            client,
            policy,
            featureToggles,
            prefill: false,
          })
        : {};

    const copyModal = (
      <CopySettingsFromAnotherBillModal
        open={copyModalIsOpen}
        onCancel={toggleCopyModalIsOpen}
        client={client}
        policy={policy}
        bills={bills}
        admins={admins}
        billGroupInputs={temporaryFormik.values}
        billingPreferencesValues={temporaryFormik.values}
        onCopy={async (copiedValues) => {
          await temporaryFormik.setValues({ ...temporaryFormik.values, ...copiedValues });
          toggleCopyModalIsOpen();
        }}
      />
    );

    if (billingAdministrationType === "LIST" && billSplitType === "NONE") {
      return (
        <>
          {copyModal}

          <BillDetailsInputs
            client={client}
            policy={policy}
            featureToggles={featureToggles}
            locations={locations}
            admins={admins}
            formik={temporaryFormik}
            prefillErrors={prefillErrors}
            disabled={formik.isSubmitting || disabled}
            billSplitType={billSplitType}
            billingAdministrationType={billingAdministrationType}
            advanceOrArrears={formik.values.advanceOrArrears}
            billIds={billIds}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
          />
        </>
      );
    }

    if (
      (billingAdministrationType === "SELF" || billingAdministrationType === "TPA") &&
      billSplitType == null
    ) {
      assertIsDefined(formik.values.billingStructureType, "formik.values.billingStructureType");
      return (
        <>
          {copyModal}

          <BillDetailsInputs
            client={client}
            policy={policy}
            featureToggles={featureToggles}
            locations={locations}
            admins={admins}
            formik={temporaryFormik}
            prefillErrors={prefillErrors}
            disabled={formik.isSubmitting || disabled}
            billSplitType={billSplitType}
            billingAdministrationType={billingAdministrationType}
            billingStructureType={formik.values.billingStructureType}
            billIds={billIds}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
          />
        </>
      );
    }

    return null;
  },
);

function useSyncFormikValues(args: {
  formik: ReturnType<typeof useFormik<BillingPreferencesFormValues>>;
  temporaryFormik: ReturnType<typeof useFormik<ValuesForValidationSchema<typeof validationSchema>>>;
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  billSplitType: BillSplitType | null;
  billPreviews: BillPreview[];
  advanceOrArrears: AdvanceOrArrearsSelection;
}) {
  const {
    formik,
    temporaryFormik,
    client,
    policy,
    featureToggles,
    billSplitType,
    billPreviews,
    advanceOrArrears,
  } = args;

  const { setFieldValue } = formik;

  const [[existingBill1, existingBill2, autogeneratedBill]] = useState([
    billPreviews[0],
    billPreviews[1],
    billPreviews[2],
  ]);

  useEffect(() => {
    const [bill1, bill2] = getBillsFromBillGroup({
      billGroup: {
        ...temporaryFormik.values,
        billingAdministrationType: temporaryFormik.values.billingAdministrationType,
        billingStructureType: temporaryFormik.values.billingStructureType,
        billSplitType: temporaryFormik.values.billSplitType,
      },
      policy,
      billSplitType,
      advanceOrArrears,
      existingBill1,
      existingBill2,
    });

    const nextBills = bill1 && bill2 ? [bill1, bill2] : [bill1];

    const asoDentalAdvanceBill = nextBills.find(
      (bill) => bill.billTiming === "Advance" && bill.slfCoverages?.includes("ASO Dental"),
    );
    if (asoDentalAdvanceBill) {
      const asoDentalArrearsBill = {
        ...asoDentalAdvanceBill,
        id: autogeneratedBill?.id ?? null,
        policyId: policy.id,
        billName: "Dental ASO Claims Reimbursement",
        billTiming: "Arrears" as const,
        slfCoverages: ["ASO Dental" as const],
      };
      nextBills.push(asoDentalArrearsBill);
    }

    if (temporaryFormik.dirty || nextBills.length !== billPreviews.length) {
      void setFieldValue("bills", nextBills);
    }
  }, [
    advanceOrArrears,
    billSplitType,
    client,
    policy,
    featureToggles,
    existingBill1,
    existingBill2,
    autogeneratedBill,
    setFieldValue,
    temporaryFormik.values,
    temporaryFormik.dirty,
    billPreviews.length,
  ]);
}
