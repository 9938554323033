import React from "react";

import { Loading } from "../../components/Loading/Loading";
import { useDSEmitEventMessage } from "../../hooks/docusign";
import { triggerError } from "../../hooks/generalError";

export const SignedPage = () => {
  const { isPending, isError, error } = useDSEmitEventMessage();

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return triggerError(error);
  }

  return (
    <div>
      <p>Thank you!</p>
      <p>Moving back to the Benefits Forms page</p>
    </div>
  );
};
