import { Anchor } from "client/src/components/Anchor/Anchor";
import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { DownloadIcon } from "client/src/components/Icons/DownloadIcon";
import { Loading } from "client/src/components/Loading/Loading";
import { OrderedList, OrderedListItem } from "client/src/components/OrderedList/OrderedList";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H5 } from "client/src/components/Typography/Typography";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { AddFilesCard } from "client/src/domain/Document/AddFilesCard/AddFilesCard";
import { DocumentUpload } from "client/src/domain/Document/DocumentUpload/DocumentUpload";
import { StoredSecurelyText } from "client/src/domain/Document/StoredSecurelyText";
import { useToggler } from "client/src/hooks/useToggler";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import moment from "moment";
import { useState } from "react";
import { yesNoNullableRequired } from "shared/validation/validation";
import * as Yup from "yup";
import * as styles from "./ActivelyAtWorkCard.module.less";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { ElementClickedOptions } from "client/src/utils/analytics";
import type { Client } from "shared/types/Client";
import type { CensusDocument } from "shared/types/Document";

type ActivelyAtWorkCardProps = {
  client: Client;
  trackElementClicked: ({ buttonLabel, module, moduleState }: ElementClickedOptions) => void;
  documents?: CensusDocument[];
  isFetchingDocuments: boolean;
  updateClient: UpdateClientFunc;
};

export const ActivelyAtWorkCard = ({
  client,
  trackElementClicked,
  documents,
  isFetchingDocuments,
  updateClient,
}: ActivelyAtWorkCardProps) => {
  const documentCategory = "actively-at-work";
  const [activelyAtWorkEditable, toggleActivelyAtWorkEditable] = useToggler();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const track = (buttonLabel: string) => {
    trackElementClicked({
      buttonLabel,
      module: "Actively at Work",
    });
  };

  const fileMissingError = "Please upload the list of employees not actively at work";
  const validationSchema = Yup.object({
    allEmployeesActivelyAtWork: yesNoNullableRequired.test(
      "fileRequiredForNoResponse",
      fileMissingError,
      (v) => {
        const isMissingDocumentWhenRequired = documents && documents.length === 0 && v === "NO";
        const isValid = !isMissingDocumentWhenRequired;
        return isValid;
      },
    ),
  });

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(["allEmployeesActivelyAtWork"]),
    updateClient,
    validationSchema,
    type: "Actively at Work",
    track,
    onSuccessCallback: () => toggleActivelyAtWorkEditable(),
  });

  const getTitleBodyContent = (client: Client) => {
    if (formik.isSubmitting) {
      return <Loading pageHeight={false} />;
    }

    if (client.allEmployeesActivelyAtWork === null) {
      return (
        <>
          <p>
            Let us know if there are employees covered by your plan who will not be actively at work
            on your benefits effective date.
          </p>

          <p>
            Employees not actively at work may be eligible to continue coverage under Sun Life's{" "}
            <Anchor
              href="https://p1.aprimocdn.net/sunlife/db394d0b-db87-4f4a-b44b-afcc010babbb/glho-7522-life-and-ad%26d-continuity-of-coverage-original-file.pdf"
              target="_blank"
            >
              Continuity of Coverage provision
            </Anchor>
            . Identifying these employees can help avoid an unnecessary loss of coverage.
          </p>
        </>
      );
    }

    if (client.allEmployeesActivelyAtWork === "YES") {
      return (
        <>
          <p>
            You let us know on {moment(client.activelyAtWorkUpdatedAt).format("MMMM DD, YYYY")} that
            all employees covered by your plan will be actively at work on the benefits effective
            date.
          </p>
          <p>
            <Body5>
              Employees not actively at work may be eligible to continue coverage under Sun Life's{" "}
              <Anchor
                href="https://p1.aprimocdn.net/sunlife/db394d0b-db87-4f4a-b44b-afcc010babbb/glho-7522-life-and-ad%26d-continuity-of-coverage-original-file.pdf"
                target="_blank"
              >
                Continuity of Coverage provision
              </Anchor>
              . Identifying these employees can help avoid an unnecessary loss of coverage.
            </Body5>
          </p>
        </>
      );
    }

    if (client.allEmployeesActivelyAtWork === "NO") {
      return (
        <p>
          You have indicated that some employees covered by your plan will not be actively at work
          on the benefits effective date.
        </p>
      );
    }
  };

  return (
    <div className="mb-0">
      <form onSubmit={formik.handleSubmit}>
        <AddFilesCard
          clientId={client.id}
          taskStatus={null}
          category={documentCategory}
          title={
            <>
              <H5 as="h2">Actively at Work</H5>
              {getTitleBodyContent(client)}
            </>
          }
          hideButton={true}
          hideTable={
            client.allEmployeesActivelyAtWork !== "NO" &&
            formik.values.allEmployeesActivelyAtWork !== "NO"
          }
          hideRule={!activelyAtWorkEditable}
          message=""
          actionRequiredMessage={null}
          subTitle={
            documents &&
            documents.length > 0 &&
            formik.values.allEmployeesActivelyAtWork === "NO" &&
            !activelyAtWorkEditable ? (
              <Body2>Employee list:</Body2>
            ) : null
          }
          trackElementClicked={trackElementClicked}
          taskCompletedTitle=""
          remainingTasks={false}
          description={
            <>
              {activelyAtWorkEditable && (
                <StackY dist={32}>
                  <RadioGroup
                    name="allEmployeesActivelyAtWork"
                    label="Will all employees covered by your plans be actively at work on the benefits effective date?"
                    disabled={formik.isSubmitting}
                    // We do not want the error to show up here, instead it is shown above the Save button
                    error={undefined}
                    touched={formik.touched.allEmployeesActivelyAtWork}
                    options={[
                      {
                        value: "YES",
                        label: "Yes, all employees will be actively at work",
                      },
                      {
                        value: "NO",
                        label: "No, some employees will not be actively at work",
                      },
                    ]}
                    direction="vertical"
                    value={formik.values.allEmployeesActivelyAtWork}
                    onChange={formik.handleChange}
                  />

                  {documents &&
                    documents.length === 0 &&
                    formik.values.allEmployeesActivelyAtWork === "NO" && (
                      <StackY dist={24}>
                        <Body2>
                          Please provide us with a list of employees who will not be actively at
                          work.
                        </Body2>

                        <OrderedList>
                          <OrderedListItem>
                            <Body3>
                              Use this template to create your employee list:{" "}
                              <Anchor href="/Actively At Work Employee Template.xlsx">
                                Download template{" "}
                                <span className={styles.downloadIcon}>
                                  <DownloadIcon />
                                </span>
                              </Anchor>
                            </Body3>
                          </OrderedListItem>
                          <OrderedListItem>
                            <Body3>
                              Upload your completed list to send to Sun Life for review:
                            </Body3>
                          </OrderedListItem>
                        </OrderedList>

                        <Body3>Once you have filled out the template upload it here:</Body3>
                        <StackX dist={24}>
                          <Button
                            type="tertiary"
                            size="middle"
                            onClick={() => setShowUploadModal(true)}
                          >
                            Upload employee list
                          </Button>
                          <StoredSecurelyText track={track} />
                        </StackX>
                        <HorizontalDivider />
                        <DocumentUpload
                          modalTitle={"Upload employee list"}
                          visible={showUploadModal}
                          closeModal={() => setShowUploadModal(false)}
                          clientId={client.id}
                          category={documentCategory}
                          onUpload={() => {
                            setShowUploadModal(false);
                            formik.setFieldError("allEmployeesActivelyAtWork", undefined);
                          }}
                          track={track}
                          singleFile={true}
                        />
                      </StackY>
                    )}
                </StackY>
              )}
            </>
          }
          footer={(_setShowUploadModal, _tracker) => {
            if (formik.isSubmitting) {
              return null;
            }

            if (!activelyAtWorkEditable) {
              if (client.allEmployeesActivelyAtWork === null) {
                return (
                  <Button type="primary" size="middle" onClick={toggleActivelyAtWorkEditable}>
                    Add details
                  </Button>
                );
              }
              return (
                <>
                  <StackX dist={24}>
                    <Button type="tertiary" size="middle" onClick={toggleActivelyAtWorkEditable}>
                      Edit
                    </Button>
                    {client.allEmployeesActivelyAtWork === "NO" && (
                      <StoredSecurelyText track={track} />
                    )}
                  </StackX>
                  {client.allEmployeesActivelyAtWork === "NO" && ActivelyAtWorkCardBulbMessage}
                  {documents &&
                    documents.length === 0 &&
                    client.allEmployeesActivelyAtWork === "NO" && (
                      <Body3 redMedium>{fileMissingError}</Body3>
                    )}
                </>
              );
            }

            return (
              <>
                {formik.touched.allEmployeesActivelyAtWork &&
                  formik.errors.allEmployeesActivelyAtWork &&
                  formik.values.allEmployeesActivelyAtWork !== "YES" && (
                    <Body3 redMedium>{`${formik.errors.allEmployeesActivelyAtWork}`}</Body3>
                  )}
                <div aria-live="assertive" className="hide:empty">
                  {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
                </div>
                {(documents ?? []).length > 0 && <StoredSecurelyText track={track} />}
                {formik.values.allEmployeesActivelyAtWork === "NO" && ActivelyAtWorkCardBulbMessage}
                <Row>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      disabled={formik.isSubmitting || isFetchingDocuments}
                      loading={formik.isSubmitting}
                    >
                      Save
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      size="middle"
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      onClick={toggleActivelyAtWorkEditable}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </>
            );
          }}
        />
      </form>
    </div>
  );
};

const ActivelyAtWorkCardBulbMessage = (
  <BulbMessage>
    If you're submitting your employee list several weeks in advance of your benefits effective
    date, please reach out to your Implementation Consultant if any new employees need to be added.
  </BulbMessage>
);
