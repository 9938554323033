import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import identity from "lodash/identity";
import { useLocation } from "react-router-dom";
import { getURLSearchParamsFromObject } from "../utils/url";
import { invalidateGetEmailsQuery } from "./email";
import { useSlobMutation, useSlobQuery, compareQueryKey } from "./query";
import { getTableQueryArguments } from "./slobTable";
import type { AuthState } from "../auth/oktaAuth";
import type { JsonToTypeMapper, ResponseError, SlobQueryType } from "./query";
import type { TableQueryParams } from "./slobTable";
import type { QueryClient, UseQueryOptions, QueryKey } from "@tanstack/react-query";
import type { UserRole } from "shared/rbac/roles";

import type { ClientId } from "shared/types/Client";
import type {
  UpdateOnboardingFormRecipientEmailInput,
  UpdateOnboardingFormRecipientEmailOutput,
} from "shared/types/OnboardingForm";
import type {
  UpdateEmailAndActivateUserInput,
  CreateActivationLinkOutput,
  BenAdminCreate,
  BrokerCreate,
  DomainUser,
  SlfInternalCreate,
  UserId,
  DomainUserOption,
  BenAdmin,
  SlfInternal,
  SimplifiedDomainUser,
  Broker,
} from "shared/types/User";
import type { PasswordUpsertRequest } from "shared/validation/user";

export const jsonBenAdminToBenAdmin: JsonToTypeMapper<BenAdmin> = (benAdmin) => {
  return {
    ...benAdmin,
    welcomeEmailSentDate: benAdmin.welcomeEmailSentDate
      ? new Date(benAdmin.welcomeEmailSentDate)
      : null,
    termsOfUseDate: benAdmin.termsOfUseDate ? new Date(benAdmin.termsOfUseDate) : null,
    firstLogin: benAdmin.firstLogin ? new Date(benAdmin.firstLogin) : null,
    firstPasswordSet: benAdmin.firstPasswordSet ? new Date(benAdmin.firstPasswordSet) : null,
    lastLogin: benAdmin.lastLogin ? new Date(benAdmin.lastLogin) : null,
    lastPasswordSet: benAdmin.lastPasswordSet ? new Date(benAdmin.lastPasswordSet) : null,
  };
};

export const jsonDomainUsertoUser: JsonToTypeMapper<SimplifiedDomainUser> = (user) => {
  return {
    ...user,
    termsOfUseDate: user.termsOfUseDate ? new Date(user.termsOfUseDate) : null,
  };
};

export const jsonSlfConsultantToUser: JsonToTypeMapper<
  Omit<SimplifiedDomainUser, "clientIds" | "role">
> = (user) => {
  return {
    ...user,
    termsOfUseDate: user.termsOfUseDate ? new Date(user.termsOfUseDate) : null,
  };
};

const jsonSlfInternalToSlfInternal: JsonToTypeMapper<SlfInternal> = (slfInternal) => {
  return {
    ...slfInternal,
    welcomeEmailSentDate: slfInternal.welcomeEmailSentDate
      ? new Date(slfInternal.welcomeEmailSentDate)
      : null,
    termsOfUseDate: slfInternal.termsOfUseDate ? new Date(slfInternal.termsOfUseDate) : null,
    firstLogin: slfInternal.firstLogin ? new Date(slfInternal.firstLogin) : null,
    firstPasswordSet: slfInternal.firstPasswordSet ? new Date(slfInternal.firstPasswordSet) : null,
    lastLogin: slfInternal.lastLogin ? new Date(slfInternal.lastLogin) : null,
    lastPasswordSet: slfInternal.lastPasswordSet ? new Date(slfInternal.lastPasswordSet) : null,
  };
};

export const jsonUserToDomainUser: JsonToTypeMapper<DomainUser> = (user) => {
  return {
    ...user,
    userInfoUpdatedAt: new Date(user.userInfoUpdatedAt),
    createdAt: new Date(user.createdAt),
    updatedAt: new Date(user.updatedAt),
    firstLogin: user.firstLogin ? new Date(user.firstLogin) : null,
    firstPasswordSet: user.firstPasswordSet ? new Date(user.firstPasswordSet) : null,
    lastLogin: user.lastLogin ? new Date(user.lastLogin) : null,
    lastPasswordSet: user.lastPasswordSet ? new Date(user.lastPasswordSet) : null,
    termsOfUseDate: user.termsOfUseDate ? new Date(user.termsOfUseDate) : null,
    welcomeEmailSentDate: user.welcomeEmailSentDate ? new Date(user.welcomeEmailSentDate) : null,
    deletedAt: user.deletedAt ? new Date(user.deletedAt) : null,
  };
};

export const jsonBrokerToBroker: JsonToTypeMapper<Broker> = (broker) => {
  return {
    ...broker,
    welcomeEmailSentDate: broker.welcomeEmailSentDate
      ? new Date(broker.welcomeEmailSentDate)
      : null,
    termsOfUseDate: broker.termsOfUseDate ? new Date(broker.termsOfUseDate) : null,
    firstLogin: broker.firstLogin ? new Date(broker.firstLogin) : null,
    firstPasswordSet: broker.firstPasswordSet ? new Date(broker.firstPasswordSet) : null,
    lastLogin: broker.lastLogin ? new Date(broker.lastLogin) : null,
    lastPasswordSet: broker.lastPasswordSet ? new Date(broker.lastPasswordSet) : null,
  };
};

export const usePostLoginActions = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    { accessToken: string; refreshToken: string },
    AuthState,
    `/api/users/authenticated/logged-in`
  >({
    method: "post",
    path: `/api/users/authenticated/logged-in`,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/users/authenticated`]),
        });
      },
    },
  });
};

export const invalidateGetBenAdmins = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    predicate: compareQueryKey(["get", `/api/users/ben-admin`]),
  });
};

const invalidateGetSlfInternals = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    predicate: compareQueryKey(["get", `/api/users/internal`]),
  });
};

type UseGetSlfInternalsReponse = { data: SlfInternal[]; meta: { count: number } };

export const useGetSlfInternals = (
  query: TableQueryParams,
  options?: UseQueryOptions<
    UseGetSlfInternalsReponse,
    ResponseError,
    UseGetSlfInternalsReponse,
    QueryKey
  >,
) => {
  const params = getTableQueryArguments(query);

  return useSlobQuery<UseGetSlfInternalsReponse>({
    method: "get",
    path: `/api/users/internal?${params.toString()}`,
    map: (response) => ({ ...response, data: response.data.map(jsonSlfInternalToSlfInternal) }),
    options: { placeholderData: keepPreviousData, ...options },
  });
};

export const useCreateSlfInternal = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<SlfInternalCreate, SlfInternal>({
    method: "post",
    path: `/api/users/internal`,
    options: {
      async onSuccess(_, variables) {
        await invalidateGetSlfInternals(queryClient);
        if (variables.data.sendWelcomeEmail) {
          await invalidateGetEmailsQuery(queryClient);
        }
      },
    },
  });
};

export const useUpdateSlfInternal = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<Partial<SlfInternal>, SlfInternal, `/api/users/internal/:userId`>({
    method: "put",
    path: `/api/users/internal/:userId`,
    map: jsonSlfInternalToSlfInternal,
    options: {
      async onSuccess() {
        await invalidateGetSlfInternals(queryClient);
      },
    },
  });
};

export const useDeleteSlfInternal = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `/api/users/internal/:userId`,
    options: {
      async onSuccess() {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/users`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients`]),
          }),
        ]);
      },
    },
  });
};

export const useSendSlfInternalWelcomeEmail = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    void,
    never,
    "/api/users/internal/:userId/activation-email",
    ResponseError,
    unknown,
    { clientId?: ClientId }
  >({
    method: "post",
    path: `/api/users/internal/:userId/activation-email`,
    options: {
      async onSuccess() {
        await Promise.all([
          invalidateGetSlfInternals(queryClient),
          invalidateGetEmailsQuery(queryClient),
        ]);
      },
    },
  });
};

export const invalidateGetBrokers = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    predicate: compareQueryKey(["get", `/api/users/broker`]),
  });
};

export const useGetBenAdmins = (query: TableQueryParams<{ clientId?: ClientId }>) => {
  const params = getTableQueryArguments(query);
  return useSlobQuery<{ data: BenAdmin[]; meta: { count: number } }>({
    method: "get",
    path: `/api/users/ben-admin?${params.toString()}`,
    map: (response) => ({ ...response, data: response.data.map(jsonBenAdminToBenAdmin) }),
    options: { placeholderData: keepPreviousData },
  });
};

export const useSetTermsOfUse = () => {
  return useSlobMutation<void, never, `/api/users/terms-of-use`>({
    method: "post",
    path: `/api/users/terms-of-use`,
  });
};

export const useGetBrokers = (query: TableQueryParams<{ clientId?: ClientId }>) => {
  const params = getTableQueryArguments(query);

  return useSlobQuery<{ data: Broker[]; meta: { count: number } }>({
    method: "get",
    path: `/api/users/broker?${params.toString()}`,
    map: (response) => ({ ...response, data: response.data.map(jsonBrokerToBroker) }),
    options: { placeholderData: keepPreviousData },
  });
};

export const useUpdateBenAdmin = (initialValue: BenAdmin) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateUser, data: updateUserData } = useSlobMutation<
    Partial<BenAdmin>,
    BenAdmin,
    `/api/users/ben-admin/:userId`
  >({
    method: "put",
    path: `/api/users/ben-admin/:userId`,
    map: jsonBenAdminToBenAdmin,
    options: {
      async onSuccess() {
        await invalidateGetBenAdmins(queryClient);
      },
    },
  });

  const { mutateAsync: updateFormRecipientEmail, data: updateFormRecipientEmailData } =
    useUpdateFormRecipientEmail();

  const updateBenAdmin = async (...[variables, options]: Parameters<typeof updateUser>) => {
    const updateUserMutation = await updateUser(variables, options);

    if (variables.data.email && initialValue.email !== variables.data.email) {
      const updateFormRecipientEmailMutation = await updateFormRecipientEmail({
        params: {
          userId: updateUserMutation.data.id,
        },
        data: {
          oldEmail: initialValue.email,
        },
      });

      return {
        updateUserMutation,
        updateFormRecipientEmailMutation,
      };
    }

    return {
      updateUserMutation,
      updateFormRecipientEmailMutation: null,
    };
  };

  return {
    updateUserData,
    updateFormRecipientEmailData,
    updateBenAdmin,
  };
};

export const useUnassignBenAdminToClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<BenAdmin>,
    BenAdmin,
    `/api/users/ben-admin/:userId/unassign/:clientId`
  >({
    method: "put",
    path: `/api/users/ben-admin/:userId/unassign/:clientId`,
    map: jsonBenAdminToBenAdmin,
    options: {
      async onSuccess({ data: _benAdmin }) {
        await invalidateGetBenAdmins(queryClient);
      },
    },
  });
};

export const useGetBroker = (userId: UserId) => {
  return useSlobQuery<Broker>({
    method: "get",
    path: `/api/users/broker/${userId}`,
    map: jsonBrokerToBroker,
    options: { placeholderData: keepPreviousData },
  });
};

export const useGetBenAdmin = (userId: UserId) => {
  return useSlobQuery<BenAdmin>({
    method: "get",
    path: `/api/users/ben-admin/${userId}`,
    map: jsonBenAdminToBenAdmin,
    options: { placeholderData: keepPreviousData },
  });
};

export const useUpdateBroker = (initialValue: Broker) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateUser, data: updateUserData } = useSlobMutation<
    Partial<Broker>,
    Broker,
    `/api/users/broker/:userId`
  >({
    method: "put",
    path: `/api/users/broker/:userId`,
    map: jsonBrokerToBroker,
    options: {
      async onSuccess() {
        await invalidateGetBrokers(queryClient);
      },
    },
  });

  const { mutateAsync: updateFormRecipientEmail, data: updateFormRecipientEmailData } =
    useUpdateFormRecipientEmail();

  const updateBroker = async (...[variables, options]: Parameters<typeof updateUser>) => {
    const updateUserMutation = await updateUser(variables, options);

    if (variables.data.email && initialValue.email !== variables.data.email) {
      const updateFormRecipientEmailMutation = await updateFormRecipientEmail({
        params: {
          userId: updateUserMutation.data.id,
        },
        data: {
          oldEmail: initialValue.email,
        },
      });

      return {
        updateUserMutation,
        updateFormRecipientEmailMutation,
      };
    }

    return {
      updateUserMutation,
      updateFormRecipientEmailMutation: null,
    };
  };

  return {
    updateUserData,
    updateFormRecipientEmailData,
    updateBroker,
  };
};

export const useAssignBenAdminToClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<BenAdmin> & { sendWelcomeEmail: boolean },
    BenAdmin,
    `/api/users/ben-admin/:userId/assign/:clientId`
  >({
    method: "put",
    path: `/api/users/ben-admin/:userId/assign/:clientId`,
    map: jsonBenAdminToBenAdmin,
    options: {
      async onSuccess() {
        await invalidateGetBenAdmins(queryClient);
      },
    },
  });
};

export const useAssignBrokerToClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<Broker> & { sendWelcomeEmail: boolean },
    Broker,
    `/api/users/broker/:userId/assign/:clientId`
  >({
    method: "put",
    path: `/api/users/broker/:userId/assign/:clientId`,
    map: jsonBrokerToBroker,
    options: {
      async onSuccess() {
        await invalidateGetBrokers(queryClient);
      },
    },
  });
};

export const useUnassignBrokerToClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<Partial<Broker>, Broker, `/api/users/broker/:userId/unassign/:clientId`>({
    method: "put",
    path: `/api/users/broker/:userId/unassign/:clientId`,
    map: jsonBrokerToBroker,
    options: {
      async onSuccess() {
        await invalidateGetBrokers(queryClient);
      },
    },
  });
};

export const useCreateBroker = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<BrokerCreate, Broker>({
    method: "post",
    path: `/api/users/broker`,
    options: {
      async onSuccess({ data: _broker }, variables) {
        await Promise.all([invalidateGetBrokers(queryClient), invalidateGetBenAdmins(queryClient)]);
        if (variables.data.sendWelcomeEmail) {
          await invalidateGetEmailsQuery(queryClient);
        }
      },
    },
  });
};

export const useCreateBenAdmin = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<BenAdminCreate, BenAdmin>({
    method: "post",
    path: `/api/users/ben-admin`,
    options: {
      async onSuccess({ data: _benAdmin }, variables) {
        await Promise.all([invalidateGetBenAdmins(queryClient), invalidateGetBrokers(queryClient)]);
        if (variables.data.sendWelcomeEmail) {
          await invalidateGetEmailsQuery(queryClient);
        }
      },
    },
  });
};

export const useDeleteBenAdmin = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `/api/users/ben-admin/:userId`,
    options: {
      async onSuccess({ data: _benAdmin }) {
        await invalidateGetBenAdmins(queryClient);
      },
    },
  });
};
export const useDeleteBroker = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `/api/users/broker/:userId`,
    options: {
      async onSuccess({ data: _broker }) {
        await invalidateGetBrokers(queryClient);
      },
    },
  });
};

export const useResetPassword = () =>
  useSlobMutation({
    method: "post",
    path: `/api/users/reset-password/:email`,
  });

export const useResetMFA = () =>
  useSlobMutation({
    method: "post",
    path: `/api/users/reset-mfa/:email`,
  });

export const useChangeBenAdminToBroker = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    void,
    never,
    "/api/users/ben-admin/:userId/change-to-broker",
    ResponseError,
    unknown,
    { clientId?: ClientId }
  >({
    method: "post",
    path: `/api/users/ben-admin/:userId/change-to-broker`,
    options: {
      async onSuccess({ data: _broker }) {
        await Promise.all([invalidateGetBrokers(queryClient), invalidateGetBenAdmins(queryClient)]);
      },
    },
  });
};

export const useSendBenAdminWelcomeEmail = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    void,
    never,
    "/api/users/ben-admin/:userId/activation-email",
    ResponseError,
    unknown,
    { clientId?: ClientId }
  >({
    method: "post",
    path: `/api/users/ben-admin/:userId/activation-email`,
    options: {
      async onSuccess({ data: _benAdmin }) {
        await Promise.all([
          invalidateGetBenAdmins(queryClient),
          invalidateGetEmailsQuery(queryClient),
        ]);
      },
    },
  });
};
export const useSendBrokerWelcomeEmail = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    void,
    never,
    "/api/users/broker/:userId/activation-email",
    ResponseError,
    unknown,
    { clientId?: ClientId }
  >({
    method: "post",
    path: `/api/users/broker/:userId/activation-email`,
    options: {
      async onSuccess({ data: _benAdmin }) {
        await Promise.all([
          invalidateGetBrokers(queryClient),
          invalidateGetEmailsQuery(queryClient),
        ]);
      },
    },
  });
};

type Activation = {
  email: string;
  firstName: string;
  lastName: string;
};

export const useVerifyUserActivationToken = (token: string) => {
  return useSlobQuery<Activation>({
    method: "get",
    path: `/api/users/auth-token/${token}`,
    options: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled: !!token,
    },
    map: (response) => ({ ...response }),
    shouldRequireAuth: false,
  });
};
export const useVerifyUserResetToken = (token: string) => {
  return useSlobQuery<Activation>({
    method: "get",
    path: `/api/users/auth-token/${token}`,
    options: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
    map: (response) => ({ ...response }),
    shouldRequireAuth: false,
  });
};

export const useUpsertPassword = () =>
  useSlobMutation<PasswordUpsertRequest, boolean, "/api/users/password-upsert">({
    method: "post",
    path: "/api/users/password-upsert",
  });

export const useForgotPassword = () =>
  useSlobMutation<void, boolean, "/api/users/reset-password/:email">({
    method: "post",
    path: "/api/users/reset-password/:email",
  });
export type ForgotPasswordFunc = ReturnType<typeof useForgotPassword>["mutateAsync"];

export const useResendActivationEmail = () =>
  useSlobMutation<void, boolean, "/api/users/activation-email/:email">({
    method: "post",
    path: "/api/users/activation-email/:email",
  });
export type ResendActivationEmailFunc = ReturnType<typeof useResendActivationEmail>["mutateAsync"];

export function useActivationTokenExpired() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token") ?? "";
  const { data, isError, isInitialLoading, isLoading, isFetching } =
    useVerifyUserActivationToken(token);

  return {
    data,
    isError,
    isInitialLoading,
    isLoading,
    isFetching,
    token,
    isExpired: !data || isError,
  };
}

export const useGetUsersByRole = (
  userRole: UserRole,
  query: TableQueryParams,
  options: SlobQueryType<DomainUser[], ResponseError>["options"] = {},
) => {
  const params = getTableQueryArguments(query);

  return useSlobQuery<DomainUser[]>({
    method: "get",
    path: `/api/users/role/${userRole}?${params.toString()}`,
    map: (response) => response.map(jsonUserToDomainUser),
    options: { placeholderData: keepPreviousData, ...options },
  });
};

export const useGetAllSlfUsers = (
  options: SlobQueryType<DomainUserOption[], ResponseError>["options"] = {},
) => {
  return useSlobQuery<DomainUserOption[]>({
    method: "get",
    path: "/api/users/slfs",
    map: identity,
    options: { placeholderData: keepPreviousData, ...options },
  });
};

export const useUpdateFormRecipientEmail = () => {
  return useSlobMutation<
    UpdateOnboardingFormRecipientEmailInput,
    UpdateOnboardingFormRecipientEmailOutput,
    "/api/users/:userId/onboarding-form-email"
  >(
    {
      method: "put",
      path: "/api/users/:userId/onboarding-form-email",
      options: {
        retry: false,
      },
    },
    {
      timeout: 60000,
    },
  );
};

export type CreateActivationLinkFunc = ReturnType<typeof useCreateActivationLink>["mutateAsync"];

export const useCreateActivationLink = () => {
  return useSlobMutation<void, CreateActivationLinkOutput, "/api/users/:userId/activation-link">({
    method: "post",
    path: "/api/users/:userId/activation-link",
    options: {
      retry: false,
    },
  });
};

export const checkExistingUser = async (
  email: string,
): Promise<
  | { data: { id: string; email: string; role: UserRole; clients: { id: string; name: string }[] } }
  | { data: null }
> => {
  return await axios.get(`/api/users/check-existing/${email}`);
};

export type UpdateEmailAndSendActivationEmailFunc = ReturnType<
  typeof useUpdateEmailAndSendActivationEmail
>["mutateAsync"];

export const useUpdateEmailAndSendActivationEmail = () => {
  const queryClient = useQueryClient();
  const query = useSlobMutation<
    UpdateEmailAndActivateUserInput,
    void,
    "/api/users/update-email-and-send-activation-email"
  >({
    method: "post",
    path: "/api/users/update-email-and-send-activation-email",
    options: {
      retry: false,
      onSuccess: async (_data, variables) => {
        const clientIds = variables.data.users.map((u) => u.clientId);
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients`]),
          }),
          ...clientIds.map((clientId) =>
            queryClient.invalidateQueries({
              predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
            }),
          ),
        ]);
      },
    },
  });
  return query;
};

export const useGetUsers = (
  query: { userIds: string[] },
  options: SlobQueryType<DomainUser[], ResponseError>["options"] = {},
) => {
  const params = getURLSearchParamsFromObject(query);
  return useSlobQuery<DomainUser[]>({
    method: "get",
    path: `/api/users?${params.toString()}`,
    map: (response) => response.map(jsonUserToDomainUser),
    options: { placeholderData: keepPreviousData, ...options },
  });
};
