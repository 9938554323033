import { Row, Col } from "client/src/components/Grid/Grid";

import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { EIFPlanConfigAndEligibilitySectionHeader } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFPlanConfigAndEligibilitySectionHeader";
import { EIFSingleBenefitsChipGroup } from "client/src/domain/EIF/common/EIFBenefitsChipGroup/EIFSingleBenefitsChipGroup";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getBenefitChangeDetailInfoList } from "client/src/domain/EIF/common/utils/getBenefitChangeDetailInfoList";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useMemo } from "react";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";

import {
  getIsBenefitSupportedInClassBuilder,
  getIsPFMLBenefitType,
} from "shared/types/BenefitTypes";
import { getEmployeeClassBenefitsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { unique } from "shared/utils/utils";
import * as Yup from "yup";
import { Button } from "../../../../components/Button/Button";
import { InternalLinkButton } from "../../../../components/Button/InternalLinkButton";

import { StackY } from "../../../../components/Spacing/Spacing";
import { Body2, Body5, H3 } from "../../../../components/Typography/Typography";
import { slobMessage } from "../../../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { EIFSectionStatusIconography } from "../../common/EIFSectionStatusIconography";
import { EIFClassBuilderBenefitList } from "./EIFClassBuilderBenefitList";
import type { CreateClassQuery } from "../../../../hooks/employeeClass";
import type { SetBenefitTypeAvailablePlansQuery } from "../../../../hooks/employeeClassPlan";
import type { SectionTracker } from "./EIFClassBuilderCreator";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";

type Props = {
  isActive: boolean;
  client: Client;
  clientPlans: Plan[];
  createClassQuery: CreateClassQuery;
  employeeClass: EmployeeClass | undefined;
  setBenefitTypesAvailablePlansQuery: SetBenefitTypeAvailablePlansQuery;
  onSave: (employeeClass: EmployeeClass) => void;
  isLoading: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  track: SectionTracker;
  authUser: UserData | null;
};

const validationSchema = Yup.object({
  benefitTypes: Yup.array()
    .of(Yup.mixed<BenefitTypeEIF>().required())
    .strict()
    .min(1, "Please select at least one benefit type")
    .required(),
});

export function EIFClassBuilderBenefits(props: Props) {
  const {
    isActive,
    client,
    clientPlans,
    createClassQuery: { mutateAsync: createClass },
    employeeClass,
    setBenefitTypesAvailablePlansQuery: { mutateAsync: setBenefitTypesAvailablePlans },
    onSave,
    isLoading,
    track,
    changeSnapshot,
    authUser,
  } = props;

  const location = useLocation();

  const clientBenefitTypes = useMemo(
    () =>
      unique(clientPlans.map((cp) => cp.benefitType)).filter((bt) =>
        getIsBenefitSupportedInClassBuilder(bt, clientPlans),
      ),
    [clientPlans],
  );

  const hasPFML = clientPlans.some((plan) => getIsPFMLBenefitType(plan.benefitType));

  const formik = useSlobFormik({
    validationSchema,
    initialValues: {
      benefitTypes:
        unique(
          employeeClass?.employeeClassPlans.map(
            (employeeClassPlan) => employeeClassPlan.plan.benefitType,
          ),
        ) ?? [],
    },
    onSubmit: async () => {
      // create employee class if it does not exists
      const employeeClassId = employeeClass
        ? employeeClass.id
        : (
            await createClass({
              data: { clientId: client.id },
              params: { clientId: client.id },
            })
          ).data.id;

      const benefitTypes = unique(formik.values.benefitTypes);

      const { isSuccess, data } = await setBenefitTypesAvailablePlans({
        params: {
          clientId: client.id,
          employeeClassId,
        },
        data: {
          benefitTypes,
        },
      });

      if (isSuccess) {
        const created = !employeeClass?.employeeClassPlans;
        track("Save");
        void slobMessage.success(created ? "Class Plans created" : "Class Plans updated");
        onSave(data.employeeClass);
      }
    },
  });

  const status = getEmployeeClassBenefitsCompletionStatus(employeeClass);

  const benefitChangeDetailInfoList = getBenefitChangeDetailInfoList(
    changeSnapshot,
    employeeClass?.id,
  );

  if (!isActive) {
    const inactiveAndEmpty =
      !isActive && (!employeeClass || employeeClass?.employeeClassPlans.length === 0);

    return (
      <EIFPlanConfigAndEligibilitySectionHeader
        status={status}
        isLoading={isLoading}
        location={location}
        inactiveAndEmpty={inactiveAndEmpty}
        changeDetailInfoList={benefitChangeDetailInfoList}
        section="Benefits"
        client={client}
        authUser={authUser}
        hideActionButtons={employeeClass?.importedFromQPS}
      >
        <EIFClassBuilderBenefitList employeeClassPlans={employeeClass?.employeeClassPlans ?? []} />
      </EIFPlanConfigAndEligibilitySectionHeader>
    );
  }

  const allBenefitsSelected = formik.values.benefitTypes.length === clientBenefitTypes.length;

  return (
    <>
      <H3 as="h2">
        <EIFSectionStatusIconography status={status} />
        Benefits
      </H3>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32}>
          <StackY dist={8}>
            <div>
              <StackY dist={2}>
                <Body2 as="div">Which benefit(s) is this group eligible for?</Body2>
                <UnorderedList markerColor="gray">
                  <UnorderedListItem>
                    <Body5>
                      If you have an employee assistance program (EAP) benefit it will not be listed
                      below.
                    </Body5>
                  </UnorderedListItem>
                  {hasPFML && (
                    <UnorderedListItem>
                      <Body5>PFML contributions will be entered in PFML Preferences.</Body5>
                    </UnorderedListItem>
                  )}
                </UnorderedList>
              </StackY>
              <StackY dist={16} className="mt-16">
                <Button
                  role="button"
                  type="text-only"
                  size="middle"
                  onClick={async () => {
                    if (allBenefitsSelected) {
                      await formik.setFieldValue("benefitTypes", []);
                    } else {
                      await formik.setFieldValue("benefitTypes", clientBenefitTypes);
                    }
                  }}
                >
                  {allBenefitsSelected ? "Deselect All" : "Select All"}
                </Button>

                <EIFSingleBenefitsChipGroup
                  benefitTypes={clientBenefitTypes}
                  selectedBenefitTypes={formik.values.benefitTypes}
                  name="benefitTypes"
                  touched={formik.touched.benefitTypes}
                  error={formik.errors.benefitTypes}
                  disabled={formik.isSubmitting}
                  setFieldValue={formik.setFieldValue}
                />
              </StackY>
            </div>

            <EditedFieldMsg
              changeDetailInfoList={benefitChangeDetailInfoList}
              client={client}
              authUser={authUser}
            />
          </StackY>

          <Row justify="end">
            <Col>
              <Row align="middle" gutter={28}>
                <Col>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={location.pathname}
                    disabled={formik.isSubmitting}
                    onClick={() => flushSync(formik.resetForm)}
                  >
                    Cancel
                  </InternalLinkButton>
                </Col>

                <Col>
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    size="middle"
                  >
                    Save & Continue
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
