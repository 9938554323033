import clsx from "clsx";

import { ReactComponent as NewTabIcon } from "../Icons/NewTabIcon.svg";
import { Body3, Body2 } from "../Typography/Typography";

import * as styles from "./knowledgeBaseItem.module.less";

import type { AnchorHTMLAttributes } from "react";

export type KnowledgeBaseItemProps = Omit<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "className" | "rel"
> & {
  title: string;
  category?: string;
  bottomText?: string;
};

export const KnowledgeBaseItem: React.FC<KnowledgeBaseItemProps> = ({
  title,
  category,
  bottomText,
  ...anchorProps
}) => {
  return (
    <a {...anchorProps} rel="noopener noreferrer">
      <div className={clsx([styles.knowledgeBaseItem, !bottomText && styles.titleOnly])}>
        <div>
          {category && <p className="eyebrow">{category}</p>}
          <div className={styles.textWithIcon}>
            <Body2 as="p" blueMedium>
              {title}
            </Body2>
            {!bottomText && <NewTabIcon />}
          </div>
        </div>

        {bottomText && (
          <div className={styles.textWithIcon}>
            <Body3 colorSecondary>{bottomText}</Body3>
            <NewTabIcon />
          </div>
        )}
      </div>
    </a>
  );
};
