import { DatePicker } from "antd";
import clsx from "clsx";

import { useSlobId } from "../../hooks/useSlobId";
import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type { PickerDateProps } from "rc-picker/lib/Picker";

type FormDatePickerProps = {
  touched?: boolean;
  errorValue?: string;
  topText?: string;
  bottomText?: string;
  showRequired?: boolean;
} & Pick<
  PickerDateProps<moment.Moment>,
  "name" | "value" | "onChange" | "disabled" | "disabledDate" | "placeholder" | "picker"
>;

export const FormDatePicker = ({
  name,
  value,
  touched,
  errorValue,
  onChange,
  disabled = false,
  disabledDate,
  topText,
  bottomText,
  placeholder,
  showRequired,
}: FormDatePickerProps) => {
  const id = useSlobId({ prefix: name });
  const shouldShowError = touched && errorValue;
  const errorId = shouldShowError ? `${id}__errormessage` : undefined;

  const inputClass = shouldShowError ? styles.inputError : "";

  const format = "MM/DD/YYYY";

  const props = {
    id,
    name,
    value,
    disabled,
    disabledDate,
    className: inputClass,
    format,
    onChange,
    placeholder: placeholder,
  };

  return (
    <div className={styles.datePickerContainer}>
      {topText && (
        <p id={`info-top-${name}`} className={clsx("body2", styles.topText)}>
          {topText}
        </p>
      )}

      <DatePicker {...props} placeholder={showRequired ? "Select date*" : undefined} />

      <div aria-live="assertive">
        {shouldShowError && <InputErrorMessage id={errorId} error={errorValue} />}
      </div>

      {bottomText && (
        <span id={`info-bottom-${name}`} className={clsx("body5", styles.bottomText)}>
          {bottomText}
        </span>
      )}
    </div>
  );
};
