import { Button } from "client/src/components/Button/Button";
import {
  getPolicyWithHigherPhaseScore,
  comparePolicyByPhaseScore,
  CompactProgressTrackerPolicy,
} from "client/src/domain/Client/ClientsList/ClientsTable/CompactProgressTrackerPolicy";
import { MultiCellValue } from "client/src/domain/Client/ClientsList/ClientsTable/MultiCellValue";
import { getPoliciesEffectiveDates } from "client/src/utils/sort";
import { RouteData } from "shared/config/routeData";

import { ClientDraftBadge } from "../../../../components/Client/ClientDraftBadge";
import { ClientTestBadge } from "../../../../components/Client/ClientTestBadge";
import { SlobTable } from "../../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../../components/Table/ActionButtons";

import * as styles from "./Table.module.less";

import type { SlobTableProps, SlobColumnsType } from "../../../../components/SlobTable/SlobTable";
import type { Client } from "shared/types/Client";

const columns: SlobColumnsType<Client> = [
  {
    width: "30%",
    title: "Client",
    dataIndex: "name",
    defaultSortOrder: "ascend",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    render(value: string, client) {
      return (
        <div className={styles.flexCell}>
          {value} {!client.isActive && <ClientDraftBadge />} {client.isTest && <ClientTestBadge />}
        </div>
      );
    },
  },
  {
    title: "Effective Date",
    dataIndex: "firstPolicyEffective",
    sorter: true,
    sortDirections: ["ascend", "descend", "ascend"],
    className: MultiCellValue.tdClassName,
    render: (_, client: Client) => {
      const dates = getPoliciesEffectiveDates(client.policies);
      return (
        <MultiCellValue>
          {dates.map((date, i) => (
            <div key={`${date}_${i}`}>{date}</div>
          ))}
        </MultiCellValue>
      );
    },
  },
  {
    title: "Onboarding Phase",
    sorter: (aClient, bClient) => {
      const aPolicy = getPolicyWithHigherPhaseScore(aClient.policies);
      const bPolicy = getPolicyWithHigherPhaseScore(bClient.policies);
      return comparePolicyByPhaseScore(aPolicy?.phaseId, bPolicy?.phaseId);
    },
    sortDirections: ["ascend", "descend", "ascend"],
    render: (client: Client) => {
      return (
        <>
          {client.policies.map((policy) => {
            return (
              <div key={policy.slfPolicyNumber}>
                {`Policy #${policy.slfPolicyNumber}`}
                <CompactProgressTrackerPolicy key={policy.id} client={client} policy={policy} />
              </div>
            );
          })}
        </>
      );
    },
  },
  {
    width: 50,
    render: (client: Client) => (
      <ActionButtons>
        <Button type="secondary" size="xtra-small" to={RouteData.homeHub.getPath(client.id)}>
          View Portal
        </Button>
        <Button size="xtra-small" to={RouteData.clientDetail.getPath(client.id)}>
          Manage
        </Button>
      </ActionButtons>
    ),
  },
];

export type InternalBrokerClientsTableProps = Omit<SlobTableProps<Client>, "columns">;

export const InternalBrokerClientsTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
}: InternalBrokerClientsTableProps) => {
  return (
    <SlobTable
      columns={columns}
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      featureToggles={featureToggles}
    />
  );
};
