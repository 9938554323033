import { Button } from "client/src/components/Button/Button";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { RouteData } from "shared/config/routeData";

import { getTaskDetailRoute } from "../../../../shared/utils/client";
import { getInfoForPolicy } from "../../../../shared/utils/policy";
import { Badge, taskStatusToLabel, taskStatusToVariant } from "../Badge/Badge";
import { Modal } from "../Modal/Modal";
import { SlobLink } from "../SlobLink/SlobLink";
import { StackY } from "../Spacing/Spacing";
import { UnorderedList, UnorderedListItem } from "../UnorderedList/UnorderedList";

import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { Variant } from "../Badge/Badge";
import type { TaskId, TaskStatus } from "shared/data/Tasks";
import type { ClientId, Policy, PolicyId } from "shared/types/Client";

export type GuidanceModalProps = {
  badgeStatus?: string;
  badgeVariant?: Variant;
  taskStatus?: TaskStatus;
  taskId?: TaskId;
  policyId?: PolicyId;
  policies?: Policy[];
  remainingTasks: boolean;
  clientId: ClientId;
  isVisible: boolean;
  nextText: string | JSX.Element;
  onCancel: () => void;
  showSecondaryButton?: boolean;
  titleText: string;
  trackElementClicked: TrackElementClickedFunc;
};

export const GuidanceModal = ({
  remainingTasks,
  clientId,
  taskId,
  isVisible,
  nextText,
  onCancel,
  showSecondaryButton = false,
  badgeStatus = "",
  badgeVariant,
  titleText,
  taskStatus,
  policyId,
  policies = [],
  trackElementClicked,
}: GuidanceModalProps) => {
  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: "Guidance modal",
      buttonLabel,
    });
  };

  const closeModal = (buttonText: string) => {
    track(buttonText);
    onCancel();
  };

  const variant = taskStatus ? taskStatusToVariant[taskStatus] : badgeVariant;
  const status = taskStatus ? taskStatusToLabel[taskStatus] : badgeStatus;

  const extraPolicies = policies.filter((p) => p.id !== policyId);
  const taskName = !policyId || !taskId ? "" : taskId.replace(/-/g, " ");
  return (
    <Modal
      open={isVisible}
      footer={
        <>
          <Button
            type="primary"
            size="large"
            to={RouteData.homeHub.getPath(clientId)}
            onClick={() => track("Return to home")}
          >
            Return to Home
          </Button>
          {showSecondaryButton && (
            <>
              <span className="body3 ml-24">
                or{" "}
                <ButtonOld
                  type="link-inline"
                  size="middle"
                  onClick={() => closeModal("add more files")}
                >
                  add more files
                </ButtonOld>
              </span>
            </>
          )}
        </>
      }
      title={
        <StackY dist={16}>
          <Badge srOnlyLabel="Task Status" variant={variant} status={status} />
          <div>{titleText}</div>
        </StackY>
      }
      onCancel={() => closeModal("Close")}
    >
      <p className="body2 mb-8">What happens next?</p>
      <div className="body3 mb-40">{nextText}</div>
      {remainingTasks ? (
        <>
          <p className="body2 mb-8">You still have tasks that aren't completed</p>
          <p>
            Return to home to continue making progress on setting up your benefits, or access any
            enrollment resources.
          </p>
        </>
      ) : (
        <>
          <p className="body2 mb-8">Want to check on anything else?</p>
          <p>Return to home to access any enrollment resources or see any completed tasks.</p>
        </>
      )}
      {Boolean(extraPolicies.length) && taskId && policyId && (
        <>
          <p>You can also view {taskName} for benefits effective:</p>
          <UnorderedList>
            {extraPolicies.map((policy) => (
              <UnorderedListItem key={policy.id}>
                <SlobLink
                  variant="normal"
                  to={getTaskDetailRoute(clientId, taskId, policy.id)}
                  onClick={() => closeModal("View another policy task")}
                >
                  {getInfoForPolicy(policy)}
                </SlobLink>
              </UnorderedListItem>
            ))}
          </UnorderedList>
        </>
      )}
    </Modal>
  );
};
