import { Portal } from "@reach/portal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { PrintHidden } from "../../utils/print";
import { GiveFeedbackInputPicker } from "./GiveFeedbackInputPicker";

export function GiveFeedbackExternal() {
  const isMobile = !useBreakpoint().md;

  if (isMobile) return null;

  return (
    <Portal type="give-feedback-portal">
      <PrintHidden>
        <GiveFeedbackInputPicker variant={"explorer"} position="right" />
      </PrintHidden>
    </Portal>
  );
}
