import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Body1, Body3, H3, H5 } from "client/src/components/Typography/Typography";
import { getCSSVariableValue } from "client/src/utils/getCSSVariableValue";
import clsx from "clsx";

import * as styles from "./TableRow.module.less";

type TableRowProps = {
  /**
   * The label or element to be displayed in the value column of the table row.
   */
  valueLabel: string | React.ReactElement;
  /**
   * An array of values or elements to be displayed in the value columns of the table row.
   */
  values: (string | React.ReactElement)[];
  /**
   * Indicates whether the table row is a header row.
   */
  isHeader?: boolean;
};

/**
 * Renders a table row for the Explorer Plan Comparison table.
 */
export const TableRow = ({ valueLabel, values, isHeader = false }: TableRowProps) => {
  const screens = useBreakpoint();
  const condenseValues =
    values.reduce<(string | React.ReactElement)[]>((acc, val) => {
      if (!acc.includes(val)) acc.push(val);
      return acc;
    }, []).length === 1 &&
    values.length !== 1 &&
    screens.md;
  const condensedValues = condenseValues ? [values[0]] : values;
  const columns = screens.md ? [valueLabel, ...condensedValues] : condensedValues;

  return (
    <Row align="stretch" wrap={false}>
      {columns.map((col, i) => {
        const firstCol = i === 0;
        return (
          <Col
            key={`col-${i}`}
            style={{
              position: screens.md && firstCol ? "sticky" : "unset",
              left: 0,
              right: 0,
              display: "inline-block",
              zIndex: firstCol ? 1 : 0,
              backgroundColor:
                screens.md && firstCol ? getCSSVariableValue("--color-gray-100") : "",
            }}
            className={clsx(styles.tableCell, "p-24", !screens.md ? styles.fixedWidthCell : null)}
            // The following three props controls the width of the column.
            span={Math.ceil(24 / columns.length)}
            md={
              firstCol && columns.length <= 4
                ? 24 - 6 * values.length
                : condenseValues
                ? 6 * values.length
                : 6
            }
            lg={
              firstCol && columns.length <= 5
                ? 24 - 5 * values.length
                : condenseValues
                ? 5 * values.length
                : 5
            }
          >
            <Row
              align="middle"
              justify={firstCol || !screens.md ? "start" : "center"}
              style={{ height: "100%" }}
            >
              <Col style={{ textAlign: firstCol || !screens.md ? "left" : "center" }}>
                {isHeader ? (
                  <H3 style={{ marginBottom: "0" }}>
                    <strong>{col}</strong>
                  </H3>
                ) : screens.md ? (
                  firstCol ? (
                    <H5 style={{ marginBottom: "0" }}>{col}</H5>
                  ) : (
                    <Body3>{col}</Body3>
                  )
                ) : (
                  <>
                    <Body1 as="div">
                      <strong>{valueLabel}</strong>
                    </Body1>
                    <Body3>{col}</Body3>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};
