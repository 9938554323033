import { StackY } from "client/src/components/Spacing/Spacing";
import { Body5, Body3 } from "client/src/components/Typography/Typography";
import { relevantChangesForEarningsFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderEarnings";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { yesNoValuesRecord, type Client } from "shared/types/Client";
import {
  numberOfYearsValuesRecord,
  organizationTypesValuesRecord,
  incomeTypesPartnershipValuesRecord,
  incomeTypesSCorporationValuesRecord,
  averagedOverValuesRecord,
  additionalCompensationsSorter,
} from "shared/types/EmployeeClass";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { earningsValidationSchema } from "shared/validation/employeeClass";

type Props = {
  employeeClass: EmployeeClass;
  client: Client;
  prefillErrors: FormikErrors<ValuesForValidationSchema<typeof earningsValidationSchema>>;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
};

export function EIFClassBuilderEarningsReview(props: Props) {
  const { employeeClass, changeSnapshot, client, prefillErrors: e, authUser } = props;

  return (
    <StackY dist={16} wrap={false}>
      <EditedFieldMsg
        changeDetailInfoList={relevantChangesForEarningsFields(employeeClass.id, changeSnapshot)}
        client={client}
        authUser={authUser}
      />

      {employeeClass.customEarnings ? (
        <>
          <div>
            <Body5>Custom earnings type</Body5>
            <Body3 as="div">{employeeClass.customEarningsDescription}</Body3>

            {e.customEarningsDescription && !employeeClass.customEarningsDescription && (
              <Body3 redMedium>{e.customEarningsDescription}</Body3>
            )}

            {!e.customEarningsDescription && !employeeClass.customEarningsDescription && (
              <Body3>-</Body3>
            )}
          </div>

          <Body5 as="div">
            This step has been completed by your implementation consultant to accomodate a custom
            setup. For questions or changes, reach out to your implementation consultant.
          </Body5>
        </>
      ) : (
        employeeClass.earningsType && (
          <div>
            <Body5 as="div">Earnings type</Body5>

            {employeeClass.earningsType === "GROSS_EARNINGS" && (
              <StackY dist={16} wrap={false}>
                <Body3 as="div">Gross earnings</Body3>

                <div>
                  <Body5 as="div">Earnings time frame</Body5>

                  {employeeClass.earningsTimeFrame === "CURRENT_EARNINGS" && (
                    <StackY dist={16} wrap={false}>
                      <Body3 as="div">Current earnings</Body3>
                      <div>
                        <Body5 as="div">Salary changes take effect:</Body5>

                        {employeeClass.currentEarnings === "IMMEDIATELY" && (
                          <Body3 as="div">Immediately</Body3>
                        )}

                        {employeeClass.currentEarnings === "FIRST_OF_THE_FOLLOWING_MONTH" && (
                          <div>
                            <Body3 as="div">First of the following month</Body3>
                            {employeeClass.includeFirstDayOfTheMonth === true ? (
                              <Body3 as="div">Include first day of the month</Body3>
                            ) : (
                              <Body3 as="div">Not including first day of the month</Body3>
                            )}
                          </div>
                        )}

                        {e.currentEarnings && !employeeClass.currentEarnings && (
                          <Body3 redMedium>{e.currentEarnings}</Body3>
                        )}
                        {!e.currentEarnings && !employeeClass.currentEarnings && <Body3>-</Body3>}
                      </div>
                    </StackY>
                  )}

                  {employeeClass.earningsTimeFrame === "PRIOR_CALENDAR_YEAR_EARNINGS" && (
                    <>
                      {employeeClass.numberOfYears && (
                        <Body3 as="div">
                          {numberOfYearsValuesRecord[employeeClass.numberOfYears]}
                        </Body3>
                      )}

                      {!employeeClass.numberOfYears && !e.numberOfYears && (
                        <Body3 as="div">-</Body3>
                      )}

                      {e.numberOfYears && (
                        <Body3 redMedium as="div">
                          {e.numberOfYears}
                        </Body3>
                      )}
                    </>
                  )}

                  {employeeClass.earningsTimeFrame === "FROZEN_EARNINGS" &&
                    employeeClass.frozenEarningsAsOf && (
                      <Body3 as="div">
                        Frozen earnings as of {employeeClass.frozenEarningsAsOf}
                      </Body3>
                    )}

                  {e.earningsTimeFrame && !employeeClass.earningsTimeFrame && (
                    <Body3 redMedium as="div">
                      {e.earningsTimeFrame}
                    </Body3>
                  )}
                  {!e.earningsTimeFrame && !employeeClass.earningsTimeFrame && (
                    <Body3 as="div">-</Body3>
                  )}

                  {employeeClass.earningsTimeFrame === "FROZEN_EARNINGS" &&
                    e.frozenEarningsAsOf &&
                    !employeeClass.frozenEarningsAsOf && (
                      <Body3 redMedium as="div">
                        {e.frozenEarningsAsOf}
                      </Body3>
                    )}
                  {employeeClass.earningsTimeFrame === "FROZEN_EARNINGS" &&
                    !e.frozenEarningsAsOf &&
                    !employeeClass.frozenEarningsAsOf && <Body3 as="div">-</Body3>}
                </div>

                {employeeClass.additionalCompensations && (
                  <div>
                    <Body5 as="div">Additional compensation</Body5>
                    {employeeClass.additionalCompensations
                      .sort(additionalCompensationsSorter)
                      .map((ac) => readableAdditionalCompensations(ac, employeeClass))
                      .filter((s) => !!s)
                      .map((label) => (
                        <Body3 as="div" key={label}>
                          {label}
                        </Body3>
                      ))}

                    {employeeClass.additionalCompensationsAveragedWithEarnings && (
                      <Body3 as="div">
                        Additional compensation averaged together with earnings
                      </Body3>
                    )}

                    {[
                      e.additionalCompensations,
                      e.averagedOverBonuses,
                      e.averagedOverComissions,
                      e.averagedOverOvertimePay,
                    ].map(
                      (s, index) =>
                        s && (
                          <Body3 redMedium as="div" key={index}>
                            {s}
                          </Body3>
                        ),
                    )}
                  </div>
                )}
              </StackY>
            )}

            {employeeClass.earningsType === "W2_EARNINGS" && (
              <StackY dist={16} wrap={false}>
                <Body3 as="div">W-2 earnings</Body3>

                <div>
                  <Body5 as="div">
                    Average the earnings over the following number of prior calendar years
                  </Body5>

                  {employeeClass.numberOfYears && (
                    <Body3 as="div">{numberOfYearsValuesRecord[employeeClass.numberOfYears]}</Body3>
                  )}

                  {!employeeClass.numberOfYears && !e.numberOfYears && <Body3 as="div">-</Body3>}

                  {e.numberOfYears && <Body3 redMedium>{e.numberOfYears}</Body3>}
                </div>
              </StackY>
            )}

            {employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" && (
              <StackY dist={16} wrap={false}>
                <Body3 as="div">Earnings for Partners, Owners, and/or Shareholders</Body3>

                <div>
                  <Body5 as="div">Form used to report earnings</Body5>

                  {employeeClass.organizationType && (
                    <Body3 as="div">
                      {organizationTypesValuesRecord[employeeClass.organizationType]}
                    </Body3>
                  )}

                  {e.organizationType && !employeeClass.organizationType && (
                    <Body3 redMedium>{e.organizationType}</Body3>
                  )}
                  {!e.organizationType && !employeeClass.organizationType && <Body3>-</Body3>}
                </div>

                {employeeClass.organizationType === "PARTNERSHIP" && (
                  <div>
                    <Body5 as="div">Income type</Body5>

                    {employeeClass.incomeTypePartnership && (
                      <Body3 as="div">
                        {incomeTypesPartnershipValuesRecord[employeeClass.incomeTypePartnership]}
                      </Body3>
                    )}

                    {e.incomeTypePartnership && !employeeClass.incomeTypePartnership && (
                      <Body3 redMedium>{e.incomeTypePartnership}</Body3>
                    )}
                    {!e.incomeTypePartnership && !employeeClass.incomeTypePartnership && (
                      <Body3>-</Body3>
                    )}
                  </div>
                )}

                {client.healthcareProfessionalsSegment &&
                  employeeClass.organizationType === "PARTNERSHIP" && (
                    <div>
                      <Body5 as="div">
                        Is the Schedule K-1 paid by the Policyholder to the Physician's own company?
                      </Body5>

                      {employeeClass.scheduleK1PaidByPolicyholder && (
                        <Body3 as="div">
                          {yesNoValuesRecord[employeeClass.scheduleK1PaidByPolicyholder]}
                        </Body3>
                      )}

                      {e.scheduleK1PaidByPolicyholder &&
                        !employeeClass.scheduleK1PaidByPolicyholder && (
                          <Body3 redMedium>{e.scheduleK1PaidByPolicyholder}</Body3>
                        )}
                      {!e.scheduleK1PaidByPolicyholder &&
                        !employeeClass.scheduleK1PaidByPolicyholder && <Body3>-</Body3>}
                    </div>
                  )}

                {employeeClass.organizationType === "S_CORPORATION" && (
                  <div>
                    <Body5 as="div">Income type</Body5>
                    {employeeClass.incomeTypeSCorporation && (
                      <Body3 as="div">
                        {incomeTypesSCorporationValuesRecord[employeeClass.incomeTypeSCorporation]}
                      </Body3>
                    )}
                    {e.incomeTypeSCorporation && !employeeClass.incomeTypeSCorporation && (
                      <Body3 redMedium>{e.incomeTypeSCorporation}</Body3>
                    )}
                    {!e.incomeTypeSCorporation && !employeeClass.incomeTypeSCorporation && (
                      <Body3>-</Body3>
                    )}
                  </div>
                )}

                {client.healthcareProfessionalsSegment &&
                  employeeClass.organizationType === "S_CORPORATION" && (
                    <div>
                      <Body5 as="div">
                        Are earnings paid to the Shareholders associated company?
                      </Body5>
                      {employeeClass.earningsPaidToShareholdersCompany && (
                        <Body3 as="div">
                          {yesNoValuesRecord[employeeClass.earningsPaidToShareholdersCompany]}
                        </Body3>
                      )}
                      {e.earningsPaidToShareholdersCompany &&
                        !employeeClass.earningsPaidToShareholdersCompany && (
                          <Body3 redMedium>{e.earningsPaidToShareholdersCompany}</Body3>
                        )}
                      {!e.earningsPaidToShareholdersCompany &&
                        !employeeClass.earningsPaidToShareholdersCompany && <Body3>-</Body3>}
                    </div>
                  )}
              </StackY>
            )}

            {e.earningsType && (
              <Body3 redMedium as="div">
                {e.earningsType}
              </Body3>
            )}
          </div>
        )
      )}
    </StackY>
  );
}

function readableAdditionalCompensations(
  additionalCompensation: string,
  employeeClass: EmployeeClass,
): string {
  switch (additionalCompensation) {
    case "Commissions": {
      const label = employeeClass.averagedOverComissions
        ? averagedOverValuesRecord[employeeClass.averagedOverComissions].toLowerCase()
        : "";
      return label ? `Commissions averaged over ${label}` : "";
    }
    case "Bonuses": {
      const label = employeeClass.averagedOverBonuses
        ? averagedOverValuesRecord[employeeClass.averagedOverBonuses].toLowerCase()
        : "";
      return label ? `Bonuses averaged over ${label}` : "";
    }
    case "Overtime pay": {
      const label = employeeClass.averagedOverOvertimePay
        ? averagedOverValuesRecord[employeeClass.averagedOverOvertimePay].toLowerCase()
        : "";
      return label ? `Overtime pay averaged over ${label}` : "";
    }
    default:
      return additionalCompensation;
  }
}
