import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { Body2 } from "client/src/components/Typography/Typography";
import { useSlobId } from "client/src/hooks/useSlobId";
import clsx from "clsx";
import * as styles from "./SegmentedButton.module.less";
import type { ElementLabel } from "client/src/types/ElementLabel";
import type { MouseEventHandler } from "react";

interface HTMLInputElementSegmentedButton<TValue extends string> extends HTMLInputElement {
  value: TValue;
}

export type SegmentedButtonProps<TValue extends string> = {
  variant?: "default" | "small";
  name: string;
  value: TValue | null | undefined;
  disabled?: boolean;
  options: Array<{
    value: TValue;
    label: string;
  }>;
  onClick: MouseEventHandler<HTMLInputElementSegmentedButton<TValue>>;
  touched?: boolean;
  error?: string;
  form?: string;
} & ElementLabel;

export function SegmentedButton<TValue extends string>(props: SegmentedButtonProps<TValue>) {
  const {
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledby,
    variant = "default",
    label,
    disabled,
    name,
    value,
    options,
    onClick,
    touched,
    error,
    form,
  } = props;

  const handleOnClick: typeof onClick = (e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    onClick(e);
  };

  const id = useSlobId({ prefix: name });
  const labelId = `${id}__label ${ariaLabelledby || ""}`.trim();
  const invalid = touched && !!error;
  const errorId = invalid ? `${id}__errormessage` : undefined;

  return (
    <div
      role="radiogroup"
      className={styles.segmentedButton}
      aria-label={ariaLabel}
      aria-labelledby={label ? labelId : ariaLabelledby}
      aria-invalid={invalid}
      aria-errormessage={invalid ? errorId : undefined}
    >
      {label && (
        <p>
          <Body2 as="label" id={labelId}>
            {label}
          </Body2>
        </p>
      )}

      <div className={styles.radios}>
        {options.map((option) => (
          <label
            key={option.value}
            className={clsx(
              styles.radio,
              variant === "small" && styles.radio__small,
              option.value === value && styles.radio__checked,
              disabled && styles.radio__disabled,
            )}
          >
            <input
              type="radio"
              className={styles.radioInput}
              name={name}
              onClick={handleOnClick}
              value={option.value}
              disabled={disabled}
              aria-disabled={disabled}
              form={form}
            />

            {option.label}
          </label>
        ))}
      </div>

      <div aria-live="assertive" className="hide:empty mt-16">
        {invalid && <InputErrorMessage id={errorId} error={error} />}
      </div>
    </div>
  );
}
