import { faChevronDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { clientErrorLabel, clientErrorWarningTypes } from "client/src/hooks/clientError";
import { uniq } from "lodash";
import { type FC, useCallback, memo } from "react";
import { getKeys } from "shared/utils/utils";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { ReactComponent as WarningInfo } from "../../../components/Icons/WarningInfo.svg";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../components/Typography/Typography";
import * as style from "./clientErrorsBanner.module.less";
import type { ClientErrorType } from "@prisma/client";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { Client } from "shared/types/Client";

type ClientErrorsBannerProps = {
  data?: {
    data: Client[];
    meta: {
      count: number;
      countByError?: Record<string, number>;
      countWithErrors?: number;
    };
  };
  filters: ClientErrorType[];
  onChange: (filters: ClientErrorType[]) => void;
};

const warnings = getKeys(clientErrorLabel).filter((clientError) =>
  clientErrorWarningTypes.includes(clientError),
);
const errors = getKeys(clientErrorLabel).filter(
  (clientError) => !clientErrorWarningTypes.includes(clientError),
);

type ErrorFilterProps = {
  data: Record<ClientErrorType, number>;
  filters: ClientErrorType[];
  onChange: (value: ClientErrorType[]) => void;
};
const ErrorFilter: FC<ErrorFilterProps> = ({ data, filters, onChange }) => {
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- help Checkbox remember the value type
      const { checked, value } = e.target as { checked: boolean; value: ClientErrorType };
      const newSelected = checked ? [...filters, value] : filters.filter((s) => s !== value);
      onChange(newSelected);
    },
    [onChange, filters],
  );

  return (
    <div>
      <div className={style.filterHeader}>
        <span className={style.iconError}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <Body2>Errors</Body2>
        {errors.some((error) => !filters.includes(error)) ? (
          <Button
            type="text"
            className={style.toggleAll}
            onClick={() => onChange(uniq([...errors, ...filters]))}
          >
            Select all
          </Button>
        ) : (
          <Button
            type="text"
            className={style.toggleAll}
            onClick={() => onChange(filters.filter((filter) => !errors.includes(filter)))}
          >
            Unselect all
          </Button>
        )}
      </div>
      <StackY dist={8} className={style.filterContent}>
        {errors.map((error) => (
          <Checkbox
            key={error}
            name="clientErrorFilter"
            label={`${clientErrorLabel[error]} (${data[error]})`}
            value={error}
            checked={filters.includes(error)}
            onChange={handleChange}
          />
        ))}
      </StackY>
      <div className={style.filterHeader}>
        <span className={style.iconWarning}>
          <WarningInfo width={16} height={16} />
        </span>
        <Body2>Warnings</Body2>
        {warnings.some((warning) => !filters.includes(warning)) ? (
          <Button
            type="text"
            size="small"
            className={style.toggleAll}
            onClick={() => onChange(uniq([...warnings, ...filters]))}
          >
            Select all
          </Button>
        ) : (
          <Button
            type="text"
            size="small"
            className={style.toggleAll}
            onClick={() => onChange(filters.filter((filter) => !warnings.includes(filter)))}
          >
            Unselect all
          </Button>
        )}
      </div>
      <StackY dist={8} className={style.filterContent}>
        {warnings.map((warning) => (
          <Checkbox
            key={warning}
            name="clientErrorFilter"
            label={`${clientErrorLabel[warning]} (${data[warning]})`}
            value={warning}
            checked={filters.includes(warning)}
            onChange={handleChange}
          />
        ))}
      </StackY>
    </div>
  );
};

export const ClientErrorsBanner: FC<ClientErrorsBannerProps> = memo(
  ({ data, filters, onChange }) => {
    if (!data) {
      return null;
    }

    const countByError = data.meta.countByError || {};
    const errorCount = Object.values(countByError).reduce((sum, item) => sum + item, 0);

    if (!errorCount) {
      return null;
    }

    return (
      <AlertBanner
        variant="error"
        message={
          <>
            <Body3>
              There are <strong>{errorCount}</strong> issues across{" "}
              <strong>{data.meta.countWithErrors}</strong> clients. See client specific
              troubleshooting help each client row.
            </Body3>
            <Popover
              content={<ErrorFilter data={countByError} filters={filters} onChange={onChange} />}
              trigger="click"
              placement="bottom"
              // open={filtersOpen}
              onOpenChange={console.log}
            >
              <Button
                type="text"
                size="xtra-small"
                aria-label="View more"
                className={style.viewMoreBtn}
              >
                <StackX dist={8}>
                  <span>Filter by issue type</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </StackX>
              </Button>
            </Popover>
          </>
        }
      />
    );
  },
);
