import { formatDate } from "shared/utils/format";

import { Anchor } from "../../components/Anchor/Anchor";

import * as styles from "./policyList.module.less";

import type { Client } from "shared/types/Client";

export type KickoffCallProps = {
  client: Client;
  jiraUrl: string;
};

export const PolicyList = ({ client, jiraUrl }: KickoffCallProps) => {
  return (
    <>
      {client.policies.map((policy, index) => (
        <div key={policy.ticketId} className={index > 0 ? styles.panelList : undefined}>
          {policy.policyEffective ? formatDate(policy.policyEffective) : "-"},&nbsp;
          {policy.slfPolicyNumber ? `#${policy.slfPolicyNumber}` : "-"}
          <br />
          {policy.primaryPolicy ? (
            <>
              (Primary)
              <br />
            </>
          ) : null}
          <Anchor href={`${jiraUrl}/browse/${policy.ticketId}`} target="_blank">
            View in Jira
          </Anchor>
        </div>
      ))}
    </>
  );
};
