import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { ClientListFilterModal } from "./ClientListFilterModal";
import type { ClientTableFilters } from "../../../../../../shared/types/Client";
import type { SlfImplementationConsultant } from "../../../../../../shared/types/User";
import type { SlobListFilterComponentProps } from "../../../../components/SlobList/SlobList";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type ClientListFilterButtonProps = {
  implementationConsultants: SlfImplementationConsultant[];
  ebrEmails: string[];
  variant: "internal" | "broker";
  featureToggles: ClientFeatureToggles | undefined;
} & SlobListFilterComponentProps<ClientTableFilters>;

export const ClientListFilterButton = (props: ClientListFilterButtonProps) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const filterCount = useMemo(() => {
    if (!props.filters) return 0;

    return Object.values(props.filters).reduce((count, value) => {
      if (Array.isArray(value)) {
        return count + value.length;
      } else {
        return count + (value ? 1 : 0);
      }
    }, 0);
  }, [props.filters]);

  return (
    <>
      <Button type="secondary" onClick={() => setShowFilterModal(true)}>
        <FontAwesomeIcon icon={faSlidersH} className="mr-8" />
        {`Filters • ${filterCount}`}
      </Button>
      <ClientListFilterModal
        {...props}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        onFiltersChanged={(newFilters) => {
          props.onFiltersChanged(newFilters);
          setShowFilterModal(false);
        }}
        onFiltersReset={() => {
          props.onFiltersReset();
          setShowFilterModal(false);
        }}
      />
    </>
  );
};
