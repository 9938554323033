import { useCallback, useState } from "react";

import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { Anchor } from "../../../components/Anchor/Anchor";
import { taskStatusToLabel } from "../../../components/Badge/Badge";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { GuidanceModal } from "../../../components/GuidanceModal/GuidanceModal";
import { HubCardSection } from "../../../components/HubCard/HubCardSection";
import { StackY } from "../../../components/Spacing/Spacing";
import { SunLifeConnectCard } from "../../../domain/Client/SunLifeConnectCard";
import { SunLifeConnectForm } from "../../../domain/Client/SunLifeConnectForm";
import { useSlobAuth } from "../../../hooks/auth";
import { useUpdateClient } from "../../../hooks/client";
import { useTitle } from "../../../hooks/useTitle";
import { useToggler } from "../../../hooks/useToggler";
import { useTrackElementClicked } from "../../../utils/analytics";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import * as styles from "./SunLifeConnect.module.less";
import { ReactComponent as EOIIcon } from "./icon-eoi-wrapper.svg";

import type { Client } from "shared/types/Client";

export type SunLifeConnectProps = {
  client: Client;
};

export function SunLifeConnectPage({ client }: SunLifeConnectProps): JSX.Element | null {
  useTitle("Sun Life Connect");
  const trackElementClicked = useTrackElementClicked(client, "Sun Life Connect");
  const [editing, toggleEditing] = useToggler();
  const [guidanceModalVisible, setGuidanceModalVisible] = useState(false);
  const task = client.tasks?.find((task) => task.id === "sun-life-connect");

  const remainingTasks = hasRemainingHubTasks(client);

  const usingDEIF = Boolean(client.digitalEIF);
  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const { mutateAsync: updateClient } = useUpdateClient();

  const learnClick = useCallback(() => {
    trackElementClicked({
      moduleState: task?.status ? taskStatusToLabel[task.status] : undefined,
      buttonLabel: "Adding administrators docs",
    });
  }, [trackElementClicked, task]);
  const watchVidoeClick = useCallback(() => {
    trackElementClicked({
      moduleState: task?.status ? taskStatusToLabel[task.status] : undefined,
      buttonLabel: "How to register video",
    });
  }, [trackElementClicked, task]);
  if (!task) {
    return null;
  }
  const isNotStarted = task.status === "Not Started" || task.status === "Upcoming";
  const policiesPolicyNumbers = client.policies
    .map((p) => p.slfPolicyNumber)
    .filter((p): p is string => Boolean(p));

  const handlePolicyNumber = (policyNumbers: string[]) => {
    if (!policyNumbers || !Array.isArray(policyNumbers)) {
      return (
        <p>
          There is no policy number at this time. Your Primary Web Administrator will need this to
          complete their registration. Please reach out to your IC.
        </p>
      );
    }
    if (policyNumbers.length === 1) {
      return (
        <p>
          Your policy number is {policyNumbers[0]} and your Primary Web Administrator will need this
          to complete their registration.
        </p>
      );
    }
    return (
      <p>
        Your policy numbers are {policyNumbers.join(", ")} and your Primary Web Administrator will
        need this to complete their registration.
      </p>
    );
  };
  const initialSubmitMessage =
    "An email will be sent to your designated Primary Web Administrator within 1-2 business days. Look for an email from sun.life.financial.online@sunlife.com. Be sure to double check spam folders. If you or they haven’t received the email, please reach out to your IC directly.";
  const completedMessage = {
    heading: "Need to edit this person?",
    body: "Please feel free to do so if you’ve identified another person to take on this responsibility. Your Implementation Consultant will be notified that you changed contacts and will send out another email to them.",
  };

  return (
    <ChildPageLayout
      topNavTitle="Get set up on Sun Life Connect"
      aside={
        <>
          <h1>Get set up on Sun Life Connect</h1>

          <p>
            Once your benefits are effective, Sun Life Connect is your destination for managing your
            account, employees, and claims with us.
          </p>
          <p>
            Complete this task by having your Primary Web Administrator register for Sun Life
            Connect.
          </p>
        </>
      }
      contents={
        <>
          <HubCardSection
            title="What is Sun Life Connect?"
            content={
              <p>
                This is a separate system from Onboard (where you are right now) and requires a
                separate registration process. The reason this is separate today is because Sun Life
                Connect is the system for managing your benefits ongoing.
                <br />
                Registration for Sun Life Connect <strong>is required</strong> to access your bill,
                receive bill-ready emails, opt-in for ePay, manage your employees, view claims
                information, Evidence of Insurability (EOI) and benefit details.
                <br />
                Depending on your benefits and administration (billing) type, Sun Life Connect is
                the place to go for:
              </p>
            }
          />
          <StackY dist={40}>
            <table className={styles.detailsTable}>
              <tbody>
                <tr>
                  <th>Managing Your Account</th>
                  <th>Managing Employees</th>
                </tr>
                <tr>
                  <td>Change settings, including company information.</td>
                  <td>Add, remove, and manage your employees.</td>
                </tr>
                <tr className={styles.spaced}>
                  <th>Managing Disability Claims</th>
                  <th>Managing Billing</th>
                </tr>
                <tr>
                  <td>Complete your employees’ claims and get real time status updates on them.</td>
                  <td>
                    <strong>
                      <em>Sun Life is paperless billing!</em>
                    </strong>{" "}
                    No need to wait to receive your bill in the mail. You can easily view and print
                    your bill and run premium reports through Sun Life Connect.
                  </td>
                </tr>
                <tr className={styles.spaced}>
                  <th>Viewing Policy Documents</th>
                  <th>Pay Your Bill</th>
                </tr>
                <tr>
                  <td>Documents can be downloaded, printed, and emailed.</td>
                  <td>Set up your payment profile and opt in for ePay.</td>
                </tr>
              </tbody>
            </table>
            {client.eoiRequired && (
              <div className={styles.eoiBanner}>
                <EOIIcon />
                <p>
                  <strong>
                    Sun Life Connect is necessary for your Evidence of Insurability (EOI)
                    connections.
                  </strong>{" "}
                  Don’t delay in setting up your account! Delaying could impact your employees’ EOI
                  experience.
                </p>
              </div>
            )}
            {!isInternalUser && (
              <GuidanceModal
                isVisible={guidanceModalVisible}
                remainingTasks={remainingTasks}
                clientId={client.id}
                nextText={
                  <>
                    <div className="body3 mb-40">{initialSubmitMessage}</div>
                    <div className="body2 mb-8">{completedMessage.heading}</div>
                    <div className="body3">{completedMessage.body}</div>
                  </>
                }
                titleText="Your info has been submitted!"
                onCancel={() => setGuidanceModalVisible(false)}
                taskStatus={task.status}
                trackElementClicked={trackElementClicked}
              />
            )}
            {editing || isNotStarted ? (
              <SunLifeConnectForm
                client={client}
                task={task}
                toggleEditing={isNotStarted ? undefined : toggleEditing}
                updateClient={updateClient}
                trackElementClicked={trackElementClicked}
                isInitialSave={isNotStarted}
                onInitialSave={setGuidanceModalVisible}
              />
            ) : (
              <SunLifeConnectCard
                client={client}
                task={task}
                toggleEditing={toggleEditing}
                trackElementClicked={trackElementClicked}
                initialSubmitMessage={initialSubmitMessage}
                completedMessage={completedMessage}
                usingDEIF={usingDEIF}
              />
            )}
            <div>
              <h3>Looking to add additional administrators? </h3>

              <p>
                Once your Primary Web Administrator is registered, they can add additional users to
                Sun Life Connect.
              </p>
              <p>
                <Anchor
                  href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4402387140883"
                  target="_blank"
                  onClick={learnClick}
                >
                  Learn how to add additional administrator accounts
                </Anchor>
              </p>
            </div>
            <div>
              <h3>Tips for completing this task</h3>
              <StackY dist={24}>
                <div>
                  <b className="body2">Have your policy # handy</b>
                  {handlePolicyNumber(policiesPolicyNumbers)}
                </div>
                <div>
                  <b className="body2">Still need help registering?</b>
                  <p>
                    <Anchor
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403016014099"
                      target="_blank"
                      onClick={watchVidoeClick}
                    >
                      Watch our video walkthrough
                    </Anchor>{" "}
                    on how to register.
                  </p>
                </div>
              </StackY>
            </div>
          </StackY>
        </>
      }
    />
  );
}
