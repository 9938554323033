import { Row, Col } from "client/src/components/Grid/Grid";

import { StackY } from "client/src/components/Spacing/Spacing";
import { Button } from "../../../components/Button/Button";
import { FormInput } from "../../../components/Form/Input";
import { Modal } from "../../../components/Modal/Modal";
import { urlForExplorerPage } from "../utils";

import type { ExplorerPageId, ExplorerPageVanitySlug } from "shared/types/ExplorerPage";

type ExplorerPageCreatedModalProps = {
  visible: boolean;
  onCancel: () => void;
  pageId: ExplorerPageId;
  pageVanitySlug?: ExplorerPageVanitySlug | null;
  pageName: string;
};

export const ExplorerPageCreatedModal = ({
  visible,
  onCancel,
  pageId,
  pageVanitySlug,
  pageName,
}: ExplorerPageCreatedModalProps) => {
  const explorerPageURL = urlForExplorerPage(pageVanitySlug ?? pageId, !!pageVanitySlug);
  return (
    <Modal open={visible} title="Explorer Page Created!" footer={null} onCancel={onCancel}>
      <Row justify="center" align="bottom">
        <Col flex={1}>
          <StackY dist={24} style={{ width: "100%" }}>
            <div>
              <p className="body2" style={{ marginBottom: "10px" }}>
                Page Name
              </p>
              <p className="body1">{pageName}</p>
            </div>
            <FormInput
              label="Site URL"
              topText="Site URL"
              value={explorerPageURL}
              maxLength={1000}
              name="siteURL"
              disabled={true}
              touched={undefined}
              error={undefined}
            />
            <Row justify="center">
              <Button
                size="middle"
                onClick={async () => {
                  if ("clipboard" in navigator) {
                    return await navigator.clipboard.writeText(explorerPageURL);
                  } else {
                    return document.execCommand("copy", true, explorerPageURL);
                  }
                }}
              >
                Copy URL
              </Button>
            </Row>
          </StackY>
        </Col>
      </Row>
    </Modal>
  );
};
