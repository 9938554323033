import { useState } from "react";

import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import { ProgressOverlayContainer } from "../../../components/ProgressOverlayContainer/ProgressOverlayContainer";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body1 } from "../../../components/Typography/Typography";
import { DownloadFileTable } from "../DownloadFileTable/DownloadFileTable";

import * as styles from "./documentDownload.module.less";

import type { ClientId, PolicyId } from "../../../../../shared/types/Client";
import type {
  Document,
  CensusDocument,
  DocumentCategory,
  DocumentId,
} from "../../../../../shared/types/Document";
import type { WebLink, WebLinkCategory, WebLinkId } from "../../../../../shared/types/WebLink";
import type { CensusSource } from "../../../../../shared/validation/document";
import type { ResponseError, ResponseToMutationReturnType } from "../../../hooks/query";
import type { ResourceRowData } from "../DownloadFileTable/DownloadFileTable";
import type { ReactNode } from "react";

let overlayContainerIdCounter = 0;

export type DocumentDownloadPresentationProps = {
  clientId: ClientId;

  documents: CensusDocument[];
  webLinks: WebLink[];

  isGeneratingZippedDocument: boolean;
  isLoadingDeleteDocument: boolean;
  isLoadingDeleteWebLink: boolean;

  errorGeneratingZippedFile: ResponseError | boolean;
  errorDeleteDocument: ResponseError | null;
  errorDeleteWebLink: ResponseError | null;
  errorDocumentURL: ResponseError | null;

  downloadDocument: (id: string | number | undefined) => void;
  renameDocument: (args: {
    data: { name: string };
    params: { documentId: DocumentId; clientId: ClientId };
  }) => Promise<ResponseToMutationReturnType<Document>>;
  requestZipFile: (docIdsToZip: DocumentId[]) => void;
  downloadExpandedRowRender?: (record: ResourceRowData) => ReactNode;

  documentDeleteConfirmDialogVisible: boolean;
  documentToDeleteName: string;
  documentPolicyIds: PolicyId[] | undefined;
  openDocumentDeletionConfirmDialog: (
    documentId: DocumentId,
    name: string,
    category: DocumentCategory,
    policyIds?: PolicyId[],
  ) => void;
  handleConfirmDeleteDocument: () => Promise<void>;
  resetDeleteDocumentParams: () => void;

  webLinkDeleteConfirmDialogVisible: boolean;
  webLinkToDeleteName: string;
  openWebLinkDeletionConfirmDialog: (
    webLinkId: WebLinkId,
    name: string,
    category: WebLinkCategory,
  ) => void;
  handleConfirmDeleteWebLink: () => Promise<void>;
  resetDeleteWebLinkParams: () => void;

  empty: ReactNode;
  readonly: boolean;
  showUploadUser: boolean;
  actionsDisabled: boolean;
  newDocumentNotices: Set<string>;
  columnTitleOverrides?: { title: string; key: string }[];
  showInReviewDeleteText: boolean;
  showResourceTypeColumn?: boolean;
  showBenefitTypeColumn?: boolean;
  withSource?: boolean;
  showDownloadAllButton?: boolean;
  showDownloadButton?: boolean;
  showProcessStatusValidation?: boolean;

  track: (buttonLabel: string) => void;
};

export function DocumentDownloadPresentation(props: DocumentDownloadPresentationProps) {
  const {
    clientId,

    // entities
    documents,
    webLinks,

    // loading
    isGeneratingZippedDocument,
    isLoadingDeleteDocument,
    isLoadingDeleteWebLink,

    // errors
    errorGeneratingZippedFile,
    errorDeleteDocument,
    errorDeleteWebLink,
    errorDocumentURL,

    // actions
    downloadDocument,
    renameDocument,
    requestZipFile,

    // delete documents
    documentDeleteConfirmDialogVisible,
    documentToDeleteName,
    documentPolicyIds,
    openDocumentDeletionConfirmDialog,
    handleConfirmDeleteDocument,
    resetDeleteDocumentParams,

    // delete web links
    webLinkDeleteConfirmDialogVisible,
    webLinkToDeleteName,
    openWebLinkDeletionConfirmDialog,
    handleConfirmDeleteWebLink,
    resetDeleteWebLinkParams,

    // misc
    empty,
    readonly,
    showUploadUser,
    actionsDisabled,
    newDocumentNotices,
    columnTitleOverrides,
    showInReviewDeleteText,
    showBenefitTypeColumn = false,
    showResourceTypeColumn = false,
    withSource = false,
    showDownloadAllButton = true,
    showDownloadButton = true,
    showProcessStatusValidation = false,
    downloadExpandedRowRender,

    track,
  } = props;

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- .
  const [overlayContainerId] = useState(() => overlayContainerIdCounter++);

  const documentIds = (documents ?? []).map(({ id }) => id);

  const resourcesList = [...documents, ...webLinks]
    .map((item) => convertToResource(item, showUploadUser, newDocumentNotices))
    .sort((a, b) => b.updatedAt - a.updatedAt);

  if (resourcesList.length === 0 && empty != null) {
    return <>{empty}</>;
  }

  if (resourcesList.length === 0) return null;

  const multiPolicyFile = (documentPolicyIds?.length || 0) > 1;
  const multiPolicyDeleteAlert = multiPolicyFile
    ? " This file will be deleted from all policies. "
    : " ";

  return (
    <>
      <ProgressOverlayContainer
        id={`document-download-${overlayContainerId.toString(36)}`}
        showProgress={isGeneratingZippedDocument}
        progressBarText="Generating your zip file…"
      >
        <StackY dist={32}>
          {errorGeneratingZippedFile && (
            <div className={styles.downloadAllErrorWrapper}>
              <ErrorMessage children="There was an issue generating the zip file. Please try again." />
            </div>
          )}

          <DownloadFileTable
            resourcesList={resourcesList}
            showDownloadAllButton={showDownloadAllButton}
            showDownloadButton={showDownloadButton}
            showBenefitTypeColumn={showBenefitTypeColumn}
            showResourceTypeColumn={showResourceTypeColumn}
            downloadAllAction={() => {
              track("Download All");
              requestZipFile(documentIds);
            }}
            downloadDocument={(id: string) => {
              track("File download");
              downloadDocument(id);
            }}
            downloadExpandedRowRender={downloadExpandedRowRender}
            deleteDocument={openDocumentDeletionConfirmDialog}
            renameDocument={async (id: string, newName: string) => {
              track("File rename");
              await renameDocument({
                data: { name: newName },
                params: { documentId: id, clientId },
              });
            }}
            deleteWebLink={openWebLinkDeletionConfirmDialog}
            disabled={actionsDisabled}
            readonly={readonly}
            withAddedBy
            withSource={withSource}
            showProcessStatusValidation={showProcessStatusValidation}
            track={track}
            columnTitleOverrides={columnTitleOverrides}
          />

          {(errorDeleteDocument || errorDeleteWebLink || errorDocumentURL) && (
            <LoadingError
              type="component"
              error={errorDeleteDocument || errorDeleteWebLink || errorDocumentURL}
              title={GenericErrorCopy2}
            />
          )}
        </StackY>
      </ProgressOverlayContainer>

      <ConfirmDialog
        title={"Delete file"}
        isVisible={documentDeleteConfirmDialogVisible}
        isLoading={isLoadingDeleteDocument}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={handleConfirmDeleteDocument}
        onCancel={resetDeleteDocumentParams}
      >
        {showInReviewDeleteText ? (
          <Body1 as="p">
            This file has already been submitted for review.{multiPolicyDeleteAlert}Are you sure you{" "}
            want to delete: {documentToDeleteName}?
          </Body1>
        ) : (
          <Body1 as="p">
            {multiPolicyDeleteAlert}Are you sure you want to delete: {documentToDeleteName}?
          </Body1>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        title={"Delete link"}
        isVisible={webLinkDeleteConfirmDialogVisible}
        isLoading={isLoadingDeleteWebLink}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={handleConfirmDeleteWebLink}
        onCancel={resetDeleteWebLinkParams}
      >
        <Body1 as="p">Are you sure you want to delete this web link: {webLinkToDeleteName}?</Body1>
      </ConfirmDialog>
    </>
  );
}

type ConvertToResourceOptions = {
  addedByPrefix?: string;
};

export function convertToResource(
  resource: CensusDocument | WebLink,
  showUploadUser: boolean,
  newDocumentNotices: Set<string>,
  { addedByPrefix = "" }: ConvertToResourceOptions = {},
): ResourceRowData {
  const createdByName = resource.createdByUser
    ? `${resource.createdByUser.firstName} ${resource.createdByUser.lastName}`
    : "No record of who uploaded this resource";
  const addedBy = showUploadUser
    ? `${addedByPrefix}${String(createdByName)} on ${resource.createdAt.toDateString()}`
    : `${resource.createdAt.toDateString()}`;
  const key = String(resource.id);
  const id = resource.id;
  const updatedAt = resource.updatedAt.getTime();
  const isNew = newDocumentNotices.has(resource.id);
  const policyIds = resource.policyIds;

  if ("name" in resource) {
    const source: CensusSource | string | null =
      resource.censusSource === "EXPORT" ? resource.platform : resource.censusSource;
    return {
      key,
      id,
      addedBy,
      updatedAt,
      type: "document" as const,
      name: resource.name,
      category: resource.category,
      policyIds,
      processStatus: resource.processStatus,
      benefitTypes: resource.benefitTypes,
      source,
      isNew,
    };
  } else {
    return {
      key,
      id,
      addedBy,
      updatedAt,
      type: "webLink" as const,
      text: resource.text,
      category: resource.category,
      policyIds,
      benefitTypes: resource.benefitTypes,
      url: resource.url,
      isNew,
    };
  }
}
