import { getIn, type FormikProps } from "formik";
import type { Client } from "shared/types/Client";

type GetHasPendingEditParams = {
  field: string;
  client: Client;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- .
  formik: FormikProps<any>;
  variant?: "number" | "checkbox";
};

export const getHasPendingEdit = ({ field, client, formik, variant }: GetHasPendingEditParams) => {
  if (!client.eifSignedAt) {
    return false;
  }

  if (field === "waitingPeriodTypeFOFM") {
    return (
      (getIn(formik.values, "waitingPeriodType") === "FOFM" &&
        getIn(formik.initialValues, "waitingPeriodType") !== "FOFM") ||
      (getIn(formik.initialValues, "waitingPeriodType") === "FOFM" &&
        getIn(formik.values, "waitingPeriodType") !== "FOFM")
    );
  }

  if (field === "waitingPeriodTypeNONE") {
    return (
      (getIn(formik.values, "waitingPeriodType") === "NONE" &&
        getIn(formik.initialValues, "waitingPeriodType") !== "NONE") ||
      (getIn(formik.initialValues, "waitingPeriodType") === "NONE" &&
        getIn(formik.values, "waitingPeriodType") !== "NONE")
    );
  }

  if (field === "waitingPeriodTypeDAY_MONTH") {
    return (
      (getIn(formik.values, "waitingPeriodType") === "DAY_MONTH" &&
        getIn(formik.initialValues, "waitingPeriodType") !== "DAY_MONTH") ||
      (getIn(formik.initialValues, "waitingPeriodType") === "DAY_MONTH" &&
        getIn(formik.values, "waitingPeriodType") !== "DAY_MONTH")
    );
  }

  const initialValue = getIn(formik.initialValues, field);
  const currentValue = getIn(formik.values, field);

  if (Array.isArray(currentValue) && Array.isArray(initialValue)) {
    return !(
      currentValue.length === initialValue.length &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- .
      currentValue.every((value: any) => initialValue.includes(value))
    );
  }

  if (
    currentValue &&
    initialValue &&
    typeof currentValue === "object" &&
    typeof initialValue === "object"
  ) {
    const hasPendingEdit = JSON.stringify(currentValue) !== JSON.stringify(initialValue);
    return hasPendingEdit;
  }

  // Unchecked boxes are saved as null on the server, but in formik until saving they are false
  // so need to account for this specifically.
  if (variant === "checkbox") {
    return currentValue !== initialValue && !(initialValue === null && currentValue === false);
  }

  // This is needed because the initial value of fields that are `int` are of type number
  // but when edited in formik, they become strings, so in order to compare correctly we need the `parseInt`
  if (variant === "number") {
    return (
      currentValue !== initialValue &&
      parseInt(currentValue) !== parseInt(initialValue) &&
      !(initialValue === null && currentValue === "")
    );
  }

  const initialAndCurrentFalsey =
    (currentValue === null || currentValue === undefined || currentValue === "") &&
    (initialValue === null || initialValue === undefined);
  return currentValue !== initialValue && !initialAndCurrentFalsey;
};
