import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { LoadingError } from "./LoadingError";
import type { FallbackRender } from "@sentry/react";
import type { Location } from "react-router-dom";

export const BoundaryError: FallbackRender = ({ resetError, error }) => {
  const location = useLocation();
  const [lastLocation, setLastlocation] = useState<Location>(location);
  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    setLastlocation(location);
    return () => {
      location !== lastLocation && resetError();
    };
  }, [lastLocation, location, resetError]);

  return <LoadingError type="page" error={error} />;
};
