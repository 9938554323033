import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { getShowExplorerPageEnrollButton } from "client/src/utils/getShowExplorerPageEnrollButton";
import clsx from "clsx";
import React, { createRef, forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { Button } from "../../../components/Button/Button";
import { CollapsePanel } from "../../../components/Collapse/CollapsePanel";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../components/Typography/Typography";
import { useTranslation } from "../../../i18n";
import { useExplorerTrackElementClicked } from "../../../utils/analytics";

import * as styles from "./clientPage.module.less";

import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  ExplorerMenuMobile: "explorer-menu-mobile",
  ExplorerMenuWrapper: "explorer-menu-wrapper",
  ExplorerMenuCheckmark: "explorer-menu-checkmark",
};

type ExplorerMenuProps = {
  explorerPage: PublicExplorerPage;
  plans: ExplorerPageBenefit[];
  onSelectBenefit: (benefitType: BenefitTypeBenEx) => void;
  activeBenefitType: BenefitTypeBenEx | null;
  sticky?: boolean;
  viewedBenefits?: BenefitTypeBenEx[];
};

export const ExplorerMenu = forwardRef<HTMLDivElement, ExplorerMenuProps>(
  (
    {
      explorerPage,
      onSelectBenefit,
      activeBenefitType,
      sticky = false,
      plans,
      viewedBenefits = [],
    },
    ref,
  ) => {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const menuItems = useMenuItems(plans);
    useScrollToActiveItem(activeBenefitType, menuItems, scrollRef);

    const trackElementClicked = useExplorerTrackElementClicked(explorerPage);

    const slide = (shift: number) => {
      if (scrollRef.current) {
        scrollRef.current.scrollBy({ left: shift, behavior: "smooth" });
      }
    };

    // use callback ref to be able to trigger inital onScroll() after ref is set
    const callbackRef = useCallback((node: HTMLDivElement | null) => {
      scrollRef.current = node;
      if (node) {
        setTimeout(() => {
          onScroll();
        });
      }
    }, []);

    const onScroll = () => {
      if (scrollRef.current) {
        setShowRightArrow(
          scrollRef.current.scrollLeft + scrollRef.current.clientWidth <
            scrollRef.current.scrollWidth,
        );
        setShowLeftArrow(scrollRef.current.scrollLeft !== 0);
      }
    };

    const breakpoint = useBreakpoint();

    const showEnrollButton = explorerPage && getShowExplorerPageEnrollButton(explorerPage);

    return !breakpoint.md ? (
      <ExplorerMenuMobile
        ref={ref}
        activeBenefit={activeBenefitType}
        items={menuItems}
        onSelectBenefit={(benefitType) => {
          onSelectBenefit(benefitType);
        }}
        viewedBenefits={viewedBenefits}
        benAdminUrl={showEnrollButton ? explorerPage.benAdminPlatformUrl ?? undefined : undefined}
      />
    ) : (
      <div className={styles.overlayWrapper} data-testid={DataTestId.ExplorerMenuWrapper}>
        {showLeftArrow && (
          <div
            className={clsx(styles.btnOverlay, styles.leftOverlay, sticky ? styles.sticky : null)}
          >
            <Button
              type="text"
              aria-label="left"
              onClick={() => {
                trackElementClicked({ module: "Explorer Menu", buttonLabel: "Scroll Left" });
                slide(-500);
              }}
            >
              <LeftCircleFilled style={{ fontSize: "250%" }} alt="left arrow" />
            </Button>
          </div>
        )}
        <div className={styles.scrollmenu} ref={callbackRef} onScroll={onScroll}>
          <Row ref={ref} wrap={false} className={clsx("py-16")}>
            {menuItems.map((item, index) => (
              <Col key={String(index)}>
                <div ref={item.ref}>
                  <ButtonOld // @todo: migrate to html button https://maxwellhealth.atlassian.net/browse/FP-3519
                    size="middle"
                    type="link"
                    className={clsx(
                      styles.menuLink,
                      item.benefitType === activeBenefitType ? styles.menuActiveLink : null,
                    )}
                    onClick={() => {
                      onSelectBenefit(item.benefitType);
                    }}
                  >
                    <span className={styles.linkText}>
                      {viewedBenefits.includes(item.benefitType) && (
                        <FontAwesomeIcon
                          data-testid={DataTestId.ExplorerMenuCheckmark}
                          icon={faCheck}
                          className="mr-8"
                        />
                      )}
                      {item.benefitName}
                    </span>
                  </ButtonOld>

                  {/* <div
                  className={clsx(
                    styles.arrow,
                    benefit.benefitType !== activeBenefitType ? styles.inactive : null,
                  )}
                ></div> */}
                </div>
              </Col>
            ))}
          </Row>
        </div>
        {showRightArrow && (
          <div
            className={clsx(styles.btnOverlay, styles.rightOverlay, sticky ? styles.sticky : null)}
          >
            <Button
              type="text"
              aria-label="right"
              onClick={() => {
                trackElementClicked({ module: "Explorer Menu", buttonLabel: "Scroll Right" });
                slide(+500);
              }}
            >
              <RightCircleFilled style={{ fontSize: "250%" }} alt="right arrow" />
            </Button>
          </div>
        )}
      </div>
    );
  },
);

type ExplorerMenuMobileProps = {
  activeBenefit: BenefitTypeBenEx | null;
  items: MenuItem[];
  onSelectBenefit: (benefitType: BenefitTypeBenEx) => void;
  viewedBenefits?: BenefitTypeBenEx[];
  benAdminUrl?: string;
};

const ExplorerMenuMobile = forwardRef<HTMLDivElement, ExplorerMenuMobileProps>(
  (
    { activeBenefit, items, onSelectBenefit, viewedBenefits = [] }: ExplorerMenuMobileProps,
    ref,
  ) => {
    const [activeKey, setActiveKey] = useState<Array<string> | string>([]);
    return (
      <div ref={ref} data-testid={DataTestId.ExplorerMenuMobile}>
        <CollapsePanel
          variant="default"
          activeKey={activeKey}
          onChange={(k) => setActiveKey(k)}
          content={[
            {
              key: "benefits",
              title: (
                <Row style={{ width: "100%" }}>
                  <Col span={24} className={clsx("py-12", "pl-24")}>
                    <Body2>
                      {items.find((benefit) => benefit.benefitType === activeBenefit)
                        ?.benefitName ?? ""}
                    </Body2>
                  </Col>
                </Row>
              ),
              description: (
                <StackY dist={12}>
                  {items.map((benefit) => (
                    <Row key={benefit.benefitType}>
                      <Col className={clsx("py-8", "pl-24")}>
                        <ButtonOld
                          type="link-inline"
                          onClick={() => {
                            setActiveKey([]);
                            // using timeout to avoid a jerky scroll
                            setTimeout(() => {
                              onSelectBenefit(benefit.benefitType);
                            }, 200);
                          }}
                        >
                          <div>
                            <Body3>
                              {viewedBenefits.includes(benefit.benefitType) && (
                                <FontAwesomeIcon
                                  data-testid={DataTestId.ExplorerMenuCheckmark}
                                  icon={faCheck}
                                  className="mr-8"
                                />
                              )}
                              {benefit.benefitName}
                            </Body3>
                          </div>
                        </ButtonOld>
                      </Col>
                    </Row>
                  ))}
                </StackY>
              ),
            },
          ]}
        />
      </div>
    );
  },
);

type MenuItem = {
  benefitType: BenefitTypeBenEx;
  benefitName: string;
  ref: React.RefObject<HTMLDivElement>;
};
const useMenuItems = (benefits: ExplorerPageBenefit[]): MenuItem[] => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitsNames" });

  useEffect(() => {
    setMenuItems(
      benefits.reduce<MenuItem[]>((acc, val) => {
        if (!acc.some(({ benefitType }) => benefitType === val.benefitType)) {
          return acc.concat([
            {
              benefitType: val.benefitType,
              benefitName: t(val.benefitType),
              ref: createRef<HTMLDivElement>(),
            },
          ]);
        }
        return acc;
      }, []),
    );
  }, [benefits, t]);

  return menuItems;
};

const useScrollToActiveItem = (
  activeBenefitType: BenefitTypeBenEx | null,
  menuItems: MenuItem[],
  scrollRef: React.RefObject<HTMLElement>,
) =>
  useEffect(() => {
    if (!activeBenefitType) return;

    // calculate active item scroll offset
    const activeItemPosition = menuItems.slice(0).reduce<{ x: number; width: number }>(
      (acc, val, i, arr) => {
        if (val.benefitType === activeBenefitType) {
          acc.width = val.ref.current?.clientWidth ?? 0;
          arr.splice(i + 1);
          return acc;
        }

        acc.x += val.ref.current?.clientWidth ?? 0;
        return acc;
      },
      { x: 0, width: 0 },
    );

    const scrollLeft = scrollRef.current?.scrollLeft ?? 0;
    const scrollClientWidth = scrollRef.current?.clientWidth ?? 0;

    if (
      scrollLeft > activeItemPosition.x ||
      scrollLeft + scrollClientWidth < activeItemPosition.x + activeItemPosition.width
    ) {
      //scroll to center the active item
      scrollRef.current?.scrollTo({
        left: activeItemPosition.x - scrollClientWidth / 2.0 + activeItemPosition.width / 2.0,
        behavior: "smooth",
      });
    }
  }, [activeBenefitType, menuItems, scrollRef]);
