import clsx from "clsx";

import { Anchor } from "../Anchor/Anchor";

import { KnowledgeBaseItem } from "./KnowledgeBaseItem";
import * as styles from "./knowledgeBaseToolbar.module.less";

import type { KnowledgeBaseItemProps } from "./KnowledgeBaseItem";

type BasicLinkProps = {
  text: string;
  href: string;
};

type KnowledgeBaseToolbarProps = {
  title: string;
  topLink: BasicLinkProps;
  links: ReadonlyArray<KnowledgeBaseItemProps>;
};

export const KnowledgeBaseToolbar: React.FC<KnowledgeBaseToolbarProps> = ({
  title,
  topLink,
  links,
}) => {
  return (
    <div className={clsx(styles.toolbarContainer, "py-48 px-32")}>
      <div className={styles.topTextContainer}>
        <p className="h2">{title}</p>
        <Anchor variant="bold" target="_blank" href={topLink.href}>
          {topLink.text}
        </Anchor>
      </div>

      <div className={styles.knowledgeBaseItemList}>
        {links.map((linkProps, index) => (
          <KnowledgeBaseItem key={index} {...linkProps} />
        ))}
      </div>
    </div>
  );
};
