import { DateTime } from "luxon";
import { useCallback } from "react";
import { clientValidationSchema } from "shared/validation/client";

import { isPeakSeason } from "../../../../shared/utils/client";
import { Badge } from "../../components/Badge/Badge";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import { TextArea } from "../../components/Form/TextArea";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";

import { UnsavedChangesModal } from "./UnsavedChangesModal";
import { useClientUtils } from "./useClientUtils";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormEventHandler, Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";

const kickoffCallValidationSchema = clientValidationSchema.pick([
  "kickoffCallSelection",
  "kickoffCallPeople",
  "kickoffCallTopics",
  "kickoffCallSchedule",
]);

const getClientPropertiesToUpdate = (client: Client) => {
  const { kickoffCallSelection, kickoffCallSchedule, kickoffCallTopics, kickoffCallPeople } =
    client;
  return {
    kickoffCallSelection,
    kickoffCallSchedule,
    kickoffCallTopics,
    kickoffCallPeople,
  };
};

export type KickoffCallProps = {
  client: Client;
  isInitialSave: boolean;
  onInitialSave: Dispatch<SetStateAction<boolean>>;
  toggleEditing: () => void;
  updateClient: UpdateClientFunc;
  trackElementClicked: (options: ElementClickedOptions) => void;
};

export function KickoffCallForm({
  client,
  toggleEditing,
  updateClient,
  trackElementClicked,
  isInitialSave,
  onInitialSave,
}: KickoffCallProps) {
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: "Not Started",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const toggleAndScroll = () => {
    toggleEditing();
    window.scrollTo(0, 0);
  };

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: kickoffCallValidationSchema,
    type: "Kickoff Call",
    track,
    onSuccessCallback: () => {
      if (isInitialSave) onInitialSave(true);
      toggleAndScroll();
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await formik.setFieldValue("kickoffCallSelection", "YES", true);
    formik.handleSubmit();
  };

  const handleCancel = () => {
    track("Cancel");
    if (formik.dirty) {
      const proceed = window.confirm("There are unsaved changes. Are you sure you want to cancel?");
      if (!proceed) {
        track("Abort cancel");
        return;
      } else {
        track("Confirm cancel");
      }
    }
    formik.resetForm();
    toggleAndScroll();
  };

  const kickoffCallSLA = isPeakSeason(DateTime.local()) ? "48-72 hours" : "24-48 hours";
  return (
    <>
      <UnsavedChangesModal form={formik} />
      <HubCard>
        <HubCardHeader
          title="Ready to schedule your kickoff call?"
          description="Provide your preferred dates and times, any specific topics you’d like to cover, and who you would like to include. Your Implementation Consultant will reach out to you to schedule."
          badge={<Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />}
          hasDivider
        />
        <form onSubmit={handleSubmit}>
          <StackY dist={40}>
            <TextArea
              name="kickoffCallSchedule"
              label="What dates and times work for you?"
              labelBottomText={`Please include your time zone. We ask that your requested dates are at least ${kickoffCallSLA} from the date of this submission.`}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.kickoffCallSchedule || undefined}
              touched={undefined}
              error={undefined}
            />

            <TextArea
              name="kickoffCallTopics"
              label="Are there specific topics you want to be sure that are covered?"
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.kickoffCallTopics || undefined}
              touched={undefined}
              error={undefined}
            />
            <TextArea
              name="kickoffCallPeople"
              label="Who else should we include on the invite?"
              labelBottomText="Please feel free to include the name and email of any person heavily involved in your implementation & enrollment. A meeting invitation will be sent for every contact you note here. We find these meetings are most successful when all contacts are aligned leaving the call. "
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.kickoffCallPeople || undefined}
              touched={undefined}
              error={undefined}
            />

            {formik.status && <LoadingError type="component" title={formik.status} />}
          </StackY>
          <StackX dist={8} className="mt-32">
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              aria-label={
                formik.isSubmitting ? (isInitialSave ? "Submit Request" : "Save") : undefined
              }
            >
              {formik.isSubmitting ? "" : isInitialSave ? "Submit Request" : "Save"}
            </Button>
            <Button size="middle" type="text" onClick={handleCancel} disabled={formik.isSubmitting}>
              Cancel
            </Button>
          </StackX>
        </form>
      </HubCard>
    </>
  );
}
