import { Divider } from "antd";
import Text from "antd/lib/typography/Text";
import { FormDatePicker } from "client/src/components/Form/DatePicker";
import { Row, Col } from "client/src/components/Grid/Grid";

import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { DentalModalFormFields } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/DentalModalFormFields";
import { MedicalModalFormFields } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/MedicalModalFormFields";
import { VisionModalFormFields } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/VisionModalFormFields";
import moment from "moment";
import { LocationStateData } from "../../../../../../shared/data/LocationState";
import { Button } from "../../../../components/Button/Button";
import { ConfirmDialogContent } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { Checkbox } from "../../../../components/Form/Checkbox";
import { FormInput } from "../../../../components/Form/Input";
import { FormInputNumber } from "../../../../components/Form/InputNumber";
import { RadioGroup } from "../../../../components/Form/RadioGroup";
import { SlobSelect } from "../../../../components/Form/SlobSelect";
import { TextArea } from "../../../../components/Form/TextArea";
import { Modal } from "../../../../components/Modal/Modal";
import { Body1, Body2, Body3 } from "../../../../components/Typography/Typography";
import { formatCurrency } from "../../../../utils/formatters";
import { hasFormikError } from "../../../../utils/hasFormikError";
import { DocumentUploadContent } from "../../../Document/DocumentUpload/DocumentUpload";
import { CreateCarrierFormStateful } from "../../CreateCarrierForm";
import { PlanSummaryDocumentDropdown } from "../../PlanSummaryDocumentDropdown";

import * as styles from "./ExplorerBenefitModalPresentation.module.less";
import type { BenefitLevel } from "../../../../../../shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "../../../../../../shared/types/Location";
import type { useExplorerBenefitModalState } from "./useExplorerBenefitModalState";

export const DataTestId = {
  BenefitModal: "explorer-benefit-modal",
  BenefitForm: "explorer-benefit-form",
};

export const fieldErrorMsg = (fieldName: string, isSelector = false) =>
  `Please ${isSelector ? "select" : "enter"} a ${fieldName}.`;

export type ExplorerBenefitModalPresentationProps = ReturnType<typeof useExplorerBenefitModalState>;

export const ExplorerBenefitModalPresentation = (props: ExplorerBenefitModalPresentationProps) => {
  const {
    formik,
    data: {
      title,
      clientId,
      featureToggle,
      visible,
      canCreateCarrier,
      benefit,
      benefitOption,
      benefitOptions,
      benefitPlanTypeOption,
      benefitPlanTypeOptions,
      benefitCarrierOption,
      benefitCarrierOptions,
      prepaidSitusStateRequired,
      benefitPrepaidSitusStateOption,
      benefitPrepaidSitusStateOptions,
      benefitNetworkOption,
      benefitNetworkOptions,
      situsState,
      prepaidNetworkRequired,
      benefitLevelOption,
      benefitLevelOptions,
      availableContentFlags,
      isBenefitTypeNotGap,
      isBenefitTypeMedical,
      benefitPremiumOptions,
      planSummaryDocuments,
      spanishPlanSummaryDocuments,
      planSummaryDocumentsByBenefitType,
      spanishPlanSummaryDocumentsByBenefitType,
      showLegalEntity,
      showBenefitLevels,
      showWellnessAmount,
      showAdditionalContentByContentFlag,
      showLinkedHDHPPlanName,
      showPlanComparisonFields,
      showFSARolloverFields,
    },
    state: { confirmingBenefitDeletion, uploadingDocument, uploadSpanishDocument },
    modalActions: { onCancelModal, onConfirmDialogContent, onCancelDialogContent },
    documentUploadContentActions: {
      onTrackDocumentUploadContent,
      onDoneDocumentUploadContent,
      onUploadDocumentUploadContent,
    },
    benefitActions: {
      onBenefitChange,
      onBenefitPlanTypeChange,
      onBenefitCarrierChange,
      onBenefitCarrierCreate,
      onBenefitPrepaidSitusStateChange,
      onBenefitNetworkChange,
      onBenefitLevelChange,
      onBenefitLevelClear,
      onRemoveBenefitClick,
      createBenefitContentFlagChangeHandler,
    },
    planSummaryDocumentActions: {
      onPlanSummaryDocumentChange,
      onPlanSummaryDocumentUploadClick,
      onSpanishPlanSummaryDocumentChange,
      onSpanishPlanSummaryDocumentUploadClick,
    },
  } = props;

  const twoColumnLayout =
    showPlanComparisonFields &&
    (formik.values.benefitType === "DENTAL"
      ? !!formik.values.planType && !["DHMO", "DCA"].includes(formik.values.planType)
      : true);

  const planSummaryDocs = planSummaryDocumentsByBenefitType
    ? planSummaryDocumentsByBenefitType.find(
        (docByBenefit) => docByBenefit.benType === formik.values.benefitType,
      )?.docs
    : [];
  const spanishPlanSummaryDocs = spanishPlanSummaryDocumentsByBenefitType
    ? spanishPlanSummaryDocumentsByBenefitType.find(
        (docByBenefit) => docByBenefit.benType === formik.values.benefitType,
      )?.docs
    : [];

  const renderPlanComparisonFields = () => {
    switch (showPlanComparisonFields) {
      case "MEDICAL":
        return <MedicalModalFormFields formik={formik} />;
      case "DENTAL":
        return <DentalModalFormFields formik={formik} />;
      case "VISION":
        return <VisionModalFormFields formik={formik} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      data-testid={DataTestId.BenefitModal}
      open={visible}
      title={title}
      footer={null}
      width={twoColumnLayout ? 1200 : 660}
      onCancel={onCancelModal}
    >
      {confirmingBenefitDeletion ? (
        <ConfirmDialogContent
          confirmActionText={"Remove"}
          cancelActionText={"Cancel"}
          onConfirm={onConfirmDialogContent}
          onCancel={onCancelDialogContent}
        >
          <Body1 as="p">Are you sure you want to remove this benefit?</Body1>
        </ConfirmDialogContent>
      ) : uploadingDocument ? (
        <DocumentUploadContent
          clientId={clientId}
          category="explorer-benefit-summaries"
          language={uploadSpanishDocument ? "ES" : "EN"}
          track={onTrackDocumentUploadContent}
          onDone={onDoneDocumentUploadContent}
          onUpload={onUploadDocumentUploadContent}
        />
      ) : (
        <form onSubmit={formik.handleSubmit} data-testid={DataTestId.BenefitForm}>
          <StackY dist={16}>
            <Row gutter={[64, 24]}>
              <Col xs={24} sm={24} md={twoColumnLayout ? 12 : 24}>
                <StackY dist={32}>
                  <StackY dist={16}>
                    <SlobSelect
                      name="benefitType"
                      label="Benefit Type"
                      placeholder="Select a benefit type"
                      value={benefitOption?.value}
                      options={benefitOptions}
                      onChange={onBenefitChange}
                      error={
                        hasFormikError(formik, "benefitType")
                          ? fieldErrorMsg("benefit type", true)
                          : undefined
                      }
                      touched
                      fillWidth
                      showRequired
                    />
                  </StackY>

                  <StackY dist={16}>
                    <SlobSelect
                      name="carrier"
                      label="Carrier"
                      showRequired
                      value={benefitCarrierOption?.value}
                      options={benefitCarrierOptions}
                      placeholder="Select a carrier"
                      onChange={onBenefitCarrierChange}
                      error={
                        hasFormikError(formik, "carrierId")
                          ? fieldErrorMsg("carrier", true)
                          : undefined
                      }
                      touched={formik.touched.carrierId}
                      fillWidth
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {canCreateCarrier && (
                            <>
                              <Divider style={{ margin: "0 0 8px 0" }} />
                              <CreateCarrierFormStateful onCreate={onBenefitCarrierCreate} />
                            </>
                          )}
                        </>
                      )}
                    />
                  </StackY>

                  {isBenefitTypeNotGap && (
                    <StackY dist={16}>
                      <FormInput
                        label="Plan Name"
                        topText="Plan Name"
                        value={formik.values.planName ?? ""}
                        maxLength={191}
                        name="planName"
                        touched={formik.touched.planName}
                        onChange={formik.handleChange}
                        error={
                          hasFormikError(formik, "planName")
                            ? fieldErrorMsg("plan name")
                            : undefined
                        }
                        disabled={formik.isSubmitting}
                        showRequired
                      />
                    </StackY>
                  )}

                  {benefitPlanTypeOptions && (
                    <StackY dist={16}>
                      <SlobSelect
                        name="planType"
                        label="Plan Type"
                        placeholder="Select a plan type"
                        value={benefitPlanTypeOption?.value}
                        options={benefitPlanTypeOptions}
                        onChange={onBenefitPlanTypeChange}
                        disabled={formik.isSubmitting}
                        touched={formik.touched.planType}
                        error={
                          hasFormikError(formik, "planType")
                            ? fieldErrorMsg("plan type", true)
                            : undefined
                        }
                        fillWidth
                        showRequired
                      />
                    </StackY>
                  )}

                  {showFSARolloverFields && (
                    <StackY
                      className={`${styles.radioGroupContentFullWidth} ${styles.radioGroupOptionsWithInputs}`}
                      dist={32}
                    >
                      <RadioGroup
                        direction="vertical"
                        name="financialFSAMoneyRollover"
                        label="Does unused money rollover to the next year?"
                        value={formik.values.financialFSAMoneyRollover}
                        error={
                          hasFormikError(formik, "financialFSAMoneyRollover")
                            ? fieldErrorMsg("money rollover option", true)
                            : undefined
                        }
                        touched={formik.touched.financialFSAMoneyRollover}
                        disabled={formik.isSubmitting}
                        options={[
                          {
                            label: (
                              <StackX dist={16} data-has-inputs="true">
                                <Body3>Yes, up to</Body3>
                                <StackX dist={8}>
                                  <FormInputNumber
                                    label="Rollover amount"
                                    value={formik.values.financialFSAMoneyRolloverAmount ?? null}
                                    maxLength={7}
                                    name="financialFSAMoneyRolloverAmount"
                                    touched={formik.touched.financialFSAMoneyRolloverAmount}
                                    onChange={async (val) =>
                                      await formik.setFieldValue(
                                        "financialFSAMoneyRolloverAmount",
                                        val ?? null,
                                      )
                                    }
                                    error={
                                      hasFormikError(formik, "financialFSAMoneyRolloverAmount")
                                        ? fieldErrorMsg("money rollover amount")
                                        : undefined
                                    }
                                    disabled={formik.isSubmitting}
                                    formatter={formatCurrency}
                                    parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                                    step={100}
                                    min={0}
                                    showRequired
                                  />
                                  <FormDatePicker
                                    name="financialFSAMoneyRolloverDate"
                                    value={
                                      formik.values.financialFSAMoneyRolloverDate != null
                                        ? moment(formik.values.financialFSAMoneyRolloverDate)
                                        : null
                                    }
                                    placeholder="Choose date"
                                    disabled={formik.isSubmitting}
                                    touched={formik.touched.financialFSAMoneyRolloverDate}
                                    errorValue={
                                      hasFormikError(formik, "financialFSAMoneyRolloverDate")
                                        ? fieldErrorMsg("money rollover date")
                                        : undefined
                                    }
                                    onChange={async (val) =>
                                      await formik.setFieldValue(
                                        "financialFSAMoneyRolloverDate",
                                        val?.toDate() ?? null,
                                      )
                                    }
                                    showRequired
                                  />
                                </StackX>
                              </StackX>
                            ),
                            value: true,
                          },
                          { label: "No, it doesn't rollover", value: false },
                        ]}
                        onChange={formik.handleChange}
                      />
                    </StackY>
                  )}

                  {showLinkedHDHPPlanName && (
                    <FormInput
                      value={formik.values.linkedHDHPPlanName}
                      maxLength={191}
                      label={"Linked Plan Name"}
                      topText={"Linked Plan Name"}
                      bottomText={"Leave blank if more than 1 plan has an HSA"}
                      name={"linkedHDHPPlanName"}
                      onChange={formik.handleChange}
                      touched={formik.touched.planName}
                      error={formik.errors.planName}
                      disabled={formik.isSubmitting}
                    />
                  )}

                  {prepaidSitusStateRequired && benefitPrepaidSitusStateOptions && (
                    <StackY dist={16}>
                      <SlobSelect<{ label: string; value: LocationStateCode | "" }>
                        name="prepaidSitusState"
                        label="Contract State"
                        placeholder="Select contract state"
                        showRequired
                        value={benefitPrepaidSitusStateOption?.value}
                        options={benefitPrepaidSitusStateOptions}
                        onChange={onBenefitPrepaidSitusStateChange}
                        disabled={formik.isSubmitting}
                        error={
                          hasFormikError(formik, "prepaidSitusState")
                            ? fieldErrorMsg("state", true)
                            : undefined
                        }
                        touched={formik.touched.prepaidSitusState}
                        fillWidth
                      />
                    </StackY>
                  )}

                  {prepaidNetworkRequired &&
                    (!prepaidSitusStateRequired || !!benefitNetworkOptions.length) && (
                      <StackY dist={16}>
                        <SlobSelect
                          name="network"
                          options={benefitNetworkOptions}
                          value={benefitNetworkOption?.value}
                          label="Networks"
                          placeholder="Select a network"
                          onChange={onBenefitNetworkChange}
                          disabled={formik.isSubmitting}
                          error={
                            benefitNetworkOptions.length === 0
                              ? situsState
                                ? `There are no networks available for ${LocationStateData[situsState].displayName}`
                                : "This field requires a situs state to be selected."
                              : formik.errors.prepaidDentalNetwork
                              ? fieldErrorMsg("network", true)
                              : undefined
                          }
                          showRequired
                          touched={formik.touched.prepaidDentalNetwork}
                          fillWidth
                        />
                      </StackY>
                    )}

                  {benefitLevelOptions && showBenefitLevels && (
                    <StackY dist={16}>
                      <SlobSelect<{ label: string; value: BenefitLevel }>
                        name="benefitLevel"
                        label="Benefit Level"
                        placeholder="Select a benefit level"
                        value={benefitLevelOption?.value}
                        options={benefitLevelOptions}
                        disabled={formik.isSubmitting}
                        onChange={onBenefitLevelChange}
                        onClear={onBenefitLevelClear}
                        allowClear
                        fillWidth
                        touched={undefined}
                        error={undefined}
                      />
                    </StackY>
                  )}
                  {showWellnessAmount && (
                    <StackY dist={16}>
                      <FormInputNumber
                        label="Wellness Amount"
                        topText="Wellness Amount"
                        value={formik.values.wellnessAmount ?? undefined}
                        maxLength={5}
                        formatter={(val) => (val ? formatCurrency(val) : "")}
                        parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                        name="wellnessAmount"
                        touched={formik.touched.wellnessAmount}
                        onChange={async (val) => await formik.setFieldValue("wellnessAmount", val)}
                        error={
                          hasFormikError(formik, "wellnessAmount")
                            ? fieldErrorMsg("amount")
                            : undefined
                        }
                        disabled={formik.isSubmitting}
                      />
                    </StackY>
                  )}

                  {availableContentFlags?.length && (
                    <StackY dist={16}>
                      <Body2>Additional Content</Body2>
                      <Row gutter={[24, 24]}>
                        {availableContentFlags.map(
                          (flag) =>
                            showAdditionalContentByContentFlag?.get(flag) && (
                              <Col key={`contentFlag-${flag}`}>
                                <Checkbox
                                  label={flag}
                                  checked={formik.values.contentFlags?.includes(flag) ?? false}
                                  name={`contentFlag-${flag}`}
                                  onChange={async (event) => {
                                    await createBenefitContentFlagChangeHandler(flag)(event);
                                    if (
                                      event.target.name === "contentFlag-HSA compatible" &&
                                      !event.target.checked
                                    ) {
                                      await formik.setFieldValue(
                                        "medicalHealthSavingsAccountEmployerContribution",
                                        undefined,
                                      );
                                    }
                                  }}
                                  disabled={formik.isSubmitting}
                                />
                              </Col>
                            ),
                        )}
                      </Row>
                      {formik.errors.contentFlags && (
                        <Text type="danger">{formik.errors.contentFlags}</Text>
                      )}
                    </StackY>
                  )}

                  <StackY dist={16}>
                    <Body2>Plan Summary Document (English)</Body2>
                    <PlanSummaryDocumentDropdown
                      selectedPlanSummaryDocumentId={formik.values.planSummaryDocumentId}
                      planSummaryDocuments={
                        planSummaryDocs ? planSummaryDocs : planSummaryDocuments ?? []
                      }
                      onChange={onPlanSummaryDocumentChange}
                    />
                    <Body3>Don't see the document you're looking for?</Body3>
                    <Button onClick={onPlanSummaryDocumentUploadClick}>Upload Document</Button>
                  </StackY>

                  {featureToggle?.BENEFIT_EXPLORER_SPANISH && (
                    <StackY dist={16}>
                      <Body2>Plan Summary Document (Spanish)</Body2>
                      <PlanSummaryDocumentDropdown
                        selectedPlanSummaryDocumentId={formik.values.spanishPlanSummaryDocumentId}
                        planSummaryDocuments={
                          spanishPlanSummaryDocs
                            ? spanishPlanSummaryDocs
                            : spanishPlanSummaryDocuments ?? []
                        }
                        onChange={onSpanishPlanSummaryDocumentChange}
                      />
                      <Body3>Don't see the document you're looking for?</Body3>
                      <Button onClick={onSpanishPlanSummaryDocumentUploadClick}>
                        Upload Document
                      </Button>
                    </StackY>
                  )}

                  {showLegalEntity && (
                    <TextArea
                      value={formik.values.legalEntity ?? undefined}
                      name="legalEntity"
                      label="Underwriter/Administrator"
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                      error={
                        hasFormikError(formik, "legalEntity")
                          ? fieldErrorMsg("underwriter/administrator")
                          : undefined
                      }
                      showRequired
                      touched={formik.touched.legalEntity}
                    />
                  )}

                  <Checkbox
                    label="New Benefit"
                    name="isNew"
                    checked={formik.values.isNew}
                    onChange={formik.handleChange}
                  />
                </StackY>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StackY dist={32}>
                  {showPlanComparisonFields
                    ? renderPlanComparisonFields()
                    : isBenefitTypeMedical &&
                      !!benefitPremiumOptions && (
                        <>
                          <Row gutter={[24, 24]}>
                            <Col>
                              <RadioGroup
                                name="premium"
                                label="Premium"
                                value={formik.values.premium}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                touched={formik.touched.premium}
                                error={
                                  hasFormikError(formik, "premium")
                                    ? fieldErrorMsg("Premium amount", true)
                                    : false
                                }
                                options={benefitPremiumOptions}
                              />
                            </Col>
                          </Row>
                          <Row gutter={[24, 24]}>
                            <Col>
                              <RadioGroup
                                name="outOfPocketCosts"
                                label="Out-of-pocket costs"
                                value={formik.values.outOfPocketCosts}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                touched={formik.touched.outOfPocketCosts}
                                error={
                                  hasFormikError(formik, "outOfPocketCosts")
                                    ? fieldErrorMsg("Out-of-pocket amount", true)
                                    : false
                                }
                                options={benefitPremiumOptions}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                </StackY>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12}>
                <StackY dist={16}>
                  <StackX dist={16}>
                    <Button htmlType="submit" type="primary" size="middle">
                      {benefit ? "Save" : "Add"}
                    </Button>

                    {benefit && (
                      <Button type="danger" size="middle" onClick={onRemoveBenefitClick}>
                        Remove
                      </Button>
                    )}
                  </StackX>
                </StackY>
              </Col>
            </Row>
          </StackY>
        </form>
      )}
    </Modal>
  );
};
