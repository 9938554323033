import { Row, Col } from "client/src/components/Grid/Grid";
import { useCallback } from "react";

import { Anchor } from "../../components/Anchor/Anchor";
import { taskStatusToVariant, taskStatusToLabel } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../components/Typography/Typography";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";

type SunLifeConnectCardProps = {
  client: Client;
  completedMessage: {
    heading: string;
    body: string;
  };
  initialSubmitMessage: string;
  toggleEditing: () => void;
  task: Task;
  trackElementClicked: (options: ElementClickedOptions) => void;
  usingDEIF: boolean;
};

type HeaderProps = {
  headerText: string;
  task: Task;
  hasDivider?: boolean;
  client: Client;
  message?: string;
};

const Header = ({ headerText, task, client, message, hasDivider = true }: HeaderProps) => {
  return (
    <HubCardHeader
      title={headerText}
      hasDivider={hasDivider}
      badge={
        task.status && (
          <PopoverBadge
            aria-label="Task Status"
            status={taskStatusToLabel[task.status]}
            variant={taskStatusToVariant[task.status]}
            updatedAt={client.webAdminContact?.updatedAt}
            updatedBy={client.webAdminContact?.updatedByUser}
            message={message}
          />
        )
      }
    />
  );
};

export const SunLifeConnectCard = ({
  client,
  completedMessage,
  initialSubmitMessage,
  toggleEditing,
  task,
  trackElementClicked,
  usingDEIF,
}: SunLifeConnectCardProps) => {
  const headerText = usingDEIF
    ? "Add your web administrators"
    : "Activate Sun Life Connect for your company";

  const editClick = useCallback(() => {
    trackElementClicked({
      moduleState: task.status ? taskStatusToLabel[task.status] : undefined,
      buttonLabel: "Edit",
    });
    toggleEditing();
  }, [trackElementClicked, task, toggleEditing]);
  const loginClick = useCallback(() => {
    trackElementClicked({
      moduleState: task.status ? taskStatusToLabel[task.status] : undefined,
      buttonLabel: "login to Sun Life Connect",
    });
  }, [trackElementClicked, task]);

  const showDivider = !!client.webAdminContact?.email;

  const locationFields = client.webAdminContact?.location
    ? {
        address1: client.webAdminContact?.location.address1,
        address2: client.webAdminContact?.location.address2,
        city: client.webAdminContact?.location.city,
        state: client.webAdminContact?.location.state,
        zipCode: client.webAdminContact?.location.zipCode,
      }
    : {
        address1: client.webAdminContact?.address1,
        address2: client.webAdminContact?.address2,
        city: client.webAdminContact?.city,
        state: client.webAdminContact?.state,
        zipCode: client.webAdminContact?.zipCode,
      };

  const mainContact = usingDEIF
    ? client.webAdminContact && (
        <>
          <Row wrap={false}>
            <Col flex="250px">
              <Body2>Primary Web Administrator</Body2>
            </Col>
            <Col flex="1" className="body3">
              {client.webAdminContact.firstName} {client.webAdminContact.lastName}
              <div>
                <Body5>{client.webAdminContact.email}</Body5>
              </div>
              <div>
                <Body5>Benefits Administrator</Body5>
              </div>
              <p className="body5" style={{ wordBreak: "break-word" }}>
                {client.webAdminContact.phoneNumber}
              </p>
            </Col>
          </Row>
          <Row wrap={false}>
            <Col flex="250px">
              <Body3>Address</Body3>
            </Col>
            <Col flex="1" className="body3">
              <div>
                <Body5>{locationFields.address1 || "-"}</Body5>
              </div>
              <p className="body5" style={{ wordBreak: "break-word" }}>
                {locationFields.city || "-"}, {locationFields.state || "-"}{" "}
                {locationFields.zipCode || "-"}
              </p>
            </Col>
          </Row>
        </>
      )
    : client.webAdminContact && (
        <Row wrap={false}>
          <Col flex="250px">
            <Body2>Primary Web Administrator</Body2>
          </Col>
          <Col flex="1" className="body3">
            {client.webAdminContact.firstName} {client.webAdminContact.lastName}
            <p className="body5" style={{ wordBreak: "break-word" }}>
              {client.webAdminContact.email}
            </p>
          </Col>
        </Row>
      );

  if (task.status === "In Progress" && client.sLConnectCredentialSentDate) {
    return (
      <HubCard>
        <Header
          headerText={headerText}
          client={client}
          hasDivider={showDivider}
          task={task}
          message={
            "An email has been sent to your Primary Web Administrator. Be sure they click the link in the email to activate their account."
          }
        />
        <StackY dist={16}>{mainContact}</StackY>
      </HubCard>
    );
  } else if (task.status === "In Progress") {
    return (
      <HubCard>
        <Header
          headerText={headerText}
          client={client}
          task={task}
          hasDivider={showDivider}
          message={initialSubmitMessage}
        />
        <StackY dist={16}>
          {mainContact}
          {client.webAdminContact?.email && (
            <Button size="middle" onClick={editClick}>
              Edit
            </Button>
          )}
          {client.webAdminContact?.email && (
            <div className="body5">
              <strong>{completedMessage.heading}</strong> {completedMessage.body}
            </div>
          )}
        </StackY>
      </HubCard>
    );
  } else if (task.status === "Done") {
    return (
      <HubCard>
        <Header headerText={headerText} client={client} task={task} />
        <div className="body3">
          Registration is complete. Anyone who has been set up as a Sun Life Web Administrator can{" "}
          <Anchor
            href="https://login.sunlifeconnect.com/commonlogin/#/login/10"
            target="_blank"
            onClick={loginClick}
          >
            login to Sun Life Connect
          </Anchor>
          . If you haven’t been set up as a Sun Life Web Administrator, please reach out to your
          Primary Web Administrator or your IC for assistance.{" "}
        </div>
      </HubCard>
    );
  } else {
    return null;
  }
};
