import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { malpracticeReimbursementsValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { malpracticeReimbursementsFormKeyProps } from "shared/validation/planAdminsAndBillingFormKeys";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const formTestId = "malpractice-reimbursements-form";

type Props = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  updateClient: UpdateClientFunc;
  authUser: UserData;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
};

export function EIFMalpracticeReimbursement(props: Props) {
  const { client, changeSnapshot, updateClient, authUser, trackElementClicked } = props;

  const eifSubStepId = "malpractice-reimbursements";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(
      malpracticeReimbursementsFormKeyProps,
    ),
    updateClient,
    validationSchema: malpracticeReimbursementsValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(malpracticeReimbursementsFormKeyProps)(client),
  );
  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, malpracticeReimbursementsValidationSchema, {
        prefill: false,
      })
    : {};

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>

        <StackY dist={40}>
          <StackY dist={32}>
            <HubCard>
              <StackY dist={16}>
                <RadioGroup
                  name="malpracticeReimbursementRider"
                  label="Did you purchase the Malpractice Insurance Reimbursement Rider?"
                  options={[
                    { label: "Yes", value: "YES" },
                    {
                      label: "No",
                      value: "NO",
                    },
                  ]}
                  disabled={formik.isSubmitting}
                  touched={
                    formik.touched.malpracticeReimbursementRider ||
                    !!strictErrors.malpracticeReimbursementRider
                  }
                  error={
                    formik.errors.malpracticeReimbursementRider ||
                    strictErrors.malpracticeReimbursementRider
                  }
                  direction="vertical"
                  value={formik.values.malpracticeReimbursementRider}
                  onChange={formik.handleChange}
                />
                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Client.malpracticeReimbursementRider]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "malpracticeReimbursementRider",
                    client,
                    formik,
                  })}
                />

                {formik.values.malpracticeReimbursementRider === "YES" && (
                  <>
                    <RadioGroup
                      name="malpracticeReimbursementPayableTo"
                      label="Who should the benefit be payable to?"
                      options={[
                        { label: "The employer", value: "EMPLOYER" },
                        {
                          label: "The employee",
                          value: "EMPLOYEE",
                        },
                      ]}
                      disabled={formik.isSubmitting}
                      touched={
                        formik.touched.malpracticeReimbursementPayableTo ||
                        !!strictErrors.malpracticeReimbursementPayableTo
                      }
                      error={
                        formik.errors.malpracticeReimbursementPayableTo ||
                        strictErrors.malpracticeReimbursementPayableTo
                      }
                      direction="vertical"
                      value={formik.values.malpracticeReimbursementPayableTo}
                      onChange={formik.handleChange}
                    />

                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Client.malpracticeReimbursementPayableTo,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "malpracticeReimbursementPayableTo",
                        client,
                        formik,
                      })}
                    />
                  </>
                )}
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
