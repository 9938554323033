import { useUploadDocumentToVidyard } from "client/src/hooks/document";
import { useCallback, useEffect, useState } from "react";

export const useVidyardPlayer = (enabled = true) => {
  const [ready, setReady] = useState(false);

  // Watch for the vidyard API.
  useEffect(() => {
    // tslint:disable-next-line
    let timeoutId: number;
    function checkReCaptchaLoaded(): void {
      if (window.vidyardEmbed) {
        setReady(true);
      } else {
        timeoutId = window.setTimeout(checkReCaptchaLoaded, 500);
      }
    }
    checkReCaptchaLoaded();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  //render the vidyard players
  useEffect(() => {
    if (ready && enabled && window.vidyardEmbed) {
      window.vidyardEmbed.api.renderDOMPlayers();
    }
  }, [ready, enabled]);
};

export const useVidyardRerenderer = () => window.vidyardEmbed?.api.renderDOMPlayers();

export const useUploadAndSaveFile = (
  vidyardId: string | null,
  docId: string | null,
  fileExt: string | null,
  videoTitle: string | null,
  clientId: string,
  onChange: (vidyardUuid: string | null, vidyardId: number | null) => void,
) => {
  const [uuid, setUuid] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  const { mutateAsync: uploadDocumentToVidyard } = useUploadDocumentToVidyard();

  const saveDocumentToVidyard = useCallback(async () => {
    const { data } = await uploadDocumentToVidyard({
      data: {
        docId: docId ?? "",
        docExt: fileExt ?? "",
        videoTitle: videoTitle ?? "",
      },
      params: { clientId },
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo
    const { facade_player_uuid, player_id } = data.chapters_attributes[0]!.video_attributes;
    setUuid(facade_player_uuid);

    onChange(facade_player_uuid, player_id);
  }, [clientId, docId, fileExt, onChange, uploadDocumentToVidyard, videoTitle]);

  useEffect(() => {
    if (!vidyardId && docId && fileExt && !error)
      saveDocumentToVidyard().catch((e) => {
        console.error(e);
        setError(true);
      });
  }, [vidyardId, docId, error, fileExt, saveDocumentToVidyard]);

  return { uuid };
};
