import { Badge } from "client/src/components/Badge/Badge";
import { Col, Row } from "client/src/components/Grid/Grid";
import { H3, H5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import {
  copyKey,
  copyKeyExists,
  copyKeySwitch,
  copyKeySwitchCustom,
} from "client/src/domain/ExplorerPages/utils";
import { Trans, useTranslation } from "client/src/i18n";
import { PrintHidden } from "client/src/utils/print";
import { isSunlifeCarrier } from "shared/types/ExplorerPageBenefit";
import type { useExplorerPlanState } from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";

type Props = Pick<
  ReturnType<typeof useExplorerPlanState>,
  "isMobile" | "isPrint" | "location" | "offering" | "plan" | "vasUpdateFeatureToggle"
> & { groupedVariant?: boolean };

export const ExplorerPlanContent: React.FC<Props> = ({
  isMobile,
  isPrint,
  location,
  offering,
  plan,
  vasUpdateFeatureToggle,
  groupedVariant = false,
}) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlan" });
  const { benefitType, planType, carrierId, benefitLevel, isNew, planName, contentFlags, carrier } =
    plan;

  const planTypeKey = copyKeySwitch(t, benefitType, planType);

  const carrierKey = copyKeySwitch(
    t,
    benefitType,
    planTypeKey,
    isSunlifeCarrier(carrierId) ? "sunLife" : "default",
  );
  const benefitLevelKey = copyKeySwitch(
    t,
    benefitType,
    planTypeKey,
    carrierKey,
    groupedVariant ? "grouped" : benefitLevel,
  );
  const baseCopyKey = [benefitType, planTypeKey, carrierKey, benefitLevelKey];

  const localizedPlanKey = copyKeySwitchCustom(
    t,
    [...baseCopyKey, `${planType}_${location}`],
    planType,
  );

  const showContentflags = vasUpdateFeatureToggle ? true : benefitType !== "ACCIDENT";

  return (
    <Col flex={isMobile && !isPrint ? "100%" : "1 1 52%"}>
      <Row gutter={12} style={{ marginBottom: "12px" }} align="middle">
        {!groupedVariant && (
          <PrintHidden>
            {isNew && !isMobile && (
              <Col>
                <Badge
                  srOnlyLabel="Information"
                  status={t("newPlanText") ?? undefined}
                  variant="info"
                />
              </Col>
            )}
          </PrintHidden>
        )}
        <Col>
          <H3 style={{ marginBottom: 0 }}>
            {groupedVariant
              ? t(copyKey(...baseCopyKey, "heading"), { carrier: carrier?.carrierName })
              : planName}
          </H3>
        </Col>
      </Row>
      <H5>
        {t(copyKey(...baseCopyKey, "subHeading"), {
          localizedPlanName: t(copyKey(...baseCopyKey, localizedPlanKey), {
            defaultValue: null,
          }),
          defaultValue: null,
        })}
      </H5>
      <PrintHidden>
        {copyKeyExists(t, ...baseCopyKey, "content") && (
          <Trans
            t={t}
            i18nKey={copyKey(...baseCopyKey, "content")}
            tOptions={{ context: offering }}
          >
            <H5>header</H5>
            <UnorderedList>
              <UnorderedListItem>item</UnorderedListItem>
            </UnorderedList>
          </Trans>
        )}
        {(contentFlags?.includes("VAS_1") || contentFlags?.includes("VAS_2")) &&
          showContentflags && (
            <Trans
              t={t}
              i18nKey={copyKey(
                benefitType,
                "VAS",
                isSunlifeCarrier(carrierId) ? "sunLife" : "default",
              )}
            />
          )}
        {contentFlags?.includes("VAS_1") && showContentflags && (
          <Trans
            t={t}
            i18nKey={copyKey(
              benefitType,
              "VAS_1",
              isSunlifeCarrier(carrierId) ? "sunLife" : "default",
            )}
          >
            <H5>header</H5>
            <UnorderedList>
              <UnorderedListItem>item</UnorderedListItem>
            </UnorderedList>
          </Trans>
        )}
        {contentFlags?.includes("VAS_2") && (
          <Trans
            t={t}
            i18nKey={copyKey(
              benefitType,
              "VAS_2",
              isSunlifeCarrier(carrierId) ? "sunLife" : "default",
            )}
          >
            <H5>header</H5>
            <UnorderedList>
              <UnorderedListItem>item</UnorderedListItem>
            </UnorderedList>
          </Trans>
        )}
        {contentFlags?.includes("Self_Care+") && (
          <Trans
            t={t}
            i18nKey={copyKey(
              benefitType,
              "Self_Care_Plus",
              isSunlifeCarrier(carrierId) ? "sunLife" : "default",
            )}
          >
            <H5>header</H5>
            <UnorderedList>
              <UnorderedListItem>item</UnorderedListItem>
            </UnorderedList>
          </Trans>
        )}
        {contentFlags?.includes("HDHP") && (
          <Trans t={t} i18nKey={copyKey(benefitType, "HDHP")} values={{ planType }}></Trans>
        )}
        {contentFlags?.includes("HSA compatible") && (
          <Trans t={t} i18nKey={copyKey(benefitType, "HSA_addon")} />
        )}
      </PrintHidden>
    </Col>
  );
};
