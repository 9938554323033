import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { ResponseError } from "client/src/hooks/query";
import pluralize from "pluralize";
import { useState, useCallback } from "react";
import { formatShortDateTimeLocal } from "shared/utils/format";

import { isActivated } from "shared/utils/user";
import { Button } from "../../../components/Button/Button";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { Loading } from "../../../components/Loading/Loading";
import { LightTable } from "../../../components/Table/LightTable";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useSendBrokerWelcomeEmail, useSendBenAdminWelcomeEmail } from "../../../hooks/user";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { ColumnsType } from "antd/es/table";
import type { Client, ClientSetupStepId } from "shared/types/Client";
import type { Broker, BenAdmin } from "shared/types/User";

type CardWelcomeEmailProps = {
  client: Client;
  isLoading: boolean;
  brokers: Broker[] | undefined;
  benAdmins: BenAdmin[] | undefined;
  welcomeEmailStatus: string;
  refetchData?: (stepId: ClientSetupStepId) => Promise<void>;
};

type TypeUserBroker = Broker & { type: "broker" };
type TypeUserBenAdmin = BenAdmin & { type: "benAdmin" };

export const CardWelcomeEmail = ({
  client,
  brokers,
  benAdmins,
  isLoading,
  welcomeEmailStatus,
  refetchData,
}: CardWelcomeEmailProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isAllDialogVisible, setAllIsDialogVisible] = useState(false);
  const [activeEdit, setActiveEdit] = useState<TypeUserBroker | TypeUserBenAdmin | null>(null);
  const [loadingUsers, setLoadingUsers] = useState<(TypeUserBroker | TypeUserBenAdmin)[]>([]);
  const [disabledButtons, setDisabledButtons] = useState(false);

  const { mutateAsync: sendBrokerWelcomeEmail } = useSendBrokerWelcomeEmail();
  const { mutateAsync: sendBenAdminWelcomeEmail } = useSendBenAdminWelcomeEmail();

  const typedBrokers: TypeUserBroker[] | undefined = brokers?.map((u) => ({
    ...u,
    type: "broker",
  }));
  const typedBenAdmins: TypeUserBenAdmin[] = (benAdmins || []).map((u) => ({
    ...u,
    type: "benAdmin",
  }));

  const usersToSend = [
    ...(typedBrokers || []).filter((u) => !u.welcomeEmailSentDate && !isActivated(u)),
    ...(typedBenAdmins || []).filter((u) => !u.welcomeEmailSentDate && !isActivated(u)),
  ];

  const addLoadingUser = (user: TypeUserBroker | TypeUserBenAdmin) => {
    setLoadingUsers((prevLoadingUsers) => prevLoadingUsers.concat(user));
  };
  const removeLoadingUser = (user: TypeUserBroker | TypeUserBenAdmin | undefined) => {
    if (!user) return;
    setLoadingUsers((prevLoadingUsers) => prevLoadingUsers.filter((u) => u.id !== user.id));
  };

  const onClickSendWelcomeEmail = (user: TypeUserBroker | TypeUserBenAdmin) => {
    setActiveEdit(user);
    setIsDialogVisible(true);
  };

  const handleSendWelcomeEmail = async (user: TypeUserBroker | TypeUserBenAdmin | null) => {
    if (!user) return;
    setIsDialogVisible(false);
    addLoadingUser(user);
    const sendWelcomeEmail =
      user.type === "broker" ? sendBrokerWelcomeEmail : sendBenAdminWelcomeEmail;
    try {
      await sendWelcomeEmail({
        params: { userId: user.id },
        query: { clientId: client.id },
      });
      void slobMessage.success("Welcome email sent");
      if (refetchData) await refetchData("SEND_WELCOME_EMAIL");
    } catch (error) {
      const message = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy2);
      void slobMessage.error(message);
    } finally {
      removeLoadingUser(user);
    }
  };

  const handleSendAllWelcomeEmail = async () => {
    setAllIsDialogVisible(false);

    if (!usersToSend.length) return;
    setDisabledButtons(true);

    let emailsSent = 0;
    for (const user of usersToSend) {
      const isLoading = loadingUsers.find((u) => u.id === user.id);
      if (isLoading) continue;
      setActiveEdit(user);
      addLoadingUser(user);

      const sendWelcomeEmail =
        user.type === "broker" ? sendBrokerWelcomeEmail : sendBenAdminWelcomeEmail;

      try {
        await sendWelcomeEmail({
          params: { userId: user.id },
          query: { clientId: client.id },
        });
        removeLoadingUser(user);
        emailsSent++;
      } catch (error) {
        const message = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy2);
        void slobMessage.error(message);
      } finally {
        removeLoadingUser(user);
      }
    }
    setDisabledButtons(false);
    setActiveEdit(null);
    if (emailsSent > 0) {
      void slobMessage.success(`${emailsSent} ${pluralize("Email", emailsSent)} sent`);
      if (refetchData) await refetchData("SEND_WELCOME_EMAIL");
    }
  };

  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Client Setup - Step 7",
        buttonLabel,
        moduleState: welcomeEmailStatus,
      });
    },
    [trackElementClicked, welcomeEmailStatus],
  );

  if (isLoading) return <Loading pageHeight={false} />;

  const hasBrokers = Boolean(brokers?.length);
  const hasBenAdmin = Boolean(benAdmins?.length);

  const brokerColumns: ColumnsType<TypeUserBroker> = [
    {
      title: "Brokers",
      dataIndex: "fullName",
      key: "name",
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date sent",
      key: "actions",
      align: "right",
      render: (_: string, user: TypeUserBroker) => (
        <>
          {user.welcomeEmailSentDate && formatShortDateTimeLocal(user.welcomeEmailSentDate)}
          {!user.welcomeEmailSentDate && (
            <Tooltip
              title={
                isActivated(user) &&
                "This user has activated their account. A welcome email can no longer be sent."
              }
              placement="bottom"
            >
              <Button
                loading={Boolean(loadingUsers.find((u) => u.id === user.id))}
                onClick={() => {
                  track("Send welcome email");
                  onClickSendWelcomeEmail(user);
                }}
                type="secondary"
                disabled={disabledButtons || isActivated(user)}
                data-testid="send-email"
              >
                Send welcome email
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const benAdminsColumns: ColumnsType<TypeUserBenAdmin> = [
    {
      title: "Ben Admins",
      dataIndex: "fullName",
      key: "name",
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date sent",
      key: "actions",
      align: "right",
      render: (_: string, user: TypeUserBenAdmin) => (
        <>
          {user.welcomeEmailSentDate && formatShortDateTimeLocal(user.welcomeEmailSentDate)}
          {!user.welcomeEmailSentDate && (
            <Tooltip
              title={
                isActivated(user) &&
                "This user has activated their account. A welcome email can no longer be sent."
              }
              placement="bottom"
            >
              <Button
                loading={Boolean(loadingUsers.find((u) => u.id === user.id))}
                onClick={() => {
                  track("Send welcome email");
                  onClickSendWelcomeEmail(user);
                }}
                type="secondary"
                disabled={disabledButtons || isActivated(user)}
              >
                Send welcome email
              </Button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {!hasBrokers && !hasBenAdmin && (
        <p>
          This step cannot be completed until Broker and Benefits Administrator contacts are added
          to the Client. Please complete steps 1 & 2.
        </p>
      )}
      {(hasBrokers || hasBenAdmin) && (
        <>
          <p>
            Now it’s time to invite your Client and broker contacts to log in to Onboard and begin
            completing information for their implementation.
          </p>
          <p>Use the options below to send welcome emails that include account activation links.</p>
          {usersToSend.length > 0 && (
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  if (!usersToSend.length) return;
                  track("Send all");
                  setAllIsDialogVisible(true);
                }}
                type="secondary"
                disabled={disabledButtons}
              >
                Send All
              </Button>
            </div>
          )}
          <ConfirmDialog
            title="Send welcome email"
            isVisible={isDialogVisible}
            onCancel={() => setIsDialogVisible(false)}
            onConfirm={() => handleSendWelcomeEmail(activeEdit)}
            confirmActionText="Yes"
            cancelActionText="No"
          >
            Are you sure you want to send a welcome email to: {activeEdit?.fullName}?
          </ConfirmDialog>
          <ConfirmDialog
            title="Send welcome email"
            isVisible={isAllDialogVisible}
            onCancel={() => setAllIsDialogVisible(false)}
            onConfirm={() => handleSendAllWelcomeEmail()}
            confirmActionText="Yes"
            cancelActionText="No"
          >
            Are you sure you want to sent a welcome email to all Broker and Benefits Administrator
            contacts?
          </ConfirmDialog>
          {hasBrokers && (
            <LightTable
              rowKey={"id"}
              className="mb-16"
              tableLayout="fixed"
              columns={brokerColumns}
              dataSource={typedBrokers}
            />
          )}
          {hasBenAdmin && (
            <LightTable
              rowKey={"id"}
              className="mb-16"
              tableLayout="fixed"
              columns={benAdminsColumns}
              dataSource={typedBenAdmins}
            />
          )}
        </>
      )}
    </>
  );
};
