import { Row, Col } from "client/src/components/Grid/Grid";
import { NavigationHeader } from "client/src/components/NavigationHeader/NavigationHeader";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { EIFReviewAndSubmit } from "client/src/domain/EIF/EIFReviewAndSubmit";
import { getIsOutsideSignerClient } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getIsOutsideSignerClient";
import { useChangeSnapshot } from "client/src/hooks/changeLogs";
import { useSuspenseOutsideSignerQueries } from "client/src/hooks/client";
import { useOutsideSignEIF } from "client/src/hooks/outsideSigner";

import { useTitle } from "client/src/hooks/useTitle";
import { EIFSummary } from "client/src/pages/EIFSummaryPage/EIFSummary";
import { useTrackElementClicked } from "client/src/utils/analytics";
import React from "react";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import * as styles from "./OutsideSignerPage.module.less";
import type { UserData } from "shared/rbac/rbac";
import type { ClientId } from "shared/types/Client";
import type { OutsideSignerActionId, OutsideSignerTokenStatus } from "shared/types/OutsideSigner";

type OutsideSignerAuthenticatedProps = {
  clientId: ClientId;
  outsideSignerActionId: OutsideSignerActionId;
  authUser: UserData;
  tokenStatus: OutsideSignerTokenStatus | null;
};

export const OutsideSignerAuthenticated = ({
  clientId,
  outsideSignerActionId,
  authUser,
  tokenStatus,
}: OutsideSignerAuthenticatedProps) => {
  const pageTitle =
    outsideSignerActionId === "review-&-submit" ? "Review & Submit" : "Review & Submit Updates";

  useTitle(pageTitle);

  const [
    { data: client },
    { data: employeeClasses },
    { data: clientPlans },
    { data: bills },
    { data: deletedBills },
    { data: contacts },
    { data: featureToggles },
    { data: changes },
    { data: billingSummaryStatementTemplates },
    { data: subsidiaries },
  ] = useSuspenseOutsideSignerQueries(clientId);

  const changeSnapshot = useChangeSnapshot(changes);

  const trackElementClicked = useTrackElementClicked(client, "Submit Company Information");

  const outsideSignEIFQuery = useOutsideSignEIF();
  const { mutateAsync: outsideSignEIF } = outsideSignEIFQuery;

  const alreadySigned = client.eifSignedAt ? true : false;

  if (client.isArchived || !outsideSignerActionId) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const isOutsideSignerClient = getIsOutsideSignerClient(client);

  if (!isOutsideSignerClient) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  let OutsideSignerPageContents = <React.Fragment></React.Fragment>;
  switch (outsideSignerActionId) {
    case "review-&-submit": {
      OutsideSignerPageContents = (
        <EIFReviewAndSubmit
          client={client}
          signEIF={outsideSignEIF}
          authUser={authUser}
          changesSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          signerMode={"outside"}
          tokenStatus={tokenStatus}
        />
      );
      break;
    }
    case "review-&-submit-updates": {
      OutsideSignerPageContents = (
        <EIFSummary
          client={client}
          contacts={contacts}
          bills={bills}
          deletedBills={deletedBills}
          billingSummaryStatementTemplates={billingSummaryStatementTemplates}
          clientPlans={clientPlans}
          subsidiaries={subsidiaries}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          employeeClasses={employeeClasses}
          featureToggles={featureToggles}
          signerMode={"outside"}
        />
      );
      break;
    }
  }

  const showHeader =
    (outsideSignerActionId === "review-&-submit" && !alreadySigned) ||
    outsideSignerActionId === "review-&-submit-updates";

  return (
    <div className={styles.content}>
      {showHeader && <NavigationHeader backTo={null} hideBack title={pageTitle} />}

      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={16}>{OutsideSignerPageContents}</Col>
        </Row>
      </PageContent>
    </div>
  );
};
