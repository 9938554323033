import { EIFBenAdminAndDataFeed } from "client/src/domain/EIF/CompanyDetails/EIFBenAdminAndDataFeed";
import { EIFBillingPreferences } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/EIFBillingPreferences";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  bills: Bill[];
  locations: Location[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  eifStepId: EIFStepId;
  eifSubStepId: EIFSubStepId;
  benAdminPlatforms: BenAdminPlatform[];
  changeSnapshot: DEIFChangeSnapshot;
  updatePolicy: UpdatePolicyFunc;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  policy: Policy;
};

export function EIFSubStepPolicyPageContents(props: Props) {
  const {
    client,
    bills,
    locations,
    contacts,
    billingSummaryStatementTemplates,
    eifStepId,
    eifSubStepId,
    benAdminPlatforms,
    changeSnapshot,
    updatePolicy,
    trackElementClicked,
    authUser,
    featureToggles,
    policy,
  } = props;

  switch (eifSubStepId) {
    case "benefits-administration-and-data-feed":
      return (
        <EIFBenAdminAndDataFeed
          key={policy.id}
          client={client}
          benAdminPlatforms={benAdminPlatforms}
          updatePolicy={updatePolicy}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
          policy={policy}
        />
      );
    case "billing-preferences":
      return (
        <EIFBillingPreferences
          key={policy.id}
          client={client}
          bills={bills}
          locations={locations}
          contacts={contacts}
          billingSummaryStatementTemplates={billingSummaryStatementTemplates}
          updatePolicy={updatePolicy}
          trackElementClicked={trackElementClicked}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          policy={policy}
        />
      );
    default:
      return (
        <Navigate
          to={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
          replace
        />
      );
  }
}
