import { getIsOutsideSignerClient } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getIsOutsideSignerClient";
import { useChangeSnapshot } from "client/src/hooks/changeLogs";
import { useSuspenseOutsideSignerQueries } from "client/src/hooks/client";
import { useOutsideSignerAuth } from "client/src/hooks/outsideSigner";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { EIFStepPageContentsLoading } from "client/src/pages/EIFStepPage/EIFStepPage";
import { EIFSummary } from "client/src/pages/EIFSummaryPage/EIFSummary";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

export function OutsideSignerEIFSummaryPage() {
  const { clientId } = useClientHubParams(["clientId"]);
  const { authUser, isLoading: isLoadingAuthUser } = useOutsideSignerAuth();

  const [
    { data: client },
    { data: employeeClasses },
    { data: clientPlans },
    { data: bills },
    { data: deletedBills },
    { data: contacts },
    { data: featureToggles },
    { data: changes },
    { data: billingSummaryStatementTemplates },
    { data: subsidiaries },
  ] = useSuspenseOutsideSignerQueries(clientId);

  const changeSnapshot = useChangeSnapshot(changes);

  const isLoading = isLoadingAuthUser || !authUser;
  const isSuccess = !!authUser;

  if (client.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const isOutsideSignerClient = getIsOutsideSignerClient(client);

  if (!isOutsideSignerClient) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  return (
    <>
      {isLoading && <EIFStepPageContentsLoading />}
      {isSuccess && (
        <EIFSummary
          client={client}
          contacts={contacts}
          bills={bills}
          deletedBills={deletedBills}
          billingSummaryStatementTemplates={billingSummaryStatementTemplates ?? []}
          clientPlans={clientPlans}
          subsidiaries={subsidiaries}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          employeeClasses={employeeClasses}
          featureToggles={featureToggles}
          signerMode={"inside"}
        />
      )}
    </>
  );
}
