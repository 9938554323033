import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { getIn } from "formik";
import { getBenAdminAndDataFeedsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getIsMultiPolicyMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { formatFullName } from "shared/utils/format";
import { benAdminAndDataFeedsValidationSchema } from "shared/validation/policy";
import { Body3, Body5 } from "../../../components/Typography/Typography";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  viewMode: EIFSubStepViewMode;
  changeSnapshot: DEIFChangeSnapshot;
  toggleableDetails: boolean;
};

export function BenefitsAdministrationDataFeedsPolicySummary(props: Props) {
  const { client, policy, viewMode, changeSnapshot, toggleableDetails } = props;

  const [showDetails, toggleShowDetails] = useToggler();

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const policyStatus = getBenAdminAndDataFeedsCompletionStatus(policy);

  if (policyStatus === "Not Started") {
    return null;
  }

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(policy, benAdminAndDataFeedsValidationSchema, { prefill: false })
    : {};

  const getDataFeedContactNameOrErrors = (dataFeedContact: Contact, errors: typeof e) => {
    const dataFeedTypeMap = {
      DATA_FEEDS_BEN_ADMIN: "dataFeedsBenAdminContact",
      DATA_FEEDS_IMPLEMENTOR: "dataFeedsImplementorContact",
      DATA_FEEDS_PRODUCTION_SUPPORT: "dataFeedsProductionSupportContact",
    };

    const type = dataFeedContact.type;
    if (
      type !== "DATA_FEEDS_BEN_ADMIN" &&
      type !== "DATA_FEEDS_IMPLEMENTOR" &&
      type !== "DATA_FEEDS_PRODUCTION_SUPPORT"
    ) {
      throw new Error(`Incorrect data feed contact type provided: ${type}`);
    }
    const mappedType = dataFeedTypeMap[type];
    if (dataFeedContact?.firstName && dataFeedContact?.lastName) {
      return <Body3 as="div">{formatFullName(dataFeedContact)}</Body3>;
    }

    return (
      <>
        {dataFeedContact?.firstName ? (
          <Body3>{dataFeedContact?.firstName}</Body3>
        ) : (
          <Body3 redMedium>{getIn(errors, `${mappedType}.firstName`)}</Body3>
        )}{" "}
        {dataFeedContact?.lastName ? (
          <Body3>{dataFeedContact?.lastName}</Body3>
        ) : (
          <Body3 redMedium>{getIn(errors, `${mappedType}.lastName`)}</Body3>
        )}
      </>
    );
  };

  return (
    <StackY dist={24} wrap={false}>
      <StackY dist={16} wrap={false}>
        <div>
          <Body5 as="div">Do you use a benefits administration platform?</Body5>

          {policy.hasBenAdminPlatform === "NO" && <Body3 as="div">No</Body3>}

          {policy.hasBenAdminPlatform === "NOT_SURE" && <Body3 as="div">I'm not sure</Body3>}

          {policy.hasBenAdminPlatform === "YES" && (
            <>
              {policy.benAdminPlatform == null ? (
                <Body3 as="div">Yes</Body3>
              ) : (
                <>
                  {policy.benAdminPlatform.name === "Other" ? (
                    <Body3 as="div">{policy.benAdminPlatformOtherName || "Other"}</Body3>
                  ) : (
                    <Body3 as="div">{policy.benAdminPlatform.name}</Body3>
                  )}
                </>
              )}
            </>
          )}

          {e.benAdminPlatformId && <Body3 redMedium>{e.benAdminPlatformId}</Body3>}
        </div>

        {((toggleableDetails && showDetails) || !toggleableDetails || !isMultiPolicyMode) && (
          <>
            {policy.hasBenAdminPlatform === "YES" && (
              <>
                <div>
                  <Body5 as="div">
                    Do you want to set up a data feed between this platform and Sun Life?
                  </Body5>

                  {policy.dataFeeds && (
                    <Body3 as="div">
                      {policy.dataFeeds === "YES"
                        ? "Yes"
                        : policy.dataFeeds === "NO"
                        ? "No, not at this time"
                        : "I’m not sure. Reach out to your Implementation Consultant to discuss further."}
                    </Body3>
                  )}

                  {!policy.dataFeeds && !e.dataFeeds && <Body3 as="div">-</Body3>}

                  {e.dataFeeds && <Body3 redMedium>{e.dataFeeds}</Body3>}
                </div>

                {policy.dataFeeds === "YES" && (
                  <>
                    {policy.dataFeedsBenAdminContact && (
                      <div>
                        <Body5 as="div">
                          Who is the contact at your benefits administration platform for the data
                          feed setup?
                        </Body5>
                        {getDataFeedContactNameOrErrors(policy.dataFeedsBenAdminContact, e)}
                        {policy.dataFeedsBenAdminContact?.email ? (
                          <Body3 as="div">{policy.dataFeedsBenAdminContact?.email}</Body3>
                        ) : (
                          <Body3 as="div" redMedium>
                            {getIn(e.dataFeedsBenAdminContact, "email")}
                          </Body3>
                        )}
                      </div>
                    )}

                    {policy.dataFeedsProductionSupportContact && (
                      <div>
                        <Body5 as="div">Who is your data feed support contact?</Body5>
                        {getDataFeedContactNameOrErrors(
                          policy.dataFeedsProductionSupportContact,
                          e,
                        )}
                        {policy.dataFeedsProductionSupportContact?.email ? (
                          <Body3 as="div">{policy.dataFeedsProductionSupportContact?.email}</Body3>
                        ) : (
                          <Body3 as="div" redMedium>
                            {getIn(e.dataFeedsProductionSupportContact, "email")}
                          </Body3>
                        )}
                      </div>
                    )}

                    {policy.dataFeedsImplementorContact && (
                      <div>
                        <Body5 as="div">Who is your data feed implementation contact?</Body5>
                        {getDataFeedContactNameOrErrors(policy.dataFeedsImplementorContact, e)}
                        {policy.dataFeedsImplementorContact?.email ? (
                          <Body3 as="div">{policy.dataFeedsImplementorContact?.email}</Body3>
                        ) : (
                          <Body3 as="div" redMedium>
                            {getIn(e.dataFeedsImplementorContact, "email")}
                          </Body3>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </StackY>

      {isMultiPolicyMode && toggleableDetails && policy.hasBenAdminPlatform === "YES" && (
        <StackY dist={32} wrap={false}>
          <hr className="m-0" />

          <Button type="text-only" onClick={toggleShowDetails}>
            {showDetails ? "Hide details" : "Show details"}
          </Button>
        </StackY>
      )}
    </StackY>
  );
}
