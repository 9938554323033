import { Portal } from "@reach/portal";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { BillDetailsInputs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillDetailsInputs";
import { getIsBillEmpty } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { billDetailsSpec } from "shared/validation/bill";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import type { BillingAdministrationType, BillingStructureType } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { BillId } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy, PolicyId } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const validationSchema = Yup.object({
  policyId: billDetailsSpec.policyId,

  billName: Yup.string()
    .trim()
    .required("Please provide a name for this bill")
    .nullable()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) =>
        schema.test(nullNotAllowedTestConfig("Please provide a name for this bill")),
    }),
  contactId: billDetailsSpec.contactId,
  hasDifferentMailingAddress: billDetailsSpec.hasDifferentMailingAddress,
  locationId: billDetailsSpec.locationId,
  numberOfEmployees: billDetailsSpec.numberOfEmployees,
});

export type BillStatementFormValues = {
  policyId: PolicyId;
  billingStructureType: BillingStructureType | null | undefined;
  billName: string | null;
  contactId: string | null;
  hasDifferentMailingAddress: boolean;
  locationId: string | null;
  numberOfEmployees: number | null;
};

type Props = {
  client: Client;
  policy: Policy;
  locations: Location[];
  admins: Contact[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  initialValues: ValuesForValidationSchema<typeof validationSchema> | undefined;
  billId: BillId | undefined;
  onSubmit: (values: BillStatementFormValues) => void;
  onCancel: () => void;
  disabled: boolean;
  billingAdministrationType: Extract<BillingAdministrationType, "SELF" | "TPA">;
  billingStructureType: BillingStructureType;
  isSubStepStarted: boolean;
  featureToggles: ClientFeatureToggles;
};

export function BillStatementsForm(props: Props) {
  const {
    client,
    policy,
    locations,
    admins,
    changeSnapshot,
    authUser,
    initialValues,
    billId,
    onSubmit,
    onCancel,
    disabled,
    billingAdministrationType,
    billingStructureType,
    isSubStepStarted,
    featureToggles,
  } = props;

  const formik = useSlobFormik({
    validationSchema,
    validationContext: { client, prefill: true },
    initialValues: initialValues || {
      policyId: policy.id,
      billName: null,
      contactId: null,
      hasDifferentMailingAddress: false,
      locationId: null,
      numberOfEmployees: null,
    },
    onSubmit: (values) => {
      const billStatementFormValues: BillStatementFormValues = {
        billingStructureType,
        policyId: values.policyId,
        billName: values.billName,
        contactId: values.contactId,
        hasDifferentMailingAddress: values.hasDifferentMailingAddress,
        locationId: values.locationId,
        numberOfEmployees: values.numberOfEmployees,
      };
      onSubmit(billStatementFormValues);
    },
  });

  const form = "bill-statements-form-id";

  const prefillErrors =
    isSubStepStarted && !(formik.isSubmitting || disabled)
      ? getFormikErrors(formik.values, validationSchema, { client, prefill: false })
      : {};

  const isBillEmpty = getIsBillEmpty({
    ...formik.values,
    billingAdministrationType,
    billingStructureType,
    billSplitType: "NONE",
  });

  return (
    <StackY dist={32} wrap={false}>
      <BillDetailsInputs
        client={client}
        locations={locations}
        admins={admins}
        formik={formik}
        policy={policy}
        featureToggles={featureToggles}
        prefillErrors={prefillErrors}
        disabled={formik.isSubmitting || disabled}
        billSplitType={null}
        billingAdministrationType={billingAdministrationType}
        billingStructureType={billingStructureType}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        billIds={billId ? [billId] : []}
        form={form}
      />

      <Row justify="end" align="middle" gutter={20}>
        <Col>
          <Button
            type="text"
            size="small"
            htmlType="button"
            onClick={onCancel}
            disabled={formik.isSubmitting || disabled}
            form={form}
          >
            Cancel
          </Button>
        </Col>

        <Col>
          <Button
            type="secondary"
            size="middle"
            htmlType="button"
            disabled={formik.isSubmitting || disabled || isBillEmpty}
            onClick={formik.submitForm}
            form={form}
          >
            Save
          </Button>
        </Col>
      </Row>

      {/* 
        We need the inputs above to be attached to a form in order for
        keyboard submission to work, which is requried for accessibility compliance.
        We can't wrap them in a form element though because there's another form element
        further up in the tree in the Billing Preferences component and the HTML standard
        doesn't allow forms to be children of forms.
        So what I do is portal out the current form's form element and tie them via an ID. 
      */}
      <Portal type="bill-statements-form-portal">
        <form id={form} onSubmit={formik.handleSubmit} />
      </Portal>
    </StackY>
  );
}
