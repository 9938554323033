import { getIsSlfCoverageLongName, slfCoverageNameToLongName } from "shared/types/SlfCoverages";
import type { PolicyTask, Task } from "../data/Tasks";
import type { BenAdminPlatform } from "./BenAdminPlatform";
import type { Contact, ContactInput } from "./Contact";
import type { ValueOf } from "./Helper";
import type { LocationStateCode, DisabilityStateCode } from "./Location";
import type { BenAdmin, Broker, DomainUser, SimplifiedDomainUser, User, UserId } from "./User";
import type {
  DefaultsSelection,
  BillingAdministrationType,
  BillingStructureType,
  BillingSummaryStatementType,
  BillSplitType,
  ERISAPlanNumber,
  ERISAPlanType,
  Frontloading,
  MvpBrokerTier,
  PhaseId,
  PriorCarrierBill,
  RehireProvision,
  BenefitPayableTo,
  IssueOrAttainedAge,
  Tabacco,
  EmployeeSpouse,
  ClientError,
  ClientErrorType,
  DentalRollover,
  WhoSubmitsClaims,
} from "@prisma/client";
import type { TicketStatus } from "server/types/Ticket";
import type { ClientUser as DomainClientUser } from "shared/types/ClientUser";
import type { MonthlyClaimsReportMailingLocation } from "shared/types/MonthlyClaimsReportMailingLocation";
import type { OnboardingFormWithMetadata } from "shared/types/OnboardingForm";
import type { OutsideSigner, OutsideSignerId } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type {
  SlfCoverageLongName,
  CoverageNotIncludedInSection125Plan,
  PFMLCoverage,
} from "shared/types/SlfCoverages";
import type { PolicyTicket, PrimaryTicket } from "shared/validation/ticket";

export type ClientId = string;
export type PolicyId = string;
export type ClientType = "O100" | "U100" | "U100MVP" | "HEALTHPRO" | null;

export type YesNoNotSure = YesNo | "NOT_SURE";
export type YesNo = "YES" | "NO";
export type RehireProvisionUnit = "MONTH" | "WEEK" | "DAY";
export type ReleaseStatus = "NOT_READY" | "READY_FOR_RELEASE" | "LIVE";
export const implementationType = ["NEW_IMPLEMENTATION", "ADD_COVERAGE"] as const;
export type ImplementationType = ValueOf<typeof implementationType>;

export type EIFSubStepViewMode = "pre-sign" | "post-sign-with-edit" | "review-page";

export const yesNoValuesRecord: Record<YesNo, string> = {
  YES: "Yes",
  NO: "No",
};

export const payableToValuesRecord: Record<BenefitPayableTo, string> = {
  EMPLOYEE: "The employee",
  EMPLOYER: "The employer",
};

export const coverageChangesEffective = [
  "IMMEDIATELY",
  "FIRST_OF_THE_FOLLOWING_MONTH",
  "ANNUALLY_ON_POLICY_ANNIVERSARY",
  "NOT_APPLICABLE",
] as const;
export type CoverageChangesEffective = ValueOf<typeof coverageChangesEffective>;

export const coverageChangesEffectiveAgeRules = [
  "IMMEDIATELY",
  "ANNUALLY_ON_POLICY_ANNIVERSARY",
] as const;
export type CoverageChangesEffectiveAgeRules = Extract<
  CoverageChangesEffective,
  "IMMEDIATELY" | "ANNUALLY_ON_POLICY_ANNIVERSARY"
>;

export const ageForDependentLifeBenefitsOptions = [
  "USE_THE_SPOUSE_AGE",
  "USE_THE_EMPLOYEE_AGE",
] as const;
export type AgeForDependentLifeBenefits = ValueOf<typeof ageForDependentLifeBenefitsOptions>;

export const ageForDependentLifeBenefitsText: Record<AgeForDependentLifeBenefits, string> = {
  USE_THE_SPOUSE_AGE: "Use the spouse’s age",
  USE_THE_EMPLOYEE_AGE: "Use the employee’s age",
};

export type AdministerAEPOptions = "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY" | "CUSTOM";
export type AEPTakeEffectOptions = "ANNUALLY" | "OTHER";
export type DecreaseInsuranceTakeEffectOptions = "IMMEDIATELY" | "AT_ANNUAL_ENROLLMENT";
export type ChangesToBenefitsTakeEffectOptions = "IMMEDIATELY" | "FOFM" | "FOFM_INCLUSIVE";

export const disabilityClaimsPreferencesDefaults: DefaultsSelection[] = [
  "NO_SELECTION",
  "ACCEPTED_DEFAULTS",
  "EDITED_DEFAULTS",
];

export interface Client {
  id: ClientId;
  isActive: boolean;
  isTest: boolean;
  isBenefitsExplorer: boolean;
  isOnboard: boolean;
  isMaxwell: boolean;
  isArchived: boolean;
  name?: string | null;
  caseId?: string | null;
  ticketId: string;
  limitedEmails: boolean;
  baseClientId: string | null;
  implementationType: ImplementationType;
  inForceSLCoverages: SlfCoverageLongName[] | null;
  completedSetup: boolean;
  completedSetupAt: Date | null;
  /** @readonly Domain Calculated field  */
  allPoliciesSlfCoverages: SlfCoverageLongName[] | null;
  /** @readonly Domain Calculated field  */
  allPoliciesSlfCoveragesReady: SlfCoverageLongName[] | null;
  postOnboardingSurveySent: boolean;
  VDRReviewed: boolean | null;
  employeesNumber?: number | null;
  mvpBrokerTier?: MvpBrokerTier | null;
  eoiRequired?: boolean | null;
  sLConnectCredentialSentDate?: Date | null;
  baRegisteredSLConnect?: boolean | null;
  creCssEmailAddress?: string | null;
  creCssName?: string | null;
  territory?: string | null;
  priorCarrierCertificationsStatus?: PriorCarrierBill | null;
  NIGOReasons?: NIGOReason[] | null;
  releaseStatus: ReleaseStatus;
  jiraUpdatedAt?: Date | null;
  kickoffCallSelection?: YesNo | null;
  kickoffCallSchedule?: string | null;
  kickoffCallStatus: KickoffCallStatus | null;
  kickoffCallTopics?: string | null;
  kickoffCallPeople?: string | null;
  kickoffCallUpdatedAt?: Date | null;
  kickoffCallUpdatedBy: string | null;
  kickoffCallUpdatedByUser?: User | null;
  installCallSelection?: YesNo | null;
  installCallSchedule?: string | null;
  installCallTopics?: string | null;
  installCallPeople?: string | null;
  installCallUpdatedAt?: Date | null;
  installCallUpdatedBy: string | null;
  installCallUpdatedByUser?: User | null;
  enrollmentNeeds?: string | null;
  firstPolicyEffective?: Date | null;
  policyAnniversaryMonth: MonthOfTheYear | null;
  upcomingEnrollment?: YesNoNotSure | null;
  enrollmentDateStart?: Date | null;
  enrollmentDateEnd?: Date | null;
  dontKnowEnrollmentEventDates?: boolean | null;
  enrollmentNeedsUpdatedAt?: Date | null;
  enrollmentEventUpdatedAt?: Date | null;
  enrollmentNeedsUpdatedByUser?: User | null;
  enrollmentEventUpdatedBy: string | null;
  enrollmentEventUpdatedByUser?: User | null;
  alwaysShowBenefitSummaries: boolean;
  completedPaperworkReceivedOutsideOnboard: boolean | null;
  censusTaskHidden: boolean | null;
  benefitsExplorerCustomBenefitOrder: boolean;
  benefitsExplorerCustomBranding: boolean;
  ageRules?: AgeRules[] | null;
  healthcareProfessionalsSegment: boolean | null;

  enrollmentSupport: boolean | null;
  streamlinedTechnology: boolean | null;
  valueAddedServices: boolean | null;
  valueAddedServicesOptions: ValueAddedServicesOptions | null;
  valueAddedServicesStepViewed: boolean | null;
  billing: boolean | null;
  ongoingService: boolean | null;
  additionalSupport: boolean | null;
  otherTopicsToPayAttentionTo: boolean | null;
  otherTopicsToPayAttentionToText: string | null;
  productVariety: boolean | null;
  recommendationFromBroker: boolean | null;
  qualityCoverage: boolean | null;
  digitalCapabilities: boolean | null;
  service: boolean | null;
  cost: boolean | null;
  claimsManagement: boolean | null;
  mostImportantOther: boolean | null;
  mostImportantOtherText: string | null;
  setYourGoalsUpdatedAt?: Date | null;
  setYourGoalsUpdatedBy?: string | null;
  setYourGoalsUpdatedByUser: User | null;
  riskAcceptanceEmailSentDate: Date | null;
  genericDentalIdCardEmailSentDate: Date | null;
  personalizedDentalIdCardEmailSentDate: Date | null;
  eifBAReviewAndSubmitEmailSentDate: Date | null;
  slfImplementationConsultant: Omit<SimplifiedDomainUser, "clientIds" | "role"> | null;
  slfImplementationConsultantId: string | null;

  ebrEmailAddress?: string | null;
  issuedStateSitus: LocationStateCode[] | null; // @todo: remove after multi policies implementation
  users?: DomainClientUser[] | null;
  tasks?: Task[] | null;
  policies: Policy[];
  liveReleaseStatusAt: Date | null;

  clientErrors: ClientError[] | null;

  limitDueDateEmails: boolean;

  /**
   * EIF Related
   **/
  digitalEIF?: boolean | null;
  /**
   * @deprecated Use prefillCompanyInformation instead
   */
  didPrefillCompanyInformation: boolean;
  prefillCompanyInformation: PrefillCompanyInformation;
  needsEmployeeClasses?: YesNo | null;

  taxId: string | null;
  previouslyInsuredBySunLife?: YesNo | null;
  previousSlfPolicies: string[] | null;
  allowClientSelfServicePlanConfig: YesNo;
  allowClientToViewPlanConfig: boolean | null;
  benefitsReplacedWithSlf: SlfCoverageLongName[] | "none" | null; // @todo: remove when benefits are defined by client.
  authorizedSignerUserId: string | null;
  authorizedSignerUser?: DomainUser | null;
  brokerAsAuthorizedSigner: boolean | null;
  outsideSigner: OutsideSigner | null;
  eifSignedAt: Date | null;
  eifSignedBy: UserId | null;
  eifSignedByUser: User | null;
  eifOutsideSignedBy: OutsideSignerId | null;
  eifOutsideSignedByUser: OutsideSigner | null;
  pfmlPreferencesStepViewed: boolean | null;
  fliPreferencesStepViewed: boolean | null;
  fliCoversAllEmployees: boolean | null;
  fliExcludedGroups: string[] | null;

  disabilityClaimsPreferencesDefaults: DefaultsSelection;
  claimsCheckPreferencesDefaults: DefaultsSelection;
  claimsCheckLTDOnlyStepViewed: boolean | null;

  monthlyClaimsReportsAndEOBsDefaults: DefaultsSelection;
  monthlyClaimsReportMailingLocations: MonthlyClaimsReportMailingLocation[] | null;

  // Additional waiting period rules
  unionMembersCovered: YesNo | null;
  domesticPartnersCovered: YesNo | null;
  timeSpentShouldCountTowardsWaitingPeriod: YesNo | null;
  timeSpentAsPartTimeShouldCount: boolean | null;
  timeSpentAsOtherShouldCount: boolean | null;
  timeSpentAsOtherShouldCountText: string | null;
  waitingPeriodShouldBeWaived: YesNo | null;

  // Employee Certificates
  shouldCertificatesBeSplitInAnotherWay: YesNo | null;
  shouldCertificatesBeSplitInAnotherWayText: string | null;

  // EIF - rehire provisions
  reHireProvisionExists: YesNo | null;
  reHireProvisionUnit: RehireProvisionUnit | null;
  reHireProvisionDuration: number | null;
  rehireProvision: RehireProvision | null;

  // Section 125
  hasSection125Plan: YesNo | null;
  hasSection125Dental: boolean | null;
  hasSection125Vision: boolean | null;
  hasSection125DHMO: boolean | null;
  hasAdditionalCoverageIncludedIn125Plan: boolean | null;
  additonalCoverageIncludedInSection125Plan: CoverageNotIncludedInSection125Plan[] | null;

  // Age Rules
  ageReductionDecreaseTakeEffectWhen: CoverageChangesEffectiveAgeRules | null;
  ageBandIncreaseTakeEffectWhen: CoverageChangesEffectiveAgeRules | null;
  ageForDependentLifeBenefits: AgeForDependentLifeBenefits | null;
  changesForDependentLifeBenefitsUseAge: YesNo | null;

  // Other Changes to Coverage
  otherCoverageChangesTakeEffectWhen: CoverageChangesEffective | null;
  firstOfTheFollowingMonthIncludesFirstDay: boolean | null;

  // Changes during annual enrollment
  whenDoYouAdministerYourAEP: AdministerAEPOptions | null;
  whenDoYouAdministerYourAEPStartDate: string | null;
  whenDoYouAdministerYourAEPEndDate: string | null;
  whenDoAEPTakeEffect: AEPTakeEffectOptions | null;
  whenDoAEPTakeEffectOther: string | null;
  whenDecreasesInsuranceTakeEffect: DecreaseInsuranceTakeEffectOptions | null;
  changesOnlyAllowedDuringAE: YesNo | null;
  whenDoChangesToBenefitsTakeEffect: ChangesToBenefitsTakeEffectOptions | null;

  // Web Admin
  /**
   * @deprecated No longer identifying contact type via id on the Client. Rather we are idenfitying contacts by their contact type
   */
  webAdminContactId?: string | null;
  webAdminContact?: Contact | null;
  additionalWebAdminContacts?: Contact[];

  // ERISA
  erisaHasPlan: YesNo | null;
  erisaPlanType: ERISAPlanType | null;
  erisaPlanTypeOther: string | null;
  erisaPrefersPlanDetails: YesNo | null;
  erisaPlanAdminName: string | null;
  erisaStreetAddress: string | null;
  erisaCity: string | null;
  erisaState: LocationStateCode | null;
  erisaZipCode: string | null;
  erisaPlanNumber: ERISAPlanNumber | null;
  erisaPlanNumberOther: string | null;
  erisaPlanYearEndDate_deprecated: string | null;
  erisaPlanYearEndDateMMDD: string | null;
  erisaAdministerOwnPlan: YesNo | null;
  erisaHasAgentForLegalProcess: YesNo | null;
  erisaIsLegalAgentSameAsAdmin: boolean | null;
  erisaLegalAgentName: string | null;
  erisaLegalAgentStreetAddress: string | null;
  erisaLegalAgentCity: string | null;
  erisaLegalAgentState: LocationStateCode | null;
  erisaLegalAgentZipCode: string | null;

  // Subsidiaries and Affiliate
  hasSubsidiary: YesNo | null;
  subsidiaryEEsInNYNJOrHI: YesNo | null;
  mainPolicyHolderEEsInNYNJOrHI: YesNo | null;
  mainPolicyHolderEEStates: DisabilityStateCode[] | null;

  deifChangesAcceptedAt: Date | null;
  deifChangesAcceptedBy: string | null;
  deifChangesAcceptedByUser?: User | null;
  deifChangesOutsideAcceptedBy: OutsideSignerId | null;
  deifChangesOutsideAcceptedByUser: OutsideSigner | null;
  deifChangesDeclinedAt: Date | null;
  deifChangesDeclinedBy: string | null;
  deifChangesDeclinedByUser?: User | null;
  deifChangesOutsideDeclinedBy: OutsideSignerId | null;
  deifChangesOutsideDeclinedByUser: OutsideSigner | null;
  deifChangesDeclinedReason: string | null;
  deifChangesReadyForReviewAt: Date | null;
  deifChangesReadyForReviewBy: string | null;
  deifChangesReadyForReviewByUser?: User | null;

  allEmployeesActivelyAtWork: YesNo | null;
  allBenefitsNonContributory: YesNo | null;
  activelyAtWorkUpdatedAt: Date | null;
  activelyAtWorkUpdatedBy: string | null;

  malpracticeReimbursementRider: YesNo | null;
  malpracticeReimbursementPayableTo: BenefitPayableTo | null;

  criticalIllnessType: IssueOrAttainedAge | null;
  criticalIllnessRateType: Tabacco | null;

  criticalIllnessDependentAge: EmployeeSpouse | null;
  criticalIllnessDependentTobacco: EmployeeSpouse | null;

  deductibleMaximumReportTask: boolean | null;

  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  deletedAt?: Date | null;
  deletedBy: string | null;
}
export const clientSetupStepID = [
  "MERGE_CLIENT_INSTANCES",
  "ADD_BROKER",
  "ADD_BEN_ADMIN",
  "ADD_CENSUS_TEMPLATE",
  "ADD_DOCUSIGN",
  "CONFIGURE_COMPANY",
  "PREFILL_COMPANY",
  "ADD_RESOURCES",
  "UPDATE_JIRA",
  "SEND_WELCOME_EMAIL",
  "CONFIRM_SETUP",
] as const;

export type ClientSetupStepId = ValueOf<typeof clientSetupStepID>;

export const clientSetupStepStatus = ["VIEWED", "COMPLETED"] as const;

export type ClientSetupStepStatus = ValueOf<typeof clientSetupStepStatus>;

export type ClientInput<ContactType = ContactInput> = Omit<Client, "webAdminContact"> & {
  webAdminContact?: ContactType | null;
};

export interface Policy {
  id: PolicyId;
  clientId: ClientId;
  ticketId: string;
  policyEffective: Date | null;
  /** @readonly Domain Calculated field  */
  daysToPolicyEffective: number | null;
  ticketStatus: TicketStatus | null;
  primaryPolicy: boolean;
  phaseId: PhaseId | null;
  phaseChangeToInfoGatheringSent: boolean;
  phaseChangeToReviewAndValidateSent: boolean;
  phaseChangeToPlanApprovalSent: boolean;
  phaseChangeToContractsAndBillingSent: boolean;
  phaseChangeToLiveSent: boolean;
  riskAcceptanceEmailSent: boolean;
  dentalRolloverUpdatedEmailSent: boolean;
  slfCoverages: SlfCoverageLongName[] | null;
  slfCoveragesReady: SlfCoverageLongName[] | null;
  slfPolicyNumber: string | null;
  enrollmentCensusDeadline: Date | null;
  infoGatheringDeadline: Date | null;
  riskAcceptanceEmailSentDate: Date | null;
  genericDentalIdCardEmailSentDate: Date | null;
  personalizedDentalIdCardEmailSentDate: Date | null;
  liveStatusDate: Date | null;
  frontloading: Frontloading | null;
  priorCarrierBill: PriorCarrierBill | null;
  spdCompletedForPFML: boolean;
  sunAdvisorSPDComplete: boolean | null;
  sunAdvisorAdvicePlusPreFundingAmount: number | null;
  issuedStateSitus: LocationStateCode[] | null;
  approvedPFMLPlans: boolean | null;
  connecticutUseSLFSPDForPFML: boolean | null;
  coloradoUseSLFSPDForPFML: boolean | null;
  massachusettsUseSLFSPDForPFML: boolean | null;
  oregonUseSLFSPDForPFML: boolean | null;
  washingtonUseSLFSPDForPFML: boolean | null;
  massachusettsPFMLCheckCutting: number | null;
  connecticutPFMLCheckCutting: number | null;
  washingtonPFMLCheckCutting: number | null;
  coloradoPFMLCheckCutting: number | null;
  oregonPFMLCheckCutting: number | null;
  dentalRollover: DentalRollover | null;
  dentalRolloverCompleted: boolean | null;
  noDentalRollover: boolean | null;
  policyTasks: PolicyTask[] | null;

  /**
   * DEIF
   */

  // Company Details - Benefits administration & data feeds
  hasBenAdminPlatform: YesNoNotSure | null;
  benAdminPlatformId: number | null;
  benAdminPlatform: BenAdminPlatform | null;
  benAdminPlatformOtherName: string | null;
  benAdminPlatformUpdatedAt: Date | null;
  benAdminPlatformUpdatedBy: string | null;
  benAdminPlatformUpdatedByUser: User | null;
  dataFeeds: YesNoNotSure | null;
  dataFeedsUpdatedAt: Date | null;
  dataFeedsUpdatedBy: string | null;
  dataFeedsUpdatedByUser: User | null;
  dataFeedsImplementorContact: Contact | null;
  dataFeedsProductionSupportContact: Contact | null;
  dataFeedsBenAdminContact: Contact | null;

  // Plan Administrators & Billing - Billing Preferences
  billingAdministrationType: BillingAdministrationType | null;
  billingStructureType: BillingStructureType | null;
  billingSummaryStatementType: BillingSummaryStatementType | null;
  billPayrollCycles: BillPayrollCycle[] | null;
  billPayrollCyclesOther: string | null;
  billPayrollCyclesExplanation: string | null;
  billSplitType: BillSplitType | null;
  whoSubmitsClaims: WhoSubmitsClaims | null;
  tpaContact: Contact | null;
  // We don't care about this type because we never read from this value ever.
  // The only reason we have it is to write to it, and by writing to it it gets
  // tracked in our change log tables and we can use that to show IC Edit stamps
  // in the "Advance or Arrears" radio buttons in the Billing Preferences step.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't care about this type
  advanceOrArrears: any | null;

  jiraUpdatedAt: Date;
  jiraCreatedAt: Date;
  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  deletedAt?: Date | null;
  deletedBy: string | null;
}

export type PolicyInput<ContactInputType = ContactInput> = {
  noDentalRollover?: boolean;
  dentalRolloverUpdatedEmailSent?: boolean;

  // Company Details - Benefits administration & data feeds
  hasBenAdminPlatform: YesNoNotSure | null;
  benAdminPlatformId: number | null;
  benAdminPlatform: BenAdminPlatform | null;
  benAdminPlatformOtherName: string | null;
  dataFeeds: YesNoNotSure | null;
  dataFeedsUpdatedAt: Date | null;
  dataFeedsImplementorContact: ContactInputType | null;
  dataFeedsProductionSupportContact: ContactInputType | null;
  dataFeedsBenAdminContact: ContactInputType | null;

  // Plan Administrators & Billing - Billing Preferences
  billingAdministrationType: BillingAdministrationType | null;
  billingStructureType: BillingStructureType | null;
  billingSummaryStatementType: BillingSummaryStatementType | null;
  billPayrollCycles: BillPayrollCycle[] | null;
  billPayrollCyclesOther: string | null;
  billPayrollCyclesExplanation: string | null;
  billSplitType: BillSplitType | null;
  whoSubmitsClaims: WhoSubmitsClaims | null;
  tpaContact: ContactInputType | null;
  advanceOrArrears: unknown | null;

  connecticutUseSLFSPDForPFML?: boolean;
  massachusettsUseSLFSPDForPFML?: boolean;
  washingtonUseSLFSPDForPFML?: boolean;
  coloradoUseSLFSPDForPFML?: boolean;
  oregonUseSLFSPDForPFML?: boolean;
};
export type ClientSetupStatus =
  | "Not Started"
  | "In Progress"
  | "In Review"
  | "Needs Review"
  | "Done";

export type ClientSetup = {
  mergeClient: {
    status: ClientSetupStatus;
  };
  broker: {
    data: Broker[];
    status: ClientSetupStatus;
  };
  benAdmin: {
    data: BenAdmin[];
    status: ClientSetupStatus;
  };
  censusTemplate: {
    status: ClientSetupStatus;
  };
  docusignEnvelop: {
    data: readonly OnboardingFormWithMetadata[];
    status: ClientSetupStatus;
  };
  configCompanyInfo: {
    data: Plan[];
    status: ClientSetupStatus;
  };
  prefillCompanyInfo: {
    status: ClientSetupStatus;
  };
  enrollmentResources: {
    status: ClientSetupStatus;
  };
  updateJiraTicket: {
    status: ClientSetupStatus;
  };
  welcomeEmail: {
    status: ClientSetupStatus;
  };
  completeSetup: {
    status: ClientSetupStatus;
  };
};

export type ClientSetupSteps = {
  clientId: ClientId;
  stepId: ClientSetupStepId;
  stepStatus: ClientSetupStepStatus;
};

export type ClientTableQuery = {
  limit?: number;
  offset?: number;
  sortBy?:
    | "caseId"
    | "firstPolicyEffective"
    | "createdAt"
    | "name"
    | "slfImplementationConsultant"
    | "policies"
    | "implementationType";
  sortDirection?: "asc" | "desc";
} & ClientTableFilters;

export type ClientTableFilters = {
  search?: string;
  userId?: string;
  ic?: string[];
  noic?: boolean;
  completedSetup?: boolean;
  isOnboard?: boolean;
  isBenefitsExplorer?: boolean;
  isNewIssue?: boolean;
  isAddCoverage?: boolean;
  phaseIds?: PhaseId[];
  ebrEmails?: string[];
  includeArchived?: boolean;
  clientErrorTypes?: ClientErrorType[];
};

export const isClientTableFilters = (
  filters: Record<string, unknown>,
): filters is ClientTableFilters => {
  const props: Record<keyof ClientTableFilters, null> = {
    search: null,
    userId: null,
    ic: null,
    noic: null,
    completedSetup: null,
    isOnboard: null,
    isBenefitsExplorer: null,
    isNewIssue: null,
    isAddCoverage: null,
    phaseIds: null,
    ebrEmails: null,
    includeArchived: null,
    clientErrorTypes: null,
  };

  const isIt = Object.keys(props).every((prop) => filters[prop] == null || prop in filters);

  return isIt;
};

export type ClientUserTableFilters = ClientTableFilters & {
  userId: string;
  phaseIds?: PhaseId[];
  completedSetup?: boolean;
};

export type ClientPolicy = {
  ticketId: string;
  ticketStatus: TicketStatus | null;
  primaryPolicy: boolean;
  priorCarrierBill: PriorCarrierBill | null;
  enrollmentCensusDeadline: Date | null;
  infoGatheringDeadline: Date | null;
  slfCoverages: SlfCoverageLongName[];
  slfCoveragesReady: SlfCoverageLongName[];
  slfPolicyNumber: string[];
  policyEffective: Date | null;
  frontloading: Frontloading | null;
  approvedPFMLPlans: boolean;
  sunAdvisorSPDComplete: boolean;
  spdCompletedForPFML: boolean;
  sunAdvisorAdvicePlusPreFundingAmount: number | null;
  issuedStateSitus: LocationStateCode[];
  massachusettsPFMLCheckCutting: number | null;
  connecticutPFMLCheckCutting: number | null;
  washingtonPFMLCheckCutting: number | null;
  coloradoPFMLCheckCutting: number | null;
  oregonPFMLCheckCutting: number | null;
  jiraUpdatedAt: Date;
  jiraCreatedAt: Date;
  phaseId: PhaseId | null;
};

export const NIGOReasons = [
  "Awaiting Census",
  "Census Missing Information",
  "Paperwork Missing Information",
  "Missing Prior Carrier Information",
  "Pending Risk Rejection",
  "BA Signature Missing",
  "Broker Signature Missing",
  "Prior Carrier Cert Missing",
  "Prior Carrier Bill Missing",
  "Pending Broker Licensing",
];
export type NIGOReason = ValueOf<typeof NIGOReasons>;

export const ageRules = ["Age Reductions", "Age Band Rates"] as const;
export type AgeRules = ValueOf<typeof ageRules>;

export const valueAddedServicesOptions = [
  "ONLINE_WILL_PREPARATION",
  "EMERGENCY_TRAVEL_ASSISTANCE",
  "SELF_CARE_PLUS",
] as const;
export type ValueAddedServicesOptions = ValueOf<typeof valueAddedServicesOptions>;

export const valueAddedServicesOptionsVT = [
  "ONLINE_WILL_PREPARATION",
  "EMERGENCY_TRAVEL_ASSISTANCE",
] as const;
export type ValueAddedServicesOptionsVT = Extract<
  ValueAddedServicesOptions,
  "ONLINE_WILL_PREPARATION" | "EMERGENCY_TRAVEL_ASSISTANCE"
>;

export const valueAddedServicesOptionsText: Record<ValueAddedServicesOptions, string> = {
  ONLINE_WILL_PREPARATION: "Online Will Preparation and Claimant Support Services",
  EMERGENCY_TRAVEL_ASSISTANCE: "Emergency Travel Assistance and Identity Theft Protection",
  SELF_CARE_PLUS: "Self Care+",
};

export function getIsNIGOReason(reason: string): reason is NIGOReason {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isNIGOReason = NIGOReasons.includes(reason);
  return isNIGOReason;
}

export type MultiPolicyTicket = PrimaryTicket & {
  firstPolicyEffective: Date | null;
  isActive: boolean;
  /*
   * This differs from `liveStatusDate` because `liveReleaseStatusAt`
   * represents the date at which all the linked policies became live
   */
  liveReleaseStatusAt: Date | null;
  releaseStatus: ReleaseStatus;
  policies: PolicyTicket[];
};

export interface ClientBaseInfoResponse {
  name: string;
  caseId: string;
  policyEffective: (string | null)[];
  ticketStatus: (TicketStatus | null)[];
}

const pfmlKeys = [
  "ASO CT PFML",
  "CT PFML Fully Ins",
  "ASO MA PFML",
  "MA PFML Fully Ins",
  "ASO WA PFML",
  "ASO CO PFML",
  "CO PFML Fully Ins",
  "ASO OR PFML",
  "OR PFML Fully Ins",
] as const;
export type PFMLCoverageTypes = ValueOf<
  Pick<typeof slfCoverageNameToLongName, ValueOf<typeof pfmlKeys>>
>;
export const PFMLCoverages: PFMLCoverageTypes[] = pfmlKeys.map(
  (key) => slfCoverageNameToLongName[key],
);

const pfmlSpdKeys = [
  "ASO CT PFML",
  "ASO MA PFML",
  "ASO WA PFML",
  "ASO CO PFML",
  "ASO OR PFML",
] as const;
type PFMLSPDCoverageTypes = ValueOf<
  Pick<typeof slfCoverageNameToLongName, ValueOf<typeof pfmlSpdKeys>>
>;
export const PFMLSPDCoverages: PFMLSPDCoverageTypes[] = pfmlSpdKeys.map(
  (key) => slfCoverageNameToLongName[key],
);

const sunAdvisorKeys = ["Sun Advisor Advice to Pay", "Sun Advice to Pay"] as const;
type SunAdvisorCoverageTypes = ValueOf<
  Pick<typeof slfCoverageNameToLongName, ValueOf<typeof sunAdvisorKeys>>
>;
export const SunAdvisorCoverages: SunAdvisorCoverageTypes[] = sunAdvisorKeys.map(
  (key) => slfCoverageNameToLongName[key],
);

export const kickoffCallStatus = [
  "Pending",
  "Complete",
  "Complete - No Recording",
  "Complete - Call Recorded",
  "Declined",
  "No Response",
];
export type KickoffCallStatus = ValueOf<typeof kickoffCallStatus>;

export const censusStepIdMap = {
  "upload-files": "Upload your file",
  "indicate-source": "Confirm your file format",
  "validate-submit": "Review validation results",
} as const;
export type CensusStepId = keyof typeof censusStepIdMap;

export const billingAdministrationTypes: BillingAdministrationType[] = ["LIST", "SELF", "TPA"];
export const billingAdministrationTypeLabels: { [key in BillingAdministrationType]: string } = {
  LIST: "List bill",
  SELF: "Self-bill",
  TPA: "TPA administration",
};

export const billingStructureTypes: BillingStructureType[] = ["SINGLE", "MULTIPLE"];
export const billingStructureTypeLabels: { [key in BillingStructureType]: string } = {
  SINGLE: "Single bill",
  MULTIPLE: "Multiple bills",
};
export const alternateBillingStructureTypeLabels: { [key in BillingStructureType]: string } = {
  SINGLE: "One summary statement and payment",
  MULTIPLE: "Multiple statements and payments",
};

export const billingSummaryStatementTypes: BillingSummaryStatementType[] = ["SUN_LIFE", "CUSTOM"];
export const billSplitTypes: BillSplitType[] = ["LOCATION", "BENEFIT", "TAGS", "NONE"];

export const billPayrollCycles = ["WEEKLY", "BIWEEKLY", "SEMIMONTHLY", "MONTHLY", "OTHER"] as const;
export type BillPayrollCycle = ValueOf<typeof billPayrollCycles>;
export const billingPayrollCycleTypeLabels: Record<BillPayrollCycle, string> = {
  WEEKLY: "Weekly",
  BIWEEKLY: "Bi-weekly",
  SEMIMONTHLY: "Semi-monthly",
  MONTHLY: "Monthly",
  OTHER: "Other",
};

export function getIsBillPayrollCycle(item: string): item is BillPayrollCycle {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isIt = billPayrollCycles.includes(item as BillPayrollCycle);
  return isIt;
}

export const pfmlSlfCoverages: PFMLCoverage[] = [
  "CT PFML Fully Ins",
  "MA PFML Fully Ins",
  "CO PFML Fully Ins",
  "OR PFML Fully Ins",
  "ASO CT PFML",
  "ASO MA PFML",
  "ASO WA PFML",
  "ASO CO PFML",
  "ASO OR PFML",
];

export const getIsAgeRule = (item: string): item is AgeRules => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isIt = ageRules.includes(item as AgeRules);
  return isIt;
};

export type RehireProvisionMonths = Extract<
  RehireProvision,
  | "MONTHS_1"
  | "MONTHS_2"
  | "MONTHS_3"
  | "MONTHS_4"
  | "MONTHS_6"
  | "MONTHS_9"
  | "MONTHS_12"
  | "MONTHS_18"
  | "MONTHS_24"
  | "MONTHS_36"
  | "MONTHS_60"
>;
export const rehireProvisionMonths: readonly RehireProvisionMonths[] = [
  "MONTHS_1",
  "MONTHS_2",
  "MONTHS_3",
  "MONTHS_4",
  "MONTHS_6",
  "MONTHS_9",
  "MONTHS_12",
  "MONTHS_18",
  "MONTHS_24",
  "MONTHS_36",
  "MONTHS_60",
] as const;

export type RehireProvisionDays = Extract<
  RehireProvision,
  "DAYS_30" | "DAYS_45" | "DAYS_60" | "DAYS_90" | "DAYS_120"
>;
export const rehireProvisionDays = [
  "DAYS_30",
  "DAYS_45",
  "DAYS_60",
  "DAYS_90",
  "DAYS_120",
] as const;

export const rehireProvisions: readonly RehireProvision[] = [
  "MONTHS_1",
  "MONTHS_2",
  "MONTHS_3",
  "MONTHS_4",
  "MONTHS_6",
  "MONTHS_9",
  "MONTHS_12",
  "MONTHS_18",
  "MONTHS_24",
  "MONTHS_36",
  "MONTHS_60",
  "DAYS_30",
  "DAYS_45",
  "DAYS_60",
  "DAYS_90",
  "DAYS_120",
] as const;

export const rehireProvisionLabels = [
  "1 month",
  "2 months",
  "3 months",
  "4 months",
  "6 months",
  "9 months",
  "12 months",
  "18 months",
  "24 months",
  "36 months",
  "60 months",
  "30 days",
  "45 days",
  "60 days",
  "90 days",
  "120 days",
] as const;
export type RehireProvisionLabels = ValueOf<typeof rehireProvisionLabels>;

export const rehireProvisionValueToLabelMap: Readonly<
  Record<RehireProvision, RehireProvisionLabels>
> = Object.freeze({
  MONTHS_1: "1 month",
  MONTHS_2: "2 months",
  MONTHS_3: "3 months",
  MONTHS_4: "4 months",
  MONTHS_6: "6 months",
  MONTHS_9: "9 months",
  MONTHS_12: "12 months",
  MONTHS_18: "18 months",
  MONTHS_24: "24 months",
  MONTHS_36: "36 months",
  MONTHS_60: "60 months",
  DAYS_30: "30 days",
  DAYS_45: "45 days",
  DAYS_60: "60 days",
  DAYS_90: "90 days",
  DAYS_120: "120 days",
});

export const monthsOfTheYear = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
] as const;

export type MonthOfTheYear = ValueOf<typeof monthsOfTheYear>;

export const prefillCompanyInformation = ["Not Started", "In Progress", "Done"] as const;
export type PrefillCompanyInformation = ValueOf<typeof prefillCompanyInformation>;

export type AdvanceOrArrears = Partial<Record<SlfCoverageLongName, "Arrears" | "Advance">>;
export const isAdvanceOrArrearsRecord = (value: unknown): value is AdvanceOrArrears => {
  return (
    value != null &&
    typeof value === "object" &&
    Object.keys(value).every(getIsSlfCoverageLongName) &&
    Object.values(value).every((v) => v === "Arrears" || v === "Advance")
  );
};
