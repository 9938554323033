import { listFormat } from "../../../../../shared/utils/format";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { Body5 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { formatCurrencyWithCents } from "../../../utils/formatters";

import { PFMLSPDUploadCard } from "./PFMLSPDUploadCard";

import type { PolicyTask } from "../../../../../shared/data/Tasks";
import type { Client, Policy } from "../../../../../shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type SummaryPlanDescriptionforPFMLProps = {
  client: Client;
  task: PolicyTask;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
};

type SummaryDescriptionPlanPFMLLinksOptions =
  | "connecticut"
  | "colorado"
  | "massachusetts"
  | "oregon"
  | "washington";
export const getSummaryDescriptionPlanPFMLLinks: {
  [key in SummaryDescriptionPlanPFMLLinksOptions]: string;
} = {
  connecticut: "/CT PFML_ASO_SPD 2.7.22.doc",
  colorado: "/pfmltp-1932-co-pfml-aso-spd-template-original-file.docx",
  massachusetts: "/MAPFMLASOfinal.doc",
  oregon: "/orpfml-aso-spd-original-file.docx",
  washington: "/WA PFML ASO SPD Template April 2023.docx",
};

export function SummaryPlanDescriptionforPFML({
  client,
  policy,
  task,
}: SummaryPlanDescriptionforPFMLProps) {
  useTitle("Submit Summary Plan Description for PFML");

  const trackElementClicked = useTrackElementClicked(client, "PFML SPDs");

  const { allPoliciesSlfCoverages, policies, name } = client;

  const slfCoveragesSet = new Set(allPoliciesSlfCoverages);
  const listStatesCode = [];
  const listStates = [];

  const primaryPolicy = policies.find((p) => p.primaryPolicy);

  if (slfCoveragesSet.has("ASO CT PFML")) {
    listStatesCode.push("CT");
    listStates.push("Connecticut");
  }
  if (slfCoveragesSet.has("ASO CO PFML")) {
    listStatesCode.push("CO");
    listStates.push("Colorado");
  }
  if (slfCoveragesSet.has("ASO MA PFML")) {
    listStatesCode.push("MA");
    listStates.push("Massachusetts");
  }
  if (slfCoveragesSet.has("ASO OR PFML")) {
    listStatesCode.push("OR");
    listStates.push("Oregon");
  }
  if (slfCoveragesSet.has("ASO WA PFML")) {
    listStatesCode.push("WA");
    listStates.push("Washington");
  }

  const listStatesCodeFormatted = listFormat(listStatesCode, {
    style: "short",
    type: "conjunction",
  });
  const listStatesFormatted = listFormat(listStates, {
    style: "long",
    type: "disjunction",
  });

  const showCheckCutting = Boolean(
    primaryPolicy?.massachusettsPFMLCheckCutting ||
      primaryPolicy?.connecticutPFMLCheckCutting ||
      primaryPolicy?.washingtonPFMLCheckCutting ||
      primaryPolicy?.coloradoPFMLCheckCutting ||
      primaryPolicy?.oregonPFMLCheckCutting,
  );

  return (
    <ChildPageLayout
      topNavTitle="Submit Summary Plan Description for PFML"
      onClickBack={() => trackElementClicked({ buttonLabel: "Back to Home" })}
      aside={
        <>
          <h1>Submit Summary Plan Description for PFML</h1>

          <DueDateAlert task={task} />
          <br />

          <p>
            Review and adopt Sun Life's standard Summary Plan Description (SPD) for each state in
            which you will be offering a PFML plan.
          </p>

          <p>
            Once you have reviewed each SPD, complete it and return it to Sun Life for review. Sun
            Life will review and reach out to you with any questions. If anything else is needed,
            your Implementation Consultant will work with you on how to re-submit.
          </p>
        </>
      }
      contents={
        <>
          <h2>What is a Summary Plan Description (SPD)?</h2>

          <p>
            An SPD is a document that tells your employees what the plan provides and how it
            operates.
          </p>
          <p>
            To complete onboarding in a timely fashion, Sun Life utilizes a standard SPD for the
            states in which we offer PFML benefits.
          </p>
          <p>
            Please download and complete Sun Life’s standard SPD and then upload it for your
            Implementation Consultant to review. You must do this for each state in which you intend
            to offer a PFML plan.
          </p>

          {slfCoveragesSet.has("ASO CT PFML") && (
            <div className="mb-40">
              <PFMLSPDUploadCard
                clientId={client.id}
                state="Connecticut"
                useSunLifeSPD={policy.connecticutUseSLFSPDForPFML ?? false}
                policy={policy}
                sunLifeSPDLink={getSummaryDescriptionPlanPFMLLinks["connecticut"]}
                spdCompletedForPFML={primaryPolicy?.spdCompletedForPFML || false}
                documentCategory="summary-plan-description-pfml-connecticut"
                trackElementClicked={trackElementClicked}
              />
            </div>
          )}

          {slfCoveragesSet.has("ASO CO PFML") && (
            <div className="mb-40">
              <PFMLSPDUploadCard
                clientId={client.id}
                state="Colorado"
                useSunLifeSPD={policy.coloradoUseSLFSPDForPFML ?? false}
                policy={policy}
                sunLifeSPDLink={getSummaryDescriptionPlanPFMLLinks["colorado"]}
                spdCompletedForPFML={primaryPolicy?.spdCompletedForPFML || false}
                documentCategory="summary-plan-description-pfml-colorado"
                trackElementClicked={trackElementClicked}
              />
            </div>
          )}

          {slfCoveragesSet.has("ASO MA PFML") && (
            <div className="mb-40">
              <PFMLSPDUploadCard
                clientId={client.id}
                state="Massachusetts"
                useSunLifeSPD={policy.massachusettsUseSLFSPDForPFML ?? false}
                policy={policy}
                sunLifeSPDLink={getSummaryDescriptionPlanPFMLLinks["massachusetts"]}
                spdCompletedForPFML={primaryPolicy?.spdCompletedForPFML || false}
                documentCategory="summary-plan-description-pfml-massachusetts"
                trackElementClicked={trackElementClicked}
              />
            </div>
          )}

          {slfCoveragesSet.has("ASO OR PFML") && (
            <div className="mb-40">
              <PFMLSPDUploadCard
                clientId={client.id}
                state="Oregon"
                useSunLifeSPD={policy.oregonUseSLFSPDForPFML ?? false}
                policy={policy}
                sunLifeSPDLink={getSummaryDescriptionPlanPFMLLinks["oregon"]}
                spdCompletedForPFML={primaryPolicy?.spdCompletedForPFML || false}
                documentCategory="summary-plan-description-pfml-oregon"
                trackElementClicked={trackElementClicked}
              />
            </div>
          )}

          {slfCoveragesSet.has("ASO WA PFML") && (
            <div className="mb-40">
              <PFMLSPDUploadCard
                clientId={client.id}
                state="Washington"
                useSunLifeSPD={policy.washingtonUseSLFSPDForPFML ?? false}
                policy={policy}
                sunLifeSPDLink={getSummaryDescriptionPlanPFMLLinks["washington"]}
                spdCompletedForPFML={primaryPolicy?.spdCompletedForPFML || false}
                documentCategory="summary-plan-description-pfml-washington"
                trackElementClicked={trackElementClicked}
              />
            </div>
          )}

          {showCheckCutting && (
            <div id="CheckCutting">
              <h3>For PFML ASO plans when we pay your claim on your behalf</h3>

              <p>
                PFML ASO plans also requires a pre-funding amount to be received prior to any claim
                payments.
              </p>

              <p>A check can be sent to:</p>

              <address className="body3">
                Sun Life of Canada <br />
                P.O. Box 7247-0381 <br />
                Philadelphia PA 19170-0381
              </address>

              <p>Sun Life will also need a pre-funding amount prior to any claim payments.</p>

              {primaryPolicy?.connecticutPFMLCheckCutting && (
                <p>
                  The pre-funding amount for Connecticut is:{" "}
                  <strong>
                    {formatCurrencyWithCents(primaryPolicy.connecticutPFMLCheckCutting)}
                  </strong>
                </p>
              )}

              {primaryPolicy?.coloradoPFMLCheckCutting && (
                <p>
                  The pre-funding amount for Colorado is:{" "}
                  <strong>{formatCurrencyWithCents(primaryPolicy.coloradoPFMLCheckCutting)}</strong>
                </p>
              )}

              {primaryPolicy?.massachusettsPFMLCheckCutting && (
                <p>
                  The pre-funding amount for Massachusetts is:{" "}
                  <strong>
                    {formatCurrencyWithCents(primaryPolicy.massachusettsPFMLCheckCutting)}
                  </strong>
                </p>
              )}

              {primaryPolicy?.oregonPFMLCheckCutting && (
                <p>
                  The pre-funding amount for Oregon is:{" "}
                  <strong>{formatCurrencyWithCents(primaryPolicy.oregonPFMLCheckCutting)}</strong>
                </p>
              )}

              {primaryPolicy?.washingtonPFMLCheckCutting && (
                <p>
                  The pre-funding amount for Washington is:{" "}
                  <strong>
                    {formatCurrencyWithCents(primaryPolicy.washingtonPFMLCheckCutting)}
                  </strong>
                </p>
              )}

              <Body5 as="p">
                Please indicate on the memo line of the check that this is an ASO pre-funding
                payment for policy {primaryPolicy?.slfPolicyNumber || "-"} for {name}.
              </Body5>
            </div>
          )}

          <h3>Tips for completing this task</h3>

          <p>
            <strong>
              Don’t forget to complete the Private Plan Exemption Documents for{" "}
              {listStatesCodeFormatted} too.
            </strong>
          </p>

          <p>
            With Paid Family and Medical Leave in {listStatesFormatted}, we will need a copy of your
            Private Plan Exemption Approval to ensure that your exemption has been approved.
          </p>

          <h3>FAQs</h3>

          <p>
            <strong>What is Paid Family and Medical Leave or PFML?</strong>
          </p>

          <p>
            The Paid Family and Medical Leave Act (PFMLA) offers your employees the opportunity to
            take time to attend to personal and family health needs without worrying about lost
            income.
          </p>
          <p>
            This could be when an employee is out of work due to their own medical condition and/or
            to care for a family member, bond with a new child, or certain other leave reasons.{" "}
          </p>

          <p>
            <strong>How does PFML help me and my employees?</strong>
          </p>

          <p>
            In the event that your employees get sick or for some other reason need to leave work,
            they will continue to get paid through the PFML benefit fund. This will ensure that you
            are able to allow your employees to take time off of work, and still get paid, and it is
            a benefit to you financially.
          </p>

          <p>
            <strong>What is the difference between a fully insured plan versus an ASO plan?</strong>
          </p>

          <p>
            You have the option to offer a fully-insured PFML plan or an ASO PFML plan. With an ASO
            plan, you will be responsible for funding the claims, and with a fully insured plan, the
            insurance company will be responsible for funding the claims.
          </p>

          <p>
            <strong>Are there extra steps with an ASO plan?</strong>
          </p>

          <p>
            As a part of your onboarding, you must provide the summary plan document that conforms
            to the State template in which your plan is offered. If your SPD varies from the State
            template, then the SPD template must be marked up and submitted to Sun Life legal for
            approval, which will be coordinated by your Implementation Consultant. When legal
            approves the marked up SPD, the submission be considered final.
          </p>

          <p>
            <strong>Which states does Sun Life offer PFML in?</strong>
          </p>

          <p>Currently, Sun Life offers PFML in the following states:</p>

          <UnorderedList>
            <UnorderedListItem>Connecticut</UnorderedListItem>
            <UnorderedListItem>Colorado</UnorderedListItem>
            <UnorderedListItem>Massachusetts</UnorderedListItem>
            <UnorderedListItem>Oregon</UnorderedListItem>
            <UnorderedListItem>Washington</UnorderedListItem>
          </UnorderedList>
        </>
      }
    />
  );
}
