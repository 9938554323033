import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { isFavoritePlan } from "client/src/domain/ExplorerPages/utils";
import { useGetPublicDocumentDownloadUrl } from "client/src/hooks/document";
import { useMediaQuery } from "client/src/hooks/useMediaQuery";
import { useEmployeeLocation, useFavoritePlans } from "client/src/hooks/useSlobLocalStorage";
import { useCallback, useState } from "react";
import type { useExplorerTrackElementClicked } from "client/src/utils/analytics";
import type { Language } from "shared/types/ExplorerPage";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export interface ExplorerPlanStateProps {
  plan: ExplorerPageBenefit;
  kioskMode?: boolean;
  confirmDeselection?: boolean;
  trackElementClicked?: ReturnType<typeof useExplorerTrackElementClicked>;
  language: Language;
  onPlanDeselected?: (plan: ExplorerPageBenefit) => void;
  offering?: PlanType | "MULTI_CHOICE" | null;
  expiresAt?: Date | null;
  vasUpdateFeatureToggle: boolean;
}

export const useExplorerPlanState = ({
  plan,
  kioskMode = false,
  confirmDeselection = true,
  onPlanDeselected,
  language,
  trackElementClicked,
  offering,
  expiresAt,
  vasUpdateFeatureToggle,
}: ExplorerPlanStateProps) => {
  const isMobile = !useBreakpoint().md;
  const isPrint = useMediaQuery("print");
  const [location] = useEmployeeLocation();

  const toggleFavoritePlan = useToggleFavoritePlan({
    kioskMode,
    confirmDeselection,
    onPlanDeselected,
    trackElementClicked,
    plan,
  });

  const downloadPlanSummary = useDownloadPlanSummary({ plan, trackElementClicked });

  return {
    isMobile,
    isPrint,
    plan,
    offering,
    location,
    language,
    expiresAt,
    vasUpdateFeatureToggle,
    ...toggleFavoritePlan,
    ...downloadPlanSummary,
  };
};

type TogglePlanParams = Pick<
  ExplorerPlanStateProps,
  "kioskMode" | "confirmDeselection" | "onPlanDeselected" | "trackElementClicked" | "plan"
>;

export const useToggleFavoritePlan = ({
  kioskMode,
  confirmDeselection,
  onPlanDeselected,
  trackElementClicked,
  plan,
}: TogglePlanParams) => {
  const { pageId } = plan;
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const [favoritePlans, writeFavoritePlans] = useFavoritePlans(pageId);

  const toggleFavoritePlan = useCallback(
    (planId: string) => {
      if (!kioskMode) {
        if (isFavoritePlan(favoritePlans, planId)) {
          setShowAnimation(false);
          if (confirmDeselection) {
            setConfirmingDelete(true);
          } else {
            writeFavoritePlans(favoritePlans.filter((favPlan) => favPlan !== plan.id));
            onPlanDeselected?.(plan);
            trackElementClicked?.({
              module: "Explorer Plan",
              buttonLabel: "Unfavorite",
              explorerPlanId: planId,
            });
          }
        } else {
          setShowAnimation(true);
          writeFavoritePlans([...favoritePlans, planId]);
          trackElementClicked?.({
            module: "Explorer Plan",
            buttonLabel: "Favorite",
            explorerPlanId: planId,
          });
        }
      }
    },
    [
      kioskMode,
      favoritePlans,
      confirmDeselection,
      writeFavoritePlans,
      onPlanDeselected,
      plan,
      trackElementClicked,
    ],
  );

  const onConfirmUnfavorite = useCallback(() => {
    setConfirmingDelete(false);
    writeFavoritePlans(favoritePlans.filter((favPlan) => favPlan !== plan.id));
    onPlanDeselected?.(plan);
    trackElementClicked?.({
      module: "Explorer Plan",
      buttonLabel: "Unfavorite",
      explorerPlanId: plan.id,
    });
  }, [favoritePlans, onPlanDeselected, plan, trackElementClicked, writeFavoritePlans]);

  const onCancelConfirm = useCallback(() => setConfirmingDelete(false), []);

  const isFavPlan = isFavoritePlan(favoritePlans, plan.id);

  return {
    confirmingDelete,
    onCancelConfirm,
    showAnimation,
    isFavPlan,
    toggleFavoritePlan,
    onConfirmUnfavorite,
  };
};

type DownloadPlanParams = Pick<ExplorerPlanStateProps, "plan" | "trackElementClicked">;

export const useDownloadPlanSummary = ({ plan, trackElementClicked }: DownloadPlanParams) => {
  const { planSummaryDocumentId, spanishPlanSummaryDocumentId, id } = plan;

  const { data: documentURL } = useGetPublicDocumentDownloadUrl(planSummaryDocumentId ?? undefined);
  const { data: spanishDocumentURL } = useGetPublicDocumentDownloadUrl(
    spanishPlanSummaryDocumentId ?? undefined,
  );

  const onDownloadPlanSummary = useCallback(
    () =>
      trackElementClicked?.({
        module: "Explorer Plan",
        buttonLabel: "Download plan summary",
        explorerPlanId: id,
      }),
    [id, trackElementClicked],
  );

  return { documentURL, spanishDocumentURL, onDownloadPlanSummary };
};
