import { TrashIcon } from "client/src/components/Icons/TrashIcon";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import styles from "./deleteFileButton.module.less";
interface Props {
  onClick: (() => void) | (() => Promise<void>);
  disabled?: boolean;
}

export const DeleteFileButton = ({ onClick, disabled }: Props) => (
  <Tooltip title="Delete" placement="bottom">
    <span>
      <button
        onClick={() => onClick()}
        title="Remove file"
        aria-label="Remove file"
        className={`btn-reset ${styles.deleteFileButton}`}
        disabled={disabled}
        type="button"
      >
        <TrashIcon />
      </button>
    </span>
  </Tooltip>
);
