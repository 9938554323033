import type { ValueOf } from "./Helper";
import type { BeneficiaryDesignationCoverages } from "shared/types/SlfCoverages";

export const documentBenefitTypes = [
  "ABSENCE_&_ADA",
  "ACCIDENT",
  "BASIC_LTD",
  "BASIC_STD",
  "CANCER",
  "CRITICAL_ILLNESS",
  "DENTAL",
  "DENTAL_ASO",
  "DENTAL_DHMO",
  "EAP",
  "FLI",
  "GAP",
  "HI_TDI",
  "HOSPITAL_INDEMNITY",
  "LIFE",
  "MEDICAL",
  "NJ_TDB",
  "NY_DB",
  "PFML_CO",
  "PFML_CT",
  "PFML_MA",
  "PFML_OR",
  "PFML_WA",
  "STAND_ALONE_VOL_AD&D",
  "SUN_ADVISOR",
  "VISION",
  "VOL_AD&D",
  "VOL_LIFE",
  "VOL_LTD",
  "VOL_STD",
] as const;

export type DocumentBenefitType = ValueOf<typeof documentBenefitTypes>;

export const documentBenefitTypesNames: Record<DocumentBenefitType, string> = {
  MEDICAL: "Medical",
  ACCIDENT: "Accident",
  CRITICAL_ILLNESS: "Critical Illness",
  CANCER: "Cancer",
  GAP: "Gap",
  DENTAL: "Dental",
  DENTAL_DHMO: "Dental DHMO",
  DENTAL_ASO: "Dental ASO",
  VISION: "Vision",
  SUN_ADVISOR: "SunAdvisor",
  "ABSENCE_&_ADA": "Absence & ADA",
  EAP: "EAP",
  PFML_CO: "PFML CO",
  PFML_CT: "PFML CT",
  PFML_MA: "PFML MA",
  PFML_OR: "PFML OR",
  PFML_WA: "PFML WA",
  HOSPITAL_INDEMNITY: "Hospital Indemnity",
  "VOL_AD&D": "Vol AD&D",
  "STAND_ALONE_VOL_AD&D": "Stand-Alone Vol AD&D",
  VOL_STD: "Vol STD",
  VOL_LTD: "Vol LTD",
  VOL_LIFE: "Vol Life",
  LIFE: "Life",
  BASIC_STD: "Basic STD",
  BASIC_LTD: "Basic LTD",
  NJ_TDB: "NJ Temporary Disability",
  HI_TDI: "HI Temporary Disability",
  NY_DB: "NY Disability Benefits",
  FLI: "Family Leave Insurance",
};

export const beneficiaryDesignationCoveragesToDocumentBenefitType: Record<
  BeneficiaryDesignationCoverages,
  DocumentBenefitType
> = {
  "Basic Life": "LIFE",
  "Voluntary Life": "LIFE",
  "Stand-alone Vol AD&D": "STAND_ALONE_VOL_AD&D",
};

export function isDocumentBenefitType(benefitType: string): benefitType is DocumentBenefitType {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isDocumentBenefitType = documentBenefitTypes.includes(benefitType as DocumentBenefitType);
  return isDocumentBenefitType;
}
