/* eslint-disable use-encapsulation/prefer-custom-hooks -- meh */
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in tsdocs
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { createContext, useContext, useEffect, useRef } from "react";
import { useBlocker } from "react-router-dom";
import type { useFormik } from "formik";

// Auto-Save EIF forms on browser navigation
// https://maxwellhealth.atlassian.net/browse/TB-6070

type Props = {
  formik: Pick<ReturnType<typeof useFormik>, "isSubmitting" | "dirty" | "submitForm">;
  /**
   * If you use `optimistic={true}`, make sure your component is not navigating
   * after it has unmounted. You can use the {@link useNavigateIfMounted}
   * instead of the regular `useNavigate` for that.
   *
   * @see {@link useNavigateIfMounted}
   */
  optimistic?: boolean;
};

export const AutoSaveContext = createContext({ canceledAutoSave: false });

export function AutoSaveOnNavigation(props: Props) {
  const { formik, optimistic } = props;

  const { canceledAutoSave } = useContext(AutoSaveContext);

  const wasBlocked = useRef(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const currentURL = currentLocation.pathname + currentLocation.hash + currentLocation.search;
    const nextURL = nextLocation.pathname + nextLocation.hash + nextLocation.search;
    const shouldBlock =
      currentURL !== nextURL && formik.dirty && (!isSubmitting || wasBlocked.current);
    wasBlocked.current = shouldBlock;
    return shouldBlock;
  });

  const { state, proceed } = blocker;
  const { isSubmitting, dirty, submitForm } = formik;

  useEffect(() => {
    if (state === "blocked") {
      if (canceledAutoSave) {
        wasBlocked.current = false;
        proceed();
        return;
      }

      if (optimistic) {
        void submitForm();
        wasBlocked.current = false;
        proceed();
      } else {
        submitForm()
          .then(() => {
            wasBlocked.current = false;
            proceed();
          })
          .catch(() => {
            // We don't want to block navigation if
            // there's an error when submitting the form,
            // or we would trap the user in the current page
            wasBlocked.current = false;
            proceed();
          });
      }
    }
  }, [state, canceledAutoSave, optimistic, submitForm, proceed]);

  // Prevent browser refresh on unsaved (dirty) form
  useEffect(() => {
    const beforeUnloadListener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (dirty) {
      window.addEventListener("beforeunload", beforeUnloadListener);
    }

    return () => {
      if (dirty) {
        window.removeEventListener("beforeunload", beforeUnloadListener);
      }
    };
  }, [dirty]);

  return null;
}

/* eslint-enable use-encapsulation/prefer-custom-hooks */
