import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { StackX } from "client/src/components/Spacing/Spacing";
import { useUpdatePolicy } from "client/src/hooks/policy";
import clsx from "clsx";
import { useState } from "react";

import { exhaustiveCheck } from "../../../../../shared/utils/exhaustiveCheck";
import { Anchor } from "../../../components/Anchor/Anchor";
import { Button } from "../../../components/Button/Button";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { ReactComponent as ListProgressCompleted } from "../../../components/Icons/listProgressCompleted.svg";
import { OrderedList, OrderedListItem } from "../../../components/OrderedList/OrderedList";
import { H5 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { DocumentDownload } from "../../../domain/Document/DocumentDownload/DocumentDownload";
import { DocumentUpload } from "../../../domain/Document/DocumentUpload/DocumentUpload";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { useGetDocuments } from "../../../hooks/document";
import { triggerError } from "../../../hooks/generalError";

import * as styles from "./pfmlSPDUploadCard.module.less";

import type { TaskStatus } from "../../../../../shared/data/Tasks";
import type { ElementClickedOptions } from "../../../utils/analytics";
import type { ClientId, Policy } from "shared/types/Client";
import type { PFMLStates } from "shared/types/Plan";

type PFMLSPDDocumentCategory =
  | "summary-plan-description-pfml-connecticut"
  | "summary-plan-description-pfml-colorado"
  | "summary-plan-description-pfml-massachusetts"
  | "summary-plan-description-pfml-oregon"
  | "summary-plan-description-pfml-washington";

type PFMLSPDUploadCardProps = {
  clientId: ClientId;
  policy: Policy;
  state: PFMLStates;
  useSunLifeSPD: boolean;
  spdCompletedForPFML: boolean;
  sunLifeSPDLink: string;
  documentCategory: PFMLSPDDocumentCategory;
  trackElementClicked: ({ buttonLabel, module, moduleState }: ElementClickedOptions) => void;
};

const getTaskStatus = (
  useSunLifeSPD: boolean,
  spdCompletedForPFML: boolean,
  hasCustomDocuments: boolean,
): TaskStatus => {
  if (useSunLifeSPD || (spdCompletedForPFML && hasCustomDocuments)) {
    return "Done";
  }

  return hasCustomDocuments ? "In Review" : "Not Started";
};

const getSPDPFMLProperty = (documentCategory: PFMLSPDDocumentCategory) => {
  switch (documentCategory) {
    case "summary-plan-description-pfml-connecticut":
      return "connecticutUseSLFSPDForPFML";
    case "summary-plan-description-pfml-colorado":
      return "coloradoUseSLFSPDForPFML";
    case "summary-plan-description-pfml-massachusetts":
      return "massachusettsUseSLFSPDForPFML";
    case "summary-plan-description-pfml-oregon":
      return "oregonUseSLFSPDForPFML";
    case "summary-plan-description-pfml-washington":
      return "washingtonUseSLFSPDForPFML";
    default:
      exhaustiveCheck(documentCategory);
      return triggerError();
  }
};

export const PFMLSPDUploadCard = ({
  clientId,
  policy,
  state,
  useSunLifeSPD,
  spdCompletedForPFML,
  documentCategory,
  sunLifeSPDLink,
  trackElementClicked,
}: PFMLSPDUploadCardProps) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const { mutateAsync: updatePolicy, isPending: isUpdating } = useUpdatePolicy();
  const { data: documents } = useGetDocuments({ clientId, categories: [documentCategory] });

  const hasCustomDocuments = !!documents?.length;
  const getUseSunLifeSPDProp = getSPDPFMLProperty(documentCategory);

  const track = (buttonLabel: string) => {
    trackElementClicked({
      buttonLabel,
      module: `Upload your SPD for ${state}`,
    });
  };

  const trackDownloadSunLifeSPD = () => {
    track(`Download Sun Life’s SPD for ${state}`);
  };

  const onUploadCustomDocument = async () => {
    try {
      const { isError } = await updatePolicy({
        data: { [getUseSunLifeSPDProp]: false },
        params: { clientId, policyId: policy.id },
      });
      if (isError) {
        void slobMessage.error(genericErrorCopy2);
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    }
  };

  const taskStatus = getTaskStatus(useSunLifeSPD, spdCompletedForPFML, hasCustomDocuments);
  const bannerMessage = `You have uploaded your SPD for ${state}. Your Implementation Consultant will review it and contact you with any questions.`;

  return (
    <AddFilesCard
      clientId={clientId}
      taskStatus={taskStatus}
      category={documentCategory}
      title={<H5 as="h3">Upload your SPD for {state}</H5>}
      message={bannerMessage}
      actionRequiredMessage={null}
      trackElementClicked={trackElementClicked}
      taskCompletedTitle=""
      remainingTasks={false}
      hideButton={true}
      hideTable={true}
      hideRule={true}
      description={
        <>
          {!useSunLifeSPD && !hasCustomDocuments && (
            <>
              <div className={styles.list}>
                <OrderedList>
                  <OrderedListItem>
                    Download and review{" "}
                    <Anchor href={sunLifeSPDLink} onClick={trackDownloadSunLifeSPD}>
                      Sun Life’s standard SPD for {state}
                    </Anchor>
                  </OrderedListItem>
                  <OrderedListItem>Add your information to the template.</OrderedListItem>
                  <OrderedListItem>
                    Upload the completed template to Sun Life Onboard for review.
                  </OrderedListItem>
                </OrderedList>
              </div>
              <StackX dist={32} className="mt-16">
                <Button
                  type="primary"
                  size="middle"
                  loading={isUpdating}
                  onClick={() => setShowUploadModal(true)}
                >
                  Secure Upload
                </Button>
                <StoredSecurelyText track={track} />
              </StackX>
            </>
          )}
          {useSunLifeSPD && (
            <>
              <div className={clsx("body3", styles.completed)}>
                <ListProgressCompleted /> You have indicated that you’re going to use Sun Life’s SPD
                as-is.
              </div>
              <Button
                size="middle"
                type="text-only"
                onClick={() => {
                  track("Never mind");
                  setShowUploadModal(true);
                }}
              >
                Never mind, I’d like to upload a version with comments
              </Button>
              <p className="text-center">
                <Anchor target="_blank" href={sunLifeSPDLink} onClick={trackDownloadSunLifeSPD}>
                  Download Sun Life’s original SPD file
                </Anchor>
              </p>
            </>
          )}
          {hasCustomDocuments && (
            <>
              <DocumentDownload clientId={clientId} categories={[documentCategory]} track={track} />
              <div className={clsx("body5", "mt-32", "mb-8", "text-center")}>
                Missed something?{" "}
                <ButtonOld
                  size="small"
                  type="link-inline"
                  onClick={() => {
                    setShowUploadModal(true);
                    track("Add more files");
                  }}
                >
                  Add more files
                </ButtonOld>{" "}
                or{" "}
                <Anchor target="_blank" href={sunLifeSPDLink} onClick={trackDownloadSunLifeSPD}>
                  Download Sun Life’s standard SPD for {state}
                </Anchor>
              </div>

              <div className={styles.storedSecurely}>
                <StoredSecurelyText track={track} />
              </div>
            </>
          )}
          <DocumentUpload
            modalTitle={"Upload"}
            visible={showUploadModal}
            closeModal={() => setShowUploadModal(false)}
            clientId={clientId}
            category={documentCategory}
            onUpload={onUploadCustomDocument}
            track={track}
            singleFile={false}
          />

          <div className={styles.storedSecurely}>
            <StoredSecurelyText track={track} />
          </div>
        </>
      }
    />
  );
};
