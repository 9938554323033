import { useState } from "react";

import { useDeleteWebLink } from "../../../hooks/webLink";

import type { ClientId } from "../../../../../shared/types/Client";
import type { WebLinkCategory, WebLinkId } from "../../../../../shared/types/WebLink";

export function useWebLinkDeletionProps({
  clientId,
  track,
}: {
  clientId: ClientId;
  track: (label: string) => void;
}) {
  const emptyDeleteParamsWebLink = {
    isVisible: false,
    webLinkIdToDelete: null,
    name: null,
    category: null,
  };

  const [deleteWebLinkParam, setDeleteWebLinkParam] = useState<{
    isVisible: boolean;
    webLinkIdToDelete: WebLinkId | null;
    name: string | null;
    category: WebLinkCategory | null;
  }>(emptyDeleteParamsWebLink);

  const {
    isPending: isPendingDeleteWebLink,
    error: errorDeleteWebLink,
    mutateAsync: deleteWebLink,
  } = useDeleteWebLink(deleteWebLinkParam.category);

  const openWebLinkDeletionConfirmDialog = (
    webLinkId: WebLinkId,
    name: string,
    category: WebLinkCategory,
  ) => {
    track("Web link delete");
    setDeleteWebLinkParam({ isVisible: true, webLinkIdToDelete: webLinkId, name, category });
  };

  const handleConfirmDeleteWebLink = async () => {
    track("Web link delete confirmed");
    if (deleteWebLinkParam.webLinkIdToDelete) {
      try {
        await deleteWebLink({
          params: { webLinkId: deleteWebLinkParam.webLinkIdToDelete, clientId },
        });
      } finally {
        setDeleteWebLinkParam(emptyDeleteParamsWebLink);
      }
    }
  };

  const resetDeleteWebLinkParams = () => {
    track("Web link delete canceled");
    setDeleteWebLinkParam(emptyDeleteParamsWebLink);
  };

  const webLinkDeleteConfirmDialogVisible = deleteWebLinkParam.isVisible;
  const webLinkToDeleteName = deleteWebLinkParam.name || "";

  return {
    webLinkDeleteConfirmDialogVisible,
    webLinkToDeleteName,
    openWebLinkDeletionConfirmDialog,
    handleConfirmDeleteWebLink,
    resetDeleteWebLinkParams,
    isPendingDeleteWebLink,
    errorDeleteWebLink,
  };
}
