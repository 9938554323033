import { taxChoiceOptions } from "shared/types/EmployeeClassPlan";
import { eligibilityEmployeeDefinitions, qpsBenefitCodes } from "shared/types/QPSClass";
import { assertIsDefined } from "shared/utils/utils";
import { employeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";
import { monthDaySchema } from "shared/validation/monthDaySchema";
import * as Yup from "yup";
import {
  classCompensationTypes,
  classEmploymentTypes,
  earningsTypesValues,
  earningsTimeFramesValues,
  currentEarningsValues,
  numberOfYearsValues,
  organizationTypesValues,
  incomeTypesPartnershipValues,
  incomeTypesSCorporationValues,
  averagedOverValues,
  employeeClassEarningsFields,
} from "../types/EmployeeClass";
import { nullNotAllowedTestConfig } from "./validation";
import type {
  AdditionalCompensationMostCommon,
  ClassCompensationType,
  ClassEmploymentType,
  DuplicateEmployeeClassInput,
} from "../types/EmployeeClass";
import type {
  EarningsType,
  EarningsTimeFrame,
  CurrentEarnings,
  NumberOfYears,
  OrganizationType,
  IncomeTypePartnership,
  IncomeTypeSCorporation,
  AveragedOver,
} from "@prisma/client";
import type { Client, YesNo } from "shared/types/Client";
import type { TaxChoiceOption } from "shared/types/EmployeeClassPlan";
import type { EligibilityEmployeeDefinition, QPSBenefitCode } from "shared/types/QPSClass";

export const employeeClassValidationSchema = Yup.object({
  id: Yup.string().required(),
  clientId: Yup.string().required(),

  // Eligibility
  jobTitles: Yup.array().of(Yup.string().required()).strict().nullable(),
  compensationTypes: Yup.array()
    .of(Yup.mixed<ClassCompensationType>().oneOf(classCompensationTypes.slice()).required())
    .strict()
    .nullable(),
  otherAttributes: Yup.array().of(Yup.string().required()).strict().nullable(),

  // Details
  numberOfEmployees: Yup.number()
    .nullable()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) =>
        schema
          .typeError("Numbers only please")
          .positive("Must be a positive number")
          .integer("Must be a whole number")
          .max(16777215, "Can't be larger than 16777215")
          .nullable()
          .test(nullNotAllowedTestConfig("Please provide the number of eligible employees")),
    }),
  employmentTypes: Yup.array()
    .of(Yup.mixed<ClassEmploymentType>().oneOf(classEmploymentTypes.slice()).required())
    .strict()
    .nullable()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) =>
        schema
          .min(1, "Please provide at least one employment type")
          .test(nullNotAllowedTestConfig("Please provide a least one employment type")),
    }),
  minimumWeeklyHours: Yup.number()
    .typeError("Numbers only please")
    .nullable()
    .when(["minimumWeeklyHoursAreNotCalculatedWeekly", "$prefill"], {
      is: (
        minimumWeeklyHoursAreNotCalculatedWeekly: boolean | undefined,
        prefill: boolean | undefined,
      ) => !prefill && !minimumWeeklyHoursAreNotCalculatedWeekly,
      then: (schema) =>
        schema
          .typeError("Numbers only please")
          .positive("Must be a positive number")
          .nullable()
          .test(nullNotAllowedTestConfig("Please provide the minimum hours")),
    }),
  minimumWeeklyHoursAreNotCalculatedWeekly: Yup.boolean().nullable(),
  minimumHoursDetails: Yup.string()
    .nullable()
    .when(["minimumWeeklyHoursAreNotCalculatedWeekly", "$prefill"], {
      is: (
        minimumWeeklyHoursAreNotCalculatedWeekly: boolean | undefined,
        prefill: boolean | undefined,
      ) => !prefill && minimumWeeklyHoursAreNotCalculatedWeekly,
      then: (schema) => schema.required("Please provide the minimum hours"),
    }),

  // QPS
  groupName: Yup.string().nullable(),
  importedFromQPS: Yup.bool().nullable(),

  // Earnings
  customEarnings: Yup.boolean().nullable(),
  customEarningsDescription: Yup.string()
    .nullable()
    .when("customEarnings", {
      is: true,
      then: (schema) =>
        schema
          .max(2500, "Cannot be longer than 2,500 characters.")
          .required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  earningsType: Yup.mixed<EarningsType>()
    .oneOf<EarningsType>(earningsTypesValues)
    .nullable()
    .when("customEarnings", {
      is: (customEarnings?: boolean | null | undefined) =>
        customEarnings !== undefined && !customEarnings,
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  earningsTimeFrame: Yup.mixed<EarningsTimeFrame>()
    .oneOf<EarningsTimeFrame>(earningsTimeFramesValues)
    .nullable()
    .when("earningsType", {
      is: "GROSS_EARNINGS",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  currentEarnings: Yup.mixed<CurrentEarnings>()
    .oneOf<CurrentEarnings>(currentEarningsValues)
    .nullable()
    .when(["earningsType", "earningsTimeFrame"], {
      is: (earningsType: EarningsType, earningsTimeFrame: EarningsTimeFrame) =>
        earningsType === "GROSS_EARNINGS" && earningsTimeFrame === "CURRENT_EARNINGS",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  includeFirstDayOfTheMonth: Yup.boolean().nullable(),

  numberOfYears: Yup.mixed<NumberOfYears>()
    .oneOf<NumberOfYears>(numberOfYearsValues)
    .strict()
    .nullable()
    .when(["earningsType", "earningsTimeFrame"], {
      is: (earningsType: EarningsType, earningsTimeFrame: EarningsTimeFrame) =>
        (earningsType === "GROSS_EARNINGS" &&
          earningsTimeFrame === "PRIOR_CALENDAR_YEAR_EARNINGS") ||
        earningsType === "W2_EARNINGS",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  frozenEarningsAsOf: Yup.string()
    .nullable()
    .when(["earningsType", "earningsTimeFrame"], {
      is: (earningsType: EarningsType, earningsTimeFrame: EarningsTimeFrame) =>
        earningsType === "GROSS_EARNINGS" && earningsTimeFrame === "FROZEN_EARNINGS",
      then: (_) => monthDaySchema,
    }),

  additionalCompensations: Yup.array()
    .of(Yup.string().required())
    .nullable()
    .when("earningsType", {
      is: "GROSS_EARNINGS",
      then: (schema) =>
        schema
          .of(
            Yup.string()
              .trim("The name cannot include leading and trailing spaces")
              .max(
                191,
                (params) => `Cannot be longer than 191 characters: "${String(params.value)}".`,
              )
              .strict()
              .required(),
          )
          .nullable(),
    }),

  averagedOverComissions: averagedOverSchema(
    "Commissions",
    "Please tell us how your comissions will be averaged over",
  ),
  averagedOverBonuses: averagedOverSchema(
    "Bonuses",
    "Please tell us how your bonuses will be averaged over",
  ),
  averagedOverOvertimePay: averagedOverSchema(
    "Overtime pay",
    "Please tell us how your overtime pay will be averaged over",
  ),

  additionalCompensationsAveragedWithEarnings: Yup.boolean().nullable(),

  organizationType: Yup.mixed<OrganizationType>()
    .oneOf<OrganizationType>(organizationTypesValues)
    .nullable()
    .when("earningsType", {
      is: "PARTNERS_OWNERS_SHAREHOLDERS",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  incomeTypePartnership: Yup.mixed<IncomeTypePartnership>()
    .oneOf<IncomeTypePartnership>(incomeTypesPartnershipValues)
    .nullable()
    .when(["earningsType", "organizationType"], {
      is: (earningsType: EarningsType, organizationType: OrganizationType) => {
        return (
          earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" && organizationType === "PARTNERSHIP"
        );
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  incomeTypeSCorporation: Yup.mixed<IncomeTypeSCorporation>()
    .oneOf<IncomeTypeSCorporation>(incomeTypesSCorporationValues)
    .nullable()
    .when(["earningsType", "organizationType"], {
      is: (earningsType: EarningsType, organizationType: OrganizationType) => {
        return (
          earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" && organizationType === "S_CORPORATION"
        );
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  scheduleK1PaidByPolicyholder: Yup.mixed<YesNo>()
    .oneOf<YesNo>(["YES", "NO"])
    .nullable()
    .when(["$client", "earningsType", "organizationType"], {
      is: (
        client: Client | undefined,
        earningsType: EarningsType,
        organizationType: OrganizationType,
      ) => {
        assertIsDefined(client, "client");
        return (
          client.healthcareProfessionalsSegment &&
          earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
          organizationType === "PARTNERSHIP"
        );
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  earningsPaidToShareholdersCompany: Yup.mixed<YesNo>()
    .oneOf<YesNo>(["YES", "NO"])
    .nullable()
    .when(["$client", "earningsType", "organizationType"], {
      is: (
        client: Client | undefined,
        earningsType: EarningsType,
        organizationType: OrganizationType,
      ) => {
        assertIsDefined(client, "client");
        return (
          client.healthcareProfessionalsSegment &&
          earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
          organizationType === "S_CORPORATION"
        );
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
});

export const earningsValidationSchema = employeeClassValidationSchema.pick(
  employeeClassEarningsFields,
);

export const newEmployeeClassValidationSchema = employeeClassValidationSchema.omit(["id"]);

export const duplicateFieldNamesToCheck: (keyof DuplicateEmployeeClassInput)[] = [
  "eligibility",
  "details",
  "benefits",
  "waitingPeriods",
  "contributions",
];

const duplicateTestConfig: Yup.TestConfig<boolean | undefined> = {
  name: "selection required",
  message: "Please provide a response",
  test: (_value, context) => {
    const parent: DuplicateEmployeeClassInput = context.parent;
    const hasAnyAttributeSelected = duplicateFieldNamesToCheck.some((field) =>
      Boolean(parent[field]),
    );
    const isValid = hasAnyAttributeSelected;
    return isValid;
  },
};

export const duplicateClassValidationSchema = Yup.object({
  eligibility: Yup.boolean().test(duplicateTestConfig).required(),
  details: Yup.boolean().test(duplicateTestConfig).required(),
  benefits: Yup.boolean().test(duplicateTestConfig).required(),
  waitingPeriods: Yup.boolean().test(duplicateTestConfig).required(),
  contributions: Yup.boolean().test(duplicateTestConfig).required(),
  earnings: Yup.boolean().test(duplicateTestConfig).required(),
});

function averagedOverSchema(
  additionalCompensation: AdditionalCompensationMostCommon,
  message: string,
) {
  return Yup.mixed<AveragedOver>()
    .oneOf<AveragedOver>(averagedOverValues.slice())
    .strict()
    .nullable()
    .when(["earningsType", "additionalCompensations"], {
      is: (earningsType: EarningsType, additionalCompensations: string[] | undefined) => {
        const compensationSelected = additionalCompensations?.includes(additionalCompensation);
        return earningsType === "GROSS_EARNINGS" && compensationSelected;
      },
      then: (schema) => schema.required(message),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    });
}

export const createClassFromQPSDraftValidation = Yup.object({
  groupName: Yup.string().required(),
  qpsBasicClasses: Yup.array()
    .of(
      Yup.object({
        benefitCode: Yup.mixed<QPSBenefitCode>().oneOf(qpsBenefitCodes).required(),
        planDesignSeqNumber: Yup.number().required(),
        ratingGroupNumber: Yup.number().required(),
        ratingClassNumber: Yup.number().required(),
        adminClassNumber: Yup.number().required(),
        planDesignName: Yup.string().required(),
        ratingClassName: Yup.string().required(),
        adminClassName: Yup.string().required(),
        benefitStateCode: Yup.string().nullable().required(),
        eligibleEmployeeDefinition: Yup.mixed<EligibilityEmployeeDefinition>()
          .oneOf<EligibilityEmployeeDefinition>(eligibilityEmployeeDefinitions)
          .required(),
        employeeClass: employeeClassValidationSchema
          .shape({
            id: Yup.string().optional(),
            clientId: Yup.string().required(),
          })
          .required(),
        employeeClassPlan: Yup.array()
          .of(
            employeeClassPlanValidationSchema.shape({
              id: Yup.string().optional(),
              employeeClassId: Yup.string().optional(),
              taxChoice: Yup.array()
                .of(Yup.mixed<TaxChoiceOption>().oneOf(taxChoiceOptions.slice()).required())
                .optional(),
            }),
          )
          .required(),
      }),
    )
    .min(1)
    .required(),
});
