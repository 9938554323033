import { faChevronLeft, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { H2 } from "client/src/components/Typography/Typography";
import { MonthlyClaimsReportsAndEOBsReviewCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/MonthlyClaimsReportsAndEOBsReviewCard";
import { EIFMonthlyClaimsReportsAndEOBsCreate } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFMonthlyClaimsReportsAndEOBsCreate";
import { EIFMonthlyClaimsReportsAndEOBsForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFMonthlyClaimsReportsAndEOBsForm";
import { MissingMonthlyClaimsAlert } from "client/src/domain/EIF/PlanAdministratorsAndBilling/MissingMonthlyClaimsAlert";
import { getPFMLPlanStatesNotManagedBySLF } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/claimsCheckMailingLocations";
import { getPrefillErrorsForMonthlyClaimsReportsAndEOBs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/monthlyClaimsReportsAndEOBs";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import {
  useCreateMonthlyClaimsReportMailingLocation,
  useDeleteMonthlyClaimsReportMailingLocation,
  useUpdateMonthlyClaimsReportMailingLocation,
} from "client/src/hooks/monthlyClaimsReportMailingLocation";
import { ResponseError } from "client/src/hooks/query";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useToggler } from "client/src/hooks/useToggler";
import { Fragment, useState } from "react";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { type Plan } from "shared/types/Plan";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getPlansWhereCanSetMonthlyClaimsLocations } from "shared/utils/EIF/monthlyClaimsReportsAndEOBs";
import { getEIFSubStepViewMode } from "shared/utils/client";
import { assertIsDefinedInObject, unique } from "shared/utils/utils";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { MonthlyClaimsReportMailingLocation } from "shared/types/MonthlyClaimsReportMailingLocation";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const eifSubStepId = "monthly-claims-reports-and-eobs";

const subStepName = getEIFSubStepMap({ eifSubStepId });

type Props = {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  updateClientQuery: UpdateClientQuery;
  featureToggles: ClientFeatureToggles;
};

export function EIFMonthlyClaimsReportsAndEOBs(props: Props) {
  const {
    client,
    clientPlans,
    contacts,
    authUser,
    changeSnapshot,
    updateClientQuery,
    featureToggles,
  } = props;

  const adminContacts = contacts.filter((contact) => contact.type === "WEB_ADMIN");

  const { mutateAsync: updateClient } = updateClientQuery;

  const createQuery = useCreateMonthlyClaimsReportMailingLocation();
  const updateQuery = useUpdateMonthlyClaimsReportMailingLocation();
  const deleteQuery = useDeleteMonthlyClaimsReportMailingLocation();

  const isLoading =
    updateClientQuery.isPending ||
    createQuery.isPending ||
    updateQuery.isPending ||
    deleteQuery.isPending;

  const relevantPlans = getPlansWhereCanSetMonthlyClaimsLocations(clientPlans);

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  assertIsDefinedInObject(client, "monthlyClaimsReportMailingLocations");

  const [viewState, setViewState] = useState<
    | { type: "create" }
    | { type: "list" }
    | { type: "create-additional" }
    | {
        type: "edit";
        monthlyClaimsReportMailingLocation: MonthlyClaimsReportMailingLocation;
        index: number;
      }
  >(
    client.monthlyClaimsReportsAndEOBsDefaults !== "EDITED_DEFAULTS" ||
      client.monthlyClaimsReportMailingLocations.length === 0
      ? { type: "create" }
      : { type: "list" },
  );

  const [editPreferences, toggleEditPreferences] = useToggler();

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    plans: clientPlans,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const showFAQ = editPreferences || client.monthlyClaimsReportMailingLocations.length > 0;

  const claimReportsAndEOBsNotManagedBySLF = getPFMLPlanStatesNotManagedBySLF(clientPlans);

  const tellUsMessage =
    relevantPlans.length === 1
      ? `Tell us where monthly claims reports and explanation of benefits (EOB) documents should be sent for ${
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
          benefitTypeToCoverage[relevantPlans[0]!.benefitType]
        }.`
      : "Tell us where monthly claims reports and explanation of benefits (EOB) documents should be sent.";

  const showTellUsMessage =
    // actively editing or have edited and created 1 or more locations
    editPreferences ||
    (client.monthlyClaimsReportsAndEOBsDefaults === "EDITED_DEFAULTS" &&
      client.monthlyClaimsReportMailingLocations.length > 0);

  const viewMode = getEIFSubStepViewMode({ client });

  return (
    <StackY dist={20} wrap={false}>
      <div>
        <H2>{subStepName}</H2>

        {showTellUsMessage && <p>{tellUsMessage}</p>}
      </div>

      <EIFEntityDeletedBy
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        client={client}
        entity="MonthlyClaimsReportMailingLocation"
        prefixWord="Mailing"
        pluralizeWord="Location"
      />

      {viewState.type === "create" || client.monthlyClaimsReportMailingLocations.length === 0 ? (
        <EIFMonthlyClaimsReportsAndEOBsCreate
          client={client}
          relevantPlans={relevantPlans}
          claimReportsAndEOBsNotManagedBySLF={claimReportsAndEOBsNotManagedBySLF}
          adminContacts={adminContacts}
          authUser={authUser}
          featureToggles={featureToggles}
          updateClientQuery={updateClientQuery}
          changeSnapshot={changeSnapshot}
          onSave={() => setViewState({ type: "list" })}
          editPreferences={editPreferences}
          toggleEditPreferences={toggleEditPreferences}
        />
      ) : (
        <>
          <HubCard>
            <StackY dist={20} wrap={false}>
              {client.monthlyClaimsReportMailingLocations.map((mailingLocation, index) => {
                const prefillErrors = getPrefillErrorsForMonthlyClaimsReportsAndEOBs(
                  mailingLocation,
                  viewMode,
                  changeSnapshot,
                );

                return (
                  <Fragment key={mailingLocation.id}>
                    {viewState.type === "edit" && viewState.index === index ? (
                      <>
                        <h5>Mailing Location {index + 1}</h5>
                        <EIFMonthlyClaimsReportsAndEOBsForm
                          client={client}
                          relevantPlans={relevantPlans}
                          monthlyClaimsReportMailingLocation={mailingLocation}
                          claimReportsAndEOBsNotManagedBySLF={claimReportsAndEOBsNotManagedBySLF}
                          adminContacts={adminContacts}
                          authUser={authUser}
                          featureToggles={featureToggles}
                          updateClient={updateClient}
                          changeSnapshot={changeSnapshot}
                          onSave={() => setViewState({ type: "list" })}
                          onCancel={() => setViewState({ type: "list" })}
                          prefillErrors={prefillErrors}
                        />
                      </>
                    ) : (
                      <MonthlyClaimsReportsAndEOBsReviewCard
                        data-testid={`MonthlyClaimsReportsAndEOBsReviewCard__${index + 1}`}
                        index={index + 1}
                        disabled={
                          isLoading ||
                          viewState.type === "create-additional" ||
                          viewState.type === "edit"
                        }
                        client={client}
                        mailingLocation={mailingLocation}
                        changeSnapshot={changeSnapshot}
                        authUser={authUser}
                        featureToggles={featureToggles}
                        onEdit={() =>
                          setViewState({
                            type: "edit",
                            monthlyClaimsReportMailingLocation: mailingLocation,
                            index,
                          })
                        }
                        onDelete={async () => {
                          await deleteQuery.mutateAsync({
                            params: { clientId: client.id, id: mailingLocation.id },
                          });
                          // Go back to the "Create" state if we deleted the last mailing location
                          if (client.monthlyClaimsReportMailingLocations.length === 1) {
                            setViewState({ type: "create" });
                          }
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}

              <div aria-live="assertive" className="hide:empty mt-20">
                {(createQuery.isError || updateQuery.isError || deleteQuery.isError) && (
                  <ErrorMessage>
                    {ResponseError.getUserFacingErrorMessage(
                      createQuery.error || updateQuery.error || deleteQuery.error,
                      GenericErrorCopy2,
                    )}
                  </ErrorMessage>
                )}
              </div>

              {viewState.type === "create-additional" && (
                <>
                  <hr style={{ marginLeft: -32, marginRight: -32 }} />

                  <h5>Mailing Location {client.monthlyClaimsReportMailingLocations.length + 1}</h5>

                  <EIFMonthlyClaimsReportsAndEOBsForm
                    client={client}
                    relevantPlans={relevantPlans}
                    monthlyClaimsReportMailingLocation={null}
                    claimReportsAndEOBsNotManagedBySLF={claimReportsAndEOBsNotManagedBySLF}
                    adminContacts={adminContacts}
                    authUser={authUser}
                    featureToggles={featureToggles}
                    updateClient={updateClient}
                    changeSnapshot={changeSnapshot}
                    onSave={() => setViewState({ type: "list" })}
                    onCancel={() => setViewState({ type: "list" })}
                    prefillErrors={{}}
                  />
                </>
              )}
            </StackY>
          </HubCard>

          <MissingMonthlyClaimsAlert
            client={client}
            relevantPlans={relevantPlans}
            showSuccess
            relevantSubStep="monthly-claims-reports-and-eobs"
          />

          {client.monthlyClaimsReportMailingLocations.length > 0 && (
            <>
              <div>
                <Button
                  type="text-only"
                  size="middle"
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  disabled={viewState.type === "create-additional"}
                  onClick={() => setViewState({ type: "create-additional" })}
                >
                  Create another mailing location
                </Button>
              </div>
              <hr />
            </>
          )}

          <Row justify="space-between" align="middle">
            <Col>
              {previousSubStepLink && (
                <InternalLinkButton
                  to={previousSubStepLink}
                  type="link-inline-bold"
                  size="middle"
                  disabled={isLoading}
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
                  Previous
                </InternalLinkButton>
              )}
            </Col>

            <Col>
              <Button
                to={nextSubStepLink}
                type="primary"
                size="middle"
                disabled={
                  isLoading ||
                  viewState.type !== "list" ||
                  relevantPlans.length >
                    unique(
                      client.monthlyClaimsReportMailingLocations.flatMap((m) => m.slfCoverages),
                    ).length
                }
              >
                Next
              </Button>
            </Col>
          </Row>
        </>
      )}

      {showFAQ && (
        <>
          <div className="py-4">
            <HorizontalDivider />
          </div>
          <H2>
            Why do I have to set mailing locations for claims reports and EOB statements together?
          </H2>
          <p>
            It is a Sun Life requirement that to have multiple EOB mailing locations, each EOB
            location must have a matching contact for the monthly claims report.
          </p>
        </>
      )}
    </StackY>
  );
}
