import { useSlobQuery } from "./query";

import type { SlfImplementationConsultant } from "shared/types/User";

export const useGetImplementationConsultants = () =>
  useSlobQuery<{ data: SlfImplementationConsultant[] }>({
    method: "get",
    path: "/api/clients/implementation-consultant",
    map: (response) => ({ data: response.data }),
  });
