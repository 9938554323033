import * as Yup from "yup";
import type { YesNo, YesNoNotSure } from "@prisma/client";
import type { YesNoShared } from "shared/types/EmployeeClass";

export function mergeSchema(
  first: Yup.StringSchema<string | undefined, Record<string, unknown>, string | undefined>,
  ...rest: Yup.StringSchema<string | undefined, Record<string, unknown>, string | undefined>[]
) {
  const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

  return merged;
}

export const nullNotAllowedTestConfig = <T>(message?: string): Yup.TestConfig<T | null> => ({
  name: "null not allowed",
  message: message || "Please provide a response",
  // This is an intentional triple equals
  test: (value) => value !== null,
});

export const undefinedNotAllowedTestConfig = <T>(message?: string): Yup.TestConfig<T | null> => ({
  name: "undefined not allowed",
  message: message || "Please provide a response",
  // This is an intentional triple equals
  test: (value) => value !== undefined,
});

// eslint-disable-next-line @typescript-eslint/ban-types -- disable
export const getOneOfValidation = <T extends {}>(enums: readonly T[], message?: string) => {
  return Yup.mixed<T>()
    .oneOf<T>(enums)
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.test(nullNotAllowedTestConfig(message)),
    });
};

// eslint-disable-next-line @typescript-eslint/ban-types -- disable
export const getOneOfValidationRequired = <T extends {}>(enums: readonly T[], message?: string) => {
  return Yup.mixed<T>().oneOf<T>(enums, message);
};

export const yesNoRequired = Yup.mixed<YesNo>()
  .oneOf<YesNo>(["YES", "NO"])
  .required("Please provide a response");
export const yesNoNullablePrefill = getOneOfValidation<YesNo>(["YES", "NO"]);
export const yesNoNullable = Yup.mixed<YesNo>().oneOf<YesNo>(["YES", "NO"]).nullable();
export const yesNoNullableRequired = yesNoNullable.test(nullNotAllowedTestConfig());

export const yesNoNotSureNullableRequired = Yup.mixed<YesNoNotSure>()
  .oneOf<YesNoNotSure>(["YES", "NO", "NOT_SURE"])
  .nullable()
  .test(nullNotAllowedTestConfig());

export const yesNoNotSureNullablePrefill = getOneOfValidation<YesNoNotSure>([
  "YES",
  "NO",
  "NOT_SURE",
]);

export const yesNoSharedRequired = Yup.mixed<YesNoShared>()
  .oneOf<YesNoShared>(["YES", "NO", "SHARED"])
  .required("Please provide a response");

export const percentageValidationSchema = Yup.number()
  .min(0, "Cannot be less than 0%")
  .max(100, "Cannot be greater than 100%")
  .required("Please enter an amount")
  .transform((val: number | undefined) => (val != null ? val : null))
  .typeError("Numbers only please");
