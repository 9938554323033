import { DocumentUploadContent } from "client/src/domain/Document/DocumentUpload/DocumentUpload";
import type { ClientId } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

export type AddMedicalVideoProps = {
  clientId: ClientId;
  dragEnabled?: boolean;
  disabled?: boolean;
  uploadButtonDisabled?: boolean;
  onChange?: (documentId: string | null) => void;
};

export const AddMedicalVideo = ({
  clientId,
  dragEnabled,
  disabled = false,
  uploadButtonDisabled = false,
  onChange,
}: AddMedicalVideoProps) => {
  const category: DocumentCategory = "medical-video";

  return (
    <DocumentUploadContent
      clientId={clientId}
      category={category}
      showCancelButton={false}
      hideUploadButtonInitially
      dragEnabled={dragEnabled}
      singleFile={true}
      disabled={disabled}
      uploadButtonDisabled={uploadButtonDisabled}
      track={() => null}
      onUpload={(documents) => documents.length && documents[0] && onChange?.(documents[0].id)}
    />
  );
};
