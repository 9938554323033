import { getContactHasExpectedAccess } from "shared/utils/contact";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import { locationInputValidation, locationStateCodeValidation } from "shared/validation/location";
import * as Yup from "yup";
import { LocationManagementTypes, contactTypes } from "../types/Contact";
import type { LocationManagementType } from "@prisma/client";
import type { Bill } from "shared/types/Bill";
import type { Contact, ContactType } from "shared/types/Contact";
import "./email";

export const phoneNumberValidation = Yup.string()
  .trim()
  .matches(/^\d{3}-\d{3}-\d{4}$/, "Phone number needs to be in the format ###-###-####");

export const firstNameSchema = Yup.string()
  .trim()
  .nullable()
  .defined()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !prefill,
    then: (schema) => schema.required("First name is required"),
  });

export const lastNameSchema = Yup.string()
  .trim()
  .nullable()
  .defined()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !prefill,
    then: (schema) => schema.required("Last name is required"),
  });

export const emailSchema = Yup.string()
  .emailV1("Email must be in a valid format i.e. - example@example.com")
  .trim()
  .nullable()
  .defined()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !prefill,
    then: (schema) => schema.required("Email is required"),
  });

export const firstLastEmailUndefinedSchema = Yup.string()
  .trim()
  .nullable()
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !prefill,
    then: (schema) => schema.required("Field is required"),
  });

export const contactTypeValidationSchema = Yup.mixed<ContactType>()
  .oneOf<ContactType>([...contactTypes])
  .required("Contact type is required")
  .when("$prefill", {
    is: (prefill: boolean | undefined) => !!prefill,
    then: (schema) => schema.nullable(),
  });

export const locationManagementTypeValidation =
  Yup.mixed<LocationManagementType>().oneOf<LocationManagementType>(LocationManagementTypes);

const accessOptionsTestFunction: Yup.TestFunction<boolean | null | undefined, Yup.AnyObject> = (
  _value,
  context,
) => {
  const parent: Pick<
    Contact,
    | "id"
    | "type"
    | "accessesForPolicyIds"
    | "billingAccess"
    | "claimsAccess"
    | "paymentsAccess"
    | "documentsAccess"
    | "memberChangesAccess"
  > = context.parent;

  const {
    id,
    type,
    billingAccess,
    claimsAccess,
    paymentsAccess,
    documentsAccess,
    memberChangesAccess,
  } = parent;

  if (type !== "PRIMARY_WEB_ADMIN" && type !== "WEB_ADMIN") {
    return true;
  }

  if (type === "PRIMARY_WEB_ADMIN") {
    const allAccessSelected =
      billingAccess && claimsAccess && paymentsAccess && documentsAccess && memberChangesAccess;
    return !allAccessSelected
      ? context.createError({
          message: "All access options must be true when contact is the Primary Plan Administrator",
        })
      : true;
  }

  const bills: Bill[] = context.options.context?.bills;
  const prefill = context.options.context?.prefill ?? false;

  assertIsDefined(bills, "bills");

  const policyIds = bills
    .filter((bill) => bill.contactId === id)
    .map((b) => b.policyId)
    .filter(rejectNullableValues);
  const hasBillingAccess = getContactHasExpectedAccess(parent, policyIds, "billingAccess");

  // We want to get this error when appropriate, regardless of prefill status
  if (context.path === "billingAccess" && !hasBillingAccess) {
    return context.createError({
      message:
        "Bills access is required because this administrator is set as a billing contact in your billing preferences",
    });
  }

  if (prefill) {
    return true;
  }

  if (
    !(billingAccess || claimsAccess || paymentsAccess || documentsAccess || memberChangesAccess)
  ) {
    return context.createError({
      message: "Please choose at least one access option",
    });
  }

  return true;
};

const accessOptionsValidationSchema = Yup.boolean().nullable().optional().test({
  name: "access options test",
  message: "Please choose at least one access option",
  test: accessOptionsTestFunction,
});

export const contactWithAddressValidationSchema = Yup.object({
  id: Yup.string().defined(),
  type: contactTypeValidationSchema.required("Contact type is required"),
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  title: Yup.string()
    .trim()
    .notRequired()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Title is required"),
    }),
  phoneNumber: phoneNumberValidation
    .notRequired()
    .transform((val: string | undefined) => (val ? val : null))
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Phone number is required"),
    }),
  faxNumber: Yup.string().trim().optional().nullable(),

  // The address fields on the contact are antiquated, we now use the address fields on the location instead
  address1: Yup.string().trim().nullable().optional(),
  address2: Yup.string().trim().nullable().optional(),
  city: Yup.string().trim().nullable().optional(),
  zipCode: Yup.string().trim().nullable().optional(),
  state: locationStateCodeValidation.nullable().default(undefined),

  locationManagementType: locationManagementTypeValidation
    .default(undefined)
    .nullable()
    .when(["type", "$prefill"], {
      is: (type: ContactType, prefill: boolean | undefined) => type === "WEB_ADMIN" && !prefill,
      then: (schema) => schema.required("Location management is required"),
    })
    .when("type", {
      is: "PRIMARY_WEB_ADMIN",
      then: (_) => {
        return Yup.mixed<"ALL">()
          .oneOf<"ALL">(["ALL"], "Primary Plan Administrator must be set to All")
          .required("Primary Plan Administrator must be set to All");
      },
    }),
  managedLocationIds: Yup.array().of(Yup.string().required()),
  locationId: Yup.string().trim().nullable().optional(),
  location: locationInputValidation
    .optional()
    .default(undefined)
    .when(["locationId", "$prefill"], {
      is: (v: string, prefill: boolean | undefined) => !v && !prefill,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- revisit in yup@v1
      then: (schema) => schema.required(),
    }),

  billingAccess: accessOptionsValidationSchema,
  claimsAccess: accessOptionsValidationSchema,
  memberChangesAccess: accessOptionsValidationSchema,
  documentsAccess: accessOptionsValidationSchema,
  paymentsAccess: accessOptionsValidationSchema,
  accessesForPolicyIds: Yup.array()
    .of(Yup.string().required())
    .default([])
    .when("type", {
      is: (type: ContactType) => type === "PRIMARY_WEB_ADMIN" || type === "WEB_ADMIN",
      then: (schema) =>
        schema
          .required("Please select at least one policy")
          .min(1, "Please select at least one policy"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
}).noUnknown();

export const contactInputValidationSchema = contactWithAddressValidationSchema.shape({
  id: Yup.string().trim().nullable().optional(),
  policyId: Yup.string().nullable().defined(),
});

export const maskPhoneNumber = (phoneNumber: string | null | undefined) => {
  const maskedPhoneNumber =
    phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4})/, "$1-$2-$3") || phoneNumber;
  return maskedPhoneNumber;
};
