import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StackY } from "client/src/components/Spacing/Spacing";
import { useCallback, useState } from "react";

import { InternalControl } from "../../../components/InternalControl/InternalControl";
import { Loading } from "../../../components/Loading/Loading";
import { CreateOnboardingFormModal } from "../../../domain/OnboardingForm/CreateOnboardingFormModal";
import { OnboardingFormTile } from "../../../domain/OnboardingForm/OnboardingFormTile";
import {
  getOnboardingFormName,
  sortOnboardingFormByStatus,
} from "../../../domain/OnboardingForm/utils/onboardingFormUtils";
import { useSlobAuth } from "../../../hooks/auth";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { Client, ClientSetupStepId } from "shared/types/Client";
import type { OnboardingFormWithMetadata } from "shared/types/OnboardingForm";

type CardDocusignPropsV2 = {
  client: Client;
  isFetchingOnboardingForms: boolean;
  isLoadingOnboardingForms: boolean;
  onboardingForms?: readonly OnboardingFormWithMetadata[];
  refetchData: (stepId: ClientSetupStepId) => Promise<void>;
  status: string;
};

export const CardDocusignV2 = ({
  client,
  isFetchingOnboardingForms,
  isLoadingOnboardingForms,
  onboardingForms,
  refetchData,
  status,
}: CardDocusignPropsV2) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const moduleName = "Client Setup - Step 4";
  const trackElementClicked = useTrackElementClicked(client, "Onboarding Forms");
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: status,
        module: moduleName,
        buttonLabel,
      });
    },
    [trackElementClicked, status],
  );

  const { authUser } = useSlobAuth();

  const addButtonLabel = onboardingForms?.length
    ? "Add Another DocuSign Template ID"
    : "Add Template ID";

  const addIdClick = () => {
    setIsModalVisible(true);
    track(addButtonLabel);
  };

  const sendFormCancelCallback = () => {
    setIsModalVisible(false);
    track("Cancel");
  };

  const sendFormAddCallback = async () => {
    await refetchData("ADD_DOCUSIGN");
    setIsModalVisible(false);
  };

  if (isLoadingOnboardingForms) return <Loading pageHeight={false} />;

  const sortedOnboardingForms = onboardingForms && sortOnboardingFormByStatus(onboardingForms);

  return (
    <>
      <p>
        When the Client’s DocuSign envelope is ready, copy the Template ID from DocuSign and add it
        here to link it to Onboard.
      </p>
      <p>
        Note: Pre-fill the form in DocuSign using the ‘edit’ option from the ‘templates’ folder
        before adding it to Onboard.
      </p>
      <StackY dist={16} className="w-full">
        {authUser && (
          <>
            {sortedOnboardingForms?.map((form, index) => (
              <OnboardingFormTile
                key={index}
                title={getOnboardingFormName(form, index)}
                currentUserEmail={authUser?.email ?? ""}
                currentUserName={authUser?.name ?? ""}
                onboardingForm={form}
                client={client}
                isInternalUser={true}
                userRole={authUser.role}
                refetchOnboardingForms={async () => await refetchData("ADD_DOCUSIGN")}
                trackElementClicked={trackElementClicked}
              />
            ))}
          </>
        )}
        <InternalControl
          buttonLabel={addButtonLabel}
          buttonIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={addIdClick}
        />
      </StackY>
      <CreateOnboardingFormModal
        clientId={client.id}
        isVisible={isModalVisible}
        addCallback={sendFormAddCallback}
        cancelCallback={sendFormCancelCallback}
        isFetchingOnboardingForms={isFetchingOnboardingForms}
        track={track}
      />
    </>
  );
};

type CardDocusignProps = {
  client: Client;
  isFetchingOnboardingForms: boolean;
  isLoadingOnboardingForms: boolean;
  onboardingForms?: readonly OnboardingFormWithMetadata[];
  refetchOnboardingForms: () => unknown;
  status: string;
};

export const CardDocusign = ({
  client,
  isFetchingOnboardingForms,
  isLoadingOnboardingForms,
  onboardingForms,
  refetchOnboardingForms,
  status,
}: CardDocusignProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const moduleName = "Client Setup - Step 4";
  const trackElementClicked = useTrackElementClicked(client, "Onboarding Forms");
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: status,
        module: moduleName,
        buttonLabel,
      });
    },
    [trackElementClicked, status],
  );

  const { authUser } = useSlobAuth();

  const addButtonLabel = onboardingForms?.length
    ? "Add Another DocuSign Template ID"
    : "Add Template ID";

  const addIdClick = () => {
    setIsModalVisible(true);
    track(addButtonLabel);
  };

  const sendFormCancelCallback = () => {
    setIsModalVisible(false);
    track("Cancel");
  };

  const sendFormAddCallback = async () => {
    await refetchOnboardingForms();
    setIsModalVisible(false);
  };

  if (isLoadingOnboardingForms) return <Loading pageHeight={false} />;

  const sortedOnboardingForms = onboardingForms && sortOnboardingFormByStatus(onboardingForms);

  return (
    <>
      <p>
        When the Client’s DocuSign envelope is ready, copy the Template ID from DocuSign and add it
        here to link it to Onboard.
      </p>
      <p>
        Note: Pre-fill the form in DocuSign using the ‘edit’ option from the ‘templates’ folder
        before adding it to Onboard.
      </p>
      <StackY dist={16} className="w-full">
        {authUser && (
          <>
            {sortedOnboardingForms?.map((form, index) => (
              <OnboardingFormTile
                key={index}
                title={getOnboardingFormName(form, index)}
                currentUserEmail={authUser?.email ?? ""}
                currentUserName={authUser?.name ?? ""}
                onboardingForm={form}
                client={client}
                isInternalUser={true}
                userRole={authUser.role}
                refetchOnboardingForms={refetchOnboardingForms}
                trackElementClicked={trackElementClicked}
              />
            ))}
          </>
        )}
        <InternalControl
          buttonLabel={addButtonLabel}
          buttonIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={addIdClick}
        />
      </StackY>
      <CreateOnboardingFormModal
        clientId={client.id}
        isVisible={isModalVisible}
        addCallback={sendFormAddCallback}
        cancelCallback={sendFormCancelCallback}
        isFetchingOnboardingForms={isFetchingOnboardingForms}
        track={track}
      />
    </>
  );
};
