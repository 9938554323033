import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { QPSErrorBanner } from "client/src/domain/EIF/QPS/QPSErrorBanner";
import { useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser, getIsOwnerUser } from "shared/rbac/rbac";
import { isPlanLevelSetupBenefitType } from "shared/types/BenefitTypes";
import { isBenefitMappingToQPSSupported, type QPSSyncErrorDetail } from "shared/types/QPSClass";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type CanPushToQPSProps = {
  client: Client;
  changesSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  employeeClasses: EmployeeClass[];
  plans: Plan[];
  featureToggles: ClientFeatureToggles;
};

type QPSSyncState = {
  isSuccess: boolean;
  qpsSyncErrorDetails: QPSSyncErrorDetail[];
};

const defaultQPSSyncState = {
  isSuccess: false,
  qpsSyncErrorDetails: [],
};

export function PushToQPSButton(props: CanPushToQPSProps) {
  const { client } = props;
  const location = useLocation();

  const { canPush, buttonLabel } = getCanPushToQPS(props);

  if (!canPush) {
    return null;
  }
  const qpsSyncState = isQPSSyncState(location.state) ? location.state : defaultQPSSyncState;

  return (
    <DashedBanner variant="warning">
      <StackY dist={16}>
        <Row align="middle" justify="space-between">
          <Col>
            <Body2 darkYellow>Internal user:</Body2>
          </Col>
          <Col>
            <Button to={RouteData.eifPushToQPS.getPath(client.id)} type="secondary">
              {buttonLabel}
            </Button>
          </Col>
        </Row>
        {qpsSyncState.isSuccess && (
          <AlertBanner
            variant="success"
            message={<Body3>Digital SmartForm successfully synced to QPS</Body3>}
          />
        )}
        {qpsSyncState.qpsSyncErrorDetails.length > 0 && (
          <StackY dist={16}>
            {qpsSyncState.qpsSyncErrorDetails.map((qpsSyncError, errorIndex) => (
              <QPSErrorBanner qpsSyncError={qpsSyncError} key={errorIndex} />
            ))}
          </StackY>
        )}
      </StackY>
    </DashedBanner>
  );
}

export function getCanPushToQPS(args: CanPushToQPSProps): {
  canPush: boolean;
  buttonLabel: string;
} {
  const { client, authUser, plans, changesSnapshot, employeeClasses, featureToggles } = args;

  const eifEditState = getEIFEditState({ client, changesSnapshot });
  const hasQPSEnabledEmployeeClassPlans = employeeClasses.some((employeeClass) =>
    employeeClass.employeeClassPlans.some(({ plan }) =>
      isBenefitMappingToQPSSupported(plan.benefitType, featureToggles),
    ),
  );

  // plan without classes benefit type
  const hasQPSEnabledPlanLevelSetup = plans.some(
    (plan) =>
      isPlanLevelSetupBenefitType(plan.benefitType) &&
      isBenefitMappingToQPSSupported(plan.benefitType, featureToggles),
  );

  const hasQPSEnabledPlans = hasQPSEnabledEmployeeClassPlans || hasQPSEnabledPlanLevelSetup;
  const isInternalUser = getIsInternalUser(authUser);

  const canSee = isInternalUser;
  const canPush = Boolean(
    canSee &&
      client.eifSignedAt != null &&
      (eifEditState === "no-edits" || eifEditState === "changes-accepted") &&
      hasQPSEnabledPlans,
  );

  const isSlfOwner = getIsOwnerUser(authUser);
  if (!canPush && client.isTest && isSlfOwner) {
    return { canPush: true, buttonLabel: "Early Push to QPS" };
  }

  return { canPush, buttonLabel: "Push digital smart form to QPS" };
}

const isQPSSyncState = (state: unknown): state is QPSSyncState => {
  return (
    state != null &&
    typeof state === "object" &&
    "isSuccess" in state &&
    "qpsSyncErrorDetails" in state
  );
};
