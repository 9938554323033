import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement>;

export function DownloadIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={20} {...props}>
      <path
        d="M13.122 20C14.157 20 15 19.16 15 18.125V5.155c0-.496-.202-.976-.554-1.328L11.17.551A1.877 1.877 0 009.846 0H1.875A1.882 1.882 0 000 1.879v16.246C0 19.16.84 20 1.875 20h11.247zm.003-1.875H1.875V1.88h6.248v4.06c0 .52.418.937.938.937h4.064v11.248zM12.97 5.003H9.998V2.03l2.972 2.972zM7.852 16.108l2.825-2.803a.47.47 0 00-.33-.802h-1.91V9.221a.468.468 0 00-.468-.468h-.938a.469.469 0 00-.469.468v3.282H4.653a.47.47 0 00-.33.802l2.825 2.803a.499.499 0 00.704 0z"
        fill="currentColor"
      />
    </svg>
  );
}
