import { useNavigate, useLocation } from "react-router-dom";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Checkbox } from "../../components/Form/Checkbox";
import { Body3 } from "../../components/Typography/Typography";
import { useGetEmailsCount } from "../../hooks/email";
import { triggerError } from "../../hooks/generalError";
import { useEmailTableFilters } from "../../hooks/useEmailTableFilters";
import { useSlobId } from "../../hooks/useSlobId";
import { getURLSearchParamsFromObject } from "../../utils/url";
import type { Location, To } from "react-router-dom";
import type { EmailTableFilters } from "shared/types/Email";

interface EmailTableLocation extends Location {
  state: EmailTableFilters | undefined;
}

type ClientEmailsWithErrorsAlertProps = {
  clientId?: string;
};

export const ClientEmailsWithErrorsAlert = (props: ClientEmailsWithErrorsAlertProps) => {
  const { clientId } = props;

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const location = useLocation() as EmailTableLocation;

  const filters = useEmailTableFilters();

  const {
    data,
    error: emailFetchError,
    isFetching,
  } = useGetEmailsCount({
    clientId: clientId,
    errorsOnly: true,
  });

  if (emailFetchError) {
    triggerError(emailFetchError);
  }

  const labelId = useSlobId({ prefix: "onlyWithEmailErrorsLabelId" });

  return data?.count && data.count > 0 ? (
    <AlertBanner
      loading={isFetching}
      variant="error"
      message={
        <>
          <span className="mr-8">
            <Body3 id={labelId}>
              This client has <b>{data.count}</b> rows with email errors
            </Body3>
          </span>

          <Checkbox
            name="errorsOnlyEmailFilter"
            label={null}
            aria-labelledby={labelId}
            disabled={isFetching}
            checked={!!filters.errorsOnly}
            onChange={() => {
              const nextFilters: EmailTableFilters = {
                ...filters,
                errorsOnly: !filters.errorsOnly,
              };
              const nextSearchParams = getURLSearchParamsFromObject(nextFilters);
              const to: To = {
                pathname: location.pathname,
                search: nextSearchParams.toString(),
              };
              navigate(to, { replace: true });
            }}
          />
        </>
      }
    />
  ) : null;
};
