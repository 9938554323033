import { Portal } from "@reach/portal";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { BillDetailsInputs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillDetailsInputs";
import { CopySettingsFromAnotherBillModal } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/CopySettingsFromAnotherBillModal";
import { getIsBillEmpty } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { billDetailsSpec } from "shared/validation/bill";
import * as Yup from "yup";
import type { BillSplitType } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillId, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";

const validationSchema = Yup.object({
  policyId: billDetailsSpec.policyId,

  advanceOrArrears: billDetailsSpec.advanceOrArrears,

  billingAdministrationType: billDetailsSpec.billingAdministrationType,
  billSplitType: billDetailsSpec.billSplitType,

  billName: billDetailsSpec.billName,

  groupByLocationIds: billDetailsSpec.groupByLocationIds,
  splitTags: billDetailsSpec.splitTags,
  slfCoverages: billDetailsSpec.slfCoverages,
  contactId: billDetailsSpec.contactId,
  hasDifferentMailingAddress: billDetailsSpec.hasDifferentMailingAddress,
  locationId: billDetailsSpec.locationId,
  numberOfEmployees: billDetailsSpec.numberOfEmployees,

  categorizeEmployees: billDetailsSpec.categorizeEmployees,
  employeeCategorizationType: billDetailsSpec.employeeCategorizationType,
  categorizeByLocationIds: billDetailsSpec.categorizeByLocationIds,
  categoriesByTags: billDetailsSpec.categoriesByTags,

  categorizeEmployees_secondary: billDetailsSpec.categorizeEmployees_secondary,
  employeeCategorizationType_secondary: billDetailsSpec.employeeCategorizationType_secondary,
  categorizeByLocationIds_secondary: billDetailsSpec.categorizeByLocationIds_secondary,
  categoriesByTags_secondary: billDetailsSpec.categoriesByTags_secondary,
});

type Props = {
  client: Client;
  policy: Policy;
  locations: Location[];
  admins: Contact[];
  billSplitType: BillSplitType;
  advanceOrArrears: AdvanceOrArrearsSelection;
  billIds: BillId[];
  billPreviews: BillPreview[];
  index1: number;
  index2: number | undefined;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  disabled: boolean;
  initialValues?: ValuesForValidationSchema<typeof validationSchema>;
  isSubStepStarted: boolean;
  onCancel: () => void;
  onSubmit: (values: Yup.InferType<typeof validationSchema>) => void;
  featureToggles: ClientFeatureToggles;
  bills: Bill[];
  copyModalIsOpen: boolean;
  toggleCopyModalIsOpen: () => void;
};

export function BillCreationAndEditingForm(props: Props) {
  const {
    client,
    policy,
    locations,
    admins,
    billSplitType,
    advanceOrArrears,
    billIds,
    billPreviews,
    index1,
    index2,
    changeSnapshot,
    authUser,
    disabled,
    initialValues,
    isSubStepStarted,
    onCancel,
    onSubmit,
    featureToggles,
    bills,
    copyModalIsOpen,
    toggleCopyModalIsOpen,
  } = props;

  const formik = useSlobFormik({
    validationSchema,
    validationContext: {
      client,
      policy,
      featureToggles,
      prefill: true,
    },
    initialValues: initialValues || {
      policyId: policy.id,
      advanceOrArrears,
      billingAdministrationType: "LIST",
      billSplitType,
      billName: null,
      groupByLocationIds: null,
      splitTags: null,
      slfCoverages: null,
      contactId: null,
      hasDifferentMailingAddress: false,
      locationId: null,
      numberOfEmployees: null,
      categorizeEmployees: null,
      employeeCategorizationType: null,
      categorizeByLocationIds: null,
      categoriesByTags: null,
      categorizeEmployees_secondary: null,
      employeeCategorizationType_secondary: null,
      categorizeByLocationIds_secondary: null,
      categoriesByTags_secondary: null,
    },
    onSubmit,
  });

  const form = "bill-creation-and-editing-form-id";

  const prefillErrors =
    isSubStepStarted && !(formik.isSubmitting || disabled)
      ? getFormikErrors(formik.values, validationSchema, {
          client,
          policy,
          featureToggles,
          prefill: false,
        })
      : {};

  const isBillEmpty = getIsBillEmpty({
    ...formik.values,
    billingStructureType: "MULTIPLE",
  });

  return (
    <StackY dist={32} wrap={false}>
      <BillDetailsInputs
        client={client}
        policy={policy}
        featureToggles={featureToggles}
        locations={locations}
        admins={admins}
        formik={formik}
        prefillErrors={prefillErrors}
        form={form}
        disabled={formik.isSubmitting || disabled}
        billSplitType={billSplitType}
        billingAdministrationType="LIST"
        advanceOrArrears={advanceOrArrears}
        billPreviews={billPreviews}
        index1={index1}
        index2={index2}
        billIds={billIds}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
      />

      <Row justify="end" align="middle" gutter={20}>
        <Col>
          <Button
            type="text"
            size="small"
            htmlType="button"
            onClick={onCancel}
            disabled={formik.isSubmitting || disabled}
            form={form}
          >
            Cancel
          </Button>
        </Col>

        <Col>
          <Button
            type="secondary"
            size="middle"
            htmlType="button"
            disabled={formik.isSubmitting || disabled || isBillEmpty}
            onClick={formik.submitForm}
            form={form}
          >
            Save
          </Button>
        </Col>
      </Row>

      {/* 
        We need the inputs above to be attached to a form in order for
        keyboard submission to work, which is requried for accessibility compliance.
        We can't wrap them in a form element though because there's another form element
        further up in the tree in the Billing Preferences component and the HTML standard
        doesn't allow forms to be children of forms.
        So what I do is portal out the current form's form element and tie them via an ID. 
      */}
      <Portal type="bill-creation-and-editing-form-portal">
        <form id={form} onSubmit={formik.handleSubmit} />
      </Portal>

      <CopySettingsFromAnotherBillModal
        open={copyModalIsOpen}
        onCancel={toggleCopyModalIsOpen}
        client={client}
        policy={policy}
        bills={bills}
        admins={admins}
        billGroupInputs={formik.values}
        billingPreferencesValues={formik.values}
        onCopy={async (copiedValues) => {
          await formik.setValues({ ...formik.values, ...copiedValues });
          toggleCopyModalIsOpen();
        }}
      />
    </StackY>
  );
}
