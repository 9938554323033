import { Divider, Image } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ScrollTopButton } from "client/src/components/Button/ScrollTopButton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Loading } from "client/src/components/Loading/Loading";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { Body3 } from "client/src/components/Typography/Typography";

import { ExplorerBenefit } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerBenefit";
import { BenefitInterstitialDivider } from "client/src/domain/ExplorerPages/ClientPage/BenefitInterstitialDivider";
import { ExplorerCountdown } from "client/src/domain/ExplorerPages/ClientPage/ExplorerCountdown";
import { ExplorerDisclaimers } from "client/src/domain/ExplorerPages/ClientPage/ExplorerDisclaimers";
import { ExplorerEndOfScroll } from "client/src/domain/ExplorerPages/ClientPage/ExplorerEndOfScroll";
import { ExplorerHeadline } from "client/src/domain/ExplorerPages/ClientPage/ExplorerHeadline";
import { ExplorerHighlighters } from "client/src/domain/ExplorerPages/ClientPage/ExplorerHighlighters";
import { ExplorerMenu } from "client/src/domain/ExplorerPages/ClientPage/ExplorerMenu";
import { ExplorerNeedGuidance } from "client/src/domain/ExplorerPages/ClientPage/ExplorerNeedGuidance";
import { ExplorerLocationModal } from "client/src/domain/ExplorerPages/Modals/ClientPage/ExplorerLocationModal";
import { ExplorerNewSiteModal } from "client/src/domain/ExplorerPages/Modals/ClientPage/ExplorerNewSiteModal";
import { useIsStuck } from "client/src/domain/ExplorerPages/utils";
import { useBenefitsData } from "client/src/hooks/ExplorerPage/useBenefitsData";
import { useCustomTheme } from "client/src/hooks/ExplorerPage/useCustomTheme";
import { useGetPublicExplorerPageById } from "client/src/hooks/ExplorerPage/useExplorerPages";
import { useGetPublicDocumentDownloadUrl } from "client/src/hooks/document";
import { triggerError } from "client/src/hooks/generalError";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useExplorerPageLocation } from "client/src/hooks/useExplorerPageLocation";
import { useEmployeeLocation, useFavoritePlans } from "client/src/hooks/useSlobLocalStorage";
import { useTitle } from "client/src/hooks/useTitle";
import { useTranslation } from "client/src/i18n";
import {
  useExplorerTrackElementChanged,
  useExplorerTrackElementClicked,
} from "client/src/utils/analytics";
import { getCSSVariableValue } from "client/src/utils/getCSSVariableValue";
import { timezoneAdjustedMoment } from "client/src/utils/moment";
import { useVidyardPlayer } from "client/src/utils/vidyard";
import { setZendeskWebWidgetVisibility } from "client/src/utils/zendesk";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { LocationStateData } from "shared/data/LocationState";
import { isBenefitTypeBenEx } from "shared/types/BenefitTypes";
import { isSunlifeCarrier } from "shared/types/ExplorerPageBenefit";

import * as styles from "./explorerPage.module.less";

import type { Dispatch, RefObject, SetStateAction } from "react";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "shared/types/Location";

type PlansBenefitMapping = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
  ref: React.RefObject<HTMLDivElement>;
  carrierId: number | null;
};

const usePageLoaded = (dataLoaded: boolean, logoLoaded: boolean): boolean => {
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    if (dataLoaded && !logoLoaded && !pageLoaded) {
      setTimeout(() => {
        if (!pageLoaded) setPageLoaded(true);
      }, 3000);
    } else if (dataLoaded && logoLoaded) {
      if (!pageLoaded) setPageLoaded(true);
    } else if (!dataLoaded) setPageLoaded(false);
  }, [dataLoaded, pageLoaded, logoLoaded]);

  return pageLoaded;
};

export const ExplorerPage = () => {
  useTitle("Benefits Explorer", true);
  const { state } = useExplorerPageLocation();

  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.md;
  const { t } = useTranslation({ keyPrefix: "ExplorerPage" });

  const { explorerPageId } = useClientHubParams(["explorerPageId"]);
  const [confirmDeselection, setConfirmDeselection] = useState(true);

  const demoMode = state?.demoEnrollmentStatus || "pre";
  const {
    isLoading: isLoadingData,
    error,
    data: explorerPage,
  } = useGetPublicExplorerPageById(explorerPageId, demoMode);

  // TODO: Also check for FT and client field.
  const customTheme = explorerPage?.customBranding ? explorerPage?.customTheme : undefined;
  useCustomTheme(customTheme);

  const [employeePrepaidAndChatLocation, writeEmployeePrepaidAndChatLocation] =
    useEmployeeLocation();
  const dhmoBenefitData = explorerPage?.benefits?.filter((ben) => ben.planType === "DHMO");
  const isSunLifeDHMOBenefit = isSunlifeCarrier(dhmoBenefitData?.[0]?.carrier?.id ?? null);
  const hasDentalDHMO =
    explorerPage?.benefits?.some((ben) => ben.planType === "DHMO") && isSunLifeDHMOBenefit;
  const hasEmployeesInNYBySitusState =
    explorerPage?.hasEmployeesInNY && explorerPage.employerState !== "NY";
  const isChatdisabled = hasEmployeesInNYBySitusState && employeePrepaidAndChatLocation === "NY";
  const [showLocationModal, setShowLocationModal] = useActiveLocation(
    hasDentalDHMO,
    employeePrepaidAndChatLocation,
    hasEmployeesInNYBySitusState,
  );

  // show or hide the chat for NY employees if situs state is not NY
  useChatForNyEmployees(isChatdisabled);

  const [favoritePlans, writeFavoritePlans] = useFavoritePlans(explorerPageId);

  const benefitsData = useBenefitsData(explorerPage, employeePrepaidAndChatLocation);
  const menuRow = useMenuRowRef();
  const menuRowContainerRef = useMenuRowRef();
  const menuIsSticky = useIsStuck(menuRowContainerRef);
  const [activeBenefitType, setActiveBenefitType, pauseActiveListening, viewedBeneftis] =
    useActiveBenefit(benefitsData, menuRow);

  const { data: logoURL, isInitialLoading: isInitialLoadingLogo } = useGetPublicDocumentDownloadUrl(
    explorerPage?.logoDocument?.id,
  );

  const isLoadingLogo = isInitialLoadingLogo;

  const trackElementClicked = useExplorerTrackElementClicked(explorerPage);
  const trackElementChanged = useExplorerTrackElementChanged(explorerPage);

  const scrollToBenefitType = useCallback(
    (benefitType: BenefitTypeBenEx, smooth = true) => {
      if (!benefitsData) return;
      const currentPostition = benefitsData
        .find((data) => data.benefitType === benefitType)
        ?.ref.current?.getBoundingClientRect().top;

      if (!currentPostition) return;

      pauseActiveListening(smooth ? 1000 : 500);
      setActiveBenefitType(benefitType);
      window.scrollBy({
        top:
          currentPostition -
          (menuRow.current?.clientHeight ?? 0) -
          (menuRowContainerRef.current
            ? parseFloat(getComputedStyle(menuRowContainerRef.current).top)
            : 100),
        behavior: smooth ? "smooth" : "auto",
      });
    },
    [benefitsData, menuRow, menuRowContainerRef, pauseActiveListening, setActiveBenefitType],
  );

  const isNewPageCreated = explorerPage?.newExplorerPageId ? true : false;
  const [showNewSiteModal, setShowNewSiteModal] = useNewSiteModal(
    isNewPageCreated,
    explorerPage?.newExplorerPageStartDate,
  );

  const pageLoaded = usePageLoaded(!isLoadingData, !isLoadingLogo);
  useInitialScroll(pageLoaded && !!benefitsData, (benefitType) =>
    scrollToBenefitType(benefitType, false),
  );

  useVidyardPlayer(pageLoaded && !error);

  if (!pageLoaded) {
    return <Loading />;
  }

  //we want to gracefully handle 404 errors
  if (error && error.status !== 404) {
    return triggerError(error ?? "Failed to get explorer page data");
  }

  const isPageExpiresInFuture = explorerPage?.expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
      ? true
      : false
    : true;

  const highlightersCount = Object.values(explorerPage?.benefitTypeMetadata ?? {}).filter(
    ({ highlighterText }) => highlighterText,
  ).length;

  const pageExpired =
    (!explorerPage?.newExplorerPageId &&
      moment().isAfter(timezoneAdjustedMoment(moment(explorerPage?.expiresAt)))) ??
    false;

  const pageIsActive =
    explorerPage &&
    benefitsData &&
    !explorerPage.maintenanceMode &&
    moment().isAfter(timezoneAdjustedMoment(moment(explorerPage?.startsAt))) &&
    !pageExpired;

  const customCalendlyUrl =
    moment().isBefore(
      timezoneAdjustedMoment(moment(explorerPage?.openEnrollmentEnd).add({ day: 1 })),
    ) && explorerPage?.customCalendlyLink
      ? explorerPage?.customCalendlyLink
      : "https://calendly.com/slfappointment";

  const benefitsCount = benefitsData?.length ?? 0;
  const carrierNameForDHMO = dhmoBenefitData?.[0]?.carrier?.carrierName;

  const showSMSNotificationBanner =
    Boolean(explorerPage?.notificationLink) &&
    moment().isBefore(timezoneAdjustedMoment(moment(explorerPage?.openEnrollmentStart)));

  const favoriteDHMOPlan =
    favoritePlans.reduce<string[]>((acc, val) => {
      const favDHMO = dhmoBenefitData?.find((d) => d.id === val);
      if (favDHMO) {
        acc.push(val);
        return acc;
      }
      return acc;
    }, []) ?? [];

  //plans of type OTHER should be counted as a benefit
  const benefitCount = benefitsData?.reduce((count, benVal) => {
    if (explorerPage?.showBenefitCount && benVal.benefitType === "OTHER")
      return count + benVal.plans.length;
    return count + 1;
  }, 0);

  const scrollTopButtonText = t("scrollToButtonText");

  const showEnrollButton =
    isMobile &&
    explorerPage?.benAdminPlatformUrl &&
    moment().isBetween(
      timezoneAdjustedMoment(moment(explorerPage?.openEnrollmentStart)),
      timezoneAdjustedMoment(moment(explorerPage?.openEnrollmentEnd).add({ day: 1 })),
    );

  return (
    <div className={clsx(breakpoint)}>
      {pageIsActive && <ScrollTopButton buttonText={scrollTopButtonText} />}
      <div
        style={{
          overflow: "hidden",
          maxWidth: "100vw",
          height: !pageIsActive ? "100vh" : undefined,
        }}
      >
        <PageContent style={{ position: "relative", height: !pageIsActive ? "100%" : undefined }}>
          {!isMobile && (
            <Image
              wrapperStyle={{
                position: "absolute",
                left: 0,
                top: 0,
              }}
              style={{ width: "auto", transform: "translateX(-40%)" }}
              height="100%"
              width="100%"
              preview={false}
              src="/circle-graphic/circle-graphic.png"
              srcSet="/circle-graphic/circle-graphic@2x.png 2x, /circle-graphic/circle-graphic@2x.png 3x"
              alt="Circle Graphic"
            />
          )}
          <ExplorerLocationModal
            visible={showLocationModal}
            initialLocation={employeePrepaidAndChatLocation}
            dhmoPlanFavorited={favoriteDHMOPlan.length > 0 ? true : false}
            onSave={(location) => {
              trackElementClicked({
                module: "Explorer Location",
                buttonLabel: "Save",
                moduleState: employeePrepaidAndChatLocation ? "updating" : "initializing",
              });
              writeEmployeePrepaidAndChatLocation(location.prepaidLocation);
              writeFavoritePlans(
                favoritePlans.filter((favPlan) => !favoriteDHMOPlan.includes(favPlan)),
              );
              setShowLocationModal(false);
            }}
            onCancel={() => setShowLocationModal(false)}
          />

          <ExplorerNewSiteModal
            visible={showNewSiteModal}
            onCancel={() => setShowNewSiteModal(false)}
            explorerPage={explorerPage}
          />

          {!pageIsActive && <div style={{ height: "15%" }}></div>}
          {(!pageIsActive || benefitsCount > 0) && (
            <ExplorerHeadline
              explorerPage={explorerPage}
              logoURL={logoURL?.objectUrl}
              benefitCount={benefitCount ?? 0}
            />
          )}
          {pageIsActive &&
            employeePrepaidAndChatLocation &&
            (hasDentalDHMO || hasEmployeesInNYBySitusState) && (
              <Row justify="center">
                <Col span={24} md={17} style={{ textAlign: "center" }}>
                  <Body3>
                    {benefitsCount > 0
                      ? t("locationHeading", {
                          location: LocationStateData[employeePrepaidAndChatLocation].displayName,
                        })
                      : t("locationHeadingNoBenefits", {
                          location: LocationStateData[employeePrepaidAndChatLocation].displayName,
                          carrierName: carrierNameForDHMO,
                        })}
                  </Body3>
                  <InternalLinkButton
                    type="link-inline"
                    size="middle"
                    to="#"
                    onClick={() => {
                      trackElementClicked({
                        module: "Explorer Page",
                        buttonLabel: "Change your Location",
                      });
                      setShowLocationModal(true);
                    }}
                  >
                    {t("changeLocationText")}
                  </InternalLinkButton>
                </Col>
              </Row>
            )}
          {pageIsActive && (
            <Row justify="center" align="stretch" className="mt-48" gutter={[30, 30]}>
              {!!highlightersCount && benefitsCount > 0 && isPageExpiresInFuture && (
                <Col span={24} md={12}>
                  <ExplorerHighlighters
                    explorerPage={explorerPage}
                    onClick={(benefitType) => scrollToBenefitType(benefitType)}
                  />
                </Col>
              )}
              {benefitsCount === 0 && !isChatdisabled && (
                <Col span={24} md={12}>
                  <ExplorerNeedGuidance />
                </Col>
              )}
              <Col span={24} md={12}>
                <ExplorerCountdown explorerPage={explorerPage} benefitsCount={benefitsCount} />
              </Col>
            </Row>
          )}
        </PageContent>
      </div>
      {pageIsActive && benefitsCount === 0 && customCalendlyUrl && (
        <div style={{ backgroundColor: getCSSVariableValue("--color-tertiary-900") }}>
          <PageContent>
            <BenefitInterstitialDivider
              type="one-on-one"
              doNotshowImage={benefitsCount === 0 ? true : false}
              customCalendlyLink={customCalendlyUrl}
              track={() =>
                trackElementClicked({
                  module: "Explorer Interstitial",
                  buttonLabel: "Schedule Call",
                })
              }
            />
          </PageContent>
        </div>
      )}
      {pageIsActive && benefitsCount > 0 && (
        <>
          <div className={styles.benefitsSection}>
            {benefitsCount > 1 && (
              <Row
                ref={menuRowContainerRef}
                justify="center"
                align="stretch"
                className={clsx(
                  styles.menuRow,
                  menuIsSticky ? styles.sticky : null,
                  !explorerPage.isLive && styles.demo,
                  !explorerPage.languageFeatureToggle && styles.noLanguageToggle,
                  !showEnrollButton && styles.noSignInButton,
                )}
              >
                <Col className={clsx(styles.menuCol, isMobile && styles.mobile)}>
                  <ExplorerMenu
                    ref={menuRow}
                    explorerPage={explorerPage}
                    plans={benefitsData.reduce<ExplorerPageBenefit[]>((acc, val) => {
                      return acc.concat(val.plans);
                    }, [])}
                    onSelectBenefit={(benefitType) => {
                      trackElementClicked({ buttonLabel: benefitType, module: "Explorer Menu" });
                      scrollToBenefitType(benefitType);
                    }}
                    activeBenefitType={activeBenefitType}
                    sticky={menuIsSticky}
                    viewedBenefits={viewedBeneftis}
                  />
                </Col>
              </Row>
            )}
            <PageContent style={{ paddingTop: 0 }}>
              {benefitsData.reduce<React.ReactElement[]>((acc, mapping, i) => {
                if (i === 1 && customCalendlyUrl)
                  acc.push(
                    <BenefitInterstitialDivider
                      key={`interstitial-${i}`}
                      type="one-on-one"
                      customCalendlyLink={customCalendlyUrl}
                      track={() =>
                        trackElementClicked({
                          module: "Explorer Interstitial",
                          buttonLabel: "Schedule Call",
                        })
                      }
                    />,
                  );
                else if (i === 2 && !isChatdisabled)
                  acc.push(
                    <BenefitInterstitialDivider
                      key={`interstitial-${i}`}
                      type="chat"
                      track={() =>
                        trackElementClicked({
                          module: "Explorer Interstitial",
                          buttonLabel: "Chat Now",
                        })
                      }
                      hideLogo={explorerPage.hideSunLifeLogo}
                    />,
                  );
                else if (i === 3 && showSMSNotificationBanner && explorerPage.notificationLink)
                  acc.push(
                    <BenefitInterstitialDivider
                      key={`interstitial-${i}`}
                      type="SMS"
                      notificationLink={explorerPage.notificationLink}
                      track={() => {
                        const daysUntilOE =
                          moment(explorerPage.openEnrollmentStart).diff(moment(), "days") + 1;

                        trackElementClicked({
                          module: "Explorer Interstitial",
                          buttonLabel: "Text Notification",
                          daysUntilOE,
                        });
                      }}
                    />,
                  );
                else if (i !== 0)
                  acc.push(<Divider key={`benefit-divider-${i}`} style={{ margin: 0 }} />);

                acc.push(
                  <div
                    key={mapping.benefitType}
                    className={
                      i === 0 ? "pb-40" : i === benefitsData.length - 1 ? "pt-40" : "py-40"
                    }
                  >
                    <ExplorerBenefit
                      ref={mapping.ref}
                      benefitType={mapping.benefitType}
                      otherBenefitTypes={benefitsData
                        .map(({ benefitType }) => benefitType)
                        .filter((benefitType) => benefitType !== mapping.benefitType)}
                      plans={mapping.plans}
                      metadata={explorerPage.benefitTypeMetadata ?? undefined}
                      carrierId={mapping.carrierId}
                      kioskMode={explorerPage.kioskMode}
                      employerState={explorerPage.employerState}
                      clientName={
                        explorerPage.customClientName
                          ? explorerPage.customClientName
                          : explorerPage.clientName
                      }
                      confirmDeselection={confirmDeselection}
                      onPlanDeselected={(_) => setConfirmDeselection(false)}
                      trackElementClicked={trackElementClicked}
                      trackElementChanged={trackElementChanged}
                      trackElementViewedOptions={{
                        explorerPage,
                        clientId: explorerPage.clientId,
                        clientName: explorerPage.clientName ?? undefined,
                        module: "Explorer Benefit",
                        moduleState: mapping.benefitType,
                        benefitIndex: i,
                      }}
                      expiresAt={explorerPage?.expiresAt}
                      createdAt={explorerPage.createdAt}
                      showLegacyVisionPlanTable={explorerPage.showLegacyVisionPlanTable}
                      showLegacyDentalPlanTable={explorerPage.showLegacyDentalPlanTable}
                      largeClientFeatureToggle={explorerPage?.largeClientFeatureToggle}
                      medicalPlanComparisonFeatureToggle={
                        explorerPage?.medicalPlanComparisonFeatureToggle
                      }
                      visionPlanComparisonFeatureToggle={
                        explorerPage?.visionPlanComparisonFeatureToggle
                      }
                      dentalPlanComparisonFeatureToggle={
                        explorerPage?.dentalPlanComparisonFeatureToggle
                      }
                      fsaRolloverFeatureToggle={explorerPage?.fsaRolloverFeatureToggle}
                      vasUpdateFeatureToggle={explorerPage?.vasUpdateFeatureToggle}
                      dentalPlansCombinedFeatureToggle={
                        explorerPage?.dentalPlansCombinedFeatureToggle
                      }
                      customBrandingFeatureToggle={explorerPage.customBrandingFeatureToggle}
                      customBranding={
                        explorerPage.benefitsExplorerCustomBranding && explorerPage.customBranding
                      }
                      onChangeActiveBenefitType={(benefitType) => scrollToBenefitType(benefitType)}
                      hiDisclosure={explorerPage?.hiDisclosure}
                    />
                  </div>,
                );
                return acc;
              }, [])}
            </PageContent>
          </div>
          <ExplorerEndOfScroll
            explorerPageId={explorerPageId}
            explorerPage={explorerPage}
            customCalendlyUrl={customCalendlyUrl ? customCalendlyUrl : undefined}
          />
          <div style={{ backgroundColor: getCSSVariableValue("--color-gray-100") }}>
            <PageContent>
              <ExplorerDisclaimers
                page={explorerPage}
                vasUpdateFeatureToggle={explorerPage.vasUpdateFeatureToggle}
              />
            </PageContent>
          </div>
        </>
      )}
    </div>
  );
};

const useMenuRowRef = () => useRef<HTMLDivElement>(null);

const useActiveBenefit = (
  benefitsData: PlansBenefitMapping[] | undefined,
  menuRow: RefObject<HTMLDivElement>,
): [
  BenefitTypeBenEx | null,
  Dispatch<SetStateAction<BenefitTypeBenEx | null>>,
  (pauseTime: number) => void,
  BenefitTypeBenEx[],
] => {
  const [activeBenfitType, setActiveBenefitType] = useState<BenefitTypeBenEx | null>(
    benefitsData?.[0]?.benefitType ?? null,
  );
  const [viewedBenefits, setViewedBenefits] = useState<BenefitTypeBenEx[]>([]);

  const [paused, setPaused] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<NodeJS.Timeout | null>(null);

  const pauseListening = (time: number) => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
    setPaused(true);
    setCurrentTimeout(
      setTimeout(() => {
        setPaused(false);
        setCurrentTimeout(null);
      }, time),
    );
  };

  //inital active benefit
  useEffect(() => {
    if (benefitsData?.[0]) setActiveBenefitType(benefitsData[0].benefitType);
  }, [benefitsData]);

  useEffect(() => {
    const callback = () => {
      if (!benefitsData) return;
      if (paused) return;
      for (let i = 0; i < benefitsData.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- for loop
        const benData = benefitsData[i]!;

        const position = benData.ref.current?.getBoundingClientRect();
        if (position === undefined) continue;

        if (
          position.top <= window.innerHeight * 0.8 &&
          position.bottom > window.innerHeight * 0.8
        ) {
          //add benefit to viewed benefits when we are transitioning away from it
          if (
            activeBenfitType &&
            activeBenfitType !== benData.benefitType &&
            !viewedBenefits.includes(activeBenfitType)
          ) {
            setViewedBenefits((v) => v.concat([activeBenfitType]));
          }

          setActiveBenefitType(benData.benefitType);
          break;
        }

        //handle last benefit
        if (
          i === benefitsData.length - 1 &&
          activeBenfitType &&
          position.bottom < window.innerHeight * 0.8
        ) {
          setViewedBenefits((v) => v.concat([activeBenfitType]));
        }
      }
    };
    window.addEventListener("scroll", callback, { passive: true });
    return () => window.removeEventListener("scroll", callback);
  }, [activeBenfitType, benefitsData, menuRow, paused, viewedBenefits]);

  return [activeBenfitType, setActiveBenefitType, pauseListening, viewedBenefits];
};

const useActiveLocation = (
  hasDentalDHMO: boolean | undefined,
  employeePrepaidAndChatLocation: LocationStateCode | null,
  hasEmployeesInNYBySitusState: boolean | undefined,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [showLocationModal, setShowLocationModal] = useState(false);
  useEffect(() => {
    if ((hasDentalDHMO || hasEmployeesInNYBySitusState) && !employeePrepaidAndChatLocation) {
      setShowLocationModal(true);
    }
  }, [hasDentalDHMO, employeePrepaidAndChatLocation, hasEmployeesInNYBySitusState]);

  return [showLocationModal, setShowLocationModal];
};

const useNewSiteModal = (
  isNewPageCreated: boolean | undefined,
  newPageStartDate: Date | null | undefined,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [showNewSiteModal, setShowNewSiteModal] = useState(false);
  useEffect(() => {
    if (
      isNewPageCreated &&
      newPageStartDate &&
      moment().isSameOrAfter(timezoneAdjustedMoment(moment(newPageStartDate)))
    ) {
      setShowNewSiteModal(true);
    }
  }, [isNewPageCreated, newPageStartDate]);

  return [showNewSiteModal, setShowNewSiteModal];
};

const useInitialScroll = (enabled: boolean, scroll: (benefitType: BenefitTypeBenEx) => void) => {
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.substring(1);
    if (enabled && hash && isBenefitTypeBenEx(hash)) setTimeout(() => scroll(hash), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want this controlled by enabled
  }, [enabled]);
};

export function useChatForNyEmployees(isChatdisabled: boolean | undefined) {
  useLayoutEffect(() => {
    setZendeskWebWidgetVisibility(!isChatdisabled);
  }, [isChatdisabled]);
}
