import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { eifSubStepsForStep, type EIFStepId } from "shared/types/EIF";
import { assertIsDefined } from "shared/utils/utils";
import type { Client } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export function EIFPlanConfigAndEligibilityIntro(props: {
  client: Client;
  clientPlans: Plan[];
  eifStepId: EIFStepId;
  featureToggles: ClientFeatureToggles;
}) {
  const { client, clientPlans, eifStepId, featureToggles } = props;

  const steps = eifSubStepsForStep(
    "plan-configuration-&-eligibility",
    client,
    clientPlans,
    featureToggles,
  );

  if (steps.length === 0) {
    return (
      <Navigate
        to={RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information")}
        replace
      />
    );
  }

  const needsClassbuilder = steps.includes("class-builder");

  const [firstEIFSubStepId] = steps;
  assertIsDefined(firstEIFSubStepId, "firstEIFSubStepId");

  return (
    <>
      {needsClassbuilder ? (
        <>
          <h1>Tell us about benefit eligibility and employee groups</h1>

          <p>
            In this section you’ll tell us which employees are eligible for which benefits, what
            their waiting periods will be, and how much premium you will contribute towards the cost
            of their benefits.
          </p>
        </>
      ) : (
        <>
          <h1>Tell us about your benefit contributions</h1>

          <p>
            In this section you’ll tell us how much premium you will contribute towards the cost of
            your employees’ benefits.
          </p>
        </>
      )}

      <Row justify="end" className="mt-32 mb-48">
        <Col>
          <Button
            to={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, firstEIFSubStepId)}
            type="primary"
            size="large"
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
}
