import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { EIFStepBottomNavLinks } from "client/src/domain/EIF/EIFStepBottomNavLinks";
import { ClaimsLocationReviewCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/ClaimsLocationReviewCard";
import { MonthlyClaimsReportsAndEOBsReviewCard } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/MonthlyClaimsReportsAndEOBsReviewCard";
import { BillingPreferencesPolicySummary } from "client/src/domain/EIF/PlanAdministratorsAndBilling/BillingPreferencesPolicySummary";
import { claimsCheckMailingLocationValidationSchema } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFClaimsCheckMailingLocationSelection";
import { MissingMonthlyClaimsAlert } from "client/src/domain/EIF/PlanAdministratorsAndBilling/MissingMonthlyClaimsAlert";
import {
  getBillsHaveChanges,
  useGetChangesHasDeletedBills,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { ClaimSentToHomeAddressMsg } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/claimsCheckMailingLocations";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import { EIFStepHeader } from "client/src/domain/EIF/common/EIFStepHeader";
import { EIFSubStepHeader } from "client/src/domain/EIF/common/EIFSubStepHeader";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import {
  getChangeDetailInfoListForSubStep,
  getChangeListDetailInfoIsEmpty,
} from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getIsLastSubStepMap } from "client/src/domain/EIF/common/utils/getIsLastStepOrSubStepMap";
import { shouldShowDivider } from "client/src/domain/EIF/common/utils/getShouldShowHorizontalDivider";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { PrintHidden } from "client/src/utils/print";
import { Fragment } from "react";
import { RouteData } from "shared/config/routeData";
import { payableToValuesRecord } from "shared/types/Client";
import { type EIFPlanAdministratorsAndBillingSubStepMap, type EIFStepId } from "shared/types/EIF";
import {
  getClaimsCheckMailingLocationKey,
  getClientCoveragesThatAreAlwaysSentToEmployeesHome,
  getPlansWhereCanSetClaimsCheckLocations,
  type Plan,
} from "shared/types/Plan";
import { getBillingPreferencesCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getPlansWhereCanSetMonthlyClaimsLocations } from "shared/utils/EIF/monthlyClaimsReportsAndEOBs";
import { getStatementsForPolicy } from "shared/utils/bill";
import {
  getPrimaryPolicy,
  getIsMultiPolicyMode,
  getShowValidationErrorsInSummary,
} from "shared/utils/client";
import { getClaimsCheckMailingLocationsInitialFormValues } from "shared/utils/plan";
import { assertIsDefined, assertIsDefinedInObject } from "shared/utils/utils";
import { malpracticeReimbursementsValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3, Eyebrow } from "../../../components/Typography/Typography";
import { ContactCard } from "../../Contact/ContactCard";
import type { EIFSubStepsHaveChanges } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const ShowAllSubSteps: EIFSubStepsHaveChanges<EIFPlanAdministratorsAndBillingSubStepMap> = {
  "plan-administrators": true,
  "billing-preferences": true,
  "claims-check-mailing-locations": true,
  "malpractice-reimbursements": true,
  "monthly-claims-reports-and-eobs": true,
} as const;

export function PrimaryPlanAdministratorSummary(props: {
  client: Client;
  contacts: Contact[];
  bills: Bill[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  featureToggles: ClientFeatureToggles;
  signerMode: SignerMode;
  isLastStep: boolean;
  isLastSubStep: Record<string, boolean>;
}) {
  const {
    client,
    contacts,
    bills,
    changeSnapshot,
    authUser,
    viewMode,
    featureToggles,
    signerMode,
    isLastStep,
    isLastSubStep,
  } = props;

  const eifSubStepId = "plan-administrators";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client, bills });

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showEditBanner = getShowEditBanner(viewMode);

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode}>
      <StackY dist={16} wrap={false}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-plan-administrators"
            navigateLink={editPath}
            changeDetailInfoList={getChangeDetailInfoListForSubStep({
              eifSubStepId,
              changeSnapshot,
              contacts,
            })}
            client={client}
            authUser={authUser}
          />
        )}

        <EIFSubStepHeader
          eifSubStepId={eifSubStepId}
          viewMode={viewMode}
          editPath={editPath}
          subStepStatus={subStepStatus}
          headingLevel="h3"
          showRedEditBanner={false}
        />

        {client.webAdminContact && (
          <>
            <ContactCard
              contact={client.webAdminContact}
              bills={bills}
              changeSnapshot={changeSnapshot}
              client={client}
              authUser={authUser}
              showEditBanner={showEditBanner}
              navigateLink={editPath}
              allowEdit={false}
              featureToggles={featureToggles}
            />
            {((signerMode === "outside" &&
              shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep)) ||
              (signerMode === "outside" && client.additionalWebAdminContacts?.length)) && (
              <PrintHidden>
                <HorizontalDivider />
              </PrintHidden>
            )}
          </>
        )}

        {!!client.additionalWebAdminContacts?.length && (
          <div className="mt-32">
            <StackY dist={24} wrap={false}>
              <h3>Additional Sun Life Connect users</h3>

              {client.additionalWebAdminContacts?.map((contact) => (
                <ContactCard
                  key={contact.id}
                  contact={contact}
                  bills={bills}
                  showLocationAccess
                  showSunLifeConnectAccess
                  changeSnapshot={changeSnapshot}
                  client={client}
                  authUser={authUser}
                  showEditBanner={showEditBanner}
                  navigateLink={editPath}
                  allowEdit={false}
                  featureToggles={featureToggles}
                />
              ))}
              {signerMode === "outside" &&
                shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
            </StackY>
          </div>
        )}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}

export function BillingPreferencesSummary(props: {
  client: Client;
  policy: Policy;
  bills: Bill[];
  deletedBills: Bill[];
  billingSummaryStatementTemplates: Document[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  signerMode: SignerMode;
  isLastStep: boolean;
  isLastSubStep: Record<string, boolean>;
}) {
  const {
    client,
    policy,
    bills: allBills,
    deletedBills,
    billingSummaryStatementTemplates: allBillingSummaryStatementTemplates,
    featureToggles,
    changeSnapshot,
    authUser,
    viewMode,
    signerMode,
    isLastStep,
    isLastSubStep,
  } = props;

  const eifSubStepId = "billing-preferences";

  const bills = allBills.filter((b) => b.policyId === policy.id);

  const billingSummaryStatementTemplates = getStatementsForPolicy(
    allBillingSummaryStatementTemplates,
    policy,
  );

  const subStepStatus = getBillingPreferencesCompletionStatus({
    policy,
    bills,
    billingSummaryStatementTemplates,
  });

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const editPath = isMultiPolicyMode
    ? RouteData.eifSubStepPolicyDetail.getPath(client.id, eifStepId, eifSubStepId, policy.id)
    : RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showEditBanner = getShowEditBanner(viewMode);

  const changeDetailsInfoListForPolicy = getChangeDetailInfoListForSubStep({
    eifSubStepId,
    bills,
    deletedBills,
    policies: [policy],
    changeSnapshot,
    featureToggles,
  });
  const noChangesForPolicy = getChangeListDetailInfoIsEmpty(changeDetailsInfoListForPolicy);
  const noChangesForBills = !getBillsHaveChanges(bills, changeSnapshot);
  const noDeletedBills = !useGetChangesHasDeletedBills(changeSnapshot, client, policy);

  if (signerMode === "outside" && noChangesForPolicy && noChangesForBills && noDeletedBills) {
    return null;
  }

  const lastClientPolicy = client.policies[client.policies.length - 1];
  assertIsDefined(lastClientPolicy, "lastClientPolicy");
  const showDivider = isMultiPolicyMode
    ? (lastClientPolicy.id !== policy.id && signerMode === "outside") ||
      (signerMode === "outside" && !isLastSubStep[eifSubStepId])
    : signerMode === "outside" && shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep);

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode}>
      <StackY dist={16} wrap={false}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-billing-preferences"
            navigateLink={editPath}
            changeDetailInfoList={changeDetailsInfoListForPolicy}
            client={client}
            authUser={authUser}
          />
        )}

        <EIFSubStepHeader
          eifSubStepId={eifSubStepId}
          viewMode={viewMode}
          editPath={editPath}
          subStepStatus={subStepStatus}
          headingLevel="h3"
          showRedEditBanner={
            getShowValidationErrorsInSummary(viewMode, changeSnapshot) &&
            viewMode !== "review-page" &&
            subStepStatus === "In Progress"
          }
        />

        {isMultiPolicyMode && (
          <h5 className="mb-0">
            {policy.primaryPolicy
              ? `Policy #${policy.slfPolicyNumber} (Primary)`
              : `Policy #${policy.slfPolicyNumber}`}
          </h5>
        )}

        <BillingPreferencesPolicySummary
          client={client}
          policy={policy}
          bills={bills}
          billingSummaryStatementTemplates={billingSummaryStatementTemplates}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          viewMode={viewMode}
          toggleableDetails={viewMode !== "review-page"}
          signerMode={signerMode}
        />
        {showDivider && (
          <PrintHidden>
            <HorizontalDivider />
          </PrintHidden>
        )}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}

export function ClaimsCheckMailingLocationsSummary(props: {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  signerMode: SignerMode;
  isLastStep: boolean;
  isLastSubStep: Record<string, boolean>;
}) {
  const {
    client,
    clientPlans,
    contacts,
    featureToggles,
    changeSnapshot,
    authUser,
    viewMode,
    signerMode,
    isLastStep,
    isLastSubStep,
  } = props;

  const eifSubStepId = "claims-check-mailing-locations";

  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    featureToggles,
    client,
    clientPlans,
    contacts,
  });

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showEditBanner = getShowEditBanner(viewMode);

  const relevantPlansForClaimsCheckLocations = getPlansWhereCanSetClaimsCheckLocations(clientPlans);
  const clientCoveragesThatAreAlwaysSentToEmployeesHome =
    getClientCoveragesThatAreAlwaysSentToEmployeesHome(client);

  const isClaimsCheckPlanSentToEEOnly =
    relevantPlansForClaimsCheckLocations.length === 0 &&
    clientCoveragesThatAreAlwaysSentToEmployeesHome.length > 0;

  const claimsCheckPlansGrouped = relevantPlansForClaimsCheckLocations.reduce<
    Record<string, Plan[]>
  >((acc, plan) => {
    if (plan.sendClaimsCheckTo === null) {
      // plan does not have a claims check mailing location yet, so skip it
      return acc;
    }

    const locationKey = getClaimsCheckMailingLocationKey(plan);

    acc[locationKey] = (acc[locationKey] || []).concat(plan);
    return acc;
  }, {});

  const hasClaimsCheckPlans = Object.keys(claimsCheckPlansGrouped).length > 0;

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode} data-testid={eifSubStepId}>
      <StackY dist={20} wrap={false}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-claims-check-mailing-locations"
            navigateLink={editPath}
            changeDetailInfoList={getChangeDetailInfoListForSubStep({
              changeSnapshot,
              eifSubStepId,
              clientPlans,
            })}
            client={client}
            authUser={authUser}
          />
        )}

        <EIFSubStepHeader
          eifSubStepId={eifSubStepId}
          viewMode={viewMode}
          editPath={editPath}
          subStepStatus={subStepStatus}
          headingLevel="h3"
          showRedEditBanner={false}
        />

        {subStepStatus !== "Not Started" && (
          <>
            {isClaimsCheckPlanSentToEEOnly ? (
              <ClaimSentToHomeAddressMsg
                client={client}
                clientPlans={clientPlans}
                variant="review"
              />
            ) : (
              <>
                {client.claimsCheckPreferencesDefaults === "ACCEPTED_DEFAULTS" && (
                  <AlertBanner
                    variant="success"
                    message={<Body3>You have accepted the default preferences</Body3>}
                  />
                )}

                {client.claimsCheckPreferencesDefaults === "EDITED_DEFAULTS" && (
                  <>
                    {getShowValidationErrorsInSummary(viewMode, changeSnapshot) && (
                      <MissingMonthlyClaimsAlert
                        relevantPlans={relevantPlansForClaimsCheckLocations}
                        relevantSubStep={eifSubStepId}
                      />
                    )}

                    {hasClaimsCheckPlans && (
                      <StackY dist={32} wrap={false}>
                        {Object.entries(claimsCheckPlansGrouped).map(
                          (plansByCheckLocationKeyEntry, index, arr) => {
                            const plansForCheckLocation = plansByCheckLocationKeyEntry[1];
                            const values = getClaimsCheckMailingLocationsInitialFormValues(
                              plansForCheckLocation,
                              contacts,
                            )[0];
                            assertIsDefined(values, "values");
                            const claimsCheckPrefillErrors = getShowValidationErrorsInSummary(
                              viewMode,
                              changeSnapshot,
                            )
                              ? getFormikErrors(
                                  values,
                                  claimsCheckMailingLocationValidationSchema.omit([
                                    "selectedBenefitTypes",
                                  ]),
                                  { prefill: false },
                                )
                              : {};

                            return (
                              <div
                                key={index}
                                data-testid={`ClaimsLocationReviewCard__${index + 1}`}
                              >
                                <ClaimsLocationReviewCard
                                  index={arr.length > 1 ? index + 1 : null}
                                  client={client}
                                  plans={plansForCheckLocation}
                                  featureToggles={featureToggles}
                                  contacts={contacts}
                                  claimsCheckPrefillErrors={claimsCheckPrefillErrors}
                                  changeSnapshot={changeSnapshot}
                                />
                                {signerMode === "outside" &&
                                  index !== Object.entries(claimsCheckPlansGrouped).length - 1 &&
                                  shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
                                    <PrintHidden>
                                      <HorizontalDivider />
                                    </PrintHidden>
                                  )}
                              </div>
                            );
                          },
                        )}
                      </StackY>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {signerMode === "outside" && shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
          <PrintHidden>
            <HorizontalDivider />
          </PrintHidden>
        )}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}

export function MonthlyClaimsReportsAndEOBsSummary(props: {
  client: Client;
  clientPlans: Plan[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  signerMode: SignerMode;
  isLastStep: boolean;
  isLastSubStep: Record<string, boolean>;
}) {
  const {
    client,
    clientPlans,
    featureToggles,
    changeSnapshot,
    authUser,
    viewMode,
    signerMode,
    isLastStep,
    isLastSubStep,
  } = props;

  const eifSubStepId = "monthly-claims-reports-and-eobs";

  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
    clientPlans,
  });

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showEditBanner = getShowEditBanner(viewMode);

  const relevantPlans = getPlansWhereCanSetMonthlyClaimsLocations(clientPlans);

  const showValidationErrorsInSummary = getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  assertIsDefinedInObject(client, "monthlyClaimsReportMailingLocations");

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode} data-testid={eifSubStepId}>
      <StackY dist={20} wrap={false}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-monthly-claims-reports-and-eobs-locations"
            navigateLink={editPath}
            changeDetailInfoList={getChangeDetailInfoListForSubStep({
              changeSnapshot,
              eifSubStepId,
              client,
            })}
            client={client}
            authUser={authUser}
          />
        )}

        <EIFEntityDeletedBy
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          client={client}
          entity="MonthlyClaimsReportMailingLocation"
          prefixWord="Mailing"
          pluralizeWord="Location"
        />

        <EIFSubStepHeader
          eifSubStepId={eifSubStepId}
          viewMode={viewMode}
          editPath={editPath}
          subStepStatus={subStepStatus}
          headingLevel="h3"
          showRedEditBanner={false}
        />

        {subStepStatus !== "Not Started" && (
          <>
            {client.monthlyClaimsReportsAndEOBsDefaults === "ACCEPTED_DEFAULTS" && (
              <AlertBanner
                variant="success"
                message={<Body3>You have accepted the default preferences</Body3>}
              />
            )}

            {client.monthlyClaimsReportsAndEOBsDefaults === "EDITED_DEFAULTS" && (
              <>
                {showValidationErrorsInSummary && (
                  <MissingMonthlyClaimsAlert
                    client={client}
                    relevantPlans={relevantPlans}
                    relevantSubStep={eifSubStepId}
                  />
                )}

                {client.monthlyClaimsReportMailingLocations.length > 0 && (
                  <StackY dist={32} wrap={false}>
                    {client.monthlyClaimsReportMailingLocations.map((mailingLocation, index) => (
                      <Fragment key={mailingLocation.id}>
                        <MonthlyClaimsReportsAndEOBsReviewCard
                          index={
                            client.monthlyClaimsReportMailingLocations.length > 1 ? index + 1 : null
                          }
                          disabled={false}
                          client={client}
                          mailingLocation={mailingLocation}
                          authUser={authUser}
                          changeSnapshot={changeSnapshot}
                          featureToggles={featureToggles}
                        />
                        {signerMode === "outside" &&
                          index !== client.monthlyClaimsReportMailingLocations.length - 1 &&
                          shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
                            <PrintHidden>
                              <HorizontalDivider />
                            </PrintHidden>
                          )}
                      </Fragment>
                    ))}
                  </StackY>
                )}
              </>
            )}
          </>
        )}
        {signerMode === "outside" && shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
          <PrintHidden>
            <HorizontalDivider />
          </PrintHidden>
        )}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}

export function MalpracticeReimbursementsSummary(props: {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  signerMode: SignerMode;
  isLastStep: boolean;
  isLastSubStep: Record<string, boolean>;
}) {
  const { client, changeSnapshot, authUser, viewMode, signerMode, isLastStep, isLastSubStep } =
    props;

  const eifSubStepId = "malpractice-reimbursements";

  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
  });

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showEditBanner = getShowEditBanner(viewMode);

  const { malpracticeReimbursementRider, malpracticeReimbursementPayableTo } = client;
  const errors = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(
        { malpracticeReimbursementRider, malpracticeReimbursementPayableTo },
        malpracticeReimbursementsValidationSchema,
        {
          prefill: false,
        },
      )
    : {};

  return (
    <EIFSubStepSummaryWrapper signerMode={signerMode}>
      <StackY dist={20} wrap={false}>
        {showEditBanner && (
          <EditBanner
            data-testid="internal-control-malpractice-reimbursements"
            navigateLink={editPath}
            changeDetailInfoList={getChangeDetailInfoListForSubStep({
              changeSnapshot,
              eifSubStepId,
            })}
            client={client}
            authUser={authUser}
          />
        )}

        <EIFSubStepHeader
          eifSubStepId={eifSubStepId}
          viewMode={viewMode}
          editPath={editPath}
          subStepStatus={subStepStatus}
          headingLevel="h3"
          showRedEditBanner={Object.keys(errors).length > 0}
        />

        {subStepStatus !== "Not Started" && (
          <>
            {client.malpracticeReimbursementRider === "NO" && (
              <div>
                <Body3>Benefit not purchased</Body3>
              </div>
            )}
            {client.malpracticeReimbursementRider === "YES" && (
              <div>
                <Eyebrow>The benefit is payable to</Eyebrow>
                <br />
                {client.malpracticeReimbursementPayableTo && (
                  <Body3>{payableToValuesRecord[client.malpracticeReimbursementPayableTo]}</Body3>
                )}

                {errors.malpracticeReimbursementPayableTo && (
                  <Body3 redMedium>{errors.malpracticeReimbursementPayableTo}</Body3>
                )}

                {!client.malpracticeReimbursementPayableTo &&
                  !errors.malpracticeReimbursementPayableTo && <Body3>-</Body3>}
              </div>
            )}
          </>
        )}
        {signerMode === "outside" && shouldShowDivider(eifSubStepId, isLastSubStep, isLastStep) && (
          <PrintHidden>
            <HorizontalDivider />
          </PrintHidden>
        )}
      </StackY>
    </EIFSubStepSummaryWrapper>
  );
}

const eifStepId: EIFStepId = "plan-administrators-&-billing";

type EIFPlanAdministrationAndBillingReviewProps = {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  bills: Bill[];
  deletedBills: Bill[];
  billingSummaryStatementTemplates: Document[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  authUser: UserData;
  signerMode: SignerMode;
  showSubSteps: EIFSubStepsHaveChanges<EIFPlanAdministratorsAndBillingSubStepMap>;
  isLastStep: boolean;
};

export const EIFPlanAdministrationAndBillingReview = ({
  client,
  clientPlans,
  contacts,
  bills,
  deletedBills,
  billingSummaryStatementTemplates,
  featureToggles,
  viewMode,
  changeSnapshot,
  authUser,
  signerMode,
  showSubSteps,
  isLastStep,
}: EIFPlanAdministrationAndBillingReviewProps) => {
  const showClaimsCheckMailingLocations = getIsSubStepApplicable({
    eifSubStepId: "claims-check-mailing-locations",
    plans: clientPlans,
    client,
  });

  const showMonthlyClaimsReportsAndEobs = getIsSubStepApplicable({
    eifSubStepId: "monthly-claims-reports-and-eobs",
    plans: clientPlans,
  });

  const showMalpracticeReimbursements = getIsSubStepApplicable({
    eifSubStepId: "malpractice-reimbursements",
    client,
  });

  const isMultiPolicyMode = getIsMultiPolicyMode(client);
  const isLastSubStep = getIsLastSubStepMap(showSubSteps);

  return (
    <>
      {signerMode === "inside" && <EIFStepHeader viewMode={viewMode} eifStepId={eifStepId} />}

      <StackY dist={32}>
        {showSubSteps["plan-administrators"] && (
          <PrimaryPlanAdministratorSummary
            client={client}
            contacts={contacts}
            bills={bills}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            viewMode={viewMode}
            featureToggles={featureToggles}
            signerMode={signerMode}
            isLastStep={isLastStep}
            isLastSubStep={isLastSubStep}
          />
        )}

        {isMultiPolicyMode && showSubSteps["billing-preferences"]
          ? client.policies.map((policy) => {
              return (
                <BillingPreferencesSummary
                  key={policy.id}
                  client={client}
                  policy={policy}
                  bills={bills}
                  deletedBills={deletedBills}
                  billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                  featureToggles={featureToggles}
                  changeSnapshot={changeSnapshot}
                  authUser={authUser}
                  viewMode={viewMode}
                  signerMode={signerMode}
                  isLastStep={isLastStep}
                  isLastSubStep={isLastSubStep}
                />
              );
            })
          : showSubSteps["billing-preferences"] && (
              <BillingPreferencesSummary
                client={client}
                policy={getPrimaryPolicy(client)}
                bills={bills}
                deletedBills={deletedBills}
                billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                featureToggles={featureToggles}
                changeSnapshot={changeSnapshot}
                authUser={authUser}
                viewMode={viewMode}
                signerMode={signerMode}
                isLastStep={isLastStep}
                isLastSubStep={isLastSubStep}
              />
            )}

        {showClaimsCheckMailingLocations && showSubSteps["claims-check-mailing-locations"] && (
          <ClaimsCheckMailingLocationsSummary
            client={client}
            clientPlans={clientPlans}
            contacts={contacts}
            featureToggles={featureToggles}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            viewMode={viewMode}
            signerMode={signerMode}
            isLastStep={isLastStep}
            isLastSubStep={isLastSubStep}
          />
        )}

        {showMonthlyClaimsReportsAndEobs && showSubSteps["monthly-claims-reports-and-eobs"] && (
          <MonthlyClaimsReportsAndEOBsSummary
            client={client}
            clientPlans={clientPlans}
            featureToggles={featureToggles}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            viewMode={viewMode}
            signerMode={signerMode}
            isLastStep={isLastStep}
            isLastSubStep={isLastSubStep}
          />
        )}

        {showMalpracticeReimbursements && showSubSteps["malpractice-reimbursements"] && (
          <MalpracticeReimbursementsSummary
            client={client}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            viewMode={viewMode}
            signerMode={signerMode}
            isLastStep={isLastStep}
            isLastSubStep={isLastSubStep}
          />
        )}

        {viewMode !== "review-page" && <EIFStepBottomNavLinks eifStepId={eifStepId} />}
      </StackY>
    </>
  );
};
