import { Divider, Image } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import {
  Body1,
  Body3,
  Body5,
  Eyebrow,
  H1,
  H2,
  H5,
} from "../../../../components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "../../../../components/UnorderedList/UnorderedList";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey } from "../../utils";

import * as styles from "./benefit.module.less";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

export const DataTestId = {
  RealWorldWrapper: "real-world-wrapper",
  PlanSummaryDownloadLink: "plan-summary-download",
};

export type ExplorerBenefitRealWorldCopyStructure = {
  header: string;
} & {
  [benefitType in Extract<
    BenefitTypeBenEx,
    | "ACCIDENT"
    | "CANCER"
    | "HOSPITAL_INDEMNITY"
    | "CRITICAL_ILLNESS"
    | "GAP"
    | "LONG_TERM_DISABILITY"
    | "SHORT_TERM_DISABILITY"
  >]: {
    title: string;
    subTitle: string;
    bulletListHeader: string;
    bulletList: string[];
    description: string;
    costsHeader: string;
    costs: { name: string; amount: string }[];
    costTotal: string;
    costTotalSubTitle: string;
    showClaims: boolean;
    claimsHeader?: string;
    claims?: { name: string; amount: string }[];
    claimsTotal?: string;
    claimsTotalSubTitle?: string;
    disclaimer: string;
  };
};

type ExplorerBenefitRealWorldProps = {
  benefitType: BenefitTypeBenEx;
  carrierName: string;
  alternateName: string;
  isLargeClient: boolean;
};

const imagePropsPerBenefit: {
  [key in BenefitTypeBenEx]?: { src: string; srcSet: string; alt: string };
} = {
  ACCIDENT: {
    src: "/Accident-rosa.png",
    srcSet: "",
    alt: "Rosa",
  },
  CANCER: {
    src: "/Cancer-jane.png",
    srcSet: "",
    alt: "Jane",
  },
  HOSPITAL_INDEMNITY: {
    src: "/Hospital Indemnity-sandra.jpg",
    srcSet: "",
    alt: "Jane",
  },
  CRITICAL_ILLNESS: {
    src: "/Critical Illness-len.png",
    srcSet: "",
    alt: "Len",
  },
  GAP: {
    src: "/Gap-lakisha.png",
    srcSet: "",
    alt: "Lakisha",
  },
  LONG_TERM_DISABILITY: {
    src: "/LTD-steve.png",
    srcSet: "",
    alt: "Steve",
  },
  SHORT_TERM_DISABILITY: {
    src: "/STD-maria.png",
    srcSet: "",
    alt: "Maria",
  },
};

export const ExplorerBenefitRealWorld = ({
  benefitType,
  carrierName,
  alternateName,
  isLargeClient,
}: ExplorerBenefitRealWorldProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitRealWorld" });

  const benefitAmountAndHelpTextCopy = isLargeClient ? "70%" : "60%";
  const monthlyBenefitCopy =
    benefitType === "LONG_TERM_DISABILITY"
      ? isLargeClient
        ? "$2,917"
        : "$2,500"
      : benefitType === "SHORT_TERM_DISABILITY"
      ? isLargeClient
        ? "$673"
        : "$576"
      : "";
  const payoutCopy =
    benefitType === "LONG_TERM_DISABILITY"
      ? isLargeClient
        ? "$35,000"
        : "$22,500"
      : benefitType === "SHORT_TERM_DISABILITY"
      ? isLargeClient
        ? "$673"
        : "$576"
      : "";

  const bulletList: string[] = t(copyKey(benefitType, "bulletList"), {
    returnObjects: true,
    defaultValue: [],
    alternateName,
  });
  const costs = t<string, { name: string; amount: string }[]>(copyKey(benefitType, "costs"), {
    returnObjects: true,
    defaultValue: [],
    alternateName,
    benefitAmountAndHelpTextCopy,
    monthlyBenefitCopy,
  }).map((_, i) => ({
    name: (
      <Trans t={t} i18nKey={copyKey(benefitType, "costs", i, "name")} values={{ alternateName }} />
    ),
    amount: (
      <Trans
        t={t}
        i18nKey={copyKey(benefitType, "costs", i, "amount")}
        values={{ alternateName, benefitAmountAndHelpTextCopy, monthlyBenefitCopy }}
      />
    ),
  }));
  const claims: { name: string; amount: string }[] = t(copyKey(benefitType, "claims"), {
    returnObjects: true,
    defaultValue: [],
    alternateName,
  });

  const screens = useBreakpoint();

  const lineItem = (
    name: string | React.ReactElement,
    amount: string | React.ReactElement,
    key: string,
  ) => (
    <div key={key}>
      <Row justify="space-between" gutter={48} wrap={false}>
        <Col>
          <Body3> {name}</Body3>
        </Col>
        <Col>
          <Body3> {amount}</Body3>
        </Col>
      </Row>
      <Divider className={clsx("mt-4", "mb-8")} type="horizontal" />
    </div>
  );

  return (
    <div data-testid={DataTestId.RealWorldWrapper}>
      <H2>{t("header")}</H2>

      <Row className={styles.realWorldWrapper}>
        <Col span={24} md={7}>
          <Image width="100%" preview={false} {...imagePropsPerBenefit[benefitType]} />
        </Col>
        <Col span={24} md={17} className="p-24">
          <Row justify="center" style={{ flexDirection: "column", height: "100%" }}>
            <H1>{t(copyKey(benefitType, "title"))}</H1>
            <Row gutter={[0, 16]} className="mt-8">
              <Col span={24} md={11}>
                <Body1>{t(copyKey(benefitType, "subTitle"), { alternateName })}</Body1>
              </Col>
              {screens.md && (
                <Col span={2}>
                  <Row justify="center" style={{ height: "100%" }}>
                    <Divider type="vertical" style={{ height: "100%", margin: "0" }} />
                  </Row>
                </Col>
              )}
              <Col span={24} md={11}>
                <div className="mb-8">
                  <Eyebrow>
                    {t(copyKey(benefitType, "bulletListHeader"), { alternateName }).toUpperCase()}
                  </Eyebrow>
                </div>
                <UnorderedList>
                  {bulletList.map((item, i) => (
                    <UnorderedListItem key={`${item}-${i}`}>{item}</UnorderedListItem>
                  ))}
                </UnorderedList>
              </Col>
            </Row>
          </Row>
        </Col>
        <Col span={24} md={7} className={clsx("px-24", "pb-32", screens.md && "pt-32")}>
          <Body1>
            {t(copyKey(benefitType, "description"), { carrier: carrierName, alternateName })}
          </Body1>
        </Col>
        <Col span={24} md={17} className={clsx("px-24", "py-32", styles.costClaims)}>
          <Row gutter={[56, 24]} align="stretch">
            <Col span={24} md={12}>
              <Row justify="space-between" style={{ flexDirection: "column", height: "100%" }}>
                <Col>
                  <H1>{t(copyKey(benefitType, "costsHeader"), { alternateName })}</H1>
                  {costs.map((cost, i) =>
                    lineItem(cost.name, cost.amount, `${benefitType}-costs-${i}`),
                  )}
                </Col>
                <Col>
                  <H1 as="span">
                    <Trans
                      t={t}
                      i18nKey={copyKey(benefitType, "costTotal")}
                      values={{ payoutCopy }}
                    />
                  </H1>
                  <H5>{t(copyKey(benefitType, "costTotalSubTitle"))}</H5>
                </Col>
              </Row>
            </Col>
            <Col span={24} md={12}>
              {t(copyKey(benefitType, "showClaims")) && (
                <Row justify="space-between" style={{ flexDirection: "column", height: "100%" }}>
                  <Col>
                    <H1>{t(copyKey(benefitType, "claimsHeader"), { alternateName })}</H1>
                    {claims.map((cost, i) =>
                      lineItem(cost.name, cost.amount, `${benefitType}-claims-${i}`),
                    )}
                  </Col>
                  <Col>
                    <H1 as="span">
                      <div className={styles.claimsTotal}>
                        {t(copyKey(benefitType, "claimsTotal"))}
                      </div>
                    </H1>
                    <H5>
                      <div className={styles.claimsTotal}>
                        {t(copyKey(benefitType, "claimsTotalSubTitle"), { carrier: carrierName })}
                      </div>
                    </H5>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Body5>
        <Trans
          t={t}
          i18nKey={copyKey(benefitType, "disclaimer")}
          values={{ benefitAmountAndHelpTextCopy }}
        />
      </Body5>
    </div>
  );
};
