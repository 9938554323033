import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { getEditedByWhoText } from "client/src/domain/EIF/common/utils/getEditedByWhoText";
import { useGetDeletedBills } from "client/src/hooks/bill";
import pluralize from "pluralize";
import { getIsInternalUser } from "shared/rbac/rbac";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, PolicyId } from "shared/types/Client";
import type { User } from "shared/types/User";

export function EIFEntityDeletedBy(
  props: {
    changeSnapshot: DEIFChangeSnapshot;
    authUser: UserData | null;
    client: Client;
    initialChangeDetailInfo?: Array<{
      user: Pick<User, "firstName" | "lastName">;
      date: Date;
      status: "pending";
    }>;
    prefixWord?: string;
    pluralizeWord: string;
    changeSummaryVariant?: boolean;
  } & (
    | {
        entity: Exclude<keyof DEIFChangeSnapshot, "Client" | "Bill">;
      }
    | {
        entity: "Bill";
        policyId: PolicyId;
      }
  ),
) {
  const {
    changeSnapshot,
    authUser,
    client,
    entity,
    pluralizeWord,
    prefixWord,
    changeSummaryVariant = false,
    initialChangeDetailInfo = [],
  } = props;

  const { data: deletedBills } = useGetDeletedBills(client.id, { enabled: entity === "Bill" });

  const deletedEntities = Object.keys(changeSnapshot?.[entity] ?? {})
    .filter((id) => {
      if (entity === "Bill") {
        assertIsDefined(deletedBills, "deletedBills");
        const keep = deletedBills.some(
          (deletedBill) => deletedBill.policyId === props.policyId && deletedBill.id === id,
        );
        return keep;
      }
      return true;
    })
    .map((key) => {
      const value = changeSnapshot?.[entity]?.[key];
      return value;
    })
    .filter(rejectNullableValues)
    .filter((changeDetailRecord) => "deletedAt" in changeDetailRecord)
    .map((changeDetailRecord) => changeDetailRecord.deletedAt)
    .filter(rejectNullableValues)
    .map((changeDetailInfo) => ({
      user: changeDetailInfo.currentValue.user
        ? {
            firstName: changeDetailInfo.currentValue.user.firstName,
            lastName: changeDetailInfo.currentValue.user.lastName,
          }
        : null,
      date: changeDetailInfo.currentValue.date,
      status: changeDetailInfo.status,
    }))
    .concat(...initialChangeDetailInfo)
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  if (deletedEntities == null || deletedEntities.length === 0) {
    return null;
  }

  const [changeDetailInfo] = deletedEntities;
  assertIsDefined(changeDetailInfo, "changeDetailInfo");

  const editedByUser = changeDetailInfo.user;
  const editedByUserTxt = editedByUser
    ? `${editedByUser.firstName} ${editedByUser.lastName}`
    : "(unknown)";
  const formattedDate = changeDetailInfo
    ? formatDateFullMonthWithYear(changeDetailInfo.date)
    : "(unknown date)";

  const isInternalUser = authUser && getIsInternalUser(authUser);

  const areChangesReadyForReview = client.deifChangesReadyForReviewAt != null;
  const areChangesAcceptedOrDeclined =
    changeDetailInfo.status === "accepted" || changeDetailInfo.status === "declined";

  const banner = (
    <AlertBanner
      variant="warning"
      message={
        <Body3>
          {deletedEntities.length} {prefixWord} {pluralize(pluralizeWord, deletedEntities.length)}{" "}
          {pluralize("was", deletedEntities.length)} deleted by{" "}
          {getEditedByWhoText(authUser, editedByUserTxt)} on {formattedDate}
        </Body3>
      }
    />
  );

  if (changeSummaryVariant) {
    return banner;
  }

  if (isInternalUser && !areChangesReadyForReview) {
    return (
      <DashedBanner variant="warning">
        <StackY dist={8}>
          <Body2 darkYellow as="div">
            Internal User:
          </Body2>

          {banner}
        </StackY>
      </DashedBanner>
    );
  }

  return areChangesReadyForReview || areChangesAcceptedOrDeclined ? banner : null;
}
