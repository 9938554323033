import { Body3, Body5 } from "client/src/components/Typography/Typography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { displayCompensationTypeName, displayEmploymentTypeName } from "shared/types/EmployeeClass";
import { getEmployeeClassEligibilityCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { employeeClassValidationSchema } from "shared/validation/employeeClass";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

const detailsSectionProps = [
  "numberOfEmployees",
  "employmentTypes",
  "minimumWeeklyHours",
  "minimumWeeklyHoursAreNotCalculatedWeekly",
  "minimumHoursDetails",
] as const;
const detailsValidationSchema = employeeClassValidationSchema.pick(detailsSectionProps);

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot | null;
  authUser: UserData | null;
  showSingleClassBuilderName?: boolean;
  hideTitle?: boolean;
};

export function EIFClassBuilderItemEligibility(props: Props) {
  const {
    client,
    employeeClass,
    changeSnapshot,
    authUser,
    showSingleClassBuilderName = false,
    hideTitle = false,
  } = props;

  const singleClassBuilderMode = client.needsEmployeeClasses === "NO";

  const eligibilityStatus = getEmployeeClassEligibilityCompletionStatus(
    employeeClass,
    singleClassBuilderMode,
  );

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const strictErrors = !suppressErrorsPostSigning
    ? getFormikErrors(employeeClass, detailsValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid="eligibility-class-builder-item">
      {!hideTitle && <h5>Eligibility</h5>}
      <EditedFieldMsg
        changeDetailInfoList={[
          changeSnapshot?.EmployeeClass[employeeClass.id]?.jobTitles ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.employmentTypes ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.compensationTypes ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.otherAttributes ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.numberOfEmployees ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]
            ?.minimumWeeklyHoursAreNotCalculatedWeekly ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.minimumHoursDetails ?? null,
          changeSnapshot?.EmployeeClass[employeeClass.id]?.minimumWeeklyHours ?? null,
        ]}
        client={client}
        authUser={authUser}
      />
      <div className="mt-12">
        {!singleClassBuilderMode && (
          <>
            <Body3 as="div">{employeeClass.jobTitles?.join(", ")}</Body3>

            <Body3 as="div">
              {employeeClass.compensationTypes
                ?.map((item) => displayCompensationTypeName[item])
                .join(", ")}
            </Body3>

            <Body3 as="div">{employeeClass.otherAttributes?.join(", ")}</Body3>

            {eligibilityStatus === "Not Started" &&
              !singleClassBuilderMode &&
              !suppressErrorsPostSigning && (
                <Body3 as="div" redMedium>
                  Please select at least one eligibility attribute
                </Body3>
              )}

            {eligibilityStatus === "Not Started" &&
              !singleClassBuilderMode &&
              suppressErrorsPostSigning && <Body3 as="div">-</Body3>}
          </>
        )}

        {singleClassBuilderMode && showSingleClassBuilderName && (
          <Body3 as="div">All Full-Time Employees</Body3>
        )}

        {employeeClass.numberOfEmployees && (
          <Body3 as="div">{employeeClass.numberOfEmployees} eligible employees</Body3>
        )}

        {strictErrors.numberOfEmployees && !employeeClass.numberOfEmployees && (
          <Body3 as="div" redMedium>
            {strictErrors.numberOfEmployees}
          </Body3>
        )}

        {!strictErrors.numberOfEmployees && !employeeClass.numberOfEmployees && (
          <Body3 as="div">-</Body3>
        )}

        <Body3 as="div">
          {employeeClass.employmentTypes?.map((item) => displayEmploymentTypeName[item]).join(", ")}
        </Body3>

        {strictErrors.employmentTypes &&
          !(employeeClass.employmentTypes && employeeClass.employmentTypes.length > 0) && (
            <Body3 as="div" redMedium>
              {strictErrors.employmentTypes}
            </Body3>
          )}

        {!strictErrors.employmentTypes &&
          !(employeeClass.employmentTypes && employeeClass.employmentTypes.length > 0) && (
            <Body3 as="div">-</Body3>
          )}

        {employeeClass.minimumWeeklyHoursAreNotCalculatedWeekly ? (
          <div className="mt-12">
            <Body5>Eligible hours</Body5>
            <Body3 as="div">{employeeClass.minimumHoursDetails}</Body3>
          </div>
        ) : (
          <>
            {employeeClass.minimumWeeklyHours && (
              <Body3 as="div">{employeeClass.minimumWeeklyHours} minimum weekly hours</Body3>
            )}
          </>
        )}

        {strictErrors.minimumHoursDetails && !employeeClass.minimumHoursDetails && (
          <Body3 as="div" redMedium>
            {strictErrors.minimumHoursDetails}
          </Body3>
        )}

        {!strictErrors.minimumHoursDetails &&
          employeeClass.minimumWeeklyHoursAreNotCalculatedWeekly &&
          !employeeClass.minimumHoursDetails && <Body3 as="div">-</Body3>}

        {strictErrors.minimumWeeklyHours && !employeeClass.minimumWeeklyHours && (
          <Body3 as="div" redMedium>
            {strictErrors.minimumWeeklyHours}
          </Body3>
        )}

        {!strictErrors.minimumWeeklyHours && !employeeClass.minimumWeeklyHours && (
          <Body3 as="div">-</Body3>
        )}
      </div>
    </div>
  );
}
