import { Image } from "antd";
import SkeletonImage from "antd/lib/skeleton/Image";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import moment from "moment";

import { H1Large, H4 } from "../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../i18n";
import { getCSSVariableValue } from "../../../utils/getCSSVariableValue";
import { timezoneAdjustedMoment } from "../../../utils/moment";

import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";

export const DataTestId = {
  headlineWrapper: "explorer-headline-wrapper",
};

export type ExplorerHeadlineCopyStructure = {
  header: string;
  header_one: string;
  header_other: string;
  header_maintenance: string;
  header_before: string;
  header_after: string;
  header_deleted: string;
  subHeader: string;
  subHeader_maintenance: string;
  subHeader_before: string;
  subHeader_after: string;
  subHeader_deleted: string;
};

type ExplorerHeadlineProps = {
  explorerPage: PublicExplorerPage | undefined;
  logoURL?: string | undefined;
  benefitCount: number;
};

export const ExplorerHeadline = ({
  explorerPage,
  logoURL,
  benefitCount,
}: ExplorerHeadlineProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerHeadline" });

  const context = !explorerPage
    ? "deleted"
    : explorerPage.maintenanceMode
    ? "maintenance"
    : moment().isBefore(timezoneAdjustedMoment(moment(explorerPage.startsAt)))
    ? "before"
    : !explorerPage.newExplorerPageId &&
      moment().isAfter(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
    ? "after"
    : undefined;

  const isPageExpiresInFuture = explorerPage?.expiresAt
    ? moment().isBefore(timezoneAdjustedMoment(moment(explorerPage.expiresAt)))
      ? true
      : false
    : true;

  return (
    <div data-testid={DataTestId.headlineWrapper}>
      {explorerPage?.logoDocument?.id && (
        <Row justify="center" style={{ marginTop: "-10px" }}>
          <Col style={{ maxWidth: "250px" }}>
            {!logoURL ? (
              <SkeletonImage style={{ height: "100px" }} />
            ) : (
              <Image src={logoURL} alt="Client Logo" preview={false} />
            )}
          </Col>
        </Row>
      )}
      <Row justify="center" className={clsx(logoURL ? "mt-12" : null)}>
        <Col span={24} md={18} style={{ textAlign: "center" }} className="mb-8">
          <H1Large>
            <Trans
              t={t}
              i18nKey="header"
              count={explorerPage?.showBenefitCount ? benefitCount : undefined}
              tOptions={{ context }}
              values={{
                startsAt: timezoneAdjustedMoment(moment(explorerPage?.startsAt)).toDate(),
                clientName: explorerPage?.customClientName
                  ? explorerPage?.customClientName
                  : explorerPage?.clientName,
              }}
            ></Trans>
          </H1Large>
        </Col>
      </Row>
      {isPageExpiresInFuture && (
        <Row justify="center">
          <Col span={24} md={17} style={{ textAlign: "center" }}>
            <H4>
              <Trans t={t} i18nKey="subHeader" tOptions={{ context }}>
                <span
                  style={{
                    color: getCSSVariableValue("--color-tertiary-900"),
                    textDecoration: "underline",
                  }}
                ></span>
              </Trans>
            </H4>
          </Col>
        </Row>
      )}
    </div>
  );
};
