import { Skeleton } from "antd";
import { useMemo } from "react";

import { GenericErrorCopy } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import {
  useGetDocuments,
  useDocumentDownloadHelper,
  useRenameDocument,
  useGetNewDocumentNotices,
  useDocumentDownloadHelperById,
} from "../../../hooks/document";
import { useGetWebLinks } from "../../../hooks/webLink";
import { useDownloadZipFileForDocumentIds } from "../../../hooks/zipped-documents";

import { DocumentDownloadPresentation } from "./DocumentDownloadPresentation";
import { useDocumentDeletionProps } from "./useDocumentDeletionProps";
import { useWebLinkDeletionProps } from "./useWebLinkDeletionProps";

import type { TaskStatus } from "../../../../../shared/data/Tasks";
import type { WebLinkCategory } from "../../../../../shared/types/WebLink";
import type { ResourceRowData } from "../DownloadFileTable/DownloadFileTable";
import type { ReactNode } from "react";
import type { ClientId, PolicyId } from "shared/types/Client";
import type { DocumentCategory, DocumentId } from "shared/types/Document";

export type DocumentDownloadProps = {
  clientId: ClientId;
  categories: DocumentCategory[];
  webLinkCategories?: WebLinkCategory[];
  policyId?: PolicyId;
  documentId?: DocumentId;
  documentIds?: DocumentId[];
  empty?: ReactNode;
  readonly?: boolean;
  track: (buttonLabel: string) => void;
  columnTitleOverrides?: { title: string; key: string }[];
  showUploadUser?: boolean;
  taskStatus?: TaskStatus;
  showResourceTypeColumn?: boolean;
  showBenefitTypeColumn?: boolean;
  withSource?: boolean;
  showDownloadAllButton?: boolean;
  showDownloadButton?: boolean;
  showProcessStatusValidation?: boolean;
  downloadExpandedRowRender?: (record: ResourceRowData) => ReactNode;
  onDelete?: () => void;
};

export const DocumentDownload = ({
  clientId,
  categories,
  policyId,
  webLinkCategories,
  empty,
  readonly,
  track,
  columnTitleOverrides,
  showUploadUser = true,
  taskStatus,
  showBenefitTypeColumn = false,
  showResourceTypeColumn = false,
  withSource = false,
}: DocumentDownloadProps) => {
  const {
    isLoadingDocuments,
    isFetchingDocuments,
    isDownloading,
    errorGetDocuments,
    errorDocumentURL,
    documents,
    downloadDocument,
  } = useDocumentDownloadHelper(clientId, categories, policyId);

  const { mutateAsync: renameDocument, isPending: isPendingRenameDocument } = useRenameDocument();

  const {
    documentDeleteConfirmDialogVisible,
    documentToDeleteName,
    documentPolicyIds,
    openDocumentDeletionConfirmDialog,
    handleConfirmDeleteDocument,
    resetDeleteDocumentParams,
    isPendingDeleteDocument,
    errorDeleteDocument,
  } = useDocumentDeletionProps({
    clientId,
    policyId, // used for cleaning local frontend cache only, as the file will be deleted for all multiple policies anyway (in case of single file for multiple policies)
    track,
  });

  const {
    webLinkDeleteConfirmDialogVisible,
    webLinkToDeleteName,
    openWebLinkDeletionConfirmDialog,
    handleConfirmDeleteWebLink,
    resetDeleteWebLinkParams,
    isPendingDeleteWebLink,
    errorDeleteWebLink,
  } = useWebLinkDeletionProps({ clientId, track });

  const {
    isInitialLoading: isInitialLoadingWebLinks,
    isFetching: isFetchingWebLinks,
    error: errorWebLinks,
    data: webLinks,
  } = useGetWebLinks(clientId, webLinkCategories ? webLinkCategories : []);

  const isLoadingWebLinks = isInitialLoadingWebLinks || isFetchingWebLinks;

  const {
    data: newDocumentNoticesData,
    error: newDocumentNoticesError,
    isLoading: newDocumentNoticesIsLoading,
  } = useGetNewDocumentNotices(clientId);

  const newDocumentNotices = useMemo(() => {
    const set = new Set(newDocumentNoticesData?.map((d) => d.documentId));
    return set;
  }, [newDocumentNoticesData]);

  const { isGeneratingZippedDocument, errorGeneratingZippedFile, requestZipFile } =
    useDownloadZipFileForDocumentIds(clientId);

  if (isLoadingDocuments || isLoadingWebLinks || newDocumentNoticesIsLoading) {
    return <Skeleton active />;
  }

  if (errorGetDocuments || documents == null) {
    return <LoadingError type="component" error={errorGetDocuments} title={GenericErrorCopy} />;
  }

  if (errorWebLinks || (webLinkCategories?.length && webLinks == null)) {
    return <LoadingError type="component" error={errorWebLinks} title={GenericErrorCopy} />;
  }

  if (newDocumentNoticesError || newDocumentNoticesData == null) {
    return (
      <LoadingError type="component" error={newDocumentNoticesError} title={GenericErrorCopy} />
    );
  }

  const showInReviewDeleteText = taskStatus === "In Review";

  const actionsDisabled =
    isDownloading ||
    isPendingDeleteDocument ||
    isPendingRenameDocument ||
    isPendingDeleteWebLink ||
    isFetchingDocuments;

  return (
    <DocumentDownloadPresentation
      clientId={clientId}
      documents={documents}
      webLinks={webLinks ?? []}
      isGeneratingZippedDocument={isGeneratingZippedDocument}
      isLoadingDeleteDocument={isPendingDeleteDocument}
      isLoadingDeleteWebLink={isPendingDeleteWebLink}
      errorGeneratingZippedFile={errorGeneratingZippedFile}
      errorDeleteDocument={errorDeleteDocument}
      errorDeleteWebLink={errorDeleteWebLink}
      errorDocumentURL={errorDocumentURL}
      downloadDocument={downloadDocument}
      renameDocument={renameDocument}
      requestZipFile={requestZipFile}
      documentDeleteConfirmDialogVisible={documentDeleteConfirmDialogVisible}
      documentToDeleteName={documentToDeleteName}
      documentPolicyIds={documentPolicyIds || undefined}
      openDocumentDeletionConfirmDialog={openDocumentDeletionConfirmDialog}
      handleConfirmDeleteDocument={handleConfirmDeleteDocument}
      resetDeleteDocumentParams={resetDeleteDocumentParams}
      webLinkDeleteConfirmDialogVisible={webLinkDeleteConfirmDialogVisible}
      webLinkToDeleteName={webLinkToDeleteName}
      openWebLinkDeletionConfirmDialog={openWebLinkDeletionConfirmDialog}
      handleConfirmDeleteWebLink={handleConfirmDeleteWebLink}
      resetDeleteWebLinkParams={resetDeleteWebLinkParams}
      empty={empty}
      readonly={readonly ?? false}
      showUploadUser={showUploadUser}
      actionsDisabled={actionsDisabled}
      newDocumentNotices={newDocumentNotices}
      columnTitleOverrides={columnTitleOverrides}
      showInReviewDeleteText={showInReviewDeleteText}
      showBenefitTypeColumn={showBenefitTypeColumn}
      showResourceTypeColumn={showResourceTypeColumn}
      track={track}
      withSource={withSource}
    />
  );
};

export const useDocumentDownload = ({
  clientId,
  categories,
  policyId,
  documentId,
  documentIds,
  webLinkCategories,
  empty,
  readonly = false,
  track,
  columnTitleOverrides,
  showUploadUser = true,
  taskStatus,
  showBenefitTypeColumn = false,
  showResourceTypeColumn = false,
  withSource = false,
  showDownloadAllButton = true,
  showDownloadButton = true,
  showProcessStatusValidation = false,
  downloadExpandedRowRender,
  onDelete,
}: DocumentDownloadProps) => {
  return {
    clientId,
    categories,
    policyId,
    webLinkCategories,
    empty,
    readonly,
    track,
    columnTitleOverrides,
    showUploadUser,
    taskStatus,
    showBenefitTypeColumn,
    showResourceTypeColumn,
    withSource,
    showDownloadAllButton,
    showDownloadButton,
    showProcessStatusValidation,
    documents: useGetDocuments({ clientId, categories, policyId, documentId, documentIds }),
    documentNotices: useGetNewDocumentNotices(clientId),
    downloadDocument: useDocumentDownloadHelperById(clientId),
    renameDocument: useRenameDocument(),
    deleteDocument: useDocumentDeletionProps({
      clientId,
      policyId, // used for cleaning local frontend cache only, as the file will be deleted for all multiple policies anyway (in case of single file for multiple policies)
      track,
      onDelete,
    }),
    webLinks: useGetWebLinks(clientId, webLinkCategories ? webLinkCategories : []),
    zipDocuments: useDownloadZipFileForDocumentIds(clientId),
    deleteWebLink: useWebLinkDeletionProps({ clientId, track }),
    downloadExpandedRowRender,
  };
};

export type DocumentDownloadV2Props = ReturnType<typeof useDocumentDownload>;

export const DocumentDownloadV2 = (props: DocumentDownloadV2Props) => {
  // TODO: merge into <DocumentDownloadPresentation />
  const {
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
    error: errorGetDocuments,
    data: documents,
  } = props.documents;
  const { isDownloading, errorDocumentURL, downloadDocument } = props.downloadDocument;
  const { mutateAsync: renameDocument, isPending: isPendingRenameDocument } = props.renameDocument;
  const {
    documentDeleteConfirmDialogVisible,
    documentToDeleteName,
    documentPolicyIds,
    openDocumentDeletionConfirmDialog,
    handleConfirmDeleteDocument,
    resetDeleteDocumentParams,
    isPendingDeleteDocument,
    errorDeleteDocument,
  } = props.deleteDocument;
  const {
    webLinkDeleteConfirmDialogVisible,
    webLinkToDeleteName,
    openWebLinkDeletionConfirmDialog,
    handleConfirmDeleteWebLink,
    resetDeleteWebLinkParams,
    isPendingDeleteWebLink,
    errorDeleteWebLink,
  } = props.deleteWebLink;
  const {
    isInitialLoading: isInitialLoadingWebLinks,
    isFetching: isFetchingWebLinks,
    error: errorWebLinks,
    data: webLinks,
  } = props.webLinks;

  const isLoadingWebLinks = isInitialLoadingWebLinks || isFetchingWebLinks;

  const {
    data: newDocumentNoticesData,
    error: newDocumentNoticesError,
    isLoading: newDocumentNoticesIsLoading,
  } = props.documentNotices;

  const newDocumentNotices = useMemo(() => {
    const set = new Set(newDocumentNoticesData?.map((d) => d.documentId));
    return set;
  }, [newDocumentNoticesData]);

  const { isGeneratingZippedDocument, errorGeneratingZippedFile, requestZipFile } =
    props.zipDocuments;

  if (isLoadingDocuments || isLoadingWebLinks || newDocumentNoticesIsLoading) {
    return <Skeleton active />;
  }

  if (errorGetDocuments || documents == null) {
    return <LoadingError type="component" error={errorGetDocuments} title={GenericErrorCopy} />;
  }

  if (errorWebLinks || (props.webLinkCategories?.length && webLinks == null)) {
    return <LoadingError type="component" error={errorWebLinks} title={GenericErrorCopy} />;
  }

  if (newDocumentNoticesError || newDocumentNoticesData == null) {
    return (
      <LoadingError type="component" error={newDocumentNoticesError} title={GenericErrorCopy} />
    );
  }

  const showInReviewDeleteText = props.taskStatus === "In Review";

  const actionsDisabled =
    isDownloading ||
    isPendingDeleteDocument ||
    isPendingRenameDocument ||
    isPendingDeleteWebLink ||
    isFetchingDocuments;

  return (
    <DocumentDownloadPresentation
      clientId={props.clientId}
      documents={documents}
      webLinks={webLinks ?? []}
      isGeneratingZippedDocument={isGeneratingZippedDocument}
      isLoadingDeleteDocument={isPendingDeleteDocument}
      isLoadingDeleteWebLink={isPendingDeleteWebLink}
      errorGeneratingZippedFile={errorGeneratingZippedFile}
      errorDeleteDocument={errorDeleteDocument}
      errorDeleteWebLink={errorDeleteWebLink}
      errorDocumentURL={errorDocumentURL}
      downloadDocument={downloadDocument}
      renameDocument={renameDocument}
      requestZipFile={requestZipFile}
      documentDeleteConfirmDialogVisible={documentDeleteConfirmDialogVisible}
      documentToDeleteName={documentToDeleteName}
      documentPolicyIds={documentPolicyIds || undefined}
      openDocumentDeletionConfirmDialog={openDocumentDeletionConfirmDialog}
      handleConfirmDeleteDocument={handleConfirmDeleteDocument}
      resetDeleteDocumentParams={resetDeleteDocumentParams}
      webLinkDeleteConfirmDialogVisible={webLinkDeleteConfirmDialogVisible}
      webLinkToDeleteName={webLinkToDeleteName}
      openWebLinkDeletionConfirmDialog={openWebLinkDeletionConfirmDialog}
      handleConfirmDeleteWebLink={handleConfirmDeleteWebLink}
      resetDeleteWebLinkParams={resetDeleteWebLinkParams}
      empty={props.empty}
      readonly={props.readonly}
      showUploadUser={props.showUploadUser}
      actionsDisabled={actionsDisabled}
      newDocumentNotices={newDocumentNotices}
      columnTitleOverrides={props.columnTitleOverrides}
      showInReviewDeleteText={showInReviewDeleteText}
      showBenefitTypeColumn={props.showBenefitTypeColumn}
      showResourceTypeColumn={props.showResourceTypeColumn}
      track={props.track}
      withSource={props.withSource}
      showDownloadAllButton={props.showDownloadAllButton}
      showDownloadButton={props.showDownloadButton}
      showProcessStatusValidation={props.showProcessStatusValidation}
      downloadExpandedRowRender={props.downloadExpandedRowRender}
    />
  );
};
