import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { StackY } from "client/src/components/Spacing/Spacing";
import { RouteData } from "shared/config/routeData";
import { formatFullName } from "shared/utils/format";
import type { ComponentProps } from "react";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";

type Props = {
  client: Client;
  id: string;
  name: string;
  label: React.ReactNode;
  placeholder: string;
  loading: boolean;
  disabled: boolean;
  touched: boolean | undefined;
  error: string | undefined;
  onChange: ComponentProps<typeof SlobSelect>["onChange"];
  contacts: Contact[];
  value: string | null | undefined;
  showRequired?: boolean;
};

export function ContactDropdown(props: Props) {
  const {
    client,
    id,
    name,
    label,
    placeholder,
    loading,
    disabled,
    touched,
    error,
    onChange,
    contacts,
    value,
    showRequired,
  } = props;

  const contactsOptions = contacts.map((admin) => ({
    label: formatFullName(admin),
    value: admin.id,
  }));

  return (
    <StackY dist={8} wrap={false}>
      <SlobSelect
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        loading={loading}
        touched={touched}
        error={error}
        onChange={onChange}
        value={value}
        options={contactsOptions}
        showRequired={showRequired}
      />

      <div>
        <InternalLinkButton
          type="link-inline-bold"
          size="middle"
          to={RouteData.eifSubStepDetail.getPath(
            client.id,
            "plan-administrators-&-billing",
            "plan-administrators",
          )}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faPlusCircle} className="mr-4" />
          Create a new Sun Life Connect user
        </InternalLinkButton>
      </div>
    </StackY>
  );
}
