export const debounce = <T>(callback: (args: T) => void, delay: number) => {
  let timeoutId: number | null = null;

  return (args: T) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      callback(args);
    }, delay);
  };
};
