import { Anchor } from "client/src/components/Anchor/Anchor";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { Body2, Body3 } from "client/src/components/Typography/Typography";

import * as styles from "./ThreeYearLookBackModal.module.less";

type Props = {
  open: boolean;
  onCancel: () => void;
};

export function ThreeYearLookBackModal(props: Props) {
  const { open, onCancel } = props;

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onCancel}
      focusTriggerAfterClose={false}
      destroyOnClose
      title="Three-Year Look-Back percentage"
    >
      <p>
        Three-Year Look-Back percentage can be calculated by adding up the total{" "}
        <strong>employer contributions over the past three years</strong> and then dividing that by
        the total of the{" "}
        <strong>
          employer contributions plus the total of employee post-tax contributions over the past
          three years.
        </strong>
      </p>

      <p>
        For example, if the total employer contributions over the past three years is $10,000 and
        the total employee post-tax contributions over the past three years is $20,000, the
        Three-Year Look-Back would be 33%
      </p>

      <Row justify="space-between" className="mb-20 px-24">
        <Col>
          <Body2 as="p">Example:</Body2>

          <table className={styles.ThreeYearLookBackModal__table}>
            <tr>
              <td></td>
              <Body3 as="td">Employer</Body3>
            </tr>
            <tr>
              <Body3 as="td">÷</Body3>
              <Body3 as="td">(Employer + Employee post-tax)</Body3>
            </tr>
            <tr>
              <Body3 as="td" colSpan={2}>
                _______________________________
              </Body3>
            </tr>
            <tr>
              <Body3 as="td">=</Body3>
              <Body3 as="td">Three-Year Look-Back</Body3>
            </tr>
          </table>
        </Col>
        <Col>
          <Body2 as="p">Or:</Body2>

          <table className={styles.ThreeYearLookBackModal__table}>
            <tr>
              <td></td>
              <Body3 as="td">$10,000</Body3>
            </tr>
            <tr>
              <Body3 as="td">÷</Body3>
              <Body3 as="td">($10,000 + $20,000)</Body3>
            </tr>
            <tr>
              <Body3 as="td" colSpan={2}>
                _______________________________
              </Body3>
            </tr>
            <tr>
              <Body3 as="td">=</Body3>
              <Body3 as="td">0.33 or 33%</Body3>
            </tr>
          </table>
        </Col>
      </Row>

      <p>
        This information will decide the taxability of an Employee’s claim. The Three-Year Look-Back
        should be updated on an annual basis.
      </p>

      <p>
        Note: If you do not have three years of contribution amounts to use for calculating this,
        you can use the prior year or prior two years in this equation. If no previous amounts are
        known for even one year, reasonable estimates of the yearly amounts may be used.
      </p>

      <p>
        Need more help? See Sun Life’s white paper on{" "}
        <Anchor
          target="_blank"
          href="https://p1.aprimocdn.net/sunlife/bc543ea5-ce7f-4243-afa2-afc10136e322/gdiwp-4875-3-yrlookbackwp-original-file.pdf"
        >
          Three-Year Look-Back
        </Anchor>
      </p>

      <Row justify="end" className="mt-32">
        <Col>
          <Button onClick={onCancel} size="middle">
            Close
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
