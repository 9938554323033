import { contactWithAddressValidationSchema } from "shared/validation/contact";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import type { Contact } from "shared/types/Contact";

const primaryWebAdminValidationSchema = // These omitted fields are found on the contact.location not contact in the context of Plan Admins
  contactWithAddressValidationSchema.omit(["city", "state", "zipCode", "address1"]);

const getPrimaryWebAdminValidationErrors = (webAdminContact: Contact | null | undefined) => {
  return getValidationErrors(primaryWebAdminValidationSchema, webAdminContact, {
    prefill: false,
  });
};

export const primaryWebAdminContactValidation = {
  primaryWebAdminValidationSchema,
  getPrimaryWebAdminValidationErrors,
  getIsPrimaryWebAdminValid: (webAdminContact: Contact | null | undefined) => {
    return getPrimaryWebAdminValidationErrors(webAdminContact).length === 0;
  },
};
