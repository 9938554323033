import { datadogRum } from "@datadog/browser-rum";
import { isLocalDev, isTest } from "shared/utils/config";

export function initDatadog(): void {
  if (isLocalDev || isTest) {
    console.log("Datadog is disabled in local development.");
    return;
  }
  const env = ((host) => {
    switch (host) {
      case "slfus-client-onboard.maxwellhealth.com":
      case "onboard.sunlifeconnect.com":
      case "app.yourbenefitsexplorer.com":
        return "production";
      case "staging-slfus-client-onboard.maxwellhealth.com":
      case "staging-onboard.sunlifeconnect.com":
      case "stage-dcs.yourbenefitsexplorer.com":
        return "staging";
      case "qa-slfus-client-onboard.maxwellhealth.com":
      case "qa-onboard.sunlifeconnect.com":
      case "qa-dcs.yourbenefitsexplorer.com":
        return "qa";
      case "localhost:1234":
        return "local";
      default:
        return "unknown";
    }
  })(window.location.host);

  // https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    applicationId: "ad7e610a-7a8d-45b3-ace7-e9035830109e",
    clientToken: "pub33ec96fd0a8affab5fad265f7f9433b9",
    site: "datadoghq.com",
    service: "slfus-client-onboard-front-end",
    env,
    version:
      process.env.GITHUB_SHA && process.env.BUILD_TIMESTAMP
        ? `${process.env.BUILD_TIMESTAMP}__${process.env.GITHUB_SHA}`
        : process.env.GITHUB_SHA || process.env.BUILD_TIMESTAMP || "1.0.0",
    sessionSampleRate: 100,
    // Does not take effect until startSessionReplayRecording()
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: "mask",
  });
}
