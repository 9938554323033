import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import React, { useState } from "react";
import { getIsOwnerUser } from "shared/rbac/rbac";
import { addRecipientValidation } from "shared/validation/onboardingForm";

import { SLF_ADMIN, SLF_OWNER, type UserRole } from "../../../../../shared/rbac/roles";
import { Anchor } from "../../../components/Anchor/Anchor";
import { Button } from "../../../components/Button/Button";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import { ReactComponent as LeftRightArrows } from "../../../components/Icons/LeftRightArrows.svg";
import { Loading } from "../../../components/Loading/Loading";
import { Modal } from "../../../components/Modal/Modal";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useAddRecipientToForm } from "../../../hooks/onboardingForms";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useGetBenAdmins, useGetBrokers, useGetUsersByRole } from "../../../hooks/user";
import { debounce } from "../../../utils/debounce";
import { AddRecipientForm } from "../Form/AddRecipientForm";

import * as styles from "./modal.module.less";

import type { BenAdmin, SlfInternal } from "../../../../../shared/types/User";
import type { ClientId } from "shared/types/Client";
import type { OnboardingFormId } from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

export type BasicUserInfo = {
  id: string;
  name: string;
  email: string;
};

const getBasicUserInfo = (users: SlfInternal[] | BenAdmin[]): BasicUserInfo[] =>
  users.map(({ id, firstName, lastName, email }) => ({
    id,
    name: `${firstName} ${lastName}`,
    email,
  }));

type AddRecipientModalPresentationProps = {
  onShowModalButtonClick: () => void;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  track: (buttonLabel: string) => void;
  slobUsers: BasicUserInfo[];
  manageEnvelopeUrl: string;
  isLoadingUsers: boolean;
  onSuccess: () => unknown;
  setSearchParam: (searchParam: string) => void;
  addRecipient: (options: {
    data: { email: string; name: string; roleName: string };
  }) => Promise<unknown>;
};

export const AddRecipientModalPresentation: React.FC<AddRecipientModalPresentationProps> = ({
  slobUsers,
  onSuccess,
  onShowModalButtonClick,
  onboardingFormRecipients,
  manageEnvelopeUrl,
  isLoadingUsers,
  track,
  setSearchParam,
  addRecipient,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const addRecipientFormik = useSlobFormik({
    initialValues: {
      newSignerOnboardId: "",
      roleName: "",
    },
    validationSchema: addRecipientValidation,
    async onSubmit(values) {
      try {
        const signer = slobUsers.find(({ id }) => id === values.newSignerOnboardId);
        if (!signer) {
          return;
        }

        await addRecipient({
          data: {
            email: signer.email,
            name: signer.name,
            roleName: values.roleName,
          },
        });

        void slobMessage.success("Successfully added the recipient");
        onSuccess();
        setShowSuccessMsg(true);
      } catch (error) {
        const errorMsg = ResponseError.getUserFacingErrorMessage(
          error,
          "An error trying to add the recipient",
        );
        addRecipientFormik.setStatus(errorMsg);
      }
    },
  });

  const closeModal = () => {
    setModalVisible(false);
    setShowSuccessMsg(false);
    addRecipientFormik.resetForm();
  };

  const addRecipientButtonClickAction = () => {
    setModalVisible(true);
    onShowModalButtonClick();
    track("Add an Onboard Signer");
  };

  const onCancel = () => {
    closeModal();
    track("Cancel");
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button type="text" onClick={addRecipientButtonClickAction}>
          <div className={clsx(["stack-x-12", styles.popoverLinkRow])}>
            <LeftRightArrows /> <span className="body3">Add an Inside Signer</span>
          </div>
        </Button>
      </div>
      <Modal
        maskClosable={false}
        title="Add an Onboard Signer"
        open={isModalVisible}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={onCancel}
      >
        {showSuccessMsg ? (
          <StackY dist={16} className="w-full">
            <p>Log into DocuSign to add the new user's tabs and signature field</p>
            <p>
              Click here to{" "}
              <Anchor href={manageEnvelopeUrl} target="_blank">
                View envelope in DocuSign
              </Anchor>
            </p>
            <Button type="primary" loading={addRecipientFormik.isSubmitting} onClick={closeModal}>
              Close
            </Button>
          </StackY>
        ) : isLoadingUsers ? (
          <Loading />
        ) : (
          <form onSubmit={addRecipientFormik.handleSubmit}>
            <StackY dist={16} className="w-full">
              <AddRecipientForm
                formik={addRecipientFormik}
                onboardingFormRecipients={onboardingFormRecipients}
                slobUsers={slobUsers}
                setSearchParam={setSearchParam}
              />
              <StackX dist={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={addRecipientFormik.isSubmitting}
                  disabled={
                    !addRecipientFormik.dirty ||
                    !addRecipientFormik.isValid ||
                    addRecipientFormik.isSubmitting
                  }
                  onClick={() => {
                    track("Add Recipient");
                  }}
                >
                  Add Recipient
                </Button>
                <Button onClick={onCancel} type="text">
                  <span className="body4">Cancel</span>
                </Button>
              </StackX>
              {addRecipientFormik.status && (
                <ErrorMessage>{addRecipientFormik.status}</ErrorMessage>
              )}
            </StackY>
          </form>
        )}
      </Modal>
    </>
  );
};

type AddRecipientButtonProps = {
  onSuccess: () => unknown;
  clientId: ClientId;
  userRole: UserRole;
  onboardingFormId: OnboardingFormId;
  onShowModalButtonClick: () => void;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  manageEnvelopeUrl: string;
  track: (buttonLabel: string) => void;
};

export const AddRecipientModal: React.FC<AddRecipientButtonProps> = ({
  clientId,
  onboardingFormId,
  onSuccess,
  userRole,
  onShowModalButtonClick,
  onboardingFormRecipients,
  track,
  manageEnvelopeUrl,
}) => {
  const [searchParam, setSearchParam] = useState<string>("");
  const queryParams = {
    page: 1,
    pageSize: 100,
    search: "",
  };
  const queryParamsWithClientId = {
    ...queryParams,
    clientId,
  };
  const queryParamsWithSearch = {
    ...queryParams,
    search: searchParam,
  };

  const { data: brokers, isLoading: isLoadingBrokers } = useGetBrokers(queryParamsWithClientId);
  const { data: benAdmins, isLoading: isLoadingBenAdmins } =
    useGetBenAdmins(queryParamsWithClientId);
  const {
    data: slfAdmins,
    isLoading: isLoadingSlfAdmins,
    refetch: refetchSlfAdmins,
  } = useGetUsersByRole(SLF_ADMIN, queryParamsWithSearch);

  const {
    data: slfOwners,
    isInitialLoading: isLoadingSlfOwners,
    refetch: refetchSlfOwners,
  } = useGetUsersByRole(SLF_OWNER, queryParamsWithSearch, {
    enabled: getIsOwnerUser({ role: userRole }),
  });

  const { mutateAsync: addRecipient } = useAddRecipientToForm(clientId, onboardingFormId);

  const isLoadingUsers =
    isLoadingBenAdmins || isLoadingBrokers || isLoadingSlfAdmins || isLoadingSlfOwners;
  const adminsBasicInfoUsers = getBasicUserInfo(slfAdmins ?? []);
  const ownersBasicInfoUsers = getBasicUserInfo(slfOwners ?? []);
  const brokersBasicInfoUsers = getBasicUserInfo(brokers?.data ?? []);
  const benAdminBasicInfoUsers = getBasicUserInfo(benAdmins?.data ?? []);

  const slobUsers = ownersBasicInfoUsers.concat(
    brokersBasicInfoUsers,
    benAdminBasicInfoUsers,
    adminsBasicInfoUsers,
  );

  const setSearchAndRefetch = debounce<string>((searchValue: string) => {
    setSearchParam(searchValue);
    void refetchSlfAdmins();
    void refetchSlfOwners();
  }, 250);

  return (
    <AddRecipientModalPresentation
      onSuccess={onSuccess}
      onShowModalButtonClick={onShowModalButtonClick}
      onboardingFormRecipients={onboardingFormRecipients}
      track={track}
      slobUsers={slobUsers}
      isLoadingUsers={isLoadingUsers}
      addRecipient={addRecipient}
      setSearchParam={setSearchAndRefetch}
      manageEnvelopeUrl={manageEnvelopeUrl}
    />
  );
};
