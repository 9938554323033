import { useRef, useLayoutEffect } from "react";
import { removeSubjectAndPreviewTextMarkup } from "shared/utils/html";

import { useToggler } from "../hooks/useToggler";

export function HTMLPreview({
  title,
  html,
  showCopyHTMLButton,
}: {
  title: string;
  html: string;
  showCopyHTMLButton: boolean;
}) {
  const ref = useIFrame(html);
  const [copySuccessVisible, toggleCopySuccessVisible] = useToggler();
  return (
    <div>
      {showCopyHTMLButton && (
        <>
          <button
            style={{ cursor: "pointer" }}
            onClick={async () => {
              const cleanedHTML = removeSubjectAndPreviewTextMarkup(html);
              await navigator.clipboard.writeText(cleanedHTML);
              toggleCopySuccessVisible();
              setTimeout(toggleCopySuccessVisible, 1000);
            }}
          >
            Copy html
          </button>{" "}
          {copySuccessVisible && "✅ Copied"}
          <br />
          <br />
        </>
      )}
      <iframe
        ref={ref}
        title={title}
        style={{
          height: "100vh",
          width: "100%",
        }}
      />
    </div>
  );
}

function useIFrame(html: string) {
  const ref = useRef<HTMLIFrameElement>(null);
  useLayoutEffect(() => {
    const iframe = ref.current;
    if (iframe) {
      const document = iframe.contentDocument;
      if (document) {
        document.open();
        document.write(html);
        document.close();
      }
    }
  }, [html]);
  return ref;
}

export function sanitizeSnapshotHTML(str: string) {
  let result = str.trim();
  result = result.substring(1, result.length - 1).trim();
  return result;
}
