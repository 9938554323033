import { RouteData } from "shared/config/routeData";
import { formatDate, formatDateFullMonthWithYear, listFormat } from "./format";
import { isRiskRejected } from "./riskRejected";
import type { TicketStatus } from "../../server/types/Ticket";

import type { Client, Policy } from "../types/Client";
import type { PhaseId } from "@prisma/client";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export const getUniquePolicyEffectiveDates = ({
  policies,
  format,
  removeRiskRejected,
}: {
  policies: {
    policyEffective: Date | null;
    ticketStatus: TicketStatus | null;
    phaseId: PhaseId | null;
  }[];
  format?: (d: Date) => string;
  removeRiskRejected?: boolean;
}) => {
  const uniquePolicyEffectiveDates = policies
    .filter((p) => !(removeRiskRejected && isRiskRejected(p)))
    .map((p) => p.policyEffective)
    .filter(
      (date, i, self): date is Date =>
        Boolean(date) && self.findIndex((d) => d?.getTime() === date?.getTime()) === i,
    )
    .sort((a, b) => a.getTime() - b.getTime());

  const policyEffectiveDates = uniquePolicyEffectiveDates.map((p) =>
    format ? format(p) : formatDate(p),
  );

  const formattedDates = listFormat(policyEffectiveDates);

  return { formattedDates, dates: uniquePolicyEffectiveDates };
};

export const getClientSlfCoveragesFromPolicies = (policies: Policy[]) =>
  policies
    .map((policy) => policy?.slfCoverages)
    .flat()
    .reduce((memo: SlfCoverageLongName[], coverage) => {
      if (coverage && !memo?.includes(coverage)) return memo.concat(coverage);
      return memo;
    }, []);

export const getClientSlfCoveragesReadyFromPolicies = (policies: Policy[]) =>
  policies
    .map((policy) => policy?.slfCoveragesReady)
    .flat()
    .reduce((memo: SlfCoverageLongName[], coverage) => {
      if (coverage && !memo?.includes(coverage)) return memo.concat(coverage);
      return memo;
    }, []);

export const getFormattedPolicyEffectiveFromPolicy = (policy: Policy | undefined) => {
  return policy?.policyEffective ? formatDateFullMonthWithYear(policy.policyEffective) : "";
};

export const getInfoForPolicy = (policy: Policy | undefined) => {
  if (!policy) return "";
  const policyEffective = getFormattedPolicyEffectiveFromPolicy(policy);
  const policyNumber = policy.slfPolicyNumber || "";
  return `${policyEffective}, policy #${policyNumber}`;
};

export const effectiveDateSiblings = (policy: Policy, client: Client) => {
  return client.policies.filter(
    (p) => p.id !== policy.id && p.policyEffective?.getTime() === policy.policyEffective?.getTime(),
  );
};

// Only creating one Census task per Policy Effective Date
const checkIsIgnoredCensusOnRepeatedEffectiveDate = (
  siblingEffectiveDatePolicies: Policy[],
  policy: Policy,
) => {
  // if this is primary ticket, this will be the one
  if (policy.primaryPolicy) return false;
  // if this is not primary but we have one sibling that is
  if (siblingEffectiveDatePolicies.find((p) => p.primaryPolicy)) return true;

  // If none of the polcies is the primary, choose one by ticketId
  const policiesByTicketId = siblingEffectiveDatePolicies.map((p) => p.ticketId);
  const sortedPolicies = [policy.ticketId, ...policiesByTicketId].sort();
  if (sortedPolicies[0] !== policy.ticketId) return true;
  return false;
};

export const isIgnoredCensusOnRepeatedEffectiveDate = (policy: Policy, client: Client): boolean => {
  const siblingEffectiveDatePolicies = effectiveDateSiblings(policy, client);
  if (!siblingEffectiveDatePolicies.length) return false;
  return checkIsIgnoredCensusOnRepeatedEffectiveDate(siblingEffectiveDatePolicies, policy);
};

export const isMainPolicyOnRepeatedEffectiveDate = (policy: Policy, client: Client) => {
  const siblingEffectiveDatePolicies = effectiveDateSiblings(policy, client);
  if (!siblingEffectiveDatePolicies.length) return false;
  return !checkIsIgnoredCensusOnRepeatedEffectiveDate(siblingEffectiveDatePolicies, policy);
};

type PolicyToSort = Pick<
  Policy,
  "primaryPolicy" | "policyEffective" | "ticketId" | "slfPolicyNumber"
>;

/**
 * Primary policie(s) will always be at the top,
 * then it'll be ordered by earliest effective date.
 * If the effective dates are the same, we sort by policy number.
 * Finally, if nothing else, sort alphabetically by ticketId.
 *
 * @param a
 * @param b
 * @returns
 */
export function policySorter(a: PolicyToSort, b: PolicyToSort) {
  if (a.primaryPolicy && !b.primaryPolicy) return -1;
  if (!a.primaryPolicy && b.primaryPolicy) return 1;

  if (a.policyEffective && b.policyEffective) {
    if (a.policyEffective.getTime() !== b.policyEffective.getTime()) {
      return a.policyEffective.getTime() - b.policyEffective.getTime();
    }
  }

  if (a.slfPolicyNumber && b.slfPolicyNumber) {
    if (a.slfPolicyNumber !== b.slfPolicyNumber) {
      return a.slfPolicyNumber.localeCompare(b.slfPolicyNumber);
    }
  }

  return a.ticketId.localeCompare(b.ticketId);
}

export function getPreviousAndNextPolicy(client: Client, policy: Policy) {
  const currentPolicyIndex = client.policies.findIndex((p) => p.id === policy.id);
  const previousPolicy =
    currentPolicyIndex != null && currentPolicyIndex !== -1
      ? client.policies[currentPolicyIndex - 1]
      : null;
  const nextPolicy =
    currentPolicyIndex != null && currentPolicyIndex !== -1
      ? client.policies[currentPolicyIndex + 1]
      : null;

  return { previousPolicy, nextPolicy };
}

export function getNextURLToNavigateToAfterEIFStepPolicyUpdate(args: {
  client: Client;
  policy: Policy;
  eifStepId: EIFStepId;
  eifSubStepId: EIFSubStepId;
  nextSubStepLink: string | null;
  isMultiPolicyMode: boolean;
}) {
  const { client, policy, eifStepId, eifSubStepId, nextSubStepLink, isMultiPolicyMode } = args;

  const { nextPolicy } = getPreviousAndNextPolicy(client, policy);

  if (isMultiPolicyMode) {
    if (nextPolicy) {
      const nextPolicyUrl = RouteData.eifSubStepPolicyDetail.getPath(
        client.id,
        eifStepId,
        eifSubStepId,
        nextPolicy.id,
      );
      return nextPolicyUrl;
    } else {
      const subStepLink = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);
      return subStepLink;
    }
  } else {
    if (nextSubStepLink) {
      return nextSubStepLink;
    }
  }
}
