import { SlobSelect } from "../../components/Form/SlobSelect";

import * as styles from "./ExplorerPageForm/explorerPageForm.module.less";

import type { BenefitPlanSummaryDocument } from "../../../../shared/types/ExplorerPageBenefit";
import type { LabeledValue } from "antd/lib/select";

interface DoucmentDropdownProps {
  planSummaryDocuments: BenefitPlanSummaryDocument[];
  selectedPlanSummaryDocumentId?: string | null;
  onChange: (option?: LabeledValue) => Promise<void>;
}
export const PlanSummaryDocumentDropdown = (props: DoucmentDropdownProps) => {
  const { planSummaryDocuments, selectedPlanSummaryDocumentId, onChange } = props;

  const initialValue = { label: "", value: "" };

  const documentDropdownOptions = planSummaryDocuments.map(documentToDropdownOption);

  return (
    <SlobSelect
      name="planSummaryDocument"
      value={selectedPlanSummaryDocumentId}
      options={documentDropdownOptions}
      onChange={onChange}
      placeholder="Select a document"
      className={styles.planSummaryContainer}
      allowClear={true}
      onClear={() => onChange(initialValue)}
      touched={undefined}
      error={undefined}
    />
  );
};

export const documentToDropdownOption = ({
  name,
  id,
}: BenefitPlanSummaryDocument): LabeledValue => ({
  label: name,
  value: id,
});
