import { DatePicker } from "antd";
import clsx from "clsx";

import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type moment from "moment";
import type { RangePickerProps } from "rc-picker/lib/RangePicker";

const { RangePicker } = DatePicker;

type DateRangeProps = {
  errorValue?: string;
  topText?: string;
  bottomText?: string;
} & Pick<
  RangePickerProps<moment.Moment>,
  "name" | "value" | "onChange" | "disabled" | "disabledDate" | "placeholder"
>;

export const DateRange = ({
  name,
  value,
  errorValue,
  onChange,
  disabled = false,
  disabledDate,
  topText,
  bottomText,
  placeholder,
}: DateRangeProps) => {
  const shouldShowError = errorValue;

  const inputClass = shouldShowError ? styles.inputError : "";

  const format = "MM/DD/YYYY";

  const props = {
    id: name,
    name,
    value,
    disabled,
    disabledDate,
    className: inputClass,
    format,
    onChange,
    placeholder: placeholder,
  };

  return (
    <div className={clsx(styles.dateRangeContainer, shouldShowError && styles.dateRangeError)}>
      {topText && (
        <p id={`info-top-${name}`} className={clsx("body2", styles.topText)}>
          {topText}
        </p>
      )}
      <RangePicker {...props} />
      {shouldShowError && <InputErrorMessage id={`error-${name}`} error={errorValue} />}
      {bottomText && (
        <span id={`info-bottom-${name}`} className={clsx("body5", styles.bottomText)}>
          {bottomText}
        </span>
      )}
    </div>
  );
};
