import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";

type ConfirmBenAdminToBrokerModalProps = {
  clientName: string;
  isVisible: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};
export function ConfirmBenAdminToBrokerModal(props: ConfirmBenAdminToBrokerModalProps) {
  return (
    <ConfirmDialog
      title={"Change user's role from Benefits Administrator to Broker"}
      isVisible={props.isVisible}
      isLoading={props.isLoading || false}
      confirmActionText={"Continue"}
      cancelActionText={"Cancel"}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    >
      <>
        <p>
          Are you sure you want to change this user's role?
          {props.clientName && (
            <> They are currently a Benefits Administrator associated with {props.clientName}.</>
          )}
        </p>
        <p>If you continue and this Benefits Administrator previously activated their account:</p>
        <UnorderedList>
          <UnorderedListItem>Their credentials will remain the same.</UnorderedListItem>
          <UnorderedListItem>
            They will not need to go through the account setup or accept Terms of Service again.
          </UnorderedListItem>
          <UnorderedListItem>
            When they log in, they will have broker access to the Client they were originally
            associated with.
          </UnorderedListItem>
        </UnorderedList>
        <p>If you continue and this Benefits Administrator has not yet activated their account:</p>
        <UnorderedList>
          <UnorderedListItem>
            You should send out a new welcome email as part of the account setup.
          </UnorderedListItem>
          <UnorderedListItem>
            They will use this to activate their account as a broker in Onboard.
          </UnorderedListItem>
          <UnorderedListItem>
            When they log in, they will have broker access to the Client they were originally
            associated with.
          </UnorderedListItem>
        </UnorderedList>
      </>
    </ConfirmDialog>
  );
}
