import * as renameItemStyles from "client/src/components/RenameItem/RenameItem.module.less";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { ResourcesTable } from "../../../components/SlobTable/ResourcesTable";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3 } from "../../../components/Typography/Typography";
import type { ComponentProps } from "react";

type UploadFileTablePresentationProps<RecordType extends Record<string, unknown>> = {
  dataSource: NonNullable<ComponentProps<typeof ResourcesTable<RecordType>>["dataSource"]>;
  columns: ComponentProps<typeof ResourcesTable<RecordType>>["columns"];
  errorExists: boolean;
  hasEmbeddedScripts: boolean;
};

export function UploadFileTablePresentation<RecordType extends Record<string, unknown>>(
  props: UploadFileTablePresentationProps<RecordType>,
) {
  const { dataSource, columns, errorExists, hasEmbeddedScripts } = props;

  return (
    <>
      {dataSource.length > 0 && (
        <StackY dist={40} wrap={false}>
          <StackY dist={16}>
            {!errorExists && (
              <AlertBanner variant="success" message={<Body3>Ready to upload</Body3>} />
            )}
            <ResourcesTable
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              rowClassName={renameItemStyles.renameButton_hoverableContainer}
            />
          </StackY>

          {hasEmbeddedScripts && (
            <Body3 as="div" redMedium>
              Updloaded file has embedded scripts
            </Body3>
          )}

          {!hasEmbeddedScripts && errorExists && (
            <Body3 as="div" redMedium>
              Please correct the errors above
            </Body3>
          )}
        </StackY>
      )}
    </>
  );
}
