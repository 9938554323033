import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { TagList } from "client/src/components/TagList/TagList";
import { BenefitsInThisPolicy } from "client/src/domain/Client/BenefitsInThisPolicy";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { hasFormikError } from "client/src/utils/hasFormikError";
import { getIn } from "formik";
import { useState } from "react";
import { LocationStateData } from "shared/data/LocationState";
import {
  sunLifeAccessMap,
  type Contact,
  type ContactType,
  contactSunLifeAccesses,
} from "shared/types/Contact";
import { LocationStateCodes } from "shared/types/Location";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import {
  maskPhoneNumber,
  contactWithAddressValidationSchema,
  locationManagementTypeValidation,
} from "shared/validation/contact";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import * as Yup from "yup";
import { BulbMessage } from "../../components/BulbMessage/BulbMessage";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import { Checkbox } from "../../components/Form/Checkbox";
import { FormInput } from "../../components/Form/Input";
import { InputErrorMessage } from "../../components/Form/InputErrorMessage";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { SlobSelectMultiple } from "../../components/MultiSelect/SlobSelectMultiple";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Eyebrow } from "../../components/Typography/Typography";
import { useCreateContact, useUpdateContact } from "../../hooks/contact";
import { getFormikErrors, useSlobFormik } from "../../hooks/useSlobFormik";
import { LocationDropdown } from "../Location/LocationDropdown";
import { LocationModal } from "../Location/LocationModal";
import { getLocationMultiSelectOptionsAndValue } from "../Location/getLocationMultiSelectProps";
import type { RadioGroupProps } from "../../components/Form/RadioGroup";
import type { LocationManagementType } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, ClientId } from "shared/types/Client";
import type { ContactSunLifeAccess } from "shared/types/Contact";
import type { Location, LocationId } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const primaryPlanAdminFormValidationSchema = contactWithAddressValidationSchema
  .pick([
    "id",
    "type",
    "firstName",
    "lastName",
    "email",
    "title",
    "phoneNumber",
    "billingAccess",
    "claimsAccess",
    "paymentsAccess",
    "documentsAccess",
    "memberChangesAccess",
    "accessesForPolicyIds",
  ])
  .concat(
    Yup.object({
      locationId: Yup.string()
        .nullable()
        .optional()
        .when(["$showLocationDropdown", "$prefill"], {
          is: (showLocationDropdown: boolean, prefill: boolean | undefined) => {
            assertIsDefined(showLocationDropdown, "showLocationDropdown");
            return showLocationDropdown && !prefill;
          },
          then: (schema) => schema.required("Please provide a response"),
        }),
      location: locationInputValidation
        .optional()
        .default(undefined)
        .when(["locationId"], {
          is: (locationId: string | undefined) => !locationId,
          then: (schema) => schema.required("Please provide location information"),
          otherwise: (schema) => schema.transform(() => undefined),
        })
        .when(["$prefill"], {
          is: (prefill: boolean | undefined) => prefill,
          then: (schema) => schema.notRequired(),
        }),
      locationManagementType: locationManagementTypeValidation
        .default(undefined)
        .when(["type", "$prefill"], {
          is: (type: ContactType | undefined, prefill: boolean | undefined) =>
            type === "WEB_ADMIN" && !prefill,
          then: (schema) => schema.required("Please provide a response"),
        }),
      managedLocationIds: Yup.array()
        .of(Yup.string().required())
        .when("locationManagementType", {
          is: "LIMITED",
          then: (schema) =>
            schema.required("Please provide a response").min(1, "Please provide a response"),
        }),
    }),
  );

const planAdminFormFields = [
  "firstName",
  "lastName",
  "email",
  "title",
  "phoneNumber",
  "location",
  "billingAccess",
  "claimsAccess",
  "paymentsAccess",
  "documentsAccess",
  "memberChangesAccess",
  "locationManagementType",
  "locationId",
] as const;

type PlanAdminFormProps = {
  clientId: ClientId;
  contact?: Contact;
  newAdminType: Extract<ContactType, "WEB_ADMIN" | "PRIMARY_WEB_ADMIN">;
  locations: Location[];
  changeSnapshot: DEIFChangeSnapshot;
  onCancel: () => void;
  onUpdated?: () => void;
  onCreated?: () => void;
  allowCancel?: boolean;
  bills: Bill[];
  client: Client;
  authUser: UserData | null;
  readOnlyLocationId?: LocationId;
  featureToggles: ClientFeatureToggles;
};

export const usePrimaryPlanAdminFormState = ({
  clientId,
  contact,
  newAdminType,
  locations = [],
  changeSnapshot,
  onCancel,
  onUpdated,
  onCreated,
  allowCancel = false,
  bills,
  client,
  authUser,
  readOnlyLocationId,
  featureToggles,
}: PlanAdminFormProps) => {
  const isEditing = !!contact;

  const { mutateAsync: createContact } = useCreateContact();
  const { mutateAsync: updateContact } = useUpdateContact();

  const contactLocationIsComplete =
    getValidationErrors(locationInputValidation, contact?.location ?? {}, {
      prefill: false,
    }).length === 0;

  const adminType = contact?.type ?? newAdminType;

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const getInitialAccessOption = (
    field: ContactSunLifeAccess,
    type: ContactType,
    contact: Contact | undefined,
  ) => {
    if (type === "PRIMARY_WEB_ADMIN") {
      return true;
    }

    return contact ? contact[field] ?? undefined : undefined;
  };

  // If there are already locations we want to show the drop down, unless the location for that contact
  // is not yet complete, then continue to show the location form
  const showLocationDropdown =
    locations.length &&
    (!(contact?.location && !contactLocationIsComplete) || adminType === "WEB_ADMIN");

  const formik = useSlobFormik({
    validationSchema: primaryPlanAdminFormValidationSchema,
    validationContext: {
      bills,
      prefill: true,
      showLocationDropdown,
    },
    initialValues: {
      id: contact?.id ?? "",
      type: adminType,
      firstName: contact?.firstName ?? "",
      lastName: contact?.lastName ?? "",
      email: contact?.email ?? "",
      title: contact?.title ?? "",
      phoneNumber: contact?.phoneNumber ?? "",
      locationId:
        contactLocationIsComplete || adminType === "WEB_ADMIN" ? contact?.location?.id : undefined,
      location: showLocationDropdown
        ? undefined
        : {
            id: contact?.location?.id,
            name: contact?.location?.name ?? "",
            address1: contact?.location?.address1 ?? "",
            address2: contact?.location?.address2 ?? null,
            city: contact?.location?.city ?? "",
            state: contact?.location?.state ?? null,
            zipCode: contact?.location?.zipCode ?? "",
            locationType: null,
          },
      billingAccess: getInitialAccessOption("billingAccess", adminType, contact),
      claimsAccess: getInitialAccessOption("claimsAccess", adminType, contact),
      documentsAccess: getInitialAccessOption("documentsAccess", adminType, contact),
      memberChangesAccess: getInitialAccessOption("memberChangesAccess", adminType, contact),
      paymentsAccess: getInitialAccessOption("paymentsAccess", adminType, contact),
      accessesForPolicyIds:
        adminType === "PRIMARY_WEB_ADMIN" || !isMultiPolicyMode
          ? client.policies.map((policy) => policy.id)
          : contact?.accessesForPolicyIds ?? [],
      locationManagementType:
        adminType === "PRIMARY_WEB_ADMIN" ? "ALL" : contact?.locationManagementType ?? undefined,
      managedLocationIds: contact?.managedLocations?.map((l) => l.id),
    },
    onSubmit: async (values) => {
      const data = { ...values, policyId: null };
      if (contact) {
        await updateContact({
          data,
          params: { clientId, contactId: contact.id },
        });
        onUpdated?.();
      } else {
        await createContact({
          data,
          params: { clientId },
        });
        onCreated?.();
      }
    },
  });

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [editingLocation, setEditingLocation] = useState<Location | undefined>(undefined);

  const haveEverSavedForm = !!contact?.type;

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, primaryPlanAdminFormValidationSchema, {
        prefill: false,
        bills,
        showLocationDropdown,
      })
    : {};

  return {
    formik,
    state: {
      isEditing,
      showLocationModal,
      allowCancel,
      editingLocation,
      showBrokerMessage:
        contact?.type === "PRIMARY_WEB_ADMIN" || newAdminType === "PRIMARY_WEB_ADMIN",
    },
    data: {
      locations,
      clientId,
      contactId: contact?.id,
      changeSnapshot,
      readOnlyLocationId,
      contact,
      featureToggles,
    },
    actions: {
      onCancel,
      onSaveLocation: (location: Location) => {
        if (editingLocation) {
          setEditingLocation(undefined);
          setShowLocationModal(false);
          return;
        }
        setShowLocationModal(false);
        void formik.setFieldValue("locationId", location.id);
      },
      onCancelLocation: () => {
        setEditingLocation(undefined);
        setShowLocationModal(false);
      },
      onCreateLocation: () => setShowLocationModal(true),
      onEditLocation: (location: Location) => {
        setEditingLocation(location);
        setShowLocationModal(true);
      },
    },
    authUser,
    client,
    strictErrors,
    showLocationDropdown,
  };
};

const getLocationManagementTypeOptions = (): RadioGroupProps<LocationManagementType>["options"] => [
  {
    value: "ALL",
    label: "All locations",
  },
  {
    value: "LIMITED",
    label: "Specific locations",
  },
];

export const PlanAdminForm = (...args: Parameters<typeof usePrimaryPlanAdminFormState>) => {
  const state = usePrimaryPlanAdminFormState(...args);

  const {
    formik,
    state: { showLocationModal, allowCancel, editingLocation, showBrokerMessage },
    data: { locations, clientId, contactId, changeSnapshot, readOnlyLocationId },
    actions: { onCancel, onSaveLocation, onCreateLocation, onCancelLocation, onEditLocation },
    client,
    authUser,
    strictErrors,
    showLocationDropdown,
  } = state;

  const changeLogContactId = contactId ?? "";

  const contactLocationChanges = Object.entries(changeSnapshot.ContactLocation ?? {})
    .filter(([contactLocationId]) => {
      // we store the ContactLocation ID as {contactId}_{locationId}
      const [contactId, locationId] = contactLocationId.split("_");
      return contactId === changeLogContactId && locationId;
    })
    .flatMap(([_, changes]) => changes);

  const accessesErrorId =
    hasFormikError(formik, "claimsAccess") ||
    hasFormikError(formik, "billingAccess") ||
    hasFormikError(formik, "paymentsAccess") ||
    hasFormikError(formik, "documentsAccess") ||
    hasFormikError(formik, "memberChangesAccess") ||
    strictErrors.claimsAccess ||
    strictErrors.billingAccess ||
    strictErrors.paymentsAccess ||
    strictErrors.documentsAccess ||
    strictErrors.memberChangesAccess
      ? "access_errormessage"
      : undefined;

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const disableSaveButton = isMultiPolicyMode
    ? planAdminFormFields.every((field) => !formik.values[field]) &&
      !formik.values.accessesForPolicyIds.length
    : planAdminFormFields.every((field) => !formik.values[field]);

  const accessesForPolicyIds_errorId =
    hasFormikError(formik, "accessesForPolicyIds") || strictErrors.accessesForPolicyIds
      ? "accessesForPolicyIds_errormessage"
      : undefined;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Body2 as="p">Contact information</Body2>
        {showBrokerMessage && (
          <div className="mb-24">
            <BulbMessage>
              Note: Broker contacts should not be listed here but can be added separately as Sun
              Life Connect users.
            </BulbMessage>
          </div>
        )}
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <StackY dist={8}>
              <FormInput
                label="First name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={191}
                touched={formik.touched.firstName || !!strictErrors.firstName}
                error={formik.errors.firstName || strictErrors.firstName}
                showRequired={true}
                disabled={formik.isSubmitting}
              />
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Contact[changeLogContactId]?.firstName ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "firstName",
                  client,
                  formik,
                })}
              />
            </StackY>
          </Col>
          <Col span={12}>
            <StackY dist={8}>
              <FormInput
                label="Last name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={191}
                touched={formik.touched.lastName || !!strictErrors.lastName}
                error={formik.errors.lastName || strictErrors.lastName}
                showRequired={true}
                disabled={formik.isSubmitting}
              />
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Contact[changeLogContactId]?.lastName ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "lastName",
                  client,
                  formik,
                })}
              />
            </StackY>
          </Col>
          <Col span={12}>
            <StackY dist={8}>
              <FormInput
                label="Email address"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={191}
                touched={formik.touched.email || !!strictErrors.email}
                error={formik.errors.email || strictErrors.email}
                showRequired={true}
                disabled={formik.isSubmitting}
              />
              <EditedFieldMsg
                changeDetailInfoList={[changeSnapshot.Contact[changeLogContactId]?.email ?? null]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "email",
                  client,
                  formik,
                })}
              />
            </StackY>
          </Col>
          <Col span={12}>
            <StackY dist={8}>
              <FormInput
                label="Phone number"
                name="phoneNumber"
                value={formik.values.phoneNumber ?? ""}
                onChange={async (e) => {
                  const { value } = e.currentTarget;
                  const maskedPhoneNumber = maskPhoneNumber(value);
                  await formik.setFieldValue("phoneNumber", maskedPhoneNumber || null);
                }}
                onBlur={formik.handleBlur}
                maxLength={12}
                touched={formik.touched.phoneNumber || !!strictErrors.phoneNumber}
                error={formik.errors.phoneNumber || strictErrors.phoneNumber}
                showRequired={true}
                disabled={formik.isSubmitting}
              />
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Contact[changeLogContactId]?.phoneNumber ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "phoneNumber",
                  client,
                  formik,
                })}
              />
            </StackY>
          </Col>
          <Col span={12}>
            <StackY dist={8}>
              <FormInput
                label="Job title"
                name="title"
                value={formik.values.title ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                maxLength={191}
                touched={Boolean(formik.touched.title) || !!strictErrors.title}
                error={formik.errors.title || strictErrors.title}
                showRequired={true}
                disabled={formik.isSubmitting}
              />
              <EditedFieldMsg
                changeDetailInfoList={[changeSnapshot.Contact[changeLogContactId]?.title ?? null]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "title",
                  client,
                  formik,
                })}
              />
            </StackY>
          </Col>
          <Col span={12}></Col>
          <Col span={12}></Col>
          <Col span={12}></Col>
        </Row>

        <Body2 as="p">Location</Body2>
        <Row gutter={[24, 24]}>
          {showLocationDropdown ? (
            <Col span={24} data-testid="locations-dropdown-container">
              <StackY dist={8}>
                <LocationDropdown
                  formik={formik}
                  prefillErrors={strictErrors}
                  name="locationId"
                  clientId={clientId}
                  locations={locations}
                  readOnlyLocationId={readOnlyLocationId}
                />
                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Contact[changeLogContactId]?.locationId]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "locationId",
                    client,
                    formik,
                  })}
                />
              </StackY>
            </Col>
          ) : (
            <>
              <Col span={12} data-testid="location-form-container">
                <FormInput
                  label="Name of location"
                  name="location.name"
                  value={formik.values.location?.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={191}
                  touched={
                    getIn(formik.touched, "location.name") || getIn(strictErrors, "location.name")
                  }
                  error={
                    getIn(formik.errors, "location.name") || getIn(strictErrors, "location.name")
                  }
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Address 1"
                  name="location.address1"
                  value={formik.values.location?.address1 ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={191}
                  touched={
                    getIn(formik.touched, "location.address1") ||
                    getIn(strictErrors, "location.address1")
                  }
                  error={
                    getIn(formik.errors, "location.address1") ||
                    getIn(strictErrors, "location.address1")
                  }
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
              </Col>
              <Col span={24}>
                <FormInput
                  label="Address 2 (optional)"
                  name="location.address2"
                  value={formik.values.location?.address2 ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={191}
                  touched={
                    getIn(formik.touched, "location.address2") ||
                    getIn(strictErrors, "location.address2")
                  }
                  error={
                    getIn(formik.errors, "location.address2") ||
                    getIn(strictErrors, "location.address2")
                  }
                  disabled={formik.isSubmitting}
                />
              </Col>
              <Col span={12}>
                <FormInput
                  label="City"
                  name="location.city"
                  value={formik.values.location?.city ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={191}
                  touched={
                    getIn(formik.touched, "location.city") || getIn(strictErrors, "location.city")
                  }
                  error={
                    getIn(formik.errors, "location.city") || getIn(strictErrors, "location.city")
                  }
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
              </Col>
              <Col span={8}>
                <SlobSelect
                  name="location.state"
                  showRequired={true}
                  value={formik.values.location?.state || null}
                  options={LocationStateCodes.map((stateCode) => ({
                    label: LocationStateData[stateCode].displayName,
                    value: stateCode,
                  }))}
                  placeholder="State"
                  onChange={(val) => {
                    void formik.setFieldValue("location.state", val.value);
                  }}
                  error={
                    getIn(formik.errors, "location.state") || getIn(strictErrors, "location.state")
                  }
                  touched={
                    getIn(formik.touched, "location.state") || getIn(strictErrors, "location.state")
                  }
                  disabled={formik.isSubmitting}
                  onBlur={formik.handleBlur}
                />
              </Col>
              <Col span={4}>
                <FormInput
                  label="Zip"
                  name="location.zipCode"
                  value={formik.values.location?.zipCode ?? ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={191}
                  touched={
                    getIn(formik.touched, "location.zipCode") ||
                    getIn(strictErrors, "location.zipCode")
                  }
                  error={
                    getIn(formik.errors, "location.zipCode") ||
                    getIn(strictErrors, "location.zipCode")
                  }
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
              </Col>
            </>
          )}
        </Row>

        {formik.values.type === "WEB_ADMIN" && (
          <>
            <div className="mt-24" data-testid="access-options-container">
              <Body2 as="p">Access options</Body2>

              {isMultiPolicyMode ? (
                <>
                  <StackY dist={20} wrap={false}>
                    <p>
                      Choose the policies this Sun Life Connect user will have access to. Please
                      select all that apply
                    </p>

                    <div>
                      <StackY dist={4} wrap={false}>
                        {client.policies.map((policy) => {
                          return (
                            <Checkbox
                              key={policy.id}
                              name="accessesForPolicyIds"
                              value={policy.id}
                              checked={formik.values.accessesForPolicyIds.includes(policy.id)}
                              onChange={formik.handleChange}
                              errorId={accessesForPolicyIds_errorId}
                              disabled={formik.isSubmitting}
                              label={
                                policy.primaryPolicy
                                  ? `Policy #${policy.slfPolicyNumber} (Primary)`
                                  : `Policy #${policy.slfPolicyNumber}`
                              }
                              content={<BenefitsInThisPolicy policy={policy} />}
                            />
                          );
                        })}
                        <EditedFieldMsg
                          changeDetailInfoList={[
                            changeSnapshot.Contact[changeLogContactId]?.accessesForPolicyIds,
                          ]}
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "accessesForPolicyIds",
                            client,
                            formik,
                          })}
                        />
                      </StackY>

                      <div aria-live="assertive" className="hide:empty">
                        {accessesForPolicyIds_errorId && (
                          <InputErrorMessage
                            id={accessesForPolicyIds_errorId}
                            error={
                              formik.errors.accessesForPolicyIds
                                ? Array.isArray(formik.errors.accessesForPolicyIds)
                                  ? formik.errors.accessesForPolicyIds[0]
                                  : formik.errors.accessesForPolicyIds
                                : Array.isArray(strictErrors.accessesForPolicyIds)
                                ? strictErrors.accessesForPolicyIds[0]
                                : strictErrors.accessesForPolicyIds
                            }
                          />
                        )}
                      </div>
                    </div>

                    <p>
                      Choose which accesses this user will have for the selected policies. Please
                      select all that apply.
                    </p>

                    <TagList
                      name="accesses"
                      placeholder="Select accesses"
                      touched={
                        contactSunLifeAccesses.some((access) => formik.touched[access]) ||
                        contactSunLifeAccesses.some((access) => strictErrors[access])
                      }
                      error={contactSunLifeAccesses
                        .map((access) => [formik.errors[access], strictErrors[access]])
                        .flat()
                        .find(Boolean)}
                      value={contactSunLifeAccesses.filter(
                        (access) => formik.values[access] ?? false,
                      )}
                      disabled={formik.isSubmitting}
                      options={Array.from(sunLifeAccessMap.entries()).map(([value, label]) => ({
                        value,
                        label,
                      }))}
                      onChange={async (event) => {
                        const { value } = event.target;
                        const promises = contactSunLifeAccesses.map((access) =>
                          formik.setFieldValue(access, value.includes(access)),
                        );
                        await Promise.all(promises);
                      }}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={contactSunLifeAccesses.map(
                        (access) => changeSnapshot.Contact[changeLogContactId]?.[access],
                      )}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={contactSunLifeAccesses.some((access) =>
                        getHasPendingEdit({
                          field: access,
                          client,
                          formik,
                        }),
                      )}
                    />
                  </StackY>
                </>
              ) : (
                <>
                  <Body3 as="p">
                    Choose the account options this user will access in Sun Life Connect. Please
                    select all that apply.
                  </Body3>
                  <StackY dist={8}>
                    <Checkbox
                      checked={formik.values.memberChangesAccess ?? false}
                      onChange={formik.handleChange}
                      label={
                        <>
                          <Body3 as="div">Manage employees</Body3>
                          <Eyebrow>
                            Only available if you are managing employee enrollments directly with
                            Sun Life
                          </Eyebrow>
                        </>
                      }
                      name={"memberChangesAccess"}
                      disabled={formik.isSubmitting}
                      errorId={accessesErrorId}
                      onBlur={formik.handleBlur}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Contact[changeLogContactId]?.memberChangesAccess ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "memberChangesAccess",
                        client,
                        formik,
                      })}
                    />
                    <Checkbox
                      checked={formik.values.billingAccess ?? false}
                      onChange={formik.handleChange}
                      label={"Bills"}
                      name={"billingAccess"}
                      disabled={formik.isSubmitting}
                      errorId={accessesErrorId}
                      onBlur={formik.handleBlur}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Contact[changeLogContactId]?.billingAccess ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "billingAccess",
                        client,
                        formik,
                      })}
                    />
                    <Checkbox
                      checked={formik.values.paymentsAccess ?? false}
                      onChange={formik.handleChange}
                      label={"Payments"}
                      name={"paymentsAccess"}
                      disabled={formik.isSubmitting}
                      errorId={accessesErrorId}
                      onBlur={formik.handleBlur}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Contact[changeLogContactId]?.paymentsAccess ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "paymentsAccess",
                        client,
                        formik,
                      })}
                    />
                    <Checkbox
                      checked={formik.values.claimsAccess ?? false}
                      onChange={formik.handleChange}
                      label={"Claims"}
                      name={"claimsAccess"}
                      disabled={formik.isSubmitting}
                      errorId={accessesErrorId}
                      onBlur={formik.handleBlur}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Contact[changeLogContactId]?.claimsAccess ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "claimsAccess",
                        client,
                        formik,
                      })}
                    />
                    <Checkbox
                      checked={formik.values.documentsAccess ?? false}
                      onChange={formik.handleChange}
                      label={"Policy documents"}
                      name={"documentsAccess"}
                      disabled={formik.isSubmitting}
                      errorId={accessesErrorId}
                      onBlur={formik.handleBlur}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Contact[changeLogContactId]?.documentsAccess ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "documentsAccess",
                        client,
                        formik,
                      })}
                    />
                    <div aria-live="assertive" className="hide:empty">
                      {accessesErrorId && (
                        <InputErrorMessage
                          id={accessesErrorId}
                          error={
                            formik.errors.billingAccess ||
                            strictErrors.billingAccess ||
                            formik.errors.claimsAccess ||
                            formik.errors.paymentsAccess ||
                            formik.errors.documentsAccess ||
                            formik.errors.memberChangesAccess ||
                            strictErrors.claimsAccess ||
                            strictErrors.paymentsAccess ||
                            strictErrors.documentsAccess ||
                            strictErrors.memberChangesAccess
                          }
                        />
                      )}
                    </div>
                  </StackY>
                </>
              )}
            </div>

            <StackY dist={16} className="mt-32" data-testid="location-management-type-container">
              <RadioGroup
                direction="vertical"
                name={"locationManagementType"}
                label={
                  <Body2 as="p">
                    Which locations should this user be able to manage online in Sun Life Connect?
                  </Body2>
                }
                value={formik.values.locationManagementType}
                onChange={formik.handleChange}
                disabled={formik.isSubmitting}
                touched={
                  formik.touched.locationManagementType || !!strictErrors.locationManagementType
                }
                error={formik.errors.locationManagementType || strictErrors.locationManagementType}
                options={getLocationManagementTypeOptions()}
              />
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Contact[changeLogContactId]?.locationManagementType ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "locationManagementType",
                  client,
                  formik,
                })}
              />
              {formik.values.locationManagementType === "LIMITED" && (
                <div>
                  <SlobSelectMultiple
                    aria-label={"Select locations"}
                    placeholder={"Select locations"}
                    name={"managedLocationIds"}
                    maxTagTextLength={50}
                    open={showLocationModal ? false : undefined}
                    touched={formik.touched.managedLocationIds}
                    error={formik.errors.managedLocationIds}
                    onBlur={formik.handleBlur}
                    disabled={formik.isSubmitting}
                    {...getLocationMultiSelectOptionsAndValue({
                      locations,
                      unassignedLocations: locations,
                      selectedLocationIds: formik.values.managedLocationIds,
                      onEditLocation,
                      readOnlyLocationId,
                    })}
                    onChange={(val) => {
                      if (val)
                        void formik.setFieldValue(
                          "managedLocationIds",
                          val.map((v) => v.value),
                        );
                    }}
                  />
                  {/* @todo: FP-3498: move to Link component with normal text margin */}
                  <ButtonOld type="link" size="middle" className="px-0" onClick={onCreateLocation}>
                    <FontAwesomeIcon icon={faPlusCircle} /> Add another location
                  </ButtonOld>
                  <EditedFieldMsg
                    changeDetailInfoList={contactLocationChanges
                      .filter(rejectNullableValues)
                      .filter((change) => change.contactManagesLocation)
                      .map(({ contactManagesLocation }) => contactManagesLocation)}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "managedLocationIds",
                      client,
                      formik,
                    })}
                  />
                </div>
              )}
            </StackY>
          </>
        )}

        {formik.status && (
          <div className="mt-12">
            <LoadingError type="component" title={formik.status} />
          </div>
        )}

        <Row justify="end" className="mt-32">
          <Col>
            <StackX dist={8}>
              {allowCancel && (
                <Button type="text" size="middle" disabled={formik.isSubmitting} onClick={onCancel}>
                  Cancel
                </Button>
              )}

              <Button
                type="secondary"
                htmlType="submit"
                size="middle"
                disabled={formik.isSubmitting || disableSaveButton}
                loading={formik.isSubmitting}
                aria-label={formik.isSubmitting ? "Save" : undefined}
              >
                {formik.isSubmitting ? "" : "Save"}
              </Button>
            </StackX>
          </Col>
        </Row>
      </form>
      <LocationModal
        visible={showLocationModal}
        clientId={clientId}
        onCancel={onCancelLocation}
        onSave={onSaveLocation}
        location={editingLocation}
      />

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
