import { InternalControl } from "client/src/components/InternalControl/InternalControl";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import type { ChangeDetailInfoList } from "client/src/domain/EIF/common/EditBanner";
import type { NavigateFunction } from "react-router-dom";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";

type InternalEditBannerProps = {
  "data-testid"?: string;
  navigateLink?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  changeDetailInfoList: ChangeDetailInfoList;
  navigate: NavigateFunction;
  authUser: UserData | null;
  client: Client;
  isAdd?: boolean;
};

export const EIFInternalEditBanner = ({
  "data-testid": dataTestId,
  navigateLink,
  onEdit,
  onDelete,
  changeDetailInfoList,
  navigate,
  authUser,
  client,
  isAdd,
}: InternalEditBannerProps) => {
  const onClickFunc = navigateLink ? () => navigate(navigateLink) : onEdit;

  if (client.deifChangesReadyForReviewAt) {
    return (
      <EditedFieldMsg
        changeDetailInfoList={changeDetailInfoList}
        withAlertBanner={true}
        authUser={authUser}
        client={client}
        isAdd={isAdd}
      />
    );
  }

  return (
    <InternalControl
      data-testid={dataTestId}
      position="detached"
      buttonLabel="Edit"
      buttonType="text-only"
      onClick={onClickFunc}
      {...(onDelete
        ? {
            secondaryButtonLabel: "Delete",
            secondaryOnClick: onDelete,
            secondaryDisabled: false,
          }
        : {})}
      extraContent={
        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          withAlertBanner={true}
          authUser={authUser}
          client={client}
          isAdd={isAdd}
        />
      }
    />
  );
};
