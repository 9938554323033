import { ImplementationTypeFilter } from "client/src/components/Filter/ImplementationTypeFilter";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { useEffect, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { ClientTypeFilter } from "../../../../components/Filter/ClientTypeFilter";
import { EBRFilter } from "../../../../components/Filter/EBRFilter";
import { ImplementationConsultantFilter } from "../../../../components/Filter/ImplementationConsultantFilter";
import { PhaseFilter } from "../../../../components/Filter/PhaseFilter";
import { Modal } from "../../../../components/Modal/Modal";
import { Body2 } from "../../../../components/Typography/Typography";
import * as styles from "./clientListFilterModal.module.less";
import type { ModalProps } from "../../../../components/Modal/Modal";
import type { SlobListFilterComponentProps } from "../../../../components/SlobList/SlobList";
import type { Dispatch, SetStateAction } from "react";
import type { ClientTableFilters } from "shared/types/Client";
import type { SlfImplementationConsultant } from "shared/types/User";

export type ClientListFilterModalProps = Pick<ModalProps, "open" | "onCancel"> &
  ClientListFilterModalContentProps;

export const ClientListFilterModal = (props: ClientListFilterModalProps) => {
  const { open, onCancel, ...contentProps } = props;
  return (
    <Modal title="Filters" footer={null} open={open} onCancel={onCancel} destroyOnClose>
      <ClientListFilterModalContent {...contentProps} />
    </Modal>
  );
};

type ClientListFilterModalContentProps = {
  implementationConsultants: SlfImplementationConsultant[];
  ebrEmails: string[];
  variant: "internal" | "broker";
} & SlobListFilterComponentProps<ClientTableFilters>;

const ClientListFilterModalContent = ({
  filters,
  onFiltersChanged,
  onFiltersReset,
  implementationConsultants,
  ebrEmails,
  variant,
}: ClientListFilterModalContentProps) => {
  const [pendingFilters, setPendingFilters] = usePendingFilters(filters ?? {});

  return (
    <StackY dist={24} className="w-full">
      {variant === "internal" && (
        <StackY dist={16}>
          <Body2>Implementation consultant</Body2>
          <div className={styles.formFieldWrapper}>
            <ImplementationConsultantFilter
              filters={pendingFilters}
              onFiltersChanged={(newFilters) =>
                setPendingFilters({ ...pendingFilters, ...newFilters })
              }
              implementationConsultants={implementationConsultants}
            />
          </div>
        </StackY>
      )}
      <PhaseFilter
        filters={pendingFilters}
        onFiltersChanged={(newFilters) => setPendingFilters({ ...pendingFilters, ...newFilters })}
        variant={variant}
      />

      {variant === "internal" && (
        <>
          <ClientTypeFilter
            filters={pendingFilters}
            onFiltersChanged={(newFilters) =>
              setPendingFilters({ ...pendingFilters, ...newFilters })
            }
          />

          <ImplementationTypeFilter
            filters={pendingFilters}
            onFiltersChanged={(newFilters) =>
              setPendingFilters({ ...pendingFilters, ...newFilters })
            }
          />

          <div className={styles.formFieldWrapper}>
            <EBRFilter
              ebrEmails={ebrEmails}
              filters={pendingFilters}
              onFiltersChanged={(newFilters) =>
                setPendingFilters({ ...pendingFilters, ...newFilters })
              }
            />
          </div>
        </>
      )}
      <Row justify="end" align="middle" gutter={24} className="w-full">
        <Col>
          <Button type="text" size="middle" onClick={() => onFiltersReset()}>
            Reset all
          </Button>
        </Col>
        <Col>
          <Button type="primary" size="middle" onClick={() => onFiltersChanged(pendingFilters)}>
            Apply filters
          </Button>
        </Col>
      </Row>
    </StackY>
  );
};

ClientListFilterModal.Content = ClientListFilterModalContent;

const usePendingFilters = (
  initialFilters: ClientTableFilters,
): [ClientTableFilters, Dispatch<SetStateAction<ClientTableFilters>>] => {
  const [pendingFilters, setPendingFilters] = useState(initialFilters);
  useEffect(() => {
    setPendingFilters(initialFilters);
  }, [initialFilters]);
  return [pendingFilters, setPendingFilters];
};
