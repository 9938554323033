import * as styles from "./hubCard.module.less";

import type { ReactElement, ReactFragment } from "react";

export const HubCardSection = ({
  title,
  content,
}: {
  title: string;
  content: ReactElement | ReactFragment;
}): JSX.Element => {
  return (
    <div className={styles.hubCardSection}>
      <h2>{title}</h2>
      {content}
    </div>
  );
};
