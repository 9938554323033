import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";

import { Button } from "client/src/components/Button/Button";
import { EmployeeEnrollmentResourcesCardLayout } from "client/src/components/EmployeeEnrollmentResourcesCard/EmployeeEnrollmentResourcesCardLayout";
import { ReadinessLabel } from "client/src/components/ReadinessLabel/ReadinessLabel";
import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { useCallback, useState } from "react";

import { assertIsDefined } from "shared/utils/utils";
import styles from "./employeeEnrollmentResourcesCard.module.less";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

function calculateItemsPerRow(element: HTMLDivElement | null) {
  const itemsInEachRow = [0];
  let prevChild: HTMLElement | null = null;

  if (element?.children) {
    [...element.children].forEach((child) => {
      if (child instanceof HTMLElement) {
        const currChild = child;
        if (prevChild && prevChild.offsetTop !== currChild.offsetTop) itemsInEachRow.push(0);
        const currLastValueInItems = itemsInEachRow[itemsInEachRow.length - 1];
        assertIsDefined(currLastValueInItems, "currLastValueInItems");
        itemsInEachRow[itemsInEachRow.length - 1] = currLastValueInItems + 1;
        prevChild = currChild;
      }
    });
  }

  return itemsInEachRow;
}

const useBenefitsOverflow = (benefits: SlfCoverageLongName[], numRows = 2) => {
  const [shownBenefits, setShownBenefits] = useState<SlfCoverageLongName[]>(
    // Order by character length ascending (shortest coverages shown first to prevent nothing being displayed in the overflow row)
    benefits.sort((a, b) => a.length - b.length),
  );
  const [hiddenBenefits, setHiddenBenefits] = useState<SlfCoverageLongName[]>([]);

  const refCallBack = useCallback(
    (element: HTMLDivElement | null) => {
      const itemsInEachRow = calculateItemsPerRow(element);

      if (itemsInEachRow.length > numRows) {
        // if overflow, remove overflow items + 1 (space for popover)
        const numItemsToHide =
          itemsInEachRow.slice(numRows).reduce((sum, numItems) => sum + numItems, 0) + 1;
        setShownBenefits(benefits.slice(0, -numItemsToHide));
        setHiddenBenefits(benefits.slice(-numItemsToHide));
      }
    },
    [benefits, numRows],
  );

  return { shownBenefits, hiddenBenefits, refCallBack };
};

const BenefitItem = ({
  benefit,
  seperator,
}: {
  benefit: SlfCoverageLongName;
  seperator: boolean;
}) => {
  return (
    <div className={styles.benefitItem}>
      <span className="mx-12">{benefit}</span>
      {seperator && <Divider type="vertical" className={styles.benefitItemDivider} />}
    </div>
  );
};

const BenefitOverflowList = ({
  benefits,
  numRows = 2,
}: {
  benefits: SlfCoverageLongName[];
  numRows?: number;
}) => {
  const { shownBenefits, hiddenBenefits, refCallBack } = useBenefitsOverflow(benefits, numRows);

  return (
    <div className={styles.benefitsList} ref={refCallBack}>
      {shownBenefits.map((b, i) => (
        <BenefitItem benefit={b} seperator={i < benefits.length - 1} key={i} />
      ))}
      {!!hiddenBenefits.length && (
        <div className="ml-12">
          <SlobPopover
            id={"more-benefits"}
            placement="bottom"
            trigger={["click"]}
            content={() => (
              <StackY dist={8}>
                {hiddenBenefits.map((b) => (
                  <Body3 key={b}>{b}</Body3>
                ))}
              </StackY>
            )}
            variant="secondary"
          >
            <Button type="text-only">
              {shownBenefits.length === 0
                ? `${hiddenBenefits.length} benefits`
                : `+${hiddenBenefits.length} more`}
            </Button>
          </SlobPopover>
        </div>
      )}
    </div>
  );
};

const BenefitMessageTooltip = () => (
  <StackX dist={8}>
    <Body5>What does this mean?</Body5>
    <Tooltip
      title={
        <StackY dist={20}>
          <Body5 white>Benefits will not be ready for claims if:</Body5>
          <UnorderedList color="white" markerColor="white" content="body5" tight>
            <UnorderedListItem>
              You haven't finished all the necessary tasks in Onboard
            </UnorderedListItem>
            <UnorderedListItem>
              Sun Life is still processing the information you've provided
            </UnorderedListItem>
          </UnorderedList>
          <Body5 white>
            For more information about the status of your benefits, please reach out to your
            Implementation Consultant.
          </Body5>
        </StackY>
      }
      placement="bottom"
      large
    >
      <button aria-label="info" className={`btn-reset ${styles.infoIcon}`}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    </Tooltip>
  </StackX>
);

interface Props {
  benefits: SlfCoverageLongName[];
  benefitsReady: SlfCoverageLongName[];
  isOnboardingComplete?: boolean;
}

export const EmployeeEnrollmentResourcesCard = ({
  benefits,
  benefitsReady,
  isOnboardingComplete,
}: Props) => {
  const benefitsNotReady = benefits.filter((benefit) => !benefitsReady.includes(benefit));
  const hasReady = !!benefitsReady.length;
  const hasNotReady = !!benefitsNotReady.length;

  const renderMessage = () => {
    // some benefits are claims ready
    if (hasReady && hasNotReady)
      return (
        <StackY dist={4}>
          <Body3>
            Nearly there - {benefitsReady.length} of your benefits are claims ready. As of the
            effective date, employees can use these benefits and submit claims.
          </Body3>
          <Body3>
            Sun Life is still working to set up {benefitsNotReady.length} of your benefits.
          </Body3>
          <BenefitMessageTooltip />
        </StackY>
      );
    // All Benefits are claims ready
    else if (hasReady || isOnboardingComplete)
      return (
        <Body3>
          All of your benefits are claims ready. As of the effective date, employees can use their
          benefits and submit claims.
        </Body3>
      );
    // No Benefits Claims Ready
    return (
      <StackY dist={4}>
        <div className="mb-4">
          <Body3>Sun Life is working with you to set up your benefits.</Body3>
        </div>

        <BenefitMessageTooltip />
      </StackY>
    );
  };

  return (
    <EmployeeEnrollmentResourcesCardLayout bgSrc="/enrollment-resources-banner-bg/enrollment-resources-banner-bg-v2.svg">
      <div className={styles.cardContent}>
        <div className={styles.cardSection}>
          <h3>Your benefits:</h3>
          {isOnboardingComplete ? (
            <>
              <ReadinessLabel ready={true} />
              <BenefitOverflowList benefits={benefits} numRows={hasNotReady ? 1 : 2} />
            </>
          ) : (
            <>
              {hasReady && (
                <>
                  <ReadinessLabel ready={true} />
                  <BenefitOverflowList benefits={benefitsReady} numRows={hasNotReady ? 1 : 2} />
                </>
              )}
              {hasNotReady && (
                <>
                  <ReadinessLabel ready={false} />
                  <BenefitOverflowList benefits={benefitsNotReady} numRows={hasReady ? 1 : 2} />
                </>
              )}
            </>
          )}
        </div>

        <Divider type="vertical" className={styles.divider} />

        <div className={`${styles.cardSection}`}>{renderMessage()}</div>
      </div>
    </EmployeeEnrollmentResourcesCardLayout>
  );
};
