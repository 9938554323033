import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Body5, Body3, Body2 } from "client/src/components/Typography/Typography";
import { getCoverageWithPolicyNumbersLabel } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getCoverageWithPolicyNumbersLabel";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getContactLocation } from "client/src/utils/getContactLocations";
import { getEIFSubStepViewMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { formatFullName } from "shared/utils/format";
import { assertIsDefined } from "shared/utils/utils";
import { contactWithAddressValidationSchema } from "shared/validation/contact";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import type { FormikErrors } from "formik";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { ValidationError } from "yup";

type Props = {
  "data-testid"?: string;
  disabled?: boolean;
  index: number | null;
  client: Client;
  plans: Plan[];
  contacts: Contact[];
  onEdit?: () => void;
  onDelete?: () => void;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  claimsCheckPrefillErrors: FormikErrors<
    Pick<
      Plan,
      | "sendClaimsCheckTo"
      | "claimsCheckPayee"
      | "planAdminPayeeContactId"
      | "someoneElsePayeeContactId"
    > &
      Pick<
        Contact,
        | "address1"
        | "address2"
        | "city"
        | "firstName"
        | "lastName"
        | "email"
        | "phoneNumber"
        | "state"
        | "zipCode"
      >
  >;
};

export function ClaimsLocationReviewCard(props: Props) {
  const {
    "data-testid": dataTestId,
    disabled,
    index,
    client,
    plans,
    contacts,
    onEdit,
    onDelete,
    claimsCheckPrefillErrors,
    changeSnapshot,
  } = props;

  const [plan] = plans;
  assertIsDefined(plan, "plan");

  const hasPrefillErrors =
    claimsCheckPrefillErrors && Object.keys(claimsCheckPrefillErrors).length > 0;

  const claimsCheckPayeeIsPlanAdmin =
    plan.sendClaimsCheckTo === "EMPLOYER" &&
    plan.claimsCheckPayee === "PLAN_ADMINISTRATOR" &&
    plan.planAdminPayeeContactId !== null;

  const claimsCheckPayeeIsSomeoneElse =
    plan.sendClaimsCheckTo === "EMPLOYER" &&
    plan.claimsCheckPayee === "SOMEONE_ELSE" &&
    plan.someoneElsePayeeContactId !== null;

  const claimsCheckPayeeContact = claimsCheckPayeeIsPlanAdmin
    ? contacts.find((contact) => contact.id === plan.planAdminPayeeContactId)
    : claimsCheckPayeeIsSomeoneElse
    ? contacts.find((contact) => contact.id === plan.someoneElsePayeeContactId)
    : null;

  const payeeFullName =
    claimsCheckPayeeContact?.firstName && claimsCheckPayeeContact?.lastName
      ? formatFullName(claimsCheckPayeeContact)
      : null;

  const { name, ...payeeContactLocationWithoutName } = claimsCheckPayeeContact?.location ?? {};

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsBecausePostSigningWithoutChanges = !getShowValidationErrorsInSummary(
    viewMode,
    changeSnapshot,
  );

  let locationPrefillErrors: false | ValidationError[] = false;

  if (!suppressErrorsBecausePostSigningWithoutChanges) {
    locationPrefillErrors =
      claimsCheckPayeeContact?.location !== null && claimsCheckPayeeIsPlanAdmin
        ? getValidationErrors(locationInputValidation, claimsCheckPayeeContact?.location, {
            prefill: false,
          })
        : claimsCheckPayeeContact?.location !== null && claimsCheckPayeeIsSomeoneElse
        ? getValidationErrors(
            locationInputValidation.omit(["name"]),
            payeeContactLocationWithoutName,
            {
              prefill: false,
            },
          )
        : claimsCheckPayeeContact !== null
        ? getValidationErrors(
            contactWithAddressValidationSchema.pick([
              "address1",
              "address2",
              "city",
              "state",
              "zipCode",
            ]),
            claimsCheckPayeeContact,
            {
              prefill: false,
            },
          )
        : false;
  }

  const relevantContactErrors =
    claimsCheckPayeeContact && getShowValidationErrorsInSummary(viewMode, changeSnapshot)
      ? getFormikErrors(
          claimsCheckPayeeContact,
          contactWithAddressValidationSchema.omit([
            "city",
            "state",
            "zipCode",
            "address1",
            "location",
            "claimsAccess",
            "billingAccess",
            "memberChangesAccess",
            "documentsAccess",
            "paymentsAccess",
          ]),
          { prefill: false },
        )
      : {};

  const hasNonLocationPrefillErrors =
    !claimsCheckPayeeContact?.firstName ||
    !claimsCheckPayeeContact?.lastName ||
    !claimsCheckPayeeContact?.email ||
    !claimsCheckPayeeContact?.title ||
    !claimsCheckPayeeContact?.phoneNumber;

  const hasLocationPrefillErrors = locationPrefillErrors && locationPrefillErrors.length > 0;

  const showPlanAdminInfoError =
    ((claimsCheckPayeeIsPlanAdmin && hasLocationPrefillErrors && claimsCheckPayeeContact) ||
      (claimsCheckPayeeIsPlanAdmin && hasNonLocationPrefillErrors && claimsCheckPayeeContact)) &&
    !suppressErrorsBecausePostSigningWithoutChanges;

  const WrappingComponent = index != null ? FlatCard : "div";

  return (
    <WrappingComponent data-testid={dataTestId} disabled={disabled}>
      <Row wrap={false} gutter={20}>
        {index != null && (
          <Col flex="1">
            <h5>Mailing Location {index}</h5>
          </Col>
        )}

        {onEdit && (
          <Col flex="0 1 auto">
            <Button type="text-only" size="middle" disabled={disabled} onClick={onEdit}>
              Edit
            </Button>
          </Col>
        )}

        {onDelete && (
          <Col flex="0 1 auto">
            <Button type="text-only" size="middle" disabled={disabled} onClick={onDelete}>
              Delete
            </Button>
          </Col>
        )}
      </Row>

      {hasPrefillErrors && (
        <Row gutter={[32, 18]} className="mb-16">
          <Col flex="1">
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Click <Body2>Edit</Body2> to complete this section.
                </Body3>
              }
            />
          </Col>
        </Row>
      )}

      {showPlanAdminInfoError && (
        <Row gutter={[32, 18]} className="mb-16">
          <Col flex="1">
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Please go to the <Body2>Plan Administrators</Body2> step to finish setting up this
                  user.
                </Body3>
              }
            />
          </Col>
        </Row>
      )}

      <Row gutter={[32, 18]}>
        <Col>
          <Body5 as="div">Mailing Location</Body5>

          {true &&
            !claimsCheckPayeeContact &&
            plan.sendClaimsCheckTo !== "EMPLOYEE_HOME" &&
            !claimsCheckPrefillErrors.claimsCheckPayee &&
            !claimsCheckPrefillErrors.planAdminPayeeContactId && <Body3>-</Body3>}

          {true &&
            (claimsCheckPayeeContact ? (
              <>
                {(claimsCheckPayeeIsPlanAdmin || claimsCheckPayeeIsSomeoneElse) && (
                  <>
                    {payeeFullName && <Body3 as="div">{payeeFullName}</Body3>}
                    <Row>
                      <Col className="mr-8">
                        {!payeeFullName && claimsCheckPayeeContact?.firstName && (
                          <Body3 as="div">{claimsCheckPayeeContact.firstName}</Body3>
                        )}
                        {relevantContactErrors.firstName && (
                          <Body3 redMedium as="div">
                            {relevantContactErrors.firstName}
                          </Body3>
                        )}
                        {!claimsCheckPayeeContact?.firstName &&
                          !relevantContactErrors.firstName && <Body3 as="div">-</Body3>}
                      </Col>
                      <Col>
                        {!payeeFullName && claimsCheckPayeeContact?.lastName && (
                          <Body3 as="div">{claimsCheckPayeeContact.lastName}</Body3>
                        )}
                        {relevantContactErrors.lastName && (
                          <Body3 redMedium as="div">
                            {`${relevantContactErrors.lastName}`}
                          </Body3>
                        )}
                        {!claimsCheckPayeeContact?.lastName && !relevantContactErrors.lastName && (
                          <Body3 as="div">-</Body3>
                        )}
                      </Col>
                    </Row>

                    {getContactLocation(
                      claimsCheckPayeeContact,
                      locationPrefillErrors ? locationPrefillErrors : [],
                      claimsCheckPayeeIsPlanAdmin,
                    )}
                    {claimsCheckPayeeContact && relevantContactErrors.email && (
                      <Body3 as="div" redMedium>
                        {relevantContactErrors.email}
                      </Body3>
                    )}
                    {claimsCheckPayeeContact && relevantContactErrors.phoneNumber && (
                      <Body3 as="div" redMedium>
                        {relevantContactErrors.phoneNumber}
                      </Body3>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {plan.sendClaimsCheckTo === "EMPLOYEE_HOME" && (
                  <Body3 as="div">
                    Employee claim check will be sent directly to the employee's home address.
                  </Body3>
                )}
                {claimsCheckPrefillErrors.planAdminPayeeContactId && (
                  <Body3 redMedium>{claimsCheckPrefillErrors.planAdminPayeeContactId}</Body3>
                )}
                {claimsCheckPrefillErrors.claimsCheckPayee && (
                  <Body3 redMedium>{claimsCheckPrefillErrors.claimsCheckPayee}</Body3>
                )}
              </>
            ))}
        </Col>

        <Col span={24}>
          <Body5 as="div">This mailing location applies to</Body5>
          {plans.map((plan) => (
            <Body3 key={plan.id} as="div">
              {getCoverageWithPolicyNumbersLabel({
                client,
                benefitType: plan.benefitType,
              })}
            </Body3>
          ))}
        </Col>
      </Row>
    </WrappingComponent>
  );
}
