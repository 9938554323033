import { rejectNullableValues, unique } from "shared/utils/utils";
import { StackY } from "../Spacing/Spacing";
import { Body3 } from "../Typography/Typography";
import type { NIGOReason } from "shared/types/Client";

export function getCustomBannerMessageForNIGOReasons(
  record: Partial<Record<NIGOReason, string>>,
  nigoReasons: NIGOReason[] | null | undefined,
) {
  const messages = unique(nigoReasons?.map((r) => record[r]).filter(rejectNullableValues) ?? []);

  if (messages.length === 0) return null;

  const customBannerMessage =
    messages.length > 1 ? (
      <StackY dist={16}>
        {messages.map((m) => (
          <Body3 key={m} as="div">
            {m}
          </Body3>
        ))}
      </StackY>
    ) : (
      messages[0]
    );

  return customBannerMessage;
}
