import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton, Dropdown } from "antd";
import { Button } from "client/src/components/Button/Button";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Details } from "client/src/components/Details/Details";
import { LoadingError } from "client/src/components/Error/LoadingError";
import { Row, Col } from "client/src/components/Grid/Grid";
import { GoBackLink } from "client/src/components/Link/GoBackLink";
import { ActionMenu } from "client/src/components/Menu/ActionMenu";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { TinyBadge } from "client/src/components/TinyBadge/TinyBadge";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EmailErrorDrawer } from "client/src/domain/Client/ClientsList/ClientsTable/EmailError/EmailErrorDrawer";
import { EmailViewHeader } from "client/src/domain/Email/EmailPageTitle";
import { useGetClientByID } from "client/src/hooks/client";
import { triggerError } from "client/src/hooks/generalError";
import { useHubConfiguration } from "client/src/hooks/useConfig";
import {
  useCreateActivationLink,
  useUpdateEmailAndSendActivationEmail,
} from "client/src/hooks/user";
import { ConfirmDialogResendEmail } from "client/src/pages/EmailsPage/ConfirmDialogResendEmail";
import { RouteData } from "shared/config/routeData";
import { formatDateWithTimeAndtimezone } from "shared/utils/format";
import { getIsOwnerUser } from "../../../../shared/rbac/rbac";
import { Anchor } from "../../components/Anchor/Anchor";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../components/Typography/Typography";
import { useSlobAuth } from "../../hooks/auth";
import { useGetEmail, useResendEmail } from "../../hooks/email";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useTitle } from "../../hooks/useTitle";
import { useToggler } from "../../hooks/useToggler";
import { HTMLPreview } from "../../tests/HTMLPreview";
import type { ResendEmailFunc } from "../../hooks/email";
import type { ResponseError } from "client/src/hooks/query";
import type {
  CreateActivationLinkFunc,
  UpdateEmailAndSendActivationEmailFunc,
} from "client/src/hooks/user";
import type { ClientId, Client } from "shared/types/Client";
import type { EmailId, EmailWithStatusHistory } from "shared/types/Email";

export function EmailPage() {
  const { clientId, emailId } = useClientHubParams(["emailId"]);

  const {
    data: email,
    isSuccess: isSuccessEmail,
    isLoading: isLoadingEmail,
    isError: isErrorEmail,
    error: errorEmail,
  } = useGetEmail(emailId);

  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientByID(clientId, { enabled: !!clientId });

  const config = useHubConfiguration();

  const jiraURL = config.REACT_APP_JIRA_URL;

  const { mutateAsync: createActivationLink, isPending: isPendingCreateActivationLink } =
    useCreateActivationLink();
  const { mutateAsync: updateEmailAndSendActivationEmail } = useUpdateEmailAndSendActivationEmail();

  const {
    mutateAsync: resendEmail,
    isPending: isPendingResendEmail,
    error: resendEmailError,
  } = useResendEmail();

  const { authUser } = useSlobAuth();
  const isOwnerUser = getIsOwnerUser(authUser);

  useTitle(email ? `Email - ${email.subject}` : `Email`);

  if (isErrorClient) {
    triggerError(errorClient);
  }

  return (
    <EmailPagePresentation
      clientId={clientId}
      emailId={emailId}
      emailQuery={{
        email,
        isSuccessEmail,
        isLoadingEmail,
        isErrorEmail,
        errorEmail,
      }}
      clientQuery={{
        client,
        isLoadingClient,
        isErrorClient,
        errorClient,
      }}
      emailErrorDrawer={{
        createActivationLink,
        updateEmailAndSendActivationEmail,
        isPendingCreateActivationLink,
        jiraURL,
      }}
      showEmailProvider={isOwnerUser}
      resendEmail={resendEmail}
      isPendingResendEmail={isPendingResendEmail}
      resendEmailError={resendEmailError}
    />
  );
}

type EmailPagePresentationProps = {
  clientId: ClientId | undefined;
  emailId: EmailId;
  emailQuery: {
    email: EmailWithStatusHistory | undefined;
    isSuccessEmail: boolean;
    isLoadingEmail: boolean;
    isErrorEmail: boolean;
    errorEmail: ResponseError | null;
  };
  clientQuery: {
    client: Client | undefined;
    isLoadingClient: boolean;
    isErrorClient: boolean;
    errorClient: ResponseError | null;
  };
  emailErrorDrawer: {
    createActivationLink: CreateActivationLinkFunc;
    updateEmailAndSendActivationEmail: UpdateEmailAndSendActivationEmailFunc;
    isPendingCreateActivationLink: boolean;
    jiraURL: string;
  };
  showEmailProvider: boolean;
  resendEmail: ResendEmailFunc;
  isPendingResendEmail: boolean;
  resendEmailError: ResponseError | null;
};

export function EmailPagePresentation(props: EmailPagePresentationProps) {
  const {
    clientId,
    emailId,
    emailQuery: { email, isSuccessEmail, isLoadingEmail, isErrorEmail, errorEmail },
    clientQuery: { client, isLoadingClient },
    emailErrorDrawer: {
      createActivationLink,
      isPendingCreateActivationLink,
      jiraURL,
      updateEmailAndSendActivationEmail,
    },
    showEmailProvider,
    resendEmail,
    resendEmailError,
    isPendingResendEmail,
  } = props;

  const [erorrHelpVisible, toggleErrorHelpVisible] = useToggler();
  const [isVisibleResendEmail, toggleIsVisibleResendEmail] = useToggler();

  const [copySuccessVisible, toggleCopySuccessVisible] = useToggler();

  const hasEmailError = email ? email.errorUsers.length > 0 : false;

  const backTo = clientId ? RouteData.emails.getPath(clientId) : RouteData.allEmails.getPath();

  return (
    <>
      <PageContent>
        <div>
          <div className="mb-12">
            <GoBackLink to={backTo}>&lt; Back to email log</GoBackLink>
          </div>

          <Row align="bottom">
            <Col flex={1}>
              {clientId && isLoadingClient ? (
                <EmailHeaderSkeleton />
              ) : (
                <EmailViewHeader client={client} />
              )}
              {showEmailProvider && <p>Sent with: {email?.sentWith}</p>}
            </Col>
            <Col className="text-right">
              {(hasEmailError || email?.resent) && (
                <div className="mb-24 stack-x-8">
                  {hasEmailError &&
                    (email?.emailType === "ACCOUNT_ACTIVATION_REQUEST" ? (
                      <TinyBadge variant="red">Welcome Email Error</TinyBadge>
                    ) : (
                      <TinyBadge variant="red">Email Error</TinyBadge>
                    ))}

                  {email?.resent && <TinyBadge variant="gray">Resent</TinyBadge>}
                </div>
              )}

              <Row justify="end">
                <Col>
                  <StackX dist={12}>
                    {hasEmailError && (
                      <Button
                        type="secondary"
                        disabled={!client}
                        onClick={() => {
                          toggleErrorHelpVisible();
                        }}
                      >
                        Email error help
                      </Button>
                    )}

                    <Dropdown
                      dropdownRender={() => (
                        <ActionMenu
                          items={[
                            {
                              key: "copy-html",
                              label: (
                                <ButtonOld
                                  type="link-inline"
                                  disabled={copySuccessVisible || !email}
                                  onClick={async () => {
                                    if (email?.body) {
                                      await navigator.clipboard.writeText(email.body);
                                      void slobMessage.success("HTML Copied");
                                      toggleCopySuccessVisible();
                                      setTimeout(toggleCopySuccessVisible, 1500);
                                    }
                                  }}
                                >
                                  {copySuccessVisible ? "Copied!" : "Copy HTML"}
                                </ButtonOld>
                              ),
                            },
                            ...(email?.emailType !== "ACCOUNT_ACTIVATION_REQUEST"
                              ? [
                                  {
                                    key: "resend-email",
                                    label: (
                                      <Tooltip
                                        title={
                                          hasEmailError &&
                                          `This user is having trouble receiving emails from us. Please use "Email error help" to troubleshoot.`
                                        }
                                        placement="bottom"
                                      >
                                        <ButtonOld
                                          type="link-inline"
                                          disabled={
                                            hasEmailError || isLoadingClient || isLoadingEmail
                                          }
                                          onClick={toggleIsVisibleResendEmail}
                                        >
                                          Resend email
                                        </ButtonOld>
                                      </Tooltip>
                                    ),
                                  },
                                ]
                              : []),
                            ...(showEmailProvider && email?.sentWith === "MAILER"
                              ? [
                                  {
                                    key: "view-in-mandrill",
                                    label: (
                                      <Anchor
                                        href={`https://mandrillapp.com/activity?q=u_slob-email-id:%22${emailId}%22`}
                                        target="_blank"
                                        variant="bodyText"
                                      >
                                        View in Mandrill
                                      </Anchor>
                                    ),
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                    >
                      <Button shrink size="xtra-small" aria-label="View more">
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    </Dropdown>
                  </StackX>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr />

          {isLoadingEmail && <EmailPageSkeleton />}

          {isErrorEmail && <LoadingError type="page" error={errorEmail} />}

          {isSuccessEmail && !isErrorEmail && !isLoadingEmail && email && (
            <>
              <StackY dist={32}>
                <div>
                  <h3>{email.subject}</h3>
                  <StackY dist={12}>
                    <div>
                      <Body2>Preview Text:</Body2>
                      <Body3> {email.previewText}</Body3>
                    </div>

                    <Row gutter={12} align="bottom">
                      <Col>
                        <Body2>Email ID:</Body2>
                        <Body3> {email.id}</Body3>
                      </Col>
                      <Col>
                        <Copy content={email.id} />
                      </Col>
                    </Row>

                    <div>
                      <Body2>Email Type:</Body2>
                      <Body3> {email.emailType}</Body3>
                    </div>

                    <div>
                      <Body2>From: </Body2>
                      <Body3>
                        {email.fromName} {`<${email.from}>`}
                      </Body3>
                    </div>
                    <div>
                      <Body2>To:</Body2> <Body3>{prettyEmailList(email.to)}</Body3>
                    </div>

                    <div>
                      <Body2>CC:</Body2>
                      <Body3> {prettyEmailList(email.cc)}</Body3>
                    </div>

                    <div>
                      <Body2>BCC:</Body2>
                      <Body3> {prettyEmailList(email.bcc)}</Body3>
                    </div>

                    <div>
                      <Row gutter={8}>
                        <Col>
                          <Body2>Created: </Body2>
                        </Col>

                        <Col>
                          <Details>
                            <summary>
                              <Body3>{formatDateWithTimeAndtimezone(email.createdAt)}</Body3>
                            </summary>

                            {email.statusHistory.length === 0 && <Body5>No history</Body5>}

                            {email.statusHistory.map((s) => (
                              <Body5 key={s.id} as="div">
                                {/* @todo: call out when we are using createdAt instead of the actual event time eventAt  */}
                                {formatDateWithTimeAndtimezone(s.eventAt ?? s.createdAt)} -{" "}
                                <strong>{s.status}</strong>
                              </Body5>
                            ))}
                          </Details>
                        </Col>
                      </Row>
                    </div>

                    {email.metadata && (
                      <Row gutter={8}>
                        <Col>
                          <Body2>Metadata:</Body2>
                        </Col>

                        <Col>
                          <Details>
                            <summary>
                              <Body3>Expand/collapse</Body3>
                            </summary>

                            <pre>{JSON.stringify(email.metadata, null, 2)}</pre>
                          </Details>
                        </Col>
                      </Row>
                    )}
                  </StackY>
                </div>
                <div>
                  <HTMLPreview title={email.subject} html={email.body} showCopyHTMLButton={false} />
                </div>
              </StackY>
            </>
          )}
        </div>
      </PageContent>

      <EmailErrorDrawer
        createActivationLink={createActivationLink}
        updateEmailAndSendActivationEmail={updateEmailAndSendActivationEmail}
        isPendingCreateActivationLink={isPendingCreateActivationLink}
        visible={erorrHelpVisible}
        clientId={clientId}
        ticketId={client?.ticketId}
        emailErrorUsers={email?.errorUsers}
        onClose={() => toggleErrorHelpVisible()}
        jiraURL={jiraURL}
        welcomeEmailError={email?.emailType === "ACCOUNT_ACTIVATION_REQUEST"}
      />

      <ConfirmDialogResendEmail
        isVisible={isVisibleResendEmail}
        onCancel={toggleIsVisibleResendEmail}
        emailId={email?.id ?? ""}
        resendEmail={resendEmail}
        isPendingResendEmail={isPendingResendEmail}
        resendEmailError={resendEmailError}
      />
    </>
  );
}

function Copy({ content }: { content: string }) {
  const [copySuccessVisible, toggleCopySuccessVisible] = useToggler();
  return (
    <>
      <Button
        type="text-only"
        icon={<FontAwesomeIcon icon={faCopy} />}
        onClick={async () => {
          await navigator.clipboard.writeText(content);
          toggleCopySuccessVisible();
          setTimeout(toggleCopySuccessVisible, 1000);
        }}
      >
        Copy ID
      </Button>
      {copySuccessVisible && " ✅ Copied"}
    </>
  );
}

function EmailHeaderSkeleton() {
  return (
    <>
      <Skeleton active paragraph={false} title={{ width: 360 }} />
      <Skeleton active paragraph={false} title={{ width: 200 }} />
      <Skeleton active paragraph={false} title={{ width: 360 }} />
    </>
  );
}

function EmailPageSkeleton() {
  return (
    <>
      <Skeleton active paragraph={false} title={{ width: 400 }} />
      <Skeleton active paragraph={false} title={{ width: 520 }} />
      <Skeleton active paragraph={false} title={{ width: 220 }} />
      <Skeleton active paragraph={false} title={{ width: 220 }} />
      <Skeleton active paragraph={false} title={{ width: 260 }} />
      <Skeleton active paragraph={false} title={{ width: 180 }} />
      <Skeleton active paragraph={false} title={{ width: 300 }} />
      <Skeleton active paragraph={false} title={{ width: 160 }} />
      <Skeleton active paragraph={false} title={{ width: 80 }} />
      <br />
      <br />

      <Skeleton active paragraph={{ rows: 7 }} />
      <Skeleton active title={false} paragraph={{ rows: 7 }} />
      <Skeleton active title={false} paragraph={{ rows: 7 }} />
      <Skeleton active title={false} paragraph={{ rows: 7 }} />
    </>
  );
}

function prettyEmailList(emailList: string | null | string) {
  const result = emailList?.split(/[,;\s]+/).join(", ") || "";
  return result;
}
