import { useEffect, useRef } from "react";

type FocusableElement = Pick<HTMLOrSVGElement, "focus">;

/**
 * useFocus hook - to consistently set focus on an element
 * @param shouldFocus if the element assigned to the Ref should have focus
 * @returns a Ref; assign the Ref to the element that you want focus on when shouldFocus is true
 */
export function useFocus<T extends FocusableElement>(shouldFocus: boolean) {
  const ref = useRef<T>(null);

  useEffect(() => {
    const rafId = requestAnimationFrame(() => {
      if (shouldFocus) {
        ref.current?.focus({ preventScroll: true });
      }
    });

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [shouldFocus]);

  return ref;
}
