import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "client/src/components/Loading/Loading";
import React, { useEffect, useRef, useState } from "react";
import { doctaErrorMessage, duplicateErrorMessage } from "shared/types/Document";

import { sanitizeDocumentName } from "shared/utils/document";
import {
  getFormattedPolicyEffectiveFromPolicy,
  getInfoForPolicy,
} from "../../../../../shared/utils/policy";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Button/Button";
import { GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { Checkbox } from "../../../components/Form/Checkbox";
import { Modal } from "../../../components/Modal/Modal";
import { ProgressModal } from "../../../components/Modal/ProgressModal";
import { SlobPopover } from "../../../components/SlobPopover/SlobPopover";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3 } from "../../../components/Typography/Typography";
import { Upload, useDocumentBodyAsFileDropZone } from "../../../components/Upload/Upload";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useSlobAuth } from "../../../hooks/auth";
import { useUploadDocument } from "../../../hooks/document";
import { ResponseError } from "../../../hooks/query";
import { useHubConfiguration } from "../../../hooks/useConfig";
import { useGetFeatureToggles } from "../../../hooks/useFeatureToggles";
import { useSlobId } from "../../../hooks/useSlobId";
import { debounce } from "../../../utils/debounce";
import { RejectedFileTable } from "../RejectedFileTable/RejectedFileTable";
import { UploadDifferentFilesTable } from "../UploadDifferentFilesTable/UploadDifferentFilesTable";
import { UploadFileTable } from "../UploadFileTable/UploadFileTable";

import * as styles from "./documentUpload.module.less";

import type { DocumentBenefitType } from "../../../../../shared/types/DocumentBenefitType";
import type { CensusSource } from "../../../../../shared/validation/document";
import type { UploadProps } from "../../../components/Upload/Upload";
import type { RejectedFile } from "../RejectedFileTable/RejectedFileTable";
import type { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import type { ClientId, Policy, PolicyId } from "shared/types/Client";
import type {
  Document,
  DocumentCategory,
  DocumentLanguage,
  DocumentId,
} from "shared/types/Document";

export const DataTestId = {
  DocumentUploadContent: "doc-upload-content",
};

export type DocumentUploadProps = {
  modalTitle?: string;
  clientId: ClientId;
  policyId?: string;
  policies?: Policy[];
  selectedResourcesType?: DocumentCategory[];
  multiPolicyFiles?: boolean;
  category: DocumentCategory | null;
  censusSource?: CensusSource;
  replaceDocumentId?: DocumentId;
  shouldOverrideFilename?: boolean;
  uploadProps?: UploadProps;
  visible: boolean;
  closeModal: () => void;
  singleFile?: boolean;
  track: (buttonLabel: string) => void;
  onUpload?: () => void;
  infoMessage?: string;
  autoUpload?: boolean;
  vdrDocumentId?: DocumentId;
};

export function DocumentUpload({
  modalTitle,
  clientId,
  category,
  vdrDocumentId,
  censusSource,
  replaceDocumentId,
  selectedResourcesType,
  shouldOverrideFilename = false,
  policyId,
  policies,
  multiPolicyFiles = false,
  uploadProps,
  visible,
  closeModal,
  singleFile = false,
  track,
  onUpload,
  infoMessage,
  autoUpload = false,
}: DocumentUploadProps) {
  const [uploading, toggleUploading] = useState(false);

  const handleClose = () => {
    track("Cancel");
    closeModal();
  };

  const title = singleFile ? "Upload Single File" : modalTitle || "Upload";

  return (
    <Modal
      open={visible}
      destroyOnClose
      maskClosable={false}
      onCancel={handleClose}
      title={title}
      width={994}
      focusTriggerAfterClose={false}
      disableClose={uploading}
      footer={null}
    >
      <DocumentUploadContent
        clientId={clientId}
        category={category}
        policyId={policyId}
        selectedResourcesType={selectedResourcesType}
        vdrDocumentId={vdrDocumentId}
        censusSource={censusSource}
        replaceDocumentId={replaceDocumentId}
        shouldOverrideFilename={shouldOverrideFilename}
        policies={policies}
        multiPolicyFiles={multiPolicyFiles}
        uploadProps={uploadProps}
        onDone={closeModal}
        singleFile={singleFile}
        track={track}
        toggleUploading={toggleUploading}
        onUpload={onUpload}
        infoMessage={infoMessage}
        autoUpload={autoUpload}
      />
    </Modal>
  );
}

export type DocumentUploadContentProps = {
  clientId: ClientId;
  policyId?: string;
  policies?: Policy[];
  vdrDocumentId?: DocumentId;
  selectedResourcesType?: DocumentCategory[];
  multiPolicyFiles?: boolean;
  category: DocumentCategory | null;
  censusSource?: CensusSource;
  replaceDocumentId?: DocumentId;
  shouldOverrideFilename?: boolean;
  uploadProps?: Partial<UploadProps>;
  onDone?: () => void;
  singleFile?: boolean;
  track: (buttonLabel: string) => void;
  toggleUploading?: (bool: boolean) => void;
  showCancelButton?: boolean;
  autoUpload?: boolean;
  showDeleteButton?: boolean;
  onDelete?: VoidFunction;
  hideUploadButtonInitially?: boolean;
  uploadButtonTitle?: string;
  deleteButtonTitle?: string;
  onUpload?: (documents: Document[]) => void;
  dragEnabled?: boolean;
  disabled?: boolean;
  uploadButtonDisabled?: boolean;
  infoMessage?: string;
  language?: DocumentLanguage;
};

type ApprovedFile = {
  uploadFile: UploadFile<Document> & {
    errorCode?: "DUPLICATE_NAME" | "EMBEDDED_SCRIPTS";
  };
  resourceType: DocumentCategory | null;
  benefitTypes: DocumentBenefitType[] | null;
};

export function DocumentUploadContent({
  clientId,
  category,
  policyId,
  vdrDocumentId,
  policies,
  censusSource,
  replaceDocumentId,
  selectedResourcesType,
  shouldOverrideFilename = false,
  multiPolicyFiles = false,
  uploadProps,
  onDone,
  singleFile = false,
  track,
  toggleUploading,
  showCancelButton = true,
  autoUpload = false,
  showDeleteButton = false,
  onDelete,
  hideUploadButtonInitially = false,
  uploadButtonTitle = "Upload",
  deleteButtonTitle = "Delete",
  onUpload,
  dragEnabled = true,
  disabled = false,
  uploadButtonDisabled = false,
  infoMessage,
  language,
}: DocumentUploadContentProps) {
  const { authUser } = useSlobAuth();

  const config = useHubConfiguration();
  const { data: featureToggles } = useGetFeatureToggles(clientId);

  const [approvedFiles, setApprovedFiles] = useState<ApprovedFile[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFile[]>([]);
  const [extraPolicyIdFile, setExtraPolicyIdFile] = useState<PolicyId[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

  const id = useSlobId({ prefix: "document-upload__" });

  /* subtract 1MB from limit in config to align file size limit
    with request size limit (including metadata) enforced by nginx */
  const fileSizeLimitInMB = config.UPLOAD_SIZE_LIMIT_MB - 1;

  const {
    isPending: uploading,
    mutateAsync,
    reset: resetUploadMutationState,
  } = useUploadDocument(policyId);

  const duplicateFileUXHelpMessage =
    "A matching filename already exists. Hover over the file name and click the pencil icon to rename it.";

  const getAddedBy = () => {
    const fullName = authUser?.name || "Unknown";
    return `${fullName} on ${new Date().toDateString()}`;
  };

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    toggleUploading?.(uploading);
  }, [uploading, toggleUploading]);

  const ref = useElementScrollToTopWhen<HTMLDivElement>(rejectedFiles);
  const draggerRef = useDocumentBodyAsFileDropZone(dragEnabled);

  const policy = (policies || []).find((policy) => policy.id === policyId);
  const extraPoliciesForFile = (policies || []).filter((policy) => policy.id !== policyId);

  const handleCheckExtraPolicyFile = (policyId: PolicyId) => {
    if (extraPolicyIdFile.includes(policyId))
      return setExtraPolicyIdFile(extraPolicyIdFile.filter((i) => i !== policyId));
    return setExtraPolicyIdFile([...extraPolicyIdFile, policyId]);
  };

  const checkForMatchingFileName = (file: UploadFile<Document>) => {
    if (file.status === "uploading") {
      return false;
    }
    const nameLowerCaseWithoutExtension = stripExtensionAndLowercase(file.name);
    const matchingFileNameInApproved = approvedFiles?.some((aFile) => {
      return stripExtensionAndLowercase(aFile.uploadFile.name) === nameLowerCaseWithoutExtension;
    });
    return matchingFileNameInApproved;
  };

  const validateFiles = (files: UploadFile<Document>[]): [ApprovedFile[], RejectedFile[]] => {
    const rejected: RejectedFile[] = [];
    const approved: ApprovedFile[] = [];
    for (const rawFile of files) {
      const file = {
        ...rawFile,
        name: sanitizeDocumentName(rawFile.name),
      };
      const fileSizeInMB = Math.ceil((file.size ? file.size : 0) / 1024 / 1024);
      if (!file.originFileObj || file.originFileObj === undefined) {
        rejected.push({
          key: file.uid,
          uid: file.uid,
          name: file.name,
          category: category ?? "",
          originFileObj: file.originFileObj,
          reason: `File is unsuported or invalid`,
          code: "UNSUPPORTED",
          addedBy: getAddedBy(),
        });
      } else if (fileSizeInMB > fileSizeLimitInMB) {
        rejected.push({
          key: file.uid,
          uid: file.uid,
          name: file.name,
          category: category ?? "",
          originFileObj: file.originFileObj,
          reason: `File is larger than the ${fileSizeLimitInMB} MB limit`,
          code: "TOO_LARGE",
          addedBy: getAddedBy(),
        });
      } else if (!shouldOverrideFilename && checkForMatchingFileName(file)) {
        rejected.push({
          key: file.uid,
          uid: file.uid,
          name: file.name,
          category: category ?? "",
          originFileObj: file.originFileObj,
          reason: duplicateFileUXHelpMessage,
          code: "DUPLICATE_NAME",
          addedBy: getAddedBy(),
        });
      } else if (file.name.length > 256) {
        rejected.push({
          key: file.uid,
          uid: file.uid,
          name: file.name,
          category: category ?? "",
          originFileObj: file.originFileObj,
          reason: "File name cannot exceed 256 characters",
          code: "NAME_TOO_LONG",
          addedBy: getAddedBy(),
        });
      } else {
        file.status = "uploading";
        approved.push({
          uploadFile: file,
          resourceType: null,
          benefitTypes: [],
        });
      }
    }
    return [approved, rejected];
  };

  const singleFileProps = singleFile ? { multiple: false, maxCount: 1 } : {};

  let successMessage = false;
  const mergedUploadProps: UploadProps = {
    data: { clientId, category, policyId },
    showUploadList: false,
    multiple: true,
    uploading,
    disabled,
    beforeUpload: () => false,
    // debounce because antd calls onChange for each selected file
    onChange: debounce((info: UploadChangeParam<UploadFile<Document>>) => {
      if (disabled) return;
      const [approvedValidated, rejectedValidated] = validateFiles(info.fileList);
      setApprovedFiles(approvedValidated);
      setRejectedFiles((prevRejectedFiles) =>
        prevRejectedFiles
          .filter((p) => !rejectedValidated.some((rjv) => rjv.name === p.name))
          .concat(rejectedValidated),
      );

      if (autoUpload) {
        void handleUpload(approvedValidated);
      } else if (approvedValidated.length > approvedFiles.length) {
        if (!successMessage) {
          successMessage = true;
          void slobMessage.success("Successfully added", undefined, () => {
            successMessage = false;
          });
        }
      }
    }, 0),
    fileList: approvedFiles.map((a) => a.uploadFile),
    track,
    ...uploadProps,
    ...singleFileProps,
  };

  const renameDocument = (fileId: number | string, newName: string) => {
    const file = rejectedFiles.find((rf) => rf.uid === fileId);
    if (file) {
      file.name = newName;
      Object.defineProperty(file.originFileObj, "name", { value: newName, writable: true });
      const [approvedValidated, rejectedValidated] = validateFiles([file]);
      const renameApproved = approvedValidated.length > 0;
      // always filter out the renamed file since even if it was rejected again, we need to put the newly rejected file back in
      const newRejectedFiles = rejectedFiles.filter((rv) => rv.name !== file.name);
      if (renameApproved) {
        setApprovedFiles((prevApprovedFiles) => prevApprovedFiles.concat(approvedValidated));
        setRejectedFiles(newRejectedFiles);
      } else {
        setRejectedFiles((prevRejected) => prevRejected.concat(rejectedValidated));
      }
    }
  };

  const handleUpload = async (approvedFiles: ApprovedFile[]) => {
    track(uploading ? "Uploading" : "Upload");
    let errorCount = 0;
    const result = await Promise.all(
      approvedFiles.map(async (approvedFile) => {
        try {
          if (approvedFile.uploadFile.originFileObj) {
            const uploadResult = await uploadFile(approvedFile);

            if (uploadResult) {
              setApprovedFiles((prevApprovedFiles) => {
                return prevApprovedFiles.filter(
                  (paf) => paf.uploadFile.name !== approvedFile.uploadFile.name,
                );
              });

              return uploadResult.data;
            }
            errorCount = errorCount + 1;
            return null;
          }
        } catch (e: unknown) {
          errorCount = errorCount + 1;
          setUploadInProgress(false);
          setApprovedFiles((prevApprovedFiles) => {
            return prevApprovedFiles.map((paf) => {
              if (ResponseError.isResponseError(e) && e.message === duplicateErrorMessage) {
                return {
                  ...paf,
                  uploadFile: {
                    ...paf.uploadFile,
                    error: duplicateFileUXHelpMessage,
                    errorCode: "DUPLICATE_NAME",
                  },
                };
              } else if (ResponseError.isResponseError(e) && e.message === doctaErrorMessage) {
                const errorCode =
                  ResponseError.isSlobServerError(e.data) && e.data.code === "EMBEDDED_SCRIPTS"
                    ? "EMBEDDED_SCRIPTS"
                    : undefined;
                return {
                  ...paf,
                  uploadFile: {
                    ...paf.uploadFile,
                    error: doctaErrorMessage,
                    errorCode,
                  },
                };
              } else {
                return {
                  ...paf,
                  uploadFile: {
                    ...paf.uploadFile,
                    error: GenericErrorCopy2,
                  },
                };
              }
            });
          });

          return null;
        }
      }),
    );
    if (errorCount === 0) {
      handleClose();
      onUpload?.(result.filter((d): d is Document => !!d));
    }
  };

  const handleClose = () => {
    track("Cancel");
    setUploadInProgress(false);
    resetUploadMutationState();
    onDone?.();
  };

  const uploadFile = async (approvedFile: ApprovedFile) => {
    if (!approvedFile.uploadFile.originFileObj) {
      throw new Error(`File is undefined`);
    }

    const formData = new FormData();
    if (policyId) formData.append("policyIds", JSON.stringify([policyId, ...extraPolicyIdFile]));
    formData.append("file", approvedFile.uploadFile.originFileObj);
    formData.append("name", approvedFile.uploadFile.name);
    formData.append("language", language ? language : "EN");

    if (category) {
      formData.append("category", category);
    } else {
      if (approvedFile.resourceType) {
        formData.append("category", approvedFile.resourceType);
      } else {
        setApprovedFiles((prevApprovedFiles) => {
          return prevApprovedFiles.map((paf) => {
            if (paf.uploadFile.uid === approvedFile.uploadFile.uid) {
              return {
                ...paf,
                uploadFile: {
                  ...paf.uploadFile,
                  error: {
                    resourceType: "Please provide a response.",
                  },
                },
              };
            }
            return paf;
          });
        });
        return;
      }

      if (approvedFile.benefitTypes) {
        approvedFile.benefitTypes.forEach((cfb) => {
          formData.append("benefitTypes[]", cfb);
        });
      } else {
        // No-op, benefit types are optional
      }
    }

    formData.append("singleFile", singleFile ? "true" : "false");
    if (censusSource) {
      formData.append("censusSource", censusSource);
    }
    if (replaceDocumentId) {
      formData.append("replaceDocumentId", replaceDocumentId);
    }
    if (shouldOverrideFilename) {
      formData.append("shouldOverrideFilename", "true");
    }
    if (vdrDocumentId) {
      formData.append("vdrDocumentId", vdrDocumentId);
    }

    setUploadInProgress(true);
    return await mutateAsync({ data: formData, params: { clientId } });
  };

  const onClickRemove = (filename: string) => {
    setApprovedFiles((prevList) => prevList.filter((file) => file.uploadFile.name !== filename));
  };

  const onClickRemoveRejected = (filename: string) => {
    setRejectedFiles((prevList) => prevList.filter((file) => file.name !== filename));
  };

  const fileList = approvedFiles
    .map((f) => ({
      key: String(f.uploadFile.uid),
      id: f.uploadFile.uid,
      name: f.uploadFile.name,
      resourceType: f.resourceType,
      benefitTypes: f.benefitTypes,
      error: f.uploadFile.error,
      errorCode: f.uploadFile.errorCode,
      updatedBy: getAddedBy(),
    }))
    // display errors when autouploading
    .filter((a) => !autoUpload || a.error);

  const approvedFilesRenameDocument = (recordId: string, newName: string) => {
    setApprovedFiles((prevApprovedFiles) => {
      return prevApprovedFiles.map((prevApprovedFile) => {
        if (prevApprovedFile.uploadFile.uid === recordId) {
          prevApprovedFile.uploadFile.name = newName;
        }
        return prevApprovedFile;
      });
    });
  };

  const showFooter =
    (!autoUpload && (!hideUploadButtonInitially || approvedFiles.length > 0)) ||
    showCancelButton ||
    showDeleteButton;

  if (!featureToggles) {
    return <Loading />;
  }

  return (
    <>
      {uploading && (
        <ProgressModal
          centered
          progressText="Uploading your file..."
          body="Depending on the size of your file, this could take up to 1 minute."
        />
      )}
      <StackY dist={32}>
        <div ref={ref} data-testid={DataTestId.DocumentUploadContent}>
          <StackY dist={32}>
            <Upload
              {...mergedUploadProps}
              ref={draggerRef}
              track={track}
              dragEnabled={dragEnabled && !disabled}
              maxSizeInMB={fileSizeLimitInMB}
            />

            {(fileList.length > 0 || rejectedFiles.length > 0) && (
              <>
                <StackY dist={48}>
                  <RejectedFileTable
                    onClickRemoveRejected={onClickRemoveRejected}
                    rejectedFiles={rejectedFiles}
                    renameDocument={renameDocument}
                    track={track}
                  />

                  {category !== null && (
                    <UploadFileTable
                      onClickRemove={onClickRemove}
                      fileList={fileList}
                      disabled={uploading || disabled}
                      track={track}
                      renameDocument={approvedFilesRenameDocument}
                    />
                  )}

                  {category == null && (
                    <UploadDifferentFilesTable
                      onClickRemove={onClickRemove}
                      fileList={fileList}
                      disabled={uploading || disabled}
                      track={track}
                      selectedResourcesType={selectedResourcesType}
                      renameDocument={approvedFilesRenameDocument}
                      getPopupContainer={() => ref.current || document.body}
                      onChangeResourceType={(option, record) => {
                        setApprovedFiles((prevApprovedFiles) => {
                          return prevApprovedFiles.map((prevApprovedFile) => {
                            if (prevApprovedFile.uploadFile.uid === record.id) {
                              if (option.value) {
                                const next = { ...prevApprovedFile };
                                next.resourceType = option.value;
                                delete prevApprovedFile.uploadFile.error;
                                return next;
                              } else {
                                const next = { ...prevApprovedFile };
                                next.resourceType = null;
                                return next;
                              }
                            }
                            return prevApprovedFile;
                          });
                        });
                      }}
                      onChangeBenefitType={({ recordId: fileId, name, checked }) => {
                        setApprovedFiles((prevApprovedFiles) => {
                          return prevApprovedFiles.map((prevApprovedFile) => {
                            if (prevApprovedFile.uploadFile.uid === fileId) {
                              const nextBenefitTypes = checked
                                ? prevApprovedFile.benefitTypes?.concat(name) ?? [name]
                                : prevApprovedFile.benefitTypes?.filter((b) => b !== name) ?? [];
                              return {
                                ...prevApprovedFile,
                                benefitTypes: nextBenefitTypes,
                              };
                            }
                            return prevApprovedFile;
                          });
                        });
                      }}
                      featureToggles={featureToggles}
                    />
                  )}
                </StackY>
                {multiPolicyFiles && policy && Boolean(extraPoliciesForFile.length) && (
                  <StackY dist={16}>
                    <hr />
                    <p>
                      You're uploading this for benefits effective {getInfoForPolicy(policy)}. If
                      you would like to upload this for other effective dates or policy numbers,
                      please specify:
                    </p>
                    <StackY dist={8} className="w-full">
                      {extraPoliciesForFile.map((extraPolicy) => {
                        const policyEffective = getFormattedPolicyEffectiveFromPolicy(extraPolicy);
                        return (
                          <React.Fragment key={extraPolicy.id}>
                            <div className={styles.extraPolicy}>
                              <Checkbox
                                id={extraPolicy.id}
                                label={getInfoForPolicy(extraPolicy)}
                                name="extraPolicyId"
                                checked={extraPolicyIdFile.includes(extraPolicy.id)}
                                disabled={disabled}
                                onChange={() => handleCheckExtraPolicyFile(extraPolicy.id)}
                              />
                              <SlobPopover
                                id={id + extraPolicy.id}
                                title={policyEffective}
                                overlayClassName={styles.policyInfo}
                                content={extraPolicy.slfCoverages?.join(", ")}
                                placement="top"
                                trigger={"hover"}
                              >
                                <button className="btn-reset" disabled={disabled}>
                                  <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className={styles.infoIcon}
                                  />
                                </button>
                              </SlobPopover>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </StackY>
                  </StackY>
                )}
              </>
            )}
          </StackY>
        </div>
        {infoMessage && (fileList.length > 0 || rejectedFiles.length > 0) && (
          <AlertBanner variant="info" message={<Body3>{infoMessage}</Body3>} />
        )}
        {showFooter && (
          <div className={styles.footer}>
            {!autoUpload && (!hideUploadButtonInitially || approvedFiles.length > 0) && (
              <Button
                type="primary"
                size="middle"
                disabled={
                  approvedFiles.length === 0 || uploadInProgress || disabled || uploadButtonDisabled
                }
                loading={uploading || uploadInProgress}
                aria-label={uploading || uploadInProgress ? "Uploading" : undefined}
                onClick={() => void handleUpload(approvedFiles)}
              >
                {uploading ? "" : uploadButtonTitle}
              </Button>
            )}
            {showCancelButton && (
              <Button
                type="text"
                size="middle"
                disabled={uploading || disabled}
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
            {showDeleteButton && (
              <Button type="text" size="middle" disabled={uploading || disabled} onClick={onDelete}>
                {deleteButtonTitle}
              </Button>
            )}
          </div>
        )}
      </StackY>
    </>
  );
}

const stripExtensionAndLowercase = (name: string) => {
  const lowercase = name.toLowerCase();
  const lowercaseWithoutExtension = lowercase.substring(0, lowercase.lastIndexOf(".")) || lowercase;
  return lowercaseWithoutExtension;
};

function useElementScrollToTopWhen<TElement extends HTMLElement>(rejectedFiles: RejectedFile[]) {
  const ref = useRef<TElement>(null);

  useEffect(() => {
    if (rejectedFiles.length > 0) {
      let overflowEl: null | Element = ref.current;
      if (overflowEl) {
        while ((overflowEl = overflowEl.parentElement)) {
          if (overflowEl.scrollTop > 0) {
            overflowEl.scrollTo({ top: 0, behavior: "smooth" });
            break;
          }
        }
      }
    }
  }, [rejectedFiles]);

  return ref;
}
