import { Form, Tooltip } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";

import { signerEmailValidator } from "shared/validation/onboardingForm";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { FormInput } from "../../../components/Form/Input";
import { ReactComponent as InfoOutlineIcon } from "../../../components/Icons/InfoOutlineIcon.svg";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { Body3, Body4 } from "../../../components/Typography/Typography";
import * as outsideSignerStyles from "../outsideSigner.module.less";

import type { ResponseError } from "../../../hooks/query";
import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type { TemplateLookup } from "shared/types/OnboardingForm";
import type { templateLookupValidation } from "shared/validation/onboardingForm";

type CreateFormProps = {
  formik: FormikTypeFromValidator<typeof templateLookupValidation>;
  template: TemplateLookup | null;
  hasTemplateLookupError: boolean;
  isTemplateLookupLoading: boolean;
  isCreateOnboardFormLoading: boolean;
  errorCreatingForm: ResponseError | null;
  handleTemplateLookup: () => void;
  cancelAction: () => void;
};

export const CreateForm: React.FC<CreateFormProps> = ({
  formik,
  template,
  hasTemplateLookupError,
  isCreateOnboardFormLoading,
  isTemplateLookupLoading,
  errorCreatingForm,
  handleTemplateLookup,
  cancelAction,
}) => {
  const [form] = Form.useForm();
  const areRecipientEmailsValid = signerEmailValidator.isValidSync({
    emails: template?.recipients.map(({ email }) => email),
  });

  return (
    <div>
      <Form form={form} onFinish={formik.handleSubmit}>
        <StackY dist={24}>
          <StackY dist={16}>
            <Row align="bottom" gutter={8}>
              <Col flex={1}>
                <FormInput
                  name="docusignTemplateId"
                  label="DocuSign Template ID"
                  topText="Onboarding Form"
                  maxLength={40}
                  value={formik.values.docusignTemplateId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleTemplateLookup();
                    }
                  }}
                  error={hasTemplateLookupError ? "Please enter a valid DocuSign Template ID." : ""}
                  touched={formik.touched.docusignTemplateId}
                />
              </Col>
              <Col style={{ marginBottom: 8 }}>
                <Button
                  type="secondary"
                  loading={isTemplateLookupLoading}
                  onClick={handleTemplateLookup}
                  disabled={!formik.values.docusignTemplateId || isCreateOnboardFormLoading}
                >
                  Lookup
                </Button>
              </Col>
            </Row>
            <div>
              {template && (
                <StackY dist={12}>
                  <AlertBanner
                    variant="success"
                    message={
                      <div>
                        <Body4>Template Found: </Body4>
                        <Body3>{template.name}</Body3>
                      </div>
                    }
                  />
                  {template.recipients.map((recipient, index) => (
                    <Row key={`recipient-${index}`}>
                      <Col span={6}>
                        <Body4>{recipient.name}</Body4>
                      </Col>
                      <Col span={10}>
                        <Body3>{recipient.roleName}</Body3>
                      </Col>
                      <Col span={8}>
                        {recipient.isOutsideSigner && (
                          <span className={outsideSignerStyles.outsideSigner}>
                            <Body3>Outside Signer</Body3>
                            <span className={outsideSignerStyles.infoIcon}>
                              <Tooltip
                                title="Outside signers will receive an email from DocuSign to sign their form outside of Onboard. You will still be able to track their form signing progress within Onboard"
                                placement="top"
                                arrowPointAtCenter
                                overlayClassName={outsideSignerStyles.toolTip}
                              >
                                <InfoOutlineIcon />
                              </Tooltip>
                            </span>
                          </span>
                        )}
                      </Col>
                    </Row>
                  ))}
                </StackY>
              )}
            </div>

            <div aria-live="assertive">
              {errorCreatingForm && <ErrorMessage size="middle">{GenericErrorCopy2}</ErrorMessage>}
              {template && !areRecipientEmailsValid && (
                <ErrorMessage size="middle">
                  The template has an invalid email. Please review and correct the template and try
                  again.
                </ErrorMessage>
              )}
            </div>
          </StackY>
          <StackX dist={8}>
            <Button
              loading={isCreateOnboardFormLoading}
              disabled={
                !formik.isValid || !template || isTemplateLookupLoading || !areRecipientEmailsValid
              }
              type="primary"
              htmlType="button"
              size="middle"
              onClick={formik.submitForm}
            >
              Save and Link Form
            </Button>

            <Button onClick={cancelAction} size="middle" type="text">
              Cancel
            </Button>
          </StackX>
        </StackY>
      </Form>
    </div>
  );
};
