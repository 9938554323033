import { DateTime } from "luxon";
import { useCallback } from "react";
import { clientValidationSchema } from "shared/validation/client";

import { isPeakSeason } from "../../../../shared/utils/client";
import { Badge } from "../../components/Badge/Badge";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import { TextArea } from "../../components/Form/TextArea";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";

import { UnsavedChangesModal } from "./UnsavedChangesModal";
import { useClientUtils } from "./useClientUtils";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormEventHandler, Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";

const installCallValidationSchema = clientValidationSchema.pick([
  "installCallSelection",
  "installCallPeople",
  "installCallTopics",
  "installCallSchedule",
]);

const getClientPropertiesToUpdate = (client: Client) => {
  const { installCallSelection, installCallSchedule, installCallTopics, installCallPeople } =
    client;
  return {
    installCallSelection,
    installCallSchedule,
    installCallTopics,
    installCallPeople,
  };
};

export type installCallProps = {
  client: Client;
  contactType: string;
  isInitialSave: boolean;
  onInitialSave: Dispatch<SetStateAction<boolean>>;
  toggleEditing: () => void;
  updateClient: UpdateClientFunc;
  trackElementClicked: (options: ElementClickedOptions) => void;
};

export function InstallCallForm({
  client,
  contactType,
  isInitialSave,
  onInitialSave,
  toggleEditing,
  updateClient,
  trackElementClicked,
}: installCallProps) {
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: "Not Started",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const toggleAndScroll = () => {
    toggleEditing();
    window.scrollTo(0, 0);
  };

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: installCallValidationSchema,
    type: "Ongoing Service Call",
    track,
    onSuccessCallback: () => {
      if (isInitialSave) onInitialSave(true);
      toggleAndScroll();
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    await formik.setFieldValue("installCallSelection", "YES", true);
    formik.handleSubmit();
  };

  const handleCancel = () => {
    track("Cancel");
    if (formik.dirty) {
      const proceed = window.confirm("There are unsaved changes. Are you sure you want to cancel?");
      if (!proceed) {
        track("Abort cancel");
        return;
      } else {
        track("Confirm cancel");
      }
    }
    formik.resetForm();
    toggleAndScroll();
  };

  const installCallSLA = isPeakSeason(DateTime.local()) ? "48-72 hours" : "24-48 hours";

  return (
    <>
      <UnsavedChangesModal form={formik} />
      <HubCard>
        <HubCardHeader
          title="Tell us about your needs"
          description={
            <p>
              Your {contactType} will reach out to you to schedule. You can help us coordinate your
              call by leaving your preferred dates and times, entering any specific topics you’d
              like to cover, and who you would like to include.
            </p>
          }
          badge={<Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />}
          hasDivider
        ></HubCardHeader>
        <form onSubmit={handleSubmit}>
          <StackY dist={40}>
            <TextArea
              name="installCallSchedule"
              label="What dates and times work for you?"
              labelBottomText={`Please include your time zone. Our team will acknowledge your request within ${installCallSLA} to coordinate the meeting.`}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.installCallSchedule || undefined}
              touched={undefined}
              error={undefined}
            />
            <TextArea
              name="installCallTopics"
              label="Are there specific topics you want to be sure that are covered?"
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.installCallTopics || undefined}
              touched={undefined}
              error={undefined}
            />
            <TextArea
              name="installCallPeople"
              label="Who else should we include on the invite?"
              labelBottomText="Please feel free to include the name and email of any parties heavily involved in your
              ongoing administration for things like billing, claims, and managing employees."
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              value={formik.values.installCallPeople || undefined}
              touched={undefined}
              error={undefined}
            />

            {formik.status && <LoadingError type="component" title={formik.status} />}
          </StackY>
          <StackX dist={8} className="mt-32">
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              aria-label={
                formik.isSubmitting ? (isInitialSave ? "Submit Request" : "Save") : undefined
              }
            >
              {formik.isSubmitting ? "" : isInitialSave ? "Submit Request" : "Save"}
            </Button>
            <Button type="text" size="middle" onClick={handleCancel} disabled={formik.isSubmitting}>
              Cancel
            </Button>
          </StackX>
        </form>
      </HubCard>
    </>
  );
}
