import { getCustomBannerMessageForNIGOReasons } from "../../../components/Badge/getCustomBannerMessageForNIGOReasons";
import type { NIGOReason } from "shared/types/Client";
import type {
  MetadataStatus,
  OnboardingFormWithMetadata,
  OnboardingFormWithIndex,
} from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";
import type { User } from "shared/types/User";

type FormRecentAction = {
  updatedBy: User | null;
  updatedAt: Date | null;
};

export const getVoidedActionValues = (
  onboardingForm: OnboardingFormWithMetadata,
): FormRecentAction => {
  if (
    !onboardingForm.voidedActorName ||
    !onboardingForm.voidedActorEmail ||
    !onboardingForm.voidedDateTime
  ) {
    return {
      updatedBy: null,
      updatedAt: null,
    };
  }

  //  This info comes from DocuSign we fill the values required to show the last touch popover
  const user: User = {
    id: "",
    firstName: onboardingForm.voidedActorName ?? "",
    lastName: "",
    email: onboardingForm.voidedActorEmail ?? "",
    role: "BEN_ADMIN",
  };

  return {
    updatedBy: user,
    updatedAt: onboardingForm.voidedDateTime,
  };
};

export const getLastActionRecipientValues = (
  recipients: ReadonlyArray<RecipientWithMetadata>,
): FormRecentAction | null => {
  const sortedRecipients = [...recipients].sort((recipientA, recipientB) => {
    if (
      recipientA.metadata.lastAction.date == null &&
      recipientB.metadata.lastAction.date == null
    ) {
      return 0;
    } else if (recipientA.metadata.lastAction.date == null) {
      return 1;
    } else if (recipientB.metadata.lastAction.date == null) {
      return -1;
    }
    return (
      recipientB.metadata.lastAction.date.getTime() - recipientA.metadata.lastAction.date.getTime()
    );
  });

  return sortedRecipients[0]
    ? {
        updatedBy: recipientToUser(sortedRecipients[0]),
        updatedAt: sortedRecipients[0].metadata.lastAction.date,
      }
    : null;
};

const recipientToUser = (recipient: RecipientWithMetadata): User => ({
  id: recipient.docusignRecipientId,
  firstName: recipient.name,
  // Empty since the recipient.name has the full name of the recipient and we use it to concatenate the first and last name
  lastName: "",
  email: recipient.email,
  role: "BEN_ADMIN",
});

const addOriginalFormIndex = (
  forms: readonly OnboardingFormWithMetadata[],
): Array<OnboardingFormWithIndex> =>
  forms.map((onboardingForm, index) => ({ ...onboardingForm, formIndex: index }));

// Show the voided and declined forms at the end but with their original index number
export const sortOnboardingFormByStatus = (
  onboardingForms: readonly OnboardingFormWithMetadata[],
) => {
  const onboardingFormsWithIndex = addOriginalFormIndex(onboardingForms);
  return [...onboardingFormsWithIndex].sort((a, b) => {
    if (["VOIDED", "DECLINED"].includes(a.status)) {
      return 1;
    }
    if (["VOIDED", "DECLINED"].includes(b.status)) {
      return -1;
    }

    return 0;
  });
};

export const getOnboardingFormName = (
  onboardingForm: OnboardingFormWithMetadata,
  index: number,
) => {
  const action = ["in-review", "completed"].includes(onboardingForm.metadata.status)
    ? "Download"
    : "Complete";
  const number = index > 0 ? `#${index + 1}` : "";

  return `${action} your Onboarding Form ${number}`;
};

export const getOnboardingFormBadgeMessage = (
  status: MetadataStatus,
  nigoReasons: NIGOReason[] | null | undefined,
) => {
  switch (status) {
    case "in-review":
      return "Sun Life will review your form and let you know if anything else is required from you.";
    case "nigo": {
      const record: Partial<Record<NIGOReason, string>> = {
        "BA Signature Missing":
          "Your Onboarding Forms need to be completed and signed by the primary Benefits Administrator.  Access the forms by clicking the View/Edit button, review and sign the forms.",
        "Broker Signature Missing":
          "Your Onboarding Forms need to be completed and signed by your Broker.  Access the forms by clicking the View/Edit button, review and sign the forms.",
        "Paperwork Missing Information":
          "Your Onboarding Form needs you and/or your broker’s attention. Your Implementation Consultant will reach out to you via email with more information on what is needed.",
      };
      const customBannerMessage = getCustomBannerMessageForNIGOReasons(record, nigoReasons);
      return customBannerMessage;
    }
    case "voided":
      return "This form was voided. Your implementation consultant will reach out with next steps.";
    case "declined":
      return "This form was declined. Your implementation consultant will reach out with next steps.";
    default:
      return null;
  }
};
