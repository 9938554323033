import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Button } from "../../../components/Button/Button";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import { Modal } from "../../../components/Modal/Modal";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3, Body2 } from "../../../components/Typography/Typography";
import type {
  OnboardingFormDocumentId,
  OnboardingFormDocumentMetadata,
} from "../../../../../shared/types/OnboardingFormDocument";

type DownloadFormModalProps = {
  downloadCombinedDocument: () => Promise<unknown>;
  downloadIndividualDocument: (documentId: OnboardingFormDocumentId) => void;
  formDocuments: ReadonlyArray<OnboardingFormDocumentMetadata>;
  isVisible: boolean;
  isDownloading: boolean;
  showDownloadErrorMsg: boolean;
  onClose: () => void;
};

export const DownloadFormModal = ({
  downloadCombinedDocument,
  downloadIndividualDocument,
  formDocuments,
  isVisible,
  isDownloading,
  showDownloadErrorMsg,
  onClose,
}: DownloadFormModalProps) => {
  return (
    <Modal
      maskClosable={false}
      title=""
      open={isVisible}
      footer={null}
      focusTriggerAfterClose={false}
      onCancel={onClose}
    >
      <StackY dist={16} className="w-full">
        <StackY dist={8}>
          <p>
            <Body2>Download entire PDF:</Body2>
            <br />
            <Body3>Get the entire completed form from DocuSign.</Body3>
          </p>
          <div>
            <ButtonOld onClick={downloadCombinedDocument} type="link-inline">
              Download all
            </ButtonOld>
          </div>
        </StackY>

        <Row align="middle">
          <Col xs={11}>
            <hr />
          </Col>
          <Col xs={2}>
            <div className="text-center">
              <Body2 colorSecondary>or</Body2>
            </div>
          </Col>
          <Col xs={11}>
            <hr />
          </Col>
        </Row>

        <StackY dist={8}>
          <p>
            <Body2>Download by section:</Body2>
            <br />
            <Body3>Get an individual section from the full DocuSign envelope.</Body3>
          </p>
          {formDocuments.map(({ id, name }) => (
            <div key={id}>
              <ButtonOld onClick={() => downloadIndividualDocument(id)} type="link-inline">
                {name}
              </ButtonOld>
            </div>
          ))}
        </StackY>

        {showDownloadErrorMsg && <ErrorMessage>Failed to download the form</ErrorMessage>}

        <div className="mt-15">
          <Button type="tertiary" loading={isDownloading} onClick={onClose} size="middle">
            Done
          </Button>
        </div>
      </StackY>
    </Modal>
  );
};
