import { Button } from "client/src/components/Button/Button";
import * as renameItemStyles from "client/src/components/RenameItem/RenameItem.module.less";
import { RenameFile } from "client/src/domain/Document/RenameFile/RenameFile";
import { useToggler } from "client/src/hooks/useToggler";
import { useMemo } from "react";

import { rejectNullableValues } from "shared/utils/utils";
import {
  categoryNameByCategoryType,
  isDocumentCategory,
} from "../../../../../shared/types/Document";
import {
  documentBenefitTypesNames,
  isDocumentBenefitType,
} from "../../../../../shared/types/DocumentBenefitType";
import { exhaustiveCheck } from "../../../../../shared/utils/exhaustiveCheck";
import { Anchor } from "../../../components/Anchor/Anchor";
import { DownloadIcon } from "../../../components/Icons/DownloadIcon";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import { NewBadge } from "../../../components/NewBadge/NewBadge";
import { ResourcesTable } from "../../../components/SlobTable/ResourcesTable";
import { Tooltip } from "../../../components/Tooltip/Tooltip";

import * as styles from "./downloadFileTable.module.less";

import type { PolicyId } from "../../../../../shared/types/Client";
import type { DocumentCategory } from "../../../../../shared/types/Document";
import type { DocumentBenefitType } from "../../../../../shared/types/DocumentBenefitType";
import type { WebLinkCategory } from "../../../../../shared/types/WebLink";
import type { CensusSource, ProcessStatus } from "../../../../../shared/validation/document";
import type { ReactNode } from "react";

type FileRowData = {
  type: "document";
  key: string | number;
  id: string;
  name: string;
  addedBy: string;
  isNew: boolean;
  category: DocumentCategory;
  policyIds?: PolicyId[];
  processStatus?: ProcessStatus | null;
  updatedAt: number;
  source?: CensusSource | string | null;
  benefitTypes: string[] | null;
};

type WebLinkRowData = {
  type: "webLink";
  key: string | number;
  id: string;
  text: string;
  url: string;
  addedBy: string;
  isNew: boolean;
  category: WebLinkCategory;
  policyIds?: PolicyId[];
  benefitTypes: string[] | null;
  updatedAt: number;
  cta?: "view" | "download";
};

export type ResourceRowData = FileRowData | WebLinkRowData;

export type DownloadFileTableProps = {
  resourcesList: ResourceRowData[];
  showDownloadAllButton: boolean;
  showDownloadButton?: boolean;
  showHeader?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  withSource?: boolean;
  showProcessStatusValidation?: boolean;
  withAddedBy: boolean;
  downloadDocument: (id: string) => void;
  downloadExpandedRowRender?: (record: ResourceRowData) => ReactNode;
  downloadAllAction: () => void;
  deleteDocument: (
    id: string,
    name: string,
    category: DocumentCategory,
    policyIds?: PolicyId[],
  ) => void;
  renameDocument: (id: string, name: string) => Promise<void>;
  deleteWebLink: (id: string, name: string, category: WebLinkCategory) => void;
  track: (buttonLabel: string) => void;
  columnTitleOverrides?: { title: string; key: string }[];
  showResourceTypeColumn?: boolean;
  showBenefitTypeColumn?: boolean;
};

export const DownloadFileTable = ({
  resourcesList,
  showDownloadAllButton,
  showDownloadButton = true,
  downloadDocument,
  downloadAllAction,
  deleteDocument,
  renameDocument,
  deleteWebLink,
  showHeader,
  disabled,
  readonly,
  withAddedBy,
  withSource = false,
  showProcessStatusValidation = false,
  track,
  columnTitleOverrides,
  showResourceTypeColumn = false,
  showBenefitTypeColumn = false,
  downloadExpandedRowRender,
}: DownloadFileTableProps) => {
  const [renameFileResetToken, resetRenameFile] = useToggler();

  const hasWebLinks = useMemo(
    () => resourcesList.some(({ type }) => type === "webLink"),
    [resourcesList],
  );
  const hasDocumentsToDownload = useMemo(
    () => resourcesList.some(({ type }) => type === "document"),
    [resourcesList],
  );

  const expandableConfig = downloadExpandedRowRender
    ? {
        expandedRowRender: downloadExpandedRowRender,
        defaultExpandAllRows: true,
        indentSize: 0,
        rowExpandable: (record: ResourceRowData) =>
          Boolean(
            showProcessStatusValidation &&
              record.type === "document" &&
              record.processStatus &&
              record.processStatus !== "SUBMITTED",
          ),
      }
    : undefined;

  const columnHeaders = [
    {
      title: columnTitleOverrides?.find((chto) => chto.key === "name")?.title ?? "Name",
      dataIndex: "name",
      key: "name",
      render: (_text: unknown, record: ResourceRowData) => {
        if (record.type === "webLink") {
          return <div className={styles.nameColumn}>{record.text}</div>;
        }

        return (
          <div className={styles.nameColumn}>
            {readonly ? (
              <>
                {record.isNew && (
                  <span className="mr-8">
                    <NewBadge />
                  </span>
                )}
                {record.name}
              </>
            ) : (
              <RenameFile
                key={renameFileResetToken.toString()}
                record={record}
                renameDocument={renameDocument}
              />
            )}
          </div>
        );
      },
    },
    withAddedBy
      ? {
          title: columnTitleOverrides?.find((chto) => chto.key === "addedBy")?.title ?? "Added By",
          dataIndex: "addedBy",
          key: "addedBy",
          onCell: () => ({ width: "50%" }),
        }
      : null,
    withSource
      ? {
          title:
            columnTitleOverrides?.find((chto) => chto.key === "source")?.title ?? "Template Source",
          dataIndex: "source",
          key: "source",
          onCell: () => ({ width: "50%" }),
          render: (censusSource: CensusSource | string | null, _record: ResourceRowData) => {
            switch (censusSource) {
              case "MDT":
                return "Sun Life template";
              case "ENROLLMENT_FORM":
                return "Enrollment form";
              case "OTHER":
                return "Other";
              default:
                return censusSource;
            }
          },
        }
      : null,
    showResourceTypeColumn
      ? {
          title:
            columnTitleOverrides?.find((chto) => chto.key === "resourceTypeColumn")?.title ??
            "Resource Type",
          dataIndex: "category",
          key: "resourceType",
          width: "160px",
          render: (text: unknown, { category }: ResourceRowData) => {
            return isDocumentCategory(category) ? categoryNameByCategoryType[category] : "";
          },
        }
      : null,
    showBenefitTypeColumn
      ? {
          title:
            columnTitleOverrides?.find((chto) => chto.key === "benefitTypes")?.title ??
            "Benefit Types",
          dataIndex: "benefitTypes",
          key: "benefitTypes",
          width: "160px",
          render: (text: unknown, { benefitTypes }: ResourceRowData) => {
            const documentBenefitTypes = benefitTypes?.filter(isDocumentBenefitType) ?? [];

            return renderBenefitTypeCell(documentBenefitTypes);
          },
        }
      : null,
    {
      width: "60px",
      title: showDownloadAllButton ? (
        hasWebLinks ? (
          <Tooltip
            title={
              hasDocumentsToDownload
                ? "Links like video clips will not be included in your download."
                : "Download all unavailable when only links are listed"
            }
            placement="bottom"
          >
            <Button
              type="text-only"
              onClick={(e) => {
                e.preventDefault();
                resetRenameFile();
                downloadAllAction();
              }}
              disabled={disabled || !hasDocumentsToDownload}
            >
              Download All
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="text-only"
            onClick={(e) => {
              e.preventDefault();
              resetRenameFile();
              downloadAllAction();
            }}
            disabled={disabled}
          >
            Download All
          </Button>
        )
      ) : (
        ""
      ),
      key: "action",
      colSpan: 2,
      onCell: () => ({ colSpan: 2 }),
      render: (text: unknown, record: ResourceRowData) => {
        if (record.type === "document") {
          return (
            <div className={styles.actions}>
              {showDownloadButton && (
                <Tooltip title="Download" placement="bottom">
                  <span>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        track("Download " + record.name);
                        resetRenameFile();
                        downloadDocument(record.id);
                      }}
                      aria-label="Download"
                      className="btn-reset"
                      disabled={disabled}
                    >
                      <DownloadIcon />
                    </button>
                  </span>
                </Tooltip>
              )}

              {!readonly && (
                <span>
                  <Tooltip title="Delete" placement="bottom">
                    <span>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          track("Delete " + record.name);
                          resetRenameFile();
                          deleteDocument(record.id, record.name, record.category, record.policyIds);
                        }}
                        aria-label="Delete"
                        className="btn-reset"
                        disabled={disabled}
                      >
                        <TrashIcon />
                      </button>
                    </span>
                  </Tooltip>
                </span>
              )}
            </div>
          );
        } else if (record.type === "webLink") {
          return (
            <div className={styles.actions}>
              <Anchor
                variant="bold"
                href={record.url}
                newTabIcon={false}
                target="_blank"
                onClick={() => {
                  track("View " + record.text);
                }}
              >
                {(record.cta == null || record.cta === "view") && `View`}
                {record.cta === "download" && <DownloadIcon />}
              </Anchor>

              {!readonly && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    track("Delete " + record.text);
                    deleteWebLink(record.id, record.text, record.category);
                  }}
                  title="Delete link"
                  aria-label="Delete link"
                  className="btn-reset"
                  disabled={disabled}
                >
                  <TrashIcon />
                </button>
              )}
            </div>
          );
        } else {
          exhaustiveCheck(record);
          return null;
        }
      },
    },
  ];

  const columnHeadersFiltered = columnHeaders.filter(rejectNullableValues);

  return (
    <>
      {resourcesList.length > 0 && (
        <ResourcesTable
          showHeader={showHeader}
          dataSource={resourcesList}
          expandable={expandableConfig}
          columns={columnHeadersFiltered}
          pagination={{ hideOnSinglePage: true }}
          rowClassName={renameItemStyles.renameButton_hoverableContainer}
        />
      )}
    </>
  );
};

export const renderBenefitTypeCell = (recordBenefitTypes: DocumentBenefitType[]) => {
  if (!recordBenefitTypes.length) {
    return <span>None</span>;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
  const firstDocumentTypeName = documentBenefitTypesNames[recordBenefitTypes[0]!];
  const description =
    recordBenefitTypes.length > 0
      ? recordBenefitTypes.length > 1
        ? `${firstDocumentTypeName} +${recordBenefitTypes.length - 1}`
        : firstDocumentTypeName
      : null;
  const allBenefitTypeNames = recordBenefitTypes
    .map((b) => documentBenefitTypesNames[b])
    .join(", ");
  return <span title={allBenefitTypeNames}>{description}</span>;
};
