import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import React, { useState } from "react";
import { getIsOwnerUser } from "shared/rbac/rbac";
import { reassignRecipientValidationV2 } from "shared/validation/onboardingForm";

import { SLF_ADMIN, SLF_OWNER, type UserRole } from "../../../../../shared/rbac/roles";
import { Button } from "../../../components/Button/Button";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import { ReactComponent as LeftRightArrows } from "../../../components/Icons/LeftRightArrows.svg";
import { Loading } from "../../../components/Loading/Loading";
import { Modal } from "../../../components/Modal/Modal";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useReassignRecipients } from "../../../hooks/onboardingForms";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useGetBenAdmins, useGetBrokers, useGetUsersByRole } from "../../../hooks/user";
import { debounce } from "../../../utils/debounce";
import { ReassignRecipientFormV2 } from "../Form/ReassignRecipientFormV2";

import * as styles from "./modal.module.less";

import type { BenAdmin, SlfInternal } from "../../../../../shared/types/User";
import type { ClientId } from "shared/types/Client";
import type { OnboardingFormId } from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

export type BasicUserInfo = {
  id: string;
  name: string;
  email: string;
};

const getBasicUserInfo = (users: SlfInternal[] | BenAdmin[]): BasicUserInfo[] =>
  users.map(({ id, firstName, lastName, email }) => ({
    id,
    name: `${firstName} ${lastName}`,
    email,
  }));

type ReassignRecipientButtonProps = {
  onSuccess: () => unknown;
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  onClick: () => void;
  userRole: UserRole;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  track: (buttonLabel: string) => void;
};

export const ReassignRecipientModal: React.FC<ReassignRecipientButtonProps> = ({
  clientId,
  onboardingFormId,
  onSuccess,
  onClick,
  userRole,
  onboardingFormRecipients,
  track,
}) => {
  const [searchParam, setSearchParam] = useState<string>("");
  const queryParams = {
    page: 1,
    pageSize: 100,
    search: "",
  };
  const queryParamsWithClientId = {
    ...queryParams,
    clientId,
  };
  const queryParamsWithSearch = {
    ...queryParams,
    search: searchParam,
  };

  const { data: brokers, isLoading: isLoadingBrokers } = useGetBrokers(queryParamsWithClientId);
  const { data: benAdmins, isLoading: isLoadingBenAdmins } =
    useGetBenAdmins(queryParamsWithClientId);

  const {
    data: slfAdmins,
    isLoading: isLoadingSlfAdmins,
    refetch: refetchSlfAdmins,
  } = useGetUsersByRole(SLF_ADMIN, queryParamsWithSearch);

  const {
    data: slfOwners,
    isInitialLoading: isLoadingSlfOwners,
    refetch: refetchSlfOwners,
  } = useGetUsersByRole(SLF_OWNER, queryParamsWithSearch, {
    enabled: getIsOwnerUser({ role: userRole }),
  });

  const [isModalVisible, setModalVisible] = useState(false);
  const { mutateAsync: reassignRecipient } = useReassignRecipients(clientId, onboardingFormId);

  const isLoadingUsers =
    isLoadingBenAdmins || isLoadingBrokers || isLoadingSlfAdmins || isLoadingSlfOwners;
  const adminsBasicInfoUsers = getBasicUserInfo(slfAdmins ?? []);
  const ownersBasicInfoUsers = getBasicUserInfo(slfOwners ?? []);
  const brokersBasicInfoUsers = getBasicUserInfo(brokers?.data ?? []);
  const benAdminBasicInfoUsers = getBasicUserInfo(benAdmins?.data ?? []);

  const slobUsers = ownersBasicInfoUsers.concat(
    brokersBasicInfoUsers,
    benAdminBasicInfoUsers,
    adminsBasicInfoUsers,
  );

  const reassignFormik = useSlobFormik({
    initialValues: {
      previousRecipientId: "",
      name: "",
      email: "",
      newSignerType: "",
      newSignerOnboardId: "",
    },
    validationSchema: reassignRecipientValidationV2,
    async onSubmit(values) {
      try {
        if (values.newSignerType === "EXISTING_ONBOARD_USER") {
          const slobUser = slobUsers.find(({ id }) => id === values.newSignerOnboardId);
          if (!slobUser) {
            return;
          }

          await reassignRecipient({
            data: {
              email: slobUser.email,
              name: slobUser.name,
              outsideSlobSigner: false,
              previousRecipientId: values.previousRecipientId,
            },
          });
        } else {
          await reassignRecipient({
            data: {
              email: values.email ?? "",
              name: values.name ?? "",
              outsideSlobSigner: true,
              previousRecipientId: values.previousRecipientId,
            },
          });
        }
        void slobMessage.success("Successfully reassigned the recipient");
        onSuccess();
        setModalVisible(false);
      } catch (error) {
        const errorMsg = ResponseError.getUserFacingErrorMessage(
          error,
          "An error trying to reassign the recipient",
        );
        reassignFormik.setStatus(errorMsg);
      }
    },
  });

  const assignButtonClickAction = () => {
    setModalVisible(true);
    onClick();
    track("Reassign");
  };

  const onCancel = () => {
    setModalVisible(false);
    reassignFormik.resetForm();
    track("Cancel Save and Reassign");
  };

  const setSearchAndRefetch = debounce<string>((searchValue: string) => {
    setSearchParam(searchValue);
    void refetchSlfAdmins();
    void refetchSlfOwners();
  }, 250);

  return (
    <>
      <Button type="text" onClick={assignButtonClickAction}>
        <div className={clsx(["stack-x-12", styles.popoverLinkRow])}>
          <LeftRightArrows /> <span className="body3">Reassign</span>
        </div>
      </Button>
      <Modal
        maskClosable={false}
        title="Assign to Someone Else"
        disableClose={reassignFormik.isSubmitting}
        open={isModalVisible}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={onCancel}
      >
        {isLoadingUsers ? (
          <Loading />
        ) : (
          <form onSubmit={reassignFormik.handleSubmit}>
            <StackY dist={16} className="w-full">
              <ReassignRecipientFormV2
                formik={reassignFormik}
                onboardingFormRecipients={onboardingFormRecipients}
                slobUsers={slobUsers}
                setSearchParam={setSearchAndRefetch}
              />
              <StackX dist={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={reassignFormik.isSubmitting}
                  disabled={
                    !reassignFormik.dirty || !reassignFormik.isValid || reassignFormik.isSubmitting
                  }
                  onClick={() => {
                    track("Save and Reassign");
                  }}
                >
                  Save and Reassign
                </Button>
                <Button onClick={onCancel} type="text" disabled={reassignFormik.isSubmitting}>
                  Cancel
                </Button>
              </StackX>
              {reassignFormik.status && <ErrorMessage>{reassignFormik.status}</ErrorMessage>}
            </StackY>
          </form>
        )}
      </Modal>
    </>
  );
};
