export function getSubjectAndPreviewTextMarkup(args: {
  subject: string;
  previewText: string | null;
}) {
  const { subject, previewText } = args;
  return `<!-- strip start -->
<div style="margin: 16px; font-family: sans-serif;">
  <p><strong>Subject:</strong> ${subject}</p>
  <p><strong>Preview text:</strong> ${previewText || ""}</p>
</div>
<hr style="margin-bottom: 0" />
<!-- strip end -->

`;
}

export function removeSubjectAndPreviewTextMarkup(html: string) {
  const regex = /<!-- strip start -->([\s\S.]*)<!-- strip end -->/g;
  const cleanedHTML = html.replace(regex, "").trim();
  return cleanedHTML;
}
