import React from "react";

import { RecipientRow } from "./RecipientRow";
import * as styles from "./recipientTable.module.less";

import type { VoidedFormInfo } from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

type RecipientTableVariant =
  | "no-order"
  | "current"
  | "waiting"
  | "completed"
  | "declined"
  | "voided"
  | "other-signers";

const getTableName: { [key in RecipientTableVariant]: string } = {
  current: "Current",
  waiting: "Waiting",
  completed: "Complete",
  declined: "Declined by",
  voided: "Voided by",
  "other-signers": "Other signers",
  "no-order": "",
};

const getTableMessage = (variant: RecipientTableVariant) => {
  switch (variant) {
    case "completed":
      return "The following recipients have completed their portion of the form";
    case "waiting":
      return "The following recipients are waiting on others";
    case "current":
      return "The following recipients need to complete their portion of the form";
    default:
      return "";
  }
};

const getRecipientsByVariant = (
  recipients: ReadonlyArray<RecipientWithMetadata>,
  currentRoutingOrder: string,
  variant: RecipientTableVariant,
  voidedActorEmail: string | null | undefined,
): ReadonlyArray<RecipientWithMetadata> => {
  switch (variant) {
    case "other-signers":
      return recipients.filter(
        ({ status, email }) => !(status === "DECLINED" || email === voidedActorEmail),
      );
    case "declined":
      return recipients.filter(({ status }) => status === "DECLINED");
    case "current":
      return recipients.filter(
        ({ status, routingOrder }) =>
          currentRoutingOrder === routingOrder && status !== "COMPLETED",
      );
    case "waiting":
      return recipients.filter(
        ({ status, routingOrder }) =>
          currentRoutingOrder !== routingOrder && status !== "COMPLETED",
      );
    case "completed":
      return recipients.filter(({ status }) => status === "COMPLETED");
    default:
      return recipients;
  }
};

const getVoidedRecipient = (
  recipients: ReadonlyArray<RecipientWithMetadata>,
  voidedFormInfo: VoidedFormInfo,
): VoidedFormInfo => {
  // Anyone with DocuSign permission can void an envelope. We check if is one of the recipients of the envelope to get the rolename. In case is someone outside the envelope we can't populate the rolename.
  const envelopeVoidedRecipient = recipients.find(({ email }) => email === voidedFormInfo.email);
  return { ...voidedFormInfo, roleName: envelopeVoidedRecipient?.roleName };
};

type RecipientTableProps = {
  variant: RecipientTableVariant;
  currentRoutingOrder: string;
  recipients: ReadonlyArray<RecipientWithMetadata>;
  showSubText?: boolean;
  voidedFormInfo?: VoidedFormInfo;
  showMismatchedRecipients?: boolean;
};

export const RecipientTable: React.FC<RecipientTableProps> = ({
  variant,
  currentRoutingOrder,
  recipients,
  showSubText = true,
  voidedFormInfo,
  showMismatchedRecipients = false,
}) => {
  const title = getTableName[variant];
  const message = getTableMessage(variant);
  const tableRecipients = getRecipientsByVariant(
    recipients,
    currentRoutingOrder,
    variant,
    voidedFormInfo?.email,
  );
  const voidedRecipient = voidedFormInfo ? getVoidedRecipient(recipients, voidedFormInfo) : null;

  if (!tableRecipients.length) {
    return null;
  }

  return (
    <div>
      {title && (
        <p className={styles.descriptionText}>
          <span className="body2">{title}</span>
          <br />
          {showSubText && <span className="body5">{message}</span>}
        </p>
      )}
      {variant === "voided" && voidedRecipient ? (
        <RecipientRow
          name={voidedRecipient.name ?? ""}
          roleName={voidedRecipient.roleName ?? ""}
          action="voided"
          date={voidedRecipient.dateTime}
          reason={voidedRecipient.reason}
        />
      ) : (
        tableRecipients.map((recipient) => (
          <RecipientRow
            key={recipient.id}
            name={recipient.name}
            roleName={recipient.roleName}
            action={recipient.metadata.lastAction.action}
            date={recipient.metadata.lastAction.date}
            reason={recipient.declinedReason}
            isOutsideSigner={recipient.docusignClientUserId === null}
            isEmailMismatch={recipient.metadata.isEmailMismatch && showMismatchedRecipients}
          />
        ))
      )}
    </div>
  );
};
