import { faChevronLeft, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { ReactComponent as BlueRightArrow } from "client/src/components/Icons/BlueRightArrow.svg";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { ReviewDisabilityContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Disability/ReviewDisabilityContributions";
import { ReviewTierContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/ReviewTierContributions";
import { EIFClassBuilderItemEligibility } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemEligibility";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { getIn } from "formik";
import {
  getIsLifeADDContributionBenefitType,
  getIsDHMOBenefitType,
  getIsDisabilityContributionBenefitType,
  isBenefitTypeEIF,
} from "shared/types/BenefitTypes";
import {
  waitingPeriodTypeToReadableType,
  type EmployeeClassPlanWithPlan,
} from "shared/types/EmployeeClassPlan";
import {
  eligibilityEmployeeDefinitions,
  type EligibilityEmployeeDefinition,
  eligibilityEmployeeDefinitionsValuesRecord,
  categoryCodeToBenefitType,
  getAdminClassId,
  isBenefitMappingToQPSSupported,
} from "shared/types/QPSClass";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { assertIsDefined, unique, mapValues } from "shared/utils/utils";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import * as styles from "./eifPushToQPS.module.less";
import type { BenefitTypeEIF } from "@prisma/client";
import type { Dispatch, SetStateAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type { EmployeeClass, EmployeeClassId } from "shared/types/EmployeeClass";
import type { EmployeeClassPlan } from "shared/types/EmployeeClassPlan";
import type { QPSBasicClass, QPSBenefitCode } from "shared/types/QPSClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

// This benefits are automatically selected if the related benefit is selected
const qpsRelatedBenefitCode: Partial<Record<QPSBenefitCode, QPSBenefitCode[]>> = {
  EMPCRTCLILL: ["SPSCRTCLILL", "CHLDCRTCLILL"],
} as const;
const benefitCodesToHide: QPSBenefitCode[] = Object.values(qpsRelatedBenefitCode).flat();
export const SKIP_THIS_BENEFIT = "__SKIP_THIS_BENEFIT__";

type AssignBenefitsToAdminClassesFormValues = {
  eligibleEmployeeDefinition: EligibilityEmployeeDefinition;
  adminClassesId: Record<string, Array<string | typeof SKIP_THIS_BENEFIT>>;
};

export type AssignBenefitsToAdminClassesMapping = {
  employeeClassId: EmployeeClassId;
  formValues: AssignBenefitsToAdminClassesFormValues;
};

type EIFPushToQPSStepClassMappingProps = {
  client: Client;
  authUser: UserData | null;
  mapping: AssignBenefitsToAdminClassesMapping[];
  setMapping: Dispatch<SetStateAction<AssignBenefitsToAdminClassesMapping[]>>;
  employeeClasses: EmployeeClass[];
  qpsBasicClasses: QPSBasicClass[];
  featureToggles: ClientFeatureToggles;
  onPrevious: () => void;
  onNext: (mapping: AssignBenefitsToAdminClassesMapping[]) => void;
  employeeClassIndex: number;
  setEmployeeClassIndex: Dispatch<SetStateAction<number>>;
};

export function EIFPushToQPSStepClassMapping(props: EIFPushToQPSStepClassMappingProps) {
  const {
    client,
    authUser,
    mapping,
    setMapping,
    employeeClasses,
    qpsBasicClasses,
    featureToggles,
    onPrevious,
    onNext,
    employeeClassIndex,
    setEmployeeClassIndex,
  } = props;
  const employeeClass = employeeClasses[employeeClassIndex];
  assertIsDefined(employeeClass, "employeeClass");

  return (
    <div className="my-64 stack-y-20">
      <h1>Assign benefits to Admin Classes</h1>

      <p>
        Assign the benefits for each eligible employee group to its corresponding Admin Class in
        QPS.The values in QPS will be overwritten by the values in Onboard.
      </p>

      <EIFPushToQPSStep2Content
        key={employeeClassIndex}
        employeeClassIndex={employeeClassIndex}
        client={client}
        authUser={authUser}
        employeeClasses={employeeClasses}
        qpsBasicClasses={qpsBasicClasses}
        employeeClass={employeeClass}
        currentMappingValues={mapping}
        initialValues={mapping[employeeClassIndex]?.formValues}
        featureToggles={featureToggles}
        onPrevious={() => {
          if (employeeClassIndex === 0) {
            onPrevious();
          } else {
            setEmployeeClassIndex((prev) => prev - 1);
          }
          window.scrollTo({ top: 150, behavior: "smooth" });
        }}
        onNext={(currentFormValues) => {
          setMapping((prev) => {
            const updatedMapping = prev.slice();
            updatedMapping[employeeClassIndex] = {
              employeeClassId: employeeClass.id,
              formValues: currentFormValues,
            };
            if (employeeClassIndex === employeeClasses.length - 1) {
              onNext(updatedMapping);
            }
            return updatedMapping;
          });

          if (employeeClassIndex < employeeClasses.length - 1) {
            setEmployeeClassIndex((prev) => prev + 1);
          }
          window.scrollTo({ top: 150, behavior: "smooth" });
        }}
      />
    </div>
  );
}

type EIFPushToQPSStep2ContentProps = {
  client: Client;
  authUser: UserData | null;
  employeeClasses: EmployeeClass[];
  employeeClass: EmployeeClass;
  qpsBasicClasses: QPSBasicClass[];
  employeeClassIndex: number;
  currentMappingValues: AssignBenefitsToAdminClassesMapping[];
  initialValues: AssignBenefitsToAdminClassesFormValues | undefined;
  featureToggles: ClientFeatureToggles;
  onPrevious: () => void;
  onNext: (formValues: AssignBenefitsToAdminClassesFormValues) => void;
};

export function EIFPushToQPSStep2Content(props: EIFPushToQPSStep2ContentProps) {
  const {
    client,
    authUser,
    employeeClasses,
    employeeClass,
    qpsBasicClasses,
    employeeClassIndex,
    initialValues,
    featureToggles,
    currentMappingValues,
    onPrevious,
    onNext,
  } = props;

  const benefitTypeToClassPlansMap = getBenefitTypeToClassPlansMap(employeeClass);
  const benefitTypeToClassPlansMapEntries = Array.from(benefitTypeToClassPlansMap.entries());

  const benefitTypeToQPSBasicClassMap = getQPSClassesPerBenefitTypeMap(
    qpsBasicClasses,
    featureToggles,
  );

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      eligibleEmployeeDefinition: Yup.mixed<EligibilityEmployeeDefinition>()
        .oneOf<EligibilityEmployeeDefinition>(
          eligibilityEmployeeDefinitions.slice(),
          "Please provide a response",
        )
        .nullable()
        .test(nullNotAllowedTestConfig()),
      adminClassesId: Yup.lazy((value) => {
        const spec = mapValues(value, (key) => {
          const basicClasses = isBenefitTypeEIF(key)
            ? getQPSClassByBenefitType(benefitTypeToQPSBasicClassMap, key)
            : [];

          if (basicClasses.length === 0) {
            return Yup.array().of(Yup.string().required()).required();
          }

          const schema = Yup.array()
            .of(
              Yup.mixed<string | typeof SKIP_THIS_BENEFIT>()
                .required("Please provide a response")
                .test(nullNotAllowedTestConfig()),
            )
            .min(1, "Please provide a response")
            .required();
          return schema;
        });
        const schema = Yup.object(spec).required();
        return schema;
      }),
    }),
    initialValues: initialValues || {
      eligibleEmployeeDefinition: null,
      adminClassesId: mapValues(Object.fromEntries(benefitTypeToClassPlansMap), () => []),
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const { eligibleEmployeeDefinition, adminClassesId } = values;
      assertIsDefined(eligibleEmployeeDefinition, "eligibleEmployeeDefinition");

      const updatedAdminClassesId = mapValues(adminClassesId, (_key, selectedQPSClassesId) =>
        selectedQPSClassesId
          .map((qpsClassId) => getAdminClassWithRelatedQPSClasses(qpsBasicClasses, qpsClassId))
          .flat(),
      );
      onNext({
        eligibleEmployeeDefinition,
        adminClassesId: updatedAdminClassesId,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="stack-y-20">
      <HubCard>
        <Body2 as="p">
          {featureToggles.BENEFIT_EXPLORER_SPANISH && employeeClass.groupName
            ? employeeClass.groupName
            : `Eligible employee group ${employeeClassIndex + 1}`}
        </Body2>

        <EIFClassBuilderItemEligibility
          client={client}
          employeeClass={employeeClass}
          changeSnapshot={null}
          authUser={authUser}
          showSingleClassBuilderName
        />
      </HubCard>

      <HubCard>
        <Body2 as="p">Eligible employee definition</Body2>

        <Body5 as="p">
          Choose the closest Eligible employee definition for your class to push to QPS. Eligible
          Employee Definition must be manually adjusted in QPS if custom language is needed.
        </Body5>

        <SlobSelect
          name="eligibleEmployeeDefinition"
          placeholder="Eligible employee definition"
          onChange={async (event) => {
            await formik.setFieldValue("eligibleEmployeeDefinition", event.value);
          }}
          options={eligibilityEmployeeDefinitions.map((value) => ({
            value,
            label: eligibilityEmployeeDefinitionsValuesRecord[value],
          }))}
          value={formik.values.eligibleEmployeeDefinition}
          touched={formik.touched.eligibleEmployeeDefinition}
          error={formik.errors.eligibleEmployeeDefinition}
          disabled={false}
        />
      </HubCard>

      {benefitTypeToClassPlansMapEntries.map(([benefitType, employeeClassPlans]) => {
        if (!employeeClassPlans) return null;
        assertIsDefined(employeeClassPlans[0], "employeeClassPlans[0]");

        const qpsBasicClassesForThisBenefitType = getQPSClassByBenefitType(
          benefitTypeToQPSBasicClassMap,
          benefitType,
        );

        const planNames = unique(qpsBasicClassesForThisBenefitType.map((q) => q.planDesignName));

        const relevantEmployeeClassPlan = employeeClass.employeeClassPlans.find(
          (ecp) => ecp.plan.benefitType === benefitType,
        );

        const showQPSOptions = planNames.length > 0;
        const checkboxIndex = `adminClassesId.${benefitType}`;
        const currentValue: string[] = getIn(formik.values, checkboxIndex) ?? [];
        const isSkipBenefitSelected = currentValue.includes(SKIP_THIS_BENEFIT);
        const disabledSkipBenefit = Boolean(
          currentValue.length && !currentValue.includes(SKIP_THIS_BENEFIT),
        );

        const isAllAdminClassesSelected = planNames.every((planName) => {
          const qpsClassesForThisPlanName = qpsBasicClassesForThisBenefitType.filter(
            (q) => q.planDesignName === planName,
          );

          return qpsClassesForThisPlanName.every((qpsBasicClass) => {
            const adminClassId = getAdminClassId(qpsBasicClass);
            const isChecked = currentValue.includes(adminClassId);
            return isChecked;
          });
        });

        const selectAllAction = async () => {
          if (isAllAdminClassesSelected) {
            await formik.setFieldValue(checkboxIndex, []);
            return;
          }

          const newValues = planNames.reduce<string[]>((acc, planName) => {
            const qpsClassesForThisPlanName = qpsBasicClassesForThisBenefitType.filter(
              (q) => q.planDesignName === planName,
            );

            const currentValues = currentMappingValues.map(({ employeeClassId, formValues }) => ({
              employeeClassId,
              adminClassIds: Object.values(formValues.adminClassesId).flat(),
            }));

            return qpsClassesForThisPlanName.reduce<string[]>((acc, qpsBasicClass) => {
              const adminClassId = getAdminClassId(qpsBasicClass);

              const isAlreadySelectedInOtherMap = currentValues.some(
                ({ adminClassIds, employeeClassId }) =>
                  adminClassIds.includes(adminClassId) && employeeClassId !== employeeClass.id,
              );

              if (isAlreadySelectedInOtherMap) {
                return acc;
              }

              return acc.concat(adminClassId);
            }, acc);
          }, []);

          await formik.setFieldValue(checkboxIndex, newValues);
        };

        return (
          <HubCard key={benefitType}>
            <Body2 as="p">{benefitTypeToCoverage[benefitType]}</Body2>

            {!isBenefitMappingToQPSSupported(benefitType, featureToggles) ? (
              <Body3>Not supported yet.</Body3>
            ) : (
              <Row>
                <Col span={10}>
                  <StackY dist={24}>
                    <Row justify="space-between">
                      <Col>
                        <Body2 as="p">Onboard</Body2>
                      </Col>
                    </Row>

                    <div>
                      <Body5 as="div">Waiting period</Body5>
                      <Body3 as="div">
                        {waitingPeriodTypeToReadableType(employeeClassPlans[0])}
                      </Body3>
                    </div>

                    <div>
                      <Body5 as="div">Contributions</Body5>

                      {getContributionReview(
                        client,
                        authUser,
                        employeeClass,
                        benefitType,
                        relevantEmployeeClassPlan,
                      )}
                    </div>
                  </StackY>
                </Col>

                <Col span={1}>
                  <BlueRightArrow />
                </Col>
                <Col span={2} className={styles.middleColumn}>
                  <div className={styles.verticalLine} />
                </Col>

                <Col offset={1} span={10}>
                  {showQPSOptions ? (
                    <>
                      <div className="mb-16">
                        <StackY dist={12}>
                          <Body2 as="div">QPS case {client.caseId}</Body2>
                          <Body5 as="div">
                            The Admin Class you select below will have it's information overwritten
                            by the information from Onboard
                          </Body5>
                          <Button
                            type="text-only"
                            size="middle"
                            onClick={selectAllAction}
                            disabled={isSkipBenefitSelected}
                          >
                            {isAllAdminClassesSelected ? "Deselect all" : "Select all"}
                          </Button>
                        </StackY>
                      </div>

                      <StackY dist={24} wrap={false}>
                        {planNames.map((planName, planIndex) => {
                          const qpsClassesForThisPlanName =
                            qpsBasicClassesForThisBenefitType.filter(
                              (q) => q.planDesignName === planName,
                            );
                          const isLastPlan = planNames.length - 1 === planIndex;

                          return (
                            <StackY
                              dist={8}
                              key={planName}
                              data-testid={`mapper-checkboxGroup="${planName}"`}
                            >
                              <Body2 as="div">{planName}</Body2>

                              {qpsClassesForThisPlanName.map((qpsBasicClass) => {
                                if (benefitCodesToHide.includes(qpsBasicClass.benefitCode)) {
                                  return null;
                                }

                                const adminClassId = getAdminClassId(qpsBasicClass);
                                const selectedMappingIndex = currentMappingValues.findIndex(
                                  ({ employeeClassId, formValues }) =>
                                    Object.values(formValues.adminClassesId)
                                      .flat()
                                      .some(
                                        (mappedAdminClassId) =>
                                          mappedAdminClassId === adminClassId &&
                                          employeeClassId !== employeeClass.id,
                                      ),
                                );

                                const isAlreadySelectedInOtherMap = selectedMappingIndex !== -1;
                                const isDisabled =
                                  isAlreadySelectedInOtherMap || isSkipBenefitSelected;
                                const isChecked = currentValue.includes(adminClassId);

                                return (
                                  <Checkbox
                                    key={`${checkboxIndex}.${adminClassId}`}
                                    name={checkboxIndex}
                                    value={adminClassId}
                                    label={
                                      <>
                                        <Body3 as="div" greyMedium={isDisabled}>
                                          {qpsBasicClass.adminClassName}
                                        </Body3>
                                        {isAlreadySelectedInOtherMap && (
                                          <Body5>
                                            This class is already assigned to Eligible Employee
                                            Group
                                            {` ${selectedMappingIndex + 1}`}. You can assign
                                            multiple groups to an admin class, but their values must
                                            match.
                                          </Body5>
                                        )}
                                      </>
                                    }
                                    checked={isChecked}
                                    errorId={
                                      getIn(formik.errors, checkboxIndex) &&
                                      getIn(formik.touched, checkboxIndex)
                                    }
                                    onChange={formik.handleChange}
                                    disabled={isDisabled}
                                  />
                                );
                              })}

                              {isLastPlan && (
                                <>
                                  <Body5 as="div">or</Body5>
                                  <Checkbox
                                    key={`${checkboxIndex}.${SKIP_THIS_BENEFIT}`}
                                    name={checkboxIndex}
                                    value={SKIP_THIS_BENEFIT}
                                    label="Skip this benefit"
                                    checked={isSkipBenefitSelected}
                                    errorId={
                                      getIn(formik.errors, checkboxIndex) &&
                                      getIn(formik.touched, checkboxIndex)
                                    }
                                    error={
                                      getIn(formik.touched, checkboxIndex) &&
                                      getIn(formik.errors, checkboxIndex)
                                    }
                                    onChange={formik.handleChange}
                                    disabled={disabledSkipBenefit}
                                  />
                                </>
                              )}
                            </StackY>
                          );
                        })}
                      </StackY>
                    </>
                  ) : getIsDHMOBenefitType(benefitType) ? (
                    <ErrorMessage>
                      <StackX dist={12} style={{ alignItems: "flex-start" }}>
                        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                        There is no admin class in QPS that matches this Dental Prepaid state.
                      </StackX>
                    </ErrorMessage>
                  ) : (
                    <>
                      <Body3 darkYellow as="div">
                        <StackX dist={12} style={{ alignItems: "flex-start" }}>
                          <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                          There is no admin class in QPS that matches this benefit type.
                        </StackX>
                      </Body3>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </HubCard>
        );
      })}

      <Row justify="space-between" className="mt-32 mb-48">
        <Col>
          <Button
            onClick={onPrevious}
            type="text-only"
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            size="large"
            htmlType="button"
          >
            Previous
          </Button>
        </Col>

        <Col>
          <Button type="primary" size="large" htmlType="submit">
            {employeeClassIndex < employeeClasses.length - 1
              ? "Next eligible employee group"
              : "Review assignments"}
          </Button>
        </Col>
      </Row>
    </form>
  );
}

function getBenefitTypeToClassPlansMap(employeeClass: EmployeeClass) {
  const benefitTypeToClassplansMap = employeeClass.employeeClassPlans.reduce<
    Map<BenefitTypeEIF, EmployeeClassPlanWithPlan[] | undefined>
  >((map, ecp) => {
    const key = ecp.plan.benefitType;
    const item = (map.get(key) || []).concat(ecp);
    map.set(key, item);
    return map;
  }, new Map());

  return benefitTypeToClassplansMap;
}

function getQPSClassesPerBenefitTypeMap(
  qpsBasicClasses: QPSBasicClass[],
  featureToggles: ClientFeatureToggles,
) {
  const qpsClassesPerBenefitTypeMap = qpsBasicClasses.reduce<
    Map<BenefitTypeEIF, QPSBasicClass[] | undefined>
  >((map, q) => {
    const basicClassBenefitType = categoryCodeToBenefitType[q.benefitCode][0];
    assertIsDefined(basicClassBenefitType, "basicClassBenefitType");
    if (
      basicClassBenefitType == null ||
      !isBenefitMappingToQPSSupported(basicClassBenefitType, featureToggles)
    ) {
      return map;
    }
    const key = basicClassBenefitType;
    const item = (map.get(key) || []).concat(q);
    map.set(key, item);
    return map;
  }, new Map());

  return qpsClassesPerBenefitTypeMap;
}

const isLifeADDContributionToExport = (benefitType: BenefitTypeEIF): boolean => {
  return benefitType === "BASIC_DEP_ADND" || getIsLifeADDContributionBenefitType(benefitType);
};

const getContributionReview = (
  client: Client,
  authUser: UserData | null,
  employeeClass: EmployeeClass,
  benefitType: BenefitTypeEIF,
  relevantEmployeeClassPlan: EmployeeClassPlan | undefined,
) => {
  if (relevantEmployeeClassPlan && isLifeADDContributionToExport(benefitType)) {
    return relevantEmployeeClassPlan.employerContribution === "NO" ? (
      <Body3>I am not contributing and employees pay 100%</Body3>
    ) : (
      <Body3>Employer pays {relevantEmployeeClassPlan.eeContributionAmount}%</Body3>
    );
  }

  if (getIsDisabilityContributionBenefitType(benefitType)) {
    return (
      <ReviewDisabilityContributions
        authUser={authUser}
        benefitType={benefitType}
        changeSnapshot={null}
        client={client}
        employeeClass={employeeClass}
      />
    );
  }

  return (
    <ReviewTierContributions
      client={client}
      employeeClass={employeeClass}
      benefitType={benefitType}
      changeSnapshot={null}
      authUser={authUser}
    />
  );
};

const getQPSClassByBenefitType = (
  benefitTypeMap: Map<BenefitTypeEIF, QPSBasicClass[] | undefined>,
  benefitType: BenefitTypeEIF,
): QPSBasicClass[] => {
  if (getIsDHMOBenefitType(benefitType)) {
    const dhmoClasses = benefitTypeMap.get("DENTAL_DHMO") ?? [];
    return dhmoClasses.filter(
      (dhmoClass) => dhmoClass.benefitStateCode === benefitType.split("_")[0],
    );
  }

  return benefitTypeMap.get(benefitType) ?? [];
};

const getAdminClassWithRelatedQPSClasses = (
  qpsBasicClasses: QPSBasicClass[],
  qpsClassId: string,
) => {
  const selectedAdminClassIds = [qpsClassId];
  const selectedQPSBasicClass = qpsBasicClasses.find(
    (qpsBasicClass) => getAdminClassId(qpsBasicClass) === qpsClassId,
  );

  if (!selectedQPSBasicClass) {
    return selectedAdminClassIds;
  }

  const relatedQPSClasses = qpsBasicClasses.filter((qpsBasicClass) => {
    const relatedBenefitCodes = qpsRelatedBenefitCode[selectedQPSBasicClass.benefitCode];
    return (
      relatedBenefitCodes?.includes(qpsBasicClass.benefitCode) &&
      selectedQPSBasicClass.ratingClassNumber === qpsBasicClass.ratingClassNumber &&
      selectedQPSBasicClass.adminClassNumber === qpsBasicClass.adminClassNumber &&
      selectedQPSBasicClass.planDesignSeqNumber === qpsBasicClass.planDesignSeqNumber
    );
  });

  const relatedQPSClassIds = relatedQPSClasses.map((relatedQPSClass) =>
    getAdminClassId(relatedQPSClass),
  );

  return [...selectedAdminClassIds, ...relatedQPSClassIds];
};
