import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInputNumber } from "client/src/components/Form/InputNumber";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2 } from "client/src/components/Typography/Typography";
import { formatCurrency, formatPercentage } from "client/src/utils/formatters";
import { hasFormikError } from "client/src/utils/hasFormikError";
import {
  fieldErrorMsg,
  type ExplorerBenefitModalPresentationProps,
} from "./ExplorerBenefitModalPresentation";
import * as styles from "./ExplorerBenefitModalPresentation.module.less";
import { FORM_FIELDS_DOLLAR_MAX } from "./FormFields";
import {
  OrthodonticServicesLabel,
  OrthodonticServicesValue,
  isOrthonoticServicesValue,
} from "./OrthodonticServices";
import type { RadioChangeEvent } from "antd/lib/radio/interface";

type Props = Pick<ExplorerBenefitModalPresentationProps, "formik">;

export const DentalModalFormFields = ({ formik }: Props) => {
  if (!formik.values.planType) return null;

  const handleOrthoServicesChange = async (e: RadioChangeEvent) => {
    formik.handleChange(e);
    const orthoService: OrthodonticServicesValue = e.target.value;

    if (orthoService === OrthodonticServicesValue.Adults) {
      await formik.setFieldValue("dentalOrthodonticMaximumChild", null);
    } else if (orthoService === OrthodonticServicesValue.Children) {
      await formik.setFieldValue("dentalOrthodonticMaximumAdult", null);
    } else if (orthoService === OrthodonticServicesValue.None) {
      await formik.setFieldValue("dentalOrthodonticMaximumAdult", null);
      await formik.setFieldValue("dentalOrthodonticMaximumChild", null);
    }
  };

  return (
    <StackY dist={32}>
      {formik.values.planType !== "DHMO" && (
        <>
          <StackY dist={16}>
            <Body2>Plan year deductible</Body2>
            <Checkbox
              label="Out-of-network not applicable"
              name="planYearDeductibleOutOfNetworkNA"
              checked={formik.values.planYearDeductibleOutOfNetworkNA}
              error={
                hasFormikError(formik, "planYearDeductibleOutOfNetworkNA")
                  ? fieldErrorMsg("plan year deductible out of network not applicable")
                  : undefined
              }
              onChange={async (e) => {
                await formik.setFieldValue("planYearDeductibleOutOfNetworkNA", e.target.checked);
              }}
            />
            <div style={{ marginBottom: "20px" }} />
            <Row gutter={[16, 16]}>
              <Col span={!formik.values.planYearDeductibleOutOfNetworkNA ? 12 : 24}>
                <FormInputNumber
                  label="Individual (In-Network)"
                  value={formik.values.planYearDeductibleIndividualInNetwork ?? 0}
                  maxLength={8}
                  max={FORM_FIELDS_DOLLAR_MAX}
                  name="planYearDeductibleIndividualInNetwork"
                  touched={formik.touched.planYearDeductibleIndividualInNetwork}
                  onChange={async (val) =>
                    await formik.setFieldValue("planYearDeductibleIndividualInNetwork", val ?? 0)
                  }
                  error={
                    hasFormikError(formik, "planYearDeductibleIndividualInNetwork")
                      ? fieldErrorMsg("plan year deductible individual in network")
                      : undefined
                  }
                  disabled={formik.isSubmitting}
                  formatter={formatCurrency}
                  parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                  step={100}
                  min={0}
                  showRequired
                />
              </Col>
              {!formik.values.planYearDeductibleOutOfNetworkNA && (
                <Col span={12}>
                  <FormInputNumber
                    label="Individual (Out-of-Network)"
                    value={formik.values.planYearDeductibleIndividualOutOfNetwork ?? 0}
                    maxLength={8}
                    max={FORM_FIELDS_DOLLAR_MAX}
                    name="planYearDeductibleIndividualOutOfNetwork"
                    touched={formik.touched.planYearDeductibleIndividualOutOfNetwork}
                    onChange={async (val) =>
                      await formik.setFieldValue(
                        "planYearDeductibleIndividualOutOfNetwork",
                        val ?? 0,
                      )
                    }
                    error={
                      hasFormikError(formik, "planYearDeductibleIndividualOutOfNetwork")
                        ? fieldErrorMsg("plan year deductible individual out of network")
                        : undefined
                    }
                    disabled={formik.isSubmitting}
                    formatter={formatCurrency}
                    parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                    step={100}
                    min={0}
                    showRequired
                  />
                </Col>
              )}
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={!formik.values.planYearDeductibleOutOfNetworkNA ? 12 : 24}>
                <FormInputNumber
                  label="Family (In-Network)"
                  value={formik.values.planYearDeductibleFamilyInNetwork ?? 0}
                  maxLength={8}
                  max={FORM_FIELDS_DOLLAR_MAX}
                  name="planYearDeductibleFamilyInNetwork"
                  touched={formik.touched.planYearDeductibleFamilyInNetwork}
                  onChange={async (val) =>
                    await formik.setFieldValue("planYearDeductibleFamilyInNetwork", val ?? 0)
                  }
                  error={
                    hasFormikError(formik, "planYearDeductibleFamilyInNetwork")
                      ? fieldErrorMsg("plan year deductible family in network")
                      : undefined
                  }
                  disabled={formik.isSubmitting}
                  formatter={formatCurrency}
                  parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                  step={100}
                  min={0}
                  showRequired
                />
              </Col>
              {!formik.values.planYearDeductibleOutOfNetworkNA && (
                <Col span={12}>
                  <FormInputNumber
                    label="Family (Out-of-Network)"
                    value={formik.values.planYearDeductibleFamilyOutOfNetwork ?? 0}
                    maxLength={8}
                    max={FORM_FIELDS_DOLLAR_MAX}
                    name="planYearDeductibleFamilyOutOfNetwork"
                    touched={formik.touched.planYearDeductibleFamilyOutOfNetwork}
                    onChange={async (val) =>
                      await formik.setFieldValue("planYearDeductibleFamilyOutOfNetwork", val ?? 0)
                    }
                    error={
                      hasFormikError(formik, "planYearDeductibleFamilyOutOfNetwork")
                        ? fieldErrorMsg("plan year deductible family out of network")
                        : undefined
                    }
                    disabled={formik.isSubmitting}
                    formatter={formatCurrency}
                    parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                    step={100}
                    min={0}
                    showRequired
                  />
                </Col>
              )}
            </Row>
          </StackY>

          <StackY dist={16}>
            <Body2>Plan year maximum</Body2>
            <Checkbox
              label="Out-of-network not applicable"
              name="planYearMaximumOutOfNetworkNA"
              checked={formik.values.planYearMaximumOutOfNetworkNA}
              error={
                hasFormikError(formik, "planYearMaximumOutOfNetworkNA")
                  ? fieldErrorMsg("out of pocket maximum out of network not applicable")
                  : undefined
              }
              onChange={async (e) => {
                await formik.setFieldValue("planYearMaximumOutOfNetworkNA", e.target.checked);
              }}
            />
            <div style={{ marginBottom: "20px" }} />
            <Row gutter={[16, 16]}>
              <Col span={!formik.values.planYearMaximumOutOfNetworkNA ? 12 : 24}>
                <FormInputNumber
                  label="Maximum (In-Network)"
                  value={formik.values.planYearMaximumIndividualInNetwork ?? 0}
                  maxLength={8}
                  max={FORM_FIELDS_DOLLAR_MAX}
                  name="planYearMaximumIndividualInNetwork"
                  touched={formik.touched.planYearMaximumIndividualInNetwork}
                  onChange={async (val) =>
                    await formik.setFieldValue("planYearMaximumIndividualInNetwork", val ?? 0)
                  }
                  error={
                    hasFormikError(formik, "planYearMaximumIndividualInNetwork")
                      ? fieldErrorMsg("plan year maximum individual in network")
                      : undefined
                  }
                  disabled={formik.isSubmitting}
                  formatter={formatCurrency}
                  parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                  step={100}
                  min={0}
                  showRequired
                />
              </Col>
              {!formik.values.planYearMaximumOutOfNetworkNA && (
                <Col span={12}>
                  <FormInputNumber
                    label="Maximum (Out-of-Network)"
                    value={formik.values.planYearMaximumIndividualOutOfNetwork ?? 0}
                    maxLength={8}
                    max={FORM_FIELDS_DOLLAR_MAX}
                    name="planYearMaximumIndividualOutOfNetwork"
                    touched={formik.touched.planYearMaximumIndividualOutOfNetwork}
                    onChange={async (val) =>
                      await formik.setFieldValue("planYearMaximumIndividualOutOfNetwork", val ?? 0)
                    }
                    error={
                      hasFormikError(formik, "planYearMaximumIndividualOutOfNetwork")
                        ? fieldErrorMsg("plan year maximum individual out of network")
                        : undefined
                    }
                    disabled={formik.isSubmitting}
                    formatter={formatCurrency}
                    parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                    step={100}
                    min={0}
                    showRequired
                  />
                </Col>
              )}
            </Row>
          </StackY>

          <StackY dist={12}>
            <Body2>Type 1 care like cleanings and exams</Body2>
            <StackX dist={16} className={styles.stackXItemsFlexGrow}>
              <FormInputNumber
                label="% covered in network"
                value={formik.values.dentalTypeOneInNetwork ?? 0}
                maxLength={4}
                name="dentalTypeOneInNetwork"
                touched={formik.touched.dentalTypeOneInNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeOneInNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeOneInNetwork")
                    ? fieldErrorMsg("dental type 1 care in network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
              <FormInputNumber
                label="% covered out of network"
                value={formik.values.dentalTypeOneOutOfNetwork ?? 0}
                maxLength={4}
                name="dentalTypeOneOutOfNetwork"
                touched={formik.touched.dentalTypeOneOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeOneOutOfNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeOneOutOfNetwork")
                    ? fieldErrorMsg("dental type 1 care out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
            </StackX>
          </StackY>
          <StackY dist={12}>
            <Body2>Type 2 care like filings</Body2>
            <StackX dist={16} className={styles.stackXItemsFlexGrow}>
              <FormInputNumber
                label="% covered in network"
                value={formik.values.dentalTypeTwoInNetwork ?? 0}
                maxLength={4}
                name="dentalTypeTwoInNetwork"
                touched={formik.touched.dentalTypeTwoInNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeTwoInNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeTwoInNetwork")
                    ? fieldErrorMsg("dental type 2 care in network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
              <FormInputNumber
                label="% covered out of network network"
                value={formik.values.dentalTypeTwoOutOfNetwork ?? 0}
                maxLength={4}
                name="dentalTypeTwoOutOfNetwork"
                touched={formik.touched.dentalTypeTwoOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeTwoOutOfNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeTwoOutOfNetwork")
                    ? fieldErrorMsg("dental type 2 care out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
            </StackX>
          </StackY>
          <StackY dist={12}>
            <Body2>Type 3 care like crowns and surgeries</Body2>
            <StackX dist={16} className={styles.stackXItemsFlexGrow}>
              <FormInputNumber
                label="% covered in network"
                value={formik.values.dentalTypeThreeInNetwork ?? 0}
                maxLength={4}
                name="dentalTypeThreeInNetwork"
                touched={formik.touched.dentalTypeThreeInNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeThreeInNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeThreeInNetwork")
                    ? fieldErrorMsg("dental type 3 care in network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
              <FormInputNumber
                label="% covered out of network"
                value={formik.values.dentalTypeThreeOutOfNetwork ?? 0}
                maxLength={4}
                name="dentalTypeThreeOutOfNetwork"
                touched={formik.touched.dentalTypeThreeOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("dentalTypeThreeOutOfNetwork", val ?? null)
                }
                error={
                  hasFormikError(formik, "dentalTypeThreeOutOfNetwork")
                    ? fieldErrorMsg("dental type 3 care out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={(value) => (value != null ? formatPercentage(value) : "")}
                parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
                step={1}
                min={0}
                max={100}
              />
            </StackX>
          </StackY>
        </>
      )}
      {!["DHMO", "DCA"].includes(formik.values.planType) && (
        <>
          <RadioGroup
            name="dentalOrthodonticServices"
            label="Orthodontic services"
            direction="vertical"
            value={formik.values.dentalOrthodonticServices}
            error={
              hasFormikError(formik, "dentalOrthodonticServices")
                ? fieldErrorMsg("in-network orthodontic service", true)
                : undefined
            }
            touched={formik.touched.dentalOrthodonticServices}
            disabled={formik.isSubmitting}
            options={[
              { label: OrthodonticServicesLabel.None, value: OrthodonticServicesValue.None },
              {
                label: OrthodonticServicesLabel.Children,
                value: OrthodonticServicesValue.Children,
              },
              { label: OrthodonticServicesLabel.Adults, value: OrthodonticServicesValue.Adults },
              { label: OrthodonticServicesLabel.Both, value: OrthodonticServicesValue.Both },
            ]}
            onChange={handleOrthoServicesChange}
          />

          {formik.values.dentalOrthodonticServices &&
            isOrthonoticServicesValue(formik.values.dentalOrthodonticServices) && (
              <>
                {[OrthodonticServicesValue.Adults, OrthodonticServicesValue.Both].includes(
                  formik.values.dentalOrthodonticServices,
                ) && (
                  <StackY dist={12}>
                    <Body2>Lifetime orthodontic maximum adults (per person)</Body2>
                    <FormInputNumber
                      label="Maximum"
                      value={formik.values.dentalOrthodonticMaximumAdult ?? 0}
                      maxLength={8}
                      max={FORM_FIELDS_DOLLAR_MAX}
                      name="dentalOrthodonticMaximumAdult"
                      touched={formik.touched.dentalOrthodonticMaximumAdult}
                      onChange={async (val) =>
                        await formik.setFieldValue("dentalOrthodonticMaximumAdult", val ?? null)
                      }
                      error={
                        hasFormikError(formik, "dentalOrthodonticMaximumAdult")
                          ? fieldErrorMsg("Lifetime orthodontic maximum")
                          : undefined
                      }
                      disabled={formik.isSubmitting}
                      formatter={formatCurrency}
                      parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                      step={100}
                      min={0}
                    />
                  </StackY>
                )}
                {[OrthodonticServicesValue.Children, OrthodonticServicesValue.Both].includes(
                  formik.values.dentalOrthodonticServices,
                ) && (
                  <StackY dist={12}>
                    <Body2>Lifetime orthodontic maximum children (per person)</Body2>
                    <FormInputNumber
                      label="Maximum"
                      value={formik.values.dentalOrthodonticMaximumChild ?? 0}
                      maxLength={8}
                      max={FORM_FIELDS_DOLLAR_MAX}
                      name="dentalOrthodonticMaximumChild"
                      touched={formik.touched.dentalOrthodonticMaximumChild}
                      onChange={async (val) =>
                        await formik.setFieldValue("dentalOrthodonticMaximumChild", val ?? null)
                      }
                      error={
                        hasFormikError(formik, "dentalOrthodonticMaximumChild")
                          ? fieldErrorMsg("Lifetime orthodontic maximum")
                          : undefined
                      }
                      disabled={formik.isSubmitting}
                      formatter={formatCurrency}
                      parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                      step={100}
                      min={0}
                    />
                  </StackY>
                )}
              </>
            )}
        </>
      )}
    </StackY>
  );
};
