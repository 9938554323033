import { type BenefitTypeEIF } from "shared/types/BenefitTypes";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  getIsCoverageSupportedInEIF,
} from "shared/types/SlfCoverages";
import { listFormat } from "shared/utils/format";
import { unique, rejectNullableValues } from "shared/utils/utils";
import type { Client } from "shared/types/Client";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export type BenefitTypeEIFGroup =
  | "group:DENTAL"
  | "group:VISION"
  | "group:SHORT_TERM_DISABILITY"
  | "group:LONG_TERM_DISABILITY"
  | "group:LIFE" // -> "LIFE", "DEP_LIFE", "BASIC_ADND"
  | "group:VOL_STD"
  | "group:VOL_LTD"
  | "group:VOL_LIFE" // -> "VOL_LIFE", "VOL_ADND"
  | "group:STANDALONE_VOL_ADND"
  // Supp Health
  | "group:ACCIDENT"
  | "group:CANCER"
  | "group:HOSPITAL_INDEMNITY"
  | "group:CRITICAL_ILLNESS"
  | "group:ISSUE_AGE_STD"
  // PFML
  | "group:PFML_CT_ASO"
  | "group:PFML_CT_FULLY_INS"
  | "group:PFML_MA_ASO"
  | "group:PFML_MA_FULLY_INS"
  | "group:PFML_WA_ASO"
  | "group:PFML_CO_ASO"
  | "group:PFML_CO_FULLY_INS"
  | "group:PFML_OR_ASO"
  | "group:PFML_OR_FULLY_INS"
  // DHMO
  | "group:DENTAL_DHMO"
  | "group:AL_DHMO"
  | "group:AZ_DHMO"
  | "group:CA_DHMO"
  | "group:CO_DHMO"
  | "group:FL_DHMO"
  | "group:GA_DHMO"
  | "group:IL_DHMO"
  | "group:KS_DHMO"
  | "group:KY_DHMO"
  | "group:MI_DHMO"
  | "group:MO_DHMO"
  | "group:NE_DHMO"
  | "group:NJ_DHMO"
  | "group:NM_DHMO"
  | "group:NY_DHMO"
  | "group:OH_DHMO"
  | "group:OK_DHMO"
  | "group:PA_DHMO"
  | "group:TN_DHMO"
  | "group:TX_DHMO"
  | "group:UT_DHMO"
  | "group:WI_DHMO"
  // FLI
  | "group:FLI"
  // Dental ASO
  | "group:DENTAL_ASO";

export const benefitTypeToGroupRecord: Partial<Record<BenefitTypeEIF, BenefitTypeEIFGroup>> = {
  DENTAL: "group:DENTAL",

  VISION: "group:VISION",

  SHORT_TERM_DISABILITY: "group:SHORT_TERM_DISABILITY",

  LONG_TERM_DISABILITY: "group:LONG_TERM_DISABILITY",

  // LIFE group
  LIFE: "group:LIFE",
  DEP_LIFE: "group:LIFE",
  BASIC_ADND: "group:LIFE",

  VOL_STD: "group:VOL_STD",

  VOL_LTD: "group:VOL_LTD",

  // VOL_LIFE group
  VOL_LIFE: "group:VOL_LIFE",
  VOL_ADND: "group:VOL_LIFE",

  STANDALONE_VOL_ADND: "group:STANDALONE_VOL_ADND",

  // Supp Health
  ACCIDENT: "group:ACCIDENT",
  CANCER: "group:CANCER",
  HOSPITAL_INDEMNITY: "group:HOSPITAL_INDEMNITY",
  CRITICAL_ILLNESS: "group:CRITICAL_ILLNESS",

  ISSUE_AGE_STD: "group:ISSUE_AGE_STD",

  // PFML
  PFML_CT_ASO: "group:PFML_CT_ASO",
  PFML_CT_FULLY_INS: "group:PFML_CT_FULLY_INS",
  PFML_MA_ASO: "group:PFML_MA_ASO",
  PFML_MA_FULLY_INS: "group:PFML_MA_FULLY_INS",
  PFML_WA_ASO: "group:PFML_WA_ASO",
  PFML_CO_ASO: "group:PFML_CO_ASO",
  PFML_CO_FULLY_INS: "group:PFML_CO_FULLY_INS",
  PFML_OR_ASO: "group:PFML_OR_ASO",
  PFML_OR_FULLY_INS: "group:PFML_OR_FULLY_INS",

  // DHMO
  DENTAL_DHMO: "group:DENTAL_DHMO",
  AL_DHMO: "group:AL_DHMO",
  AZ_DHMO: "group:AZ_DHMO",
  CA_DHMO: "group:CA_DHMO",
  CO_DHMO: "group:CO_DHMO",
  FL_DHMO: "group:FL_DHMO",
  GA_DHMO: "group:GA_DHMO",
  IL_DHMO: "group:IL_DHMO",
  KS_DHMO: "group:KS_DHMO",
  KY_DHMO: "group:KY_DHMO",
  MI_DHMO: "group:MI_DHMO",
  MO_DHMO: "group:MO_DHMO",
  NE_DHMO: "group:NE_DHMO",
  NJ_DHMO: "group:NJ_DHMO",
  NM_DHMO: "group:NM_DHMO",
  NY_DHMO: "group:NY_DHMO",
  OH_DHMO: "group:OH_DHMO",
  OK_DHMO: "group:OK_DHMO",
  PA_DHMO: "group:PA_DHMO",
  TN_DHMO: "group:TN_DHMO",
  TX_DHMO: "group:TX_DHMO",
  UT_DHMO: "group:UT_DHMO",
  WI_DHMO: "group:WI_DHMO",

  // FLI
  FLI: "group:FLI",

  // DENTAL_ASO
  DENTAL_ASO: "group:DENTAL_ASO",
};

export function getBenefitTypesGroupings(
  client: Client,
): Map<BenefitTypeEIFGroup, BenefitTypeEIF[]>;
export function getBenefitTypesGroupings(
  coverages: SlfCoverageLongName[],
): Map<BenefitTypeEIFGroup, BenefitTypeEIF[]>;
export function getBenefitTypesGroupings(clientOrCoverages: Client | SlfCoverageLongName[]) {
  const coverages = Array.isArray(clientOrCoverages)
    ? clientOrCoverages.filter(getIsCoverageSupportedInEIF)
    : clientOrCoverages.allPoliciesSlfCoverages?.filter(getIsCoverageSupportedInEIF) ?? [];

  const benefitTypes = unique(coverages.map((c) => coverageToBenefitType[c]));
  const benefitTypeEIFGroups = unique(
    benefitTypes
      .map((benefitType) => benefitTypeToGroupRecord[benefitType])
      .filter(rejectNullableValues),
  );

  const order = Object.keys(benefitTypeToGroupRecord);

  const groupsWithBenefitTypes = new Map<BenefitTypeEIFGroup, BenefitTypeEIF[]>(
    benefitTypeEIFGroups.map((groupName) => {
      const benefitTypesInThisGroup = benefitTypes
        .filter((benefitType) => benefitTypeToGroupRecord[benefitType] === groupName)
        .sort((a, b) => {
          if (order.includes(a) && order.includes(b)) return order.indexOf(a) - order.indexOf(b);
          if (order.includes(a)) return -1;
          if (order.includes(b)) return 1;
          return a.localeCompare(b);
        });

      return [groupName, benefitTypesInThisGroup];
    }),
  );

  return groupsWithBenefitTypes;
}

export function benefitTypesToCombinedCoverageNames(
  employeeClassPlans: EmployeeClassPlanWithPlan[],
) {
  const coverages = employeeClassPlans.map(
    (classPlan) => benefitTypeToCoverage[classPlan.plan.benefitType],
  );

  const benefitTypeGroupsMap = getBenefitTypesGroupings(coverages);
  const benefitGroupsEntries = Array.from(benefitTypeGroupsMap.entries());
  const combinedCoverageNames = benefitGroupsEntries.map(getCombinedCoverageName);
  return combinedCoverageNames;
}

export function getCombinedCoverageName(tuple: [BenefitTypeEIFGroup, BenefitTypeEIF[]]) {
  const [groupName, benefitTypesInGroup] = tuple;

  const coveragesInGroup = benefitTypesInGroup.map((b) => benefitTypeToCoverage[b]);

  if (groupName === "group:LIFE") {
    const [firstCoverage, ...restOfTheCoverages] = coveragesInGroup;
    const coverageList = restOfTheCoverages.join(", ");
    const combinedCoverageName =
      restOfTheCoverages.length === 0 ? firstCoverage : `${firstCoverage} (inc. ${coverageList})`;
    return combinedCoverageName;
  }

  const combinedCoverageName = listFormat(coveragesInGroup);
  return combinedCoverageName;
}
