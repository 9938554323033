import { useQueryClient } from "@tanstack/react-query";

import { unique } from "shared/utils/utils";
import { useSlobMutation, useSlobQuery } from "./query";

import type { ClientId } from "../../../shared/types/Client";
import type { JsonToTypeMapper } from "./query";
import type { QueryClient } from "@tanstack/react-query";
import type { WebLink, WebLinkCategory } from "shared/types/WebLink";

const jsonWebLinkToWebLink: JsonToTypeMapper<WebLink> = (webLink) => {
  return {
    ...webLink,
    createdAt: new Date(webLink.createdAt),
    updatedAt: new Date(webLink.updatedAt),
    deletedAt: webLink.deletedAt ? new Date(webLink.deletedAt) : null,
  };
};

export const useGetWebLinks = (clientId: ClientId, categories: WebLinkCategory[]) => {
  const sortedCategories = categories.sort();
  const params = new URLSearchParams();
  for (const category of sortedCategories) {
    params.append("categories[]", category);
  }
  const qs = params.toString();

  return useSlobQuery<WebLink[]>({
    method: "get",
    path: `/api/clients/${clientId}/web-links/meta?${qs}`,
    map: (d) => d.map(jsonWebLinkToWebLink),
    options: {
      enabled: categories.length > 0,
    },
  });
};

export const useCreateWebLinks = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    { webLinks: Pick<WebLink, "category" | "benefitTypes" | "url" | "text">[] },
    { count: number },
    "/api/clients/:clientId/web-links/bulk"
  >({
    method: "post",
    path: "/api/clients/:clientId/web-links/bulk",
    map: (i) => i,
    options: {
      async onSuccess(_, { data: { webLinks }, params: { clientId } }) {
        const categories = unique(webLinks.map((w) => w.category));
        await Promise.all(
          categories.map((category) => invalidatGetWebLinksQuery(queryClient, clientId, category)),
        );
      },
    },
  });
};

export const useDeleteWebLink = (category: WebLinkCategory | undefined | null) => {
  const queryClient = useQueryClient();
  return useSlobMutation({
    method: "delete",
    path: `api/clients/:clientId/web-links/:webLinkId`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        if (category) {
          await invalidatGetWebLinksQuery(queryClient, clientId, category);
        }
      },
    },
  });
};

function invalidatGetWebLinksQuery(
  queryClient: QueryClient,
  clientId: string | number,
  category: WebLinkCategory,
) {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      if (Array.isArray(query.queryKey)) {
        const [method, path] = query.queryKey;
        if (typeof path === "string") {
          const [basePath, qs] = path.split("?");
          const searchParams = new URLSearchParams(qs);
          const categories = searchParams.getAll("categories[]");
          const shouldInvalidate =
            method === "get" &&
            basePath === `/api/clients/${clientId}/web-links/meta` &&
            categories.includes(category);
          return shouldInvalidate;
        }
      }
      return false;
    },
  });
}
