import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonLocationToLocation } from "client/src/hooks/location";
import { compareQueryId, useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type { ClientId } from "shared/types/Client";
import type { Subsidiary, SubsidiaryInput } from "shared/types/Subsidiary";

export const jsonSubsidaryToSubsidiary: JsonToTypeMapper<Subsidiary> = (subsidiary) => {
  return {
    ...subsidiary,
    createdAt: new Date(subsidiary.createdAt),
    updatedAt: new Date(subsidiary.updatedAt),
    deletedAt: subsidiary.deletedAt ? new Date(subsidiary.deletedAt) : null,
    location: subsidiary.location && jsonLocationToLocation(subsidiary.location),
  };
};

export type CreateSubsidiaryQuery = Pick<
  ReturnType<typeof useCreateSubsidiary>,
  "isPending" | "mutateAsync"
>;

export type UpdateSubsidiaryQuery = Pick<
  ReturnType<typeof useUpdateSubsidiary>,
  "isPending" | "mutateAsync"
>;

export type DeleteSubsidiaryQuery = Pick<
  ReturnType<typeof useDeleteSubsidiary>,
  "isPending" | "mutateAsync"
>;

export type CreateSubsidiaryFunc = CreateSubsidiaryQuery["mutateAsync"];
export type UpdateSubsidiaryFunc = UpdateSubsidiaryQuery["mutateAsync"];
export type DeleteSubsidiaryFunc = DeleteSubsidiaryQuery["mutateAsync"];

export const useCreateSubsidiary = () => {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    SubsidiaryInput,
    Subsidiary,
    `api/clients/:clientId/subsidiary`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `api/clients/:clientId/subsidiary`,
    map: jsonSubsidaryToSubsidiary,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          invalidateDEIFChanges(queryClient, clientId.toString()),
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-subsidiaries-by-client-id"),
          }),
        ]);
      },
    },
  });

  return query;
};

export const useUpdateSubsidiary = () => {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    SubsidiaryInput,
    Subsidiary,
    `api/clients/:clientId/subsidiary/:subsidiaryId`,
    ResponseError,
    unknown
  >({
    method: "put",
    path: `api/clients/:clientId/subsidiary/:subsidiaryId`,
    map: jsonSubsidaryToSubsidiary,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          invalidateDEIFChanges(queryClient, clientId.toString()),
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-subsidiaries-by-client-id"),
          }),
        ]);
      },
    },
  });

  return query;
};

export const useDeleteSubsidiary = () => {
  const queryClient = useQueryClient();

  const query = useSlobMutation({
    method: "delete",
    path: `api/clients/:clientId/subsidiary/:subsidiaryId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          invalidateDEIFChanges(queryClient, clientId.toString()),
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-subsidiaries-by-client-id"),
          }),
        ]);
      },
    },
  });

  return query;
};

export const useGetSubsidiariesByClientId = (clientId: ClientId) => {
  const query = useSlobQuery<Subsidiary[]>({
    method: "get",
    path: `/api/clients/${clientId}/subsidiary`,
    queryId: "get-subsidiaries-by-client-id",
    map: (subsidiaries) => subsidiaries.map(jsonSubsidaryToSubsidiary),
  });
  return query;
};
