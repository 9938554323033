import { compareTicketStatus } from "../../server/utils/compareTicketStatus";

import type { TicketStatus } from "../../server/types/Ticket";
import type { PhaseId } from "@prisma/client";

export const isRiskRejected = <
  T extends {
    ticketStatus: TicketStatus | null;
    phaseId: PhaseId | null;
  },
>(
  entity: T,
) =>
  entity.phaseId === "REJECTED" ||
  compareTicketStatus<TicketStatus>(entity.ticketStatus)(["Risk Rejected"]);
