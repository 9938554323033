import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { capitalize } from "client/src/utils/string";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { additionalWaitingPeriodRulesValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { additionalWaitingPeriodRulesFormKeyProps } from "shared/validation/otherContractDetailsFormKeys";
import { Checkbox } from "../../components/Form/Checkbox";
import { FormInput } from "../../components/Form/Input";
import { InputErrorMessage } from "../../components/Form/InputErrorMessage";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { useSlobId } from "../../hooks/useSlobId";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { hasFormikError } from "../../utils/hasFormikError";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

export const formTestId = "form";

export function EIFAdditionalWaitingPeriodRules(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const eifSubStepId = "additional-waiting-period-rules";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(
      additionalWaitingPeriodRulesFormKeyProps,
    ),
    updateClient,
    validationSchema: additionalWaitingPeriodRulesValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const checkboxesErrorId = useSlobId({ prefix: "time-spent-rule-error" });
  const hasAnyCheckboxError =
    hasFormikError(formik, "timeSpentAsPartTimeShouldCount") ||
    hasFormikError(formik, "timeSpentAsOtherShouldCount");

  const policyYear = client.firstPolicyEffective
    ? client.firstPolicyEffective.getUTCFullYear()
    : null;

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(additionalWaitingPeriodRulesFormKeyProps)(client),
  );

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, additionalWaitingPeriodRulesValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <>
      <StackY dist={40}>
        <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
          <h2 className="mb-40">{subStepName}</h2>
          <PFMLBulbMessage client={client} />

          <StackY dist={32}>
            <HubCard>
              <h3>Service to count towards waiting period</h3>

              <StackY dist={16}>
                <RadioGroup
                  name="timeSpentShouldCountTowardsWaitingPeriod"
                  label={
                    <>
                      <Body2 as="p">
                        If an employee is not initially eligible to receive benefits, but later
                        becomes eligible, should their time already served count towards their
                        waiting period?
                      </Body2>

                      <p>
                        For example, some companies allow time served during part-time or temporary
                        employment to count towards the completion of the waiting period when an
                        employee reaches full-time or permanent status.
                      </p>
                    </>
                  }
                  disabled={formik.isSubmitting}
                  touched={
                    formik.touched.timeSpentShouldCountTowardsWaitingPeriod ||
                    !!strictErrors.timeSpentShouldCountTowardsWaitingPeriod
                  }
                  error={
                    formik.errors.timeSpentShouldCountTowardsWaitingPeriod ||
                    strictErrors.timeSpentShouldCountTowardsWaitingPeriod
                  }
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                  ]}
                  direction="vertical"
                  value={formik.values.timeSpentShouldCountTowardsWaitingPeriod}
                  onChange={async (event) => {
                    const currentValue = event.target.value;
                    if (currentValue === "NO") {
                      await formik.setFieldValue("timeSpentAsPartTimeShouldCount", false);
                      await formik.setFieldValue("timeSpentAsOtherShouldCount", false);
                      await formik.setFieldValue("timeSpentAsOtherShouldCountText", null);
                    }
                    formik.handleChange(event);
                  }}
                />

                <EditedFieldMsg
                  changeDetailInfoList={[
                    changeSnapshot.Client.timeSpentShouldCountTowardsWaitingPeriod,
                  ]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "timeSpentShouldCountTowardsWaitingPeriod",
                    client,
                    formik,
                  })}
                />

                {formik.values.timeSpentShouldCountTowardsWaitingPeriod === "YES" && (
                  <StackY dist={16} wrap={false}>
                    <p>
                      <strong>
                        Will time served as a part-time employee, or other type of employee, be
                        included?
                      </strong>
                    </p>

                    <div>
                      <Body3>Time served as a full-time employee is always included.</Body3>
                    </div>

                    <div>
                      <Checkbox
                        label="Part-time"
                        name="timeSpentAsPartTimeShouldCount"
                        checked={Boolean(formik.values.timeSpentAsPartTimeShouldCount)}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        errorId={hasAnyCheckboxError ? checkboxesErrorId : undefined}
                      />

                      <Checkbox
                        label="Other (please explain)"
                        name="timeSpentAsOtherShouldCount"
                        checked={Boolean(formik.values.timeSpentAsOtherShouldCount)}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                        errorId={hasAnyCheckboxError ? checkboxesErrorId : undefined}
                      />
                    </div>

                    {formik.values.timeSpentAsOtherShouldCount && (
                      <div className="ml-32 ">
                        <FormInput
                          name="timeSpentAsOtherShouldCountText"
                          label="Other, please explain"
                          maxLength={191}
                          touched={
                            formik.touched.timeSpentAsOtherShouldCountText ||
                            !!strictErrors.timeSpentAsOtherShouldCountText
                          }
                          disabled={formik.isSubmitting}
                          value={formik.values.timeSpentAsOtherShouldCountText}
                          onChange={formik.handleChange}
                          error={
                            formik.errors.timeSpentAsOtherShouldCountText ||
                            strictErrors.timeSpentAsOtherShouldCountText
                          }
                        />
                      </div>
                    )}

                    <div>
                      <Body5>This may require Sun Life review and approval.</Body5>
                    </div>

                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Client.timeSpentAsPartTimeShouldCount,
                        changeSnapshot.Client.timeSpentAsOtherShouldCount,
                        changeSnapshot.Client.timeSpentAsOtherShouldCountText,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={
                        getHasPendingEdit({
                          field: "timeSpentAsPartTimeShouldCount",
                          client,
                          formik,
                        }) ||
                        getHasPendingEdit({
                          field: "timeSpentAsOtherShouldCount",
                          client,
                          formik,
                        }) ||
                        getHasPendingEdit({
                          field: "timeSpentAsOtherShouldCountText",
                          client,
                          formik,
                        })
                      }
                    />

                    <div
                      aria-live="assertive"
                      style={hasAnyCheckboxError ? undefined : { margin: 0 }}
                    >
                      {hasAnyCheckboxError && (
                        <InputErrorMessage
                          id={checkboxesErrorId}
                          error={
                            formik.errors.timeSpentAsPartTimeShouldCount ||
                            formik.errors.timeSpentAsOtherShouldCount ||
                            strictErrors.timeSpentAsPartTimeShouldCount ||
                            strictErrors.timeSpentAsOtherShouldCount
                          }
                        />
                      )}
                    </div>
                  </StackY>
                )}
              </StackY>
            </HubCard>

            <HubCard>
              <h3>
                {client.policyAnniversaryMonth && policyYear
                  ? "New employees hired before the effective date"
                  : "New and recently hired employees"}
              </h3>

              <StackY dist={16}>
                <RadioGroup
                  name="waitingPeriodShouldBeWaived"
                  label={
                    <div className="mb-16">
                      <Body2>
                        Should the waiting period be waived for new employees hired before the{" "}
                        {client.policyAnniversaryMonth && policyYear
                          ? `${capitalize(client.policyAnniversaryMonth)} 1, ${policyYear} `
                          : "plan "}
                        effective date?
                      </Body2>
                    </div>
                  }
                  disabled={formik.isSubmitting}
                  touched={
                    formik.touched.waitingPeriodShouldBeWaived ||
                    !!strictErrors.waitingPeriodShouldBeWaived
                  }
                  error={
                    formik.errors.waitingPeriodShouldBeWaived ||
                    strictErrors.waitingPeriodShouldBeWaived
                  }
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                  ]}
                  direction="vertical"
                  value={formik.values.waitingPeriodShouldBeWaived}
                  onChange={formik.handleChange}
                />

                <div className="mt-16">
                  <Body5>This may require Sun Life review and approval.</Body5>
                </div>

                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Client.waitingPeriodShouldBeWaived]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "waitingPeriodShouldBeWaived",
                    client,
                    formik,
                  })}
                />
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </form>

        <hr />

        <div>
          <h2>What is an eligibility waiting period?</h2>

          <p>
            The amount of time required after employees are hired before they are eligible for
            benefits. Any period of time an employee is actively at work for your company as a
            full-time employee (or part-time when applicable) may count towards completion of the
            eligibility waiting period.
          </p>

          <h2>What makes an employee not eligible to receive benefits?</h2>

          <p>
            An employee who is not in a benefits eligible class or who does not work the minimum
            number of hours required may not be eligible for benefits.
          </p>
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
