import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { EIFNonClassBenefitsContributionsCompletedItem } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsCompletedItem";
import { EIFNonClassBenefitsReadOnlyContribution } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsReadOnlyContribution";
import { useToggler } from "client/src/hooks/useToggler";
import { getAdminClassId } from "shared/types/QPSClass";
import { getUniquePFMLSections } from "shared/utils/EIF/nonClassBenefitsPreferences";
import * as styles from "./eifPushToQPS.module.less";
import type { AssignPlansToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepPlanMapping";
import type { Plan } from "shared/types/Plan";
import type { QPSBasicClass } from "shared/types/QPSClass";

type PlanMappedSummaryItemProps = {
  plans: Plan[];
  qpsBasicClasses: QPSBasicClass[];
  mappedQPSPlan: AssignPlansToAdminClassesMapping;
  editButtonAction: () => void;
};

export const PlanMappedSummaryItem = ({
  qpsBasicClasses,
  mappedQPSPlan,
  plans,
  editButtonAction,
}: PlanMappedSummaryItemProps) => {
  const [hideDetails, toggleHideDetails] = useToggler();

  const uniquePFMLSections = getUniquePFMLSections(plans);

  return (
    <HubCard>
      <Row justify="space-between">
        <Col>
          <h5>PFML Preference</h5>
        </Col>
        <Col>
          <Button type="text-only" onClick={editButtonAction}>
            Edit
          </Button>
        </Col>
      </Row>
      <Body5 as="div">Eligibility</Body5>
      <Body3 as="div">All employees are eligible under applicable state law.</Body3>

      {!hideDetails &&
        uniquePFMLSections.map((pfmlSection) => {
          const selectedQPSClasses = qpsBasicClasses.filter((qpsClass) => {
            const formSelectionIds = mappedQPSPlan[pfmlSection.sectionType] ?? [];
            return formSelectionIds.some((selectionId) => {
              return selectionId === getAdminClassId(qpsClass);
            });
          });

          const qpsClassesByPlanName = selectedQPSClasses.reduce<Record<string, QPSBasicClass[]>>(
            (acc, qpsClass) => {
              if (!acc[qpsClass.planDesignName]) {
                acc[qpsClass.planDesignName] = [];
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we just checked this
              acc[qpsClass.planDesignName]!.push(qpsClass);
              return acc;
            },
            {},
          );

          const showQPSPlanSummary = Object.keys(qpsClassesByPlanName).length > 0;
          return (
            <div key={pfmlSection.sectionType} data-testid="mapped-benefit-type-summary-item">
              <hr />
              <Body2 as="p">{pfmlSection.state} PFML</Body2>

              <Row align={!showQPSPlanSummary ? "middle" : "top"}>
                <Col span={12}>
                  <StackY dist={24}>
                    <div>
                      <Body5 as="div">Waiting period</Body5>
                      <Body3 as="div">There is no waiting period for eligible employees.</Body3>
                    </div>

                    <div>
                      <Body5 as="div">Contributions</Body5>
                      {pfmlSection.readOnly && pfmlSection.state ? (
                        <EIFNonClassBenefitsReadOnlyContribution
                          key={pfmlSection.sectionType}
                          sectionState={pfmlSection.state}
                          isReviewOnly
                          showSectionState={false}
                        />
                      ) : (
                        <EIFNonClassBenefitsContributionsCompletedItem
                          plans={pfmlSection.plans}
                          suppressErrorsPostSigning
                        />
                      )}
                    </div>
                  </StackY>
                </Col>

                <Col span={12}>
                  {showQPSPlanSummary && (
                    <StackY dist={24}>
                      {Object.entries(qpsClassesByPlanName).map(([planName, qpsClasses]) => (
                        <StackY
                          dist={16}
                          key={planName}
                          className={styles.qpsSelectionSummaryContainer}
                        >
                          <div>
                            <Body5 as="div">Plan Description</Body5>
                            <Body3 as="div">{planName}</Body3>
                          </div>
                          <div>
                            <Body5 as="div">Admin Class</Body5>
                            {qpsClasses.map((qpsClass) => (
                              <Body3 as="div" key={getAdminClassId(qpsClass)}>
                                {qpsClass.adminClassName}
                              </Body3>
                            ))}
                          </div>
                        </StackY>
                      ))}
                    </StackY>
                  )}
                  {!showQPSPlanSummary && (
                    <Body3 darkYellow>
                      <FontAwesomeIcon icon={faInfoCircle} className={styles.infoWarningIcon} /> No
                      Admin Class assigned
                    </Body3>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
      <hr />
      <Button type="text-only" onClick={toggleHideDetails}>
        {hideDetails ? "Show" : "Hide"} details
      </Button>
    </HubCard>
  );
};
