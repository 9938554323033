import { useCallback } from "react";

import { Loading } from "../../../components/Loading/Loading";
import { DocumentDownload } from "../../../domain/Document/DocumentDownload/DocumentDownload";
import { useTrackElementClicked } from "../../../utils/analytics";
import { InternalControlUploadFilesAndLinks } from "../../EnrollmentResourcesPage/InternalControlUploadFilesAndLinks";

import type { Client } from "shared/types/Client";

type CardCustomEnrollmentResourcesProps = {
  client: Client;
  isLoading: boolean;
  customEnrollmentResourcessStatus: string;
};

export const CardCustomEnrollmentResources = ({
  client,
  isLoading,
  customEnrollmentResourcessStatus,
}: CardCustomEnrollmentResourcesProps) => {
  const trackElementClicked = useTrackElementClicked(client, "Enrollment Resources");
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: customEnrollmentResourcessStatus,
        module: "Client Setup - Step 5",
        buttonLabel,
      });
    },
    [trackElementClicked, customEnrollmentResourcessStatus],
  );

  if (isLoading) return <Loading pageHeight={false} />;

  return (
    <>
      <p>
        Upload files or add web links to add them to the ‘Enrollment Resources’ task page. You
        should include flyers and guides that are not automatically added to Onboard. You must
        associate each resource with a Resource Type. You can optionally associate each resource
        with one or more benefit types.
      </p>
      <InternalControlUploadFilesAndLinks
        clientId={client.id}
        track={track}
        extraContent={
          <DocumentDownload
            clientId={client.id}
            categories={[
              "benefit-summaries",
              "benefit-rate-sheets",
              "enrollment-forms",
              "id-cards-and-online-access",
              "custom-enrollment-materials",
              "value-added-services",
              "group-life-accelerated-benefits",
            ]}
            webLinkCategories={[
              "benefit-summaries",
              "benefit-rate-sheets",
              "enrollment-forms",
              "id-cards-and-online-access",
              "custom-enrollment-materials",
              "value-added-services",
            ]}
            track={track}
            showResourceTypeColumn
            showBenefitTypeColumn
          />
        }
      />
    </>
  );
};
