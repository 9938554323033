import { useListParams } from "client/src/hooks/useListParams";
import { SlobList } from "../../../components/SlobList/SlobList";
import { useGetExplorerPages } from "../../../hooks/ExplorerPage/useExplorerPages";

import { ExplorerPageTable } from "./ExplorerPageTable";

import type { ClientId } from "../../../../../shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const EXPLORER_PAGE_LIST_PAGE_SIZE = 25;

export const ExplorerPageList = ({
  clientId,
  namespace,
  featureToggles,
}: {
  clientId: ClientId;
  namespace?: string;
  featureToggles: ClientFeatureToggles;
}) => {
  const listParams = useListParams({
    initialPageSize: EXPLORER_PAGE_LIST_PAGE_SIZE,
    initialSort: {
      direction: "desc",
      sortBy: "createdAt",
    },
    listNamespace: namespace,
  });

  const query = useGetExplorerPages({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    clientId,
  });

  return (
    <SlobList
      TableComponent={ExplorerPageTable}
      listParams={listParams}
      query={query}
      namespace={namespace}
      featureToggles={featureToggles}
    />
  );
};
