import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { capitalize } from "client/src/utils/string";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { otherChangesToCoverageValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, CoverageChangesEffective } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const formKeyProps: (keyof Client)[] = [
  "otherCoverageChangesTakeEffectWhen",
  "firstOfTheFollowingMonthIncludesFirstDay",
];

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const formTestId = "other-changes-to-coverage-form";

export function EIFOtherChangesToCoverage(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const eifSubStepId = "other-changes-to-coverage";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: otherChangesToCoverageValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  return (
    <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
      <h2 className="mb-40">{subStepName}</h2>
      <PFMLBulbMessage client={client} />

      <StackY dist={32}>
        <HubCard>
          <StackY dist={16}>
            <RadioGroup<CoverageChangesEffective>
              name="otherCoverageChangesTakeEffectWhen"
              label={
                <>
                  <Body2 as="p">
                    When do coverage changes due to employee status or classification changes take
                    effect?{" "}
                    <SlobPopover
                      id="more-info"
                      title={null}
                      content={
                        <>
                          This is only needed when there are multiple eligible employee groups with
                          differences of benefits, waiting periods or eligibility. Choose Not
                          applicable if you have only a single eligible employee group.
                        </>
                      }
                      placement="top"
                      trigger={["click", "hover"]}
                    >
                      <button className="btn-reset" style={{ color: "var(--color-gray-600)" }}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <span className="sr-only">Learn more about change of class effects</span>
                      </button>
                    </SlobPopover>
                  </Body2>

                  <p>
                    An example of a change in classification might be an employee who moves into a
                    management position that is a different class than their current role. A change
                    in status could be an employee moving from part-time to full-time.
                  </p>
                </>
              }
              direction="vertical"
              disabled={formik.isSubmitting}
              touched={formik.touched.otherCoverageChangesTakeEffectWhen}
              error={formik.errors.otherCoverageChangesTakeEffectWhen}
              options={[
                { value: "IMMEDIATELY", label: "Immediately" },
                {
                  value: "FIRST_OF_THE_FOLLOWING_MONTH",
                  label: "First of the following month",
                  contentSpacing: "tight",
                  content: (
                    <div className="ml-32 stack-y-16">
                      <Body5 as="div">
                        Changes will take effect on the first day of the next month.
                      </Body5>

                      {formik.values.otherCoverageChangesTakeEffectWhen ===
                        "FIRST_OF_THE_FOLLOWING_MONTH" && (
                        <Checkbox
                          name="firstOfTheFollowingMonthIncludesFirstDay"
                          label={
                            <>
                              <div className="mb-4">Include first day of the month</div>
                              <Body5>
                                Changes that occur on the first of the month will take effect the
                                same day. Otherwise, changes will take effect on the first day of
                                the next month.
                              </Body5>
                            </>
                          }
                          checked={formik.values.firstOfTheFollowingMonthIncludesFirstDay ?? false}
                          onChange={formik.handleChange}
                          disabled={formik.isSubmitting}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  value: "ANNUALLY_ON_POLICY_ANNIVERSARY",
                  label: (
                    <StackY dist={2}>
                      <Body3>Annually, on the policy anniversary</Body3>
                      {client.policyAnniversaryMonth && (
                        <Body5>
                          Your policy anniversary is{" "}
                          <b>{capitalize(client.policyAnniversaryMonth)} 1.</b>
                        </Body5>
                      )}
                    </StackY>
                  ),
                },
                {
                  value: "NOT_APPLICABLE",
                  label: "Not applicable",
                },
              ]}
              value={formik.values.otherCoverageChangesTakeEffectWhen}
              onChange={async (e) => {
                const currentValue = e.target.value;
                if (
                  currentValue === "IMMEDIATELY" ||
                  currentValue === "ANNUALLY_ON_POLICY_ANNIVERSARY"
                ) {
                  // clear out the checkbox value since it only applies for FIRST_OF_THE_FOLLOWING_MONTH option
                  await formik.setFieldValue("firstOfTheFollowingMonthIncludesFirstDay", null);
                }
                formik.handleChange(e);
              }}
            />

            <EditedFieldMsg
              changeDetailInfoList={[changeSnapshot.Client.otherCoverageChangesTakeEffectWhen]}
              client={client}
              authUser={authUser}
              hasPendingEdit={
                getHasPendingEdit({
                  field: "otherCoverageChangesTakeEffectWhen",
                  client,
                  formik,
                }) ||
                getHasPendingEdit({
                  field: "firstOfTheFollowingMonthIncludesFirstDay",
                  client,
                  formik,
                  variant: "checkbox",
                })
              }
            />
          </StackY>
        </HubCard>

        <EIFBottomNavButtons
          previousLink={previousSubStepLink}
          previousButtonDisabled={formik.isSubmitting}
          nextButtonDisabled={formik.isSubmitting}
        />
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </form>
  );
}
