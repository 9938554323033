import { faCircleXmark, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import * as styles from "./ReadinessLabel.module.less";

import type { FC } from "react";

export const DataTestId = {
  FontAwesomeIcon: "font-awesome-icon",
};

export const ReadinessLabel: FC<{ ready?: boolean }> = ({ ready = false }) => (
  <div className={styles.readinessLabelContainer}>
    <FontAwesomeIcon
      className={clsx(styles.readinessLabelIcon, {
        [styles.ready]: ready,
        [styles.notReady]: !ready,
      })}
      icon={ready ? faCheckCircle : faCircleXmark}
      data-testid={DataTestId.FontAwesomeIcon}
    />
    <span className={styles.readinessLabelText}>
      {ready === true ? "Ready for claims" : "Not ready for claims"}
    </span>
  </div>
);
