import { LocationModal } from "./LocationModal";
import { useLocationModal } from "./useLocationModal";
import type { FC, ReactNode } from "react";
import type { Client } from "shared/types/Client";
import type { Location } from "shared/types/Location";

type SaveCallback = (location: Location) => void;

export type LocationModalProviderContext = {
  locationModalVisible: boolean;
  showLocationModal: (location?: Location) => void;
  hideLocationModal: () => void;
  onLocationModalSave: (callback: SaveCallback) => void;
};

export type LocationModalProviderProps = {
  clientId: Client["id"];
  children: (context: LocationModalProviderContext) => ReactNode;
};

export const LocationModalProvider: FC<LocationModalProviderProps> = ({ clientId, children }) => {
  const [showLocationModal, hideLocationModal, onLocationModalSave, modalProps] =
    useLocationModal(clientId);

  return (
    <>
      {children({
        locationModalVisible: modalProps.visible,
        showLocationModal,
        hideLocationModal,
        onLocationModalSave,
      })}
      <LocationModal {...modalProps} />
    </>
  );
};
