import { EIFBillingPreferencesPolicySelection } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/EIFBillingPreferencesPolicySelection";
import { EIFClaimsCheckMailingLocations } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFClaimsCheckMailingLocations";
import { EIFMalpracticeReimbursement } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFMalpracticeReimbursement";
import { EIFMonthlyClaimsReportsAndEOBs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFMonthlyClaimsReportsAndEOBs";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { EIFPlaceholder } from "../common/EIFPlaceholder";
import {
  EIFPlanAdministrationAndBillingReview,
  ShowAllSubSteps,
} from "./EIFPlanAdministrationAndBillingReview";
import { EIFPlanAdministrators } from "./EIFPlanAdministrators";
import { EIFPlanAdministratorsAndBillingIntroduction } from "./EIFPlanAdministratorsAndBillingIntroduction";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { CreateContactQuery } from "client/src/hooks/contact";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { UpdatePolicyQuery } from "client/src/hooks/policy";
import type { FC } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFPlanAdministratorsAndBillingSubStepMap, EIFStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Location } from "shared/types/Location";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFPlanAdministratorsAndBillingProps = {
  eifStepId: EIFStepId;
  eifSubStepId?: EIFPlanAdministratorsAndBillingSubStepMap;
  client: Client;
  clientPlans: Plan[];
  locations: Location[];
  bills: Bill[];
  deletedBills: Bill[];
  classes: EmployeeClass[];
  employeeClassDocumentCount: number;
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  subsidiaries: Subsidiary[];
  updateClientQuery: UpdateClientQuery;
  updatePolicyQuery: UpdatePolicyQuery;
  updatePlansQuery: UpdatePlansQuery;
  createContactQuery: CreateContactQuery;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  authUser: UserData;
};

export const EIFPlanAdministratorsAndBilling: FC<EIFPlanAdministratorsAndBillingProps> = ({
  client,
  clientPlans,
  locations,
  bills,
  deletedBills,
  classes,
  employeeClassDocumentCount,
  contacts,
  billingSummaryStatementTemplates,
  subsidiaries,
  eifStepId,
  eifSubStepId,
  updateClientQuery,
  updatePolicyQuery,
  updatePlansQuery,
  createContactQuery,
  trackElementClicked,
  featureToggles,
  viewMode,
  changeSnapshot,
  authUser,
}) => {
  const { mutateAsync: updateClient } = updateClientQuery;
  const { mutateAsync: updatePolicy } = updatePolicyQuery;

  const stepCompleteStatus = getEIFStepCompleteStatus(
    eifStepId,
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    classes,
    employeeClassDocumentCount,
    changeSnapshot,
    featureToggles,
    subsidiaries,
  );

  if (!eifSubStepId) {
    return stepCompleteStatus === "Not Started" ? (
      <EIFPlanAdministratorsAndBillingIntroduction eifStepId={eifStepId} client={client} />
    ) : (
      <EIFPlanAdministrationAndBillingReview
        client={client}
        clientPlans={clientPlans}
        contacts={contacts}
        bills={bills}
        deletedBills={deletedBills}
        billingSummaryStatementTemplates={billingSummaryStatementTemplates}
        featureToggles={featureToggles}
        changeSnapshot={changeSnapshot}
        viewMode={viewMode}
        authUser={authUser}
        signerMode="inside"
        showSubSteps={ShowAllSubSteps}
        isLastStep={true}
      />
    );
  }

  switch (eifSubStepId) {
    case "plan-administrators":
      return (
        <EIFPlanAdministrators
          client={client}
          locations={locations}
          bills={bills}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          viewMode={viewMode}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "billing-preferences":
      return (
        <EIFBillingPreferencesPolicySelection
          client={client}
          bills={bills}
          deletedBills={deletedBills}
          locations={locations}
          contacts={contacts}
          billingSummaryStatementTemplates={billingSummaryStatementTemplates}
          updateClient={updateClient}
          updatePolicy={updatePolicy}
          trackElementClicked={trackElementClicked}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
        />
      );
    case "claims-check-mailing-locations":
      return (
        <EIFClaimsCheckMailingLocations
          client={client}
          clientPlans={clientPlans}
          contacts={contacts}
          featureToggles={featureToggles}
          updateClientQuery={updateClientQuery}
          updatePlansQuery={updatePlansQuery}
          createContactQuery={createContactQuery}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
        />
      );
    case "monthly-claims-reports-and-eobs":
      return (
        <EIFMonthlyClaimsReportsAndEOBs
          client={client}
          clientPlans={clientPlans}
          contacts={contacts}
          authUser={authUser}
          changeSnapshot={changeSnapshot}
          updateClientQuery={updateClientQuery}
          featureToggles={featureToggles}
        />
      );
    case "malpractice-reimbursements":
      return (
        <EIFMalpracticeReimbursement
          client={client}
          changeSnapshot={changeSnapshot}
          updateClient={updateClient}
          authUser={authUser}
          trackElementClicked={trackElementClicked}
          featureToggles={featureToggles}
        />
      );
    default:
      exhaustiveCheck(eifSubStepId);
      break;
  }

  return EIFPlaceholder({ eifStepId, eifSubStepId });
};
