import { Row, Col } from "client/src/components/Grid/Grid";
import { EIFStepBottomNavLinks } from "client/src/domain/EIF/EIFStepBottomNavLinks";
import { EIFStepHeader } from "client/src/domain/EIF/common/EIFStepHeader";
import { EIFSubStepHeader } from "client/src/domain/EIF/common/EIFSubStepHeader";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getChangeDetailInfoListForSubStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getIsLastSubStepMap } from "client/src/domain/EIF/common/utils/getIsLastStepOrSubStepMap";
import { shouldShowDivider } from "client/src/domain/EIF/common/utils/getShouldShowHorizontalDivider";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import pluralize from "pluralize";
import { useMemo } from "react";
import { RouteData } from "shared/config/routeData";
import {
  ageForDependentLifeBenefitsText,
  rehireProvisionValueToLabelMap,
  valueAddedServicesOptionsText,
} from "shared/types/Client";
import { type EIFOtherContractDetailsSubStep, type EIFStepId } from "shared/types/EIF";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import {
  getAtLeastOneSubStepIsApplicable,
  getIsSubStepApplicable,
  shouldShowValueAddedServicesLifeCoverage,
  shouldShowValueAddedServicesSuppHealthCoverage,
} from "shared/utils/EIF/getIsSubStepApplicable";
import { getRelevantClientAgeRuleFields } from "shared/utils/EIF/getRelevantClientAgeRuleFields";
import { getShouldAskAgeQuestionForSpouseDependentLife } from "shared/utils/EIF/getShouldAskAgeQuestionForSpouseDependentLife";
import { getTerminationOfInsuranceRelevantPlans } from "shared/utils/EIF/getTerminationOfInsuranceRelevantPlans";
import { getShowValidationErrorsInSummary } from "shared/utils/client";
import { getDateStringFromShortString } from "shared/utils/date";
import {
  clientValidationSchema,
  additionalWaitingPeriodRulesValidationSchema,
  rehireProvisionValidationSchema,
  unionMembersAndDomesticPartnersValidationSchema,
  changesDuringAnnualEnrollmentValidationSchema,
  otherChangesToCoverageValidationSchema,
  employeeCertificatesValidationSchema,
  valueAddedServicesSchema,
  confirmPolicyAnniversarySchema,
  section125ValidationSchema,
  erisaValidationSchema,
} from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { HorizontalDivider } from "../../components/HorizontalDivider/HorizontalDivider";
import { StackY } from "../../components/Spacing/Spacing";
import { Body3, Eyebrow } from "../../components/Typography/Typography";
import { AvoidPageBreak, PrintHidden } from "../../utils/print";
import { capitalize } from "../../utils/string";
import type { EIFSubStepsHaveChanges } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, CoverageChangesEffective, EIFSubStepViewMode } from "shared/types/Client";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type BasicProps = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

type EIFOtherContractDetailsReviewProps = BasicProps & {
  clientPlans: Plan[];
  signerMode: SignerMode;
  showSubSteps: EIFSubStepsHaveChanges<EIFOtherContractDetailsSubStep>;
  isLastStep: boolean;
};

type TerminationOfInsuranceSummaryProps = BasicProps & {
  clientPlans: Plan[];
};

const eifStepId: EIFStepId = "other-contract-details";

const otherCoverageChangesTakeEffectWhenMap: Record<CoverageChangesEffective, string> = {
  ANNUALLY_ON_POLICY_ANNIVERSARY: "Annually, on the policy anniversary",
  FIRST_OF_THE_FOLLOWING_MONTH: "First of the following month",
  IMMEDIATELY: "Immediately",
  NOT_APPLICABLE: "Not Applicable",
};

export const ShowAllSubSteps: EIFSubStepsHaveChanges<EIFOtherContractDetailsSubStep> = {
  "confirm-policy-anniversary": true,
  "additional-waiting-period-rules": true,
  "rehire-provision": true,
  "union-members-and-domestic-partners": true,
  "changes-during-annual-enrollment": true,
  "termination-of-insurance": true,
  "age-rules": true,
  "other-changes-to-coverage": true,
  "employee-certificate": true,
  "section-125": true,
  erisa: true,
  "value-added-services": true,
} as const;

export const ConfirmPolicyAnniversarySummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "confirm-policy-anniversary";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, confirmPolicyAnniversarySchema, { prefill: false })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <div>
          <Eyebrow>Policy anniversary</Eyebrow>
          <br />

          {client.policyAnniversaryMonth && (
            <Body3 as="div">{`${capitalize(client.policyAnniversaryMonth)} 1`}</Body3>
          )}

          {e.policyAnniversaryMonth && <Body3 redMedium>{e.policyAnniversaryMonth}</Body3>}
          {!client.policyAnniversaryMonth && !e.policyAnniversaryMonth && <Body3>-</Body3>}
        </div>
      )}
    </div>
  );
};

export const AdditionalWaitingPeriodRulesSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "additional-waiting-period-rules";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const hasTimeServedCheckboxAnswered =
    client.timeSpentAsPartTimeShouldCount ||
    (client.timeSpentAsOtherShouldCount && client.timeSpentAsOtherShouldCountText);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, additionalWaitingPeriodRulesValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          <div>
            <Eyebrow>
              Should the employee’s non-benefit eligible time served count towards their waiting
              period?
            </Eyebrow>
            <br />

            {client.timeSpentShouldCountTowardsWaitingPeriod && (
              <Body3 as="div">
                {client.timeSpentShouldCountTowardsWaitingPeriod === "YES" && "Yes"}
                {client.timeSpentShouldCountTowardsWaitingPeriod === "NO" && "No"}
              </Body3>
            )}

            {e.timeSpentShouldCountTowardsWaitingPeriod && (
              <Body3 redMedium>{e.timeSpentShouldCountTowardsWaitingPeriod}</Body3>
            )}

            {!client.timeSpentShouldCountTowardsWaitingPeriod &&
              !e.timeSpentShouldCountTowardsWaitingPeriod && <Body3>-</Body3>}
          </div>

          {client.timeSpentShouldCountTowardsWaitingPeriod === "YES" &&
            hasTimeServedCheckboxAnswered && (
              <div>
                <Eyebrow>
                  What types of employee time already served count towards the waiting period?
                </Eyebrow>
                <br />

                {client.timeSpentAsPartTimeShouldCount && <Body3 as="div">Part time</Body3>}
                {client.timeSpentAsOtherShouldCount && client.timeSpentAsOtherShouldCountText && (
                  <Body3 as="div">{client.timeSpentAsOtherShouldCountText}</Body3>
                )}

                {(e.timeSpentAsPartTimeShouldCount ||
                  e.timeSpentAsOtherShouldCount ||
                  e.timeSpentAsOtherShouldCountText) && (
                  <Body3 redMedium>
                    {e.timeSpentAsPartTimeShouldCount ||
                      e.timeSpentAsOtherShouldCount ||
                      e.timeSpentAsOtherShouldCountText}
                  </Body3>
                )}

                {!client.timeSpentAsPartTimeShouldCount &&
                  !(client.timeSpentAsOtherShouldCount && client.timeSpentAsOtherShouldCountText) &&
                  !e.timeSpentAsPartTimeShouldCount &&
                  !e.timeSpentAsOtherShouldCount &&
                  !e.timeSpentAsOtherShouldCountText && <Body3>-</Body3>}
              </div>
            )}

          <div>
            <Eyebrow>
              Should the waiting period be waived for new employees hired before the plan effective
              date?
            </Eyebrow>
            <br />

            {client.waitingPeriodShouldBeWaived && (
              <Body3 as="div">
                {client.waitingPeriodShouldBeWaived === "YES" && "Yes"}
                {client.waitingPeriodShouldBeWaived === "NO" && "No"}
              </Body3>
            )}

            {e.waitingPeriodShouldBeWaived && (
              <Body3 redMedium>{e.waitingPeriodShouldBeWaived}</Body3>
            )}

            {!client.waitingPeriodShouldBeWaived && !e.waitingPeriodShouldBeWaived && (
              <Body3>-</Body3>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const RehireProvisionSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "rehire-provision";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, rehireProvisionValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({
            eifSubStepId,
            changeSnapshot,
          })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          <div>
            <Eyebrow>
              Do you want to set a rehire provision for employees who are rehired within a specific
              time frame from their termination date?
            </Eyebrow>
            <br />

            {client.reHireProvisionExists === "YES" && <Body3 as="div">Yes</Body3>}
            {client.reHireProvisionExists === "NO" && <Body3 as="div">No</Body3>}

            {e.reHireProvisionExists && <Body3 redMedium>{e.reHireProvisionExists}</Body3>}
            {!client.reHireProvisionExists && !e.reHireProvisionExists && <Body3>-</Body3>}
          </div>

          {client.reHireProvisionExists === "YES" && (
            <div>
              <Eyebrow>What is the time frame for your rehire provision?</Eyebrow>
              <br />
              {client.rehireProvision ? (
                <Body3 as="div">{rehireProvisionValueToLabelMap[client.rehireProvision]}</Body3>
              ) : (
                // reHireProvisionUnit and reHireProvisionDuration
                // are legacy fields that have been replaced by reHireProvision as of TB-5584
                client.reHireProvisionUnit &&
                client.reHireProvisionDuration && (
                  <Body3 as="div">
                    {client.reHireProvisionDuration}{" "}
                    {pluralize(
                      client.reHireProvisionUnit,
                      client.reHireProvisionDuration,
                    ).toLocaleLowerCase()}
                  </Body3>
                )
              )}

              {e.rehireProvision && !client.rehireProvision && (
                <Body3 redMedium>{e.rehireProvision}</Body3>
              )}
              {!e.rehireProvision && !client.rehireProvision && <Body3>-</Body3>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const UnionMembersAndDomesticPartnersSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "union-members-and-domestic-partners";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, unionMembersAndDomesticPartnersValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          <div>
            <Eyebrow>Union Members</Eyebrow>
            <br />

            {client.unionMembersCovered && (
              <Body3 as="div">
                {client.unionMembersCovered === "YES" ? "Covered" : "Not covered"}
              </Body3>
            )}

            {e.unionMembersCovered && !client.unionMembersCovered && (
              <Body3 redMedium>{e.unionMembersCovered}</Body3>
            )}
            {!e.unionMembersCovered && !client.unionMembersCovered && <Body3>-</Body3>}
          </div>

          <div>
            <Eyebrow>Domestic Partners</Eyebrow>
            <br />

            {client.domesticPartnersCovered && (
              <Body3 as="div">
                {client.domesticPartnersCovered === "YES" ? "Covered" : "Not covered"}
              </Body3>
            )}

            {e.domesticPartnersCovered && !client.domesticPartnersCovered && (
              <Body3 redMedium>{e.domesticPartnersCovered}</Body3>
            )}
            {!e.domesticPartnersCovered && !client.domesticPartnersCovered && <Body3>-</Body3>}
          </div>
        </>
      )}
    </div>
  );
};

export const ChangesDuringAnnualEnrollmentSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
  featureToggles,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "changes-during-annual-enrollment";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client, featureToggles });

  const aepStartDateLongForm = client.whenDoYouAdministerYourAEPStartDate
    ? getDateStringFromShortString(client.whenDoYouAdministerYourAEPStartDate)
    : null;

  const aepEndDateLongForm = client.whenDoYouAdministerYourAEPEndDate
    ? getDateStringFromShortString(client.whenDoYouAdministerYourAEPEndDate)
    : null;

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);
  const e = getFormikErrors(client, changesDuringAnnualEnrollmentValidationSchema, {
    prefill: false,
  });

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={suppressErrorsPostSigning ? false : Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          {(client.changesOnlyAllowedDuringAE || e.changesOnlyAllowedDuringAE) && (
            <div>
              <Eyebrow>
                Are changes to benefits only allowed during your annual enrollment period?
              </Eyebrow>
              <br />

              {client.changesOnlyAllowedDuringAE === "YES" && <Body3 as="div">Yes</Body3>}
              {client.changesOnlyAllowedDuringAE === "NO" && <Body3 as="div">No</Body3>}

              {e.changesOnlyAllowedDuringAE &&
                !client.changesOnlyAllowedDuringAE &&
                !suppressErrorsPostSigning && (
                  <Body3 redMedium>{e.changesOnlyAllowedDuringAE}</Body3>
                )}
              {!client.changesOnlyAllowedDuringAE &&
                e.changesOnlyAllowedDuringAE &&
                suppressErrorsPostSigning && <Body3>-</Body3>}
            </div>
          )}

          {(client.changesOnlyAllowedDuringAE === "NO" || e.whenDoChangesToBenefitsTakeEffect) && (
            <div>
              <Eyebrow>When do changes to benefits take effect?</Eyebrow>
              <br />

              {client.whenDoChangesToBenefitsTakeEffect === "IMMEDIATELY" && (
                <Body3 as="div">Immediately</Body3>
              )}
              {client.whenDoChangesToBenefitsTakeEffect === "FOFM" && (
                <Body3 as="div">First of the following month</Body3>
              )}
              {client.whenDoChangesToBenefitsTakeEffect === "FOFM_INCLUSIVE" && (
                <Body3 as="div">First of the following month coincident</Body3>
              )}

              {e.whenDoChangesToBenefitsTakeEffect &&
                !client.whenDoChangesToBenefitsTakeEffect &&
                !suppressErrorsPostSigning && (
                  <Body3 redMedium>{e.whenDoChangesToBenefitsTakeEffect}</Body3>
                )}

              {!client.whenDoChangesToBenefitsTakeEffect &&
                e.whenDoChangesToBenefitsTakeEffect &&
                suppressErrorsPostSigning && <Body3>-</Body3>}
            </div>
          )}

          {(client.whenDoYouAdministerYourAEP || e.whenDoYouAdministerYourAEP) && (
            <div>
              <Eyebrow>When do you administer your annual enrollment period?</Eyebrow>
              <br />

              {client.whenDoYouAdministerYourAEP === "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY" && (
                <Body3 as="div">On the calendar month prior to the policy anniversary</Body3>
              )}
              {client.whenDoYouAdministerYourAEP === "CUSTOM" &&
                aepStartDateLongForm &&
                aepEndDateLongForm && (
                  <Body3 as="div">
                    {aepStartDateLongForm} through {aepEndDateLongForm}
                  </Body3>
                )}

              {(e.whenDoYouAdministerYourAEP ||
                e.whenDoYouAdministerYourAEPStartDate ||
                e.whenDoYouAdministerYourAEPEndDate) &&
                !(
                  client.whenDoYouAdministerYourAEP === "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY" ||
                  (client.whenDoYouAdministerYourAEP === "CUSTOM" &&
                    aepStartDateLongForm &&
                    aepEndDateLongForm)
                ) &&
                !suppressErrorsPostSigning && (
                  <Body3 redMedium>
                    {e.whenDoYouAdministerYourAEP ||
                      e.whenDoYouAdministerYourAEPStartDate ||
                      e.whenDoYouAdministerYourAEPEndDate}
                  </Body3>
                )}

              {!(
                client.whenDoYouAdministerYourAEP === "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY" ||
                (client.whenDoYouAdministerYourAEP === "CUSTOM" &&
                  aepStartDateLongForm &&
                  aepEndDateLongForm)
              ) &&
                (e.whenDoYouAdministerYourAEP ||
                  e.whenDoYouAdministerYourAEPStartDate ||
                  e.whenDoYouAdministerYourAEPEndDate) &&
                suppressErrorsPostSigning && <Body3>-</Body3>}
            </div>
          )}

          {(client.whenDoAEPTakeEffect || e.whenDoAEPTakeEffect || e.whenDoAEPTakeEffectOther) && (
            <div>
              <Eyebrow>When do annual enrollment changes take effect?</Eyebrow>
              <br />

              <Body3 as="div">
                {client.whenDoAEPTakeEffect === "ANNUALLY" && "Annually"}
                {client.whenDoAEPTakeEffect === "OTHER" && client.whenDoAEPTakeEffectOther}
              </Body3>

              {(e.whenDoAEPTakeEffect || e.whenDoAEPTakeEffectOther) &&
                !suppressErrorsPostSigning && (
                  <Body3 redMedium>{e.whenDoAEPTakeEffect || e.whenDoAEPTakeEffectOther}</Body3>
                )}
              {(e.whenDoAEPTakeEffect || e.whenDoAEPTakeEffect || e.whenDoAEPTakeEffectOther) &&
                !(
                  client.whenDoAEPTakeEffect === "ANNUALLY" ||
                  (client.whenDoAEPTakeEffect === "OTHER" && client.whenDoAEPTakeEffectOther)
                ) &&
                suppressErrorsPostSigning && <Body3>-</Body3>}
            </div>
          )}

          {(client.whenDecreasesInsuranceTakeEffect || e.whenDecreasesInsuranceTakeEffect) && (
            <div>
              <Eyebrow>When do decreases in insurance take effect?</Eyebrow>
              <br />

              {client.whenDecreasesInsuranceTakeEffect && (
                <Body3 as="div">
                  {client.whenDecreasesInsuranceTakeEffect === "AT_ANNUAL_ENROLLMENT"
                    ? "At annual enrollment"
                    : "Immediately"}
                </Body3>
              )}

              {e.whenDecreasesInsuranceTakeEffect &&
                !client.whenDecreasesInsuranceTakeEffect &&
                !suppressErrorsPostSigning && (
                  <Body3 redMedium>{e.whenDecreasesInsuranceTakeEffect}</Body3>
                )}

              {!client.whenDecreasesInsuranceTakeEffect &&
                e.whenDecreasesInsuranceTakeEffect &&
                suppressErrorsPostSigning && <Body3>-</Body3>}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TerminationOfInsuranceSummary = ({
  client,
  clientPlans,
  viewMode,
  changeSnapshot,
  authUser,
}: TerminationOfInsuranceSummaryProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "termination-of-insurance";

  const subStepStatus = getEIFSubStepStatus({
    eifSubStepId,
    client,
    clientPlans,
  });

  const relevantPlans = getTerminationOfInsuranceRelevantPlans(clientPlans);

  const planDetails = useMemo(
    () =>
      relevantPlans.reduce<Partial<Record<BenefitTypeEIF, Plan>>>((acc, clientPlan) => {
        if (clientPlan.benefitType in acc) {
          return acc;
        }

        acc[clientPlan.benefitType] = clientPlan;
        return acc;
      }, {}),
    [relevantPlans],
  );

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const shouldShowValidationErrors = getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({
            eifSubStepId,
            changeSnapshot,
            clientPlans,
          })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={
          shouldShowValidationErrors && relevantPlans.some((plan) => plan.terminationDate == null)
        }
      />

      {relevantPlans.length === 0 && <Body3 as="div">No applicable plans.</Body3>}

      {subStepStatus !== "Not Started" && (
        <>
          {Object.values(planDetails).map((clientPlan) => (
            <div key={clientPlan.id}>
              <Eyebrow>{benefitTypeToCoverage[clientPlan.benefitType]}</Eyebrow>
              <br />

              {clientPlan.terminationDate && (
                <Body3 as="div">
                  {clientPlan.terminationDate === "DATE_OF_TERM"
                    ? "Date of termination"
                    : clientPlan.terminationDate === "END_OF_MONTH"
                    ? "End of the month"
                    : null}
                </Body3>
              )}

              {shouldShowValidationErrors && clientPlan.terminationDate == null && (
                <Body3 redMedium>Please select a termination date</Body3>
              )}
              {!shouldShowValidationErrors && clientPlan.terminationDate == null && (
                <Body3>-</Body3>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const AgeRulesSummary = ({ client, changeSnapshot, viewMode, authUser }: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "age-rules";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const showDependentBasicLifeAge = getShouldAskAgeQuestionForSpouseDependentLife(client);

  const relevantClientFields = getRelevantClientAgeRuleFields(client);
  const relevantValidationSchema = clientValidationSchema.pick(relevantClientFields);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, relevantValidationSchema, {
        client,
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          {client.ageRules?.includes("Age Reductions") && (
            <div>
              <Eyebrow>Age Reductions</Eyebrow>
              <br />
              {client.ageReductionDecreaseTakeEffectWhen && (
                <Body3 as="div">
                  {otherCoverageChangesTakeEffectWhenMap[client.ageReductionDecreaseTakeEffectWhen]}
                </Body3>
              )}

              {e.ageReductionDecreaseTakeEffectWhen &&
                !client.ageReductionDecreaseTakeEffectWhen && (
                  <Body3 redMedium>{e.ageReductionDecreaseTakeEffectWhen}</Body3>
                )}

              {!e.ageReductionDecreaseTakeEffectWhen &&
                !client.ageReductionDecreaseTakeEffectWhen && <Body3>-</Body3>}
            </div>
          )}

          {client.ageRules?.includes("Age Band Rates") && (
            <div>
              <Eyebrow>Age Bands</Eyebrow>
              <br />

              {client.ageBandIncreaseTakeEffectWhen && (
                <Body3 as="div">
                  {otherCoverageChangesTakeEffectWhenMap[client.ageBandIncreaseTakeEffectWhen]}
                </Body3>
              )}

              {e.ageBandIncreaseTakeEffectWhen && !client.ageBandIncreaseTakeEffectWhen && (
                <Body3 redMedium>{e.ageBandIncreaseTakeEffectWhen}</Body3>
              )}

              {!e.ageBandIncreaseTakeEffectWhen && !client.ageBandIncreaseTakeEffectWhen && (
                <Body3>-</Body3>
              )}
            </div>
          )}

          {showDependentBasicLifeAge && (
            <div>
              <Eyebrow>Age for spouse Dependent Life benefits</Eyebrow>
              <br />

              {client.ageForDependentLifeBenefits && (
                <Body3 as="div">
                  {ageForDependentLifeBenefitsText[client.ageForDependentLifeBenefits]}
                </Body3>
              )}

              {e.ageForDependentLifeBenefits && !client.ageForDependentLifeBenefits && (
                <Body3 redMedium>{e.ageForDependentLifeBenefits}</Body3>
              )}

              {!e.ageForDependentLifeBenefits && !client.ageForDependentLifeBenefits && (
                <Body3>-</Body3>
              )}
            </div>
          )}

          {client.criticalIllnessDependentAge !== null && (
            <div>
              <Eyebrow>Age for Dependent Critical Illness benefits</Eyebrow>
              <br />

              <Body3 as="div">
                {client.criticalIllnessDependentAge === "EMPLOYEE" ? "Employee" : "Spouse"}
              </Body3>

              {e.criticalIllnessDependentAge && !client.criticalIllnessDependentAge && (
                <Body3 redMedium>{e.criticalIllnessDependentAge}</Body3>
              )}

              {!e.criticalIllnessDependentAge && !client.criticalIllnessDependentAge && (
                <Body3>-</Body3>
              )}
            </div>
          )}

          {client.criticalIllnessDependentTobacco !== null && (
            <div>
              <Eyebrow>Dependent tobacco status</Eyebrow>
              <br />

              <Body3 as="div">
                {client.criticalIllnessDependentTobacco === "EMPLOYEE" ? "Employee" : "Spouse"}
              </Body3>

              {e.criticalIllnessDependentTobacco && !client.criticalIllnessDependentTobacco && (
                <Body3 redMedium>{e.criticalIllnessDependentTobacco}</Body3>
              )}

              {!e.criticalIllnessDependentTobacco && !client.criticalIllnessDependentTobacco && (
                <Body3>-</Body3>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const OtherChangesToCoverageSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "other-changes-to-coverage";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, otherChangesToCoverageValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <div>
          <Eyebrow>When does a change of class take effect?</Eyebrow>
          <br />

          {client.otherCoverageChangesTakeEffectWhen && (
            <Body3 as="div">
              {otherCoverageChangesTakeEffectWhenMap[client.otherCoverageChangesTakeEffectWhen]}
            </Body3>
          )}

          {client.otherCoverageChangesTakeEffectWhen === "FIRST_OF_THE_FOLLOWING_MONTH" && (
            <Body3 as="div">
              {client.firstOfTheFollowingMonthIncludesFirstDay
                ? "Changes that occur on the first of the month will take effect the same day."
                : "Changes will take effect on the first day of the next month."}
            </Body3>
          )}

          {e.otherCoverageChangesTakeEffectWhen && (
            <Body3 redMedium>{e.otherCoverageChangesTakeEffectWhen}</Body3>
          )}
        </div>
      )}
    </div>
  );
};

export const EmployeeCertificateSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "employee-certificate";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, employeeCertificatesValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <div>
          <Eyebrow>How should employee certificate be split</Eyebrow>
          <br />

          {client.shouldCertificatesBeSplitInAnotherWay && (
            <Body3 as="div">
              {client.shouldCertificatesBeSplitInAnotherWay === "NO"
                ? "By benefit"
                : client.shouldCertificatesBeSplitInAnotherWayText}
            </Body3>
          )}

          {(e.shouldCertificatesBeSplitInAnotherWay ||
            e.shouldCertificatesBeSplitInAnotherWayText) &&
            !(
              client.shouldCertificatesBeSplitInAnotherWay === "NO" ||
              (client.shouldCertificatesBeSplitInAnotherWay === "YES" &&
                client.shouldCertificatesBeSplitInAnotherWayText)
            ) && (
              <Body3 redMedium>
                {e.shouldCertificatesBeSplitInAnotherWay ||
                  e.shouldCertificatesBeSplitInAnotherWayText}
              </Body3>
            )}

          {!e.shouldCertificatesBeSplitInAnotherWay &&
            !e.shouldCertificatesBeSplitInAnotherWayText &&
            !(
              client.shouldCertificatesBeSplitInAnotherWay === "NO" ||
              (client.shouldCertificatesBeSplitInAnotherWay === "YES" &&
                client.shouldCertificatesBeSplitInAnotherWayText)
            ) && <Body3>-</Body3>}
        </div>
      )}
    </div>
  );
};

export const Section125Summary = ({ client, changeSnapshot, viewMode, authUser }: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "section-125";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, section125ValidationSchema, { prefill: false })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <div>
          <Eyebrow>
            {client.hasSection125Plan === "YES"
              ? "What benefits are included in your Section 125 plan?"
              : "Do you offer a section 125 plan?"}
          </Eyebrow>
          <br />

          {client.hasSection125Plan === "NO" && <Body3 as="div">No</Body3>}

          {client.hasSection125Plan === "YES" && client.hasSection125Dental && (
            <Body3 as="div">Dental</Body3>
          )}

          {client.hasSection125Plan === "YES" && client.hasSection125Vision && (
            <Body3 as="div">Vision</Body3>
          )}

          {client.hasSection125Plan === "YES" && client.hasSection125DHMO && (
            <Body3 as="div">Dental (DHMO)</Body3>
          )}

          {client.hasSection125Plan === "YES" &&
            client.hasAdditionalCoverageIncludedIn125Plan &&
            client.additonalCoverageIncludedInSection125Plan &&
            client.additonalCoverageIncludedInSection125Plan.length > 0 && (
              <Body3 as="div">{client.additonalCoverageIncludedInSection125Plan.join(" + ")}</Body3>
            )}

          {client.hasSection125Plan === "YES" && (
            <Body3 redMedium>
              {e.hasSection125Plan ||
                e.hasSection125Dental ||
                e.hasSection125Vision ||
                e.hasAdditionalCoverageIncludedIn125Plan ||
                e.additonalCoverageIncludedInSection125Plan}
            </Body3>
          )}

          {!(
            e.hasSection125Plan ||
            e.hasSection125Dental ||
            e.hasSection125Vision ||
            e.hasAdditionalCoverageIncludedIn125Plan ||
            e.additonalCoverageIncludedInSection125Plan
          ) &&
            !(
              client.hasSection125Plan === "NO" ||
              (client.hasSection125Plan === "YES" && client.hasSection125Dental) ||
              (client.hasSection125Plan === "YES" && client.hasSection125Vision) ||
              (client.hasSection125Plan === "YES" && client.hasSection125DHMO) ||
              (client.additonalCoverageIncludedInSection125Plan &&
                client.additonalCoverageIncludedInSection125Plan.length > 0)
            ) && <Body3>-</Body3>}
        </div>
      )}
    </div>
  );
};

export const ERISASummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
  featureToggles,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "erisa";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client, featureToggles });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);

  const formatAddress = (city: string | null, state: string | null, zipCode: string | null) => {
    const result =
      city || state || zipCode
        ? [city, `${state || ""} ${zipCode || ""}`.trim()]
            .filter(Boolean)
            .flatMap((s) => [s, ", "])
            .slice(0, -1)
            .join("")
            .trim()
        : null;
    return result;
  };

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, erisaValidationSchema, {
        client,
        prefill: false,
      })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({
            eifSubStepId,
            changeSnapshot,
            client,
          })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          <div>
            <Eyebrow>Is your company's plan subject to ERISA?</Eyebrow>
            <br />

            {client.erisaHasPlan === "YES" && <Body3 as="div">Yes</Body3>}
            {client.erisaHasPlan === "NO" && <Body3 as="div">No</Body3>}

            {e.erisaHasPlan && !client.erisaHasPlan && <Body3 redMedium>{e.erisaHasPlan}</Body3>}
            {!e.erisaHasPlan && !client.erisaHasPlan && <Body3>-</Body3>}
          </div>

          {client.erisaHasPlan === "YES" && (
            <>
              <div>
                <Eyebrow>
                  Would you like us to provide custom ERISA plan details in your certificate?
                </Eyebrow>
                <br />

                {client.erisaPrefersPlanDetails === "YES" && <Body3 as="div">Yes</Body3>}
                {client.erisaPrefersPlanDetails === "NO" && <Body3 as="div">No</Body3>}

                {e.erisaPrefersPlanDetails && !client.erisaPrefersPlanDetails && (
                  <Body3 redMedium>{e.erisaPrefersPlanDetails}</Body3>
                )}
                {!e.erisaPrefersPlanDetails && !client.erisaPrefersPlanDetails && <Body3>-</Body3>}
              </div>

              {client.erisaPrefersPlanDetails === "YES" && (
                <>
                  <div>
                    <Eyebrow>ERISA plan administrator</Eyebrow>
                    <br />

                    {client.erisaAdministerOwnPlan === "YES" && (
                      <Body3 as="div">Policyholder</Body3>
                    )}
                    {client.erisaAdministerOwnPlan === "NO" && (
                      <Body3 as="div">
                        {[
                          client.erisaPlanAdminName,
                          client.erisaStreetAddress,
                          formatAddress(client.erisaCity, client.erisaState, client.erisaZipCode),
                        ]
                          .filter(Boolean)
                          .flatMap((el, i) => [el, <br key={i} />])
                          .slice(0, -1)}
                      </Body3>
                    )}

                    {((e.erisaAdministerOwnPlan && !client.erisaAdministerOwnPlan) ||
                      (e.erisaPlanAdminName && !client.erisaPlanAdminName) ||
                      (e.erisaPlanNumber && !client.erisaPlanNumber) ||
                      (e.erisaPlanAdminName && !client.erisaPlanAdminName) ||
                      (e.erisaStreetAddress && !client.erisaStreetAddress) ||
                      (e.erisaCity && !client.erisaCity) ||
                      (e.erisaState && !client.erisaState) ||
                      (e.erisaZipCode && !client.erisaZipCode)) && (
                      <Body3 redMedium>
                        {e.erisaAdministerOwnPlan ||
                          e.erisaPlanAdminName ||
                          e.erisaStreetAddress ||
                          e.erisaCity ||
                          e.erisaState ||
                          e.erisaZipCode}
                      </Body3>
                    )}

                    {!(
                      e.erisaAdministerOwnPlan ||
                      e.erisaPlanAdminName ||
                      e.erisaStreetAddress ||
                      e.erisaCity ||
                      e.erisaState ||
                      e.erisaZipCode
                    ) &&
                      client.erisaAdministerOwnPlan !== "YES" &&
                      !(
                        client.erisaAdministerOwnPlan === "NO" &&
                        client.erisaPlanAdminName &&
                        client.erisaStreetAddress &&
                        client.erisaCity &&
                        client.erisaState &&
                        client.erisaZipCode
                      ) && <Body3>-</Body3>}
                  </div>

                  <div>
                    <Eyebrow>ERISA agent for legal process</Eyebrow>
                    <br />

                    {client.erisaHasAgentForLegalProcess === "YES" && (
                      <Body3 as="div">Policyholder</Body3>
                    )}
                    {client.erisaHasAgentForLegalProcess === "NO" && (
                      <>
                        {client.erisaAdministerOwnPlan === "NO" &&
                        client.erisaIsLegalAgentSameAsAdmin ? (
                          <Body3 as="div">Same as plan administrator</Body3>
                        ) : (
                          <Body3 as="div">
                            {[
                              client.erisaLegalAgentName,
                              client.erisaLegalAgentStreetAddress,
                              formatAddress(
                                client.erisaLegalAgentCity,
                                client.erisaLegalAgentState,
                                client.erisaLegalAgentZipCode,
                              ),
                            ]
                              .filter(Boolean)
                              .flatMap((el, i) => [el, <br key={i} />])
                              .slice(0, -1)}
                          </Body3>
                        )}
                      </>
                    )}

                    {(e.erisaHasAgentForLegalProcess ||
                      e.erisaIsLegalAgentSameAsAdmin ||
                      e.erisaLegalAgentName ||
                      e.erisaLegalAgentStreetAddress ||
                      e.erisaLegalAgentCity ||
                      e.erisaLegalAgentState ||
                      e.erisaLegalAgentZipCode) &&
                      !(
                        client.erisaLegalAgentName ||
                        client.erisaLegalAgentStreetAddress ||
                        client.erisaLegalAgentCity ||
                        client.erisaLegalAgentState ||
                        client.erisaLegalAgentZipCode
                      ) && (
                        <Body3 redMedium>
                          {e.erisaHasAgentForLegalProcess ||
                            e.erisaIsLegalAgentSameAsAdmin ||
                            e.erisaLegalAgentName ||
                            e.erisaLegalAgentStreetAddress ||
                            e.erisaLegalAgentCity ||
                            e.erisaLegalAgentState ||
                            e.erisaLegalAgentZipCode}
                        </Body3>
                      )}
                    {!(
                      e.erisaHasAgentForLegalProcess ||
                      e.erisaIsLegalAgentSameAsAdmin ||
                      e.erisaLegalAgentName ||
                      e.erisaLegalAgentStreetAddress ||
                      e.erisaLegalAgentCity ||
                      e.erisaLegalAgentState ||
                      e.erisaLegalAgentZipCode
                    ) &&
                      client.erisaHasAgentForLegalProcess !== "YES" &&
                      !(
                        client.erisaAdministerOwnPlan === "NO" &&
                        client.erisaIsLegalAgentSameAsAdmin
                      ) &&
                      !(
                        client.erisaHasAgentForLegalProcess === "NO" &&
                        client.erisaLegalAgentName &&
                        client.erisaLegalAgentStreetAddress &&
                        client.erisaLegalAgentCity &&
                        client.erisaLegalAgentState &&
                        client.erisaLegalAgentZipCode
                      ) && <Body3>-</Body3>}
                  </div>

                  <div>
                    <Eyebrow>What is your company tax ID number?</Eyebrow>
                    <br />

                    {client.taxId && <Body3 as="div">{client.taxId}</Body3>}
                    {e.taxId && !client.taxId && <Body3 redMedium>{e.taxId}</Body3>}
                    {!e.taxId && !client.taxId && <Body3>-</Body3>}
                  </div>

                  <div>
                    <Eyebrow>What is your ERISA plan number?</Eyebrow>
                    <br />

                    {(client.erisaPlanNumber === "PN501" || client.erisaPlanNumber === "PN502") && (
                      <Body3 as="div">{client.erisaPlanNumber}</Body3>
                    )}

                    {client.erisaPlanNumber === "OTHER" && client.erisaPlanNumberOther && (
                      <Body3 as="div">{client.erisaPlanNumberOther}</Body3>
                    )}

                    {(e.erisaPlanNumber || e.erisaPlanNumberOther) &&
                      !(client.erisaPlanNumber === "PN501" || client.erisaPlanNumber === "PN502") &&
                      !(client.erisaPlanNumber === "OTHER" && client.erisaPlanNumberOther) && (
                        <Body3 redMedium>{e.erisaPlanNumber || e.erisaPlanNumberOther}</Body3>
                      )}
                    {!(e.erisaPlanNumber || e.erisaPlanNumberOther) &&
                      !(client.erisaPlanNumber === "PN501" || client.erisaPlanNumber === "PN502") &&
                      !(client.erisaPlanNumber === "OTHER" && client.erisaPlanNumberOther) && (
                        <Body3>-</Body3>
                      )}
                  </div>

                  <div>
                    <Eyebrow>What is your plan year end date?</Eyebrow>
                    <br />
                    {client.erisaPlanYearEndDateMMDD && (
                      <Body3 as="div">{client.erisaPlanYearEndDateMMDD}</Body3>
                    )}
                    {e.erisaPlanYearEndDateMMDD && !client.erisaPlanYearEndDateMMDD && (
                      <Body3 redMedium>{e.erisaPlanYearEndDateMMDD}</Body3>
                    )}
                    {!e.erisaPlanYearEndDateMMDD && !client.erisaPlanYearEndDateMMDD && (
                      <Body3>-</Body3>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {client.erisaHasPlan === "NO" && (
            <Row>
              <Col>
                <Eyebrow>Your company's plan is:</Eyebrow>
                <br />

                {client.erisaPlanType === "CHURCH_PLAN" && <Body3 as="div">Church plan</Body3>}
                {client.erisaPlanType === "GOVERNMENT_PLAN" && (
                  <Body3 as="div">Government plan</Body3>
                )}
                {client.erisaPlanType === "OTHER" && client.erisaPlanTypeOther && (
                  <Body3 as="div">{client.erisaPlanTypeOther}</Body3>
                )}

                {(e.erisaPlanType || e.erisaPlanTypeOther) &&
                  !(
                    client.erisaPlanType === "CHURCH_PLAN" ||
                    client.erisaPlanType === "GOVERNMENT_PLAN" ||
                    (client.erisaPlanType === "OTHER" && client.erisaPlanTypeOther)
                  ) && <Body3 redMedium>{e.erisaPlanType || e.erisaPlanTypeOther}</Body3>}
                {!(e.erisaPlanType || e.erisaPlanTypeOther) &&
                  !(
                    client.erisaPlanType === "CHURCH_PLAN" ||
                    client.erisaPlanType === "GOVERNMENT_PLAN" ||
                    (client.erisaPlanType === "OTHER" && client.erisaPlanTypeOther)
                  ) && <Body3>-</Body3>}
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export const ValueAddedServicesSummary = ({
  client,
  changeSnapshot,
  viewMode,
  authUser,
}: BasicProps) => {
  const eifSubStepId: EIFOtherContractDetailsSubStep = "value-added-services";

  const subStepStatus = getEIFSubStepStatus({ eifSubStepId, client });

  const showEditBanner = getShowEditBanner(viewMode);

  const editPath = RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId);
  const showValueAddedServicesLifeCoverage = shouldShowValueAddedServicesLifeCoverage(client);
  const showValueAddedServicesSuppHealthCoverage =
    shouldShowValueAddedServicesSuppHealthCoverage(client);
  const emergencyTravelAssistanceSelected =
    client.valueAddedServicesOptions &&
    client.valueAddedServicesOptions === "EMERGENCY_TRAVEL_ASSISTANCE";
  const hasAccidentCoverage = client.allPoliciesSlfCoverages?.includes("Accident");
  const showAccidentReadOnly = hasAccidentCoverage && !emergencyTravelAssistanceSelected;
  const showCriticalIllnessReadOnly = client.allPoliciesSlfCoverages?.includes("Critical Illness");

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(client, valueAddedServicesSchema, { client, prefill: false })
    : {};

  return (
    <div data-testid={eifSubStepId} className="stack-y-16">
      {showEditBanner && (
        <EditBanner
          navigateLink={editPath}
          changeDetailInfoList={getChangeDetailInfoListForSubStep({ eifSubStepId, changeSnapshot })}
          client={client}
          authUser={authUser}
        />
      )}

      <EIFSubStepHeader
        eifSubStepId={eifSubStepId}
        viewMode={viewMode}
        editPath={editPath}
        subStepStatus={subStepStatus}
        showRedEditBanner={Object.keys(e).length > 0}
      />

      {subStepStatus !== "Not Started" && (
        <>
          {client.valueAddedServicesOptions && showValueAddedServicesLifeCoverage && (
            <Body3 as="div">
              {valueAddedServicesOptionsText[client.valueAddedServicesOptions]}
            </Body3>
          )}
          {showValueAddedServicesSuppHealthCoverage && showAccidentReadOnly && (
            <Body3 as="div">Accident: Emergency Travel Assistance & ID Theft Protection</Body3>
          )}
          {showValueAddedServicesSuppHealthCoverage && showCriticalIllnessReadOnly && (
            <Body3 as="div">Critical Illness: Health Care Navigation Service</Body3>
          )}

          {e.valueAddedServicesOptions && !client.valueAddedServicesOptions && (
            <Body3 redMedium>{e.valueAddedServicesOptions}</Body3>
          )}
          {!e.valueAddedServicesOptions && !client.valueAddedServicesOptions && <Body3>-</Body3>}
        </>
      )}
    </div>
  );
};

export const EIFOtherContractDetailsReview = ({
  client,
  clientPlans,
  changeSnapshot,
  viewMode,
  featureToggles,
  authUser,
  signerMode,
  showSubSteps,
  isLastStep,
}: EIFOtherContractDetailsReviewProps) => {
  const showConfirmPolicyAnniversary =
    getIsSubStepApplicable({ eifSubStepId: "confirm-policy-anniversary", client }) &&
    showSubSteps["confirm-policy-anniversary"];

  const showAgeRules =
    getIsSubStepApplicable({ eifSubStepId: "age-rules", client }) && showSubSteps["age-rules"];

  const isAdditionalWaitingPeriodRulesVisible =
    getIsSubStepApplicable({
      eifSubStepId: "additional-waiting-period-rules",
      client,
    }) && showSubSteps["additional-waiting-period-rules"];
  const isRehireProvisionVisible =
    getIsSubStepApplicable({
      eifSubStepId: "rehire-provision",
      client,
    }) && showSubSteps["rehire-provision"];
  const isUnionAndDomesticPartnersVisible =
    getIsSubStepApplicable({
      eifSubStepId: "union-members-and-domestic-partners",
      client,
    }) && showSubSteps["union-members-and-domestic-partners"];
  const isChangesDuringAEVisible =
    getIsSubStepApplicable({
      eifSubStepId: "changes-during-annual-enrollment",
      client,
    }) && showSubSteps["changes-during-annual-enrollment"];
  const isOtherChangesToCoverageVisible =
    getIsSubStepApplicable({
      eifSubStepId: "other-changes-to-coverage",
      client,
    }) && showSubSteps["other-changes-to-coverage"];
  const isEmployeeCertificate =
    getIsSubStepApplicable({
      eifSubStepId: "employee-certificate",
      client,
    }) && showSubSteps["employee-certificate"];
  const isSection125Visible =
    getIsSubStepApplicable({ eifSubStepId: "section-125", client }) && showSubSteps["section-125"];
  const isErisaVisible =
    getIsSubStepApplicable({ eifSubStepId: "erisa", client }) && showSubSteps["erisa"];
  const showValueAddedServices =
    getIsSubStepApplicable({
      eifSubStepId: "value-added-services",
      client,
    }) && showSubSteps["value-added-services"];
  const isTerminationOfInsuranceVisible =
    getIsSubStepApplicable({
      eifSubStepId: "termination-of-insurance",
      client,
      plans: clientPlans,
    }) && showSubSteps["termination-of-insurance"];

  const isLastSubStep = getIsLastSubStepMap(showSubSteps);

  const atLeastOneSubStepIsApplicable = getAtLeastOneSubStepIsApplicable(
    "other-contract-details",
    client,
    clientPlans,
    featureToggles,
  );
  if (!atLeastOneSubStepIsApplicable) return null;

  return (
    <>
      {signerMode === "inside" && <EIFStepHeader viewMode={viewMode} eifStepId={eifStepId} />}

      <StackY dist={32}>
        <EIFSubStepSummaryWrapper signerMode={signerMode}>
          <StackY dist={32}>
            {showConfirmPolicyAnniversary && (
              <AvoidPageBreak>
                <ConfirmPolicyAnniversarySummary
                  client={client}
                  changeSnapshot={changeSnapshot}
                  viewMode={viewMode}
                  featureToggles={featureToggles}
                  authUser={authUser}
                />
                {signerMode === "outside" &&
                  shouldShowDivider("confirm-policy-anniversary", isLastSubStep, isLastStep) && (
                    <PrintHidden>
                      <HorizontalDivider />
                    </PrintHidden>
                  )}
              </AvoidPageBreak>
            )}

            {isAdditionalWaitingPeriodRulesVisible && (
              <>
                {signerMode === "inside" && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}

                <AvoidPageBreak>
                  <AdditionalWaitingPeriodRulesSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>
                {shouldShowDivider(
                  "additional-waiting-period-rules",
                  isLastSubStep,
                  isLastStep,
                ) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isRehireProvisionVisible && (
              <>
                <AvoidPageBreak>
                  <RehireProvisionSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("rehire-provision", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isUnionAndDomesticPartnersVisible && (
              <>
                <AvoidPageBreak>
                  <UnionMembersAndDomesticPartnersSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider(
                  "union-members-and-domestic-partners",
                  isLastSubStep,
                  isLastStep,
                ) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isChangesDuringAEVisible && (
              <>
                <AvoidPageBreak>
                  <ChangesDuringAnnualEnrollmentSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider(
                  "changes-during-annual-enrollment",
                  isLastSubStep,
                  isLastStep,
                ) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isTerminationOfInsuranceVisible && (
              <>
                <AvoidPageBreak>
                  <TerminationOfInsuranceSummary
                    client={client}
                    clientPlans={clientPlans}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("termination-of-insurance", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {showAgeRules && (
              <>
                <AvoidPageBreak>
                  <AgeRulesSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("age-rules", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isOtherChangesToCoverageVisible && (
              <>
                <AvoidPageBreak>
                  <OtherChangesToCoverageSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("other-changes-to-coverage", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isEmployeeCertificate && (
              <>
                <AvoidPageBreak>
                  <EmployeeCertificateSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("employee-certificate", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isSection125Visible && (
              <>
                <AvoidPageBreak>
                  <Section125Summary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                </AvoidPageBreak>

                {shouldShowDivider("section-125", isLastSubStep, isLastStep) && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}
              </>
            )}

            {isErisaVisible && (
              <>
                <AvoidPageBreak>
                  <ERISASummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />

                  {signerMode === "outside" &&
                    shouldShowDivider("erisa", isLastSubStep, isLastStep) && (
                      <PrintHidden>
                        <HorizontalDivider />
                      </PrintHidden>
                    )}
                </AvoidPageBreak>
              </>
            )}

            {showValueAddedServices && (
              <>
                {signerMode === "inside" && (
                  <PrintHidden>
                    <HorizontalDivider />
                  </PrintHidden>
                )}

                <AvoidPageBreak>
                  <ValueAddedServicesSummary
                    client={client}
                    changeSnapshot={changeSnapshot}
                    viewMode={viewMode}
                    featureToggles={featureToggles}
                    authUser={authUser}
                  />
                  {signerMode === "outside" &&
                    shouldShowDivider("value-added-services", isLastSubStep, isLastStep) && (
                      <PrintHidden>
                        <HorizontalDivider />
                      </PrintHidden>
                    )}
                </AvoidPageBreak>
              </>
            )}
          </StackY>
        </EIFSubStepSummaryWrapper>

        {viewMode !== "review-page" && <EIFStepBottomNavLinks eifStepId={eifStepId} />}
      </StackY>
    </>
  );
};
