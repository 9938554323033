import { genericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { ReactComponent as LeftRightArrows } from "client/src/components/Icons/LeftRightArrows.svg";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSyncOnboardingForm } from "client/src/hooks/onboardingForms";
import clsx from "clsx";
import React from "react";

import { Button } from "../../../components/Button/Button";
import { Modal } from "../../../components/Modal/Modal";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { Body3 } from "../../../components/Typography/Typography";
import * as styles from "./modal.module.less";
import type { SyncOnboardingFormFunc } from "client/src/hooks/onboardingForms";
import type { ClientId } from "shared/types/Client";
import type { OnboardingFormId } from "shared/types/OnboardingForm";

type SyncOnboardingFormUIProps = {
  syncOnboardingForm: SyncOnboardingFormFunc;
  onShowModalButtonClick: () => void;
  isLoading: boolean;
  onSuccess: () => unknown;
};

export const SyncOnboardingFormUIModal = ({
  syncOnboardingForm,
  onShowModalButtonClick,
  isLoading,
  onSuccess,
}: SyncOnboardingFormUIProps) => {
  const [isModalVisible, setModalVisible] = React.useState(false);

  const openModalHandler = () => {
    setModalVisible(true);
    onShowModalButtonClick();
  };
  const closeModalHandler = () => setModalVisible(false);
  const syncOnboardingFormHandler = async () => {
    try {
      await syncOnboardingForm({});
      onSuccess();
      void slobMessage.success("Successfully synced onboarding form");
      closeModalHandler();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    }
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button type="text" onClick={openModalHandler}>
          <div className={clsx(["stack-x-12", styles.popoverLinkRow])}>
            <LeftRightArrows /> <Body3>Sync with DocuSign</Body3>
          </div>
        </Button>
      </div>
      <Modal
        maskClosable={false}
        title="Sync Envelope"
        open={isModalVisible}
        footer={null}
        focusTriggerAfterClose={false}
        disableClose={isLoading}
        onCancel={closeModalHandler}
      >
        <StackY dist={24}>
          <Body3 as="p">
            Sync current envelope with DocuSign in case the data in Onboard and DocuSign mismatch
          </Body3>
          <Row justify="end" align="middle" className="mt-32">
            <Col xs={8}>
              <StackX dist={24}>
                <Button onClick={closeModalHandler} type="text-only" disabled={isLoading}>
                  Cancel
                </Button>

                <Button
                  type="primary"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={syncOnboardingFormHandler}
                >
                  Sync Envelope
                </Button>
              </StackX>
            </Col>
          </Row>
        </StackY>
      </Modal>
    </>
  );
};

type SyncOnboardingFormModalProps = {
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  onShowModalButtonClick: () => void;
  onSuccess: () => unknown;
};

export const SyncOnboardingFormModal = ({
  clientId,
  onboardingFormId,
  onShowModalButtonClick,
  onSuccess,
}: SyncOnboardingFormModalProps) => {
  const { mutateAsync: syncOnboardingForm, isPending } = useSyncOnboardingForm(
    clientId,
    onboardingFormId,
  );

  return (
    <SyncOnboardingFormUIModal
      onSuccess={onSuccess}
      syncOnboardingForm={syncOnboardingForm}
      isLoading={isPending}
      onShowModalButtonClick={onShowModalButtonClick}
    />
  );
};
