import type { ValueOf } from "shared/types/Helper";

export const yesNo = ["Yes", "No"];
export type YesNo = ValueOf<typeof yesNo>;
export type YesNoNA = ValueOf<typeof yesNo> | "Not Applicable";
export type Segment = ValueOf<typeof segment>;
export const segment = ["Maxwell", "MGIS", "N/A"];

export const maxwell: (typeof segment)[number] = "Maxwell";

export const ticketStatus = [
  "Prepare Sold Case Paperwork",
  "NIGO - with Intake",
  "New Case Assigned",
  "On Hold at Welcome",
  "Quality Assurance",
  "Information Gathering",
  "Paperwork Review",
  "NIGO",
  "QPS Setup and Running of Rates",
  "QPS Setup - Frontloading",
  "Ready for Risk Approval",
  "Ready for QA",
  "Correction(s) Needed",
  "Member Group Activity Backlog",
  "Member Group Activity in Progress",
  "On Query",
  "Export and Code Compass",
  "Policy Install in Progress",
  "Ready for Release",
  "Clarifications Needed",
  "Start QA",
  "Ready for Audit Complete",
  "Correction(s) Needed for Audit Complete",
  "Back to QA",
  "Live",
  "Frontloaded - Ready for Final RA",
  "Frontloaded - Ready for Final Audit Complete",
  "Correction(s) Needed for Audit Complete (Frontloading)",
  "Frontloaded - Pending Census",
  "Frontloaded - Clarifications Needed",
  "Re-Export and Re-Code Compass",
  "Risk Rejected",
  "Compass Simple Cell Coding",
  "Frontloaded - Compass Simple Cell Coding",
] as const;

export type TicketStatus = ValueOf<typeof ticketStatus>;

// TB-5292
export const invalidFrontloadingTicketStatus: TicketStatus[] = [
  "Compass Simple Cell Coding",
  "Ready for Risk Approval",
  "Start QA",
  "Back to QA",
  "QPS Setup and Running of Rates",
];

export const notStartedTicketStatuses: TicketStatus[] = [
  "Prepare Sold Case Paperwork",
  "NIGO - with Intake",
  "New Case Assigned",
  "On Hold at Welcome",
];
