import { copyKeySwitch } from "client/src/domain/ExplorerPages/utils";
import { useTranslation } from "client/src/i18n";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

/**
 * Get the base key for the plan table.
 */
export function usePlanTableBaseKey(plans: ExplorerPageBenefit[], benefitType: string) {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable" });

  const offering = plans.reduce<PlanType | "MULTI_CHOICE" | null>((acc, { planType }) => {
    const effectivePlanType = planType === "ASO_PPO" || planType === "INDEMNITY" ? "PPO" : planType;
    if (acc && acc !== effectivePlanType) {
      return "MULTI_CHOICE";
    }
    return effectivePlanType;
  }, null);
  const offeringKey = copyKeySwitch(t, benefitType, offering);

  const baseKey = [benefitType, offeringKey];
  return baseKey;
}
