import { Skeleton } from "antd";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { useTrackElementClicked } from "client/src/utils/analytics";
import { Navigate, Outlet } from "react-router-dom";
import { RouteData } from "../../../../../shared/config/routeData";
import { HubCard } from "../../../components/HubCard/HubCard";
import { NavigationHeader } from "../../../components/NavigationHeader/NavigationHeader";
import { StackY } from "../../../components/Spacing/Spacing";
import { useSlobAuth } from "../../../hooks/auth";
import { useGetClientByID } from "../../../hooks/client";
import { useGetDocuments, useUpdateDocumentMeta } from "../../../hooks/document";
import { triggerError } from "../../../hooks/generalError";
import { useClientHubParams } from "../../../hooks/useClientHubParams";

import * as styles from "./censusDetail.module.less";
import type { UpdateDocumentMetaQuery } from "../../../hooks/document";
import type { ElementClickedOptions } from "client/src/utils/analytics";
import type { Client, PolicyId } from "shared/types/Client";

export type CensusOutletContext = {
  client: Client;
  policyId: PolicyId;
  updateDocumentMeta: UpdateDocumentMetaQuery;
  trackElementClicked: (options: ElementClickedOptions) => void;
  isUserMFA: boolean;
};

export const CensusDetailPage = () => {
  const { clientId, policyId } = useClientHubParams(["clientId", "policyId"]);

  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
    isSuccess: isSuccessClient,
  } = useGetClientByID(clientId);
  const {
    error: errorDocuments,
    isLoading: isLoadingDocuments,
    isError: isErrorDocument,
    isSuccess: isSuccessDocument,
  } = useGetDocuments({ clientId, categories: ["enrollment-elections"], policyId });

  const { isLoading: isLoadingFeatureToggles, isError: isErrorFeatureToggles } =
    useGetFeatureToggles(clientId);

  const updateDocumentMeta = useUpdateDocumentMeta();
  const { authUser } = useSlobAuth();

  const isLoading = isLoadingClient || isLoadingDocuments || isLoadingFeatureToggles;
  const isSuccess = isSuccessClient && isSuccessDocument;
  if (isErrorClient) {
    triggerError(errorClient);
  }
  if (isErrorDocument) {
    triggerError(errorDocuments);
  }
  if (isErrorFeatureToggles) {
    triggerError(isErrorFeatureToggles);
  }

  if (!isLoadingClient && !client?.policies.find((policy) => policy.id === policyId)) {
    return triggerError("Policy ID is required for this task");
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  return (
    <CensusDetailPageComponent
      policyId={policyId}
      isLoading={isLoading}
      isSuccess={isSuccess}
      client={client}
      updateDocumentMeta={updateDocumentMeta}
      isUserMFA={authUser?.hasMfa ?? false}
    />
  );
};

type CensusDetailPageComponentProps = {
  policyId: PolicyId;
  updateDocumentMeta: UpdateDocumentMetaQuery;
  isUserMFA: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  client?: Client;
};
export const CensusDetailPageComponent = ({
  policyId,
  isLoading,
  isSuccess,
  client,
  updateDocumentMeta,
  isUserMFA,
}: CensusDetailPageComponentProps) => {
  const trackElementClicked = useTrackElementClicked(
    client,
    "Submit Enrollment Elections",
    policyId,
  );

  return (
    <>
      <div className={styles.header}>
        <NavigationHeader
          backTo={RouteData.policyTaskDetail.getPath(
            client?.id ?? "",
            "enrollment-elections",
            policyId,
          )}
          title="Upload your Enrollment Elections"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.centeredPanel}>
          {isLoading && <CensusPageLoading />}
          {isSuccess && client && (
            <Outlet
              context={{
                client,
                updateDocumentMeta,
                isUserMFA,
                policyId,
                trackElementClicked,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const CensusPageLoading = () => {
  return (
    <div className={styles.content} data-testid="loading-census">
      <div className={styles.centeredPanel}>
        <StackY dist={32}>
          <Skeleton title={{ width: "60%" }} paragraph={false} />
          <HubCard>
            <StackY dist={32}>
              <Skeleton paragraph={{ rows: 5 }} />
              <Skeleton title={false} paragraph={{ rows: 9 }} />
            </StackY>
          </HubCard>
        </StackY>
      </div>
    </div>
  );
};
