import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import React, { useState } from "react";
import { updateSignerTypeValidator } from "shared/validation/onboardingForm";

import { Button } from "../../../components/Button/Button";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import { ReactComponent as LeftRightArrows } from "../../../components/Icons/LeftRightArrows.svg";

import { Modal } from "../../../components/Modal/Modal";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useReassignRecipients } from "../../../hooks/onboardingForms";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { UpdateSignerTypeForm } from "../Form/UpdateSignerTypeForm";

import * as styles from "./modal.module.less";

import type { ClientId } from "shared/types/Client";
import type { OnboardingFormId } from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

type PresentationProps = {
  onSuccess: () => unknown;
  onClick: () => void;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  reassignRecipient: (options: {
    data: { email: string; name: string; outsideSlobSigner: boolean; previousRecipientId: string };
  }) => Promise<unknown>;
  track: (buttonLabel: string) => void;
};

export const UpdateSignerTypeModalPresentation: React.FC<PresentationProps> = ({
  onSuccess,
  onClick,
  onboardingFormRecipients,
  reassignRecipient,
  track,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const updateSignerTypeForm = useSlobFormik({
    initialValues: {
      recipientId: "",
      updateSigner: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: updateSignerTypeValidator,
    async onSubmit({ recipientId, updateSigner }) {
      if (!updateSigner) {
        return;
      }

      const recipientToUpdate = onboardingFormRecipients.find(({ id }) => id === recipientId);

      if (!recipientToUpdate) {
        return;
      }

      const outsideSlobSigner = recipientToUpdate.docusignClientUserId !== null;

      await reassignRecipient({
        data: {
          email: recipientToUpdate.email,
          name: recipientToUpdate.name,
          outsideSlobSigner,
          previousRecipientId: recipientId,
        },
      });

      void slobMessage.success("Successfully updated the signer type");
      onSuccess();
      setModalVisible(false);
    },
  });

  const updateSignerTypeClickAction = () => {
    setModalVisible(true);
    onClick();
    track("Update Signer Type");
  };

  const onCancel = () => {
    setModalVisible(false);
    updateSignerTypeForm.resetForm();
    track("Cancel Update Signer TYpe");
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button type="text" onClick={updateSignerTypeClickAction}>
          <div className={clsx(["stack-x-12", styles.popoverLinkRow])}>
            <LeftRightArrows /> <span className="body3">Update Signer Type</span>
          </div>
        </Button>
      </div>
      <Modal
        maskClosable={false}
        title="Update Signer Type"
        open={isModalVisible}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={onCancel}
      >
        <form onSubmit={updateSignerTypeForm.handleSubmit}>
          <StackY dist={16} className="w-full">
            <UpdateSignerTypeForm
              formik={updateSignerTypeForm}
              onboardingFormRecipients={onboardingFormRecipients}
            />
            <StackX dist={8}>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateSignerTypeForm.isSubmitting}
                disabled={updateSignerTypeForm.isSubmitting}
                onClick={() => track("Update Signer Type Action")}
              >
                Save
              </Button>
              <Button onClick={onCancel} type="text" disabled={updateSignerTypeForm.isSubmitting}>
                <span className="body4">Cancel</span>
              </Button>
            </StackX>
            {updateSignerTypeForm.status && (
              <ErrorMessage>{updateSignerTypeForm.status}</ErrorMessage>
            )}
          </StackY>
        </form>
      </Modal>
    </>
  );
};

type Props = {
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  onSuccess: () => unknown;
  onClick: () => void;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  track: (buttonLabel: string) => void;
};

export const UpdateSignerTypeModal: React.FC<Props> = ({
  clientId,
  onboardingFormId,
  onSuccess,
  onClick,
  onboardingFormRecipients,
  track,
}) => {
  const { mutateAsync: reassignRecipient } = useReassignRecipients(clientId, onboardingFormId);

  return (
    <UpdateSignerTypeModalPresentation
      reassignRecipient={reassignRecipient}
      onSuccess={onSuccess}
      onboardingFormRecipients={onboardingFormRecipients}
      track={track}
      onClick={onClick}
    />
  );
};
