import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobSelectMultiple } from "client/src/components/MultiSelect/SlobSelectMultiple";
import { StackY, StackX } from "client/src/components/Spacing/Spacing";
import { Body3, Body5, Body2 } from "client/src/components/Typography/Typography";
import { ContactCard } from "client/src/domain/Contact/ContactCard";
import { ContactDropdown } from "client/src/domain/Contact/ContactDropdown";
import { UpdateSunLifeUserAccessModal } from "client/src/domain/Contact/UpdateSunLifeUserAccessModal";
import { getCoverageWithPolicyNumbersLabel } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getCoverageWithPolicyNumbersLabel";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import {
  useCreateMonthlyClaimsReportMailingLocation,
  useUpdateMonthlyClaimsReportMailingLocation,
} from "client/src/hooks/monthlyClaimsReportMailingLocation";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { useState } from "react";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getContactHasExpectedAccess } from "shared/utils/contact";
import { listFormat } from "shared/utils/format";
import { assertIsDefinedInObject, hasNonNullValues } from "shared/utils/utils";
import { monthlyClaimsReportMailingLocationSchema } from "shared/validation/monthlyClaimsReportMailingLocation";
import type { SendMonthlyClaimsReportsTo } from "@prisma/client";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type {
  MonthlyClaimsReportMailingLocation,
  MonthlyClaimsReportMailingLocationInput,
} from "shared/types/MonthlyClaimsReportMailingLocation";
import type { PFMLStates, Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  relevantPlans: Plan[];
  monthlyClaimsReportMailingLocation: MonthlyClaimsReportMailingLocation | null;
  claimReportsAndEOBsNotManagedBySLF: PFMLStates[];
  adminContacts: Contact[];
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  onSave: () => void;
  onCancel?: () => void;
  prefillErrors: FormikErrors<
    Pick<MonthlyClaimsReportMailingLocationInput, "sendMonthlyClaimsReportsToContactId">
  >;
};

export function EIFMonthlyClaimsReportsAndEOBsForm(props: Props) {
  const {
    client,
    relevantPlans,
    monthlyClaimsReportMailingLocation,
    claimReportsAndEOBsNotManagedBySLF,
    adminContacts,
    authUser,
    featureToggles,
    updateClient,
    changeSnapshot,
    onSave,
    onCancel,
    prefillErrors: e,
  } = props;

  const createQuery = useCreateMonthlyClaimsReportMailingLocation();
  const updateQuery = useUpdateMonthlyClaimsReportMailingLocation();

  const claimReportsAndEOBsNotManagedMessage = claimReportsAndEOBsNotManagedBySLF.length
    ? `PFML claim reports and EOBs for ${listFormat(
        claimReportsAndEOBsNotManagedBySLF,
      )} are not sent by Sun Life as these plans are advice only.`
    : "";

  const formik = useSlobFormik({
    validationSchema: monthlyClaimsReportMailingLocationSchema,
    validationContext: { prefill: true },
    initialValues: {
      id: monthlyClaimsReportMailingLocation?.id,
      clientId: client.id,
      sendMonthlyClaimsReportsTo:
        monthlyClaimsReportMailingLocation?.sendMonthlyClaimsReportsTo ?? null,
      sendMonthlyClaimsReportsToContactId:
        monthlyClaimsReportMailingLocation?.sendMonthlyClaimsReportsToContactId ?? null,
      slfCoverages: monthlyClaimsReportMailingLocation
        ? monthlyClaimsReportMailingLocation.slfCoverages
        : relevantPlans.length === 1
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
          [benefitTypeToCoverage[relevantPlans[0]!.benefitType]]
        : null,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (client.monthlyClaimsReportsAndEOBsDefaults !== "EDITED_DEFAULTS") {
        await updateClient({
          params: { clientId: client.id },
          data: {
            monthlyClaimsReportsAndEOBsDefaults: "EDITED_DEFAULTS",
          },
        });
      }

      if (!values.id) {
        await createQuery.mutateAsync({
          params: { clientId: client.id },
          data: {
            clientId: client.id,
            sendMonthlyClaimsReportsTo: values.sendMonthlyClaimsReportsTo,
            sendMonthlyClaimsReportsToContactId: values.sendMonthlyClaimsReportsToContactId,
            slfCoverages: values.slfCoverages,
          },
        });
      } else {
        await updateQuery.mutateAsync({
          params: { id: values.id, clientId: client.id },
          data: {
            id: values.id,
            clientId: client.id,
            sendMonthlyClaimsReportsTo: values.sendMonthlyClaimsReportsTo,
            sendMonthlyClaimsReportsToContactId: values.sendMonthlyClaimsReportsToContactId,
            slfCoverages: values.slfCoverages,
          },
        });
      }

      onSave();
    },
  });

  const sendMonthlyClaimsReportToContact = formik.values.sendMonthlyClaimsReportsToContactId
    ? adminContacts.find(
        (contact) => contact.id === formik.values.sendMonthlyClaimsReportsToContactId,
      )
    : null;

  assertIsDefinedInObject(client, "allPoliciesSlfCoverages");

  const options = relevantPlans
    .map((plan) => benefitTypeToCoverage[plan.benefitType])
    .filter((slfCoverage) => client.allPoliciesSlfCoverages.includes(slfCoverage))
    .map((slfCoverage) => ({
      label: getCoverageWithPolicyNumbersLabel({
        client,
        slfCoverage,
      }),
      value: slfCoverage,
    }));

  const option = options.filter((option) => formik.values.slfCoverages?.includes(option.value));

  const haveEverSavedForm = monthlyClaimsReportMailingLocation
    ? hasNonNullValues({
        sendMonthlyClaimsReportsTo: monthlyClaimsReportMailingLocation.sendMonthlyClaimsReportsTo,
        sendMonthlyClaimsReportsToContactId:
          monthlyClaimsReportMailingLocation.sendMonthlyClaimsReportsToContactId,
        slfCoverages: monthlyClaimsReportMailingLocation.slfCoverages,
      })
    : false;

  const prefillErrors = haveEverSavedForm ? e : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24} wrap={false}>
          <SendMonthlyClaimsReportsToInput
            client={client}
            contactsToChooseFrom={adminContacts}
            authUser={authUser}
            sendMonthlyClaimsReportToFieldName="sendMonthlyClaimsReportsTo"
            monthlyClaimsRecipientFieldName="sendMonthlyClaimsReportsToContactId"
            monthlyClaimsRecipientContact={sendMonthlyClaimsReportToContact}
            formik={formik}
            changeSnapshot={changeSnapshot}
            prefillErrors={prefillErrors}
            featureToggles={featureToggles}
          />

          {options.length > 1 && (
            <StackY dist={8} wrap={false}>
              <div data-testid="benefitsPickerContainer">
                <label htmlFor="slfCoverages" className="mb-16" style={{ display: "block" }}>
                  <Body2>This claims reports and EOB mailing location applies to:</Body2>
                </label>

                <SlobSelectMultiple
                  id="slfCoverages"
                  name="slfCoverages"
                  placeholder="Select benefits"
                  disabled={formik.isSubmitting}
                  touched={formik.touched.slfCoverages}
                  error={
                    Array.isArray(formik.errors.slfCoverages)
                      ? formik.errors.slfCoverages[0]
                      : formik.errors.slfCoverages
                  }
                  onChange={async (labeledValue) => {
                    const nextSlfCoverages = labeledValue.map((e) => e.value);
                    await formik.setFieldValue("slfCoverages", nextSlfCoverages);
                  }}
                  value={option}
                  options={options}
                  tagLabelRender={(props) => props.value}
                />
              </div>

              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.MonthlyClaimsReportMailingLocation[formik.values.id ?? ""]
                    ?.slfCoverages,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "slfCoverages",
                  client,
                  formik,
                })}
              />
            </StackY>
          )}

          {claimReportsAndEOBsNotManagedMessage && (
            <div>
              <Body3 colorSecondary>
                <Body2 colorSecondary>Note:</Body2> {claimReportsAndEOBsNotManagedMessage}
              </Body3>
            </div>
          )}

          <Row justify="end" align="middle" gutter={20}>
            {onCancel && (
              <Col>
                <Button
                  type="text-only"
                  size="small"
                  htmlType="button"
                  onClick={onCancel}
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
              </Col>
            )}

            <Col>
              <Button
                type="secondary"
                size="small"
                htmlType="submit"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Col>
          </Row>

          <div aria-live="assertive" className="hide:empty mt-20">
            {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
          </div>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}

type SendMonthlyClaimsReportsToProps = {
  client: Client;
  contactsToChooseFrom: Contact[];
  authUser: UserData;
  sendMonthlyClaimsReportToFieldName: "sendMonthlyClaimsReportsTo";
  monthlyClaimsRecipientFieldName: "sendMonthlyClaimsReportsToContactId";
  monthlyClaimsRecipientContact: Contact | null | undefined;
  formik: ReturnType<typeof useSlobFormik<typeof monthlyClaimsReportMailingLocationSchema>>;
  changeSnapshot: DEIFChangeSnapshot;
  prefillErrors: FormikErrors<
    Pick<MonthlyClaimsReportMailingLocationInput, "sendMonthlyClaimsReportsToContactId">
  >;
  featureToggles: ClientFeatureToggles;
};

// @todo refactor this component
function SendMonthlyClaimsReportsToInput(props: SendMonthlyClaimsReportsToProps) {
  const {
    client,
    contactsToChooseFrom,
    authUser,
    sendMonthlyClaimsReportToFieldName,
    monthlyClaimsRecipientFieldName,
    monthlyClaimsRecipientContact,
    formik,
    changeSnapshot,
    prefillErrors: e,
    featureToggles,
  } = props;

  const [contactCardVisible, toggleContactCardVisible] = useToggler(
    monthlyClaimsRecipientContact != null,
  );

  const [contactAccessModalState, setContactAccessModalState] = useState<
    { state: "closed" } | { state: "open"; previousContactId: string | null; nextContactId: string }
  >({ state: "closed" });

  return (
    <>
      <StackY dist={16} wrap={false}>
        <RadioGroup<SendMonthlyClaimsReportsTo>
          name={sendMonthlyClaimsReportToFieldName}
          touched={formik.touched[sendMonthlyClaimsReportToFieldName]}
          error={formik.errors[sendMonthlyClaimsReportToFieldName]}
          value={formik.values[sendMonthlyClaimsReportToFieldName]}
          disabled={formik.isSubmitting}
          onChange={formik.handleChange}
          direction="vertical"
          label="Where should Sun Life send monthly claims reports and EOB statements?"
          options={[
            {
              value: "PRIMARY_PLAN_ADMINISTRATOR",
              label: (
                <StackX dist={12} className="pt-4">
                  <Body3>To the Primary Plan Administrator</Body3>
                  <Body5>Most Common</Body5>
                </StackX>
              ),
              contentSpacing: "tight",
            },
            {
              value: "OTHER",
              label: "Other",
              contentSpacing: "tight",
            },
          ]}
        />

        <EditedFieldMsg
          changeDetailInfoList={[
            changeSnapshot.MonthlyClaimsReportMailingLocation[formik.values.id ?? ""]
              ?.sendMonthlyClaimsReportsTo,
          ]}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({
            field: "sendMonthlyClaimsReportsTo",
            client,
            formik,
          })}
        />

        {formik.values[sendMonthlyClaimsReportToFieldName] === "OTHER" && (
          <div className="ml-32 stack-y-8" data-testid="otherRecipientPickerContainer">
            {monthlyClaimsRecipientContact && contactCardVisible ? (
              <ContactCard
                client={client}
                bills={[]} // Bill access not relevant in this step
                authUser={authUser}
                contact={monthlyClaimsRecipientContact}
                changeSnapshot={changeSnapshot}
                allowEdit
                onEdit={toggleContactCardVisible}
                allowRemove
                onRemove={async () => {
                  await formik.setFieldValue(monthlyClaimsRecipientFieldName, null);
                  toggleContactCardVisible();
                }}
                disabled={formik.isSubmitting}
                featureToggles={featureToggles}
              />
            ) : (
              <ContactDropdown
                client={client}
                id={monthlyClaimsRecipientFieldName}
                name={monthlyClaimsRecipientFieldName}
                label="Select a Sun Life Connect user as the recipient"
                placeholder="Select a user*"
                disabled={formik.isSubmitting}
                loading={formik.isSubmitting}
                value={monthlyClaimsRecipientContact?.id ?? ""}
                touched={
                  formik.touched[monthlyClaimsRecipientFieldName] ||
                  !!e.sendMonthlyClaimsReportsToContactId
                }
                error={
                  formik.errors[monthlyClaimsRecipientFieldName] ||
                  e.sendMonthlyClaimsReportsToContactId
                }
                onChange={async (event) => {
                  const selectedContact = contactsToChooseFrom.find((c) => c.id === event.value);

                  if (selectedContact) {
                    const policiesForTheSelectedBenefitTypes = client.policies.filter((policy) =>
                      policy.slfCoverages?.some((slfCoverage) =>
                        formik.values.slfCoverages?.includes(slfCoverage),
                      ),
                    );
                    const policyIds = policiesForTheSelectedBenefitTypes.map((p) => p.id);
                    const hasClaimsAccess = getContactHasExpectedAccess(
                      selectedContact,
                      policyIds,
                      "claimsAccess",
                    );

                    if (hasClaimsAccess) {
                      await formik.setFieldValue(monthlyClaimsRecipientFieldName, event.value);
                      toggleContactCardVisible();
                    } else {
                      setContactAccessModalState({
                        state: "open",
                        previousContactId: monthlyClaimsRecipientContact?.id ?? null,
                        nextContactId: String(event.value),
                      });
                    }
                  }
                }}
                contacts={contactsToChooseFrom.filter((contact) => contact.type === "WEB_ADMIN")}
              />
            )}

            <EditedFieldMsg
              changeDetailInfoList={[
                changeSnapshot.MonthlyClaimsReportMailingLocation[formik.values.id ?? ""]
                  ?.sendMonthlyClaimsReportsToContactId,
              ]}
              client={client}
              authUser={authUser}
              hasPendingEdit={getHasPendingEdit({
                field: "sendMonthlyClaimsReportsToContactId",
                client,
                formik,
              })}
            />
          </div>
        )}
      </StackY>

      <UpdateSunLifeUserAccessModal
        isVisible={contactAccessModalState.state === "open"}
        variant="monthly-claims-reports"
        onConfirm={async () => {
          if (contactAccessModalState.state === "open") {
            await formik.setFieldValue(
              monthlyClaimsRecipientFieldName,
              contactAccessModalState.nextContactId,
            );
          }
          toggleContactCardVisible();
          setContactAccessModalState({ state: "closed" });
        }}
        onCancel={async () => {
          if (contactAccessModalState.state === "open") {
            await formik.setFieldValue(
              monthlyClaimsRecipientFieldName,
              contactAccessModalState.previousContactId,
            );
          }
          setContactAccessModalState({ state: "closed" });
        }}
      />
    </>
  );
}
