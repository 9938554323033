import clsx from "clsx";
import { Fragment } from "react";

import * as styles from "./detailsList.module.less";

import type { ReactNode } from "react";

export const DetailsList = ({
  items,
  className,
}: {
  items: { label: string; value: ReactNode }[];
  className?: string;
}) => {
  return (
    <dl className={clsx(styles.detailsList, className)}>
      {items.map(({ label, value }) => {
        return (
          <Fragment key={label}>
            <dt>{label}</dt>
            <dd>{value}</dd>
          </Fragment>
        );
      })}
    </dl>
  );
};
