import { StackY } from "client/src/components/Spacing/Spacing";
import { PhaseIdValues } from "shared/types/Policy";
import { phasesCopy } from "../../hooks/phase";
import { Checkbox } from "../Form/Checkbox";
import { Body2 } from "../Typography/Typography";
import type { SlobListFilterComponentProps } from "../SlobList/SlobList";
import type { ClientTableFilters } from "shared/types/Client";

export const DataTestId = {
  phaseFilterWrapper: "phase-filter-wrapper",
};

type PhaseFilterValues = Pick<ClientTableFilters, "phaseIds">;

export type PhaseFilterProps = Omit<
  SlobListFilterComponentProps<PhaseFilterValues>,
  "onFiltersReset"
> & {
  variant?: "internal" | "broker";
};

export const PhaseFilter = ({
  filters,
  onFiltersChanged,
  variant = "internal",
}: PhaseFilterProps) => {
  const selectedPhaseIds = filters?.phaseIds ? [...filters.phaseIds] : [...PhaseIdValues];

  const phaseIdOptions =
    variant === "internal" ? PhaseIdValues : PhaseIdValues.filter((phase) => phase !== "REJECTED");
  return (
    <StackY dist={16} data-testid={DataTestId.phaseFilterWrapper}>
      <Body2>Onboarding phase</Body2>
      {phaseIdOptions.map((phaseId) => {
        return (
          <Checkbox
            key={`PhaseFilter-${phaseId}`}
            checked={selectedPhaseIds.includes(phaseId)}
            onChange={(e) => {
              if (e.target.checked) {
                onFiltersChanged({
                  phaseIds: selectedPhaseIds.concat([phaseId]),
                });
              } else {
                selectedPhaseIds.splice(selectedPhaseIds.indexOf(phaseId), 1);
                onFiltersChanged({
                  phaseIds: selectedPhaseIds,
                });
              }
            }}
            label={phasesCopy[phaseId].title}
            name={phaseId}
          ></Checkbox>
        );
      })}
    </StackY>
  );
};
