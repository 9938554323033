import { useMemo } from "react";

import { getCoverageOutlineLinks } from "shared/data/CoverageOutline";
import type { Client } from "shared/types/Client";
import type { Document, DocumentCategory } from "shared/types/Document";
import type { SlfCoverageLink } from "shared/types/Ticket";
import type { WebLink, WebLinkCategory } from "shared/types/WebLink";

type AmountPerCategory = Partial<Record<DocumentCategory | WebLinkCategory, number>>;

export function useAmountOfItemsPerCategory(
  documents: Document[] | undefined,
  webLinks: WebLink[] | undefined,
  basicEnrollmentFlyers: SlfCoverageLink[] | undefined,
  beneficiaryDesignationWebLinks: SlfCoverageLink[] | undefined,
  client: Client | undefined,
) {
  const amountOfItemsPerCategory = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    const itemsWithCategory = ([] as { category?: DocumentCategory | WebLinkCategory }[])
      .concat(documents ?? [])
      .concat(webLinks ?? [])
      .concat(basicEnrollmentFlyers ?? [])
      .concat(beneficiaryDesignationWebLinks ?? [])
      .concat(client ? getCoverageOutlineLinks(client) : []);

    const amountOfItemsPerCategory = itemsWithCategory.reduce<AmountPerCategory>(
      (prev, current) => {
        if (current.category) {
          prev[current.category] = (prev[current.category] || 0) + 1;
        }
        return prev;
      },
      {},
    );

    return amountOfItemsPerCategory;
  }, [documents, webLinks, basicEnrollmentFlyers, beneficiaryDesignationWebLinks, client]);

  return amountOfItemsPerCategory;
}
