import { getIsPFMLBenefitType } from "shared/types/BenefitTypes";
import { getPlansWhereCanSetClaimsCheckLocations, type Plan } from "shared/types/Plan";
import {
  getBenAdminAndDataFeedsCompletionStatus,
  getBillingPreferencesCompletionStatus,
  getClassBuilderCompletionStatus,
  getPFMLPreferencesCompletionStatus,
  getStatutoryPreferencesCompletionStatus,
} from "shared/utils/EIF/getEIFStepCompleteStatus";
import {
  getIsSubStepApplicable,
  shouldShowValueAddedServicesLifeCoverage,
} from "shared/utils/EIF/getIsSubStepApplicable";
import { getRelevantClientAgeRuleFields } from "shared/utils/EIF/getRelevantClientAgeRuleFields";
import { getTerminationOfInsuranceRelevantPlans } from "shared/utils/EIF/getTerminationOfInsuranceRelevantPlans";
import { getPlansWhereCanSetMonthlyClaimsLocations } from "shared/utils/EIF/monthlyClaimsReportsAndEOBs";
import { getIsStatutorySectionType } from "shared/utils/EIF/nonClassBenefitsPreferences";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { assertIsDefinedInObject, hasNonNullValues, unique } from "shared/utils/utils";
import { contactWithAddressValidationSchema } from "shared/validation/contact";

import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import { monthlyClaimsReportMailingLocationSchema } from "shared/validation/monthlyClaimsReportMailingLocation";
import {
  additionalWaitingPeriodRulesFormKeyProps,
  employeeCertificateFormKeyProps,
  erisaFormKeyProps,
  haveEverSavedChangesDuringEnrollmentFormKeyProps,
  haveEverSavedSection125FormKeyProps,
  rehireProvisionsFormKeyProps,
  unionMembersAndDomesticPartnersFormKeyProps,
} from "shared/validation/otherContractDetailsFormKeys";
import { claimCheckMailingLocationContactProps } from "shared/validation/plan";
import { malpracticeReimbursementsFormKeyProps } from "shared/validation/planAdminsAndBillingFormKeys";
import { primaryWebAdminContactValidation } from "shared/validation/primaryWebAdminContact";
import { subsidiaryValidationSchema } from "shared/validation/subsidiary";
import { getPropertiesToUpdate } from "../../client/src/utils/getPropertiesToUpdate";
import {
  clientValidationSchema,
  malpracticeReimbursementsValidationSchema,
  erisaValidationSchema,
  confirmPolicyAnniversarySchema,
  additionalWaitingPeriodRulesValidationSchema,
  unionMembersAndDomesticPartnersValidationSchema,
  rehireProvisionValidationSchema,
  changesDuringAnnualEnrollmentValidationSchema,
  otherChangesToCoverageValidationSchema,
  employeeCertificatesValidationSchema,
  valueAddedServicesSchema,
  section125ValidationSchema,
  subsidiaryClientValidationSchema,
} from "./client";
import { getIsSubStepCompleted } from "./getIsSubStepCompleted";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFSubStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type IsEIFSubStepCompletedArgs = {
  client: Client;
} & (
  | {
      eifSubStepId: Exclude<
        EIFSubStepId,
        | "termination-of-insurance"
        | "billing-preferences"
        | "changes-during-annual-enrollment"
        | "erisa"
        | "claims-check-mailing-locations"
        | "monthly-claims-reports-and-eobs"
        | "class-builder"
        | "non-class-benefits-preferences"
        | "fli-preferences"
        | "benefits-administration-and-data-feed"
        | "plan-administrators"
        | "subsidiaries-and-affiliates"
      >;
    }
  | {
      eifSubStepId: "termination-of-insurance";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "claims-check-mailing-locations";
      featureToggles: ClientFeatureToggles;
      clientPlans: Plan[];
      contacts: Contact[];
    }
  | {
      eifSubStepId: "monthly-claims-reports-and-eobs";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "billing-preferences";
      bills: Bill[];
      billingSummaryStatementTemplates: Document[];
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: "changes-during-annual-enrollment";
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: "erisa";
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: "class-builder";
      employeeClasses: EmployeeClass[];
      employeeClassDocumentCount: number;
      featureToggles: ClientFeatureToggles;
      changeSnapshot: DEIFChangeSnapshot;
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "non-class-benefits-preferences";
      clientPlans: Plan[];
    }
  | {
      eifSubStepId: "fli-preferences";
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: "benefits-administration-and-data-feed";
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: "plan-administrators";
      bills: Bill[];
    }
  | {
      eifSubStepId: "subsidiaries-and-affiliates";
      subsidiaries: Subsidiary[];
      clientPlans: Plan[];
    }
);

export function getEIFSubStepStatus(args: IsEIFSubStepCompletedArgs) {
  const { eifSubStepId, client } = args;

  switch (eifSubStepId) {
    // Other Contract Details
    case "confirm-policy-anniversary": {
      const isCompleted = getIsSubStepCompleted(client, confirmPolicyAnniversarySchema);

      return isCompleted ? "Completed" : "Not Started";
    }
    case "additional-waiting-period-rules": {
      const isCompleted = getIsSubStepCompleted(
        client,
        additionalWaitingPeriodRulesValidationSchema,
      );
      const haveEverSavedAdditionalWaitingPeriodsForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(additionalWaitingPeriodRulesFormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedAdditionalWaitingPeriodsForm
        ? "In Progress"
        : "Not Started";
    }
    case "rehire-provision": {
      const isCompleted = getIsSubStepCompleted(client, rehireProvisionValidationSchema);
      const haveEverSavedRehireProvisionsForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(rehireProvisionsFormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedRehireProvisionsForm
        ? "In Progress"
        : "Not Started";
    }
    case "union-members-and-domestic-partners": {
      const isCompleted = getIsSubStepCompleted(
        client,
        unionMembersAndDomesticPartnersValidationSchema,
      );
      const haveEverSavedUnionMembersAndDomesticPartnersForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(unionMembersAndDomesticPartnersFormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedUnionMembersAndDomesticPartnersForm
        ? "In Progress"
        : "Not Started";
    }
    case "changes-during-annual-enrollment": {
      const isCompleted = getIsSubStepCompleted(
        client,
        changesDuringAnnualEnrollmentValidationSchema,
      );
      const haveEverSavedChangesDuringEnrollmentForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(haveEverSavedChangesDuringEnrollmentFormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedChangesDuringEnrollmentForm
        ? "In Progress"
        : "Not Started";
    }
    case "termination-of-insurance": {
      const relevantPlans = getTerminationOfInsuranceRelevantPlans(args.clientPlans);

      const hasAtLeastOnePlanCompleted = relevantPlans.some((plan) => plan.terminationDate != null);
      if (hasAtLeastOnePlanCompleted) {
        const allPlansCompleted = relevantPlans.every((plan) => plan.terminationDate != null);
        return allPlansCompleted ? "Completed" : "In Progress";
      }
      return "Not Started";
    }
    case "age-rules": {
      const relevantClientFields = getRelevantClientAgeRuleFields(client);
      const relevantValidationSchema = clientValidationSchema.pick(relevantClientFields);
      const isCompleted = getIsSubStepCompleted(client, relevantValidationSchema);
      const haveEverSavedForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(relevantClientFields)(client),
      );

      return isCompleted ? "Completed" : haveEverSavedForm ? "In Progress" : "Not Started";
    }
    case "other-changes-to-coverage": {
      const isCompleted = getIsSubStepCompleted(client, otherChangesToCoverageValidationSchema);

      return isCompleted ? "Completed" : "Not Started";
    }
    case "employee-certificate": {
      const isCompleted = getIsSubStepCompleted(client, employeeCertificatesValidationSchema);
      const haveEverSavedEmployeeCertificateForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(employeeCertificateFormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedEmployeeCertificateForm
        ? "In Progress"
        : "Not Started";
    }
    case "section-125": {
      const isCompleted = getIsSubStepCompleted(client, section125ValidationSchema);
      const haveEverSavedSection125Form = hasNonNullValues(
        getPropertiesToUpdate<Client>(haveEverSavedSection125FormKeyProps)(client),
      );

      return isCompleted
        ? "Completed"
        : haveEverSavedSection125Form
        ? "In Progress"
        : "Not Started";
    }
    case "erisa": {
      const isCompleted = getIsSubStepCompleted(client, erisaValidationSchema, args.featureToggles);
      const haveEverSavedErisaForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(erisaFormKeyProps)(client),
      );

      return isCompleted ? "Completed" : haveEverSavedErisaForm ? "In Progress" : "Not Started";
    }
    case "value-added-services": {
      const valueAddedServicesStepViewed = client.valueAddedServicesStepViewed === true;
      const showValueAddedServicesLifeCoverage = shouldShowValueAddedServicesLifeCoverage(client);
      const hasNoValidationErrors = getIsSubStepCompleted(client, valueAddedServicesSchema);
      const isCompleted = showValueAddedServicesLifeCoverage
        ? hasNoValidationErrors
        : valueAddedServicesStepViewed;

      return isCompleted ? "Completed" : "Not Started";
    }

    // Plan Config and Eligibility
    case "class-builder": {
      const classBuilderStatus = getClassBuilderCompletionStatus(
        client,
        args.employeeClasses,
        args.employeeClassDocumentCount,
        args.changeSnapshot,
        args.clientPlans,
      );

      return classBuilderStatus;
    }
    case "non-class-benefits-preferences": {
      const pfmlClientPlans = args.clientPlans.filter((clientPlan) =>
        getIsPFMLBenefitType(clientPlan.benefitType),
      );
      const statutoryClientPlans = args.clientPlans.filter((clientPlan) =>
        getIsStatutorySectionType(clientPlan.benefitType),
      );
      const pfmlStatus = getPFMLPreferencesCompletionStatus(client, pfmlClientPlans);

      const statutoryStatus = getStatutoryPreferencesCompletionStatus(client, statutoryClientPlans);

      if (pfmlStatus === "Completed" && statutoryStatus === "Completed") {
        return "Completed";
      }

      if (pfmlStatus === "In Progress" || statutoryStatus === "In Progress") {
        return "In Progress";
      }

      if (
        pfmlStatus === "Completed" &&
        pfmlClientPlans.length > 0 &&
        statutoryStatus === "Not Started"
      ) {
        return "In Progress";
      }

      if (
        pfmlStatus === "Not Started" &&
        statutoryStatus === "Completed" &&
        statutoryClientPlans.length > 0
      ) {
        return "In Progress";
      }

      return "Not Started";
    }
    case "fli-preferences": {
      const needsFLIPreferences = getIsSubStepApplicable(args);
      if (!needsFLIPreferences) return "Completed";

      if (!args.featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS) {
        const status = args.client.fliPreferencesStepViewed ? "Completed" : "Not Started";
        return status;
      }

      return getFliPreferencesStepStatus(args.client);
    }

    // Everything else
    case "benefits-administration-and-data-feed": {
      const statuses = args.client.policies.map((policy) =>
        getBenAdminAndDataFeedsCompletionStatus(policy),
      );

      const status = statuses.every((status) => status === "Not Started")
        ? "Not Started"
        : statuses.every((status) => status === "Completed")
        ? "Completed"
        : "In Progress";

      return status;
    }
    case "tax-id":
      return client.taxId ? "Completed" : "Not Started";
    case "subsidiaries-and-affiliates": {
      const haveEverSavedSection = client.hasSubsidiary !== null;

      const subsidiaryClientValidationErrors = getValidationErrors(
        subsidiaryClientValidationSchema,
        client,
        {
          prefill: false,
        },
      );

      const benefitTypes = args.clientPlans.map((plan) => plan.benefitType);

      const subsidiariesCompleteIfApplicable =
        (client.hasSubsidiary === "YES" && client.subsidiaryEEsInNYNJOrHI === "YES") ||
        benefitTypes.includes("DENTAL_ASO")
          ? args.subsidiaries &&
            args.subsidiaries.length > 0 &&
            args.subsidiaries.every((subsidiary) => {
              return (
                getValidationErrors(subsidiaryValidationSchema, subsidiary, {
                  prefill: false,
                  benefitTypes,
                }).length === 0
              );
            })
          : true;

      return subsidiaryClientValidationErrors.length === 0 && subsidiariesCompleteIfApplicable
        ? "Completed"
        : haveEverSavedSection
        ? "In Progress"
        : "Not Started";
    }
    case "current-sun-life-policies":
      if (
        client.previouslyInsuredBySunLife === "YES" &&
        (client.previousSlfPolicies ?? []).length === 0
      ) {
        return "In Progress";
      }

      return client.previouslyInsuredBySunLife ? "Completed" : "Not Started";

    case "previous-coverage-information":
      return client.benefitsReplacedWithSlf === "none" ||
        (Array.isArray(client.benefitsReplacedWithSlf) && client.benefitsReplacedWithSlf.length > 0)
        ? "Completed"
        : "Not Started";
    case "billing-preferences": {
      assertIsDefinedInObject(args.client, "policies");

      const statuses = args.client.policies.map((policy) =>
        getBillingPreferencesCompletionStatus({
          ...args,
          policy,
        }),
      );

      const status = statuses.every((status) => status === "Not Started")
        ? "Not Started"
        : statuses.every((status) => status === "Completed")
        ? "Completed"
        : "In Progress";

      return status;
    }
    case "plan-administrators": {
      if (!client.webAdminContact) {
        return "Not Started";
      }
      const validationErrors = primaryWebAdminContactValidation.getPrimaryWebAdminValidationErrors(
        client.webAdminContact,
      );

      if (client.additionalWebAdminContacts) {
        for (const waContact of client.additionalWebAdminContacts) {
          validationErrors.push(
            ...getValidationErrors(
              primaryWebAdminContactValidation.primaryWebAdminValidationSchema,
              waContact,
              {
                prefill: false,
                bills: args.bills,
              },
            ),
          );
        }
      }

      return validationErrors.length === 0 ? "Completed" : "In Progress";
    }
    case "claims-check-mailing-locations": {
      const relevantPlans = getPlansWhereCanSetClaimsCheckLocations(args.clientPlans);
      const hasLTD = Boolean(
        args.clientPlans.find((clientPlan) => clientPlan.benefitType === "LONG_TERM_DISABILITY"),
      );
      const hasFLI = Boolean(
        args.client.allPoliciesSlfCoverages?.includes("Family Leave Insurance"),
      );
      const isClaimsCheckPlanLTDOrFLI = relevantPlans.length === 0 && (hasLTD || hasFLI);

      if (isClaimsCheckPlanLTDOrFLI && client.claimsCheckLTDOnlyStepViewed) {
        return "Completed";
      }

      if (client.claimsCheckPreferencesDefaults === "NO_SELECTION") {
        return "Not Started";
      }
      if (client.claimsCheckPreferencesDefaults === "ACCEPTED_DEFAULTS") {
        return "Completed";
      }
      if (client.claimsCheckPreferencesDefaults === "EDITED_DEFAULTS") {
        const everyPlanHasAClaimsCheckLocationSet = relevantPlans.every((plan) =>
          isClaimsCheckLocationComplete(plan, args.contacts),
        );
        const somePlanHasAClaimsCheckLocationSet = relevantPlans.some(
          (plan) => plan.sendClaimsCheckTo !== null,
        );

        return everyPlanHasAClaimsCheckLocationSet
          ? "Completed"
          : somePlanHasAClaimsCheckLocationSet
          ? "In Progress"
          : "Not Started";
      }

      throw new Error(
        `Exhaustive check failed. Case for value "disabilityClaimsPreferencesDefaults = ${String(
          client.claimsCheckPreferencesDefaults,
        )}" was not handled.`,
      );
    }
    case "monthly-claims-reports-and-eobs": {
      if (client.monthlyClaimsReportsAndEOBsDefaults === "NO_SELECTION") {
        return "Not Started";
      }
      if (client.monthlyClaimsReportsAndEOBsDefaults === "ACCEPTED_DEFAULTS") {
        return "Completed";
      }
      if (client.monthlyClaimsReportsAndEOBsDefaults === "EDITED_DEFAULTS") {
        const mailingLocations = client.monthlyClaimsReportMailingLocations ?? [];

        if (mailingLocations.length === 0) return "Not Started";

        for (const mailingLocation of mailingLocations) {
          const validationErrors = getValidationErrors(
            monthlyClaimsReportMailingLocationSchema,
            mailingLocation,
            { prefill: false },
          );
          const isCompleted = validationErrors.length === 0;

          if (!isCompleted) {
            return "In Progress";
          }
        }

        const relevantPlans = getPlansWhereCanSetMonthlyClaimsLocations(args.clientPlans);

        const thereAreUnassignedBenefits =
          relevantPlans.length > unique(mailingLocations.flatMap((m) => m.slfCoverages)).length;

        if (thereAreUnassignedBenefits) return "In Progress";

        return "Completed";
      }

      throw new Error(
        `Exhaustive check failed. Case for value "monthlyClaimsReportsAndEOBsDefaults = ${String(
          client.monthlyClaimsReportsAndEOBsDefaults,
        )}" was not handled.`,
      );
    }

    case "malpractice-reimbursements": {
      const isCompleted = getIsSubStepCompleted(client, malpracticeReimbursementsValidationSchema);
      const haveEverSavedMalpracticeReimbursementsForm = hasNonNullValues(
        getPropertiesToUpdate<Client>(malpracticeReimbursementsFormKeyProps)(client),
      );
      return isCompleted
        ? "Completed"
        : haveEverSavedMalpracticeReimbursementsForm
        ? "In Progress"
        : "Not Started";
    }
    default:
      exhaustiveCheck(eifSubStepId);
      break;
  }

  return "Not Started";
}

export function isClaimsCheckLocationComplete(plan: Plan, contacts: Contact[]) {
  if (plan.benefitType === "LONG_TERM_DISABILITY" || plan.benefitType === "FLI") {
    return true;
  }

  if (plan.sendClaimsCheckTo == null) {
    return false;
  }

  if (plan.sendClaimsCheckTo === "EMPLOYEE_HOME") {
    return true;
  }

  if (plan.claimsCheckPayee === "PLAN_ADMINISTRATOR" && plan.planAdminPayeeContactId != null) {
    return true;
  }

  if (plan.claimsCheckPayee === "SOMEONE_ELSE" && plan.someoneElsePayeeContactId != null) {
    const selectedContact = contacts.find(({ id }) => id === plan.someoneElsePayeeContactId);
    if (!selectedContact) {
      return false;
    }

    const claimsCheckMailingContact = contactWithAddressValidationSchema.pick(
      claimCheckMailingLocationContactProps,
    );

    const contactErrors = getValidationErrors(claimsCheckMailingContact, selectedContact, {
      prefill: false,
      bills: [],
    });

    const claimsCheckMailingContactLocation = locationInputValidation.omit(["name"]);

    const contactLocationErrors = getValidationErrors(
      claimsCheckMailingContactLocation,
      selectedContact.location,
      {
        prefill: false,
      },
    );

    const isContactCompleted = contactErrors.length === 0 && contactLocationErrors.length === 0;
    return isContactCompleted;
  }

  return false;
}

function getFliPreferencesStepStatus(client: Client) {
  if (client.fliCoversAllEmployees === true) return "Completed";
  if (client.fliCoversAllEmployees === false && client.fliExcludedGroups?.length)
    return "Completed";

  if (client.fliCoversAllEmployees === false && !client.fliExcludedGroups?.length)
    return "In Progress";
  if (client.fliPreferencesStepViewed) return "In Progress";

  return "Not Started";
}
