import { DeleteFileButton } from "client/src/domain/Document/DeleteFileButton/DeleteFileButton";
import { RenameFile } from "client/src/domain/Document/RenameFile/RenameFile";
import { UploadFileTablePresentation } from "client/src/domain/Document/UploadFileTable/UploadFileTablePresentation";
import { rejectNullableValues } from "shared/utils/utils";
import { ErrorMessage } from "../../../components/Error/ErrorMessage";
import * as styles from "../DownloadFileTable/downloadFileTable.module.less";

type FileRowData = {
  key: string | number;
  id: string | number;
  name: string;
  error?: string;
  errorCode?: "DUPLICATE_NAME" | "EMBEDDED_SCRIPTS";
  updatedBy: string;
};

export type FileTableProps = {
  fileList: FileRowData[];
  onClickRemove?: (filename: string) => void;
  disabled?: boolean;
  track: (buttonLabel: string) => void;
  renameDocument: (recordId: string, newName: string) => void;
};

export const UploadFileTable = ({
  fileList,
  onClickRemove,
  disabled,
  track,
  renameDocument,
}: FileTableProps) => {
  const errorExists = fileList.some((file) => file.error);
  const hasEmbeddedScripts = fileList.some((file) => file.errorCode === "EMBEDDED_SCRIPTS");

  const columnHeaders = [
    {
      title: "File Name",
      dataIndex: "name",
      key: "name",
      render: (text: unknown, record: FileRowData) => {
        return record.errorCode === "DUPLICATE_NAME" ? (
          <RenameFile
            record={{ ...record, id: String(record.id), isNew: false }}
            renameDocument={renameDocument}
          />
        ) : (
          record.name
        );
      },
    },
    {
      title: "Added By",
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
    {
      width: "60px",
      title: "",
      key: "action",
      render: (text: unknown, record: FileRowData) => (
        <div className={styles.actions}>
          <DeleteFileButton
            onClick={() => {
              track("Remove file");
              onClickRemove?.(record.name);
            }}
            disabled={disabled}
          />
        </div>
      ),
    },
    errorExists
      ? {
          title: "Error",
          key: "error",
          render: (text: unknown, record: FileRowData) => {
            return <ErrorMessage>{record.error}</ErrorMessage>;
          },
        }
      : null,
  ];

  const columnHeadersFiltered = columnHeaders.filter(rejectNullableValues);

  return (
    <UploadFileTablePresentation
      dataSource={fileList.map(convertFileToRowData)}
      columns={columnHeadersFiltered}
      errorExists={errorExists}
      hasEmbeddedScripts={hasEmbeddedScripts}
    />
  );
};

const convertFileToRowData = (file: FileRowData): FileRowData => {
  return {
    key: String(file.id),
    id: file.id,
    name: file.name,
    error: file.error,
    errorCode: file.errorCode,
    updatedBy: `${file.updatedBy ?? "unknown"}`,
  };
};
