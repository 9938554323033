import { getKeys } from "shared/utils/utils";
import type { ClientErrorType } from "@prisma/client";

export const clientErrorLabel: Record<ClientErrorType, string> = {
  WELCOME_EMAIL: "Welcome Email Error",
  JIRA_SYNC: "Jira Sync Failure",
  ACCOUNT_NOT_ACTIVATED: "Not Activated",
  ABANDONED_CENSUS: "Abandoned Census",
};

export const clientErrorWarningTypes: ClientErrorType[] = [
  "ABANDONED_CENSUS",
  "ACCOUNT_NOT_ACTIVATED",
];

export const clientErrorTypes = getKeys(clientErrorLabel);
