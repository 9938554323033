import clsx from "clsx";
import * as styles from "./orderedList.module.less";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  spacing?: "small" | "large";
};

export function OrderedList(props: Props) {
  const { children, spacing = "small" } = props;
  return (
    <ol
      className={clsx(
        styles.orderedList,
        spacing === "small" && styles.smallSpacing,
        spacing === "large" && styles.largeSpacing,
      )}
    >
      {children}
    </ol>
  );
}

export function OrderedListItem(props: Props) {
  const { children } = props;
  return (
    <li>
      <span>{children}</span>
    </li>
  );
}
