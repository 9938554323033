import clsx from "clsx";

import { StackY } from "../../components/Spacing/Spacing";
import { HorizontalDivider } from "../HorizontalDivider/HorizontalDivider";

import * as styles from "./hubCard.module.less";

import type { ReactNode } from "react";

type HubCardHeaderProps = {
  title: string;
  description?: ReactNode;
  badge?: JSX.Element | null;
  hasDivider?: boolean;
  button?: JSX.Element | null;
};

export const HubCardHeader = ({
  title,
  description,
  badge,
  hasDivider = false,
  button,
}: HubCardHeaderProps) => {
  return (
    <StackY dist={16}>
      {badge}
      <div className={button ? styles.hubCardHeaderWithButton : styles.hubCardHeader}>
        <h5 className="mb-4">{title}</h5>
        {description && <div className={clsx("body3", styles.description)}>{description}</div>}
        {button}
      </div>
      {hasDivider && <HorizontalDivider />}
    </StackY>
  );
};
