import tinycolor from "tinycolor2";
import type { ColorInput } from "tinycolor2";

export function getTextColor(
  color: string,
  text: { light: ColorInput; dark: ColorInput } = {
    light: "#ffffff",
    dark: "#000000",
  },
) {
  return tinycolor.mostReadable(color, [text.dark, text.light]).toHex8String();
}
