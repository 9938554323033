import { faChevronCircleLeft, faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { sendPersonalBenefitsPlanRequestValidation } from "../../../../../shared/validation/explorerPage";
import { Button } from "../../../components/Button/Button";
import { FormInput } from "../../../components/Form/Input";
import { ExplorerFeedbackForm } from "../../../components/GiveFeedbackForm/ExplorerFeedbackForm";
import { Modal } from "../../../components/Modal/Modal";
import { Body1, Body3, H1, H1Large, H3, H4 } from "../../../components/Typography/Typography";
import { useSendPersonalBenefitsPlanEmail } from "../../../hooks/ExplorerPage/usePersonalBenefitsPlan";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useFavoritePlans } from "../../../hooks/useSlobLocalStorage";
import { i18n, Trans, useTranslation } from "../../../i18n";
import {
  useExplorerTrackElementClicked,
  useSendExplorerFeedbackForm,
} from "../../../utils/analytics";
import { PrintHidden, PrintOnly } from "../../../utils/print";
import { useRecaptcha } from "../../../utils/re-captcha";

import * as styles from "./clientPage.module.less";

import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";

export const DataTestId = {
  benExFavGif: "benex-fav-gif",
};

type PersonalBenefitsPlanHeadlineProps = {
  page: PublicExplorerPage;
};

export type PersonalBenefitsPlanHeadlineCopyStructure = {
  header_empty: string;
  header: string;
  subHeader_empty: string;
  subHeader: string;
  oeBanner: string;
  hrLinkBanner: string;
  printHeader: string;
  feedbackTitle: string;
  feedbackConfirmTitle: string;
  feedbackConfirmBody: string;
  feedbackConfirmCTA: string;
  emailCTA: string;
  backButton: string;
  downloadText: string;
};

export const PersonalBenefitsPlanHeadline = ({ page }: PersonalBenefitsPlanHeadlineProps) => {
  const breakpoint = useBreakpoint();
  const { t } = useTranslation({ keyPrefix: "PersonalBenefitsPlanHeadline" });
  const [favoritePlans] = useFavoritePlans(page.id, page.benefits);
  const [modalState, setModalState] = useState<"hidden" | "feedback" | "enroll">("hidden");

  const { mutateAsync: sendEmail, isPending } = useSendPersonalBenefitsPlanEmail();
  const sendExplorerFeedbackForm = useSendExplorerFeedbackForm(page.id);

  const trackElementClicked = useExplorerTrackElementClicked(page);

  const { recaptchaRef, execute: executeRecaptcha } = useRecaptcha({
    onError: useCallback((err: Error) => console.error(err), []),
  });

  const language = i18n.language === "es" ? "es" : "en";

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      benefits: favoritePlans,
      recaptchaToken: "token",
      language: language,
    },
    validationSchema: sendPersonalBenefitsPlanRequestValidation,
    onSubmit: async (vals) => {
      try {
        const token = await executeRecaptcha();
        if (token) {
          vals.recaptchaToken = token;
          await sendEmail({ data: vals });
          setModalState("feedback");
          formik.resetForm();
          trackElementClicked({
            module: "Personal Benefits Plan",
            buttonLabel: "Send Email",
            personalBenefitsPlan: vals.benefits,
          });
        } else {
          formik.setFieldError("email", "Oops, something went wrong!");
        }
      } catch (e) {
        if (typeof e === "string") formik.setFieldError("email", e);
      }
    },
  });

  const hasCustomBranding = page.customBranding && page.benefitsExplorerCustomBranding;

  return (
    <>
      <Modal
        title={modalState === "feedback" ? t("feedbackTitle") : t("feedbackConfirmTitle")}
        width={600}
        open={modalState !== "hidden"}
        onCancel={() => setModalState("hidden")}
        footer={null}
      >
        {modalState === "feedback" ? (
          <ExplorerFeedbackForm
            sendExplorerFeedbackForm={sendExplorerFeedbackForm}
            onSubmit={() => {
              if (page.benAdminPlatformUrl) setModalState("enroll");
              else setModalState("hidden");
            }}
            onCancel={() => setModalState("hidden")}
          />
        ) : (
          <>
            <p className="mb-32">{t("feedbackConfirmBody")}</p>
            <Button
              type="primary"
              size="middle"
              onClick={() => {
                page.benAdminPlatformUrl && window.open(page.benAdminPlatformUrl, "_blank");
                setModalState("hidden");
              }}
            >
              {t("feedbackConfirmCTA")}
              <FontAwesomeIcon className="ml-8" icon={faExternalLinkSquareAlt} />
            </Button>
          </>
        )}
      </Modal>
      <PrintOnly>
        <div className="mb-24">
          <H1>{t("header")}</H1>
          <div className={clsx("py-8", "mb-32")}>
            {page.openEnrollmentStart && page.openEnrollmentEnd && (
              <div className="mb-4">
                <Body3>
                  <Trans
                    t={t}
                    i18nKey="oeBanner"
                    values={{
                      oeStart: page.openEnrollmentStart,
                      oeEnd: page.openEnrollmentEnd,
                    }}
                  />
                </Body3>
              </div>
            )}
            {page.benAdminPlatformUrl && (
              <Body3>
                <Trans
                  t={t}
                  i18nKey="hrLinkBanner"
                  values={{
                    hrAdminURL: page.benAdminPlatformUrl,
                  }}
                >
                  <Link to={page.benAdminPlatformUrl} target="_blank" />
                </Trans>
              </Body3>
            )}
          </div>
          <H3>{t("printHeader", { year: page.planYear })}</H3>
        </div>
      </PrintOnly>
      <PrintHidden>
        <Row justify="center" className="my-32">
          <Col xs={{ span: 24 }} md={{ span: 3 }} style={{ padding: "10px 0" }}>
            <Link to={RouteData.benefitsExplorer.getPath(page.vanitySlug ?? page.id)}>
              <Button
                type="text-only"
                icon={<FontAwesomeIcon icon={faChevronCircleLeft} />}
                size="large"
              >
                {t("backButton")}
              </Button>
            </Link>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 18 }}
            className={breakpoint.xs ? styles.textAlignLeft : styles.textAlignCenter}
          >
            <StackY dist={16}>
              <H1Large>
                <strong>
                  <Trans
                    t={t}
                    i18nKey={"header"}
                    tOptions={{ context: !formik.values.benefits.length && "empty" }}
                  />
                </strong>
              </H1Large>
              <H4>
                <Trans
                  t={t}
                  i18nKey={"subHeader"}
                  tOptions={{ context: !formik.values.benefits.length && "empty" }}
                />
              </H4>
              <form
                className={styles.personalBenefitsPlanHeadlineForm}
                onSubmit={formik.handleSubmit}
              >
                <StackY dist={16}>
                  <FormInput
                    label="Email"
                    topText=""
                    value={formik.values.email}
                    maxLength={191}
                    name="email"
                    error={formik.errors.email ?? formik.errors.benefits?.toString()}
                    touched={formik.touched.email}
                    onChange={formik.handleChange}
                    disabled={!formik.values.benefits.length}
                  />
                  <div
                    ref={(elem) => {
                      // https://github.com/facebook/react/issues/29196
                      recaptchaRef.current = elem;
                    }}
                  />
                  <Button
                    htmlType="submit"
                    block={breakpoint.xs ? true : false}
                    type="primary"
                    size="large"
                    disabled={isPending || !formik.values.benefits.length}
                    loading={formik.isSubmitting}
                  >
                    {t("emailCTA")}
                  </Button>
                </StackY>
              </form>
              {!!formik.values.benefits.length && (
                <Row justify="center">
                  <Col>
                    <Body1>
                      <Trans t={t} i18nKey={"downloadText"}>
                        {/* @todo: FP-3498: move to Link component with normal text margin */}
                        <ButtonOld
                          type="link-inline"
                          size="large"
                          style={{
                            whiteSpace: "pre-line",
                            textAlign: "left",
                          }}
                          onClick={() => window.print()}
                        >
                          here
                        </ButtonOld>
                      </Trans>
                    </Body1>
                  </Col>
                </Row>
              )}
              {!formik.values.benefits.length && !page.hideSunLifeLogo && !hasCustomBranding && (
                <Image
                  src="/BenEx_favorite.gif"
                  preview={false}
                  data-testid={DataTestId.benExFavGif}
                />
              )}
            </StackY>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 3 }} style={{ padding: "10px" }}></Col>
        </Row>
      </PrintHidden>
    </>
  );
};
