import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";

import { WebLinkCategories } from "../types/WebLink";

import type { WebLinkCategory } from "../types/WebLink";

export const webLinkTextValidationSchema = Yup.string()
  .max(191, "Link text cannot be longer than 191 characters")
  .required("Please provide a response");

export const webLinkUrlValidationSchema = Yup.string()
  .max(2048, "URL cannot be longer than 2048 characters")
  .url("Please provide a valid URL")
  .required("Required");

export const webLinkCategoryValidationSchema = Yup.mixed<WebLinkCategory>()
  .oneOf(WebLinkCategories.slice(), "Please provide a response")
  .required("Please provide a response")
  .test(nullNotAllowedTestConfig());

export const webLinkCategoriesValidationSchema = Yup.array()
  .of(webLinkCategoryValidationSchema)
  .required();
