import { StackX } from "client/src/components/Spacing/Spacing";
import { getHandleFormClosing } from "client/src/domain/Client/UnsavedChangesModal";
import { clientValidationSchema } from "shared/validation/client";
import { Anchor } from "../../components/Anchor/Anchor";
import { Button } from "../../components/Button/Button";
import { GenericErrorCopy } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { TextArea } from "../../components/Form/TextArea";
import { Modal } from "../../components/Modal/Modal";
import { Body2 } from "../../components/Typography/Typography";
import { useClientUtils } from "../../domain/Client/useClientUtils";

import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Client } from "shared/types/Client";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  client: Client;
  updateClient: UpdateClientFunc;
  track: (buttonLabel: string) => void;
};

export function RequestFreeExpertHelpModal({
  isOpen,
  closeModal,
  client,
  updateClient,
  track,
}: Props) {
  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: ({ enrollmentNeeds }) => ({
      enrollmentNeeds: enrollmentNeeds || "",
    }),
    updateClient,
    validationSchema: clientValidationSchema.pick(["enrollmentNeeds"]),
    type: "Enrollment Needs",
    track,
    onSuccessCallback: closeModal,
  });

  const handleCancelAndClose = getHandleFormClosing({ formik, onProceed: closeModal });

  const hasMoreThan25Employees = (client?.employeesNumber ?? 0) > 25;

  return (
    <Modal
      open={isOpen}
      title="Request Expert Help"
      footer={null}
      destroyOnClose
      maskClosable={false}
      onCancel={handleCancelAndClose}
      disableClose={formik.isSubmitting}
    >
      <form onSubmit={formik.handleSubmit}>
        <Body2 as="div">Build your custom enrollment plan</Body2>
        <p className="mt-4">
          Talk with a Voluntary Solutions Manager (VSM) about your unique enrollment needs and
          they’ll help to design the right combination for you from our custom services and
          materials.
        </p>

        <div className="mt-36">
          <Body2 as="div">Tell us about</Body2>
          <p className="mb-24">
            Include details about your enrollment and note any resources and materials you're
            interested in. We can help you with:{" "}
            {hasMoreThan25Employees && `Benefits Explorer sites, `}email templates, webinars and
            presentation materials, telephonic enrollment, employee Benefit Counselors, and foreign
            language assistance.{" "}
            <Anchor
              target="_blank"
              href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4417522674323"
            >
              Learn More
            </Anchor>
          </p>
          <TextArea
            name="enrollmentNeeds"
            label=""
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            value={formik.values.enrollmentNeeds || undefined}
            maxLength={1000}
            aria-labelledby="enrollmentNeeds--label"
            aria-describedby="enrollmentNeeds--description"
            touched={formik.touched.enrollmentNeeds}
            error={formik.errors.enrollmentNeeds}
          />
        </div>

        {formik.status && (
          <LoadingError type="component" error={formik.status} title={GenericErrorCopy} />
        )}

        <StackX dist={8} className="mt-32">
          <Button
            type="primary"
            size="middle"
            htmlType="submit"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            aria-label={formik.isSubmitting ? "Submit Request" : undefined}
          >
            Submit Request
          </Button>

          <Button
            type="text"
            size="middle"
            onClick={handleCancelAndClose}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
        </StackX>
      </form>
    </Modal>
  );
}
