import memoizeOne from "memoize-one";
import {
  advanceCoverages,
  arrearCoverages,
  getIsAdvanceOrArrearsCoverage,
} from "shared/types/SlfCoverages";
import type { BillTiming } from "@prisma/client";
import type { Policy } from "shared/types/Client";
import type { AdvanceOrArrearsCoverage, SlfCoverageLongName } from "shared/types/SlfCoverages";

export type AdvanceOrArrearsSelection = Partial<
  Record<AdvanceOrArrearsCoverage, BillTiming | null | undefined>
>;

function getAdvanceAndArrearsCoveragesBreakdown_nomemo(
  policy: Policy,
  advanceOrArrears: AdvanceOrArrearsSelection,
) {
  const advanceOrArrearsEntries = Object.entries(advanceOrArrears);
  const additionalAdvance = advanceOrArrearsEntries
    .filter(([_coverage, billTiming]) => billTiming === "Advance")
    .map(([coverage, _billTiming]) => coverage)
    .filter(getIsAdvanceOrArrearsCoverage);
  const additionalArrears = advanceOrArrearsEntries
    .filter(([_coverage, billTiming]) => billTiming === "Arrears")
    .map(([coverage, _billTiming]) => coverage)
    .filter(getIsAdvanceOrArrearsCoverage);

  const advanceCoveragesAll = new Array<SlfCoverageLongName>()
    .concat(advanceCoverages)
    .concat(additionalAdvance ?? [])
    .filter((cov) => policy.slfCoverages?.includes(cov))
    .sort();
  const arrearsCoveragesAll = new Array<SlfCoverageLongName>()
    .concat(arrearCoverages)
    .concat(additionalArrears ?? [])
    .filter((cov) => policy.slfCoverages?.includes(cov))
    .sort();

  const hasMixedTiming = advanceCoveragesAll.length > 0 && arrearsCoveragesAll.length > 0;

  return {
    advanceCoveragesAll,
    arrearsCoveragesAll,
    hasMixedTiming,
  };
}

export const getAdvanceAndArrearsCoveragesBreakdown = memoizeOne(
  getAdvanceAndArrearsCoveragesBreakdown_nomemo,
);
