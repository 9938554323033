import { getShouldAskAgeQuestionForSpouseDependentLife } from "shared/utils/EIF/getShouldAskAgeQuestionForSpouseDependentLife";
import type { AgeRules, Client } from "shared/types/Client";

type AgeRulesRelevantFields = Extract<
  keyof Client,
  | "ageReductionDecreaseTakeEffectWhen"
  | "ageBandIncreaseTakeEffectWhen"
  | "ageForDependentLifeBenefits"
  | "criticalIllnessDependentAge"
  | "criticalIllnessDependentTobacco"
>;

export const getRelevantClientAgeRuleFields = (client: Client) => {
  const ageRuleToClientFieldMap: Record<
    AgeRules,
    Extract<keyof Client, "ageReductionDecreaseTakeEffectWhen" | "ageBandIncreaseTakeEffectWhen">
  > = {
    "Age Reductions": "ageReductionDecreaseTakeEffectWhen",
    "Age Band Rates": "ageBandIncreaseTakeEffectWhen",
  };
  const relevantClientFields: AgeRulesRelevantFields[] =
    client?.ageRules?.map((ageRule) => {
      return ageRuleToClientFieldMap[ageRule];
    }) ?? [];

  const allCoverages = client.allPoliciesSlfCoverages ?? [];
  const slfCoverageHasSpouseVolLife = allCoverages.includes("Spouse Voluntary Life");
  if (slfCoverageHasSpouseVolLife || getShouldAskAgeQuestionForSpouseDependentLife(client)) {
    relevantClientFields.push("ageForDependentLifeBenefits");
  }

  if (client.criticalIllnessType === "ATTAINED") {
    relevantClientFields.push("criticalIllnessDependentAge");

    if (client.criticalIllnessRateType === "TOBACCO") {
      relevantClientFields.push("criticalIllnessDependentTobacco");
    }
  }

  return relevantClientFields;
};
