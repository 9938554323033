import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { OrderedList, OrderedListItem } from "client/src/components/OrderedList/OrderedList";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { Body1, Body3, Body5, H5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { AddFilesCard } from "client/src/domain/Document/AddFilesCard/AddFilesCard";
import { useUpdateDentalRollover } from "client/src/hooks/policyTask";
import { useToggler } from "client/src/hooks/useToggler";
import { hasRemainingHubTasks } from "client/src/pages/Hub/Hub";
import { useCallback } from "react";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { taskStatusToLabel } from "../../../components/Badge/Badge";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";
import styles from "./DentalRolloverPage.module.less";

import type { DentalRollover } from "@prisma/client";

import type { PolicyTask } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";

type DentalRolloverProps = {
  client: Client;
  policyTask: PolicyTask;
  policy: Policy;
};

const dentalRolloverToText: Record<DentalRollover, string> = {
  PREVENTIVE_REWARDS: "Preventive Rewards",
  ROLLMAX: "Rollmax",
};

export function DentalRolloverPage({ client, policyTask, policy }: DentalRolloverProps) {
  const pageTitle = "Dental Rollover";

  useTitle(pageTitle);
  const trackElementClicked = useTrackElementClicked(client, pageTitle, policy.id);

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: policyTask.status ? taskStatusToLabel[policyTask.status] : "Not Started",
        buttonLabel,
      });
    },
    [trackElementClicked, policyTask],
  );

  const { mutateAsync: updateDentalRollover, isPending: isSaving } = useUpdateDentalRollover();

  const [uncheckConfirmation, toggleUncheckConfirmation] = useToggler();

  if (!policy.dentalRollover) {
    return <Navigate to={RouteData.homeHub.getPath(client.id)} replace />;
  }

  const onFirstRolloutChange = async (checked: boolean) => {
    track("Checkbox first time including a rollover plan");
    if (!checked) toggleUncheckConfirmation();
    else await handleUpdate(checked);
  };

  const handleUpdate = async (checked: boolean) => {
    const hide = slobMessage.loading("Saving...", 0);
    try {
      await updateDentalRollover({
        params: { clientId: client.id, policyId: policy.id },
        data: { noDentalRollover: checked },
      });
    } catch (error) {
      void slobMessage.error(`Failed to save`);
    } finally {
      hide?.();
    }
  };

  const remainingTasks = hasRemainingHubTasks(client);
  const shouldDisplayDueDate = getShouldDisplayDueDate(policyTask);
  const shouldShowFileUpload = policyTask.status !== "Done";
  const shouldShowCheckboxOption =
    policyTask.status !== "In Review" && !policy.dentalRolloverCompleted;
  const shouldShowFileUploadSteps = policyTask.status !== "In Review" && shouldShowFileUpload;
  const dentalRolloverText = dentalRolloverToText[policy.dentalRollover];

  return (
    <ChildPageLayout
      topNavTitle={pageTitle}
      task={policyTask}
      aside={
        <>
          <h1>{pageTitle}</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={policyTask} />
              <br />
            </>
          )}
          <p>
            Your dental plan includes the Sun Life {dentalRolloverText} program. To take advantage
            of this feature in your plan, you will need to provide us with a report that shows the
            current banked amounts to be rolled over from your prior carrier.
          </p>
        </>
      }
      contents={
        <StackY dist={40}>
          <AddFilesCard
            clientId={client.id}
            policyId={policy.id}
            taskStatus={policyTask.status}
            category="dental-rollover"
            modalTitle="Submit banked dental rollover amounts"
            hideButton={!shouldShowFileUpload}
            message="Sun Life will review your previous dental information and let you know if anything else is required from you. Review typically takes 2-3 days. You can continue to add more files if needed."
            taskCompletedTitle="Your files have been submitted for review!"
            actionRequiredMessage={null}
            remainingTasks={remainingTasks}
            trackElementClicked={trackElementClicked}
            title={<H5 as="h2">Submit banked dental rollover amounts</H5>}
            description={
              <>
                <ConfirmDialog
                  isVisible={uncheckConfirmation}
                  title="Uncheck box"
                  onConfirm={() => {
                    toggleUncheckConfirmation();
                    void handleUpdate(false);
                  }}
                  onCancel={toggleUncheckConfirmation}
                  confirmActionText="Yes"
                  cancelActionText="No"
                >
                  <Body1 as="p">
                    This task has already been submitted for review. Are you sure you want to
                    uncheck the checkbox?
                  </Body1>
                </ConfirmDialog>
                <StackY dist={16}>
                  {shouldShowCheckboxOption && (
                    <Checkbox
                      name="firstDentalRollover"
                      value={"noDentalRollover"}
                      label={
                        <>
                          This is our first time including a rollover plan
                          <br />
                          <Body5>This means you have no banked amounts for your employees.</Body5>
                        </>
                      }
                      checked={Boolean(policy.noDentalRollover)}
                      errorId={undefined}
                      onChange={async (e) => {
                        await onFirstRolloutChange(e.target.checked);
                      }}
                      disabled={isSaving}
                    />
                  )}
                  {shouldShowCheckboxOption && shouldShowFileUploadSteps && (
                    <Body5 as="p">Or</Body5>
                  )}
                  {shouldShowFileUploadSteps && (
                    <OrderedList>
                      <OrderedListItem>
                        <StackX dist={8}>
                          <Body3>
                            Download and review our{" "}
                            <Anchor
                              href="/Dental Rollover Template.xlsx"
                              onClick={() => {
                                track("Download Dental Rollover Template");
                              }}
                            >
                              banked amounts template
                            </Anchor>
                          </Body3>
                          <Tooltip
                            title={
                              <StackY dist={20}>
                                <Body5 white>
                                  Another file may be used if it has the following information:
                                </Body5>
                                <UnorderedList
                                  color="white"
                                  markerColor="white"
                                  content="body5"
                                  tight
                                >
                                  <UnorderedListItem>Employee SSN</UnorderedListItem>
                                  <UnorderedListItem>Employee Name</UnorderedListItem>
                                  <UnorderedListItem>Employee DOB</UnorderedListItem>
                                  <UnorderedListItem>
                                    Dependent name, DOB, and relationship to insured
                                  </UnorderedListItem>
                                  <UnorderedListItem>
                                    Rollover amount for each person
                                  </UnorderedListItem>
                                </UnorderedList>
                              </StackY>
                            }
                            placement="right"
                            large
                          >
                            <button aria-label="info" className={`btn-reset ${styles.infoIcon}`}>
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </button>
                          </Tooltip>
                        </StackX>
                      </OrderedListItem>
                      <OrderedListItem>
                        <Body3>Add your employee information into the template.</Body3>
                      </OrderedListItem>
                      <OrderedListItem>
                        <Body3>
                          Upload your completed file and submit it to Sun Life for review.
                        </Body3>
                      </OrderedListItem>
                    </OrderedList>
                  )}
                </StackY>
              </>
            }
          />
        </StackY>
      }
    />
  );
}
