import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";

import { Button } from "../../../components/Button/Button";
import { OverlappingCircles } from "../../../components/Circle/OverlappingCircles";
import { Body1, H2 } from "../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../i18n";
import { openZendeskChat, useZendeskDepartmentStatus } from "../../../utils/zendesk";
import { copyKey } from "../utils";

import * as styles from "./clientPage.module.less";

type BenefitInterstitialDividerProps = {
  type: "chat" | "one-on-one" | "SMS";
  notificationLink?: string;
  customCalendlyLink?: string;
  doNotshowImage?: boolean;
  track?: () => void;
  hideLogo?: boolean;
};

export const BenefitInterstitialDivider = ({
  type,
  notificationLink,
  customCalendlyLink,
  doNotshowImage,
  track,
  hideLogo,
}: BenefitInterstitialDividerProps) => {
  const { t } = useTranslation({ keyPrefix: "BenefitInterstitialDivider" });

  const zendeskStatus = useZendeskDepartmentStatus();
  const screens = useBreakpoint();

  if (type === "SMS" && !notificationLink) {
    return null;
  }

  const sunLifeCopy = !hideLogo ? "Sun Life " : "";

  return (
    <div className={styles.interstitialWrapper} data-testid={type}>
      {!doNotshowImage && screens.sm && (
        <OverlappingCircles side={type === "one-on-one" ? "right" : "left"} className="mx-128" />
      )}
      <Row justify="center" className="p-40">
        <Col>
          <H2>
            <span className={styles.header}>
              <Trans
                t={t}
                i18nKey={copyKey(type, "header")}
                values={{
                  sunLifeCopy,
                }}
              ></Trans>
            </span>
          </H2>
          {type === "chat" && (
            <>
              <div>
                <Body1>
                  <span className={styles.header}>{t(copyKey(type, "subHeader1"))}</span>
                </Body1>
              </div>
              <div className="mb-16">
                <Body1>
                  <span className={styles.header}>{t(copyKey(type, "subHeader2"))}</span>
                </Body1>
              </div>
            </>
          )}
          <Row justify="center">
            <Col>
              <Button
                type="primary"
                size="middle"
                disabled={type === "chat" && zendeskStatus !== "online"}
                onClick={() => {
                  track?.();

                  if (type === "chat") {
                    openZendeskChat();
                    return;
                  }

                  const link = type === "SMS" ? notificationLink : customCalendlyLink;
                  if (!link) return;
                  window.open(link, "_blank");
                }}
              >
                {t(copyKey(type, "callToAction"))}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
