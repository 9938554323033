import { Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { Navigate } from "react-router-dom";

import { RouteData } from "../../../../shared/config/routeData";
import { SLF_OWNER } from "../../../../shared/rbac/roles";
import { Loading } from "../../components/Loading/Loading";
import { PageContent } from "../../components/PageContent/PageContent";
import { SlfInternalList } from "../../domain/User/List/SlfInternalList";
import { useSlobAuth } from "../../hooks/auth";

import type { RbacCheckTypeSync } from "shared/rbac/rbac";

const { Title } = Typography;

export const SlfInternalPage = () => {
  const { isLoading, accessCheck } = useSlobAuth();
  const { data: featureToggles, isLoading: isFeatureTogglesLoading } = useGetFeatureToggles();

  if (isLoading || isFeatureTogglesLoading) return <Loading />;

  if (!hasAccess(accessCheck)) {
    return <Navigate to={RouteData.index.getPathTemplate()} replace />;
  }

  return (
    <PageContent>
      <Row>
        <Col span={15}>
          <Title>Internal Users</Title>
        </Col>
      </Row>
      <SlfInternalList featureToggles={featureToggles} />
    </PageContent>
  );
};

const hasAccess = (accessCheck: RbacCheckTypeSync) => {
  try {
    return accessCheck("update", SLF_OWNER).granted && accessCheck("delete", SLF_OWNER).granted;
  } catch (error) {
    return false;
  }
};
