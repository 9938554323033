import { EIFBillingPreferences } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/EIFBillingPreferences";
import { BillingPreferencesPolicySummary } from "client/src/domain/EIF/PlanAdministratorsAndBilling/BillingPreferencesPolicySummary";
import { EIFSubStepPolicySelection } from "client/src/domain/EIF/common/EIFSubStepPolicySelection";
import { getChangeDetailInfoListForSubStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import {
  type EIFStepId,
  type EIFPlanAdministratorsAndBillingSubStepMap,
  getEIFSubStepMap,
} from "shared/types/EIF";
import { getBillingPreferencesCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getEIFSubStepViewMode } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const eifStepId: EIFStepId = "plan-administrators-&-billing";

const eifSubStepId = "billing-preferences" satisfies EIFPlanAdministratorsAndBillingSubStepMap;

type Props = {
  client: Client;
  bills: Bill[];
  deletedBills: Bill[];
  locations: Location[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  updateClient: UpdateClientFunc;
  updatePolicy: UpdatePolicyFunc;
  trackElementClicked: TrackElementClickedFunc;
};

export function EIFBillingPreferencesPolicySelection(props: Props) {
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  return (
    <EIFBillingPreferencesPolicySelectionPresentation
      {...props}
      previousSubStepLink={previousSubStepLink}
      nextSubStepLink={nextSubStepLink}
    />
  );
}

export function EIFBillingPreferencesPolicySelectionPresentation(
  props: Props & { previousSubStepLink: string | null; nextSubStepLink: string },
) {
  const {
    client,
    bills,
    deletedBills,
    billingSummaryStatementTemplates,
    authUser,
    changeSnapshot,
    featureToggles,
    previousSubStepLink,
    nextSubStepLink,
  } = props;

  const subStepName = getEIFSubStepMap({ eifSubStepId });

  if (client.policies.length === 1) {
    const [policy] = client.policies;
    assertIsDefined(policy, "policy");
    return <EIFBillingPreferences {...props} policy={policy} />;
  }

  const viewMode = getEIFSubStepViewMode({ client });

  return (
    <EIFSubStepPolicySelection
      client={client}
      getChangeDetailInfoList={(policy) => {
        const changeDetailInfoList = getChangeDetailInfoListForSubStep({
          eifSubStepId,
          bills,
          deletedBills,
          changeSnapshot,
          policies: [policy],
          featureToggles,
        });
        return changeDetailInfoList;
      }}
      authUser={authUser}
      viewMode={viewMode}
      eifStepId={eifStepId}
      eifSubStepId={eifSubStepId}
      previousSubStepLink={previousSubStepLink}
      nextSubStepLink={nextSubStepLink}
      getStatus={(policy) => {
        const status = getBillingPreferencesCompletionStatus({
          policy,
          bills,
          billingSummaryStatementTemplates,
        });
        return status;
      }}
      header={
        <>
          <h2 className="mb-8">{subStepName}</h2>

          <p>Tell us how you would like to administer and structure the way you pay your bill.</p>
        </>
      }
      summary={(policy) => {
        return (
          <BillingPreferencesPolicySummary
            client={client}
            policy={policy}
            bills={bills}
            billingSummaryStatementTemplates={billingSummaryStatementTemplates}
            featureToggles={featureToggles}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            viewMode={viewMode}
            toggleableDetails={false}
            signerMode="inside"
          />
        );
      }}
      footer={null}
    />
  );
}
