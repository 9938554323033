import { InsideSignerInOutsideSignerPage } from "client/src/domain/OutsideSigner/InsideSignerInOutsideSignerPage";
import { OutsideSignerAuthenticated } from "client/src/domain/OutsideSigner/OutsideSignerAuthenticated";
import { OutsideSignerExpired } from "client/src/domain/OutsideSigner/OutsideSignerExpired";
import { OutsideSignerPageSkeleton } from "client/src/domain/OutsideSigner/OutsideSignerPageSkeleton";
import { OutsideSignerRevoked } from "client/src/domain/OutsideSigner/OutsideSignerRevoked";
import { useSlobAuth } from "client/src/hooks/auth";
import { useOutsideSignerAuth } from "client/src/hooks/outsideSigner";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

export function OutsideSignerPage() {
  // do not make outsideSignerActionId required because don't want an Oops page to show; 404 instead
  const { clientId, outsideSignerActionId } = useClientHubParams(["clientId"]);

  const { isLoading: isLoadingInsideUser, authUser: insideAuthUser } = useSlobAuth();
  const {
    authUser: outsideAuthUser,
    isLoading: isLoadingAuthOutsideUser,
    tokenStatus,
  } = useOutsideSignerAuth();

  if (isLoadingAuthOutsideUser || isLoadingInsideUser) {
    return <OutsideSignerPageSkeleton />;
  }

  if (insideAuthUser) {
    return <InsideSignerInOutsideSignerPage />;
  }

  if (!outsideAuthUser || !outsideSignerActionId) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  if (tokenStatus === "EXPIRED") {
    return <OutsideSignerExpired clientId={clientId} />;
  }

  if (tokenStatus === "REVOKED") {
    return <OutsideSignerRevoked />;
  }

  return (
    <OutsideSignerAuthenticated
      authUser={outsideAuthUser}
      clientId={clientId}
      outsideSignerActionId={outsideSignerActionId}
      tokenStatus={tokenStatus}
    />
  );
}
