import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal } from "antd";
import { TinyBadge } from "client/src/components/TinyBadge/TinyBadge";
import { useCallback, useState } from "react";

import { BROKER } from "shared/rbac/roles";
import { Button } from "../../../components/Button/Button";
import { DropdownButton } from "../../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { Loading } from "../../../components/Loading/Loading";
import { ActionMenu } from "../../../components/Menu/ActionMenu";
import { LightTable } from "../../../components/Table/LightTable";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { BrokerCreateForm } from "../../../domain/User/Form/BrokerCreateForm";
import { BrokerUpdateForm } from "../../../domain/User/Form/BrokerUpdateForm";
import { useDeleteBroker, useUnassignBrokerToClient } from "../../../hooks/user";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { ColumnsType } from "antd/es/table";
import type { Client, ClientSetupStepId } from "shared/types/Client";
import type { Broker } from "shared/types/User";

type CardBrokersProps = {
  client: Client;
  isLoading: boolean;
  brokers: Broker[] | undefined;
  brokersStatus: string;
  refetchData?: (stepId: ClientSetupStepId) => Promise<void>;
};

const isImportedUser = (client: Client, broker: Broker) => {
  const currentClient = broker.clientUser?.filter((c) => c.clientId === client.id);
  return currentClient?.some((c) => Boolean(c.importedFromClientId));
};

export const CardBrokers = ({
  client,
  brokers,
  isLoading,
  brokersStatus,
  refetchData,
}: CardBrokersProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [editState, setEditState] = useState<Broker | null>(null);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isUnassignDialogVisible, setIsUnassignDialogVisible] = useState(false);

  const { mutateAsync: deleteBroker } = useDeleteBroker();
  const { mutateAsync: unassignClient } = useUnassignBrokerToClient();

  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Client Setup - Step 1",
        buttonLabel,
        moduleState: brokersStatus,
      });
    },
    [trackElementClicked, brokersStatus],
  );

  if (isLoading) return <Loading pageHeight={false} />;

  const hasBrokers = Boolean(brokers?.length);

  const onBrokerChange = async () => {
    if (refetchData) await refetchData("ADD_BROKER");
  };

  const handleDelete = async () => {
    if (!editState) return;
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progess", 0);
    try {
      await deleteBroker({
        params: { userId: editState.id },
      });
      track("Deleted");
      void slobMessage.success("Broker deleted");
      await onBrokerChange();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleUnassignFromClient = async () => {
    if (!editState) return;
    setIsUnassignDialogVisible(false);
    if (!client.id) return;
    const hide = slobMessage.loading("Removing from client", 0);
    try {
      await unassignClient({
        data: {},
        params: { userId: editState.id, clientId: client.id },
      });
      track("Unassigned");
      void slobMessage.success("Removed from client");
      await onBrokerChange();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const columns: ColumnsType<Broker> = [
    {
      title: "Brokers",
      dataIndex: "fullName",
      key: "name",
      render: (_: string, broker: Broker) => (
        <>
          {isImportedUser(client, broker) && (
            <>
              <TinyBadge variant="gray">IMPORTED</TinyBadge>
              <br />
            </>
          )}
          {broker.fullName}
        </>
      ),
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
      render: (_: string, broker: Broker) => (
        <>
          {isImportedUser(client, broker) && <br />}
          {broker.email}
        </>
      ),
    },
    {
      title: "",
      key: "actions",
      align: "right",
      render: (_: string, broker: Broker) => (
        <Dropdown
          dropdownRender={() => (
            <ActionMenu
              items={[
                { key: "edit-broker", label: "Edit Broker" },
                { key: "delete-broker", label: "Delete Broker" },
                { key: "unassign-broker", label: "Unassign from Client" },
              ]}
              onClick={({ key }) => {
                switch (key) {
                  case "edit-broker":
                    setEditState(broker);
                    setShowUpdateModal(true);
                    track("Edit");
                    break;
                  case "delete-broker":
                    setEditState(broker);
                    setIsDeleteDialogVisible(true);
                    track("Delete");
                    break;
                  case "unassign-broker":
                    setEditState(broker);
                    setIsUnassignDialogVisible(true);
                    track("Unassign from Client");
                    break;
                }
              }}
            />
          )}
          placement="bottomRight"
        >
          <DropdownButton size="xtra-small" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <p>
        Add or assign any Broker contacts working on this Client’s implementation. Search by name
        first to see if a Broker has an existing Onboard account before adding them as a new user.
      </p>
      <p>
        <i>
          <b>Note</b>: If a Broker will sign DocuSign forms, but will not complete or want updates
          on other onboarding tasks, you should not create an Onboard account for them. Instead, set
          them as an ‘Outside Signer’ when linking the DocuSign form to Onboard.
        </i>
      </p>
      {hasBrokers && (
        <LightTable className="mb-16" rowKey={"id"} columns={columns} dataSource={brokers} />
      )}

      <ConfirmDialogDeleteUser
        role={BROKER}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={editState}
        handleDelete={handleDelete}
      />
      <ConfirmDialog
        title="Unassign from Client"
        isVisible={isUnassignDialogVisible}
        onCancel={() => setIsUnassignDialogVisible(false)}
        onConfirm={() => handleUnassignFromClient()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to unassign from a client?
      </ConfirmDialog>
      <Modal
        onCancel={() => setShowCreateModal(false)}
        destroyOnClose={true}
        open={showCreateModal}
        footer={null}
        centered={true}
      >
        <BrokerCreateForm
          client={client}
          handleClose={() => setShowCreateModal(false)}
          onChange={onBrokerChange}
          showWelcomeEmail={false}
          sendWelcomeEmail={false}
        />
      </Modal>
      {editState && (
        <Modal
          onCancel={() => setShowUpdateModal(false)}
          destroyOnClose={true}
          open={showUpdateModal}
          footer={null}
          centered={true}
        >
          <BrokerUpdateForm
            broker={editState}
            handleClose={() => setShowUpdateModal(false)}
            onChange={onBrokerChange}
          />
        </Modal>
      )}
      <Button
        onClick={() => {
          setShowCreateModal(true);
          track("Add Broker");
        }}
        type="tertiary"
        icon={<FontAwesomeIcon icon={faUserPlus} />}
      >
        Add Broker
      </Button>
    </>
  );
};
