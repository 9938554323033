import { Anchor } from "client/src/components/Anchor/Anchor";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { ReactComponent as BlueRightArrow } from "client/src/components/Icons/BlueRightArrow.svg";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3, H5 } from "client/src/components/Typography/Typography";
import {
  getIsDisabilityContributionBenefitType,
  getIsPFMLBenefitType,
  type BenefitTypeEIF,
} from "shared/types/BenefitTypes";
import { isAdditionalBenefitIncludedIn125Plan } from "shared/types/EIF";
import { is100PercentEmployerPaidForDisability } from "shared/types/EmployeeClassPlan";
import {
  getBasicBenefitsBillingPreferenceToQPSValue,
  type QPSBenefitTypeHasHighPlanMap,
  type QPSBasicClass,
  type QPSPlanInfoByBenefitCode,
  categoryCodeToBenefitType,
  planInfoSelectedBenefitCodesToBenefitType,
} from "shared/types/QPSClass";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  slfCoverageLongNameToShortName,
} from "shared/types/SlfCoverages";
import { listFormat } from "shared/utils/format";
import * as styles from "./eifPushToQPS.module.less";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { PlanInfoSelectedBenefitCodes } from "shared/types/QPSClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClasses: EmployeeClass[];
  qpsBenefitTypeLevels: QPSBenefitTypeHasHighPlanMap;
  hasERISAContacts: boolean;
  planInfoByBenefitCode: QPSPlanInfoByBenefitCode;
  qpsBasicClasses: QPSBasicClass[];
  featureToggles: ClientFeatureToggles;
  missingPFMLLegalNameAndTaxId: boolean;
  plans: Plan[];
  onNext: () => void;
};

export function EIFPushToQPSStepInitial(props: Props) {
  const {
    client,
    onNext,
    qpsBenefitTypeLevels,
    employeeClasses,
    missingPFMLLegalNameAndTaxId,
    hasERISAContacts,
    plans,
    planInfoByBenefitCode,
    featureToggles,
  } = props;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
  const benefitTypes = Object.keys(qpsBenefitTypeLevels) as BenefitTypeEIF[];

  const optionScheduleChangeMsgs = benefitTypes.map((benefitType) => {
    const hasOnboardHighPlan = employeeClasses.some(({ employeeClassPlans }) =>
      employeeClassPlans.some(
        ({ plan }) => plan.benefitType === benefitType && plan.level === "HIGH",
      ),
    );
    const hasQPSHighPlan = qpsBenefitTypeLevels[benefitType] ?? false;

    if (hasQPSHighPlan === hasOnboardHighPlan) {
      return null;
    }

    return (
      <AlertBanner
        key={benefitType}
        variant="error"
        message={
          <Body3 as="div">
            A mismatch exists between the {benefitTypeToCoverage[benefitType]} plans in Onboard and
            QPS. Onboard may not have been configured correctly based on the plans sold in QPS. You
            will need to manually update Option Changes to the correct sold plans in QPS.
          </Body3>
        }
      />
    );
  });

  const hasAverageAdditionalCompensationEarnings = employeeClasses.some(
    (employeeClass) =>
      employeeClass.earningsType === "GROSS_EARNINGS" &&
      !employeeClass.additionalCompensationsAveragedWithEarnings,
  );

  const averageAdditionalCompensationMsg = hasAverageAdditionalCompensationEarnings ? (
    <AlertBanner
      variant="error"
      message={
        <Body3 as="div">
          Average Additional Compensation With Earnings has been set to No. Please confirm this is
          correct before proceeding.
        </Body3>
      }
    />
  ) : null;

  const hasNonSupportedBasicLifePlanMsg = planInfoByBenefitCode.BASICLFE
    .hasNonSupportedBasicLifePlan ? (
    <AlertBanner
      variant="warning"
      message={
        <Body3 as="div">
          The API mapping only supports an Employee Basic Life Contract Type of 2015Life. Please
          confirm Employee Basic Life and/or AD&D plans/classes are configure correctly after
          syncing.
        </Body3>
      }
    />
  ) : null;

  const hasCustomERISAAgents =
    client.erisaHasPlan === "YES" && client.erisaPrefersPlanDetails === "YES";

  const erisaContactMsg =
    hasCustomERISAAgents && !hasERISAContacts ? (
      <AlertBanner
        variant="error"
        message={
          <Body3 as="div">
            This case contains ERISA contact data. Please follow the{" "}
            <Anchor
              target="_blank"
              href="https://sunlifefinancial.sharepoint.com/sites/Group/CH/sunlifeonboard/SitePages/Digital-Smart-Form-API-Connectivity-Dental-Vision.aspx?csf=1&web=1&e=c3gHs3#erisa-set-to-yes-1"
            >
              ERISA contact workaround
            </Anchor>{" "}
            outlined in the wiki before syncing this case via API.
          </Body3>
        }
      />
    ) : null;

  const employerPaidAndSection125Msg = getEmployerPaidAndSection125WarningMsg(
    client,
    employeeClasses,
  );

  const employerPaudAndSection125Warning = employerPaidAndSection125Msg ? (
    <AlertBanner variant="error" message={<Body3 as="div">{employerPaidAndSection125Msg}</Body3>} />
  ) : null;

  const showMissingLegalNameAndTaxIdMsg = missingPFMLLegalNameAndTaxId ? (
    <AlertBanner
      variant="error"
      message={
        <Body3 as="div">
          Please fill the Legal Name and Tax ID values in the Sold Case Document section to
          continue.
        </Body3>
      }
    />
  ) : null;

  const administrationTypeToChangeMsgs = getAdministrationTypeToChangeMsg(
    client,
    planInfoByBenefitCode,
  ).map((msg, index) => (
    <AlertBanner key={index} variant="warning" message={<Body3 as="div">{msg}</Body3>} />
  ));

  const administrationTypeWillBeTPATxt = getAdministrationChangeWillBeTPAErrorMsg(
    client,
    planInfoByBenefitCode,
  );
  const administrationTypeWillBeTPAErrorMsg = administrationTypeWillBeTPATxt ? (
    <AlertBanner
      variant="error"
      message={<Body3 as="div">{administrationTypeWillBeTPATxt}</Body3>}
    />
  ) : null;

  const hasClassMappingOptions = employeeClasses.length > 0;
  const hasNonClassMappingOptions =
    Boolean(featureToggles.ONBOARD_API_PFML) &&
    plans.some((plan) => getIsPFMLBenefitType(plan.benefitType));

  const showNextButton =
    (hasClassMappingOptions || hasNonClassMappingOptions) &&
    !administrationTypeWillBeTPATxt &&
    !missingPFMLLegalNameAndTaxId;

  return (
    <>
      <div className="my-64 stack-y-20">
        <h1>Send digital smart form to QPS</h1>

        <p>
          By sending the digital smart form to QPS, you will be overwriting the company information
          in QPS with the information that has been entered in Onboard for this case.
        </p>

        <p>Please confirm the client and case number are correct before you continue.</p>

        <HubCard>
          <Row>
            <Col span={10}>
              <H5 as="p">Onboard Client</H5>

              <Body3>{client.name}</Body3>
            </Col>
            <Col span={1}>
              <BlueRightArrow />
            </Col>
            <Col span={2} className={styles.middleColumn}>
              <div className={styles.verticalLine} />
            </Col>
            <Col offset={1} span={10}>
              <H5 as="p">QPS Case</H5>

              <Body3>{client.caseId}</Body3>
            </Col>
          </Row>
        </HubCard>

        <StackY dist={16} className="hide:empty">
          <>
            {optionScheduleChangeMsgs}
            {erisaContactMsg}
            {averageAdditionalCompensationMsg}
            {hasNonSupportedBasicLifePlanMsg}
            {administrationTypeToChangeMsgs}
            {employerPaudAndSection125Warning}
            {administrationTypeWillBeTPAErrorMsg}
            {showMissingLegalNameAndTaxIdMsg}
          </>
        </StackY>

        {showNextButton && (
          <Row justify="end" className="mt-32 mb-48">
            <Col>
              <Button onClick={onNext} type="primary" size="large">
                Next
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
}

const benefitCodeToFriendlyLabel: Record<PlanInfoSelectedBenefitCodes, string> = {
  BASICLFE: "EE Basic Life",
  BASICLTD: "Basic LTD",
  BASICSTD: "Basic STD",
};

const getAdministrationTypeToChangeMsg = (
  client: Client,
  planInfoByBenefitCode: QPSPlanInfoByBenefitCode,
) => {
  const warningMsgs = [];

  for (const _benefitCode of Object.keys(planInfoByBenefitCode)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- missed type from Object.keys
    const benefitCode = _benefitCode as keyof QPSPlanInfoByBenefitCode;

    const relatedPolicy =
      client.policies.find(({ slfCoverages }) =>
        (slfCoverages || []).some((slfCoverage) => {
          const benefitType = coverageToBenefitType[slfCoverage];
          const qpsRelatedBenefitTypes = categoryCodeToBenefitType[benefitCode];
          return qpsRelatedBenefitTypes.includes(benefitType);
        }),
      ) ?? null;

    if (!relatedPolicy?.billingAdministrationType) {
      continue;
    }

    const onboardAdministrationType = getBasicBenefitsBillingPreferenceToQPSValue(
      relatedPolicy.billingAdministrationType,
      relatedPolicy.dataFeeds,
    ).text;
    const qpsAdministationType = planInfoByBenefitCode[benefitCode].administrationType;

    if (qpsAdministationType != null && onboardAdministrationType !== qpsAdministationType) {
      warningMsgs.push(
        `After syncing this client, the Administration Type for ${benefitCodeToFriendlyLabel[benefitCode]} will be updated from ${qpsAdministationType} to ${onboardAdministrationType}. Please confirm this change has been approved`,
      );
    }
  }

  return warningMsgs;
};

const getEmployerPaidAndSection125WarningMsg = (
  client: Client,
  employeeClasses: EmployeeClass[],
) => {
  const selectedBenefitTypes: BenefitTypeEIF[] = [];
  for (const employeeClass of employeeClasses) {
    for (const employeeClassPlan of employeeClass.employeeClassPlans) {
      const { plan, employerContribution, taxChoice } = employeeClassPlan;
      const isDisabilityBenefitType = getIsDisabilityContributionBenefitType(plan.benefitType);
      const is100EmployerPaid = is100PercentEmployerPaidForDisability(
        employerContribution,
        taxChoice,
      );
      const hasSection125 = isAdditionalBenefitIncludedIn125Plan(client, plan.benefitType);

      if (isDisabilityBenefitType && is100EmployerPaid && hasSection125) {
        selectedBenefitTypes.push(plan.benefitType);
      }
    }
  }

  const displayNames = selectedBenefitTypes.map(
    (benefitType) => slfCoverageLongNameToShortName[benefitTypeToCoverage[benefitType]],
  );
  const msg = `Section 125 should be set to No if Employees are not contributing or have a Gross Up plan. Please set the Section 125 value to "No" in QPS for ${listFormat(
    displayNames,
  )}.`;

  return displayNames.length ? msg : null;
};

const getAdministrationChangeWillBeTPAErrorMsg = (
  client: Client,
  planInfoByBenefitCode: QPSPlanInfoByBenefitCode,
) => {
  const selectedBenefitTypes: BenefitTypeEIF[] = [];
  for (const _benefitCode of Object.keys(planInfoByBenefitCode)) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- missed type from Object.keys
    const benefitCode = _benefitCode as keyof QPSPlanInfoByBenefitCode;
    const benefitType = planInfoSelectedBenefitCodesToBenefitType[benefitCode];

    const relatedPolicy =
      client.policies.find(({ slfCoverages }) =>
        (slfCoverages || []).some((slfCoverage) => {
          const benefitType = coverageToBenefitType[slfCoverage];
          const qpsRelatedBenefitTypes = categoryCodeToBenefitType[benefitCode];
          return qpsRelatedBenefitTypes.includes(benefitType);
        }),
      ) ?? null;

    if (!relatedPolicy?.billingAdministrationType) {
      continue;
    }
    const qpsAdministationType = planInfoByBenefitCode[benefitCode].administrationType;

    if (
      qpsAdministationType != null &&
      !["TPA", "TPA+"].includes(qpsAdministationType) &&
      relatedPolicy.billingAdministrationType === "TPA"
    ) {
      selectedBenefitTypes.push(benefitType);
    }
  }

  const displayNames = selectedBenefitTypes.map(
    (benefitType) => slfCoverageLongNameToShortName[benefitTypeToCoverage[benefitType]],
  );
  const msg = `Before syncing, update QPS with the Administration Type TPA and add the TPA Allowance for ${listFormat(
    displayNames,
  )}. If TPA Allowance percentage was not provided, enter Zero (0).`;

  return displayNames.length ? msg : null;
};
