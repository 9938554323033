import { EIFPushToQPSStepClassMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepClassMapping";
import { EIFPushToQPSStepInitial } from "client/src/domain/EIF/QPS/EIFPushToQPSStepInitial";
import { EIFPushToQPSStepPlanMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepPlanMapping";
import { EIFPushToQPSStepReview } from "client/src/domain/EIF/QPS/EIFPushToQPSStepReview";
import { useState } from "react";
import { getIsPFMLBenefitType } from "shared/types/BenefitTypes";
import { buildEmployeeClassesToExport, type EmployeeClass } from "shared/types/EmployeeClass";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import type { AssignBenefitsToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepClassMapping";
import type { AssignPlansToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStepPlanMapping";
import type { MapAndSyncQPSClassesFunc } from "client/src/hooks/qps";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";
import type { QPSPlanDesignInfo } from "shared/types/QPSClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClasses: EmployeeClass[];
  qpsPlanDesignInfo: QPSPlanDesignInfo;
  authUser: UserData;
  plans: Plan[];
  featureToggles: ClientFeatureToggles;
  onGoToReview?: (mapping: AssignBenefitsToAdminClassesMapping[]) => void;
  mapAndSyncQPSClasses: MapAndSyncQPSClassesFunc;
  isPendingMapAndSyncQPSClasses: boolean;
};

type PushToQPSStep = "initial" | "class-mapping" | "plan-mapping" | "review";

export function EIFPushToQPSPageContentsV2(props: Props) {
  const {
    client,
    employeeClasses,
    qpsPlanDesignInfo,
    authUser,
    plans,
    featureToggles,
    onGoToReview,
    isPendingMapAndSyncQPSClasses,
    mapAndSyncQPSClasses,
  } = props;

  const [employeeClassIndex, setEmployeeClassIndex] = useState<number>(0);
  const [step, goToStep] = useState<PushToQPSStep>("initial");
  const [classMapping, setClassMapping] = useState<AssignBenefitsToAdminClassesMapping[]>([]);
  const [planMapping, setPlanMapping] = useState<AssignPlansToAdminClassesMapping | null>(null);

  const employeeClassesToExport = buildEmployeeClassesToExport(
    client,
    employeeClasses,
    qpsPlanDesignInfo.basicClasses,
  );

  const hasClassMappingOptions = employeeClasses.length > 0;
  const hasNonClassMappingOptions =
    Boolean(featureToggles.ONBOARD_API_PFML) &&
    plans.some((plan) => getIsPFMLBenefitType(plan.benefitType));

  const getPreviousStep = () => {
    switch (step) {
      case "initial":
        return null;
      case "class-mapping":
        return "initial";
      case "plan-mapping":
        return hasClassMappingOptions ? "class-mapping" : "initial";
      case "review":
        return hasNonClassMappingOptions ? "plan-mapping" : "class-mapping";
      default:
        exhaustiveCheckFail(step);
    }
  };

  const getNextStep = () => {
    switch (step) {
      case "initial":
        return hasClassMappingOptions ? "class-mapping" : "plan-mapping";
      case "class-mapping":
        return hasNonClassMappingOptions ? "plan-mapping" : "review";
      case "plan-mapping":
        return "review";
      case "review":
        return null;
      default:
        exhaustiveCheckFail(step);
    }
  };

  const onPreviousCallback = () => {
    const previousStep = getPreviousStep();
    if (previousStep) {
      goToStep(previousStep);
    }
  };

  const onNextCallback = (classMapping?: AssignBenefitsToAdminClassesMapping[]) => {
    const nextStep = getNextStep();
    if (nextStep) {
      goToStep(nextStep);
      if (nextStep === "review" && classMapping) {
        onGoToReview?.(classMapping);
      }
    }
  };

  return (
    <>
      {step === "initial" && (
        <EIFPushToQPSStepInitial
          client={client}
          onNext={onNextCallback}
          employeeClasses={employeeClassesToExport}
          plans={plans}
          featureToggles={featureToggles}
          qpsBenefitTypeLevels={qpsPlanDesignInfo.benefitTypeLevels}
          hasERISAContacts={qpsPlanDesignInfo.hasERISAContacts}
          planInfoByBenefitCode={qpsPlanDesignInfo.planInfoByBenefitCode}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          missingPFMLLegalNameAndTaxId={qpsPlanDesignInfo.missingPFMLLegalNameAndTaxId ?? false}
        />
      )}

      {step === "class-mapping" && (
        <EIFPushToQPSStepClassMapping
          client={client}
          authUser={authUser}
          mapping={classMapping}
          setMapping={setClassMapping}
          employeeClasses={employeeClassesToExport}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          featureToggles={featureToggles}
          onPrevious={onPreviousCallback}
          onNext={(mapping) => {
            setClassMapping(mapping);
            onNextCallback(mapping);
          }}
          employeeClassIndex={employeeClassIndex}
          setEmployeeClassIndex={setEmployeeClassIndex}
        />
      )}

      {step === "plan-mapping" && (
        <EIFPushToQPSStepPlanMapping
          client={client}
          mapping={planMapping}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          featureToggles={featureToggles}
          onPrevious={onPreviousCallback}
          onNext={(mapping) => {
            setPlanMapping(mapping);
            onNextCallback();
          }}
          plans={plans}
        />
      )}

      {step === "review" && (
        <EIFPushToQPSStepReview
          client={client}
          authUser={authUser}
          plans={plans}
          classMapping={classMapping}
          planMapping={planMapping}
          onPrevious={onPreviousCallback}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          employeeClasses={employeeClassesToExport}
          mapAndSyncQPSClasses={mapAndSyncQPSClasses}
          isPendingMapAndSyncQPSClasses={isPendingMapAndSyncQPSClasses}
          setEmployeeClassIndex={setEmployeeClassIndex}
        />
      )}
    </>
  );
}
