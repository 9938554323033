import { Fragment } from "react";

import { includesCaseInsensitive } from "shared/utils/utils";
import { Anchor } from "../../../components/Anchor/Anchor";
import { useHubConfiguration } from "../../../hooks/useConfig";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { ElementClickedOptions } from "../../../utils/analytics";
import type { Client } from "shared/types/Client";

type CardJiraProps = {
  client: Client;
  status: string;
};

type JiraLinkBtnProps = {
  ticketId: string;
  url: string;
  trackElementClicked: (o: ElementClickedOptions) => void;
  status: string;
};

const JiraLinkBtn = ({ ticketId, url, trackElementClicked, status }: JiraLinkBtnProps) => {
  const jiraLink = `${url}/browse/${ticketId}`;
  return (
    <Anchor
      href={jiraLink}
      target="_blank"
      newTabIcon
      onClick={() => {
        trackElementClicked({
          buttonLabel: jiraLink,
          module: "Client Setup - Step 6",
          moduleState: status,
        });
      }}
    >
      view {ticketId}
    </Anchor>
  );
};

const visibleStatuses = [
  "Prepare Sold Case Paperwork",
  "NIGO - with Intake",
  "New Case Assigned",
  "On Hold at Welcome",
];

export const CardJira = ({ client, status }: CardJiraProps) => {
  const trackElementClicked = useTrackElementClicked(client);
  const config = useHubConfiguration();

  const policies = client.policies.filter((policy) =>
    includesCaseInsensitive(policy.ticketStatus || "", visibleStatuses),
  );

  return (
    <>
      <p>
        Confirm all the Jira field information entered for this Client is correct and move the
        status of all associated Jira tickets listed below into ‘Info Gathering’. This is required
        for Onboard to open up the paperwork and census tasks for the Client.
      </p>
      {policies.length > 0 && (
        <p>
          The following Jira tickets can be updated now:{" "}
          {policies.map((policy, index) => (
            <Fragment key={index}>
              {Boolean(index) && ", "}
              <JiraLinkBtn
                ticketId={policy.ticketId}
                url={config.REACT_APP_JIRA_URL}
                trackElementClicked={trackElementClicked}
                status={status}
              />
            </Fragment>
          ))}
        </p>
      )}
      <p>
        Note: You may need to wait a few moments and refresh the page to see this step show as
        ‘Completed’ after you update the Jira status.
      </p>
    </>
  );
};
