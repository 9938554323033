import { rejectNullableValues } from "shared/utils/utils";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EmployeeClassId } from "shared/types/EmployeeClass";

export function getBenefitChangeDetailInfoList(
  changeSnapshot: DEIFChangeSnapshot,
  employeeClassId: EmployeeClassId | undefined,
) {
  if (employeeClassId == null) {
    return [];
  }

  const benefitChangeDetailInfoList = Object.keys(changeSnapshot.EmployeeClassPlan ?? {})
    .filter((key) => changeSnapshot.EmployeeClassPlan[key]?.metadata?.parentId === employeeClassId)
    .flatMap((key) => [
      changeSnapshot.EmployeeClassPlan[key]?.createdBy,
      changeSnapshot.EmployeeClassPlan[key]?.deletedAt,
    ])
    .filter(rejectNullableValues);

  return benefitChangeDetailInfoList;
}
