import clsx from "clsx";

import * as styles from "./ChildPageContactBlock.module.less";

export type ChildPageContactBlockProps = {
  email?: string;
  phoneNumber?: string;
};

export function ChildPageContactBlock({ email, phoneNumber }: ChildPageContactBlockProps) {
  return (
    <div className={styles.container}>
      {phoneNumber && <p className={clsx("body2", styles.contact)}>{phoneNumber}</p>}
      {email && <p className={clsx("body2", styles.contact)}>{email}</p>}
    </div>
  );
}
