import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { rejectNullableValues } from "shared/utils/utils";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { Client } from "shared/types/Client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export function getCoverageWithPolicyNumbersLabel(args: {
  client: Client;
  slfCoverage: SlfCoverageLongName;
}): string;
export function getCoverageWithPolicyNumbersLabel(args: {
  client: Client;
  benefitType: BenefitTypeEIF;
}): string;
export function getCoverageWithPolicyNumbersLabel(
  args:
    | {
        client: Client;
        slfCoverage: SlfCoverageLongName;
      }
    | {
        client: Client;
        benefitType: BenefitTypeEIF;
      },
) {
  const { client } = args;

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const slfCoverage =
    "slfCoverage" in args ? args.slfCoverage : benefitTypeToCoverage[args.benefitType];

  const policyNumbers = isMultiPolicyMode
    ? client.policies
        .filter((p) => p.slfCoverages?.includes(slfCoverage))
        .map((p) => p.slfPolicyNumber || null)
        .filter(rejectNullableValues)
        .map((slfPolicyNumber) => "#" + slfPolicyNumber)
    : undefined;

  const label =
    policyNumbers && policyNumbers.length > 0
      ? `${slfCoverage} (${policyNumbers.join(", ")})`
      : slfCoverage;

  return label;
}
