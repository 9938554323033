import { HubCard } from "../HubCard/HubCard";

import * as styles from "./employeeEnrollmentResourcesCardLayout.module.less";

interface Props {
  children: React.ReactNode;
  bgSrc: string;
  bgSrcSet?: string;
}

export const EmployeeEnrollmentResourcesCardLayout = ({ children, bgSrc, bgSrcSet }: Props) => {
  return (
    <HubCard padding="none">
      <img
        alt="Enrollment Resources Banner"
        src={bgSrc}
        srcSet={bgSrcSet}
        className={styles.cardBackground}
        height={406}
        width={224}
        aria-hidden={true}
      />

      <div className={styles.cardContentContainer}>{children}</div>
    </HubCard>
  );
};
