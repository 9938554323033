import { useState } from "react";
import { SlfInternalUpdateValidationSchema } from "shared/validation/user";

import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useUpdateSlfInternal } from "../../../hooks/user";

import { SlfInternalUpdateForm } from "./SlfInternalUpdateForm";

import type { SlfInternal } from "shared/types/User";

type SlfInternalUpdateProps = {
  handleClose: () => void;
  slfInternal: SlfInternal;
};

export const SlfInternalUpdate = ({ handleClose, slfInternal }: SlfInternalUpdateProps) => {
  const { mutateAsync: updateUser } = useUpdateSlfInternal();
  const [clientError, setClientError] = useState("");

  const formik = useSlobFormik({
    initialValues: slfInternal,
    validateOnBlur: true,
    validationSchema: SlfInternalUpdateValidationSchema,
    async onSubmit(values) {
      try {
        setClientError("");
        await updateUser({
          data: values,
          params: { userId: slfInternal.id },
        });
        handleClose();
        void slobMessage.success("Successfully changed user");
      } catch (error) {
        const responseError = ResponseError.getUserFacingErrorMessage(
          error,
          "Problem updating user",
        );
        setClientError(responseError);
      }
    },
  });

  return (
    <SlfInternalUpdateForm formik={formik} clientError={clientError} handleClose={handleClose} />
  );
};
