import type { WhoSubmitsClaims } from "@prisma/client";
import type { ValueOf } from "shared/types/Helper";

export const PhaseIdValues = [
  "WELCOME",
  "INFO_GATHERING",
  "REVIEW_AND_VALIDATE",
  "PLAN_APPROVAL",
  "CONTRACTS_AND_BILLING",
  "ONBOARDING_COMPLETE",
  "REJECTED",
] as const;

export const FrontLoadingValues = ["YES_COMPLETED", "YES_REQUESTED", "YES_REJECTED", "NO"] as const;

export type PhaseId = ValueOf<typeof PhaseIdValues>;

export const DentalRolloverValues = ["PREVENTIVE_REWARDS", "ROLLMAX"] as const;

const WhoSubmitsClaimsRecord: Record<WhoSubmitsClaims, WhoSubmitsClaims> = {
  PRIMARY_PLAN_ADMINISTRATOR: "PRIMARY_PLAN_ADMINISTRATOR",
  THIRD_PARTY_ADMINISTRATOR: "THIRD_PARTY_ADMINISTRATOR",
};

export const WhoSubmitsClaimsValues = Object.values(WhoSubmitsClaimsRecord);
