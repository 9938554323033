export const compareTicketStatus =
  <T extends string = "Please provide the type explicitly">(value: string | null) =>
  (arr: T[] | null) =>
    arr
      ? arr.some(
          (name) =>
            name.toLowerCase() === value?.toLowerCase() ||
            // Accept an empty string or undefined when 'none' is expected
            (name.toLowerCase() === "none" && !value),
        )
      : value === null;
