import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { StackX, StackY } from "../Spacing/Spacing";

import type { StackProps } from "../Spacing/Spacing";
import type { ReactNode } from "react";

export interface ConfirmDialogProps {
  isVisible: boolean;
  isLoading?: boolean;
  title?: string;
  confirmActionText?: string;
  cancelActionText?: string;
  onConfirm: (() => Promise<void>) | (() => void);
  onCancel: (() => Promise<void>) | (() => void);
  children: ReactNode;
}

export const ConfirmDialogDataTestId = {
  content: "confirm-modal-content",
};
export const ConfirmDialog = ({
  isVisible,
  isLoading,
  title,
  confirmActionText,
  cancelActionText = "Cancel",
  onConfirm,
  onCancel,
  children,
}: ConfirmDialogProps) => {
  return (
    <Modal
      title={title}
      open={isVisible}
      footer={null}
      onCancel={onCancel}
      focusTriggerAfterClose={false}
      disableClose={isLoading}
      destroyOnClose
    >
      <ConfirmDialogContent
        isLoading={isLoading}
        confirmActionText={confirmActionText}
        cancelActionText={cancelActionText}
        onConfirm={onConfirm}
        onCancel={onCancel}
        children={children}
      />
    </Modal>
  );
};

interface ConfirmDialogContentProps {
  isLoading?: boolean;
  confirmActionText?: string;
  cancelActionText?: string;
  onConfirm: (() => Promise<void>) | (() => void);
  onCancel: (() => Promise<void>) | (() => void);
  children: ReactNode;
}

export const ConfirmDialogContent = ({
  isLoading,
  confirmActionText,
  cancelActionText = "Cancel",
  onConfirm,
  onCancel,
  children,
}: ConfirmDialogContentProps) => {
  const isMobile = !useBreakpoint().md;
  const Stack = (props: StackProps) =>
    isMobile ? <StackY {...props}></StackY> : <StackX {...props}></StackX>;

  return (
    <div data-testid={ConfirmDialogDataTestId.content}>
      <div className="mb-24 body1">{children}</div>
      <Stack dist={16}>
        {confirmActionText && (
          <Button type="primary" size="middle" onClick={onConfirm} loading={isLoading}>
            {confirmActionText}
          </Button>
        )}

        <Button type="default" size="middle" onClick={onCancel} disabled={isLoading}>
          {cancelActionText}
        </Button>
      </Stack>
    </div>
  );
};
