import { useCallback, useMemo } from "react";

import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { getLastTouched } from "../../../../../shared/utils/getLastTouched";
import { Badge, taskStatusToLabel, taskStatusToVariant } from "../../../components/Badge/Badge";
import { PopoverBadge } from "../../../components/Badge/PopoverBadge";
import { Button } from "../../../components/Button/Button";
import { GuidanceModal } from "../../../components/GuidanceModal/GuidanceModal";
import { HorizontalDivider } from "../../../components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { useSlobAuth } from "../../../hooks/auth";
import { useGetLastTouchedDocument, useGetDocumentsForUser } from "../../../hooks/document";
import { querySuccess } from "../../../hooks/query";
import { useToggler } from "../../../hooks/useToggler";
import {
  DocumentDownload,
  DocumentDownloadV2,
  useDocumentDownload,
} from "../DocumentDownload/DocumentDownload";
import { DocumentUpload } from "../DocumentUpload/DocumentUpload";
import { StoredSecurelyText } from "../StoredSecurelyText";

import type { ElementClickedOptions } from "../../../utils/analytics";
import type { ResourceRowData } from "../DownloadFileTable/DownloadFileTable";
import type { ReactNode } from "react";
import type { TaskId, TaskStatus } from "shared/data/Tasks";
import type { ClientId, Policy, PolicyId } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

type AddFilesCardProps = {
  clientId: ClientId;
  taskStatus: TaskStatus;
  taskId?: TaskId;
  category: DocumentCategory;
  policyId?: PolicyId;
  policies?: Policy[];
  multiPolicyFiles?: boolean;
  separateByProcessStatus?: boolean;
  title: ReactNode;
  subTitle?: ReactNode;
  modalTitle?: string;
  buttonDisabled?: boolean;
  buttonText?: string;
  hideTable?: boolean;
  message: string;
  progressMessage?: string;
  actionRequiredMessage: ReactNode;
  description?: ReactNode;
  bottomDescription?: ReactNode;
  footer?: (toggleUploaderVisible: () => void, track: (buttonLabel: string) => void) => ReactNode;
  hideButton?: boolean;
  hideRule?: boolean;
  header?: ReactNode;
  trackElementClicked: (options: ElementClickedOptions) => void;
  taskCompletedTitle: string;
  remainingTasks: boolean;
  readonly?: boolean;
  singleFile?: boolean;
  infoMessage?: string;
  uploadLink?: string;
  downloadExpandedRowRender?: (record: ResourceRowData) => ReactNode;
  withSource?: boolean;
  showDownloadButton?: boolean;
  showDownloadAllButton?: boolean;
};

export function AddFilesCard({
  clientId,
  taskStatus,
  taskId,
  category,
  policyId,
  policies,
  multiPolicyFiles = false,
  separateByProcessStatus = false,
  title,
  subTitle,
  modalTitle,
  buttonDisabled,
  buttonText,
  hideTable,
  message,
  progressMessage,
  actionRequiredMessage,
  description,
  bottomDescription,
  footer,
  hideButton = false,
  hideRule = false,
  header,
  trackElementClicked,
  taskCompletedTitle,
  remainingTasks,
  readonly,
  singleFile,
  infoMessage,
  uploadLink,
  downloadExpandedRowRender,
  showDownloadButton,
  showDownloadAllButton = false,
  withSource = false,
}: AddFilesCardProps) {
  const variant = taskStatus ? taskStatusToVariant[taskStatus] : "info";
  const statusLabel = taskStatus ? taskStatusToLabel[taskStatus] : "Not Started";
  const badgeMessage =
    taskStatus === "In Review"
      ? message
      : taskStatus === "In Progress"
      ? progressMessage
      : taskStatus === "NIGO"
      ? actionRequiredMessage
      : "";

  const uploadButtonText = buttonText
    ? buttonText
    : taskStatus === "In Progress"
    ? "Continue"
    : "Upload Files";

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const [uploaderVisible, toggleUploaderVisible] = useToggler();
  const [guidanceModalVisible, toggleGuidanceModalVisible] = useToggler();

  const { isFetching, error, data } = useGetLastTouchedDocument(clientId, category, policyId);
  const lastTouched = data ? getLastTouched(data) : null;

  const {
    isFetching: isFetchingDocuments,
    error: documentsError,
    data: documents,
    refetch,
  } = useGetDocumentsForUser(clientId, category, authUser?.id ?? "", policyId);

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: statusLabel,
        buttonLabel,
      });
    },
    [trackElementClicked, statusLabel],
  );

  const documentDownloadData = useDocumentDownload({
    clientId,
    policyId,
    categories: ["enrollment-elections"],
    track,
    taskStatus,
    withSource,
    showDownloadButton,
    showDownloadAllButton,
    showProcessStatusValidation: true,
  });

  const groupDocumentsBySubmittedStatus = useMemo(() => {
    const submittedStatuses = [
      "SUBMITTED",
      "SUBMITTED_WITH_ERRORS",
      "SUBMITTED_WITH_WARNINGS",
      "SUBMITTED_WITH_ERRORS_AND_WARNINGS",
      "SUBMITTED_WITH_FAILURES",
    ];
    const submitted = [];
    const unsubmitted = [];
    for (const document of documentDownloadData.documents.data ?? []) {
      if (document.processStatus && submittedStatuses.includes(document.processStatus)) {
        submitted.push(document);
      } else {
        unsubmitted.push(document);
      }
    }

    return {
      submitted,
      unsubmitted,
    };
  }, [documentDownloadData.documents.data]);

  const uploadClick = useCallback(() => {
    track("Upload files");
    toggleUploaderVisible();
  }, [track, toggleUploaderVisible]);

  const closeModal = async () => {
    toggleUploaderVisible();
    // Show guidance modal the first time each user uploads a document
    if (!isFetchingDocuments && !documentsError && documents?.length === 0) {
      const updatedDocuments = await refetch();
      if (updatedDocuments?.length && updatedDocuments.length > 0) toggleGuidanceModalVisible();
    }
  };

  const submittedDocs = querySuccess(
    groupDocumentsBySubmittedStatus.submitted,
    documentDownloadData.documents,
  );

  const unsubmittedDocs = querySuccess(
    groupDocumentsBySubmittedStatus.unsubmitted,
    documentDownloadData.documents,
  );

  return (
    <HubCard header={header}>
      <StackY dist={16}>
        {taskStatus &&
          (statusLabel === "Not Started" && !isFetching && !error && !lastTouched ? (
            <Badge
              srOnlyLabel="Task Status"
              variant={variant}
              status={statusLabel}
              message={badgeMessage}
            />
          ) : (
            <PopoverBadge
              aria-label="Task Status"
              variant={variant}
              status={statusLabel}
              loading={isFetching}
              error={error}
              updatedBy={lastTouched?.lastTouchedByUser}
              updatedAt={lastTouched?.lastTouchedAt}
              message={badgeMessage}
            />
          ))}

        {title}
        {subTitle && <p>{subTitle}</p>}
      </StackY>

      {!hideRule && <HorizontalDivider />}

      {!isInternalUser && (
        <GuidanceModal
          remainingTasks={remainingTasks}
          clientId={clientId}
          policyId={policyId}
          taskId={taskId}
          isVisible={guidanceModalVisible}
          nextText={message}
          policies={policies}
          onCancel={toggleGuidanceModalVisible}
          showSecondaryButton={true}
          badgeStatus={statusLabel}
          badgeVariant={variant}
          titleText={taskCompletedTitle}
          trackElementClicked={trackElementClicked}
        />
      )}

      <StackY dist={24}>
        <div>
          <StackY dist={32}>
            {description}

            {!hideTable && !separateByProcessStatus && (
              <DocumentDownload
                clientId={clientId}
                categories={[category]}
                policyId={policyId}
                track={track}
                taskStatus={taskStatus}
                readonly={readonly}
                withSource={withSource}
                empty={""}
              />
            )}

            {!hideTable && separateByProcessStatus && (
              <>
                {Boolean(submittedDocs?.data?.length) && (
                  <>
                    <h5>Submitted</h5>
                    <DocumentDownloadV2
                      {...documentDownloadData}
                      documents={submittedDocs}
                      downloadExpandedRowRender={downloadExpandedRowRender}
                    />
                    {Boolean(unsubmittedDocs?.data?.length) && <hr />}
                  </>
                )}
                {Boolean(unsubmittedDocs?.data?.length) && (
                  <>
                    <h5>Not Yet Submitted</h5>
                    <DocumentDownloadV2
                      {...documentDownloadData}
                      documents={unsubmittedDocs}
                      downloadExpandedRowRender={downloadExpandedRowRender}
                    />
                  </>
                )}
              </>
            )}

            {bottomDescription}

            {!hideButton && !uploadLink && (
              <StackX dist={24}>
                <Button
                  type="primary"
                  onClick={uploadClick}
                  disabled={buttonDisabled}
                  size="middle"
                >
                  {singleFile ? "Secure Upload" : "Upload files"}
                </Button>

                <StoredSecurelyText track={track} />
              </StackX>
            )}
            {uploadLink && (
              <StackX dist={24}>
                <Button type="primary" size="middle" to={uploadLink}>
                  {uploadButtonText}
                </Button>

                <StoredSecurelyText track={track} />
              </StackX>
            )}
          </StackY>
        </div>

        {footer?.(toggleUploaderVisible, track)}
      </StackY>

      <DocumentUpload
        modalTitle={modalTitle}
        visible={uploaderVisible}
        closeModal={closeModal}
        clientId={clientId}
        category={category}
        policyId={policyId}
        policies={policies}
        multiPolicyFiles={multiPolicyFiles}
        track={track}
        singleFile={singleFile}
        infoMessage={infoMessage}
      />
    </HubCard>
  );
}
