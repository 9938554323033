import { PlanTypes } from "shared/types/ExplorerPageBenefit";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { PlanType } from "shared/types/ExplorerPageBenefit";

export type PlanTableConfig = {
  rowCount: number;
  supportedPlans: ReadonlyArray<PlanType | "FSA_ROLLOVER" | "DCA_ROLLOVER">;
  hasNoPlanColumn: boolean;
  allowNullPlanType: boolean;
};

export const configs: {
  [key in BenefitTypeBenEx]?: PlanTableConfig;
} = {
  MEDICAL: {
    rowCount: 9,
    supportedPlans: ["HMO", "PPO", "EPO", "POS"],
    hasNoPlanColumn: false,
    allowNullPlanType: false,
  },
  DENTAL: {
    rowCount: 7,
    supportedPlans: ["PPO", "DHMO"],
    hasNoPlanColumn: true,
    allowNullPlanType: false,
  },
  VISION: {
    rowCount: 6,
    supportedPlans: [],
    hasNoPlanColumn: true,
    allowNullPlanType: true,
  },
  ACCIDENT: { rowCount: 0, supportedPlans: [], hasNoPlanColumn: false, allowNullPlanType: false },
  FINANCIAL: {
    rowCount: 8,
    supportedPlans: ["HSA", "FSA", "FSA_ROLLOVER", "LPFSA", "HRA", "DCA", "DCA_ROLLOVER"],
    hasNoPlanColumn: false,
    allowNullPlanType: false,
  },
};

const defaultConfig: PlanTableConfig = {
  rowCount: 6,
  supportedPlans: PlanTypes,
  hasNoPlanColumn: true,
  allowNullPlanType: false,
};

/**
 * Get the plan table configuration for a given benefit type.
 */
export function usePlanTableConfig(benefitType: BenefitTypeBenEx): PlanTableConfig {
  return configs[benefitType] ?? defaultConfig;
}
