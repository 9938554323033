import { PlusCircleOutlined } from "@ant-design/icons";
import { Dropdown as AntdDropdown, Menu } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import pluralize from "pluralize";
import React, { useEffect, useRef, useMemo } from "react";
import { documentCategorySorter } from "shared/utils/document";
import { benefitTypesValidationSchema } from "shared/validation/document";
import {
  webLinkCategoryValidationSchema,
  webLinkTextValidationSchema,
  webLinkUrlValidationSchema,
} from "shared/validation/webLink";
import * as Yup from "yup";

import {
  categoryNameByCategoryType,
  EnrollmentResourcesCategoriesNoVDR,
  isDocumentCategory,
} from "../../../../shared/types/Document";
import {
  documentBenefitTypes,
  documentBenefitTypesNames,
  isDocumentBenefitType,
} from "../../../../shared/types/DocumentBenefitType";
import {
  WebLinkCategories,
  webLinkCategoryNameByCategoryType,
} from "../../../../shared/types/WebLink";
import { Button } from "../../components/Button/Button";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { Checkbox } from "../../components/Form/Checkbox";
import { FormInput } from "../../components/Form/Input";
import { MenuWithCheckboxes } from "../../components/Form/MenuWithCheckboxes/MenuWithCheckboxes";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { TrashIcon } from "../../components/Icons/TrashIcon";
import { Modal } from "../../components/Modal/Modal";
import { Body3 } from "../../components/Typography/Typography";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../hooks/useSlobFormik";
import { useCreateWebLinks } from "../../hooks/webLink";

import * as styles from "./webLinksUpload.module.less";

import type { ClientId } from "../../../../shared/types/Client";
import type { DocumentCategory } from "../../../../shared/types/Document";
import type { DocumentBenefitType } from "../../../../shared/types/DocumentBenefitType";
import type { WebLinkCategory, WebLink } from "../../../../shared/types/WebLink";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { ValuesForValidationSchema } from "shared/types/Helper";

const key = () => Math.random().toString(36).slice(2);

function webLinkHasValue(weblink: { text: string; url: string }): boolean {
  return Boolean(weblink.text || weblink.url);
}

const validationSchema = Yup.object({
  webLinks: Yup.array()
    .of(
      Yup.object({
        key: Yup.string().required(),
        text: webLinkTextValidationSchema,
        url: webLinkUrlValidationSchema,
        category: webLinkCategoryValidationSchema.nullable(),
        benefitTypes: benefitTypesValidationSchema.nullable(),
        checked: Yup.boolean().required(),
      }),
    )
    .test("unique", "Multiple links cannot share the same text.", (list) => {
      const texts = list?.map((i) => i.text);
      return list?.length === new Set(texts).size;
    })
    .required(),
});

type WebLinkFormValues = ValuesForValidationSchema<typeof validationSchema>["webLinks"][0];

const getEmptyLink = (category: WebLinkCategory | null): WebLinkFormValues => ({
  key: key(),
  url: "https://",
  text: "",
  category,
  benefitTypes: [],
  checked: false,
});

type CreateLinksFunction = (params: {
  data: {
    webLinks: Pick<WebLink, "text" | "url" | "category" | "benefitTypes">[];
  };
  params: {
    clientId: ClientId;
  };
}) => Promise<{ isSuccess: boolean }>;

type WebLinksUploadPresentationProps = {
  visible: boolean;
  handleClose: () => void;
  clientId: ClientId;
  category: WebLinkCategory | null;
  track: (buttonLabel: string) => void;
  createLinks: CreateLinksFunction;
};

export function WebLinksUploadPresentation({
  visible,
  handleClose,
  clientId,
  category,
  track,
  createLinks,
}: WebLinksUploadPresentationProps) {
  const initialValues = useMemo(() => ({ webLinks: [getEmptyLink(category)] }), [category]);

  const formik = useSlobFormik({
    validationSchema,
    initialValues,
    async onSubmit(values) {
      const hide = slobMessage.loading(`Creating web links...`, 0);
      formik.setStatus("");

      try {
        const data = {
          webLinks: values.webLinks
            .map(({ url, text, category: currentLinkCategory, benefitTypes }) => ({
              url,
              text,
              category: category || currentLinkCategory,
              benefitTypes,
            }))
            .filter(
              (l): l is Pick<WebLink, "category" | "benefitTypes" | "url" | "text"> =>
                l.category != null,
            ),
        };
        const { isSuccess } = await createLinks({
          data,
          params: { clientId },
        });

        if (isSuccess) {
          void slobMessage.success(`Web links created`);
          handleClose();
        }
      } catch (error: unknown) {
        console.error({ error });
        formik.setStatus("Sorry, there was a problem. Please try again.");
      } finally {
        hide();
      }
    },
  });

  const { resetForm } = formik;

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- 🎟
  useEffect(() => {
    if (visible) {
      resetForm();
    }
  }, [visible, resetForm]);

  const allInputsEmpty = formik.values.webLinks.every((l) => !webLinkHasValue(l));

  const onClickOk = async () => {
    track("Add link");
    const next = formik.values.webLinks.filter(webLinkHasValue);
    await formik.setFieldValue("webLinks", next);
    await formik.submitForm();
  };
  const onClickCancel = () => {
    track("Cancel");
    handleClose();
  };

  const anyCategoryUpload = category === null;
  const numberOfItems = formik.values.webLinks.length;
  const numberOfCheckedItems = Object.values(formik.values.webLinks).filter(
    (wl) => wl.checked,
  ).length;
  const allItemsChecked = numberOfCheckedItems === numberOfItems;

  const multiRowSelectCheckboxLabel =
    numberOfCheckedItems > 0
      ? `${String(numberOfCheckedItems)} ${pluralize("Links", numberOfCheckedItems)} Selected`
      : "Link Text";

  const onChangeAllRowsCheckbox = async (_e: CheckboxChangeEvent) => {
    const next = formik.values.webLinks.map((w) => {
      return { ...w, checked: !w.checked };
    });
    await formik.setFieldValue("webLinks", next);
  };

  const removeSingleWebLink = async (key: string) => {
    track("Remove Web Link");
    const nextWebLinks = formik.values.webLinks.filter((l) => l.key !== key);
    await formik.setFieldValue("webLinks", nextWebLinks);
  };

  const removeMultipleWebLinks = async () => {
    track("Remove Multiple Web Links");
    const nextWebLinks = formik.values.webLinks
      .map((link) => {
        if (link.checked === false) {
          return link;
        } else return null;
      })
      .filter((l) => l); // drop the nulls
    if (nextWebLinks.length === 0) {
      nextWebLinks.push(getEmptyLink(category)); // always want to have at least 1 empty link for user input
    }
    await formik.setFieldValue("webLinks", nextWebLinks);
  };

  const setCategoryForMultipleWebLinks = async (category: DocumentCategory) => {
    track("Set Category for Multiple Web Links");
    const nextWebLinks = formik.values.webLinks.map((link) => {
      if (link.checked === false) {
        return link;
      } else {
        return { ...link, category: category };
      }
    });
    await formik.setFieldValue("webLinks", nextWebLinks);
  };

  const setBenefitTypeForMultipleWebLinks = async (
    benefitType: DocumentBenefitType,
    checked: boolean,
  ) => {
    track("Set Benefit Type for Multiple Web Links");
    const nextWebLinks = formik.values.webLinks.map((link) => {
      if (link.checked === false) {
        return link;
      } else {
        if (checked) {
          // add benefit type
          return { ...link, benefitTypes: link.benefitTypes?.concat(benefitType) ?? [benefitType] };
        } else {
          // remove benefit type
          return {
            ...link,
            benefitTypes: link.benefitTypes?.filter((b) => b !== benefitType) ?? [],
          };
        }
      }
    });
    await formik.setFieldValue("webLinks", nextWebLinks);
  };

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- ref to make dropdowns "stick" to the modal
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <Modal
      open={visible}
      destroyOnClose
      onCancel={onClickCancel}
      maskClosable={false}
      width={category == null ? 1206 : 600}
      title="Add Link"
      focusTriggerAfterClose={false}
      disableClose={formik.isSubmitting}
      footer={null}
    >
      <div ref={ref}>
        {/* Header */}
        {anyCategoryUpload && ( // only offer multi-select actions when no category is provided in props
          <Row gutter={16} align="middle">
            <Col xs={6}>
              <Checkbox
                variant="secondary"
                label={
                  <span className={styles.multiRowSelectCheckboxLabel}>
                    {multiRowSelectCheckboxLabel}
                  </span>
                }
                name="LinkText"
                checked={allItemsChecked}
                onChange={onChangeAllRowsCheckbox}
                disabled={formik.isSubmitting}
                indeterminate={numberOfCheckedItems > 0 && !allItemsChecked}
              />
            </Col>
            {/* Show column labels when no or 1 items are checked */}
            {numberOfCheckedItems <= 1 && (
              <>
                {/* Column 2 - Link Url Label */}
                <Col xs={5}>
                  <div className={styles.headerTitle}>Link Url</div>
                </Col>
                {/* Column 3 - Resource Type Label */}
                <Col xs={5}>
                  <div className={styles.headerTitle}>Resource Type</div>
                </Col>
                {/* Column 4 - Benefit Type Label */}
                <Col xs={5}>
                  <div className={styles.headerTitle}>Benefit Type</div>
                </Col>
              </>
            )}
            {/* Show multi-edit controls when more than 1 items are checked */}
            {numberOfCheckedItems > 1 && (
              <>
                {/* Column 2 - Blank */}
                <Col xs={5}>{""}</Col>
                {/* Column 3 - Set category of selected */}
                <Col xs={5} className={styles.multiSelectResourceTypeWrapper}>
                  <AntdDropdown
                    trigger={["click"]}
                    disabled={formik.isSubmitting}
                    dropdownRender={() => (
                      <Menu
                        className="p-24"
                        items={[...EnrollmentResourcesCategoriesNoVDR]
                          .sort(documentCategorySorter)
                          .map((c) => ({
                            key: c,
                            label: <Body3>{categoryNameByCategoryType[c]}</Body3>,
                          }))}
                        onClick={async ({ key }) => {
                          if (isDocumentCategory(key)) await setCategoryForMultipleWebLinks(key);
                        }}
                      />
                    )}
                    getPopupContainer={() => ref.current || document.body}
                  >
                    <Button type="text-only">Edit Resource Type</Button>
                  </AntdDropdown>
                </Col>
                {/* Column 4 - Set benefit type(s) of selected and Delete */}
                <Col xs={5} className={styles.deleteCheckedWrapper}>
                  <MenuWithCheckboxes
                    getPopupContainer={() => ref.current || document.body}
                    variant="secondary"
                    name="editAllBenefitTypes"
                    label="Edit Benefit Type"
                    disabled={formik.isSubmitting}
                    menuItemCheckboxes={documentBenefitTypes.map((bt) => {
                      const webLinksThatAreCheckedAndHaveCurrentBenefitType =
                        formik.values.webLinks.filter(
                          (wl) => wl.checked && wl.benefitTypes?.includes(bt),
                        );
                      const checked =
                        webLinksThatAreCheckedAndHaveCurrentBenefitType.length ===
                        numberOfCheckedItems
                          ? true
                          : webLinksThatAreCheckedAndHaveCurrentBenefitType.length === 0
                          ? false
                          : "mixed";
                      return {
                        id: `All__${bt}`,
                        label: documentBenefitTypesNames[bt],
                        name: bt,
                        checked,
                        onChange: async (e) => {
                          const name = e.target.name;
                          const checked = e.target.checked;
                          if (name && isDocumentBenefitType(name)) {
                            await setBenefitTypeForMultipleWebLinks(name, checked);
                          }
                        },
                      };
                    })}
                  />
                  <Button
                    type="text-only"
                    disabled={formik.isSubmitting}
                    onClick={removeMultipleWebLinks}
                  >
                    Delete
                  </Button>
                </Col>
              </>
            )}
          </Row>
        )}
        {formik.values.webLinks.map((link, i, arr) => {
          const rawError = formik.errors.webLinks?.[i];
          const error = rawError && typeof rawError !== "string" ? rawError : null;

          const touched = formik.touched.webLinks?.[i];

          const linkUrlId = `linkurl_${link.key}`;
          const linkTextId = `linktext_${link.key}`;

          const menuItemCheckBoxesOnChange = async (e: CheckboxChangeEvent) => {
            const name = e.target.name;
            const checked = e.target.checked;
            if (name && isDocumentBenefitType(name)) {
              const next = formik.values.webLinks.map((w) => {
                if (w.key === link.key) {
                  const nextBenefitTypes = checked
                    ? w.benefitTypes?.concat(name) ?? [name]
                    : w.benefitTypes?.filter((b) => b !== name) ?? [];
                  return { ...w, benefitTypes: nextBenefitTypes };
                }
                return w;
              });
              await formik.setFieldValue("webLinks", next);
            }
          };

          const rowCheckboxOnChange = async (e: CheckboxChangeEvent) => {
            const evtChecked = e.target.checked;
            const next = formik.values.webLinks.map((w) =>
              w.key === link.key ? { ...w, checked: evtChecked } : w,
            );
            await formik.setFieldValue("webLinks", next);
          };

          return (
            <React.Fragment key={link.key}>
              <Row gutter={16} align="middle" className={styles.webLinkRow}>
                {/* row checkbox if multiple rows */}
                {anyCategoryUpload && (
                  <Col xs={1}>
                    <Checkbox
                      label={
                        <span className="sr-only">
                          Select {link.text === "" ? "empty" : link.text} link's row
                        </span>
                      }
                      name={String(link.key)}
                      checked={link.checked}
                      onChange={rowCheckboxOnChange}
                      disabled={formik.isSubmitting}
                      indeterminate={false}
                    />
                  </Col>
                )}
                {/* Link Text form input */}
                <Col xs={anyCategoryUpload ? 5 : 12}>
                  {" "}
                  <FormInput
                    name={linkTextId}
                    maxLength={191}
                    label="Link Text"
                    touched={touched?.text}
                    aria-invalid={Boolean(touched?.text && error?.text)}
                    disabled={formik.isSubmitting}
                    value={link.text}
                    showRequired
                    onChange={async (e) => {
                      const next = formik.values.webLinks.map((w) =>
                        w.key === link.key ? { ...w, text: e.currentTarget.value } : w,
                      );
                      await formik.setFieldValue("webLinks", next);
                    }}
                    error={error?.text}
                  />
                </Col>
                <Col xs={anyCategoryUpload ? 5 : 12}>
                  <div className={clsx(category != null && styles.cellWithDeleteButton)}>
                    <FormInput
                      name={linkUrlId}
                      maxLength={2048}
                      label="Link URL"
                      touched={touched?.text}
                      aria-invalid={Boolean(touched?.url && error?.url)}
                      disabled={formik.isSubmitting}
                      value={link.url}
                      showRequired
                      onChange={async (e) => {
                        const next = formik.values.webLinks.map((w) =>
                          w.key === link.key ? { ...w, url: e.currentTarget.value } : w,
                        );
                        await formik.setFieldValue("webLinks", next);
                      }}
                      error={error?.url}
                    />

                    {category != null && i > 0 && (
                      <button
                        className="btn-reset"
                        disabled={formik.isSubmitting}
                        title="Remove"
                        aria-label="Remove"
                        onClick={async () => {
                          track("Remove Web Link");
                          const nextWebLinks = formik.values.webLinks.filter(
                            (l) => l.key !== link.key,
                          );
                          await formik.setFieldValue("webLinks", nextWebLinks);
                        }}
                      >
                        <TrashIcon />
                      </button>
                    )}
                  </div>
                </Col>

                {anyCategoryUpload && (
                  <>
                    <Col xs={5}>
                      <SlobSelect<{ label: string; value: WebLinkCategory | "" }>
                        name={`category__${link.key}`}
                        placeholder="Resource Type"
                        aria-invalid={Boolean(error)}
                        disabled={formik.isSubmitting}
                        loading={formik.isSubmitting}
                        touched={touched?.category}
                        error={error?.category}
                        showRequired
                        options={WebLinkCategories.map((c) => ({
                          value: c,
                          label: webLinkCategoryNameByCategoryType[c],
                        }))}
                        value={link.category}
                        onChange={async (option) => {
                          const next = formik.values.webLinks.map((w) =>
                            w.key === link.key ? { ...w, category: option.value } : w,
                          );
                          await formik.setFieldValue("webLinks", next);
                        }}
                      />
                    </Col>

                    <Col xs={5}>
                      <div className={styles.cellWithDeleteButton}>
                        <MenuWithCheckboxes
                          variant="primary"
                          name={`benefitTypes__${link.key}`}
                          label="Benefit Type"
                          disabled={formik.isSubmitting}
                          menuItemCheckboxes={documentBenefitTypes.map((bt) => ({
                            id: `${link.key}__${bt}`,
                            label: documentBenefitTypesNames[bt],
                            name: bt,
                            checked: link.benefitTypes?.includes(bt) ?? false,
                            onChange: menuItemCheckBoxesOnChange,
                          }))}
                        />

                        {i > 0 && (
                          <button
                            className="btn-reset"
                            disabled={formik.isSubmitting}
                            title="Remove"
                            aria-label="Remove"
                            onClick={() => removeSingleWebLink(link.key)}
                          >
                            <TrashIcon />
                          </button>
                        )}
                      </div>
                    </Col>
                  </>
                )}
              </Row>

              {i < arr.length - 1 && <hr />}
            </React.Fragment>
          );
        })}

        <div className="mt-24">
          <Button
            type="text"
            disabled={formik.isSubmitting}
            onClick={async () => {
              track("Add another link");
              const nextWebLinks = formik.values.webLinks.concat(getEmptyLink(category));
              await formik.setFieldValue("webLinks", nextWebLinks);
            }}
          >
            <PlusCircleOutlined /> Add another link
          </Button>
        </div>

        {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}

        {typeof formik.errors.webLinks === "string" && formik.touched.webLinks && (
          <ErrorMessage>{formik.errors.webLinks}</ErrorMessage>
        )}

        <hr />

        <div className={styles.footer}>
          <Button
            type="primary"
            size="middle"
            onClick={onClickOk}
            disabled={allInputsEmpty || formik.isSubmitting || formik.values.webLinks.length === 0}
            loading={formik.isSubmitting}
            aria-label={formik.isSubmitting ? "Uploading" : undefined}
          >
            {formik.isSubmitting ? "" : "Upload"}
          </Button>

          <Button type="text" size="middle" onClick={handleClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

type WebLinksUploadProps = {
  visible: boolean;
  handleClose: () => void;
  clientId: ClientId;
  category: WebLinkCategory | null;
  track: (buttonLabel: string) => void;
};

export function WebLinksUpload(props: WebLinksUploadProps) {
  const { mutateAsync: createLinks } = useCreateWebLinks();
  return <WebLinksUploadPresentation createLinks={createLinks} {...props} />;
}
