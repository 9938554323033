import { Rate } from "antd";
import clsx from "clsx";

import { InputErrorMessage } from "../Form/InputErrorMessage";

import * as styles from "./startRate.module.less";

import type { RateProps } from "antd";

export const DataTestId = {
  starRateWrapper: "star-rate-wrapper",
};

type StarRateProps = Pick<
  RateProps,
  "allowClear" | "className" | "value" | "onChange" | "style" | "disabled"
> & { errorValue?: string };

export const StarRate = ({ className, errorValue, ...rest }: StarRateProps) => {
  return (
    <div data-testid={DataTestId.starRateWrapper}>
      <Rate {...rest} className={clsx(styles.starRate, className)} />{" "}
      {errorValue && <InputErrorMessage id={`error-star-rating`} error={errorValue} />}
    </div>
  );
};
