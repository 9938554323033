import { EIFAgeRules } from "client/src/domain/EIF/EIFAgeRules";
import { EIFConfirmPolicyAnniversary } from "client/src/domain/EIF/EIFConfirmPolicyAnniversary";
import { EIFValueAddedServices } from "client/src/domain/EIF/EIFValueAddedServices";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getAtLeastOneSubStepIsApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { EIFAdditionalWaitingPeriodRules } from "./EIFAdditionalWaitingPeriodRules";
import { EIFChangesDuringAnnualEnrollment } from "./EIFChangesDuringAnnualEnrollment";
import { EIFERISA } from "./EIFERISA";
import { EIFEmployeeCertificate } from "./EIFEmployeeCertificate";
import { EIFOtherChangesToCoverage } from "./EIFOtherChangesToCoverage";
import { EIFOtherContractDetailsIntroduction } from "./EIFOtherContractDetailsIntroduction";
import {
  EIFOtherContractDetailsReview,
  ShowAllSubSteps as ShowAllOtherContractDetailsSubSteps,
} from "./EIFOtherContractDetailsReview";
import { EIFRehireProvisions } from "./EIFRehireProvisions";
import { EIFSection125 } from "./EIFSection125";
import { EIFTerminationOfInsurance } from "./EIFTerminationOfInsurance";
import { EIFUnionMembersAndDomesticPartners } from "./EIFUnionMembersAndDomesticPartners";
import { EIFPlaceholder } from "./common/EIFPlaceholder";
import type { UpdatePlansQuery } from "../../hooks/plans";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFOtherContractDetailsSubStep, EIFStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  eifSubStepId?: EIFOtherContractDetailsSubStep;
  clientPlans: Plan[];
  bills: Bill[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  classes: EmployeeClass[];
  changeSnapshot: DEIFChangeSnapshot;
  employeeClassDocumentCount: number;
  updateClient: UpdateClientFunc;
  updatePlansQuery: UpdatePlansQuery;
  trackElementClicked: TrackElementClickedFunc;
  viewMode: EIFSubStepViewMode;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
  subsidiaries: Subsidiary[];
};

const eifStepId: EIFStepId = "other-contract-details";

export function EIFOtherContractDetails(props: Props) {
  const {
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    classes,
    changeSnapshot,
    employeeClassDocumentCount,
    eifSubStepId,
    updateClient,
    trackElementClicked,
    updatePlansQuery,
    viewMode,
    featureToggles,
    authUser,
    subsidiaries,
  } = props;

  const atLeastOneSubStepIsApplicable = getAtLeastOneSubStepIsApplicable(
    "other-contract-details",
    client,
    clientPlans,
    featureToggles,
  );
  if (!atLeastOneSubStepIsApplicable) {
    const to = RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information");
    return <Navigate to={to} replace />;
  }

  const stepCompleteStatus = getEIFStepCompleteStatus(
    eifStepId,
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    classes,
    employeeClassDocumentCount,
    changeSnapshot,
    featureToggles,
    subsidiaries,
  );

  if (!eifSubStepId) {
    return stepCompleteStatus === "Not Started" ? (
      <EIFOtherContractDetailsIntroduction />
    ) : (
      <EIFOtherContractDetailsReview
        client={client}
        clientPlans={clientPlans}
        changeSnapshot={changeSnapshot}
        viewMode={viewMode}
        featureToggles={featureToggles}
        authUser={authUser}
        signerMode="inside"
        showSubSteps={ShowAllOtherContractDetailsSubSteps}
        isLastStep={true}
      />
    );
  }

  switch (eifSubStepId) {
    case "confirm-policy-anniversary":
      return (
        <EIFConfirmPolicyAnniversary
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "additional-waiting-period-rules":
      return (
        <EIFAdditionalWaitingPeriodRules
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "rehire-provision":
      return (
        <EIFRehireProvisions
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "union-members-and-domestic-partners":
      return (
        <EIFUnionMembersAndDomesticPartners
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "changes-during-annual-enrollment":
      return (
        <EIFChangesDuringAnnualEnrollment
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "termination-of-insurance":
      return (
        <EIFTerminationOfInsurance
          client={client}
          clientPlans={clientPlans}
          changeSnapshot={changeSnapshot}
          updatePlansQuery={updatePlansQuery}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
        />
      );
    case "age-rules":
      return (
        <EIFAgeRules
          updateClient={updateClient}
          client={client}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "other-changes-to-coverage":
      return (
        <EIFOtherChangesToCoverage
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "employee-certificate":
      return (
        <EIFEmployeeCertificate
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
        />
      );
    case "section-125":
      return (
        <EIFSection125
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
        />
      );
    case "erisa":
      return (
        <EIFERISA
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "value-added-services":
      return (
        <EIFValueAddedServices
          client={client}
          updateClient={updateClient}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
        />
      );
    default:
      exhaustiveCheck(eifSubStepId);
      break;
  }

  return EIFPlaceholder({ eifStepId, eifSubStepId });
}
