import React, { useState } from "react";
import { templateLookupValidation } from "shared/validation/onboardingForm";

import { Modal } from "../../components/Modal/Modal";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useCreateOnboardingForm, useTemplateLookup } from "../../hooks/onboardingForms";
import { useSlobFormik } from "../../hooks/useSlobFormik";

import { CreateForm } from "./Form/CreateForm";

import type { ClientId } from "shared/types/Client";
import type { TemplateLookup } from "shared/types/OnboardingForm";

type CreateOnboardingFormProps = {
  clientId: ClientId;
  addCallback: () => Promise<unknown>;
  cancelCallback: () => void;
  isVisible: boolean;
  isFetchingOnboardingForms: boolean;
  track: (buttonLabel: string) => void;
};

export const CreateOnboardingFormModal: React.FC<CreateOnboardingFormProps> = ({
  clientId,
  addCallback,
  isVisible,
  isFetchingOnboardingForms,
  cancelCallback,
  track,
}) => {
  const [template, setTemplate] = useState<TemplateLookup | null>(null);

  const formik = useSlobFormik({
    initialValues: {
      docusignTemplateId: "",
    },
    validationSchema: templateLookupValidation,
    async onSubmit(values) {
      track("Save and Send Envelope");
      const { isSuccess, isError } = await createOnboardingForm({
        data: {
          templateId: values.docusignTemplateId,
        },
      });
      if (isSuccess) {
        await addCallback();
        void slobMessage.success("Successfully created");
      }

      if (isError) {
        void slobMessage.error("Failed to create the envelope");
      }
      setTemplate(null);
      formik.resetForm();
    },
  });

  const {
    mutateAsync: createOnboardingForm,
    isPending: isCreateOnboardFormLoading,
    error: errorCreatingForm,
  } = useCreateOnboardingForm(clientId);
  const {
    refetch: templateLookup,
    isError: hasTemplateLookupError,
    isFetching: isTemplateLookupLoading,
  } = useTemplateLookup(clientId, formik.values.docusignTemplateId);

  const handleTemplateLookup = async () => {
    track("Lookup");
    formik.setSubmitting(true);
    const { data } = await templateLookup();
    formik.setSubmitting(false);

    setTemplate(data || null);
  };

  const handleCloseModal = () => {
    setTemplate(null);
    formik.resetForm();
    cancelCallback();
  };

  return (
    <Modal
      open={isVisible}
      footer={
        <p>
          A form recipient is marked as an “Outside Signer” if they do not have an Onboard account
          and if they want to receive emails directly from DocuSign to sign the form.
        </p>
      }
      onCancel={handleCloseModal}
      title="Add Template ID"
      maskClosable={false}
      focusTriggerAfterClose={false}
    >
      <CreateForm
        formik={formik}
        template={template}
        isTemplateLookupLoading={isTemplateLookupLoading}
        isCreateOnboardFormLoading={isCreateOnboardFormLoading || isFetchingOnboardingForms}
        hasTemplateLookupError={hasTemplateLookupError}
        errorCreatingForm={errorCreatingForm}
        handleTemplateLookup={handleTemplateLookup}
        cancelAction={handleCloseModal}
      />
    </Modal>
  );
};
