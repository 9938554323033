import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useToggler } from "../../../hooks/useToggler";
import { DocumentUpload } from "../DocumentUpload/DocumentUpload";

import type { ButtonProps } from "../../../components/Button/ButtonOld";
import type { ClientId } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

type DocumentUploadSingleProps = {
  clientId: ClientId;
  category: DocumentCategory;
  policyId?: string;
  buttonLabel: string;
  buttonIcon?: React.ReactNode;
  buttonType?: ButtonProps["type"] | "link";
  buttonSize?: ButtonProps["size"];
  buttonClassName?: string;
  onClick: () => void;
  track: (buttonLabel: string) => void;
  infoMessage?: string;
  disabled?: boolean;
};

export function DocumentUploadSingle({
  clientId,
  category,
  policyId,
  buttonLabel,
  buttonIcon,
  buttonType = "primary",
  buttonClassName,
  buttonSize,
  onClick,
  track,
  infoMessage,
  disabled,
}: DocumentUploadSingleProps) {
  const [visible, toggleVisible] = useToggler();

  return (
    <>
      <ButtonOld
        type={buttonType}
        onClick={() => {
          track(buttonLabel);
          toggleVisible();
          onClick();
        }}
        icon={buttonIcon}
        className={buttonClassName} // @todo: FP-3498: move to switch case with Link component/button
        size={buttonSize}
        disabled={disabled}
      >
        {buttonLabel}
      </ButtonOld>
      <DocumentUpload
        visible={visible}
        closeModal={toggleVisible}
        clientId={clientId}
        category={category}
        policyId={policyId}
        singleFile={true}
        track={track}
        infoMessage={infoMessage}
      />
    </>
  );
}
