import { Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";

import { Loading } from "../../components/Loading/Loading";
import { PageContent } from "../../components/PageContent/PageContent";
import { BrokerClientsList } from "../../domain/Client/ClientsList/BrokerClientsList";
import { useSlobAuth } from "../../hooks/auth";

const { Title } = Typography;

export const BrokerClientsPage = () => {
  const { authUser, isLoading } = useSlobAuth();
  const { data: featureToggles, isLoading: isLoadingFT } = useGetFeatureToggles();

  const userId = authUser?.id || "";
  const searchParams = { userId, completedSetup: true };

  if (isLoading || isLoadingFT) {
    return <Loading />;
  }

  return (
    <PageContent>
      <Row>
        <Col span={15}>
          <Title>{authUser?.name}</Title>
        </Col>
      </Row>

      <BrokerClientsList extraSearchParams={searchParams} featureToggles={featureToggles} />
    </PageContent>
  );
};
