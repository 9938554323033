import { getAreTherePendingChanges } from "shared/utils/EIF/changeLog";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

type Args = {
  client: Client;
  authUser: UserData | null;
  changesSnapshot: DEIFChangeSnapshot | null;
};
export type EIFEditState =
  | "no-edits"
  | "edits-in-progress"
  | "ready-for-review"
  | "changes-declined-then-edited"
  | "changes-declined-no-new-edits"
  | "changes-accepted";

export function getEIFEditState(args: Omit<Args, "authUser">): EIFEditState {
  const { client, changesSnapshot } = args;

  // Review is in progress -> ba/broker can review
  if (client.deifChangesReadyForReviewAt) {
    return "ready-for-review";
  }

  // any pending changes since signed, accepted or declined
  const hasPendingChanges = changesSnapshot ? getAreTherePendingChanges(changesSnapshot) : false;

  // Changes accepted
  if (
    client.deifChangesAcceptedAt &&
    client.deifChangesAcceptedAt > (client.deifChangesDeclinedAt || new Date(0))
  ) {
    return hasPendingChanges ? "edits-in-progress" : "changes-accepted";
  }

  // Changes declined
  if (
    client.deifChangesDeclinedAt &&
    client.deifChangesDeclinedAt > (client.deifChangesAcceptedAt || new Date(0))
  ) {
    return hasPendingChanges ? "changes-declined-then-edited" : "changes-declined-no-new-edits";
  }

  return hasPendingChanges ? "edits-in-progress" : "no-edits";
}
