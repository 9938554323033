import { Popover } from "antd";
import { Button } from "client/src/components/Button/Button";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { Row, Col } from "client/src/components/Grid/Grid";

import { Body2 } from "../Typography/Typography";

import * as styles from "./internalControl.module.less";
import type { ButtonProps } from "../Button/Button";

import type { ActionType } from "rc-trigger/lib/interface";

export type Position = "attached" | "detached" | undefined;

export interface InternalControlProps {
  "data-testid"?: string;
  bodyText?: string;
  buttonIcon?: React.ReactNode;
  buttonLabel?: React.ReactNode;
  buttonType?: ButtonProps["type"];
  buttonHref?: string;
  buttonNewTab?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  secondaryButtonIcon?: React.ReactNode;
  secondaryButtonLabel?: React.ReactNode;
  secondaryOnClick?: React.MouseEventHandler<HTMLElement>;
  secondaryDisabled?: boolean;
  popoverContent?: React.ReactNode;
  popoverVisible?: boolean;
  popoverTrigger?: ActionType | ActionType[];
  position?: Position;
  onPopoverVisibleChange?: (visible: boolean) => void;
  extraContent?: React.ReactNode;
  hidePrimaryButton?: boolean;
}

export const InternalControl = ({
  bodyText = "Internal User:",
  buttonIcon,
  buttonType = "secondary",
  buttonLabel,
  buttonHref,
  buttonNewTab,
  onClick,
  disabled,
  secondaryButtonIcon,
  secondaryButtonLabel,
  secondaryOnClick,
  secondaryDisabled,
  popoverContent,
  popoverVisible,
  popoverTrigger,
  position,
  onPopoverVisibleChange,
  extraContent,
  "data-testid": dataTestId,
  hidePrimaryButton = false,
}: InternalControlProps) => {
  const extraPropsForExternalLink = buttonHref
    ? { href: buttonHref, newTab: buttonNewTab ? true : false }
    : {};

  return (
    <DashedBanner variant="warning" attached={position !== "detached"} data-testid={dataTestId}>
      <Row align="middle" justify="space-between">
        <Col>
          <Body2 darkYellow>{bodyText}</Body2>
        </Col>

        <Col>
          <Row gutter={8}>
            <Col>
              {popoverContent ? (
                <Popover
                  content={popoverContent}
                  trigger={popoverTrigger}
                  placement="bottomRight"
                  arrowPointAtCenter
                  open={popoverVisible}
                  onOpenChange={onPopoverVisibleChange}
                  overlayClassName={styles.popover}
                >
                  <Button
                    type={buttonType}
                    icon={buttonIcon}
                    onClick={onClick}
                    disabled={disabled}
                    data-testid="internal-control-edit-button"
                  >
                    {buttonLabel}
                  </Button>
                </Popover>
              ) : hidePrimaryButton ? null : (
                <Button
                  type={buttonType}
                  icon={buttonIcon}
                  onClick={onClick}
                  disabled={disabled}
                  data-testid="internal-control-edit-button"
                  {...extraPropsForExternalLink}
                >
                  {buttonLabel}
                </Button>
              )}
            </Col>

            {secondaryButtonLabel && (
              <Col>
                <Button
                  type={buttonType}
                  icon={secondaryButtonIcon}
                  onClick={secondaryOnClick}
                  disabled={secondaryDisabled}
                >
                  {secondaryButtonLabel}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {extraContent && <div className="mt-8 hide:empty">{extraContent}</div>}
    </DashedBanner>
  );
};
