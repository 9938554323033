import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { Button } from "../../components/Button/Button";
import { ActivationCard } from "../../components/HubCard/ActivationCard";
import { Loading } from "../../components/Loading/Loading";
import { H2 } from "../../components/Typography/Typography";
import { PasswordUpsertForm } from "../../domain/User/Form/PasswordUpsertForm";
import { useVerifyUserResetToken } from "../../hooks/user";
import { useTrackElementClicked } from "../../utils/analytics";

export const ResetPage = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const trackElementClicked = useTrackElementClicked();

  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token") ?? "";
  const { data, isLoading: isLoadingTokenVerify, isError } = useVerifyUserResetToken(token);
  const goLoginClick = (module: string, buttonLabel: string) => {
    trackElementClicked({ module, buttonLabel });
    navigate(RouteData.login.getPath());
  };

  if (isLoadingTokenVerify) {
    return <Loading />;
  }

  if (isError || !data) {
    return (
      <ActivationCard>
        <H2 as="h1">The link has expired</H2>
        <p className="mb-24">Sorry, but the link you used to get here has expired.</p>
        <Button
          type="primary"
          size="large"
          block
          onClick={() => goLoginClick("Password Reset Link Expired", "Return to Sign In")}
        >
          Return to Sign In
        </Button>
      </ActivationCard>
    );
  }

  const { email, firstName, lastName } = data;

  const onSuccess = () => {
    setSuccessMessage(true);
  };

  if (successMessage) {
    return (
      <ActivationCard>
        <H2 as="h1">Success!</H2>
        <p className="mb-24">You updated the password on your account.</p>
        <Button
          type="primary"
          size="large"
          block
          onClick={() => goLoginClick("Password Reset Success", "Return to Sign In")}
        >
          Return to Sign In
        </Button>
      </ActivationCard>
    );
  }

  return (
    <ActivationCard>
      <H2 as="h1">Reset Your Password</H2>
      <PasswordUpsertForm
        onSuccess={onSuccess}
        email={email}
        firstName={firstName}
        lastName={lastName}
        token={token}
        passwordFormLabel="Enter a new password"
        submitButtonLabel="Save new password"
        track={() =>
          trackElementClicked({ module: "Reset Password", buttonLabel: "Save new password" })
        }
      />
    </ActivationCard>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default ResetPage;
