import { useEffect, useState } from "react";
import { RouteData } from "shared/config/routeData";

import slugify from "slugify";
import type { BenefitTypeBenEx } from "@prisma/client";
import type { Namespace, TFunction } from "i18next";
import type React from "react";
import type { DocumentLanguage } from "shared/types/Document";
import type {
  ExplorerPage,
  ExplorerPageId,
  ExplorerPageVanitySlug,
  PlanSummaryDocumentByBenefitType,
} from "shared/types/ExplorerPage";
import type { PlanType } from "shared/types/ExplorerPageBenefit";
import type { ShowPlanComparisonFields } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const urlForExplorerPage = (pageID: ExplorerPageId | ExplorerPageVanitySlug, slug = false) =>
  window.location.origin.toString() +
  RouteData.benefitsExplorer.getPath(slug ? slugify(pageID, { lower: true }) : pageID);

export const useWaitForData = (isLoading: boolean) => {
  const [waitForData, setWaitForData] = useState(isLoading);

  useEffect(() => {
    setWaitForData(isLoading);
  }, [isLoading]);

  return waitForData;
};

export const isFavoritePlan = (favoritePlans: string[], planId: string) => {
  if (favoritePlans.length > 0) {
    return !!favoritePlans.find((favPlan) => favPlan === planId);
  }
  return false;
};

/**
 * Create a record with an optional `default` key.
 */
export type CopySwitch<S extends string | number | symbol, T> = { [key in S]?: T } & {
  default?: T;
};

/**
 * Return a copy key given an array of value.
 */
export const copyKey = (...a: (string | number | null | undefined)[]) => {
  return a.filter((val) => (typeof val === "number" ? true : val?.length)).join(".");
};

/**
 * Return true if the given copy key exists.
 */
export const copyKeyExists = (t: TFunction<Namespace<string>>, ...a: Parameters<typeof copyKey>) =>
  // TODO Consider using `i18next.exists(key, options)`
  !!t(copyKey(...a), { defaultValue: null });

export const useCopyKeyExists = (...params: Parameters<typeof copyKeyExists>) => {
  const [exists, setExists] = useState(copyKeyExists(...params));
  useEffect(() => setExists(copyKeyExists(...params)), [params]);
  return exists;
};

export const copyKeySwitch = (
  t: TFunction<Namespace<string>>,
  ...a: Parameters<typeof copyKey>
): string | number => {
  const key = a[a.length - 1];
  return key && copyKeyExists(t, ...a) ? key : "default";
};
export const useCopyKeySwitch = (...params: Parameters<typeof copyKeySwitch>) => {
  const [key, setKey] = useState(copyKeySwitch(...params));
  useEffect(() => setKey(copyKeySwitch(...params)), [params]);
  return key;
};

export const copyKeySwitchCustom = (
  t: TFunction<Namespace<string>>,
  a: Parameters<typeof copyKey>,
  defaultKey: string | number | null,
) => {
  const key = a[a.length - 1];
  return key && copyKeyExists(t, ...a) ? key : defaultKey;
};

export const useIsStuck = (ref: React.RefObject<HTMLElement>) => {
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const detectIfStuck = () => {
      if (ref.current) {
        const top = parseFloat(getComputedStyle(ref.current).top);
        const topBound = ref.current.getBoundingClientRect().top;
        setStuck(top === topBound);
      }
    };

    detectIfStuck();
    window.addEventListener("scroll", detectIfStuck, { passive: true });
    window.addEventListener("resize", detectIfStuck, { passive: true });

    return () => {
      window.removeEventListener("scroll", detectIfStuck);
      window.removeEventListener("resize", detectIfStuck);
    };
  }, [ref]);

  return stuck;
};

export const getDocumentsListFromAssociatedPages = (
  associatedPages: ExplorerPage[],
  language: DocumentLanguage,
): PlanSummaryDocumentByBenefitType[] => {
  let documents: PlanSummaryDocumentByBenefitType[] = [];
  associatedPages.forEach((explorerPage) => {
    const res = explorerPage.benefits?.reduce<PlanSummaryDocumentByBenefitType[]>((acc, val) => {
      if (val.planSummaryDocument && language === "EN") {
        return acc.concat([
          {
            benType: val.benefitType,
            docs: [
              {
                documentKey: val.planSummaryDocument.documentKey,
                id: val.planSummaryDocument.id,
                name: val.planSummaryDocument.name,
                mimeType: val.planSummaryDocument.mimeType,
              },
            ],
          },
        ]);
      } else if (val.spanishPlanSummaryDocument && language === "ES") {
        return acc.concat([
          {
            benType: val.benefitType,
            docs: [
              {
                documentKey: val.spanishPlanSummaryDocument.documentKey,
                id: val.spanishPlanSummaryDocument.id,
                name: val.spanishPlanSummaryDocument.name,
                mimeType: val.spanishPlanSummaryDocument.mimeType,
              },
            ],
          },
        ]);
      }
      return acc;
    }, []);

    if (res) {
      documents = documents.concat(res);
    }
  });
  return documents;
};

export const getDocumentsListByBenefitType = (allDocuments: PlanSummaryDocumentByBenefitType[]) => {
  const planSummaryDocumentsByBenefitType = allDocuments.reduce<PlanSummaryDocumentByBenefitType[]>(
    (acc, val) => {
      if (!acc.some(({ benType }) => benType === val.benType)) {
        return acc.concat(val);
      }

      const documentKeyToMatch = val.docs[0]?.documentKey;
      if (
        acc.some(({ docs }) => docs.some(({ documentKey }) => documentKey === documentKeyToMatch))
      )
        return acc;

      const documentToPush = val.docs[0];
      const combinedValues = acc.filter((a) => {
        return a.benType === val.benType && a.docs.length < 15 && documentToPush
          ? a.docs.push(documentToPush)
          : a.docs;
      });
      return combinedValues;
    },
    [],
  );
  return planSummaryDocumentsByBenefitType;
};

// pages created before this date are considered "old" and should not have new "plan comparison table fields" feature
const OLD_PAGE_DATE = new Date("5/30/2024");

/**
 * Check if the page is new or has a valid date.
 * @param pageCreationDate The date the page was created.
 */
export const isPageNewOrValidDate = (pageCreationDate?: Date) =>
  !pageCreationDate || pageCreationDate >= OLD_PAGE_DATE;

export const checkIfShowPlanComparisonFields = (
  pageCreationDate?: Date,
  benefitType?: BenefitTypeBenEx | "",
  planType?: PlanType | null,
  featureToggle?: ClientFeatureToggles,
  isRenewal?: boolean,
  hideNewVisionPlanFields?: boolean,
  hideNewDentalPlanFields?: boolean,
): ShowPlanComparisonFields => {
  if (!isPageNewOrValidDate(pageCreationDate) && !isRenewal) return false;
  else if (benefitType === "MEDICAL" && !!featureToggle?.BENEX_MEDICAL_PLAN_COMPARISON) {
    return "MEDICAL";
  } else if (
    benefitType === "DENTAL" &&
    !!featureToggle?.BENEX_DENTAL_PLAN_COMPARISON &&
    !hideNewDentalPlanFields
  ) {
    if (["DHMO", "DCA"].includes(planType ?? "")) return false;
    return "DENTAL";
  } else if (
    benefitType === "VISION" &&
    !!featureToggle?.BENEX_VISION_PLAN_COMPARISON &&
    !hideNewVisionPlanFields
  ) {
    return "VISION";
  }

  return false;
};
