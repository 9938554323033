import clsx from "clsx";
import { Anchor } from "../../components/Anchor/Anchor";
import { Body3, Body2 } from "../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../components/UnorderedList/UnorderedList";
import * as styles from "./eifSigningAgreement.module.less";

export function EIFSigningAgreement() {
  return (
    <ol className={clsx("stack-y-16", styles.orderedList)}>
      <li>
        <Body3>The requested group insurance will:</Body3>

        <UnorderedList>
          <UnorderedListItem>
            be issued only if the requested insurance is accepted by Sun Life and is legally
            permissible (cashing of the initial deposit check or processing initial deposit payments
            does not constitute approval of the requested insurance);
          </UnorderedListItem>
          <UnorderedListItem>
            be issued in the language customarily used by Sun Life;
          </UnorderedListItem>
          <UnorderedListItem>
            be subject to Sun Life’s standard underwriting requirements; and
          </UnorderedListItem>
          <UnorderedListItem>take effect on the date determined by Sun Life.</UnorderedListItem>
        </UnorderedList>
      </li>

      <li>
        <Body3>
          All information given in connection with this Submit Your Company Information task is true
          and complete to the best of the employer’s knowledge, information, and belief. Employer
          agrees to supplement and correct information provided in writing. If any information given
          in this task differs from what is given on the Application for Group Insurance, the terms
          of the Application for Group Insurance will control.
        </Body3>
      </li>

      <li>
        <Body3>
          Employees not Actively at Work on the effective date agreed to by Sun Life will be
          identified to Sun Life and will be insured only as required by law or as approved in
          writing by Sun Life.
        </Body3>
      </li>

      <li>
        <Body3>
          The receipt of premium by Sun Life is not a guarantee of coverage. Eligibility for
          benefits will be determined at the time of claim submission. Employer should not pay or
          collect premiums for or from employees who are not eligible for coverage. Employer will
          not collect premiums for coverage subject to Evidence of Insurability before such Evidence
          has been approved by Sun Life. Employer acknowledges and agrees that it will be
          responsible for confirming eligibility of spouses and dependents and for notifying Sun
          Life if any spouses and dependents are not eligible for coverage.
        </Body3>
      </li>

      <li>
        <Body3>
          No producer, agent, or broker can make or modify a contract for Sun Life, and all coverage
          will be as stated in Sun Life policies. No agent or broker has the authority to guarantee
          the acceptability of the requested insurance.
        </Body3>
      </li>

      <li>
        <Body3>
          Employer agrees not to alter any NAIC publication provided by Sun Life, to limit
          distribution to its employees, to distribute only the most current version as supplied by
          Sun Life and not to receive any compensation for its use. Employer acknowledges any NAIC
          publication is the exclusive property of the NAIC and will take no action adverse to those
          rights. Employer will dispose of any copies of NAIC publications supplied by Sun Life when
          its group insurance with Sun Life terminates.
        </Body3>
      </li>

      <li>
        <p>
          <Body2>Electronic Transactions:</Body2> <br />
          Pursuant to the terms of the Client and Consumer Electronic Consent and Disclosure, the
          undersigned employer consents to electronic transactions, including transactions involving
          membership and billing, premium payment, Evidence of Insurability, claims (where
          applicable), and electronic delivery of application and policy documents. The employer may
          at any time opt out of electronic transaction, or at no additional charge obtain a paper
          copy of any document or notice that has been delivered or made available electronically by
          request to the Company. See{" "}
          <Anchor href="/Electronic Disclosure docusign.pdf" target="_blank">
            Client and Consumer Electronic Consent and Disclosure
          </Anchor>{" "}
          for more information.
        </p>

        <p>
          <Body2>Kentucky only:</Body2> <br />
          The employer authorizes Sun Life at its discretion to communicate cancellations, renewals,
          premium increase and to deliver policy documents, group application and related forms
          electronically by the web portal and DocuSign. The employer is aware that this election
          operates as consent for all notices to be sent electronically; therefore, the employer
          should be diligent in updating the electronic mail address provided to the insurer. The
          policyholder may, at its option, request Sun Life to provide paper copies of the policy
          and all notices; or to receive the policy electronically and all notices in paper copy; or
          to receive the policy and all notices electronically.
        </p>
      </li>

      <li>
        <Body2>Employer web portal authorization:</Body2> <br />
        <Body3>
          The employer authorizes the administrators named in the “Plan Administrators & Billing”
          section to have access to the employer web portal and DocuSign and requests that a user
          name and password be assigned to allow for such access. Sun Life’s interactive web
          services requires an electronic identification. The use of the electronic identification
          by Employer’s representatives is the legal equivalent of Employer’s written signed
          instructions to Sun Life. Sun Life will rely on instructions from persons using the
          electronic identifications assigned by Employer. Actions taken by persons using an
          electronic identification assigned by Employer will be deemed to be authorized by
          Employer. For this reason, Employer must safeguard the electronic identifications and
          inform Sun Life promptly to terminate an identification if Employer believes the security
          of an identification has been compromised or person previously issued an identification is
          no longer authorized.
        </Body3>
      </li>

      <li>
        <Body3>
          I acknowledge receipt of Sun Life's Privacy Policy, as well as the HIPAA Notice of Privacy
          Practices applicable to the Sun Life Dental and Vision products, made available to me
          electronically on Sun Life Connect.
        </Body3>
      </li>

      <li>
        <Body3>
          I certify that the above statements and all information provided in this document are true
          and complete.
        </Body3>
      </li>
    </ol>
  );
}
