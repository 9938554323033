import { useListParams } from "client/src/hooks/useListParams";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PhaseIdValues } from "../../../../../shared/types/Policy";
import { SlobList } from "../../../components/SlobList/SlobList";
import { useSlobAuth } from "../../../hooks/auth";
import { CLIENTS_LIST_PAGE_SIZE, useGetUserClients } from "../../../hooks/client";

import { useLocalStorageClientTableFilters } from "../../../hooks/useSlobLocalStorage";
import { getObjectFromURLSearchParams, getURLSearchParamsFromObject } from "../../../utils/url";
import { BrokerClientsTable } from "./ClientsTable/BrokerClientsTable";
import { ClientListFilterButton } from "./Filter/ClientListFilterButton";
import type { ClientUserTableFilters } from "../../../../../shared/types/Client";
import type { To } from "react-router-dom";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type BrokerClientsListProps = {
  extraSearchParams: ClientUserTableFilters;
  featureToggles: ClientFeatureToggles | undefined;
};

type TableFilters = Pick<ClientUserTableFilters, "phaseIds">;

const isTableFilters = (filters: Record<string, unknown>): filters is TableFilters => {
  const isIt = filters.phaseIds == null || "phaseIds" in filters;
  return isIt;
};

export const BrokerClientsList = ({
  extraSearchParams,
  featureToggles,
}: BrokerClientsListProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading: isLoadingAuth, authUser } = useSlobAuth();

  const [localStorageFilters, writeLocalStorageFilters] = useLocalStorageClientTableFilters(
    authUser?.userId,
  );

  const defaultSearch = useMemo<TableFilters>(() => {
    return {
      phaseIds: PhaseIdValues.filter((val) => val !== "ONBOARDING_COMPLETE" && val !== "REJECTED"),
    };
  }, []);

  const filters = useMemo<TableFilters>(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryFiltersUntyped = getObjectFromURLSearchParams(queryParams);
    const queryFilters = isTableFilters(queryFiltersUntyped) ? queryFiltersUntyped : {};

    return {
      phaseIds: queryFilters.phaseIds ?? localStorageFilters?.phaseIds ?? defaultSearch.phaseIds,
    };
  }, [defaultSearch.phaseIds, location.search, localStorageFilters?.phaseIds]);

  const onFiltersChanged = useCallback<(f: TableFilters) => void>(
    (filters) => {
      const searchParams = getURLSearchParamsFromObject(filters);
      const to: To = {
        pathname: location.pathname,
        search: searchParams.toString(),
      };
      navigate(to, { replace: true });

      writeLocalStorageFilters(filters);
    },
    [navigate, location, writeLocalStorageFilters],
  );
  const onFiltersReset = useCallback(() => {
    const to: To = {
      pathname: location.pathname,
      search: "",
    };
    navigate(to, { replace: true });
    writeLocalStorageFilters(null);
  }, [navigate, location, writeLocalStorageFilters]);

  const listParams = useListParams({
    initialPageSize: CLIENTS_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "name",
    },
  });

  const query = useGetUserClients({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...extraSearchParams,
    ...filters,
  });

  if (isLoadingAuth) return null;

  return (
    <SlobList
      TableComponent={BrokerClientsTable}
      query={query}
      listParams={listParams}
      filter={filters}
      renderFilterComponent={() => (
        <ClientListFilterButton
          filters={filters}
          featureToggles={featureToggles}
          onFiltersChanged={onFiltersChanged}
          onFiltersReset={onFiltersReset}
          implementationConsultants={[]}
          ebrEmails={[]}
          variant="broker"
        />
      )}
      onGetDataError={(error) => error.status === 400 && onFiltersReset()}
    />
  );
};
