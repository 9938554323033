import Skeleton from "antd/lib/skeleton/Skeleton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { PageContent } from "client/src/components/PageContent/PageContent";

import * as styles from "./OutsideSignerPage.module.less";

export function OutsideSignerPageSkeleton() {
  return (
    <div className={styles.content}>
      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={16}>
            <Skeleton title={{ width: "25%" }} paragraph={false} className="mb-12" />

            <HubCard>
              <Skeleton paragraph={{ rows: 3 }} />
              <Skeleton title={false} paragraph={{ rows: 3 }} />
            </HubCard>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}
