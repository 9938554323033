import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { ReactComponent as CircleComplete } from "../../../components/Icons/CircleComplete.svg";
import { ReactComponent as StepInProgressIcon } from "../../../components/Icons/StepInProgressIcon.svg";
import { StepNotStartedIcon } from "../../../components/Icons/StepNotStartedIcon";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type EIFSectionStatusIconographyProps = {
  status: EIFStepCompleteStatus;
};

export function EIFSectionStatusIconography({ status }: EIFSectionStatusIconographyProps) {
  switch (status) {
    case "Not Started": {
      return (
        <>
          <span className="sr-only">Section status: Not Started</span>
          <StepNotStartedIcon />
        </>
      );
    }
    case "In Progress": {
      return (
        <>
          <span className="sr-only">Section status: In Progress</span>
          <StepInProgressIcon className="mr-12" aria-hidden />
        </>
      );
    }
    case "Completed": {
      return (
        <>
          <span className="sr-only">Section status: Completed</span>
          <CircleComplete className="mr-12" aria-hidden />
        </>
      );
    }
    default:
      exhaustiveCheckFail(status);
  }
}
