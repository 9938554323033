import { isEmailTableFilters } from "shared/types/Email";
import { getObjectFromURLSearchParams } from "../utils/url";
import { useQueryString } from "./useQueryString";
import type { EmailTableFilters } from "shared/types/Email";

export const useEmailTableFilters = (): EmailTableFilters => {
  const queryString = useQueryString();
  const queryFiltersUntyped = getObjectFromURLSearchParams(queryString);
  const queryFilters = isEmailTableFilters(queryFiltersUntyped) ? queryFiltersUntyped : {};

  const filters = {
    errorsOnly: queryFilters.errorsOnly,
  };

  return filters;
};
