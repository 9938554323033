import { StackY } from "client/src/components/Spacing/Spacing";
import React from "react";

import { FormInput } from "../../../components/Form/Input";
import { SlobSelect } from "../../../components/Form/SlobSelect";

import * as styles from "./addRecipientForm.module.less";

import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type { BasicUserInfo } from "../Modal/AddRecipientModal";
import type { LabeledValue } from "antd/lib/select";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";
import type { addRecipientValidation } from "shared/validation/onboardingForm";

type AddRecipientFormProps = {
  formik: FormikTypeFromValidator<typeof addRecipientValidation>;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  setSearchParam: (searchParam: string) => void;
  slobUsers: BasicUserInfo[];
};

export const AddRecipientForm: React.FC<AddRecipientFormProps> = ({
  formik,
  onboardingFormRecipients,
  setSearchParam,
  slobUsers,
}) => {
  const currentRecipientEmails = onboardingFormRecipients.map(({ email }) => email);

  const newSignerOnboardIdOptions = slobUsers.reduce<Array<LabeledValue>>((options, user) => {
    if (!currentRecipientEmails.includes(user.email)) {
      options.push({
        label: user.name,
        value: user.id,
      });
    }

    return options;
  }, []);

  const newSignerOnboardIdOnChange = (labeledValue: LabeledValue) => {
    void formik.setFieldValue("newSignerOnboardId", labeledValue.value);
  };

  return (
    <div className={styles.addRecipientForm}>
      <StackY dist={16} className="w-full">
        <h3>Select new signer</h3>
        <SlobSelect
          placeholder="New Signer's Name"
          name="newSignerOnboardId"
          value={formik.values.newSignerOnboardId}
          options={newSignerOnboardIdOptions}
          onChange={newSignerOnboardIdOnChange}
          onBlur={formik.handleBlur}
          onInputKeyDown={(event) => setSearchParam(event.currentTarget.value)}
          touched={formik.touched.newSignerOnboardId}
          error={formik.errors.newSignerOnboardId}
        />
        <h3>New signer role</h3>
        <FormInput
          label="Role"
          name="roleName"
          value={formik.values.roleName}
          onChange={formik.handleChange}
          maxLength={191}
          onBlur={formik.handleBlur}
          touched={formik.touched.roleName}
          error={formik.errors.roleName}
        />
        <p className="body3">
          Ensure you have added the user to Onboard first, then choose the user from the drop down
          and click Save to make them an Inside signer on the form.
        </p>
      </StackY>
    </div>
  );
};
