import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { StackY } from "client/src/components/Spacing/Spacing";
import { useState, useCallback, useEffect } from "react";

import { isAddCoverageClient } from "shared/utils/client";
import { formatFullName } from "shared/utils/format";
import { RouteData } from "../../../../../shared/config/routeData";
import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { roleToPretty } from "../../../../../shared/types/User";
import { Anchor } from "../../../components/Anchor/Anchor";
import { Badge } from "../../../components/Badge/Badge";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import {
  ChildPageLayout,
  ChildPageSkeleton,
} from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { HubCard } from "../../../components/HubCard/HubCard";
import { HubCardHeader } from "../../../components/HubCard/HubCardHeader";
import { InternalControl } from "../../../components/InternalControl/InternalControl";
import { Body3, Eyebrow } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { CreateOnboardingFormModal } from "../../../domain/OnboardingForm/CreateOnboardingFormModal";
import { OnboardingFormTile } from "../../../domain/OnboardingForm/OnboardingFormTile";
import {
  getOnboardingFormName,
  sortOnboardingFormByStatus,
} from "../../../domain/OnboardingForm/utils/onboardingFormUtils";
import { useSlobAuth } from "../../../hooks/auth";
import { triggerError } from "../../../hooks/generalError";
import { useGetOnboardingForms } from "../../../hooks/onboardingForms";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";

import * as styles from "./fillOutBenefitsDocuments.module.less";

import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type FillOutBenefitsDocumentsProps = {
  client: Client;
  task: Task;
  featureToggles: ClientFeatureToggles;
};

function getAuthorizedSignerInfo(client: Client): string {
  const { authorizedSignerUser, outsideSigner } = client;
  if (authorizedSignerUser)
    return `${formatFullName(authorizedSignerUser)} (${roleToPretty(authorizedSignerUser.role)})`;
  if (outsideSigner) return `${formatFullName(outsideSigner)} (outside signer)`;
  return "the signing BA";
}

export function FillOutBenefitsDocuments({
  client,
  task,
  featureToggles,
}: FillOutBenefitsDocumentsProps) {
  useTitle("Complete and Sign Onboarding Forms");
  const trackElementClicked = useTrackElementClicked(client, "Onboarding Forms");

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const [isSendFormModalVisible, setIsSendFormModalVisible] = useState(false);
  const [isFinalDocumentForUser, setIsFinalDocumentForUser] = useState(false);

  const sendFormCancelCallback = useCallback(() => {
    track("Cancel");
    setIsSendFormModalVisible(false);
  }, [track, setIsSendFormModalVisible]);
  const {
    isLoadingError: isLoadingOnboardingFormError,
    isError: isOnboardingFormError,
    error: onboardingFormError,
    isLoading,
    isFetching: isFetchingOnboardingForms,
    data: onboardingForms,
    refetch: refetchOnboardingForms,
  } = useGetOnboardingForms(client.id);

  const { authUser } = useSlobAuth();

  const showSendFormModal = useCallback(() => {
    track(onboardingForms?.length ? "Add Another DocuSign Template ID" : "Add Template ID");
    setIsSendFormModalVisible(true);
  }, [track, setIsSendFormModalVisible, onboardingForms]);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    const remainingCount = onboardingForms?.reduce((acc, form) => {
      const currentFormRecipient = form.metadata.recipients.find(
        ({ email }) => email.toLowerCase() === authUser?.email?.toLowerCase(),
      );
      return currentFormRecipient?.status === "COMPLETED" ? acc - 1 : acc;
    }, onboardingForms?.length);
    remainingCount === 0 ? setIsFinalDocumentForUser(true) : setIsFinalDocumentForUser(false);
  }, [onboardingForms, authUser?.email]);

  if (isLoading) {
    return <ChildPageSkeleton />;
  }

  if (isOnboardingFormError || isLoadingOnboardingFormError) {
    return triggerError(onboardingFormError);
  }

  if (!onboardingForms) {
    return triggerError("Could not retrieve the onboarding forms");
  }

  const shouldDisplayDueDate = getShouldDisplayDueDate(task);
  const companyInfoTask = (client.tasks || []).find(
    (t) => t.id === "eif-submit-company-information",
  );
  const showCompanyInfoMissingSigningBanner =
    featureToggles.ONBOARD_MISSING_SIGNING_BANNERS &&
    Boolean(companyInfoTask) &&
    !client.eifSignedAt;
  const authorizedSigner = getAuthorizedSignerInfo(client);

  const sendFormAddCallback = async () => {
    await refetchOnboardingForms();
    setIsSendFormModalVisible(false);
  };

  let sortedOnboardingForms = sortOnboardingFormByStatus(onboardingForms);
  const usingDEIF = client.digitalEIF;
  const isAddCoverage = isAddCoverageClient(client);
  const isInternalUser = getIsInternalUser(authUser);

  if (!isInternalUser) {
    sortedOnboardingForms = sortedOnboardingForms.filter(
      (obForm) => !(obForm.status === "VOIDED" || obForm.status === "DECLINED"),
    );
  }

  return (
    <ChildPageLayout
      topNavTitle="Complete and Sign Onboarding Forms"
      task={task}
      aside={
        <>
          <h1>Complete and Sign Onboarding Forms</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={task} />
              <br />
            </>
          )}

          {usingDEIF ? (
            <>
              <p>
                In addition to submitting your company information for us to review, you and your
                Broker will need to complete and sign these forms. This step is complete after we
                receive and verify the information submitted on your forms.
              </p>
            </>
          ) : isAddCoverage ? (
            <p>
              Please review your company information and confirm that it is up to date. These forms
              are important in helping Sun Life understand you and your needs.
            </p>
          ) : (
            <>
              <p>
                These forms are used to gather some basic information that helps our team understand
                you and your group better and get you set up in Sun Life systems.
              </p>
              <p>
                You and your broker will need to complete and sign the forms in order to submit them
                for review. This task is complete after we receive and verify the information
                submitted on your forms.
              </p>
            </>
          )}
        </>
      }
      contents={
        <>
          {isAddCoverage && !usingDEIF ? (
            <h2>Complete and Sign Onboarding Forms</h2>
          ) : (
            <h2>What are Onboarding Forms?</h2>
          )}
          {usingDEIF ? (
            <>
              <p>
                In addition to{" "}
                <InternalLinkButton
                  type="link-inline"
                  size="middle"
                  to={RouteData.clientTaskDetail.getPath(
                    client.id,
                    "eif-submit-company-information",
                  )}
                >
                  submitting your company information
                </InternalLinkButton>{" "}
                to us for review, you should have reviewed other forms that you'll need to complete
                with your Implementation Consultant. The forms are customized based on your group
                and benefits, but could include:
              </p>

              <UnorderedList>
                <UnorderedListItem>
                  <div>Application</div>
                  <Eyebrow>
                    In the Application, you will confirm your legal policy holder details, effective
                    date, terms of agreement, benefits sold with Sun Life, and authorize that this
                    information is true and accurate.
                  </Eyebrow>
                </UnorderedListItem>
                <UnorderedListItem>
                  W-2 and/or Federal Insurance Contributions Act (FICA) forms
                </UnorderedListItem>
                <UnorderedListItem>Summary Plan Description</UnorderedListItem>
                <UnorderedListItem>Service agreement</UnorderedListItem>
              </UnorderedList>
            </>
          ) : (
            <>
              {isAddCoverage ? (
                <p>
                  In addition to reviewing your company information, you should review other forms
                  to make sure the information provided is up to date. The forms are customized
                  based on your group and benefits, but could include:
                </p>
              ) : (
                <p>
                  You should have reviewed any forms that you’ll need to complete in depth with your
                  Implementation Consultant on your Implementation Kick-off Call. The forms are
                  customized based on your group and benefits, but could include:
                </p>
              )}

              <UnorderedList>
                <UnorderedListItem>Employer Information Form</UnorderedListItem>
                <UnorderedListItem>
                  W-2 and/or Federal Insurance Contributions Act (FICA) forms
                </UnorderedListItem>
                <UnorderedListItem>Summary Plan Description</UnorderedListItem>
                <UnorderedListItem>Application</UnorderedListItem>
                <UnorderedListItem>Service agreement</UnorderedListItem>
              </UnorderedList>
            </>
          )}

          <StackY dist={16}>
            {authUser && (
              <>
                {sortedOnboardingForms.map((onboardingForm, index) => (
                  <OnboardingFormTile
                    key={index}
                    title={getOnboardingFormName(onboardingForm, onboardingForm.formIndex)}
                    userRole={authUser.role}
                    currentUserEmail={authUser?.email ?? ""}
                    currentUserName={authUser?.name ?? ""}
                    onboardingForm={onboardingForm}
                    client={client}
                    isInternalUser={isInternalUser}
                    showAddRecipientButton={true}
                    refetchOnboardingForms={refetchOnboardingForms}
                    trackElementClicked={trackElementClicked}
                    isFinalDocumentForUser={isFinalDocumentForUser}
                    setIsFinalDocumentForUser={setIsFinalDocumentForUser}
                  />
                ))}
              </>
            )}

            {!onboardingForms.length && (
              <HubCard>
                <HubCardHeader
                  title="Onboarding Forms"
                  badge={<Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />}
                  hasDivider
                />
                <Body3 as="div">
                  Nothing to do here just yet. Sun Life will notify you when it’s time to complete
                  this task.
                </Body3>
              </HubCard>
            )}

            {isInternalUser && (
              <InternalControl
                buttonLabel={
                  onboardingForms.length ? "Add Another DocuSign Template ID" : "Add Template ID"
                }
                buttonIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={showSendFormModal}
                position="detached"
              />
            )}

            {showCompanyInfoMissingSigningBanner && (
              <AlertBanner
                variant="warning"
                message={
                  <Body3>
                    Please note that {authorizedSigner} will also need to digitally sign the company
                    information once it is complete.
                    <br />
                    <InternalLinkButton
                      type="link-inline-bold"
                      size="middle"
                      to={RouteData.clientTaskDetail.getPath(
                        client.id,
                        "eif-submit-company-information",
                      )}
                    >
                      Go to Submit Company Information
                    </InternalLinkButton>
                  </Body3>
                }
              />
            )}

            <div className={styles.secondSection}>
              <h3>Tips for completing these forms</h3>

              <p>
                <b className="body2">Learn about DocuSign</b>
                <br />
                If you are new to using DocuSign and need some help getting started, check out our{" "}
                <Anchor
                  href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015553427"
                  target="_blank"
                  onClick={() => track("DocuSign Guide")}
                >
                  guide
                </Anchor>{" "}
                to learn the basics such as navigating and signing.
              </p>

              <p>
                <b className="body2">Need someone else to sign?</b>
                <br />
                If you need to add a colleague to Sun Life Onboard so they can provide their
                signature instead of you, please contact your Implementation Consultant.
                {usingDEIF && (
                  <>
                    <br />
                    <br />
                    Outside signers will receive an email from DocuSign to sign their form outside
                    of Onboard. You will still be able to track their form signing progress within
                    Onboard on this page.
                  </>
                )}
              </p>
              {!usingDEIF && (
                <>
                  <p>
                    <b className="body2">What if I have questions while completing these forms? </b>
                    <br />
                    <Anchor
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015645331"
                      target="_blank"
                      onClick={() => track("Onboarding Forms Resource Center")}
                    >
                      Check out this article on our Help Center
                    </Anchor>
                    <br />
                    This article contains answers to frequently asked questions on a variety of
                    forms such as:
                  </p>
                  <UnorderedList>
                    <UnorderedListItem>
                      What type of information do I need to provide?
                    </UnorderedListItem>
                    <UnorderedListItem>What billing types are available to me?</UnorderedListItem>
                  </UnorderedList>
                </>
              )}
            </div>
          </StackY>
          <CreateOnboardingFormModal
            clientId={client.id}
            isVisible={isSendFormModalVisible}
            addCallback={sendFormAddCallback}
            cancelCallback={sendFormCancelCallback}
            isFetchingOnboardingForms={isFetchingOnboardingForms}
            track={track}
          />
        </>
      }
    />
  );
}
