import { Anchor } from "../../components/Anchor/Anchor";
import { ReactComponent as ShieldIcon } from "../../components/Icons/ShieldIcon.svg";
import { Body5 } from "../../components/Typography/Typography";

import * as styles from "./storedSecurelyText.module.less";

type StoredSecurelyTextProps = {
  track: (buttonLabel: string) => void;
};

export function StoredSecurelyText({ track }: StoredSecurelyTextProps) {
  return (
    <Body5>
      <span className={styles.textWithIcon}>
        <ShieldIcon />
        <span>
          Stored securely using{" "}
          <Anchor
            href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015569555"
            target="_blank"
            onClick={() => track("encryption standards")}
          >
            encryption standards
          </Anchor>
        </span>
      </span>
    </Body5>
  );
}
