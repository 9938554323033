import useLocalStorage from "@rehooks/local-storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import { RouteData } from "shared/config/routeData";
import * as Yup from "yup";
import { minutesToTimeFormat } from "../../../../shared/utils/format";
import { useGetOktaAuth, LOCAL_STORAGE_REMEMBER_USER_KEY } from "../../../src/auth/oktaAuth";
import { Anchor } from "../../components/Anchor/Anchor";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Button } from "../../components/Button/Button";
import { Checkbox } from "../../components/Form/Checkbox";
import { FormInput } from "../../components/Form/Input";
import { SlobLink } from "../../components/SlobLink/SlobLink";
import { StackY } from "../../components/Spacing/Spacing";
import { useFocus } from "../../hooks/useFocus";
import { useTrackElementClicked } from "../../utils/analytics";
import {
  FactorEnrollmentSelection,
  FactorPhoneSetup,
  GoogleAuthenticatorHelper,
} from "./LoginMFASteps";
import * as styles from "./login.module.less";
import type { Factor } from "../../../src/auth/oktaAuth";
import type { AuthnTransaction } from "@okta/okta-auth-js";
import type { InputRef } from "antd";
import "shared/validation/email";

const loginFormValidation = Yup.object({
  email: Yup.string().emailV1("Must be a valid email").required("Email Address is required"),
  password: Yup.string().required("Password is required"),
});

const MFAFormValidation = Yup.object({
  otp: Yup.string().required("Passcode is required"),
  rememberDevice: Yup.bool(),
});

const factorTypeToMainType = {
  call: "phone",
  sms: "phone",
  "token:software:totp": "google",
  question: "google",
  "": "google",
};

// @todo: check existing session and redirect if so? https://github.com/okta/okta-auth-js/blob/HEAD/docs/authn.md#txexists

type LoginPanels =
  | "signin"
  | "MFAChallenge"
  | "MFAGoogleAppSelection"
  | "MFAEnrollmentSelection"
  | "MFAEnrollSetupGoogle"
  | "MFAEnrollSetupPhoneBased"
  | "NeedHelp";
type MFAChallengeType = "challenge" | "setup";
const defaultLocalStorageRememberUser = "";

const ChallengeHeader = ({
  factor,
  isChallengeSetup,
}: {
  factor: Factor | undefined;
  isChallengeSetup: boolean;
}) => {
  const typePhone = factor?.factorType === "call" || factor?.factorType === "sms";
  const typeAuthenticator = factor?.factorType === "token:software:totp";
  return (
    <>
      {typeAuthenticator && (
        <>
          <h2>{isChallengeSetup ?? "Setup "}Authenticator</h2>
          {isChallengeSetup && <p>Enter code displayed from the application</p>}
        </>
      )}
      {typePhone && (
        <>
          <h2>{isChallengeSetup ?? "Setup "}Phone Authentication</h2>
          <p>Enter code sent to: {factor.profile?.phoneNumber}</p>
        </>
      )}
    </>
  );
};

export const LoginWindow = ({ emailToPopulate }: { emailToPopulate?: string }) => {
  const [loginPanel, setLoginPanel] = useState<LoginPanels>("signin");
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const [rememberDeviceTime, setRememberDeviceTime] = useState("");
  const [transaction, setTransaction] = useState<AuthnTransaction>();
  const [MFAFactor, setMFAFactor] = useState<Factor>();
  const [MFAfactorType, setMFAfactorType] = useState<Factor["factorType"] | "">("");
  const [MFAFactorsToEnroll, setMFAFactorsToEnroll] = useState<Factor[]>();
  const [MFAChallengeType, setMFAChallengeType] = useState<MFAChallengeType>("challenge");
  const trackElementClicked = useTrackElementClicked();

  const [localStorageRememberMeUser] = useLocalStorage(
    LOCAL_STORAGE_REMEMBER_USER_KEY,
    defaultLocalStorageRememberUser,
  );

  const [processError, setProcessError] = useState<React.ReactNode>("");
  const autofillEmail = emailToPopulate || localStorageRememberMeUser;

  const emailInput = useFocus<InputRef>(loginPanel === "signin" && !autofillEmail);
  const passwordInput = useFocus<InputRef>(loginPanel === "signin" && Boolean(autofillEmail));
  const passcodeInput = useFocus<InputRef>(loginPanel === "MFAChallenge");

  const { oktaStep, isLoading: isLoadingAuth } = useGetOktaAuth();

  const resetFields = () => {
    formikLogin.resetForm();
    formikMFA.resetForm();
  };

  const onSelectFactor = (factorType: Factor["factorType"]) => {
    setMFAFactor(MFAFactorsToEnroll?.find((f) => f.factorType === factorType));
  };

  const onFactorEnrollmentSelection = async (factorType: Factor["factorType"]) => {
    switch (factorType) {
      case "token:software:totp":
        if (MFAFactor) {
          await submitGoogleEnroll(MFAFactor);
        }
        break;
      case "call":
      case "sms":
        return setLoginPanel("MFAEnrollSetupPhoneBased");
    }
  };

  const submitGoogleEnroll = async (factor: Factor) => {
    const {
      status,
      mfaOptions,
      error,
      transaction: processTransaction,
    } = await oktaStep.enrollMFAGoogle(factor);
    if (error) {
      return setProcessError(getErrorMessage(error));
    }
    if (status === "MFA_ENROLL_ACTIVATE") {
      resetFields();
      if (!mfaOptions?.factor?.activation?.qrcode)
        return setProcessError("Missing QR code or activation for Factor");
      setMFAfactorType(mfaOptions?.factor?.factorType || "");
      setMFAFactor(mfaOptions?.factor);
      setTransaction(processTransaction);
      setLoginPanel("MFAGoogleAppSelection");
    }
  };

  const submitPhoneBasedEnroll = async (factor: Factor | undefined, phoneNumber: string) => {
    if (!factor) {
      return setProcessError("No MFA Factor selected");
    }
    const {
      status,
      mfaOptions,
      error,
      transaction: processTransaction,
    } = await oktaStep.enrollMFAPhoneBased(factor, phoneNumber);
    if (error) {
      return setProcessError(getErrorMessage(error));
    }
    if (status === "MFA_ENROLL_ACTIVATE") {
      resetFields();
      setMFAChallengeType("setup");
      setMFAFactor(mfaOptions?.factor);
      setMFAfactorType(mfaOptions?.factor?.factorType || "");
      setTransaction(processTransaction);
      setLoginPanel("MFAChallenge");
    }
  };

  const requestInitialPhoneCode = async (factor: Factor) => {
    if (!factor) {
      return setProcessError("No MFA Factor selected");
    }
    const {
      status,
      mfaOptions,
      error,
      transaction: processTransaction,
    } = await oktaStep.requestPhoneCode(factor);
    if (error) {
      return setProcessError(getErrorMessage(error));
    }
    if (status === "MFA_CHALLENGE") {
      resetFields();
      setMFAFactor(mfaOptions?.factor);
      setMFAfactorType(mfaOptions?.factor?.factorType || "");
      setTransaction(processTransaction);
      setLoginPanel("MFAChallenge");
    }
  };

  const resendPhoneCode = async () => {
    if (!transaction) {
      return setProcessError("Missing transaction when requesting new code");
    }
    if (MFAfactorType !== "sms" && MFAfactorType !== "call") {
      return setProcessError("Can't request code from non phone MFA factor");
    }
    setIsRequestingCode(true);
    const {
      status,
      mfaOptions,
      error,
      transaction: processTransaction,
    } = await oktaStep.resendPhoneCode(transaction, MFAfactorType);
    if (error) {
      setProcessError(getErrorMessage(error));
    }
    if (status === "MFA_ENROLL_ACTIVATE") {
      setMFAChallengeType("setup");
    }
    if (status === "MFA_CHALLENGE" || status === "MFA_ENROLL_ACTIVATE") {
      resetFields();
      setMFAFactor(mfaOptions?.factor);
      setMFAfactorType(mfaOptions?.factor?.factorType || "");
      setTransaction(processTransaction);
      setLoginPanel("MFAChallenge");
    }
    setIsRequestingCode(false);
  };

  const getErrorMessage = (error: string) => {
    switch (error) {
      case "Authentication failed":
        return "You entered an incorrect email address, password, or both. Please try again.";
      case "Invalid Passcode/Answer":
        return "You entered an invalid code. Please try again.";
      case "The JWT was issued in the future":
        return (
          <div>
            Your computer clock is set more than 5 minutes from the Universal Time Clock. You’ll
            need to reset your computer clock to the proper time before you can continue in the
            system.{" "}
            <Anchor
              target="_blank"
              href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403022646547-Logging-in-to-your-account-in-Sun-Life-Onboard#h_01GCY81YGS3GZYJXRY74N3HWVS"
            >
              Learn more
            </Anchor>
          </div>
        );
      default:
        return error;
    }
  };

  const formikLogin = useFormik({
    initialValues: {
      email: autofillEmail || "",
      password: "",
      rememberMe: Boolean(!emailToPopulate && autofillEmail),
    },
    validationSchema: loginFormValidation,
    async onSubmit({ email, password, rememberMe }) {
      setProcessError("");
      const {
        status,
        mfaOptions,
        mfaFactorsToEnroll,
        error,
        transaction: processTransaction,
      } = await oktaStep.signIn({ username: email.trim(), password, rememberMe });
      if (error) {
        return setProcessError(getErrorMessage(error));
      }
      if (status === "MFA_REQUIRED") {
        resetFields();
        setMFAFactor(mfaOptions?.factor);
        setTransaction(processTransaction);
        setMFAfactorType(mfaOptions?.factor?.factorType || "");
        if (mfaOptions?.factor?.factorType === "sms" || mfaOptions?.factor?.factorType === "call") {
          return requestInitialPhoneCode(mfaOptions?.factor);
        }
        const rememberDeviceLifetimeInMinutes = mfaOptions?.rememberDeviceLifetimeInMinutes;
        if (rememberDeviceLifetimeInMinutes)
          setRememberDeviceTime(minutesToTimeFormat(rememberDeviceLifetimeInMinutes));
        setLoginPanel("MFAChallenge");
      }
      if (status === "MFA_ENROLL") {
        resetFields();
        setMFAFactorsToEnroll(mfaFactorsToEnroll);
        setTransaction(processTransaction);
        if (mfaFactorsToEnroll?.length === 1 && mfaFactorsToEnroll[0]) {
          onSelectFactor(mfaFactorsToEnroll[0].factorType);
          setMFAFactor(mfaFactorsToEnroll[0]);
          await onFactorEnrollmentSelection(mfaFactorsToEnroll[0].factorType);
        } else {
          setLoginPanel("MFAEnrollmentSelection");
        }
      }
      // Only happening if there's only one factor option (needs to be Google)
      if (status === "MFA_ENROLL_ACTIVATE") {
        resetFields();
        if (!mfaOptions?.factor?.activation?.qrcode)
          return setProcessError("Missing QR code or activation for Factor");
        setMFAFactor(mfaOptions?.factor);
        setMFAfactorType(mfaOptions?.factor?.factorType || "");
        setTransaction(processTransaction);
        setLoginPanel("MFAGoogleAppSelection");
      }
      // Possibly take advantage of SUCCESS status to show something right before redirecting
      if (status === "SUCCESS") {
        return;
      }
    },
  });

  const formikMFA = useFormik({
    initialValues: {
      otp: "",
      rememberDevice: false,
    },
    validationSchema: MFAFormValidation,
    async onSubmit({ otp, rememberDevice }) {
      setProcessError("");
      if (!MFAFactor) return;
      if (MFAChallengeType === "setup" && transaction) {
        const { error } = await oktaStep.setupMFA(otp, transaction);
        if (error) return setProcessError(getErrorMessage(error));
      } else {
        const mainType = factorTypeToMainType[MFAfactorType];
        if (mainType === "google") {
          const { error } = await oktaStep.verifyGoogleMFA(otp, MFAFactor, rememberDevice);
          if (error) return setProcessError(getErrorMessage(error));
        }
        if (mainType === "phone" && transaction) {
          const { error } = await oktaStep.verifyPhoneMFA(otp, transaction, rememberDevice);
          if (error) return setProcessError(getErrorMessage(error));
        }
      }
    },
  });

  const isChallengeSetup = MFAChallengeType === "setup";

  return (
    <div className={styles.loginContainer}>
      {processError && (
        <div className="mb-24">
          <AlertBanner variant="error" message={processError} />
        </div>
      )}
      {loginPanel === "signin" && (
        <div className={styles.loginWindow}>
          <form onSubmit={formikLogin.handleSubmit}>
            <StackY dist={24}>
              <h2>Sign in to Sun Life Onboard</h2>
              <FormInput
                value={formikLogin.values.email}
                maxLength={191}
                ref={emailInput}
                label={"Email Address"}
                name={"email"}
                touched={formikLogin.touched.email}
                disabled={isLoadingAuth}
                onChange={formikLogin.handleChange}
                error={formikLogin.errors.email}
                autoComplete="username"
                onBlur={(e) => {
                  void formikLogin.setFieldValue("email", e.target.value.trim(), true);
                }}
              />
              <FormInput
                value={formikLogin.values.password}
                maxLength={191}
                ref={passwordInput}
                label={"Password"}
                name={"password"}
                touched={formikLogin.touched.password}
                disabled={isLoadingAuth}
                onChange={formikLogin.handleChange}
                error={formikLogin.errors.password}
                isPassword={true}
                autoComplete="current-password"
              />
              <Checkbox
                label={`Remember me`}
                name="rememberMe"
                checked={formikLogin.values.rememberMe}
                onChange={formikLogin.handleChange}
                disabled={formikLogin.isSubmitting}
              />
              <Button
                type="primary"
                size="large"
                disabled={
                  !formikLogin.dirty || !!formikLogin.errors.email || !!formikLogin.errors.password
                }
                loading={formikLogin.isSubmitting}
                block
                htmlType="submit"
              >
                Sign In
              </Button>
            </StackY>
          </form>
          <p className={styles.needhelp}>
            <Anchor
              onClick={() => {
                trackElementClicked({ module: "Login", buttonLabel: "Having trouble signing in" });
                setLoginPanel("NeedHelp");
              }}
            >
              Having trouble signing in?
            </Anchor>
          </p>
        </div>
      )}
      {loginPanel === "MFAChallenge" && (
        <div className={styles.loginWindow}>
          <form onSubmit={formikMFA.handleSubmit}>
            <StackY dist={24}>
              <ChallengeHeader factor={MFAFactor} isChallengeSetup={isChallengeSetup} />
              <FormInput
                value={formikMFA.values.otp}
                maxLength={6}
                ref={passcodeInput}
                label={"Enter your passcode"}
                // otp as the name allows 1password to autofill the mfa code
                // undocumented at https://developer.1password.com/docs/web/compatible-website-design/ but it works
                name={"otp"}
                touched={formikMFA.touched.otp}
                disabled={isLoadingAuth}
                onChange={formikMFA.handleChange}
                error={formikMFA.errors.otp}
                autoComplete="on"
              />
              {rememberDeviceTime && (
                <Checkbox
                  label={`Do not challenge me on this device for the next ${rememberDeviceTime}`}
                  name="rememberDevice"
                  checked={formikMFA.values.rememberDevice}
                  onChange={formikMFA.handleChange}
                  disabled={formikMFA.isSubmitting}
                />
              )}
              <Button
                type="primary"
                size="middle"
                block
                loading={formikMFA.isSubmitting}
                disabled={!formikMFA.dirty || !!formikMFA.errors.otp || isRequestingCode}
                htmlType="submit"
              >
                Verify
              </Button>
              {transaction?.resend && (
                <div className={styles.backLink}>
                  <Button
                    type="text-only"
                    loading={isRequestingCode}
                    disabled={!transaction}
                    onClick={async () => {
                      await resendPhoneCode();
                    }}
                  >
                    Resend Code
                  </Button>
                </div>
              )}
              <div className={styles.backLink}>
                <Button
                  type="text-only"
                  disabled={formikMFA.isSubmitting}
                  onClick={() => {
                    setProcessError("");
                    setLoginPanel("signin");
                  }}
                >
                  Back to Sign In
                </Button>
              </div>
            </StackY>
          </form>
        </div>
      )}
      {loginPanel === "MFAGoogleAppSelection" && (
        <GoogleAuthenticatorHelper
          next={() => setLoginPanel("MFAEnrollSetupGoogle")}
          back={() => {
            setProcessError("");
            setLoginPanel("signin");
          }}
        />
      )}
      {loginPanel === "MFAEnrollmentSelection" && (
        <FactorEnrollmentSelection
          isLoading={isLoadingAuth}
          processError={processError}
          next={async (factorType) => {
            await onFactorEnrollmentSelection(factorType);
          }}
          back={() => {
            setProcessError("");
            setLoginPanel("signin");
          }}
          MFAFactorsToEnroll={MFAFactorsToEnroll}
          onFactorSelect={(factorType) => {
            onSelectFactor(factorType);
          }}
        />
      )}
      {loginPanel === "MFAEnrollSetupPhoneBased" && (
        <FactorPhoneSetup
          isLoading={isLoadingAuth}
          processError={processError}
          next={async (phoneNumber) => {
            await submitPhoneBasedEnroll(MFAFactor, phoneNumber);
          }}
          back={() => {
            setProcessError("");
            setLoginPanel("signin");
          }}
        />
      )}
      {loginPanel === "MFAEnrollSetupGoogle" && (
        <div className={styles.loginWindow}>
          <StackY dist={24}>
            <h2>Setup Google Authenticator</h2>
            <p>Launch Google Authenticator, tap the "+" icon, then select "Scan QR code".</p>
            <img
              className={styles.qrcode}
              src={MFAFactor?.activation?.qrcode?.href ?? ""}
              alt="qrcode"
            />
            <Button
              type="primary"
              size="middle"
              block
              onClick={() => {
                setMFAChallengeType("setup");
                setLoginPanel("MFAChallenge");
              }}
            >
              Next
            </Button>
            <div className={styles.backLink}>
              <Button
                type="text-only"
                onClick={() => {
                  setProcessError("");
                  setLoginPanel("signin");
                }}
              >
                Back to Sign In
              </Button>
            </div>
          </StackY>
        </div>
      )}
      {loginPanel === "NeedHelp" && (
        <div className={styles.loginWindow}>
          <StackY dist={16}>
            <h2>How can we help?</h2>
            <div className={styles.helpBlock}>
              <h3 className={styles.helpHeader}>I forgot my password</h3>
              <p>
                It happens to the best of us! Don't worry, we can help you reset your password
                securely.
              </p>
              <SlobLink
                variant="bold"
                to={RouteData.forgot.getPath()}
                onClick={() =>
                  trackElementClicked({ module: "Need Help", buttonLabel: "Reset my password" })
                }
              >
                Reset my password
              </SlobLink>
            </div>

            <div className={styles.helpBlock}>
              <h3 className={styles.helpHeader}>I'm new to Sun Life Onboard</h3>
              <p>
                We can send you a new activation link so you can continue setting up your Sun Life
                Onboard account.
              </p>

              <SlobLink
                variant="bold"
                to={RouteData.reactivate.getPath()}
                onClick={() =>
                  trackElementClicked({
                    module: "Need Help",
                    buttonLabel: "Request activation link",
                  })
                }
              >
                Request activation link
              </SlobLink>
            </div>

            <Button
              type="primary"
              size="large"
              block
              onClick={() => {
                trackElementClicked({ module: "Need Help", buttonLabel: "Return to sign in" });
                setLoginPanel("signin");
              }}
            >
              Return to sign in
            </Button>

            <div className={styles.needhelp}>
              Still need help? Contact{" "}
              <Anchor
                href="mailto:support@onboard.sunlife.com"
                onClick={() =>
                  trackElementClicked({ module: "Need Help", buttonLabel: "Support email" })
                }
              >
                support@onboard.sunlife.com
              </Anchor>
            </div>
          </StackY>
        </div>
      )}
    </div>
  );
};
