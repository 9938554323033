import { JiraSyncDetails } from "client/src/domain/Client/ClientErrors/details/JiraSyncDetails";
import { useEffect, useState } from "react";
import { Anchor } from "../../../components/Anchor/Anchor";
import { Button } from "../../../components/Button/Button";
import { Col, Row } from "../../../components/Grid/Grid";
import { SlobDrawer } from "../../../components/SlobDrawer/SlobDrawer";
import { clientErrorLabel } from "../../../hooks/clientError";
import { AbandonedCensusDetails } from "./details/AbandonedCensusDetails";
import { AccountNotActivatedDetails } from "./details/AccountNotActivatedDetails";
import { WelcomeEmailDetails } from "./details/WelcomeEmailDetails";
import type { ClientError, ClientErrorType } from "@prisma/client";
import type { FC } from "react";
import type { Client } from "shared/types/Client";

export type ClientErrorDrawerData = {
  errorType: ClientErrorType;
  data: ClientError[];
  client: Client;
};

type ClientErrorsDrawerProps = {
  data?: ClientErrorDrawerData;
  jiraURL: string;
  onClose: () => void;
};

const submitTicketClientErrorTypes: ClientErrorType[] = ["ACCOUNT_NOT_ACTIVATED", "WELCOME_EMAIL"];

const getContent = (
  { errorType, data, client }: ClientErrorDrawerData,
  jiraURL: string,
  onClose: () => void,
) => {
  switch (errorType) {
    case "ABANDONED_CENSUS":
      return <AbandonedCensusDetails data={data} />;
    case "ACCOUNT_NOT_ACTIVATED":
      return <AccountNotActivatedDetails data={data} clientId={client.id} />;
    case "WELCOME_EMAIL":
      return <WelcomeEmailDetails data={data} clientId={client.id} />;
    case "JIRA_SYNC":
      return <JiraSyncDetails data={data} client={client} jiraURL={jiraURL} onClose={onClose} />;
    default:
      return <p>Unknown error type {errorType}</p>;
  }
};

export const ClientErrorsDrawer: FC<ClientErrorsDrawerProps> = ({ data, jiraURL, onClose }) => {
  const [cachedData, setCachedData] = useState<ClientErrorDrawerData>();

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- remeber data while drawer is closing
  useEffect(() => {
    if (data) {
      setCachedData(data);
    }
  }, [data]);

  return (
    <SlobDrawer
      width={705}
      visible={!!data}
      onClose={onClose}
      title={cachedData && `${clientErrorLabel[cachedData.errorType]} help`}
      children={cachedData ? getContent(cachedData, jiraURL, onClose) : <div />}
      footerInner={
        <Row justify="end" align="middle" gutter={24}>
          {data?.errorType && submitTicketClientErrorTypes.includes(data?.errorType) && (
            <Col>
              <Anchor
                href={`${jiraURL}/browse/${data?.client.ticketId}`}
                target="_blank"
                variant="bold"
              >
                Submit a ticket
              </Anchor>
            </Col>
          )}
          <Col>
            <Button type="primary" size="middle" onClick={onClose}>
              Close
            </Button>
          </Col>
        </Row>
      }
    />
  );
};
