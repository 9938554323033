import type { TaskId } from "../data/Tasks";
import type { CensusSource, ProcessStatus } from "../validation/document";
import type { ClientId, PolicyId } from "./Client";
import type { DocumentBenefitType } from "./DocumentBenefitType";
import type { ValueOf } from "./Helper";
import type { User, UserId } from "./User";
import type { Readable } from "stream";

export type DocumentId = string;
export interface Document {
  id: DocumentId;
  clientId: ClientId;
  name: string;
  documentKey: string;
  category: DocumentCategory;
  mimeType: string;
  vdrDocumentId: DocumentId | null;
  sizeInBytes: number;
  policyIds: PolicyId[];
  benefitTypes: DocumentBenefitType[] | null;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedBy: string | null;
  deletedAt: Date | null;
  createdByUser?: User | null;
  updatedByUser?: User | null;
  deletedByUser?: User | null;
  language: DocumentLanguage;
}

export interface CensusDocument extends Document {
  censusSource: CensusSource | null;
  censusSourceOther: string | null;
  processStatus: ProcessStatus | null;
  platform: string | null;
}

export interface FileInput {
  name: string;
  originalName: string;
  mimeType: string;
  buffer: Buffer;
  sizeInBytes: number;
}

export interface DocumentContentWrapper {
  filename: string;
  contentStream: Readable;
  category?: DocumentCategory;
  language?: DocumentLanguage;
  benefitTypes?: DocumentBenefitType[] | null;
  policyIds?: PolicyId[];
  size?: number;
}

export interface DocumentDownloadUrl {
  filename: string;
  objectUrl: string;
}

export const DocumentCategories = [
  "prior-carrier-bills",
  "prior-carrier-cert",
  "enrollment-elections",
  "census-template",
  "benefit-summaries",
  "explorer-benefit-summaries",
  "benefit-rate-sheets",
  "enrollment-forms",
  "onboarding-forms",
  "custom-enrollment-materials",
  "client-logo",
  "zipped-documents",
  "voluntary-deduction-report",
  "voluntary-deduction-report-marked",
  "summary-plan-description",
  "plan-exemption-document-pfml",
  "summary-plan-description-pfml-connecticut",
  "summary-plan-description-pfml-colorado",
  "summary-plan-description-pfml-massachusetts",
  "summary-plan-description-pfml-oregon",
  "summary-plan-description-pfml-washington",
  "id-cards-and-online-access",
  "value-added-services",
  "billing-summary-statement-template",
  "employee-classes",
  "eif",
  "medical-video",
  "actively-at-work",
  "dental-rollover",
  "group-life-accelerated-benefits",
  "deductible-maximum-report",
] as const;

export const documentCategoriesOnlyByInternalUser: ReadonlyArray<DocumentCategory> = [
  "benefit-summaries",
  "explorer-benefit-summaries",
  "benefit-rate-sheets",
  "enrollment-forms",
  "custom-enrollment-materials",
  "census-template",
  "onboarding-forms",
  "employee-classes",
];

export type DocumentCategory = (typeof DocumentCategories)[number];

export type EnrollmentResourcesCategory = Extract<
  DocumentCategory,
  | "benefit-summaries"
  | "enrollment-forms"
  | "benefit-rate-sheets"
  | "id-cards-and-online-access"
  | "custom-enrollment-materials"
  | "value-added-services"
  | "voluntary-deduction-report"
  | "voluntary-deduction-report-marked"
  | "group-life-accelerated-benefits"
>;

export const EnrollmentResourcesCategories: EnrollmentResourcesCategory[] = [
  "benefit-summaries",
  "benefit-rate-sheets",
  "enrollment-forms",
  "custom-enrollment-materials",
  "id-cards-and-online-access",
  "value-added-services",
  "voluntary-deduction-report",
  "voluntary-deduction-report-marked",
  "group-life-accelerated-benefits",
];

export type EnrollmentResourcesCategoryNoVDR = Extract<
  DocumentCategory,
  | "benefit-summaries"
  | "enrollment-forms"
  | "benefit-rate-sheets"
  | "id-cards-and-online-access"
  | "custom-enrollment-materials"
  | "value-added-services"
  | "group-life-accelerated-benefits"
>;

export const EnrollmentResourcesCategoriesNoVDR: EnrollmentResourcesCategoryNoVDR[] = [
  "benefit-summaries",
  "benefit-rate-sheets",
  "enrollment-forms",
  "custom-enrollment-materials",
  "id-cards-and-online-access",
  "value-added-services",
  "group-life-accelerated-benefits",
];

export const categoryNameByCategoryType: Record<DocumentCategory, string> = {
  "benefit-rate-sheets": "Benefit Rate Sheets",
  "benefit-summaries": "Benefit Summaries",
  "explorer-benefit-summaries": "Benefit Summaries",
  "census-template": "Census Template",
  "client-logo": "Client Logo",
  "custom-enrollment-materials": "Custom Resources",
  "enrollment-elections": "Enrollment Election",
  "enrollment-forms": "Enrollment Forms",
  "onboarding-forms": "Onboarding Forms",
  "prior-carrier-bills": "Prior Carrier Bills",
  "prior-carrier-cert": "Prior Carrier Certificates",
  "zipped-documents": "Zipped Documents",
  "voluntary-deduction-report": "Voluntary Deduction Report",
  "voluntary-deduction-report-marked": "Marked Voluntary Deduction Report",
  "summary-plan-description": "Summary Plan Description",
  "plan-exemption-document-pfml": "Plan Exemption Document for PFML",
  "summary-plan-description-pfml-connecticut": "Connecticut Summary Plan Description for PFML",
  "summary-plan-description-pfml-colorado": "Colorado Summary Plan Description for PFML",
  "summary-plan-description-pfml-massachusetts": "Massachusetts Summary Plan Description for PFML",
  "summary-plan-description-pfml-oregon": "Oregon Summary Plan Description for PFML",
  "summary-plan-description-pfml-washington": "Washington Summary Plan Description for PFML",
  "id-cards-and-online-access": "ID Cards and Online Access",
  "value-added-services": "Value-added Services & EAP",
  "billing-summary-statement-template": "Billing Summary Statement template",
  "employee-classes": "Employee Classes",
  eif: "EIF Summary",
  "medical-video": "Medical Video",
  "actively-at-work": "Actively At Work",
  "dental-rollover": "Dental Rollover",
  "group-life-accelerated-benefits": "Group Life Accelerated Benefits",
  "deductible-maximum-report": "Deductible Maximum Report",
};

export const documentCategoryToTask: Record<DocumentCategory, TaskId | null> = {
  "benefit-rate-sheets": null, // resources (not a task)
  "benefit-summaries": null, // resources (not a task)
  "explorer-benefit-summaries": null, // benefits explorer resource
  "census-template": "enrollment-elections",
  "client-logo": null, // ben explorer
  "custom-enrollment-materials": null, // resources (not a task)
  "enrollment-elections": "enrollment-elections",
  "enrollment-forms": "onboarding-forms",
  "onboarding-forms": "onboarding-forms",
  "prior-carrier-bills": "prior-carrier-bills",
  "prior-carrier-cert": "prior-carrier-certificates",
  "zipped-documents": null,
  "voluntary-deduction-report": "enrollment-elections",
  "voluntary-deduction-report-marked": "enrollment-elections",
  "summary-plan-description": "sunadvisor-spd",
  "plan-exemption-document-pfml": "pfml",
  "summary-plan-description-pfml-connecticut": "pfml-spd",
  "summary-plan-description-pfml-colorado": "pfml-spd",
  "summary-plan-description-pfml-massachusetts": "pfml-spd",
  "summary-plan-description-pfml-oregon": "pfml-spd",
  "summary-plan-description-pfml-washington": "pfml-spd",
  "id-cards-and-online-access": null, // resources (not a task)
  "value-added-services": null, // resources (not a task)
  "billing-summary-statement-template": null,
  "employee-classes": "eif-submit-company-information",
  eif: "eif-submit-company-information",
  "medical-video": null, // ben explorer
  "actively-at-work": "enrollment-elections",
  "dental-rollover": "dental-rollover",
  "group-life-accelerated-benefits": null,
  "deductible-maximum-report": "deductible-maximum-report",
};

export const duplicateErrorMessage = "Document with matching name already exists for this client";
export const doctaErrorMessage = "Document rejected, failed our virus scan check";
export const genericDocumentUploadError =
  "Document failed to upload.  Please try again shortly or contact support@onboard.sunlife.com.";

export type NewDocumentNotice = {
  documentId: DocumentId;
  userId: UserId;
  newDocumentNoticeStatus: "NEW_AND_NOT_SEEN" | "NEW_AND_SEEN" | "OLD";
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  document: Document;
};

export const documentCategoriesThatNeedNotices: Record<DocumentCategory, boolean> = {
  "benefit-rate-sheets": true,
  "benefit-summaries": true,
  "explorer-benefit-summaries": false,
  "client-logo": false,
  "census-template": true,
  "custom-enrollment-materials": true,
  "enrollment-elections": true,
  "enrollment-forms": true,
  "onboarding-forms": true,
  "prior-carrier-bills": true,
  "prior-carrier-cert": true,
  "zipped-documents": true,
  "voluntary-deduction-report": true,
  "voluntary-deduction-report-marked": true,
  "summary-plan-description": true,
  "plan-exemption-document-pfml": true,
  "summary-plan-description-pfml-connecticut": true,
  "summary-plan-description-pfml-colorado": true,
  "summary-plan-description-pfml-massachusetts": true,
  "summary-plan-description-pfml-oregon": true,
  "summary-plan-description-pfml-washington": true,
  "id-cards-and-online-access": true,
  "value-added-services": true,
  "billing-summary-statement-template": false,
  "employee-classes": true,
  eif: false,
  "medical-video": false,
  "actively-at-work": false,
  "dental-rollover": false,
  "group-life-accelerated-benefits": true,
  "deductible-maximum-report": true,
};

export function isDocumentCategory(category: string): category is DocumentCategory {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isDocumentCategory = DocumentCategories.includes(category as DocumentCategory);
  return isDocumentCategory;
}

export const DocumentLanguages = ["EN", "ES"] as const;

export type DocumentLanguage = ValueOf<typeof DocumentLanguages>;
