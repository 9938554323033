import { HubCard } from "../../components/HubCard/HubCard";

import * as styles from "./activationCard.module.less";

type ActivationCardProps = {
  children: React.ReactNode;
};

export const ActivationCard = ({ children }: ActivationCardProps) => (
  <div className={styles.background}>
    <HubCard variant="activation">{children}</HubCard>
  </div>
);
