import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { TriAgencyDisclosure } from "client/src/components/TriAgencyDisclosure/TriAgencyDisclosure";
import { Body5 } from "client/src/components/Typography/Typography";
import { ExplorerBenefitCustomMedicalVideo } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerBenefitCustomMedicalVideo";
import { ExplorerPlanComparisonTableWVClientDental } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerPlanComparisonTableWVClientDental";

import { copyKey, copyKeyExists } from "client/src/domain/ExplorerPages/utils";
import { useEmployeeLocation } from "client/src/hooks/useSlobLocalStorage";
import { Trans, useTranslation } from "client/src/i18n";
import { useTrackElementViewed } from "client/src/utils/analytics";
import { forwardRef } from "react";
import { isSunlifeCarrier } from "shared/types/ExplorerPageBenefit";

import { ExplorerBenefitADD } from "./ExplorerBenefitADD";
import { ExplorerBenefitCoverageCalculator } from "./ExplorerBenefitCoverageCalculator";
import { ExplorerBenefitFaq } from "./ExplorerBenefitFaq";
import { ExplorerBenefitFindDoctor } from "./ExplorerBenefitFindDoctor";
import { ExplorerBenefitHelpfulHint } from "./ExplorerBenefitHelpfulHint";
import { ExplorerBenefitHowMuchCoverage } from "./ExplorerBenefitHowMuchCoverage";
import { ExplorerBenefitHowToUsePlan } from "./ExplorerBenefitHowToUsePlan";
import { ExplorerBenefitOverview } from "./ExplorerBenefitOverview";
import { ExplorerBenefitPlans } from "./ExplorerBenefitPlans";
import { ExplorerBenefitRealWorld } from "./ExplorerBenefitRealWorld";
import { ExplorerBenefitVideo } from "./ExplorerBenefitVideo";
import { ExplorerBenefitWellness } from "./ExplorerBenefitWellness";
import { ExplorerBenefitWhyNeedIt } from "./ExplorerBenefitWhyNeedIt";
import { ExplorerPlanComparisonTable } from "./ExplorerPlanComparisonTable";

import type {
  ElementViewedOptions,
  useExplorerTrackElementClicked,
  useExplorerTrackElementChanged,
} from "client/src/utils/analytics";
import type { Namespace, TFunction } from "i18next";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerBenefitTypeMetadata } from "shared/types/ExplorerPage";
import type { ContentFlag, ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "shared/types/Location";

export const DataTestId = {
  BenefitWrapper: "benefit-wrapper",
};

export type ExplorerBenefitCopyStructure = {
  [benefit in BenefitTypeBenEx]: {
    legal: string;
    legal_nonSL?: string;
    disclaimer_1?: string;
    disclaimer_2?: string;
    disclaimer_many?: string;
    disclaimer_prefix?: string;
    PLAN_COMPARISON?: {
      legal: string;
      legal_nonSL?: string;
      disclaimer_1?: string;
      disclaimer_2?: string;
      disclaimer_many?: string;
      disclaimer_prefix?: string;
    };
  };
};

/**
 * The props for {@link ExplorerBenefit} component.
 */
type ExplorerBenefitProps = {
  benefitType: BenefitTypeBenEx;
  otherBenefitTypes?: BenefitTypeBenEx[];
  plans: ExplorerPageBenefit[];
  metadata?: ExplorerBenefitTypeMetadata;
  carrierId: number | null;
  kioskMode: boolean;
  employerState: LocationStateCode;
  clientName?: string | null;
  confirmDeselection?: boolean;
  onPlanDeselected?: (plan: ExplorerPageBenefit) => void;
  trackElementClicked?: ReturnType<typeof useExplorerTrackElementClicked>;
  trackElementChanged?: ReturnType<typeof useExplorerTrackElementChanged>;
  trackElementViewedOptions: ElementViewedOptions;
  expiresAt?: Date | null;
  /**
   * Date when the explorer page was created.
   */
  createdAt: Date;
  /**
   * Whether to show the legacy vision plan comparison table.
   */
  showLegacyVisionPlanTable?: boolean;
  /**
   * Whether to show the legacy dental plan comparison table.
   */
  showLegacyDentalPlanTable?: boolean;
  largeClientFeatureToggle?: boolean;
  medicalPlanComparisonFeatureToggle?: boolean;
  visionPlanComparisonFeatureToggle?: boolean;
  dentalPlanComparisonFeatureToggle?: boolean;
  fsaRolloverFeatureToggle?: boolean;
  vasUpdateFeatureToggle?: boolean;
  dentalPlansCombinedFeatureToggle?: boolean;
  customBrandingFeatureToggle?: boolean;
  customBranding?: boolean;
  hiDisclosure?: boolean;
  onChangeActiveBenefitType?: (benefitType: BenefitTypeBenEx) => void;
};

export const ExplorerBenefit = forwardRef<HTMLDivElement, ExplorerBenefitProps>(
  (
    {
      benefitType,
      otherBenefitTypes,
      metadata,
      plans,
      carrierId,
      kioskMode,
      employerState,
      clientName,
      confirmDeselection,
      onPlanDeselected,
      trackElementClicked,
      trackElementChanged,
      trackElementViewedOptions,
      expiresAt,
      createdAt,
      showLegacyVisionPlanTable,
      showLegacyDentalPlanTable,
      largeClientFeatureToggle,
      vasUpdateFeatureToggle,
      medicalPlanComparisonFeatureToggle,
      visionPlanComparisonFeatureToggle,
      dentalPlanComparisonFeatureToggle,
      fsaRolloverFeatureToggle,
      dentalPlansCombinedFeatureToggle,
      customBrandingFeatureToggle,
      customBranding,
      hiDisclosure,
      onChangeActiveBenefitType,
    },
    ref,
  ) => {
    const { i18n, t } = useTranslation({ keyPrefix: "ExplorerBenefit" });
    const showLifeComponent = (exp: string) => {
      switch (exp) {
        case "AD&D": {
          const addPlan = plans.filter((plan) => plan.contentFlags?.find((cf) => cf === exp));
          if (addPlan.length) {
            return benefitType === "LIFE";
          }
          return false;
        }
        case "VOLUNTARY_LIFE": {
          return benefitType === "LIFE" && plans.find((plan) => plan.planType === exp);
        }
        default:
          return false;
      }
    };

    const hasSunLifePlans = plans.find((p) => isSunlifeCarrier(p.carrierId));

    const showWellnessComponent =
      hasSunLifePlans &&
      (benefitType === "ACCIDENT" ||
        benefitType === "HOSPITAL_INDEMNITY" ||
        benefitType === "CRITICAL_ILLNESS" ||
        benefitType === "CANCER") &&
      plans.some((plan) => plan.wellnessAmount !== null);

    const showRealWorldExample =
      benefitType === "CRITICAL_ILLNESS" ||
      benefitType === "ACCIDENT" ||
      benefitType === "HOSPITAL_INDEMNITY" ||
      benefitType === "CANCER" ||
      benefitType === "GAP" ||
      benefitType === "SHORT_TERM_DISABILITY" ||
      benefitType === "LONG_TERM_DISABILITY";

    const showPlanComparision =
      benefitType === "VISION"
        ? hasSunLifePlans
        : benefitType !== "LIFE" &&
          benefitType !== "SHORT_TERM_DISABILITY" &&
          benefitType !== "LONG_TERM_DISABILITY" &&
          benefitType !== "OTHER";

    const showWhyBuyVideos =
      !!customBrandingFeatureToggle && !!customBranding ? hasSunLifePlans : true;

    const client = clientName ? clientName : "";

    const alternateName = useGetAlternateName(benefitType, employerState, plans);
    const alternateNameInline = useGetAlternateName(benefitType, employerState, plans, true, true);

    const combinedContentFlags = plans
      .reduce<ContentFlag[]>((acc, val) => {
        return acc.concat(val.contentFlags ?? []);
      }, [])
      .reduce<ContentFlag[]>((acc, val) => {
        if (!acc.includes(val)) acc.push(val);
        return acc;
      }, []);

    let disclaimerBaseCopyKey = "disclaimer";
    let legalCopyKey = "legal";
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check -- only need to handle benefit types that have a feature toggle
    switch (benefitType) {
      case "MEDICAL":
        if (
          medicalPlanComparisonFeatureToggle === true &&
          copyKeyExists(t, "MEDICAL", "PLAN_COMPARISON")
        ) {
          disclaimerBaseCopyKey = "PLAN_COMPARISON.disclaimer";
          legalCopyKey = "PLAN_COMPARISON.legal";
        }
        break;
      case "DENTAL":
        if (
          dentalPlanComparisonFeatureToggle === true &&
          copyKeyExists(t, "DENTAL", "PLAN_COMPARISON")
        ) {
          disclaimerBaseCopyKey = "PLAN_COMPARISON.disclaimer";
          legalCopyKey = "PLAN_COMPARISON.legal";
        }
        break;
      case "VISION":
        if (
          visionPlanComparisonFeatureToggle === true &&
          copyKeyExists(t, "VISION", "PLAN_COMPARISON")
        ) {
          disclaimerBaseCopyKey = "PLAN_COMPARISON.disclaimer";
          legalCopyKey = "PLAN_COMPARISON.legal";
        }
        break;
      case "FINANCIAL":
        if (fsaRolloverFeatureToggle === true && copyKeyExists(t, "FINANCIAL", "PLAN_COMPARISON")) {
          disclaimerBaseCopyKey = "PLAN_COMPARISON.disclaimer";
          legalCopyKey = "PLAN_COMPARISON.legal";
        }
        break;
    }

    const disclaimers = plans
      .filter((p) => !!p.legalEntity && p.legalEntity.length && !isSunlifeCarrier(p.carrierId))
      .reduce<{ planNames: string[]; legalEntity: string }[]>((acc, plan) => {
        const existing = acc.find((m) => m.legalEntity === plan.legalEntity);
        if (existing) {
          if (!existing.planNames.includes(plan.planName)) existing.planNames.push(plan.planName);
          return acc;
        }

        return acc.concat([
          {
            planNames: [plan.planName],
            legalEntity: plan.legalEntity ?? "",
          },
        ]);
      }, [])
      .reduce<string>((acc, { planNames, legalEntity }) => {
        if (acc === "") {
          acc += t(copyKey(benefitType, disclaimerBaseCopyKey), {
            context: "prefix",
            defaultValue: "",
          });
        }

        if (planNames.length <= 2) {
          acc += t(copyKey(benefitType, disclaimerBaseCopyKey), {
            planNames,
            legalEntity,
            context: planNames.length.toString(),
            defaultValue: "",
          });
        } else {
          const plansList = planNames.slice(0, planNames.length - 1).reduce<string>((acc, val) => {
            if (acc === "") {
              acc += val;
            } else {
              acc += ", " + val;
            }
            return acc;
          }, "");

          acc += t(copyKey(benefitType, disclaimerBaseCopyKey), {
            plansList,
            lastPlan: planNames[planNames.length - 1],
            legalEntity,
            context: "many",
            defaultValue: "",
          });
        }

        return acc;
      }, "");

    const screens = useBreakpoint();

    const trackViewedRef = useTrackElementViewed(
      trackElementViewedOptions,
      window.innerHeight * 0.2,
    );

    const language = i18n.language === "es" ? "es" : "en";

    return (
      <div
        ref={(elem) => {
          if (typeof ref === "function") ref(elem);
          else if (ref) ref.current = elem;

          // https://github.com/facebook/react/issues/29196
          trackViewedRef.current = elem;
        }}
        className="py-40"
        data-testid={DataTestId.BenefitWrapper}
      >
        <StackY dist={40} style={{ width: "100%" }}>
          <Row gutter={[56, 24]}>
            <Col span={24} md={13}>
              <StackY dist={40} style={{ width: "100%" }}>
                <ExplorerBenefitOverview
                  benefitType={benefitType}
                  metadata={metadata}
                  plans={plans}
                  clientName={client}
                  alternateName={alternateName}
                  contentFlags={combinedContentFlags}
                  language={language}
                />

                {screens.md && (
                  <ExplorerBenefitWhyNeedIt
                    benefitType={benefitType}
                    alternateName={alternateName}
                    contentFlags={combinedContentFlags}
                    employerState={employerState}
                  />
                )}
              </StackY>
            </Col>
            {benefitType === "MEDICAL" &&
              i18n.language !== "es" &&
              trackElementViewedOptions?.explorerPage != null &&
              trackElementViewedOptions.explorerPage.vidyardUploadId != null && (
                <Col span={24} md={11}>
                  <ExplorerBenefitCustomMedicalVideo
                    videoID={trackElementViewedOptions.explorerPage.vidyardUploadId}
                    englishTitle={
                      trackElementViewedOptions.explorerPage.medicalVideoEnglishTitle || ""
                    }
                    spanishTitle={
                      trackElementViewedOptions.explorerPage.medicalVideoSpanishTitle || undefined
                    }
                  />
                </Col>
              )}
            {showWhyBuyVideos &&
              benefitType !== "OTHER" &&
              benefitType !== "FINANCIAL" &&
              benefitType !== "MEDICAL" && (
                <Col span={24} md={11}>
                  <ExplorerBenefitVideo
                    benefitType={benefitType}
                    alternateName={alternateNameInline}
                    plans={plans}
                  />
                </Col>
              )}
          </Row>

          {!screens.md && (
            <Row>
              <Col span={24} md={12}>
                <ExplorerBenefitWhyNeedIt
                  benefitType={benefitType}
                  alternateName={alternateName}
                  contentFlags={combinedContentFlags}
                  employerState={employerState}
                />
              </Col>
            </Row>
          )}

          {benefitType === "HOSPITAL_INDEMNITY" && hiDisclosure && <TriAgencyDisclosure />}

          <ExplorerBenefitPlans
            benefitType={benefitType}
            plans={plans}
            kioskMode={kioskMode}
            confirmDeselection={confirmDeselection}
            onPlanDeselected={onPlanDeselected}
            language={language}
            trackElementClicked={trackElementClicked}
            expiresAt={expiresAt}
            vasUpdateFeatureToggle={vasUpdateFeatureToggle ?? false}
            dentalPlansCombinedFeatureToggle={dentalPlansCombinedFeatureToggle ?? false}
          />
          {showRealWorldExample && (
            <ExplorerBenefitRealWorld
              benefitType={benefitType}
              carrierName={plans[0]?.carrier?.carrierName ?? "the carrier"}
              alternateName={alternateName}
              isLargeClient={largeClientFeatureToggle ?? false}
            />
          )}
          <ExplorerBenefitFindDoctor
            benefitType={benefitType}
            plans={plans}
            employerState={employerState}
            language={language}
            track={() => {
              if (benefitType === "DENTAL")
                trackElementClicked?.({
                  module: "Explorer Find Doctor",
                  buttonLabel: "Find a Dentist",
                });
            }}
          />
          {showPlanComparision ? (
            benefitType === "DENTAL" && largeClientFeatureToggle ? (
              <ExplorerPlanComparisonTableWVClientDental />
            ) : (
              <ExplorerPlanComparisonTable
                benefitType={benefitType}
                otherBenefitTypes={otherBenefitTypes}
                plans={plans}
                createdAt={createdAt}
                showLegacyVisionPlanTable={showLegacyVisionPlanTable}
                showLegacyDentalPlanTable={showLegacyDentalPlanTable}
                medicalPlanComparisonFeatureToggle={medicalPlanComparisonFeatureToggle}
                visionPlanComparisonFeatureToggle={visionPlanComparisonFeatureToggle}
                dentalPlanComparisonFeatureToggle={dentalPlanComparisonFeatureToggle}
                fsaRolloverFeatureToggle={fsaRolloverFeatureToggle}
                onChangeActiveBenefitType={onChangeActiveBenefitType}
              />
            )
          ) : null}
          {(showLifeComponent("AD&D") ||
            plans.find((plan) => plan.planType === "STANDALONE_ADD") != null) && (
            <Row gutter={[24, 24]}>
              <Col span={24} md={13}>
                <ExplorerBenefitADD />
              </Col>
            </Row>
          )}
          {showLifeComponent("VOLUNTARY_LIFE") && (
            <Row gutter={[24, 24]}>
              <Col span={24} md={12}>
                <ExplorerBenefitHowMuchCoverage benefitType={benefitType} />
              </Col>
              <Col span={24} md={12}>
                <ExplorerBenefitCoverageCalculator
                  track={(val) =>
                    trackElementChanged?.({
                      module: "Explorer Coverage Calculator",
                      value: val.toString(),
                    })
                  }
                />
              </Col>
            </Row>
          )}

          {showWellnessComponent && (
            <ExplorerBenefitWellness plans={plans} benefitType={benefitType} />
          )}

          <ExplorerBenefitHelpfulHint
            benefitType={benefitType}
            plans={plans}
            alternateName={alternateName}
          />

          {benefitType !== "OTHER" && benefitType !== "FINANCIAL" && (
            <ExplorerBenefitHowToUsePlan
              benefitType={benefitType}
              carrierId={carrierId}
              contentFlags={combinedContentFlags}
              plans={plans}
            />
          )}
          <ExplorerBenefitFaq
            benefitType={benefitType}
            alternateName={alternateName}
            plans={plans}
            largeClientFeatureToggle={largeClientFeatureToggle ?? false}
          />
          <StackY dist={12}>
            {copyKeyExists(t, benefitType, legalCopyKey) && (
              <Body5 as="div">
                <Trans
                  t={t}
                  i18nKey={copyKey(benefitType, legalCopyKey)}
                  tOptions={{
                    context: !hasSunLifePlans ? "nonSL" : undefined,
                  }}
                />
              </Body5>
            )}
            {!!disclaimers && <Body5 as="div">{disclaimers}</Body5>}
          </StackY>
        </StackY>
      </div>
    );
  },
);

const useGetAlternateName = (
  benefitType: BenefitTypeBenEx,
  employerState: LocationStateCode,
  plans: ExplorerPageBenefit[],
  forceSingular = false,
  inline = false,
) => {
  const { t } = useTranslation({ keyPrefix: "AlternateNames" });
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();

  return getAlternateName(
    t,
    benefitType,
    employerState,
    plans,
    employeePrepaidAndChatLocation,
    forceSingular,
    inline,
  );
};

export const getAlternateName = (
  t: TFunction<Namespace<string>>,
  benefitType: BenefitTypeBenEx,
  employerState: LocationStateCode,
  plans: ExplorerPageBenefit[],
  prepaidLocation: LocationStateCode | null,
  forceSingular = false,
  inline = false,
) => {
  if (benefitType === "DENTAL") {
    //special handling for dental
    const planTypes = plans.reduce<PlanType[]>((acc, val) => {
      if (val.benefitType !== benefitType) return acc;
      if (val.planType && !acc.includes(val.planType)) acc.push(val.planType);
      return acc;
    }, []);
    const planOffering = planTypes.length === 1 ? planTypes[0] : "DEFAULT";

    return t(`${benefitType}_${planOffering}_${prepaidLocation}`, {
      defaultValue: t(`${benefitType}_${planOffering}`, {
        count: forceSingular ? 1 : planTypes.length,
        context: inline && "inline",
      }),
    });
  }

  return t(`${benefitType}_${employerState}`, {
    defaultValue: t(`${benefitType}_DEFAULT`, { defaultValue: "" }),
  });
};
