import { Row, Col } from "client/src/components/Grid/Grid";
import { Eyebrow, Body3 } from "client/src/components/Typography/Typography";
import { isUsing3TierContributionAttributes } from "client/src/domain/EIF/common/utils/isUsing3TierContributionAttributes";
import {
  getIsFourTierIndividualContributionBenefitType,
  type BenefitTypeEIF,
  getIsDHMOBenefitType,
  getIsSupplementalHealthBenefitType,
} from "shared/types/BenefitTypes";
import type { FormikErrors } from "formik";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  EmployerContributionOption,
  EmployerContributionUnit,
  PlanContributions,
} from "shared/types/EmployeeClassPlan";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { editContributionsForEmployeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";

type ContributionsTableProps = {
  employeeClass: EmployeeClass;
  lowPlanContributions?: PlanContributions | null;
  highPlanContributions?: PlanContributions | null;
  benefitType: BenefitTypeEIF;
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof editContributionsForEmployeeClassPlanValidationSchema>
  >;
};
export const ContributionsTable = (props: ContributionsTableProps) => {
  const { employeeClass, lowPlanContributions, highPlanContributions, benefitType, strictErrors } =
    props;

  const isFourTierIndividualBenefitType =
    getIsFourTierIndividualContributionBenefitType(benefitType);

  const useIndividualRoleAttrs =
    isUsing3TierContributionAttributes(employeeClass, benefitType) ||
    isFourTierIndividualBenefitType;

  const spouseDisplayValue = useIndividualRoleAttrs ? "Spouse" : "Employee + Spouse";
  const childrenDisplayValue = useIndividualRoleAttrs ? "Child(ren)" : "Employee + Children";
  const relevantFamilyField = isFourTierIndividualBenefitType
    ? "familyContributionAmount"
    : "eeAndFamilyContributionAmount";

  const isDHMOBenefitType = getIsDHMOBenefitType(benefitType);
  const isSuppHealthBenefitType = getIsSupplementalHealthBenefitType(benefitType);

  return (
    <>
      <Row>
        <Col span={contributionsTableSpan.principal.default}>
          <Eyebrow>TIER</Eyebrow>
        </Col>
        {lowPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Eyebrow>
              {isDHMOBenefitType || isSuppHealthBenefitType ? "EMPLOYER CONTRIBUTION" : "LOW PLAN"}
            </Eyebrow>
          </Col>
        )}
        {highPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Eyebrow>HIGH PLAN</Eyebrow>
          </Col>
        )}
      </Row>

      <Row>
        <Col span={contributionsTableSpan.principal.default}>
          <Body3>Employee</Body3>
        </Col>
        {lowPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.eeContributionAmountLow ? true : false}>
              {strictErrors.eeContributionAmountLow
                ? strictErrors.eeContributionAmountLow
                : formatContribution(
                    lowPlanContributions.eeContributionAmount,
                    lowPlanContributions.employerContributionUnit,
                    lowPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
        {highPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.eeContributionAmountHigh ? true : false}>
              {strictErrors.eeContributionAmountHigh
                ? strictErrors.eeContributionAmountHigh
                : formatContribution(
                    highPlanContributions.eeContributionAmount,
                    highPlanContributions.employerContributionUnit,
                    highPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
      </Row>

      <Row>
        <Col span={contributionsTableSpan.principal.default}>
          <Body3>{spouseDisplayValue}</Body3>
        </Col>
        {lowPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.spouseContributionAmountLow ? true : false}>
              {strictErrors.spouseContributionAmountLow
                ? strictErrors.spouseContributionAmountLow
                : formatContribution(
                    useIndividualRoleAttrs
                      ? lowPlanContributions.spouseContributionAmount
                      : lowPlanContributions.eeAndSpouseContributionAmount,
                    lowPlanContributions.employerContributionUnit,
                    lowPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
        {highPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.spouseContributionAmountHigh ? true : false}>
              {strictErrors.spouseContributionAmountHigh
                ? strictErrors.spouseContributionAmountHigh
                : formatContribution(
                    useIndividualRoleAttrs
                      ? highPlanContributions.spouseContributionAmount
                      : highPlanContributions.eeAndSpouseContributionAmount,
                    highPlanContributions.employerContributionUnit,
                    highPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
      </Row>

      <Row>
        <Col span={contributionsTableSpan.principal.default}>
          <Body3>{childrenDisplayValue}</Body3>
        </Col>
        {lowPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.childrenContributionAmountLow ? true : false}>
              {strictErrors.childrenContributionAmountLow
                ? strictErrors.childrenContributionAmountLow
                : formatContribution(
                    useIndividualRoleAttrs
                      ? lowPlanContributions.childrenContributionAmount
                      : lowPlanContributions.eeAndChildrenContributionAmount,
                    lowPlanContributions.employerContributionUnit,
                    lowPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
        {highPlanContributions && (
          <Col span={contributionsTableSpan.secondary.default}>
            <Body3 redMedium={strictErrors.childrenContributionAmountHigh ? true : false}>
              {strictErrors.childrenContributionAmountHigh
                ? strictErrors.childrenContributionAmountHigh
                : formatContribution(
                    useIndividualRoleAttrs
                      ? highPlanContributions.childrenContributionAmount
                      : highPlanContributions.eeAndChildrenContributionAmount,
                    highPlanContributions.employerContributionUnit,
                    highPlanContributions.employerContribution,
                  )}
            </Body3>
          </Col>
        )}
      </Row>
      {(!useIndividualRoleAttrs || isFourTierIndividualBenefitType) && (
        <Row>
          <Col span={contributionsTableSpan.principal.default}>
            <Body3>Family</Body3>
          </Col>
          {lowPlanContributions && (
            <Col span={contributionsTableSpan.secondary.default}>
              <Body3 redMedium={strictErrors.familyContributionAmountLow ? true : false}>
                {strictErrors.familyContributionAmountLow
                  ? strictErrors.familyContributionAmountLow
                  : formatContribution(
                      lowPlanContributions[relevantFamilyField],
                      lowPlanContributions.employerContributionUnit,
                      lowPlanContributions.employerContribution,
                    )}
              </Body3>
            </Col>
          )}
          {highPlanContributions && (
            <Col span={contributionsTableSpan.secondary.default}>
              <Body3 redMedium={strictErrors.familyContributionAmountHigh ? true : false}>
                {strictErrors.familyContributionAmountHigh
                  ? strictErrors.familyContributionAmountHigh
                  : formatContribution(
                      highPlanContributions[relevantFamilyField],
                      highPlanContributions.employerContributionUnit,
                      highPlanContributions.employerContribution,
                    )}
              </Body3>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

const formatContribution = (
  amount: number | null,
  unit: EmployerContributionUnit | null,
  employerContribution: EmployerContributionOption | null,
) => {
  if (amount !== null) {
    if (unit === "DOLLAR") {
      return `$${amount} employer paid`;
    } else if (unit === "PERCENT") {
      return `${amount}% employer paid`;
    } else {
      return `${amount}`;
    }
  } else {
    const isSuppHealthEmployerPaid = employerContribution === "YES";
    return isSuppHealthEmployerPaid ? "100% employer paid" : "-";
  }
};

export const contributionsTableSpan = {
  principal: {
    default: 7,
  },
  secondary: {
    default: 7,
  },
};
