import { ExplorerBenefitModalPresentation } from "./ExplorerBenefitModalPresentation";
import { useExplorerBenefitModalState } from "./useExplorerBenefitModalState";

import type { FC } from "react";
import type { Carrier } from "shared/types/Carrier";
import type { Document } from "shared/types/Document";
import type { PlanSummaryDocumentByBenefitType } from "shared/types/ExplorerPage";
import type { Pretty } from "shared/types/Helper";
import type { LocationStateCode } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { explorerPageBenefitInputValidation } from "shared/validation/explorerPageBenefit";
import type { InferType } from "yup";

export type ExplorerBenefitModalProps = {
  visible: boolean;
  onSave: (benefit: Pretty<InferType<typeof explorerPageBenefitInputValidation>>) => void;
  onDelete: () => void;
  onCancel: () => void;
  planSummaryDocuments?: Document[];
  spanishPlanSummaryDocuments?: Document[];
  carriers: Carrier[];
  benefit?: Pretty<InferType<typeof explorerPageBenefitInputValidation>> | null;
  clientId: string;
  employerState: LocationStateCode | null;
  featureToggle: ClientFeatureToggles | undefined;
  defaultLinkedHDHPPlanName: string | null;
  planSummaryDocumentsByBenefitType: PlanSummaryDocumentByBenefitType[];
  spanishPlanSummaryDocumentsByBenefitType: PlanSummaryDocumentByBenefitType[];
  pageCreationDate?: Date;
  isRenewal?: boolean;
  hideNewVisionPlanFields?: boolean;
  hideNewDentalPlanFields?: boolean;
};

export const ExplorerBenefitModal: FC<ExplorerBenefitModalProps> = (props) => {
  const state = useExplorerBenefitModalState(props);

  return <ExplorerBenefitModalPresentation {...state} />;
};
