import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { H3 } from "client/src/components/Typography/Typography";
import { BillExclusiveProps } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillExclusiveProps";
import { BillSharedProps } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillSharedProps";
import {
  getChangesForBills,
  getPrefillErrorsForBill,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { useToggler } from "client/src/hooks/useToggler";
import { wasEntityAddedInLatestICEdit } from "shared/utils/EIF/changeLog";
import * as styles from "./BillCardDouble.module.less";
import type { BillTiming } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { PolicyBillingPreferences } from "shared/validation/bill";

type Props = {
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  bills: Array<{ billName: string | null; billTiming?: BillTiming | null }>;
  advanceBill: BillPreview;
  arrearsBill: BillPreview;
  policyBillingPreferences: PolicyBillingPreferences;
  disabled?: boolean;
  expanded?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

export function BillCardDouble(props: Props) {
  const {
    client,
    policy,
    changeSnapshot,
    authUser,
    bills,
    advanceBill,
    arrearsBill,
    policyBillingPreferences,
    disabled,
    expanded,
    onEdit,
    onDelete,
  } = props;

  const [showDetails, toggleShowDetails] = useToggler(!!expanded);

  const changeDetailInfoList = getChangesForBills([advanceBill, arrearsBill], changeSnapshot);

  const prefillErrorsForAdvanceBill = getPrefillErrorsForBill(
    client,
    policy,
    policyBillingPreferences,
    advanceBill,
    changeSnapshot,
  );
  const prefillErrorsForArrearsBill = getPrefillErrorsForBill(
    client,
    policy,
    policyBillingPreferences,
    arrearsBill,
    changeSnapshot,
  );

  return (
    <FlatCard disabled={disabled}>
      <Row wrap={false}>
        <Col flex="1">
          <H3 as="div">Bill summary</H3>
        </Col>

        {onEdit && (
          <Col flex="50px">
            <Button type="text-only" size="middle" onClick={onEdit} disabled={disabled}>
              Edit
            </Button>
          </Col>
        )}

        {onDelete && (
          <Col flex="40px">
            <Button type="text-only" size="middle" onClick={onDelete} disabled={disabled}>
              Delete
            </Button>
          </Col>
        )}
      </Row>

      <p>
        This summary includes two bills because you have benefits that are billed in advance and
        arrears.
      </p>

      <BillSharedProps
        bill={advanceBill}
        showDetails={showDetails}
        prefillErrors={expanded ? prefillErrorsForAdvanceBill : {}}
        billSplitType={policyBillingPreferences.billSplitType}
      />

      <hr />

      <StackY dist={24} wrap={false}>
        <Row>
          <Col span={11}>
            <BillExclusiveProps
              bills={bills}
              bill={advanceBill}
              prefillErrors={expanded ? prefillErrorsForAdvanceBill : {}}
              billingAdministrationType={policyBillingPreferences.billingAdministrationType}
              showDetails={showDetails}
              withBilName
            />
          </Col>

          <Col span={2}>
            <div className={styles.verticalLine} />
          </Col>

          <Col span={11}>
            <BillExclusiveProps
              bills={bills}
              bill={arrearsBill}
              prefillErrors={expanded ? prefillErrorsForArrearsBill : {}}
              billingAdministrationType={policyBillingPreferences.billingAdministrationType}
              showDetails={showDetails}
              withBilName
            />
          </Col>
        </Row>

        {!expanded && (
          <StackY dist={20} wrap={false}>
            <hr className="m-0" />

            <Button type="text-only" onClick={toggleShowDetails} disabled={disabled}>
              {showDetails ? "Hide details" : "Show details"}
            </Button>
          </StackY>
        )}

        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
          isAdd={
            wasEntityAddedInLatestICEdit(advanceBill, client) ||
            wasEntityAddedInLatestICEdit(arrearsBill, client)
          }
        />
      </StackY>
    </FlatCard>
  );
}
