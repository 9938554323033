import { contributionFields as defaultContributionFields } from "shared/types/EmployeeClassPlan";
import { rejectNullableValues } from "shared/utils/utils";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { PlanContributions } from "shared/types/EmployeeClassPlan";

export const relevantChangesForContributionFields = (
  employeeClassPlanIds: string[],
  changeSnapshot: DEIFChangeSnapshot | null,
  contributionFields: (keyof PlanContributions)[] = defaultContributionFields,
) => {
  if (!changeSnapshot) return [];

  const employeeClassPlansWithRelevantChanges = employeeClassPlanIds
    .flatMap((id) => {
      if (!changeSnapshot.EmployeeClassPlan[id]) return [];

      const changeDetailRecords = contributionFields.map((fieldName) => {
        const changeDetailRecord = changeSnapshot.EmployeeClassPlan[id]?.[fieldName];
        return changeDetailRecord;
      });

      return changeDetailRecords;
    })
    .filter(rejectNullableValues);

  return employeeClassPlansWithRelevantChanges;
};
