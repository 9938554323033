import { RouteData } from "shared/config/routeData";

import { ExplorerNavBar } from "../../components/NavBar/ExplorerNavBar";

type ExplorerNavPageProps = {
  pageId: string;
};

export const ExplorerNavPage = ({ pageId }: ExplorerNavPageProps) => {
  return (
    <ExplorerNavBar
      personalBenefitsPlanLink={RouteData.personalBenefitsPlan.getPath(pageId)}
      pageId={pageId}
    />
  );
};
