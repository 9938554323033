import { Row, Col } from "client/src/components/Grid/Grid";
import * as Yup from "yup";

import { useSlobFormik } from "../../hooks/useSlobFormik";
import { Trans, useTranslation } from "../../i18n";

import { Button } from "../Button/Button";
import { InputErrorMessage } from "../Form/InputErrorMessage";
import { TextArea } from "../Form/TextArea";
import { StackX } from "../Spacing/Spacing";
import { StarRate } from "../StarRate/StarRate";
import { Body3 } from "../Typography/Typography";

export const DataTestId = {
  explorerFeedbackWrapper: "explorer-feedback-wrapper",
};

const explorerFeedbackFormValidation = Yup.object({
  rating: Yup.number().nullable(),
  feedback: Yup.string().nullable(),
});

export type ExplorerFeedbackFormProps = {
  centered?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  showCancelButton?: boolean;
  sendExplorerFeedbackForm: (feedback: string, rating?: number | undefined) => void;
};

export const ExplorerFeedbackForm = ({
  centered = false,
  onCancel,
  showCancelButton = true,
  sendExplorerFeedbackForm,
  onSubmit,
}: ExplorerFeedbackFormProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerFeedbackForm" });

  const formik = useSlobFormik({
    initialValues: {
      feedback: null,
      rating: null,
    },
    validationSchema: explorerFeedbackFormValidation,
    onSubmit({ feedback, rating }) {
      if (!rating && !feedback) {
        formik.setFieldError("rating", "Please provide a response");
        return;
      }
      sendExplorerFeedbackForm(feedback ?? "", rating ?? undefined);
      formik.resetForm();
      onSubmit?.();
    },
  });

  return (
    <Row
      data-testid={DataTestId.explorerFeedbackWrapper}
      gutter={[16, 16]}
      align={centered ? "middle" : "top"}
      style={{ flexDirection: "column" }}
    >
      <Col>
        <Body3>
          <Trans i18nKey={"body"} t={t} />
        </Body3>
      </Col>
      <Col>
        <StarRate
          value={formik.values.rating ?? 0}
          onChange={(v) => formik.setFieldValue("rating", v)}
          errorValue={formik.errors.rating}
        />
      </Col>

      <Col style={{ width: "100%", maxWidth: "360px" }}>
        <TextArea
          name="feedback"
          label=""
          placeholder={t("feedbackLabel") ?? undefined}
          value={formik.values.feedback ?? undefined}
          onChange={formik.handleChange}
          error={formik.errors.feedback}
          touched={formik.touched.feedback}
          disabled={formik.isSubmitting}
          maxLength={1000}
          style={{ width: "100%" }}
        />
      </Col>

      <Col>
        {formik.status && <InputErrorMessage id={`feedback-error`} error={formik.status} />}

        <StackX dist={8}>
          <Button
            type="primary"
            size="middle"
            onClick={formik.submitForm}
            disabled={!formik.values.rating && !formik.values.feedback}
          >
            {t("callToAction")}
          </Button>
          {showCancelButton && (
            <Button
              type="text"
              onClick={() => {
                formik.resetForm();
                onCancel?.();
              }}
            >
              {t("cancel")}
            </Button>
          )}
        </StackX>
      </Col>
    </Row>
  );
};
