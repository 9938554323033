import { EIFBenAdminAndDataFeedPolicySelection } from "client/src/domain/EIF/CompanyDetails/EIFBenAdminAndDataFeedPolicySelection";
import { EIFSubsidiaries } from "client/src/domain/EIF/CompanyDetails/EIFSubsidiaries";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { EIFPlaceholder } from "../common/EIFPlaceholder";
import { EIFCompanyDetailsIntroduction } from "./EIFCompanyDetailsIntroduction";
import { EIFCompanyDetailsReview, ShowAllSubSteps } from "./EIFCompanyDetailsReview";
import { EIFCurrentlyInsuredWithSunLife } from "./EIFCurrentlyInsuredWithSunLife";
import { EIFPreviousCoverageInformation } from "./EIFPreviousCoverageInformation/EIFPreviousCoverageInformation";
import { EIFTaxId } from "./EIFTaxId";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { CreateSubsidiaryFunc, UpdateSubsidiaryFunc } from "client/src/hooks/subsidiary";
import type { UserData } from "shared/rbac/rbac";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFCompanyDetailsSubStep, EIFStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFCompanyDetailsProps = {
  eifStepId: EIFStepId;
  eifSubStepId?: EIFCompanyDetailsSubStep;
  client: Client;
  benAdminPlatforms: BenAdminPlatform[];
  clientPlans: Plan[];
  bills: Bill[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  classes: EmployeeClass[];
  subsidiaries: Subsidiary[];
  changeSnapshot: DEIFChangeSnapshot;
  employeeClassDocumentCount: number;
  updateClient: UpdateClientFunc;
  updatePolicy: UpdatePolicyFunc;
  createSubsidiary: CreateSubsidiaryFunc;
  updateSubsidiary: UpdateSubsidiaryFunc;
  trackElementClicked: TrackElementClickedFunc;
  viewMode: EIFSubStepViewMode;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

export const EIFCompanyDetails = ({
  eifStepId,
  eifSubStepId,
  client,
  benAdminPlatforms,
  clientPlans,
  changeSnapshot,
  bills,
  contacts,
  billingSummaryStatementTemplates,
  classes,
  subsidiaries,
  employeeClassDocumentCount,
  trackElementClicked,
  updateClient,
  updatePolicy,
  createSubsidiary,
  updateSubsidiary,
  viewMode,
  featureToggles,
  authUser,
}: EIFCompanyDetailsProps) => {
  const stepCompleteStatus = getEIFStepCompleteStatus(
    eifStepId,
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    classes,
    employeeClassDocumentCount,
    changeSnapshot,
    featureToggles,
    subsidiaries,
  );

  if (!eifSubStepId) {
    return stepCompleteStatus === "Not Started" ? (
      <EIFCompanyDetailsIntroduction client={client} eifStepId={eifStepId} />
    ) : (
      <EIFCompanyDetailsReview
        client={client}
        subsidiaries={subsidiaries}
        changeSnapshot={changeSnapshot}
        viewMode={viewMode}
        authUser={authUser}
        featureToggles={featureToggles}
        signerMode="inside"
        showSubSteps={ShowAllSubSteps(clientPlans)}
        isLastStep={true}
        plans={clientPlans}
      />
    );
  }

  switch (eifSubStepId) {
    case "benefits-administration-and-data-feed":
      return (
        <EIFBenAdminAndDataFeedPolicySelection
          client={client}
          plans={clientPlans}
          benAdminPlatforms={benAdminPlatforms}
          updateClient={updateClient}
          updatePolicy={updatePolicy}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          featureToggles={featureToggles}
        />
      );
    case "tax-id":
      return (
        <EIFTaxId
          client={client}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          updateClient={updateClient}
          authUser={authUser}
        />
      );
    case "subsidiaries-and-affiliates":
      return (
        <EIFSubsidiaries
          client={client}
          subsidiaries={subsidiaries}
          updateClient={updateClient}
          createSubsidiary={createSubsidiary}
          updateSubsidiary={updateSubsidiary}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          plans={clientPlans}
          authUser={authUser}
        />
      );
    case "current-sun-life-policies":
      return (
        <EIFCurrentlyInsuredWithSunLife
          client={client}
          changeSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          updateClient={updateClient}
          authUser={authUser}
        />
      );
    case "previous-coverage-information":
      return (
        <EIFPreviousCoverageInformation
          client={client}
          plans={clientPlans}
          trackElementClicked={trackElementClicked}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          updateClient={updateClient}
          authUser={authUser}
        />
      );

    default:
      exhaustiveCheck(eifSubStepId);
      break;
  }
  return EIFPlaceholder({ eifStepId, eifSubStepId });
};
