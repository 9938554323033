import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { useState } from "react";

import { BenefitTypeBenExData } from "shared/types/BenefitTypes";
import { RouteData } from "../../../../../shared/config/routeData";
import { PlanTypeData } from "../../../../../shared/data/ExplorerBenefit";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import { H1, H2, H4, H5 } from "../../../components/Typography/Typography";
import {
  useEmployeeLocation,
  useFavoritePlans,
  useFavoritePlansByLocation,
} from "../../../hooks/useSlobLocalStorage";
import { i18n, useTranslation } from "../../../i18n";
import { PrintHidden, PrintOnly } from "../../../utils/print";

import { ExplorerBenefitPlans } from "./Benefit/ExplorerBenefitPlans";
import * as styles from "./clientPage.module.less";

import type { ExplorerPageId } from "../../../../../shared/types/ExplorerPage";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

type PersonalBenefitsFavoritePlansProps = {
  plans: ExplorerPageBenefit[];
  explorerPageID: ExplorerPageId;
  vasUpdateFeatureToggle: boolean;
};

type BenefitPlansMap = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
};

export const PersonalBenefitsFavoritePlans = ({
  plans,
  explorerPageID,
  vasUpdateFeatureToggle,
}: PersonalBenefitsFavoritePlansProps) => {
  const [favoritePlans] = useFavoritePlans(explorerPageID, plans);
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();
  const { t } = useTranslation({ keyPrefix: "PersonalBenefitsFavoritePlans" });

  const [confirmDeselection, setConfirmDeselection] = useState(true);

  const dhmoBenefitData = plans.filter((ben) => ben.planType === "DHMO");
  const filteredFavPlansByPrepaidLocation = useFavoritePlansByLocation(
    favoritePlans,
    dhmoBenefitData,
    employeePrepaidAndChatLocation,
  );

  const favoriteBenefitPlans = plans.filter((o) =>
    filteredFavPlansByPrepaidLocation.some((favPlan) => o.id === favPlan),
  );

  const favoritePlansByBenefit = favoriteBenefitPlans.reduce<BenefitPlansMap[]>(
    (plansByBen, val) => {
      const mapping = plansByBen.find((planByBen) => planByBen.benefitType === val.benefitType);
      if (!mapping) {
        plansByBen.push({
          benefitType: val.benefitType,
          plans: [val],
        });
      } else mapping.plans.push(val);
      return plansByBen;
    },
    [],
  );

  const language = i18n.language === "es" ? "es" : "en";

  return (
    <>
      <PrintOnly>
        {favoritePlansByBenefit.map((benefit, index) => (
          <div
            key={`personal-plan-benefit-${index}`}
            className={clsx("mb-24", styles.printPersonalBenefit)}
          >
            <H2>
              <span style={{ fontWeight: 300 }}>
                {BenefitTypeBenExData[benefit.benefitType].displayName}
              </span>
            </H2>
            {benefit.plans.map((plan) => (
              <Row key={`personal-plan-${plan.id}`}>
                <Col className="mr-12">
                  <FontAwesomeIcon icon={faHeartSolid} className={styles.saveIcon} />
                </Col>
                <Col className="mb-8">
                  <H4 as="span">{plan.planName}</H4>
                  {plan.planType && PlanTypeData[plan.planType].fullName && (
                    <div>
                      <H5 as="span">
                        <span style={{ fontWeight: 500 }}>
                          {PlanTypeData[plan.planType].fullName}
                        </span>
                      </H5>
                    </div>
                  )}
                </Col>
              </Row>
            ))}
          </div>
        ))}
      </PrintOnly>
      <PrintHidden>
        {favoritePlansByBenefit.map((plan, index) => (
          <div key={`personal-plan-benefit-${index}`} className="mt-48">
            <Row>
              <Col span={24} md={20}>
                <H1>{BenefitTypeBenExData[plan.benefitType].displayName}</H1>
              </Col>
              <Col
                span={24}
                md={4}
                style={{
                  display: "-webkit-flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  WebkitAlignItems: "flex-end",
                  WebkitJustifyContent: "flex-end",
                }}
              >
                <InternalLinkButton
                  type="link-inline-bold"
                  to={RouteData.benefitsExplorer.getPath(explorerPageID) + `#${plan.benefitType}`}
                  size="middle"
                >
                  {t("benefitLink")}
                </InternalLinkButton>
              </Col>
            </Row>
            <ExplorerBenefitPlans
              benefitType={plan.benefitType}
              plans={plan.plans}
              kioskMode={false}
              isFavoritePlan={true}
              language={language}
              confirmDeselection={confirmDeselection}
              onPlanDeselected={(_) => setConfirmDeselection(false)}
              expiresAt={null}
              vasUpdateFeatureToggle={vasUpdateFeatureToggle}
              dentalPlansCombinedFeatureToggle={false}
            />
          </div>
        ))}
      </PrintHidden>
    </>
  );
};
