import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Body3, Body2 } from "client/src/components/Typography/Typography";
import { getAvailableEIFClassBuilderSections } from "client/src/domain/EIF/common/utils/getAvailableEIFClassBuilderSections";
import { getEmployeeClassSectionCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";

type Props = {
  client: Client;
  clientPlans: Plan[];
  employeeClass: EmployeeClass;
  singleClassBuilderMode: boolean;
};

export function EIFMissingInformationAlertBanner(props: Props) {
  const { client, employeeClass, singleClassBuilderMode, clientPlans } = props;

  const clientPlansBenefitTypes = new Set(clientPlans.map((p) => p.benefitType));

  const availableSections = getAvailableEIFClassBuilderSections(
    singleClassBuilderMode,
    clientPlansBenefitTypes,
    employeeClass,
  );

  const completionStatuses = availableSections
    .map((section) => {
      const status = getEmployeeClassSectionCompletionStatus(
        client,
        employeeClass,
        singleClassBuilderMode,
        section,
      );
      const completed = status === "Completed";

      return [section, completed] as const;
    })
    .filter(([_section, completed]) => !completed);

  if (completionStatuses.length === 0) return null;

  return (
    <AlertBanner
      variant="error"
      message={
        <div>
          <div>
            <Body3>
              Click <Body2>edit</Body2> to complete this group.
            </Body3>
          </div>
        </div>
      }
    />
  );
}
