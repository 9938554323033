import { Button } from "client/src/components/Button/Button";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { StackY } from "../Spacing/Spacing";
import { Body1, Body5 } from "../Typography/Typography";
import { Modal } from "./Modal";
import * as styles from "./progressModal.module.less";

type ProgressModalProps = {
  cancelLink?: string;
  progressText?: React.ReactNode;
  body?: string;
  centered?: boolean;
  trackCancel?: (options: string) => void;
};

export const ProgressModal = ({
  cancelLink,
  progressText,
  body,
  centered = false,
  trackCancel,
}: ProgressModalProps) => (
  <Modal
    open
    footer={null}
    centered={centered}
    disableClose={true}
    noTopPadding={true}
    showCloseIcon={false}
  >
    <div className={styles.loadingModalContent}>
      <StackY dist={16}>
        <div className={styles.submitProgress}>
          <ProgressBar id="census-validation-progress" bottomText={null} />
        </div>
        <div>
          {progressText && (
            <Body1>
              <strong>{progressText}</strong>
            </Body1>
          )}
          {body && <Body5 as="p">{body}</Body5>}
        </div>
        {cancelLink && trackCancel && (
          <Button
            type="text"
            size="middle"
            to={cancelLink}
            onClick={() => trackCancel("Validation cancel")}
          >
            Cancel
          </Button>
        )}
      </StackY>
    </div>
  </Modal>
);
