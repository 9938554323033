import { Anchor } from "client/src/components/Anchor/Anchor";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ContributionsCTA } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/ContributionsCTA";
import { ReviewDisabilityContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Disability/ReviewDisabilityContributions";
import { ThreeYearLookBackModal } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Disability/ThreeYearLookBackModal";
import { hasAtLeastOneContributionSelected } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/hasAtLeastOneContributionSelected";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { ResponseError } from "client/src/hooks/query";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { getIsDisabilityContributionBenefitType } from "shared/types/BenefitTypes";
import { taxChoiceOptions, displayTaxChoiceOptionsTypeName } from "shared/types/EmployeeClassPlan";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getEmployeeClassDisabilityContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { assertIsDefined, rejectNullableValues } from "shared/utils/utils";
import {
  availableCombinationTaxChoice,
  disabilityContributionsValidationSchema,
} from "shared/validation/employeeClassPlan";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { FormikProps } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DisabilityContributionBenefitType } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  EmployeeClassPlanWithPlan,
  EmployeeClassPlanContributions,
  EmployeeClassPlanId,
  EmployeeClassContributionFields,
} from "shared/types/EmployeeClassPlan";

type Props = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  setContributionsQuery: SetContributionQuery;
  onSave: (employeeClass: EmployeeClass) => void;
  track: SectionTracker;
  isLoading: boolean;
  benefitType: DisabilityContributionBenefitType;
};

export function EIFClassBuilderDisabilityContributionsEditable(props: Props) {
  const {
    isActive,
    employeeClass,
    client,
    changeSnapshot,
    authUser,
    setContributionsQuery,
    setContributionsQuery: { mutateAsync: setContributions },
    onSave,
    track,
    isLoading,
    benefitType,
  } = props;

  const location = useLocation();
  const ctaBasePathname = location.pathname;

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter(
    (p) => p.plan.benefitType === benefitType,
  );
  const employeeClassPlanIds = relevantEmployeeClassPlans.map((ecp) => ecp.id);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const relevantEmployeeClassPlan = relevantEmployeeClassPlans[0] as
    | EmployeeClassPlanWithPlan
    | undefined;

  if (!relevantEmployeeClassPlan) {
    throw new Error("No employee class plan found");
  }

  const status = getEmployeeClassDisabilityContributionsCompletionStatus(employeeClass, [
    benefitType,
  ]);

  const [visible, toggleVisible] = useToggler();

  const formik = useSlobFormik({
    validationSchema: disabilityContributionsValidationSchema,
    initialValues: {
      employerContribution: relevantEmployeeClassPlan.employerContribution || null,
      eeContributionAmount: relevantEmployeeClassPlan.eeContributionAmount || null,
      employerPremiumPayments: relevantEmployeeClassPlan.employerPremiumPayments || null,
      threeYearLookBackPercent: relevantEmployeeClassPlan.threeYearLookBackPercent || null,
      taxChoice: relevantEmployeeClassPlan.taxChoice,
    },
    enableReinitialize: true,
    validationContext: { prefill: true, benefitType },
    onSubmit: async () => {
      const contributions: EmployeeClassPlanContributions[] = relevantEmployeeClassPlans
        .map((ecp) => {
          if (getIsDisabilityContributionBenefitType(ecp.plan.benefitType)) {
            return {
              employeeClassPlanId: ecp.id,
              employerContribution: formik.values.employerContribution || null,
              employerContributionUnit: "PERCENT" as const,
              eeContributionAmount: formik.values.eeContributionAmount || null,
              spouseContributionAmount: null,
              childrenContributionAmount: null,
              eeAndSpouseContributionAmount: null,
              eeAndChildrenContributionAmount: null,
              eeAndFamilyContributionAmount: null,
              familyContributionAmount: null,
              employerPremiumPayments: formik.values.employerPremiumPayments || null,
              threeYearLookBackPercent: formik.values.threeYearLookBackPercent || null,
              taxChoice: formik.values.taxChoice || null,
            };
          }
          return null;
        })
        .filter(rejectNullableValues);

      const { isSuccess, data } = await setContributions({
        params: {
          clientId: client.id,
          employeeClassId: employeeClass.id,
        },
        data: {
          employeeClassId: employeeClass.id,
          contributions,
        },
      });

      if (isSuccess) {
        track("Save");
        void slobMessage.success("Updated Contributions");
        onSave(data.employeeClass);
      }
    },
  });

  if (!isActive) {
    const atLeastOneContributionSelected = hasAtLeastOneContributionSelected(
      relevantEmployeeClassPlans,
    );

    return (
      <>
        <Row justify="start" wrap={false} gutter={0}>
          <Col>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              <EIFSectionStatusIconography status={status} />
            </H3>
          </Col>

          <Col flex={1}>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              Contributions - {benefitTypeToCoverage[benefitType]}
            </H3>
          </Col>

          <Col className="ml-8">
            <ContributionsCTA
              employeeClass={employeeClass}
              ctaBasePathname={ctaBasePathname}
              benefitTypes={[benefitType]}
              relevantEmployeeClassPlans={relevantEmployeeClassPlans}
              setContributionsQuery={setContributionsQuery}
              isLoading={isLoading}
            />
          </Col>
        </Row>

        <StackY dist={16}>
          {status === "In Progress" && (
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Click <Body2>Edit</Body2> to complete this section.
                </Body3>
              }
            />
          )}

          <ReviewDisabilityContributions
            client={client}
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            benefitType={benefitType}
          />
        </StackY>
      </>
    );
  }

  const editedFieldMsgProps = {
    employeeClassPlanIds,
    changeSnapshot,
    client,
    authUser,
    formik,
  };

  const currentNumberOfTaxChoiceSelections = formik.values.taxChoice
    ? formik.values.taxChoice.length
    : 0;

  if (formik.values.taxChoice != null) {
    assertIsDefined(formik.values.taxChoice[0], "formik.values.taxChoice[0]");
  }
  const referenceTaxChoiceOption =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked with assertIsDefined above
    formik.values.taxChoice != null ? formik.values.taxChoice[0]! : null;

  const strictErrors =
    status !== "Not Started"
      ? getFormikErrors(relevantEmployeeClassPlan, disabilityContributionsValidationSchema, {
          prefill: false,
          benefitType: relevantEmployeeClassPlan.plan.benefitType,
        })
      : {};

  const taxChoiceError =
    formik.touched.taxChoice || strictErrors.taxChoice
      ? formik.errors.taxChoice
        ? formik.errors.taxChoice
        : strictErrors.taxChoice
      : undefined;

  return (
    <>
      <Row justify="start" wrap={false} gutter={0}>
        <Col>
          <H3 as="h2">
            <EIFSectionStatusIconography status={status} />
          </H3>
        </Col>
        <Col flex={1}>
          <H3 as="h2">Contributions - {benefitTypeToCoverage[benefitType]}</H3>
        </Col>
      </Row>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24} wrap={false}>
          <RadioGroup
            name="employerContribution"
            label={
              <>
                <Body2 as="div">What type of premium contributions will you be making?</Body2>
                <Anchor
                  variant="boldSmall"
                  href="https://p1.aprimocdn.net/sunlife/34218d61-c08c-4070-90c2-afc80117c7fb/gdiwp-4905-disability-taxation-and-reporting-original-file.pdf"
                  target="_blank"
                  onClick={() => track("Learn more about Disability taxation")}
                >
                  Learn more about Disability taxation and reporting
                </Anchor>
              </>
            }
            disabled={formik.isSubmitting}
            error={formik.errors.employerContribution || strictErrors.employerContribution}
            touched={formik.touched.employerContribution || !!strictErrors.employerContribution}
            options={[
              {
                value: "YES",
                label: (
                  <>
                    <Body2 as="div">100% employer paid contributions</Body2>
                    <Body5>The employer pays the entire premium.</Body5>
                  </>
                ),
              },
              {
                value: "GROSS_UP",
                label: (
                  <>
                    <Body2 as="div">Gross up</Body2>
                    <Body5>
                      The employer adds the premium to the employee’s W-2 as taxable income.
                    </Body5>
                  </>
                ),
              },
              {
                value: "SHARED",
                label: (
                  <>
                    <Body2 as="div">Shared contributions by employer and employee</Body2>
                    <Body5>
                      The employer pays part of the premium and the employee pays part of the
                      premium.
                    </Body5>
                  </>
                ),
              },
              {
                value: "NO",
                label: (
                  <>
                    <Body2 as="div">100% employee paid contributions</Body2>
                    <Body5>The employee pays the entire premium.</Body5>
                  </>
                ),
              },
              {
                value: "TAX_CHOICE",
                label: (
                  <>
                    <Body2 as="div">Tax choice</Body2>
                    <Body5>
                      The employer offers the employee a choice between two contribution types with
                      different tax implications.
                    </Body5>
                  </>
                ),
              },
            ]}
            direction="vertical"
            value={formik.values.employerContribution}
            onChange={formik.handleChange}
          />
          {getEditedFieldMsgForField(editedFieldMsgProps, "employerContribution")}

          {formik.values.employerContribution === "SHARED" && (
            <>
              <div>
                <Body2 as="p">How much are you contributing?</Body2>

                <div style={{ maxWidth: 300 }}>
                  <FormInput
                    name="eeContributionAmount"
                    suffix="%"
                    maxLength={6}
                    label="Employer contribution percent"
                    touched={
                      formik.touched.eeContributionAmount || !!strictErrors.eeContributionAmount
                    }
                    error={formik.errors.eeContributionAmount || strictErrors.eeContributionAmount}
                    value={formik.values.eeContributionAmount}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </div>
                {getEditedFieldMsgForField(editedFieldMsgProps, "eeContributionAmount")}
              </div>

              <RadioGroup
                name="employerPremiumPayments"
                label="Are your employees’ premium payments made pre-tax or post-tax?"
                disabled={formik.isSubmitting}
                error={
                  formik.errors.employerPremiumPayments || strictErrors.employerPremiumPayments
                }
                touched={
                  formik.touched.employerPremiumPayments || !!strictErrors.employerPremiumPayments
                }
                options={[
                  {
                    value: "PRE_TAX",
                    label: "Pre-tax",
                  },
                  {
                    value: "POST_TAX",
                    label: (
                      <>
                        <Body3 as="div">Post-tax</Body3>
                        <Body5>
                          If you choose post-tax, you will need to provide us with a Three-Year
                          Look-Back percentage.
                        </Body5>
                        <Body5>
                          <ButtonOld type="link-inline" onClick={toggleVisible}>
                            How do I calculate this?
                          </ButtonOld>
                        </Body5>
                      </>
                    ),
                  },
                ]}
                direction="vertical"
                value={formik.values.employerPremiumPayments}
                onChange={formik.handleChange}
              />
              {getEditedFieldMsgForField(editedFieldMsgProps, "employerPremiumPayments")}

              {formik.values.employerPremiumPayments === "POST_TAX" && (
                <>
                  <div>
                    <Body2 as="div">
                      What is the Three-Year Look-Back percentage? <br />
                      <Button type="text-only" onClick={toggleVisible}>
                        How do I calculate this?
                      </Button>
                    </Body2>
                    <Body3 as="p">
                      If your contributions over the look-back period have been the same, then the
                      Three-Year Look-Back percentage will be the same as your contribution
                      percentage.
                    </Body3>

                    <div style={{ maxWidth: 300 }}>
                      <FormInput
                        name="threeYearLookBackPercent"
                        prefix={<span />}
                        maxLength={6}
                        label="Three-Year Look-Back percent"
                        touched={
                          formik.touched.threeYearLookBackPercent ||
                          !!strictErrors.threeYearLookBackPercent
                        }
                        error={
                          formik.errors.threeYearLookBackPercent ||
                          strictErrors.threeYearLookBackPercent
                        }
                        value={formik.values.threeYearLookBackPercent}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                      />
                    </div>
                    {getEditedFieldMsgForField(editedFieldMsgProps, "threeYearLookBackPercent")}
                  </div>
                </>
              )}
            </>
          )}

          {formik.values.employerContribution === "NO" && (
            <>
              <RadioGroup
                name="employerPremiumPayments"
                label="Are your employees’ premium payments made pre-tax or post-tax?"
                disabled={formik.isSubmitting}
                error={
                  formik.errors.employerPremiumPayments || strictErrors.employerPremiumPayments
                }
                touched={
                  formik.touched.employerPremiumPayments || !!strictErrors.employerPremiumPayments
                }
                options={[
                  {
                    value: "PRE_TAX",
                    label: "Pre-tax",
                  },
                  {
                    value: "POST_TAX",
                    label: "Post-tax",
                  },
                ]}
                direction="vertical"
                value={formik.values.employerPremiumPayments}
                onChange={formik.handleChange}
              />
              {getEditedFieldMsgForField(editedFieldMsgProps, "employerPremiumPayments")}
            </>
          )}

          {formik.values.employerContribution === "TAX_CHOICE" && (
            <StackY dist={8}>
              <div>
                <Body2 as="div">
                  Which two contribution types are you offering your employees?
                </Body2>
                <Body5>Please select two of the following:</Body5>
              </div>
              {taxChoiceOptions.map((taxChoiceOption) => {
                const isChecked = !!formik.values.taxChoice?.includes(taxChoiceOption);

                const isAvailableCombinationOption = availableCombinationTaxChoice(
                  referenceTaxChoiceOption,
                ).some((availablePair) => availablePair === taxChoiceOption);

                const isNotPairOption =
                  referenceTaxChoiceOption && referenceTaxChoiceOption !== taxChoiceOption
                    ? !isAvailableCombinationOption
                    : false;

                return (
                  <Checkbox
                    key={taxChoiceOption}
                    name="taxChoice"
                    value={taxChoiceOption}
                    label={displayTaxChoiceOptionsTypeName[taxChoiceOption]}
                    checked={!!formik.values.taxChoice?.includes(taxChoiceOption)}
                    errorId={taxChoiceError}
                    onChange={formik.handleChange}
                    disabled={
                      formik.isSubmitting ||
                      (currentNumberOfTaxChoiceSelections > 1 && !isChecked) ||
                      isNotPairOption
                    }
                  />
                );
              })}
              {getEditedFieldMsgForField(editedFieldMsgProps, "taxChoice")}

              <Body3>{`${currentNumberOfTaxChoiceSelections} of 2 selected`}</Body3>

              <div aria-live="assertive" className="hide:Empty">
                {taxChoiceError && (
                  <InputErrorMessage
                    id={taxChoiceError ? "taxChoiceError" : undefined}
                    error={taxChoiceError}
                  />
                )}
              </div>
            </StackY>
          )}

          {setContributionsQuery.isError && (
            <div aria-live="assertive" className="mt-24 hide:empty">
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(
                  setContributionsQuery.error,
                  GenericErrorCopy2,
                )}
              </ErrorMessage>
            </div>
          )}

          <Row justify="end" align="middle" gutter={28}>
            <Col>
              <InternalLinkButton
                type="link-inline-bold"
                size="middle"
                to={location.pathname}
                disabled={formik.isSubmitting}
                onClick={() => flushSync(formik.resetForm)}
              >
                Cancel
              </InternalLinkButton>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="secondary"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || formik.values.employerContribution === null}
                size="middle"
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />

      <ThreeYearLookBackModal open={visible} onCancel={toggleVisible} />
    </>
  );
}

function getEditedFieldMsgForField<T>(
  props: {
    employeeClassPlanIds: EmployeeClassPlanId[];
    changeSnapshot: DEIFChangeSnapshot;
    client: Client;
    authUser: UserData | null;
    formik: FormikProps<T>;
  },
  field: Exclude<EmployeeClassContributionFields, "employeeClassPlanId">,
) {
  const { employeeClassPlanIds, changeSnapshot, client, authUser, formik } = props;

  return (
    <EditedFieldMsg
      changeDetailInfoList={relevantChangesForContributionFields(
        employeeClassPlanIds,
        changeSnapshot,
        [field],
      )}
      client={client}
      authUser={authUser}
      hasPendingEdit={getHasPendingEdit({
        field,
        client,
        formik,
      })}
    />
  );
}
