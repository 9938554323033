import { getIsLifeCoverageEIF } from "shared/types/SlfCoverages";
import type { AgeRules } from "shared/types/Client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export const shouldShowAgeRuleQuestions = (
  coverages: readonly SlfCoverageLongName[],
  ageRule: AgeRules,
) => {
  const hasLife = coverages.some((coverage) => getIsLifeCoverageEIF(coverage));
  const hasCriticalIllness = coverages.includes("Critical Illness");

  return !(ageRule === "Age Reductions" && !hasLife && !hasCriticalIllness);
};
