import { useState } from "react";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { Body1 } from "../../../components/Typography/Typography";
import { useGetDocuments, useDeleteDocument } from "../../../hooks/document";
import type { ConfirmDialogProps } from "../../../components/ConfirmDialog/ConfirmDialog";
import type { ReactNode } from "react";

import type { ClientId } from "shared/types/Client";
import type { Document, DocumentCategory } from "shared/types/Document";

type PromptDeleteOptions = Pick<
  ConfirmDialogProps,
  "title" | "confirmActionText" | "cancelActionText"
>;

type ConfirmDeleteCallback = (document: Document) => void;

type CancelDeleteCallback = (document: Document) => void;

export type AddFilesProviderContext = {
  documents: Document[];
  documentsResult: Omit<ReturnType<typeof useGetDocuments>, "data">;
  deleteDocument: (document: Document) => Promise<boolean>;
  promptDeleteDocument: (document: Document, promptOptions?: PromptDeleteOptions) => void;
  deleteDocumentResult: Omit<ReturnType<typeof useDeleteDocument>, "mutate" | "mutateAsync">;
  onConfirmDelete: (callback: ConfirmDeleteCallback) => void;
  onCancelDelete: (callback: CancelDeleteCallback) => void;
};

const defaultPrompDeleteOptions: Omit<ConfirmDialogProps, "onConfirm" | "onCancel"> = {
  title: "Delete file",
  confirmActionText: "Delete",
  cancelActionText: "Cancel",
  isVisible: false,
  children: undefined,
};

export type AddFilesProviderProps = {
  clientId: ClientId;
  category: DocumentCategory;
  policyId?: string;
  children: (context: AddFilesProviderContext) => ReactNode;
};

export const AddFilesProvider = ({
  clientId,
  category,
  policyId,
  children,
}: AddFilesProviderProps) => {
  const { data, ...documentsResult } = useGetDocuments({
    clientId,
    categories: [category],
    policyId,
  });
  const { mutateAsync: deleteDocumentAsync, ...deleteDocumentResult } = useDeleteDocument(
    category,
    policyId,
  );
  const [promptDeleteOptions, setPromptDeleteOptions] = useState(defaultPrompDeleteOptions);
  const [deletingDocument, setDeletingDocument] = useState<Document | undefined>();
  const [handleOnConfirmDelete, setHandleOnConfirmDelete] = useState<ConfirmDeleteCallback>(
    () => (document: Document) => document && deleteDocument(document),
  );
  const [handleOnCancelDelete, setHandleOnCancelDelete] = useState<CancelDeleteCallback>(
    () => () => setPromptDeleteOptions({ ...promptDeleteOptions, isVisible: false }),
  );

  const documents = documentsResult.isSuccess ? data ?? [] : [];

  const deleteDocument: AddFilesProviderContext["deleteDocument"] = async (document) => {
    if (document && clientId) {
      try {
        await deleteDocumentAsync({
          params: { documentId: document.id, clientId },
        });
        return true;
      } catch {
        return false;
      } finally {
        setPromptDeleteOptions({ ...promptDeleteOptions, isVisible: false });
      }
    }
    return false;
  };

  const promptDeleteDocument: AddFilesProviderContext["promptDeleteDocument"] = (
    document,
    promptOptions = {},
  ) => {
    setDeletingDocument(document);
    setPromptDeleteOptions({
      ...defaultPrompDeleteOptions,

      ...promptOptions,
      isVisible: true,
    });
  };

  const onConfirmDelete: AddFilesProviderContext["onConfirmDelete"] = (callback) => {
    setHandleOnConfirmDelete(() => callback);
  };
  const onCancelDelete: AddFilesProviderContext["onCancelDelete"] = (callback) => {
    setHandleOnCancelDelete(() => callback);
  };

  return (
    <>
      {children({
        documents,
        documentsResult,
        deleteDocument,
        promptDeleteDocument,
        deleteDocumentResult,
        onConfirmDelete,
        onCancelDelete,
      })}
      <ConfirmDialog
        {...promptDeleteOptions}
        isLoading={deleteDocumentResult.isPending}
        onConfirm={() => deletingDocument && handleOnConfirmDelete(deletingDocument)}
        onCancel={() => deletingDocument && handleOnCancelDelete(deletingDocument)}
      >
        <Body1 as="p">Are you sure you want to delete this file: {deletingDocument?.name}?</Body1>
      </ConfirmDialog>
    </>
  );
};
