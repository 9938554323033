import { Button } from "client/src/components/Button/Button";
import { DownloadIcon } from "client/src/components/Icons/DownloadIcon";
import { useDownloadPlanSummary } from "client/src/domain/ExplorerPages/ClientPage/Benefit/useExplorerPlanState";
import { i18n } from "client/src/i18n";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

/**
 * Props for the {@link PlanSummaryLink} component.
 */
type PlanSummaryLinkProps = {
  /**
   * The benefit for which to render the download link.
   */
  benefit: ExplorerPageBenefit;
  /**
   * The label for the download link.
   */
  label: string;
  /**
   * Whether to show the download icon.
   */
  showIcon?: boolean;
};

/**
 * A link component to download the plan summary for a given benefit.
 */
export function PlanSummaryLink({ benefit, label, showIcon }: PlanSummaryLinkProps) {
  const { documentURL, spanishDocumentURL } = useDownloadPlanSummary({ plan: benefit });

  const language =
    i18n.language === "en" || i18n.language.startsWith("en-")
      ? "en"
      : i18n.language === "es" || i18n.language.startsWith("es-")
      ? "es"
      : undefined;
  const url = language === "en" ? documentURL : spanishDocumentURL;

  return (
    url && (
      <Button
        type="text-only"
        role="link"
        size="middle"
        icon={showIcon ? <DownloadIcon /> : undefined}
        iconPosition={showIcon ? "right" : undefined}
        href={url.objectUrl}
      >
        {label}
      </Button>
    )
  );
}
