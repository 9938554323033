import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { H3, Body3, Body2 } from "client/src/components/Typography/Typography";
import { BenefitsInThisPolicy } from "client/src/domain/Client/BenefitsInThisPolicy";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { Fragment } from "react";
import { RouteData } from "shared/config/routeData";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo } from "shared/types/Change";
import type { EIFSubStepViewMode, Client, Policy } from "shared/types/Client";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type Props = {
  client: Client;
  getChangeDetailInfoList: (policy: Policy) => (ChangeDetailInfo | undefined)[];
  authUser: UserData | null;
  viewMode: EIFSubStepViewMode;
  eifStepId: EIFStepId;
  eifSubStepId: EIFSubStepId;
  previousSubStepLink: string | null;
  nextSubStepLink: string;
  getStatus: (policy: Policy) => EIFStepCompleteStatus;
  header: JSX.Element;
  summary: (policy: Policy) => JSX.Element;
  footer: JSX.Element | null;
};

export function EIFSubStepPolicySelection(props: Props) {
  const {
    client,
    getChangeDetailInfoList,
    authUser,
    viewMode,
    eifStepId,
    eifSubStepId,
    previousSubStepLink,
    nextSubStepLink,
    getStatus,
    header,
    summary,
    footer,
  } = props;

  return (
    <StackY dist={40} wrap={false}>
      <div>
        {header}

        <HubCard>
          {client.policies.length === 0 && <Body3>No policies to list</Body3>}

          {client.policies.map((policy, index, arr) => {
            const status = getStatus(policy);

            const to = RouteData.eifSubStepPolicyDetail.getPath(
              client.id,
              eifStepId,
              eifSubStepId,
              policy.id,
            );

            const showEditBanner = getShowEditBanner(viewMode);

            const changeDetailInfoList = getChangeDetailInfoList(policy);

            return (
              <Fragment key={policy.id}>
                <div className="px-24 py-32 stack-y-20">
                  {showEditBanner && (
                    <EditBanner
                      navigateLink={to}
                      changeDetailInfoList={changeDetailInfoList}
                      client={client}
                      authUser={authUser}
                    />
                  )}

                  <Row>
                    <Col className="mt-4">
                      <EIFSectionStatusIconography status={status} />
                    </Col>

                    <Col flex={1}>
                      <Row align="middle" justify="space-between">
                        <Col flex={1}>
                          <Row align="middle" justify="space-between">
                            <Col flex={1}>
                              <H3 greyMedium={status === "Not Started"} style={{ margin: 0 }}>
                                {policy.primaryPolicy
                                  ? `Policy #${policy.slfPolicyNumber} (Primary)`
                                  : `Policy #${policy.slfPolicyNumber}`}
                              </H3>
                            </Col>

                            {viewMode === "pre-sign" && status !== "Not Started" && (
                              <Col>
                                <Button to={to} size="small" shrink type="text-only">
                                  Edit
                                </Button>
                              </Col>
                            )}
                          </Row>

                          <Body3 as="div" greyMedium={status === "Not Started"}>
                            Effective date:{" "}
                            {policy.policyEffective
                              ? formatDateFullMonthWithYear(policy.policyEffective)
                              : "-"}
                          </Body3>

                          <BenefitsInThisPolicy policy={policy} />
                        </Col>

                        {viewMode === "pre-sign" && status === "Not Started" && (
                          <Col>
                            <Button to={to} size="small" shrink>
                              Start
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  {status !== "Not Started" && (
                    <>
                      {status === "In Progress" && (
                        <AlertBanner
                          variant="error"
                          message={
                            <Body3>
                              Click <Body2>Edit</Body2> to complete this section.
                            </Body3>
                          }
                        />
                      )}

                      {summary(policy)}
                    </>
                  )}
                </div>

                {index !== arr.length - 1 && <hr className="m-0" />}
              </Fragment>
            );
          })}
        </HubCard>
      </div>

      <EIFBottomNavButtons
        previousLink={previousSubStepLink}
        previousButtonDisabled={false}
        nextLink={nextSubStepLink}
        nextButtonDisabled={false}
      />

      {footer}
    </StackY>
  );
}
