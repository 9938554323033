import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { RouteData } from "../../../../../shared/config/routeData";
import { StackY } from "../../../components/Spacing/Spacing";
import type { Client } from "../../../../../shared/types/Client";
import type { EIFStepId } from "../../../../../shared/types/EIF";

type Props = {
  eifStepId: EIFStepId;
  client: Client;
};

export const EIFPlanAdministratorsAndBillingIntroduction = ({ eifStepId, client }: Props) => {
  return (
    <>
      <StackY dist={40}>
        <h2 className="mb-40">Tell us about your plan administrators and billing preferences</h2>
        <p>
          In this step, you’ll tell us who will have access to view or manage Sun Life plans online
          after this initial setup. You’ll also set up your billing preferences.
        </p>
      </StackY>

      <Row justify="end">
        <Col>
          <Button
            to={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, "plan-administrators")}
            type="primary"
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};
