import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import {
  DocumentDownloadV2,
  useDocumentDownload,
} from "client/src/domain/Document/DocumentDownload/DocumentDownload";
import { noop } from "lodash";
import { RouteData } from "shared/config/routeData";
import { getRoleName } from "shared/data/Role";
import { assertIsDefined } from "shared/utils/utils";
import { formatFullName } from "../../../../../../shared/utils/format";
import type { ClientError } from "@prisma/client";
import type { FC } from "react";
import type { DocumentId } from "shared/types/Document";

type AbandonedCensusDetailsProps = {
  data: ClientError[];
};

export const AbandonedCensusDetails: FC<AbandonedCensusDetailsProps> = ({ data }) => {
  assertIsDefined(data[0], "data[0]");
  const clientId = data[0].clientId;
  const documentDownloadData = useDocumentDownload({
    clientId,
    categories: ["enrollment-elections"],
    documentIds: data
      .filter((clientError) => clientError.modelType === "DOCUMENT" && clientError.modelId)
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- filter above ensures proper value
      .map((clientError) => clientError.modelId as DocumentId),
    track: noop,
    readonly: true,
    withSource: true,
    showDownloadAllButton: false,
  });

  const documents = documentDownloadData.documents.data || [];
  const policyId = documents[0]?.policyIds?.[0];

  return (
    <StackY dist={24}>
      <Body2>This client has uploaded census files but has not submitted them.</Body2>
      <DocumentDownloadV2 {...documentDownloadData} />
      {documents.map((document) => (
        <StackY dist={12} key={document.id}>
          <Body2>The census file {document.name} was uploaded by:</Body2>
          <StackY dist={1}>
            <Body3>
              <b>Name:</b> {formatFullName(document.createdByUser ?? undefined)}
            </Body3>
            <Body3>
              <b>Email:</b> {document.createdByUser?.email}
            </Body3>
            <Body3>
              <b>Role:</b> {getRoleName(document.createdByUser?.role)}
            </Body3>
          </StackY>
          <Body3>
            Please review the file and then reach out directly to this user to see if they are ready
            to submit this file.
          </Body3>
        </StackY>
      ))}

      <Button
        // policyId is undefined until useDocumentDownload hook fetches the data, but will be defined by the time someone can click the link
        href={RouteData.policyTaskDetail.getPath(clientId, "enrollment-elections", policyId ?? "")}
      >
        Go to Submit Enrollment Elections
      </Button>
      <Body3>This issue will be resolved once the censuses have been submitted</Body3>
    </StackY>
  );
};
