import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { Body2 } from "client/src/components/Typography/Typography";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";

type Props = {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
} & (
  | {
      variant: "monthly-claims-reports";
    }
  | {
      variant: "bills";
      slfPolicyNumber: string | null;
    }
);

export function UpdateSunLifeUserAccessModal(props: Props) {
  const { isVisible, onConfirm, onCancel } = props;

  const contents = getContents(props);

  return (
    <ConfirmDialog
      isVisible={isVisible}
      title="Update Sun Life Connect user?"
      confirmActionText="Yes, add access"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {contents}
    </ConfirmDialog>
  );
}

function getContents(props: Props) {
  const { variant } = props;

  switch (variant) {
    case "monthly-claims-reports":
      return (
        <>
          <p>
            The Sun Life Connect user you selected was not given{" "}
            <Body2>Sun Life Connect claims access</Body2> in your Plan Administrator settings.
          </p>

          <p>
            Adding them as the recipient for monthly claims reports and EOB statements will update
            their access to allow for viewing claims information in Sun Life Connect.
          </p>

          <p>
            Do you want to give this user claims access and add them as the recipient contact for
            monthly claims reports and EOB statements?
          </p>
        </>
      );
    case "bills":
      return (
        <>
          <p>
            The Sun Life Connect user you selected was not given{" "}
            <Body2>
              Sun Life Connect billing access and/or access for this policy (#
              {props.slfPolicyNumber || "-"})
            </Body2>{" "}
            in your Onboard settings.
          </p>

          <p>
            Do you want to give this user access to this policy and/or for billing? Please note that
            billing access applies to all policies selected for this user.
          </p>
        </>
      );
    default:
      exhaustiveCheck(variant);
  }
}
