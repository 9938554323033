import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getContactLocation } from "client/src/utils/getContactLocations";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { formatFullName } from "shared/utils/format";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { tpaContactValidationSchemaNonNullable } from "shared/validation/policy";
import { Body3, Body5, H5 } from "../../components/Typography/Typography";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";

export type TPAContactCardProps = {
  contact: Contact;
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
};

export const DataTestId = {
  TPAContactCard: "tpa-contact-card",
};

export const TPAContactCard = ({
  contact: contactProp,
  client,
  changeSnapshot,
  authUser,
}: TPAContactCardProps) => {
  const viewMode = getEIFSubStepViewMode({ client });

  const contact: Contact & { fullName?: string } = {
    ...contactProp,
    fullName: formatFullName(contactProp),
  };

  const contactLocationErrors = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getValidationErrors(
        tpaContactValidationSchemaNonNullable.pick(["address1", "city", "state", "zipCode"]),
        contact,
        {
          prefill: false,
        },
      )
    : [];

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(contact, tpaContactValidationSchemaNonNullable, {
        prefill: false,
      })
    : {};

  return (
    <FlatCard data-testid={DataTestId.TPAContactCard}>
      <StackY dist={8}>
        <Row>
          <StackX dist={8}>
            <H5>TPA Contact</H5>
          </StackX>
        </Row>
      </StackY>
      <StackY dist={16}>
        <Row gutter={[32, 18]}>
          <Col>
            <Body5 as="div">TPA firm name</Body5>
            {contact.tpaFirmName && <Body3>{contact.tpaFirmName}</Body3>}
            {e.tpaFirmName && <Body3 redMedium>{e.tpaFirmName}</Body3>}
            {!contact.tpaFirmName && !e.tpaFirmName && <Body3>-</Body3>}
          </Col>
          <Col>
            <Body5 as="div">TPA Contact Name</Body5>
            {contact.fullName && <Body3>{contact.fullName}</Body3>}
            {e.fullName && <Body3 redMedium>{e.fullName}</Body3>}
            {!contact.fullName && !e.fullName && <Body3>-</Body3>}
          </Col>
          <Col>
            <Body5 as="div">Email address</Body5>
            {contact.email && <Body3>{contact.email}</Body3>}
            {e.email && <Body3 redMedium>{e.email}</Body3>}
            {!contact.email && !e.email && <Body3>-</Body3>}
          </Col>
          <Col>
            <Body5 as="div">Phone</Body5>
            {contact.phoneNumber && <Body3>{contact.phoneNumber}</Body3>}
            {e.phoneNumber && <Body3 redMedium>{e.phoneNumber}</Body3>}
            {!contact.phoneNumber && !e.phoneNumber && <Body3>-</Body3>}
          </Col>
          {
            <Col span={24}>
              <Body5 as="div">Location</Body5>
              {getContactLocation(
                contact,
                contactLocationErrors ? contactLocationErrors : [],
                false,
              )}
            </Col>
          }
        </Row>
        <EditedFieldMsg
          changeDetailInfoList={getChangeDetailInfoForTPAContact(contact, changeSnapshot)}
          client={client}
          authUser={authUser}
        />
      </StackY>
    </FlatCard>
  );
};

const getChangeDetailInfoForTPAContact = (
  tpaContact: Contact,
  changeSnapshot: DEIFChangeSnapshot,
) => {
  return [
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.tpaFirmName,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.firstName,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.lastName,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.email,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.phoneNumber,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.title,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.address1,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.address2,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.city,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.state,
    changeSnapshot.Contact[tpaContact?.id ?? ""]?.zipCode,
  ];
};
