import { ButtonOld } from "client/src/components/Button/ButtonOld";
import clsx from "clsx";
import { useState } from "react";

import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { LoadingError } from "../../../components/Error/LoadingError";
import { ReactComponent as ShieldIcon } from "../../../components/Icons/ShieldIcon.svg";
import { TrashIcon } from "../../../components/Icons/TrashIcon";
import { Spinner } from "../../../components/Spinner/Spinner";
import { Body1 } from "../../../components/Typography/Typography";
import { useGetDocuments, useDeleteDocument } from "../../../hooks/document";
import { DocumentUploadSingle } from "../DocumentUploadSingle/DocumentUploadSingle";

import * as styles from "./addFile.module.less";

import type { ClientId } from "shared/types/Client";
import type { DocumentCategory, DocumentId } from "shared/types/Document";

export type AddFileProps = {
  clientId: ClientId;
  category: DocumentCategory;
  policyId?: string;
  addDocumentsLabel: string;
  replaceDocumentsLabel: string;
  deleteDocumentsLabel: string;
  onClick?: () => void;
  track: (buttonLabel: string) => void;
  infoMessage?: string;
};

export const AddFile = ({
  clientId,
  category,
  policyId,
  addDocumentsLabel,
  replaceDocumentsLabel = addDocumentsLabel,
  deleteDocumentsLabel,
  onClick = () => undefined,
  track,
  infoMessage,
}: AddFileProps) => {
  const { isFetching, error, data } = useGetDocuments({
    clientId,
    categories: [category],
    policyId,
  });

  const {
    mutateAsync: deleteDocument,
    isPending: isPendingDeleteDocument,
    isError: errorDeleteDocument,
  } = useDeleteDocument(category, policyId);

  const emptyDeleteParams = { isVisible: false, docIdToDelete: null, name: null };
  const [deleteDocumentParams, setDeleteDocumentParams] = useState<{
    isVisible: boolean;
    docIdToDelete: DocumentId | null;
    name: string | null;
  }>(emptyDeleteParams);
  const atLeastOneDoc = data && data.length > 0;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
  const existingDocId = atLeastOneDoc && data && data[0]!.id;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
  const existingDocName = atLeastOneDoc && data && data[0]!.name;
  const uploadButtonLabel = atLeastOneDoc ? replaceDocumentsLabel : addDocumentsLabel;

  const handleConfirmDeleteDocument = async () => {
    if (deleteDocumentParams.docIdToDelete) {
      try {
        await deleteDocument({
          params: { documentId: deleteDocumentParams.docIdToDelete, clientId },
        });
      } finally {
        resetDeleteDocumentParams();
        onClick();
      }
    }
  };

  const resetDeleteDocumentParams = () => {
    setDeleteDocumentParams(emptyDeleteParams);
  };

  if (error || errorDeleteDocument) {
    return (
      <div className={styles.notReadyWrapper}>
        <LoadingError type="component" />
      </div>
    );
  }

  return (
    <div className={styles.addFile}>
      <div className={clsx(styles.notReadyWrapper, !isFetching && styles.hide)}>
        <Spinner size="small" />
      </div>

      <div
        className={clsx(
          styles.linkList,
          isFetching && styles.hide,
          isPendingDeleteDocument && styles.disabled,
        )}
      >
        <DocumentUploadSingle
          clientId={clientId}
          category={category}
          policyId={policyId}
          buttonLabel={uploadButtonLabel}
          buttonIcon={<ShieldIcon />}
          buttonType="link"
          buttonClassName={styles.iconLink}
          onClick={onClick}
          track={track}
          infoMessage={infoMessage}
        />

        {atLeastOneDoc && (
          <>
            <ButtonOld
              type="link"
              icon={<TrashIcon />}
              onClick={() => {
                track(deleteDocumentsLabel);
                setDeleteDocumentParams({
                  isVisible: true,
                  docIdToDelete: String(existingDocId),
                  name: existingDocName || null,
                });
                onClick();
              }}
              className={styles.iconLink} // @todo: FP-3498: move to Link component
              destructive={true}
            >
              {deleteDocumentsLabel}
            </ButtonOld>
            <ConfirmDialog
              title={"Delete file"}
              isVisible={deleteDocumentParams.isVisible}
              isLoading={isPendingDeleteDocument}
              confirmActionText={"Delete"}
              cancelActionText={"Cancel"}
              onConfirm={handleConfirmDeleteDocument}
              onCancel={resetDeleteDocumentParams}
            >
              <Body1 as="p">
                Are you sure you want to delete this file: {deleteDocumentParams.name}?
              </Body1>
            </ConfirmDialog>
          </>
        )}
      </div>
    </div>
  );
};
