import { useMemo } from "react";
import { isClientTableFilters } from "shared/types/Client";
import { PhaseIdValues } from "shared/types/Policy";
import { getObjectFromURLSearchParams } from "../utils/url";
import { useQueryString } from "./useQueryString";
import { useLocalStorageClientTableFilters } from "./useSlobLocalStorage";
import type { SlfImplementationConsultant } from "../../../shared/types/User";
import type { ClientTableFilters } from "shared/types/Client";

export function useClientTableFilters(
  userId: string,
  implementationConsultants: SlfImplementationConsultant[],
) {
  const queryString = useQueryString();

  const [localStorageFilters] = useLocalStorageClientTableFilters(userId);

  const defaultFilter = useMemo<ClientTableFilters>(() => {
    const ic = implementationConsultants?.find((ic) => ic.id === userId)?.id;
    return {
      ic: ic ? [ic] : [],
      noic: false,
      isOnboard: true,
      isBenefitsExplorer: true,
      isNewIssue: true,
      isAddCoverage: true,
      phaseIds: PhaseIdValues.filter((val) => val !== "ONBOARDING_COMPLETE" && val !== "REJECTED"),
      ebrEmails: undefined,
      clientErrorTypes: [],
    };
  }, [userId, implementationConsultants]);

  const filters = useMemo<ClientTableFilters>(() => {
    const queryFiltersUntyped = getObjectFromURLSearchParams(queryString);
    const queryFilters = isClientTableFilters(queryFiltersUntyped) ? queryFiltersUntyped : {};

    return {
      ic: queryFilters.ic ?? localStorageFilters?.ic ?? defaultFilter.ic,
      noic: queryFilters.noic ?? localStorageFilters?.noic ?? defaultFilter.noic,
      isOnboard:
        queryFilters.isOnboard ?? localStorageFilters?.isOnboard ?? defaultFilter.isOnboard,
      isBenefitsExplorer:
        queryFilters.isBenefitsExplorer ??
        localStorageFilters?.isBenefitsExplorer ??
        defaultFilter.isBenefitsExplorer,
      isNewIssue:
        queryFilters.isNewIssue ?? localStorageFilters?.isNewIssue ?? defaultFilter.isNewIssue,
      isAddCoverage:
        queryFilters.isAddCoverage ??
        localStorageFilters?.isAddCoverage ??
        defaultFilter.isAddCoverage,
      phaseIds: queryFilters.phaseIds ?? localStorageFilters?.phaseIds ?? defaultFilter.phaseIds,
      ebrEmails:
        queryFilters.ebrEmails ?? localStorageFilters?.ebrEmails ?? defaultFilter.ebrEmails,
      clientErrorTypes:
        queryFilters.clientErrorTypes ??
        localStorageFilters?.clientErrorTypes ??
        defaultFilter.clientErrorTypes,
    };
  }, [
    queryString,
    defaultFilter.ebrEmails,
    defaultFilter.ic,
    defaultFilter.isBenefitsExplorer,
    defaultFilter.isOnboard,
    defaultFilter.isNewIssue,
    defaultFilter.isAddCoverage,
    defaultFilter.noic,
    defaultFilter.phaseIds,
    defaultFilter.clientErrorTypes,
    localStorageFilters?.ebrEmails,
    localStorageFilters?.ic,
    localStorageFilters?.isBenefitsExplorer,
    localStorageFilters?.isOnboard,
    localStorageFilters?.isNewIssue,
    localStorageFilters?.isAddCoverage,
    localStorageFilters?.noic,
    localStorageFilters?.phaseIds,
    localStorageFilters?.clientErrorTypes,
  ]);

  return filters;
}
