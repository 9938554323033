import { useSlobQuery } from "./query";

import type { BenAdminPlatform } from "../../../shared/types/BenAdminPlatform";
import type { JsonToTypeMapper } from "./query";

export const jsonBenAdminPlatformToBenAdminPlatform: JsonToTypeMapper<BenAdminPlatform> = (
  benAdminPlatform,
) => {
  return {
    ...benAdminPlatform,
    createdAt: new Date(benAdminPlatform.createdAt),
    updatedAt: new Date(benAdminPlatform.updatedAt),
  };
};

export const useGetBenAdminPlatforms = () =>
  useSlobQuery<BenAdminPlatform[]>({
    method: "get",
    path: `/api/ben-admin-platforms`,
    map: (b) => b.map(jsonBenAdminPlatformToBenAdminPlatform),
  });
