import { StackY } from "client/src/components/Spacing/Spacing";
import { useState } from "react";
import { getPrimaryPolicy, isAddCoverageClient } from "shared/utils/client";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { GuidanceModal } from "../../../components/GuidanceModal/GuidanceModal";
import { H1, Body1 } from "../../../components/Typography/Typography";
import { BenefitsAdministrationForm } from "../../../domain/Client/BenefitsAdministrationForm";
import { DataFeedsForm } from "../../../domain/Client/DataFeedsForm";
import { EnrollmentEventForm } from "../../../domain/Client/EnrollmentEventForm";
import { SetYourGoals } from "../../../domain/Client/SetYourGoals";
import { useToggleActiveSection } from "../../../domain/Client/useToggleActiveSection";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import type { Task } from "../../../../../shared/data/Tasks";
import type { BenAdminPlatform } from "../../../../../shared/types/BenAdminPlatform";
import type { Client } from "../../../../../shared/types/Client";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { UpdatePolicyQuery } from "client/src/hooks/policy";

export type CompanyProfilePagePresentationProps = {
  client: Client;
  task: Task;
  benAdminPlatforms: BenAdminPlatform[];
  isInternalUser: boolean;
  updateClientQuery: UpdateClientQuery;
  updatePolicyQuery: UpdatePolicyQuery;
  trackElementClicked: TrackElementClickedFunc;
};

export function CompanyProfilePagePresentation(props: CompanyProfilePagePresentationProps) {
  const {
    client,
    task,
    benAdminPlatforms,
    isInternalUser,
    updateClientQuery,
    updatePolicyQuery,
    trackElementClicked,
  } = props;

  const { mutateAsync: updateClient } = updateClientQuery;

  const [guidanceModalVisible, setGuidanceModalVisible] = useState(false);

  const remainingTasks = hasRemainingHubTasks(client);
  const { setYourGoalsUpdatedAt, enrollmentEventUpdatedAt } = client;
  const primaryPolicy = getPrimaryPolicy(client);
  const { dataFeedsUpdatedAt, benAdminPlatformUpdatedAt } = primaryPolicy;

  const totalForms = [
    Boolean(setYourGoalsUpdatedAt),
    Boolean(enrollmentEventUpdatedAt),
    Boolean(dataFeedsUpdatedAt),
    Boolean(benAdminPlatformUpdatedAt),
  ];
  const remainingCount = totalForms.length - totalForms.filter(Boolean).length;
  const submittingFinalForm = remainingCount === 1;

  const finalSubmitHandler = submittingFinalForm ? setGuidanceModalVisible : undefined;

  const {
    statusEnrollmentEventForm,
    statusBenefitsAdministrationForm,
    statusDataFeedsForm,
    statusSetYourGoals,
    toggleEnrollmentEventForm,
    toggleBenefitsAdministrationForm,
    toggleDataFeedsForm,
    toggleSetYourGoals,
  } = useToggleActiveSection();

  const isSubmitting = updateClientQuery.isPending || updatePolicyQuery.isPending;

  const usingDEIF = client.digitalEIF;
  const isAddCoverage = isAddCoverageClient(client);
  const title = usingDEIF
    ? isAddCoverage
      ? "Your enrollment event"
      : "Your enrollment event & goals"
    : "Your Company Profile";

  return (
    <>
      <ChildPageLayout
        topNavTitle={title}
        aside={
          <>
            <H1>{title}</H1>
            <Body1 as="p">
              {isAddCoverage
                ? usingDEIF
                  ? "Complete these fields to set up your enrollment event if you are having one."
                  : `Make sure the company information we have is still up to date so our team can assist
            with adding your new benefits.`
                : `Complete these fields to help our team learn how we can best help you with your new
            benefits.`}
            </Body1>
          </>
        }
        contents={
          <StackY dist={40} wrap={false}>
            <EnrollmentEventForm
              client={client}
              editing={statusEnrollmentEventForm === "editing"}
              disabled={statusEnrollmentEventForm === "disabled" || isSubmitting}
              updateClient={updateClient}
              toggleActiveSection={toggleEnrollmentEventForm}
              trackElementClicked={trackElementClicked}
              onFinalSubmit={finalSubmitHandler}
            />

            {!usingDEIF && (
              <BenefitsAdministrationForm
                client={client}
                benAdminPlatforms={benAdminPlatforms}
                editing={statusBenefitsAdministrationForm === "editing"}
                disabled={statusBenefitsAdministrationForm === "disabled" || isSubmitting}
                updatePolicyQuery={updatePolicyQuery}
                toggleActiveSection={toggleBenefitsAdministrationForm}
                trackElementClicked={trackElementClicked}
                onFinalSubmit={finalSubmitHandler}
              />
            )}

            {!usingDEIF && (
              <DataFeedsForm
                client={client}
                editing={statusDataFeedsForm === "editing"}
                disabled={statusDataFeedsForm === "disabled" || isSubmitting}
                updatePolicyQuery={updatePolicyQuery}
                toggleActiveSection={toggleDataFeedsForm}
                trackElementClicked={trackElementClicked}
                onFinalSubmit={finalSubmitHandler}
              />
            )}

            {!isAddCoverage && (
              <SetYourGoals
                client={client}
                editing={statusSetYourGoals === "editing"}
                disabled={statusSetYourGoals === "disabled" || isSubmitting}
                updateClient={updateClient}
                toggleActiveSection={toggleSetYourGoals}
                trackElementClicked={trackElementClicked}
                onFinalSubmit={finalSubmitHandler}
              />
            )}
          </StackY>
        }
      />

      {!isInternalUser && (
        <GuidanceModal
          isVisible={guidanceModalVisible}
          clientId={client.id}
          nextText="This will help our team learn how we can best help you with your new benefits. Your Implementation Consultant will let you know if anything further is needed from you."
          onCancel={() => setGuidanceModalVisible(false)}
          remainingTasks={remainingTasks}
          taskStatus={task.status}
          titleText={
            usingDEIF
              ? "You've completed your enrollment event and goals!"
              : "You've completed your Company Profile!"
          }
          trackElementClicked={trackElementClicked}
        />
      )}
    </>
  );
}
