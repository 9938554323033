import { formatDate } from "shared/utils/format";

import { unique } from "shared/utils/utils";
import type { Policy } from "shared/types/Client";

export const sortPoliciesEffectiveDates = (policies: Policy[]) => {
  return policies
    .map((p) => p.policyEffective)
    .filter((d): d is Date => Boolean(d))
    .sort((a, b) => a.getTime() - b.getTime());
};

export const getSortedDedupePoliciesEffectiveDates = (policies: Policy[]) => {
  return unique(sortPoliciesEffectiveDates(policies).map((d: Date) => formatDate(d)));
};

export const getPoliciesEffectiveDates = (policies: Policy[]) => {
  return [...policies.map((p: Policy) => p.policyEffective && formatDate(p.policyEffective))];
};
