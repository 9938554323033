import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { employeeCertificatesValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { employeeCertificateFormKeyProps } from "shared/validation/otherContractDetailsFormKeys";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { TextArea } from "../../components/Form/TextArea";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body5 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
};

export const formTestId = "form";

export function EIFEmployeeCertificate(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;
  const eifSubStepId = "employee-certificate";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(employeeCertificateFormKeyProps),
    updateClient,
    validationSchema: employeeCertificatesValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(employeeCertificateFormKeyProps)(client),
  );

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, employeeCertificatesValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>

        <StackY dist={32}>
          <HubCard>
            <p>
              By default, certificates are split by benefit and include your policyholder name.
              You’ll receive a notification when the certificates are available to view and download
              in your employer web portal, Sun Life Connect.
            </p>

            <StackY dist={16}>
              <RadioGroup
                name="shouldCertificatesBeSplitInAnotherWay"
                label={
                  <>
                    <Body2 as="p">
                      Would you like to have your certificates split in another way?
                    </Body2>

                    <p className="mb-0">For example, split by class or location.</p>
                  </>
                }
                disabled={formik.isSubmitting}
                touched={
                  formik.touched.shouldCertificatesBeSplitInAnotherWay ||
                  !!strictErrors.shouldCertificatesBeSplitInAnotherWay
                }
                error={
                  formik.errors.shouldCertificatesBeSplitInAnotherWay ||
                  strictErrors.shouldCertificatesBeSplitInAnotherWay
                }
                options={[
                  {
                    value: "YES",
                    label: "Yes",
                  },
                  { value: "NO", label: "No" },
                ]}
                direction="vertical"
                value={formik.values.shouldCertificatesBeSplitInAnotherWay}
                onChange={formik.handleChange}
              />

              {formik.values.shouldCertificatesBeSplitInAnotherWay === "YES" && (
                <StackY dist={12} className="mt-32">
                  <TextArea
                    name="shouldCertificatesBeSplitInAnotherWayText"
                    label="Please provide details about how you would like your certificates split."
                    placeholder="Provide details here"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    value={formik.values.shouldCertificatesBeSplitInAnotherWayText}
                    touched={
                      formik.touched.shouldCertificatesBeSplitInAnotherWayText ||
                      !!strictErrors.shouldCertificatesBeSplitInAnotherWayText
                    }
                    error={
                      formik.errors.shouldCertificatesBeSplitInAnotherWayText ||
                      strictErrors.shouldCertificatesBeSplitInAnotherWayText
                    }
                    maxLength={1000}
                  />

                  <Body5 as="div">
                    Our Implementation Consultants will work to accommodate your situation as best
                    as possible, and they will review and follow up with you to discuss.
                  </Body5>
                </StackY>
              )}

              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Client.shouldCertificatesBeSplitInAnotherWay,
                  changeSnapshot.Client.shouldCertificatesBeSplitInAnotherWayText,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={
                  getHasPendingEdit({
                    field: "shouldCertificatesBeSplitInAnotherWay",
                    client,
                    formik,
                  }) ||
                  getHasPendingEdit({
                    field: "shouldCertificatesBeSplitInAnotherWayText",
                    client,
                    formik,
                  })
                }
              />
            </StackY>
          </HubCard>

          <EIFBottomNavButtons
            previousLink={previousSubStepLink}
            previousButtonDisabled={formik.isSubmitting}
            nextButtonDisabled={formik.isSubmitting}
          />
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
