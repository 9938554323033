import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { handleTaxIdChange } from "client/src/utils/handleMaskedInputChange";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { getEIFSubStepMap } from "../../../../../shared/types/EIF";
import { taxIdValidationSchema } from "../../../../../shared/validation/client";
import { Anchor } from "../../../components/Anchor/Anchor";
import { FormInput } from "../../../components/Form/Input";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../../Client/useClientUtils";
import { EIFBottomNavButtons } from "../EIFBottomNavButtons";
import type { Client } from "../../../../../shared/types/Client";
import type { ElementClickedOptions } from "../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";

const formKeyProps: (keyof Client)[] = ["taxId"];

const getClientPropertiesToUpdate = getPropertiesToUpdate<Client>(formKeyProps);

type EIFTaxIdProps = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: (options: ElementClickedOptions) => void;
  updateClient: UpdateClientFunc;
  authUser: UserData | null;
};

export const EIFTaxId = ({
  client,
  trackElementClicked,
  changeSnapshot,
  updateClient,
  authUser,
}: EIFTaxIdProps) => {
  const eifSubStepId = "tax-id";
  const subStepName = getEIFSubStepMap({ eifSubStepId });
  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };
  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: taxIdValidationSchema,
    type: "DEIF Tax ID",
    track,
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  return (
    <>
      <StackY dist={40}>
        <h2 className="mb-40">{subStepName}</h2>
        <form onSubmit={formik.handleSubmit}>
          <StackY dist={32}>
            <HubCard>
              <StackY dist={16}>
                <Body2>What is your company’s Tax ID Number?</Body2>

                <Body3>
                  A federal tax identification number is used to identify a company to the IRS. This
                  is also known as an Employer Identification Number (EIN).
                </Body3>
                <StackY dist={8}>
                  <Row>
                    <Col xs={10}>
                      <FormInput
                        label="12-3456789"
                        value={formik.values.taxId}
                        maxLength={10}
                        name="taxId"
                        onChange={handleTaxIdChange((maskedTaskId) =>
                          formik.setFieldValue("taxId", maskedTaskId),
                        )}
                        disabled={formik.isSubmitting}
                        touched={formik.touched.taxId}
                        error={formik.errors.taxId}
                      />
                    </Col>
                  </Row>
                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.taxId ?? null]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({ field: "taxId", client, formik })}
                  />
                </StackY>
              </StackY>
            </HubCard>
            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </form>

        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>

        <hr />
        <div>
          <h2>Do I need a Tax ID Number (EIN)? </h2>
          <Body3 as="p">
            Yes, generally companies need an EIN. You may apply for an EIN in various ways. To learn
            more or apply for an EIN online,{" "}
            <Anchor href="https://www.irs.gov/" target="_blank">
              visit the IRS website.
            </Anchor>
          </Body3>
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
