import { message } from "antd";

import { ReactComponent as CircleComplete } from "../Icons/CircleComplete.svg";
import { Spinner } from "../Spinner/Spinner";

import * as styles from "./slobMessage.module.less";

import type { ArgsProps, ConfigOnClose, MessageInstance } from "antd/lib/message";

type NoticeType = "info" | "success" | "error" | "warning" | "loading";

type ConfigContent = React.ReactNode | string;
type JointContent = ConfigContent | ArgsProps;
type ConfigDuration = number | (() => void);

const getIcon = (type: NoticeType) => {
  switch (type) {
    case "success":
      return (
        <span role="img" className={styles.slobMessageIcon} aria-label="Success">
          <CircleComplete />
        </span>
      );
    case "loading":
      return (
        <span role="img" className={styles.slobMessageIcon} aria-label="Loading">
          <Spinner size="xsmall" />
        </span>
      );
    default:
      return undefined;
  }
};

const attachTypeApi = (
  type: NoticeType,
  content: JointContent,
  duration?: ConfigDuration,
  onClose?: ConfigOnClose,
) => {
  const icon = getIcon(type);
  if (isArgsProps(content)) {
    return message.open({
      ...content,
      type,
      className: styles.slobMessageStyles,
      icon,
    });
  }

  if (typeof duration === "function") {
    onClose = duration;
    duration = undefined;
  }

  return message.open({
    type,
    content,
    // When not provided, duration HAS to be undefined, not null,
    // or otherwise the toaster will never go away
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- 👆
    // @ts-ignore
    duration,
    onClose,
    className: styles.slobMessageStyles,
    icon,
  });
};

export const slobMessage: MessageInstance = {
  info: (...args) => attachTypeApi("info", ...args),
  success: (...args) => attachTypeApi("success", ...args),
  error: (...args) => attachTypeApi("error", ...args),
  warning: (...args) => attachTypeApi("warning", ...args),
  loading: (...args) => attachTypeApi("loading", ...args),
  open: (args) => attachTypeApi(args.type || "info", args),
};

function isArgsProps(content: JointContent): content is ArgsProps {
  return (
    Object.prototype.toString.call(content) === "[object Object]" &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- disable
    // @ts-ignore
    !!content?.content
  );
}
