import { categoryNameByCategoryType } from "shared/types/Document";
import type { DocumentCategory } from "shared/types/Document";

/**
 * Sanitize the document by removing all the characters that are not allowed to Amazon S3.
 * Valid Characters are: letters, numbers, !-_.*'() and space
 * Invalid Characters are: &, $, @, =, ;, /, :, +, ?, \, {, }, ^, %, `, [, ], ‘, ’, “, ”, <, >, ~, #, | and ANSI non-printable characters
 * Ref: https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
 *
 * @param documentName - The document name to sanitize.
 * @returns The sanitized document name.
 */
export const sanitizeDocumentName = (documentName: string): string => {
  return documentName.match(/(\w|!|-|\.|\*'|\(|\)\s)/gi)?.join("") ?? "";
};

export const documentCategorySorter = (a: DocumentCategory, b: DocumentCategory) =>
  categoryNameByCategoryType[a].localeCompare(categoryNameByCategoryType[b]);
