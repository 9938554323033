import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { Body1, H2 } from "client/src/components/Typography/Typography";
import { Trans, useTranslation } from "client/src/i18n";
import clsx from "clsx";
import * as styles from "./triAgencyDisclosure.module.less";

export const TriAgencyDisclosure = () => {
  const { t } = useTranslation({ keyPrefix: "HospitalIndemnityDisclosure" });
  const isMobile = !useBreakpoint().md;

  const disclosureListItems: string[] = t("disclosure.listItems", {
    returnObjects: true,
    defaultValue: [],
  });
  const lookingForListItems: string[] = t("lookingFor.listItems", {
    returnObjects: true,
    defaultValue: [],
  });
  const questionsListItems: string[] = t("questions.listItems", {
    returnObjects: true,
    defaultValue: [],
  });

  return (
    <div className={clsx(styles.disclosure, !isMobile && "flex")}>
      <div className={clsx(!isMobile && styles.section, !isMobile && "mr-40")}>
        <H2>{t("disclosure.heading")}</H2>
        <Body1 as="p">{t("disclosure.description")}</Body1>
        <ul>
          {disclosureListItems.map((item) => (
            <Body1 as="li">{item}</Body1>
          ))}
        </ul>
      </div>
      <div className={clsx(!isMobile && styles.section)}>
        <H2>{t("lookingFor.heading")}</H2>
        <ul>
          {lookingForListItems.map((item) => (
            <Body1 as="li">
              <Trans
                i18nKey={item}
                components={[
                  <Anchor href="https://www.healthcare.gov/" target="_blank" newTabIcon>
                    HealthCare.gov
                  </Anchor>,
                ]}
              />
            </Body1>
          ))}
        </ul>
        <H2>{t("questions.heading")}</H2>
        <ul>
          {questionsListItems.map((item) => (
            <Body1 as="li">
              <Trans
                i18nKey={item}
                components={[
                  <Anchor href="https://content.naic.org/" target="_blank" newTabIcon>
                    naic.org
                  </Anchor>,
                ]}
              />
            </Body1>
          ))}
        </ul>
      </div>
    </div>
  );
};
