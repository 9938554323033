import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, H3 } from "client/src/components/Typography/Typography";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { type EIFClassBuilderSection } from "shared/utils/EIF/getEIFStepCompleteStatus";
import type { PropsWithChildren } from "react";
import type { Location } from "react-router-dom";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type Props = PropsWithChildren<{
  status: EIFStepCompleteStatus;
  isLoading: boolean;
  location: Location;
  inactiveAndEmpty: boolean;
  changeDetailInfoList: (ChangeDetailInfo | undefined)[];
  section: EIFClassBuilderSection | string;
  client: Client;
  authUser: UserData | null;
  hideActionButtons?: boolean | null;
}>;

export function EIFPlanConfigAndEligibilitySectionHeader(props: Props) {
  const {
    status,
    isLoading,
    location,
    inactiveAndEmpty,
    changeDetailInfoList,
    children,
    section,
    client,
    authUser,
    hideActionButtons,
  } = props;

  const buttons = (
    <>
      {status !== "Not Started" ? (
        <InternalLinkButton
          to={location.pathname + `?section=${section}`}
          type="link-inline-bold"
          size="middle"
          disabled={isLoading}
        >
          Edit
        </InternalLinkButton>
      ) : (
        <Button
          to={location.pathname + `?section=${section}`}
          size="small"
          shrink
          disabled={isLoading}
        >
          Start
        </Button>
      )}
    </>
  );

  return (
    <StackY dist={16} wrap={false}>
      <div>
        <Row align="middle">
          <Col flex={1}>
            <H3 as="h2" greyMedium={inactiveAndEmpty}>
              <EIFSectionStatusIconography status={status} />
              {section}
            </H3>
          </Col>

          {!hideActionButtons && <Col>{buttons}</Col>}
        </Row>

        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
        />

        {status === "In Progress" && (
          <AlertBanner
            variant="error"
            message={
              <Body3>
                Click <Body2>Edit</Body2> to complete this section.
              </Body3>
            }
          />
        )}
      </div>

      {children}
    </StackY>
  );
}
