import { useQueryClient } from "@tanstack/react-query";
import { jsonClientPoliciesToClientPolicies } from "client/src/hooks/client";
import { compareQueryKey, useSlobMutation } from "client/src/hooks/query";
import type { ResponseError } from "client/src/hooks/query";
import type { Policy, PolicyInput } from "shared/types/Client";

export type UpdatePolicyQuery = Pick<
  ReturnType<typeof useUpdatePolicy>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export type UpdatePolicyFunc = UpdatePolicyQuery["mutateAsync"];

export const useUpdatePolicy = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<PolicyInput>,
    Policy,
    `/api/clients/:clientId/policies/:policyId`,
    ResponseError,
    unknown,
    { prefill?: boolean }
  >({
    method: "put",
    path: `/api/clients/:clientId/policies/:policyId`,
    map: jsonClientPoliciesToClientPolicies,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
        });
      },
    },
  });
};
