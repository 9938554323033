import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";

export const EIFOtherContractDetailsIntroduction = () => {
  const { nextSubStepLink } = useGetEIFPreviousAndNextLink();
  return (
    <>
      <h1>Let's get some final details</h1>
      <p>In this section we'll ask about any remaining info needed to complete your contracts.</p>
      <Row justify="end">
        <Col>
          <Button to={nextSubStepLink} type="primary" size="large">
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};
