import { useQueryClient } from "@tanstack/react-query";

import { compareQueryKey, useSlobMutation, useSlobQuery } from "./query";

import type { Carrier } from "../../../shared/types/Carrier";
import type { Pretty } from "../../../shared/types/Helper";
import type { carrierInputValidation } from "../../../shared/validation/carrier";
import type { JsonToTypeMapper } from "./query";
import type { InferType } from "yup";

export const useGetCarriers = () =>
  useSlobQuery<Carrier[]>({
    method: "get",
    path: `/api/carriers`,
    map: (c) => c.map(jsonCarrierToCarrier),
  });

export const useCreateCarrier = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<Pretty<InferType<typeof carrierInputValidation>>, Carrier>({
    method: "post",
    path: `/api/carriers`,
    map: jsonCarrierToCarrier,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/carriers`]),
        });
      },
    },
  });
};

const jsonCarrierToCarrier: JsonToTypeMapper<Carrier> = (carrier) => {
  return {
    id: carrier.id,
    carrierName: carrier.carrierName,
    logoUrl: carrier.logoUrl,
  };
};
