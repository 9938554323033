import { Portal } from "@reach/portal";
import { Popover } from "antd";
import { Button } from "client/src/components/Button/Button";
import { FormInput } from "client/src/components/Form/Input";
import { ReactComponent as PencilIcon } from "client/src/components/Icons/PencilIcon.svg";
import { StackX } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useClickAway } from "client/src/hooks/useClickAway";
import { useFocus } from "client/src/hooks/useFocus";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import clsx from "clsx";
import {
  useEffect,
  type DispatchWithoutAction,
  type PropsWithChildren,
  type ReactNode,
  useState,
} from "react";
import { v4 as UUIDv4 } from "uuid";
import * as Yup from "yup";
import * as styles from "./RenameItem.module.less";
import type { InputRef } from "antd";

type RenameItemProps = PropsWithChildren<{
  visible: "always" | "hover";
  label?: string;
  description?: ReactNode;
  initialNewName?: string;
  maxLength: number;
  onRename: (newName: string) => void | Promise<void>;
  disabled?: boolean;
}>;

export function RenameItem(props: RenameItemProps) {
  const {
    visible,
    children,
    onRename,
    initialNewName,
    maxLength,
    label = "Rename",
    description,
    disabled,
  } = props;

  const [popoverVisible, togglePopoverVisible] = useToggler();

  useCloseOthersOnOpeningThis(popoverVisible, togglePopoverVisible);

  useCloseOnPressESC(popoverVisible, togglePopoverVisible);

  const popoverContainerRef = useClickAway<HTMLDivElement>(
    () => popoverVisible && togglePopoverVisible(),
  );

  return (
    <>
      <div className={clsx(styles.renameItemContainer, styles.renameButton_hoverableContainer)}>
        {typeof children === "string" ? <Body3>{children}</Body3> : children}{" "}
        <Popover
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- .
          getPopupContainer={() => popoverContainerRef.current!}
          placement="bottom"
          overlayClassName={styles.popover}
          openClassName={styles.renameButton__visible}
          content={
            <RenameItemForm
              onRename={onRename}
              togglePopoverVisible={togglePopoverVisible}
              initialNewName={initialNewName}
              maxLength={maxLength}
              label={label}
              description={description}
            />
          }
          open={popoverVisible}
          destroyTooltipOnHide={{ keepParent: false }}
        >
          <button
            onClick={disabled ? undefined : togglePopoverVisible}
            type="button"
            title={label}
            aria-label={label}
            className={clsx(
              "btn-reset",
              styles.renameButton,
              visible === "always" && styles.renameButton__visible,
            )}
            aria-disabled={disabled}
          >
            <PencilIcon />
          </button>
        </Popover>
      </div>

      <Portal type="rename-item-popover-portal">
        <div ref={popoverContainerRef} data-info={`popover container for '${label}'`} />
      </Portal>
    </>
  );
}

type RenameItemFormProps = {
  label: string;
  description?: ReactNode;
  initialNewName?: string;
  maxLength: number;
  onRename: (newName: string) => void | Promise<void>;
  togglePopoverVisible: DispatchWithoutAction;
};

export function RenameItemForm(props: RenameItemFormProps) {
  const {
    label,
    description,
    initialNewName = "",
    maxLength,
    togglePopoverVisible,
    onRename,
  } = props;

  const renameInputRef = useFocus<InputRef>(true);

  const formik = useSlobFormik({
    initialValues: {
      newName: initialNewName,
    },
    validationSchema: Yup.object({
      newName: Yup.string()
        .max(maxLength, `Cannot be longer than ${maxLength} characters`)
        .required("Required")
        .test({
          name: "name must be different",
          message: "New name cannot be the same as the old one",
          test: (value) => {
            const sameName = value === initialNewName;
            const isValid = !sameName;
            return isValid;
          },
        }),
    }),
    onSubmit: async (values) => {
      const promise = onRename(values.newName);
      if (promise instanceof Promise) {
        // Only show slobMessage if promise takes longer than 100ms
        let hide = () => {
          /*nop*/
        };
        const id = setTimeout(() => {
          hide = slobMessage.loading("Renaming...", 0);
        }, 100);

        try {
          await promise;
          togglePopoverVisible();
        } finally {
          clearTimeout(id);
          hide();
        }
      } else {
        togglePopoverVisible();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="stack-y-24">
      <FormInput
        label={label}
        name="newName"
        value={formik.values.newName}
        onChange={formik.handleChange}
        maxLength={maxLength}
        disabled={formik.isSubmitting}
        touched={formik.touched.newName}
        error={formik.errors.newName || formik.status}
        ref={renameInputRef}
        allowClear={true}
        autoComplete="off"
      />

      {description}

      <StackX dist={12}>
        <Button type="primary" htmlType="submit" loading={formik.isSubmitting}>
          Save
        </Button>
        <Button type="default" disabled={formik.isSubmitting} onClick={togglePopoverVisible}>
          Cancel
        </Button>
      </StackX>
    </form>
  );
}

const listenersMap: Map<string, DispatchWithoutAction> = new Map();

function useCloseOthersOnOpeningThis(
  popoverVisible: boolean,
  togglePopoverVisible: DispatchWithoutAction,
) {
  const [id] = useState(
    "crypto" in window && "randomUUID" in crypto ? crypto.randomUUID() : UUIDv4(),
  );

  useEffect(() => {
    const listener = () => {
      if (popoverVisible) togglePopoverVisible();
    };

    listenersMap.set(id, listener);

    return () => {
      listenersMap.delete(id);
    };
  }, [id, popoverVisible, togglePopoverVisible]);

  useEffect(() => {
    if (popoverVisible) {
      for (const [currentId, listener] of listenersMap.entries()) {
        if (currentId !== id) {
          listener();
        }
      }
    }
  }, [id, popoverVisible]);
}

function useCloseOnPressESC(popoverVisible: boolean, togglePopoverVisible: DispatchWithoutAction) {
  useEffect(() => {
    function onKeyUp(e: KeyboardEvent) {
      if (e.key === "Escape") {
        if (popoverVisible) togglePopoverVisible();
      }
    }

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [popoverVisible, togglePopoverVisible]);
}
