import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Modal } from "../../../components/Modal/Modal";
import { Body3 } from "../../../components/Typography/Typography";

type RecipientChangedToOutsideSignerModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const RecipientChangedToOutsideSignerModal = ({
  isVisible,
  onClose,
}: RecipientChangedToOutsideSignerModalProps) => {
  return (
    <Modal
      maskClosable={false}
      title="Access form via DocuSign"
      open={isVisible}
      footer={null}
      focusTriggerAfterClose={false}
      onCancel={onClose}
    >
      <Body3 as="p">
        You have a DocuSign signature that doesn’t match your name in Onboard. You must access these
        forms by logging into DocuSign directly. You will receive an email from DocuSign with a link
        to complete and sign the form.
      </Body3>
      <Body3 as="p">
        In the meantime, you can still manage all other Sun Life tasks directly within Onboard.
      </Body3>
      <Body3 as="p">
        To view these forms directly in Onboard in the future, you can update or clear your
        signature in DocuSign. Please reach out to your Implementation Consultant with any
        questions.
      </Body3>
      <Row justify="end">
        <Col className="mt-15">
          <Button type="tertiary" onClick={onClose} size="middle">
            Close
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
