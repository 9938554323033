import { useIsMounted } from "client/src/hooks/useIsMounted";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import type { NavigateOptions, To } from "react-router-dom";

export const useNavigateIfMounted: typeof useNavigate = (...useNavigateArgs) => {
  const isMountedRef = useIsMounted();

  const navigate = useNavigate(...useNavigateArgs);

  const navigateIfMounted: typeof navigate = useCallback(
    (toOrDelta: To | number, options?: NavigateOptions) => {
      if (isMountedRef.current) {
        if (typeof toOrDelta === "number") {
          const delta = toOrDelta;
          navigate(delta);
        } else {
          const to = toOrDelta;
          navigate(to, options);
        }
      }
    },
    [isMountedRef, navigate],
  );

  return navigateIfMounted;
};
