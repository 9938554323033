type MedicalOutOfNetworkBenefits = Readonly<
  Record<
    string,
    Readonly<{
      label: string;
      value: boolean;
    }>
  >
>;

export const medicalOutOfNetworkBenefits: MedicalOutOfNetworkBenefits = {
  yes: {
    label: "Yes, but at a higher cost",
    value: true,
  },
  no: {
    label: "No, unless emergency",
    value: false,
  },
};
