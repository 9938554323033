import { useState, useEffect } from "react";

/**
 * Custom hook that returns the size (width and height) of a given element.
 * The size is updated whenever the window is resized.
 *
 * @param element The target element.
 * @returns The size of the target element.
 */
export function useElementSize(element: HTMLElement | null) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      if (element) {
        const { width, height } = element.getBoundingClientRect();
        setSize({ width, height });
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [element]);

  return size;
}
