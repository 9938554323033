import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { useState } from "react";

import { Button } from "../../components/Button/Button";
import { BrokerCreateForm } from "../../domain/User/Form/BrokerCreateForm";

import type { Client } from "shared/types/Client";

type BrokerCreateProps = {
  client: Client;
  sendWelcomeEmail: boolean;
};
export const BrokerCreatePage = ({ client, sendWelcomeEmail }: BrokerCreateProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        open={isModalVisible}
        footer={null}
        centered={true}
      >
        <BrokerCreateForm
          client={client}
          sendWelcomeEmail={sendWelcomeEmail}
          handleClose={() => setIsModalVisible(false)}
        />
      </Modal>
      <Button
        type="default"
        size="middle"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} /> Add Broker
      </Button>
    </>
  );
};
