import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { EIFBenefitsChipGroup } from "client/src/domain/EIF/common/EIFBenefitsChipGroup/EIFBenefitsChipGroup";
import { getIn } from "formik";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { rejectNullableValues } from "shared/utils/utils";
import type { FormikErrors, FormikValues, useFormik } from "formik";
import type { BillPreview } from "shared/types/Bill";
import type { Policy } from "shared/types/Client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";

type Props<TValues extends FormikValues> = {
  policy: Policy;
  formik: ReturnType<typeof useFormik<TValues>>;
  form?: string;
  disabled: boolean;
  billPreviews: BillPreview[];
  prefillErrors: FormikErrors<TValues>;
  index1: number;
  index2?: number;
  advanceOrArrears: AdvanceOrArrearsSelection;
};

export function BillSeparationByBenefit<TValues extends FormikValues>(props: Props<TValues>) {
  const {
    policy,
    formik,
    form,
    disabled,
    billPreviews,
    prefillErrors,
    index1,
    index2,
    advanceOrArrears,
  } = props;

  const { advanceCoveragesAll, arrearsCoveragesAll } = getAdvanceAndArrearsCoveragesBreakdown(
    policy,
    advanceOrArrears,
  );

  const slfCoverages: SlfCoverageLongName[] | null | undefined = getIn(
    formik.values,
    "slfCoverages",
  );

  const anyAdvanceSelected = slfCoverages?.some((cov) => advanceCoveragesAll.includes(cov));
  const anyArrearsSelected = slfCoverages?.some((cov) => arrearsCoveragesAll.includes(cov));

  const slfCoveragesInOtherGroups = new Set(
    billPreviews
      ?.filter((_, i) => i !== index1 && i !== index2)
      .flatMap((billPreview) => billPreview.slfCoverages)
      .filter(rejectNullableValues) ?? [],
  );

  return (
    <StackY dist={20} wrap={false}>
      <div>
        <Body2>Which benefit(s) do you want on this bill?</Body2>
        <br />
        <Body3>
          Note that all benefits must be assigned to a bill and benefits billed in Advance and
          Arrears cannot be on the same bill.
        </Body3>
      </div>

      {advanceCoveragesAll.length > 0 && (
        <>
          <Body2 as="p">Advance Billing</Body2>

          <EIFBenefitsChipGroup
            slfCoverages={advanceCoveragesAll}
            name="slfCoverages"
            selectedSlfCoverages={getIn(formik.values, "slfCoverages")}
            touched={getIn(formik.touched, "slfCoverages") || !!prefillErrors.slfCoverages}
            error={getIn(formik.errors, "slfCoverages") || prefillErrors.slfCoverages}
            setFieldValue={formik.setFieldValue}
            form={form}
            getOptionProps={({ label, value, coverages }) => {
              const coverageIsSelectedInOtherGroup = coverages.some((cov) =>
                slfCoveragesInOtherGroups.has(cov),
              );

              return {
                label,
                value,
                disabled:
                  anyArrearsSelected ||
                  coverageIsSelectedInOtherGroup ||
                  formik.isSubmitting ||
                  disabled,
                disabledReason: anyArrearsSelected
                  ? "This benefit cannot be grouped with benfits billed in arrears"
                  : coverageIsSelectedInOtherGroup
                  ? "This benefit has already been added to a group"
                  : undefined,
              };
            }}
          />
        </>
      )}

      {arrearsCoveragesAll.length > 0 && (
        <>
          <Body2 as="p">Arrears Billing</Body2>

          <EIFBenefitsChipGroup
            slfCoverages={arrearsCoveragesAll}
            name="slfCoverages"
            selectedSlfCoverages={getIn(formik.values, "slfCoverages")}
            touched={getIn(formik.touched, "slfCoverages") || !!prefillErrors.slfCoverages}
            error={getIn(formik.errors, "slfCoverages") || prefillErrors.slfCoverages}
            setFieldValue={formik.setFieldValue}
            form={form}
            getOptionProps={({ label, value, coverages }) => {
              const coverageIsSelectedInOtherGroup = coverages.some((cov) =>
                slfCoveragesInOtherGroups.has(cov),
              );

              return {
                label,
                value,
                disabled:
                  anyAdvanceSelected ||
                  coverageIsSelectedInOtherGroup ||
                  formik.isSubmitting ||
                  disabled,
                disabledReason: anyAdvanceSelected
                  ? "This benefit cannot be grouped with benfits billed in advance"
                  : coverageIsSelectedInOtherGroup
                  ? "This benefit has already been added to a group"
                  : undefined,
              };
            }}
          />
        </>
      )}
    </StackY>
  );
}
