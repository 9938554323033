import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { ReactComponent as LockIcon2 } from "client/src/components/Icons/LockIcon2.svg";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ConfirmDialogDeclineAllChanges } from "client/src/domain/EIF/ConfirmDialogDeclineAllChanges";
import { EIFChangesSummary } from "client/src/domain/EIF/EIFChangesSummary";
import { PushToQPSButton } from "client/src/domain/EIF/PushToQPSButton";
import { SyncWithQPSInternalControl } from "client/src/domain/EIF/SyncWithQPSInternalControl";
import { getChangeDetailInfoListForStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import {
  getIsAllowedToAcceptOrDeclineEIF,
  getIsAllowedToReviewEIF,
} from "client/src/domain/EIF/common/utils/getIsAllowedToReviewEIF";
import { useChangeSnapshot } from "client/src/hooks/changeLogs";
import { useUpdateClient } from "client/src/hooks/client";
import { ResponseError } from "client/src/hooks/query";
import { useToggler } from "client/src/hooks/useToggler";
import clsx from "clsx";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { eifStepNavigation, eifStepIdMap } from "shared/types/EIF";
import { getAreTherePendingChanges } from "shared/utils/EIF/changeLog";
import { getAuthorizedSignerFullName } from "shared/utils/EIF/getAuthorizedSignerFullName";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getICEditsAcceptedAndDeclinedBy } from "shared/utils/EIF/getICEditsAcceptedAndDeclinedBy";
import { getAtLeastOneSubStepIsApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { formatDateFullMonthWithYear, formatFullName } from "shared/utils/format";
import { Badge, taskStatusToVariant, taskStatusToLabel } from "../../components/Badge/Badge";
import { InternalLinkButton } from "../../components/Button/InternalLinkButton";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { ReactComponent as StaticSpinner } from "../../components/Icons/StaticSpinner.svg";
import { SlobLink } from "../../components/SlobLink/SlobLink";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../components/Typography/Typography";
import { useGetDocumentCount } from "../../hooks/document";
import { DownloadEIFSummaryPDFLink } from "../../pages/EIFSummaryPage/DownloadEIFSummaryPDFLink";
import { eifNIGOMessage } from "../Task/nigoMessage";
import * as styles from "./eifCheckpointNavigation.module.less";
import type { Task } from "shared/data/Tasks";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { ChangeDetailInfo, ChangeWithDetails } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ReadyToSignOnboardingFormsMetadata } from "shared/types/OnboardingForm";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type EIFCheckpointNavigationProps = {
  task: Task;
  client: Client;
  contacts: Contact[];
  bills: Bill[];
  deletedBills: Bill[];
  billingSummaryStatementTemplates: Document[];
  clientPlans: Plan[];
  employeeClasses: EmployeeClass[];
  changes: ChangeWithDetails[];
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  subsidiaries: Subsidiary[];
  readyToSignOnboardingFormsMetadata: ReadyToSignOnboardingFormsMetadata[];
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
const steps = Object.keys(eifStepNavigation) as EIFStepId[];

export const EIFCheckpointNavigation = ({
  task,
  client,
  contacts,
  bills,
  deletedBills,
  billingSummaryStatementTemplates,
  clientPlans,
  employeeClasses,
  changes,
  authUser,
  featureToggles,
  subsidiaries,
  readyToSignOnboardingFormsMetadata,
}: EIFCheckpointNavigationProps) => {
  const signerFullName = getAuthorizedSignerFullName(client);
  const signerName = signerFullName
    ? `${signerFullName} (benefits administrator)`
    : "the signing BA";

  const variant = task.status ? taskStatusToVariant[task.status] : "info";
  const status = task.status ? taskStatusToLabel[task.status] : "Not Started";
  const message =
    task.status === "In Review" ? (
      <>
        Your company information has been submitted by <strong>{signerName}</strong>, on behalf of{" "}
        {client.name}. Sun Life will review your information and let you know if anything else is
        required from you. Revisions can only be made by speaking with your implementation
        consultant.
      </>
    ) : task.status === "NIGO" ? (
      eifNIGOMessage
    ) : undefined;

  const employeeClassDocumentCount = useGetDocumentCount(
    client.allowClientSelfServicePlanConfig === "NO" ? client.id : "",
    ["employee-classes"],
  ).data?.count;

  const changesSnapshot = useChangeSnapshot(changes);

  const statusByStep = steps.reduce<Record<EIFStepId, EIFStepCompleteStatus>>((acc, step) => {
    const status = getEIFStepCompleteStatus(
      step,
      client,
      clientPlans,
      bills,
      contacts,
      billingSummaryStatementTemplates,
      employeeClasses,
      employeeClassDocumentCount ?? 0,
      changesSnapshot,
      featureToggles,
      subsidiaries,
    );
    return { ...acc, [step]: status };
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  }, {} as Record<EIFStepId, EIFStepCompleteStatus>);

  const { mutateAsync: updateClient, isPending, error } = useUpdateClient();

  const [isVisibleRequestReview, toggleIsVisibleRequestReview] = useToggler();
  const [isVisibleAcceptChanges, toggleIsVisibleAcceptChanges] = useToggler();
  const [isVisibleDeclineChanges, toggleIsVisibleDeclineChanges] = useToggler();

  const requestReview = async () => {
    const { isSuccess } = await updateClient({
      params: { clientId: client.id },
      data: { deifChangesReadyForReviewAt: new Date() },
    });
    if (isSuccess) {
      toggleIsVisibleRequestReview();
      void slobMessage.success("Change notification email sent!");
    }
  };
  const acceptChanges = async () => {
    const { isSuccess } = await updateClient({
      params: { clientId: client.id },
      data: { deifChangesAcceptedAt: new Date() },
    });
    if (isSuccess) {
      toggleIsVisibleAcceptChanges();
    }
  };
  const declineChanges = async (deifChangesDeclinedReason: string) => {
    const { isSuccess } = await updateClient({
      params: { clientId: client.id },
      data: { deifChangesDeclinedAt: new Date(), deifChangesDeclinedReason },
    });
    if (isSuccess) {
      toggleIsVisibleDeclineChanges();
    }
  };

  const deifHasUnreviewedChanges = getAreTherePendingChanges(changesSnapshot);

  const isAllowedToReviewEIF = getIsAllowedToReviewEIF({ client, authUser, changesSnapshot });
  const eifEditState = getEIFEditState({ client, changesSnapshot });

  const isAllowedToAcceptOrDeclineEIF = getIsAllowedToAcceptOrDeclineEIF({
    client,
    authUser,
    changesSnapshot,
  });

  const missingBenAdminOnboardingFormSignatures = readyToSignOnboardingFormsMetadata.filter(
    (data) => data.user?.role === "BEN_ADMIN",
  );
  const onboardingFormsTask = (client.tasks || []).find((t) => t.id === "onboarding-forms");

  const isStepReviewAndSubmitComplete = statusByStep["review-&-submit"] === "Completed";

  const showOnboardingFormsMissingSigningBanner = Boolean(
    featureToggles.ONBOARD_MISSING_SIGNING_BANNERS &&
      isStepReviewAndSubmitComplete &&
      Boolean(onboardingFormsTask) &&
      missingBenAdminOnboardingFormSignatures?.length,
  );

  const { acceptedBy, acceptedAt, declinedBy, declinedAt } =
    getICEditsAcceptedAndDeclinedBy(client);

  const isInternalUser = getIsInternalUser(authUser);

  return (
    <>
      <StackY dist={16} wrap={false}>
        <HubCard data-testid="task-card">
          <HubCardHeader
            title="Complete your company information"
            description="Fill out your details in any order and save as you go. You can continue from where you left off if you need to come back later."
            badge={
              <Badge
                srOnlyLabel="Task Status"
                variant={variant}
                status={status}
                message={message}
                showStaticSpinner={task.status === "In Progress"}
              />
            }
            hasDivider={true}
          />

          <StackY dist={8}>
            {steps.map((step) => {
              const atLeastOneSubStepIsApplicable = getAtLeastOneSubStepIsApplicable(
                step,
                client,
                clientPlans,
                featureToggles,
              );
              if (!atLeastOneSubStepIsApplicable) return null;

              const status = statusByStep[step];
              const completed = status === "Completed";
              const isReview = step === "review-&-submit";

              const srOnlyStatus = (
                <>
                  {status === "Not Started" && (
                    <span className="sr-only">(section not started) </span>
                  )}
                  {status === "In Progress" && (
                    <span className="sr-only">(section in progress) </span>
                  )}
                  {status === "Completed" && <span className="sr-only">(section completed) </span>}
                </>
              );

              if (isReview) {
                return (
                  <div key={step}>
                    {status === "Not Started" ? (
                      <>
                        <LockIcon2 className={styles.lockIcon} />
                        <Body2 colorSecondary>
                          {srOnlyStatus}
                          {eifStepIdMap[step]}
                        </Body2>
                      </>
                    ) : (
                      <InternalLinkButton
                        type="link-inline-bold"
                        size="middle"
                        to={RouteData.eifStepDetail.getPath(client.id, step)}
                      >
                        <FontAwesomeIcon
                          icon={completed ? faCheckCircle : faCircle}
                          className={clsx(styles.icon, completed && styles.completed)}
                        />
                        {srOnlyStatus}
                        {eifStepIdMap[step]}
                      </InternalLinkButton>
                    )}

                    <div className="ml-32 mt-4">
                      {status === "Not Started" && (
                        <Body5>
                          This final step must be completed by <strong>{signerName}</strong>. You'll
                          be able to review and submit after all other steps are completed.
                        </Body5>
                      )}

                      {status === "In Progress" && (
                        <Body5>
                          This final section must be completed by <strong>{signerName}</strong>.
                        </Body5>
                      )}

                      {status === "Completed" && (
                        <StackY dist={12}>
                          <Body5 as="div">
                            Edits can no longer be made once your form is signed. If you need to
                            make any changes, contact your implementation consultant.
                          </Body5>

                          <Body5 as="div">
                            Need a copy of your company information?{" "}
                            <DownloadEIFSummaryPDFLink clientId={client.id} />
                            {(isInternalUser ||
                              eifEditState === "no-edits" ||
                              eifEditState === "changes-accepted") && (
                              <>
                                {" or "}
                                <SlobLink
                                  to={RouteData.eifSummary.getPath(client.id)}
                                  variant="boldSmall"
                                >
                                  View Online
                                </SlobLink>
                              </>
                            )}
                          </Body5>
                        </StackY>
                      )}
                    </div>
                  </div>
                );
              }

              const changesSetForStep = getChangeDetailInfoListForStep({
                eifStepId: step,
                client,
                clientPlans,
                contacts,
                bills,
                deletedBills,
                changeSnapshot: changesSnapshot,
                featureToggles,
                subsidiaries,
              });

              const flatChangesForStep = changesSetForStep
                .flatMap(([_substepId, _substepName, changes]) => changes)
                .sort(
                  (changeA, changeB) =>
                    // sort by most recent change first
                    changeB.currentValue.date.getTime() - changeA.currentValue.date.getTime(),
                );

              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
              const latestChangeForStep = flatChangesForStep[0] as ChangeDetailInfo | undefined;

              const editedBy =
                latestChangeForStep?.status === "pending"
                  ? latestChangeForStep.currentValue.user
                    ? formatFullName(latestChangeForStep.currentValue.user)
                    : "an unknown user"
                  : null;
              const editedAt =
                latestChangeForStep?.status === "pending"
                  ? formatDateFullMonthWithYear(latestChangeForStep.currentValue.date)
                  : null;

              const externalUserReviewText = isAllowedToReviewEIF
                ? "Please review the changes in this section"
                : "You'll receive an email notification when it's ready for you to review";
              const editedMessage = isInternalUser
                ? `Edited by ${editedBy} on ${editedAt}. Please review the changes in this section.`
                : `Edited by your implementation consultant on ${editedAt}. ${externalUserReviewText}.`;

              return (
                <div key={step} data-testid={eifStepIdMap[step]}>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={RouteData.eifStepDetail.getPath(client.id, step)}
                    disabled={!isAllowedToReviewEIF}
                  >
                    {status === "In Progress" ? (
                      <StaticSpinner className={styles.inProgress} />
                    ) : (
                      <FontAwesomeIcon
                        icon={completed ? faCheckCircle : faCircle}
                        className={clsx(styles.icon, completed && styles.completed)}
                      />
                    )}

                    {srOnlyStatus}

                    {eifStepIdMap[step]}

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="ml-8"
                      style={{ fontSize: "0.875rem" }}
                    />
                  </InternalLinkButton>
                  {(function () {
                    switch (latestChangeForStep?.status) {
                      case "pending":
                        return (
                          <div className="ml-32 my-12 flex items-center">
                            <AlertBanner
                              variant="warning"
                              w-full
                              message={<Body3>{editedMessage}</Body3>}
                            />
                          </div>
                        );
                      case "declined":
                        return (
                          <div className="ml-32 my-12 flex items-center">
                            <AlertBanner
                              variant="error"
                              w-full
                              message={
                                <Body3>{`Edits declined by ${declinedBy} on ${declinedAt}`}</Body3>
                              }
                            />
                          </div>
                        );
                      case "accepted":
                        return (
                          <div className="ml-32 my-12 flex items-center">
                            <AlertBanner
                              variant="success"
                              w-full
                              message={
                                <Body3>{`Edits accepted by ${acceptedBy} on ${acceptedAt}`}</Body3>
                              }
                            />
                          </div>
                        );
                      case undefined:
                        return null;
                    }
                  })()}
                </div>
              );
            })}
          </StackY>

          {deifHasUnreviewedChanges && (
            <div className="mt-16">
              <StackY dist={16}>
                {isAllowedToAcceptOrDeclineEIF && (
                  <StackX dist={16}>
                    <Button size="middle" type="primary" onClick={toggleIsVisibleAcceptChanges}>
                      Accept changes
                    </Button>
                    <Button size="middle" type="text" onClick={toggleIsVisibleDeclineChanges}>
                      Decline changes
                    </Button>
                  </StackX>
                )}

                <Body5>
                  Changes can only be accepted by <strong>{signerName}</strong>.
                </Body5>
              </StackY>
            </div>
          )}
        </HubCard>

        {deifHasUnreviewedChanges &&
          isInternalUser &&
          client.deifChangesReadyForReviewAt == null && (
            <EIFChangesSummary
              client={client}
              clientPlans={clientPlans}
              contacts={contacts}
              bills={bills}
              deletedBills={deletedBills}
              toggleIsVisibleRequestReview={toggleIsVisibleRequestReview}
              changeSnapshot={changesSnapshot}
              authUser={authUser}
              featureToggles={featureToggles}
              employeeClasses={employeeClasses}
              subsidiaries={subsidiaries}
            />
          )}

        <PushToQPSButton
          client={client}
          changesSnapshot={changesSnapshot}
          authUser={authUser}
          plans={clientPlans}
          employeeClasses={employeeClasses}
          featureToggles={featureToggles}
        />

        {featureToggles.ONBOARD_CLASS_IMPORT && isInternalUser && (
          <SyncWithQPSInternalControl client={client} employeeClasses={employeeClasses} />
        )}

        {showOnboardingFormsMissingSigningBanner && (
          <AlertBanner
            variant="warning"
            message={
              <Body3>
                Thank you for submitting your company information. Please review the Complete and
                Sign Onboarding Forms task as it may also require digital signatures.
                <br />
                <InternalLinkButton
                  type="link-inline-bold"
                  size="middle"
                  to={RouteData.clientTaskDetail.getPath(client.id, "onboarding-forms")}
                >
                  Go to Complete and Sign Onboarding Forms
                </InternalLinkButton>
              </Body3>
            }
          />
        )}
      </StackY>

      <ConfirmDialog
        isVisible={isVisibleRequestReview}
        isLoading={isPending}
        title="Are you sure you are done making changes?"
        confirmActionText="Yes, send change notification"
        onConfirm={requestReview}
        onCancel={toggleIsVisibleRequestReview}
      >
        <p>
          You should only send a change notification when you are reasonably sure there will be no
          more changes to the client’s information. Try to avoid asking the client to review and
          accept changes more than once.
        </p>

        <p>Are you reasonably sure you are done making changes to the client’s information?</p>

        {error && (
          <ErrorMessage>
            {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
          </ErrorMessage>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        isVisible={isVisibleAcceptChanges}
        isLoading={isPending}
        title="Are you sure you are ready to accept the changes?"
        confirmActionText="Accept all changes"
        onConfirm={acceptChanges}
        onCancel={toggleIsVisibleAcceptChanges}
      >
        <p>If you have reviewed the changes and have no questions, you are good to go.</p>

        <p>Your implementation consultant will be notified that you’ve accepted the changes.</p>

        {error && (
          <ErrorMessage>
            {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
          </ErrorMessage>
        )}
      </ConfirmDialog>

      <ConfirmDialogDeclineAllChanges
        isVisible={isVisibleDeclineChanges}
        isLoading={isPending}
        onCancel={toggleIsVisibleDeclineChanges}
        onDecline={declineChanges}
        error={error}
        signerMode="inside"
      />
    </>
  );
};
