import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { Button } from "../../components/Button/Button";
import { ActivationCard } from "../../components/HubCard/ActivationCard";
import { StackY } from "../../components/Spacing/Spacing";
import { H2 } from "../../components/Typography/Typography";
import { PasswordForgotForm } from "../../domain/User/Form/PasswordForgotForm";
import { useForgotPassword } from "../../hooks/user";
import { useTrackElementClicked } from "../../utils/analytics";
import { useHideZendeskWidget } from "../../utils/zendesk";

export const ForgotPasswordPage = () => {
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: forgotPassword } = useForgotPassword();
  const trackElementClicked = useTrackElementClicked();

  useHideZendeskWidget();

  const onSuccess = () => {
    setEmailSent(true);
  };

  const goLoginClick = (module: string, buttonLabel: string) => {
    trackElementClicked({ module, buttonLabel });
    navigate(RouteData.login.getPath());
  };

  if (emailSent) {
    return (
      <ActivationCard>
        <H2 as="h1">Check your email</H2>
        <p>
          If this email address was used to create an account, instructions to reset your password
          will be sent to you.
        </p>
        <p className="mb-24">
          If the email doesn’t show up soon, check your spam folder. It will be sent from
          support@onboard.sunlife.com.
        </p>
        <Button
          type="primary"
          size="large"
          block
          onClick={() => goLoginClick("Password Reset Sent", "Sign In")}
        >
          Sign In
        </Button>
      </ActivationCard>
    );
  }

  return (
    <ActivationCard>
      <H2 as="h1">Forgot your password?</H2>
      <p>We’ll send you password reset instructions to you get back on track.</p>
      <StackY dist={16}>
        <PasswordForgotForm
          submitButtonLabel="Reset Password"
          mutate={forgotPassword}
          onSuccess={onSuccess}
          track={() =>
            trackElementClicked({ module: "Forgot Password", buttonLabel: "Reset Password" })
          }
        />
        <div className="text-center">
          <Button
            type="text"
            size="small"
            onClick={() => goLoginClick("Forgot Password", "Return to Sign In")}
          >
            Return to Sign In
          </Button>
        </div>
      </StackY>
    </ActivationCard>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default ForgotPasswordPage;
