import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import { formatDateFullMonthWithYear } from "../../../../shared/utils/format";
import { Circle } from "../Circle/Circle";
import { ReactComponent as AwardIcon } from "../Icons/AwardIcon.svg";
import { ProgressTrackerPolicies } from "../ProgressTracker/ProgressTrackerPolicies";
import { StackY } from "../Spacing/Spacing";
import { H5, Body2 } from "../Typography/Typography";

import * as styles from "./hubHeader.module.less";

import type { Client } from "shared/types/Client";

type HubHeaderProps = {
  isCoverageActive: boolean | null;
  isOnboardingComplete: boolean;
  client: Client;
  policyEffectiveDates?: Array<{ effective: Date | null; active: boolean | null }> | null;
};

export const HubHeader = ({
  isCoverageActive,
  isOnboardingComplete,
  client,
  policyEffectiveDates,
}: HubHeaderProps) => {
  return (
    <header
      className={clsx(
        styles.hubHeader,
        isOnboardingComplete && styles.hubHeader__isOnboardingComplete,
        styles.hubHeader__isMultiPolicy,
      )}
    >
      {isOnboardingComplete && (
        <>
          <div className={styles.circlePosition}>
            <Circle variant="yellowLight" />
          </div>
          <div className={styles.circleThinPosition}>
            <Circle variant="yellowLight" thin />
          </div>
        </>
      )}

      <div className={clsx(styles.innerContainer, "stack-y-32", "px-32")}>
        <div>
          {!isOnboardingComplete && (
            <Body2 as="h2">Onboarding progress for your benefits effective:</Body2>
          )}
          <ProgressTrackerPolicies client={client} />
        </div>

        {isOnboardingComplete && (
          <Row justify="center" className="mt-40">
            <Col className={styles.textCenter}>
              <StackY dist={24}>
                <AwardIcon />

                <h1>
                  Congratulations, your benefits <br /> onboarding is now complete!
                </h1>

                <H5 as="div" colorSecondary>
                  {client.name} <br />
                  {isCoverageActive ? (
                    <>
                      Your coverage is <span className={styles.activeCoverage}>active</span>
                    </>
                  ) : policyEffectiveDates?.length === 1 ? (
                    <>
                      Your coverage{" "}
                      <span className={styles.inactiveCoverage}>
                        will be active on effective date
                      </span>
                    </>
                  ) : (
                    policyEffectiveDates?.map((policy, i, arr) => {
                      const beforeText = i === 0 ? "Your coverage" : "";
                      const benefitsText = `for benefits effective ${
                        policy.effective ? formatDateFullMonthWithYear(policy.effective) : ""
                      }`;
                      const afterText = i < arr.length - 1 ? ", and" : "";

                      return policy.active ? (
                        <div key={`${i}${policy.effective?.toString()}`}>
                          {beforeText} is <span className={styles.activeCoverage}>active</span>{" "}
                          {benefitsText}
                          {afterText}
                        </div>
                      ) : (
                        <div key={`${i}${policy.effective?.toString()}`}>
                          {beforeText}{" "}
                          <span className={styles.inactiveCoverage}>will be active soon</span>{" "}
                          {benefitsText}
                          {afterText}
                        </div>
                      );
                    })
                  )}
                </H5>
              </StackY>
            </Col>
          </Row>
        )}
      </div>
    </header>
  );
};
