import { Select } from "antd";
import { tagRender } from "client/src/components/TagList/tagRender";
import clsx from "clsx";
import { useSlobId } from "../../hooks/useSlobId";
import { InputErrorMessage } from "../Form/InputErrorMessage";
import * as slobSelectStyles from "../Form/SlobSelect.module.less";
import * as formStyles from "../Form/form.module.less";
import * as styles from "./TagList.module.less";

import type { DefaultOptionType } from "antd/lib/select";

type Props = {
  name: string;
  placeholder: string;
  touched?: boolean;
  error?: string;
  errorId?: string;
  hasExternalErrorStyles?: boolean;
  disabled?: boolean;
  value: string[] | null | undefined;
  topText?: string;
  bottomText?: string;
  options?: DefaultOptionType[];
  onChange: (e: { target: { name: string; value: string[]; outerHTML: string } }) => void;
};

export function TagList(props: Props) {
  const {
    name,
    placeholder,
    touched,
    error,
    errorId,
    hasExternalErrorStyles,
    onChange,
    disabled,
    value,
    topText,
    bottomText,
    options,
    ...rest
  } = props;

  const id = useSlobId({ prefix: name });
  const showError = touched && Boolean(error);
  const externalErrorId = errorId;
  const internalErrorId = showError ? `${id}__errormessage` : undefined;
  const hasError = Boolean(externalErrorId || internalErrorId);
  const ariaErrorMessage = [externalErrorId, internalErrorId].join(" ").trim();

  return (
    <div data-component="<TagList />" data-name={name}>
      {topText && <p className={clsx("body2", formStyles.topText)}>{topText}</p>}

      <Select
        {...rest}
        aria-invalid={hasError}
        aria-errormessage={ariaErrorMessage}
        aria-label={placeholder}
        mode="tags"
        allowClear
        placeholder={placeholder}
        disabled={disabled}
        className={clsx(styles.tagList, hasExternalErrorStyles && styles.hasExternalErrorStyles)}
        style={{ width: "100%" }}
        dropdownStyle={!options || options.length === 0 ? { display: "none" } : {}}
        popupClassName={slobSelectStyles.selectPopup}
        value={value || undefined}
        onChange={(values: string[]) => {
          onChange({
            target: { name, value: values, outerHTML: `<TagList name="${name}" ... />` },
          });
        }}
        tagRender={tagRender}
        options={options}
      />

      <div aria-live="assertive" className="hide:empty">
        {showError && <InputErrorMessage id={internalErrorId} error={error} />}
      </div>

      {bottomText && <span className={clsx("body5", formStyles.bottomText)}>{bottomText}</span>}
    </div>
  );
}
