import { isPolicyTaskId, isClientTaskId } from "shared/utils/client";
import type { ClientTaskId, PolicyTaskId, TaskId } from "shared/data/Tasks";
import type { ClientId, PolicyId } from "shared/types/Client";
import type { DocumentId } from "shared/types/Document";
import type {
  EIFPlanConfigEligibilityWithPFMLAndStatutorySubStep,
  EIFStepId,
  EIFSubStepId,
} from "shared/types/EIF";
import type { EmailId } from "shared/types/Email";
import type { EmployeeClassId } from "shared/types/EmployeeClass";
import type { ExplorerPageId, ExplorerPageVanitySlug } from "shared/types/ExplorerPage";
import type { OnboardingFormId } from "shared/types/OnboardingForm";
import type { RecipientId } from "shared/types/OnboardingFormRecipient";
import type { OutsideSignerActionId } from "shared/types/OutsideSigner";
import type { UserId } from "shared/types/User";

export const VIEW_TYPE = {
  internal: "internal",
  hub: "hub",
  broker: "broker",
  benAdmin: "benAdmin",
  basic: "basic",
  clear: "clear",
  auth: "auth",
  explorer: "explorer",
  outside_signer: "outside_signer",
} as const;

export const AUTH_TYPE = {
  required: "required", // user must be logged in
  not_allowed: "not_allowed", // user must not be logged in
  optional: "optional", // public route
  outside_signer: "outside_signer", // for outside signers only
} as const;

const isParamClientTaskId = (param: TaskId | ValidParam | undefined): param is TaskId => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  return isClientTaskId(param as ClientTaskId);
};
const isParamPolicyTaskId = (param: TaskId | ValidParam | undefined): param is TaskId => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  return isPolicyTaskId(param as PolicyTaskId);
};

export const RouteData = {
  index: {
    friendlyName: "Index",
    getPath: () => `/`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.basic,
    authType: AUTH_TYPE.required,
  },
  notFound: {
    friendlyName: "404",
    getPath: () => `/404`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.basic,
    authType: AUTH_TYPE.optional,
  },
  login: {
    friendlyName: "Login",
    getPath: () => `/login`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.auth,
    authType: AUTH_TYPE.not_allowed,
  },
  maintenance: {
    friendlyName: "Maintenance",
    getPath: () => `/maintenance`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.basic,
    authType: AUTH_TYPE.required,
  },
  forgot: {
    friendlyName: "Forgot Password",
    getPath: () => `/forgot`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.auth,
    authType: AUTH_TYPE.not_allowed,
  },
  activate: {
    friendlyName: "Account Activation",
    getPath: () => `/activate`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.auth,
    authType: AUTH_TYPE.not_allowed,
  },
  reactivate: {
    friendlyName: "Resend Activation",
    getPath: () => `/reactivate`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.auth,
    authType: AUTH_TYPE.not_allowed,
  },
  reset: {
    friendlyName: "Password Reset",
    getPath: () => `/reset`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.auth,
    authType: AUTH_TYPE.not_allowed,
  },
  termsOfUse: {
    friendlyName: "Terms of Use",
    getPath: () => `/terms-of-use`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.basic,
    authType: AUTH_TYPE.required,
  },
  homeHub: {
    friendlyName: "Home",
    getPath: (clientId: ClientId | ValidParam) => `/client/${clientId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["dashboard"];
    },
  },
  welcome: {
    friendlyName: "Welcome",
    getPath: (clientId: ClientId | ValidParam) => `${RouteData.homeHub.getPath(clientId)}/welcome`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["welcome"];
    },
  },
  eifStepDetail: {
    friendlyName: "EIF Step Detail",
    getPath: (clientId: ClientId | ValidParam, eifStepId: EIFStepId | ValidParam) => {
      const home = RouteData.homeHub.getPath(clientId);
      return `${home}/task/eif-submit-company-information/step/${eifStepId}`;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.eifStepId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: ({ eifStepId }: ValidParams) => {
      const record: Record<EIFStepId, string[]> = {
        "company-information": ["company details"],
        "plan-administrators-&-billing": ["plan admin"],
        "plan-configuration-&-eligibility": ["plan eligibility"],
        "other-contract-details": ["other contract details"],
        "review-&-submit": ["review submit"],
      };
      const labels = eifStepId ? record[eifStepId] || [] : [];
      return labels;
    },
  },
  eifSubStepDetail: {
    friendlyName: "EIF Submit Company Info",
    getPath: (
      clientId: ClientId | ValidParam,
      eifStepId: EIFStepId | ValidParam,
      eifSubStepId: EIFSubStepId | ValidParam,
    ) =>
      `${RouteData.homeHub.getPath(
        clientId,
      )}/task/eif-submit-company-information/step/${eifStepId}/${eifSubStepId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.eifStepId, VALID_PARAMS.eifSubStepId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: ({ eifStepId }: ValidParams) => {
      const record: Record<EIFStepId, string[]> = {
        "company-information": ["company details"],
        "plan-administrators-&-billing": ["plan admin"],
        "plan-configuration-&-eligibility": ["plan eligibility"],
        "other-contract-details": ["other contract details"],
        "review-&-submit": ["review submit"],
      };
      const labels = eifStepId ? record[eifStepId] || [] : [];
      return labels;
    },
  },
  eifSubStepPolicyDetail: {
    friendlyName: "EIF Sub Step Policy Detail",
    getPath: (
      clientId: ClientId | ValidParam,
      eifStepId: EIFStepId | ValidParam,
      eifSubStepId: EIFSubStepId | ValidParam,
      policyId: PolicyId | ValidParam,
    ) => {
      const home = RouteData.homeHub.getPath(clientId);
      return `${home}/task/eif-submit-company-information/step/${eifStepId}/${eifSubStepId}/policy/${policyId}`;
    },
    getPathTemplate: function () {
      return this.getPath(
        VALID_PARAMS.clientId,
        VALID_PARAMS.eifStepId,
        VALID_PARAMS.eifSubStepId,
        VALID_PARAMS.policyId,
      );
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: ({ eifStepId }: ValidParams) => {
      const record: Record<EIFStepId, string[]> = {
        "company-information": ["company details"],
        "plan-administrators-&-billing": ["plan admin"],
        "plan-configuration-&-eligibility": ["plan eligibility"],
        "other-contract-details": ["other contract details"],
        "review-&-submit": ["review submit"],
      };
      const labels = eifStepId ? record[eifStepId] || [] : [];
      return labels;
    },
  },
  eifClassBuilderCreator: {
    friendlyName: "EIF Class Builder Creator",
    getPath: (
      clientId: ClientId | ValidParam,
      eifStepId: "plan-configuration-&-eligibility" | ValidParam,
      eifSubStepId: EIFPlanConfigEligibilityWithPFMLAndStatutorySubStep | ValidParam,
    ) => {
      const homeHubPath = RouteData.homeHub.getPath(clientId);
      type Path =
        `${string}/task/eif-submit-company-information/step/${typeof eifStepId}/${typeof eifSubStepId}/create`;
      const path: Path = `${homeHubPath}/task/eif-submit-company-information/step/${eifStepId}/${eifSubStepId}/create`;
      return path;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.eifStepId, VALID_PARAMS.eifSubStepId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["plan eligibility"];
    },
  },
  eifClassBuilderEdit: {
    friendlyName: "EIF Class Builder Edit",
    getPath: (
      clientId: ClientId | ValidParam,
      eifStepId: "plan-configuration-&-eligibility" | ValidParam,
      eifSubStepId: EIFPlanConfigEligibilityWithPFMLAndStatutorySubStep | ValidParam,
      employeeClassId: EmployeeClassId | ValidParam,
    ) => {
      const homeHubPath = RouteData.homeHub.getPath(clientId);
      type Path =
        `${string}/task/eif-submit-company-information/step/${typeof eifStepId}/${typeof eifSubStepId}/${string}`;
      const path: Path = `${homeHubPath}/task/eif-submit-company-information/step/${eifStepId}/${eifSubStepId}/${employeeClassId}`;
      return path;
    },
    getPathTemplate: function () {
      return this.getPath(
        VALID_PARAMS.clientId,
        VALID_PARAMS.eifStepId,
        VALID_PARAMS.eifSubStepId,
        VALID_PARAMS.employeeClassId,
      );
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["plan eligibility"];
    },
  },
  eifSummary: {
    friendlyName: "EIF Summary",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeHub.getPath(clientId)}/task/eif-submit-company-information/summary`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["review submit"];
    },
  },
  eifPushToQPS: {
    friendlyName: "Push to QPS",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeHub.getPath(clientId)}/task/eif-submit-company-information/push-to-qps`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return [];
    },
  },
  outsideSigner: {
    friendlyName: "Outside Signer",
    getPath: (
      clientId: ClientId | ValidParam,
      outsideSignerActionId: OutsideSignerActionId | ValidParam,
    ) => `${RouteData.homeHub.getPath(clientId)}/outside-signer/${outsideSignerActionId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.outsideSignerActionId);
    },
    viewType: VIEW_TYPE.outside_signer,
    authType: AUTH_TYPE.outside_signer,
    getZendeskLabels: () => {
      return [];
    },
  },
  outsideSignerEifSummary: {
    friendlyName: "Outside Signer - EIF Summary",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeHub.getPath(clientId)}/outside-signer/eif-summary`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.outside_signer,
    authType: AUTH_TYPE.outside_signer,
    getZendeskLabels: () => {
      return ["review submit"];
    },
  },
  censusStepDetail: {
    friendlyName: "Census Upload and Validation",
    getPath: (clientId: ClientId | ValidParam, policyId: string) => {
      return `${RouteData.homeHub.getPath(
        clientId,
      )}/task/enrollment-elections/${policyId}/validations`;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.policyId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["enrollment-elections-validation"];
    },
  },
  censusValidateReportTab: {
    friendlyName: "Census Validation and Report tab",
    getPath: (clientId: ClientId | ValidParam, policyId: string, documentId: DocumentId) =>
      `${RouteData.homeHub.getPath(
        clientId,
      )}/task/enrollment-elections/${policyId}/validation-report/${documentId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.policyId, VALID_PARAMS.documentId);
    },
    viewType: VIEW_TYPE.clear,
    authType: AUTH_TYPE.required,
  },
  clientTaskDetail: {
    friendlyName: "Task Detail",
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    getPath: ((clientId: ClientId | ValidParam, taskId: ClientTaskId): string =>
      `${RouteData.homeHub.getPath(clientId)}/task/${taskId}`) as {
      (clientId: ClientId | ValidParam, taskId: ClientTaskId | ValidParam): string;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.taskId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: ({ taskId }: ValidParams) => {
      if (isParamClientTaskId(taskId)) {
        const r: Record<ClientTaskId, string[]> = {
          "kickoff-call": ["kickoff call"],
          "ongoing-service-call": ["ongoing service call"],
          "onboarding-forms": ["onboarding forms"],
          "prior-carrier-certificates": ["prior carrier certificates"],
          "sun-life-connect": ["sun life connect"],
          "enrollment-resources": ["enrollment resources"],
          "company-profile": ["company profile"],
          "eif-submit-company-information": ["digital smart form"],
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
        const labels = r[taskId as ClientTaskId];
        return labels || [];
      }
      return [];
    },
  },
  policyTaskDetail: {
    friendlyName: "PolicyTask Detail",
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    getPath: ((clientId: ClientId | ValidParam, taskId: PolicyTaskId, policyId: PolicyId): string =>
      `${RouteData.homeHub.getPath(clientId)}/task/${taskId}/${policyId}`) as {
      (
        clientId: ClientId | ValidParam,
        taskId: PolicyTaskId | ValidParam,
        policyId: PolicyId,
      ): string;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.taskId, VALID_PARAMS.policyId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: ({ policyTaskId }: ValidParams) => {
      if (isParamPolicyTaskId(policyTaskId)) {
        const r: Record<PolicyTaskId, string[]> = {
          "prior-carrier-bills": ["prior carrier bills"],
          "enrollment-elections": ["enrollment elections"],
          pfml: ["pfml"],
          "pfml-spd": ["pfml-spd"],
          "sunadvisor-spd": ["sunadvisor-spd"],
          "dental-rollover": [],
          "deductible-maximum-report": [],
        };
        const labels = r[policyTaskId];
        return labels || [];
      }
      return [];
    },
  },
  resourcesListing: {
    friendlyName: "Resources Listing",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeHub.getPath(clientId)}/resources-listing`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["resources listing"];
    },
  },
  homeInternal: {
    friendlyName: "Clients",
    getPath: () => `/clients/admin`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  clientDetail: {
    friendlyName: "Client Details",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeInternal.getPath()}/${clientId}/manage`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  usersInternal: {
    friendlyName: "Internal Users",
    getPath: () => `/users/internal`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  brokers: {
    friendlyName: "Brokers",
    getPath: () => `/brokers/admin/`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  brokerDetail: {
    friendlyName: "Broker Details",
    getPath: (userId: UserId | ValidParam) => `${RouteData.brokers.getPath()}${userId}/manage`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.userId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  benAdmins: {
    friendlyName: "Users",
    getPath: () => `/ben-admins/admin/`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  benAdminDetail: {
    friendlyName: "User Details",
    getPath: (userId: UserId | ValidParam) => `${RouteData.benAdmins.getPath()}${userId}/manage`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.userId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  onboardingFormsSigned: {
    friendlyName: "Benefits Documents - Signed",
    getPath: (
      clientId: ClientId | ValidParam,
      onboardingFormId: OnboardingFormId | ValidParam,
      recipientId: RecipientId | ValidParam,
    ) =>
      `${RouteData.homeHub.getPath(
        clientId,
      )}/onboarding-forms/${onboardingFormId}/action/${recipientId}`,
    getPathTemplate: function () {
      return this.getPath(
        VALID_PARAMS.clientId,
        VALID_PARAMS.onboardingFormId,
        VALID_PARAMS.recipientId,
      );
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
  },
  onboardingFormsViewer: {
    friendlyName: "Benefits Documents - Signed",
    getPath: (
      clientId: ClientId | ValidParam,
      onboardingFormId: OnboardingFormId | ValidParam,
      recipientId: RecipientId | ValidParam,
    ) =>
      `${RouteData.homeHub.getPath(
        clientId,
      )}/onboarding-forms/${onboardingFormId}/view/${recipientId}`,
    getPathTemplate: function () {
      return this.getPath(
        VALID_PARAMS.clientId,
        VALID_PARAMS.onboardingFormId,
        VALID_PARAMS.recipientId,
      );
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
  },
  allEmails: {
    friendlyName: "Emails",
    getPath: () => {
      return `/emails`;
    },
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  emails: {
    friendlyName: "Emails",
    getPath: (clientId: ClientId | ValidParam) => {
      return `${RouteData.homeHub.getPath(clientId)}/emails`;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
  },
  email: {
    friendlyName: "Email",
    getPath: (emailId: EmailId | ValidParam) => {
      return `/emails/${emailId}`;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.emailId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  emailClient: {
    friendlyName: "Email",
    getPath: (clientId: ClientId | ValidParam, emailId: EmailId | ValidParam) => {
      return `${RouteData.homeHub.getPath(clientId)}/emails/${emailId}`;
    },
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.emailId);
    },
    viewType: VIEW_TYPE.hub,
    authType: AUTH_TYPE.required,
  },
  homeBroker: {
    friendlyName: "Home",
    getPath: () => `/clients`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.broker,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["clients"];
    },
  },
  homeBenAdmin: {
    friendlyName: "Home",
    getPath: () => `/clients/ben-admin`,
    getPathTemplate: function () {
      return this.getPath();
    },
    viewType: VIEW_TYPE.benAdmin,
    authType: AUTH_TYPE.required,
    getZendeskLabels: () => {
      return ["clients"];
    },
  },
  createSite: {
    friendlyName: "Create Benefits Explorer page",
    getPath: (clientId: ClientId | ValidParam) =>
      `${RouteData.homeInternal.getPath()}/${clientId}/manage/benefits-explorer/`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  editBenefitsExplorerSite: {
    friendlyName: "Edit Benefits Explorer page",
    getPath: (clientId: ClientId | ValidParam, explorerPageId: ExplorerPageId | ValidParam) =>
      `${RouteData.homeInternal.getPath()}/${clientId}/manage/benefits-explorer/${explorerPageId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.explorerPageId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  renewBenefitsExplorerSite: {
    friendlyName: "Renew Benefits Explorer page",
    getPath: (clientId: ClientId | ValidParam, explorerPageId: ExplorerPageId | ValidParam) =>
      `${RouteData.homeInternal.getPath()}/${clientId}/manage/benefits-explorer/${explorerPageId}/renew`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.clientId, VALID_PARAMS.explorerPageId);
    },
    viewType: VIEW_TYPE.internal,
    authType: AUTH_TYPE.required,
  },
  benefitsExplorer: {
    friendlyName: "Benefits Explorer",
    getPath: (explorerPageId: ExplorerPageId | ExplorerPageVanitySlug | ValidParam) =>
      `/benefits-explorer/${explorerPageId}`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.explorerPageId);
    },
    viewType: VIEW_TYPE.explorer,
    authType: AUTH_TYPE.optional,
  },
  personalBenefitsPlan: {
    friendlyName: "Personal Benefits Plan",
    getPath: (explorerPageId: ExplorerPageId | ExplorerPageVanitySlug | ValidParam) =>
      `/benefits-explorer/${explorerPageId}/personal-benefits-plan`,
    getPathTemplate: function () {
      return this.getPath(VALID_PARAMS.explorerPageId);
    },
    viewType: VIEW_TYPE.explorer,
    authType: AUTH_TYPE.optional,
  },
};

export const VALID_PARAMS = {
  userId: `:userId`,
  taskId: `:taskId`,
  clientId: `:clientId`,
  policyTaskId: `:policyTaskId`,
  onboardingFormId: `:onboardingFormId`,
  recipientId: `:recipientId`,
  explorerPageId: `:explorerPageId`,
  policyId: `:policyId`,
  eifStepId: ":eifStepId",
  eifSubStepId: ":eifSubStepId",
  employeeClassId: ":employeeClassId",
  emailId: ":emailId",
  documentId: ":documentId",
  outsideSignerActionId: ":outsideSignerActionId",
} as const;

export type ParamToType = {
  userId: UserId;
  taskId: TaskId;
  policyTaskId: PolicyTaskId;
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  recipientId: RecipientId;
  explorerPageId: ExplorerPageId;
  eifStepId: EIFStepId;
  eifSubStepId: EIFSubStepId;
  employeeClassId: EmployeeClassId;
  policyId: string;
  emailId: EmailId;
  documentId: DocumentId;
  outsideSignerActionId: OutsideSignerActionId;
};

export type ValidParams = {
  [K in keyof typeof VALID_PARAMS]?: ParamToType[K];
};

type ValueOf<T> = T[keyof T];
export type ValidParam = ValueOf<typeof VALID_PARAMS>;
export type ValidRoute = keyof typeof RouteData;

export const UNSCHEDULED_MAINTENANCE_MESSAGE = "Unscheduled maintenance";
export const SCHEDULED_MAINTENANCE_MESSAGE = "Scheduled maintenance";
