import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobLink } from "client/src/components/SlobLink/SlobLink";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsBenAdmin, getIsBroker, getIsInternalUser } from "shared/rbac/rbac";
import { getEIFSubStepMap, eifSubStepsForStep } from "shared/types/EIF";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { nullNotAllowedTestConfig, yesNoRequired } from "shared/validation/validation";
import * as Yup from "yup";

import { AlertBanner } from "../../../../components/Banner/AlertBanner";
import { Button } from "../../../../components/Button/Button";

import { ErrorMessage } from "../../../../components/Error/ErrorMessage";
import { RadioGroup } from "../../../../components/Form/RadioGroup";
import { HubCard } from "../../../../components/HubCard/HubCard";
import { ReactComponent as GearSolid } from "../../../../components/Icons/GearSolid.svg";
import { ReactComponent as BulbIcon } from "../../../../components/Icons/bulb.svg";
import { InternalControl } from "../../../../components/InternalControl/InternalControl";
import { Modal } from "../../../../components/Modal/Modal";
import { StackX, StackY } from "../../../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../../../components/Typography/Typography";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { useToggler } from "../../../../hooks/useToggler";
import { DocumentDownload } from "../../../Document/DocumentDownload/DocumentDownload";
import { DocumentUpload } from "../../../Document/DocumentUpload/DocumentUpload";
import * as styles from "./EIFClassBuilderModeSetup.module.less";
import { EIFClassBuilderNoActionNeeded } from "./EIFClassBuilderNoActionNeeded";
import type { CreateClassQuery } from "../../../../hooks/employeeClass";
import type { TrackElementClickedFunc } from "../../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserRole } from "shared/rbac/roles";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Document, DocumentCategory } from "shared/types/Document";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const filteringQuestionSchema = Yup.object({
  needsEmployeeClasses: yesNoRequired.nullable().test(nullNotAllowedTestConfig()),
});

type EIFClassBuilderModeSetupProps = {
  client: Client;
  userRole: UserRole;
  updateClient: UpdateClientFunc;
  clientPlans: Plan[];
  documents: Document[];
  createClassQuery: CreateClassQuery;
  trackElementClicked: TrackElementClickedFunc;
  toggleIsSubmittingIntro: () => void;
  employeeClasses: EmployeeClass[];
  employeeClassDocumentCount: number;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
};

const category: DocumentCategory = "employee-classes";

export const EIFClassBuilderModeSetup = ({
  client,
  userRole,
  updateClient,
  clientPlans,
  documents,
  createClassQuery: { mutateAsync: createClass },
  trackElementClicked,
  toggleIsSubmittingIntro,
  employeeClasses,
  employeeClassDocumentCount,
  featureToggles,
  changeSnapshot,
}: EIFClassBuilderModeSetupProps) => {
  const eifSubStepId = "class-builder";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId: "class-builder",
        employeeClasses,
        employeeClassDocumentCount,
        featureToggles,
        changeSnapshot,
        clientPlans,
      }),
    });
  };

  const navigate = useNavigate();

  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);

  const [docUploadVisible, toggleDocUploadVisible] = useToggler();

  const doesNotHavePlans = clientPlans.length === 0;

  const formik = useSlobFormik({
    validationSchema: filteringQuestionSchema,
    initialValues: {
      needsEmployeeClasses: client.needsEmployeeClasses || null,
    },
    onSubmit: async ({ needsEmployeeClasses }) => {
      if (needsEmployeeClasses) {
        toggleIsSubmittingIntro();

        try {
          if (client.needsEmployeeClasses !== needsEmployeeClasses) {
            await updateClient({
              params: { clientId },
              data: { needsEmployeeClasses },
            });
          }

          if (needsEmployeeClasses === "YES") {
            navigate(
              `${RouteData.eifClassBuilderCreator.getPath(
                clientId,
                "plan-configuration-&-eligibility",
                "class-builder",
              )}`,
            );
          } else {
            const {
              data: { id: employeeClassId },
            } = await createClass({
              params: { clientId },
              data: { clientId },
            });

            navigate(
              `${RouteData.eifClassBuilderEdit.getPath(
                clientId,
                "plan-configuration-&-eligibility",
                "class-builder",
                employeeClassId,
              )}?section=Details`,
            );
          }
        } finally {
          toggleIsSubmittingIntro();
        }
      }
    },
  });

  const clientId = client.id;
  const isSelfServicePlanConfigOff = client.allowClientSelfServicePlanConfig === "NO";
  if (
    (getIsBenAdmin({ role: userRole }) || getIsBroker({ role: userRole })) &&
    isSelfServicePlanConfigOff &&
    (documents.length === 0 || !client.allowClientToViewPlanConfig)
  ) {
    return <EIFClassBuilderNoActionNeeded clientId={clientId} />;
  }

  const isInternalUser = getIsInternalUser({ role: userRole });
  const internalControl = isSelfServicePlanConfigOff && isInternalUser && (
    <InternalControl
      position="attached"
      bodyText="Internal User:"
      buttonIcon={<GearSolid />}
      buttonLabel="Edit Settings"
      onClick={() => navigate(RouteData.clientDetail.getPath(clientId))}
      disabled={formik.isSubmitting}
      secondaryButtonLabel="Upload Files"
      secondaryButtonIcon={<FontAwesomeIcon icon={faFileUpload} />}
      secondaryOnClick={toggleDocUploadVisible}
      secondaryDisabled={formik.isSubmitting}
    />
  );

  const steps = eifSubStepsForStep(
    "plan-configuration-&-eligibility",
    client,
    clientPlans,
    featureToggles,
  );

  return (
    <>
      <DocumentUpload
        visible={docUploadVisible}
        closeModal={toggleDocUploadVisible}
        clientId={clientId}
        category={category}
        track={track}
      />

      <div>
        {documents.length === 0 || client.allowClientSelfServicePlanConfig === "YES" ? (
          <>
            <form onSubmit={formik.handleSubmit}>
              <StackY dist={32}>
                {steps.length > 1 && <h2 className="mb-40">{subStepName}</h2>}

                <HubCard header={isSelfServicePlanConfigOff && internalControl}>
                  <StackY dist={24}>
                    <RadioGroup
                      name="needsEmployeeClasses"
                      label={
                        <>
                          <Body2 as="p">
                            Do you need to create more than one group of all eligible employees due
                            to differences in eligibility, waiting period or contribution amounts?
                          </Body2>

                          <p className="mb-0">
                            Many companies do not need to create more than one group. You'll only
                            need them if you:
                          </p>

                          <ul>
                            <Body3 as="li">
                              Exclude employees from any benefit based on criteria like job title or
                              employment type
                            </Body3>
                            <Body3 as="li">
                              Have different waiting period requirements or contribution amounts for
                              certain groups of employees
                            </Body3>
                          </ul>

                          <Button type="text-only" onClick={() => setShowExampleModal(true)}>
                            Show examples
                          </Button>
                        </>
                      }
                      options={[
                        { label: "Yes", value: "YES" },
                        {
                          label: (
                            <>
                              <StackX dist={12}>
                                <Body3>No</Body3>
                              </StackX>
                            </>
                          ),
                          value: "NO",
                        },
                      ]}
                      disabled={formik.isSubmitting}
                      touched={formik.touched.needsEmployeeClasses}
                      error={formik.errors.needsEmployeeClasses}
                      direction="vertical"
                      value={formik.values.needsEmployeeClasses}
                      onChange={formik.handleChange}
                    />

                    {formik.values.needsEmployeeClasses === "NO" && (
                      <Row className={styles.lightBlueContainer} align="middle" justify="center">
                        <Col span={2} className={styles.bulbIconContainer}>
                          <BulbIcon />
                        </Col>
                        <Col span={22}>
                          <Body3>
                            This means that all full-time, U.S. employees working in the U.S. are
                            eligible to elect all benefit lines with the same waiting period and
                            contribution amounts. If your company does this differently, select
                            "Yes" above.
                          </Body3>
                        </Col>
                      </Row>
                    )}

                    {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
                  </StackY>
                </HubCard>

                <Row justify="end" className="mb-48">
                  <Col>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      loading={formik.isSubmitting}
                      disabled={doesNotHavePlans}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </StackY>
            </form>

            {doesNotHavePlans && (
              <>
                {isInternalUser ? (
                  <div className="mb-32">
                    <DashedBanner variant="warning">
                      <Body2 darkYellow>Internal user:</Body2>{" "}
                      <Body3 darkYellow>
                        Please complete{" "}
                        <SlobLink variant="normal" to={RouteData.clientDetail.getPath(client.id)}>
                          Configure Submit Company Information
                        </SlobLink>{" "}
                        to access this section.
                      </Body3>
                    </DashedBanner>
                  </div>
                ) : (
                  <div className="mb-32">
                    <AlertBanner
                      variant="warning"
                      message={
                        <Body3>
                          Your implementation consultant is still getting things ready. Check back
                          later to complete this section.
                        </Body3>
                      }
                    />
                  </div>
                )}
              </>
            )}

            <Modal
              maskClosable={false}
              title="Do you need more than one group of all eligible employees?"
              open={showExampleModal}
              footer={null}
              focusTriggerAfterClose={false}
              onCancel={() => setShowExampleModal(false)}
            >
              <p>
                There are three factors that you should consider. If any of these apply, you’ll need
                to create more than one eligible employee group.
              </p>

              <StackY dist={40}>
                <div>
                  <h3>Benefit eligibility</h3>
                  <p>
                    Are certain groups of employees eligible for specific benefits based on criteria
                    like job title, compensation type (salaried or hourly), or other factors?
                  </p>

                  <div className={styles.lightBlueContainer}>
                    <Body2 as="p">Example:</Body2>
                    <ul>
                      <Body3 as="li">
                        All Executives and Directors are eligible for all benefits.
                      </Body3>
                      <Body3 as="li">
                        All other employees are only eligible for some benefits.
                      </Body3>
                    </ul>
                  </div>
                </div>

                <div>
                  <h3>Waiting period</h3>
                  <p>
                    Do certain groups of employees have a different waiting period for a benefit?
                  </p>

                  <div className={styles.lightBlueContainer}>
                    <Body2 as="p">Example:</Body2>
                    <ul>
                      <Body3 as="li">
                        All Executives and Directors are eligible for Dental on their date of hire.
                      </Body3>
                      <Body3 as="li">
                        All other employees need to wait 30 days from their date of hire to be
                        eligible for Dental.
                      </Body3>
                    </ul>
                  </div>
                </div>

                <div>
                  <h3>Employer contribution</h3>
                  <p>
                    Do you, as the employer, contribute different amounts towards the premium of a
                    given benefit for certain groups of employees?
                  </p>

                  <div className={styles.lightBlueContainer}>
                    <Body2 as="p">Example:</Body2>
                    <ul>
                      <Body3 as="li">
                        You will contribute 100% towards the premium for Dental for all Executives,
                        Directors, and Managers.
                      </Body3>
                      <Body3 as="li">
                        For all other employees, you contribute 50% towards the premium for Dental.
                      </Body3>
                    </ul>
                  </div>
                </div>
              </StackY>

              <div className="mt-32">
                <Button type="tertiary" size="middle" onClick={() => setShowExampleModal(false)}>
                  Ok
                </Button>
              </div>
            </Modal>
          </>
        ) : (
          <>
            <h2>Review this Section</h2>

            <p>
              Your implementation consultant has completed the information in this section for you.
              Please review, and if anything looks incorrect, reach out to your implementation
              consultant and let them know.
            </p>

            <StackY dist={32}>
              <HubCard header={internalControl}>
                <DocumentDownload
                  clientId={clientId}
                  categories={[category]}
                  track={track}
                  readonly={getIsBenAdmin({ role: userRole }) || getIsBroker({ role: userRole })}
                />
              </HubCard>

              <Row justify="center">
                <Button
                  type="primary"
                  size="large"
                  to={RouteData.clientTaskDetail.getPath(
                    clientId,
                    "eif-submit-company-information",
                  )}
                >
                  Close
                </Button>
              </Row>

              <hr />

              <div>
                <Body5 as="p">
                  Employees not actively at work are not covered until they return to work, unless
                  required by applicable state law or approved in writing by the Sun Life
                  Unerwriting department.
                </Body5>

                <Body5>May require Sun Life approval.</Body5>
              </div>
            </StackY>
          </>
        )}
      </div>
    </>
  );
};
