import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { getIsPFMLASOBenefitType } from "shared/types/BenefitTypes";
import {
  pfmlASOBenefitTypeToStateLabel,
  type PFMLStates,
  type Plan,
  getPlansWhereCanSetClaimsCheckLocations,
  getClientCoveragesThatAreAlwaysSentToEmployeesHome,
} from "shared/types/Plan";
import { listFormat } from "shared/utils/format";

import { rejectNullableValues } from "shared/utils/utils";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

export const ClaimSentToHomeAddressMsg = ({
  client,
  clientPlans,
  variant,
}: {
  client: Client;
  clientPlans: Plan[];
  variant: "default" | "dimmed" | "review";
}) => {
  const clientCoveragesThatAreAlwaysSentToEmployeesHome =
    getClientCoveragesThatAreAlwaysSentToEmployeesHome(client);
  const claimsChecksNotManagedBySLF = getPFMLPlanStatesNotManagedBySLF(clientPlans);

  const claimsChecksNotManagedMessage = getClaimsChecksNotManagedMsg(claimsChecksNotManagedBySLF);

  const dimmed = variant === "dimmed";
  const note = variant !== "review";

  if (clientCoveragesThatAreAlwaysSentToEmployeesHome.length === 0) {
    if (claimsChecksNotManagedMessage) {
      return (
        <div>
          {note && <Body2 colorSecondary={dimmed}>Note: </Body2>}
          <Body3 colorSecondary={dimmed}>{claimsChecksNotManagedMessage}</Body3>
        </div>
      );
    }
  }

  if (clientCoveragesThatAreAlwaysSentToEmployeesHome.length === 1) {
    const [coverage] = clientCoveragesThatAreAlwaysSentToEmployeesHome;

    return (
      <div>
        {note && <Body2 colorSecondary={dimmed}>Note: </Body2>}
        <Body3 colorSecondary={dimmed}>
          {coverage} claim checks are always sent to the employee’s home address.
          {claimsChecksNotManagedMessage && ` ${claimsChecksNotManagedMessage}`}
        </Body3>
      </div>
    );
  }

  if (clientCoveragesThatAreAlwaysSentToEmployeesHome.length > 1) {
    return (
      <div>
        <p>
          {note && <Body2 colorSecondary={dimmed}>Note: </Body2>}
          <Body3 colorSecondary={dimmed}>
            The following benefits' claim checks are always sent to the employee’s home address:
          </Body3>
        </p>

        <Body3 colorSecondary={dimmed} as="p">
          {clientCoveragesThatAreAlwaysSentToEmployeesHome.join(", ")}
        </Body3>

        {claimsChecksNotManagedMessage && (
          <Body3 colorSecondary={dimmed} as="p">
            {claimsChecksNotManagedMessage}
          </Body3>
        )}
      </div>
    );
  }

  return null;
};

export const getPFMLPlanStatesNotManagedBySLF = (clientPlans: Plan[]) => {
  return clientPlans.reduce<PFMLStates[]>((acc, clientPlan) => {
    if (
      getIsPFMLASOBenefitType(clientPlan.benefitType) &&
      clientPlan.pfmlOption !== "ADVICE_PLUS"
    ) {
      acc.push(pfmlASOBenefitTypeToStateLabel[clientPlan.benefitType]);
    }
    return acc;
  }, []);
};

const getClaimsChecksNotManagedMsg = (claimsChecksNotManagedBySLF: PFMLStates[]) => {
  const multipleNotManagedPlans = claimsChecksNotManagedBySLF.length > 1;

  const adviceOnlyNotManagedMsg = multipleNotManagedPlans
    ? "as these plans are advice only."
    : "as this plan is advice only.";

  return claimsChecksNotManagedBySLF.length
    ? `PFML claim checks for ${listFormat(
        claimsChecksNotManagedBySLF,
      )} are not sent by Sun Life ${adviceOnlyNotManagedMsg}`
    : "";
};

export const getClaimsCheckRelevantPlansAndChanges = (
  plans: Plan[],
  changeSnapshot: DEIFChangeSnapshot,
) => {
  const relevantPlans = getPlansWhereCanSetClaimsCheckLocations(plans);

  const relevantPlanIds = relevantPlans.map((plan) => plan.id);

  const changesForRelevantPlans = relevantPlanIds
    .flatMap((id) => [
      changeSnapshot.Plan[id]?.sendClaimsCheckTo,
      changeSnapshot.Plan[id]?.claimsCheckPayee,
      changeSnapshot.Plan[id]?.planAdminPayeeContactId,
      changeSnapshot.Plan[id]?.someoneElsePayeeContactId,
    ])
    .filter(rejectNullableValues);

  return { relevantPlans, changesForRelevantPlans };
};
