import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { genericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { LoadingError } from "client/src/components/Error/LoadingError";
import { SlobRadio } from "client/src/components/Form/SlobRadio/SlobRadio";
import { Modal } from "client/src/components/Modal/Modal";
import { ResourcesTable } from "client/src/components/SlobTable/ResourcesTable";
import { useToggler } from "client/src/hooks/useToggler";
import pluralize from "pluralize";
import { Fragment, useState, useCallback } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import { hasValidationError } from "shared/utils/census";
import { assertIsDefined } from "shared/utils/utils";
import writeXlsxFile from "write-excel-file";
import { RouteData } from "../../../../../shared/config/routeData";
import { hasValidationWarning } from "../../../../../shared/utils/census";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Button/Button";
import { CollapsePanel } from "../../../components/Collapse/CollapsePanel";
import { DownloadIcon } from "../../../components/Icons/DownloadIcon";
import { ProgressModal } from "../../../components/Modal/ProgressModal";
import { OrderedList, OrderedListItem } from "../../../components/OrderedList/OrderedList";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { Body1, Body3 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useSubmitCensus, useCensusValidateSubmit } from "../../../hooks/document";
import { querySuccess } from "../../../hooks/query";
import { useFeatureToggles } from "../../../hooks/useFeatureToggles";
import { downloadFile, renameFile } from "../../../hooks/utils";
import {
  DocumentDownloadV2,
  useDocumentDownload,
} from "../../Document/DocumentDownload/DocumentDownload";
import { DocumentUpload } from "../../Document/DocumentUpload/DocumentUpload";
import { validationError } from "./ValidationFailure";
import * as styles from "./censusContents.module.less";
import type { ClientFeatureToggles } from "../../../../../shared/types/Toggles";
import type { SlobColumnsType } from "client/src/components/SlobTable/SlobTable";
import type { CensusOutletContext } from "client/src/domain/Client/Census/CensusDetailPage";
import type { Dispatch, DispatchWithoutAction, SetStateAction } from "react";
import type { CensusDomainValidations, CensusValidation } from "shared/types/Census";

import type { Client } from "shared/types/Client";
import type { CensusDocument, Document } from "shared/types/Document";
import type { SheetData, Row } from "write-excel-file";

type CensusValidationItemListProps = {
  header: string;
  items: CensusValidation[];
  type: "error" | "warning";
};

const SubmitIcon = ({ type }: Pick<CensusValidationItemListProps, "type">) => {
  return type === "error" ? (
    <span className={styles.iconError}>
      <FontAwesomeIcon icon={faExclamationTriangle} />
    </span>
  ) : (
    <span className={styles.iconWarning}>
      <FontAwesomeIcon icon={faInfoCircle} />
    </span>
  );
};

type GroupByRow = {
  rowNumber: number;
  name: string | null;
  items: (CensusValidation & { row: number })[];
};

export const CensusValidationItemList = ({
  items,
  header,
  type,
}: CensusValidationItemListProps) => {
  const sortedItems = items
    .filter((i) => !!i.cell)
    .map((item) => ({ ...item, row: item.cell ? parseInt(item.cell.replace(/[A-Z]+/, "")) : -1 }))
    .sort((a, b) => a.row - b.row);

  const rows = sortedItems.reduce<GroupByRow[]>((memo, current) => {
    const row = memo.find((m) => m.rowNumber === current.row);
    if (!row) {
      memo.push({ rowNumber: current.row, name: current.name, items: [current] });
      return memo;
    }
    row.items.push(current);
    return memo;
  }, []);

  const unmatchedColumnsRows = items.filter((item) => !item.cell && item.error);

  const count = sortedItems.length + unmatchedColumnsRows.length;

  return (
    <div data-testid={`validations-${type}`} className={styles.submitErrorsList}>
      <h5 className="mb-8">
        {header} <strong>({count})</strong>
      </h5>
      {unmatchedColumnsRows.length > 0 && (
        <Fragment>
          <p>
            <SubmitIcon type={type} /> <strong>Column Header Errors</strong>
          </p>
          <ul className="pl-12">
            {unmatchedColumnsRows.map((item, index) => (
              <li key={`${item.cell} - ${index}`}>
                <strong>General:</strong> {item.error}
              </li>
            ))}
          </ul>
        </Fragment>
      )}
      {rows.map((row) => (
        <Fragment key={row.rowNumber}>
          <p>
            <SubmitIcon type={type} /> Row {row.rowNumber}: <strong>{row.name || "-"}</strong>
          </p>
          <ul className="pl-12" key={row.rowNumber}>
            {row.items.map((item, index) => (
              <li key={`${row.rowNumber} - ${item.cell} - ${index}`}>
                <strong>Cell {item.cell || "unknown"}:</strong> {item.error}
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </div>
  );
};

const describeCell = (cell: string) => {
  const [, col, row] = /^([a-z]+)([0-9]+)$/i.exec(cell) || [];
  return { col, row };
};
const sortValidation = (result1: CensusValidation, result2: CensusValidation) => {
  // errors first
  if (result1.type > result2.type) return 1;
  if (result1.type < result2.type) return -1;
  const { row: row1string = "", col: col1 = "" } = describeCell(result1.cell || "");
  const { row: row2string = "", col: col2 = "" } = describeCell(result2.cell || "");
  const row1 = parseInt(row1string) || 0;
  const row2 = parseInt(row2string) || 0;
  // lower rows first
  if (row1 > row2) return 1;
  if (row1 < row2) return -1;
  // lower column first
  if (col1.length > col2.length) return 1;
  if (col1.length < col2.length) return -1;
  if (col1 > col2) return 1;
  if (col1 < col2) return -1;
  return 0;
};
export const generateFile = async (document: Document, validationData: CensusValidation[]) => {
  const header: Row = [
    { value: "Type", fontWeight: "bold" },
    { value: "Column", fontWeight: "bold" },
    { value: "Row", fontWeight: "bold" },
    { value: "Cell", fontWeight: "bold" },
    { value: "Member name", fontWeight: "bold" },
    { value: "Description", fontWeight: "bold" },
  ];
  const columns = [
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
    { width: 10 },
  ];
  const xlsx: SheetData = [
    header,
    ...validationData.sort(sortValidation).map((validationRow): Row => {
      const { col, row } = describeCell(validationRow.cell || "");
      const nameLength = validationRow.name?.length || 0;
      const errorLength = validationRow.error?.length || 0;
      assertIsDefined(columns[4], "columns[4]");
      assertIsDefined(columns[5], "columns[5]");
      if (nameLength > columns[4].width) {
        columns[4].width = nameLength;
      }
      if (errorLength > columns[5].width) {
        columns[5].width = errorLength;
      }
      return [
        validationRow.type === "ERROR"
          ? { value: "Error", backgroundColor: "#F8E5D8", color: "#b81f39" }
          : validationRow.type === "WARNING"
          ? { value: "Warning", backgroundColor: "#FDF2D0", color: "#8b5c04" }
          : validationRow.type === "FAILURE"
          ? { value: "Failure", backgroundColor: "#F8E5D8", color: "#b81f39" }
          : { value: "" },
        { value: col ?? "" },
        { value: row ?? "" },
        { value: validationRow.cell ?? "" },
        { value: validationRow.name ?? "" },
        { value: validationRow.error },
      ];
    }),
  ];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- the return type is wrong
  const res = (await writeXlsxFile(xlsx, { columns })) as unknown as Blob;
  const objectUrl = window.URL.createObjectURL(res);
  downloadFile(
    objectUrl,
    renameFile(document.name, { postfix: "_Validation_Report", extension: "xlsx" }),
  );
};

type ValidationResult = {
  errors: CensusValidation[];
  warnings: CensusValidation[];
  failures: CensusValidation[];
};

type ValidationResultProps = {
  validationResult: ValidationResult;
};

export const getValidationResultData = (censusValidation: CensusValidation[]) => {
  return {
    errors: censusValidation.filter((v) => v.type === "ERROR"),
    warnings: censusValidation.filter((v) => v.type === "WARNING"),
    failures: censusValidation.filter((v) => v.type === "FAILURE"),
  };
};

export const ValidationResultList = ({ validationResult }: ValidationResultProps) => {
  return (
    <>
      {validationResult.errors.length > 0 && (
        <CensusValidationItemList items={validationResult.errors} type="error" header="Errors" />
      )}
      {validationResult.warnings.length > 0 && (
        <CensusValidationItemList
          items={validationResult.warnings}
          type="warning"
          header="Warnings"
        />
      )}
    </>
  );
};

type ValidationResultsProps = {
  document: Document;
  featureToggles?: ClientFeatureToggles;
  censusValidation: CensusValidation[];
  track: (buttonLabel: string) => void;
};
const ValidationResults = ({ document, censusValidation, track }: ValidationResultsProps) => {
  const downloadReport = useCallback(() => {
    track("Download validation report");
    void generateFile(document, censusValidation);
    return false;
  }, [track, censusValidation, document]);

  const validationResult = getValidationResultData(censusValidation);
  const { errors, warnings } = validationResult;
  const errorMessage = errors.length
    ? errors.length === 1
      ? "We found 1 value that is an error that needs to be corrected."
      : `We found ${errors.length} ${pluralize(
          "value",
          errors.length,
        )} that are errors that need to be corrected.`
    : "";
  const warningMessage = warnings.length
    ? warnings.length === 1
      ? "We found 1 value that requires additional review."
      : `We found ${warnings.length} values that require additional review.`
    : "";
  return (
    <>
      <StackY dist={12}>
        {errors.length > 0 && <AlertBanner variant="error" message={errorMessage} />}
        {warnings.length > 0 && <AlertBanner variant="warning" message={warningMessage} />}
        <p className={styles.alignRight}>
          <Button type="text" icon={<DownloadIcon />} iconPosition="right" onClick={downloadReport}>
            Download validation report
          </Button>
        </p>
        <CollapsePanel
          variant="default"
          onChange={() => track("View validation report")}
          content={[
            {
              key: "results",
              title: "View validation report",
              description: <ValidationResultList validationResult={validationResult} />,
            },
          ]}
        />
        <div>
          <Body1>
            <strong>What do I do now?</strong>
          </Body1>
          {errors.length > 0 && (
            <p>
              Review the validation report above and use the notes to correct the errors in your
              file.
            </p>
          )}
          {!errors.length && (
            <p>
              Review the validation report above and check those against the values in your original
              file.
              <br />
              If the value of the cells we indicated in the report are correct and intentional in
              your file, you can submit your file with the warnings.
            </p>
          )}
          <OrderedList>
            <OrderedListItem>
              View your validation results by{" "}
              <ButtonOld type="link-inline" onClick={downloadReport}>
                downloading
              </ButtonOld>{" "}
              or expanding the report.
            </OrderedListItem>
            <OrderedListItem>
              Use the validation results to correct the errors in your file.
            </OrderedListItem>
            <OrderedListItem>Upload your revised file.</OrderedListItem>
          </OrderedList>
        </div>
      </StackY>
    </>
  );
};

type MdtCensusValidateSubmitProps = {
  client: Client;
  documentDownloadData: ReturnType<typeof useDocumentDownload>;
  mdtState: {
    document: CensusDocument;
    submitted: boolean;
    isError: boolean;
    isWarning: boolean;
    isFailure: boolean;
    failureType: CensusDomainValidations["failureType"];
    validations: CensusDomainValidations["validations"] | undefined;
  };
  track: (buttonLabel: string) => void;
};

const MdtCensusValidateSubmit = ({
  client,
  documentDownloadData,
  mdtState,
  track,
}: MdtCensusValidateSubmitProps) => {
  const featureToggles = useFeatureToggles(client.id);
  if (!mdtState) return <AlertBanner variant="error" message="File validation failed" />;

  const document = mdtState.document;

  const header = mdtState.isFailure ? (
    <h5>Thank you for uploading your file.</h5>
  ) : mdtState.isError || mdtState.isWarning ? (
    <h5>We've done an initial review of your file to validate the basic data and format.</h5>
  ) : (
    <h5>Thank you for uploading your file.</h5>
  );

  const failureInfo = mdtState.failureType
    ? validationError[mdtState.failureType || "UNKNOWN"]
    : undefined;

  return (
    <>
      {!mdtState.submitted && header}
      <StackY dist={32}>
        {(mdtState.isError || mdtState.isWarning) && !mdtState.isFailure && mdtState && (
          <ValidationResults
            document={document}
            censusValidation={mdtState.validations || []}
            track={track}
            featureToggles={featureToggles}
          />
        )}
        {mdtState.isFailure && failureInfo?.content && (
          <AlertBanner
            variant={failureInfo.type || "warning"}
            message={<Body3 as="div">{failureInfo.content}</Body3>}
          />
        )}
        <DocumentDownloadV2 {...documentDownloadData} />
      </StackY>
    </>
  );
};

type ReplacingDocument = {
  id: string;
  name: string;
  addedBy: string;
  censusSource: string;
};

const getCensusSource = (censusSource: CensusDocument["censusSource"]): string => {
  switch (censusSource) {
    case "MDT":
      return "Sun Life template";
    case "ENROLLMENT_FORM":
      return "Enrollment form";
    case "OTHER":
      return "Other";
    default:
      return censusSource || "";
  }
};

const toReplacingDocument = (document: CensusDocument): ReplacingDocument => ({
  id: document.id,
  name: document.name,
  addedBy: `${String(document.createdByUser?.firstName)} ${String(
    document.createdByUser?.lastName,
  )} on ${document.createdAt.toDateString()}`,
  censusSource: getCensusSource(document.censusSource),
});

export const CensusValidateSubmit = () => {
  const { client, policyId, trackElementClicked, isUserMFA } =
    useOutletContext<CensusOutletContext>();
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "File validation",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const {
    data: validateSubmitData,
    isPending: isLoadingCensusValidation,
    error: errorCensusValidation,
    validateSubmitCensus,
  } = useCensusValidateSubmit(client.id, policyId);

  const allDocsSubmitted = validateSubmitData?.every((data) => data.document.processStatus);

  const onFileChange = useCallback(async () => {
    await validateSubmitCensus();
  }, [validateSubmitCensus]);

  const documentDownloadData = useDocumentDownload({
    clientId: client.id,
    policyId,
    categories: ["enrollment-elections"],
    track,
    taskStatus: "In Progress",
    showDownloadButton: isUserMFA,
    showDownloadAllButton: false,
    onDelete: onFileChange,
  });
  const [showUploadRevisedSelectionList, toggleShowUploadRevisedSelectionList] = useToggler();
  const [documentToUploadRevised, setDocumentToUploadRevised] = useState<
    CensusDocument | undefined
  >();

  const [uploadRevisedModal, setUploadRevisedModal] = useState(false);
  const { mutateAsync: submitCensus, isPending: censusSubmitting } = useSubmitCensus();

  const navigate = useNavigate();

  const isLoading = isLoadingCensusValidation;

  const documents = validateSubmitData?.map((data) => data.document) || [];

  const mdtDocuments = documents.filter((d) => d.censusSource === "MDT");
  const otherDocuments = documents.filter((d) => d.censusSource !== "MDT");

  const mdtSubmittedDocuments = mdtDocuments.filter((d) => d.processStatus);

  const mdtState =
    validateSubmitData
      ?.map((data) => ({
        document: data.document,
        submitted: data.submitted,
        isError: hasValidationError(data.domainValidations?.validations),
        isWarning: hasValidationWarning(data.domainValidations?.validations),
        isFailure: !!data.domainValidations?.failureType,
        failureType: data.domainValidations?.failureType || null,
        validations: data.domainValidations?.validations,
      }))
      .filter((data) => !data.document.processStatus) || [];

  const isError = mdtState.some((state) => state.isError);
  const isWarning = mdtState.some((state) => state.isWarning);
  const isFailure = mdtState.some((state) => state.isFailure);
  const shouldReviewFile = isError || isWarning || isFailure;
  const cancelLink = `${RouteData.censusStepDetail.getPath(client.id, policyId)}/indicate-source`;
  const submitLabel =
    isError || isFailure ? "Submit with errors" : isWarning ? "Submit with warnings" : "Submit";
  const submitButtonType = shouldReviewFile ? "text-only" : "primary";

  const mdtDocumentsWithErrorsOrWarnings = mdtDocuments.filter((doc) => {
    const docState = mdtState.find((data) => data.document.id === doc.id);
    if (!docState) return false;
    return docState.isFailure || docState.isError || docState.isWarning;
  });

  const onClickUploadRevised = useCallback(() => {
    if (mdtDocumentsWithErrorsOrWarnings.length > 1) {
      setDocumentToUploadRevised(undefined);
      toggleShowUploadRevisedSelectionList();
    }
    if (mdtDocumentsWithErrorsOrWarnings.length === 1) {
      setDocumentToUploadRevised(mdtDocumentsWithErrorsOrWarnings[0]);
      setUploadRevisedModal(true);
    }
  }, [
    mdtDocumentsWithErrorsOrWarnings,
    setDocumentToUploadRevised,
    toggleShowUploadRevisedSelectionList,
    setUploadRevisedModal,
  ]);

  const UploadRevisedButtons = () => {
    return (
      <StackX dist={16} style={{ justifyContent: "flex-end" }}>
        <Button
          type={submitButtonType}
          size="middle"
          onClick={onSubmitRevisedCensus}
          loading={censusSubmitting}
        >
          {submitLabel}
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onClickUploadRevised();
            track("Upload revised file");
          }}
        >
          Upload revised file
        </Button>
      </StackX>
    );
  };

  const onSelectRevised = () => {
    toggleShowUploadRevisedSelectionList();
    setUploadRevisedModal(true);
  };

  const onSubmitRevisedCensus = useCallback(async () => {
    track(submitLabel);
    const { isSuccess, isError } = await submitCensus({
      params: {
        clientId: client.id,
        policyId,
      },
    });

    if (isSuccess) {
      navigate(
        RouteData.policyTaskDetail.getPath(client.id ?? "", "enrollment-elections", policyId),
      );
    }

    if (isError) {
      void slobMessage.error("Something went wrong");
    }
  }, [client.id, policyId, submitCensus, submitLabel, track, navigate]);

  if (isLoading) {
    return (
      <ProgressModal
        progressText="Reviewing your files..."
        body="Depending on the size of your files, this could take up to 1 minute."
        cancelLink={cancelLink}
        trackCancel={track}
      />
    );
  }
  if (errorCensusValidation || !validateSubmitData) {
    return <LoadingError type="page" title={genericErrorCopy2} />;
  }

  if (!documents.length) {
    return (
      <Navigate
        to={`${RouteData.censusStepDetail.getPath(client.id ?? "", policyId)}/upload-files`}
        replace
      />
    );
  }

  if (allDocsSubmitted)
    return (
      <Navigate
        to={RouteData.policyTaskDetail.getPath(client.id ?? "", "enrollment-elections", policyId)}
      />
    );

  return (
    <>
      <UploadRevisedModal
        show={showUploadRevisedSelectionList}
        onNext={onSelectRevised}
        onCancel={toggleShowUploadRevisedSelectionList}
        documents={mdtDocumentsWithErrorsOrWarnings}
        documentToUploadRevised={documentToUploadRevised}
        setDocumentToUploadRevised={setDocumentToUploadRevised}
        track={track}
      />
      {documentToUploadRevised && (
        <DocumentUpload
          modalTitle="Upload revised file"
          clientId={client.id}
          policyId={policyId}
          visible={uploadRevisedModal}
          closeModal={() => setUploadRevisedModal(false)}
          track={track}
          category="enrollment-elections"
          censusSource="MDT"
          replaceDocumentId={documentToUploadRevised.id}
          shouldOverrideFilename={true}
          onUpload={onFileChange}
        />
      )}
      <StackY dist={32}>
        {shouldReviewFile && <UploadRevisedButtons />}
        {mdtState.map((state) => (
          <div className={styles.contentPanel} key={state.document.id}>
            <MdtCensusValidateSubmit
              client={client}
              mdtState={state}
              documentDownloadData={{
                ...documentDownloadData,
                documents: querySuccess([state.document], documentDownloadData.documents),
              }}
              track={track}
            />
          </div>
        ))}
        {shouldReviewFile && <UploadRevisedButtons />}
        {mdtSubmittedDocuments.length > 0 && (
          <div className={styles.contentPanel}>
            <StackY dist={32}>
              <AlertBanner
                variant="info"
                message="Your file looks good. It has been submitted to Sun Life for review."
              />
              <DocumentDownloadV2
                {...documentDownloadData}
                documents={querySuccess(mdtSubmittedDocuments, documentDownloadData.documents)}
                showDownloadButton={false}
              />
            </StackY>
          </div>
        )}
        {otherDocuments.length > 0 && (
          <div className={styles.contentPanel}>
            <h5>
              The files below can't be validated but have been submitted to Sun Life for review.
            </h5>
            <StackY dist={32}>
              <DocumentDownloadV2
                {...documentDownloadData}
                documents={querySuccess(otherDocuments, documentDownloadData.documents)}
                showDownloadButton={false}
              />
            </StackY>
          </div>
        )}
      </StackY>
    </>
  );
};

type UploadRevisedModalProps = {
  show: boolean;
  onNext: () => void;
  onCancel: DispatchWithoutAction;
  documentToUploadRevised: CensusDocument | undefined;
  setDocumentToUploadRevised: Dispatch<SetStateAction<CensusDocument | undefined>>;
  documents: CensusDocument[];
  track: (buttonLabel: string) => void;
};

export const UploadRevisedModal = ({
  show,
  onNext,
  onCancel,
  documents,
  documentToUploadRevised,
  setDocumentToUploadRevised,
  track,
}: UploadRevisedModalProps) => {
  const getColumns = (): SlobColumnsType<ReplacingDocument> => [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
    },
    {
      title: "Template Source",
      dataIndex: "censusSource",
    },
    {
      render: (document: ReplacingDocument) => (
        <SlobRadio
          name="documentSelection"
          checked={documentToUploadRevised?.id === document.id}
          onChange={() => setDocumentToUploadRevised(documents.find((d) => d.id === document.id))}
          disabled={false}
          aria-label={document.name}
        />
      ),
    },
  ];
  return (
    <Modal
      open={show}
      footer={null}
      centered={true}
      disableClose={true}
      title="Which file are you replacing?"
      noTopPadding={true}
      showCloseIcon={false}
    >
      <div>
        <StackY dist={32}>
          <ResourcesTable
            rowKey="id"
            showHeader={true}
            dataSource={documents.map(toReplacingDocument)}
            columns={getColumns()}
            pagination={{ hideOnSinglePage: true }}
          />
          <StackX dist={16} style={{ justifyContent: "flex-end" }}>
            <Button
              type="text"
              size="middle"
              onClick={() => {
                onCancel();
                track("Upload Revised cancel");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="middle"
              disabled={!documentToUploadRevised}
              onClick={() => {
                track("Upload revised next");
                onNext();
              }}
            >
              Next
            </Button>
          </StackX>
        </StackY>
      </div>
    </Modal>
  );
};
