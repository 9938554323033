type MedicalRequiresPcp = Readonly<
  Record<
    string,
    Readonly<{
      label: string;
      value: boolean;
    }>
  >
>;

export const medicalRequiresPcp: MedicalRequiresPcp = {
  yes: {
    label: "Yes",
    value: true,
  },
  no: {
    label: "No",
    value: false,
  },
};
