import clsx from "clsx";
import * as styles from "./DashedBanner.module.less";
import type { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  "data-testid"?: string;
  variant: "info" | "success" | "warning" | "error";
  attached?: boolean;
}>;

export function DashedBanner(props: Props) {
  const { children, attached, variant, "data-testid": dataTestId } = props;
  return (
    <div
      data-testid={dataTestId}
      className={clsx(styles.dashedBanner, {
        [styles.attached]: attached,
        [styles.info]: variant === "info",
        [styles.success]: variant === "success",
        [styles.warning]: variant === "warning",
        [styles.error]: variant === "error",
      })}
    >
      {children}
    </div>
  );
}
