import { ChipGroup } from "client/src/components/Chip/ChipGroup";
import { type BenefitTypeEIF } from "shared/types/BenefitTypes";
import { benefitTypeToCoverage, type SlfCoverageLongName } from "shared/types/SlfCoverages";
import {
  benefitTypeToGroupRecord,
  getBenefitTypesGroupings,
} from "shared/utils/benefitTypesGroupings";
import { assertIsDefined } from "shared/utils/utils";
import type { ChipGroupProps } from "client/src/components/Chip/ChipGroup";

type Props = {
  benefitTypes: BenefitTypeEIF[];
  selectedBenefitTypes: BenefitTypeEIF[];

  name: ChipGroupProps<SlfCoverageLongName>["name"];
  touched: ChipGroupProps<SlfCoverageLongName>["touched"];
  error: ChipGroupProps<SlfCoverageLongName>["error"];
  disabled?: boolean;
  setFieldValue: (name: string, value: unknown) => Promise<unknown>;
};

export function EIFSingleBenefitsChipGroup(props: Props) {
  const { benefitTypes, selectedBenefitTypes, name, touched, error, disabled, setFieldValue } =
    props;

  const slfCoverages = benefitTypes.map((bt) => benefitTypeToCoverage[bt]).sort();

  const benefitTypeGroupsMap = getBenefitTypesGroupings(slfCoverages);
  const benefitGroupsEntries = Array.from(benefitTypeGroupsMap.entries());
  const options = benefitGroupsEntries.flatMap(([_groupName, benefitsTypesInGroup]) => {
    const [mainBenefitType, ...otherBenefits] = benefitsTypesInGroup;
    assertIsDefined(mainBenefitType, "mainBenefitType");
    const mainSlfCoverage = benefitTypeToCoverage[mainBenefitType];
    assertIsDefined(mainSlfCoverage, "mainSlfCoverage");

    const groupOptions = benefitsTypesInGroup.map((benefitType) => {
      const slfCoverage = benefitTypeToCoverage[benefitType];

      const mainBenefitIsNotSelected =
        otherBenefits.includes(benefitType) && !selectedBenefitTypes.includes(mainBenefitType);

      return {
        label: slfCoverage,
        value: benefitType,
        disabled: disabled || mainBenefitIsNotSelected,
        disabledReason: mainBenefitIsNotSelected
          ? `${mainSlfCoverage} needs to be selected first.`
          : undefined,
      };
    });
    return groupOptions;
  });

  return (
    <ChipGroup
      name={name}
      value={selectedBenefitTypes}
      touched={touched}
      error={error}
      onChange={async (e) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- guaranteed
        const benefitType = e.target.value as BenefitTypeEIF;
        const checked = e.target.checked;

        const groupNameForThisBenefitType = benefitTypeToGroupRecord[benefitType];
        const benefitTypesInThisGroup = groupNameForThisBenefitType
          ? benefitTypeGroupsMap.get(groupNameForThisBenefitType) || []
          : [];
        const [mainBenefit, ...otherBenefits] = benefitTypesInThisGroup;

        const nextValues = checked
          ? selectedBenefitTypes.concat(benefitType).sort()
          : selectedBenefitTypes
              .filter((bt) => bt !== benefitType)
              .filter((bt) => bt === mainBenefit || !otherBenefits.includes(bt))
              .sort();

        await setFieldValue(name, nextValues);
      }}
      options={options}
    />
  );
}
