import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useCallback, useState } from "react";

import { isAddCoverageClient } from "shared/utils/client";
import { RouteData } from "../../../../shared/config/routeData";
import { Anchor } from "../../components/Anchor/Anchor";
import { BulbMessage } from "../../components/BulbMessage/BulbMessage";
import { GenericErrorCopy } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { ReactComponent as PencilIcon } from "../../components/Icons/PencilIcon.svg";
import { InternalControl } from "../../components/InternalControl/InternalControl";
import { OrderedList, OrderedListItem } from "../../components/OrderedList/OrderedList";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body5, H5 } from "../../components/Typography/Typography";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { hasRemainingHubTasks } from "../../pages/Hub/Hub";
import { useTrackElementClicked } from "../../utils/analytics";
import { AddFile } from "../Document/AddFile/AddFile";
import { AddFilesCard } from "../Document/AddFilesCard/AddFilesCard";
import { DocumentDownload } from "../Document/DocumentDownload/DocumentDownload";
import { DocumentDownloadLink } from "../Document/DocumentDownloadLink/DocumentDownloadLink";
import { StoredSecurelyText } from "../Document/StoredSecurelyText";
import { getCensusEnrollmentElectionsBadgeMessage } from "../Task/nigoMessage";

import * as styles from "./censusCard.module.less";
import type { Client, Policy } from "../../../../shared/types/Client";
import type { ElementClickedOptions } from "../../utils/analytics";
import type { ResourceRowData } from "../Document/DownloadFileTable/DownloadFileTable";
import type { ResponseError } from "client/src/hooks/query";
import type { Task } from "shared/data/Tasks";
import type { CensusDocument } from "shared/types/Document";

const submittedStatuses = [
  "SUBMITTED",
  "SUBMITTED_WITH_ERRORS",
  "SUBMITTED_WITH_WARNINGS",
  "SUBMITTED_WITH_ERRORS_AND_WARNINGS",
  "SUBMITTED_WITH_FAILURES",
];

const isSubmittedDoc = (doc: CensusDocument) => {
  return submittedStatuses?.includes(doc.processStatus || "");
};

const CensusSubmitStatusRenderer = (record: ResourceRowData) => {
  const { clientId, policyId } = useClientHubParams();
  const trackElementClicked = useTrackElementClicked(
    undefined,
    "Submit Enrollment Elections",
    policyId,
  );

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Submitted files with errors",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  if (record.type !== "document" || !record.id) return null;
  const justWarning = record.processStatus === "SUBMITTED_WITH_WARNINGS";
  const isFailure = record.processStatus === "SUBMITTED_WITH_FAILURES";
  const warningClass = justWarning || isFailure ? styles.warning : styles.error;
  return (
    <div className={warningClass}>
      <StackX dist={16}>
        {record.processStatus === "SUBMITTED_WITH_ERRORS" && (
          <span>{<FontAwesomeIcon icon={faExclamationTriangle} />} Submitted with errors</span>
        )}
        {record.processStatus === "SUBMITTED_WITH_FAILURES" && (
          <span>{<FontAwesomeIcon icon={faInfoCircle} />} Unable to validate file</span>
        )}
        {record.processStatus === "SUBMITTED_WITH_WARNINGS" && (
          <span>{<FontAwesomeIcon icon={faInfoCircle} />} Submitted with warnings</span>
        )}
        {record.processStatus === "SUBMITTED_WITH_ERRORS_AND_WARNINGS" && (
          <span>
            {<FontAwesomeIcon icon={faExclamationTriangle} />} Submitted with errors & warnings
          </span>
        )}
        {record.processStatus &&
          (record.processStatus === "SUBMITTED_WITH_ERRORS" ||
            record.processStatus === "SUBMITTED_WITH_WARNINGS" ||
            record.processStatus === "SUBMITTED_WITH_ERRORS_AND_WARNINGS") && (
            <Anchor
              target="_blank"
              href={RouteData.censusValidateReportTab.getPath(
                clientId || "",
                policyId || "",
                record.id,
              )}
              onClick={() => track("View validation report")}
            >
              View validation report
            </Anchor>
          )}
      </StackX>
    </div>
  );
};

type CensusCardProps = {
  client: Client;
  policy: Policy;
  dhmoTipId: string;
  task: Task;
  subTitle?: string;
  isInternalUser: boolean;
  isUserMFA: boolean;
  isBrokerOrBA: boolean;
  trackElementClicked: ({ buttonLabel, module, moduleState }: ElementClickedOptions) => void;
  track: (buttonLabel: string) => void;
  hasDHMO: boolean;
  errorFetchingDocuments: ResponseError | null;
  documents?: CensusDocument[];
};

export const CensusCard = ({
  client,
  policy,
  dhmoTipId,
  task,
  subTitle,
  isInternalUser,
  isUserMFA,
  isBrokerOrBA,
  trackElementClicked,
  track,
  hasDHMO,
  errorFetchingDocuments,
  documents,
}: CensusCardProps) => {
  const [showInternalEditPopover, setShowInternalEditPopover] = useState(false);

  const remainingTasks = hasRemainingHubTasks(client);

  const notStartedDisabled =
    (task.status === "Not Started" || task.status === "Upcoming") &&
    task.statusId === "you-need-to-provide-enrollment-census";
  const notStartedEnabled =
    (task.status === "Not Started" || task.status === "Upcoming") && task.statusId === null;

  const actionRequiredMessage =
    task.status === "NIGO" ? getCensusEnrollmentElectionsBadgeMessage(client.NIGOReasons) : null;

  const enrollmentElectionDocuments = documents?.filter(
    (doc) => doc.category === "enrollment-elections",
  );
  const shouldShowDownloadAllButton =
    enrollmentElectionDocuments !== undefined && enrollmentElectionDocuments.length > 1;

  const isSubmitted = Boolean(enrollmentElectionDocuments?.every((doc) => isSubmittedDoc(doc)));
  const hasDocuments = Boolean(enrollmentElectionDocuments?.length);
  const isNIGOWithNoDocuments = Boolean(task.status === "NIGO" && !hasDocuments);
  const isNIGONotSubmitted = Boolean(task.status === "NIGO" && hasDocuments && !isSubmitted);
  const isNIGOSubmitted = Boolean(task.status === "NIGO" && hasDocuments && isSubmitted);

  const inProgressOrNIGONotSubmitted = task.status === "In Progress" || isNIGONotSubmitted;
  const inReviewOrNIGOSubmitted = task.status === "In Review" || isNIGOSubmitted;

  const buttonText = inProgressOrNIGONotSubmitted ? "Continue" : "Upload Files";

  const vdrUploadInfoMessage = `For Dental (DHMO - Prepaid), make sure the dental facility ID# is included in your enrollment elections.`;

  const documentLink = (
    <DocumentDownloadLink clientId={client.id} category={"census-template"} track={track}>
      custom template file
    </DocumentDownloadLink>
  );

  const isAddCoverage = isAddCoverageClient(client);

  return (
    <AddFilesCard
      clientId={client.id}
      uploadLink={`${RouteData.censusStepDetail.getPath(client.id ?? "", policy.id)}/upload-files`}
      hideButton={task.status === "Done"}
      readonly={task.status === "Done" && isBrokerOrBA}
      taskStatus={task.status}
      category="enrollment-elections"
      policyId={policy.id}
      taskId={task.id}
      policies={client.policies}
      showDownloadButton={isUserMFA}
      showDownloadAllButton={shouldShowDownloadAllButton}
      separateByProcessStatus={true}
      withSource={true}
      multiPolicyFiles
      title={
        <H5 as="h2">
          {inProgressOrNIGONotSubmitted
            ? "Complete your enrollment elections submission"
            : inReviewOrNIGOSubmitted
            ? "Thank you for submitting your enrollment elections"
            : "Upload your enrollment elections"}
        </H5>
      }
      downloadExpandedRowRender={CensusSubmitStatusRenderer}
      subTitle={subTitle}
      buttonDisabled={notStartedDisabled}
      buttonText={buttonText}
      hideTable={notStartedDisabled}
      message="Sun Life will review your submitted file and let you know if anything else is required from you or if there are any questions. If you realize you missed submitting something, please reach out to your Implementation Consultant to notify and discuss with them before uploading another file here."
      progressMessage="You have added an enrollment elections file, but have not submitted it for review."
      actionRequiredMessage={actionRequiredMessage}
      taskCompletedTitle="Your files have been submitted for review!"
      remainingTasks={remainingTasks}
      header={
        isInternalUser && (
          <InternalControl
            buttonIcon={<PencilIcon />}
            buttonLabel="Edit"
            position="attached"
            popoverTrigger="click"
            popoverVisible={showInternalEditPopover}
            onPopoverVisibleChange={setShowInternalEditPopover}
            onClick={() => track("Edit")}
            popoverContent={
              <AddFile
                clientId={client.id}
                category="census-template"
                addDocumentsLabel="Upload census template"
                replaceDocumentsLabel="Replace census template"
                deleteDocumentsLabel="Delete file"
                onClick={() => setShowInternalEditPopover(false)}
                track={track}
              />
            }
            extraContent={
              <DocumentDownload
                clientId={client.id}
                categories={["census-template"]}
                track={track}
                taskStatus={task.status}
                withSource={true}
              />
            }
          />
        )
      }
      description={
        <StackY dist={16}>
          {notStartedDisabled && (
            <span>
              We’re not quite ready for you to send this yet. In the meantime, you can download your{" "}
              {documentLink} if you haven’t already. The template has been updated to reflect your
              specific benefit lines and employee classes.
            </span>
          )}

          {(notStartedEnabled || isNIGOWithNoDocuments) && (
            <OrderedList>
              <OrderedListItem>
                <StackY dist={12}>
                  <div>
                    Download your {documentLink} to get started. It has been updated to reflect your
                    specific benefit lines and employee classes.
                  </div>
                  {isAddCoverage ? (
                    <BulbMessage>
                      Using the custom template allows you to easily add new coverage and take
                      advantage of real-time validation when you upload.
                    </BulbMessage>
                  ) : (
                    <BulbMessage>
                      Using the custom template allows you to take advantage of real-time validation
                      when you upload, and can save time in the onboarding process.
                    </BulbMessage>
                  )}
                </StackY>
              </OrderedListItem>

              {hasDHMO ? (
                <OrderedListItem>
                  Add your employee enrollment elections,{" "}
                  <Anchor
                    href={`${RouteData.policyTaskDetail.getPath(
                      client.id,
                      "enrollment-elections",
                      policy.id,
                    )}#${dhmoTipId}`}
                  >
                    dental office (facility) IDs
                  </Anchor>{" "}
                  and other information into the template.
                </OrderedListItem>
              ) : isAddCoverage ? (
                <OrderedListItem>
                  {policy.slfCoverages?.length === 1
                    ? `Add your enrollments for your new ${policy.slfCoverages[0]} line of coverage.`
                    : `Add your enrollments for your new lines of coverage; ${policy.slfCoverages?.join(
                        ", ",
                      )}.`}
                </OrderedListItem>
              ) : (
                <OrderedListItem>
                  Add your employee enrollment elections and information into this template.
                </OrderedListItem>
              )}
              <OrderedListItem>
                Upload your completed file to send to Sun Life for review.
              </OrderedListItem>
            </OrderedList>
          )}

          {(task.status === "In Progress" || isNIGONotSubmitted) && (
            <p>
              Select the <strong>Continue</strong> button to complete your enrollment elections
              submission. You may also delete the files you've added and start over.
            </p>
          )}

          {errorFetchingDocuments && (
            <LoadingError
              type="component"
              error={errorFetchingDocuments}
              title={GenericErrorCopy}
            />
          )}
        </StackY>
      }
      footer={
        task.status === "Done"
          ? (toggleVisible, track) => (
              <div className={`text-center`}>
                <Body5 as="p">
                  Missed something?{" "}
                  <ButtonOld
                    type="link-inline"
                    size="small"
                    onClick={() => {
                      track("Add more files");
                      toggleVisible();
                    }}
                  >
                    Add more files
                  </ButtonOld>{" "}
                  or download your{" "}
                  <DocumentDownloadLink
                    clientId={client.id}
                    category="census-template"
                    size="small"
                    track={track}
                  >
                    original census template.
                  </DocumentDownloadLink>
                </Body5>

                <StoredSecurelyText track={track} />
              </div>
            )
          : inProgressOrNIGONotSubmitted
          ? (toggleVisible, track) => (
              <span>
                Need your customized template from Sun Life?{" "}
                <DocumentDownloadLink
                  clientId={client.id}
                  category="census-template"
                  size="small"
                  track={track}
                >
                  Download it here.
                </DocumentDownloadLink>
              </span>
            )
          : undefined
      }
      trackElementClicked={trackElementClicked}
      infoMessage={hasDHMO ? vdrUploadInfoMessage : undefined}
    />
  );
};
