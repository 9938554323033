import { Divider } from "antd";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { formatDate } from "shared/utils/format";
import { Button } from "../../components/Button/Button";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { Loading } from "../../components/Loading/Loading";
import { PageContent } from "../../components/PageContent/PageContent";
import { ExplorerPageForm } from "../../domain/ExplorerPages/ExplorerPageForm/ExplorerPageForm";
import {
  useGetAssociatedExplorerPages,
  useGetExplorerPageById,
} from "../../hooks/ExplorerPage/useExplorerPages";
import { useGetTaskById } from "../../hooks/ExplorerPage/useGetTaskById";
import { useGetBenAdminPlatforms } from "../../hooks/benAdminPlatforms";
import { useGetCarriers } from "../../hooks/carrier";
import { useGetClientByID } from "../../hooks/client";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useGetFeatureToggles } from "../../hooks/useFeatureToggles";
import { isExplorerPageExpired } from "../../utils/explorerPage";

type ExplorerPageConfigPageProps = {
  isRenewal?: boolean;
};

export const ExplorerPageConfigPage = ({ isRenewal = false }: ExplorerPageConfigPageProps) => {
  const { clientId, explorerPageId } = useClientHubParams(["clientId"]);

  const navigate = useNavigate();
  const client = useGetClientByID(clientId);
  // TODO ticketMeta route uses the client and carriers data, so future effort can get rid of that route and use the client/carries data
  const ticketMeta = useGetTaskById(client.data?.ticketId ?? "");
  const carriers = useGetCarriers();
  const benAdminPlatforms = useGetBenAdminPlatforms();
  const { data: explorerPage, isFetching: isInitialLoadingExistingPage } = useGetExplorerPageById(
    clientId,
    explorerPageId ?? "",
  );
  const { data: associatedPages, isFetching: isLoadingAssociatedPages } =
    useGetAssociatedExplorerPages({
      clientId: clientId,
      associationId: explorerPage?.associationId,
    });
  const { data: featureToggles, isLoading: isLoadingFeatureToggles } =
    useGetFeatureToggles(clientId);

  const versionOptions = useMemo(
    () =>
      associatedPages?.map((page) => ({
        label:
          page.startsAt && page.expiresAt
            ? `${formatDate(page.startsAt)} - ${formatDate(page.expiresAt)}`
            : page.startsAt
            ? `${formatDate(page.startsAt)}`
            : "",
        value: page.id,
      })),
    [associatedPages],
  );

  if (
    client.isInitialLoading ||
    ticketMeta.isInitialLoading ||
    carriers.isInitialLoading ||
    isInitialLoadingExistingPage ||
    isLoadingAssociatedPages ||
    isLoadingFeatureToggles
  ) {
    return <Loading />;
  }

  if (!clientId || client.error || !client.data) {
    return triggerError("Client not found");
  }

  const formDisabled = !!(
    explorerPage &&
    isExplorerPageExpired(explorerPage) &&
    associatedPages?.find((page) => page.id !== explorerPage.id && !isExplorerPageExpired(page))
  );

  return (
    <PageContent>
      <ButtonOld
        size="middle"
        type="link-inline"
        onClick={() => navigate(RouteData.clientDetail.getPath(clientId))}
      >
        &lt; Back to manage Client
      </ButtonOld>

      <Row gutter={24}>
        <Col flex={1}>
          <h1>Benefits Explorer Configuration</h1>
        </Col>
        {!isRenewal && versionOptions?.length && (
          <Col span={6}>
            <SlobSelect
              fillWidth
              value={explorerPageId}
              onChange={(newValue) => {
                navigate(RouteData.editBenefitsExplorerSite.getPath(clientId, newValue.value), {
                  replace: true,
                });
              }}
              options={versionOptions ?? []}
              placeholder={"Site Version"}
              name={"siteVersion"}
              touched={undefined}
              error={undefined}
            />
          </Col>
        )}
        <Col>
          <Button
            href="https://survey.alchemer.com/s3/6735021/Benefits-Explorer-Feedback-Issue-Reporting-INTERNAL-ONLY"
            newTab
            size="small"
            type="primary"
          >
            Give feedback
          </Button>
        </Col>
      </Row>

      <Divider type="horizontal" />
      <Row>
        <Col flex={1}>
          <ExplorerPageForm
            client={client.data}
            explorerPage={explorerPage}
            carriers={carriers.data ?? []}
            benAdminPlatforms={benAdminPlatforms.data ?? []}
            onDelete={() => navigate(RouteData.clientDetail.getPath(clientId))}
            onCancel={() => navigate(RouteData.clientDetail.getPath(clientId))}
            initialBenefits={
              !client.data.benefitsExplorerCustomBenefitOrder
                ? ticketMeta.data?.benefits
                : undefined
            }
            isRenewal={isRenewal}
            featureToggles={featureToggles}
            disabled={formDisabled}
            slfPolicies={ticketMeta.data?.slfPolicies}
          />
        </Col>
      </Row>
    </PageContent>
  );
};
