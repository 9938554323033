import { Chip } from "client/src/components/Chip/Chip";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { listFormat } from "shared/utils/format";
import type { ChangeEventHandler } from "react";

export type ChipGroupProps<TValue extends string> = {
  name: string;
  value: TValue[] | null | undefined;
  touched: boolean | undefined;
  error?: string | string[] | undefined;
  errorId?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  form?: string;
  disabled?: boolean;
  options: Array<
    {
      label: string;
      value: TValue;
      "data-testid"?: string;
    } & (
      | { disabled?: false; disabledReason?: never }
      | { disabled: true; disabledReason: string }
      | { disabled?: boolean; disabledReason: string | undefined }
    )
  >;
};

export function ChipGroup<TValue extends string>(props: ChipGroupProps<TValue>) {
  const {
    name,
    value,
    touched,
    error: errorOrErrors,
    errorId,
    onChange,
    form,
    disabled,
    options,
  } = props;

  const externalErrorId = errorId;
  const internalErrorId = touched && !!errorOrErrors ? `${name}__errornessage` : undefined;
  const hasError = touched && Boolean(externalErrorId || internalErrorId);
  const ariaErrorMessage = [externalErrorId, internalErrorId].join(" ").trim();

  const error = Array.isArray(errorOrErrors) ? listFormat(errorOrErrors) : errorOrErrors;

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: 12,
          flexWrap: "wrap",
        }}
      >
        {options.map((option) => (
          <Chip
            key={option.value}
            name={name}
            value={option.value}
            checked={value?.includes(option.value) ?? false}
            disabled={option.disabled || disabled}
            disabledReason={option.disabledReason}
            touched={touched}
            error={error}
            errorId={errorId}
            onChange={onChange}
            form={form}
            aria-invalid={hasError}
            aria-errormessage={ariaErrorMessage}
            data-testid={option["data-testid"]}
          >
            {option.label}
          </Chip>
        ))}
      </div>

      <div aria-live="assertive" className="hide:empty">
        {touched && !!errorOrErrors && (
          <div className="mt-16">
            <InputErrorMessage id={internalErrorId} error={error} />
          </div>
        )}
      </div>
    </div>
  );
}
