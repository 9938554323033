import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import * as Yup from "yup";
import { FormInput } from "../Form/Input";
import { Body2 } from "../Typography/Typography";
import { ConfirmDialog } from "./ConfirmDialog";

type Props = {
  clientName: string;
  isVisible: boolean;
  onCancel: () => void;
  handleDelete: () => Promise<void>;
};

export const ConfirmDialogDeleteClient = ({
  isVisible,
  handleDelete,
  onCancel,
  clientName,
}: Props) => {
  const formik = useSlobFormik({
    validationSchema: Yup.object({
      clientNameToDelete: Yup.string()
        .required()
        .test(
          "should match the client name",
          "Please enter the client's name exactly as it is displayed to confirm",
          (value) => value === clientName,
        ),
    }),
    initialValues: { clientNameToDelete: "" },
    onSubmit: handleDelete,
  });

  return (
    <ConfirmDialog
      title="Delete client"
      isVisible={isVisible}
      onCancel={onCancel}
      onConfirm={formik.submitForm}
      isLoading={formik.isSubmitting}
      confirmActionText="Yes"
      cancelActionText="No"
    >
      <>
        <p>
          Are you sure you want to delete this client?
          <br />
          <Body2>{clientName}</Body2>
        </p>

        <p>
          Deleting a client can’t be undone and will{" "}
          <Body2 redMedium>delete all data and documents associated with this client.</Body2> You
          can put the client in draft mode which will prevent benefits administrators and brokers
          from accessing the client.
        </p>

        <form>
          <FormInput
            name="clientNameToDelete"
            value={formik.values.clientNameToDelete}
            onChange={formik.handleChange}
            label={`Type "${clientName}" to confirm`}
            maxLength={clientName.length}
            error={formik.errors.clientNameToDelete}
            touched={formik.touched.clientNameToDelete}
          />
        </form>
      </>
    </ConfirmDialog>
  );
};
