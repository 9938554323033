import { Anchor } from "client/src/components/Anchor/Anchor";
import { Row, Col } from "client/src/components/Grid/Grid";

import {
  DentalNetworkPlanNumber,
  isSunlifeCarrier,
} from "../../../../../../shared/types/ExplorerPageBenefit";
import { Button } from "../../../../components/Button/Button";
import { Body1, H3 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey } from "../../utils";

import type { Language } from "../../../../../../shared/types/ExplorerPage";
import type { LocationStateCode } from "../../../../../../shared/types/Location";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type {
  DentalNetwork,
  ExplorerPageBenefit,
  PlanType,
} from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  FindDoctor: "find-doctor",
};

export type ExplorerBenefitFindDoctorCopyStructure = {
  [benefitType in Extract<BenefitTypeBenEx, "VISION" | "DENTAL">]: {
    header: string;
    description: string;
    callToAction?: string;
    callToAction_one?: string;
    callToAction_other?: string;
    callToAction_DHMO_TX_other?: string;
    callToAction_DHMO_FL_other?: string;
  };
};

export type ExplorerBenefitFindDoctorProps = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
  employerState: LocationStateCode;
  language: Language;
  track?: () => void;
};

export const ExplorerBenefitFindDoctor = ({
  benefitType,
  plans,
  employerState,
  language,
  track,
}: ExplorerBenefitFindDoctorProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitFindDoctor" });

  const condensedPlans = plans.reduce<ExplorerPageBenefit[]>((acc, val) => {
    if (!isSunlifeCarrier(val.carrierId)) return acc;
    const planType =
      val.planType === "ASO_PPO" || val.planType === "INDEMNITY" ? "PPO" : val.planType;
    if (val.benefitType === "VISION" && !acc.find((p) => p.benefitType === "VISION")) acc.push(val);
    else if (val.benefitType === "DENTAL" && !acc.find((p) => p.planType === planType)) {
      val.planType = planType;
      acc.push(val);
    }
    return acc;
  }, []);

  if (!condensedPlans.length) return null;

  return (
    <Row data-testid={DataTestId.FindDoctor}>
      <Col md={12} span={24}>
        <H3>
          <strong>{t(copyKey(benefitType, "header"))}</strong>
        </H3>
        <div>
          <Body1>
            <Trans t={t} i18nKey={copyKey(benefitType, "description")}>
              <Anchor
                href="https://www.sunlife.com/us/Resources/Tools/formembers/nominate.html"
                target="_blank"
                newTabIcon={false}
              >
                .
              </Anchor>
            </Trans>
          </Body1>
        </div>
        <Row gutter={16}>
          {condensedPlans.map(({ planType, prepaidDentalNetwork, prepaidSitusState }) => (
            <Col key={`find-doctor-${benefitType}-${planType}`} className="mt-24">
              <Button
                type="tertiary"
                size="middle"
                onClick={() => {
                  track?.();

                  const link = getFindDoctorLink(
                    benefitType,
                    planType,
                    prepaidDentalNetwork,
                    prepaidSitusState ?? employerState,
                    language,
                  );
                  if (!link) return;
                  window.open(link, "_blank");
                }}
              >
                {t(copyKey(benefitType, "callToAction"), {
                  planType,
                  count: condensedPlans.length,
                  context: `${planType}_${prepaidSitusState ?? employerState}`,
                })}
              </Button>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

const getFindDoctorLink = (
  benefitType: BenefitTypeBenEx,
  planType: PlanType | null,
  network: DentalNetwork | null,
  locState: LocationStateCode | null,
  language: Language,
): string =>
  benefitType === "VISION"
    ? language === "es"
      ? "https://es.vsp.com/"
      : "https://www.vsp.com/"
    : planType === "PPO" || planType === "ASO_PPO"
    ? language === "es"
      ? `http://sunlife.go2dental.com/member/dental_search_sp/srchinp.cgi?plan_number=${
          locState === "NC" ? 46 : 47
        }`
      : `http://sunlife.go2dental.com/member/dental_search/srchinp.cgi?plan_number=${
          locState === "NC" ? 46 : 47
        }`
    : !network || !locState
    ? language === "es"
      ? "https://www.sunlife.com/us/resources/tools/Find%20a%20dentist/?vgnLocale=es_CA"
      : "https://www.sunlife.com/us/Resources/Tools/Find+a+dentist/"
    : language === "es"
    ? `http://sunlife.go2dental.com/member/dental_search_sp/srchinp.cgi?plan_number=${DentalNetworkPlanNumber[network]}&plan_state=${DentalNetworkPlanNumber[network]}_${locState}`
    : `http://sunlife.go2dental.com/member/dental_search/srchinp.cgi?plan_number=${DentalNetworkPlanNumber[network]}&plan_state=${DentalNetworkPlanNumber[network]}_${locState}`;
