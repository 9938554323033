import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { useState } from "react";
import { SLF_OWNER } from "shared/rbac/roles";
import { SlfInternalCreateValidationSchema } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { genericErrorCopy } from "../../../components/Error/ErrorMessage";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useCreateSlfInternal } from "../../../hooks/user";
import { SlfInternalCreateForm } from "../Form/SlfInternalCreateForm";

type SlfInternalCreateProps = {
  clearFilter: () => void;
};

export const SlfInternalCreate = ({ clearFilter }: SlfInternalCreateProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clientError, setClientError] = useState("");

  const { mutateAsync: createUser } = useCreateSlfInternal();

  const resetForm = () => {
    formik.resetForm();
    setClientError("");
  };

  const formik = useSlobFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      sendWelcomeEmail: false,
      role: SLF_OWNER,
    },

    validateOnBlur: true,
    validationSchema: SlfInternalCreateValidationSchema,
    async onSubmit(values) {
      try {
        setClientError("");
        await createUser({ data: values });
        setIsModalVisible(false);
        clearFilter();
        resetForm();
        void slobMessage.success("Successfully created user");
      } catch (error) {
        const responseError = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy);
        setClientError(responseError);
      }
    },
  });

  return (
    <>
      <Modal
        destroyOnClose={true}
        open={isModalVisible}
        footer={null}
        centered={true}
        onCancel={() => {
          setIsModalVisible(false);
          resetForm();
        }}
      >
        <SlfInternalCreateForm formik={formik} clientError={clientError} />
      </Modal>
      <Button
        type="primary"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} /> Add User
      </Button>
    </>
  );
};
