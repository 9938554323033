import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY, StackX } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Eyebrow } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EIFBenefitsChipGroup } from "client/src/domain/EIF/common/EIFBenefitsChipGroup/EIFBenefitsChipGroup";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import clsx from "clsx";
import { flushSync } from "react-dom";
import { type BenefitTypeEIF, getIsDHMOBenefitType } from "shared/types/BenefitTypes";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  getIsSlfCoverageLongName,
} from "shared/types/SlfCoverages";
import {
  getBenefitTypesGroupings,
  getCombinedCoverageName,
} from "shared/utils/benefitTypesGroupings";
import { unique, rejectNullableValues, assertIsDefined } from "shared/utils/utils";
import {
  employeeClassPlanValidationSchema,
  waitingPeriodUnit,
} from "shared/validation/employeeClassPlan";
import * as Yup from "yup";
import * as styles from "./EIFClassBuilderWaitingPeriodForm.module.less";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetWaitingPeriodsQuery } from "client/src/hooks/employeeClassPlan";
import type { Location } from "react-router-dom";
import type { UserData } from "shared/rbac/rbac";

import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  EmployeeClassPlanId,
  EmployeeClassPlanWithPlan,
  EmployeeClassWaitingPeriodFields,
  WaitingPeriod,
  WaitingPeriodUnit,
} from "shared/types/EmployeeClassPlan";
import type { PlanId } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const validationSchema = employeeClassPlanValidationSchema.pick(["waitingPeriodType"]).concat(
  Yup.object({
    selectedBenefitTypes: Yup.array()
      .of(Yup.mixed<BenefitTypeEIF>().required())
      .required()
      .min(1, "Select at least one benefit type"),
    waitingPeriodDuration: Yup.number().when(["waitingPeriodType"], {
      is: (waitingPeriodType: string) => waitingPeriodType === "DAY_MONTH",
      then: (schema) =>
        schema
          .nullable()
          .moreThan(
            0,
            `Value must be greater than 0. If there is no waiting period, choose the "No waiting period" option`,
          )
          .typeError("Duration must be a number great than 0")
          .required("Please enter a duration"),
    }),
    waitingPeriodUnit: waitingPeriodUnit.when(["waitingPeriodType"], {
      is: (waitingPeriodType: string) => waitingPeriodType === "DAY_MONTH",
      then: (schema) => schema.required("Please select a time period"),
    }),
    fofmAdditional: Yup.boolean(),
    fofmAdditionalDuration: Yup.number().when(["waitingPeriodType", "fofmAdditional"], {
      is: (waitingPeriodType: string, fofmAdditional: boolean) =>
        (waitingPeriodType === "FOFM" || waitingPeriodType === "FOFM_INCLUSIVE") && fofmAdditional,
      then: (schema) =>
        schema
          .moreThan(
            0,
            `Value must be greater than 0. If there is no waiting period, choose the "No waiting period" option`,
          )
          .typeError("Duration must be a number great than 0")
          .required("Please enter a duration"),
    }),
    fofmAdditionalUnit: waitingPeriodUnit.when(["waitingPeriodType", "fofmAdditional"], {
      is: (waitingPeriodType: string, fofmAdditional: boolean) =>
        (waitingPeriodType === "FOFM" || waitingPeriodType === "FOFM_INCLUSIVE") && fofmAdditional,
      then: (schema) => schema.required("Please select a time period"),
    }),
    fofmInclusive: Yup.boolean(),
  }),
);

export const relevantChangesForWaitingPeriodFields = (
  employeeClassPlanIds: string[],
  changeSnapshot: DEIFChangeSnapshot,
  contribtutionFields: EmployeeClassWaitingPeriodFields[] = [
    "waitingPeriodType",
    "waitingPeriodDuration",
    "waitingPeriodUnit",
  ],
) => {
  if (!changeSnapshot) return [];

  const employeeClassPlansWithRelevantChanges = employeeClassPlanIds
    .flatMap((id) => {
      if (!changeSnapshot.EmployeeClassPlan[id]) return [];
      return contribtutionFields.map((fieldName) => {
        return changeSnapshot.EmployeeClassPlan[id]?.[fieldName];
      });
    })
    .filter(rejectNullableValues);

  return employeeClassPlansWithRelevantChanges;
};

export const relevantChangesForWaitingPeriodType = (
  employeeClassPlanIds: string[],
  changeSnapshot: DEIFChangeSnapshot,
  relevantWaitingPeriodType: WaitingPeriod,
) => {
  if (!changeSnapshot) return [];

  const employeeClassPlansWithRelevantChanges = employeeClassPlanIds
    .flatMap((id) => {
      if (!changeSnapshot.EmployeeClassPlan[id]) return [];
      if (
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.previousValue.value ===
          relevantWaitingPeriodType ||
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.currentValue.value ===
          relevantWaitingPeriodType
      ) {
        return changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType;
      }
      return [];
    })
    .filter(rejectNullableValues);

  return employeeClassPlansWithRelevantChanges;
};

// This needs its own specific function, because the waiting period duration can
// be paired with multiple waitingPeriodTypes (DAY_MONTH, FOFM, and FOMF_INCLUSIVE).
// So multiple fields need to be evaluated simultaneously
export const relevantFOFMAdditionalChanges = (
  employeeClassPlanIds: string[],
  changeSnapshot: DEIFChangeSnapshot,
) => {
  if (!changeSnapshot) return [];
  const employeeClassPlansWithRelevantChanges = employeeClassPlanIds
    .flatMap((id) => {
      if (!changeSnapshot.EmployeeClassPlan[id]) return [];
      const startedFOFM =
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.previousValue.value === "FOFM" ||
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.previousValue.value ===
          "FOFM_INCLUSIVE";
      const endedFOFM =
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.currentValue.value === "FOFM" ||
        changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType?.currentValue.value ===
          "FOFM_INCLUSIVE";
      const involvesFOFM = (startedFOFM && endedFOFM) || endedFOFM;
      if (involvesFOFM && changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodDuration) {
        return changeSnapshot.EmployeeClassPlan[id]?.waitingPeriodType;
      }
      return [];
    })
    .filter(rejectNullableValues);

  return employeeClassPlansWithRelevantChanges;
};

const dhmoDisabledMessage = [
  "Dental Prepaid benefits must have a waiting period of",
  " first of the following month.",
  " This can follow either their date of hire or a set amount of time.",
];

type EIFClassBuilderWaitingPeriodFormProps = {
  isActive: boolean;
  client: Client;
  employeeClassPlans: EmployeeClassPlanWithPlan[];
  employeeClass: EmployeeClass;
  activeBenefitTypes: BenefitTypeEIF[] | null;
  endOfSetWaitingPeriodsSection: boolean;
  setWaitingPeriodsQuery?: SetWaitingPeriodsQuery;
  isLoading: boolean;
  toggleIsSubmitting: () => void;
  onSave: (
    employeeClass: EmployeeClass,
    planIdsSetWaitingPeriods: PlanId[],
    planIdsRemovedWaitingPeriods: PlanId[],
  ) => void;
  location: Location;
  track?: SectionTracker;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export function EIFClassBuilderWaitingPeriodForm(props: EIFClassBuilderWaitingPeriodFormProps) {
  const {
    client,
    employeeClassPlans,
    activeBenefitTypes,
    endOfSetWaitingPeriodsSection,
    employeeClass,
    setWaitingPeriodsQuery,
    isLoading,
    toggleIsSubmitting,
    onSave,
    location,
    track,
    changeSnapshot,
    authUser,
  } = props;

  const coverages = activeBenefitTypes?.map((abt) => benefitTypeToCoverage[abt]) ?? [];
  const benefitTypeGroupsMap = getBenefitTypesGroupings(coverages);
  const benefitGroupsEntries = Array.from(benefitTypeGroupsMap.entries());
  assertIsDefined(benefitGroupsEntries[0], "benefitGroupsEntries[0]");

  const setWaitingPeriods = setWaitingPeriodsQuery
    ? setWaitingPeriodsQuery.mutateAsync
    : setWaitingPeriodsQuery;

  const employeeClassPlanIds = employeeClassPlans.map((ecp) => ecp.id);

  const singleBenefitType = benefitTypeGroupsMap.size === 1;

  const [classPlan] = employeeClassPlans;
  assertIsDefined(classPlan, "classPlan");

  const initialSelectedBenefitTypes = singleBenefitType
    ? [classPlan.plan.benefitType]
    : unique(
        employeeClassPlans
          .filter((employeeClassPlans) => employeeClassPlans.waitingPeriodType !== null)
          .map((employeeClassPlan) => employeeClassPlan.plan.benefitType),
      );

  const onlyDHMOBenefits = activeBenefitTypes?.every(getIsDHMOBenefitType);

  const formik = useSlobFormik({
    validationSchema,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      selectedBenefitTypes: initialSelectedBenefitTypes,
      waitingPeriodType: onlyDHMOBenefits ? "FOFM" : classPlan.waitingPeriodType,
      waitingPeriodDuration:
        classPlan.waitingPeriodType === "DAY_MONTH" && classPlan.waitingPeriodDuration
          ? classPlan.waitingPeriodDuration
          : undefined,
      waitingPeriodUnit:
        classPlan.waitingPeriodType === "DAY_MONTH" && classPlan.waitingPeriodUnit
          ? classPlan.waitingPeriodUnit
          : undefined,
      fofmAdditional:
        (classPlan.waitingPeriodType === "FOFM" ||
          classPlan.waitingPeriodType === "FOFM_INCLUSIVE") &&
        classPlan.waitingPeriodDuration &&
        classPlan.waitingPeriodDuration > 0
          ? true
          : false,
      fofmAdditionalDuration:
        (classPlan.waitingPeriodType === "FOFM" ||
          classPlan.waitingPeriodType === "FOFM_INCLUSIVE") &&
        classPlan.waitingPeriodDuration
          ? classPlan.waitingPeriodDuration
          : undefined,
      fofmAdditionalUnit:
        (classPlan.waitingPeriodType === "FOFM" ||
          classPlan.waitingPeriodType === "FOFM_INCLUSIVE") &&
        classPlan.waitingPeriodUnit
          ? classPlan.waitingPeriodUnit
          : undefined,
      fofmInclusive: classPlan.waitingPeriodType === "FOFM_INCLUSIVE",
    },
    onSubmit: async () => {
      if (!formik.values.waitingPeriodType) {
        formik.setErrors({ waitingPeriodType: "Please select a waiting period" });
        return;
      }
      if (!employeeClass || !employeeClassPlans) {
        void slobMessage.error("Error saving waiting periods, please refresh and try again");
        return;
      }
      const type =
        formik.values.waitingPeriodType === "FOFM" && formik.values.fofmInclusive
          ? "FOFM_INCLUSIVE"
          : formik.values.waitingPeriodType === "FOFM_INCLUSIVE" && !formik.values.fofmInclusive
          ? "FOFM"
          : formik.values.waitingPeriodType;
      const duration =
        formik.values.waitingPeriodType === "NONE"
          ? null
          : formik.values.waitingPeriodType === "DAY_MONTH"
          ? formik.values.waitingPeriodDuration
          : (formik.values.waitingPeriodType === "FOFM" ||
              formik.values.waitingPeriodType === "FOFM_INCLUSIVE") &&
            formik.values.fofmAdditionalDuration !== undefined &&
            formik.values.fofmAdditional
          ? formik.values.fofmAdditionalDuration
          : 0;
      const unit =
        formik.values.waitingPeriodType === "NONE"
          ? null
          : formik.values.waitingPeriodType === "DAY_MONTH"
          ? formik.values.waitingPeriodUnit
          : (formik.values.waitingPeriodType === "FOFM" ||
              formik.values.waitingPeriodType === "FOFM_INCLUSIVE") &&
            formik.values.fofmAdditionalUnit !== undefined &&
            formik.values.fofmAdditional
          ? formik.values.fofmAdditionalUnit
          : "DAY";

      const selectedBenefitTypes = formik.values.selectedBenefitTypes;
      const planIdsToSetWaitingPeriods = employeeClassPlans.reduce<EmployeeClassPlanId[]>(
        (acc, { plan }) => {
          if (selectedBenefitTypes.includes(plan.benefitType)) {
            acc.push(plan.id);
          }
          return acc;
        },
        [],
      );

      const notSelectedBenefitTypes = activeBenefitTypes?.filter(
        (benefitType) => !selectedBenefitTypes.includes(benefitType),
      );

      const planIdsToRemoveWaitingPeriods = notSelectedBenefitTypes
        ? employeeClassPlans.reduce<EmployeeClassPlanId[]>((acc, { plan }) => {
            if (notSelectedBenefitTypes.includes(plan.benefitType)) {
              acc.push(plan.id);
            }
            return acc;
          }, [])
        : [];

      try {
        toggleIsSubmitting();
        if (setWaitingPeriods) {
          const { isSuccess: isSuccessSettingWaitingPeriods, data } = await setWaitingPeriods({
            params: {
              clientId: client.id,
              employeeClassId: employeeClass.id,
            },
            data: {
              classId: employeeClass.id,
              planIds: planIdsToSetWaitingPeriods,
              waitingPeriodType: type,
              waitingPeriodDuration: duration,
              waitingPeriodUnit: unit,
            },
          });

          let isSuccessRemovingWaitingPeriods =
            planIdsToRemoveWaitingPeriods.length > 0 ? false : true;
          if (planIdsToRemoveWaitingPeriods.length > 0) {
            const { isSuccess } = await setWaitingPeriods({
              params: {
                clientId: client.id,
                employeeClassId: employeeClass.id,
              },
              data: {
                classId: employeeClass.id,
                planIds: planIdsToRemoveWaitingPeriods,
                waitingPeriodType: null,
                waitingPeriodDuration: null,
                waitingPeriodUnit: null,
              },
            });
            isSuccessRemovingWaitingPeriods = isSuccess;
          }

          if (isSuccessSettingWaitingPeriods && isSuccessRemovingWaitingPeriods) {
            if (track) {
              track("Save");
            }
            void slobMessage.success("Updated Waiting Periods");
            formik.resetForm();
            onSave(data.employeeClass, planIdsToSetWaitingPeriods, planIdsToRemoveWaitingPeriods);
          }
        }
      } finally {
        toggleIsSubmitting();
      }
    },
  });

  const selectedSlfCoverages =
    formik.values.selectedBenefitTypes?.map((bt) => benefitTypeToCoverage[bt]) ?? [];

  const dhmoSelected = formik.values.selectedBenefitTypes.some((benefitType) =>
    getIsDHMOBenefitType(benefitType),
  );

  const dhmoRestrictedSelectionMade =
    formik.values.waitingPeriodType === "DAY_MONTH" || formik.values.waitingPeriodType === "NONE";

  const relevantBenefits = dhmoRestrictedSelectionMade
    ? activeBenefitTypes?.filter((benefitType) => !getIsDHMOBenefitType(benefitType))
    : activeBenefitTypes;

  const allBenefitsSelected =
    relevantBenefits && formik.values.selectedBenefitTypes.length === relevantBenefits?.length;

  const fofmLabel = () => {
    return (
      <>
        <Body2 as="div">First of the following month</Body2>
        <StackY dist={16}>
          <Eyebrow>
            Employees will be eligible for benefits on the first of the month following either their
            date of hire, or a set amount of time after their date of hire.
          </Eyebrow>
          <EditedFieldMsg
            changeDetailInfoList={[
              ...relevantChangesForWaitingPeriodType(employeeClassPlanIds, changeSnapshot, "FOFM"),
              ...relevantChangesForWaitingPeriodType(
                employeeClassPlanIds,
                changeSnapshot,
                "FOFM_INCLUSIVE",
              ),
            ]}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "waitingPeriodTypeFOFM",
              client,
              formik,
            })}
          />
        </StackY>
      </>
    );
  };

  const fofmContent = () => {
    return (
      <>
        {(formik.values.waitingPeriodType === "FOFM" ||
          formik.values.waitingPeriodType === "FOFM_INCLUSIVE" ||
          onlyDHMOBenefits) && (
          <StackY dist={16} className={onlyDHMOBenefits ? "mb-8" : "ml-32 mb-8"}>
            {(formik.values.waitingPeriodType === "FOFM" ||
              formik.values.waitingPeriodType === "FOFM_INCLUSIVE" ||
              onlyDHMOBenefits) && (
              <Checkbox
                id="fofmAdditional"
                name="fofmAdditional"
                label="Set additional time"
                checked={!!formik.values.fofmAdditional}
                onChange={formik.handleChange}
                disabled={isLoading}
                content={
                  <StackY dist={16}>
                    <Eyebrow>
                      Add an additional number of days or months from the date of hire. For example,
                      if an employee is hired on March 15th, and you add an additional 60 days, then
                      that employee will wait until May 15th to satisfy the 60 days and benefits
                      will begin on June 1st.
                    </Eyebrow>
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        ...relevantFOFMAdditionalChanges(employeeClassPlanIds, changeSnapshot),
                        ...relevantChangesForWaitingPeriodFields(
                          employeeClassPlanIds,
                          changeSnapshot,
                          ["waitingPeriodUnit", "waitingPeriodDuration"],
                        ),
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "fofmAdditional",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                }
              />
            )}

            {(formik.values.waitingPeriodType === "FOFM" ||
              formik.values.waitingPeriodType === "FOFM_INCLUSIVE" ||
              onlyDHMOBenefits) &&
              formik.values.fofmAdditional === true && (
                <StackX
                  dist={8}
                  className={clsx(
                    styles.dayOrMonthInputContainer,
                    onlyDHMOBenefits ? "mb-24" : "ml-32 mb-24",
                  )}
                >
                  <div className={styles.durationContainer}>
                    <FormInput
                      name="fofmAdditionalDuration"
                      maxLength={3}
                      label="Duration"
                      touched={formik.touched.fofmAdditionalDuration}
                      disabled={isLoading}
                      value={formik.values.fofmAdditionalDuration}
                      onChange={formik.handleChange}
                      error={formik.errors.fofmAdditionalDuration}
                    />
                  </div>
                  <div className={styles.timePeriodContainer}>
                    <SlobSelect<{ label: string; value: WaitingPeriodUnit | "" }>
                      name="fofmAdditionalUnit"
                      placeholder="Time period"
                      disabled={isLoading}
                      loading={isLoading}
                      touched={formik.touched.fofmAdditionalUnit}
                      showRequired={true}
                      error={formik.errors.fofmAdditionalUnit}
                      options={[
                        { value: "DAY", label: "Days" },
                        { value: "MONTH", label: "Months" },
                      ]}
                      value={formik.values.fofmAdditionalUnit}
                      onChange={async (selection) => {
                        await formik.setValues({
                          ...formik.values,
                          fofmAdditionalUnit: selection.value === "" ? undefined : selection.value,
                        });
                      }}
                    />
                  </div>
                </StackX>
              )}

            {(formik.values.waitingPeriodType === "FOFM" ||
              formik.values.waitingPeriodType === "FOFM_INCLUSIVE" ||
              onlyDHMOBenefits) && (
              <Checkbox
                id="fofmInclusive"
                name="fofmInclusive"
                label="Include first day of the month"
                checked={!!formik.values.fofmInclusive}
                disabled={isLoading}
                onChange={formik.handleChange}
                content={
                  <StackY dist={16}>
                    <Eyebrow>
                      If an employee's date of hire or the completion of additional time coincides
                      with the first of the month, benefits go into effect that same day.
                    </Eyebrow>
                    <EditedFieldMsg
                      changeDetailInfoList={relevantChangesForWaitingPeriodType(
                        employeeClassPlanIds,
                        changeSnapshot,
                        "FOFM_INCLUSIVE",
                      )}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "fofmInclusive",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                }
              />
            )}
          </StackY>
        )}
      </>
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32}>
          <div>
            {singleBenefitType ? (
              <>
                <Body2 as="p">This waiting period applies to:</Body2>
                <Body3>{getCombinedCoverageName(benefitGroupsEntries[0])}</Body3>
              </>
            ) : (
              <>
                <Body2 as="p">Which benefit type is this Waiting Period selection for?</Body2>

                <StackY dist={16} wrap={false}>
                  <Button
                    type="text-only"
                    size="middle"
                    onClick={async () => {
                      if (allBenefitsSelected) {
                        await formik.setFieldValue("selectedBenefitTypes", []);
                      } else {
                        await formik.setFieldValue("selectedBenefitTypes", relevantBenefits);
                      }
                    }}
                  >
                    {allBenefitsSelected ? "Deselect All" : "Select All"}
                  </Button>

                  <EIFBenefitsChipGroup
                    slfCoverages={coverages}
                    name="selectedBenefitTypes"
                    selectedSlfCoverages={selectedSlfCoverages}
                    touched={formik.touched.selectedBenefitTypes}
                    error={formik.errors.selectedBenefitTypes}
                    disabled={isLoading}
                    setFieldValue={async (name, value) => {
                      if (Array.isArray(value)) {
                        const benefitTypes = value
                          .filter(getIsSlfCoverageLongName)
                          .map((cov) => coverageToBenefitType[cov])
                          .sort();
                        await formik.setFieldValue(name, benefitTypes);
                      }
                    }}
                    getOptionProps={({ label, value }) => {
                      const disabled = value.includes("DHMO") && dhmoRestrictedSelectionMade;
                      return {
                        label,
                        value,
                        disabled,
                        disabledReason: dhmoDisabledMessage.join(""),
                      };
                    }}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "selectedBenefitTypes",
                      client,
                      formik,
                    })}
                  />
                </StackY>
              </>
            )}
          </div>
          <div>
            {onlyDHMOBenefits ? (
              <>
                <StackY dist={8}>
                  <Body2 as="div">
                    What is the waiting period for this eligible employee group?
                  </Body2>
                  <Body3>
                    {dhmoDisabledMessage[0]}
                    <Body2>{dhmoDisabledMessage[1]}</Body2>
                    {dhmoDisabledMessage[2]}
                  </Body3>
                  {fofmLabel()}
                </StackY>
                {fofmContent()}
              </>
            ) : (
              <RadioGroup
                name="waitingPeriodType"
                label={
                  <>
                    <Body2 as="div">
                      What is the waiting period for this eligible employee group?
                    </Body2>
                    {dhmoSelected && (
                      <div className="mb-8 mt-8">
                        <AlertBanner
                          variant="info"
                          message={
                            <Body3>
                              {dhmoDisabledMessage[0]}
                              <Body2>{dhmoDisabledMessage[1]}</Body2>
                              {dhmoDisabledMessage[2]}
                            </Body3>
                          }
                        />
                      </div>
                    )}
                    <Eyebrow>
                      An eligibility waiting period is the amount of time required after employees
                      are hired before they are eligible for benefits.
                    </Eyebrow>
                  </>
                }
                disabled={isLoading}
                error={formik.errors.waitingPeriodType}
                touched={formik.touched.waitingPeriodType}
                options={[
                  {
                    value: "DAY_MONTH",
                    label: (
                      <>
                        <Body2 as="div">Days or months</Body2>
                        <StackY dist={16}>
                          <Eyebrow>
                            Employees will be eligible for benefits following a set amount of time
                            after their date of hire.
                          </Eyebrow>
                          <EditedFieldMsg
                            changeDetailInfoList={relevantChangesForWaitingPeriodType(
                              employeeClassPlanIds,
                              changeSnapshot,
                              "DAY_MONTH",
                            )}
                            client={client}
                            authUser={authUser}
                            hasPendingEdit={getHasPendingEdit({
                              field: "waitingPeriodTypeDAY_MONTH",
                              client,
                              formik,
                            })}
                          />
                        </StackY>
                      </>
                    ),
                    content: (
                      <>
                        {formik.values.waitingPeriodType === "DAY_MONTH" && (
                          <div className="ml-32 mb-8">
                            <StackX dist={8} className={styles.dayOrMonthInputContainer}>
                              <div className={styles.durationContainer}>
                                <FormInput
                                  name="waitingPeriodDuration"
                                  maxLength={3}
                                  label="Duration"
                                  touched={formik.touched.waitingPeriodDuration}
                                  disabled={isLoading}
                                  error={formik.errors.waitingPeriodDuration}
                                  value={formik.values.waitingPeriodDuration}
                                  onChange={formik.handleChange}
                                />
                              </div>
                              <div className={styles.timePeriodContainer}>
                                <SlobSelect<{ label: string; value: WaitingPeriodUnit | "" }>
                                  name="waitingPeriodUnit"
                                  placeholder="Time period"
                                  disabled={isLoading}
                                  loading={isLoading}
                                  touched={formik.touched.waitingPeriodUnit}
                                  showRequired={true}
                                  error={formik.errors.waitingPeriodUnit}
                                  options={[
                                    { value: "DAY", label: "Days" },
                                    { value: "MONTH", label: "Months" },
                                  ]}
                                  value={formik.values.waitingPeriodUnit}
                                  onChange={async (selection) => {
                                    await formik.setValues({
                                      ...formik.values,
                                      waitingPeriodUnit:
                                        selection.value === "" ? undefined : selection.value,
                                    });
                                  }}
                                />
                              </div>
                            </StackX>
                          </div>
                        )}
                        {formik.values.waitingPeriodType === "DAY_MONTH" && (
                          <div className="ml-32">
                            <EditedFieldMsg
                              changeDetailInfoList={relevantChangesForWaitingPeriodFields(
                                employeeClassPlanIds,
                                changeSnapshot,
                                ["waitingPeriodUnit", "waitingPeriodDuration"],
                              )}
                              client={client}
                              authUser={authUser}
                              hasPendingEdit={
                                getHasPendingEdit({
                                  field: "waitingPeriodUnit",
                                  client,
                                  formik,
                                }) ||
                                getHasPendingEdit({
                                  field: "waitingPeriodDuration",
                                  client,
                                  formik,
                                })
                              }
                            />
                          </div>
                        )}
                      </>
                    ),
                    disabled: dhmoSelected,
                  },
                  {
                    value: "FOFM",
                    label: fofmLabel(),
                    content: fofmContent(),
                  },
                  {
                    value: "NONE",
                    label: (
                      <>
                        <Body2 as="div">No waiting period</Body2>
                        <StackY dist={16}>
                          <Eyebrow>
                            Employees will be eligible for benefits immediately on their date of
                            hire.
                          </Eyebrow>
                          <EditedFieldMsg
                            changeDetailInfoList={relevantChangesForWaitingPeriodType(
                              employeeClassPlanIds,
                              changeSnapshot,
                              "NONE",
                            )}
                            client={client}
                            authUser={authUser}
                            hasPendingEdit={getHasPendingEdit({
                              field: "waitingPeriodTypeNONE",
                              client,
                              formik,
                            })}
                          />
                        </StackY>
                      </>
                    ),
                    disabled: dhmoSelected,
                  },
                ]}
                direction="vertical"
                value={
                  formik.values.waitingPeriodType === "FOFM_INCLUSIVE"
                    ? "FOFM"
                    : formik.values.waitingPeriodType
                }
                onChange={formik.handleChange}
              />
            )}
          </div>
          {Object.keys(formik.errors).length > 0 && (
            <div className="mb-24">
              <Row justify="end">
                <InputErrorMessage
                  error={"Please provide a waiting period for the following benefits"}
                />
              </Row>
            </div>
          )}
          <Row justify="end">
            <AlertBanner
              variant="warning"
              message={
                <Body3>
                  Please make sure that the waiting periods you configure for your benefits match
                  the waiting periods in your own company's benefits administration system.
                </Body3>
              }
            />
          </Row>
          <Row justify="end">
            <Col>
              <Row align="middle" gutter={28}>
                <Col>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={location.pathname}
                    disabled={isLoading}
                    onClick={() => flushSync(formik.resetForm)}
                  >
                    Cancel
                  </InternalLinkButton>
                </Col>

                <Col>
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={isLoading}
                    disabled={isLoading}
                    size="middle"
                  >
                    {endOfSetWaitingPeriodsSection ? "Save & Continue" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </StackY>
      </form>

      {setWaitingPeriodsQuery && <AutoSaveOnNavigation formik={formik} optimistic />}
    </>
  );
}
