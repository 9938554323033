import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Button } from "../../components/Button/Button";

type EIFBottomNavButtonsProps = {
  previousLink: string | null;
  onSubmitClick?: React.MouseEventHandler<HTMLElement>;
  previousButtonDisabled: boolean;
  nextLink?: string;
  nextButtonDisabled: boolean;
  formId?: string;
  submitChanges?: boolean;
};

export const EIFBottomNavButtons = ({
  previousLink,
  onSubmitClick,
  previousButtonDisabled,
  nextLink,
  nextButtonDisabled,
  formId,
  submitChanges = true,
}: EIFBottomNavButtonsProps) => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        {previousLink && (
          <Button
            to={previousLink}
            type="text-only"
            size="middle"
            disabled={previousButtonDisabled}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
            Previous
          </Button>
        )}
      </Col>

      <Col>
        {nextLink ? (
          <Button to={nextLink} type="primary" size="large" disabled={nextButtonDisabled}>
            Next
          </Button>
        ) : (
          <Button
            htmlType={submitChanges ? "submit" : "button"}
            type="primary"
            size="large"
            form={formId}
            onClick={onSubmitClick}
            disabled={nextButtonDisabled}
          >
            Next
          </Button>
        )}
      </Col>
    </Row>
  );
};
