import { assertIsDefined } from "./utils";

import type { User } from "../types/User";

export type TouchableEntity = {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  createdByUser?: User | null;
  updatedByUser?: User | null;
  deletedByUser?: User | null;
};

type LastTouched<T> = {
  lastTouchedByUser: User;
  lastTouchedAt: Date;
  lastTouchedType: "created" | "updated" | "deleted";
  lastTouchedEntity: T;
};

export function getLastTouched<T extends TouchableEntity>(entity: T): LastTouched<T> {
  if (
    entity.deletedAt &&
    entity.deletedAt > entity.createdAt &&
    entity.deletedAt > entity.updatedAt
  ) {
    assertIsDefined(entity.deletedByUser, "entity.deletedByUser");
    return {
      lastTouchedByUser: entity.deletedByUser,
      lastTouchedAt: entity.deletedAt,
      lastTouchedType: "deleted",
      lastTouchedEntity: entity,
    };
  }

  if (entity.updatedAt > entity.createdAt) {
    assertIsDefined(entity.updatedByUser, "entity.updatedByUser");
    return {
      lastTouchedByUser: entity.updatedByUser,
      lastTouchedAt: entity.updatedAt,
      lastTouchedType: "updated",
      lastTouchedEntity: entity,
    };
  }

  assertIsDefined(entity.createdByUser, "entity.createdByUser");
  return {
    lastTouchedByUser: entity.createdByUser,
    lastTouchedAt: entity.createdAt,
    lastTouchedType: "created",
    lastTouchedEntity: entity,
  };
}
