import { Button as AntButton } from "antd";
import clsx from "clsx";
import React from "react";
import type { ButtonType } from "antd/lib/button";

declare const ButtonHTMLTypes: ["submit", "button", "reset"];
export declare type ButtonHTMLType = (typeof ButtonHTMLTypes)[number];
export type Size = "xtra-small" | "small" | "middle" | "large" | undefined;

export type Type =
  | "primary"
  | "secondary"
  | "tertiary"
  | "default"
  | "link"
  | "link-inline"
  | "link-inline-bold-deprecated"
  | undefined;

export interface BaseButtonProps {
  type?: Type;
  icon?: React.ReactNode;
  size?: Size;
  shrink?: boolean;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  prefixCls?: string;
  className?: string;
  ghost?: boolean;
  danger?: boolean;
  block?: boolean;
  destructive?: boolean;
  pointerEvents?: "none";
  rounded?: boolean;
  children?: React.ReactNode;
}
export declare type AnchorButtonProps = {
  href: string;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
  Omit<React.AnchorHTMLAttributes<unknown>, "type" | "onClick">;

export declare type NativeButtonProps = {
  htmlType?: ButtonHTMLType;
  onClick?: React.MouseEventHandler<HTMLElement>;
} & BaseButtonProps &
  Omit<React.ButtonHTMLAttributes<unknown>, "type" | "onClick">;

export declare type ButtonProps = Partial<AnchorButtonProps & NativeButtonProps>;

/**
 * @deprecated Use just in case of new Button is not enough and new Link component is not in place yet. Types "link-inline" and "link-inline-bold" are migrating from Button to a new <Link> component
 */
export const ButtonOld = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLElement>) => {
    const {
      type,
      size,
      shrink,
      className,
      children,
      destructive,
      pointerEvents,
      rounded,
      disabled,
      onClick,
      ...antProps
    } = props;

    const handleOnClick: React.MouseEventHandler<HTMLElement> = (e) => {
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      onClick?.(e);
    };

    const buttonType = getButtonType(type);

    if (type === "link" || type === "link-inline" || type === "link-inline-bold-deprecated") {
      return (
        <AntButton
          ref={ref}
          aria-disabled={disabled}
          onClick={handleOnClick}
          type={buttonType}
          className={clsx(
            handleType("link"),
            className,
            handleSize(size),
            type === "link-inline" && "link-inline",
            type === "link-inline-bold-deprecated" && "link-inline-bold",
            destructive && "destructive",
            pointerEvents === "none" && "pointerEventsNone",
          )}
          {...antProps}
        >
          <span className={clsx("ant-btn-link", "ant-btn-content", destructive && "destructive")}>
            {children}
          </span>

          {antProps.target === "_blank" && <span className="sr-only">(opens in a new tab)</span>}
        </AntButton>
      );
    }

    return (
      <AntButton
        ref={ref}
        aria-disabled={disabled}
        onClick={handleOnClick}
        type={buttonType}
        className={clsx(
          handleType(type),
          className,
          handleSize(size),
          shrink && "shrink",
          pointerEvents === "none" && "pointerEventsNone",
          { rounded },
        )}
        {...antProps}
      >
        <span className={clsx(`${antProps.href ? "ant-btn-href" : ""}`, "ant-btn-content")}>
          {children}
        </span>
      </AntButton>
    );
  },
);

export const handleType = (type: Type) => {
  if (!type || type === "tertiary") {
    return "ant-btn-default";
  }
  return `ant-btn-${type}`;
};

export const getButtonType = (type: Type): ButtonType | undefined => {
  const record: Record<NonNullable<Type>, ButtonType | undefined> = {
    primary: "primary",
    secondary: "text",
    tertiary: "default",
    default: "default",
    link: "link",
    "link-inline": "link",
    "link-inline-bold-deprecated": "link",
  };
  if (type) {
    const result = record[type];
    return result;
  }
};

export const handleSize = (size: Size) => {
  switch (size) {
    case "large":
      return "ant-btn-lg";
    case "small":
      return "ant-btn-sm";
    case "xtra-small":
      return "ant-btn-xs";
    case "middle":
      return "";
    default:
      return "ant-btn-sm";
  }
};
