import useLocalStorage, { writeStorage } from "@rehooks/local-storage";
import { createContext, useContext, useCallback, useEffect } from "react";
import { isSunlifeCarrier } from "../../../shared/types/ExplorerPageBenefit";
import type { ClientTableFilters } from "../../../shared/types/Client";
import type { ExplorerPageId } from "../../../shared/types/ExplorerPage";
import type { ExplorerPageBenefit } from "../../../shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "../../../shared/types/Location";
import type { FunctionComponent } from "react";

const defaultLocalStorageItemContextValue: {
  favPlans: Record<string, string[]>;
  employeePrepaidAndChatLocation: LocationStateCode | null;
  clientTableFilters: Record<string, ClientTableFilters | null>;
} = {
  favPlans: {},
  employeePrepaidAndChatLocation: null,
  clientTableFilters: {},
};

const LOCAL_STORAGE_FAVORITE_PLANS_KEY = "favorite-plans";
const LOCAL_STORAGE_PREPAID_LOCATION_KEY = "prepaid-location";
const LOCAL_STORAGE_CLIENT_TABLE_FILTERS_KEY = "client-table-filters";

export const LocalStorageContext = createContext(defaultLocalStorageItemContextValue);

export const LocalStorageProvider: FunctionComponent<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [localStorageFavPlans] = useLocalStorage(
    LOCAL_STORAGE_FAVORITE_PLANS_KEY,
    defaultLocalStorageItemContextValue.favPlans,
  );
  const [localStoragePrepaidLocation] = useLocalStorage<LocationStateCode | null>(
    LOCAL_STORAGE_PREPAID_LOCATION_KEY,
    defaultLocalStorageItemContextValue.employeePrepaidAndChatLocation,
  );
  const [localStorageClientTableFilters] = useLocalStorage<
    Record<string, ClientTableFilters | null>
  >(LOCAL_STORAGE_CLIENT_TABLE_FILTERS_KEY, defaultLocalStorageItemContextValue.clientTableFilters);

  return (
    <LocalStorageContext.Provider
      value={{
        favPlans: localStorageFavPlans,
        employeePrepaidAndChatLocation: localStoragePrepaidLocation,
        clientTableFilters: localStorageClientTableFilters,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export const useLocalStorageClientTableFilters = (
  userId: string | undefined,
): [ClientTableFilters | null, (filters: ClientTableFilters | null) => void] => {
  const { clientTableFilters } = useContext(LocalStorageContext);

  const writeFilters = useCallback(
    (filters: ClientTableFilters | null) => {
      if (!userId) return;
      writeStorage(LOCAL_STORAGE_CLIENT_TABLE_FILTERS_KEY, {
        ...clientTableFilters,
        [userId]: filters,
      });
    },
    [clientTableFilters, userId],
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo
  return [userId ? clientTableFilters[userId]! : null, writeFilters];
};

export const useFavoritePlans = (
  explorerPageId: ExplorerPageId,
  availablePlans?: ExplorerPageBenefit[],
): [string[], (plans: string[]) => void] => {
  const { favPlans } = useContext(LocalStorageContext);

  const writePlans = useCallback(
    (plans: string[]) =>
      writeStorage(LOCAL_STORAGE_FAVORITE_PLANS_KEY, { ...favPlans, [explorerPageId]: plans }),
    [explorerPageId, favPlans],
  );

  useEffect(() => {
    if (availablePlans?.length) {
      //we want to clean up this list in case some benefits were removed from the page
      const saved = favPlans[explorerPageId];
      if (saved) {
        const cleaned = saved.filter((savedID) =>
          availablePlans.map((p) => p.id).includes(savedID),
        );
        if (saved.length !== cleaned.length) writePlans(cleaned);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't run this every time fav plans change
  }, [availablePlans, explorerPageId]);

  return [favPlans[explorerPageId] ?? [], writePlans];
};

export const useEmployeeLocation = (): [
  LocationStateCode | null,
  (location: LocationStateCode | null) => void,
] => {
  const { employeePrepaidAndChatLocation } = useContext(LocalStorageContext);
  return [
    employeePrepaidAndChatLocation,
    (location: LocationStateCode | null) =>
      writeStorage(LOCAL_STORAGE_PREPAID_LOCATION_KEY, location),
  ];
};

export const useFavoritePlansByLocation = (
  favoritePlans: string[],
  dhmoBenefitData: ExplorerPageBenefit[] | undefined,
  prepaidLocation: LocationStateCode | null,
): string[] => {
  const filteredFavPlansByLocation =
    favoritePlans.reduce<string[]>((acc, val) => {
      const mismatchDHMOBenefitData = dhmoBenefitData?.find(
        (d) => d.prepaidSitusState !== prepaidLocation,
      );
      if (mismatchDHMOBenefitData?.id === val) {
        return acc;
      }
      acc.push(val);
      return acc;
    }, []) ?? [];
  return filteredFavPlansByLocation;
};

export const useDHMOByLocation = (
  prepaidLocation: LocationStateCode | null,
  dhmoBenefitData: ExplorerPageBenefit[] | undefined,
): boolean => {
  const newMexicoDHMOData =
    dhmoBenefitData?.filter(
      (dhmoBen) =>
        dhmoBen.prepaidSitusState === prepaidLocation &&
        prepaidLocation === "NM" &&
        isSunlifeCarrier(dhmoBen.carrierId),
    ) ?? null;
  if (newMexicoDHMOData) {
    return newMexicoDHMOData.length > 0 ? true : false;
  }
  return false;
};
