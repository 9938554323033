import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { SwitchToMultipleEmployeeGroupsModal } from "client/src/domain/EIF/PlanConfigAndEligibility/SwitchToMultipleEmployeeGroupsModal";
import { useToggler } from "client/src/hooks/useToggler";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Client } from "shared/types/Client";

type MultipleClassInformationBoxProps = {
  client: Client;
  updateClient: UpdateClientFunc;
};

export const MultipleClassInformationBox = ({
  client,
  updateClient,
}: MultipleClassInformationBoxProps) => {
  const [isVisibleMultipleGroupModal, toggleIsVisibleMultipleGroupModal] = useToggler();

  if (client.deifChangesReadyForReviewAt) {
    return null;
  }

  return (
    <>
      <BulbMessage bulbPosition="top">
        <StackY dist={8}>
          <Body3 as="div">
            Turns out you need more than one employee group? <br /> Switch to multiple groups and
            we'll copy over everything you've entered so far.
          </Body3>
          <Button type="text-only" onClick={toggleIsVisibleMultipleGroupModal} size="middle">
            Switch to multiple groups
          </Button>
        </StackY>
      </BulbMessage>

      <SwitchToMultipleEmployeeGroupsModal
        isVisibleMultipleGroupModal={isVisibleMultipleGroupModal}
        onCancel={toggleIsVisibleMultipleGroupModal}
        client={client}
        updateClient={updateClient}
      />
    </>
  );
};
