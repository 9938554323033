import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { StackY } from "client/src/components/Spacing/Spacing";

import { Body2, Body4, Body5 } from "client/src/components/Typography/Typography";
import * as pfmlSettingsStyles from "client/src/pages/Clients/SetupCards/pfmlSettings.module.less";
import * as setupCardStyles from "client/src/pages/Clients/SetupCards/setupCards.module.less";
import { Fragment, type ComponentProps } from "react";
import {
  pfmlASOCoverageToStateLabel,
  pfmlOptionPerksMap,
  pfmlOptions,
  pfmlOptionsRecord,
} from "shared/types/Plan";
import { coverageToBenefitType, getIsPFMLASOCoverage } from "shared/types/SlfCoverages";
import { ReactComponent as CancelCircle } from "../../../components/Icons/CancelCircle.svg";
import type { RadioChangeEvent } from "antd";
import type { Checkbox } from "client/src/components/Form/Checkbox";
import type { Client } from "shared/types/Client";
import type { PFMLOptions } from "shared/types/Plan";

type CheckboxProps = ComponentProps<typeof Checkbox>;

type Props = {
  client: Client;
  value: Record<string | number, PFMLOptions | undefined | null> | null;
  error: Record<string, string | string[]> | null;
  touched: Record<string, boolean> | null;
} & Pick<CheckboxProps, "disabled" | "onChange"> & { onChange: (e: RadioChangeEvent) => void };

export function PFMLSettings(props: Props) {
  const { client, disabled, error, value, touched, onChange } = props;

  const clientPFMLASOCoverages = client.allPoliciesSlfCoverages?.filter(getIsPFMLASOCoverage) ?? [];

  if (clientPFMLASOCoverages.length === 0) {
    return null;
  }

  return (
    <div>
      <Body2 as="p">PFML</Body2>

      <p>
        Select the type of PFML plan for each state on the policy. Based on your selection, benefits
        administrators and brokers will see the corresponding questions for contributions, claim
        checks, monthly claim reports, and EOBs.
      </p>

      <StackY dist={16} wrap={false}>
        {clientPFMLASOCoverages.map((coverage) => {
          const pfmlASOBenefitType = coverageToBenefitType[coverage];
          const radioGroupTouched = touched ? touched[pfmlASOBenefitType] : undefined;
          const radioGroupError = error ? error[pfmlASOBenefitType] : undefined;
          const selectedValue = value ? value[pfmlASOBenefitType] ?? null : null;
          const name = `planPFMLSelection.${pfmlASOBenefitType}`;

          return (
            <Fragment key={name}>
              <RadioGroup<PFMLOptions>
                label={<Body4>{pfmlASOCoverageToStateLabel[coverage]}</Body4>}
                name={name}
                direction="horizontal"
                value={selectedValue}
                horizontalLabel
                disabled={Boolean(disabled)}
                touched={radioGroupTouched}
                error={Array.isArray(radioGroupError) ? radioGroupError[0] : radioGroupError}
                onChange={onChange}
                options={pfmlOptions.map((pfmlOptionValue) => ({
                  label: pfmlOptionsRecord[pfmlOptionValue],
                  value: pfmlOptionValue,
                }))}
              />
              <PFMLOptionDescription pfmlOption={selectedValue} />
            </Fragment>
          );
        })}
      </StackY>
    </div>
  );
}

type PFMLOptionDescriptionProps = {
  pfmlOption: PFMLOptions | null;
};

export const PFMLOptionDescription = ({ pfmlOption }: PFMLOptionDescriptionProps) => {
  if (!pfmlOption) {
    return null;
  }

  const successIcon = (
    <span className={pfmlSettingsStyles.successIcon}>
      <FontAwesomeIcon icon={faCheckCircle} />
    </span>
  );
  const errorIcon = (
    <span className={pfmlSettingsStyles.errorIcon}>
      <CancelCircle />
    </span>
  );

  return (
    <StackY dist={8} className={setupCardStyles.descriptionContainer}>
      <div>
        {pfmlOptionPerksMap[pfmlOption].includes("CONTRIBUTIONS") ? successIcon : errorIcon}{" "}
        <Body5>Contributions</Body5>
      </div>
      <div>
        {pfmlOptionPerksMap[pfmlOption].includes("CLAIM_CHECK_MAILING_LOCATIONS")
          ? successIcon
          : errorIcon}{" "}
        <Body5>Claim check mailing locations</Body5>
      </div>
      <div>
        {pfmlOptionPerksMap[pfmlOption].includes("MONTHLY_CLAIMS_REPORTS_AND_EOBS")
          ? successIcon
          : errorIcon}{" "}
        <Body5>Monthly claims reports and EOBs</Body5>
      </div>
    </StackY>
  );
};
