import { EyeOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { RecipientChangedToOutsideSignerModal } from "client/src/domain/OnboardingForm/Modal/RecipientChagedToOutsideSignerModal";
import clsx from "clsx";
import { DateTime } from "luxon";
import React, { useState, useCallback } from "react";

import { getIsInternalUser, getIsOwnerUser } from "shared/rbac/rbac";
import { Anchor } from "../../components/Anchor/Anchor";
import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { GuidanceModal } from "../../components/GuidanceModal/GuidanceModal";
import { HorizontalDivider } from "../../components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { ReactComponent as PencilIcon } from "../../components/Icons/PencilIcon.svg";
import { InternalControl } from "../../components/InternalControl/InternalControl";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { StackY } from "../../components/Spacing/Spacing";
import {
  useOnboardingFormDownloader,
  useFillFormProgressBar,
  useSyncOnboardingForm,
  useOnboardingFormIndividualDocumentDownload,
  useGetOnboardingFormLockStatus,
  useCreateRecipientEmbeddedView,
} from "../../hooks/onboardingForms";

import { AddRecipientModal } from "./Modal/AddRecipientModal";
import { DeleteOnboardingFormModal } from "./Modal/DeleteOnboardingFormModal";
import { MultipleRecipientModal } from "./Modal/MultipleRecipientModal";
import { ReassignRecipientModal } from "./Modal/ReassignRecipientModal";
import { SyncOnboardingFormModal } from "./Modal/SyncOnboardingFormModal";
import { UpdateSignerTypeModal } from "./Modal/UpdateSignerTypeModal";
import { OnboardingFormActionButton } from "./OnboardingFormActionButton";
import { RecipientSummary } from "./RecipientSummary";
import * as styles from "./onboardingFormTile.module.less";
import {
  getLastActionRecipientValues,
  getOnboardingFormBadgeMessage,
  getVoidedActionValues,
} from "./utils/onboardingFormUtils";
import type { UserRole } from "../../../../shared/rbac/roles";

import type { RecipientWithMetadata } from "../../../../shared/types/OnboardingFormRecipient";
import type { ElementClickedOptions } from "../../utils/analytics";
import type { SetStateAction, Dispatch } from "react";
import type { Client } from "shared/types/Client";
import type {
  OnboardingFormWithMetadata,
  MetadataStatus,
  VoidedFormInfo,
} from "shared/types/OnboardingForm";

const envelopeStatusToBadge = (status: MetadataStatus) => {
  switch (status) {
    case "nigo":
    case "voided":
    case "declined":
      return "error";
    case "completed":
      return "success";
    case "in-review":
      return "warning";
    default:
      return "info";
  }
};

const envelopeStatusToBadgeLabel = (status: MetadataStatus) => {
  switch (status) {
    case "in-progress":
      return "IN PROGRESS";
    case "in-review":
      return "IN REVIEW";
    case "completed":
      return "COMPLETED";
    case "nigo":
      return "ACTION REQUIRED";
    case "declined":
      return "DECLINED";
    case "voided":
      return "VOIDED";
    default:
      return "NOT STARTED";
  }
};

const getFootnoteMsg = (status: MetadataStatus): React.ReactNode => {
  switch (status) {
    case "in-review":
    case "completed":
      return (
        <span>
          If you are having trouble downloading the form, chat with us or email{" "}
          <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>
        </span>
      );
    case "not-started":
    case "draft":
    case "in-progress":
      return (
        <span>
          If you are having trouble viewing the form or need to add another recipient, chat with us
          or email <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>
        </span>
      );
    default:
      return null;
  }
};

const getInstructionMsg = (status: MetadataStatus): string | null => {
  switch (status) {
    case "in-review":
    case "completed":
      return "Click the 'Download' button below to download a completed copy of your form.";
    case "not-started":
    case "in-progress":
      return "When you click the button below, you will be taken through to your DocuSign experience to complete your form. You'll be able to track you and your team's progress through the form below.";
    default:
      return null;
  }
};

const checkHasWebhookError = (waitingForWebhook: Date | null): boolean => {
  if (!waitingForWebhook) {
    return false;
  }

  const luxonInstance = DateTime.fromJSDate(waitingForWebhook, { zone: "UTC" });
  const diff = DateTime.now().diff(luxonInstance, "minutes");
  // more than 5 minutes
  return diff.minutes > 5;
};

// Show the guidance modal only if the task was recently completed.
const userRecentlySigned = (currentRecipient: RecipientWithMetadata | undefined) => {
  if (
    currentRecipient?.metadata.lastAction.action !== "completed" ||
    !currentRecipient?.metadata.lastAction.date
  )
    return false;
  const signedDate = DateTime.fromJSDate(currentRecipient?.metadata.lastAction.date, {
    zone: "UTC",
  });
  const diff = DateTime.now().diff(signedDate, "minutes");
  return diff.minutes < 2;
};

type OnboardingFormTileProps = {
  title: string;
  client: Client;
  currentUserEmail: string;
  currentUserName: string;
  userRole: UserRole;
  onboardingForm: OnboardingFormWithMetadata;
  isInternalUser: boolean;
  showAddRecipientButton?: boolean;
  refetchOnboardingForms: () => unknown;
  trackElementClicked: (options: ElementClickedOptions) => void;
  isFinalDocumentForUser?: boolean;
  setIsFinalDocumentForUser?: Dispatch<SetStateAction<boolean>>;
};

export const OnboardingFormTile: React.FC<OnboardingFormTileProps> = ({
  title,
  client: { id: clientId, NIGOReasons },
  currentUserEmail,
  currentUserName,
  onboardingForm,
  isInternalUser,
  userRole,
  showAddRecipientButton,
  refetchOnboardingForms,
  trackElementClicked,
  isFinalDocumentForUser,
  setIsFinalDocumentForUser,
}) => {
  const [showMultipleRecipientModal, setShowMultipleRecipientModal] = useState<boolean>(false);
  const [showRecipientChangedToOutsideSignerModal, setShowRecipientChangedToOutsideSignerModal] =
    useState<boolean>(false);

  const [showInternalEditPopover, setShowInternalEditPopover] = useState<boolean>(false);
  const { downloadDocument, errorLoadingDocument, isDownloading } = useOnboardingFormDownloader(
    clientId,
    onboardingForm.id,
  );

  const { downloadIndividualDocument, errorLoadingIndividualDocument, isIndividualDocDownloading } =
    useOnboardingFormIndividualDocumentDownload(clientId, onboardingForm.id);

  const {
    mutate: syncOnboardingForm,
    isPending: syncFormIsLoading,
    isSuccess: syncFormIsSuccess,
  } = useSyncOnboardingForm(clientId, onboardingForm.id);

  const {
    currentRoutingOrder,
    metadata,
    waitingForWebhook,
    voidedActorName,
    voidedDateTime,
    voidedActorEmail,
    voidedReason,
  } = onboardingForm;
  const { status: envelopeProgressStatus, recipients } = metadata;
  const voidedFormInfo: VoidedFormInfo = {
    name: voidedActorName,
    email: voidedActorEmail,
    reason: voidedReason,
    dateTime: voidedDateTime,
  };
  const showWebhookError = checkHasWebhookError(waitingForWebhook);

  const { progressBarValue, isProgressBarRunning } = useFillFormProgressBar(
    !!waitingForWebhook && !showWebhookError,
  );

  const { mutateAsync: createRecipientEmbeddedView, isPending: isCreatingRecipientEmbeddedView } =
    useCreateRecipientEmbeddedView();

  const recipientActionsCompleted = onboardingForm.status === "COMPLETED";

  const currentRecipients = recipients.filter(
    ({ email }) => email.toLowerCase() === currentUserEmail.toLowerCase(),
  );
  const [currentRecipient] = currentRecipients;

  const lastTouchedRecipient =
    envelopeProgressStatus === "voided"
      ? getVoidedActionValues(onboardingForm)
      : getLastActionRecipientValues(recipients);
  const badgeMessage = getOnboardingFormBadgeMessage(envelopeProgressStatus, NIGOReasons);

  const currentUserIsMismatchedRecipient =
    recipients.filter(
      ({ name, email }) =>
        name.toLowerCase() === currentUserName.toLowerCase() &&
        email.toLowerCase() !== currentUserEmail.toLowerCase(),
    ).length > 0;

  const showMismatchedRecipientsInSummaryView = getIsInternalUser({ role: userRole });

  const showProgressBar = waitingForWebhook || isProgressBarRunning;
  const canBeDeleted = ["draft", "not-started"].includes(envelopeProgressStatus);

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: envelopeStatusToBadgeLabel(envelopeProgressStatus),
        buttonLabel,
      });
    },
    [trackElementClicked, envelopeProgressStatus],
  );

  const docusignClick = useCallback(() => {
    track("Sign in to DocuSign / View Status");
    setShowInternalEditPopover(false);
  }, [track, setShowInternalEditPopover]);

  const showStaticSpinner = envelopeStatusToBadgeLabel(envelopeProgressStatus) === "IN PROGRESS";

  const {
    refetch: getOnboardingFormLockStatus,
    data: formLockStatus,
    error: errorGettingFormLockStatus,
    isFetching: isGettingFormLockStatus,
  } = useGetOnboardingFormLockStatus(clientId, onboardingForm.id);

  const wasError =
    errorLoadingDocument ||
    errorLoadingIndividualDocument ||
    errorGettingFormLockStatus ||
    formLockStatus?.isLocked;

  const errorMessage: string | null = wasError
    ? errorLoadingDocument || errorLoadingIndividualDocument
      ? "Failed to load document"
      : errorGettingFormLockStatus
      ? "Failed to get form lock status"
      : formLockStatus?.isLocked
      ? `This form is being corrected and cannot be accessed.  Please contact your IC to have them finish the correction process.`
      : null
    : null;

  return (
    <>
      {!isInternalUser && isFinalDocumentForUser && setIsFinalDocumentForUser && (
        <GuidanceModal
          isVisible={userRecentlySigned(currentRecipient)}
          remainingTasks={false}
          clientId={clientId}
          nextText="Sun Life will review your form and let you know if anything else is required from you."
          titleText="You've signed the form!"
          onCancel={() => setIsFinalDocumentForUser(false)}
          badgeVariant={envelopeStatusToBadge(envelopeProgressStatus)}
          badgeStatus={envelopeStatusToBadgeLabel(envelopeProgressStatus)}
          trackElementClicked={trackElementClicked}
        />
      )}
      <HubCard
        header={
          isInternalUser && (
            <InternalControl
              buttonLabel="Edit"
              buttonIcon={<PencilIcon />}
              position="attached"
              popoverTrigger="click"
              onClick={() => track("Edit")}
              popoverVisible={showInternalEditPopover}
              onPopoverVisibleChange={(visible) => setShowInternalEditPopover(visible)}
              popoverContent={
                <>
                  <StackY dist={8} className={styles.popoverLinks}>
                    <div>
                      <Anchor
                        href={onboardingForm.metadata.manageEnvelopeUrl}
                        target="_blank"
                        onClick={docusignClick}
                      >
                        <div className={styles.popoverLinkRow}>
                          <EyeOutlined />
                          <span className={clsx(["body3", styles.docusignViewLink])}>
                            Sign in to DocuSign / View Status
                          </span>
                        </div>
                      </Anchor>
                    </div>
                    {showAddRecipientButton && (
                      <AddRecipientModal
                        clientId={clientId}
                        onboardingFormId={onboardingForm.id}
                        onSuccess={refetchOnboardingForms}
                        userRole={userRole}
                        onShowModalButtonClick={() => setShowInternalEditPopover(false)}
                        onboardingFormRecipients={onboardingForm.metadata.recipients}
                        manageEnvelopeUrl={onboardingForm.metadata.manageEnvelopeUrl}
                        track={track}
                      />
                    )}

                    {getIsOwnerUser({ role: userRole }) && (
                      <SyncOnboardingFormModal
                        clientId={clientId}
                        onShowModalButtonClick={() => setShowInternalEditPopover(false)}
                        onboardingFormId={onboardingForm.id}
                        onSuccess={refetchOnboardingForms}
                      />
                    )}

                    <UpdateSignerTypeModal
                      clientId={clientId}
                      onboardingFormId={onboardingForm.id}
                      onSuccess={refetchOnboardingForms}
                      onClick={() => setShowInternalEditPopover(false)}
                      onboardingFormRecipients={onboardingForm.metadata.recipients}
                      track={track}
                    />

                    {!recipientActionsCompleted && (
                      <ReassignRecipientModal
                        clientId={clientId}
                        onboardingFormId={onboardingForm.id}
                        onSuccess={refetchOnboardingForms}
                        userRole={userRole}
                        onClick={() => setShowInternalEditPopover(false)}
                        onboardingFormRecipients={onboardingForm.metadata.recipients}
                        track={track}
                      />
                    )}
                    {canBeDeleted && (
                      <>
                        <HorizontalDivider />
                        <DeleteOnboardingFormModal
                          clientId={clientId}
                          onboardingFormId={onboardingForm.id}
                          onSuccess={refetchOnboardingForms}
                          onClick={() => setShowInternalEditPopover(false)}
                          track={track}
                        />
                      </>
                    )}
                  </StackY>
                </>
              }
            />
          )
        }
      >
        <HubCardHeader
          title={title}
          badge={
            showProgressBar ? (
              <Skeleton active paragraph={false} title={{ width: 130 }} />
            ) : lastTouchedRecipient &&
              !["draft", "not-started"].includes(envelopeProgressStatus) ? (
              <PopoverBadge
                aria-label="Form Status"
                variant={envelopeStatusToBadge(envelopeProgressStatus)}
                status={envelopeStatusToBadgeLabel(envelopeProgressStatus)}
                message={badgeMessage ?? undefined}
                updatedBy={lastTouchedRecipient.updatedBy}
                updatedAt={lastTouchedRecipient.updatedAt}
                showStaticSpinner={showStaticSpinner}
              />
            ) : (
              <Badge
                srOnlyLabel="Form Status"
                variant={envelopeStatusToBadge(envelopeProgressStatus)}
                status={envelopeStatusToBadgeLabel(envelopeProgressStatus)}
                message={badgeMessage ?? undefined}
                showStaticSpinner={showStaticSpinner}
              />
            )
          }
          hasDivider
          description={showProgressBar ? "" : getInstructionMsg(envelopeProgressStatus)}
        />
        {showProgressBar ? (
          <div className={styles.progressBarAndSyncContainer}>
            {(showWebhookError || progressBarValue >= 100) && (
              <StackY dist={16} className={styles.syncFormContainer}>
                <ErrorMessage>
                  Oops! We had some trouble loading this form from DocuSign. <br />
                  Please try reloading the form. If that doesn’t help, chat with us or email{" "}
                  <a href="mailto:support@onboard.sunlife.com">support@onboard.sunlife.com</a>.
                </ErrorMessage>
                <Button
                  type="primary"
                  onClick={() => syncOnboardingForm({})}
                  size="middle"
                  loading={syncFormIsLoading || syncFormIsSuccess}
                >
                  Reload Form
                </Button>
              </StackY>
            )}
            {progressBarValue < 100 && !showWebhookError && (
              <div className={styles.progressBarContainer}>
                <ProgressBar
                  id={`onboardingForm-${onboardingForm.id}-progressBar`}
                  value={progressBarValue}
                />
              </div>
            )}
          </div>
        ) : (
          <StackY dist={12} className="w-full">
            <div>
              <RecipientSummary
                currentRoutingOrder={currentRoutingOrder}
                recipients={recipients}
                envelopeProgressStatus={envelopeProgressStatus}
                recipientActionsCompleted={recipientActionsCompleted}
                voidedFormInfo={voidedFormInfo}
                showMismatchedRecipients={showMismatchedRecipientsInSummaryView}
              />

              <OnboardingFormActionButton
                clientId={clientId}
                onboardingFormId={onboardingForm.id}
                currentRecipients={currentRecipients}
                currentRoutingOrder={currentRoutingOrder}
                downloadDocument={downloadDocument}
                downloadIndividualDocument={downloadIndividualDocument}
                createRecipientEmbeddedView={createRecipientEmbeddedView}
                showRecipientChangedToOutsideSignerModal={() =>
                  setShowRecipientChangedToOutsideSignerModal(true)
                }
                getOnboardingFormLockStatus={getOnboardingFormLockStatus}
                showMultipleRecipientModal={() => setShowMultipleRecipientModal(true)}
                showDownloadErrorMsg={Boolean(
                  errorLoadingDocument || errorLoadingIndividualDocument,
                )}
                formDocuments={onboardingForm.metadata.documents}
                envelopeProgressStatus={envelopeProgressStatus}
                isDownloading={isDownloading || isIndividualDocDownloading}
                isGettingFormLockStatus={isGettingFormLockStatus}
                isCreatingRecipientEmbeddedView={isCreatingRecipientEmbeddedView}
                isInternalUser={isInternalUser}
                isMismatchedRecipient={currentUserIsMismatchedRecipient}
                recipientActionsCompleted={recipientActionsCompleted}
                track={track}
              />

              <MultipleRecipientModal
                clientId={onboardingForm.clientId}
                onboardingFormId={onboardingForm.id}
                currentUserEmail={currentUserEmail}
                isVisible={showMultipleRecipientModal}
                showRecipientChangedToOutsideSignerModal={() =>
                  setShowRecipientChangedToOutsideSignerModal(true)
                }
                downloadDocument={downloadDocument}
                createRecipientEmbeddedView={createRecipientEmbeddedView}
                getOnboardingFormLockStatus={getOnboardingFormLockStatus}
                isCreatingRecipientEmbeddedView={isCreatingRecipientEmbeddedView}
                isGettingFormLockStatus={isGettingFormLockStatus}
                onClose={() => setShowMultipleRecipientModal(false)}
                recipients={recipients}
              />

              <RecipientChangedToOutsideSignerModal
                isVisible={showRecipientChangedToOutsideSignerModal}
                onClose={() => setShowRecipientChangedToOutsideSignerModal(false)}
              />

              {wasError && (
                <div className="mt-16">
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </div>
              )}
            </div>

            <p className={clsx(["body5", styles.footerText])}>
              {getFootnoteMsg(envelopeProgressStatus)}
            </p>
          </StackY>
        )}
      </HubCard>
    </>
  );
};
