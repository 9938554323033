import pluralize from "pluralize";
import {
  getIsSupplementalHealthBenefitType,
  type BenefitTypeEIF,
  getIsPremiumPaymentBenefitTypes,
  getIsDisabilityContributionBenefitType,
} from "shared/types/BenefitTypes";
import type { ClientId } from "./Client";
import type { EmployeeClass, EmployeeClassId } from "./EmployeeClass";
import type { Plan, PlanId } from "./Plan";
import type { Pretty, ValueOf } from "shared/types/Helper";

export const employerContributionOptions = [
  "YES",
  "NO",
  "SHARED",
  "GROSS_UP",
  "TAX_CHOICE",
] as const;
export type EmployerContributionOption = ValueOf<typeof employerContributionOptions>;

export const taxChoiceOptions = [
  "100_EMPLOYER",
  "GROSS_UP",
  "100_EMPLOYEE_PRE_TAX",
  "100_EMPLOYEE_POST_TAX",
] as const;
export type TaxChoiceOption = ValueOf<typeof taxChoiceOptions>;

export const displayTaxChoiceOptionsTypeName: Record<TaxChoiceOption, string> = {
  "100_EMPLOYER": "100% employer paid contribution",
  GROSS_UP: "Gross up",
  "100_EMPLOYEE_PRE_TAX": "100% employee paid contributions, pre-tax",
  "100_EMPLOYEE_POST_TAX": "100% employee paid contributions, post-tax",
};

export const employerPremiumPaymentsLabels = ["Pre-tax", "Post-tax"] as const;
export type EmployerPremiumPaymentsLabel = ValueOf<typeof employerPremiumPaymentsLabels>;
export const employerPremiumPayments = ["PRE_TAX", "POST_TAX"] as const;
export type EmployerPremiumPayments = ValueOf<typeof employerPremiumPayments>;
export const employerPremiumPaymentsValuesRecord: Record<
  EmployerPremiumPayments,
  EmployerPremiumPaymentsLabel
> = {
  PRE_TAX: "Pre-tax",
  POST_TAX: "Post-tax",
};

export type EmployeeClassPlanId = string;

export interface EmployeeClassPlan {
  id: EmployeeClassPlanId;
  employeeClassId: EmployeeClassId;
  planId: PlanId;

  // Waiting Period
  waitingPeriodType: WaitingPeriod | null;
  waitingPeriodDuration: number | null;
  waitingPeriodUnit: WaitingPeriodUnit | null;

  // Contributions
  employerContribution: EmployerContributionOption | null;
  employerContributionUnit: EmployerContributionUnit | null;
  eeContributionAmount: number | null;
  spouseContributionAmount: number | null;
  childrenContributionAmount: number | null;
  eeAndSpouseContributionAmount: number | null;
  eeAndChildrenContributionAmount: number | null;
  eeAndFamilyContributionAmount: number | null;
  familyContributionAmount: number | null;
  employerPremiumPayments: EmployerPremiumPayments | null;
  threeYearLookBackPercent: number | null;
  taxChoice: TaxChoiceOption[] | null;

  // Misc
  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  deletedAt: Date | null;
  deletedBy: string | null;
}

export type PlanContributions = Pick<
  EmployeeClassPlan,
  | "employerContribution"
  | "employerContributionUnit"
  | "eeContributionAmount"
  | "spouseContributionAmount"
  | "childrenContributionAmount"
  | "eeAndSpouseContributionAmount"
  | "eeAndChildrenContributionAmount"
  | "eeAndFamilyContributionAmount"
  | "familyContributionAmount"
  | "employerPremiumPayments"
  | "threeYearLookBackPercent"
  | "taxChoice"
>;

export const contributionFields: (keyof PlanContributions)[] = [
  "eeAndChildrenContributionAmount",
  "eeAndFamilyContributionAmount",
  "eeAndSpouseContributionAmount",
  "eeContributionAmount",
  "employerContribution",
  "employerContributionUnit",
  "taxChoice",
  "employerPremiumPayments",
  "threeYearLookBackPercent",
  "spouseContributionAmount",
  "childrenContributionAmount",
  "familyContributionAmount",
];

export type WaitingPeriod = "NONE" | "DAY_MONTH" | "FOFM" | "FOFM_INCLUSIVE";
export type WaitingPeriodUnit = "DAY" | "MONTH";
export type EmployerContributionUnit = "PERCENT" | "DOLLAR";

export type SetBenefitTypeAvailablePlansInput = {
  benefitTypes: BenefitTypeEIF[];
};

export type SetBenefitTypeAvailablePlansOutput = {
  clientId: ClientId;
  employeeClassId: EmployeeClassId;
  employeeClass: EmployeeClass;
  addedPlansCount: number;
  removedPlansCount: number;
  restoredPlansCount: number;
};

export type setWaitingPeriodInput = {
  classId: EmployeeClassId;
  planIds: PlanId[];
  waitingPeriodType: WaitingPeriod | null;
  waitingPeriodDuration: number | undefined | null;
  waitingPeriodUnit: WaitingPeriodUnit | undefined | null;
};

export type EmployeeClassWaitingPeriodFields = keyof Omit<
  setWaitingPeriodInput,
  "classId" | "planIds"
>;

export type SetWaitingPeriodOutput = {
  clientId: ClientId;
  employeeClass: EmployeeClass;
  employeeClassId: EmployeeClassId;
  numberUpdated: number;
  classPlanIds: EmployeeClassPlanId[];
  waitingPeriodType: WaitingPeriod | null;
  waitingPeriodDuration: number | undefined;
  waitingPeriodUnit: WaitingPeriodUnit | null | undefined;
};

export type SetContributionsInput = {
  employeeClassId: EmployeeClassId;
  contributions: EmployeeClassPlanContributions[];
};

export type EmployeeClassPlanContributions = {
  employeeClassPlanId: EmployeeClassPlanId;
} & PlanContributions;

export type EmployeeClassContributionFields = keyof EmployeeClassPlanContributions;

export type SetContributionsOutput = {
  clientId: ClientId;
  employeeClass: EmployeeClass;
  employeeClassId: EmployeeClassId;
  contributions: EmployeeClassPlanContributions[];
  numberUpdated: number;
};

export type EmployeeClassPlanWithPlan = EmployeeClassPlan & {
  plan: Plan;
};

// storage input
type TimestampColumn =
  | "createdAt"
  | "createdBy"
  | "updatedAt"
  | "updatedBy"
  | "deletedAt"
  | "deletedBy";

export type CreateEmployeeClassPlanInput = Pretty<
  {
    employeeClassId: EmployeeClassId;
    planId: string;
  } & Partial<Omit<EmployeeClassPlan, "id" | "employeeClassId" | "planId" | TimestampColumn>>
>;

export type CreateEmployeeClassPlanDataInput = {
  planId: string;
} & Partial<Omit<EmployeeClassPlan, "id" | "employeeClassId" | "planId" | TimestampColumn>>;

export type BulkAddRemoveClassPlanInput = {
  plansToAdd: CreateEmployeeClassPlanInput[];
  plansToRemove: EmployeeClassPlanId[];
  plansToRestore: EmployeeClassPlanId[];
};

export const waitingPeriodTypeToReadableType = (classPlan: EmployeeClassPlan) => {
  let hasAdditionalWaitingPeriod = false;
  let additionalWaitingPeriodDescription = "";
  if (
    classPlan.waitingPeriodDuration &&
    classPlan.waitingPeriodDuration > 0 &&
    classPlan.waitingPeriodUnit
  ) {
    hasAdditionalWaitingPeriod = true;
    additionalWaitingPeriodDescription = `${classPlan.waitingPeriodDuration} ${pluralize(
      classPlan.waitingPeriodUnit.toLowerCase(),
      classPlan.waitingPeriodDuration,
    )}`;
  }
  switch (classPlan.waitingPeriodType) {
    case "NONE":
      return "No waiting period";
    case "DAY_MONTH":
      return `${additionalWaitingPeriodDescription} after employment`;
    case "FOFM":
      if (hasAdditionalWaitingPeriod) {
        return `First of the month following ${additionalWaitingPeriodDescription} of employment`;
      } else {
        return `First of the following month after employment`;
      }
    case "FOFM_INCLUSIVE":
      if (hasAdditionalWaitingPeriod) {
        return `First of the month coincident with or following ${additionalWaitingPeriodDescription} of employment`;
      } else {
        return `First of the month coincident with or following employment`;
      }
    case null:
      return "Waiting period not yet selected";
  }
};

export const waitingPeriodNotSetForClassPlans = (classPlans: EmployeeClassPlan[]) => {
  return classPlans.every((classPlan) => classPlan.waitingPeriodType === null);
};

export const shouldHaveContributionValues = (
  benefitType: BenefitTypeEIF,
  employerContribution: EmployerContributionOption | null | undefined,
) => {
  if (getIsDisabilityContributionBenefitType(benefitType) && employerContribution === "SHARED") {
    return true;
  }
  return employerContribution === "SHARED";
};

export const requirePremiumPayments = (
  benefitType: BenefitTypeEIF,
  employerContribution: EmployerContributionOption | null | undefined,
) => {
  if (getIsPremiumPaymentBenefitTypes(benefitType)) {
    return employerContribution === "SHARED" || employerContribution === "NO";
  }

  return false;
};

const defaultCompensationOptionToLabel: Record<EmployerContributionOption, string> = {
  YES: "Yes",
  NO: "No, my employees pay 100% of the premium",
  // used for Disability
  GROSS_UP: "",
  SHARED: "",
  TAX_CHOICE: "",
};

const suppHealthCompensationOptionToLabel: Record<EmployerContributionOption, string> = {
  YES: "The Employer pays 100% of the premium",
  NO: "Employees will pay some or all of the premium",
  // used for Disability
  GROSS_UP: "",
  SHARED: "",
  TAX_CHOICE: "",
};

export const getContributionOptionsLabel = (
  benefitType: BenefitTypeEIF,
  compensationOption: EmployerContributionOption,
): string => {
  if (getIsSupplementalHealthBenefitType(benefitType)) {
    return suppHealthCompensationOptionToLabel[compensationOption];
  }

  return defaultCompensationOptionToLabel[compensationOption];
};

export const is100PercentEmployerPaidForDisability = (
  employerContribution: EmployerContributionOption | null,
  taxChoice: TaxChoiceOption[] | null,
) => {
  const hasTaxChoice100PercentEmployer = Boolean(taxChoice?.includes("100_EMPLOYER"));
  const hasTaxChoiceGrossUp = Boolean(taxChoice?.includes("GROSS_UP"));

  const is100PercentEmployer = employerContribution === "YES";
  const isGrossUp = employerContribution === "GROSS_UP";
  const isTaxChoice =
    employerContribution === "TAX_CHOICE" && hasTaxChoice100PercentEmployer && hasTaxChoiceGrossUp;

  return is100PercentEmployer || isGrossUp || isTaxChoice;
};
