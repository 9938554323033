import { Button } from "client/src/components/Button/Button";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { formatDate } from "shared/utils/format";

import { assertIsDefined } from "shared/utils/utils";
import { ClientDraftBadge } from "../../../../components/Client/ClientDraftBadge";
import { ClientTestBadge } from "../../../../components/Client/ClientTestBadge";
import { SlobTable } from "../../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../../components/Table/ActionButtons";

import { Tooltip } from "../../../../components/Tooltip/Tooltip";
import { useSlobAuth } from "../../../../hooks/auth";
import * as styles from "./Table.module.less";

import type { SlobTableProps, SlobColumnsType } from "../../../../components/SlobTable/SlobTable";
import type { Client } from "shared/types/Client";

const buildColumns = (isInternalUser: boolean) => {
  const columns: SlobColumnsType<Client> = [
    {
      width: "30%",
      title: "Client",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      render(value: string, client) {
        return (
          <div className={styles.flexCell}>
            {value} {!client.isActive && <ClientDraftBadge showTooltip />}
            {client.isTest && <ClientTestBadge />}
          </div>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "createdAt",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: formatDate,
    },
    {
      render: (client: Client) => {
        const disabled = !client.isActive && !isInternalUser;
        return (
          <ActionButtons>
            <Tooltip
              title={
                disabled
                  ? "This client is currently unavailable to view as it is in draft mode."
                  : null
              }
              placement="top"
            >
              <Button
                type="secondary"
                size="xtra-small"
                disabled={disabled}
                to={RouteData.homeHub.getPath(client.id)}
              >
                View Portal
              </Button>
            </Tooltip>
          </ActionButtons>
        );
      },
    },
  ];
  return columns;
};

export type BenAdminClientsTableProps = Omit<SlobTableProps<Client>, "columns">;

export const BenAdminClientsTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
  shouldRedirectToClient = true,
}: BenAdminClientsTableProps) => {
  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const columns = buildColumns(isInternalUser);

  if (data && totalItems === 1 && shouldRedirectToClient) {
    const [onlyClient] = data;
    assertIsDefined(onlyClient, "onlyClient");
    return <Navigate to={RouteData.homeHub.getPath(onlyClient.id)} replace />;
  }

  return (
    <SlobTable
      columns={columns}
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      featureToggles={featureToggles}
    />
  );
};
