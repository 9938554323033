import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal } from "antd";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { StackY } from "client/src/components/Spacing/Spacing";
import { TinyBadge } from "client/src/components/TinyBadge/TinyBadge";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { useUpdateClient } from "client/src/hooks/client";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { useCallback, useState } from "react";

import { BEN_ADMIN } from "shared/rbac/roles";
import { clientValidationSchema } from "shared/validation/client";
import { Button } from "../../../components/Button/Button";
import { DropdownButton } from "../../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { Loading } from "../../../components/Loading/Loading";
import { ActionMenu } from "../../../components/Menu/ActionMenu";
import { LightTable } from "../../../components/Table/LightTable";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { BenAdminCreateForm } from "../../../domain/User/Form/BenAdminCreateForm";
import { BenAdminUpdateForm } from "../../../domain/User/Form/BenAdminUpdateForm";
import { ConfirmBenAdminToBrokerModal } from "../../../domain/User/Form/ConfirmBenAdminToBrokerModal";
import {
  useChangeBenAdminToBroker,
  useDeleteBenAdmin,
  useUnassignBenAdminToClient,
} from "../../../hooks/user";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { ColumnsType } from "antd/es/table";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Client, ClientSetupStepId } from "shared/types/Client";
import type { BenAdmin } from "shared/types/User";

type CardBenAdminsProps = {
  client: Client;
  isLoading: boolean;
  benAdmins: BenAdmin[] | undefined;
  benAdminsStatus: string;
  ONBOARD_BROKER_SIGN_DEIF: boolean;
  refetchData?: (stepId: ClientSetupStepId) => Promise<void>;
};

const isImportedUser = (client: Client, benAdmin: BenAdmin) => {
  const currentClient = benAdmin.clientUser?.filter((c) => c.clientId === client.id);
  return currentClient?.some((c) => Boolean(c.importedFromClientId));
};

export const CardBenAdmins = ({
  client,
  benAdmins,
  isLoading,
  benAdminsStatus,
  ONBOARD_BROKER_SIGN_DEIF,
  refetchData,
}: CardBenAdminsProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [editState, setEditState] = useState<BenAdmin | null>(null);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isChangeToBrokerVisible, setIsChangeToBrokerVisible] = useState(false);
  const [isUnassignDialogVisible, setIsUnassignDialogVisible] = useState(false);

  const { mutateAsync: changeBenAdminToBroker } = useChangeBenAdminToBroker();
  const { mutateAsync: deleteBenAdmin } = useDeleteBenAdmin();
  const { mutateAsync: unassignClient } = useUnassignBenAdminToClient();
  const { mutateAsync: updateClient } = useUpdateClient();

  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Client Setup - Step 2",
        buttonLabel,
        moduleState: benAdminsStatus,
      });
    },
    [trackElementClicked, benAdminsStatus],
  );

  if (isLoading) return <Loading pageHeight={false} />;

  const hasBenAdmin = Boolean(benAdmins?.length);

  const onBenAdminChange = async () => {
    if (refetchData) await refetchData("ADD_BEN_ADMIN");
  };

  const handleDelete = async () => {
    if (!editState) return;
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progess", 0);
    try {
      await deleteBenAdmin({
        params: { userId: editState.id },
      });
      track("Deleted");
      void slobMessage.success("Ben Admin deleted");
      await onBenAdminChange();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleUnassignFromClient = async () => {
    if (!editState) return;
    setIsUnassignDialogVisible(false);
    if (!client.id) return;
    const hide = slobMessage.loading("Removing from client", 0);
    try {
      await unassignClient({
        data: {},
        params: { userId: editState.id, clientId: client.id },
      });
      track("Unassigned");
      void slobMessage.success("Removed from client");
      await onBenAdminChange();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleChangeToBroker = async () => {
    if (!editState) return;
    setIsChangeToBrokerVisible(false);
    const hide = slobMessage.loading("Changing to Broker", 0);
    try {
      await changeBenAdminToBroker({
        params: { userId: editState.id },
      });
      void slobMessage.success("Changed to Broker");
      await onBenAdminChange();
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const columns: ColumnsType<BenAdmin> = [
    {
      title: "Ben Admins",
      dataIndex: "fullName",
      key: "name",
      render: (_: string, ba: BenAdmin) => (
        <>
          {isImportedUser(client, ba) && (
            <>
              <TinyBadge variant="gray">IMPORTED</TinyBadge>
              <br />
            </>
          )}
          {ba.fullName}
        </>
      ),
    },
    {
      title: "Contact",
      dataIndex: "email",
      key: "email",
      render: (_: string, ba: BenAdmin) => (
        <>
          {isImportedUser(client, ba) && <br />}
          {ba.email}
        </>
      ),
    },
    {
      title: "",
      key: "actions",
      align: "right",
      render: (_: string, benAdmin: BenAdmin) => (
        <Dropdown
          dropdownRender={() => (
            <ActionMenu
              items={[
                { key: "edit-ben-admin", label: "Edit Admin" },
                { key: "delete-ben-admin", label: "Delete Admin" },
                { key: "unassign-ben-admin", label: "Unassign from Client" },
                { key: "change-to-broker", label: "Change to Broker" },
              ]}
              onClick={({ key }) => {
                switch (key) {
                  case "edit-ben-admin":
                    setEditState(benAdmin);
                    setShowUpdateModal(true);
                    track("Edit");
                    break;
                  case "delete-ben-admin":
                    setEditState(benAdmin);
                    setIsDeleteDialogVisible(true);
                    track("Delete");
                    break;
                  case "unassign-ben-admin":
                    setEditState(benAdmin);
                    setIsUnassignDialogVisible(true);
                    track("Unassign from Client");
                    break;
                  case "change-to-broker":
                    setEditState(benAdmin);
                    setIsChangeToBrokerVisible(true);
                    track("Delete");
                    break;
                }
              }}
            />
          )}
          placement="bottomRight"
        >
          <DropdownButton size="xtra-small" />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      {!client.brokerAsAuthorizedSigner && (
        <p>Add the Benefits Administrator contacts working on this Client’s implementation.</p>
      )}
      <p>
        <i>
          <b>Note</b>: If a contact will sign DocuSign forms, but will not complete or want updates
          on other onboarding tasks, you should not create an Onboard account for them. Instead, set
          them as an 'Outside Signer' when linking the DocuSign form to Onboard..
        </i>
      </p>

      {hasBenAdmin && !client.brokerAsAuthorizedSigner && (
        <LightTable rowKey={"id"} className="mb-16" columns={columns} dataSource={benAdmins} />
      )}

      <ConfirmDialogDeleteUser
        role={BEN_ADMIN}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={editState}
        handleDelete={handleDelete}
      />
      <ConfirmDialog
        title="Unassign from Client"
        isVisible={isUnassignDialogVisible}
        onCancel={() => setIsUnassignDialogVisible(false)}
        onConfirm={() => handleUnassignFromClient()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to unassign from a client?
      </ConfirmDialog>
      <ConfirmBenAdminToBrokerModal
        clientName={client.name || ""}
        isVisible={isChangeToBrokerVisible}
        onCancel={() => setIsChangeToBrokerVisible(false)}
        onConfirm={() => handleChangeToBroker()}
      />
      <Modal
        onCancel={() => setShowCreateModal(false)}
        destroyOnClose={true}
        open={showCreateModal}
        footer={null}
        centered={true}
      >
        <BenAdminCreateForm
          clientId={client.id}
          handleClose={() => setShowCreateModal(false)}
          showWelcomeEmail={false}
          onChange={onBenAdminChange}
          sendWelcomeEmail={false}
        />
      </Modal>
      {editState && (
        <Modal
          onCancel={() => setShowUpdateModal(false)}
          destroyOnClose={true}
          open={showUpdateModal}
          footer={null}
          centered={true}
        >
          <BenAdminUpdateForm
            benAdmin={editState}
            handleClose={() => setShowUpdateModal(false)}
            onChange={onBenAdminChange}
          />
        </Modal>
      )}
      <StackY dist={24}>
        {!client.brokerAsAuthorizedSigner && (
          <Button
            onClick={() => {
              setShowCreateModal(true);
              track("Add Benefits Administrator");
            }}
            type="tertiary"
            icon={<FontAwesomeIcon icon={faUserPlus} />}
          >
            Add Benefits Administrator
          </Button>
        )}
        {ONBOARD_BROKER_SIGN_DEIF && (
          <BrokerAsAuthorizedSignerCheckBox
            client={client}
            updateClient={updateClient}
            track={track}
            clearAuthorizedSigner={false}
            onBenAdminChange={onBenAdminChange}
          />
        )}
      </StackY>
    </>
  );
};

type BrokerAsAuthorizedSignerCheckBoxProps = {
  client: Client;
  updateClient: UpdateClientFunc;
  track: (buttonLabel: string) => void;
  clearAuthorizedSigner: boolean;
  onBenAdminChange?: () => Promise<void>;
};
export const BrokerAsAuthorizedSignerCheckBox = ({
  client,
  updateClient,
  track,
  clearAuthorizedSigner,
  onBenAdminChange,
}: BrokerAsAuthorizedSignerCheckBoxProps) => {
  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>([
      "brokerAsAuthorizedSigner",
      "authorizedSignerUserId",
    ]),
    updateClient,
    validationSchema: clientValidationSchema.pick([
      "brokerAsAuthorizedSigner",
      "authorizedSignerUserId",
    ]),
    type: `Benefits Administrator: Broker As Authorized Signer`,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    prefill: false,
    onSuccessCallback: async () => {
      if (onBenAdminChange) {
        await onBenAdminChange();
      }
    },
  });

  return (
    <Checkbox
      checked={formik.values.brokerAsAuthorizedSigner ?? false}
      onChange={async (e) => {
        formik.handleChange(e);
        if (clearAuthorizedSigner) {
          await formik.setFieldValue("authorizedSignerUserId", null);
        }
        formik.handleSubmit();
      }}
      label={
        <div>
          <Body3 as="div">Assign a Broker as the Authorized Signer</Body3>
          <Body5>
            You should only enable this if the broker is the policy holder and the employees of the
            brokerage are the covered members.
          </Body5>
        </div>
      }
      name={"brokerAsAuthorizedSigner"}
      disabled={formik.isSubmitting}
      errorId={formik.errors.brokerAsAuthorizedSigner}
      onBlur={formik.handleBlur}
    />
  );
};
