import { faChevronCircleRight, faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { CalendarLabel } from "client/src/components/CalendarLabel/CalendarLabel";
import { Circle } from "client/src/components/Circle/Circle";
import { Loading } from "client/src/components/Loading/Loading";
import { Body1, H2, H4, H5 } from "client/src/components/Typography/Typography";
import { useCompleteWelcomeFlow } from "client/src/hooks/client";
import { triggerError } from "client/src/hooks/generalError";
import { useTitle } from "client/src/hooks/useTitle";
import { useTrackElementClicked } from "client/src/utils/analytics";
import clsx from "clsx";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { isAddCoverageClient } from "shared/utils/client";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import { getUniquePolicyEffectiveDates } from "shared/utils/policy";

import { assertIsDefined } from "shared/utils/utils";
import * as styles from "./welcomeSingleStep.module.less";
import type { Client } from "shared/types/Client";

export type TopIconProps = {
  type: "success" | "info";
  icon: JSX.Element;
};
const TopIcon = ({ type, icon }: TopIconProps) => {
  return (
    <>
      <div className={styles.iconClip} />
      <div
        className={clsx(styles.topIcon, {
          [styles.success]: type === "success",
          [styles.info]: type === "info",
        })}
      >
        {icon}
      </div>
    </>
  );
};

type WelcomeSingleStepProps = {
  client: Client;
};

export function WelcomeSingleStep({ client }: WelcomeSingleStepProps) {
  useTitle("Welcome");
  const navigate = useNavigate();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const trackElementClicked = useTrackElementClicked(client);

  const { dates: policyEffectives } = getUniquePolicyEffectiveDates({
    policies: client.policies,
  });

  const {
    mutateAsync: completeWelcomeFlow,
    isPending: isUpdatingWelcomeFlowStatus,
    error: errorWelcomeFlow,
  } = useCompleteWelcomeFlow();

  const handleCompleteWelcomeFlow = async () => {
    trackElementClicked({
      module: "Welcome Flow",
      buttonLabel: "Get Started",
    });
    setIsRedirecting(true);
    await completeWelcomeFlow({ params: { clientId: client.id } });
    navigate(RouteData.homeHub.getPath(client.id));
  };

  if (errorWelcomeFlow) {
    return triggerError(errorWelcomeFlow ?? "Couldn't update welcome flow");
  }

  if (isUpdatingWelcomeFlowStatus || isRedirecting) {
    return <Loading />;
  }

  const isAddcoverage = isAddCoverageClient(client);
  const shouldShowPastBenefits = isAddcoverage && client.inForceSLCoverages?.length;
  const primaryPolicy = client.policies.find((p) => p.primaryPolicy);
  const mainPolicy = primaryPolicy ? primaryPolicy : client.policies[0];
  assertIsDefined(mainPolicy, "mainPolicy");

  const restOfPolicies = client.policies.filter((p) => p.id !== mainPolicy?.id);

  const countinueButton = (
    <div className="mt-32">
      <Button
        block
        type="primary"
        icon={<FontAwesomeIcon icon={faChevronCircleRight} />}
        iconPosition="right"
        size="large"
        onClick={handleCompleteWelcomeFlow}
      >
        Get Started
      </Button>
    </div>
  );

  return (
    <article className={styles.container}>
      <section className={styles.topSection}>
        <div className={styles.circleBoundary}>
          <div className={styles.circlePosition}>
            <Circle variant="yellowLight" />
          </div>
          <div className={styles.circleThinPosition}>
            <Circle thin variant="yellowLight" />
          </div>
        </div>

        <div className={styles.topSectionInner}>
          <h1>Welcome {client.name || ""}</h1>

          {!isAddcoverage && (
            <H4 as="p">
              We are thrilled you have chosen to work with Sun Life. Sun Life Onboard is where you
              will get your benefits set up and ready for your employees to use.
            </H4>
          )}
          {isAddcoverage && (
            <H4 as="p">
              We are thrilled you have chosen to continue to trust Sun Life for your employees'
              benefits. Sun Life Onboard is where you will get your benefits set up and ready for
              your employees to use.
            </H4>
          )}
          <CalendarLabel
            label={policyEffectives.length > 1 ? "Effective Dates" : "Effective Date"}
            innerText={
              policyEffectives.length > 1
                ? "Your Sun Life benefits have different effective dates."
                : null
            }
            policyEffectives={policyEffectives}
            continueButton={countinueButton}
          />
        </div>
      </section>

      <section className={styles.bottomSection}>
        <div className={clsx(styles.bottomSectionInner, "text-center")}>
          <H2>Let’s review your Sun Life Benefits</H2>
          <div className={styles.cardsContainer}>
            <TopIcon type="info" icon={<FontAwesomeIcon icon={faPlus} />} />
            <div className={clsx(styles.cards)}>
              <div>
                <H5 as="h3">Benefits You Are Adding</H5>
                {mainPolicy.slfCoverages?.map((c) => (
                  <Body1 key={c} as="div">
                    {c}
                  </Body1>
                )) ?? <Body1 as="div">-</Body1>}
              </div>

              <div>
                <H5 as="h3">Policy Number{client.policies.length > 1 ? "s" : ""}</H5>
                <Body1 as="div">{mainPolicy.slfPolicyNumber ?? "-"}</Body1>
              </div>

              <div>
                <H5 as="h3">Benefits Effective</H5>
                <Body1>
                  {mainPolicy.policyEffective
                    ? formatDateFullMonthWithYear(mainPolicy.policyEffective)
                    : "-"}
                </Body1>
              </div>
            </div>
            {restOfPolicies.map((policy) => (
              <Fragment key={policy.id}>
                <div className={clsx(styles.cards, styles.extraCards)}>
                  <div>
                    {policy.slfCoverages?.map((c) => (
                      <Body1 key={c} as="div">
                        {c}
                      </Body1>
                    )) ?? <Body1 as="div">-</Body1>}
                  </div>

                  <div>
                    <Body1 as="div">{policy.slfPolicyNumber ?? "-"}</Body1>
                  </div>

                  <div>
                    <Body1>
                      {policy.policyEffective
                        ? formatDateFullMonthWithYear(policy.policyEffective)
                        : "-"}
                    </Body1>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          {shouldShowPastBenefits && (
            <div className={styles.cardsContainer}>
              <TopIcon type="success" icon={<FontAwesomeIcon icon={faCheck} />} />
              <div className={clsx(styles.singleCard)}>
                <H5 as="h3">Existing Benefits</H5>
                {client.inForceSLCoverages?.map((c) => (
                  <Body1 key={c} as="div">
                    {c}
                  </Body1>
                )) ?? <Body1 as="div">-</Body1>}
              </div>
            </div>
          )}
        </div>
      </section>

      <section className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={clsx(styles.left, "stack-y-48", "py-64")}>
            <p className="h2">We’re here to help</p>

            <div className={clsx(styles.list, "body5")}>
              <div>SUN LIFE</div>
              <div>You + your team at {client.name || ""}</div>
              <div>Your broker</div>
            </div>

            <p className="body1">
              You’ll have personalized help from Sun Life to make <br /> the process easy and
              efficient.
            </p>
          </div>

          <div className={styles.right}>
            <img
              src="/Landing screen image.png"
              srcSet="/Landing screen image@2x.png 2x"
              alt="Benefits Administrators working on their computers at their office"
              width={826}
              height={349}
            />
          </div>
        </div>
      </section>
    </article>
  );
}
