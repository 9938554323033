import { Button } from "client/src/components/Button/Button";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { ReactComponent as CircleComplete } from "client/src/components/Icons/CircleComplete.svg";
import { StepNotStartedIcon } from "client/src/components/Icons/StepNotStartedIcon";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { TagList } from "client/src/components/TagList/TagList";
import { Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { getIn } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import { getEIFSubStepMap, type EIFPlanConfigEligibilityOnlyPFMLSubStep } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { nullNotAllowedTestConfig, yesNoRequired } from "shared/validation/validation";
import * as Yup from "yup";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type StepTitleProps = {
  disabled?: boolean;
  completed?: boolean;
  title: string;
};

function StepTitle({ title, completed = false }: StepTitleProps) {
  return (
    <div className="flex items-center" style={{ height: 40 }}>
      {completed ? <CircleComplete className="mr-12" /> : <StepNotStartedIcon />}
      <H3 style={{ margin: 0, opacity: !completed ? 0.4 : 1 }}>{title}</H3>
    </div>
  );
}

const filteringQuestionSchema = Yup.object({
  fliCoversAllEmployees: yesNoRequired.nullable().test(nullNotAllowedTestConfig()),
  fliExcludedGroups: Yup.array()
    .of(Yup.string().required())
    .when("fliCoversAllEmployees", {
      is: "NO",
      then: (schema) =>
        schema.of(
          Yup.string()
            .trim("The name cannot include leading and trailing spaces")
            .max(
              191,
              (params) => `Cannot be longer than 191 characters: "${String(params.value)}".`,
            )
            .strict()
            .required("No empty group names allowed"),
        ),
    }),
});

type Props = {
  client: Client;
  featureToggles: ClientFeatureToggles;
  updateClientQuery: UpdateClientQuery;
};

export function EIFFLIPreferences(props: Props) {
  const { client, updateClientQuery, featureToggles } = props;
  const [eligibilityExclusionsStarted, toggleEligibilityExclusionsStarted] = useToggler();

  const { mutateAsync: updateClient, isPending } = updateClientQuery;

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const navigate = useNavigate();

  const eifSubStepId = "fli-preferences" satisfies EIFPlanConfigEligibilityOnlyPFMLSubStep;
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const formik = useSlobFormik({
    validationSchema: filteringQuestionSchema,
    validationContext: { prefill: true },
    initialValues: {
      fliCoversAllEmployees: client.fliCoversAllEmployees
        ? "YES"
        : client.fliCoversAllEmployees === false
        ? "NO"
        : null,
      fliExcludedGroups: client.fliExcludedGroups || undefined,
    },
    onSubmit: async ({ fliCoversAllEmployees, fliExcludedGroups }) => {
      try {
        await updateClient({
          params: { clientId: client.id },
          data: {
            fliPreferencesStepViewed: true,
            fliCoversAllEmployees: fliCoversAllEmployees === "YES",
            fliExcludedGroups: fliExcludedGroups?.length ? fliExcludedGroups : null,
          },
        });
        toggleEligibilityExclusionsStarted();
      } catch (error) {
        void slobMessage.error(
          "Oops! Something went wrong. Could not save eligibility exclusions. Please try again.",
        );
        throw error;
      }
    },
  });

  const onNextSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!client.fliPreferencesStepViewed) {
      formik.resetForm();
      if (eligibilityExclusionsStarted) toggleEligibilityExclusionsStarted();
      formik.setSubmitting(true);
      try {
        await updateClient({
          params: { clientId: client.id },
          data: { fliPreferencesStepViewed: true },
        });
      } catch (error) {
        void slobMessage.error(
          "Oops! Something went wrong. Could not save eligibility exclusions. Please try again.",
        );
        throw error;
      } finally {
        formik.setSubmitting(false);
      }
    }

    navigate(nextSubStepLink);
  };

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }
  const stepStatus = getEIFSubStepStatus({ eifSubStepId, client, featureToggles });
  const isStepCompleted = stepStatus === "Completed";

  return (
    <>
      {featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS && (
        <StackY dist={32} wrap={false}>
          <h1>{subStepName}</h1>

          <p>
            Family Leave is configured separately from the rest of your benefits because it applies
            to all eligible employees in the same way. Employees who do not have other benefits may
            still receive Family Leave Insurance.
          </p>
          <HubCard padding="none">
            <div className="py-32 px-32">
              <Row align="middle" justify="space-between">
                <Col flex={1}>
                  <StepTitle title="Eligibility exclusions" completed={isStepCompleted} />
                </Col>
                <Col>
                  {!eligibilityExclusionsStarted && (
                    <Button
                      size="small"
                      type={isStepCompleted ? "text" : "tertiary"}
                      onClick={toggleEligibilityExclusionsStarted}
                    >
                      {isStepCompleted ? "Edit" : "Start"}
                    </Button>
                  )}
                </Col>
              </Row>
              {eligibilityExclusionsStarted && (
                <form onSubmit={formik.handleSubmit}>
                  <div className="mt-32">
                    <Body3 as="p">
                      All eligible employees are covered by Family Leave Insurance.
                    </Body3>
                    <Body3 as="p">
                      However, in some cases you may need to exclude certain employee groups.
                      Examples of exclusions may include owners or partners.
                    </Body3>
                    <RadioGroup
                      name="fliCoversAllEmployees"
                      label="Does Family Leave Insurance cover all employees?"
                      options={[
                        {
                          value: "YES",
                          label: (
                            <StackX dist={8} wrap={false}>
                              <Body3>Yes, all employees are covered</Body3>
                              <Body5>Most common</Body5>
                            </StackX>
                          ),
                        },
                        { value: "NO", label: "No, I need to exclude some employees" },
                      ]}
                      disabled={formik.isSubmitting}
                      touched={formik.touched.fliCoversAllEmployees}
                      error={formik.errors.fliCoversAllEmployees}
                      value={formik.values.fliCoversAllEmployees}
                      onChange={(e) => {
                        if (e.target.value === "YES") {
                          void formik.setFieldValue("fliExcludedGroups", undefined);
                        }
                        formik.handleChange(e);
                      }}
                      direction="vertical"
                    />
                    {formik.values.fliCoversAllEmployees === "NO" && (
                      <div className="ml-32">
                        <TagList
                          name="fliExcludedGroups"
                          placeholder="Add a group name and press enter"
                          value={getIn(formik.values, "fliExcludedGroups")}
                          onChange={(e) => {
                            if (!e.target.value.length) {
                              void formik.setFieldValue("fliExcludedGroups", undefined);
                            } else formik.handleChange(e);
                          }}
                          touched={getIn(formik.touched, "fliExcludedGroups")}
                          error={getIn(formik.errors, "fliExcludedGroups")}
                          disabled={formik.isSubmitting}
                        />
                      </div>
                    )}
                    <div className="flex items-right mt-24">
                      <StackX dist={8}>
                        <Button
                          size="small"
                          type="text"
                          onClick={toggleEligibilityExclusionsStarted}
                        >
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          type="secondary"
                          htmlType="submit"
                          disabled={formik.isSubmitting}
                          loading={formik.isSubmitting}
                          aria-label={formik.isSubmitting ? "Save" : undefined}
                        >
                          Save
                        </Button>
                      </StackX>
                    </div>
                  </div>
                </form>
              )}
              {!eligibilityExclusionsStarted && isStepCompleted && (
                <div className="mt-32">
                  {client.fliCoversAllEmployees === true && (
                    <Body3>
                      None - all benefits-eligible employees are covered by Family Leave Insurance
                    </Body3>
                  )}
                  {client.fliCoversAllEmployees === false &&
                    Boolean(client.fliExcludedGroups?.length) && (
                      <Body3>{client.fliExcludedGroups?.join(", ")}</Body3>
                    )}
                </div>
              )}
            </div>

            <HorizontalDivider size={1} />

            <div className="py-32 px-32">
              <StepTitle title="Waiting period" completed />
              <div className="mt-32">
                <Body3>There is no waiting period for eligible employees.</Body3>
              </div>
            </div>

            <HorizontalDivider size={1} />

            <div className="py-32 px-32">
              <StepTitle title="Contributions" completed />
              <div className="mt-32">
                <Body3>
                  Family Leave Insurance is 100% employer paid. There is no employee contribution
                  required.
                </Body3>
              </div>
            </div>
          </HubCard>
          <form onSubmit={onNextSubmit}>
            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={isPending}
              nextButtonDisabled={isPending}
            />
          </form>
        </StackY>
      )}
      {!featureToggles.ONBOARD_FLI_EXCLUDED_GROUPS && (
        <>
          <h1>{subStepName}</h1>

          <p>
            Family Leave is configured separately from the rest of your benefits because it applies
            to all eligible employees in the same way. Employees who do not have other benefits may
            still receive Family Leave Insurance.
          </p>

          <hr />

          <StackY dist={32} wrap={false}>
            <h2>Eligibility</h2>

            <HubCard>
              <Body3>All eligible employees are covered by the Family Leave Insurance.</Body3>
            </HubCard>

            <h2>Waiting periods</h2>

            <HubCard>
              <Body3>There is no waiting period for eligible employees.</Body3>
            </HubCard>

            <h2>Contributions</h2>

            <HubCard>
              <Body3>
                Family Leave Insurance is 100% employer paid. There is no employee contribution
                required.
              </Body3>
            </HubCard>

            <form
              onSubmit={async (e) => {
                e.preventDefault();

                if (!client.fliPreferencesStepViewed) {
                  await updateClient({
                    params: { clientId: client.id },
                    data: { fliPreferencesStepViewed: true },
                  });
                }

                navigate(nextSubStepLink);
              }}
            >
              <EIFBottomNavButtons
                previousLink={previousSubStepLink}
                previousButtonDisabled={isPending}
                nextButtonDisabled={isPending}
              />
            </form>
          </StackY>
        </>
      )}
    </>
  );
}
