import { useTranslation } from "client/src/i18n";
import { useMemo } from "react";
import { isBenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerBenefitTypeMetadata } from "shared/types/ExplorerPage";

export type BenefitHighligherMapping = {
  benefitType: BenefitTypeBenEx;
  highlightText: string;
  highlightOrder: number;
};

export const useBenefitHighlighters = (
  benefitTypeMetadata: ExplorerBenefitTypeMetadata | null | undefined,
) => {
  const { language } = useTranslation().i18n;

  return useMemo(() => {
    if (!benefitTypeMetadata) return null;

    return Object.entries(benefitTypeMetadata)
      .map<BenefitHighligherMapping | null>(([metaKey, metaData]) => {
        // metaKey should always be a BenefitType
        return isBenefitTypeBenEx(metaKey)
          ? {
              benefitType: metaKey,
              highlightText:
                language === "es" && metaData.spanishHighlighterText
                  ? metaData.spanishHighlighterText
                  : metaData.highlighterText ?? "",
              highlightOrder: metaData.highlighterOrder ?? 0,
            }
          : null;
      })
      .filter<BenefitHighligherMapping>(
        (mapping): mapping is BenefitHighligherMapping => !!mapping?.highlightText,
      )
      .sort((a, b) => a.highlightOrder - b.highlightOrder);
  }, [benefitTypeMetadata, language]);
};
