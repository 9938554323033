import { Button } from "client/src/components/Button/Button";
import { Card } from "client/src/components/Cards/Card";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Col, Row } from "client/src/components/Grid/Grid";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import { useResendOutsideSignerEmail } from "client/src/hooks/outsideSigner";
import { ResponseError } from "client/src/hooks/query";
import * as styles from "./OutsideSignerPage.module.less";
import type { ClientId } from "shared/types/Client";
import type { ResendOutsideSignerEmailOutput } from "shared/types/OutsideSigner";

type OutsideSignerExpiredUIProps = {
  clientId: ClientId;
  isPending: boolean;
  error: ResponseError | null;
  resendEmail: ReturnType<typeof useResendOutsideSignerEmail>["mutate"];
  resendEmailResult: ResendOutsideSignerEmailOutput | null;
};

export const OutsideSignerExpiredUI = ({
  clientId,
  resendEmail,
  error,
  isPending,
  resendEmailResult,
}: OutsideSignerExpiredUIProps) => {
  const isEmailSentSuccessfully = resendEmailResult ? resendEmailResult.emailSent : false;
  const resendEmailAction = () => {
    resendEmail({
      params: {
        clientId,
      },
    });
  };

  return (
    <div className={styles.centerContent}>
      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={24}>
            <div className="text-center">
              <h1>This link has expired</h1>
            </div>
          </Col>
        </Row>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={18}>
            <div className="text-center mt-12 mb-48">
              <Card>
                <div>
                  <Body3 as="p">Click below to resend a new link to your email.</Body3>
                  {isEmailSentSuccessfully && (
                    <Body5 as="p">A new link has been sent. Please check your inbox.</Body5>
                  )}
                  {error && (
                    <div className="mt-16 mb-16">
                      <ErrorMessage>
                        {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
                      </ErrorMessage>
                    </div>
                  )}
                  <Button
                    type="primary"
                    size="large"
                    disabled={isEmailSentSuccessfully || isPending}
                    onClick={resendEmailAction}
                  >
                    Resend email
                  </Button>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};

type OutsideSignerExpiredProps = {
  clientId: ClientId;
};

export const OutsideSignerExpired = ({ clientId }: OutsideSignerExpiredProps) => {
  const {
    data: resendEmailResult,
    mutate: resendEmail,
    error,
    isPending,
  } = useResendOutsideSignerEmail();

  return (
    <OutsideSignerExpiredUI
      clientId={clientId}
      error={error}
      isPending={isPending}
      resendEmail={resendEmail}
      resendEmailResult={resendEmailResult?.data ?? null}
    />
  );
};
