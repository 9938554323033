import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SegmentedButton } from "client/src/components/SegmentedButton/SegmentedButton";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import {
  rehireProvisionDays,
  rehireProvisionMonths,
  rehireProvisionValueToLabelMap,
} from "shared/types/Client";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsPFMLCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { rehireProvisionValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { rehireProvisionsFormKeyProps } from "shared/validation/otherContractDetailsFormKeys";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body3, Body5 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { RadioChangeEvent } from "antd";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, RehireProvisionDays, RehireProvisionMonths } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type RehireTimeFrameSegmentedButtonValues = "months" | "days";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const EIFRehireProvisions = (props: Props) => {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const eifSubStepId = "rehire-provision";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const navigate = useNavigateIfMounted();

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const [rehireTimeframe, setRehireTimeframe] = useState<RehireTimeFrameSegmentedButtonValues>(
    () => {
      if (client.rehireProvision) {
        if (rehireProvisionMonths.some((r) => r === client.rehireProvision)) {
          return "months";
        }
        if (rehireProvisionDays.some((r) => r === client.rehireProvision)) {
          return "days";
        }
      }
      return "months";
    },
  );

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(rehireProvisionsFormKeyProps),
    updateClient,
    validationSchema: rehireProvisionValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const handleReHireProvisionExistsChange = (e: RadioChangeEvent) => {
    if (e.target.name === "reHireProvisionExists" && e.target.value === "NO") {
      void formik.setFieldValue("rehireProvision", null);
    }
    formik.handleChange(e);
  };

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(rehireProvisionsFormKeyProps)(client),
  );

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, rehireProvisionValidationSchema, {
        prefill: false,
      })
    : {};

  const hasPFML = client.allPoliciesSlfCoverages?.some(getIsPFMLCoverage) ?? false;
  const hasFLI = client.allPoliciesSlfCoverages?.includes("Family Leave Insurance") ?? false;

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={"form"}>
        <h2 className="mb-40">{subStepName}</h2>
        <PFMLBulbMessage client={client} />

        <StackY dist={64}>
          <StackY dist={40}>
            <HubCard>
              <StackY dist={16}>
                <RadioGroup
                  name="reHireProvisionExists"
                  label={
                    <>
                      <p>
                        When employees who leave the company are rehired within a certain time
                        frame, benefit plans may have different requirements around waiting periods
                        and how things like late entry, guaranteed issue, and pre-existing
                        conditions requirements are treated.
                      </p>

                      {hasPFML && hasFLI ? (
                        <p>There is no rehire provision for PFML or FLI.</p>
                      ) : hasPFML ? (
                        <p>There is no rehire provision for PFML.</p>
                      ) : hasFLI ? (
                        <p>There is no rehire provision for FLI.</p>
                      ) : null}

                      <p>
                        <strong>
                          Do you want to set a rehire provision for employees who are rehired within
                          a specific time frame from their termination date?
                        </strong>
                      </p>
                    </>
                  }
                  disabled={formik.isSubmitting}
                  touched={
                    formik.touched.reHireProvisionExists || !!strictErrors.reHireProvisionExists
                  }
                  error={formik.errors.reHireProvisionExists || strictErrors.reHireProvisionExists}
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                  ]}
                  direction="vertical"
                  value={formik.values.reHireProvisionExists}
                  onChange={handleReHireProvisionExistsChange}
                />

                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Client.reHireProvisionExists]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "reHireProvisionExists",
                    client,
                    formik,
                  })}
                />

                {formik.values.reHireProvisionExists === "YES" && (
                  <>
                    <Body3 as="p" id="reHireProvisionDurationDescription">
                      <strong>What is the time frame for your rehire provision?</strong>
                    </Body3>

                    <Row gutter={16}>
                      <Col>
                        <SegmentedButton<RehireTimeFrameSegmentedButtonValues>
                          name="rehireTimeFrame"
                          aria-labelledby="reHireProvisionDurationDescription"
                          value={rehireTimeframe}
                          options={[
                            { value: "months", label: "Month(s)" },
                            { value: "days", label: "Day(s)" },
                          ]}
                          onClick={(e) => {
                            setRehireTimeframe(e.currentTarget.value);
                            void formik.setFieldValue("rehireProvision", null);
                          }}
                          disabled={formik.isSubmitting}
                        />
                      </Col>

                      <Col>
                        <SlobSelect<{
                          label: string;
                          value: RehireProvisionMonths | RehireProvisionDays;
                        }>
                          name="rehireProvision"
                          placeholder="Duration"
                          options={
                            rehireTimeframe === "months"
                              ? rehireProvisionMonths.map((item) => ({
                                  label: rehireProvisionValueToLabelMap[item],
                                  value: item,
                                }))
                              : rehireProvisionDays.map((item) => ({
                                  label: rehireProvisionValueToLabelMap[item],
                                  value: item,
                                }))
                          }
                          value={formik.values.rehireProvision}
                          onChange={({ value }) => formik.setFieldValue("rehireProvision", value)}
                          disabled={formik.isSubmitting}
                          loading={formik.isSubmitting}
                          touched={formik.touched.rehireProvision || !!strictErrors.rehireProvision}
                          error={formik.errors.rehireProvision || strictErrors.rehireProvision}
                        />
                      </Col>
                    </Row>

                    <div>
                      <Body5>
                        6 months is the most common. For example, you can enter 3 months, 9 months,
                        12 months, or days, etc. The rehire provision will be the same for all
                        benefits.
                      </Body5>
                    </div>

                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.Client.rehireProvision]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "rehireProvision",
                        client,
                        formik,
                      })}
                    />
                  </>
                )}

                <div aria-live="assertive" className="hide:Empty">
                  {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
                </div>
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>

          <div>
            <h2>What is a rehire provision time frame?</h2>

            <p>
              The rehire provision time frame is the time between the employee's date of termination
              and the rehire date during which the employee would be subject to certain rehire
              provisions (like waiving the waiting period).
            </p>
            <p>
              An employee rehired during this rehire provision time frame does not have to complete
              a new waiting period in order to be eligible for benefits.
            </p>
            <p>
              Please note this is different from the eligibility waiting period. An eligibility
              waiting period is the set amount of time required after employees are hired before
              they are eligible for benefits.
            </p>
          </div>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
