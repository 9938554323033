import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { isEqual } from "shared/utils/utils";
import type { Client, ClientId, YesNo } from "./Client";
import type { ValueOf } from "./Helper";
import type {
  AveragedOver,
  CurrentEarnings,
  EarningsTimeFrame,
  EarningsType,
  IncomeTypePartnership,
  IncomeTypeSCorporation,
  NumberOfYears,
  OrganizationType,
} from "@prisma/client";
import type {
  EmployeeClassPlanWithPlan,
  CreateEmployeeClassPlanDataInput,
} from "shared/types/EmployeeClassPlan";
import type { QPSClass, QPSBasicClass } from "shared/types/QPSClass";

export type EmployeeClassId = string;

export const classEmploymentTypes = ["FULL_TIME", "PART_TIME"] as const;
export type ClassEmploymentType = ValueOf<typeof classEmploymentTypes>;

export const classCompensationTypes = ["SALARIED", "HOURLY", "EXEMPT", "NON-EXEMPT"] as const;
export type ClassCompensationType = ValueOf<typeof classCompensationTypes>;

export const yesNoShared = ["YES", "NO", "SHARED"] as const;
export type YesNoShared = ValueOf<typeof yesNoShared>;

export interface EmployeeClass {
  id: EmployeeClassId;
  clientId: ClientId;

  // Eligibility
  jobTitles: string[] | null | undefined;
  employmentTypes: ClassEmploymentType[] | null | undefined;
  compensationTypes: ClassCompensationType[] | null | undefined;
  otherAttributes: string[] | null | undefined;

  // Details
  numberOfEmployees: number | null | undefined;
  minimumWeeklyHours: number | null | undefined;
  minimumWeeklyHoursAreNotCalculatedWeekly: boolean | null | undefined;
  minimumHoursDetails: string | null | undefined;

  // Earnings
  customEarnings: boolean | null;
  customEarningsDescription: string | null;
  earningsType: EarningsType | null;
  earningsTimeFrame: EarningsTimeFrame | null;
  currentEarnings: CurrentEarnings | null;
  includeFirstDayOfTheMonth: boolean | null;
  numberOfYears: NumberOfYears | null;
  frozenEarningsAsOf: string | null;
  additionalCompensations: string[] | null;
  averagedOverComissions: AveragedOver | null;
  averagedOverBonuses: AveragedOver | null;
  averagedOverOvertimePay: AveragedOver | null;
  additionalCompensationsAveragedWithEarnings: boolean | null;
  organizationType: OrganizationType | null;
  incomeTypePartnership: IncomeTypePartnership | null;
  incomeTypeSCorporation: IncomeTypeSCorporation | null;
  scheduleK1PaidByPolicyholder: YesNo | null;
  earningsPaidToShareholdersCompany: YesNo | null;

  // QPS
  groupName: string | null;
  importedFromQPS: boolean | null;

  employeeClassPlans: EmployeeClassPlanWithPlan[];
  qpsClasses: QPSClass[];

  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  deletedAt: Date | null;
  deletedBy: string | null;
}

type TimestampColumn =
  | "createdAt"
  | "createdBy"
  | "updatedAt"
  | "updatedBy"
  | "deletedAt"
  | "deletedBy";

export type CreateEmployeeClassInput = { clientId: string } & Partial<
  Omit<EmployeeClass, "id" | "clientId" | TimestampColumn>
>;

export type CreatePlanForEmployeeClassInput = {
  employeeClassPlan: CreateEmployeeClassPlanDataInput[];
  addWaitingPeriod: boolean;
  addContribution: boolean;
};

export type UpdateEmployeeClassInput = Pick<EmployeeClass, "id" | "clientId"> &
  Partial<Omit<EmployeeClass, TimestampColumn>>;

export type DeleteEmployeeClassInput = Pick<EmployeeClass, "id" | "clientId">;

export type DuplicateEmployeeClassInput = {
  eligibility: boolean;
  details: boolean;
  benefits: boolean;
  waitingPeriods: boolean;
  contributions: boolean;
  earnings: boolean;
};

export const displayEmploymentTypeName: Record<ClassEmploymentType, string> = {
  FULL_TIME: "Full-time",
  PART_TIME: "Part-time",
};

export const displayCompensationTypeName: Record<ClassCompensationType, string> = {
  SALARIED: "Salaried",
  HOURLY: "Hourly",
  EXEMPT: "Exempt",
  "NON-EXEMPT": "Non-exempt",
};

export function isClassEmploymentType(item: string): item is ClassEmploymentType {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isClassEmploymentType = classEmploymentTypes.includes(item as ClassEmploymentType);
  return isClassEmploymentType;
}

export function isClassCompensationType(item: string): item is ClassCompensationType {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isClassCompensationType = classCompensationTypes.includes(item as ClassCompensationType);
  return isClassCompensationType;
}

/**
 * Earnings
 */

// #region Earnings

export const earningsTypesLabels = [
  "Gross earnings",
  "W-2 earnings",
  "Earnings for Partners, Owners, and/or Shareholders",
] as const;
export const earningsTypesValues: readonly EarningsType[] = [
  "GROSS_EARNINGS",
  "W2_EARNINGS",
  "PARTNERS_OWNERS_SHAREHOLDERS",
];
export type EarningsTypeLabel = ValueOf<typeof earningsTypesLabels>;
export const earningsTypesValuesRecord: Record<EarningsType, EarningsTypeLabel> = {
  GROSS_EARNINGS: "Gross earnings",
  W2_EARNINGS: "W-2 earnings",
  PARTNERS_OWNERS_SHAREHOLDERS: "Earnings for Partners, Owners, and/or Shareholders",
};

export const earningsTimeFramesLabels = [
  "Current earnings",
  "Prior calendar year earnings",
  "Frozen earnings",
] as const;
export const earningsTimeFramesValues: readonly EarningsTimeFrame[] = [
  "CURRENT_EARNINGS",
  "PRIOR_CALENDAR_YEAR_EARNINGS",
  "FROZEN_EARNINGS",
];
export type EarningsTimeFrameLabel = ValueOf<typeof earningsTimeFramesLabels>;
export const earningsTimeFramesValuesRecord: Record<EarningsTimeFrame, EarningsTimeFrameLabel> = {
  CURRENT_EARNINGS: "Current earnings",
  PRIOR_CALENDAR_YEAR_EARNINGS: "Prior calendar year earnings",
  FROZEN_EARNINGS: "Frozen earnings",
};

export const currentEarningsLabels = ["Immediately", "First of the following month"] as const;
export const currentEarningsValues: readonly CurrentEarnings[] = [
  "IMMEDIATELY",
  "FIRST_OF_THE_FOLLOWING_MONTH",
];
export type CurrentEarningsLabel = ValueOf<typeof currentEarningsLabels>;
export const currentEarningsValuesRecord: Record<CurrentEarnings, CurrentEarningsLabel> = {
  IMMEDIATELY: "Immediately",
  FIRST_OF_THE_FOLLOWING_MONTH: "First of the following month",
};

export const numberOfYearsLabels = [
  "Prior calendar year",
  "Prior 2 calendar years",
  "Prior 3 calendar years",
] as const;
export const numberOfYearsValues: readonly NumberOfYears[] = [
  "PRIOR_CALENDAR_YEAR",
  "PRIOR_2_CALENDAR_YEARS",
  "PRIOR_3_CALENDAR_YEARS",
];
export type NumberOfYearsLabel = ValueOf<typeof numberOfYearsLabels>;
export const numberOfYearsValuesRecord: Record<NumberOfYears, NumberOfYearsLabel> = {
  PRIOR_CALENDAR_YEAR: "Prior calendar year",
  PRIOR_2_CALENDAR_YEARS: "Prior 2 calendar years",
  PRIOR_3_CALENDAR_YEARS: "Prior 3 calendar years",
};

export const additionalCompensationsMostCommon = [
  "Commissions",
  "Bonuses",
  "Overtime pay",
] as const;
export type AdditionalCompensationMostCommon = ValueOf<typeof additionalCompensationsMostCommon>;

export const additionalCompensationsOther = [
  "Shift differential",
  "Housing allowance",
  "Car allowance",
  "Profit sharing",
] as const;
export type AdditionalCompensationOther = ValueOf<typeof additionalCompensationsOther>;

export type AdditionalCompensationNames =
  | AdditionalCompensationMostCommon
  | AdditionalCompensationOther;
export const additionalCompensationsNames: readonly AdditionalCompensationNames[] = [
  "Commissions",
  "Bonuses",
  "Overtime pay",
  "Shift differential",
  "Housing allowance",
  "Car allowance",
  "Profit sharing",
];

export const rejectNonStandardAdditionalCompensationNames = (v: string) => {
  const result = additionalCompensationsNames.some(
    (acn) => acn.toLocaleLowerCase() === v.trim().toLocaleLowerCase(),
  );
  return result;
};

const additionalCompensationsString = additionalCompensationsNames.map((a) => String(a));

export const additionalCompensationsSorter = (a: string, b: string) => {
  if (additionalCompensationsString.includes(a) && additionalCompensationsString.includes(b))
    return additionalCompensationsString.indexOf(a) - additionalCompensationsString.indexOf(b);
  if (additionalCompensationsString.includes(a)) return -1;
  if (additionalCompensationsString.includes(b)) return 1;
  return a.localeCompare(b);
};

export const averagedOverLabels = [
  "12 months",
  "24 months",
  "36 months",
  "Prior calendar year",
  "Prior 2 calendar years",
  "Prior 3 calendar years",
] as const;
export const averagedOverValues: readonly AveragedOver[] = [
  "OVER_12_MONTHS",
  "OVER_24_MONTHS",
  "OVER_36_MONTHS",
  "PRIOR_CALENDAR_YEAR",
  "PRIOR_2_CALENDAR_YEARS",
  "PRIOR_3_CALENDAR_YEARS",
];
export type AveragedOverLabel = ValueOf<typeof averagedOverLabels>;
export const averagedOverValuesRecord: Record<AveragedOver, AveragedOverLabel> = {
  OVER_12_MONTHS: "12 months",
  OVER_24_MONTHS: "24 months",
  OVER_36_MONTHS: "36 months",
  PRIOR_CALENDAR_YEAR: "Prior calendar year",
  PRIOR_2_CALENDAR_YEARS: "Prior 2 calendar years",
  PRIOR_3_CALENDAR_YEARS: "Prior 3 calendar years",
};

export const organizationTypesLabels = [
  "Partnership (Form 1065 Schedule K)",
  "S Corporation (Form 1120S Schedule K)",
  "Sole Proprietor (Form 1040 Schedule C Net Income)",
] as const;
export const organizationTypesValues: readonly OrganizationType[] = [
  "PARTNERSHIP",
  "S_CORPORATION",
  "SOLE_PROPRIETOR",
] as const;
export type OrganizationTypeLabel = ValueOf<typeof organizationTypesLabels>;
export const organizationTypesValuesRecord: Record<OrganizationType, OrganizationTypeLabel> = {
  PARTNERSHIP: "Partnership (Form 1065 Schedule K)",
  S_CORPORATION: "S Corporation (Form 1120S Schedule K)",
  SOLE_PROPRIETOR: "Sole Proprietor (Form 1040 Schedule C Net Income)",
};

export const incomeTypesPartnershipLabels = [
  "Ordinary business income",
  "Guaranteed payments",
  "Ordinary business income plus guaranteed payments",
  "Net earnings from self-employment",
] as const;
export const incomeTypesPartnershipValues: readonly IncomeTypePartnership[] = [
  "ORDINARY_BUSINESS_INCOME",
  "GUARANTEED_PAYMENTS",
  "ORDINARY_BUSINESS_INCOME_PLUS_GUARANTEED_PAYMENTS",
  "NET_EARNINGS_FROM_SELF_EMPLOYMENT",
];
export type IncomeTypePartnershipLabel = ValueOf<typeof incomeTypesPartnershipLabels>;
export const incomeTypesPartnershipValuesRecord: Record<
  IncomeTypePartnership,
  IncomeTypePartnershipLabel
> = {
  ORDINARY_BUSINESS_INCOME: "Ordinary business income",
  GUARANTEED_PAYMENTS: "Guaranteed payments",
  ORDINARY_BUSINESS_INCOME_PLUS_GUARANTEED_PAYMENTS:
    "Ordinary business income plus guaranteed payments",
  NET_EARNINGS_FROM_SELF_EMPLOYMENT: "Net earnings from self-employment",
};

export const incomeTypesSCorporationLabels = [
  "Ordinary business income",
  "Ordinary business income with W-2 earnings",
] as const;
export const incomeTypesSCorporationValues: readonly IncomeTypeSCorporation[] = [
  "ORDINARY_BUSINESS_INCOME",
  "ORDINARY_BUSINESS_INCOME_WITH_W2_EARNINGS",
];
export type IncomeTypeSCorporationLabel = ValueOf<typeof incomeTypesSCorporationLabels>;
export const incomeTypesSCorporationValuesRecord: Record<
  IncomeTypeSCorporation,
  IncomeTypeSCorporationLabel
> = {
  ORDINARY_BUSINESS_INCOME: "Ordinary business income",
  ORDINARY_BUSINESS_INCOME_WITH_W2_EARNINGS: "Ordinary business income with W-2 earnings",
};

export const employeeClassEarningsFields = [
  "customEarnings",
  "customEarningsDescription",
  "earningsType",
  "earningsTimeFrame",
  "currentEarnings",
  "includeFirstDayOfTheMonth",
  "numberOfYears",
  "frozenEarningsAsOf",
  "additionalCompensations",
  "averagedOverComissions",
  "averagedOverBonuses",
  "averagedOverOvertimePay",
  "additionalCompensationsAveragedWithEarnings",
  "organizationType",
  "incomeTypePartnership",
  "incomeTypeSCorporation",
  "scheduleK1PaidByPolicyholder",
  "earningsPaidToShareholdersCompany",
] as const;

export type EmployeeClassEarnings = Pick<
  EmployeeClass,
  ValueOf<typeof employeeClassEarningsFields>
>;

export type EmployeeClassEarningsFields = keyof EmployeeClassEarnings;

// #endregion Earnings

export const getAveragedOverByCompensation = (
  employeeClass: EmployeeClass,
  compensation: AdditionalCompensationMostCommon,
) => {
  switch (compensation) {
    case "Commissions": {
      return employeeClass.averagedOverComissions;
    }
    case "Bonuses": {
      return employeeClass.averagedOverBonuses;
    }
    case "Overtime pay": {
      return employeeClass.averagedOverOvertimePay;
    }
    default:
      exhaustiveCheckFail(compensation);
  }
};

export const metEarningDependencyPath = (
  employeeClass: EmployeeClass,
  propertyToValidate: EmployeeClassEarningsFields,
): boolean => {
  switch (propertyToValidate) {
    case "earningsTimeFrame": {
      return employeeClass.earningsType === "GROSS_EARNINGS";
    }
    case "currentEarnings": {
      return (
        employeeClass.earningsType === "GROSS_EARNINGS" &&
        employeeClass.earningsTimeFrame === "CURRENT_EARNINGS"
      );
    }
    case "includeFirstDayOfTheMonth": {
      return (
        employeeClass.earningsType === "GROSS_EARNINGS" &&
        employeeClass.earningsTimeFrame === "CURRENT_EARNINGS" &&
        employeeClass.currentEarnings === "FIRST_OF_THE_FOLLOWING_MONTH"
      );
    }
    case "numberOfYears": {
      return (
        (employeeClass.earningsType === "GROSS_EARNINGS" &&
          employeeClass.earningsTimeFrame === "PRIOR_CALENDAR_YEAR_EARNINGS") ||
        employeeClass.earningsType === "W2_EARNINGS"
      );
    }
    case "frozenEarningsAsOf": {
      return (
        employeeClass.earningsType === "GROSS_EARNINGS" &&
        employeeClass.earningsTimeFrame === "FROZEN_EARNINGS"
      );
    }
    case "additionalCompensations": {
      return employeeClass.earningsType === "GROSS_EARNINGS";
    }
    case "averagedOverComissions": {
      return Boolean(
        employeeClass.earningsType === "GROSS_EARNINGS" &&
          employeeClass.additionalCompensations?.includes("Comissions"),
      );
    }
    case "averagedOverBonuses": {
      return Boolean(
        employeeClass.earningsType === "GROSS_EARNINGS" &&
          employeeClass.additionalCompensations?.includes("Bonuses"),
      );
    }
    case "averagedOverOvertimePay": {
      return Boolean(
        employeeClass.earningsType === "GROSS_EARNINGS" &&
          employeeClass.additionalCompensations?.includes("Overtime pay"),
      );
    }
    case "additionalCompensationsAveragedWithEarnings": {
      return employeeClass.earningsType === "GROSS_EARNINGS";
    }
    case "organizationType": {
      return employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS";
    }
    case "incomeTypePartnership": {
      return (
        employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
        employeeClass.organizationType === "PARTNERSHIP"
      );
    }
    case "incomeTypeSCorporation": {
      return (
        employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
        employeeClass.organizationType === "S_CORPORATION"
      );
    }
    case "scheduleK1PaidByPolicyholder": {
      return (
        employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
        employeeClass.organizationType === "PARTNERSHIP"
      );
    }
    case "earningsPaidToShareholdersCompany": {
      return (
        employeeClass.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" &&
        employeeClass.organizationType === "S_CORPORATION"
      );
    }
    case "customEarningsDescription": {
      return Boolean(employeeClass.customEarnings);
    }
    // does not have conditional render or logic
    case "earningsType":
    case "customEarnings": {
      return true;
    }
    default:
      exhaustiveCheckFail(propertyToValidate);
  }
};

export const areEarningsEqual = (employeeClassA: EmployeeClass, employeeClassB: EmployeeClass) => {
  for (const earningsField of employeeClassEarningsFields) {
    const isPropertyValidToCheck = metEarningDependencyPath(employeeClassA, earningsField);
    const isPropertyValueDifferent = !isEqual(
      employeeClassA[earningsField],
      employeeClassB[earningsField],
    );
    if (isPropertyValidToCheck && isPropertyValueDifferent) {
      return false;
    }
  }

  return true;
};

export const buildEmployeeClassesToExport = (
  client: Client,
  employeeClasses: EmployeeClass[],
  qpsClasses: QPSBasicClass[],
): EmployeeClass[] => {
  const onboardHasDepBasicADND = client.allPoliciesSlfCoverages?.includes("Basic Dep AD&D");
  const qpsHasDepBasicADND = qpsClasses.some(({ benefitCode }) => benefitCode === "DEPBASICLFE");

  // Onboard doesn't support Dependent Basic AD&D currently.
  // To pass the general properties of the class to QPS we create an in memory Dependent Basic AD&D class
  if (onboardHasDepBasicADND && qpsHasDepBasicADND) {
    return employeeClasses.map((employeeClass) => {
      const eeBasicADnDClass = employeeClass.employeeClassPlans.find(
        ({ plan }) => plan.benefitType === "BASIC_ADND",
      );

      if (!eeBasicADnDClass) {
        return employeeClass;
      }

      const depLifeClass = employeeClass.employeeClassPlans.find(
        ({ plan }) => plan.benefitType === "DEP_LIFE",
      );

      const inMemoryId = "_____in-memory-id_____";
      const inMemoryDepBasicPlanClass: EmployeeClassPlanWithPlan = {
        id: inMemoryId,
        employeeClassId: employeeClass.id,
        eeContributionAmount: depLifeClass?.eeContributionAmount ?? null,
        waitingPeriodDuration: eeBasicADnDClass.waitingPeriodDuration,
        waitingPeriodType: eeBasicADnDClass.waitingPeriodType,
        waitingPeriodUnit: eeBasicADnDClass.waitingPeriodUnit,
        employerContribution: null,
        employerContributionUnit: null,
        spouseContributionAmount: null,
        childrenContributionAmount: null,
        eeAndSpouseContributionAmount: null,
        eeAndChildrenContributionAmount: null,
        eeAndFamilyContributionAmount: null,
        familyContributionAmount: null,
        employerPremiumPayments: null,
        threeYearLookBackPercent: null,
        createdAt: new Date(),
        createdBy: inMemoryId,
        updatedAt: new Date(),
        updatedBy: inMemoryId,
        deletedAt: null,
        deletedBy: null,
        taxChoice: null,
        planId: inMemoryId,
        plan: {
          id: inMemoryId,
          benefitType: "BASIC_DEP_ADND",
          clientId: employeeClass.clientId,
          level: "NOT_APPLICABLE",
          terminationDate: null,
          salaryBased: false,
          pfmlOption: null,
          payeeListedOnTheCheckContactId: null,
          payeeListedOnTheCheckContact: null,
          sendClaimsCheckTo: null,
          claimsCheckPayee: null,
          planAdminPayeeContactId: null,
          someoneElsePayeeContactId: null,
          pfmlPremiumContributionType: null,
          pfmlEmployerContributionPercentage: null,
          pfmlEmployerPremiumPayments: null,
          pfmlThreeYearLookBackPercent: null,
          qpsClasses: [],
          createdBy: inMemoryId,
          createdAt: new Date(),
          updatedBy: inMemoryId,
          updatedAt: new Date(),
          deletedBy: null,
          deletedAt: null,
        },
      };

      return {
        ...employeeClass,
        employeeClassPlans: [...employeeClass.employeeClassPlans, inMemoryDepBasicPlanClass],
      };
    });
  }

  return employeeClasses;
};
