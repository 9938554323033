import { useEffect, useRef, useState } from "react";

import { useHubConfiguration } from "../hooks/useConfig";

export interface ReCaptchaProps {
  onSuccess?: (token: string) => void;
  onExpiration?: () => void;
  onError?: (err: Error) => void;
}

declare let grecaptcha: ReCAPTCHA;

export const useRecaptcha = (props: ReCaptchaProps) => {
  const [ready, setReady] = useState(false);
  const [rendered, setRendered] = useState(false);

  const config = useHubConfiguration();

  const recaptchaRef = useRef<Element | null>(null);

  // Watch for the reCaptcha API.
  useEffect(() => {
    // tslint:disable-next-line
    let timeoutId: number;
    function checkReCaptchaLoaded(): void {
      if (grecaptcha?.ready) {
        grecaptcha.ready(() => setReady(true));
      } else {
        timeoutId = window.setTimeout(checkReCaptchaLoaded, 500);
      }
    }
    checkReCaptchaLoaded();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  // Render the reCaptcha.
  useEffect(() => {
    if (
      ready &&
      !rendered &&
      recaptchaRef.current &&
      config.RECAPTCHA_SITE_KEY &&
      grecaptcha?.render
    ) {
      grecaptcha.render(recaptchaRef.current, {
        sitekey: config.RECAPTCHA_SITE_KEY,
        callback: props.onSuccess,
        "expired-callback": props.onExpiration,
        "error-callback": props.onError,
        size: "invisible",
      });
      setRendered(true);
    }
    if (!grecaptcha) {
      console.error("unable to render recaptcha, global undefined.");
    }
  }, [
    config.RECAPTCHA_SITE_KEY,
    props.onError,
    props.onExpiration,
    props.onSuccess,
    ready,
    rendered,
    recaptchaRef,
  ]);

  return {
    recaptchaRef,
    execute: (opt_widget_id?: string) =>
      new Promise<string>((resolve, reject) => {
        //make sure it's rendered
        if (!rendered) {
          reject("reCAPTCHA did not render");
          return;
        }

        if (!grecaptcha?.execute) {
          reject("reCAPTCHA global undefined");
          return;
        }

        grecaptcha?.execute(opt_widget_id);

        //we need to wait and keep checking until we get the token
        let timesChecked = 0;
        const checkForResult = () => {
          const token = grecaptcha?.getResponse(opt_widget_id);
          if (token) {
            resolve(token);
            return;
          }
          timesChecked++;
          if (timesChecked > 60) {
            reject("Timed out waiting for reCAPTCHA token");
          } else {
            setTimeout(checkForResult, 500);
          }
        };

        checkForResult();
      }),
    reset: (opt_widget_id?: string) => grecaptcha?.reset(opt_widget_id),
  };
};

/**
 * Interface for Google's reCAPTCHA JavaScript API.
 *
 * Display API
 * @see {@link https://developers.google.com/recaptcha/docs/display}
 *
 * Invisible API
 * @see {@link https://developers.google.com/recaptcha/docs/invisible}
 */
interface ReCAPTCHA {
  /**
   * Programatically invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a div
   * instead of a button.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  execute(opt_widget_id?: string): void;

  /**
   * Renders the container as a reCAPTCHA widget and returns the ID of the newly created widget.
   *
   * @param {Element|string} container The HTML element to render the reCAPTCHA widget.  Specify
   *    either the ID of the container (string) or the DOM element itself.
   * @param {Object} parameters An object containing parameters as key=value pairs, for example,
   *    {"sitekey": "your_site_key", "theme": "light"}.
   */
  render(container: Element | string, parameters: { [key: string]: unknown }): void;

  /**
   * Resets the reCAPTCHA widget.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  reset(opt_widget_id?: string): void;

  /**
   * Gets the response for the reCAPTCHA widget. Returns a null if reCaptcha is not validated.
   *
   * @param {string} opt_widget_id Optional widget ID, defaults to the first widget created if
   *     unspecified.
   */
  getResponse(opt_widget_id?: string): string;

  /**
   * ready() runs your function when the reCAPTCHA library loads. To avoid race conditions with
   * the api.js, include the api.js before your scripts that call grecaptcha, or continue to use
   * the onload callback that's defined with the v2 API.
   * @param callback
   */
  ready(callback: () => void): void;
}
