import { benefitTypesToCombinedCoverageNames } from "shared/utils/benefitTypesGroupings";
import { StackY } from "../../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../../components/Typography/Typography";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

type EIFClassBuilderBenefitListProps = {
  employeeClassPlans: EmployeeClassPlanWithPlan[];
  variant?: "default" | "bold";
  spacing?: "default" | "compact";
};

export const EIFClassBuilderBenefitList = ({
  employeeClassPlans,
  variant,
  spacing,
}: EIFClassBuilderBenefitListProps) => {
  const combinedCoverageNames = benefitTypesToCombinedCoverageNames(employeeClassPlans);

  const TagName = variant === "bold" ? Body2 : Body3;
  const spaceBetween = spacing === "compact" ? 1 : 4;

  if (combinedCoverageNames.length === 0) return null;

  return (
    <StackY dist={spaceBetween} wrap={false}>
      {combinedCoverageNames.map((sp) => (
        <TagName key={sp} as="div">
          {sp}
        </TagName>
      ))}
    </StackY>
  );
};
