import { ExplorerPageFormPresentation } from "./ExplorerPageFormPresentation";
import { useExplorerPageFormState } from "./useExplorerPageFormState";
import type { BenAdminPlatform } from "../../../../../shared/types/BenAdminPlatform";

import type { Carrier } from "../../../../../shared/types/Carrier";
import type { Client } from "../../../../../shared/types/Client";
import type { ExplorerPage } from "../../../../../shared/types/ExplorerPage";
import type { ExplorerPageBenefitInput } from "../../../../../shared/types/ExplorerPageBenefit";
import type { ClientFeatureToggles } from "../../../../../shared/types/Toggles";

export type ExplorerPageFormProps = {
  explorerPage?: ExplorerPage;
  onDelete?: VoidFunction;
  onCancel?: VoidFunction;
  carriers: Carrier[];
  benAdminPlatforms: BenAdminPlatform[];
  initialBenefits?: ExplorerPageBenefitInput[];
  client: Client;
  isRenewal: boolean;
  featureToggles?: ClientFeatureToggles;
  disabled?: boolean;
  slfPolicies?: string[];
};

export const ExplorerPageForm: React.FC<ExplorerPageFormProps> = (props) => {
  const state = useExplorerPageFormState(props);

  return <ExplorerPageFormPresentation {...state} />;
};
