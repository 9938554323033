import { ExplorerPlanGrouped } from "client/src/domain/ExplorerPages/ClientPage/Benefit/ExplorerPlanGrouped";
import { useGroupedPlans } from "client/src/hooks/plans";
import { assertIsDefined } from "shared/utils/utils";
import { StackY } from "../../../../components/Spacing/Spacing";
import { H2 } from "../../../../components/Typography/Typography";
import { useTranslation } from "../../../../i18n";

import { StatefulExplorerPlan } from "./ExplorerPlan";

import type { Language } from "../../../../../../shared/types/ExplorerPage";
import type { useExplorerTrackElementClicked } from "../../../../utils/analytics";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  BenefitPlans: "benefit-plans",
};

export type ExplorerBenefitPlansCopyStructure = {
  header_one: string;
  header_other: string;
};

type ExplorerBenefitPlansProps = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
  kioskMode: boolean;
  isFavoritePlan?: boolean;
  confirmDeselection?: boolean;
  onPlanDeselected?: (plan: ExplorerPageBenefit) => void;
  language: Language;
  trackElementClicked?: ReturnType<typeof useExplorerTrackElementClicked>;
  expiresAt?: Date | null;
  vasUpdateFeatureToggle: boolean;
  dentalPlansCombinedFeatureToggle: boolean;
};

export const ExplorerBenefitPlans = ({
  plans,
  kioskMode,
  isFavoritePlan,
  confirmDeselection,
  onPlanDeselected,
  language,
  trackElementClicked,
  expiresAt,
  vasUpdateFeatureToggle,
  dentalPlansCombinedFeatureToggle,
}: ExplorerBenefitPlansProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitPlans" });

  const { groupedPlans, ungroupedPlans } = useGroupedPlans({
    plans,
    dentalPlansCombinedFeatureToggle,
  });

  const offering = plans.reduce<PlanType | "MULTI_CHOICE" | null>((acc, { planType }) => {
    const effectivePlanType = planType === "ASO_PPO" || planType === "INDEMNITY" ? "PPO" : planType;
    if (acc && acc !== effectivePlanType) {
      return "MULTI_CHOICE";
    }
    return effectivePlanType;
  }, null);

  groupedPlans.forEach((gp) => {
    assertIsDefined(gp[0], "gp[0]");
  });

  return (
    <div data-testid={DataTestId.BenefitPlans}>
      {!isFavoritePlan && <H2>{t("header", { count: plans.length })}</H2>}

      <StackY dist={24}>
        {groupedPlans.map((gp) => (
          <ExplorerPlanGrouped
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above with assertIsDefined
            key={gp[0]!.id}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked above with assertIsDefined
            plan={gp[0]!}
            offering={offering}
            kioskMode={kioskMode}
            confirmDeselection={confirmDeselection}
            language={language}
            onPlanDeselected={onPlanDeselected}
            trackElementClicked={trackElementClicked}
            expiresAt={expiresAt}
            vasUpdateFeatureToggle={vasUpdateFeatureToggle}
            groupedPlans={gp}
          />
        ))}
        {ungroupedPlans.map((plan) => (
          <StatefulExplorerPlan
            key={plan.id}
            plan={plan}
            offering={offering}
            kioskMode={kioskMode}
            confirmDeselection={confirmDeselection}
            language={language}
            onPlanDeselected={onPlanDeselected}
            trackElementClicked={trackElementClicked}
            expiresAt={expiresAt}
            vasUpdateFeatureToggle={vasUpdateFeatureToggle}
          />
        ))}
      </StackY>
    </div>
  );
};
