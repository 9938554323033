import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { useState } from "react";

import { Button } from "../../components/Button/Button";
import { BenAdminCreateForm } from "../../domain/User/Form/BenAdminCreateForm";

import type { ClientId } from "shared/types/Client";

type BenAdminCreateProps = {
  clientId: ClientId;
};
export const BenAdminCreatePage = ({ clientId }: BenAdminCreateProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose={true}
        open={isModalVisible}
        footer={null}
        centered={true}
      >
        <BenAdminCreateForm
          clientId={clientId}
          handleClose={() => setIsModalVisible(false)}
          sendWelcomeEmail={true}
        />
      </Modal>
      <Button
        type="default"
        size="middle"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} /> Add Benefits Administrator
      </Button>
    </>
  );
};
