import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { getEIFSubStepMap } from "shared/types/EIF";
import { hasNonNullValues } from "shared/utils/utils";
import { insuredBySunLifeValidationSchema } from "shared/validation/client";
import { currentSunLifeCoverageInformation } from "shared/validation/companyDetailsFormKeys";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { RadioGroup } from "../../../components/Form/RadioGroup";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackY } from "../../../components/Spacing/Spacing";
import { TagList } from "../../../components/TagList/TagList";
import { Body2 } from "../../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../../hooks/useGetEIFPreviousAndNextLink";
import { useClientUtils } from "../../Client/useClientUtils";
import { EIFBottomNavButtons } from "../EIFBottomNavButtons";
import type { ElementClickedOptions } from "../../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

type EIFCurrentlyInsuredWithSunLifeProps = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: (options: ElementClickedOptions) => void;
  updateClient: UpdateClientFunc;
  authUser: UserData | null;
};

export const EIFCurrentlyInsuredWithSunLife = ({
  client,
  trackElementClicked,
  changeSnapshot,
  updateClient,
  authUser,
}: EIFCurrentlyInsuredWithSunLifeProps) => {
  const eifSubStepId = "current-sun-life-policies";
  const subStepName = getEIFSubStepMap({ eifSubStepId });
  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(currentSunLifeCoverageInformation),
    updateClient,
    validationSchema: insuredBySunLifeValidationSchema,
    type: "DEIF Insured By Sun Life",
    track,
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(currentSunLifeCoverageInformation)(client),
  );
  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, insuredBySunLifeValidationSchema, {
        prefill: false,
      })
    : {};

  const yesOption = {
    value: "YES",
    label: "Yes",
    content: formik.values.previouslyInsuredBySunLife === "YES" && (
      <div className="ml-32">
        <StackY dist={16}>
          <TagList
            name="previousSlfPolicies"
            placeholder="Add policy number and press enter"
            topText="Please provide your existing policy numbers"
            value={formik.values.previousSlfPolicies ?? undefined}
            onChange={formik.handleChange}
            touched={formik.touched.previousSlfPolicies || !!strictErrors.previousSlfPolicies}
            error={formik.errors.previousSlfPolicies || strictErrors.previousSlfPolicies}
          />
          <EditedFieldMsg
            changeDetailInfoList={[changeSnapshot.Client.previousSlfPolicies ?? null]}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "previousSlfPolicies",
              client,
              formik,
            })}
          />
        </StackY>
      </div>
    ),
  };
  const noOption = { value: "NO", label: "No" };
  const options = [yesOption, noOption];

  return (
    <>
      <StackY dist={40}>
        <h2 className="mb-40">{subStepName}</h2>
        <form onSubmit={formik.handleSubmit}>
          <StackY dist={32}>
            <HubCard>
              <StackY dist={16}>
                <RadioGroup
                  name="previouslyInsuredBySunLife"
                  label={
                    <Body2>
                      Do you currently have plans insured by Sun Life for Dental, Vision, Life,
                      Disability, or Supplemental Health benefits?
                    </Body2>
                  }
                  disabled={formik.isSubmitting}
                  touched={
                    formik.touched.previouslyInsuredBySunLife ||
                    Boolean(strictErrors.previouslyInsuredBySunLife)
                  }
                  error={
                    formik.errors.previouslyInsuredBySunLife ||
                    strictErrors.previouslyInsuredBySunLife
                  }
                  options={options}
                  direction="vertical"
                  value={formik.values.previouslyInsuredBySunLife ?? null}
                  defaultValue={noOption.value}
                  onChange={async (event) => {
                    const value = event.target.value;
                    if (value === "NO") {
                      await formik.setFieldValue("previousSlfPolicies", []);
                    }

                    formik.handleChange(event);
                  }}
                  fullWidth
                />
                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Client.previouslyInsuredBySunLife ?? null]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "previouslyInsuredBySunLife",
                    client,
                    formik,
                  })}
                />
              </StackY>
            </HubCard>
            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </form>

        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
