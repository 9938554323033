import clsx from "clsx";
import { DateTime } from "luxon";
import { getIsValidDate } from "shared/utils/date";

import { Body2, Body5 } from "../Typography/Typography";

import * as styles from "./calendarLabel.module.less";

import type { ReactNode } from "react";

export type CalendarLabelProps = {
  policyEffectives: Date[];
  continueButton: ReactNode;
  label: string;
  innerText: string | null;
};

export function CalendarLabel({
  policyEffectives,
  label,
  continueButton,
  innerText,
}: CalendarLabelProps) {
  if (!Array.isArray(policyEffectives) || policyEffectives.some((p) => !getIsValidDate(p))) {
    return (
      <div className={styles.calendar}>
        <div className={styles.calendarLabel}>{label}</div>

        <div className={styles.calendarInner}>
          <div>invalid date</div>
        </div>
      </div>
    );
  }

  const buildDate = (policyEffective: Date) => {
    const luxonInstance = DateTime.fromJSDate(policyEffective, { zone: "UTC" });

    const month = luxonInstance.monthLong;
    const day = luxonInstance.day;
    const startOfDate = DateTime.utc().startOf("day");
    const diff = luxonInstance.diff(startOfDate, "days");
    const daysDiffString = luxonInstance.toRelative({
      unit: "days",
      base: startOfDate,
    });
    return { month, day, diff, daysDiffString };
  };

  const buildDates = (policyEffectives: Date[]) => {
    if (policyEffectives.length === 1 && policyEffectives[0]) {
      const { month, day, diff, daysDiffString } = buildDate(policyEffectives[0]);
      return (
        <>
          <Body2 as="div">{month}</Body2>
          <div className={styles.calendarDay}>{day}</div>
          <div className={styles.calendarCountdown}>
            {diff.days === 0 ? "Today" : daysDiffString}
          </div>
        </>
      );
    }
    return (
      <div className={styles.multipleDates}>
        {policyEffectives.map((p) => {
          const { month, day, diff, daysDiffString } = buildDate(p);
          return (
            <div className={styles.dateElements} key={`${month}-${day}`}>
              <div className={clsx("body2", styles.calendarMonthDayMultiple)}>
                {month} {day}
              </div>
              <div className={styles.calendarCountdown}>
                {diff.days === 0 ? "Today" : daysDiffString}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <time
      className={
        policyEffectives.length <= 2
          ? styles.calendar
          : clsx(styles.calendar, styles.calendarMultiple)
      }
    >
      <div className={styles.calendarLabel}>
        <Body2>{label}</Body2>
      </div>

      <div
        className={
          policyEffectives.length <= 2 ? styles.calendarInner : styles.calendarInnerMultiple
        }
      >
        {innerText && (
          <div className={styles.subText}>
            <Body5>{innerText}</Body5>
          </div>
        )}
        {buildDates(policyEffectives)}
        <div className={styles.continueButton}>{continueButton}</div>
      </div>
    </time>
  );
}
