import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import { carrierInputValidation } from "../../../../shared/validation/carrier";
import { Button } from "../../components/Button/Button";
import { FormInput } from "../../components/Form/Input";
import { Spinner } from "../../components/Spinner/Spinner";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useCreateCarrier } from "../../hooks/carrier";
import { useSlobFormik } from "../../hooks/useSlobFormik";

import type { Carrier } from "../../../../shared/types/Carrier";

export type CreateCarrierFormStateProps = Parameters<typeof useCreateCarrierFormState>[0];

export const useCreateCarrierFormState = ({
  onCreate,
}: {
  onCreate?: (newCarrier: Carrier) => void;
}) => {
  const { mutateAsync: createCarrier } = useCreateCarrier();

  const formik = useSlobFormik({
    initialValues: { carrierName: "" },
    validationSchema: carrierInputValidation,
    onSubmit: async (values) => {
      const { data: carrier } = await createCarrier({ data: values });
      formik.resetForm();
      void slobMessage.success("Successfully added carrier!");
      onCreate?.(carrier);
    },
  });

  return { formik };
};

export const CreateCarrierForm = ({ formik }: ReturnType<typeof useCreateCarrierFormState>) => {
  return (
    <form>
      <Row className={clsx("px-16", "pt-12")} gutter={16} align="middle">
        <Col flex={1}>
          <FormInput
            value={formik.values.carrierName}
            maxLength={191}
            label="Carrier Name"
            name="carrierName"
            allowClear
            disabled={formik.isSubmitting}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setStatus(null);
            }}
            touched={formik.touched.carrierName}
            error={formik.status}
          />
        </Col>
        <Col>
          <Button
            disabled={formik.isSubmitting}
            type="default"
            size="small"
            onClick={() => formik.handleSubmit()}
          >
            {formik.isSubmitting ? <Spinner size="small" /> : "+ Add Carrier"}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export const CreateCarrierFormStateful = (stateProps: CreateCarrierFormStateProps) => {
  const props = useCreateCarrierFormState(stateProps);
  return <CreateCarrierForm {...props} />;
};
