import { Modal } from "antd";
import { BenAdminUpdateForm } from "client/src/domain/User/Form/BenAdminUpdateForm";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useListParams } from "client/src/hooks/useListParams";
import { useState } from "react";

import { SlobList } from "../../components/SlobList/SlobList";
import { BenAdminTable } from "../../domain/User/List/BenAdminTable";
import { useGetBenAdmins } from "../../hooks/user";
import type { BenAdminTableProps } from "../../domain/User/List/BenAdminTable";

import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenAdmin } from "shared/types/User";

export const BEN_ADMIN_LIST_PAGE_SIZE = 25;

type BenAdminListProps = {
  featureToggles?: ClientFeatureToggles;
};

export const BenAdminList = ({ featureToggles }: BenAdminListProps) => {
  const [editState, setEditState] = useState<BenAdmin | null>(null);

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (benAdmin: BenAdmin) => {
    setEditState(benAdmin);
  };

  const listParams = useListParams({
    initialPageSize: BEN_ADMIN_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "lastName",
    },
  });

  const { clientId } = useClientHubParams();

  const query = useGetBenAdmins({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...(clientId && { clientId }),
  });

  return (
    <>
      <SlobList
        TableComponent={(props: BenAdminTableProps) => (
          <BenAdminTable
            {...props}
            hideActionButtonByKey={["change-to-broker", "send-welcome-email", "unassign"]}
          />
        )}
        query={query}
        listParams={listParams}
        showModal={showModal}
        featureToggles={featureToggles}
      />
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <BenAdminUpdateForm benAdmin={editState} handleClose={hideModal} />
        </Modal>
      )}
    </>
  );
};
