import { Form, Input, Typography } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { assertIsDefined } from "shared/utils/utils";
import { ticketIdValidator } from "shared/validation/ticket";
import { AlertBanner } from "../../../components/Banner/AlertBanner";

import { Button } from "../../../components/Button/Button";
import { genericErrorCopy } from "../../../components/Error/ErrorMessage";
import { StackY } from "../../../components/Spacing/Spacing";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import {
  useCreateClient,
  useUpdateClient,
  useGetClientInfoFromTicketByTicketId,
} from "../../../hooks/client";
import { ResponseError } from "../../../hooks/query";

import { AddClientInfo } from "./AddClientInfo";
import * as styles from "./form.module.less";

const { Title, Text } = Typography;

type ActionType = "create" | "update";

type AddOrUpdateClientFormProps = {
  clearFilter?: () => void;
  onClientAction: () => void;
  actionType: ActionType;
  clientId?: string;
};

export const AddOrUpdateClientForm = ({
  actionType,
  clearFilter,
  onClientAction,
  clientId,
}: AddOrUpdateClientFormProps) => {
  // This form instance is needed for onChange events to work
  const [form] = Form.useForm();
  const [fieldValue, setFieldValue] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [validationError, setValidationError] = useState("");
  const [requestError, setRequestError] = useState("");

  const navigate = useNavigate();

  const { mutateAsync: createClient, isPending: isAddingClient } = useCreateClient();
  const { mutateAsync: updateClient, isPending: isUpdatingClient } = useUpdateClient();
  const {
    data: clientInfo,
    error: clientError,
    isFetching: isFetchingInfo,
  } = useGetClientInfoFromTicketByTicketId(ticketId);

  const clientInfoError = clientError
    ? ResponseError.getUserFacingErrorMessage(clientError, genericErrorCopy)
    : "";

  const reset = () => {
    setTicketId("");
    setRequestError("");
    setValidationError("");
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    reset();
    const value = e.target.value;
    const match = /maxwellhealth.atlassian.net.*(BUILD-\d\d*)/i.exec(value);
    if (match?.length) {
      assertIsDefined(match[1], "match[1]");
      return setFieldValue(match[1].toUpperCase().trim());
    }
    const fieldValue = value.toUpperCase().trim();
    ticketIdValidator.validate(fieldValue).catch(({ message }) => {
      setValidationError(message);
    });
    setFieldValue(fieldValue);
  };

  const onAddClient = async () => {
    if (!ticketId) return;
    try {
      const { data } = await createClient({ data: { ticketId } });
      clearFilter?.();
      void slobMessage.success("Successfully created client");
      onClientAction();
      navigate(RouteData.clientDetail.getPath(data.id));
    } catch (error) {
      const msg = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy);
      setRequestError(msg);
    }
  };

  const onUpdateClient = async () => {
    if (!ticketId || !clientId) return;
    try {
      await updateClient({ data: { ticketId }, params: { clientId } });
      clearFilter?.();
      void slobMessage.success("Successfully updated client");
      onClientAction();
      navigate(RouteData.homeInternal.getPath());
    } catch (error) {
      const msg = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy);
      setRequestError(msg);
    }
  };

  const lookup = () => {
    if (validationError) return;
    setTicketId(fieldValue);
  };

  return (
    <StackY dist={24} className={styles.form}>
      {actionType === "create" && <Title>Add New Client</Title>}
      {actionType === "update" && <Title>Link Client</Title>}

      <p>
        <Text>Clients must be in Jira. Enter the JIRA project ID to lookup the client.</Text>
      </p>

      <Form form={form}>
        <Form.Item
          validateStatus={ticketId && validationError ? "error" : "success"}
          help={validationError}
        >
          <div className={styles.searchContainer}>
            <Input
              name="ticketId"
              value={fieldValue}
              placeholder="i.e. BUILD-123456 or Jira link"
              aria-label="JIRA project ID"
              onChange={onInputChange}
              disabled={isFetchingInfo}
            />
            <Button
              type="secondary"
              loading={isFetchingInfo}
              disabled={!fieldValue || Boolean(validationError)}
              onClick={lookup}
            >
              Lookup
            </Button>
          </div>
        </Form.Item>
      </Form>
      {clientInfo && <AddClientInfo clientInfo={clientInfo} />}
      {requestError && clientInfoError ? (
        <AlertBanner variant="error" message={clientInfoError} />
      ) : (
        <>
          {requestError && <AlertBanner variant="error" message={requestError} />}
          {clientInfoError && <AlertBanner variant="error" message={clientInfoError} />}
        </>
      )}
      {actionType === "create" && (
        <Button
          loading={isAddingClient}
          disabled={!clientInfo}
          type="primary"
          onClick={onAddClient}
          data-testid="submit"
        >
          Add Client
        </Button>
      )}
      {actionType === "update" && (
        <Button
          loading={isUpdatingClient}
          disabled={!clientInfo}
          type="primary"
          onClick={onUpdateClient}
          data-testid="submit"
        >
          Link Client
        </Button>
      )}
    </StackY>
  );
};
