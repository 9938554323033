import React from "react";

const CircleNotStarted: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M12 20a8 8 0 110-16 8 8 0 010 16z"
      fill="#FAFAFC"
      stroke="#E4E4EB"
      strokeWidth={2}
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CircleInProgress: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="#C7C9D9" d="M12 20a8 8 0 110-16 8 8 0 010 16z" />
      <path stroke="#05A660" d="M12 4a8 8 0 018 8" />
    </g>
  </svg>
);

const CircleCompleted: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g stroke="#05A660" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path strokeWidth={2} d="M12 20a8 8 0 110-16 8 8 0 010 16z" />
      <path strokeWidth={1.5} d="M8 12.333L10.454 15 16 9" />
    </g>
  </svg>
);

const CircleDeclined: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#B81F41" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 20a8 8 0 110-16 8 8 0 010 16z" fill="#FFF" />
      <path d="M9 15l6-6M9 9l6 6" />
    </g>
  </svg>
);

type ProgressCircleProps = {
  variant: "not-started" | "in-progress" | "completed" | "declined";
} & React.SVGProps<SVGSVGElement>;

export const ProgressCircle: React.FC<ProgressCircleProps> = ({ variant, ...props }) => {
  switch (variant) {
    case "completed":
      return <CircleCompleted data-testid="progressCircleCompleted" {...props} />;
    case "in-progress":
      return <CircleInProgress data-testid="progressCircleInProgress" {...props} />;
    case "declined":
      return <CircleDeclined data-testid="progressCircleDeclined" {...props} />;
    default:
      return <CircleNotStarted data-testid="progressCircleNotStarted" {...props} />;
  }
};
