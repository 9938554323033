import { Input } from "antd";
import { getIsEmptySpan } from "client/src/utils/getIsEmptySpan";
import clsx from "clsx";
import { useState, useCallback, forwardRef } from "react";

import { useSlobId } from "../../hooks/useSlobId";
import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type { CommonFormInputsProps } from "./formTypes";
import type { InputRef } from "antd";
import type { HTMLInputTypeAttribute, KeyboardEventHandler } from "react";

export type InputProps = CommonFormInputsProps & {
  value: string | number | readonly string[] | null | undefined;
  maxLength: number;
  isPassword?: boolean;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  topText?: React.ReactNode;
  bottomText?: React.ReactNode;
  allowClear?: boolean;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  "aria-describedby"?: string;
};

export const FormInput = forwardRef<InputRef, InputProps>(
  (
    {
      label,
      name,
      value,
      error,
      onChange,
      onBlur,
      onFocus,
      maxLength,
      disabled = false,
      touched = false,
      isPassword = false,
      showRequired,
      suffix,
      prefix,
      topText,
      bottomText,
      allowClear,
      readOnly,
      "aria-describedby": ariaDescribedBy,
      ...rest
    }: InputProps,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const isPrefixEmpty = getIsEmptySpan(prefix) || !prefix;
    const isSuffixEmpty = getIsEmptySpan(suffix) || !suffix;
    const shouldShowError = error && touched;
    const shouldUseWrapper = !isPrefixEmpty || !isSuffixEmpty || isPassword;

    const hasValue = value != null && value !== "";

    const labelClass = clsx(
      styles.formLabel,
      { [styles.formLabelOver]: hasValue || isFocused },
      { [styles.formLabelDisabled]: disabled },
    );
    const containerClass = !shouldUseWrapper
      ? styles.fieldContainer
      : clsx(
          styles.wrapperFieldContainer,
          !readOnly && isFocused && !isPassword && styles.wrapperFieldContainer__focused,
          !isPrefixEmpty && styles.wrapperFieldContainer__prefixed,
          !isSuffixEmpty && !isPassword && styles.wrapperFieldContainer__suffixed,
          hasValue && !isPassword && styles.wrapperFieldContainer__filled,
          readOnly && styles.wrapperFieldContainer__readOnly,
        );
    const inputClass = shouldUseWrapper
      ? shouldShowError
        ? styles.inputError
        : ""
      : clsx(styles.formInput, { [styles.inputError]: shouldShowError });

    const onInputFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (!readOnly) {
          setIsFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }
      },
      [readOnly, onFocus],
    );

    const onInputBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false);
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur],
    );

    const id = useSlobId({ prefix: name });

    const errorId = shouldShowError ? `${id}__errormessage` : undefined;
    const topDescriptionId = topText ? `${id}__top-description` : undefined;
    const bottomDescriptionId = bottomText ? `${id}__bottom-description` : undefined;

    const props = {
      ...rest,
      id,
      name: name,
      value: value == null ? undefined : value,
      "aria-invalid": !!shouldShowError,
      "aria-describedby": [topDescriptionId, bottomDescriptionId, ariaDescribedBy].join(" ").trim(),
      "aria-errormessage": shouldShowError ? errorId : undefined,
      onChange: onChange,
      maxLength: maxLength,
      onFocus: onInputFocus,
      onBlur: onInputBlur,
      disabled: disabled,
      className: inputClass,
      suffix: suffix,
      prefix: prefix,
      allowClear: allowClear,
      readOnly: readOnly,
    };

    const input = !isPassword ? (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- disable
      // @ts-ignore - this is allowed
      <Input {...props} ref={ref} />
    ) : (
      <Input.Password {...props} ref={ref} />
    );

    return (
      <div className={containerClass}>
        {topText && typeof topText === "string" && (
          <p id={topDescriptionId} className={clsx("body2", styles.topText)}>
            {topText}
          </p>
        )}
        {topText && typeof topText !== "string" && (
          <div id={topDescriptionId} className={clsx("body2", styles.topText)}>
            {topText}
          </div>
        )}

        <label className={labelClass} htmlFor={id}>
          {label}
          {showRequired ? "*" : ""}
        </label>

        {input}

        <div aria-live="assertive">
          {shouldShowError && <InputErrorMessage id={errorId} error={error} disabled={disabled} />}
        </div>

        {bottomText && (
          <span id={bottomDescriptionId} className={clsx("body5", styles.bottomText)}>
            {bottomText}
          </span>
        )}
      </div>
    );
  },
);
