import { Body2 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderVoluntaryEditable } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Voluntary/EIFClassBuilderVoluntaryEditable";
import { ReviewVoluntaryContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Voluntary/ReviewVoluntaryContributions";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type Props = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  isLoading: boolean;
  benefitType: "VOL_STD" | "VOL_LTD";
} & (
  | {
      readonly: true;
    }
  | {
      readonly: false;
      onSave: (employeeClass: EmployeeClass) => void;
      track: SectionTracker;
      setContributionsQuery: SetContributionQuery;
    }
);

export const EIFClassBuilderVoluntaryContributions = (props: Props) => {
  const {
    client,
    employeeClass,
    changeSnapshot,
    authUser,
    benefitType,
    isLoading,
    readonly,
    isActive,
  } = props;

  if (readonly) {
    return (
      <>
        <Body2 as="p">{benefitTypeToCoverage[benefitType]}</Body2>

        <ReviewVoluntaryContributions
          client={client}
          benefitType={benefitType}
          employeeClass={employeeClass}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
        />
      </>
    );
  }

  return (
    <EIFClassBuilderVoluntaryEditable
      isActive={isActive}
      employeeClass={employeeClass}
      client={client}
      changeSnapshot={changeSnapshot}
      authUser={authUser}
      setContributionsQuery={props.setContributionsQuery}
      onSave={props.onSave}
      track={props.track}
      isLoading={isLoading}
      benefitType={benefitType}
    />
  );
};
