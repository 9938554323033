import { Button } from "client/src/components/Button/Button";
import { Modal } from "client/src/components/Modal/Modal";
import { StackX } from "client/src/components/Spacing/Spacing";
import { Body1 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { yesNoRequired } from "shared/validation/validation";
import * as Yup from "yup";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Client } from "shared/types/Client";

const employeeClassQuestionSchema = Yup.object({
  needsEmployeeClasses: yesNoRequired,
});

type SwitchToMultipleEmployeeGroupsModalProps = {
  client: Client;
  isVisibleMultipleGroupModal: boolean;
  onCancel: () => void;
  updateClient: UpdateClientFunc;
};

export const SwitchToMultipleEmployeeGroupsModal = ({
  client,
  isVisibleMultipleGroupModal,
  onCancel,
  updateClient,
}: SwitchToMultipleEmployeeGroupsModalProps) => {
  const formik = useSlobFormik({
    validationSchema: employeeClassQuestionSchema,
    initialValues: {
      needsEmployeeClasses: client.needsEmployeeClasses || "",
    },
    onSubmit: async ({ needsEmployeeClasses }) => {
      try {
        if (client.needsEmployeeClasses !== needsEmployeeClasses) {
          await updateClient({
            params: { clientId: client.id },
            data: { needsEmployeeClasses },
          });
        }
      } catch (error) {
        void slobMessage.error("failed to update to multiple employee classes");
      }
    },
  });

  const onClick = async () => {
    await formik.setFieldValue("needsEmployeeClasses", "YES");
    formik.handleSubmit();
  };

  return (
    <Modal
      open={isVisibleMultipleGroupModal}
      title={"Switching to multiple employee groups"}
      footer={null}
      onCancel={onCancel}
      disableClose={formik.isSubmitting}
      destroyOnClose
    >
      <Body1 as="p">You are choosing to create additional eligible employee groups.</Body1>
      <Body1 as="p">
        Please review the information entered for your initial employee group and define eligibility
        before adding new groups.
      </Body1>
      <div className="mt-32">
        <StackX dist={24}>
          <Button
            type="primary"
            size="middle"
            htmlType="button"
            disabled={formik.isSubmitting}
            onClick={onClick}
          >
            Continue
          </Button>

          <span>
            <Button
              type="text-only"
              size="middle"
              htmlType="button"
              onClick={onCancel}
              disabled={formik.isSubmitting}
            >
              Cancel
            </Button>
          </span>
        </StackX>
      </div>
    </Modal>
  );
};
