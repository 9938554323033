import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Body3, Body2, Body5 } from "client/src/components/Typography/Typography";
import { getCoverageWithPolicyNumbersLabel } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getCoverageWithPolicyNumbersLabel";
import { getPrefillErrorsForMonthlyClaimsReportsAndEOBs } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/monthlyClaimsReportsAndEOBs";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getContactLocation } from "client/src/utils/getContactLocations";
import memoizeOne from "memoize-one";
import { getIsChangeDetailInfo, wasEntityAddedInLatestICEdit } from "shared/utils/EIF/changeLog";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { formatFullName } from "shared/utils/format";
import { contactWithAddressValidationSchema } from "shared/validation/contact";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo, ChangeMetadata, DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { MonthlyClaimsReportMailingLocation } from "shared/types/MonthlyClaimsReportMailingLocation";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const contactDemographicValidationSchema = contactWithAddressValidationSchema.pick([
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
]);

type Props = {
  "data-testid"?: string;
  index: number | null;
  disabled: boolean;
  client: Client;
  mailingLocation: MonthlyClaimsReportMailingLocation;
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
  onEdit?: () => void;
  onDelete?: () => void;
};

export function MonthlyClaimsReportsAndEOBsReviewCard(props: Props) {
  const {
    "data-testid": dataTestId,
    index,
    disabled,
    client,
    mailingLocation,
    changeSnapshot,
    authUser,
    onEdit,
    onDelete,
  } = props;

  const viewMode = getEIFSubStepViewMode({ client });

  const e = getPrefillErrorsForMonthlyClaimsReportsAndEOBs(
    mailingLocation,
    viewMode,
    changeSnapshot,
  );

  const hasPrefillErrors = Object.keys(e).length > 0;

  const showPlanAdminInfoError = getShowPlanAdminInfoError({
    client,
    mailingLocation,
    changeSnapshot,
    viewMode,
  });

  const changeDetailInfoList = new Array<ChangeDetailInfo | ChangeMetadata | undefined>()
    .concat(
      ...Object.values(
        changeSnapshot.MonthlyClaimsReportMailingLocation[mailingLocation.id ?? ""] ?? {},
      ),
    )
    .filter(getIsChangeDetailInfo);

  const WrappingComponent = index != null ? FlatCard : "div";

  return (
    <WrappingComponent data-testid={dataTestId} disabled={disabled}>
      <Row wrap={false} gutter={20}>
        {index != null && (
          <Col flex="1">
            <h5>Mailing Location {index}</h5>
          </Col>
        )}

        {onEdit && (
          <Col flex="0 1 auto">
            <Button type="text-only" size="middle" disabled={disabled} onClick={onEdit}>
              Edit
            </Button>
          </Col>
        )}

        {onDelete && (
          <Col flex="0 1 auto">
            <Button type="text-only" size="middle" disabled={disabled} onClick={onDelete}>
              Delete
            </Button>
          </Col>
        )}
      </Row>

      {hasPrefillErrors && (
        <Row gutter={[32, 18]} className="mb-16">
          <Col flex="1">
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Click <Body2>Edit</Body2> to complete this section.
                </Body3>
              }
            />
          </Col>
        </Row>
      )}

      {showPlanAdminInfoError && (
        <Row gutter={[32, 18]} className="mb-16">
          <Col flex="1">
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Please go to the <Body2>Plan Administrators</Body2> step to finish setting up this
                  user.
                </Body3>
              }
            />
          </Col>
        </Row>
      )}

      <Row gutter={[32, 18]}>
        <Col>
          <Body5 as="div">Mailing Location</Body5>

          {mailingLocation.sendMonthlyClaimsReportsTo === "PRIMARY_PLAN_ADMINISTRATOR" && (
            <>
              {client.webAdminContact ? (
                <ContactData
                  contact={client.webAdminContact}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />
              ) : (
                <Body3 as="div">Primary Plan Administrator</Body3>
              )}
            </>
          )}

          {mailingLocation.sendMonthlyClaimsReportsTo === "OTHER" && (
            <>
              {mailingLocation.sendMonthlyClaimsReportsToContact ? (
                <ContactData
                  contact={mailingLocation.sendMonthlyClaimsReportsToContact}
                  viewMode={viewMode}
                  changeSnapshot={changeSnapshot}
                />
              ) : (
                <>
                  {e.sendMonthlyClaimsReportsToContactId ? (
                    <Body3 as="div" redMedium>
                      {e.sendMonthlyClaimsReportsToContactId}
                    </Body3>
                  ) : (
                    <Body3 as="div">-</Body3>
                  )}
                </>
              )}
            </>
          )}

          {e.sendMonthlyClaimsReportsTo && (
            <Body3 as="div" redMedium>
              {e.sendMonthlyClaimsReportsTo}
            </Body3>
          )}

          {mailingLocation.sendMonthlyClaimsReportsTo == null && !e.sendMonthlyClaimsReportsTo && (
            <Body3>-</Body3>
          )}
        </Col>

        <Col span={24}>
          <Body5 as="div">This mailing location applies to</Body5>
          {mailingLocation.slfCoverages?.map((slfCoverage) => (
            <Body3 key={slfCoverage} as="div">
              {getCoverageWithPolicyNumbersLabel({
                client,
                slfCoverage,
              })}
            </Body3>
          ))}

          {e.slfCoverages && (
            <Body3 as="div" redMedium>
              {e.slfCoverages}
            </Body3>
          )}

          {(mailingLocation.slfCoverages == null || mailingLocation.slfCoverages.length === 0) &&
            !e.slfCoverages && <Body3>-</Body3>}
        </Col>

        <Col className="hide:empty">
          <EditedFieldMsg
            changeDetailInfoList={changeDetailInfoList}
            client={client}
            authUser={authUser}
            isAdd={wasEntityAddedInLatestICEdit(mailingLocation, client)}
          />
        </Col>
      </Row>
    </WrappingComponent>
  );
}

function ContactData(props: {
  contact: Contact;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
}) {
  const { contact, viewMode, changeSnapshot } = props;

  const { contactErrors, locationErrors } = getContactErrors(contact, changeSnapshot, viewMode);

  return (
    <>
      {contact.firstName &&
      contact.lastName &&
      !contactErrors.firstName &&
      !contactErrors.lastName ? (
        <Body3 as="div">{formatFullName(contact)}</Body3>
      ) : (
        <div>
          <Body3 redMedium={!!contactErrors.firstName}>
            {contact.firstName || contactErrors.firstName || "-"}
          </Body3>{" "}
          <Body3 redMedium={!!contactErrors.lastName}>
            {contact.lastName || contactErrors.lastName || "-"}
          </Body3>
        </div>
      )}

      {getContactLocation(contact, locationErrors, true)}

      {contactErrors.email && (
        <Body3 as="div" redMedium>
          {contactErrors.email}
        </Body3>
      )}

      {contactErrors.phoneNumber && (
        <Body3 as="div" redMedium>
          {contactErrors.phoneNumber}
        </Body3>
      )}
    </>
  );
}

const getContactErrors = memoizeOne(function (
  contact: Contact,
  changeSnapshot: DEIFChangeSnapshot,
  viewMode: EIFSubStepViewMode,
) {
  const showValidationErrorsInSummary = getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const contactErrors = showValidationErrorsInSummary
    ? getFormikErrors(contact, contactDemographicValidationSchema, { prefill: false, bills: [] })
    : {};

  const locationErrors = showValidationErrorsInSummary
    ? getValidationErrors(locationInputValidation, contact.location || {}, { prefill: false })
    : [];

  const isIncomplete = Object.keys(contactErrors).length > 0 || locationErrors.length > 0;

  return { contactErrors, locationErrors, isIncomplete };
});

function getShowPlanAdminInfoError(props: {
  client: Client;
  mailingLocation: MonthlyClaimsReportMailingLocation;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
}) {
  const { client, mailingLocation, changeSnapshot, viewMode } = props;

  const showValidationErrorsInSummary = getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  if (!showValidationErrorsInSummary) return false;

  if (mailingLocation.sendMonthlyClaimsReportsTo == null) {
    return false;
  }

  if (mailingLocation.sendMonthlyClaimsReportsTo === "PRIMARY_PLAN_ADMINISTRATOR") {
    if (client.webAdminContact == null) return true;

    const { isIncomplete } = getContactErrors(client.webAdminContact, changeSnapshot, viewMode);

    return isIncomplete;
  }

  if (mailingLocation.sendMonthlyClaimsReportsTo === "OTHER") {
    if (mailingLocation.sendMonthlyClaimsReportsToContact == null) return false;

    const { isIncomplete } = getContactErrors(
      mailingLocation.sendMonthlyClaimsReportsToContact,
      changeSnapshot,
      viewMode,
    );
    return isIncomplete;
  }

  exhaustiveCheckFail(mailingLocation.sendMonthlyClaimsReportsTo);
}
