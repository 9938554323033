import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { getIn } from "formik";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { getLocationDropdownOptions } from "../../utils/getLocationDropdownOptions";
import { LocationModalProvider } from "./LocationModalProvider";
import type { FormikErrors, FormikProps } from "formik";
import type { FC } from "react";
import type { Client } from "shared/types/Client";
import type { Location, LocationId } from "shared/types/Location";

export type LocationDropdownProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I need to support any shape of formik props.
  formik: FormikProps<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I need to support any shape of formik props.
  prefillErrors: FormikErrors<any>;
  name: string;
  clientId: Client["id"];
  locations: Location[];
  readOnlyLocationId?: LocationId;
  disabled?: boolean;
};

export const LocationDropdown: FC<LocationDropdownProps> = ({
  formik,
  prefillErrors,
  name,
  clientId,
  locations,
  readOnlyLocationId,
  disabled,
}) => {
  const handleLocationSaved = (location: Location) => {
    void formik.setFieldValue(name, location.id);
  };

  return (
    <LocationModalProvider clientId={clientId}>
      {({ locationModalVisible, showLocationModal, onLocationModalSave }) => {
        onLocationModalSave(handleLocationSaved);

        const options = getLocationDropdownOptions(
          locations,
          showLocationModal,
          readOnlyLocationId,
        );

        const value = getIn(formik.values, name);

        return (
          <StackY dist={8} wrap={false}>
            <SlobSelect
              id={name}
              name={name}
              value={value}
              onChange={(val) => {
                if (val) {
                  void formik.setFieldValue(name, val.value);
                }
              }}
              options={options}
              placeholder={"Select a location"}
              disabled={formik.isSubmitting || disabled}
              onBlur={formik.handleBlur}
              error={getIn(formik.errors, name) || prefillErrors[name]}
              touched={getIn(formik.touched, name) || prefillErrors[name]}
              open={locationModalVisible ? false : undefined}
              optionLabelProp="aria-label"
            />

            <div>
              <Button
                type="text-only"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                size="middle"
                onClick={() => showLocationModal()}
                disabled={formik.isSubmitting || disabled}
              >
                Create another location
              </Button>
            </div>
          </StackY>
        );
      }}
    </LocationModalProvider>
  );
};
