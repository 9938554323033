import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { formatLocation } from "../../../shared/utils/location";
import { Body3 } from "../components/Typography/Typography";
import type { SlobSelectProps } from "../components/Form/SlobSelect";
import type { LabeledValue } from "antd/lib/select";
import type { Location, LocationId } from "shared/types/Location";

export const getLocationDropdownOptions = (
  locations: Location[],
  onEditLocation: (location: Location) => void,
  readOnlyLocationId?: LocationId,
): SlobSelectProps<LabeledValue>["options"] =>
  locations.map((l) => ({
    "aria-label": formatLocation(l),
    label: (
      <Row>
        <Col flex={1}>
          <Body3>{formatLocation(l)}</Body3>{" "}
        </Col>
        <Col>
          {(readOnlyLocationId ? l.id !== readOnlyLocationId : true) && (
            <Button
              type="text-only"
              onClick={(e) => {
                e.stopPropagation();
                onEditLocation(l);
              }}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
    ),
    value: l.id,
  }));
