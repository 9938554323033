import { useGetClientDEIFChanges } from "client/src/hooks/changeLogs";
import { useGetContacts } from "client/src/hooks/contact";
import { useGetClasses } from "client/src/hooks/employeeClass";
import { useGetReadyToSignOnboardingFormsRecipients } from "client/src/hooks/onboardingForms";
import { useGetSubsidiariesByClientId } from "client/src/hooks/subsidiary";
import { DentalASODeductible } from "client/src/pages/TaskDetail/DentalASODeductible/DentalASODeductible";
import { DentalRolloverPage } from "client/src/pages/TaskDetail/DentalRollover/DentalRolloverPage";
import { Navigate } from "react-router-dom";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { assertIsDefined } from "shared/utils/utils";

import { RouteData } from "../../../../shared/config/routeData";
import { isPolicyTaskId, isClientTaskId } from "../../../../shared/utils/client";
import { ChildPageSkeleton } from "../../components/ChildPageLayout/ChildPageLayout";
import { Loading } from "../../components/Loading/Loading";
import { useSlobAuth } from "../../hooks/auth";
import { useGetBills, useGetDeletedBills } from "../../hooks/bill";
import { useGetClientByID } from "../../hooks/client";
import { useGetDocuments, useMarkNewDocumentNoticesAsSeen } from "../../hooks/document";
import { triggerError } from "../../hooks/generalError";
import { useGetPlansByClientId } from "../../hooks/plans";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useGetFeatureToggles } from "../../hooks/useFeatureToggles";
import { EnrollmentResourcesPage } from "../EnrollmentResourcesPage/EnrollmentResourcesPage";

import { CensusPage } from "./CensusPage/CensusPage";
import { CompanyProfilePage } from "./CompanyProfile/CompanyProfilePage";
import { EIFSubmitCompanyInformation } from "./EIFSubmitCompanyInformation/EIFSubmitCompanyInformation";
import { FillOutBenefitsDocuments } from "./FillOutBenefitsDocuments/FillOutBenefitsDocuments";
import { InstallCallPage } from "./InstallCall/InstallCallPage";
import { KickoffCallPage } from "./KickOffCall/KickoffCallPage";
import { PriorCarrierBillsPage } from "./PriorCarrierBills/PriorCarrierBillsPage";
import { PriorCarrierCertificatesPage } from "./PriorCarrierCertificates/PriorCarrierCertificatesPage";
import { SubmitPlanExemptionDocumentsForPFML } from "./SubmitPlanExemptionDocumentsForPFML/SubmitPlanExemptionDocumentsForPFML";
import { SummaryPlanDescriptionforPFML } from "./SummaryPlanDescriptionForPFML/SummaryPlanDescriptionforPFML";
import { SunAdvisorSPD } from "./SunAdvisorSPD/SunAdvisorSPD";
import { SunLifeConnectPage } from "./SunLifeConnect/SunLifeConnect";

export const TaskDetailPage = () => {
  const { clientId, taskId, policyId } = useClientHubParams(["clientId"]);

  const { isLoading, isLoadingError, isError, error, data: client } = useGetClientByID(clientId);
  const {
    isLoading: isLoadingFTs,
    isLoadingError: isLoadingErrorFTs,
    isError: isErrorFTs,
    error: errorFTs,
    data: featureToggles,
  } = useGetFeatureToggles(clientId);
  const {
    data: bills,
    isLoading: isLoadingBills,
    isError: isErrorBills,
    error: errorBills,
  } = useGetBills(clientId);
  const {
    data: deletedBills,
    isLoading: isLoadingDeletedBills,
    isError: isErrorDeletedBills,
    error: errorDeletedBills,
  } = useGetDeletedBills(clientId);
  const {
    data: clientPlans,
    isLoading: isLoadingClientPlans,
    isError: isErrorClientPlans,
    error: errorClientPlans,
  } = useGetPlansByClientId(clientId);
  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    error: errorContacts,
  } = useGetContacts(clientId);

  const {
    data: employeeClasses,
    isInitialLoading: isLoadingClasses,
    isError: isErrorClasses,
    error: errorClasses,
  } = useGetClasses(clientId, {
    enabled: taskId === "eif-submit-company-information",
  });

  const {
    data: changes,
    isInitialLoading: isLoadingChanges,
    isError: isErrorChanges,
    error: errorChanges,
  } = useGetClientDEIFChanges(clientId, {
    enabled: taskId === "eif-submit-company-information",
  });

  const {
    data: readyToSignOnboardingFormsMetadata,
    isError: isErrorReadyToSign,
    isLoading: isLoadingContactsReadyToSign,
  } = useGetReadyToSignOnboardingFormsRecipients(clientId, {
    enabled: taskId === "eif-submit-company-information",
  });

  const {
    data: billingSummaryStatementTemplates,
    isLoading: isLoadingBillingSummaryStatementTemplates,
    isError: isErrorBillingSummaryStatementTemplates,
    error: errorBillingSummaryStatementTemplates,
  } = useGetDocuments({ clientId, categories: ["billing-summary-statement-template"] });

  const {
    data: subsidiaries,
    isLoading: isLoadingSubsidiaries,
    isError: isErrorSubsidiaries,
    error: errorSubsidiaries,
  } = useGetSubsidiariesByClientId(clientId);

  const { authUser } = useSlobAuth();
  const userData = authUser;

  useMarkNewDocumentNoticesAsSeen(clientId ?? "");

  if (
    isLoading ||
    isLoadingFTs ||
    isLoadingBills ||
    isLoadingDeletedBills ||
    isLoadingContacts ||
    isLoadingClientPlans ||
    isLoadingClasses ||
    isLoadingChanges ||
    isLoadingBillingSummaryStatementTemplates ||
    isLoadingSubsidiaries ||
    isLoadingContactsReadyToSign
  ) {
    return taskId === "enrollment-resources" ? <Loading /> : <ChildPageSkeleton />;
  }

  if (isError || isLoadingError) {
    return triggerError(error);
  }
  if (isErrorFTs || isLoadingErrorFTs || !featureToggles) {
    return triggerError(errorFTs);
  }
  if (isErrorBills) {
    triggerError(errorBills);
  }
  if (isErrorDeletedBills) {
    triggerError(errorDeletedBills);
  }
  if (isErrorContacts) {
    return triggerError(errorContacts);
  }
  if (isErrorClientPlans) {
    triggerError(errorClientPlans);
  }
  if (isErrorChanges) {
    triggerError(errorChanges);
  }
  if (isErrorClasses) {
    triggerError(errorClasses);
  }
  if (isErrorReadyToSign) {
    triggerError(isErrorReadyToSign);
  }
  if (isErrorBillingSummaryStatementTemplates) {
    triggerError(errorBillingSummaryStatementTemplates);
  }
  if (isErrorSubsidiaries) {
    triggerError(errorSubsidiaries);
  }

  if (!featureToggles) {
    return triggerError(`Could not load feature toggles for client`);
  }

  if (!clientId || !client) {
    return triggerError(`Invalid Client ID`);
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  if (isPolicyTaskId(taskId)) {
    const policies = client.policies;
    const policy = policies.find((p) => p.id === policyId);
    const fallbackPolicy = policies.find(
      (p) => p.policyTasks?.some((policyTask) => policyTask.id === taskId) ?? false,
    );
    if (!policy && fallbackPolicy) {
      return (
        <Navigate
          to={RouteData.policyTaskDetail.getPath(client.id, taskId, fallbackPolicy.id)}
          replace
        />
      );
    }
    if (!policy) {
      return triggerError(`Policy ID does not exist for multi-policy Client`);
    }
    const policyTask = (policy?.policyTasks || []).find((t) => t.id === taskId);

    if (!policyTask) {
      return triggerError(`Policy Task ID is not valid for this client: ${taskId}`);
    }

    switch (taskId) {
      case "prior-carrier-bills":
        return <PriorCarrierBillsPage client={client} policy={policy} policyTask={policyTask} />;
      case "enrollment-elections":
        return (
          <CensusPage
            client={client}
            policy={policy}
            policyTask={policyTask}
            featureToggles={featureToggles}
          />
        );
      case "pfml":
        return <SubmitPlanExemptionDocumentsForPFML client={client} task={policyTask} />;
      case "pfml-spd":
        return (
          <SummaryPlanDescriptionforPFML
            client={client}
            policy={policy}
            task={policyTask}
            featureToggles={featureToggles}
          />
        );
      case "sunadvisor-spd":
        return <SunAdvisorSPD client={client} task={policyTask} policy={policy} />;

      case "dental-rollover":
        return <DentalRolloverPage client={client} policyTask={policyTask} policy={policy} />;

      case "deductible-maximum-report":
        return (
          <DentalASODeductible
            client={client}
            policyTask={policyTask}
            policy={policy}
            featureToggles={featureToggles}
          />
        );

      default:
        exhaustiveCheck(taskId);
        return triggerError();
    }
  }

  if (isClientTaskId(taskId)) {
    const task = client.tasks?.find((task) => task.id === taskId);

    if (!task) {
      return triggerError(`Task is not applicable to this client: ${taskId}`);
    }

    switch (taskId) {
      case "kickoff-call": {
        return <KickoffCallPage client={client} task={task} />;
      }
      case "company-profile": {
        return <CompanyProfilePage client={client} task={task} userData={userData} />;
      }
      case "prior-carrier-certificates": {
        return <PriorCarrierCertificatesPage client={client} task={task} />;
      }
      case "onboarding-forms": {
        return (
          <FillOutBenefitsDocuments client={client} task={task} featureToggles={featureToggles} />
        );
      }

      case "enrollment-resources":
        return <EnrollmentResourcesPage client={client} featureToggles={featureToggles} />;
      case "ongoing-service-call":
        return <InstallCallPage client={client} task={task} featureToggles={featureToggles} />;
      case "sun-life-connect":
        return <SunLifeConnectPage client={client} />;
      case "eif-submit-company-information":
        assertIsDefined(bills, "bills");
        assertIsDefined(deletedBills, "deletedBills");
        assertIsDefined(authUser, "authUser");
        assertIsDefined(contacts, "contacts");
        assertIsDefined(employeeClasses, "employeeClasses");
        assertIsDefined(readyToSignOnboardingFormsMetadata, "readyToSignOnboardingFormsMetadata");
        return (
          <EIFSubmitCompanyInformation
            client={client}
            task={task}
            bills={bills}
            deletedBills={deletedBills}
            contacts={contacts}
            billingSummaryStatementTemplates={billingSummaryStatementTemplates ?? []}
            clientPlans={clientPlans ?? []}
            changes={changes ?? []}
            employeeClasses={employeeClasses}
            authUser={authUser}
            featureToggles={featureToggles}
            subsidiaries={subsidiaries ?? []}
            readyToSignOnboardingFormsMetadata={readyToSignOnboardingFormsMetadata}
          />
        );
      default: {
        exhaustiveCheck(taskId);
        return triggerError();
      }
    }
  }
  return triggerError(`Invalid Task ID ${taskId}`);
};
