import { useCallback, useState } from "react";
import { RouteData } from "shared/config/routeData";

import { isAddCoverageClient } from "shared/utils/client";
import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { GuidanceModal } from "../../../components/GuidanceModal/GuidanceModal";
import { HubCardSection } from "../../../components/HubCard/HubCardSection";
import { SlobLink } from "../../../components/SlobLink/SlobLink";
import { StackY } from "../../../components/Spacing/Spacing";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { KickoffCallCard } from "../../../domain/Client/KickoffCallCard";
import { KickoffCallForm } from "../../../domain/Client/KickoffCallForm";
import { useSlobAuth } from "../../../hooks/auth";
import { useUpdateClient } from "../../../hooks/client";
import { useTitle } from "../../../hooks/useTitle";
import { useToggler } from "../../../hooks/useToggler";
import { useTrackElementClicked } from "../../../utils/analytics";
import { hasRemainingHubTasks } from "../../Hub/Hub";

import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";

export type KickoffCallProps = {
  client: Client;
  task: Task;
};

export function KickoffCallPage({ client, task }: KickoffCallProps): JSX.Element {
  useTitle("Kickoff Call");
  const [editing, toggleEditing] = useToggler();
  const [guidanceModalVisible, setGuidanceModalVisible] = useState(false);
  const { mutateAsync: updateClient, isPending, error } = useUpdateClient();
  const trackElementClicked = useTrackElementClicked(client, "Kickoff Call");

  const remainingTasks = hasRemainingHubTasks(client);
  const isInitialSave = client.kickoffCallUpdatedAt === null;

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const completedMessage = {
    yes: {
      heading: "You've requested your Kickoff Call!",
      body: "Your Implementation Consultant will reach out to you with next steps.",
    },
    no: {
      heading: "You've declined your Kickoff Call.",
      body: "You have indicated that you don't want a kickoff call. Let us know if this changes.",
    },
  };

  const completeFormsClick = useCallback(() => {
    trackElementClicked({
      buttonLabel: "Complete and Sign Onboarding Forms",
    });
  }, [trackElementClicked]);

  const handleNo = async () => {
    try {
      const { isError, isSuccess } = await updateClient({
        data: {
          kickoffCallSelection: "NO",
          kickoffCallSchedule: null,
          kickoffCallTopics: null,
          kickoffCallPeople: null,
        },
        params: { clientId: client.id },
      });

      if (isError) {
        void slobMessage.error(genericErrorCopy2);
      } else if (isSuccess) {
        isInitialSave
          ? setGuidanceModalVisible(true)
          : void slobMessage.success("Kick Off Call Updated");
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    }
  };

  const isAddCoverage = isAddCoverageClient(client);

  return (
    <ChildPageLayout
      topNavTitle="Setup your Kickoff Call"
      aside={
        <>
          <h1>Kickoff Call</h1>

          <p>
            {isAddCoverage
              ? `A kickoff call is a great way to get started onboarding your new benefits with Sun Life.
            Your Implementation Consultant will cover several important topics to help you prepare
            for adding your new benefits.`
              : `A kickoff call is a great way to start your relationship with Sun Life. Your
            Implementation Consultant will cover several important topics that will help you be
            prepared for your onboarding.`}
          </p>
        </>
      }
      contents={
        <StackY dist={40}>
          {!isInternalUser && (
            <GuidanceModal
              isVisible={guidanceModalVisible}
              remainingTasks={remainingTasks}
              clientId={client.id}
              onCancel={() => setGuidanceModalVisible(false)}
              nextText={
                client.kickoffCallSelection === "YES"
                  ? completedMessage.yes.body
                  : completedMessage.no.body
              }
              titleText={
                client.kickoffCallSelection === "YES"
                  ? completedMessage.yes.heading
                  : completedMessage.no.heading
              }
              taskStatus={task.status}
              trackElementClicked={trackElementClicked}
            />
          )}
          <HubCardSection
            title="What you’ll cover with your Implementation Consultant"
            content={
              isAddCoverage ? (
                <UnorderedList>
                  <UnorderedListItem>
                    Understand if you'll be holding an enrollment event and discuss timelines.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    We'll walk you through filling out your onboarding forms and how to submit
                    employee enrollments.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Set some milestones to achieve during onboarding.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Align on next steps and onboarding timeline.
                  </UnorderedListItem>
                </UnorderedList>
              ) : (
                <UnorderedList>
                  <UnorderedListItem>
                    Your goals and which tools would be the best fit for you & your needs.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Develop a plan around your enrollment event if you’re holding one.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    We’ll walk you through filling out your onboarding forms and how to submit
                    employee enrollments.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Set some milestones to achieve during onboarding.
                  </UnorderedListItem>
                  <UnorderedListItem>
                    Align on next steps and onboarding timeline.
                  </UnorderedListItem>
                </UnorderedList>
              )
            }
          />

          {editing ? (
            <KickoffCallForm
              client={client}
              toggleEditing={toggleEditing}
              updateClient={updateClient}
              trackElementClicked={trackElementClicked}
              isInitialSave={isInitialSave}
              onInitialSave={setGuidanceModalVisible}
            />
          ) : (
            <KickoffCallCard
              client={client}
              toggleEditing={toggleEditing}
              handleNo={handleNo}
              isLoading={isPending}
              trackElementClicked={trackElementClicked}
              error={error}
              completedMessage={completedMessage}
            />
          )}
          <div>
            <h3>How to prepare for your kickoff call</h3>

            <p>
              If you have time, review your onboarding forms under the{" "}
              <SlobLink
                variant="normal"
                to={RouteData.clientTaskDetail.getPath(client.id, "onboarding-forms")}
                onClick={completeFormsClick}
              >
                <i>Complete and Sign Onboarding Forms</i>
              </SlobLink>{" "}
              page before the meeting. Your implementation forms are unique to you and your group’s
              needs and benefits. By reviewing, you have an opportunity to identify any questions
              you may have beforehand so that we can make sure to cover these during our time
              together. You’ll have the opportunity to submit those questions when requesting your
              Kickoff Call.
            </p>
          </div>
        </StackY>
      }
    />
  );
}
