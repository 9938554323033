import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { unionMembersAndDomesticPartnersValidationSchema } from "shared/validation/client";

import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { unionMembersAndDomesticPartnersFormKeyProps } from "shared/validation/otherContractDetailsFormKeys";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { SlobPopover } from "../../components/SlobPopover/SlobPopover";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const formTestId = "form";

export function EIFUnionMembersAndDomesticPartners(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const eifSubStepId = "union-members-and-domestic-partners";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(
      unionMembersAndDomesticPartnersFormKeyProps,
    ),
    updateClient,
    validationSchema: unionMembersAndDomesticPartnersValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(unionMembersAndDomesticPartnersFormKeyProps)(client),
  );

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, unionMembersAndDomesticPartnersValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>
        <PFMLBulbMessage client={client} />

        <StackY dist={40}>
          <StackY dist={32}>
            <HubCard>
              <StackY dist={32}>
                <StackY dist={16}>
                  <RadioGroup
                    name="domesticPartnersCovered"
                    label={
                      <>
                        <Body2 as="p">
                          Are domestic partners covered?{" "}
                          <SlobPopover
                            id="more-info"
                            title={null}
                            content={
                              <>
                                A domestic partnership is a legal or personal relationship between
                                two individuals who live together and share a common domestic life,
                                but are neither joined by marriage nor a civil union. A domestic
                                partner may be covered as a dependent on Sun Life policies.
                              </>
                            }
                            placement="top"
                            trigger={["click", "hover"]}
                          >
                            <button
                              className="btn-reset"
                              style={{ color: "var(--color-gray-600)" }}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                              <span className="sr-only">Learn more about domestic partnership</span>
                            </button>
                          </SlobPopover>
                        </Body2>

                        <p>
                          If your state requires that domestic partners are covered, then Sun Life
                          will automatically cover domestic partners.
                        </p>
                      </>
                    }
                    disabled={formik.isSubmitting}
                    touched={
                      formik.touched.domesticPartnersCovered ||
                      !!strictErrors.domesticPartnersCovered
                    }
                    error={
                      formik.errors.domesticPartnersCovered || strictErrors.domesticPartnersCovered
                    }
                    options={[
                      {
                        value: "YES",
                        label: "Yes",
                      },
                      { value: "NO", label: "No" },
                    ]}
                    direction="vertical"
                    value={formik.values.domesticPartnersCovered}
                    onChange={formik.handleChange}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.domesticPartnersCovered]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "domesticPartnersCovered",
                      client,
                      formik,
                    })}
                  />
                </StackY>

                <StackY dist={16}>
                  <RadioGroup
                    name="unionMembersCovered"
                    label={
                      <>
                        <Body2 as="p">Are union members covered?</Body2>

                        <p>
                          If union members are covered, please provide Sun Life with a copy of the
                          union's collective bargaining agreement. You only need to provide the
                          sections pertaining to group benefits.
                        </p>
                      </>
                    }
                    disabled={formik.isSubmitting}
                    touched={
                      formik.touched.unionMembersCovered || !!strictErrors.unionMembersCovered
                    }
                    error={formik.errors.unionMembersCovered || strictErrors.unionMembersCovered}
                    options={[
                      {
                        value: "YES",
                        label: "Yes",
                      },
                      { value: "NO", label: "No" },
                    ]}
                    direction="vertical"
                    value={formik.values.unionMembersCovered}
                    onChange={formik.handleChange}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.unionMembersCovered]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "unionMembersCovered",
                      client,
                      formik,
                    })}
                  />
                </StackY>
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>

          <hr />

          <div>
            <h2>Why does Sun Life need this information?</h2>

            <p>
              Knowing if union members and/or domestic partners are covered can impact your contract
              and how your benefits are set up. Sun Life compares the union's collective bargaining
              agreement to confirm those details in your benefits setup.
            </p>

            <h2>What should you know about how states recognize domestic partners?</h2>

            <p>
              Several states have expanded the legal rights available to spouses in same-sex
              relationships through civil unions and domestic partnerships. California, District of
              Columbia, Maine, Nevada, Oregon, Washington and Wisconsin allow for domestic
              partnerships while Hawaii allows for a similar relationship known as reciprocal
              beneficiaries.
            </p>

            <p>Laws on domestic partnership can vary by state.</p>
          </div>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
