import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonOld } from "client/src/components/Button/ButtonOld";
import * as styles from "./dropdownButton.module.less";

import type { BaseButtonProps } from "../Button/ButtonOld";

type DropdownButtonProps = BaseButtonProps & {
  size?: "xtra-small" | "small";
};

// props required per this issue: https://github.com/ant-design/ant-design/issues/15528
export const DropdownButton = ({ size = "small", ...rest }: DropdownButtonProps) => {
  const buttonClass = size === "xtra-small" ? styles.dropdownButtonSmall : styles.dropdownButton;
  return (
    <ButtonOld
      {...rest}
      className={buttonClass}
      type="tertiary"
      size={size}
      data-testid="dropdown-menu-button"
    >
      <FontAwesomeIcon icon={faEllipsisH} />
    </ButtonOld>
  );
};
