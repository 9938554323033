import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getSalaryBasedClassPlans } from "shared/types/BenefitTypes";
import {
  duplicateClassValidationSchema,
  duplicateFieldNamesToCheck,
} from "shared/validation/employeeClass";
import { Button } from "../../../../components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "../../../../components/Error/ErrorMessage";
import { Checkbox } from "../../../../components/Form/Checkbox";
import { InputErrorMessage } from "../../../../components/Form/InputErrorMessage";
import { Modal } from "../../../../components/Modal/Modal";
import { StackX, StackY } from "../../../../components/Spacing/Spacing";
import { slobMessage } from "../../../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { useSlobId } from "../../../../hooks/useSlobId";
import { hasFormikError } from "../../../../utils/hasFormikError";
import type { DuplicateEmployeeClassQuery } from "../../../../hooks/employeeClass";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";

type Props = {
  isVisible: boolean;
  onCancel: () => void;
  employeeClass: EmployeeClass;
  clientPlans: Plan[];
  duplicateEmployeeClassQuery: DuplicateEmployeeClassQuery;
};

export function EIFEmployeeClassDuplicateModal(props: Props) {
  const {
    isVisible,
    onCancel,
    employeeClass,
    clientPlans,
    duplicateEmployeeClassQuery: { mutateAsync: duplicateEmployeeClass },
  } = props;

  const navigate = useNavigate();

  const formik = useSlobFormik({
    validationSchema: duplicateClassValidationSchema,
    initialValues: {
      eligibility: false,
      details: false,
      benefits: false,
      waitingPeriods: false,
      contributions: false,
      earnings: false,
    },
    onSubmit: async () => {
      const { isSuccess, data: duplicatedEmployeeClass } = await duplicateEmployeeClass({
        params: { clientId: employeeClass.clientId, employeeClassId: employeeClass.id },
        data: formik.values,
      });

      if (isSuccess) {
        void slobMessage.success("Class duplicated successfully");
        navigate(
          RouteData.eifClassBuilderEdit.getPath(
            employeeClass.clientId,
            "plan-configuration-&-eligibility",
            "class-builder",
            duplicatedEmployeeClass.id,
          ),
        );
      }
    },
  });

  const hasMultipleBenefits = new Set(clientPlans.map((p) => p.benefitType)).size > 1;

  const selectionError =
    duplicateFieldNamesToCheck
      .map((attr) => formik.touched[attr] && formik.errors[attr])
      .filter((err): err is string => Boolean(err))[0] || undefined;

  const selectionErrorIdString = useSlobId({
    prefix: "duplicate-employee-class-selection__errormessage",
  });
  const selectionErrorId = selectionError ? selectionErrorIdString : undefined;

  const salaryBasedClassPlans = getSalaryBasedClassPlans(employeeClass);
  const showEarningsCheckbox = salaryBasedClassPlans.length > 0;

  return (
    <Modal
      open={isVisible}
      title={"Duplicate eligible employee group"}
      footer={null}
      onCancel={onCancel}
      disableClose={formik.isSubmitting}
      destroyOnClose
    >
      <form onSubmit={formik.handleSubmit}>
        <p>Select what you would like to copy:</p>

        <StackY dist={16}>
          <Checkbox
            name="eligibility"
            label="Eligibility"
            checked={Boolean(formik.values.eligibility)}
            errorId={hasFormikError(formik, "eligibility") ? selectionErrorId : undefined}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />

          <Checkbox
            name="details"
            label="Details"
            checked={Boolean(formik.values.details)}
            errorId={hasFormikError(formik, "details") ? selectionErrorId : undefined}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
          />

          {hasMultipleBenefits && (
            <Checkbox
              name="benefits"
              label="Benefits"
              checked={Boolean(formik.values.benefits)}
              errorId={hasFormikError(formik, "benefits") ? selectionErrorId : undefined}
              onChange={async (e) => {
                const checked = e.target.checked;
                if (!checked) {
                  await formik.setFieldValue("waitingPeriods", false);
                  await formik.setFieldValue("contributions", false);
                  await formik.setFieldValue("earnings", false);
                }
                await formik.setFieldValue("benefits", checked);
              }}
              disabled={formik.isSubmitting}
            />
          )}

          <div className={hasMultipleBenefits ? "ml-32" : undefined}>
            <Checkbox
              name="waitingPeriods"
              label="Waiting Periods"
              checked={Boolean(formik.values.waitingPeriods)}
              errorId={hasFormikError(formik, "waitingPeriods") ? selectionErrorId : undefined}
              onChange={formik.handleChange}
              disabled={
                (hasMultipleBenefits ? !formik.values.benefits : false) || formik.isSubmitting
              }
            />
          </div>

          <div className={hasMultipleBenefits ? "ml-32" : undefined}>
            <Checkbox
              name="contributions"
              label="Contributions"
              checked={Boolean(formik.values.contributions)}
              errorId={hasFormikError(formik, "contributions") ? selectionErrorId : undefined}
              onChange={formik.handleChange}
              disabled={
                (hasMultipleBenefits ? !formik.values.benefits : false) || formik.isSubmitting
              }
            />
          </div>

          {showEarningsCheckbox && (
            <div className={hasMultipleBenefits ? "ml-32" : undefined}>
              <Checkbox
                name="earnings"
                label="Earnings definition"
                checked={Boolean(formik.values.earnings)}
                errorId={hasFormikError(formik, "earnings") ? selectionErrorId : undefined}
                onChange={formik.handleChange}
                disabled={
                  (hasMultipleBenefits ? !formik.values.benefits : false) || formik.isSubmitting
                }
              />
            </div>
          )}

          <div aria-live="assertive">
            {selectionError && <InputErrorMessage id={selectionErrorId} error={selectionError} />}
          </div>

          <div aria-live="assertive">
            {formik.status && <ErrorMessage>{GenericErrorCopy2}</ErrorMessage>}
          </div>
        </StackY>

        <div className="mt-32">
          <StackX dist={24}>
            <Button type="primary" size="middle" htmlType="submit" loading={formik.isSubmitting}>
              Duplicate
            </Button>

            <span>
              <Button
                type="text-only"
                size="middle"
                htmlType="button"
                onClick={onCancel}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </span>
          </StackX>
        </div>
      </form>
    </Modal>
  );
}
