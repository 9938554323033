import clsx from "clsx";
import { useState } from "react";

import { i18n } from "../../i18n";
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import { H3 } from "../Typography/Typography";

import { slobMessage } from "../slobMessage/slobMessage";
import { ExplorerFeedbackForm } from "./ExplorerFeedbackForm";
import { OnboardFeebackForm } from "./OnboardFeebackForm";
import * as styles from "./giveFeedbackForm.module.less";

type GiveFeedbackFormProps = {
  sendNativeFeedbackForm: (feedback: string, contact: "YES" | "NO") => void;
  sendExplorerFeedbackForm: (feedback: string, rating?: number | undefined) => void;
  onClose: () => void;
  position?: "left" | "right";
  variant?: "onboard" | "explorer";
  title?: string;
};

export function GiveFeedbackForm({
  sendNativeFeedbackForm,
  sendExplorerFeedbackForm,
  onClose,
  position = "left",
  variant = "onboard",
  title,
}: GiveFeedbackFormProps) {
  const [triggerClose, setTriggerClose] = useState<boolean>(false);

  const closeFeedbackForm = () => {
    setTriggerClose(true);
  };

  const containerStyle = clsx([
    styles.giveFeedbackForm,
    "p-32",
    triggerClose && styles.giveFeedbackFormOut,
    position === "right" && styles.right,
  ]);

  const containerOnAnimationEnd = () => {
    if (triggerClose) {
      onClose();
    }
  };

  return (
    <div className={containerStyle} onAnimationEnd={containerOnAnimationEnd}>
      <button
        className={styles.closeIcon}
        aria-label="Close"
        title="Close"
        onClick={closeFeedbackForm}
      >
        <CloseIcon width="16" height="16" />
      </button>

      <H3 as="p">{title ? title : "Give Feedback"}</H3>

      {variant === "explorer" ? (
        <ExplorerFeedbackForm
          sendExplorerFeedbackForm={sendExplorerFeedbackForm}
          onSubmit={() => {
            const message = i18n.t("ExplorerFeedbackForm.success");
            void slobMessage.success(message);
            closeFeedbackForm();
          }}
          onCancel={closeFeedbackForm}
        />
      ) : (
        <OnboardFeebackForm
          sendNativeFeedbackForm={sendNativeFeedbackForm}
          closeFeedbackForm={closeFeedbackForm}
        />
      )}
    </div>
  );
}
