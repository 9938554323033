import { Col, Row } from "client/src/components/Grid/Grid";
import { NavigationHeader } from "client/src/components/NavigationHeader/NavigationHeader";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { getIsAllowedToReviewEIF } from "client/src/domain/EIF/common/utils/getIsAllowedToReviewEIF";
import { useSlobAuth } from "client/src/hooks/auth";
import { useChangeSnapshot } from "client/src/hooks/changeLogs";
import { useSuspenseEIFQueries } from "client/src/hooks/client";
import { useUpdatePolicy } from "client/src/hooks/policy";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useTitle } from "client/src/hooks/useTitle";
import { EIFStepPageLoading } from "client/src/pages/EIFStepPage/EIFStepPage";
import { EIFSubStepPolicyPageContents } from "client/src/pages/EIFStepPage/EIFSubStepPolicyPageContents";
import { useTrackElementClicked } from "client/src/utils/analytics";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getEIFSubStepMap } from "shared/types/EIF";
import { assertIsDefined } from "shared/utils/utils";
import * as styles from "./EIFStepPage.module.less";

export function EIFSubStepPolicyPage() {
  const { clientId, eifStepId, eifSubStepId, policyId } = useClientHubParams([
    "clientId",
    "eifStepId",
    "eifSubStepId",
    "policyId",
  ]);

  // Data Fetching
  const { authUser, isLoading: isLoadingAuthUser } = useSlobAuth();

  const [
    { data: client },
    _employeeClasses,
    { data: clientPlans },
    { data: locations },
    { data: bills },
    { data: _deletedBills },
    { data: contacts },
    { data: benAdminPlatforms },
    { data: featureToggles },
    { data: changes },
    { data: _documents },
    { data: _documentsCount },
    { data: billingSummaryStatementTemplates },
  ] = useSuspenseEIFQueries(clientId);

  const subStepName = getEIFSubStepMap({
    eifSubStepId,
    clientPlans,
    featureToggles,
  });

  useTitle(subStepName);

  // Mutations
  const updatePolicyQuery = useUpdatePolicy();

  const trackElementClicked = useTrackElementClicked(client, "Submit Company Information");

  const changeSnapshot = useChangeSnapshot(changes);

  const policy = client.policies.find((p) => p.id === policyId);

  assertIsDefined(policy, "policy");

  const title = policy.primaryPolicy
    ? `${subStepName}: #${policy.slfPolicyNumber} (Primary)`
    : `${subStepName}: #${policy.slfPolicyNumber}`;

  if (isLoadingAuthUser) {
    return <EIFStepPageLoading />;
  }

  const isAllowedToReviewEIF = getIsAllowedToReviewEIF({
    client,
    authUser,
    changesSnapshot: changeSnapshot,
  });

  if (!isAllowedToReviewEIF) {
    return (
      <Navigate
        to={RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information")}
        replace
      />
    );
  }

  return (
    <div className={styles.content}>
      <NavigationHeader backTo={null} hideBack title={title} />

      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={16}>
            {authUser && (
              <EIFSubStepPolicyPageContents
                client={client}
                bills={bills}
                locations={locations}
                contacts={contacts}
                billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                eifStepId={eifStepId}
                eifSubStepId={eifSubStepId}
                benAdminPlatforms={benAdminPlatforms}
                featureToggles={featureToggles}
                changeSnapshot={changeSnapshot}
                updatePolicy={updatePolicyQuery.mutateAsync}
                authUser={authUser}
                trackElementClicked={trackElementClicked}
                policy={policy}
              />
            )}
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}
