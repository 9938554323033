import { useSlobMutation } from "../query";

import type { Pretty } from "../../../../shared/types/Helper";
import type { sendPersonalBenefitsPlanRequestValidation } from "../../../../shared/validation/explorerPage";
import type { InferType } from "yup";

export const useSendPersonalBenefitsPlanEmail = () =>
  useSlobMutation<
    Pretty<InferType<typeof sendPersonalBenefitsPlanRequestValidation>>,
    boolean,
    "/api/explorer-pages/personal-benefits-plan/send"
  >({
    method: "post",
    path: `/api/explorer-pages/personal-benefits-plan/send`,
  });
