import { Body3 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderEarningsReview } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderEarningsReview";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getEmployeeClassEarningsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { earningsValidationSchema } from "shared/validation/employeeClass";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export function EIFClassBuilderItemEarnings(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser } = props;

  const viewMode = getEIFSubStepViewMode({ client });

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const prefillErrorsEarnings = !suppressErrorsPostSigning
    ? getFormikErrors(employeeClass, earningsValidationSchema, { client, prefill: false })
    : {};

  const earningsStatus = getEmployeeClassEarningsCompletionStatus(client, employeeClass);

  return (
    <div data-testid="earnings-class-builder-item">
      <h5>Earnings definition</h5>

      <EIFClassBuilderEarningsReview
        employeeClass={employeeClass}
        client={client}
        authUser={authUser}
        changeSnapshot={changeSnapshot}
        prefillErrors={prefillErrorsEarnings}
      />

      {earningsStatus === "Not Started" && (
        <Body3 as="div" redMedium={!suppressErrorsPostSigning}>
          {!suppressErrorsPostSigning ? "Please complete the earnings section." : "-"}
        </Body3>
      )}
    </div>
  );
}
