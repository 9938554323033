import { Drawer } from "antd";
import { setZendeskWidgetOffset } from "client/src/utils/zendesk";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { ReactComponent as CloseIcon } from "../Icons/close.svg";
import * as styles from "./SlobDrawer.module.less";

type Props = {
  visible: boolean | undefined;
  disableClose?: boolean;
  width?: number;
  onClose: (e: React.MouseEvent | React.KeyboardEvent) => void;
  title: React.ReactNode;
  children: React.ReactNode;
  footerInner?: React.ReactNode;
  footerOuter?: React.ReactNode;
};

export function SlobDrawer(props: Props) {
  const {
    visible,
    disableClose,
    width = 500,
    onClose,
    title,
    children,
    footerInner,
    footerOuter,
  } = props;

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- .
  const timeoutIdRef = useRef(0);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- .
  useEffect(() => {
    if (visible) {
      clearTimeout(timeoutIdRef.current);
      setZendeskWidgetOffset({ horizontal: `${width}px` });
    }

    return () => {
      if (visible) {
        // Slight delay to match the closing animation of the drawer
        timeoutIdRef.current = window.setTimeout(() => {
          setZendeskWidgetOffset({ horizontal: "0" });
        }, 30);
      }
    };
  }, [visible, width]);

  return (
    <Drawer
      open={visible}
      width={width}
      onClose={onClose}
      closable={disableClose}
      keyboard={!disableClose}
      title={title && <h1>{title}</h1>}
      closeIcon={
        <div className={clsx([props.disableClose && styles.disableClose])}>
          <CloseIcon />
        </div>
      }
      footer={footerOuter}
      className={clsx(styles.slobDrawer, footerInner && styles.footerInner)}
    >
      <div className={styles.slobDrawerContent}>
        <div className={styles.slobDrawerBody}>{children}</div>
        {footerInner && <div className="py-40">{footerInner}</div>}
      </div>
    </Drawer>
  );
}
