import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { Anchor } from "../../components/Anchor/Anchor";
import { Button } from "../../components/Button/Button";
import { HorizontalDivider } from "../../components/HorizontalDivider/HorizontalDivider";
import { ActivationCard } from "../../components/HubCard/ActivationCard";
import { StackY } from "../../components/Spacing/Spacing";
import { H2, H5 } from "../../components/Typography/Typography";
import { PasswordForgotForm } from "../../domain/User/Form/PasswordForgotForm";
import { useResendActivationEmail } from "../../hooks/user";
import { useTrackElementClicked } from "../../utils/analytics";
import { useHideZendeskWidget } from "../../utils/zendesk";

import * as styles from "./login.module.less";

export const ReActivatePage = () => {
  const [successMessage, setSuccessMessage] = useState(false);
  const { mutateAsync: forgotPassword } = useResendActivationEmail();
  const trackElementClicked = useTrackElementClicked();

  useHideZendeskWidget();

  const navigate = useNavigate();

  const goLoginClick = (module: string, buttonLabel: string) => {
    trackElementClicked({ module, buttonLabel });
    navigate(RouteData.login.getPath());
  };

  const onSuccess = () => {
    setSuccessMessage(true);
  };

  if (successMessage) {
    return (
      <ActivationCard>
        <H2 as="h1">Activation Link Sent</H2>
        <StackY dist={24}>
          <p>Thanks for requesting to activate your account!</p>
          <p className="mb-0">
            If you’re not already activated, we’ll send you an email as long as the email address
            you entered is a valid address in our system.
          </p>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => goLoginClick("Activation Link Sent", "Return to Sign In")}
          >
            Return to Sign In
          </Button>
          <HorizontalDivider />
          <H5 as="h2">Not getting the email?</H5>
        </StackY>
        <p className="mt-16 mb-0">
          If you have another email address, try that one. If that doesn’t work, contact{" "}
          <Anchor
            href="mailto:support@onboard.sunlife.com"
            target="_blank"
            onClick={() =>
              trackElementClicked({ module: "Activation Link Sent", buttonLabel: "Support email" })
            }
          >
            support@onboard.sunlife.com
          </Anchor>{" "}
          for additional help.
        </p>
      </ActivationCard>
    );
  }

  return (
    <ActivationCard>
      <H2 as="h1">Request Activation Link</H2>
      <p className="mb-24">
        Enter your work email address that you provided to either Sun Life or to your broker.
      </p>
      <StackY dist={16}>
        <PasswordForgotForm
          submitButtonLabel="Send Activation Email"
          mutate={forgotPassword}
          onSuccess={onSuccess}
          track={() =>
            trackElementClicked({
              module: "Request Activation Link",
              buttonLabel: "Send Activation Email",
            })
          }
        />
        <div className="text-center">
          <Button
            type="text"
            block
            onClick={() => goLoginClick("Request Activation Link", "Return to Sign In")}
          >
            Return to Sign In
          </Button>
        </div>
      </StackY>
      <p className={styles.stillNeedHelp}>
        Still need help? Contact{" "}
        <Anchor
          variant="bold"
          href="mailto:support@onboard.sunlife.com"
          target="_blank"
          onClick={() =>
            trackElementClicked({ module: "Request Activation Link", buttonLabel: "Support email" })
          }
        >
          support@onboard.sunlife.com
        </Anchor>
      </p>
    </ActivationCard>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default ReActivatePage;
