import { faCheckCircle, faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Skeleton } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { HubCard } from "../HubCard/HubCard";
import { ReactComponent as StaticSpinner } from "../Icons/StaticSpinner.svg";
import { Body4, Eyebrow } from "../Typography/Typography";
import * as styles from "./stepNavigation.module.less";
import type { IconDefinition } from "@fortawesome/free-regular-svg-icons";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

export const StepNavigationDataTestId = "step-navigation";

export type StepNavigationStep = {
  name: string;
  status: EIFStepCompleteStatus;
  isSelected: boolean;
  icon?: IconDefinition;
  hasDivider?: boolean;
  to: string;
};

export type StepNavigationProps =
  | {
      steps: StepNavigationStep[];
      loading?: undefined;
    }
  | {
      steps?: undefined;
      loading: boolean;
    };

export const StepNavigation = (props: StepNavigationProps) => {
  const steps = "steps" in props ? props.steps : null;
  const loading = "loading" in props ? props.loading : false;

  return (
    <HubCard padding="none" data-testid={StepNavigationDataTestId}>
      <div className="py-24">
        <div className={clsx("px-24", "mb-12")}>
          <Eyebrow>COMPLETE THESE STEPS</Eyebrow>
        </div>

        {loading && <StepNavigationSkeleton items={5} />}

        {steps?.map(({ name, status, isSelected, hasDivider, icon, to }) => (
          <div key={name}>
            {hasDivider && <Divider className="my-8" />}
            <Link className={styles.navLink} to={to} aria-current={isSelected ? "page" : undefined}>
              <Row wrap={false} className={clsx(styles.navRow, isSelected && styles.selected)}>
                <Col>
                  {status === "In Progress" ? (
                    <StaticSpinner className={styles.inProgress} />
                  ) : (
                    <FontAwesomeIcon
                      icon={icon ? icon : status === "Completed" ? faCheckCircle : faCircle}
                      className={clsx(
                        styles.statusIcon,
                        status === "Completed" ? styles.completed : styles.selected,
                        isSelected && styles.completed,
                      )}
                    />
                  )}

                  {status === "Completed" && <span className="sr-only">(step completed)</span>}
                </Col>
                <Col className="pl-12">
                  <Body4>{name}</Body4>
                </Col>
              </Row>
            </Link>
          </div>
        ))}
      </div>
    </HubCard>
  );
};

function StepNavigationSkeleton({ items }: { items: number }) {
  return (
    <div>
      <div className={clsx(styles.skeleton, "px-24 stack-y-24")}>
        {Array(items)
          .fill(null)
          .map((_, i) => (
            <Skeleton key={i} active title={false} paragraph={{ rows: 1, width: "100%" }} />
          ))}
      </div>
      <Divider className="my-16" />
      <div className={clsx(styles.skeleton, "px-24")}>
        <Skeleton active title={false} paragraph={{ rows: 1, width: "50%" }} />
      </div>
    </div>
  );
}
