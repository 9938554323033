import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { CopyBenAdminAndDataFeedsFromAnotherPolicyModal } from "client/src/domain/EIF/CompanyDetails/CopyBenAdminAndDataFeedsFromAnotherPolicyModal";
import { EIFPrevAndNextPolicyButtons } from "client/src/domain/EIF/CompanyDetails/EIFPrevAndNextPolicyButtons";
import { WhatAreDataFeeds } from "client/src/domain/EIF/CompanyDetails/WhatAreDataFeeds";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { getIn } from "formik";
import { useMemo } from "react";
import { RouteData } from "shared/config/routeData";
import { contactToContactInput } from "shared/types/Contact";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { getNextURLToNavigateToAfterEIFStepPolicyUpdate } from "shared/utils/policy";
import { assertIsDefined } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { benAdminAndDataFeedsValidationSchema } from "shared/validation/policy";
import { FormInput } from "../../../components/Form/Input";
import { RadioGroup } from "../../../components/Form/RadioGroup";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../../hooks/useGetEIFPreviousAndNextLink";
import { EIFBottomNavButtons } from "../EIFBottomNavButtons";
import type { BenAdminPlatform } from "../../../../../shared/types/BenAdminPlatform";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy, YesNoNotSure } from "shared/types/Client";
import type { ContactInput, ContactType } from "shared/types/Contact";
import type { EIFCompanyDetailsSubStep, EIFStepId } from "shared/types/EIF";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const validationSchema = benAdminAndDataFeedsValidationSchema;

export type EIFBenAdminAndDataFeedProps = {
  client: Client;
  benAdminPlatforms: BenAdminPlatform[];
  changeSnapshot: DEIFChangeSnapshot;
  updatePolicy: UpdatePolicyFunc;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  policy: Policy;
};

export const EIFBenAdminAndDataFeed = (props: EIFBenAdminAndDataFeedProps) => {
  const {
    client,
    benAdminPlatforms,
    updatePolicy,
    changeSnapshot,
    trackElementClicked,
    authUser,
    featureToggles,
    policy,
  } = props;

  const eifStepId: EIFStepId = "company-information";
  const eifSubStepId: EIFCompanyDetailsSubStep = "benefits-administration-and-data-feed";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const benAdminPlatformsMap = useMemo(
    () => new Map(benAdminPlatforms.map((b) => [b.name, b])),
    [benAdminPlatforms],
  );
  const otherPlatform = benAdminPlatformsMap.get("Other");
  const noPlatform = benAdminPlatformsMap.get("I don’t use a platform");
  const notSurePlatform = benAdminPlatformsMap.get("I'm not sure");

  assertIsDefined(otherPlatform, "otherPlatform");
  assertIsDefined(noPlatform, "noPlatform");
  assertIsDefined(notSurePlatform, "notSurePlatform");

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const {
    hasBenAdminPlatform,
    benAdminPlatform,
    benAdminPlatformId,
    benAdminPlatformOtherName,
    dataFeeds,
    dataFeedsBenAdminContact,
    dataFeedsImplementorContact,
    dataFeedsProductionSupportContact,
  } = policy;

  const [copyModalIsOpen, toggleCopyModalIsOpen] = useToggler();

  const policyId = policy.id;

  const initialValues: ValuesForValidationSchema<typeof validationSchema> = {
    hasBenAdminPlatform,
    benAdminPlatform,
    benAdminPlatformId,
    benAdminPlatformOtherName,
    dataFeeds,
    dataFeedsBenAdminContact: dataFeedsBenAdminContact
      ? contactToContactInput(dataFeedsBenAdminContact)
      : dataFeeds === "YES"
      ? {
          policyId,
          firstName: "",
          lastName: "",
          email: "",
          type: "DATA_FEEDS_BEN_ADMIN",
        }
      : null,
    dataFeedsImplementorContact: dataFeedsImplementorContact
      ? contactToContactInput(dataFeedsImplementorContact)
      : dataFeeds === "YES"
      ? {
          policyId,
          firstName: "",
          lastName: "",
          email: "",
          type: "DATA_FEEDS_IMPLEMENTOR",
        }
      : null,
    dataFeedsProductionSupportContact: dataFeedsProductionSupportContact
      ? contactToContactInput(dataFeedsProductionSupportContact)
      : dataFeeds === "YES"
      ? {
          policyId,
          firstName: "",
          lastName: "",
          email: "",
          type: "DATA_FEEDS_PRODUCTION_SUPPORT",
        }
      : null,
  };

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validationContext: { prefill: true },
    onSubmit: async (values) => {
      const benAdminPlatform = benAdminPlatforms.find((b) => b.id === values.benAdminPlatformId);

      const getContactInput = (
        contact:
          | typeof values.dataFeedsBenAdminContact
          | typeof values.dataFeedsImplementorContact
          | typeof values.dataFeedsProductionSupportContact,
        type: ContactType,
      ): ContactInput | null => {
        return contact
          ? {
              id: contact.id ?? undefined,
              policyId: contact.policyId,
              firstName: contact.firstName ?? "",
              lastName: contact.lastName ?? "",
              email: contact.email ?? "",
              type,
            }
          : null;
      };

      try {
        const data = {
          benAdminPlatform,
          hasBenAdminPlatform: values.hasBenAdminPlatform,
          benAdminPlatformId: values.benAdminPlatformId,
          benAdminPlatformOtherName: values.benAdminPlatformOtherName,
          dataFeeds: values.dataFeeds,
          dataFeedsBenAdminContact: getContactInput(
            values.dataFeedsBenAdminContact,
            "DATA_FEEDS_BEN_ADMIN",
          ),
          dataFeedsImplementorContact: getContactInput(
            values.dataFeedsImplementorContact,
            "DATA_FEEDS_IMPLEMENTOR",
          ),
          dataFeedsProductionSupportContact: getContactInput(
            values.dataFeedsProductionSupportContact,
            "DATA_FEEDS_PRODUCTION_SUPPORT",
          ),
        };

        await updatePolicy({
          params: { clientId: client.id, policyId: policy.id },
          query: { prefill: true },
          data,
        });
      } catch (error) {
        void slobMessage.error(
          "Oops! Something went wrong. Could not save benefits administration & data feeds. Please try again.",
        );
        throw error;
      }

      trackElementClicked({
        module: subStepName,
        buttonLabel: "Next",
        moduleState: getEIFSubStepStatus({
          client,
          eifSubStepId,
          featureToggles,
        }),
      });

      const nextUrl = getNextURLToNavigateToAfterEIFStepPolicyUpdate({
        client,
        policy,
        eifStepId,
        eifSubStepId,
        nextSubStepLink,
        isMultiPolicyMode,
      });
      if (nextUrl) {
        navigate(nextUrl);
      }
    },
  });

  const haveEverSavedForm = Object.entries(initialValues).some(([_key, value]) => value !== null);
  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, validationSchema, { prefill: false })
    : {};

  const showOtherBenAdminField = formik.values.benAdminPlatformId === otherPlatform?.id;

  return (
    <>
      <StackY dist={32} wrap={false} className={isMultiPolicyMode ? "pt-24 pb-56" : undefined}>
        <h2>{subStepName}</h2>

        <StackY dist={16} wrap={false}>
          {isMultiPolicyMode && (
            <Button
              to={RouteData.eifSubStepDetail.getPath(client.id, eifStepId, eifSubStepId)}
              type="text-only"
              size="middle"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
              Back to all policies
            </Button>
          )}

          <form onSubmit={formik.handleSubmit} className="stack-y-32">
            <HubCard>
              <StackY dist={32}>
                <StackY dist={16}>
                  {isMultiPolicyMode &&
                    (policy.primaryPolicy ? (
                      <h2>Policy #{policy.slfPolicyNumber} (Primary)</h2>
                    ) : (
                      <h2>Policy #{policy.slfPolicyNumber}</h2>
                    ))}

                  <Body3 as="p">
                    To get started, we’ll just need a few details about your company.
                  </Body3>
                  <RadioGroup<YesNoNotSure>
                    name="hasBenAdminPlatform"
                    label="Do you use a benefits administration platform?"
                    disabled={formik.isSubmitting}
                    touched={
                      !!formik.touched.hasBenAdminPlatform ||
                      !!getIn(strictErrors, "hasBenAdminPlatform")
                    }
                    error={
                      getIn(formik.errors, "hasBenAdminPlatform") ||
                      getIn(strictErrors, "hasBenAdminPlatform")
                    }
                    value={formik.values.hasBenAdminPlatform || null}
                    onChange={async (event) => {
                      const hasBenAdminPlatform: YesNoNotSure = event.target.value;
                      if (hasBenAdminPlatform === "YES") {
                        await formik.setFieldValue("benAdminPlatform", null);
                        await formik.setFieldValue("benAdminPlatformId", null);
                      } else if (hasBenAdminPlatform === "NO") {
                        await formik.setValues({
                          hasBenAdminPlatform,
                          benAdminPlatform: noPlatform,
                          benAdminPlatformId: noPlatform.id,
                          benAdminPlatformOtherName: null,
                          dataFeeds: null,
                          dataFeedsBenAdminContact: null,
                          dataFeedsImplementorContact: null,
                          dataFeedsProductionSupportContact: null,
                        });
                      } else if (hasBenAdminPlatform === "NOT_SURE") {
                        await formik.setValues({
                          hasBenAdminPlatform,
                          benAdminPlatform: notSurePlatform,
                          benAdminPlatformId: notSurePlatform.id,
                          benAdminPlatformOtherName: null,
                          dataFeeds: null,
                          dataFeedsBenAdminContact: null,
                          dataFeedsImplementorContact: null,
                          dataFeedsProductionSupportContact: null,
                        });
                      }
                      formik.handleChange(event);
                    }}
                    options={[
                      { value: "YES", label: "Yes" },
                      { value: "NO", label: "No, I don’t use a platform" },
                      { value: "NOT_SURE", label: "I’m not sure" },
                    ]}
                    direction="vertical"
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.Policy[policy.id]?.benAdminPlatformId,
                      changeSnapshot.Policy[policy.id]?.hasBenAdminPlatform,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={
                      getHasPendingEdit({
                        field: "benAdminPlatformId",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "hasBenAdminPlatform",
                        client,
                        formik,
                      })
                    }
                  />
                </StackY>
              </StackY>

              {formik.values.hasBenAdminPlatform === "NOT_SURE" && (
                <div className="mt-8 body3">
                  Reach out to your Implementation Consultant to discuss further.{" "}
                </div>
              )}

              {formik.values.hasBenAdminPlatform === "YES" && (
                <div className="mt-32">
                  <StackY dist={8}>
                    <SlobSelect
                      name="benAdminPlatformId"
                      label="Which platform do you use?"
                      placeholder="Select platform"
                      disabled={formik.isSubmitting}
                      loading={formik.isSubmitting}
                      touched={
                        !!formik.touched.benAdminPlatformId ||
                        !!getIn(strictErrors, "benAdminPlatformId")
                      }
                      error={
                        getIn(formik.errors, "benAdminPlatformId") ||
                        getIn(strictErrors, "benAdminPlatformId")
                      }
                      value={formik.values.benAdminPlatformId}
                      onChange={async (event) => {
                        const benAdminPlatform = benAdminPlatforms.find(
                          (b) => b.id === event.value,
                        );
                        await formik.setFieldValue("benAdminPlatformId", event.value);
                        await formik.setFieldValue("benAdminPlatform", benAdminPlatform);

                        if (benAdminPlatform?.name === "I'm not sure") {
                          await formik.setFieldValue("hasBenAdminPlatform", "NOT_SURE");
                        } else if (benAdminPlatform?.name === "I don’t use a platform") {
                          await formik.setFieldValue("hasBenAdminPlatform", "NO");
                        } else {
                          await formik.setFieldValue("hasBenAdminPlatform", "YES");
                        }
                      }}
                      showRequired={true}
                      options={benAdminPlatforms.map((b) => {
                        return {
                          label: b.name,
                          value: b.id,
                        };
                      })}
                    />

                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.Policy[policy.id]?.benAdminPlatformId]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "benAdminPlatformId",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                  <div className="mt-10 mb-32">
                    Don’t see your platform listed? Select ‘Other’ and enter in your platform name.
                  </div>
                </div>
              )}

              <StackY dist={32}>
                {showOtherBenAdminField && (
                  <StackY dist={8}>
                    <FormInput
                      name="benAdminPlatformOtherName"
                      label="Name of the benefits administration platform you use"
                      maxLength={191}
                      touched={
                        !!formik.touched.benAdminPlatformOtherName ||
                        !!getIn(strictErrors, "benAdminPlatformOtherName")
                      }
                      disabled={formik.isSubmitting}
                      value={formik.values.benAdminPlatformOtherName ?? ""}
                      onChange={formik.handleChange}
                      error={
                        getIn(formik.errors, "benAdminPlatformOtherName") ||
                        getIn(strictErrors, "benAdminPlatformOtherName")
                      }
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Policy[policy.id]?.benAdminPlatformOtherName,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "benAdminPlatformOtherName",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                )}
                {formik.values.hasBenAdminPlatform === "YES" && (
                  <>
                    <StackY dist={16}>
                      <RadioGroup<YesNoNotSure>
                        name="dataFeeds"
                        label={
                          <>
                            <Body2 as="p">
                              Do you want to set up a data feed between this platform and Sun Life?
                            </Body2>
                            <Body3 as="p">
                              If you have a benefits administration platform, Sun Life can set up an
                              eligibility data feed so your employee benefit elections are
                              automatically sent to us.
                            </Body3>
                          </>
                        }
                        value={formik.values.dataFeeds}
                        direction="vertical"
                        onChange={async (event) => {
                          const value: YesNoNotSure = event.target.value;
                          if (value === "YES") {
                            await formik.setFieldValue("dataFeedsBenAdminContact", {
                              policyId,
                              type: "DATA_FEEDS_BEN_ADMIN",
                            });
                            await formik.setFieldValue("dataFeedsImplementorContact", {
                              policyId,
                              type: "DATA_FEEDS_IMPLEMENTOR",
                            });
                            await formik.setFieldValue("dataFeedsProductionSupportContact", {
                              policyId,
                              type: "DATA_FEEDS_PRODUCTION_SUPPORT",
                            });
                          } else {
                            await formik.setFieldValue("dataFeedsBenAdminContact", null);
                            await formik.setFieldValue("dataFeedsImplementorContact", null);
                            await formik.setFieldValue("dataFeedsProductionSupportContact", null);
                          }
                          formik.handleChange(event);
                        }}
                        disabled={formik.isSubmitting}
                        error={
                          getIn(formik.errors, "dataFeeds") || getIn(strictErrors, "dataFeeds")
                        }
                        touched={!!formik.touched.dataFeeds || !!getIn(strictErrors, "dataFeeds")}
                        options={[
                          { value: "YES", label: "Yes" },
                          { value: "NO", label: "No, not at this time" },
                          { value: "NOT_SURE", label: "I’m not sure" },
                        ]}
                      />
                      <EditedFieldMsg
                        changeDetailInfoList={[changeSnapshot.Policy[policy.id]?.dataFeeds]}
                        client={client}
                        authUser={authUser}
                        hasPendingEdit={getHasPendingEdit({
                          field: "dataFeeds",
                          client,
                          formik,
                        })}
                      />
                    </StackY>
                    {formik.values.dataFeeds === "NOT_SURE" && (
                      <div>
                        Someone from Sun Life will reach out to you to discuss this further.
                      </div>
                    )}

                    {formik.values.dataFeeds === "YES" && (
                      <>
                        <Body2 as="p">
                          Who is the contact at your benefits administration platform for the data
                          feed setup?
                        </Body2>
                        <Body3>
                          This is the contact that Sun Life will reach out to on your data feed
                          setup.
                        </Body3>
                        <Row gutter={[24, 24]}>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsBenAdminContact?.firstName}
                                maxLength={191}
                                label="First name"
                                aria-label="Benefits Administrator's First name"
                                name="dataFeedsBenAdminContact.firstName"
                                touched={
                                  !!formik.touched.dataFeedsBenAdminContact ||
                                  !!getIn(strictErrors.dataFeedsBenAdminContact, "firstName")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsBenAdminContact, "firstName") ||
                                  getIn(strictErrors.dataFeedsBenAdminContact, "firstName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]
                                    ?.firstName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsBenAdminContact.firstName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsBenAdminContact?.lastName}
                                maxLength={191}
                                label="Last name"
                                aria-label="Benefits Administrator's Last name"
                                name="dataFeedsBenAdminContact.lastName"
                                touched={
                                  !!formik.touched.dataFeedsBenAdminContact ||
                                  !!getIn(strictErrors.dataFeedsBenAdminContact, "lastName")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsBenAdminContact, "lastName") ||
                                  getIn(strictErrors.dataFeedsBenAdminContact, "lastName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]
                                    ?.lastName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsBenAdminContact.lastName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsBenAdminContact?.email}
                                maxLength={191}
                                label="Email address"
                                aria-label="Benefits Administrator's Email address"
                                name="dataFeedsBenAdminContact.email"
                                touched={
                                  !!formik.touched.dataFeedsBenAdminContact ||
                                  !!getIn(strictErrors.dataFeedsBenAdminContact, "email")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsBenAdminContact, "email") ||
                                  getIn(strictErrors.dataFeedsBenAdminContact, "email")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[policy.dataFeedsBenAdminContact?.id ?? ""]
                                    ?.email,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsBenAdminContact.email",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                        </Row>

                        <Body2 as="p">Who is your data feed support contact?</Body2>
                        <Body3>
                          This is the ongoing administration contact who will receive discrepancy
                          reports once the feed is live.
                        </Body3>
                        <Row gutter={[24, 24]}>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsProductionSupportContact?.firstName}
                                maxLength={191}
                                label="First name"
                                aria-label="Production Support Contact's First name"
                                name="dataFeedsProductionSupportContact.firstName"
                                touched={
                                  !!formik.touched.dataFeedsProductionSupportContact ||
                                  !!getIn(
                                    strictErrors.dataFeedsProductionSupportContact,
                                    "firstName",
                                  )
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(
                                    formik.errors.dataFeedsProductionSupportContact,
                                    "firstName",
                                  ) ||
                                  getIn(strictErrors.dataFeedsProductionSupportContact, "firstName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsProductionSupportContact?.id ?? ""
                                  ]?.firstName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsProductionSupportContact.firstName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsProductionSupportContact?.lastName}
                                maxLength={191}
                                label="Last name"
                                aria-label="Production Support Contact's Last name"
                                name="dataFeedsProductionSupportContact.lastName"
                                touched={
                                  !!formik.touched.dataFeedsProductionSupportContact ||
                                  !!getIn(
                                    strictErrors.dataFeedsProductionSupportContact,
                                    "lastName",
                                  )
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(
                                    formik.errors.dataFeedsProductionSupportContact,
                                    "lastName",
                                  ) ||
                                  getIn(strictErrors.dataFeedsProductionSupportContact, "lastName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsProductionSupportContact?.id ?? ""
                                  ]?.lastName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsProductionSupportContact.lastName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsProductionSupportContact?.email}
                                maxLength={191}
                                label="Email address"
                                aria-label="Production Support Contact's Email address"
                                name="dataFeedsProductionSupportContact.email"
                                touched={
                                  !!formik.touched.dataFeedsProductionSupportContact ||
                                  !!getIn(strictErrors.dataFeedsProductionSupportContact, "email")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsProductionSupportContact, "email") ||
                                  getIn(strictErrors.dataFeedsProductionSupportContact, "email")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsProductionSupportContact?.id ?? ""
                                  ]?.email,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsProductionSupportContact.email",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                        </Row>

                        <Body2 as="p">Who is your data feed implementation contact?</Body2>
                        <Body3>
                          This is the contact who we’d work with on test file results, questions
                          about implementation, etc.
                        </Body3>
                        <Row gutter={[24, 24]}>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsImplementorContact?.firstName}
                                maxLength={191}
                                label="First name"
                                aria-label="Implementation Contact's First name"
                                name="dataFeedsImplementorContact.firstName"
                                touched={
                                  !!formik.touched.dataFeedsImplementorContact ||
                                  !!getIn(strictErrors.dataFeedsImplementorContact, "firstName")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsImplementorContact, "firstName") ||
                                  getIn(strictErrors.dataFeedsImplementorContact, "firstName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsImplementorContact?.id ?? ""
                                  ]?.firstName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsImplementorContact.firstName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsImplementorContact?.lastName}
                                maxLength={191}
                                label="Last name"
                                aria-label="Implementation Contact's Last name"
                                name="dataFeedsImplementorContact.lastName"
                                touched={
                                  !!formik.touched.dataFeedsImplementorContact ||
                                  !!getIn(strictErrors.dataFeedsImplementorContact, "lastName")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsImplementorContact, "lastName") ||
                                  getIn(strictErrors.dataFeedsImplementorContact, "lastName")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsImplementorContact?.id ?? ""
                                  ]?.lastName,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsImplementorContact.lastName",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                          <Col span={12}>
                            <StackY dist={8}>
                              <FormInput
                                value={formik.values.dataFeedsImplementorContact?.email}
                                maxLength={191}
                                label="Email address"
                                aria-label="Implementation Contact's Email address"
                                name="dataFeedsImplementorContact.email"
                                touched={
                                  !!formik.touched.dataFeedsImplementorContact ||
                                  !!getIn(strictErrors.dataFeedsImplementorContact, "email")
                                }
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                error={
                                  getIn(formik.errors.dataFeedsImplementorContact, "email") ||
                                  getIn(strictErrors.dataFeedsImplementorContact, "email")
                                }
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={[
                                  changeSnapshot.Contact[
                                    policy.dataFeedsImplementorContact?.id ?? ""
                                  ]?.email,
                                ]}
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "dataFeedsImplementorContact.email",
                                  client,
                                  formik,
                                })}
                              />
                            </StackY>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </StackY>

              {isMultiPolicyMode && (
                <EIFPrevAndNextPolicyButtons
                  client={client}
                  policy={policy}
                  disabled={formik.isSubmitting}
                  eifStepId={eifStepId}
                  eifSubStepId={eifSubStepId}
                  onClickCopyFromAnotherPolicy={toggleCopyModalIsOpen}
                />
              )}
            </HubCard>

            {!isMultiPolicyMode && (
              <EIFBottomNavButtons
                previousLink={previousSubStepLink}
                previousButtonDisabled={formik.isSubmitting}
                nextButtonDisabled={formik.isSubmitting}
              />
            )}
          </form>
        </StackY>

        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>

        {!isMultiPolicyMode && (
          <>
            <hr />

            <WhatAreDataFeeds />
          </>
        )}
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />

      <CopyBenAdminAndDataFeedsFromAnotherPolicyModal
        open={copyModalIsOpen}
        onCancel={toggleCopyModalIsOpen}
        client={client}
        policy={policy}
        formValues={formik.values}
        onCopy={async (copiedValues) => {
          const nextValues = { ...formik.values, ...copiedValues };
          await formik.setValues(nextValues);
          toggleCopyModalIsOpen();
        }}
      />
    </>
  );
};
