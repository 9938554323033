import type { Client } from "shared/types/Client";

// @todo: remove `allPoliciesSlfCoverages` and rework validation tests that use it
//   This is a read-only value in the context of this page
//   so it is not good practice to include it in the updateClient payload
export const section125FormKeyProps: (keyof Client)[] = [
  "hasSection125Plan",
  "hasSection125Dental",
  "hasSection125Vision",
  "hasSection125DHMO",
  "allPoliciesSlfCoverages",
  "hasAdditionalCoverageIncludedIn125Plan",
  "additonalCoverageIncludedInSection125Plan",
];

// for `haveEverSavedForm`, don't care if `allPoliciesSlfCoverages` has a non-null or not
export const haveEverSavedSection125FormKeyProps = [...section125FormKeyProps].filter(
  (keyOfClient) => keyOfClient !== "allPoliciesSlfCoverages",
);

export const additionalWaitingPeriodRulesFormKeyProps: (keyof Client)[] = [
  "timeSpentShouldCountTowardsWaitingPeriod",
  "timeSpentAsPartTimeShouldCount",
  "timeSpentAsOtherShouldCount",
  "timeSpentAsOtherShouldCountText",
  "waitingPeriodShouldBeWaived",
];

export const rehireProvisionsFormKeyProps: (keyof Client)[] = [
  "reHireProvisionExists",
  "rehireProvision",
];

export const unionMembersAndDomesticPartnersFormKeyProps: (keyof Client)[] = [
  "unionMembersCovered",
  "domesticPartnersCovered",
];

// @todo: remove `allPoliciesSlfCoverages` and rework validation tests that use it
//   This is a read-only value in the context of this page
//   so it is not good practice to include it in the updateClient payload
export const changesDuringEnrollmentFormKeyProps: (keyof Client)[] = [
  "whenDoYouAdministerYourAEP",
  "whenDoYouAdministerYourAEPStartDate",
  "whenDoYouAdministerYourAEPEndDate",
  "whenDoAEPTakeEffect",
  "whenDoAEPTakeEffectOther",
  "whenDecreasesInsuranceTakeEffect",
  "changesOnlyAllowedDuringAE",
  "whenDoChangesToBenefitsTakeEffect",
  "allPoliciesSlfCoverages",
];

// for `haveEverSavedForm`, don't care if `allPoliciesSlfCoverages` has a non-null or not
export const haveEverSavedChangesDuringEnrollmentFormKeyProps =
  changesDuringEnrollmentFormKeyProps.filter(
    (keyOfClient) => keyOfClient !== "allPoliciesSlfCoverages",
  );

export const employeeCertificateFormKeyProps: (keyof Client)[] = [
  "shouldCertificatesBeSplitInAnotherWay",
  "shouldCertificatesBeSplitInAnotherWayText",
];

export const erisaFormKeyProps: (keyof Client)[] = [
  "erisaHasPlan",
  "erisaPlanType",
  "erisaPlanTypeOther",
  "erisaPrefersPlanDetails",
  "erisaPlanAdminName",
  "erisaStreetAddress",
  "erisaCity",
  "erisaState",
  "erisaZipCode",
  "taxId",
  "erisaPlanNumber",
  "erisaPlanNumberOther",
  "erisaPlanYearEndDateMMDD",
  "erisaAdministerOwnPlan",
  "erisaHasAgentForLegalProcess",
  "erisaIsLegalAgentSameAsAdmin",
  "erisaLegalAgentName",
  "erisaLegalAgentStreetAddress",
  "erisaLegalAgentCity",
  "erisaLegalAgentState",
  "erisaLegalAgentZipCode",
];
