import { H3 } from "../../../../components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "../../../../components/UnorderedList/UnorderedList";
import { Trans, useTranslation } from "../../../../i18n";

export const DataTestId = {
  IncludeADD: "include-add",
};

export type ExplorerBenefitADDCopyStructure = {
  header: string;
  description: string;
};

export const ExplorerBenefitADD = () => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitADD" });
  return (
    <div data-testid={DataTestId.IncludeADD}>
      <H3>
        <strong>{t("header")}</strong>
      </H3>
      <Trans t={t} i18nKey="description">
        <p className="mb-8">p</p>
        <UnorderedList>
          <UnorderedListItem>item</UnorderedListItem>
        </UnorderedList>
      </Trans>
    </div>
  );
};
