import { Table } from "antd";

import { Loading } from "../Loading/Loading";

import * as styles from "./slobTable.module.less";

import type { ColumnsType, ColumnType, TableProps } from "antd/lib/table";
import type { Client, ClientId } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

interface SlobColumnType<RecordType> extends Omit<ColumnType<RecordType>, "dataIndex"> {
  dataIndex?: keyof RecordType & string;
}

export interface SlobColumnGroupType<RecordType> extends Omit<ColumnType<RecordType>, "dataIndex"> {
  children: ColumnsType<RecordType>;
}
export type SlobColumnsType<RecordType = unknown> = SlobColumnType<RecordType>[];

export type SlobTableProps<Data> = {
  data?: Data[];
  columns: SlobColumnsType<Data>;
  isLoading: boolean;
  currentPage: number;
  onChange: Exclude<TableProps<Data>["onChange"], undefined>;
  totalItems: number;
  pageSize: number;
  clientId?: ClientId;
  client?: Client;
  showModal?: (value: Data) => void;
  featureToggles?: ClientFeatureToggles | undefined;
  shouldRedirectToClient?: boolean;
  getIsRowInErrorState?: (row: Data, featureToggles?: ClientFeatureToggles) => boolean;
  showFooter?: boolean;
};

const getPageLabel = ({
  currentPage,
  pageSize,
  totalItems,
}: {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}) => {
  if (!totalItems) {
    return <>&nbsp;</>;
  }

  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(currentPage * pageSize, totalItems);
  return `Showing ${start}-${end} of ${totalItems}`;
};

export const SlobTable = <Data extends { id: unknown }>({
  data,
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  columns,
  getIsRowInErrorState,
  showFooter = true,
  featureToggles,
}: SlobTableProps<Data>) => {
  const numberOfPages = Math.ceil(totalItems / pageSize);
  return (
    <Table<Data>
      className={styles.slobTable}
      loading={isLoading ? { indicator: <Loading /> } : false}
      columns={columns}
      dataSource={data}
      rowKey="id"
      onChange={onChange}
      footer={() => showFooter && getPageLabel({ currentPage, pageSize, totalItems })}
      onRow={(record) => {
        const rowIsInErrorState = getIsRowInErrorState?.(record, featureToggles);
        return rowIsInErrorState
          ? {
              className: styles.error,
            }
          : {};
      }}
      pagination={{
        total: totalItems,
        current: currentPage,
        pageSize,
        hideOnSinglePage: true,
        showSizeChanger: true,
        itemRender(page, type, originalElement) {
          switch (type) {
            case "prev":
              return (
                <button className="ant-pagination-item-link" type="button" disabled={page === 1}>
                  Previous
                </button>
              );
            case "next":
              return (
                <button
                  className="ant-pagination-item-link"
                  type="button"
                  disabled={page === numberOfPages}
                >
                  Next
                </button>
              );
            default:
              return originalElement;
          }
        },
      }}
    />
  );
};
