import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Col, Row } from "client/src/components/Grid/Grid";
import { RouteData } from "shared/config/routeData";
import { getPreviousAndNextPolicy } from "shared/utils/policy";
import type { Client, Policy } from "shared/types/Client";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";

type Props = {
  client: Client;
  policy: Policy;
  disabled: boolean;
  eifStepId: EIFStepId;
  eifSubStepId: EIFSubStepId;
  onSubmitClick?: () => void;
  onClickCopyFromAnotherPolicy?: () => void;
};

export function EIFPrevAndNextPolicyButtons(props: Props) {
  const {
    client,
    policy,
    disabled,
    eifStepId,
    eifSubStepId,
    onSubmitClick,
    onClickCopyFromAnotherPolicy,
  } = props;

  const { previousPolicy, nextPolicy } = getPreviousAndNextPolicy(client, policy);

  return (
    <Row align="middle" justify={previousPolicy ? "space-between" : "end"} className="mt-32">
      {previousPolicy && (
        <Col>
          <Button
            type="text-only"
            size="small"
            disabled={disabled}
            to={RouteData.eifSubStepPolicyDetail.getPath(
              client.id,
              eifStepId,
              eifSubStepId,
              previousPolicy.id,
            )}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
            Previous policy
          </Button>
        </Col>
      )}

      <Col className="stack-x-16">
        {onClickCopyFromAnotherPolicy && (
          <Button
            type="text-only"
            size="small"
            disabled={disabled}
            onClick={onClickCopyFromAnotherPolicy}
          >
            Copy from another policy
          </Button>
        )}

        <Button
          htmlType="submit"
          type="primary"
          size="small"
          disabled={disabled}
          onClick={onSubmitClick}
        >
          {nextPolicy ? "Next policy" : "Done"}
        </Button>
      </Col>
    </Row>
  );
}
