import { faExclamationTriangle, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import {
  ErrorMessage,
  GenericErrorCopy2,
  genericErrorCopy2,
} from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Loading } from "client/src/components/Loading/Loading";
import { SlobDrawer } from "client/src/components/SlobDrawer/SlobDrawer";
import { StackY } from "client/src/components/Spacing/Spacing";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EmailErrorModal } from "client/src/domain/Client/ClientsList/ClientsTable/EmailError/EmailErrorModal";
import clsx from "clsx";
import { useState } from "react";
import { type UserRole } from "shared/rbac/roles";
import { roleToPretty, type DomainUser, type UserId } from "shared/types/User";
import { formatFullName } from "shared/utils/format";
import { Anchor } from "../../../../../components/Anchor/Anchor";
import { Button } from "../../../../../components/Button/Button";
import { Checkbox } from "../../../../../components/Form/Checkbox";
import { HorizontalDivider } from "../../../../../components/HorizontalDivider/HorizontalDivider";
import { ReactComponent as WarningInfo } from "../../../../../components/Icons/WarningInfo.svg";
import { OrderedList, OrderedListItem } from "../../../../../components/OrderedList/OrderedList";
import { Body3, Body2 } from "../../../../../components/Typography/Typography";
import * as styles from "./EmailErrorDrawer.module.less";
import type {
  CreateActivationLinkFunc,
  UpdateEmailAndSendActivationEmailFunc,
} from "client/src/hooks/user";
import type { ClientId } from "shared/types/Client";

export type EmailErrorDrawerProps = {
  createActivationLink: CreateActivationLinkFunc;
  updateEmailAndSendActivationEmail: UpdateEmailAndSendActivationEmailFunc;
  isPendingCreateActivationLink: boolean;
  visible: boolean;
  onClose: (e: React.MouseEvent | React.KeyboardEvent) => void;
  clientId: ClientId | null | undefined;
  ticketId: string | null | undefined;
  emailErrorUsers:
    | Array<{
        userId: UserId;
        user?: {
          email: string;
          firstName?: string;
          lastName?: string;
          role: UserRole;
        };
      }>
    | null
    | undefined;
  jiraURL: string;
  welcomeEmailError?: boolean;
  isLoadingClient?: boolean;
};

export const EmailErrorDrawer = (props: EmailErrorDrawerProps) => {
  const [resendUserIds, setResendToUserIds] = useState<UserId[]>([]);
  const [showEditEmailModal, setShowEditEmailModal] = useState<boolean>(false);

  const {
    createActivationLink,
    updateEmailAndSendActivationEmail,
    isPendingCreateActivationLink,
    visible,
    onClose,
    clientId,
    ticketId,
    emailErrorUsers,
    jiraURL,
    isLoadingClient,
    welcomeEmailError,
  } = props;

  const handleResendCheckbox = (userId: UserId) => {
    const selected = resendUserIds.includes(userId);
    const updatedIds = selected
      ? resendUserIds.filter((id) => id !== userId)
      : [...resendUserIds, userId];
    setResendToUserIds(updatedIds);
  };

  const disableUpdateAndResendButton =
    (emailErrorUsers ?? []).length > 1 ? resendUserIds.length === 0 : false;
  const shouldShowCheckbox = (emailErrorUsers ?? []).length > 1;
  const usersEditOrActivate =
    emailErrorUsers?.filter(
      ({ userId }) => !shouldShowCheckbox || resendUserIds.includes(userId),
    ) ?? [];

  const title = welcomeEmailError ? "Welcome email error help" : "Email error help";
  return (
    <>
      {/* TB-5522: width is 705px, not 700px b/c at 700 we saw different number of lines for Body3 text on different machines */}
      <SlobDrawer
        width={705}
        visible={visible}
        onClose={onClose}
        title={title}
        children={
          isLoadingClient ? (
            <Loading />
          ) : (
            <StackY dist={32}>
              <div className="ml-32">
                <Body2>Do this first:</Body2>
              </div>

              <OrderedList spacing="large">
                <OrderedListItem>
                  <StackY dist={24} className="w-full">
                    <Body3>
                      The following users failed to receive their welcome email. Check to ensure the
                      email address is correct and then resend the email from here in the portal.
                    </Body3>

                    {emailErrorUsers?.map((clientUser, index) => {
                      const isUserChecked = resendUserIds.includes(clientUser.userId);
                      return (
                        <div key={clientUser.userId}>
                          {index > 0 && <HorizontalDivider size={16} />}
                          <EmailErrorUser
                            createActivationLink={createActivationLink}
                            isPendingCreateActivationLink={isPendingCreateActivationLink}
                            type={"resend"}
                            clientUser={clientUser}
                            checked={isUserChecked}
                            handleResendCheckbox={handleResendCheckbox}
                            shouldShowCheckbox={shouldShowCheckbox}
                          />
                        </div>
                      );
                    })}

                    <div className="ml-24">
                      <Button
                        type="tertiary"
                        disabled={disableUpdateAndResendButton}
                        onClick={() => setShowEditEmailModal(true)}
                      >
                        Update & resend email
                      </Button>
                    </div>

                    <Body2>
                      If step 1 doesn't resolve the issue, please follow the{" "}
                      {welcomeEmailError ? "steps" : "step"} below.
                    </Body2>
                  </StackY>
                </OrderedListItem>

                {welcomeEmailError && (
                  <OrderedListItem>
                    <StackY dist={24} className="w-full">
                      <Body3>
                        Copy the user specific welcome email activation link and email it to the
                        intended recipient directly from your own email. If we record a login from
                        them, we will remove the error from the email log.
                      </Body3>
                      {emailErrorUsers?.map((clientUser, index) => (
                        <div key={clientUser.userId}>
                          {index > 0 && <HorizontalDivider size={16} />}

                          <EmailErrorUser
                            createActivationLink={createActivationLink}
                            isPendingCreateActivationLink={isPendingCreateActivationLink}
                            type={"activationLink"}
                            clientUser={clientUser}
                            handleResendCheckbox={handleResendCheckbox}
                          />
                        </div>
                      ))}
                    </StackY>
                  </OrderedListItem>
                )}

                <OrderedListItem>
                  <StackY dist={24} className="w-full">
                    <Body3>
                      Ask the client to make sure our IP address is added to their spam filter
                      "allow-list." Our IP address is <b>198.2.180.107</b>. Submit a support ticket
                      after reaching out to the client
                    </Body3>
                    <Body2>
                      If these troubleshooting steps do not resolve this issue, please submit a Jira
                      ticket to engineering for a follow up.
                    </Body2>
                  </StackY>
                </OrderedListItem>
              </OrderedList>
            </StackY>
          )
        }
        footerInner={
          isLoadingClient ? null : (
            <Row justify="end" align="middle" gutter={24}>
              <Col>
                <Anchor href={`${jiraURL}/browse/${ticketId}`} target="_blank" variant="bold">
                  Submit a ticket
                </Anchor>
              </Col>
              <Col>
                <Button type="primary" size="middle" onClick={onClose}>
                  Close
                </Button>
              </Col>
            </Row>
          )
        }
      />
      {showEditEmailModal && clientId && (
        <EmailErrorModal
          showModal
          clientId={clientId}
          onClose={() => setShowEditEmailModal(false)}
          users={usersEditOrActivate}
          updateEmailAndSendActivationEmail={updateEmailAndSendActivationEmail}
        />
      )}
    </>
  );
};

export type EmailErrorUserProps = {
  createActivationLink: CreateActivationLinkFunc;
  isPendingCreateActivationLink: boolean;
  type: "resend" | "activationLink";
  clientUser: {
    userId: UserId;
    user?: {
      email: string;
      firstName?: string;
      lastName?: string;
      role: UserRole;
    };
  };
  checked?: boolean;
  handleResendCheckbox: (userId: UserId) => void;
  shouldShowCheckbox?: boolean;
  asAlert?: boolean;
  asWarning?: boolean;
};

export const toClientUser = (user: DomainUser): EmailErrorUserProps["clientUser"] => ({
  userId: user.id,
  user: {
    email: user.email,
    role: user.role,
    firstName: user.firstName,
    lastName: user.lastName,
  },
});

export const EmailErrorUser = (props: EmailErrorUserProps) => {
  const {
    createActivationLink,
    isPendingCreateActivationLink,
    type,
    clientUser,
    checked = false,
    shouldShowCheckbox = true,
    handleResendCheckbox,
    asAlert = false,
    asWarning = false,
  } = props;

  const fullName = formatFullName(clientUser.user);

  const [error, setError] = useState<string | JSX.Element>("");

  const handleCreateActivationLink = async () => {
    setError("");

    try {
      const { data, isError } = await createActivationLink({
        params: {
          userId: clientUser.userId,
        },
      });

      if (isError) {
        void slobMessage.error(genericErrorCopy2);
        return;
      }

      const { activationLink } = data;
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(activationLink);
      }
      void slobMessage.success("Activation link copied");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);

      if (error instanceof DOMException) {
        if (error.name === "NotAllowedError" && error.message === "Document is not focused.") {
          setError(
            "We can't copy the activation link into your clipboard if this browser tab is not active. Please click the button again and wait before opening another tab or application.",
          );
        }
      } else {
        setError(GenericErrorCopy2);
        Sentry.captureException(error);
      }
    }
  };

  return (
    <Row gutter={8} wrap={false}>
      <Col>
        {asWarning ? (
          <WarningInfo width={16} height={16} />
        ) : (
          // TODO: Remove with <EmailErrorDrawer />
          <FontAwesomeIcon icon={faExclamationTriangle} className={styles.dangerIcon} />
        )}
      </Col>

      <Col>
        <StackY dist={1}>
          <Body3>
            <b>Name:</b> {fullName}
          </Body3>
          <Body3>
            <b>Email:</b> {clientUser.user?.email}
          </Body3>
          <Body3>
            <b>Role:</b> {roleToPretty(clientUser.user?.role)}
          </Body3>

          {type === "activationLink" && (
            <Button
              type="text-only"
              onClick={handleCreateActivationLink}
              loading={isPendingCreateActivationLink}
              disabled={isPendingCreateActivationLink}
              icon={<FontAwesomeIcon icon={faCopy} />}
              size="middle"
            >
              Copy activation link
            </Button>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </StackY>
      </Col>

      {type === "resend" && shouldShowCheckbox && (
        <Col flex="20px" className={clsx(asAlert && styles.checkboxCol)}>
          <Checkbox
            name="resendEmailCheckbox"
            label={null}
            aria-label={`Resend email to ${fullName}`}
            checked={checked}
            onChange={() => handleResendCheckbox(clientUser.userId)}
          />
        </Col>
      )}
    </Row>
  );
};
