import { last } from "lodash";
import { ProgressPhaseTracker } from "../../../../components/ProgressPhaseTracker/ProgressPhaseTracker";
import { getTimelineTheme } from "../../../../components/ProgressTracker/ProgressTrackerPolicies";
import { phasesCopy } from "../../../../hooks/phase";
import type { PhaseId } from "@prisma/client";
import type { FC } from "react";
import type { Client, Policy } from "shared/types/Client";

type DefaultProgressPhaseTrackerProps = { percentage: number; score: number };

const UNDEFINED_SCORE = -1;

const config: Record<PhaseId, DefaultProgressPhaseTrackerProps> = {
  ONBOARDING_COMPLETE: { percentage: 100, score: 6 },
  CONTRACTS_AND_BILLING: { percentage: 100 * (9 / 12), score: 5 },
  PLAN_APPROVAL: { percentage: 100 * (7 / 12), score: 4 },
  REVIEW_AND_VALIDATE: { percentage: 100 * (5 / 12), score: 3 },
  INFO_GATHERING: { percentage: 100 * (3 / 12), score: 2 },
  WELCOME: { percentage: 100 * (1 / 12), score: 1 },
  REJECTED: { percentage: 0, score: 0 },
};

type CompactProgressTrackerPolicyProps = {
  client: Client;
  policy: Policy;
};

export const CompactProgressTrackerPolicy: FC<CompactProgressTrackerPolicyProps> = ({
  client,
  policy,
}) => {
  const phaseId = policy.phaseId;
  const progressPhaseTrackerProps = phaseId ? config[phaseId] : config.WELCOME;
  const text = phaseId ? phasesCopy[phaseId].title : undefined;
  const theme = getTimelineTheme(client, policy);
  return (
    <ProgressPhaseTracker
      {...progressPhaseTrackerProps}
      text={text}
      theme={theme}
      policy={policy}
    />
  );
};

export const getPolicyPhaseScore = (phaseId: Policy["phaseId"]): number => {
  return phaseId ? config[phaseId].score ?? UNDEFINED_SCORE : UNDEFINED_SCORE;
};

export const comparePolicyByPhaseScore = (a?: Policy["phaseId"], b?: Policy["phaseId"]): number => {
  const aPhaseProgress = a ? getPolicyPhaseScore(a) : UNDEFINED_SCORE;
  const bPhaseProgress = b ? getPolicyPhaseScore(b) : UNDEFINED_SCORE;
  return aPhaseProgress < bPhaseProgress ? -1 : aPhaseProgress > bPhaseProgress ? 1 : 0;
};

export const getPolicyWithHigherPhaseScore = (policies: Policy[]): Policy | undefined => {
  const sorted = policies.sort((a, b) => comparePolicyByPhaseScore(a.phaseId, b.phaseId));
  return last(sorted);
};
