import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H3, H5 } from "client/src/components/Typography/Typography";
import { getMainPolicyHolderCheckboxLabel } from "client/src/domain/EIF/CompanyDetails/EIFSubsidiaries";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { ResponseError } from "client/src/hooks/query";
import { useDeleteSubsidiary } from "client/src/hooks/subsidiary";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { useSlobId } from "client/src/hooks/useSlobId";
import { getLocation } from "client/src/utils/getLocation";
import { handleTaxIdChange } from "client/src/utils/handleMaskedInputChange";
import clsx from "clsx";
import { getIn, type useFormik } from "formik";
import { LocationStateData, getLocationStateCode } from "shared/data/LocationState";
import { LocationStateCodes, type DisabilityStateCode } from "shared/types/Location";
import { getIsChangeDetailInfo } from "shared/utils/EIF/changeLog";
import { getEIFSubStepViewMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { subsidiaryValidationSchema } from "shared/validation/subsidiary";
import * as styles from "./EIFAddSubsidiary.module.less";
import type { combinedSubsidiaryValidationSchema } from "client/src/domain/EIF/CompanyDetails/EIFSubsidiaries";
import type { DeleteSubsidiaryFunc } from "client/src/hooks/subsidiary";
import type { ChangeEvent, Dispatch, SetStateAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";

type EIFAddASubsidiaryProps = {
  client: Client;
  plans: Plan[];
  subsidiaries: Subsidiary[];
  track: (buttonLabel: string) => void;
  relevantDisabilityStates: DisabilityStateCode[];
  changeSnapshot: DEIFChangeSnapshot;
  subsidiaryToEdit: Partial<Subsidiary> | null;
  authUser: UserData | null;
  setSubsidiaryToEdit: Dispatch<SetStateAction<Partial<Subsidiary> | null>>;
  formik: ReturnType<
    typeof useFormik<ValuesForValidationSchema<typeof combinedSubsidiaryValidationSchema>>
  >;
};

export const EIFAddASubsidiary = (props: EIFAddASubsidiaryProps) => {
  const {
    client,
    plans,
    subsidiaries,
    track,
    relevantDisabilityStates,
    changeSnapshot,
    subsidiaryToEdit,
    authUser,
    setSubsidiaryToEdit,
    formik,
  } = props;

  const {
    mutateAsync: deleteSubsidiary,
    isPending: isDeletePending,
    error: deleteError,
  } = useDeleteSubsidiary();

  const benefitTypes = plans.map((plan) => plan.benefitType);

  const strictErrors = subsidiaryToEdit?.id
    ? getFormikErrors(formik.values, subsidiaryValidationSchema, {
        prefill: false,
        benefitTypes,
      })
    : {};

  const employeeStateErrorIdString = useSlobId({
    prefix: "ee-states__errormessage",
  });
  const employeeStateErrorId =
    (formik.errors.employeeStates && formik.touched.employeeStates) || strictErrors.employeeStates
      ? employeeStateErrorIdString
      : undefined;

  const hasDisabilityStates = relevantDisabilityStates.length > 0;
  const hasASODental = plans.some((plan) => plan.benefitType === "DENTAL_ASO");
  const showAddSubsidiary = !!subsidiaryToEdit || subsidiaries.length === 0;

  const subsidiaryFormEmpty =
    !formik.values.taxId &&
    !formik.values.companyName &&
    !formik.values.employeeStates &&
    (formik.values.location === null ||
      (!formik.values.location.address1 &&
        !formik.values.location.address2 &&
        !formik.values.location.state &&
        !formik.values.location.city &&
        !formik.values.location.zipCode));

  const handleCompanyNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    await formik.setFieldValue("companyName", value);
    if (benefitTypes.includes("DENTAL_ASO")) {
      // location only relevant if DENTAL_ASO is present
      await formik.setFieldValue("location.name", value);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <HubCard>
          <StackY dist={32}>
            {subsidiaries.length > 0 && (
              <div className={clsx(showAddSubsidiary && styles.disabled)}>
                <H3>Subsidiaries and affiliates</H3>
                <StackY dist={16}>
                  {subsidiaries.map((subsidiary) => {
                    return (
                      <div key={subsidiary.id}>
                        <StackY dist={8}>
                          <SubsidiaryCard
                            client={client}
                            subsidiary={subsidiary}
                            disabled={showAddSubsidiary}
                            setSubsidiaryToEdit={setSubsidiaryToEdit}
                            deleteSubsidiary={deleteSubsidiary}
                            showCta={true}
                            hasDisabilityStates={hasDisabilityStates}
                            changeSnapshot={changeSnapshot}
                            plans={plans}
                            formik={formik}
                          ></SubsidiaryCard>
                          <EditedFieldMsg
                            changeDetailInfoList={[
                              Object.values(changeSnapshot.Subsidiary[subsidiary.id] ?? {}).filter(
                                getIsChangeDetailInfo,
                              )[0],
                              ...[
                                subsidiary.location?.id
                                  ? Object.values(
                                      changeSnapshot.Location[subsidiary.location.id] ?? {},
                                    ).filter(getIsChangeDetailInfo)[0]
                                  : null,
                              ],
                            ]}
                            client={client}
                            authUser={authUser}
                            hasPendingEdit={getHasPendingEdit({
                              field: "companyName",
                              client,
                              formik,
                            })}
                          />
                        </StackY>
                      </div>
                    );
                  })}
                </StackY>
              </div>
            )}
            {subsidiaries.length > 0 && showAddSubsidiary && <hr></hr>}
            <div>
              {showAddSubsidiary && (
                <>
                  <StackY dist={24}>
                    <H3 as="div">Add a subsidiary or affiliate</H3>
                    <div>
                      <StackY dist={8}>
                        <Body2>What is the subsidiary or affiliate company's name?</Body2>
                        <FormInput
                          label="Company name"
                          value={formik.values.companyName}
                          maxLength={191}
                          name="companyName"
                          onChange={handleCompanyNameChange}
                          disabled={formik.isSubmitting || isDeletePending}
                          touched={formik.touched.companyName || !!strictErrors.companyName}
                          error={formik.errors.companyName || strictErrors.companyName}
                        />
                        <EditedFieldMsg
                          changeDetailInfoList={
                            subsidiaryToEdit?.id
                              ? [changeSnapshot.Subsidiary[subsidiaryToEdit.id]?.companyName]
                              : [null]
                          }
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "companyName",
                            client,
                            formik,
                          })}
                        />
                      </StackY>
                    </div>
                    {hasDisabilityStates && (
                      <div>
                        <StackY dist={8}>
                          <Body2>
                            What is the subsidiary or affiliate company's tax ID number?
                          </Body2>
                          <Body3>
                            A Federal Tax Identification Number is also known as an Employer
                            Identification Number (EIN), and is used to identify a company to the
                            IRS.
                          </Body3>
                          <FormInput
                            label="Tax ID number XX-XXXXXXXX"
                            value={formik.values.taxId}
                            maxLength={10}
                            name="taxId"
                            onChange={handleTaxIdChange((maskedTaskId) =>
                              formik.setFieldValue("taxId", maskedTaskId),
                            )}
                            disabled={formik.isSubmitting || isDeletePending}
                            touched={formik.touched.taxId || !!strictErrors.taxId}
                            error={formik.errors.taxId || strictErrors.taxId}
                          />
                          <EditedFieldMsg
                            changeDetailInfoList={
                              subsidiaryToEdit?.id
                                ? [changeSnapshot.Subsidiary[subsidiaryToEdit.id]?.taxId]
                                : [null]
                            }
                            client={client}
                            authUser={authUser}
                            hasPendingEdit={getHasPendingEdit({
                              field: "taxId",
                              client,
                              formik,
                            })}
                          />
                        </StackY>
                      </div>
                    )}
                    {hasASODental && (
                      <div>
                        <StackY dist={8}>
                          <Body2 as="div">
                            What is the address of this subsidiary or affiliate company?
                          </Body2>
                          <Row gutter={[24, 24]}>
                            <Col span={24} className="stack-y-8">
                              <FormInput
                                name="location.address1"
                                label="Address 1"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                touched={
                                  !!formik.touched.location ||
                                  !!getIn(strictErrors, "location.address1")
                                }
                                error={
                                  getIn(formik.errors, "location.address1") ||
                                  getIn(strictErrors, "location.address1")
                                }
                                value={formik.values.location?.address1 ?? ""}
                                maxLength={191}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={
                                  subsidiaryToEdit?.id
                                    ? [
                                        changeSnapshot.Location[subsidiaryToEdit.location?.id ?? ""]
                                          ?.address1,
                                      ]
                                    : [null]
                                }
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "location.address1",
                                  client,
                                  formik,
                                })}
                              />
                            </Col>
                            <Col span={24} className="stack-y-8">
                              <FormInput
                                name="location.address2"
                                label="Address 2 (optional)"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                touched={
                                  !!formik.touched.location ||
                                  !!getIn(strictErrors, "location.address2")
                                }
                                error={
                                  getIn(formik.errors, "location.address2") ||
                                  getIn(strictErrors, "location.address2")
                                }
                                value={formik.values.location?.address2 ?? ""}
                                maxLength={191}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={
                                  subsidiaryToEdit?.id
                                    ? [
                                        changeSnapshot.Location[subsidiaryToEdit.location?.id ?? ""]
                                          ?.address2,
                                      ]
                                    : [null]
                                }
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "location.address2",
                                  client,
                                  formik,
                                })}
                              />
                            </Col>

                            <Col span={11} className="stack-y-8">
                              <FormInput
                                name="location.city"
                                label="City"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                touched={
                                  !!formik.touched.location ||
                                  !!getIn(strictErrors, "location.city")
                                }
                                error={
                                  getIn(formik.errors, "location.city") ||
                                  getIn(strictErrors, "location.city")
                                }
                                value={formik.values.location?.city ?? ""}
                                maxLength={191}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={
                                  subsidiaryToEdit?.id
                                    ? [
                                        changeSnapshot.Location[subsidiaryToEdit.location?.id ?? ""]
                                          ?.city,
                                      ]
                                    : [null]
                                }
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "location.city",
                                  client,
                                  formik,
                                })}
                              />
                            </Col>
                            <Col span={8} className="stack-y-8">
                              <SlobSelect
                                name="location.state"
                                disabled={formik.isSubmitting}
                                options={LocationStateCodes.map((stateCode) => ({
                                  label: LocationStateData[stateCode].displayName,
                                  value: stateCode,
                                }))}
                                placeholder="State"
                                onChange={async (event) => {
                                  await formik.setFieldValue("location.state", event.value);
                                }}
                                touched={
                                  !!formik.touched.location ||
                                  !!getIn(strictErrors, "location.state")
                                }
                                error={
                                  getIn(formik.errors, "location.state") ||
                                  getIn(strictErrors, "location.state")
                                }
                                value={formik.values.location?.state ?? null}
                                onSearch={async (val) => {
                                  // TB-6250: support browser autofill for state
                                  const stateCode = getLocationStateCode(val);
                                  if (stateCode) {
                                    await formik.setFieldValue("location.state", stateCode);
                                  }
                                }}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={
                                  subsidiaryToEdit?.id
                                    ? [
                                        changeSnapshot.Location[subsidiaryToEdit.location?.id ?? ""]
                                          ?.state,
                                      ]
                                    : [null]
                                }
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "location.state",
                                  client,
                                  formik,
                                })}
                              />
                            </Col>
                            <Col span={5} className="stack-y-8">
                              <FormInput
                                name="location.zipCode"
                                label="Zip"
                                disabled={formik.isSubmitting}
                                onChange={formik.handleChange}
                                touched={
                                  !!formik.touched.location ||
                                  !!getIn(strictErrors, "location.zipCode")
                                }
                                error={
                                  getIn(formik.errors, "location.zipCode") ||
                                  getIn(strictErrors, "location.zipCode")
                                }
                                value={formik.values.location?.zipCode ?? ""}
                                maxLength={191}
                              />
                              <EditedFieldMsg
                                changeDetailInfoList={
                                  subsidiaryToEdit?.id
                                    ? [
                                        changeSnapshot.Location[subsidiaryToEdit.location?.id ?? ""]
                                          ?.zipCode,
                                      ]
                                    : [null]
                                }
                                client={client}
                                authUser={authUser}
                                hasPendingEdit={getHasPendingEdit({
                                  field: "location.zipCode",
                                  client,
                                  formik,
                                })}
                              />
                            </Col>
                          </Row>
                        </StackY>
                      </div>
                    )}
                    <div>
                      <StackY dist={8}>
                        {relevantDisabilityStates.length > 0 && (
                          <div>
                            <StackY dist={8}>
                              <Body2>
                                Which states does this subsidiary have employees working in?
                              </Body2>
                              <Body3>
                                This tells us which employees are covered by which statutory
                                benefits
                              </Body3>
                              {relevantDisabilityStates.map((state) => {
                                return (
                                  <div key={state}>
                                    <Checkbox
                                      id={`employeeStates_${state}`}
                                      name={`employeeStates`}
                                      value={state}
                                      label={getMainPolicyHolderCheckboxLabel(state, plans)}
                                      checked={
                                        formik.values.employeeStates?.includes(state) ?? false
                                      }
                                      disabled={formik.isSubmitting || isDeletePending}
                                      errorId={employeeStateErrorId}
                                      onChange={formik.handleChange}
                                    />
                                  </div>
                                );
                              })}
                            </StackY>
                          </div>
                        )}
                        <div aria-live="assertive">
                          {((formik.errors.employeeStates && formik.touched.employeeStates) ||
                            strictErrors.employeeStates) && (
                            <InputErrorMessage
                              id={employeeStateErrorId}
                              error={
                                formik.touched.employeeStates && formik.errors.employeeStates
                                  ? formik.errors.employeeStates
                                  : strictErrors.employeeStates
                                  ? strictErrors.employeeStates
                                  : undefined
                              }
                            />
                          )}
                        </div>
                        <EditedFieldMsg
                          changeDetailInfoList={
                            subsidiaryToEdit?.id
                              ? [changeSnapshot.Subsidiary[subsidiaryToEdit.id]?.employeeStates]
                              : [null]
                          }
                          client={client}
                          authUser={authUser}
                          hasPendingEdit={getHasPendingEdit({
                            field: "employeeStates",
                            client,
                            formik,
                          })}
                        />
                      </StackY>
                    </div>
                    <Row justify="end" className="mt-32">
                      <Col>
                        <StackX dist={8}>
                          <Button
                            onClick={() => {
                              void formik.resetForm();
                              setSubsidiaryToEdit(null);
                            }}
                            type="text"
                            size="middle"
                            disabled={formik.isSubmitting || isDeletePending}
                          >
                            Cancel
                          </Button>

                          <Button
                            type="secondary"
                            htmlType="submit"
                            loading={formik.isSubmitting}
                            disabled={formik.isSubmitting || isDeletePending || subsidiaryFormEmpty}
                            onClick={() => {
                              track("Saved");
                            }}
                          >
                            Save
                          </Button>
                        </StackX>
                      </Col>
                    </Row>
                  </StackY>
                </>
              )}
            </div>
            {!showAddSubsidiary && (
              <Button
                type="text-only"
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => {
                  setSubsidiaryToEdit({ taxId: null, companyName: null, employeeStates: null });
                }}
                size="middle"
                disabled={formik.isSubmitting || isDeletePending}
              >
                Add another subsidiary or affiliate
              </Button>
            )}
            {deleteError && (
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(deleteError, GenericErrorCopy2)}
              </ErrorMessage>
            )}
          </StackY>
        </HubCard>
      </form>
    </>
  );
};

type SubsidiaryCardProps = {
  client: Client;
  subsidiary: Subsidiary;
  disabled: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  hasDisabilityStates: boolean;
  plans: Plan[];
} & (
  | {
      showCta: true;
      setSubsidiaryToEdit: Dispatch<SetStateAction<Partial<Subsidiary> | null>>;
      deleteSubsidiary: DeleteSubsidiaryFunc;
      formik: ReturnType<
        typeof useFormik<ValuesForValidationSchema<typeof combinedSubsidiaryValidationSchema>>
      >;
    }
  | {
      showCta: false;
      setSubsidiaryToEdit?: undefined;
      deleteSubsidiary?: undefined;
      formik?: ReturnType<
        typeof useFormik<ValuesForValidationSchema<typeof combinedSubsidiaryValidationSchema>>
      >;
    }
);
export const SubsidiaryCard = (props: SubsidiaryCardProps) => {
  const {
    client,
    subsidiary,
    disabled,
    changeSnapshot,
    hasDisabilityStates,
    setSubsidiaryToEdit,
    deleteSubsidiary,
    showCta,
    plans,
    formik,
  } = props;

  const viewMode = getEIFSubStepViewMode({ client });

  const benefitTypes = plans.map((plan) => plan.benefitType);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(subsidiary, subsidiaryValidationSchema, { prefill: false, benefitTypes })
    : {};

  return (
    <FlatCard disabled={disabled}>
      <Row wrap={false} gutter={9}>
        <Col flex="1 1 auto">
          <Row>
            <StackX dist={8}>
              <H5 redMedium={e.companyName ? true : false}>
                {subsidiary.companyName
                  ? subsidiary.companyName
                  : e.companyName
                  ? e.companyName
                  : "-"}
              </H5>
            </StackX>
          </Row>
        </Col>
        {showCta && (
          <Col flex="0 1 auto">
            <Button
              type="text-only"
              size="middle"
              onClick={() => {
                if (showCta) {
                  setSubsidiaryToEdit(subsidiary);
                }
              }}
            >
              Edit
            </Button>
          </Col>
        )}
        {showCta && (
          <Col flex="0 1 auto">
            <Button
              type="text-only"
              size="middle"
              onClick={() => {
                if (showCta) {
                  void deleteSubsidiary({
                    params: { clientId: client.id, subsidiaryId: subsidiary.id },
                  });
                  formik.resetForm();
                }
              }}
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
      <StackY dist={8}>
        {hasDisabilityStates && (
          <Row gutter={[32, 18]}>
            <Col>
              <Body5 as="div">Company Tax ID Number</Body5>
              {subsidiary.taxId && <Body3>{subsidiary.taxId}</Body3>}
              {e.taxId && <Body3 redMedium>{e.taxId}</Body3>}
              {!subsidiary.taxId && !e.taxId && <Body3>-</Body3>}
            </Col>
            <Col>
              <Body5 as="div">State(s) of employment</Body5>
              {subsidiary.employeeStates && (
                <Body3>
                  {subsidiary.employeeStates
                    .sort()
                    .map((state) => LocationStateData[state].displayName)
                    .join(", ")}
                </Body3>
              )}
              {e.employeeStates && <Body3 redMedium>{e.employeeStates}</Body3>}
              {!subsidiary.employeeStates && !e.employeeStates && <Body3>-</Body3>}
            </Col>
          </Row>
        )}
        {benefitTypes.includes("DENTAL_ASO") && (
          <div>
            <Body5 as="div">Address</Body5>
            {getLocation(subsidiary.location, false)}
          </div>
        )}
      </StackY>
    </FlatCard>
  );
};
