import pluralize from "pluralize";
import { formatDateFullMonthWithYear } from "shared/utils/format";

import { effectiveDateSiblings } from "../../../../shared/utils/policy";
import { getShouldDisplayDueDate } from "../../utils/getShouldDisplayDueDate";
import { Badge, taskStatusToVariant, taskStatusToLabel } from "../Badge/Badge";
import { DueDate } from "../DueDate/DueDate";
import { ReactComponent as TaskCardButton } from "../Icons/TaskCardButton.svg";
import { ReactComponent as TaskCardCompleted } from "../Icons/TaskCardCompleted.svg";
import { Body3 } from "../Typography/Typography";

import { Card } from "./Card";
import * as styles from "./taskCard.module.less";

import type { ReactNode } from "react";
import type { Task } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";

export type TaskCardProps = {
  headline: string;
  body: ReactNode;
  body2?: ReactNode;
  task: Task;
  client: Client;
  policy?: Policy;
  showPolicyInfo: boolean;
  isOnboardingComplete: boolean;
  bannerMessage?: ReactNode;
};

export function TaskCard({
  headline,
  body,
  body2,
  task,
  client,
  policy,
  showPolicyInfo,
  isOnboardingComplete,
  bannerMessage,
}: TaskCardProps) {
  const { status: taskStatus } = task;
  const isNotCompleted =
    isOnboardingComplete &&
    (taskStatus === "Not Started" || taskStatus === "In Progress" || taskStatus === "In Review");
  const variant = isNotCompleted ? "info" : taskStatus ? taskStatusToVariant[taskStatus] : null;
  const statusLabel = isNotCompleted
    ? "Not Completed"
    : taskStatus
    ? taskStatusToLabel[taskStatus]
    : null;
  const showStaticSpinner = taskStatus === "In Progress";
  const policyInfo: string[] = [];
  const policyNumbers: string[] = [];

  if (policy?.policyEffective)
    policyInfo.push(`benefits effective ${formatDateFullMonthWithYear(policy.policyEffective)}`);
  if (policy?.slfPolicyNumber) policyNumbers.push(`#${policy.slfPolicyNumber}`);

  let shouldShowPolicyInfo = showPolicyInfo;

  if (policy?.policyEffective && task.id === "enrollment-elections") {
    const siblings = effectiveDateSiblings(policy, client);
    const extraPolicies = siblings.filter((p) => p.id !== policy.id);
    const extraEnrollmentElections = client.policies.filter(
      (p) =>
        p.id !== policy.id &&
        !extraPolicies.find((e) => e.id === p.id) &&
        p.policyTasks?.find((e) => e.id === "enrollment-elections"),
    );
    extraPolicies.forEach((p) => {
      if (p.slfPolicyNumber) {
        policyNumbers.push(`#${p.slfPolicyNumber}`);
      }
    });

    /* https://maxwellhealth.atlassian.net/browse/FP-3115 */
    // Only show policy info, in the case of policy siblings (other policy with same effective date), for enrollment-elections
    // if there are any other enrollment-elections task for any other policy.
    if (!extraEnrollmentElections.length && extraPolicies.length) {
      shouldShowPolicyInfo = false;
    }
  }

  const policyNumberInfo = policyNumbers.length
    ? [`${pluralize("policy", policyNumbers.length)} ${policyNumbers.join(" & ")}`]
    : [];

  const message =
    shouldShowPolicyInfo && (policyInfo.length || policyNumberInfo.length) ? (
      <>
        For {[...policyInfo, ...policyNumberInfo].join(", ")}
        {bannerMessage && (
          <>
            <br />
            {bannerMessage}
          </>
        )}
      </>
    ) : (
      bannerMessage
    );

  const badge =
    variant && statusLabel ? (
      <Badge
        srOnlyLabel="Task Status"
        variant={variant}
        status={statusLabel}
        showStaticSpinner={showStaticSpinner}
        message={message}
        data-appcues-id="task-status"
      />
    ) : null;

  const shouldDisplayDueDate = getShouldDisplayDueDate(task);

  return (
    <Card variant="task">
      <div className="stack-y-16">
        {badge}

        <div>
          <h5 className="mb-4">{headline}</h5>

          <div className="stack-y-12">
            <Body3>{body}</Body3>
          </div>

          {body2 && <div className="mt-16">{body2}</div>}
        </div>

        {shouldDisplayDueDate && (
          <div className={styles.dueDate}>
            <DueDate task={task} />
          </div>
        )}
      </div>

      <div>
        {taskStatus === "Done" ? (
          <TaskCardCompleted aria-hidden={true} />
        ) : (
          <TaskCardButton aria-hidden={true} />
        )}
      </div>
    </Card>
  );
}
