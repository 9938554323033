import { ClientErrorsBanner } from "client/src/domain/Client/ClientErrors/ClientErrorsBanner";
import { useListParams } from "client/src/hooks/useListParams";
import { omit } from "lodash";
import { useCallback } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { SlobList } from "../../../components/SlobList/SlobList";
import { useGetEBRs } from "../../../hooks/EBR";
import { CLIENTS_LIST_PAGE_SIZE, useGetClients } from "../../../hooks/client";

import { triggerError } from "../../../hooks/generalError";
import { useClientTableFilters } from "../../../hooks/useClientTableFilters";
import { useLocalStorageClientTableFilters } from "../../../hooks/useSlobLocalStorage";
import { getURLSearchParamsFromObject } from "../../../utils/url";
import { ClientCreate } from "./ClientCreate";

import { ClientsTable } from "./ClientsTable/ClientsTable";
import { ClientListFilterButton } from "./Filter/ClientListFilterButton";
import type { ClientTableFilters } from "../../../../../shared/types/Client";
import type { SlfImplementationConsultant } from "../../../../../shared/types/User";
import type { ClientCreatePageProps } from "./ClientCreate";
import type { ClientErrorType } from "@prisma/client";
import type { To } from "react-router-dom";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const ClientListButtons = ({ clearFilter }: ClientCreatePageProps) => (
  <ClientCreate clearFilter={clearFilter} />
);

export const ClientsList = ({
  userId,
  implementationConsultants,
  namespace,
  featureToggles,
}: {
  userId: string;
  implementationConsultants: SlfImplementationConsultant[];
  namespace?: string;
  featureToggles: ClientFeatureToggles | undefined;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: ebrData,
    isLoading: isLoadingEBRs,
    isError: isErrorEBRs,
    error: errorEBRs,
  } = useGetEBRs();
  const ebrs = ebrData?.data;

  const [, writeLocalStorageFilters] = useLocalStorageClientTableFilters(userId);

  const filters = useClientTableFilters(userId, implementationConsultants);

  const setFilters = useCallback(
    (filters: ClientTableFilters) => {
      const searchParams = getURLSearchParamsFromObject(filters);
      const to: To = {
        pathname: location.pathname,
        search: searchParams.toString(),
      };
      navigate(to, { replace: true });

      writeLocalStorageFilters(filters);
    },
    [location, navigate, writeLocalStorageFilters],
  );

  const onClientFiltersChanged = useCallback<(f: ClientTableFilters) => void>(
    (newFilters) => {
      setFilters({ ...newFilters, clientErrorTypes: [] });
    },
    [setFilters],
  );

  const onClientErrorFiltersChanged = useCallback(
    (clientErrorTypes: ClientErrorType[]) => {
      setFilters({ ...filters, clientErrorTypes });
    },
    [filters, setFilters],
  );

  const onFiltersReset = useCallback(() => {
    const to: To = {
      pathname: location.pathname,
      search: "",
    };
    navigate(to, { replace: true });
    writeLocalStorageFilters(null);
  }, [location, navigate, writeLocalStorageFilters]);

  const listParams = useListParams({
    initialPageSize: CLIENTS_LIST_PAGE_SIZE,
    initialSort: {
      direction: "desc",
      sortBy: "createdAt",
    },
    listNamespace: namespace,
  });

  const query = useGetClients({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...filters,
  });

  if (isLoadingEBRs) return null;

  if (isErrorEBRs) {
    triggerError(errorEBRs);
  }

  return (
    <SlobList
      TableComponent={ClientsTable}
      query={query}
      listParams={listParams}
      LeftHeader={ClientListButtons}
      renderSubHeader={(data) => (
        <ClientErrorsBanner
          data={data}
          filters={filters.clientErrorTypes || []}
          onChange={onClientErrorFiltersChanged}
        />
      )}
      searchPlaceholder="Search by name or case ID"
      filter={filters}
      renderFilterComponent={() => (
        <ClientListFilterButton
          filters={omit(filters, "clientErrorTypes")}
          featureToggles={featureToggles}
          implementationConsultants={implementationConsultants ?? []}
          ebrEmails={ebrs ?? []}
          variant="internal"
          onFiltersChanged={onClientFiltersChanged}
          onFiltersReset={onFiltersReset}
        />
      )}
      onGetDataError={(error) => error.status === 400 && onFiltersReset()}
      namespace={namespace}
      featureToggles={featureToggles}
    />
  );
};
