import { useListParams } from "client/src/hooks/useListParams";
import { SlobList } from "../../../components/SlobList/SlobList";
import { CLIENTS_LIST_PAGE_SIZE, useGetUserClients } from "../../../hooks/client";

import { BenAdminClientsTable } from "./ClientsTable/BenAdminClientsTable";
import type { ClientUserTableFilters } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type BenAdminClientsListProps = {
  extraSearchParams: ClientUserTableFilters;
  shouldRedirectToClient?: boolean;
  featureToggles?: ClientFeatureToggles;
};

export const BenAdminClientsList = ({
  extraSearchParams,
  shouldRedirectToClient,
  featureToggles,
}: BenAdminClientsListProps) => {
  const listParams = useListParams({
    initialPageSize: CLIENTS_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "name",
    },
  });

  const query = useGetUserClients({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...extraSearchParams,
  });

  return (
    <SlobList
      TableComponent={BenAdminClientsTable}
      query={query}
      listParams={listParams}
      shouldRedirectToClient={shouldRedirectToClient}
      featureToggles={featureToggles}
    />
  );
};
