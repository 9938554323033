import {
  faCheckCircle,
  faCircleInfo,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Body3 } from "client/src/components/Typography/Typography";
import { getEditedByWhoText } from "client/src/domain/EIF/common/utils/getEditedByWhoText";
import { getICEditsAcceptedAndDeclinedBy } from "shared/utils/EIF/getICEditsAcceptedAndDeclinedBy";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import type { ChangeDetailInfoList } from "client/src/domain/EIF/common/EditBanner";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo } from "shared/types/Change";
import type { Client } from "shared/types/Client";

type EditFieldMsgProps = {
  "data-testid"?: string;
  changeDetailInfoList: ChangeDetailInfoList;
  withAlertBanner?: boolean;
  authUser: UserData | null;
  client: Client;
  isAdd?: boolean;
  hasPendingEdit?: boolean | null;
};

export const EditedFieldMsg = ({
  client,
  authUser,
  changeDetailInfoList,
  withAlertBanner = false,
  "data-testid": dataTestId,
  isAdd,
  hasPendingEdit = false,
}: EditFieldMsgProps) => {
  if (hasPendingEdit) {
    const editedByUserTxt = authUser ? authUser.name : "";
    const formattedDate = formatDateFullMonthWithYear(new Date());
    const bodyText =
      `Edited by ${editedByUserTxt} on ${formattedDate}. ` +
      `These changes are not yet visible to the Benefits Administrator.`;

    return <WithoutBanner status="pending">{bodyText}</WithoutBanner>;
  }

  const validChanges = changeDetailInfoList
    .filter((change): change is ChangeDetailInfo => change != null)
    .sort(
      (changeA, changeB) =>
        // sort by most recent change first
        changeB.currentValue.date.getTime() - changeA.currentValue.date.getTime(),
    );

  const [changeDetailInfo] = validChanges;
  if (!changeDetailInfo) {
    return null;
  }

  const { acceptedBy, acceptedAt, declinedBy, declinedAt } =
    getICEditsAcceptedAndDeclinedBy(client);

  const isReviewable = !!client?.deifChangesReadyForReviewAt;

  const editedByUser = changeDetailInfo.currentValue.user;
  const editedByUserTxt = editedByUser ? `${editedByUser.firstName} ${editedByUser.lastName}` : "";
  const formattedDate = formatDateFullMonthWithYear(changeDetailInfo.currentValue.date);

  const editedBy = getEditedByWhoText(authUser, editedByUserTxt);
  const reviewableText = !isReviewable
    ? `These changes are not yet visible to the Benefits Administrator.`
    : "";

  const showAddText = isAdd && changeDetailInfo.status === "pending";

  const bodyText = showAddText
    ? `Added by ${editedBy} on ${formattedDate}. ${reviewableText}`
    : (
        {
          pending: `Edited by ${editedBy} on ${formattedDate}. ${reviewableText}`,
          declined: `Declined by ${declinedBy} on ${declinedAt}.`,
          accepted: `Accepted by ${acceptedBy} on ${acceptedAt}.`,
        } as const
      )[changeDetailInfo.status];

  return (
    <div data-testid={dataTestId}>
      {withAlertBanner ? (
        <AlertBanner
          variant={
            (
              {
                pending: "warning",
                declined: "error",
                accepted: "success",
              } as const
            )[changeDetailInfo.status]
          }
          message={<Body3>{bodyText}</Body3>}
        />
      ) : (
        <WithoutBanner status={changeDetailInfo.status}>{bodyText}</WithoutBanner>
      )}
    </div>
  );
};

const WithoutBanner = ({
  status,
  children,
}: {
  status: ChangeDetailInfo["status"];
  children: React.ReactNode;
}) => {
  return (
    <Body3
      darkYellow={status === "pending"}
      greenMedium={status === "accepted"}
      redMedium={status === "declined"}
      as="div"
    >
      <Row gutter={8} wrap={false} align="top">
        <Col>
          {
            {
              pending: <FontAwesomeIcon icon={faCircleInfo} color="var(--color-primary-500)" />,
              accepted: <FontAwesomeIcon icon={faCheckCircle} />,
              declined: <FontAwesomeIcon icon={faExclamationTriangle} />,
            }[status]
          }
        </Col>

        <Col>{children}</Col>
      </Row>
    </Body3>
  );
};
