import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Eyebrow, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ContributionsCTA } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/ContributionsCTA";
import { ReviewVoluntaryContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Voluntary/ReviewVoluntaryContributions";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getEmployeeClassVoluntaryContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { voluntaryContributionsValidationSchema } from "shared/validation/employeeClassPlan";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { FormikProps } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  EmployeeClassPlanWithPlan,
  EmployeeClassPlanContributions,
  EmployeeClassPlanId,
  EmployeeClassContributionFields,
} from "shared/types/EmployeeClassPlan";

type Props = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  setContributionsQuery: SetContributionQuery;
  onSave: (employeeClass: EmployeeClass) => void;
  track: SectionTracker;
  isLoading: boolean;
  benefitType: "VOL_STD" | "VOL_LTD";
};

export function EIFClassBuilderVoluntaryEditable(props: Props) {
  const {
    isActive,
    employeeClass,
    client,
    changeSnapshot,
    authUser,
    setContributionsQuery,
    setContributionsQuery: { mutateAsync: setContributions },
    onSave,
    track,
    isLoading,
    benefitType,
  } = props;

  const location = useLocation();
  const ctaBasePathname = location.pathname;

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter(
    (p) => p.plan.benefitType === benefitType,
  );
  const employeeClassPlanIds = relevantEmployeeClassPlans.map((ecp) => ecp.id);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const relevantEmployeeClassPlan = relevantEmployeeClassPlans[0] as
    | EmployeeClassPlanWithPlan
    | undefined;

  if (!relevantEmployeeClassPlan) {
    throw new Error("No employee class plan found");
  }

  const status = getEmployeeClassVoluntaryContributionsCompletionStatus(employeeClass, [
    benefitType,
  ]);

  const formik = useSlobFormik({
    validationSchema: voluntaryContributionsValidationSchema,
    initialValues: {
      employerPremiumPayments: relevantEmployeeClassPlan.employerPremiumPayments,
    },
    enableReinitialize: true,
    validationContext: { prefill: true, benefitType },
    onSubmit: async () => {
      const contributions: EmployeeClassPlanContributions[] = relevantEmployeeClassPlans.map(
        (ecp) => {
          return {
            employeeClassPlanId: ecp.id,
            employerContribution: "NO",
            employerContributionUnit: null,
            eeContributionAmount: null,
            spouseContributionAmount: null,
            childrenContributionAmount: null,
            eeAndSpouseContributionAmount: null,
            eeAndChildrenContributionAmount: null,
            eeAndFamilyContributionAmount: null,
            familyContributionAmount: null,
            employerPremiumPayments: formik.values.employerPremiumPayments ?? null,
            threeYearLookBackPercent: null,
            taxChoice: null,
          };
        },
      );

      const { isSuccess, data } = await setContributions({
        params: {
          clientId: client.id,
          employeeClassId: employeeClass.id,
        },
        data: {
          employeeClassId: employeeClass.id,
          contributions,
        },
      });

      if (isSuccess) {
        track("Save");
        void slobMessage.success("Updated Contributions");
        onSave(data.employeeClass);
      }
    },
  });

  if (!isActive) {
    const atLeastOneContributionSelected = relevantEmployeeClassPlans.some(
      (plan) => plan.employerPremiumPayments !== null,
    );

    return (
      <>
        <Row justify="start" wrap={false} gutter={0}>
          <Col>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              <EIFSectionStatusIconography status={status} />
            </H3>
          </Col>

          <Col flex={1}>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              Contributions - {benefitTypeToCoverage[benefitType]}
            </H3>
          </Col>

          <Col className="ml-8">
            <ContributionsCTA
              employeeClass={employeeClass}
              ctaBasePathname={ctaBasePathname}
              benefitTypes={[benefitType]}
              relevantEmployeeClassPlans={relevantEmployeeClassPlans}
              setContributionsQuery={setContributionsQuery}
              isLoading={isLoading}
            />
          </Col>
        </Row>

        <StackY dist={16}>
          {status === "In Progress" && (
            <AlertBanner
              variant="error"
              message={
                <Body3>
                  Click <Body2>Edit</Body2> to complete this section.
                </Body3>
              }
            />
          )}

          <ReviewVoluntaryContributions
            client={client}
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            benefitType={benefitType}
          />
        </StackY>
      </>
    );
  }

  const editedFieldMsgProps = {
    employeeClassPlanIds,
    changeSnapshot,
    client,
    authUser,
    formik,
  };

  const strictErrors =
    status !== "Not Started"
      ? getFormikErrors(relevantEmployeeClassPlan, voluntaryContributionsValidationSchema, {
          prefill: false,
          benefitType: relevantEmployeeClassPlan.plan.benefitType,
        })
      : {};

  return (
    <>
      <Row justify="start" wrap={false} gutter={0}>
        <Col>
          <H3 as="h2">
            <EIFSectionStatusIconography status={status} />
          </H3>
        </Col>
        <Col flex={1}>
          <H3 as="h2">Contributions - {benefitTypeToCoverage[benefitType]}</H3>
        </Col>
      </Row>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24} wrap={false}>
          <StackY dist={8}>
            <Body3>Voluntary benefits are typically 100% employee paid.</Body3>
            <Eyebrow as="div">
              If your situation requires something different, please let your Implementation
              Consultant know.
            </Eyebrow>
          </StackY>
          <RadioGroup
            name="employerPremiumPayments"
            label="Are your employees’ premium payments made pre-tax or post-tax?"
            disabled={formik.isSubmitting}
            error={formik.errors.employerPremiumPayments || strictErrors.employerPremiumPayments}
            touched={
              formik.touched.employerPremiumPayments || !!strictErrors.employerPremiumPayments
            }
            options={[
              {
                value: "PRE_TAX",
                label: "Pre-tax",
              },
              {
                value: "POST_TAX",
                label: "Post-tax",
              },
            ]}
            direction="vertical"
            value={formik.values.employerPremiumPayments}
            onChange={formik.handleChange}
          />
          {getEditedFieldMsgForField(editedFieldMsgProps, "employerPremiumPayments")}

          <Row justify="end" align="middle" gutter={28}>
            <Col>
              <InternalLinkButton
                type="link-inline-bold"
                size="middle"
                to={location.pathname}
                disabled={formik.isSubmitting}
                onClick={() => flushSync(formik.resetForm)}
              >
                Cancel
              </InternalLinkButton>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="secondary"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                size="middle"
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}

function getEditedFieldMsgForField<T>(
  props: {
    employeeClassPlanIds: EmployeeClassPlanId[];
    changeSnapshot: DEIFChangeSnapshot;
    client: Client;
    authUser: UserData | null;
    formik: FormikProps<T>;
  },
  field: Exclude<EmployeeClassContributionFields, "employeeClassPlanId">,
) {
  const { employeeClassPlanIds, changeSnapshot, client, authUser, formik } = props;

  return (
    <EditedFieldMsg
      changeDetailInfoList={relevantChangesForContributionFields(
        employeeClassPlanIds,
        changeSnapshot,
        [field],
      )}
      client={client}
      authUser={authUser}
      hasPendingEdit={getHasPendingEdit({
        field,
        client,
        formik,
      })}
    />
  );
}
