import clsx from "clsx";
import moment from "moment";
import { useCallback } from "react";
import { clientValidationSchema } from "shared/validation/client";
import { getValidationErrors } from "shared/validation/getValidationErrors";

import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import * as styles from "../../components/Error/error.module.less";
import { Checkbox } from "../../components/Form/Checkbox";
import { DateRange } from "../../components/Form/DateRange";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { hasFormikError } from "../../utils/hasFormikError";
import { hasFormikErrors } from "../../utils/hasFormikErrors";

import { UnsavedChangesModal, getHandleFormClosing } from "./UnsavedChangesModal";
import * as profileModulesStyles from "./profileModules.module.less";
import { useClientUtils } from "./useClientUtils";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";

const enrollmentEventValidationSchema = clientValidationSchema.pick([
  "upcomingEnrollment",
  "enrollmentDateStart",
  "enrollmentDateEnd",
  "dontKnowEnrollmentEventDates",
]);

function getClientPropertiesToUpdate(client: Client) {
  const {
    upcomingEnrollment,
    enrollmentDateStart,
    enrollmentDateEnd,
    dontKnowEnrollmentEventDates,
    enrollmentEventUpdatedByUser,
    enrollmentEventUpdatedAt,
  } = client;
  return {
    upcomingEnrollment,
    enrollmentDateStart,
    enrollmentDateEnd,
    dontKnowEnrollmentEventDates,
    enrollmentEventUpdatedByUser,
    enrollmentEventUpdatedAt,
  };
}

export type EnrollmentEventFormProps = {
  client: Client;
  editing: boolean;
  disabled: boolean;
  updateClient: UpdateClientFunc;
  toggleActiveSection: () => void;
  trackElementClicked: (options: ElementClickedOptions) => void;
  onFinalSubmit?: Dispatch<SetStateAction<boolean>>;
};

export function EnrollmentEventForm({
  client,
  editing,
  disabled,
  updateClient,
  toggleActiveSection,
  trackElementClicked,
  onFinalSubmit,
}: EnrollmentEventFormProps) {
  const validationErrors = getValidationErrors(enrollmentEventValidationSchema, client);
  const status = validationErrors.length > 0 ? "not-started" : "completed";

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Enrollment Event",
        moduleState: validationErrors.length > 0 ? "Not Started" : "Completed",
        buttonLabel,
      });
    },
    [trackElementClicked, validationErrors],
  );

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: enrollmentEventValidationSchema,
    type: "Company Profile",
    track,
    formikOptions: { enableReinitialize: true },
    onSuccessCallback() {
      if (status === "not-started") onFinalSubmit?.(true);
      toggleActiveSection();
    },
  });

  const handleCancel = getHandleFormClosing({ formik, onProceed: toggleActiveSection });

  const clearEnrollmentDates = useCallback(async () => {
    await formik.setFieldValue("enrollmentDateStart", null);
    await formik.setFieldValue("enrollmentDateEnd", null);
  }, [formik]);

  const addDetailsClick = useCallback(() => {
    track("Add Details");
    toggleActiveSection();
  }, [track, toggleActiveSection]);

  const editClick = useCallback(() => {
    track("Edit");
    toggleActiveSection();
  }, [track, toggleActiveSection]);

  const updatedBy = client.enrollmentEventUpdatedByUser;
  const updatedAt = client.enrollmentEventUpdatedAt;

  const hasError = hasFormikErrors(formik);

  return (
    <HubCard disabled={disabled}>
      <HubCardHeader
        title="Enrollment Event"
        description={
          <p>
            If you’re holding an enrollment event for your employees, let our team know so that we
            can assist with your enrollment strategy.
          </p>
        }
        badge={
          <div data-testid="EnrollmentEventForm--badge">
            {status === "not-started" && (
              <Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />
            )}
            {status === "completed" && (
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="Completed"
                updatedBy={updatedBy}
                updatedAt={updatedAt}
              />
            )}
          </div>
        }
        hasDivider={true}
      />

      {editing ? (
        <>
          <UnsavedChangesModal form={formik} />

          <form onSubmit={formik.handleSubmit}>
            <StackY dist={24} className="w-full">
              <StackY dist={16}>
                <RadioGroup
                  name="upcomingEnrollment"
                  label="Are you holding an enrollment event?"
                  value={formik.values.upcomingEnrollment}
                  onChange={async (e) => {
                    await clearEnrollmentDates();
                    formik.handleChange(e);
                  }}
                  disabled={formik.isSubmitting}
                  touched={formik.touched.upcomingEnrollment}
                  error={
                    hasFormikError(formik, "upcomingEnrollment") &&
                    (formik.errors.upcomingEnrollment || false)
                  }
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                    { value: "NOT_SURE", label: "Not sure" },
                  ]}
                />

                {formik.values.upcomingEnrollment === "NOT_SURE" && (
                  <div>Someone from Sun Life will reach out to you to discuss further.</div>
                )}

                {formik.values.upcomingEnrollment === "YES" && (
                  <StackY dist={8}>
                    {!formik.values.dontKnowEnrollmentEventDates && (
                      <>
                        <DateRange
                          name="datesOfEnrollment"
                          topText="When does it begin and end?"
                          disabled={formik.isSubmitting}
                          errorValue={
                            hasFormikError(formik, "enrollmentDateStart") ||
                            hasFormikError(formik, "enrollmentDateEnd")
                              ? formik.errors.enrollmentDateStart ||
                                formik.errors.enrollmentDateEnd ||
                                ""
                              : ""
                          }
                          value={[
                            formik.values.enrollmentDateStart
                              ? moment(formik.values.enrollmentDateStart)
                              : null,
                            formik.values.enrollmentDateEnd
                              ? moment(formik.values.enrollmentDateEnd)
                              : null,
                          ]}
                          onChange={async (values) => {
                            if (values) {
                              const [startDateMoment, endDateMoment] = values;
                              if (startDateMoment && endDateMoment) {
                                const startDate = startDateMoment.toDate();
                                const endDate = endDateMoment.toDate();
                                await formik.setFieldValue("enrollmentDateStart", startDate);
                                await formik.setFieldValue("enrollmentDateEnd", endDate);
                              }
                            } else {
                              await clearEnrollmentDates();
                            }
                          }}
                        />
                      </>
                    )}
                    <Checkbox
                      label="I don't know my dates yet"
                      name="dontKnowEnrollmentEventDates"
                      checked={Boolean(formik.values.dontKnowEnrollmentEventDates)}
                      onChange={async (e) => {
                        await clearEnrollmentDates();
                        formik.handleChange(e);
                      }}
                      disabled={formik.isSubmitting}
                    />
                  </StackY>
                )}
              </StackY>

              <StackY dist={16} className="w-full">
                {formik.status && <LoadingError type="component" title={formik.status} />}

                {hasError.length > 0 && (
                  <div className={styles.error}>
                    {hasError.length > 1
                      ? `Please correct the errors above`
                      : `Please correct the error above`}
                  </div>
                )}

                <StackX dist={8}>
                  <Button
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    aria-label={formik.isSubmitting ? "Save" : undefined}
                  >
                    {formik.isSubmitting ? "" : "Save"}
                  </Button>
                  <Button
                    size="middle"
                    type="text"
                    onClick={handleCancel}
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </Button>
                </StackX>
              </StackY>
            </StackY>
          </form>
        </>
      ) : (
        <>
          {status === "not-started" ? (
            <div className="mt-32">
              <Button size="middle" type="primary" onClick={addDetailsClick} disabled={disabled}>
                Add Details
              </Button>
            </div>
          ) : (
            <>
              <StackY dist={32}>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <th align="left" className={clsx(profileModulesStyles.tableHeader, "body2")}>
                        Enrollment Event
                      </th>
                      <td className="body3" data-testid="upcomingEnrollmentValue">
                        {client.upcomingEnrollment === "YES" && (
                          <>
                            {client.dontKnowEnrollmentEventDates ? (
                              "Yes, but I don't know my dates yet"
                            ) : (
                              <>
                                {client.enrollmentDateStart?.toLocaleDateString()} to{" "}
                                {client.enrollmentDateEnd?.toLocaleDateString()}
                              </>
                            )}
                          </>
                        )}

                        {client.upcomingEnrollment === "NO" && "No"}
                        {client.upcomingEnrollment === "NOT_SURE" && "Not sure"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Button size="middle" type="default" onClick={editClick} disabled={disabled}>
                  Edit
                </Button>
              </StackY>
            </>
          )}
        </>
      )}
    </HubCard>
  );
}
