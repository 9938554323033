import memoizeOne from "memoize-one";
import type { Contact } from "shared/types/Contact";
import type { Plan } from "shared/types/Plan";

const getRelevantAssignedContacts = (plan: Plan, contacts: Contact[]): Contact | null => {
  const planAdminPayeeContact = contacts.filter(
    (contact) => contact.id === plan.planAdminPayeeContactId,
  );

  const someoneElsePayeeContact = contacts.filter(
    (contact) => contact.id === plan.someoneElsePayeeContactId,
  );

  if (!planAdminPayeeContact.length && !contacts.length && !someoneElsePayeeContact.length) {
    return null;
  } else if (!planAdminPayeeContact.length && contacts.length && someoneElsePayeeContact.length) {
    return someoneElsePayeeContact[0] || null;
  } else if (planAdminPayeeContact.length && contacts.length && !someoneElsePayeeContact.length) {
    return planAdminPayeeContact[0] || null;
  } else {
    return null;
  }
};

export const getClaimsCheckMailingLocationsInitialFormValues = memoizeOne(
  function _getClaimsCheckMailingLocationsInitialFormValues(plans: Plan[], contacts: Contact[]) {
    const initialValues = plans.map((plan) => {
      const relevantContact = getRelevantAssignedContacts(plan, contacts);

      return {
        ...plan,
        sendClaimsCheckTo: plan.sendClaimsCheckTo,
        selectedBenefitTypes: [],
        claimsCheckPayee: plan.claimsCheckPayee,
        planAdminPayeeContactId: plan.planAdminPayeeContactId,
        someoneElsePayeeContactId: plan.someoneElsePayeeContactId,
        fullName: relevantContact ? `${relevantContact.firstName} ${relevantContact.lastName}` : "",
        firstName: relevantContact ? relevantContact.firstName : "",
        lastName: relevantContact ? relevantContact.lastName : "",
        email: relevantContact ? relevantContact.email : "",
        phoneNumber: relevantContact ? relevantContact.phoneNumber : "",
        address1: relevantContact
          ? relevantContact.location
            ? relevantContact.location.address1
            : relevantContact.address1
          : "",
        address2: relevantContact
          ? relevantContact.location
            ? relevantContact.location.address2
            : relevantContact.address2
          : null,
        city: relevantContact
          ? relevantContact.location
            ? relevantContact.location.city
            : relevantContact.city
          : "",
        state: relevantContact
          ? relevantContact.location
            ? relevantContact.location.state
            : relevantContact.state
          : null,
        zipCode: relevantContact
          ? relevantContact.location
            ? relevantContact.location.zipCode
            : relevantContact.zipCode
          : "",
      };
    });

    return initialValues;
  },
);
