import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { getLocationStateCode, LocationStateData } from "shared/data/LocationState";
import { LocationStateCodes } from "shared/types/Location";
import { locationInputValidation } from "shared/validation/location";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Button } from "../../components/Button/Button";
import { FormInput } from "../../components/Form/Input";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { Modal } from "../../components/Modal/Modal";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useCreateLocation, useUpdateLocation } from "../../hooks/location";
import { useSlobFormik } from "../../hooks/useSlobFormik";
import type { Location } from "shared/types/Location";

export type LocationModalProps = {
  visible: boolean;
  clientId: string;
  location?: Location;
  onCancel: () => void;
  onSave: (location: Location) => void;
};

export const useLocationModalState = ({
  visible,
  clientId,
  location,
  onCancel,
  onSave,
}: LocationModalProps) => {
  const editing = !!location;

  // mutations
  const { mutateAsync: createLocation } = useCreateLocation();
  const { mutateAsync: updateLocation } = useUpdateLocation();

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      name: location?.name ?? "",
      address1: location?.address1 ?? "",
      address2: location?.address2 ?? null,
      city: location?.city ?? "",
      state: location?.state ?? null,
      zipCode: location?.zipCode ?? "",
      locationType: null,
    },
    validationSchema: locationInputValidation,
    validationContext: { prefill: true },
    onSubmit: async (values) => {
      const { isError, isSuccess, data } = location
        ? await updateLocation({
            data: values,
            params: {
              clientId,
              locationId: location.id,
            },
          })
        : await createLocation({
            data: values,
            params: {
              clientId,
            },
          });

      if (isError) {
        void slobMessage.error("Something went wrong");
      } else if (isSuccess) {
        formik.resetForm();
        onSave(data);
      }
    },
  });

  return { formik, visible, editing, onCancel };
};

export const LocationModalPresentation = ({
  formik,
  visible,
  editing,
  onCancel,
}: ReturnType<typeof useLocationModalState>) => {
  const title = editing ? "Edit location" : "Create location";
  return (
    <Modal title={title} footer={null} open={visible} onCancel={onCancel}>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32} style={{ width: "100%" }}>
          {editing && (
            <AlertBanner
              variant="info"
              message={
                <Body3>
                  Editing this location will update it across any administrators and billing
                  locations you’ve already created.
                </Body3>
              }
            />
          )}

          <FormInput
            label="Name of Location"
            value={formik.values.name}
            maxLength={191}
            name="name"
            touched={formik.touched.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            disabled={formik.isSubmitting}
          />

          <FormInput
            label="Address 1"
            value={formik.values.address1}
            maxLength={191}
            name="address1"
            touched={formik.touched.address1}
            onChange={formik.handleChange}
            error={formik.errors.address1}
            disabled={formik.isSubmitting}
          />

          <FormInput
            label="Address 2 (optional)"
            value={formik.values.address2 ?? ""}
            maxLength={191}
            name="address2"
            touched={formik.touched.address2}
            onChange={formik.handleChange}
            error={formik.errors.address2}
            disabled={formik.isSubmitting}
          />
          <Row gutter={16}>
            <Col span={12}>
              <FormInput
                label="City"
                value={formik.values.city}
                maxLength={191}
                name="city"
                touched={formik.touched.city}
                onChange={formik.handleChange}
                error={formik.errors.city}
                disabled={formik.isSubmitting}
              />
            </Col>
            <Col span={8}>
              <SlobSelect
                name="state"
                showRequired={true}
                value={formik.values.state || null}
                options={LocationStateCodes.map((stateCode) => ({
                  label: LocationStateData[stateCode].displayName,
                  value: stateCode,
                }))}
                placeholder="Select state"
                onChange={(val) => {
                  void formik.setFieldValue("state", val.value);
                }}
                error={formik.errors.state}
                touched={formik.touched.state}
                disabled={formik.isSubmitting}
                onSearch={async (val) => {
                  // TB-6250: support browser autofill for state
                  const stateCode = getLocationStateCode(val);
                  if (stateCode) {
                    await formik.setFieldValue("state", stateCode);
                  }
                }}
              />
            </Col>
            <Col span={4}>
              <FormInput
                label="Zip"
                value={formik.values.zipCode}
                maxLength={10}
                name="zipCode"
                touched={formik.touched.zipCode}
                onChange={formik.handleChange}
                error={formik.errors.zipCode}
                disabled={formik.isSubmitting}
              />
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <StackX dist={8}>
                <Button type="text" size="middle" onClick={onCancel} disabled={formik.isSubmitting}>
                  Cancel
                </Button>

                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                >
                  Save
                </Button>
              </StackX>
            </Col>
          </Row>
        </StackY>
      </form>
    </Modal>
  );
};

export const LocationModal = (props: LocationModalProps) => {
  const state = useLocationModalState(props);

  return <LocationModalPresentation {...state} />;
};
