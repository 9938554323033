import { formatDateFullMonthWithYear } from "../../../../shared/utils/format";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { Body1, H3 } from "../Typography/Typography";

type DueSoonModalProps = {
  dueDate: Date;
  isToday: boolean;
  open: boolean;
  onOk: () => void;
};

export function DueSoonModal(props: DueSoonModalProps) {
  const { dueDate, isToday, open, onOk } = props;

  return (
    <Modal
      open={open}
      onCancel={onOk}
      title={
        isToday ? "This task is due today and is still not complete." : "Completing tasks on time"
      }
      footer={
        <Button type="tertiary" size="middle" onClick={onOk}>
          Ok
        </Button>
      }
      focusTriggerAfterClose={false}
    >
      <Body1 as="p">
        This task is due {formatDateFullMonthWithYear(dueDate)} and is still not complete. Dates are
        set by your Implementation Consultant based on the effective dates for your plans.
      </Body1>

      <H3 as="p">Why does it matter?</H3>

      <Body1 as="p">
        A late task can put you at risk of not having your plans properly installed and ready for
        ongoing administration by their effective date. This means your employees may have trouble
        filing claims or getting service for plans they have enrolled in.
      </Body1>

      <H3 as="p">What should you do?</H3>

      <Body1 as="p">
        Complete this task before the due date. If you are concerned about your status, contact your
        Implementation Consultant.
      </Body1>
    </Modal>
  );
}
