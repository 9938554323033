import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { Col, Row } from "client/src/components/Grid/Grid";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import clsx from "clsx";
import { useState } from "react";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { classCompensationTypes, displayCompensationTypeName } from "shared/types/EmployeeClass";
import { getEmployeeClassEligibilityCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import * as Yup from "yup";
import { Button } from "../../../../components/Button/Button";
import { InternalLinkButton } from "../../../../components/Button/InternalLinkButton";
import { ErrorMessage } from "../../../../components/Error/ErrorMessage";
import { Checkbox } from "../../../../components/Form/Checkbox";
import { Modal } from "../../../../components/Modal/Modal";
import { StackY } from "../../../../components/Spacing/Spacing";
import { TagList } from "../../../../components/TagList/TagList";
import { Body2, Body3, Body5, H3 } from "../../../../components/Typography/Typography";
import { slobMessage } from "../../../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { useSlobId } from "../../../../hooks/useSlobId";
import { EIFSectionStatusIconography } from "../../common/EIFSectionStatusIconography";
import { EIFPlanConfigAndEligibilitySectionHeader } from "../EIFPlanConfigAndEligibilitySectionHeader";
import * as styles from "./eifClassBuilder.module.less";
import type { CreateClassQuery, UpdateClassQuery } from "../../../../hooks/employeeClass";
import type { FormikTypeFromValidator } from "../../../../hooks/useSlobFormik";
import type { SectionTracker } from "./EIFClassBuilderCreator";
import type { ChangeDetailInfoList } from "client/src/domain/EIF/common/EditBanner";
import type { useFormik } from "formik/dist/Formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, ClientId } from "shared/types/Client";
import type {
  EmployeeClass,
  CreateEmployeeClassInput,
  ClassCompensationType,
} from "shared/types/EmployeeClass";
import type { ValuesForValidationSchema } from "shared/types/Helper";

type Attributes = {
  jobTitle: boolean | null;
  compensationType: ClassCompensationType[] | null;
  otherCheckbox: boolean | null;
};
const attributeNamesToCheck: (keyof Attributes)[] = [
  "jobTitle",
  "compensationType",
  "otherCheckbox",
];

const attributesTestConfig: Yup.TestConfig<boolean | undefined> = {
  name: "required",
  message: "Please select at least 1 attribute from above",
  test: (_value, context) => {
    const parent: Attributes = context.parent;
    const hasAnyAttributeSelected = attributeNamesToCheck.some((attr) => Boolean(parent[attr]));
    const isValid = hasAnyAttributeSelected;
    return isValid;
  },
};

const validationSchema = Yup.object({
  jobTitle: Yup.boolean().test(attributesTestConfig).nullable(),
  jobTitles: Yup.array()
    .of(Yup.string().required())
    .strict()
    .when("jobTitle", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Please provide at least one job title")
          .required("Please provide at least one job title"),
    }),
  compensationType: Yup.boolean().test(attributesTestConfig).nullable(),
  compensationTypes: Yup.array()
    .of(Yup.mixed<ClassCompensationType>().required().oneOf(classCompensationTypes.slice()))
    .strict()
    .when("compensationType", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Please provide at least one compensation type")
          .required("Please provide at least one compensation type"),
    }),
  otherCheckbox: Yup.boolean().test(attributesTestConfig).nullable(),
  otherAttributes: Yup.array()
    .of(Yup.string().required())
    .strict()
    .when("otherCheckbox", {
      is: true,
      then: (schema) =>
        schema
          .min(1, "Please provide at least one item")
          .required("Please provide at least one item"),
    }),
});

type Props = {
  isActive: boolean;
  client: Client;
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  employeeClass: EmployeeClass | undefined;
  onSave: (employeeClass: EmployeeClass) => void;
  isLoading: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  track: SectionTracker;
  authUser: UserData | null;
};

export function EIFClassBuilderEligibility(props: Props) {
  const {
    isActive,
    client,
    createClassQuery: { mutateAsync: createClass },
    updateClassQuery: { mutateAsync: updateClass },
    employeeClass,
    onSave,
    isLoading,
    changeSnapshot,
    track,
    authUser,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const location = useLocation();

  const formik = useSlobFormik({
    validationSchema,
    initialValues: employeeClass
      ? convertClassToUIValues(employeeClass)
      : {
          jobTitle: false,
          jobTitles: [],
          compensationType: false,
          compensationTypes: [],
          otherCheckbox: false,
          otherAttributes: [],
        },
    validateOnChange: false,
    onSubmit: async () => {
      const createOrUpdateEmployeeClassInput = convertUIValuesIntoCreateEmployeeClassInput(
        client.id,
        formik.values,
      );

      const creating = !employeeClass;
      const { isSuccess, data } = creating
        ? await createClass({
            params: { clientId: client.id },
            data: createOrUpdateEmployeeClassInput,
          })
        : await updateClass({
            data: { ...createOrUpdateEmployeeClassInput, id: employeeClass.id },
            params: { clientId: client.id, employeeClassId: employeeClass.id },
          });

      if (isSuccess) {
        track("Save");
        void slobMessage.success(employeeClass ? "Class updated" : "Class created");
        onSave(data);
      }
    },
  });

  const attributeSelectionErrorIdString = useSlobId({ prefix: "attribute-selection-error" });

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const attributeSelectionError = attributeNamesToCheck
    .map((attr) => formik.touched[attr] && formik.errors[attr])
    .filter((err): err is string => Boolean(err))[0] as string | undefined;
  const attributeSelectionErrorId = attributeSelectionError
    ? attributeSelectionErrorIdString
    : undefined;

  const relevantFieldsToCheckForEmpty = ["jobTitle", "compensationType", "otherCheckbox"] as const;

  const isFormEmpty = relevantFieldsToCheckForEmpty.every((field) => {
    return formik.values[field] === false;
  });

  const inactiveAndUnsaved =
    !isActive &&
    !employeeClass?.jobTitles &&
    !employeeClass?.compensationTypes &&
    !employeeClass?.otherAttributes;

  const changeLogEmployeeClassId = employeeClass?.id ?? "";
  const singleClassBuilderMode = client.needsEmployeeClasses === "NO";
  const status = getEmployeeClassEligibilityCompletionStatus(employeeClass, singleClassBuilderMode);

  const benefitChangeDetailInfoList = [
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.jobTitles,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.compensationTypes,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.otherAttributes,
  ];

  if (!isActive) {
    return (
      <EIFPlanConfigAndEligibilitySectionHeader
        status={status}
        isLoading={isLoading}
        location={location}
        inactiveAndEmpty={inactiveAndUnsaved}
        changeDetailInfoList={benefitChangeDetailInfoList}
        section="Eligibility"
        client={client}
        authUser={authUser}
      >
        <StackY dist={4}>
          <Body3 as="div">{employeeClass?.jobTitles?.join(", ") ?? null}</Body3>

          <Body3 as="div">
            {employeeClass?.compensationTypes
              ?.map((item) => displayCompensationTypeName[item])
              .join(", ") ?? null}
          </Body3>

          <Body3 as="div">{employeeClass?.otherAttributes?.join(", ") ?? null}</Body3>
        </StackY>
      </EIFPlanConfigAndEligibilitySectionHeader>
    );
  }

  return (
    <>
      <div className="mb-24">
        <H3 as="h2">
          <EIFSectionStatusIconography status={status} />
          Eligibility
        </H3>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={4} className="mb-16">
          <Body2 as="div">Define this group of employees</Body2>

          <Body3 as="div">
            Select from any combination of attributes from below. Your selection are unique and
            specific to the way your company provides benefits
          </Body3>

          <ButtonOld
            type="link-inline"
            size="middle"
            onClick={() => {
              setModalVisible(!modalVisible);
            }}
          >
            Show examples
          </ButtonOld>
        </StackY>

        <StackY dist={32}>
          <fieldset>
            <StackY dist={12}>
              <JobTitlesSelection
                jobTitle={formik.values.jobTitle}
                jobTitles={formik.values.jobTitles}
                errors={formik.errors.jobTitles}
                handleChange={formik.handleChange}
                disabled={formik.isSubmitting}
                touched={formik.touched.jobTitles}
                attributeSelectionErrorId={attributeSelectionErrorId}
                changeDetailInfoList={[
                  changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.jobTitles ?? null,
                ]}
                client={client}
                authUser={authUser}
                formik={formik}
              />

              <CompensationTypesSelection
                compensationType={formik.values.compensationType}
                compensationTypes={formik.values.compensationTypes}
                errors={formik.errors.compensationTypes}
                handleChange={formik.handleChange}
                disabled={formik.isSubmitting}
                attributeSelectionErrorId={attributeSelectionErrorId}
                changeDetailInfoList={[
                  changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.compensationTypes ?? null,
                ]}
                client={client}
                authUser={authUser}
                formik={formik}
              />

              <div
                className={clsx(
                  formik.values.otherCheckbox
                    ? styles.eligibilitySectionContainerSelected
                    : styles.eligibilitySectionContainerUnselected,
                )}
              >
                <StackY dist={8}>
                  <Checkbox
                    name="otherCheckbox"
                    label="Other"
                    checked={Boolean(formik.values.otherCheckbox)}
                    errorId={attributeSelectionErrorId}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    content={
                      <>
                        <Body5 as="div">
                          Use this option if you need to define this group in some other way not
                          offered above. Some examples are union vs non-union, annual earnings,
                          location, or anything else.
                        </Body5>

                        {formik.values.otherCheckbox && (
                          <>
                            <TagList
                              name="otherAttributes"
                              placeholder="Add any attribute and press enter"
                              value={formik.values.otherAttributes}
                              onChange={formik.handleChange}
                              error={formik.errors.otherAttributes}
                              touched={formik.touched.otherAttributes}
                              disabled={formik.isSubmitting}
                            />
                            <Body5>
                              Your Implementation Consultant will reach out to you for details if
                              needed.
                            </Body5>
                          </>
                        )}
                      </>
                    }
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.otherAttributes ??
                        null,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({ field: "otherAttributes", client, formik })}
                  />
                </StackY>
              </div>
            </StackY>
          </fieldset>

          {attributeSelectionError && (
            <div className="mb-24">
              <Row justify="end">
                <Col>
                  <ErrorMessage
                    id={attributeSelectionErrorId}
                    children={attributeSelectionError}
                    size="large"
                  />
                </Col>
              </Row>
            </div>
          )}

          <Row justify="end">
            <Col>
              <Row align="middle" gutter={28}>
                <Col>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={location.pathname}
                    disabled={formik.isSubmitting}
                    onClick={() => flushSync(formik.resetForm)}
                  >
                    Cancel
                  </InternalLinkButton>
                </Col>

                <Col>
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting || isFormEmpty}
                    size="middle"
                  >
                    Save & Continue
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {formik.status && (
            <Body2 as="div">
              <ErrorMessage children={formik.status} />
            </Body2>
          )}
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />

      <Modal
        open={modalVisible}
        title={"Eligibility examples"}
        disableClose={false}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <p>Here are some common examples of how you could define eligible employee groups.</p>
        <br />

        <StackY dist={40}>
          <div>
            <h3>Example 1 – Contribution differences</h3>
            <p>
              All of your employees are salaried and offered Dental, but owners have different
              contribution amounts than all other employees.
            </p>
            <p>
              In this example, two eligible employee groups are needed. Each will have only the
              eligibility attribute of <b>'Job title'</b> noted here. Since the employment and
              compensation type are the same for both groups, you do not need to specify those as
              separate attributes.
            </p>
            <StackY dist={16}>
              <div className={styles.exampleImageContainer}>
                <JobTitlesSelection
                  jobTitle={true}
                  jobTitles={["Owners"]}
                  errors={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- disable
                  handleChange={() => {}}
                  disabled={false}
                  touched={true}
                  attributeSelectionErrorId={undefined}
                  isExample={true}
                  changeDetailInfoList={[]}
                  client={client}
                  authUser={authUser}
                  formik={formik}
                />
                <div className="text-center">
                  <Body5 as="p">Your first group would be Owners</Body5>
                </div>
              </div>
              <div className={styles.exampleImageContainer}>
                <JobTitlesSelection
                  jobTitle={true}
                  jobTitles={["All Other Employees"]}
                  errors={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- disable
                  handleChange={() => {}}
                  disabled={false}
                  touched={true}
                  attributeSelectionErrorId={undefined}
                  isExample={true}
                  changeDetailInfoList={[]}
                  client={client}
                  authUser={authUser}
                  formik={formik}
                />
                <div className="text-center">
                  <Body5 as="p">
                    Your second eligible employee group would be All Other Employees.
                  </Body5>
                </div>
              </div>
            </StackY>
          </div>

          <div>
            <h3>Example 2 – Waiting period differences</h3>
            <p>
              All of your employees are offered Dental and Vision, but your salaried have a
              different waiting period than hourly employees.
            </p>
            <p>
              In this example, two eligible employee groups are needed with the following
              eligibility attribute of <b>'Compensation Type'</b> noted here:
            </p>
            <StackY dist={16}>
              <div className={styles.exampleImageContainer}>
                <CompensationTypesSelection
                  compensationType={true}
                  compensationTypes={["SALARIED"]}
                  errors={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- disable
                  handleChange={() => {}}
                  disabled={false}
                  attributeSelectionErrorId={undefined}
                  changeDetailInfoList={[]}
                  client={client}
                  authUser={authUser}
                  formik={formik}
                />
                <div className="text-center">
                  <Body5 as="p">Your first group would be salaried.</Body5>
                </div>
              </div>
              <div className={styles.exampleImageContainer}>
                <CompensationTypesSelection
                  compensationType={true}
                  compensationTypes={["HOURLY"]}
                  errors={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- disable
                  handleChange={() => {}}
                  disabled={false}
                  attributeSelectionErrorId={undefined}
                  changeDetailInfoList={[]}
                  client={client}
                  authUser={authUser}
                  formik={formik}
                />
                <div className="text-center">
                  <Body5 as="p">Your second group would be hourly.</Body5>
                </div>
              </div>
            </StackY>
          </div>
        </StackY>
      </Modal>
    </>
  );
}

function convertUIValuesIntoCreateEmployeeClassInput(
  clientId: ClientId,
  values: ValuesForValidationSchema<typeof validationSchema>,
) {
  // TB-5305: set values.X to null when not set in UI to make sure updates remove past values in DB
  const createEmployeeClassInput: CreateEmployeeClassInput = {
    clientId,
    jobTitles: values.jobTitle ? values.jobTitles : null,
    compensationTypes: values.compensationType ? values.compensationTypes : null,
    otherAttributes: values.otherCheckbox ? values.otherAttributes : null,
  };

  return createEmployeeClassInput;
}

function convertClassToUIValues(
  employeeClass: EmployeeClass,
): ValuesForValidationSchema<typeof validationSchema> {
  const values = {
    jobTitle: Boolean(employeeClass.jobTitles && employeeClass.jobTitles.length > 0),
    jobTitles: employeeClass.jobTitles || undefined,
    compensationType: Boolean(
      employeeClass.compensationTypes && employeeClass.compensationTypes.length > 0,
    ),
    compensationTypes: employeeClass.compensationTypes || undefined,
    otherCheckbox: Boolean(
      employeeClass.otherAttributes && employeeClass.otherAttributes.length > 0,
    ),
    otherAttributes: employeeClass.otherAttributes || undefined,
  };

  return values;
}

type JobTitlesSelectionProps = {
  jobTitle: boolean | null | undefined;
  jobTitles: string[] | undefined;
  errors: string | undefined;
  handleChange: ReturnType<typeof useFormik>["handleChange"];
  disabled: boolean;
  touched: boolean | undefined;
  attributeSelectionErrorId: string | undefined;
  isExample?: boolean;
  changeDetailInfoList: ChangeDetailInfoList;
  client: Client;
  authUser: UserData | null;
  formik: FormikTypeFromValidator<typeof validationSchema>;
};
const JobTitlesSelection = (props: JobTitlesSelectionProps) => {
  const {
    jobTitle,
    jobTitles,
    errors,
    handleChange,
    disabled,
    touched,
    attributeSelectionErrorId,
    isExample,
    changeDetailInfoList,
    client,
    authUser,
    formik,
  } = props;
  return (
    <div
      className={clsx(
        jobTitle
          ? styles.eligibilitySectionContainerSelected
          : styles.eligibilitySectionContainerUnselected,
      )}
    >
      <StackY dist={8}>
        <Checkbox
          name="jobTitle"
          label="Job title"
          checked={Boolean(jobTitle)}
          errorId={attributeSelectionErrorId}
          onChange={handleChange}
          disabled={disabled}
          content={
            <>
              <Body5>Use job title like CEO, Directors or Managers.</Body5>

              {jobTitle && (
                <div>
                  <TagList
                    name="jobTitles"
                    placeholder="Add a job title and press enter"
                    value={jobTitles}
                    onChange={handleChange}
                    touched={touched}
                    error={errors}
                    disabled={disabled}
                  />

                  {!isExample && (
                    <ul className="pl-20 mt-12 mb-0">
                      <li>Do not use abbreviations.</li>
                      <li>
                        You can use <b>All Other Employees</b> as a job title if you need a group
                        that contains everyone who is not in the other groups you’ve created.
                      </li>
                    </ul>
                  )}
                </div>
              )}
            </>
          }
        />
        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({ field: "jobTitles", client, formik })}
        />
      </StackY>
    </div>
  );
};

type CompensationTypesSelectionProps = {
  compensationType: boolean | null | undefined;
  compensationTypes: ClassCompensationType[] | undefined;
  errors: string | undefined;
  handleChange: ReturnType<typeof useFormik>["handleChange"];
  disabled: boolean;
  attributeSelectionErrorId: string | undefined;
  changeDetailInfoList: ChangeDetailInfoList;
  client: Client;
  authUser: UserData | null;
  formik: FormikTypeFromValidator<typeof validationSchema>;
};

const CompensationTypesSelection = (props: CompensationTypesSelectionProps) => {
  const {
    compensationType,
    compensationTypes,
    errors,
    handleChange,
    disabled,
    attributeSelectionErrorId,
    changeDetailInfoList,
    client,
    authUser,
    formik,
  } = props;
  return (
    <div
      className={clsx(
        compensationType
          ? styles.eligibilitySectionContainerSelected
          : styles.eligibilitySectionContainerUnselected,
      )}
    >
      <StackY dist={8}>
        <Checkbox
          name="compensationType"
          label="Compensation type"
          checked={Boolean(compensationType)}
          errorId={attributeSelectionErrorId}
          onChange={handleChange}
          disabled={disabled}
          content={
            <>
              <Body5>
                Specify whether this group includes Salaried, Hourly, Exempt, or Non-exempt.
              </Body5>

              {compensationType && (
                <>
                  {classCompensationTypes.map((compensationType) => (
                    <Checkbox
                      key={`compensationTypes_${compensationType}`}
                      name="compensationTypes"
                      value={compensationType}
                      label={displayCompensationTypeName[compensationType]}
                      checked={!!compensationTypes?.includes(compensationType)}
                      errorId={errors}
                      onChange={handleChange}
                      disabled={disabled}
                    />
                  ))}
                  {errors && <InputErrorMessage error={errors} />}
                </>
              )}
            </>
          }
        />
        <EditedFieldMsg
          changeDetailInfoList={changeDetailInfoList}
          client={client}
          authUser={authUser}
          hasPendingEdit={
            getHasPendingEdit({
              field: "compensationType",
              variant: "checkbox",
              client,
              formik,
            }) ||
            getHasPendingEdit({
              field: "compensationTypes",
              variant: "checkbox",
              client,
              formik,
            })
          }
        />
      </StackY>
    </div>
  );
};
