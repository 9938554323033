import { useHideZendeskWidget } from "client/src/utils/zendesk";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { BEN_ADMIN, BROKER, SLF_ADMIN, SLF_OWNER, roleIds } from "../../../shared/rbac/roles";

import { LoadingError } from "../components/Error/LoadingError";
import { BasePageLoading } from "../components/Loading/Loading";
import { useSlobAuth } from "../hooks/auth";
import { triggerError } from "../hooks/generalError";

export const IndexPage = () => {
  const { isLoading: isLoadingAuth, authUser } = useSlobAuth();

  useHideZendeskWidget();

  if (isLoadingAuth) {
    return <BasePageLoading />;
  }

  if (!authUser?.role || !roleIds.includes(authUser?.role)) {
    return triggerError(
      `Invalid user configuration: Invalid Or Missing User Role: ${authUser?.role}`,
    );
  }

  switch (authUser?.role) {
    case BEN_ADMIN:
      if (!authUser?.termsOfUseDate) {
        return <Navigate to={RouteData.termsOfUse.getPath()} replace />;
      }
      if (!Array.isArray(authUser.clientIds) || authUser.clientIds.length === 0) {
        return (
          <LoadingError
            type="custom"
            childElement={
              <>
                <h1>Your account is not attached to a Client.</h1>
                <h3>Please contact an admin.</h3>
              </>
            }
          />
        );
      }
      if (authUser && authUser.clientIds.length > 1) {
        return <Navigate to={RouteData.homeBenAdmin.getPath()} replace />;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we checked authUser.clientIds above
      return <Navigate to={RouteData.homeHub.getPath(authUser.clientIds[0]!)} replace />;

    case BROKER:
      if (!authUser?.termsOfUseDate) {
        return <Navigate to={RouteData.termsOfUse.getPath()} replace />;
      }
      return <Navigate to={RouteData.homeBroker.getPath()} replace />;
    case SLF_ADMIN:
    case SLF_OWNER:
      return <Navigate to={RouteData.homeInternal.getPath()} replace />;
    default:
      exhaustiveCheck(authUser?.role);
  }

  return null;
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default IndexPage;
