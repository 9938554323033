import { StackY } from "client/src/components/Spacing/Spacing";
import { useQueryString } from "client/src/hooks/useQueryString";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { Button } from "../../components/Button/Button";
import { ActivationCard } from "../../components/HubCard/ActivationCard";
import { Loading } from "../../components/Loading/Loading";
import { H2 } from "../../components/Typography/Typography";
import { PasswordUpsertForm } from "../../domain/User/Form/PasswordUpsertForm";
import { useActivationTokenExpired } from "../../hooks/user";
import { useTrackElementClicked } from "../../utils/analytics";

import { LoginPage } from "./LoginPage";

export const ActivatePage = () => {
  const [initialLogin, setInitialLogin] = useState(false);
  const trackElementClicked = useTrackElementClicked();

  const navigate = useNavigate();

  const queryString = useQueryString();
  const clientId = queryString.get("sgmClientId") ?? "";

  const {
    data,
    token,
    isInitialLoading: isLoadingTokenExpired,
    isExpired,
  } = useActivationTokenExpired();

  if (initialLogin) {
    return <LoginPage initialLogin={true} email={data?.email} />;
  }

  if (isLoadingTokenExpired) {
    return <Loading />;
  }

  const goReactivateClick = () => {
    trackElementClicked({
      module: "Activation Link Expired",
      buttonLabel: "Request Activation Link",
    });
    navigate(RouteData.reactivate.getPath());
  };

  const goLoginClick = () => {
    trackElementClicked({ module: "Activation Link Expired", buttonLabel: "Return to Sign in" });
    navigate(RouteData.login.getPath());
  };

  if (isExpired || !data) {
    // Expired link flow
    return (
      <ActivationCard>
        <StackY dist={24}>
          <H2 as="h1">Activation Link Expired</H2>
          <p className="mb-24">
            Sorry, but the link you used to get here has expired. Don't worry, we can send you a new
            link so you can continue to set up your Sun Life Onboard account.
          </p>
          <StackY dist={16}>
            <Button type="primary" size="large" block onClick={goReactivateClick}>
              Request Activation Link
            </Button>
            <div className="text-center">
              <Button type="text" onClick={goLoginClick}>
                Return to Sign in
              </Button>
            </div>
          </StackY>
        </StackY>
      </ActivationCard>
    );
  }
  const { email, firstName, lastName } = data;

  return (
    <ActivationCard>
      <H2 as="h1">Activate Your Account</H2>
      <p className="mb-32">
        Create your account by entering a password. You'll use this to sign in to Sun Life Onboard.
      </p>
      <p>
        Your email address
        <br />
        <strong>{email}</strong>
      </p>
      <PasswordUpsertForm
        onSuccess={() => setInitialLogin(true)}
        email={email}
        clientId={clientId}
        firstName={firstName}
        lastName={lastName}
        token={token}
        passwordFormLabel="Create your password"
        submitButtonLabel="Activate Account"
        isActivation={true}
        track={() =>
          trackElementClicked({ module: "Activate Your Account", buttonLabel: "Activate Account" })
        }
      />
    </ActivationCard>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default ActivatePage;
