import { BillCardDouble } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillCardDouble";
import { BillCardSingle } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/Card/BillCardSingle";
import { getBillHasChanges } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { getBillTuples } from "shared/utils/bill";
import { assertIsDefined } from "shared/utils/utils";
import type { BillSplitType } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { PolicyBillingPreferences } from "shared/validation/bill";

type Props = {
  bills: (Bill | BillPreview)[];
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billSplitType: BillSplitType | null | undefined;
  policyBillingPreferences: PolicyBillingPreferences;
  signerMode: SignerMode;
  disabled?: boolean;
  expanded?: boolean;
  onEdit?: (bill1Index: number, bill2Index?: number) => void;
  onDelete?: (bill1Index: number, bill2Index?: number) => void;
};

export function BillsList(props: Props) {
  const {
    bills: billsUnsorted,
    client,
    policy,
    featureToggles,
    changeSnapshot,
    authUser,
    billSplitType,
    policyBillingPreferences,
    signerMode,
    disabled,
    expanded,
    onEdit,
    onDelete,
  } = props;

  const billPairs = getBillTuples(billSplitType, billsUnsorted);
  const bills = billPairs.flat();

  const billIndices = new WeakMap(bills.map((bill, index) => [bill, index]));

  const showBills = bills.reduce<Record<string, boolean>>((acc, curr) => {
    if (signerMode === "inside") {
      acc[curr.id ?? ""] = true;
    } else if (signerMode === "outside") {
      assertIsDefined(curr.id, "bill.id");
      acc[curr.id] = getBillHasChanges(curr, changeSnapshot);
    }
    return acc;
  }, {});

  return (
    <>
      {billPairs.map((billPair, index) => {
        if (Array.isArray(billPair)) {
          const [advanceBill, arrearsBill] = billPair;
          const index1 = billIndices.get(advanceBill);
          const index2 = billIndices.get(arrearsBill);
          assertIsDefined(index1, "index1");

          if (!showBills[advanceBill.id ?? ""] && !showBills[arrearsBill.id ?? ""]) {
            return null;
          }

          return (
            <BillCardDouble
              key={index}
              client={client}
              policy={policy}
              featureToggles={featureToggles}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              bills={bills}
              advanceBill={advanceBill}
              arrearsBill={arrearsBill}
              policyBillingPreferences={policyBillingPreferences}
              disabled={disabled}
              // Both Advanced and Arrears bills are linked so editing
              // or deleting one will also apply to the other
              onEdit={onEdit ? () => onEdit(index1, index2) : undefined}
              onDelete={onDelete ? () => onDelete(index1, index2) : undefined}
              expanded={expanded}
            />
          );
        } else {
          const bill = billPair;
          const index = billIndices.get(bill);
          assertIsDefined(index, "index");

          if (!showBills[bill.id ?? ""]) {
            return null;
          }

          return (
            <BillCardSingle
              key={index}
              index={index}
              client={client}
              policy={policy}
              featureToggles={featureToggles}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              bills={bills}
              bill={bill}
              policyBillingPreferences={policyBillingPreferences}
              disabled={disabled}
              onEdit={onEdit ? () => onEdit(index) : undefined}
              onDelete={onDelete ? () => onDelete(index) : undefined}
              expanded={expanded}
            />
          );
        }
      })}
    </>
  );
}
