import { InputNumber as AntInputNumber } from "antd";
import clsx from "clsx";
import { useCallback, useState } from "react";

import { InputErrorMessage } from "./InputErrorMessage";
import * as styles from "./form.module.less";

import type { CommonFormInputsProps } from "./formTypes";

export type InputNumberProps = CommonFormInputsProps & {
  value?: number | null;
  topText?: string;
  name: string;
  label: string;
  maxLength: number;
  bottomText?: string;
  onChange?: (value: number | null) => void;
  formatter?: (value: number | undefined) => string;
  parser?: (displayValue: string | undefined) => number;
  min?: number;
  max?: number;
  step?: number;
  precision?: number;
};

export const FormInputNumber = ({
  topText,
  value,
  name,
  label,
  showRequired,
  disabled = false,
  onBlur,
  onFocus,
  error,
  touched,
  onChange,
  maxLength,
  bottomText,
  formatter,
  parser,
  min,
  max,
  step,
  precision,
}: InputNumberProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const shouldShowError = error && touched;

  const labelClass = clsx(
    styles.formLabel,
    { [styles.formLabelOver]: value !== undefined || isFocused },
    { [styles.formLabelDisabled]: disabled },
  );
  const inputClass = clsx(styles.formInput, { [styles.inputError]: shouldShowError });

  const onInputFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(true);
      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const onInputBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsFocused(false);
      if (onBlur) {
        onBlur(e);
      }
    },
    [onBlur],
  );

  return (
    <div className={styles.fieldContainer}>
      {topText && (
        <p id={`info-top-${name}`} className={clsx("body2", styles.topText)}>
          {topText}
        </p>
      )}
      <label className={labelClass} htmlFor={name}>
        {label}
        {showRequired ? "*" : ""}
      </label>
      <AntInputNumber
        id={name}
        value={value}
        aria-invalid={!!shouldShowError}
        aria-describedby={`info-top-${name} info-bottom-${name}`}
        aria-errormessage={`error-${name}`}
        onChange={onChange}
        maxLength={maxLength}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        disabled={disabled}
        className={inputClass}
        formatter={formatter}
        parser={parser}
        step={step}
        min={min}
        max={max}
        precision={precision}
        style={{ width: "100%" }}
      />
      {shouldShowError && <InputErrorMessage id={`error-${name}`} error={error} />}
      {bottomText && (
        <span id={`info-bottom-${name}`} className={clsx("body5", styles.bottomText)}>
          {bottomText}
        </span>
      )}
    </div>
  );
};
