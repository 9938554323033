import { getIsCoverageSupportedInEIF } from "shared/types/SlfCoverages";
import type { BillSplitType, BillingStructureType } from "@prisma/client";
import type { Bill } from "shared/types/Bill";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export function getCoveragesInClientNotYetInAnyBill(
  allPoliciesSlfCoverages: SlfCoverageLongName[] | null,
  billingStructureType: BillingStructureType | null,
  billSplitType: BillSplitType | null | undefined,
  bills: Pick<Bill, "slfCoverages">[],
) {
  const multipleBillsSplitByBenefit =
    billingStructureType === "MULTIPLE" && billSplitType === "BENEFIT";

  if (!multipleBillsSplitByBenefit) {
    return null;
  }

  const billsCoverages = new Set(bills.flatMap((bill) => bill.slfCoverages || []));
  const coveragesInClientNotYetInAnyBill = (allPoliciesSlfCoverages ?? [])
    .filter(getIsCoverageSupportedInEIF)
    .filter((c) => !billsCoverages.has(c));

  return coveragesInClientNotYetInAnyBill;
}
