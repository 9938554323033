import { Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";

import { Loading } from "../../components/Loading/Loading";
import { PageContent } from "../../components/PageContent/PageContent";
import { BenAdminClientsList } from "../../domain/Client/ClientsList/BenAdminClientsList";
import { useSlobAuth } from "../../hooks/auth";
import type { ClientUserTableFilters } from "shared/types/Client";

const { Title } = Typography;

export const BenAdminClientsPage = () => {
  const { authUser, isLoading } = useSlobAuth();
  const { data: featureToggles, isLoading: isLoadingFT } = useGetFeatureToggles();
  const userId = authUser?.id || "";
  const searchParams: ClientUserTableFilters = {
    userId,
    phaseIds: [
      "WELCOME",
      "INFO_GATHERING",
      "REVIEW_AND_VALIDATE",
      "PLAN_APPROVAL",
      "CONTRACTS_AND_BILLING",
      "ONBOARDING_COMPLETE",
    ],
    completedSetup: true,
  };

  if (isLoading || isLoadingFT) {
    return <Loading />;
  }

  return (
    <PageContent>
      <Row>
        <Col span={15}>
          <Title>{authUser?.name}</Title>
        </Col>
      </Row>

      <BenAdminClientsList extraSearchParams={searchParams} featureToggles={featureToggles} />
    </PageContent>
  );
};
