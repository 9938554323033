import { Button } from "client/src/components/Button/Button";
import { ChipGroup } from "client/src/components/Chip/ChipGroup";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { EIFBottomNavButtons } from "client/src/domain/EIF/EIFBottomNavButtons";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsCoverageSupportedInEIF, getIsStatutoryCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { previousCoverageInformationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { useGetEIFPreviousAndNextLink } from "../../../../hooks/useGetEIFPreviousAndNextLink";
import { useClientUtils } from "../../../Client/useClientUtils";
import type { ElementClickedOptions } from "../../../../utils/analytics";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FC } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EIFSubStepId } from "shared/types/EIF";
import type { Plan } from "shared/types/Plan";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const getClientPropertiesToUpdate = (client: Client) => {
  const { benefitsReplacedWithSlf } = client;
  return {
    benefitsReplacedWithSlf,
  };
};

export type EIFPreviousCoverageInformationProps = {
  client: Client;
  plans: Plan[];
  trackElementClicked: (options: ElementClickedOptions) => void;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

export const EIFPreviousCoverageInformation: FC<EIFPreviousCoverageInformationProps> = (props) => {
  const {
    client,
    plans,
    updateClient,
    trackElementClicked,
    changeSnapshot,
    authUser,
    featureToggles,
  } = props;

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const eifSubStepId: EIFSubStepId = "previous-coverage-information";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    plans,
  });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: previousCoverageInformationSchema,
    type: "DEIF Benefits Replaced by Sun Life",
    track,
    onSuccessCallback: () => {
      navigate(nextSubStepLink);
    },
    prefill: true,
  });

  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const onNotReplacingBenefitsChanged = async (event: CheckboxChangeEvent) => {
    await formik.setFieldValue("benefitsReplacedWithSlf", event.target.checked ? "none" : []);
  };

  const notReplacingBenefitsChecked = formik.values.benefitsReplacedWithSlf === "none";

  const clientEIFSupportedSlfCoverages =
    client.allPoliciesSlfCoverages
      ?.filter(getIsCoverageSupportedInEIF)
      .filter((coverage) => !getIsStatutoryCoverage(coverage)) ?? [];

  const relevantSlfCoverages = featureToggles.ONBOARD_ASO_DENTAL
    ? clientEIFSupportedSlfCoverages
    : clientEIFSupportedSlfCoverages.filter(
        (coverage: SlfCoverageLongName) => coverage !== "ASO Dental", // don't show ASO Dental if on Jira ticket, but FT off
      );

  const error = formik.touched.benefitsReplacedWithSlf
    ? formik.errors.benefitsReplacedWithSlf
    : undefined;
  const errorId = error ? "benefitsReplacedWithSlf__errormessage" : undefined;

  return (
    <>
      <StackY dist={40} wrap={false}>
        <h2 className="mb-40">{subStepName}</h2>

        <form onSubmit={formik.handleSubmit}>
          <StackY dist={32} wrap={false}>
            <HubCard>
              <Body2 as="p">
                Please select any benefits that you currently have with other carriers but will be
                replacing with Sun Life.
              </Body2>

              <Body3 as="p">
                If you are replacing any benefits with Sun Life, a copy of the prior carrier
                certificate and prior carrier bill is required. You can upload those in the tasks on
                your dashboard.
              </Body3>

              <StackY dist={32} wrap={false}>
                <StackY dist={16} wrap={false}>
                  {relevantSlfCoverages.length > 1 && (
                    <Button
                      type="text-only"
                      size="middle"
                      disabled={formik.isSubmitting}
                      onClick={async () => {
                        if (
                          formik.values.benefitsReplacedWithSlf !== "none" &&
                          formik.values.benefitsReplacedWithSlf?.length ===
                            relevantSlfCoverages.length
                        ) {
                          await formik.setFieldValue("benefitsReplacedWithSlf", []);
                        } else {
                          await formik.setFieldValue(
                            "benefitsReplacedWithSlf",
                            relevantSlfCoverages,
                          );
                        }
                      }}
                    >
                      {formik.values.benefitsReplacedWithSlf !== "none" &&
                      formik.values.benefitsReplacedWithSlf?.length === relevantSlfCoverages.length
                        ? "Deselect all"
                        : "Select all"}
                    </Button>
                  )}

                  <ChipGroup
                    name="benefitsReplacedWithSlf"
                    value={
                      Array.isArray(formik.values.benefitsReplacedWithSlf)
                        ? formik.values.benefitsReplacedWithSlf
                        : []
                    }
                    errorId={errorId}
                    touched={formik.touched.benefitsReplacedWithSlf}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    options={relevantSlfCoverages.map((coverage) => {
                      return {
                        label: coverage,
                        value: coverage,
                      };
                    })}
                  />

                  <Body3 as="div">or</Body3>

                  <Checkbox
                    label="I’m not replacing any benefits"
                    name="notReplacingBenefits"
                    checked={notReplacingBenefitsChecked}
                    onChange={onNotReplacingBenefitsChanged}
                    errorId={errorId}
                    disabled={formik.isSubmitting}
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.benefitsReplacedWithSlf ?? null]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "benefitsReplacedWithSlf",
                      client,
                      formik,
                    })}
                  />
                </StackY>

                <div aria-live="assertive" className="hide:Empty">
                  {error && <InputErrorMessage id={errorId} error={error} />}
                </div>

                <div aria-live="assertive" className="hide:Empty">
                  {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
                </div>
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </form>

        <hr />

        <div>
          <h2>
            Why does Sun Life need prior carrier certificates or bills when replacing coverage from
            another carrier?
          </h2>

          <Body3 as="p">
            A copy of the prior carrier certificate and prior carrier bill is requested when you are
            replacing a benefit from another carrier with Sun Life. Information contained in the
            prior carrier certificate is essential to help protect your employees’ benefit levels
            and minimize claims issues when you change insurers. The prior carrier certificate helps
            us configure your plan accurately when transitioning to your new group policy.
          </Body3>

          <Body3 as="p">
            Please visit the{" "}
            <strong>
              <i>Prior Carrier Certificates</i>
            </strong>{" "}
            or{" "}
            <strong>
              <i>Prior Carrier Bills</i>
            </strong>{" "}
            tasks from the Dashboard if you need to upload these files.
          </Body3>
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
