import { getIsNode } from "client/src/utils/getIsNode";
import { useRef, useLayoutEffect, useEffect } from "react";

export function useClickAway<T extends Element>(cb: (ev: MouseEvent | TouchEvent) => void) {
  const ref = useRef<T | null>(null);
  const refCb = useRef(cb);

  useLayoutEffect(() => {
    refCb.current = cb;
  });

  useEffect(() => {
    const handler = (e: MouseEvent | TouchEvent) => {
      const element = ref.current;
      const isNode = getIsNode(e.target);
      if (element && isNode && !element.contains(e.target)) {
        refCb.current(e);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, []);

  return ref;
}
