import type { Client } from "shared/types/Client";

export function getShouldAskAgeQuestionForSpouseDependentLife(
  client: Pick<Client, "allPoliciesSlfCoverages" | "changesForDependentLifeBenefitsUseAge">,
) {
  const allCoverages = client.allPoliciesSlfCoverages ?? [];
  const slfCoverageHasSpouseVolLife = allCoverages.includes("Spouse Voluntary Life");
  const slfCoverageHasDependentBasicLife = allCoverages.includes("Dependent Basic Life");
  const changesForDependentLifeBenefitsUseAge =
    client.changesForDependentLifeBenefitsUseAge === "YES";

  return (
    slfCoverageHasSpouseVolLife ||
    (slfCoverageHasDependentBasicLife &&
      !slfCoverageHasSpouseVolLife &&
      changesForDependentLifeBenefitsUseAge)
  );
}
