import { useGetDeletedBills } from "client/src/hooks/bill";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import memoizeOne from "memoize-one";
import { type Client } from "shared/types/Client";
import {
  getIsAdvanceCoverage,
  getIsArrearsCoverage,
  getIsAdvanceOrArrearsCoverage,
} from "shared/types/SlfCoverages";
import { getIsChangeDetailInfo } from "shared/utils/EIF/changeLog";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getBillingPreferencesInitialFormValues } from "shared/utils/bill";
import { getEIFSubStepViewMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { exhaustiveCheck, exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { assertIsDefined, getArePropertiesEmpty, rejectNullableValues } from "shared/utils/utils";
import { billingPreferencesSchema } from "shared/validation/policy";
import type { BillTiming, BillSplitType } from "@prisma/client";
import type { BillGroup, BillPreview } from "shared/types/Bill";
import type { ChangeDetailInfo, ChangeMetadata, DEIFChangeSnapshot } from "shared/types/Change";
import type { Policy } from "shared/types/Client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import type { BillFormValues, PolicyBillingPreferences } from "shared/validation/bill";

export const getDefaultBillNames = memoizeOne(
  (bills: Array<{ billName: string | null; billTiming?: BillTiming | null }>) => {
    const advanceBills = bills.filter((bill) => bill.billTiming === "Advance");
    const arrearsBills = bills.filter((bill) => bill.billTiming === "Arrears");

    const hasAdvance = advanceBills.length > 0;
    const hasArrears = arrearsBills.length > 0;
    const includeTiming = hasAdvance && hasArrears;

    const advanceBillIndices = new WeakMap(advanceBills.map((bill, index) => [bill, index]));
    const arrearsBillIndices = new WeakMap(arrearsBills.map((bill, index) => [bill, index]));

    const defaultBillNames = bills.map((bill, index) => {
      if (bill.billName) return bill.billName;
      if (includeTiming) {
        const timingIndex = advanceBillIndices.get(bill) ?? arrearsBillIndices.get(bill);
        return timingIndex != null
          ? `${bill.billTiming} bill ${timingIndex + 1}`
          : `${bill.billTiming} bill`;
      }
      return `Bill ${index + 1}`;
    });
    return defaultBillNames;
  },
);

export const getBillsFromBillGroup = (args: {
  billGroup: BillGroup;
  policy: Policy;
  billSplitType: BillSplitType | null;
  advanceOrArrears: AdvanceOrArrearsSelection;
  existingBill1: Pick<BillPreview, "id" | "createdAt"> | undefined;
  existingBill2: Pick<BillPreview, "id" | "createdAt"> | undefined;
}) => {
  const { billGroup, policy, billSplitType, advanceOrArrears, existingBill1, existingBill2 } = args;

  const { hasMixedTiming, advanceCoveragesAll, arrearsCoveragesAll } =
    getAdvanceAndArrearsCoveragesBreakdown(policy, advanceOrArrears);

  const billTimingsToCoverages = getBillTimingsToCoverages(policy.slfCoverages, advanceOrArrears);

  if (hasMixedTiming && billSplitType !== "BENEFIT" && billSplitType !== null) {
    const {
      categorizeEmployees_secondary,
      employeeCategorizationType_secondary,
      categoriesByTags_secondary,
      categorizeByLocationIds_secondary,
      ...commonProps
    } = billGroup;
    const advanceBill: BillFormValues = {
      id: existingBill1?.id ?? null,
      createdAt: existingBill1?.createdAt ?? null,
      ...commonProps,
      billTiming: "Advance" as const,
      slfCoverages: billTimingsToCoverages.get("Advance") ?? null,
    };
    const arrearsBill: BillFormValues = {
      id: existingBill2?.id ?? null,
      createdAt: existingBill2?.createdAt ?? null,
      ...commonProps,
      billTiming: "Arrears" as const,
      slfCoverages: billTimingsToCoverages.get("Arrears") ?? null,
      categorizeEmployees: categorizeEmployees_secondary,
      employeeCategorizationType: employeeCategorizationType_secondary,
      categoriesByTags: categoriesByTags_secondary,
      categorizeByLocationIds: categorizeByLocationIds_secondary,
    };

    return [advanceBill, arrearsBill] as const;
  } else {
    const {
      categorizeEmployees_secondary,
      employeeCategorizationType_secondary,
      categoriesByTags_secondary,
      categorizeByLocationIds_secondary,
      ...commonProps
    } = billGroup;

    const billTiming = getBillTimingForSingleTiming(
      advanceCoveragesAll,
      arrearsCoveragesAll,
      billSplitType,
      billGroup,
      billTimingsToCoverages,
    );

    const bill: BillFormValues = {
      id: existingBill1?.id ?? null,
      createdAt: existingBill1?.createdAt ?? null,
      ...commonProps,
      billTiming,
      slfCoverages:
        // If splitting by benefit, we just pick the coverages the user selected.
        // Else, we auto-pick the all the coverages that have the right bill timing.
        billSplitType === null
          ? policy.slfCoverages
          : billSplitType === "BENEFIT"
          ? billGroup.slfCoverages
          : billTiming
          ? billTimingsToCoverages.get(billTiming) ?? null
          : null,
    };

    return [bill] as const;
  }
};

export function getBillGroupFormValuesFromBills(
  bill1: BillPreview | undefined,
  bill2: BillPreview | undefined,
) {
  const billGroup = {
    // Shared across both bills
    groupByLocationIds: bill1?.groupedByLocations?.map((l) => l.id) ?? null,
    splitTags: bill1?.splitTags ?? null,
    slfCoverages: bill1?.slfCoverages ?? null,
    contactId: bill1?.contact?.id ?? null,
    hasDifferentMailingAddress: bill1?.hasDifferentMailingAddress ?? false,
    locationId: bill1?.location?.id ?? null,
    numberOfEmployees: bill1?.numberOfEmployees ?? null,

    // Not shared
    categorizeEmployees: bill1?.categorizeEmployees ?? null,
    employeeCategorizationType: bill1?.employeeCategorizationType ?? null,
    categorizeByLocationIds:
      bill1?.categoriesByLocation?.map((locs) => locs.map((loc) => loc.id)) ?? null,
    categoriesByTags: bill1?.categoriesByTags ?? null,

    categorizeEmployees_secondary: bill2?.categorizeEmployees ?? null,
    employeeCategorizationType_secondary: bill2?.employeeCategorizationType ?? null,
    categorizeByLocationIds_secondary:
      bill2?.categoriesByLocation?.map((locs) => locs.map((loc) => loc.id)) ?? null,
    categoriesByTags_secondary: bill2?.categoriesByTags ?? null,
  };

  return billGroup;
}

const getBillTimingsToCoverages = memoizeOne(function _getBillTimingsToCoverages(
  slfCoverages: SlfCoverageLongName[] | null,
  advanceOrArrears: AdvanceOrArrearsSelection,
) {
  if (slfCoverages == null) {
    return new Map<BillTiming, SlfCoverageLongName[]>();
  }

  const billTimingsToCoverages = slfCoverages.reduce<Map<BillTiming, SlfCoverageLongName[]>>(
    (map, coverage) => {
      const key = getIsAdvanceCoverage(coverage)
        ? "Advance"
        : getIsArrearsCoverage(coverage)
        ? "Arrears"
        : getIsAdvanceOrArrearsCoverage(coverage)
        ? advanceOrArrears[coverage] || null
        : null;
      if (key == null) return map;
      const item = (map.get(key) || []).concat(coverage);
      item.sort();
      map.set(key, item);
      return map;
    },
    new Map(),
  );

  return billTimingsToCoverages;
});

const getBillTimingForSingleTiming = (
  advanceCoveragesAll: SlfCoverageLongName[],
  arrearsCoveragesAll: SlfCoverageLongName[],
  billSplitType: BillSplitType | null,
  billGroup: BillGroup,
  billTimingsToCoverages: Map<BillTiming, SlfCoverageLongName[]>,
): BillTiming | null => {
  if (billSplitType === "NONE" || billSplitType === "LOCATION" || billSplitType === "TAGS") {
    return advanceCoveragesAll.length > 0 && arrearsCoveragesAll.length === 0
      ? "Advance"
      : arrearsCoveragesAll.length > 0 && advanceCoveragesAll.length === 0
      ? "Arrears"
      : null;
  }

  if (billSplitType === "BENEFIT") {
    const allCoveragesAreAdvance = billGroup.slfCoverages?.every((cov) =>
      billTimingsToCoverages.get("Advance")?.includes(cov),
    );
    if (allCoveragesAreAdvance) {
      return "Advance" as const;
    }
    const allCoveragesAreArrears = billGroup.slfCoverages?.every((cov) =>
      billTimingsToCoverages.get("Arrears")?.includes(cov),
    );
    if (allCoveragesAreArrears) {
      return "Arrears" as const;
    }
    return null;
  }

  // Administration type SELF or TPA, don't assign any bill timing.
  if (billSplitType == null) {
    return null;
  }

  exhaustiveCheck(billSplitType);

  return null;
};

export const getPrefillErrorsForBill = (
  client: Client,
  policy: Policy,
  policyBillingPreferences: PolicyBillingPreferences,
  bill: BillPreview,
  changeSnapshot: DEIFChangeSnapshot,
) => {
  const slfCoverages = policy.slfCoverages;
  const policyId = policy.id;

  const { formValues } = getBillingPreferencesInitialFormValues(
    slfCoverages,
    policyId,
    policyBillingPreferences,
    [bill],
  );
  const viewMode = getEIFSubStepViewMode({ client });
  const prefillErrors = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(formValues, billingPreferencesSchema, { policy, prefill: false })
    : {};
  const prefillErrorsForBill =
    typeof prefillErrors.bills?.[0] === "string" ? {} : prefillErrors.bills?.[0] ?? {};
  return prefillErrorsForBill;
};

type BillIsEmptyType = Pick<
  BillFormValues,
  "billingAdministrationType" | "billingStructureType" | "billSplitType"
> &
  Partial<
    Pick<
      BillFormValues,
      | "billName"
      | "contactId"
      | "hasDifferentMailingAddress"
      | "locationId"
      | "numberOfEmployees"
      | "categorizeEmployees"
      | "categorizeByLocationIds"
      | "categoriesByTags"
      | "groupByLocationIds"
      | "splitTags"
      | "slfCoverages"
    >
  > & {
    categorizeEmployees_secondary?: BillFormValues["categorizeEmployees"];
    categorizeByLocationIds_secondary?: BillFormValues["categorizeByLocationIds"];
    categoriesByTags_secondary?: BillFormValues["categoriesByTags"];
  };

export const getIsBillEmpty = (bill: BillIsEmptyType) => {
  assertIsDefined(bill.billingAdministrationType, "bill.billingAdministrationType");

  const commonKeys = [
    "contactId",
    "hasDifferentMailingAddress",
    "locationId",
    "numberOfEmployees",
  ] as const;

  if (bill.billingAdministrationType === "SELF" || bill.billingAdministrationType === "TPA") {
    assertIsDefined(bill.billingStructureType, "bill.billingStructureType");

    if (bill.billingStructureType === "SINGLE") {
      const areAllKeysEmpty = getArePropertiesEmpty(bill, commonKeys);
      return areAllKeysEmpty;
    } else if (bill.billingStructureType === "MULTIPLE") {
      const keys = [...commonKeys, "billName"] as const;
      const areAllKeysEmpty = getArePropertiesEmpty(bill, keys);
      return areAllKeysEmpty;
    } else {
      exhaustiveCheck(bill.billingStructureType);
    }
  } else if (bill.billingAdministrationType === "LIST") {
    assertIsDefined(bill.billSplitType, "bill.billSplitType");

    const commonKeysListBill = [
      "categorizeEmployees",
      "categorizeByLocationIds",
      "categoriesByTags",
      "categorizeEmployees_secondary",
      "categorizeByLocationIds_secondary",
      "categoriesByTags_secondary",
    ] as const;

    if (bill.billSplitType === "NONE") {
      const keys = [...commonKeys, ...commonKeysListBill] as const;
      const areAllKeysEmpty = getArePropertiesEmpty(bill, keys);
      return areAllKeysEmpty;
    } else if (bill.billSplitType === "LOCATION") {
      const keys = [
        ...commonKeys,
        ...commonKeysListBill,
        "billName",
        "groupByLocationIds",
      ] as const;
      const areAllKeysEmpty = getArePropertiesEmpty(bill, keys);
      return areAllKeysEmpty;
    } else if (bill.billSplitType === "TAGS") {
      const keys = [...commonKeys, ...commonKeysListBill, "billName", "splitTags"] as const;
      const areAllKeysEmpty = getArePropertiesEmpty(bill, keys);
      return areAllKeysEmpty;
    } else if (bill.billSplitType === "BENEFIT") {
      const keys = [...commonKeys, ...commonKeysListBill, "billName", "slfCoverages"] as const;
      const areAllKeysEmpty = getArePropertiesEmpty(bill, keys);
      return areAllKeysEmpty;
    } else {
      exhaustiveCheck(bill.billSplitType);
    }
  } else {
    exhaustiveCheckFail(bill.billingAdministrationType);
  }
};

export const getChangesForBill = (bill: BillPreview, changeSnapshot: DEIFChangeSnapshot) => {
  return new Array<ChangeDetailInfo | ChangeMetadata | undefined>()
    .concat(...Object.values(changeSnapshot.Bill[bill.id ?? ""] ?? {}))
    .concat(...Object.values(changeSnapshot.BillLocation[bill.id ?? ""] ?? {}))
    .filter(getIsChangeDetailInfo);
};

export const getChangesForBills = (bills: BillPreview[], changeSnapshot: DEIFChangeSnapshot) => {
  return bills.flatMap((bill) => getChangesForBill(bill, changeSnapshot));
};

export const getBillHasChanges = (bill: BillPreview, changeSnapshot: DEIFChangeSnapshot) => {
  return getChangesForBill(bill, changeSnapshot).length > 0;
};

export const getBillsHaveChanges = (bills: BillPreview[], changeSnapshot: DEIFChangeSnapshot) => {
  return getChangesForBills(bills, changeSnapshot).length > 0;
};

export const useGetChangesHasDeletedBills = (
  changeSnapshot: DEIFChangeSnapshot,
  client: Client,
  policy: Policy,
) => {
  const { data: deletedBills } = useGetDeletedBills(client.id);

  assertIsDefined(deletedBills, "deletedBills");

  const hasDeletedBills =
    Object.keys(changeSnapshot.Bill ?? {})
      .filter((billId) => {
        const keep = deletedBills.some(
          (deletedBill) => deletedBill.policyId === policy.id && deletedBill.id === billId,
        );
        return keep;
      })
      .map((billId) => {
        const value = changeSnapshot.Bill[billId];
        return value;
      })
      .filter((changeDetailRecord) => changeDetailRecord && "deletedAt" in changeDetailRecord)
      .filter(rejectNullableValues).length > 0;

  return hasDeletedBills;
};
