import { Dropdown } from "antd";
import { ConfirmDeleteUserDialog } from "client/src/domain/User/List/Dialog/ConfirmDeleteUserDialog";
import { useState } from "react";
import { SLF_ADMIN, SLF_OWNER } from "shared/rbac/roles";
import { formatShortDateTimeLocal } from "shared/utils/format";
import { isActivated } from "shared/utils/user";

import { Button } from "../../../components/Button/Button";
import { DropdownButton } from "../../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { ActionMenu } from "../../../components/Menu/ActionMenu";
import { SlobTable } from "../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../components/Table/ActionButtons";
import { Tooltip } from "../../../components/Tooltip/Tooltip";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import {
  useDeleteSlfInternal,
  useResetMFA,
  useResetPassword,
  useSendSlfInternalWelcomeEmail,
} from "../../../hooks/user";

import type { SlobColumnsType, SlobTableProps } from "../../../components/SlobTable/SlobTable";
import type { SlfInternal } from "shared/types/User";

export type SlfInternalsTableProps = Omit<SlobTableProps<SlfInternal>, "columns">;

const getRoleName = (role: string) => {
  switch (role) {
    case SLF_OWNER:
      return "Owner";
    case SLF_ADMIN:
      return "Admin";
    default:
      return role;
  }
};

export type SlfInternalsTableHookProps = {
  isDeleteDialogVisible: boolean;
  setIsDeleteDialogVisible: (value: boolean) => void;
  isResetDialogVisible: boolean;
  setIsResetDialogVisible: (value: boolean) => void;
  isResetMFADialogVisible: boolean;
  setIsResetMFADialogVisible: (value: boolean) => void;
  isWelcomeEmailDialogVisible: boolean;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  activeUser?: SlfInternal;
  setActiveUser: (value?: SlfInternal) => void;
  handleDelete: () => Promise<void>;
  handleReset: () => Promise<void>;
  handleResetMFA: () => Promise<void>;
  handleSendWelcomeEmail: () => Promise<void>;
};

export const useSlfInternalTableProps = (clientId?: string): SlfInternalsTableHookProps => {
  const { mutateAsync: deleteSlfInternal } = useDeleteSlfInternal();
  const { mutateAsync: resetPassword } = useResetPassword();
  const { mutateAsync: resetMFA } = useResetMFA();
  const { mutateAsync: sendWelcomeEmail } = useSendSlfInternalWelcomeEmail();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);
  const [isResetMFADialogVisible, setIsResetMFADialogVisible] = useState(false);
  const [isWelcomeEmailDialogVisible, setIsWelcomeEmailDialogVisible] = useState(false);

  const [activeUser, setActiveUser] = useState<SlfInternal>();

  const handleDelete = async () => {
    if (!activeUser) return;
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progress", 0);
    try {
      await deleteSlfInternal({
        params: { userId: activeUser.id },
      });
      void slobMessage.success("User deleted");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleReset = async () => {
    if (!activeUser) return;
    setIsResetDialogVisible(false);
    const hide = slobMessage.loading("Sending reset email", 0);
    try {
      await resetPassword({
        params: { email: activeUser.email },
      });
      void slobMessage.success("Password email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleResetMFA = async () => {
    if (!activeUser) return;
    setIsResetMFADialogVisible(false);
    const hide = slobMessage.loading("Sending reset MFA", 0);
    try {
      await resetMFA({
        params: { email: activeUser.email },
      });
      void slobMessage.success("MFA has been reset successfully");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleSendWelcomeEmail = async () => {
    if (!activeUser) return;
    setIsWelcomeEmailDialogVisible(false);
    const hide = slobMessage.loading("Sending welcome email", 0);
    try {
      await sendWelcomeEmail({
        params: { userId: activeUser.id },
        query: { clientId },
      });
      void slobMessage.success("Welcome email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  return {
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    setIsResetDialogVisible,
    isResetMFADialogVisible,
    setIsResetMFADialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    activeUser,
    setActiveUser,
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
  };
};

export type SlfInternalsTableActionMenuProps = {
  slfInternal: SlfInternal;
  setActiveUser: (value?: SlfInternal) => void;
  setIsDeleteDialogVisible: (value: boolean) => void;
  setIsResetDialogVisible: (value: boolean) => void;
  setIsResetMFADialogVisible: (value: boolean) => void;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  showModal?: (value: SlfInternal) => void;
};

export const SlfInternalTableActionMenu = ({
  slfInternal,
  setActiveUser,
  setIsDeleteDialogVisible,
  setIsResetDialogVisible,
  setIsResetMFADialogVisible,
  setIsWelcomeEmailDialogVisible,
  showModal,
}: SlfInternalsTableActionMenuProps) => {
  const menu = (
    <ActionMenu
      data-testid="dropdown-menu"
      items={[
        { key: "delete-user", label: "Delete User" },
        { key: "reset-password", label: "Reset Password" },
        { key: "reset-mfa", label: "Reset MFA" },
        {
          key: "send-welcome-email",
          disabled: isActivated(slfInternal),
          label: (
            <Tooltip
              title={
                isActivated(slfInternal) &&
                "This user has activated their account. A welcome email can no longer be sent."
              }
              placement="bottom"
            >
              Send Welcome Email
            </Tooltip>
          ),
        },
      ]}
      onClick={({ key }) => {
        switch (key) {
          case "delete-user":
            setActiveUser(slfInternal);
            setIsDeleteDialogVisible(true);
            break;
          case "reset-password":
            setActiveUser(slfInternal);
            setIsResetDialogVisible(true);
            break;
          case "reset-mfa":
            setActiveUser(slfInternal);
            setIsResetMFADialogVisible(true);
            break;
          case "send-welcome-email":
            setActiveUser(slfInternal);
            setIsWelcomeEmailDialogVisible(true);
            break;
        }
      }}
    />
  );
  return (
    <ActionButtons>
      {showModal && (
        <Button type="tertiary" size="xtra-small" onClick={() => showModal(slfInternal)}>
          Edit
        </Button>
      )}
      <Dropdown dropdownRender={() => menu} placement="bottomLeft">
        <DropdownButton size="xtra-small" />
      </Dropdown>
    </ActionButtons>
  );
};

export const SlfInternalTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  showModal,
  clientId,
  featureToggles,
}: SlfInternalsTableProps) => {
  const {
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    setIsResetDialogVisible,
    isResetMFADialogVisible,
    setIsResetMFADialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    activeUser,
    setActiveUser,
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
  } = useSlfInternalTableProps(clientId);

  return (
    <SlfInternalTableRender
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      showModal={showModal}
      isDeleteDialogVisible={isDeleteDialogVisible}
      setIsDeleteDialogVisible={setIsDeleteDialogVisible}
      isResetDialogVisible={isResetDialogVisible}
      setIsResetDialogVisible={setIsResetDialogVisible}
      isResetMFADialogVisible={isResetMFADialogVisible}
      setIsResetMFADialogVisible={setIsResetMFADialogVisible}
      isWelcomeEmailDialogVisible={isWelcomeEmailDialogVisible}
      setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
      activeUser={activeUser}
      setActiveUser={setActiveUser}
      handleDelete={handleDelete}
      handleReset={handleReset}
      handleResetMFA={handleResetMFA}
      handleSendWelcomeEmail={handleSendWelcomeEmail}
      featureToggles={featureToggles}
    />
  );
};

export const SlfInternalTableRender = ({
  isDeleteDialogVisible,
  setIsDeleteDialogVisible,
  isResetDialogVisible,
  setIsResetDialogVisible,
  isResetMFADialogVisible,
  setIsResetMFADialogVisible,
  isWelcomeEmailDialogVisible,
  setIsWelcomeEmailDialogVisible,
  activeUser,
  setActiveUser,
  handleDelete,
  handleReset,
  handleResetMFA,
  handleSendWelcomeEmail,
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  showModal,
  featureToggles,
}: SlfInternalsTableProps & SlfInternalsTableHookProps) => {
  const columns: SlobColumnsType<SlfInternal> = [
    {
      title: "Name",
      dataIndex: "fullName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: getRoleName,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (lastLogin: Date) => (lastLogin ? formatShortDateTimeLocal(lastLogin) : null),
    },
    {
      render: (slfInternal: SlfInternal) => {
        return (
          <SlfInternalTableActionMenu
            slfInternal={slfInternal}
            setActiveUser={setActiveUser}
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            setIsResetDialogVisible={setIsResetDialogVisible}
            setIsResetMFADialogVisible={setIsResetMFADialogVisible}
            setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
            showModal={showModal}
          />
        );
      },
    },
  ];

  return (
    <>
      <SlobTable
        columns={columns}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={data}
        featureToggles={featureToggles}
      />

      <ConfirmDeleteUserDialog
        activeUser={activeUser}
        isVisible={isDeleteDialogVisible}
        onCancel={() => setIsDeleteDialogVisible(false)}
        onConfirm={() => handleDelete()}
      />

      <ConfirmDialog
        title="Reset password"
        isVisible={isResetDialogVisible}
        onCancel={() => setIsResetDialogVisible(false)}
        onConfirm={() => handleReset()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset password?
      </ConfirmDialog>

      <ConfirmDialog
        title="Reset MFA"
        isVisible={isResetMFADialogVisible}
        onCancel={() => setIsResetMFADialogVisible(false)}
        onConfirm={() => handleResetMFA()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset MFA?
      </ConfirmDialog>

      <ConfirmDialog
        title="Send welcome email"
        isVisible={isWelcomeEmailDialogVisible}
        onCancel={() => setIsWelcomeEmailDialogVisible(false)}
        onConfirm={() => handleSendWelcomeEmail()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to send a welcome email?
      </ConfirmDialog>
    </>
  );
};
