import { getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import { disabilityStateCodes } from "shared/types/Location";
import { assertIsDefined } from "shared/utils/utils";
import { taxIdSchema } from "shared/validation/client";
import { locationInputValidation } from "shared/validation/location";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import type { BenefitTypeEIF } from "@prisma/client";
import type { DisabilityStateCode } from "shared/types/Location";

export const subsidiaryLocationInputValidation = locationInputValidation.pick([
  "name",
  "address1",
  "address2",
  "city",
  "state",
  "zipCode",
  "locationType",
]);

export const subsidiaryValidationSchema = Yup.object({
  companyName: Yup.string()
    .nullable()
    .defined()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Please provide a company name"),
      otherwise: (schema) => schema,
    }),
  taxId: taxIdSchema
    .nullable()
    .defined()
    .when(["$prefill", "$benefitTypes"], {
      is: (prefill: boolean | undefined, benefitTypes: BenefitTypeEIF[] | undefined) => {
        assertIsDefined(benefitTypes, "benefitTypes");

        const hasStatutoryBenefits = benefitTypes.some((benefitType) =>
          getIsStatutoryBenefitType(benefitType),
        );

        return !!prefill || !hasStatutoryBenefits;
      },
      then: (schema) => schema.transform((value: string | undefined) => (value ? value : null)),
      otherwise: (schema) => schema.test(nullNotAllowedTestConfig()),
    }),
  employeeStates: Yup.array()
    .of(Yup.mixed<DisabilityStateCode>().oneOf(disabilityStateCodes.slice()).required())
    .nullable()
    .defined()
    .when(["$prefill", "$benefitTypes"], {
      is: (prefill: boolean | undefined, benefitTypes: BenefitTypeEIF[] | undefined) => {
        assertIsDefined(benefitTypes, "benefitTypes");
        const hasStatutoryBenefits = benefitTypes.some((benefitType) =>
          getIsStatutoryBenefitType(benefitType),
        );
        return !prefill && hasStatutoryBenefits;
      },
      then: (schema) =>
        schema
          .min(1, "Please select at least one state")
          .required("Please select at least one state"),
    }),
  location: subsidiaryLocationInputValidation.nullable().when(["$prefill", "$benefitTypes"], {
    is: (prefill: boolean | undefined, benefitTypes: BenefitTypeEIF[] | undefined) => {
      assertIsDefined(benefitTypes, "benefitTypes");
      return !prefill && benefitTypes.includes("DENTAL_ASO");
    },
    then: (schema) => schema.required("Please enter address information"),
  }),
});
