import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";

import { StackY } from "client/src/components/Spacing/Spacing";
import { LocationStateData } from "../../../../../../shared/data/LocationState";
import { LocationStateCodes } from "../../../../../../shared/types/Location";
import { explorerPagePrepaidLocationValidation } from "../../../../../../shared/validation/explorerPage";
import { Badge } from "../../../../components/Badge/Badge";
import { Button } from "../../../../components/Button/Button";
import { SlobSelect } from "../../../../components/Form/SlobSelect";
import { Modal } from "../../../../components/Modal/Modal";
import { Body1, Body3 } from "../../../../components/Typography/Typography";
import { useSlobFormik } from "../../../../hooks/useSlobFormik";
import { useTranslation } from "../../../../i18n";
import { hasFormikError } from "../../../../utils/hasFormikError";
import * as styles from "../../ExplorerPageForm/explorerPageForm.module.less";

import type { Pretty } from "../../../../../../shared/types/Helper";
import type { LocationStateCode } from "../../../../../../shared/types/Location";
import type { InferType } from "yup";

type ExplorerLocationModalProps = {
  visible: boolean;
  initialLocation: LocationStateCode | null;
  dhmoPlanFavorited: boolean;
  onSave: (benefit: Pretty<InferType<typeof explorerPagePrepaidLocationValidation>>) => void;
  onCancel?: () => void;
};

export type ExplorerLocationModalCopyStructure = {
  header: string;
  description: string;
  editHeader: string;
  editDescription: string;
  saveButton: string;
  cancelButton: string;
  message: string;
};

export const ExplorerLocationModal = ({
  visible,
  initialLocation,
  dhmoPlanFavorited,
  onSave,
  onCancel,
}: ExplorerLocationModalProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerLocationModal" });
  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      prepaidLocation: initialLocation ?? "",
    },
    validationSchema: explorerPagePrepaidLocationValidation,
    onSubmit: (values) => {
      onSave(values);
      formik.resetForm();
    },
  });
  return (
    <Modal
      open={visible}
      footer={null}
      title={initialLocation ? t("editHeader") : t("header")}
      showCloseIcon={!!initialLocation}
      onCancel={onCancel}
    >
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={24}>
            <StackY dist={24}>
              <Body1>{initialLocation ? t("editDescription") : t("description")}</Body1>
              <SlobSelect
                name="prepaidLocation"
                showRequired={true}
                value={formik.values.prepaidLocation || null}
                options={LocationStateCodes.map((stateCode) => ({
                  label: LocationStateData[stateCode].displayName,
                  value: stateCode,
                }))}
                placeholder="State"
                onChange={async (val) => {
                  await formik.setFieldValue("prepaidLocation", val.value);
                }}
                error={hasFormikError(formik, "prepaidLocation") ? "State is required" : undefined}
                touched={true}
              />
              {initialLocation && dhmoPlanFavorited && (
                <Row gutter={[24, 12]}>
                  <Col>
                    <Badge srOnlyLabel="Warning" variant="warning" style={{ padding: "8px" }}>
                      <FontAwesomeIcon icon={faInfoCircle} className={styles.locationModalIcon} />
                      <Body3>{t("message")}</Body3>
                    </Badge>
                  </Col>
                </Row>
              )}
              <Row gutter={[24, 12]}>
                <Col>
                  <Button type="primary" size="middle" htmlType="submit">
                    {t("saveButton")}
                  </Button>
                </Col>
                {initialLocation && (
                  <Col>
                    <Button type="tertiary" size="middle" onClick={() => onCancel?.()}>
                      {t("cancelButton")}
                    </Button>
                  </Col>
                )}
              </Row>
            </StackY>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};
