import { useState, useEffect, useRef } from "react";

export const useFocusControl = () => {
  const [shouldHide, setShouldHide] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- no need for target check
      const target = e.target as HTMLElement;
      if (wrapperRef.current && !wrapperRef.current.contains(target)) {
        return setShouldHide(true);
      }
      return setShouldHide(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return { shouldHide, setShouldHide, wrapperRef };
};
