import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Tooltip } from "../../../components/Tooltip/Tooltip";

import { useGetServiceStatus } from "../../../hooks/serviceCheck";
import { AddOrUpdateClientForm } from "../Form/AddOrUpdateClientForm";

export type ClientCreatePageProps = {
  clearFilter: () => void;
};

export const ClientCreate = ({ clearFilter }: ClientCreatePageProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: serviceStatus } = useGetServiceStatus();
  const isDisabled = serviceStatus?.jira === false;

  return (
    <>
      <Modal
        destroyOnClose={true}
        open={isModalVisible}
        footer={null}
        centered={true}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <AddOrUpdateClientForm
          clearFilter={clearFilter}
          actionType="create"
          onClientAction={() => setIsModalVisible(true)}
        />
      </Modal>
      <Tooltip title={isDisabled ? "This functionality is unavailable at this time" : null}>
        <Button
          type="primary"
          size="small"
          disabled={isDisabled}
          onClick={() => {
            setIsModalVisible(true);
          }}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          Add Client
        </Button>
      </Tooltip>
    </>
  );
};
