import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { formatLocation } from "../../../../shared/utils/location";
import { Checkbox } from "../../components/Form/Checkbox";
import { Body2 } from "../../components/Typography/Typography";
import type { Location, LocationId } from "../../../../shared/types/Location";
import type { SlobSelectMultipleProps } from "../../components/MultiSelect/SlobSelectMultiple";
import type { LabeledValue } from "antd/lib/select";

export const getLocationMultiSelectOptionsAndValue = ({
  unassignedLocations,
  selectedLocationIds,
  onEditLocation,
  onAddLocation,
  readOnlyLocationId,
}: {
  locations: Location[];
  unassignedLocations: Location[];
  selectedLocationIds: string[] | undefined;
  onEditLocation: (location: Location) => void;
  onAddLocation?: () => void;
  readOnlyLocationId?: LocationId;
}): Pick<SlobSelectMultipleProps<LabeledValue>, "options" | "value" | "dropdownRender"> => ({
  options: unassignedLocations.map((loc) => ({
    "aria-label": formatLocation(loc),
    label: (
      <Row className="px-4">
        <Col flex={1} style={{ pointerEvents: "none" }}>
          <Checkbox
            checked={selectedLocationIds?.includes(loc.id) ?? false}
            onChange={() => null}
            label={formatLocation(loc)}
            name={formatLocation(loc)}
          />
        </Col>
        <Col>
          {(readOnlyLocationId ? loc.id !== readOnlyLocationId : true) && (
            <Button
              type="text-only"
              onClick={(e) => {
                e.stopPropagation();
                onEditLocation(loc);
              }}
            >
              Edit
            </Button>
          )}
        </Col>
      </Row>
    ),
    value: loc.id,
  })),
  value:
    selectedLocationIds?.map((id) => {
      const location = unassignedLocations.find((l) => l.id === id);
      return {
        value: id,
        label: location?.name,
      };
    }) ?? [],
  dropdownRender: (menu) => (
    <>
      {menu}
      {onAddLocation && (
        <Button type="text" size="middle" onClick={() => onAddLocation()}>
          <FontAwesomeIcon icon={faPlusCircle} /> Create another location
        </Button>
      )}
    </>
  ),
});

export const getLocationMultiSelectOptionsAndValueForCategorization = (
  ...params: Parameters<typeof getLocationMultiSelectOptionsAndValue>
): ReturnType<typeof getLocationMultiSelectOptionsAndValue> => {
  const [{ locations, unassignedLocations, onAddLocation }] = params;
  const defaultVals = getLocationMultiSelectOptionsAndValue(...params);
  return {
    ...defaultVals,
    dropdownRender: (menu) => (
      <>
        {!(onAddLocation && !unassignedLocations.length) && menu}
        {onAddLocation && (
          <Row
            justify={unassignedLocations.length ? "start" : "center"}
            className={clsx(
              !unassignedLocations.length && "mt-32",
              !unassignedLocations.length && "mb-24",
            )}
          >
            {(locations.length === 0 || unassignedLocations.length === 0) && (
              <Col span={24} style={{ textAlign: "center" }}>
                <Body2>
                  {locations.length === 0
                    ? "You have not created any locations yet."
                    : "All locations are already assigned to groups."}
                </Body2>
              </Col>
            )}

            <Col>
              <Button
                type="text"
                size="middle"
                onClick={() => onAddLocation()}
                icon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                {locations.length === 0 ? "Create a location" : "Create another location"}
              </Button>
            </Col>
          </Row>
        )}
      </>
    ),
  };
};
