import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";

import { isSunlifeCarrier } from "../../../../../../shared/types/ExplorerPageBenefit";
import { ReactComponent as HelpfulHintIcon } from "../../../../components/Icons/HelpfulHintIcon.svg";
import { H1, H3 } from "../../../../components/Typography/Typography";
import { useTranslation } from "../../../../i18n";
import { copyKey, copyKeySwitch } from "../../utils";

import * as styles from "./benefit.module.less";

import type { CopySwitch } from "../../utils";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  HelpfulHint: "benefit-helpful-hint",
};

export type ExplorerBenefitHelpfulHintCopyStrucutre = {
  [benefit in Exclude<BenefitTypeBenEx, "FINANCIAL" | "OTHER">]: CopySwitch<
    PlanType | "MULTI_CHOICE",
    CopySwitch<
      "Sun Life",
      {
        tip: string;
        description: string;
      }
    >
  >;
};

type ExplorerBenefitHelpfulHintProps = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
  alternateName: string;
};

export const ExplorerBenefitHelpfulHint = ({
  benefitType,
  alternateName,
  plans,
}: ExplorerBenefitHelpfulHintProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitHelpfulHint" });
  const screens = useBreakpoint();

  const planType = plans.reduce<PlanType | "MULTI_CHOICE" | null>((acc, val) => {
    if (acc) return "MULTI_CHOICE";
    return val.planType;
  }, null);

  const planTypeKey = copyKeySwitch(t, benefitType, planType);

  const hasSunLifePlans = plans.find((p) => isSunlifeCarrier(p.carrierId));

  const carrierKey = copyKeySwitch(
    t,
    benefitType,
    planTypeKey,
    hasSunLifePlans ? "Sun Life" : null,
  );

  if (benefitType === "FINANCIAL" || benefitType === "OTHER") return null;

  return (
    <div className={styles.wellness} data-testid={DataTestId.HelpfulHint}>
      <div className={styles.imageContainer}>
        <HelpfulHintIcon className={styles.icon} />
      </div>
      <Row
        justify="center"
        align="stretch"
        className={screens.sm ? "m-24" : "m-16"}
        style={{ textAlign: "center" }}
      >
        <Col span={24} md={20}>
          {screens.sm ? (
            <H1>
              {t(copyKey(benefitType, planTypeKey, carrierKey, "tip"), {
                alternateName: `${alternateName}`,
              })}
            </H1>
          ) : (
            <H3>
              {t(copyKey(benefitType, planTypeKey, carrierKey, "tip"), {
                alternateName: `${alternateName}`,
              })}
            </H3>
          )}
        </Col>
      </Row>
      <Row
        justify="center"
        align="stretch"
        className={screens.sm ? "m-24" : "m-16"}
        style={{ textAlign: "center" }}
      >
        <Col span={24} md={20}>
          <div>{t(copyKey(benefitType, planTypeKey, carrierKey, "description"))}</div>
        </Col>
      </Row>
    </div>
  );
};
