import { useDebouncedValue } from "client/src/hooks/useDebouncedValue";
import { useQueryString } from "client/src/hooks/useQueryString";
import { getObjectFromURLSearchParams, getParamsForNamespace } from "client/src/utils/url";
import { SortDirections, type SortDirection } from "shared/types/ExplorerPage";
import type { Sort } from "client/src/components/SlobList/SlobList";

export type ListParams = {
  page?: string;
  sortBy?: string;
  sortDirection?: string;
  pageSize?: string;
  search?: string;
};

export const isListParams = (params: Record<string, unknown>): params is ListParams => {
  const props: Record<keyof ListParams, null> = {
    page: null,
    sortBy: null,
    sortDirection: null,
    pageSize: null,
    search: null,
  };
  const isIt = Object.keys(props).every((prop) => params[prop] == null || prop in params);
  return isIt;
};

function isDirection(x: string): x is SortDirection {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- type guard
  return (SortDirections as readonly string[]).indexOf(x) >= 0;
}

const defaultPage = 1;

export function useListParams(args: {
  initialPageSize: number;
  initialSort: Sort;
  listNamespace?: string;
}) {
  const { initialPageSize, initialSort, listNamespace } = args;

  const queryString = useQueryString();
  const { paramsForNamespace, otherParams: notForThisList } = getParamsForNamespace(
    getObjectFromURLSearchParams(queryString),
    listNamespace,
  );
  const listParams = isListParams(paramsForNamespace) ? paramsForNamespace : {};
  const listParamsPage = listParams.page ? parseInt(listParams.page) : defaultPage;
  const listParamsPageSize = listParams.pageSize ? parseInt(listParams.pageSize) : NaN;
  const listParamsDirectionAsString = listParams.sortDirection ?? "";
  const listParamsDirection = isDirection(listParamsDirectionAsString)
    ? listParamsDirectionAsString
    : undefined;

  const search = useDebouncedValue(listParams.search, 200);

  return {
    page: !isNaN(listParamsPage) ? listParamsPage : defaultPage,
    pageSize: !isNaN(listParamsPageSize) ? listParamsPageSize : initialPageSize,
    sort:
      listParams.sortBy && listParamsDirection
        ? { sortBy: listParams.sortBy, direction: listParamsDirection }
        : initialSort,
    searchText: listParams.search,
    search,
    notForThisList,
  };
}
