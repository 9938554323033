import { Anchor } from "client/src/components/Anchor/Anchor";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { FormInput } from "client/src/components/Form/Input";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ThreeYearLookBackModal } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Disability/ThreeYearLookBackModal";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { ResponseError } from "client/src/hooks/query";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { getIsSlfCoverageLongName } from "shared/types/SlfCoverages";
import { assertIsDefined } from "shared/utils/utils";
import { planValidationSchema } from "shared/validation/plan";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { PFMLStates, StatutoryContributionLabels, Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type Props = {
  client: Client;
  sectionPlans: Plan[];
  sectionLabel: PFMLStates | StatutoryContributionLabels;
  updatePlansQuery: UpdatePlansQuery;
  onSave: () => void;
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
  status: EIFStepCompleteStatus;
  trackElementClicked: TrackElementClickedFunc;
  isPFML: boolean;
};

export function EIFNonClassBenefitsContributionsEditable(props: Props) {
  const {
    client,
    sectionPlans,
    sectionLabel,
    updatePlansQuery,
    onSave,
    authUser,
    changeSnapshot,
    status,
    trackElementClicked,
    isPFML,
  } = props;

  const location = useLocation();
  const [visible, toggleVisible] = useToggler();

  assertIsDefined(sectionPlans[0], "sectionPlans[0]");

  const formik = useSlobFormik({
    validateOnChange: false,
    validationSchema: planValidationSchema,
    validationContext: { prefill: true },
    initialValues: {
      pfmlPremiumContributionType: sectionPlans[0].pfmlPremiumContributionType,
      pfmlEmployerContributionPercentage: sectionPlans[0].pfmlEmployerContributionPercentage,
      pfmlEmployerPremiumPayments: sectionPlans[0].pfmlEmployerPremiumPayments,
      pfmlThreeYearLookBackPercent: sectionPlans[0].pfmlThreeYearLookBackPercent,
    },
    onSubmit: async () => {
      const plans: Plan[] = sectionPlans.map((plan) => {
        return {
          ...plan,
          id: plan.id,
          pfmlPremiumContributionType: formik.values.pfmlPremiumContributionType || null,
          pfmlEmployerContributionPercentage:
            formik.values.pfmlEmployerContributionPercentage || null,
          pfmlEmployerPremiumPayments: formik.values.pfmlEmployerPremiumPayments || null,
          pfmlThreeYearLookBackPercent: formik.values.pfmlThreeYearLookBackPercent || null,
        };
      });

      const { isSuccess } = await updatePlansQuery.mutateAsync({
        params: { clientId: client.id },
        data: { plans },
        query: { isPFMLContributionsUpdate: true },
      });

      if (isSuccess) {
        void slobMessage.success("Updated PFML Contributions");
        onSave();
      }
    },
  });

  const haveEverSavedForm = Object.keys(planValidationSchema.fields)
    .filter((field: string): field is keyof typeof planValidationSchema.fields => true)
    .some((field) => field !== null);

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, planValidationSchema, {
        prefill: false,
      })
    : {};

  const label = isPFML ? `${sectionLabel} Paid Family Medical Leave` : sectionLabel;

  const isHIorNY = sectionPlans.some(
    ({ benefitType }) => benefitType === "TDI" || benefitType === "DBL",
  );

  return (
    <>
      <Row justify="start" wrap={false} gutter={0}>
        <Col>
          <H3 as="h2">
            <EIFSectionStatusIconography status={status} />
          </H3>
        </Col>
        <Col flex={1}>
          <H3 as="h2">{label}</H3>
        </Col>
      </Row>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24} wrap={false}>
          <RadioGroup
            name="pfmlPremiumContributionType"
            label={
              <>
                <Body2 as="div">What type of premium contributions will you be making?</Body2>
                {getIsSlfCoverageLongName(sectionLabel) && (
                  <Anchor
                    variant="boldSmall"
                    href="https://p1.aprimocdn.net/sunlife/34218d61-c08c-4070-90c2-afc80117c7fb/gdiwp-4905-disability-taxation-and-reporting-original-file.pdf"
                    target="_blank"
                    onClick={() =>
                      trackElementClicked({
                        moduleState: status,
                        buttonLabel: "Learn more about Disability taxation",
                      })
                    }
                  >
                    Learn more about Disability taxation and reporting
                  </Anchor>
                )}
              </>
            }
            disabled={formik.isSubmitting}
            error={
              formik.errors.pfmlPremiumContributionType || strictErrors.pfmlPremiumContributionType
            }
            touched={
              formik.touched.pfmlPremiumContributionType ||
              !!strictErrors.pfmlPremiumContributionType
            }
            options={[
              {
                value: "FULLY_EMPLOYER_PAID",
                label: (
                  <>
                    <Body3 as="div">100% employer paid contributions</Body3>
                    <Body5>The employer pays the entire premium</Body5>
                  </>
                ),
              },
              {
                value: "GROSS_UP",
                label: (
                  <>
                    <Body3 as="div">Gross up</Body3>
                    <Body5>
                      The employer adds the premium to the employee’s W-2 as taxable income
                    </Body5>
                  </>
                ),
              },
              {
                value: "SHARED",
                label: (
                  <>
                    <Body3 as="div">Shared contributions by employer and employee</Body3>
                    {isHIorNY ? (
                      <Body5>
                        The employer pays part of the premium and the employee pays part of the
                        premium, with the employee contribution not to exceed the maximum amount
                        specified by law
                      </Body5>
                    ) : (
                      <Body5>
                        The employer pays part of the premium and the employee pays part of the
                        premium
                      </Body5>
                    )}
                  </>
                ),
              },
            ]}
            direction="vertical"
            value={formik.values.pfmlPremiumContributionType}
            onChange={formik.handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={[
              changeSnapshot.Plan[sectionPlans[0].id]?.pfmlPremiumContributionType ?? null,
            ]}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "pfmlPremiumContributionType",
              client,
              formik,
            })}
          />

          {formik.values.pfmlPremiumContributionType === "SHARED" && (
            <>
              <div>
                <Body3 as="p">How much are you contributing?</Body3>

                <div style={{ maxWidth: 300 }}>
                  <FormInput
                    name="pfmlEmployerContributionPercentage"
                    suffix="%"
                    maxLength={6}
                    label="Employer contribution percent"
                    touched={
                      formik.touched.pfmlEmployerContributionPercentage ||
                      !!strictErrors.pfmlEmployerContributionPercentage
                    }
                    error={
                      formik.errors.pfmlEmployerContributionPercentage ||
                      strictErrors.pfmlEmployerContributionPercentage
                    }
                    value={formik.values.pfmlEmployerContributionPercentage}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                  />
                </div>
              </div>

              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Plan[sectionPlans[0].id]?.pfmlEmployerContributionPercentage ??
                    null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "pfmlEmployerContributionPercentage",
                  variant: "number",
                  client,
                  formik,
                })}
              />

              <RadioGroup
                name="pfmlEmployerPremiumPayments"
                label="Are your employees’ premium payments made pre-tax or post-tax?"
                disabled={formik.isSubmitting}
                error={
                  formik.errors.pfmlEmployerPremiumPayments ||
                  strictErrors.pfmlEmployerPremiumPayments
                }
                touched={
                  formik.touched.pfmlEmployerPremiumPayments ||
                  !!strictErrors.pfmlEmployerPremiumPayments
                }
                options={[
                  {
                    value: "PRE_TAX",
                    label: "Pre-tax",
                  },
                  {
                    value: "POST_TAX",
                    label: (
                      <>
                        <Body3 as="div">Post-tax</Body3>
                        <Body5>
                          If you choose post-tax, you will need to provide us with a Three-Year
                          Look-Back percentage.
                        </Body5>
                        <Body5>
                          <Button type="text-only" onClick={toggleVisible}>
                            How do I calculate this?
                          </Button>
                        </Body5>
                      </>
                    ),
                  },
                ]}
                direction="vertical"
                value={formik.values.pfmlEmployerPremiumPayments}
                onChange={formik.handleChange}
              />

              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Plan[sectionPlans[0].id]?.pfmlEmployerPremiumPayments ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "pfmlEmployerPremiumPayments",
                  client,
                  formik,
                })}
              />

              {formik.values.pfmlEmployerPremiumPayments === "POST_TAX" && (
                <>
                  <div>
                    <Body2 as="div">
                      What is the Three-Year Look-Back percentage? <br />
                    </Body2>
                    <Body3 as="p">
                      If your contributions over the look-back period have been the same, then the
                      Three-Year Look-Back percentage will be the same as your contribution
                      percentage.
                      <Button type="text-only" onClick={toggleVisible}>
                        How do I calculate this?
                      </Button>
                    </Body3>

                    <div style={{ maxWidth: 300 }}>
                      <FormInput
                        name="pfmlThreeYearLookBackPercent"
                        prefix={<span />}
                        suffix="%"
                        maxLength={6}
                        label="Three-Year Look-Back percent"
                        touched={
                          formik.touched.pfmlThreeYearLookBackPercent ||
                          !!strictErrors.pfmlThreeYearLookBackPercent
                        }
                        error={
                          formik.errors.pfmlThreeYearLookBackPercent ||
                          strictErrors.pfmlThreeYearLookBackPercent
                        }
                        value={formik.values.pfmlThreeYearLookBackPercent}
                        onChange={formik.handleChange}
                        disabled={formik.isSubmitting}
                      />
                    </div>
                  </div>
                </>
              )}
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Plan[sectionPlans[0].id]?.pfmlThreeYearLookBackPercent ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "pfmlThreeYearLookBackPercent",
                  variant: "number",
                  client,
                  formik,
                })}
              />
            </>
          )}

          {updatePlansQuery.isError && (
            <div aria-live="assertive" className="mt-24 hide:empty">
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(updatePlansQuery.error, GenericErrorCopy2)}
              </ErrorMessage>
            </div>
          )}

          <Row justify="end" align="middle" gutter={28}>
            <Col>
              <Button
                type="text-only"
                size="middle"
                to={location.pathname}
                disabled={formik.isSubmitting}
                onClick={() => flushSync(formik.resetForm)}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="secondary"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || formik.values.pfmlPremiumContributionType === null}
                size="middle"
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />

      <ThreeYearLookBackModal open={visible} onCancel={toggleVisible} />
    </>
  );
}
