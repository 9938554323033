import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table } from "antd";
import clsx from "clsx";

import { StackX } from "../Spacing/Spacing";

import * as styles from "./resourcesTable.module.less";

import type { TableProps } from "antd";
import type { ColumnTitleProps } from "antd/lib/table/interface";

type ResourcesTableProps<RecordType> = Omit<TableProps<RecordType>, "className"> & {
  spacing?: "tight" | "wide";
  border?: "light" | "dark";
};

export function ResourcesTable<RecordType extends Record<string, unknown>>(
  props: ResourcesTableProps<RecordType>,
) {
  const { spacing = "tight", border = "light", ...rest } = props;
  const className = clsx(
    styles.resourcesTable,
    spacing === "wide" && styles.wide,
    border === "dark" && styles.darkBorder,
  );
  return <Table className={className} {...rest} />;
}

export function getColumnTitleWithSortingIcons<RecordType>(key: string, node: React.ReactNode) {
  return ({ sortOrder, sortColumn }: ColumnTitleProps<RecordType>) => {
    const isCurrentColumn = sortColumn?.key === key;
    return (
      <StackX dist={8}>
        <span>{node}</span>
        {isCurrentColumn && sortOrder === "ascend" && <FontAwesomeIcon icon={faArrowDown} />}
        {isCurrentColumn && sortOrder === "descend" && <FontAwesomeIcon icon={faArrowUp} />}
      </StackX>
    );
  };
}
