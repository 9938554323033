import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { pfmlEmployerPremiumPaymentsValuesRecord } from "shared/types/Plan";
import { getNonAdviceOnlyClientPlans } from "shared/utils/EIF/nonClassBenefitsPreferences";
import { assertIsDefined } from "shared/utils/utils";
import { planValidationSchema } from "shared/validation/plan";
import type { Plan } from "shared/types/Plan";

type Props = {
  plans: Plan[];
  suppressErrorsPostSigning: boolean;
};

export function EIFNonClassBenefitsContributionsCompletedItem(props: Props) {
  const { plans, suppressErrorsPostSigning } = props;
  // using the first element in this array because when there are multiple non-Advice Only
  // PFML coverages for a single state, then the data in those Plan rows will be the same
  // if there is an Advice Only and non-Advice Only plan for the same client we only care
  // about the contribution data for the non-Advice Only one.
  // This logic also works for Statutory benefit which this component is now being re-used for
  // as the statutory benefits will always be "non advice only"
  const nonAdviceOnlyClientPlans = getNonAdviceOnlyClientPlans(plans);
  const relevantPlan = nonAdviceOnlyClientPlans[0];
  assertIsDefined(relevantPlan, "relevantPlan");

  const errors = !suppressErrorsPostSigning
    ? getFormikErrors(relevantPlan, planValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <StackY dist={4} wrap={false}>
      {relevantPlan.pfmlPremiumContributionType === "FULLY_EMPLOYER_PAID" && (
        <Body3 as="div">The employer is paying 100% of the premium</Body3>
      )}
      {relevantPlan.pfmlPremiumContributionType === "GROSS_UP" && (
        <Body3 as="div">
          Gross up: The employer adds the premium to the employee’s W-2 as taxable income
        </Body3>
      )}
      {relevantPlan.pfmlPremiumContributionType === "SHARED" && (
        <div>
          <Body3 as="p">
            The employer pays part of the premium and the employee pays part of the premium
          </Body3>

          <div>
            <Body3>Contribution amount: </Body3>

            {relevantPlan.pfmlEmployerContributionPercentage && (
              <Body3>{`${relevantPlan.pfmlEmployerContributionPercentage}%`}</Body3>
            )}

            {errors.pfmlEmployerContributionPercentage && (
              <Body3 redMedium>{errors.pfmlEmployerContributionPercentage}</Body3>
            )}

            {!relevantPlan.pfmlEmployerContributionPercentage &&
              !errors.pfmlEmployerContributionPercentage && <Body3>-</Body3>}
          </div>

          <div>
            <Body3>Employer Premium Payment: </Body3>

            {relevantPlan.pfmlEmployerPremiumPayments && (
              <Body3>
                {pfmlEmployerPremiumPaymentsValuesRecord[relevantPlan.pfmlEmployerPremiumPayments]}
              </Body3>
            )}

            {errors.pfmlEmployerPremiumPayments && (
              <Body3 redMedium>{errors.pfmlEmployerPremiumPayments}</Body3>
            )}

            {!relevantPlan.pfmlEmployerPremiumPayments && !errors.pfmlEmployerPremiumPayments && (
              <Body3>-</Body3>
            )}
          </div>

          {relevantPlan.pfmlPremiumContributionType === "SHARED" &&
            relevantPlan.pfmlEmployerPremiumPayments === "POST_TAX" && (
              <div>
                <Body3>Three-Year Look-Back percentage: </Body3>

                {relevantPlan.pfmlThreeYearLookBackPercent && (
                  <Body3>{`${relevantPlan.pfmlThreeYearLookBackPercent}%`}</Body3>
                )}

                {errors.pfmlThreeYearLookBackPercent && (
                  <Body3 redMedium>{errors.pfmlThreeYearLookBackPercent}</Body3>
                )}

                {!relevantPlan.pfmlThreeYearLookBackPercent &&
                  !errors.pfmlThreeYearLookBackPercent && <Body3>-</Body3>}
              </div>
            )}
        </div>
      )}
    </StackY>
  );
}
