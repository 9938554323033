import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInputNumber } from "client/src/components/Form/InputNumber";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Col, Row } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2 } from "client/src/components/Typography/Typography";
import {
  fieldErrorMsg,
  type ExplorerBenefitModalPresentationProps,
} from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/ExplorerBenefitModalPresentation";
import { medicalOutOfNetworkBenefits } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/MedicalOutOfNetworkBenefits";
import { medicalRequiresPcp } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/MedicalRequiresPcp";
import { medicalSpecialtyCareReferral } from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/MedicalSpecialtyCareReferral";
import { formatCurrency } from "client/src/utils/formatters";
import { hasFormikError } from "client/src/utils/hasFormikError";
import { FORM_FIELDS_DOLLAR_MAX } from "./FormFields";

type Props = Pick<ExplorerBenefitModalPresentationProps, "formik">;

export const MedicalModalFormFields = ({ formik }: Props) => {
  return (
    <StackY dist={32}>
      <StackY dist={16}>
        <Body2>Plan year deductible</Body2>
        <Checkbox
          label="Out-of-network not applicable"
          name="planYearDeductibleOutOfNetworkNA"
          checked={formik.values.planYearDeductibleOutOfNetworkNA}
          error={
            hasFormikError(formik, "planYearDeductibleOutOfNetworkNA")
              ? fieldErrorMsg("plan year deductible out of network not applicable")
              : undefined
          }
          onChange={async (e) => {
            await formik.setFieldValue("planYearDeductibleOutOfNetworkNA", e.target.checked);
          }}
        />
        <div style={{ marginBottom: "20px" }} />
        <Row gutter={[16, 16]}>
          <Col span={!formik.values.planYearDeductibleOutOfNetworkNA ? 12 : 24}>
            <FormInputNumber
              label="Individual (In-Network)"
              value={formik.values.planYearDeductibleIndividualInNetwork ?? 0}
              maxLength={8}
              max={FORM_FIELDS_DOLLAR_MAX}
              name="planYearDeductibleIndividualInNetwork"
              touched={formik.touched.planYearDeductibleIndividualInNetwork}
              onChange={async (val) =>
                await formik.setFieldValue("planYearDeductibleIndividualInNetwork", val ?? 0)
              }
              error={
                hasFormikError(formik, "planYearDeductibleIndividualInNetwork")
                  ? fieldErrorMsg("plan year deductible individual in network")
                  : undefined
              }
              disabled={formik.isSubmitting}
              formatter={formatCurrency}
              parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
              step={100}
              min={0}
              showRequired
            />
          </Col>
          {!formik.values.planYearDeductibleOutOfNetworkNA && (
            <Col span={12}>
              <FormInputNumber
                label="Individual (Out-of-Network)"
                value={formik.values.planYearDeductibleIndividualOutOfNetwork ?? 0}
                maxLength={8}
                max={FORM_FIELDS_DOLLAR_MAX}
                name="planYearDeductibleIndividualOutOfNetwork"
                touched={formik.touched.planYearDeductibleIndividualOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("planYearDeductibleIndividualOutOfNetwork", val ?? 0)
                }
                error={
                  hasFormikError(formik, "planYearDeductibleIndividualOutOfNetwork")
                    ? fieldErrorMsg("plan year deductible individual out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={formatCurrency}
                parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                step={100}
                min={0}
                showRequired
              />
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={!formik.values.planYearDeductibleOutOfNetworkNA ? 12 : 24}>
            <FormInputNumber
              label="Family (In-Network)"
              value={formik.values.planYearDeductibleFamilyInNetwork ?? 0}
              maxLength={8}
              max={FORM_FIELDS_DOLLAR_MAX}
              name="planYearDeductibleFamilyInNetwork"
              touched={formik.touched.planYearDeductibleFamilyInNetwork}
              onChange={async (val) =>
                await formik.setFieldValue("planYearDeductibleFamilyInNetwork", val ?? 0)
              }
              error={
                hasFormikError(formik, "planYearDeductibleFamilyInNetwork")
                  ? fieldErrorMsg("plan year deductible family in network")
                  : undefined
              }
              disabled={formik.isSubmitting}
              formatter={formatCurrency}
              parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
              step={100}
              min={0}
              showRequired
            />
          </Col>
          {!formik.values.planYearDeductibleOutOfNetworkNA && (
            <Col span={12}>
              <FormInputNumber
                label="Family (Out-of-Network)"
                value={formik.values.planYearDeductibleFamilyOutOfNetwork ?? 0}
                maxLength={8}
                max={FORM_FIELDS_DOLLAR_MAX}
                name="planYearDeductibleFamilyOutOfNetwork"
                touched={formik.touched.planYearDeductibleFamilyOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("planYearDeductibleFamilyOutOfNetwork", val ?? 0)
                }
                error={
                  hasFormikError(formik, "planYearDeductibleFamilyOutOfNetwork")
                    ? fieldErrorMsg("plan year deductible family out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={formatCurrency}
                parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                step={100}
                min={0}
                showRequired
              />
            </Col>
          )}
        </Row>
      </StackY>
      <StackY dist={16}>
        <Body2>Out-of-pocket maximum</Body2>
        <Checkbox
          label="Out-of-network not applicable"
          name="planYearMaximumOutOfNetworkNA"
          checked={formik.values.planYearMaximumOutOfNetworkNA}
          error={
            hasFormikError(formik, "planYearMaximumOutOfNetworkNA")
              ? fieldErrorMsg("out of pocket maximum out of network not applicable")
              : undefined
          }
          onChange={async (e) => {
            await formik.setFieldValue("planYearMaximumOutOfNetworkNA", e.target.checked);
          }}
        />
        <div style={{ marginBottom: "20px" }} />
        <Row gutter={[16, 16]}>
          <Col span={!formik.values.planYearMaximumOutOfNetworkNA ? 12 : 24}>
            <FormInputNumber
              label="Individual (In-Network)"
              value={formik.values.planYearMaximumIndividualInNetwork ?? 0}
              maxLength={8}
              max={FORM_FIELDS_DOLLAR_MAX}
              name="planYearMaximumIndividualInNetwork"
              touched={formik.touched.planYearMaximumIndividualInNetwork}
              onChange={async (val) =>
                await formik.setFieldValue("planYearMaximumIndividualInNetwork", val ?? 0)
              }
              error={
                hasFormikError(formik, "planYearMaximumIndividualInNetwork")
                  ? fieldErrorMsg("plan year maximum individual in network")
                  : undefined
              }
              disabled={formik.isSubmitting}
              formatter={formatCurrency}
              parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
              step={100}
              min={0}
              showRequired
            />
          </Col>
          {!formik.values.planYearMaximumOutOfNetworkNA && (
            <Col span={12}>
              <FormInputNumber
                label="Individual (Out-of-Network)"
                value={formik.values.planYearMaximumIndividualOutOfNetwork ?? 0}
                maxLength={8}
                max={FORM_FIELDS_DOLLAR_MAX}
                name="planYearMaximumIndividualOutOfNetwork"
                touched={formik.touched.planYearMaximumIndividualOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("planYearMaximumIndividualOutOfNetwork", val ?? 0)
                }
                error={
                  hasFormikError(formik, "planYearMaximumIndividualOutOfNetwork")
                    ? fieldErrorMsg("plan year maximum individual out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={formatCurrency}
                parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                step={100}
                min={0}
                showRequired
              />
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={!formik.values.planYearMaximumOutOfNetworkNA ? 12 : 24}>
            <FormInputNumber
              label="Family (In-Network)"
              value={formik.values.planYearMaximumFamilyInNetwork ?? 0}
              maxLength={8}
              max={FORM_FIELDS_DOLLAR_MAX}
              name="planYearMaximumFamilyInNetwork"
              touched={formik.touched.planYearMaximumFamilyInNetwork}
              onChange={async (val) =>
                await formik.setFieldValue("planYearMaximumFamilyInNetwork", val ?? 0)
              }
              error={
                hasFormikError(formik, "planYearMaximumFamilyInNetwork")
                  ? fieldErrorMsg("plan year maximum family in network")
                  : undefined
              }
              disabled={formik.isSubmitting}
              formatter={formatCurrency}
              parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
              step={100}
              min={0}
              showRequired
            />
          </Col>
          {!formik.values.planYearMaximumOutOfNetworkNA && (
            <Col span={12}>
              <FormInputNumber
                label="Family (Out-of-Network)"
                value={formik.values.planYearMaximumFamilyOutOfNetwork ?? 0}
                maxLength={8}
                max={FORM_FIELDS_DOLLAR_MAX}
                name="planYearMaximumFamilyOutOfNetwork"
                touched={formik.touched.planYearMaximumFamilyOutOfNetwork}
                onChange={async (val) =>
                  await formik.setFieldValue("planYearMaximumFamilyOutOfNetwork", val ?? 0)
                }
                error={
                  hasFormikError(formik, "planYearMaximumFamilyOutOfNetwork")
                    ? fieldErrorMsg("plan year maximum family out of network")
                    : undefined
                }
                disabled={formik.isSubmitting}
                formatter={formatCurrency}
                parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
                step={100}
                min={0}
                showRequired
              />
            </Col>
          )}
        </Row>
      </StackY>

      <StackY dist={32}>
        <RadioGroup
          name="medicalOutOfNetworkBenefits"
          label="Out-of-Network Benefits"
          direction="vertical"
          value={formik.values.medicalOutOfNetworkBenefits}
          error={
            hasFormikError(formik, "medicalOutOfNetworkBenefits")
              ? fieldErrorMsg("out of network benefits option", true)
              : undefined
          }
          touched={formik.touched.medicalOutOfNetworkBenefits}
          disabled={formik.isSubmitting}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- these are constants
          options={[medicalOutOfNetworkBenefits.yes!, medicalOutOfNetworkBenefits.no!]}
          onChange={formik.handleChange}
        />
        <RadioGroup
          name="medicalPcpCoordinationRequired"
          label="Requires choosing a Primary Care Provider (PCP) to coordinate your care"
          value={formik.values.medicalPcpCoordinationRequired}
          error={
            hasFormikError(formik, "medicalPcpCoordinationRequired")
              ? fieldErrorMsg("PCP coordination option", true)
              : undefined
          }
          touched={formik.touched.medicalPcpCoordinationRequired}
          disabled={formik.isSubmitting}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- these are constants
          options={[medicalRequiresPcp.yes!, medicalRequiresPcp.no!]}
          onChange={formik.handleChange}
        />
        <RadioGroup
          name="medicalPcpSpecialtyCareReferralRequired"
          label="Specialty care requires a referral from your Primary Care Provider (PCP)"
          value={formik.values.medicalPcpSpecialtyCareReferralRequired}
          error={
            hasFormikError(formik, "medicalPcpSpecialtyCareReferralRequired")
              ? fieldErrorMsg("PCP specialty care referral option", true)
              : undefined
          }
          touched={formik.touched.medicalPcpSpecialtyCareReferralRequired}
          disabled={formik.isSubmitting}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- these are constants
          options={[medicalSpecialtyCareReferral.yes!, medicalSpecialtyCareReferral.no!]}
          onChange={formik.handleChange}
        />

        {formik.values.contentFlags?.includes("HSA compatible") && (
          <RadioGroup
            name="medicalHealthSavingsAccountEmployerContribution"
            label="HSA Employer Contribution"
            value={
              !formik.values.contentFlags?.includes("HSA compatible")
                ? null
                : formik.values.medicalHealthSavingsAccountEmployerContribution
            }
            error={
              hasFormikError(formik, "medicalHealthSavingsAccountEmployerContribution")
                ? fieldErrorMsg("employer contribution")
                : undefined
            }
            touched={formik.touched.medicalHealthSavingsAccountEmployerContribution}
            disabled={formik.isSubmitting}
            options={[
              { label: "Yes", value: true },
              { label: "No", value: false },
            ]}
            onChange={formik.handleChange}
          />
        )}
      </StackY>
    </StackY>
  );
};
