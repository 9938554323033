import type { ValueOf } from "./Helper";

export const benAdminPlatforms = [
  "I don’t use a platform",
  "4MyBenefits",
  "ADP BenMark",
  "ADP Health & Wealthfare Service Engine",
  "ADP LIFION",
  "ADP WorkForce Now",
  "Aflac",
  "Alight",
  "AllPay - Alliance Payroll",
  "American Fidelity",
  "AON Hewitt",
  "Appirio",
  "Apprize Technology",
  "APS Payroll",
  "Ascentis Corporation",
  "Baker Tilly",
  "BambooHR",
  "BAS - Benefit Allocation System",
  "Bay Bridge Administrators, LLC",
  "BCC - Benefit Coordinators Corporation",
  "Beacon Benefits Solution",
  "Benefit Architects",
  "Benefit Focus",
  "Benefit Harbor",
  "Benefit Mall",
  "Benefit Vision",
  "BenefitElect, Inc.",
  "BenefitExpress",
  "BenefitFirst (The Benefit Company)",
  "Benefits Outsourcing Solutions",
  "BenefitWerks",
  "BenefiX (Ben XML)",
  "BeneLIVE",
  "Benetech",
  "BeneTrac",
  "BenTek",
  "BenXPress",
  "BRMS (Virtual Benefits Administration System - VRBS)",
  "Bswift",
  "Businessolver - Benefitsolver",
  "CBIZ (CBAS)",
  "Centricity Solutions",
  "Ceridian - Dayforce HCM",
  "Certipay (i-enroller)",
  "Cognos HR",
  "Common Census",
  "Compass HRM",
  "Complete Benefits Alliance",
  "Conduent",
  "DATIS",
  "Decisely",
  "Ease",
  "eBenefits Network (Santeon)",
  "EBIX Benergy",
  "EBMS",
  "Employee Benefits Outsourcing",
  "Employee Benefits Specialist",
  "Employee Navigator",
  "EmpowerHR",
  "Empyrean (eBenefits Solutions)",
  "Everything Benefits",
  "Explain My Benefits",
  "ExponentHR",
  "FCE Benefit Administrators",
  "Fidelity Health (hCentive)",
  "First Financial Group of America (FFGA)",
  "Fis Global iWorks GBAS",
  "Flock",
  "FTJ Solutions",
  "Gallagher Marketplace (Liazon)",
  "Genesis - A Plus Payroll",
  "GNS Admin",
  "Greenshades",
  "Gusto",
  "HealthComp",
  "Infinity HR Software Solutions",
  "InfoSync",
  "Innovative Business Solutions (IBS)",
  "iSolved (Infinisource)",
  "Juno",
  "Kelly & Associates",
  "Lawson",
  "Liazon Bright Choices",
  "Lovitt-Touche ClearPath Prime (Liazon)",
  "Maestro",
  "Maxwell Health",
  "Mercer Marketplace 365",
  "Metis Benefits",
  "MMA MarketLink",
  "Myworkplace",
  "Namely",
  "NetChex",
  "OnePoint HCM",
  "OneSource Virtual",
  "Oracle",
  "Other",
  "PacFed",
  "Paycom",
  "Paycor HR",
  "Paylocity",
  "Payroll Strategies",
  "People Strategy",
  "PeopleSoft",
  "Pierce Group Benefits",
  "Plan Source",
  "Primepay",
  "Principal Financial Group (eBenefits Edge)",
  "PrismHR",
  "Professional Enrollment Concepts",
  "Proliant",
  "ResourceOne",
  "Rippling",
  "Sage Abra (Benefits Messenger)",
  "Sage People",
  "SAP",
  "Select Source",
  "Selerix BenSelect",
  "Servarus Systems",
  "Stratex (Toast)",
  "SyncHR",
  "Teemwurk",
  "The Benefits Expert (TBX)",
  "ThebenefitsHUB by Allsynx",
  "Triune Technologies",
  "Tyler Technologies",
  "UKG Kronos",
  "UKG Kronos Benefits Center PlanSource",
  "UKG Pro (formerly UltiPro)",
  "UKG Pro Benefits Prime PlanSource",
  "Verisource",
  "Via Benefits WTW Liazon",
  "Web Benefit Design",
  "Willis Towers Watson BenefitsConnect",
  "Workday",
  "Workforce Junction",
  "WorkPlace Solutions",
  "WORKTERRA",
  "Zenefits",
  "HR Connection",
  "I'm not sure",
  "Paychex Flex",
  "SmartBen",
  "B2E Solutions",
  "Sentrichr",
  "Benefits Technology Resources",
] as const;

export type BenAdminPlatformName = ValueOf<typeof benAdminPlatforms>;

export type BenAdminPlatform = {
  id: number;
  name: BenAdminPlatformName;
  createdAt: Date;
  updatedAt: Date;
};

const customOrder: Readonly<BenAdminPlatformName[]> = [
  "Other",
  "I'm not sure",
  "I don’t use a platform",
] as const;
export const sortBenAdminPlatforms = (benAdminPlatforms: BenAdminPlatform[]) =>
  [...benAdminPlatforms].sort((a, b) => {
    const aIdx = customOrder.findIndex((n) => n === a.name);
    const bIdx = customOrder.findIndex((n) => n === b.name);
    if (aIdx === bIdx) {
      //not custom order, default to alphabetical
      return a.name.localeCompare(b.name);
    }
    return aIdx - bIdx;
  });
