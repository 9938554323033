import {
  contributionBenefitTypes,
  getSalaryBasedClassPlans,
  waitingPeriodBenefitTypes,
} from "shared/types/BenefitTypes";
import { eifStepNavigation, eifSubStepsForStep } from "shared/types/EIF";
import { contributionFields } from "shared/types/EmployeeClassPlan";
import { getIsPFMLCoverage, getIsStatutoryCoverage } from "shared/types/SlfCoverages";
import { getAreTherePendingChanges } from "shared/utils/EIF/changeLog";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import {
  getPFMLContributionCompletionStatusForClient,
  getPFMLOnlyHasAdviceOnly,
  getStatutoryContributionCompletionStatus,
  getStatutoryOnlyHasReadOnly,
} from "shared/utils/EIF/nonClassBenefitsPreferences";
import { getBillingPreferencesInitialFormValues, getStatementsForPolicy } from "shared/utils/bill";
import { getEIFSubStepViewMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { getCoveragesInClientNotYetInAnyClass } from "shared/utils/employeeClass";
import { getCoveragesInClientNotYetInAnyBill } from "shared/utils/getCoveragesInClientNotYetInAnyBill";
import {
  benAdminAndDataFeedsValidationSchema,
  billingPreferencesSchema,
} from "shared/validation/policy";
import { employeeClassEarningsFields, type EmployeeClass } from "../../types/EmployeeClass";
import { employeeClassValidationSchema } from "../../validation/employeeClass";
import {
  disabilityContributionsValidationSchema,
  employeeClassPlanValidationSchema,
  voluntaryContributionsValidationSchema,
} from "../../validation/employeeClassPlan";
import { getEIFSubStepStatus } from "../../validation/getEIFSubStepStatus";
import { getValidationErrors } from "../../validation/getValidationErrors";
import { exhaustiveCheck } from "../exhaustiveCheck";
import type { Bill } from "../../types/Bill";
import type { ValueOf } from "../../types/Helper";
import type { Plan } from "../../types/Plan";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFStepId } from "shared/types/EIF";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type EIFStepCompleteStatus = "Not Started" | "In Progress" | "Completed";

export function getEIFStepCompleteStatus(
  eifStepId: EIFStepId,
  client: Client,
  clientPlans: Plan[],
  bills: Bill[],
  contacts: Contact[],
  billingSummaryStatementTemplates: Document[],
  employeeClasses: EmployeeClass[],
  employeeClassDocumentCount: number,
  changeSnapshot: DEIFChangeSnapshot,
  featureToggles: ClientFeatureToggles,
  subsidiaries: Subsidiary[],
): EIFStepCompleteStatus {
  if (client.eifSignedAt) {
    // If the client already signed, then the review step is completed and
    // should remain completed regardless of if further edits happened
    // after signing. The completion status of other steps is not relevant anymore
    if (eifStepId === "review-&-submit") {
      return "Completed";
    }

    const areTherePendingChanges = getAreTherePendingChanges(changeSnapshot);
    if (!areTherePendingChanges) {
      // Task is already signed and there are no edits, so we are going to mark steps as Completed
      // without checking them so that it doesn't revert back to incomplete if new fields
      // are added to EIF after it was signed and it is not being edited.
      return "Completed";
    }
  }

  // This step is special because it does not contain any substeps
  if (eifStepId === "review-&-submit") {
    const reviewStatus = getReviewAndSubmitCompletionStatus(
      client,
      clientPlans,
      bills,
      contacts,
      billingSummaryStatementTemplates,
      employeeClasses,
      employeeClassDocumentCount,
      changeSnapshot,
      featureToggles,
      subsidiaries,
    );
    return reviewStatus;
  }

  if (eifStepId === "plan-configuration-&-eligibility") {
    const planConfigStatus = getPlanConfigCompletionStatus(
      client,
      employeeClasses,
      employeeClassDocumentCount,
      clientPlans,
      featureToggles,
      changeSnapshot,
    );
    return planConfigStatus;
  }

  const subStepsComplete = eifSubStepsForStep(eifStepId, client, clientPlans, featureToggles).map(
    (subStep) =>
      getEIFSubStepStatus({
        eifSubStepId: subStep,
        client,
        clientPlans,
        bills,
        contacts,
        billingSummaryStatementTemplates,
        featureToggles,
        employeeClasses,
        employeeClassDocumentCount,
        changeSnapshot,
        subsidiaries,
      }),
  );

  if (subStepsComplete.every((sectionComplete) => sectionComplete === "Completed")) {
    return "Completed";
  } else if (
    subStepsComplete.some(
      (sectionComplete) => sectionComplete === "Completed" || sectionComplete === "In Progress",
    )
  ) {
    return "In Progress";
  } else {
    return "Not Started";
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
const steps = Object.keys(eifStepNavigation) as EIFStepId[];
const nonReviewSteps = steps.filter((s) => s !== "review-&-submit");

function getReviewAndSubmitCompletionStatus(
  client: Client,
  clientPlans: Plan[],
  bills: Bill[],
  contacts: Contact[],
  billingSummaryStatementTemplates: Document[],
  employeeClasses: EmployeeClass[],
  employeeClassDocumentCount: number,
  changeSnapshot: DEIFChangeSnapshot,
  featureToggles: ClientFeatureToggles,
  subsidiaries: Subsidiary[],
) {
  const nonReviewStepsCompletionStatus = nonReviewSteps.map((s) =>
    getEIFStepCompleteStatus(
      s,
      client,
      clientPlans,
      bills,
      contacts,
      billingSummaryStatementTemplates,
      employeeClasses,
      employeeClassDocumentCount,
      changeSnapshot,
      featureToggles,
      subsidiaries,
    ),
  );

  const allStepsCompleted = nonReviewStepsCompletionStatus.every(
    (sectionComplete) => sectionComplete === "Completed",
  );

  if (allStepsCompleted) {
    return client.eifSignedAt != null ? "Completed" : "In Progress";
  } else {
    return "Not Started";
  }
}

function getEmployeeClassFieldsStatus(
  employeeClass: EmployeeClass | undefined,
  fieldNames: (keyof typeof employeeClassValidationSchema.fields)[],
  validationContext?: { client: Client },
): EIFStepCompleteStatus {
  if (!employeeClass) return "Not Started";
  const allNulls = fieldNames.every((f) => employeeClass[f] == null);
  if (allNulls) return "Not Started";
  const employeeClassFieldsToCheck = employeeClassValidationSchema.pick(fieldNames);
  const validationErrors = getValidationErrors(
    employeeClassFieldsToCheck,
    employeeClass,
    validationContext,
  );
  const completed = validationErrors.length === 0;
  const status = completed ? "Completed" : "In Progress";
  return status;
}

function getEmployeeClassPlansFieldsStatus(
  employeeClass: EmployeeClass,
  fieldNames: readonly (keyof typeof employeeClassPlanValidationSchema.fields)[],
  benefitTypes: BenefitTypeEIF[],
) {
  const employeeClassFieldsToCheck = employeeClassPlanValidationSchema.pick(fieldNames);

  if (employeeClass.employeeClassPlans.length === 0) return "Not Started";

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter((ecp) =>
    benefitTypes.includes(ecp.plan.benefitType),
  );

  // marked as Completed since the benefit type is not included for the field list
  if (relevantEmployeeClassPlans.length === 0) {
    return "Completed";
  }

  const statuses = relevantEmployeeClassPlans.map((classPlan) => {
    const allNulls = fieldNames.every((f) => classPlan[f] == null);
    if (allNulls) return "Not Started";
    const validationErrors = getValidationErrors(employeeClassFieldsToCheck, classPlan, {
      benefitType: classPlan.plan.benefitType,
      prefill: false,
    });
    const completed = validationErrors.length === 0;
    return completed ? "Completed" : "In Progress";
  });

  const status = statuses.every((status) => status === "Not Started")
    ? "Not Started"
    : statuses.every((status) => status === "Completed")
    ? "Completed"
    : "In Progress";

  return status;
}

export function getEmployeeClassEligibilityCompletionStatus(
  employeeClass: EmployeeClass | undefined,
  singleClassBuilderMode: boolean,
) {
  if (singleClassBuilderMode) {
    return "Completed";
  }

  const fieldNames = [
    "jobTitles" as const,
    "compensationTypes" as const,
    "otherAttributes" as const,
  ];
  const status = getEmployeeClassFieldsStatus(employeeClass, fieldNames);
  return status;
}

export function getEmployeeClassDetailsCompletionStatus(employeeClass: EmployeeClass | undefined) {
  const fieldNames = [
    "numberOfEmployees" as const,
    "employmentTypes" as const,
    "minimumWeeklyHours" as const,
    "minimumWeeklyHoursAreNotCalculatedWeekly" as const,
    "minimumHoursDetails" as const,
  ];
  const status = getEmployeeClassFieldsStatus(employeeClass, fieldNames);
  return status;
}

export function getEmployeeClassBenefitsCompletionStatus(employeeClass: EmployeeClass | undefined) {
  if (!employeeClass) return "Not Started";
  const hasPlans = employeeClass.employeeClassPlans.length > 0;
  return hasPlans ? "Completed" : "Not Started";
}

export function getEmployeeClassWaitingPeriodsCompletionStatus(employeeClass: EmployeeClass) {
  const fieldNames = ["waitingPeriodType" as const];
  const status = getEmployeeClassPlansFieldsStatus(
    employeeClass,
    fieldNames,
    waitingPeriodBenefitTypes,
  );
  return status;
}

export function getEmployeeClassContributionsCompletionStatus(
  employeeClass: EmployeeClass,
  benefitTypes: BenefitTypeEIF[],
) {
  const status = getEmployeeClassPlansFieldsStatus(employeeClass, contributionFields, benefitTypes);
  return status;
}

export function getEmployeeClassTieredContributionsCompletionStatus(
  employeeClass: EmployeeClass,
  benefitTypes: BenefitTypeEIF[],
) {
  const contributionFieldsSchema = employeeClassPlanValidationSchema.pick(contributionFields);

  const fieldNames = Object.keys(contributionFieldsSchema.fields).filter(
    (field: string): field is keyof typeof employeeClassPlanValidationSchema.fields => true,
  );

  const status = getEmployeeClassPlansFieldsStatus(employeeClass, fieldNames, benefitTypes);
  return status;
}

export function getEmployeeClassDisabilityContributionsCompletionStatus(
  employeeClass: EmployeeClass,
  benefitTypes: BenefitTypeEIF[],
) {
  const fieldNames = Object.keys(disabilityContributionsValidationSchema.fields).filter(
    (field: string): field is keyof typeof disabilityContributionsValidationSchema.fields => true,
  );

  const status = getEmployeeClassPlansFieldsStatus(employeeClass, fieldNames, benefitTypes);
  return status;
}

export function getEmployeeClassVoluntaryContributionsCompletionStatus(
  employeeClass: EmployeeClass,
  benefitTypes: BenefitTypeEIF[],
) {
  const fieldNames = Object.keys(voluntaryContributionsValidationSchema.fields).filter(
    (field: string): field is keyof typeof voluntaryContributionsValidationSchema.fields => true,
  );

  const status = getEmployeeClassPlansFieldsStatus(employeeClass, fieldNames, benefitTypes);
  return status;
}

export function getEmployeeClassEarningsCompletionStatus(
  client: Client,
  employeeClass: EmployeeClass,
) {
  const salaryBasedClassPlans = getSalaryBasedClassPlans(employeeClass);
  const hasSalaryBasedClassPlans = salaryBasedClassPlans.length > 0;

  if (!hasSalaryBasedClassPlans) {
    return "Completed";
  }

  const fieldNames = employeeClassEarningsFields.slice();
  const status = getEmployeeClassFieldsStatus(employeeClass, fieldNames, { client });
  return status;
}

export function getEmployeeClassCompletionStatus(
  client: Client,
  employeeClass: EmployeeClass,
  singleClassBuilderMode: boolean,
) {
  const eligibilityStatus = getEmployeeClassEligibilityCompletionStatus(
    employeeClass,
    singleClassBuilderMode,
  );
  const detailsStatus = getEmployeeClassDetailsCompletionStatus(employeeClass);
  const benefitsStatus = getEmployeeClassBenefitsCompletionStatus(employeeClass);
  const waitingPeriodsStatus = getEmployeeClassWaitingPeriodsCompletionStatus(employeeClass);
  const contributionsStatus = getEmployeeClassContributionsCompletionStatus(
    employeeClass,
    contributionBenefitTypes,
  );

  const earningsStatus = getEmployeeClassEarningsCompletionStatus(client, employeeClass);

  const record: Record<EIFClassBuilderSection, EIFStepCompleteStatus> = {
    Eligibility: eligibilityStatus,
    Details: detailsStatus,
    Benefits: benefitsStatus,
    "Waiting Periods": waitingPeriodsStatus,
    Contributions: contributionsStatus,
    "Earnings definition": earningsStatus,
  };

  const statuses = Object.values(record);

  const status = statuses.every((status) => status === "Completed")
    ? "Completed"
    : statuses.every((status) => status === "Not Started")
    ? "Not Started"
    : "In Progress";

  return status;
}

export const EIFClassBuilderSections = [
  "Eligibility",
  "Details",
  "Benefits",
  "Waiting Periods",
  "Contributions",
  "Earnings definition",
] as const;
export type EIFClassBuilderSection = ValueOf<typeof EIFClassBuilderSections>;

export function getEmployeeClassSectionCompletionStatus(
  client: Client,
  employeeClass: EmployeeClass,
  singleClassBuilderMode: boolean,
  section: EIFClassBuilderSection,
) {
  switch (section) {
    case "Eligibility": {
      const eligibilityCompleted = getEmployeeClassEligibilityCompletionStatus(
        employeeClass,
        singleClassBuilderMode,
      );
      return eligibilityCompleted;
    }
    case "Details": {
      const detailsCompleted = getEmployeeClassDetailsCompletionStatus(employeeClass);
      return detailsCompleted;
    }
    case "Benefits": {
      const benefitsCompleted = getEmployeeClassBenefitsCompletionStatus(employeeClass);
      return benefitsCompleted;
    }
    case "Waiting Periods": {
      const waitingPeriodsCompleted = getEmployeeClassWaitingPeriodsCompletionStatus(employeeClass);
      return waitingPeriodsCompleted;
    }
    case "Contributions": {
      const contributionsCompleted = getEmployeeClassContributionsCompletionStatus(
        employeeClass,
        contributionBenefitTypes,
      );
      return contributionsCompleted;
    }
    case "Earnings definition": {
      const earningsCompleted = getEmployeeClassEarningsCompletionStatus(client, employeeClass);
      return earningsCompleted;
    }
    default:
      exhaustiveCheck(section);
  }
}

export function getPlanConfigCompletionStatus(
  client: Client,
  employeeClasses: EmployeeClass[],
  employeeClassDocumentCount: number,
  clientPlans: Plan[],
  featureToggles: ClientFeatureToggles,
  changeSnapshot: DEIFChangeSnapshot,
): EIFStepCompleteStatus {
  const needsClassbuilder = getIsSubStepApplicable({
    eifSubStepId: "class-builder",
    client,
    plans: clientPlans,
  });
  const needsPFMLPreferences = getIsSubStepApplicable({
    eifSubStepId: "non-class-benefits-preferences",
    client,
    featureToggles,
  });
  const needsFLIPreferences = getIsSubStepApplicable({
    eifSubStepId: "fli-preferences",
    client,
  });

  const classBuilderStatus = getEIFSubStepStatus({
    eifSubStepId: "class-builder",
    client,
    employeeClasses,
    employeeClassDocumentCount,
    featureToggles,
    changeSnapshot,
    clientPlans,
  });

  const pfmlPreferencesStatus = getEIFSubStepStatus({
    eifSubStepId: "non-class-benefits-preferences",
    client,
    clientPlans,
  });
  const fliPreferencesStatus = getEIFSubStepStatus({
    eifSubStepId: "fli-preferences",
    client,
    featureToggles,
  });

  const statuses: EIFStepCompleteStatus[] = [
    ...(needsClassbuilder ? ([classBuilderStatus] as const) : []),
    ...(needsPFMLPreferences ? ([pfmlPreferencesStatus] as const) : []),
    ...(needsFLIPreferences ? ([fliPreferencesStatus] as const) : []),
  ];

  const status = statuses.every((status) => status === "Not Started")
    ? "Not Started"
    : statuses.every((status) => status === "Completed")
    ? "Completed"
    : "In Progress";

  return status;
}

export function getClassBuilderCompletionStatus(
  client: Client,
  employeeClasses: EmployeeClass[] | undefined,
  employeeClassDocumentCount: number,
  changeSnapshot: DEIFChangeSnapshot,
  clientPlans: Plan[],
) {
  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);
  const benefitsNotAssociatedToClassYet = getCoveragesInClientNotYetInAnyClass(
    client.allPoliciesSlfCoverages,
    employeeClasses || [],
    clientPlans,
  );

  if (client.allowClientSelfServicePlanConfig === "NO") {
    return employeeClassDocumentCount > 0 ? "Completed" : "Not Started";
  } else if (!employeeClasses || employeeClasses.length === 0) {
    return "Not Started";
  } else if (benefitsNotAssociatedToClassYet.length > 0 && !suppressErrorsPostSigning) {
    return "In Progress";
  } else {
    const singleClassBuilderMode = client.needsEmployeeClasses === "NO";
    const allClassesCompleted = employeeClasses.every((employeeClass) => {
      const status = getEmployeeClassCompletionStatus(
        client,
        employeeClass,
        singleClassBuilderMode,
      );
      return status === "Completed";
    });

    return allClassesCompleted ? "Completed" : "In Progress";
  }
}

export function getPFMLPreferencesCompletionStatus(client: Client, pfmlClientPlans: Plan[]) {
  const clientHasPFMLCoverages =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsPFMLCoverage(coverage)) ?? false;

  if (pfmlClientPlans.length === 0 && !clientHasPFMLCoverages) {
    return "Completed";
  }

  const relevantPlansCompletetionStatus = getPFMLContributionCompletionStatusForClient(
    pfmlClientPlans,
    client,
  );
  const isReadOnly = getPFMLOnlyHasAdviceOnly(pfmlClientPlans);
  const relevantPlansCompleted = relevantPlansCompletetionStatus === "Completed";

  if (client.pfmlPreferencesStepViewed) {
    return isReadOnly || relevantPlansCompleted ? "Completed" : "In Progress";
  } else if (!client.pfmlPreferencesStepViewed) {
    return relevantPlansCompleted
      ? "Completed"
      : relevantPlansCompletetionStatus === "In Progress"
      ? "In Progress"
      : "Not Started";
  } else {
    return "Not Started";
  }
}

export function getStatutoryPreferencesCompletionStatus(
  client: Client,
  statutoryClientPlans: Plan[],
) {
  const clientHasStatutoryCoverages =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsStatutoryCoverage(coverage)) ?? false;

  if (statutoryClientPlans.length === 0 && !clientHasStatutoryCoverages) {
    return "Completed";
  }

  const relevantPlansCompletetionStatus = getStatutoryContributionCompletionStatus(
    client,
    statutoryClientPlans,
  );
  const isReadOnly = getStatutoryOnlyHasReadOnly(statutoryClientPlans);
  const relevantPlansCompleted = relevantPlansCompletetionStatus === "Completed";

  if (client.pfmlPreferencesStepViewed) {
    return isReadOnly || relevantPlansCompleted ? "Completed" : "In Progress";
  } else if (!client.pfmlPreferencesStepViewed) {
    return relevantPlansCompleted
      ? "Completed"
      : relevantPlansCompletetionStatus === "In Progress"
      ? "In Progress"
      : "Not Started";
  } else {
    return "Not Started";
  }
}

export function getBenAdminAndDataFeedsCompletionStatus(
  policy: Pick<
    Policy,
    | "hasBenAdminPlatform"
    | "benAdminPlatformId"
    | "benAdminPlatformOtherName"
    | "benAdminPlatform"
    | "dataFeeds"
    | "dataFeedsBenAdminContact"
    | "dataFeedsProductionSupportContact"
    | "dataFeedsImplementorContact"
  >,
) {
  const relevantFields = Object.keys(benAdminAndDataFeedsValidationSchema.fields).filter(
    (field: string): field is keyof typeof policy => {
      return Object.keys(policy).includes(field);
    },
  );

  const notStarted = relevantFields.every((field) => policy[field] === null);

  if (notStarted) {
    return "Not Started";
  }

  const validationErrors = getValidationErrors(benAdminAndDataFeedsValidationSchema, policy, {
    prefill: false,
  });
  const isCompleted = validationErrors.length === 0;

  return isCompleted ? "Completed" : "In Progress";
}

export function getBillingPreferencesCompletionStatus(args: {
  policy: Policy;
  bills: Bill[];
  billingSummaryStatementTemplates: Document[];
}) {
  const {
    policy,
    bills: allBills,
    billingSummaryStatementTemplates: allBillingSummaryStatementTemplates,
  } = args;

  const billsInThisPolicy = allBills.filter((b) => b.policyId === policy.id);

  const billingSummaryStatementTemplatesInThisPolicy = getStatementsForPolicy(
    allBillingSummaryStatementTemplates,
    policy,
  );

  const missingBillingSummaryStatementTemplate =
    policy.billingSummaryStatementType === "CUSTOM" &&
    billingSummaryStatementTemplatesInThisPolicy.length === 0;

  const { formValues } = getBillingPreferencesInitialFormValues(
    policy.slfCoverages,
    policy.id,
    policy,
    billsInThisPolicy,
  );

  const validationErrors = getValidationErrors(billingPreferencesSchema, formValues, {
    policy,
    prefill: false,
  });

  const isSubStepCompleted = validationErrors.length === 0;
  const coveragesInClientNotYetInAnyBill = getCoveragesInClientNotYetInAnyBill(
    policy.slfCoverages,
    policy.billingStructureType,
    policy.billSplitType,
    billsInThisPolicy,
  );
  const coveragesIncludedInBillsIfApplicable =
    policy.billSplitType === "BENEFIT"
      ? coveragesInClientNotYetInAnyBill != null && coveragesInClientNotYetInAnyBill.length === 0
      : true;

  const isCompleted =
    billsInThisPolicy.length >= 1 &&
    isSubStepCompleted &&
    !missingBillingSummaryStatementTemplate &&
    coveragesIncludedInBillsIfApplicable;

  if (isCompleted) return "Completed";

  const fields: Array<keyof typeof policy> = [
    "billingAdministrationType",
    "billingStructureType",
    "billingSummaryStatementType",
    "billPayrollCycles",
    "billPayrollCyclesOther",
    "billPayrollCyclesExplanation",
    "billSplitType",
  ];
  const isInProgress =
    billsInThisPolicy.length > 0 || fields.some((field) => policy[field] != null);
  return isInProgress ? "In Progress" : "Not Started";
}
