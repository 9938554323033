import { Row, Col } from "client/src/components/Grid/Grid";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import clsx from "clsx";
import { getIn } from "formik";
import { useCallback } from "react";
import { contactToContactInput } from "shared/types/Contact";
import { getPrimaryPolicy, isAddCoverageClient } from "shared/utils/client";
import { formatFullName } from "shared/utils/format";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { policyValidationSchema } from "shared/validation/policy";
import { yesNoNotSureNullablePrefill } from "shared/validation/validation";
import { Anchor } from "../../components/Anchor/Anchor";
import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { GenericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { FormInput } from "../../components/Form/Input";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body3, Body5 } from "../../components/Typography/Typography";
import { hasFormikErrors } from "../../utils/hasFormikErrors";
import { UnsavedChangesModal, getHandleFormClosing } from "./UnsavedChangesModal";
import * as profileModulesStyles from "./profileModules.module.less";
import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdatePolicyQuery } from "client/src/hooks/policy";
import type { Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";
import type { ContactInput, ContactType } from "shared/types/Contact";

const dataFeedsValidationSchema = policyValidationSchema
  .pick([
    "dataFeedsBenAdminContact",
    "dataFeedsProductionSupportContact",
    "dataFeedsImplementorContact",
  ])
  .shape({
    dataFeeds: yesNoNotSureNullablePrefill,
  });

export const DataFeedsFormDataTestId = {
  hubCard: "data-feeds-forms-hub-card",
};

export type DataFeedsFormProps = {
  client: Client;
  editing: boolean;
  disabled: boolean;
  updatePolicyQuery: UpdatePolicyQuery;
  toggleActiveSection: () => void;
  trackElementClicked: (options: ElementClickedOptions) => void;
  onFinalSubmit?: Dispatch<SetStateAction<boolean>>;
};

export function DataFeedsForm({
  client,
  editing,
  disabled,
  updatePolicyQuery,
  toggleActiveSection,
  trackElementClicked,
  onFinalSubmit,
}: DataFeedsFormProps) {
  const policy = getPrimaryPolicy(client);

  const validationErrors = getValidationErrors(dataFeedsValidationSchema, policy);
  const status = validationErrors.length > 0 ? "not-started" : "completed";

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Data Feed",
        moduleState: status === "not-started" ? "Not Started" : "Completed",
        buttonLabel,
      });
    },
    [trackElementClicked, status],
  );

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      dataFeeds: policy.dataFeeds,
      dataFeedsBenAdminContact: policy.dataFeedsBenAdminContact
        ? contactToContactInput(policy.dataFeedsBenAdminContact)
        : {
            policyId: policy.id,
            type: "DATA_FEEDS_BEN_ADMIN",
            firstName: "",
            lastName: "",
            email: "",
          },
      dataFeedsImplementorContact: policy.dataFeedsImplementorContact
        ? contactToContactInput(policy.dataFeedsImplementorContact)
        : {
            policyId: policy.id,
            type: "DATA_FEEDS_IMPLEMENTOR",
            firstName: "",
            lastName: "",
            email: "",
          },
      dataFeedsProductionSupportContact: policy.dataFeedsProductionSupportContact
        ? contactToContactInput(policy.dataFeedsProductionSupportContact)
        : {
            policyId: policy.id,
            type: "DATA_FEEDS_PRODUCTION_SUPPORT",
            firstName: "",
            lastName: "",
            email: "",
          },
    },
    validationSchema: dataFeedsValidationSchema,
    onSubmit: async (values) => {
      const getContactInput = (
        contact:
          | typeof values.dataFeedsBenAdminContact
          | typeof values.dataFeedsImplementorContact
          | typeof values.dataFeedsProductionSupportContact,
        type: ContactType,
      ): ContactInput | null => {
        return contact
          ? {
              id: contact.id ?? undefined,
              policyId: contact.policyId,
              firstName: contact.firstName ?? "",
              lastName: contact.lastName ?? "",
              email: contact.email ?? "",
              type,
            }
          : null;
      };

      const data = {
        dataFeeds: values.dataFeeds,
        dataFeedsBenAdminContact: getContactInput(
          values.dataFeedsBenAdminContact,
          "DATA_FEEDS_BEN_ADMIN",
        ),
        dataFeedsImplementorContact: getContactInput(
          values.dataFeedsImplementorContact,
          "DATA_FEEDS_IMPLEMENTOR",
        ),
        dataFeedsProductionSupportContact: getContactInput(
          values.dataFeedsProductionSupportContact,
          "DATA_FEEDS_PRODUCTION_SUPPORT",
        ),
      };

      await updatePolicyQuery.mutateAsync({
        params: { clientId: client.id, policyId: policy.id },
        query: { prefill: true },
        data,
      });

      if (status === "not-started") onFinalSubmit?.(true);
      toggleActiveSection();

      await slobMessage.success(`Company Profile updated`);
    },
  });

  const handleCancel = getHandleFormClosing({ formik, onProceed: toggleActiveSection });

  const updatedBy = policy.dataFeedsUpdatedByUser;
  const updatedAt = policy.dataFeedsUpdatedAt;
  const isAddCoverage = isAddCoverageClient(client);

  const addDetailsClick = useCallback(() => {
    track("Add Details");
    toggleActiveSection();
  }, [track, toggleActiveSection]);
  const editClick = useCallback(() => {
    track("Edit");
    toggleActiveSection();
  }, [track, toggleActiveSection]);

  const hasError = hasFormikErrors(formik);

  return (
    <HubCard data-testid={DataFeedsFormDataTestId.hubCard} disabled={disabled}>
      <HubCardHeader
        title="Data Feed"
        description={
          <>
            <p>
              If you have a benefits administration platform, Sun Life can set up a data feed so
              your employee benefit elections are automatically sent to us.
              {isAddCoverage &&
                " If you already have a data feed with us, someone will be reaching out to you."}
            </p>

            <Anchor
              target="_blank"
              href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015687571"
              onClick={() => track("Data Feeds")}
            >
              Learn more about Data Feeds
            </Anchor>
          </>
        }
        badge={
          <>
            {status === "not-started" && (
              <Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />
            )}
            {status === "completed" && (
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="Completed"
                updatedBy={updatedBy}
                updatedAt={updatedAt}
              />
            )}
          </>
        }
        hasDivider={true}
      />

      {editing ? (
        <>
          <UnsavedChangesModal form={formik} />

          <form onSubmit={formik.handleSubmit} className="stack-y-24">
            <StackY dist={32} wrap={false}>
              <div>
                <RadioGroup
                  name="dataFeeds"
                  label="Do you want to set up a data feed with Sun Life?"
                  value={formik.values.dataFeeds}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  touched={formik.touched.dataFeeds}
                  error={formik.errors.dataFeeds}
                  options={[
                    { value: "YES", label: "Yes" },
                    { value: "NO", label: "No" },
                    { value: "NOT_SURE", label: "Not sure" },
                  ]}
                />

                {formik.values.dataFeeds === "NOT_SURE" && (
                  <Body3>Someone from Sun Life will reach out to you to discuss further.</Body3>
                )}
              </div>

              {formik.values.dataFeeds === "YES" && (
                <>
                  <Body3 as="div">
                    These contact fields are optional but are helpful for Sun Life to have in order
                    to get started on establishing your data feed.
                  </Body3>

                  <StackY dist={32}>
                    <StackY dist={16}>
                      <Body3 as="div" id="dataFeedsImplementatorNameDescription">
                        <strong>Who is your Data Feed Setup Contact?</strong>
                      </Body3>

                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsImplementorContact?.firstName}
                            maxLength={191}
                            label={"First Name"}
                            topText={"First Name"}
                            name={"dataFeedsImplementorContact.firstName"}
                            touched={formik.touched.dataFeedsImplementorContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsImplementorContact, "firstName")}
                          />
                        </Col>

                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsImplementorContact?.lastName}
                            maxLength={191}
                            label={"Last Name"}
                            topText={"Last Name"}
                            name={"dataFeedsImplementorContact.lastName"}
                            touched={formik.touched.dataFeedsImplementorContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsImplementorContact, "lastName")}
                          />
                        </Col>

                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsImplementorContact?.email}
                            maxLength={191}
                            label={"Email"}
                            topText={"Email"}
                            name={"dataFeedsImplementorContact.email"}
                            touched={formik.touched.dataFeedsImplementorContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsImplementorContact, "email")}
                          />
                        </Col>
                      </Row>
                    </StackY>

                    <StackY dist={16}>
                      <StackY dist={4}>
                        <Body3 as="div" id="dataFeedsProductionSupportContactDescription">
                          <strong>Who is your Data Feed Support Contact?</strong>
                        </Body3>
                        <Body3 as="div">
                          This is the contact that would be sent ongoing discrepancy reports once
                          the file is live.
                        </Body3>
                      </StackY>

                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsProductionSupportContact?.firstName}
                            maxLength={191}
                            label={"First Name"}
                            topText={"First Name"}
                            name={"dataFeedsProductionSupportContact.firstName"}
                            touched={formik.touched.dataFeedsProductionSupportContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(
                              formik.errors.dataFeedsProductionSupportContact,
                              "firstName",
                            )}
                          />
                        </Col>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsProductionSupportContact?.lastName}
                            maxLength={191}
                            label={"Last Name"}
                            topText={"Last Name"}
                            name={"dataFeedsProductionSupportContact.lastName"}
                            touched={formik.touched.dataFeedsProductionSupportContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(
                              formik.errors.dataFeedsProductionSupportContact,
                              "lastName",
                            )}
                          />
                        </Col>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsProductionSupportContact?.email}
                            maxLength={191}
                            label={"Email"}
                            topText={"Email"}
                            name={"dataFeedsProductionSupportContact.email"}
                            touched={formik.touched.dataFeedsProductionSupportContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsProductionSupportContact, "email")}
                          />
                        </Col>
                      </Row>
                    </StackY>

                    <StackY dist={16}>
                      <StackY dist={4}>
                        <Body3 as="div" id="dataFeedsBenAdmintDescription">
                          <strong>Who is your Data Feed Ongoing Contact?</strong>
                        </Body3>

                        <Body3 as="div">
                          This is the contact we'd work with on test file results, questions about
                          implementation, etc.
                        </Body3>
                      </StackY>

                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsBenAdminContact?.firstName}
                            maxLength={191}
                            label={"First Name"}
                            topText={"First Name"}
                            name={"dataFeedsBenAdminContact.firstName"}
                            touched={formik.touched.dataFeedsBenAdminContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsBenAdminContact, "firstName")}
                          />
                        </Col>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsBenAdminContact?.lastName}
                            maxLength={191}
                            label={"Last Name"}
                            topText={"Last Name"}
                            name={"dataFeedsBenAdminContact.lastName"}
                            touched={formik.touched.dataFeedsBenAdminContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsBenAdminContact, "lastName")}
                          />
                        </Col>
                        <Col span={12}>
                          <FormInput
                            value={formik.values.dataFeedsBenAdminContact?.email}
                            maxLength={191}
                            label={"Email"}
                            topText={"Email"}
                            name={"dataFeedsBenAdminContact.email"}
                            touched={formik.touched.dataFeedsBenAdminContact}
                            disabled={formik.isSubmitting}
                            onChange={formik.handleChange}
                            error={getIn(formik.errors.dataFeedsBenAdminContact, "email")}
                          />
                        </Col>
                      </Row>
                    </StackY>
                  </StackY>
                </>
              )}
            </StackY>

            {formik.status && <LoadingError type="component" title={GenericErrorCopy2} />}

            {hasError.length > 0 && (
              <Body3 redMedium as="div">
                {hasError.length > 1
                  ? `Please correct the errors above`
                  : `Please correct the error above`}
              </Body3>
            )}

            <StackX dist={8}>
              <Button
                size="middle"
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting}
                loading={formik.isSubmitting}
                aria-label={formik.isSubmitting ? "Save" : undefined}
              >
                {formik.isSubmitting ? "" : "Save"}
              </Button>
              <Button
                size="middle"
                type="text"
                onClick={handleCancel}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </StackX>
          </form>
        </>
      ) : (
        <>
          {status === "not-started" ? (
            <div className="mt-32">
              <Button size="middle" type="primary" onClick={addDetailsClick} disabled={disabled}>
                Add Details
              </Button>
            </div>
          ) : (
            <>
              <StackY dist={16} className="w-full">
                <table className={clsx("w-full", profileModulesStyles.tableRowSpacing)}>
                  <tbody>
                    <tr>
                      <th align="left" className={clsx(profileModulesStyles.tableHeader, "body2")}>
                        Data Feed
                      </th>

                      <td className="body3" data-testid="dataFeedsValue">
                        {policy.dataFeeds === "YES" && <>Requested</>}
                        {policy.dataFeeds === "NO" && <>Not requested</>}
                        {policy.dataFeeds === "NOT_SURE" && <>Not sure</>}
                      </td>
                    </tr>

                    {policy.dataFeeds === "YES" && (
                      <>
                        <tr>
                          <th
                            align="left"
                            scope="row"
                            className={clsx(profileModulesStyles.tableHeader, "body2")}
                          >
                            Data Feed Setup Contact
                          </th>

                          {policy.dataFeedsImplementorContact &&
                            (!!formatFullName(policy.dataFeedsImplementorContact) ||
                              !!policy.dataFeedsImplementorContact.email) && (
                              <td
                                className={clsx("body3", profileModulesStyles.breakWord)}
                                data-testid="dataFeedsImplementatorValue"
                              >
                                {formatFullName(policy.dataFeedsImplementorContact)}
                                {!!policy.dataFeedsImplementorContact.email && (
                                  <Body5 as="div">{policy.dataFeedsImplementorContact.email}</Body5>
                                )}
                              </td>
                            )}

                          {!!policy.dataFeedsImplementorContact?.email && (
                            <td className="body3"></td>
                          )}
                          {!policy.dataFeedsImplementorContact?.firstName &&
                            !policy.dataFeedsImplementorContact?.lastName &&
                            !policy.dataFeedsImplementorContact?.email && (
                              <td className="body3">-</td>
                            )}
                        </tr>
                        <tr>
                          <th
                            align="left"
                            className={clsx(profileModulesStyles.tableHeader, "body2")}
                          >
                            Data Feed Support Contact
                          </th>

                          {policy.dataFeedsProductionSupportContact &&
                            (!!formatFullName(policy.dataFeedsProductionSupportContact) ||
                              !!policy.dataFeedsProductionSupportContact.email) && (
                              <td
                                className={clsx("body3", profileModulesStyles.breakWord)}
                                data-testid="dataFeedsProductionSupportContactValue"
                              >
                                {formatFullName(policy.dataFeedsProductionSupportContact)}

                                {!!policy.dataFeedsProductionSupportContact.email && (
                                  <Body5 as="div">
                                    {policy.dataFeedsProductionSupportContact.email}
                                  </Body5>
                                )}
                              </td>
                            )}

                          {!policy.dataFeedsProductionSupportContact?.firstName &&
                            !policy.dataFeedsProductionSupportContact?.lastName &&
                            !policy.dataFeedsProductionSupportContact?.email && (
                              <td className={clsx("body3", profileModulesStyles.breakWord)}>-</td>
                            )}
                        </tr>
                        <tr>
                          <th
                            align="left"
                            className={clsx(profileModulesStyles.tableHeader, "body2")}
                          >
                            Data Feed Ongoing Contact
                          </th>

                          {policy.dataFeedsBenAdminContact &&
                            (!!formatFullName(policy.dataFeedsBenAdminContact) ||
                              !!policy.dataFeedsBenAdminContact.email) && (
                              <td
                                className={clsx("body3", profileModulesStyles.breakWord)}
                                data-testid="dataFeedsBenAdmintValue"
                              >
                                {formatFullName(policy.dataFeedsBenAdminContact)}

                                {!!policy.dataFeedsBenAdminContact.email && (
                                  <Body5 as="div">{policy.dataFeedsBenAdminContact.email}</Body5>
                                )}
                              </td>
                            )}

                          {!policy.dataFeedsBenAdminContact?.firstName &&
                            policy.dataFeedsBenAdminContact?.lastName &&
                            !policy.dataFeedsBenAdminContact?.email && (
                              <td className={clsx("body3", profileModulesStyles.breakWord)}>-</td>
                            )}
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>

                <Button size="middle" type="default" onClick={editClick} disabled={disabled}>
                  Edit
                </Button>
              </StackY>
            </>
          )}
        </>
      )}
    </HubCard>
  );
}
