import { faInfoCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelectMultiple } from "client/src/components/MultiSelect/SlobSelectMultiple";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { TagList } from "client/src/components/TagList/TagList";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { ContactDropdown } from "client/src/domain/Contact/ContactDropdown";
import { UpdateSunLifeUserAccessModal } from "client/src/domain/Contact/UpdateSunLifeUserAccessModal";
import { BillCategorizationField } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillCategorizationField";
import { BillSeparationByBenefit } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillSeparationByBenefit";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { LocationDropdown } from "client/src/domain/Location/LocationDropdown";
import { LocationModalProvider } from "client/src/domain/Location/LocationModalProvider";
import { getLocationMultiSelectOptionsAndValue } from "client/src/domain/Location/getLocationMultiSelectProps";
import { getIn } from "formik";
import { useState } from "react";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getContactHasExpectedAccess } from "shared/utils/contact";
import * as styles from "./BillDetailsInputs.module.less";
import type { BillSplitType } from ".prisma/client";
import type { BillTiming, BillingAdministrationType, BillingStructureType } from "@prisma/client";
import type { FormikErrors, FormikValues, useFormik } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { BillId, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";

type Props<TValues extends FormikValues> = {
  client: Client;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
  locations: Location[];
  admins: Contact[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billIds: BillId[];
  formik: ReturnType<typeof useFormik<TValues>>;
  prefillErrors: FormikErrors<TValues>;
  disabled: boolean;
  form?: string;
} & (
  | {
      billingAdministrationType: Extract<BillingAdministrationType, "LIST">;
      billSplitType: Extract<BillSplitType, "NONE">;
      advanceOrArrears: AdvanceOrArrearsSelection;
    }
  | {
      billingAdministrationType: Extract<BillingAdministrationType, "LIST">;
      billSplitType: Extract<BillSplitType, "LOCATION" | "TAGS" | "BENEFIT">;
      advanceOrArrears: AdvanceOrArrearsSelection;
      billPreviews: BillPreview[];
      index1: number;
      index2: number | undefined;
    }
  | {
      billingAdministrationType: Extract<BillingAdministrationType, "SELF" | "TPA">;
      billingStructureType: BillingStructureType;
      billSplitType: null;
    }
);

export function BillDetailsInputs<TValues extends FormikValues>(props: Props<TValues>) {
  const {
    client,
    policy,
    locations,
    admins,
    billSplitType,
    formik,
    prefillErrors,
    billIds,
    changeSnapshot,
    authUser,
    disabled,
    form,
    billingAdministrationType,
  } = props;

  const [contactAccessModalState, setContactAccessModalState] = useState<
    { state: "closed" } | { state: "open"; previousContactId: string | null; nextContactId: string }
  >({ state: "closed" });

  const hasMixedTiming =
    billingAdministrationType === "LIST"
      ? getAdvanceAndArrearsCoveragesBreakdown(policy, props.advanceOrArrears).hasMixedTiming
      : false;

  return (
    <StackY dist={32} wrap={false}>
      {((billingAdministrationType === "LIST" && billSplitType !== "NONE") ||
        (billingAdministrationType === "SELF" && props.billingStructureType === "MULTIPLE") ||
        (billingAdministrationType === "TPA" && props.billingStructureType === "MULTIPLE")) && (
        <StackY dist={8} wrap={false}>
          <FormInput
            name="billName"
            maxLength={191}
            label={billingAdministrationType === "LIST" ? "Name of bill" : "Name of statement"}
            touched={getIn(formik.touched, "billName") || prefillErrors["billName"]}
            error={getIn(formik.errors, "billName") || prefillErrors["billName"]}
            disabled={formik.isSubmitting || disabled}
            value={formik.values.billName}
            onChange={formik.handleChange}
            topText={
              billingAdministrationType === "LIST" ? (
                <StackX dist={8} wrap={false}>
                  <Body2>What do you want to name this bill?</Body2>
                  <Tooltip
                    trigger="hover"
                    title={
                      <StackY dist={20}>
                        <Body5 white>
                          This name will display as a title on your bill. You can use this field to
                          describe what the bill contains or enter a name or code that you use in
                          your own systems.
                        </Body5>
                      </StackY>
                    }
                    placement="bottom"
                    large
                  >
                    <span>
                      <button aria-label="info" className={`btn-reset ${styles.infoIcon}`}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </button>
                    </span>
                  </Tooltip>
                </StackX>
              ) : (
                <StackX dist={8} wrap={false}>
                  <Body2>What do you want to name this statement?</Body2>
                  <Tooltip
                    trigger="hover"
                    title={
                      <StackY dist={20}>
                        <Body5 white>
                          This name will display as a title on your statement. You can use this
                          field to describe what the statement contains or enter a name or code that
                          you use in your own systems.
                        </Body5>
                      </StackY>
                    }
                    placement="bottom"
                    large
                  >
                    <span>
                      <button aria-label="info" className={`btn-reset ${styles.infoIcon}`}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </button>
                    </span>
                  </Tooltip>
                </StackX>
              )
            }
            autoComplete="off"
            form={form}
          />
          <EditedFieldMsg
            changeDetailInfoList={billIds.map((billId) => changeSnapshot.Bill[billId]?.billName)}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({ field: "billName", client, formik })}
          />
        </StackY>
      )}

      {billSplitType === "LOCATION" && (
        <LocationModalProvider clientId={client.id}>
          {({ showLocationModal, locationModalVisible }) => (
            <div>
              <Body2>Which location(s) do you want on this bill?</Body2>

              <Body3 as="p">
                Sun Life will compare this to your employee enrollment elections (census) file to
                ensure the correct employees are on each bill.
              </Body3>

              <StackY dist={8} wrap={false}>
                <SlobSelectMultiple
                  aria-label="Select location(s)"
                  placeholder="Select location(s)"
                  name="groupByLocationIds"
                  maxTagTextLength={50}
                  touched={
                    getIn(formik.touched, "groupByLocationIds") ||
                    !!prefillErrors.groupByLocationIds
                  }
                  error={
                    getIn(formik.errors, "groupByLocationIds") || prefillErrors.groupByLocationIds
                  }
                  open={locationModalVisible ? false : undefined}
                  disabled={formik.isSubmitting || disabled}
                  onBlur={formik.handleBlur}
                  {...getLocationMultiSelectOptionsAndValue({
                    locations,
                    unassignedLocations: locations,
                    selectedLocationIds: getIn(formik.values, "groupByLocationIds"),
                    onEditLocation: showLocationModal,
                  })}
                  onChange={(labeledValue) => {
                    if (labeledValue)
                      void formik.setFieldValue(
                        "groupByLocationIds",
                        labeledValue.map((v) => v.value),
                      );
                  }}
                />

                <div>
                  <Button
                    type="text-only"
                    size="middle"
                    icon={<FontAwesomeIcon icon={faPlusCircle} />}
                    onClick={() => showLocationModal()}
                  >
                    Create a new location
                  </Button>
                </div>

                <EditedFieldMsg
                  changeDetailInfoList={billIds.map(
                    (billId) => changeSnapshot.BillLocation[billId]?.locationId,
                  )}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "groupByLocationIds",
                    client,
                    formik,
                  })}
                />
              </StackY>
            </div>
          )}
        </LocationModalProvider>
      )}

      {billSplitType === "TAGS" && (
        <div>
          <Body2 as="p">
            Which divisions/departments or other groupings do you want on this bill?
            <br />
            <Body3>
              Sun Life will compare this to your employee enrollment elections (census) file to
              ensure the correct employees are on each bill.
            </Body3>
          </Body2>

          <StackY dist={8} wrap={false}>
            <TagList
              name="splitTags"
              placeholder="Add a name and press enter"
              value={getIn(formik.values, "splitTags")}
              onChange={formik.handleChange}
              touched={getIn(formik.touched, "splitTags") || !!prefillErrors.splitTags}
              error={getIn(formik.errors, "splitTags") || prefillErrors.splitTags}
              disabled={formik.isSubmitting || disabled}
            />

            <EditedFieldMsg
              changeDetailInfoList={billIds.map((billId) => changeSnapshot.Bill[billId]?.splitTags)}
              client={client}
              authUser={authUser}
              hasPendingEdit={getHasPendingEdit({ field: "splitTags", client, formik })}
            />
          </StackY>
        </div>
      )}

      {billSplitType === "BENEFIT" && (
        <StackY dist={16} wrap={false}>
          <BillSeparationByBenefit
            policy={policy}
            formik={formik}
            form={form}
            disabled={disabled}
            billPreviews={props.billPreviews}
            prefillErrors={prefillErrors}
            index1={props.index1}
            index2={props.index2}
            advanceOrArrears={props.advanceOrArrears}
          />
          <EditedFieldMsg
            changeDetailInfoList={billIds.map(
              (billId) => changeSnapshot.Bill[billId]?.slfCoverages,
            )}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({ field: "slfCoverages", client, formik })}
          />
        </StackY>
      )}

      <StackY dist={8} wrap={false}>
        <ContactDropdown
          client={client}
          id="contactId"
          name="contactId"
          label={
            <label htmlFor="contactId" className="mb-16" style={{ display: "block" }}>
              <Body2>
                {billSplitType == null
                  ? "Who will be the administrator for this summary statement?"
                  : "Who will be the administrator for this bill?"}
              </Body2>
              <br />
              <Body3>This is the main contact for this bill if Sun Life needs to reach out.</Body3>
            </label>
          }
          placeholder="Select an administrator*"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting || disabled}
          touched={getIn(formik.touched, "contactId") || prefillErrors["contactId"]}
          error={getIn(formik.errors, "contactId") || prefillErrors["contactId"]}
          onChange={async (event) => {
            const selectedContact = admins.find((c) => c.id === event.value);

            if (selectedContact) {
              const hasBillingAccess = getContactHasExpectedAccess(
                selectedContact,
                [policy.id],
                "billingAccess",
              );

              if (hasBillingAccess) {
                await formik.setFieldValue("contactId", event.value);
              } else {
                setContactAccessModalState({
                  state: "open",
                  previousContactId: getIn(formik.values, "contactId"),
                  nextContactId: String(event.value),
                });
              }
            }
          }}
          contacts={admins}
          value={getIn(formik.values, "contactId")}
        />
        <EditedFieldMsg
          changeDetailInfoList={billIds.map((billId) => changeSnapshot.Bill[billId]?.contactId)}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({ field: "contactId", client, formik })}
        />

        <UpdateSunLifeUserAccessModal
          isVisible={contactAccessModalState.state === "open"}
          variant="bills"
          slfPolicyNumber={policy.slfPolicyNumber}
          onConfirm={async () => {
            if (contactAccessModalState.state === "open") {
              await formik.setFieldValue("contactId", contactAccessModalState.nextContactId);
            }
            setContactAccessModalState({ state: "closed" });
          }}
          onCancel={async () => {
            if (contactAccessModalState.state === "open") {
              await formik.setFieldValue("contactId", contactAccessModalState.previousContactId);
            }
            setContactAccessModalState({ state: "closed" });
          }}
        />
      </StackY>

      <StackY dist={8} wrap={false}>
        <StackY dist={16} wrap={false}>
          <Checkbox
            name="hasDifferentMailingAddress"
            label={
              <StackX dist={8} wrap={false}>
                <Body3>My billing address is different than my plan administrator’s location</Body3>
                <Tooltip
                  trigger="hover"
                  title={
                    <StackY dist={20}>
                      <Body5 white>
                        This address will be used if important documents related to this bill need
                        to be mailed.
                      </Body5>
                    </StackY>
                  }
                  placement="bottom"
                  large
                >
                  <span>
                    <button aria-label="info" className={`btn-reset ${styles.infoIcon}`}>
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                  </span>
                </Tooltip>
              </StackX>
            }
            checked={!!getIn(formik.values, "hasDifferentMailingAddress")}
            onChange={async (e) => {
              if (!e.target.checked) {
                await formik.setFieldValue("locationId", "");
              }
              formik.handleChange(e);
            }}
            disabled={formik.isSubmitting || disabled}
            form={form}
          />

          {!!getIn(formik.values, "hasDifferentMailingAddress") && (
            <LocationDropdown
              clientId={client.id}
              formik={formik}
              prefillErrors={prefillErrors}
              name="locationId"
              locations={locations}
              disabled={formik.isSubmitting || disabled}
            />
          )}
        </StackY>
        <EditedFieldMsg
          changeDetailInfoList={billIds.map(
            (billId) => changeSnapshot.Bill[billId]?.hasDifferentMailingAddress,
          )}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({
            field: "hasDifferentMailingAddress",
            client,
            formik,
          })}
        />
      </StackY>

      <div>
        <Body2 as="p">
          {billSplitType == null
            ? "How many eligible employees are on this statement?"
            : "How many eligible employees are on this bill?"}
        </Body2>

        <StackY dist={8} wrap={false}>
          <FormInput
            name="numberOfEmployees"
            label="# of eligible employees"
            disabled={formik.isSubmitting || disabled}
            onChange={formik.handleChange}
            touched={
              getIn(formik.touched, "numberOfEmployees") || prefillErrors["numberOfEmployees"]
            }
            error={getIn(formik.errors, "numberOfEmployees") || prefillErrors["numberOfEmployees"]}
            value={getIn(formik.values, "numberOfEmployees")}
            maxLength={10}
            autoComplete="off"
            form={form}
          />
          <EditedFieldMsg
            changeDetailInfoList={billIds.map(
              (billId) => changeSnapshot.Bill[billId]?.numberOfEmployees,
            )}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({ field: "numberOfEmployees", client, formik })}
          />
        </StackY>
      </div>

      {billingAdministrationType === "LIST" && (
        <>
          {[
            {
              categorizeEmployees_employeeCategorizationType:
                "categorizeEmployees_employeeCategorizationType",
              categorizeEmployees: "categorizeEmployees",
              employeeCategorizationType: "employeeCategorizationType",
              categorizeByLocationIds: "categorizeByLocationIds",
              categoriesByTags: "categoriesByTags",
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo, TB-5458 part 3
              billId: billIds[0]!,
            },
            ...(hasMixedTiming && billSplitType !== "BENEFIT"
              ? [
                  {
                    categorizeEmployees_employeeCategorizationType:
                      "categorizeEmployees_employeeCategorizationType_secondary",
                    categorizeEmployees: "categorizeEmployees_secondary",
                    employeeCategorizationType: "employeeCategorizationType_secondary",
                    categorizeByLocationIds: "categorizeByLocationIds_secondary",
                    categoriesByTags: "categoriesByTags_secondary",
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo, TB-5458 part 3
                    billId: billIds[1]!,
                  },
                ]
              : []),
          ].map((names, index) => {
            const categorizeEmployees = getIn(formik.values, names.categorizeEmployees);
            const employeeCategorizationType = getIn(
              formik.values,
              names.employeeCategorizationType,
            );

            const billName: string | null | undefined = getIn(formik.values, "billName");
            const billTiming: BillTiming = index === 0 ? "Advance" : "Arrears";

            return (
              <div key={index} data-testid="employee-grouping-question" className="stack-y-16">
                {(billSplitType === "NONE" ||
                  billSplitType === "LOCATION" ||
                  billSplitType === "TAGS") &&
                  hasMixedTiming && (
                    <H3 as="div">
                      {billName ? (
                        `${billName} - ${billTiming} bill`
                      ) : (
                        <>
                          {billSplitType === "NONE"
                            ? `${billTiming} bill`
                            : `${billTiming} bill ${
                                (hasMixedTiming ? props.index1 / 2 : props.index1) + 1
                              }`}
                        </>
                      )}
                    </H3>
                  )}

                <StackY dist={8} wrap={false}>
                  <RadioGroup<"ALPHA" | "LOCATION" | "TAGS">
                    name={names.categorizeEmployees_employeeCategorizationType}
                    label="How do you want to sort the employees on this bill?"
                    direction="vertical"
                    disabled={formik.isSubmitting || disabled}
                    touched={
                      getIn(formik.touched, names.categorizeEmployees) ||
                      getIn(formik.touched, names.employeeCategorizationType) ||
                      !!prefillErrors[names.categorizeEmployees] ||
                      !!prefillErrors[names.employeeCategorizationType]
                    }
                    error={
                      getIn(formik.errors, names.categorizeEmployees) ||
                      getIn(formik.errors, names.employeeCategorizationType) ||
                      prefillErrors[names.categorizeEmployees] ||
                      prefillErrors[names.employeeCategorizationType]
                    }
                    value={
                      categorizeEmployees === "NO"
                        ? "ALPHA"
                        : categorizeEmployees === "YES"
                        ? employeeCategorizationType
                        : null
                    }
                    onChange={async (e) => {
                      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
                      const value = e.target.value as "ALPHA" | "LOCATION" | "TAGS";
                      if (value === "ALPHA") {
                        await formik.setFieldValue(names.categorizeEmployees, "NO");
                        await formik.setFieldValue(names.employeeCategorizationType, null);
                        await formik.setFieldValue(names.categorizeByLocationIds, null);
                        await formik.setFieldValue(names.categoriesByTags, null);
                      } else {
                        await formik.setFieldValue(names.categorizeEmployees, "YES");
                        await formik.setFieldValue(names.employeeCategorizationType, value);
                        if (value === "LOCATION") {
                          await formik.setFieldValue(names.categoriesByTags, null);
                        } else if (value === "TAGS") {
                          await formik.setFieldValue(names.categorizeByLocationIds, null);
                        }
                      }
                    }}
                    options={[
                      {
                        value: "ALPHA",
                        label: (
                          <StackX dist={8} wrap={false}>
                            <Body3>List all employees together alphabetically by last name</Body3>
                            <Body5>Most common</Body5>
                          </StackX>
                        ),
                      },
                      {
                        value: "LOCATION",
                        label: "Group employees by location",
                      },
                      {
                        value: "TAGS",
                        label: "Group employees by division, department, or something else",
                      },
                    ]}
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Bill[names.billId]?.categorizeEmployees]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "categorizeEmployees",
                      client,
                      formik,
                    })}
                  />
                </StackY>

                {employeeCategorizationType === "LOCATION" && (
                  <StackY dist={8} wrap={false}>
                    <BillCategorizationField
                      name={names.categorizeByLocationIds}
                      value={getIn(formik.values, names.categorizeByLocationIds) ?? []}
                      onChange={(val) => formik.setFieldValue(names.categorizeByLocationIds, val)}
                      clientId={client.id}
                      locations={locations}
                      employeeCategorizationType="LOCATION"
                      disabled={formik.isSubmitting || disabled}
                      touched={
                        getIn(formik.touched, names.categorizeByLocationIds) ||
                        !!prefillErrors[names.categorizeByLocationIds]
                      }
                      error={
                        getIn(formik.errors, names.categorizeByLocationIds) ||
                        prefillErrors[names.categorizeByLocationIds]
                      }
                    />

                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.BillLocation[names.billId]?.category]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({ field: "splitTags", client, formik })}
                    />
                  </StackY>
                )}

                {employeeCategorizationType === "TAGS" && (
                  <StackY dist={8} wrap={false}>
                    <BillCategorizationField
                      name={names.categoriesByTags}
                      value={getIn(formik.values, names.categoriesByTags) ?? []}
                      onChange={(val) => formik.setFieldValue(names.categoriesByTags, val)}
                      clientId={client.id}
                      locations={locations}
                      employeeCategorizationType="TAGS"
                      disabled={formik.isSubmitting || disabled}
                      touched={
                        getIn(formik.touched, names.categoriesByTags) ||
                        !!prefillErrors[names.categoriesByTags]
                      }
                      error={
                        getIn(formik.errors, names.categoriesByTags) ||
                        prefillErrors[names.categoriesByTags]
                      }
                    />

                    <EditedFieldMsg
                      changeDetailInfoList={[changeSnapshot.Bill[names.billId]?.categoriesByTags]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "categoriesByTags",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                )}
              </div>
            );
          })}
        </>
      )}

      <input type="submit" style={{ display: "none" }} form={form} />
    </StackY>
  );
}
