import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { Skeleton } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useChangeSnapshot } from "client/src/hooks/changeLogs";
import { triggerError } from "client/src/hooks/generalError";
import { useUpdatePolicy } from "client/src/hooks/policy";
import { useCreateSubsidiary, useUpdateSubsidiary } from "client/src/hooks/subsidiary";
import { useEffect, useMemo, useRef } from "react";
import { Navigate, useMatch } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { eifStepIdMap, eifSubStepsForStep, getEIFSubStepMap } from "shared/types/EIF";
import { getIsPFMLCoverage } from "shared/types/SlfCoverages";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { HubCard } from "../../components/HubCard/HubCard";

import { NavigationHeader } from "../../components/NavigationHeader/NavigationHeader";
import { PageContent } from "../../components/PageContent/PageContent";
import { StackY } from "../../components/Spacing/Spacing";
import { StepNavigation } from "../../components/StepNavigation/StepNavigation";
import { useSlobAuth } from "../../hooks/auth";
import { useSuspenseEIFQueries, useUpdateClient } from "../../hooks/client";
import { useCreateContact } from "../../hooks/contact";
import { useCreateClass, useGetClass, useUpdateClass } from "../../hooks/employeeClass";
import {
  useSetBenefitTypeAvailablePlans,
  useSetContributionsQuery,
  useSetWaitingPeriods,
} from "../../hooks/employeeClassPlan";
import { useUpdatePlans } from "../../hooks/plans";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useTitle } from "../../hooks/useTitle";
import { useTrackElementClicked, useTrackStatusChanged } from "../../utils/analytics";

import * as styles from "./EIFStepPage.module.less";
import { EIFStepPageContents } from "./EIFStepPageContents";
import type { StepNavigationStep } from "../../components/StepNavigation/StepNavigation";
import type { TrackStatusChangedFunc } from "../../utils/analytics";
import type { PathMatch } from "react-router-dom";
import type { ClientId } from "shared/types/Client";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

export function EIFStepPage() {
  const { clientId, eifStepId, eifSubStepId, employeeClassId } = useClientHubParams([
    "clientId",
    "eifStepId",
  ]);
  const isEditingClassBuilder = useMatch(RouteData.eifClassBuilderEdit.getPathTemplate());

  // Load data - always enabled
  const { authUser, isLoading: isLoadingAuthUser } = useSlobAuth();

  const {
    data: employeeClass,
    isLoading: isLoadingEmployeeClass,
    isError: isErrorEmployeeClass,
    error: errorEmployeeClass,
  } = useGetClass(clientId, employeeClassId);

  const [
    { data: client },
    { data: employeeClasses },
    { data: clientPlans },
    { data: locations },
    { data: bills },
    { data: deletedBills },
    { data: contacts },
    { data: benAdminPlatforms },
    { data: featureToggles },
    { data: changes },
    { data: documents },
    { data: documentsCount },
    { data: billingSummaryStatementTemplates },
    { data: additionalCompensations },
    { data: subsidiaries },
  ] = useSuspenseEIFQueries(clientId);

  const isLoading = isLoadingAuthUser || !authUser || isLoadingEmployeeClass;
  const isSuccess = !!authUser && !isLoadingEmployeeClass;

  const stepName = eifStepIdMap[eifStepId];
  const subStepName =
    eifSubStepId && getEIFSubStepMap({ eifSubStepId, clientPlans, featureToggles });
  useTitle(subStepName || stepName);

  // Mutations
  const updateClientQuery = useUpdateClient();
  const updatePolicyQuery = useUpdatePolicy();
  const createClassQuery = useCreateClass();
  const updateClassQuery = useUpdateClass();
  const createContactQuery = useCreateContact();
  const setBenefitTypesAvailablePlansQuery = useSetBenefitTypeAvailablePlans();
  const setWaitingPeriodsQuery = useSetWaitingPeriods();
  const setContributionsQuery = useSetContributionsQuery();
  const updatePlansQuery = useUpdatePlans();
  const createSubsidiaryQuery = useCreateSubsidiary();
  const updateSubsidiaryQuery = useUpdateSubsidiary();

  const trackElementClicked = useTrackElementClicked(client, "Submit Company Information");
  const trackStatusChanged = useTrackStatusChanged(client, "Submit Company Information");

  const changeSnapshot = useChangeSnapshot(changes);

  const stepCompleteStatus = getEIFStepCompleteStatus(
    eifStepId,
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    employeeClasses,
    documentsCount.count,
    changeSnapshot,
    featureToggles,
    subsidiaries,
  );

  useTrackSectionStatusChange(eifStepId, stepCompleteStatus || undefined, trackStatusChanged);

  const navSteps = useMemo(() => {
    const eifEditState = getEIFEditState({ client, changesSnapshot: changeSnapshot });
    const isEIFSigned = client.eifSignedAt !== null;
    const shouldSeeNavigationSteps = !isEIFSigned
      ? true
      : getIsInternalUser(authUser) && eifEditState !== "ready-for-review"; // show for ICs during IC edit, except for after edits have been sent to BA

    const steps = eifSubStepsForStep(eifStepId, client, clientPlans, featureToggles);

    const navigationSteps = shouldSeeNavigationSteps
      ? steps.map<StepNavigationStep>((step) => ({
          name: getEIFSubStepMap({ eifSubStepId: step, clientPlans, featureToggles }),
          status: getEIFSubStepStatus({
            eifSubStepId: step,
            client,
            clientPlans,
            bills,
            contacts,
            billingSummaryStatementTemplates,
            featureToggles,
            employeeClasses,
            employeeClassDocumentCount: documentsCount.count,
            changeSnapshot,
            subsidiaries,
          }),
          isSelected: step === eifSubStepId,
          to: RouteData.eifSubStepDetail.getPath(clientId, eifStepId, step),
        }))
      : [];

    const navSteps = navigationSteps.concat({
      name: "Review",
      status: "Not Started",
      hasDivider: true,
      isSelected: !eifSubStepId && stepCompleteStatus !== "Not Started",
      icon: faClipboardList,
      to: RouteData.eifStepDetail.getPath(clientId, eifStepId),
    });

    return navSteps;
  }, [
    eifStepId,
    eifSubStepId,
    stepCompleteStatus,
    clientId,
    client,
    clientPlans,
    bills,
    contacts,
    billingSummaryStatementTemplates,
    featureToggles,
    employeeClasses,
    documentsCount.count,
    authUser,
    changeSnapshot,
    subsidiaries,
  ]);

  if (isErrorEmployeeClass) {
    triggerError(errorEmployeeClass);
  }

  if (client.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  const isASubStepEditPage = Boolean(eifSubStepId);
  const nonICTryingToICEdit =
    authUser && !getIsInternalUser(authUser) && client.eifSignedAt && isASubStepEditPage;

  const isEifReadyForReview =
    getEIFEditState({ client, changesSnapshot: changeSnapshot }) === "ready-for-review";
  const tryingToEditWhenReadyForReview = isEifReadyForReview && isASubStepEditPage;

  if (nonICTryingToICEdit || tryingToEditWhenReadyForReview) {
    return <Navigate to={RouteData.eifSummary.getPath(client.id)} replace />;
  }

  const clientHasPFMLBenefitType =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsPFMLCoverage(coverage)) ?? false;
  const useNewClassBuilderFlow = clientHasPFMLBenefitType;

  const showLeftNavigation = eifStepId !== "review-&-submit";

  return (
    <div className={styles.content}>
      <NavigationHeader
        backTo={getBackToTarget(
          clientId,
          eifStepId,
          useNewClassBuilderFlow,
          isEditingClassBuilder,
          employeeClasses,
          eifSubStepId,
        )}
        title={stepName}
      />

      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          {showLeftNavigation && (
            <Col span={8}>
              <StepNavigation steps={navSteps} />
            </Col>
          )}
          <Col span={16}>
            {isLoading && <EIFStepPageContentsLoading />}

            {isSuccess && (
              <EIFStepPageContents
                eifStepId={eifStepId}
                eifSubStepId={eifSubStepId}
                client={client}
                classes={employeeClasses}
                employeeClassDocumentCount={documentsCount.count}
                locations={locations}
                bills={bills}
                deletedBills={deletedBills}
                contacts={contacts}
                clientPlans={clientPlans}
                subsidiaries={subsidiaries}
                benAdminPlatforms={benAdminPlatforms}
                updateClientQuery={updateClientQuery}
                updatePolicyQuery={updatePolicyQuery}
                createClassQuery={createClassQuery}
                updateClassQuery={updateClassQuery}
                createContactQuery={createContactQuery}
                createSubsidiaryQuery={createSubsidiaryQuery}
                updateSubsidiaryQuery={updateSubsidiaryQuery}
                employeeClass={employeeClass}
                additionalCompensations={additionalCompensations || []}
                documents={documents}
                changeSnapshot={changeSnapshot}
                billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                setBenefitTypesAvailablePlansQuery={setBenefitTypesAvailablePlansQuery}
                trackElementClicked={trackElementClicked}
                setWaitingPeriodsQuery={setWaitingPeriodsQuery}
                setContributionsQuery={setContributionsQuery}
                updatePlansQuery={updatePlansQuery}
                authUser={authUser}
                featureToggles={featureToggles}
              />
            )}
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}

export function EIFStepPageLoading() {
  const { eifStepId } = useClientHubParams(["eifStepId"]);

  const stepName = eifStepIdMap[eifStepId];

  return (
    <div className={styles.content}>
      <NavigationHeader backTo={null} title={stepName} />

      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={8}>
            <StepNavigation loading />
          </Col>

          <Col span={16}>
            <EIFStepPageContentsLoading />
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}

const getBackToTarget = (
  clientId: ClientId,
  eifStepId: EIFStepId,
  useNewClassBuilderFlow: boolean | undefined,
  isEditingClassBuilder: PathMatch<string> | null,
  employeeClasses: EmployeeClass[],
  eifSubStepId: EIFSubStepId | undefined,
): string => {
  if (eifStepId === "plan-configuration-&-eligibility") {
    if (useNewClassBuilderFlow && isEditingClassBuilder) {
      return RouteData.eifStepDetail.getPath(clientId, "plan-configuration-&-eligibility");
    }
    if (useNewClassBuilderFlow && employeeClasses.length === 0 && eifSubStepId != null) {
      return RouteData.eifStepDetail.getPath(clientId, "plan-configuration-&-eligibility");
    }
    if (!useNewClassBuilderFlow && eifSubStepId != null) {
      return RouteData.eifStepDetail.getPath(clientId, "plan-configuration-&-eligibility");
    }
  }
  return RouteData.clientTaskDetail.getPath(clientId, "eif-submit-company-information");
};

export function EIFStepPageContentsLoading() {
  return (
    <div data-testid="loading">
      <StackY dist={32}>
        <Skeleton active title={{ width: "60%" }} paragraph={false} />

        <HubCard>
          <StackY dist={32}>
            <Skeleton active paragraph={{ rows: 3 }} />
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </StackY>
        </HubCard>
        <HubCard>
          <StackY dist={32}>
            <Skeleton active paragraph={{ rows: 3 }} />
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </StackY>
        </HubCard>
      </StackY>
    </div>
  );
}

export const useTrackSectionStatusChange = (
  module: string,
  status: EIFStepCompleteStatus | undefined,
  trackStatusChanged: TrackStatusChangedFunc,
) => {
  const lastStatus = useRef<EIFStepCompleteStatus | undefined>(status);
  const lastModule = useRef<string>(module);

  useEffect(() => {
    if (
      lastModule.current === module &&
      lastStatus.current &&
      status &&
      status !== lastStatus.current
    ) {
      trackStatusChanged({ module, moduleStatus: status });
    }
    lastStatus.current = status;
    lastModule.current = module;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't run on lastStatus change
  }, [status, trackStatusChanged, module]);
};
