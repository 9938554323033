import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import pluralize from "pluralize";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import {
  EnrollmentResourcesCategories,
  type DocumentCategory,
  EnrollmentResourcesCategoriesNoVDR,
} from "shared/types/Document";
import { getCanSeeBenefitSummaries } from "shared/utils/getCanSeeBenefitSummaries";
import { RouteData } from "../../../../shared/config/routeData";
import {
  getBenefitClips,
  getBasicEnrollmentFlyers,
  getBeneficiaryDesignationWebLinks,
  isClientDateValidForEnrollmentDocsAutomation,
} from "../../../../shared/data/Client";
import { getIsInternalUser } from "../../../../shared/rbac/rbac";
import {
  documentBenefitTypesNames,
  documentBenefitTypes,
} from "../../../../shared/types/DocumentBenefitType";
import {
  formatDateWeekDayShortMonthWithYear,
  formatFullName,
} from "../../../../shared/utils/format";
import { Anchor } from "../../components/Anchor/Anchor";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Button } from "../../components/Button/Button";
import { OverlappingCircles } from "../../components/Circle/OverlappingCircles";
import { HubCard } from "../../components/HubCard/HubCard";
import { Loading } from "../../components/Loading/Loading";
import { StackY } from "../../components/Spacing/Spacing";
import { Body1, Body2, Body3, H2 } from "../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../components/UnorderedList/UnorderedList";
import { useSlobAuth } from "../../hooks/auth";
import { useUpdateClient } from "../../hooks/client";
import { useGetDocuments } from "../../hooks/document";
import { triggerError } from "../../hooks/generalError";
import { useTitle } from "../../hooks/useTitle";
import { useToggler } from "../../hooks/useToggler";
import { useGetWebLinks } from "../../hooks/webLink";
import { useTrackElementClicked } from "../../utils/analytics";

import { InternalControlForResourcesPage } from "./InternalControlForResourcesPage";
import { RequestFreeExpertHelpModal } from "./RequestFreeExpertHelpModal";
import * as styles from "./enrollmentResourcesPage.module.less";
import { useAmountOfItemsPerBenefitType } from "./useAmountOfItemsPerBenefitType";
import { useAmountOfItemsPerCategory } from "./useAmountOfItemsPerCategory";

import type { Client } from "../../../../shared/types/Client";
import type { WebLinkCategory } from "../../../../shared/types/WebLink";
import type { ReactNode } from "react";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EnrollmentResourcesPageProps = {
  client: Client;
  featureToggles: ClientFeatureToggles;
};

export function EnrollmentResourcesPage({ client }: EnrollmentResourcesPageProps) {
  const pageTitle = "Enrollment Resources";
  useTitle(pageTitle);

  const { authUser } = useSlobAuth();

  const clientId = client.id;

  // @todo: update the track element clicked and lose historical data?
  const trackElementClicked = useTrackElementClicked(client, "Enrollment Resources");
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const [requestFreeExpertHelpIsOpen, toggleRequestFreeExpertHelpModal] = useToggler();

  const documentCategoriesToGet: DocumentCategory[] = EnrollmentResourcesCategories;

  const {
    data: documents,
    isLoading: isLoadingDocuments,
    error: errorDocuments,
  } = useGetDocuments({
    clientId,
    categories: documentCategoriesToGet,
  });

  const {
    data: webLinks,
    isLoading: isLoadingWebLinks,
    error: errorWebLinks,
  } = useGetWebLinks(clientId, [
    "benefit-summaries",
    "benefit-rate-sheets",
    "enrollment-forms",
    "id-cards-and-online-access",
    "custom-enrollment-materials",
    "value-added-services",
  ]);

  const basicEnrollmentFlyers = getBasicEnrollmentFlyers(client);
  const isClientDateValid = isClientDateValidForEnrollmentDocsAutomation(client);
  const beneficiaryDesignationWebLinks = isClientDateValid
    ? getBeneficiaryDesignationWebLinks(client)
    : [];
  const amountOfItemsPerCategory = useAmountOfItemsPerCategory(
    documents,
    webLinks,
    basicEnrollmentFlyers,
    beneficiaryDesignationWebLinks,
    client,
  );
  const amountOfItemsPerBenefitType = useAmountOfItemsPerBenefitType(
    documents,
    webLinks,
    beneficiaryDesignationWebLinks,
    client,
  );

  const { mutateAsync: updateClient, isPending: isUpdatingClient } = useUpdateClient();

  if (errorDocuments) {
    return triggerError(errorDocuments);
  }
  if (errorWebLinks) {
    return triggerError(errorWebLinks);
  }

  const isLoading = isLoadingDocuments || isLoadingWebLinks;

  const isInternalUser = getIsInternalUser(authUser);

  const enrollmentNeedsUpdatedBy = client.enrollmentNeedsUpdatedByUser
    ? formatFullName({
        firstName: client.enrollmentNeedsUpdatedByUser.firstName,
        lastName: client.enrollmentNeedsUpdatedByUser.lastName,
      })
    : "Someone";

  const has = (category: DocumentCategory | WebLinkCategory) => {
    return Boolean((amountOfItemsPerCategory[category] ?? 0) > 0);
  };

  const hasMoreThan25Employees = (client.employeesNumber ?? 0) > 25;
  const benefitClips = getBenefitClips(client);

  const canSeeBenefitSummaries = getCanSeeBenefitSummaries({
    authUser,
    benefitSummaries: documents?.filter((d) => d.category === "benefit-summaries"),
    alwaysShowBenefitSummaries: client.alwaysShowBenefitSummaries,
  });

  const selectedResourcesTypeForUpload: DocumentCategory[] = [
    ...EnrollmentResourcesCategoriesNoVDR,
    "voluntary-deduction-report",
  ];

  return (
    <>
      <div className={styles.centerColumn}>
        <section className="mt-40 mb-56">
          <h1>Employee & Employer Resources</h1>

          <div className={styles.description}>
            <Body1 as="p">
              Review documents and educational resources shared with you throughout the onboarding
              process, as well as give your employees the knowledge they need to make smart
              decisions about their enrollments.
            </Body1>
          </div>
        </section>
      </div>

      <div className={styles.content}>
        <div className={styles.centerColumn}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {client.enrollmentNeeds == null ? (
                <div className={clsx(styles.banner, "mb-48")}>
                  <div>
                    <H2 white>Get FREE expert help from Sun Life</H2>
                    <Body1 white as="p">
                      Sun Life can help you plan a successful enrollment event with customized
                      resources to help you engage and educate employees.
                    </Body1>
                    <div className="mt-32">
                      <Button
                        type="primary"
                        size="large"
                        onClick={toggleRequestFreeExpertHelpModal}
                      >
                        Request FREE Expert Help
                      </Button>{" "}
                      <Body2 white>
                        <span className={clsx(styles.opaque, "mx-12")}>or</span>
                        <Anchor
                          white
                          target="_blank"
                          href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4417522674323"
                        >
                          learn more
                        </Anchor>
                      </Body2>
                    </div>
                  </div>

                  <div>
                    <div className="mb-8">
                      <Body1 white>Access to extra free custom resources</Body1>
                    </div>

                    <UnorderedList variant="checkmark" color="white" tight>
                      {hasMoreThan25Employees && (
                        <UnorderedListItem>Benefits Explorer site</UnorderedListItem>
                      )}
                      <UnorderedListItem>Email Templates</UnorderedListItem>
                      <UnorderedListItem>Webinars and Presentation Materials</UnorderedListItem>
                      <UnorderedListItem>Telephonic Enrollment</UnorderedListItem>
                      <UnorderedListItem>Employee Benefit Counselors</UnorderedListItem>
                      <UnorderedListItem>Spanish and foreign language assistance</UnorderedListItem>
                    </UnorderedList>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mb-48">
                    <div className={clsx(styles.banner, styles.banner__sharpBottomBorder)}>
                      <OverlappingCircles
                        side="right"
                        style={{ marginTop: "-36px", marginRight: "35%" }}
                      />
                      <h2 className="white h2 mb-0" style={{ position: "relative" }}>
                        Get FREE expert help from Sun Life
                      </h2>
                    </div>
                    <div className={styles.bannerWhite}>
                      <StackY dist={24}>
                        <AlertBanner
                          variant="info"
                          message={
                            <span>
                              <Body2>Expert help requested.</Body2>{" "}
                              <Body3>
                                A Voluntary Solutions Manager (VSM) will reach out to you via email
                                with next steps.
                              </Body3>
                            </span>
                          }
                        />

                        <StackY dist={16}>
                          <Row gutter={20}>
                            <Col flex="205px">
                              <Body2 as="b">Your enrollment needs</Body2>
                            </Col>
                            <Col flex="1" className="body3">
                              {client.enrollmentNeeds || "-"}
                            </Col>
                          </Row>

                          <Row gutter={20}>
                            <Col flex="205px">
                              <Body2 as="b">Requested by</Body2>
                            </Col>
                            <Col flex="1" className="body3">
                              {enrollmentNeedsUpdatedBy} on{" "}
                              {client.enrollmentNeedsUpdatedAt
                                ? formatDateWeekDayShortMonthWithYear(
                                    client.enrollmentNeedsUpdatedAt,
                                  )
                                : "-"}
                            </Col>
                          </Row>
                        </StackY>

                        <Button
                          type="default"
                          size="middle"
                          onClick={toggleRequestFreeExpertHelpModal}
                        >
                          Edit Request
                        </Button>
                      </StackY>
                    </div>
                  </div>
                </>
              )}

              {isInternalUser && (
                <div className="mb-32">
                  <InternalControlForResourcesPage
                    clientId={clientId}
                    documents={documents}
                    selectedResourcesType={selectedResourcesTypeForUpload}
                    track={track}
                    updateClient={updateClient}
                    isUpdatingClient={isUpdatingClient}
                    alwaysShowBenefitSummaries={client.alwaysShowBenefitSummaries}
                  />
                </div>
              )}

              <section id="browse-by-resource-type" className="mb-48">
                <div className={styles.twoColumns}>
                  <h2>Browse by resource type</h2>

                  <Button size="xtra-small" to={RouteData.resourcesListing.getPath(clientId)}>
                    View all resources
                  </Button>
                </div>
                <hr className="mt-0" />

                <div className={styles.cardContainer}>
                  {has("benefit-summaries") && canSeeBenefitSummaries ? (
                    <Link
                      to={RouteData.resourcesListing.getPath(clientId) + "?benefit-summaries=true"}
                    >
                      <BenefitSummariesCard>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["benefit-summaries"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["benefit-summaries"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </BenefitSummariesCard>
                    </Link>
                  ) : (
                    <BenefitSummariesCard>
                      <Body3 colorSecondary>
                        Coming Soon <span className="mx-8">•</span>
                      </Body3>{" "}
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/98bf3fe1-130d-435b-829d-b00e0110670d/gvbh-ee-8384-generic-enrollment-booklet-original-file.pdf"
                        target="_blank"
                      >
                        See a sample
                      </Anchor>
                    </BenefitSummariesCard>
                  )}

                  {has("voluntary-deduction-report") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) +
                        "?voluntary-deduction-report=true"
                      }
                    >
                      <HubCard data-testid="voluntary-deduction-report" grow={true}>
                        <Body2 blueMedium>Voluntary Deduction Report</Body2>
                        <p>
                          This report provides deduction amounts for employees who enrolled in
                          voluntary coverages.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["voluntary-deduction-report"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["voluntary-deduction-report"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("benefit-rate-sheets") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) + "?benefit-rate-sheets=true"
                      }
                    >
                      <HubCard data-testid="benefit-rate-sheets" grow={true}>
                        <Body2 blueMedium>Benefit Rate Sheets</Body2>
                        <p>
                          Custom-made benefit rate sheets that include specific coverage options
                          unique to your plan(s) with Sun Life.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["benefit-rate-sheets"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["benefit-rate-sheets"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("enrollment-forms") && (
                    <Link
                      to={RouteData.resourcesListing.getPath(clientId) + "?enrollment-forms=true"}
                    >
                      <HubCard data-testid="enrollment-forms" grow={true}>
                        <Body2 blueMedium>Enrollment Forms</Body2>
                        <p>
                          Use these employee enrollment forms to collect your employees’ benefit
                          elections.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["enrollment-forms"] ?? 0}{" "}
                          {pluralize("resource", amountOfItemsPerCategory["enrollment-forms"] ?? 0)}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {benefitClips.length > 0 && (
                    <Link to={RouteData.resourcesListing.getPath(clientId) + "?benefitClips=true"}>
                      <HubCard grow={true}>
                        <Body2 blueMedium>Benefit Video Clips</Body2>
                        <p>
                          Share these short videos that describe how benefits work and how employees
                          can evaluate if they’re right for them.
                        </p>
                        <Body3 colorSecondary>
                          {benefitClips.length} {pluralize("resource", benefitClips.length)}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {basicEnrollmentFlyers.length > 0 && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) + "?basicEnrollmentFlyers=true"
                      }
                    >
                      <HubCard grow={true}>
                        <div>
                          <Body2 blueMedium>Basic Enrollment Flyers</Body2>
                          <p>
                            Help get the word out about enrollment and the benefits you are
                            offering.
                          </p>
                        </div>
                        <Body3 colorSecondary>
                          {basicEnrollmentFlyers.length}{" "}
                          {pluralize("resource", basicEnrollmentFlyers.length)} available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("id-cards-and-online-access") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) +
                        "?id-cards-and-online-access=true"
                      }
                    >
                      <HubCard data-testid="id-cards-and-online-access" grow={true}>
                        <Body2 blueMedium> ID Cards and Online Access</Body2>
                        <p>
                          Show your employees how to access their member portal to see their
                          benefits, get ID cards and view or file claims.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["id-cards-and-online-access"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["id-cards-and-online-access"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("custom-enrollment-materials") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) +
                        "?custom-enrollment-materials=true"
                      }
                    >
                      <HubCard data-testid="custom-enrollment-materials" grow={true}>
                        <Body2 blueMedium>Custom Resources</Body2>
                        <p>
                          Sun Life has added the custom materials that you requested. If you have
                          any questions on these, please reach out to your VSM directly.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["custom-enrollment-materials"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["custom-enrollment-materials"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("value-added-services") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) + "?value-added-services=true"
                      }
                    >
                      <HubCard data-testid="value-added-services" grow={true}>
                        <Body2 blueMedium>Value-added Services & EAP</Body2>
                        <p>
                          Flyers to share with employees to help them learn more about additional
                          benefits included with your policy.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["value-added-services"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["value-added-services"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}

                  {has("group-life-accelerated-benefits") && (
                    <Link
                      to={
                        RouteData.resourcesListing.getPath(clientId) +
                        "?group-life-accelerated-benefits=true"
                      }
                    >
                      <HubCard data-testid="group-life-accelerated-benefits" grow={true}>
                        <Body2 blueMedium>Group Life Accelerated Benefits</Body2>
                        <p>
                          Get information for your employees about receiving partial payment of
                          group life death benefits in advance.
                        </p>
                        <Body3 colorSecondary>
                          {amountOfItemsPerCategory["group-life-accelerated-benefits"] ?? 0}{" "}
                          {pluralize(
                            "resource",
                            amountOfItemsPerCategory["group-life-accelerated-benefits"] ?? 0,
                          )}{" "}
                          available
                        </Body3>
                      </HubCard>
                    </Link>
                  )}
                </div>
              </section>

              <section id="browse-by-benefit-type">
                <div className={styles.twoColumns}>
                  <h2>Browse by benefit type</h2>

                  <Button size="xtra-small" to={RouteData.resourcesListing.getPath(clientId)}>
                    View all resources
                  </Button>
                </div>
                <hr className="mt-0" />

                <div className={styles.cardContainer}>
                  {documentBenefitTypes
                    .slice()
                    .sort()
                    .map(
                      (b) =>
                        (amountOfItemsPerBenefitType[b] || false) && (
                          <Link
                            key={b}
                            to={RouteData.resourcesListing.getPath(clientId) + `?${b}=true`}
                          >
                            <HubCard data-testid={b} grow={true}>
                              <div>
                                <Body2 blueMedium>{documentBenefitTypesNames[b]}</Body2>
                              </div>
                              <Body3 colorSecondary>
                                {amountOfItemsPerBenefitType[b] || 0}{" "}
                                {pluralize("resource", amountOfItemsPerBenefitType[b] || 0)}{" "}
                                available
                              </Body3>
                            </HubCard>
                          </Link>
                        ),
                    )}
                </div>
              </section>
            </>
          )}
        </div>
      </div>

      <RequestFreeExpertHelpModal
        isOpen={requestFreeExpertHelpIsOpen}
        client={client}
        updateClient={updateClient}
        closeModal={toggleRequestFreeExpertHelpModal}
        track={track}
      />
    </>
  );
}

function BenefitSummariesCard(props: { children: ReactNode }) {
  const { children } = props;
  return (
    <HubCard data-testid="benefit-summaries" grow={true}>
      <Body2 blueMedium>Benefit Summaries</Body2>
      <p>
        Custom-made benefit summaries that include specific coverage options unique to your policies
        with Sun Life.
      </p>
      <div>{children}</div>
    </HubCard>
  );
}
