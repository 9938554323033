import type { ClientId } from "./Client";
import type { DocumentBenefitType } from "./DocumentBenefitType";
import type { User } from "./User";

export type WebLinkId = string;
export interface WebLink {
  id: WebLinkId;
  clientId: ClientId;
  category: WebLinkCategory;
  policyIds?: undefined;
  benefitTypes: DocumentBenefitType[] | null;
  text: string;
  url: string;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  deletedBy: string | null;
  deletedAt: Date | null;
  createdByUser: User | null;
  updatedByUser: User | null;
  deletedByUser: User | null;
}

export const WebLinkCategories = [
  "benefit-rate-sheets",
  "benefit-summaries",
  "enrollment-forms",
  "id-cards-and-online-access",
  "custom-enrollment-materials",
  "value-added-services",
] as const;

export type WebLinkCategory = (typeof WebLinkCategories)[number];

export const webLinkCategoryNameByCategoryType: Record<WebLinkCategory, string> = {
  "benefit-rate-sheets": "Benefit Rate Sheets",
  "benefit-summaries": "Benefit Summaries",
  "enrollment-forms": "Enrollment Forms",
  "custom-enrollment-materials": "Custom Resources",
  "id-cards-and-online-access": "ID Cards and Online Access",
  "value-added-services": "Value-added Services & EAP",
};
