import { Back } from "../Back/Back";
import { OverlappingCircles } from "../Circle/OverlappingCircles";
import { StackY } from "../Spacing/Spacing";
import { H1 } from "../Typography/Typography";

import * as styles from "./navigationHeader.module.less";

type NavigationHeaderProps = {
  backTo: string | null;
  hideBack?: boolean;
  title: string;
};

export const NavigationHeader = ({ backTo, hideBack, title }: NavigationHeaderProps) => {
  return (
    <div className={styles.wrapper}>
      <OverlappingCircles side="right" className="mr-128" />

      <StackY dist={8} className={styles.content}>
        {!hideBack && (
          <Back to={backTo} variant="yellow">
            Back
          </Back>
        )}

        <H1 white>{title}</H1>
      </StackY>
    </div>
  );
};
