import { noop } from "lodash";
import { useRef, useState } from "react";
import type { LocationModalProps } from "./LocationModal";
import type { Client } from "shared/types/Client";
import type { Location } from "shared/types/Location";

type OnLocationModalSaveCallback = (location: Location) => void;

export const useLocationModal = (clientId: Client["id"]) => {
  const ref = useRef<OnLocationModalSaveCallback>(noop);
  const [modalProps, setModalProps] = useState<LocationModalProps>({
    clientId,
    visible: false,
    onCancel: () => hideLocationModal(),
    onSave: (location) => handleLocationModalSaved(location),
  });

  const showLocationModal = (location?: Location) => {
    setModalProps({ ...modalProps, location, visible: true });
  };

  const hideLocationModal = () => {
    setModalProps({ ...modalProps, location: undefined, visible: false });
  };

  const onLocationModalSave = (callback: OnLocationModalSaveCallback) => {
    ref.current = callback;
  };

  const handleLocationModalSaved: LocationModalProps["onSave"] = (location) => {
    hideLocationModal();
    ref.current?.(location);
  };

  return [showLocationModal, hideLocationModal, onLocationModalSave, modalProps] as const;
};
