import { Image } from "antd";

import { StackY } from "client/src/components/Spacing/Spacing";
import { LoadingError } from "../../../components/Error/LoadingError";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { Spinner } from "../../../components/Spinner/Spinner";
import { useGetDocumentDownloadUrl, useGetDocuments } from "../../../hooks/document";
import { DocumentUploadContent } from "../../Document/DocumentUpload/DocumentUpload";

import * as styles from "./addClientLogo.module.less";

import type { ClientId } from "../../../../../shared/types/Client";
import type { DocumentCategory } from "../../../../../shared/types/Document";

export const DataTestId = {
  AddClientWrapper: "add-client-wrapper",
};

export type AddClientLogoProps = {
  clientId: ClientId;
  dragEnabled?: boolean;
  logoDocumentId: string | null;
  disabled?: boolean;
  onChange?: (documentId: string | null) => void;
};

export const AddClientLogo = ({
  clientId,
  dragEnabled = true,
  logoDocumentId,
  disabled = false,
  onChange,
}: AddClientLogoProps) => {
  const category: DocumentCategory = "client-logo";
  const {
    isFetching,
    error,
    data: documents,
  } = useGetDocuments({ clientId, categories: [category] });

  const { data: documentURL } = useGetDocumentDownloadUrl(clientId, logoDocumentId);

  if (error || (!isFetching && !documents)) {
    return (
      <div className={styles.errorWrapper}>
        <LoadingError type="component" />
      </div>
    );
  }

  return (
    <div className={styles.addClientLogo} data-testid={DataTestId.AddClientWrapper}>
      {isFetching && !documents ? (
        <Spinner size="small" />
      ) : (
        <div className={styles.content}>
          <div className={styles.clientLogoWrapper}>
            {documentURL && (
              <Image
                style={{ maxWidth: "350px", maxHeight: "150px" }}
                src={documentURL?.objectUrl}
                alt="Client Logo"
              />
            )}
          </div>
          <StackY dist={24} style={{ width: "100%" }}>
            <SlobSelect
              name="logoDocumentId"
              showRequired={false}
              value={logoDocumentId}
              options={documents?.map((d) => ({ label: d.name, value: d.id })) ?? []}
              label="Logo"
              placeholder="Select Logo"
              disabled={disabled}
              onChange={(val) => onChange?.(val?.value)}
              onClear={() => onChange?.(null)}
              fillWidth
              allowClear
              touched={undefined}
              error={undefined}
            />

            <DocumentUploadContent
              clientId={clientId}
              category={category}
              showCancelButton={false}
              hideUploadButtonInitially
              dragEnabled={dragEnabled}
              disabled={disabled}
              track={() => null}
              onUpload={(documents) =>
                documents.length && documents[0] && onChange?.(documents[0].id)
              }
            />
          </StackY>
        </div>
      )}
    </div>
  );
};
