import { SlobList } from "client/src/components/SlobList/SlobList";
import { EmailViewHeader } from "client/src/domain/Email/EmailPageTitle";
import { useGetAllEmails } from "client/src/hooks/email";
import { useListParams } from "client/src/hooks/useListParams";
import { EmailTable } from "client/src/pages/EmailsPage/EmailTable";
import { useMemo } from "react";
import { useEmailTableFilters } from "../../hooks/useEmailTableFilters";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client?: Client;
  initialPageSize: number;
  featureToggles?: ClientFeatureToggles;
};

export function EmailsList(props: Props) {
  const { client, initialPageSize, featureToggles } = props;

  const EmailTableTitle = useMemo(() => getEmailTableTitle(client), [client]);

  const filters = useEmailTableFilters();

  const clientId = client?.id;

  const listParams = useListParams({
    initialSort: { direction: "desc", sortBy: "createdAt" },
    initialPageSize,
  });

  const query = useGetAllEmails({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...(clientId && { clientId }),
    ...filters,
  });

  return (
    <SlobList
      TableComponent={EmailTable}
      LeftHeader={EmailTableTitle}
      query={query}
      filter={filters}
      listParams={listParams}
      showSearch={false}
      client={client}
      featureToggles={featureToggles}
    />
  );
}

function getEmailTableTitle(client: Client | undefined) {
  return function EmailTableTitle() {
    return <EmailViewHeader client={client} />;
  };
}
