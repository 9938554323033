import { Typography, Divider } from "antd";
import { StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import { useState } from "react";
import { getIsBenAdmin, getIsBroker, getIsInternalUser } from "shared/rbac/rbac";
import { BrokerCreateValidationSchema } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { genericErrorCopy } from "../../../components/Error/ErrorMessage";
import { Checkbox } from "../../../components/Form/Checkbox";
import { H2 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useCreateBroker, checkExistingUser, useChangeBenAdminToBroker } from "../../../hooks/user";

import { BrokerSearchAndAssign } from "./BrokerSearchAndAssign";
import { ConfirmBenAdminToBrokerModal } from "./ConfirmBenAdminToBrokerModal";
import { UserForm } from "./UserForm";
import * as styles from "./form.module.less";

import type { Client } from "shared/types/Client";

const { Text } = Typography;

type BrokerCreateFormProps = {
  handleClose: () => void;
  client: Client;
  showWelcomeEmail?: boolean;
  onChange?: () => void;
  sendWelcomeEmail: boolean;
};

export const BrokerCreateForm = ({
  handleClose,
  client,
  showWelcomeEmail = true,
  onChange,
  sendWelcomeEmail,
}: BrokerCreateFormProps) => {
  const [clientError, setClientError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showBenAdminToBrokerConfirmation, setShowBenAdminToBrokerConfirmation] = useState(false);
  const [existingUserId, setExistingUserId] = useState("");
  const [existingUserClientName, setExistingUserClientName] = useState("");
  const { mutateAsync: createBroker } = useCreateBroker();
  const { mutateAsync: changeBenAdminToBroker } = useChangeBenAdminToBroker();

  const resetForm = () => {
    formik.resetForm();
    setClientError("");
  };

  const onConfirmBenAdminToBroker = async () => {
    setIsProcessing(true);
    const hide = slobMessage.loading("Changing to Broker", 0);
    setShowBenAdminToBrokerConfirmation(false);
    try {
      await changeBenAdminToBroker({
        params: { userId: existingUserId },
      });
      resetForm();
      handleClose();
      onChange?.();
      return void slobMessage.success("Successfully changed user to Broker");
    } catch (error) {
      const responseError = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy);
      setClientError(responseError);
    } finally {
      setIsProcessing(false);
      hide();
    }
  };

  const formik = useSlobFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      sendWelcomeEmail: false,
      clientIds: [client.id],
    },
    validationSchema: BrokerCreateValidationSchema,
    async onSubmit(values) {
      try {
        setClientError("");
        setExistingUserId("");
        const response = await checkExistingUser(values.email);
        const existingUser = response.data;
        if (!existingUser || getIsBroker(existingUser)) {
          await createBroker({ data: values });
          resetForm();
          onChange?.();
          handleClose();
          return void slobMessage.success("Successfully created Broker");
        }
        if (getIsInternalUser(existingUser)) {
          return setClientError(
            "An internal user already exists with this email and cannot be added.",
          );
        }
        if (getIsBenAdmin(existingUser)) {
          setExistingUserId(existingUser.id);
          setExistingUserClientName(
            existingUser.clients.length && existingUser.clients[0]
              ? existingUser.clients[0].name
              : "",
          );
          setShowBenAdminToBrokerConfirmation(true);
        }
      } catch (error) {
        const responseError = ResponseError.getUserFacingErrorMessage(error, genericErrorCopy);
        setClientError(responseError);
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <H2 as="h1">Add Broker</H2>
      <h3>Assign existing Broker</h3>
      <BrokerSearchAndAssign
        onAssign={() => {
          resetForm();
          handleClose();
        }}
        disabled={formik.isSubmitting}
        onError={setClientError}
        onProcess={setIsProcessing}
        onChange={onChange}
        clientId={client.id}
        sendWelcomeEmail={sendWelcomeEmail}
      />
      <Divider />
      <h3>Add new Broker</h3>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={16}>
          <UserForm formik={formik} />
          {showWelcomeEmail && (
            <>
              <span className={styles.tip}>
                Tip: Send a welcome email to provide the account activation or prompt to sign in for
                this Client.
              </span>
              <Checkbox
                label="Send welcome email"
                name="sendWelcomeEmail"
                checked={formik.values.sendWelcomeEmail}
                onChange={formik.handleChange}
              />
            </>
          )}
          {clientError && (
            <Text type="danger" className={styles.errorMessage}>
              {clientError}
            </Text>
          )}
          <div className={clsx(styles.footer, "mt-16")}>
            <Button
              type="primary"
              htmlType="submit"
              loading={formik.isSubmitting}
              disabled={isProcessing || !formik.dirty || !formik.isValid || formik.isSubmitting}
            >
              Add and Assign
            </Button>
          </div>
          {formik.status && <Text type="danger">{formik.status}</Text>}
        </StackY>
      </form>
      <ConfirmBenAdminToBrokerModal
        clientName={existingUserClientName}
        isVisible={showBenAdminToBrokerConfirmation}
        isLoading={isProcessing}
        onConfirm={onConfirmBenAdminToBroker}
        onCancel={() => {
          setShowBenAdminToBrokerConfirmation(false);
          resetForm();
          handleClose();
        }}
      />
    </div>
  );
};
