import { Row, Col } from "client/src/components/Grid/Grid";

import { H3 } from "../../../../components/Typography/Typography";
import { useClientHubParams } from "../../../../hooks/useClientHubParams";
import { useHubConfiguration } from "../../../../hooks/useConfig";
import { useTranslation } from "../../../../i18n";
import { useVidyardRerenderer } from "../../../../utils/vidyard";

export const DataTestId = {
  BenefitVideo: "benefit-custom-medical-video",
};

type ExplorerBenefitCustomMedicalVideoProps = {
  videoID: string;
  englishTitle: string;
  spanishTitle?: string;
};

export const ExplorerBenefitCustomMedicalVideo = ({
  videoID,
  englishTitle,
  spanishTitle,
}: ExplorerBenefitCustomMedicalVideoProps) => {
  const { explorerPageId } = useClientHubParams(["explorerPageId"]);
  const { i18n } = useTranslation();

  const { ENV } = useHubConfiguration();

  const anonymousId = window.analytics?.user?.().anonymousId();

  // render DOM player after the language is toggled
  useVidyardRerenderer();

  return (
    <div data-testid={DataTestId.BenefitVideo}>
      <div style={{ position: "relative" }} key={videoID}>
        <img
          style={{ maxWidth: "100%" }}
          className="vidyard-player-embed"
          src={`https://play.vidyard.com/${videoID}.jpg`}
          data-uuid={videoID}
          data-v="4"
          data-type="inline"
          data-vydata={encodeURIComponent(
            JSON.stringify({ explorerPageId, ENV, anonymousId, benefitType: "MEDICAL" }),
          )}
          alt={`MEDICAL video`}
        />
      </div>
      <Row justify="center" className="mt-12">
        <Col style={{ textAlign: "center" }}>
          <H3 style={{ marginBottom: "0" }}>
            {i18n.language === "es" ? spanishTitle || englishTitle : englishTitle}
          </H3>
        </Col>
      </Row>
    </div>
  );
};
