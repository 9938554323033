import { Button } from "client/src/components/Button/Button";

import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { EIFClassBuilderWaitingPeriods } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/WaitingPeriods/EIFClassBuilderWaitingPeriods";
import { getAvailableEIFClassBuilderSections } from "client/src/domain/EIF/common/utils/getAvailableEIFClassBuilderSections";
import { AutoSaveContext } from "client/src/hooks/AutoSaveOnNavigation";
import { ResponseError } from "client/src/hooks/query";
import { getObjectFromURLSearchParams } from "client/src/utils/url";
import clsx from "clsx";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { isBenefitTypeEIF } from "shared/types/BenefitTypes";
import {
  getIsFLICoverage,
  getIsPFMLCoverage,
  getIsStatutoryCoverage,
} from "shared/types/SlfCoverages";
import {
  getEmployeeClassSectionCompletionStatus,
  EIFClassBuilderSections,
  getPlanConfigCompletionStatus,
} from "shared/utils/EIF/getEIFStepCompleteStatus";

import { HubCard } from "../../../../components/HubCard/HubCard";
import { StackY } from "../../../../components/Spacing/Spacing";

import { useQueryString } from "../../../../hooks/useQueryString";
import { EIFClassBuilderContributions } from "./Contributions/EIFClassBuilderContributions";
import { EIFClassBuilderBenefits } from "./EIFClassBuilderBenefits";
import { EIFClassBuilderDetails } from "./EIFClassBuilderDetails";
import { EIFClassBuilderEarnings } from "./EIFClassBuilderEarnings";
import { EIFClassBuilderEligibility } from "./EIFClassBuilderEligibility";
import { NoEmployeeClassPlansMessage } from "./NoEmployeeClassPlansMessage";
import * as styles from "./eifClassBuilder.module.less";
import type { CreateClassQuery, UpdateClassQuery } from "../../../../hooks/employeeClass";
import type {
  SetContributionQuery,
  SetBenefitTypeAvailablePlansQuery,
  SetWaitingPeriodsQuery,
} from "../../../../hooks/employeeClassPlan";
import type { TrackElementClickedFunc } from "../../../../utils/analytics";
import type { AdditionalCompensation } from "@prisma/client";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFClassBuilderSection } from "shared/utils/EIF/getEIFStepCompleteStatus";

type Props = {
  client: Client;
  clientPlans: Plan[];
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  classes: EmployeeClass[];
  employeeClass: EmployeeClass | undefined;
  additionalCompensations: AdditionalCompensation[];
  setBenefitTypesAvailablePlansQuery: SetBenefitTypeAvailablePlansQuery;
  setWaitingPeriodsQuery: SetWaitingPeriodsQuery;
  setContributionsQuery: SetContributionQuery;
  trackElementClicked: TrackElementClickedFunc;
  employeeClassDocumentCount: number;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
};

export type SectionTracker = (buttonLabel: string) => void;

export function EIFClassBuilderCreator(props: Props) {
  const {
    client,
    clientPlans,
    createClassQuery,
    updateClassQuery,
    classes,
    employeeClass,
    additionalCompensations,
    setBenefitTypesAvailablePlansQuery,
    setWaitingPeriodsQuery,
    setContributionsQuery,
    trackElementClicked,
    employeeClassDocumentCount,
    featureToggles,
    changeSnapshot,
    authUser,
  } = props;

  const employeeClassPlans = employeeClass?.employeeClassPlans;

  const navigate = useNavigate();
  const queryString = useQueryString();
  const activeSection = getSectionFromQS(queryString);
  const activeBenefitType = getActiveBenefitTypeFromQS(queryString);
  const activeBenefitTypes = getActiveBenefitTypesFromQS(queryString);

  const singleClassBuilderMode = client.needsEmployeeClasses === "NO";

  const clientPlansBenefitTypes = new Set(clientPlans.map((p) => p.benefitType));

  const availableSections = getAvailableEIFClassBuilderSections(
    singleClassBuilderMode,
    clientPlansBenefitTypes,
    employeeClass,
  );

  const isThereANextSection = client.allPoliciesSlfCoverages?.some((coverage) => {
    return (
      (getIsPFMLCoverage(coverage) ||
        (featureToggles.ONBOARD_DSF_STATUTORY && getIsStatutoryCoverage(coverage)) ||
        getIsFLICoverage(coverage)) ??
      false
    );
  });

  function getSectionTracker(section: EIFClassBuilderSection): SectionTracker {
    return function trackSection(buttonLabel) {
      const sectionStatus = getPlanConfigCompletionStatus(
        client,
        classes,
        employeeClassDocumentCount,
        clientPlans,
        featureToggles,
        changeSnapshot,
      );

      trackElementClicked({
        module: `Class Builder - ${section}`,
        buttonLabel,
        moduleState: sectionStatus,
      });
    };
  }

  function goToNextSection(employeeClass: EmployeeClass) {
    const nextPath = RouteData.eifClassBuilderEdit.getPath(
      client.id,
      "plan-configuration-&-eligibility",
      "class-builder",
      employeeClass.id,
    );

    const currentWindowLocationURLSearch = new URLSearchParams(window.location.search);
    const differentSections = activeSection !== currentWindowLocationURLSearch.get("section");
    const differentBenefitTypes =
      activeBenefitType !== getActiveBenefitTypeFromQS(currentWindowLocationURLSearch);
    const isSavedViaAutoSave = differentSections || differentBenefitTypes;

    if (!isSavedViaAutoSave) {
      navigate(nextPath);
    }
  }

  const isLoading =
    createClassQuery.isPending ||
    updateClassQuery.isPending ||
    setBenefitTypesAvailablePlansQuery.isPending ||
    setWaitingPeriodsQuery.isPending ||
    setContributionsQuery.isPending;

  const [canceledAutoSave, cancelAutoSave] = useReducer(() => true, false);

  return (
    <>
      {!singleClassBuilderMode && <h2 className="mb-40">Create an eligible employee group</h2>}

      <StackY dist={32}>
        <HubCard>
          <StackY dist={32} wrap={false}>
            <AutoSaveContext.Provider value={{ canceledAutoSave }}>
              {!singleClassBuilderMode && (
                <section className={styles.cbSection}>
                  <EIFClassBuilderEligibility
                    isActive={activeSection === "Eligibility"}
                    client={client}
                    createClassQuery={createClassQuery}
                    updateClassQuery={updateClassQuery}
                    employeeClass={employeeClass}
                    changeSnapshot={changeSnapshot}
                    onSave={goToNextSection}
                    isLoading={isLoading}
                    track={getSectionTracker("Eligibility")}
                    authUser={authUser}
                  />
                </section>
              )}
              <section className={clsx(styles.cbSection)}>
                <EIFClassBuilderDetails
                  isActive={activeSection === "Details"}
                  singleClassBuilderMode={singleClassBuilderMode}
                  client={client}
                  createClassQuery={createClassQuery}
                  updateClassQuery={updateClassQuery}
                  employeeClass={employeeClass}
                  onSave={goToNextSection}
                  isLoading={isLoading}
                  changeSnapshot={changeSnapshot}
                  featureToggles={featureToggles}
                  track={getSectionTracker("Details")}
                  authUser={authUser}
                />
              </section>
              {!singleClassBuilderMode && clientPlansBenefitTypes.size > 1 && (
                <section className={clsx(styles.cbSection)}>
                  <EIFClassBuilderBenefits
                    isActive={activeSection === "Benefits"}
                    client={client}
                    clientPlans={clientPlans}
                    createClassQuery={createClassQuery}
                    employeeClass={employeeClass}
                    setBenefitTypesAvailablePlansQuery={setBenefitTypesAvailablePlansQuery}
                    onSave={goToNextSection}
                    isLoading={isLoading}
                    changeSnapshot={changeSnapshot}
                    track={getSectionTracker("Benefits")}
                    authUser={authUser}
                  />
                </section>
              )}

              {availableSections.includes("Waiting Periods") && (
                <section className={clsx(styles.cbSection)}>
                  <EIFClassBuilderWaitingPeriods
                    isActive={activeSection === "Waiting Periods"}
                    activeBenefitTypes={activeBenefitTypes}
                    client={client}
                    clientPlans={clientPlans}
                    employeeClass={employeeClass}
                    setWaitingPeriodsQuery={setWaitingPeriodsQuery}
                    isLoading={isLoading}
                    track={getSectionTracker("Waiting Periods")}
                    changeSnapshot={changeSnapshot}
                    authUser={authUser}
                    featureToggles={featureToggles}
                  />
                </section>
              )}

              <section
                className={clsx(availableSections.includes("Contributions") && styles.cbSection)}
              >
                {employeeClass &&
                employeeClassPlans &&
                employeeClassPlans.length > 0 &&
                availableSections.includes("Contributions") ? (
                  <EIFClassBuilderContributions
                    isActive={activeSection === "Contributions"}
                    employeeClass={employeeClass}
                    client={client}
                    setContributionsQuery={setContributionsQuery}
                    isLoading={isLoading}
                    sectionTracker={getSectionTracker("Contributions")}
                    changeSnapshot={changeSnapshot}
                    authUser={authUser}
                    activeBenefitType={activeBenefitType}
                    goToNextSection={goToNextSection}
                    readonly={false}
                    featureToggles={featureToggles}
                  />
                ) : (
                  <>
                    {!(employeeClass && employeeClassPlans && employeeClassPlans.length > 0) ? (
                      <NoEmployeeClassPlansMessage
                        title="Contributions"
                        userRole={authUser.role}
                        clientPlans={clientPlans}
                        clientId={client.id}
                      />
                    ) : null}
                  </>
                )}
              </section>

              {availableSections.includes("Earnings definition") && employeeClass && (
                <section className={clsx(styles.cbSection)}>
                  <EIFClassBuilderEarnings
                    isActive={activeSection === "Earnings definition"}
                    client={client}
                    classes={classes}
                    employeeClass={employeeClass}
                    additionalCompensations={additionalCompensations}
                    changeSnapshot={changeSnapshot}
                    updateClassQuery={updateClassQuery}
                    onSave={goToNextSection}
                    featureToggles={featureToggles}
                    track={getSectionTracker("Contributions")}
                    isLoading={isLoading}
                    authUser={authUser}
                  />
                </section>
              )}
            </AutoSaveContext.Provider>
          </StackY>
        </HubCard>

        <div aria-live="assertive" className="hide:empty">
          {createClassQuery.error &&
            (activeSection === "Eligibility" || activeSection === "Benefits") && (
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(createClassQuery.error, GenericErrorCopy2)}
              </ErrorMessage>
            )}
        </div>

        {isThereANextSection ? (
          <Row justify="space-between" align="middle">
            <Col>
              <Button
                to={RouteData.eifSubStepDetail.getPath(
                  client.id,
                  "plan-configuration-&-eligibility",
                  "class-builder",
                )}
                type="text-only"
                size="large"
                disabled={isLoading}
                onClick={cancelAutoSave}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                to={RouteData.eifSubStepDetail.getPath(
                  client.id,
                  "plan-configuration-&-eligibility",
                  "class-builder",
                )}
                type="primary"
                size="large"
                disabled={isLoading || employeeClass == null}
              >
                Next
              </Button>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            <Col>
              <Button
                to={RouteData.eifStepDetail.getPath(client.id, "plan-configuration-&-eligibility")}
                type="primary"
                disabled={isLoading}
              >
                Done
              </Button>
            </Col>
          </Row>
        )}
      </StackY>
    </>
  );
}

function getSectionFromQS(qs: URLSearchParams): EIFClassBuilderSection | undefined {
  const activeSection = qs.get("section");
  return isSection(activeSection) ? activeSection : undefined;
}

function getActiveBenefitTypeFromQS(qs: URLSearchParams): BenefitTypeEIF | null {
  const activeBenefitType = qs.get("benefitType") ?? "";
  return isBenefitTypeEIF(activeBenefitType) ? activeBenefitType : null;
}

function getActiveBenefitTypesFromQS(qs: URLSearchParams): BenefitTypeEIF[] | null {
  const searchObject = getObjectFromURLSearchParams(qs);
  if ("benefitTypes" in searchObject) {
    const benefitTypesRaw = searchObject["benefitTypes"];
    if (typeof benefitTypesRaw === "string" && isBenefitTypeEIF(benefitTypesRaw)) {
      return [benefitTypesRaw];
    } else if (Array.isArray(benefitTypesRaw)) {
      const benefitTypes: BenefitTypeEIF[] = [];
      for (const benefitTypeRaw of benefitTypesRaw) {
        if (isBenefitTypeEIF(benefitTypeRaw)) {
          benefitTypes.push(benefitTypeRaw);
        }
      }
      return benefitTypes.length > 0 ? benefitTypes : null;
    } else {
      return null;
    }
  } else {
    return null;
  }
}

function getNextSection(
  availableSections: EIFClassBuilderSection[],
  currentSection: EIFClassBuilderSection | undefined,
): EIFClassBuilderSection | undefined {
  const currentSectionIndex = availableSections.findIndex((s) => s === currentSection);
  const nextSection = availableSections[currentSectionIndex + 1];
  return nextSection;
}

function isSection(str: string | null | undefined): str is EIFClassBuilderSection {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  const isSection = EIFClassBuilderSections.includes(str as EIFClassBuilderSection);
  return isSection;
}

export function getNextSectionPath(
  client: Client,
  employeeClass: EmployeeClass,
  singleClassBuilderMode: boolean,
  availableSections: EIFClassBuilderSection[],
  currentSection: EIFClassBuilderSection | undefined,
  featureToggles: ClientFeatureToggles,
): string {
  const nextSection = getNextSection(availableSections, currentSection);

  if (nextSection) {
    const status = getEmployeeClassSectionCompletionStatus(
      client,
      employeeClass,
      singleClassBuilderMode,
      nextSection,
    );
    const isNextSectionCompleted = status === "Completed";

    if (isNextSectionCompleted) {
      const nextPath = getNextSectionPath(
        client,
        employeeClass,
        singleClassBuilderMode,
        availableSections,
        nextSection,
        featureToggles,
      );
      return nextPath;
    } else {
      const nextQS = new URLSearchParams([["section", nextSection]]);
      const nextPath =
        RouteData.eifClassBuilderEdit.getPath(
          client.id,
          "plan-configuration-&-eligibility",
          "class-builder",
          employeeClass.id,
        ) + `?${nextQS.toString()}`;
      return nextPath;
    }
  } else {
    const nextPath = RouteData.eifClassBuilderEdit.getPath(
      client.id,
      "plan-configuration-&-eligibility",
      "class-builder",
      employeeClass.id,
    );
    return nextPath;
  }
}
