import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ResponseError } from "client/src/hooks/query";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import type { EmailId } from "../../../../shared/types/Email";
import type { ResendEmailFunc } from "client/src/hooks/email";

type Props = {
  emailId: EmailId;
  isVisible: boolean;
  onCancel: () => void;
  resendEmail: ResendEmailFunc;
  isPendingResendEmail: boolean;
  resendEmailError: ResponseError | null;
};

export function ConfirmDialogResendEmail(props: Props) {
  const {
    emailId,
    isVisible,
    onCancel,
    resendEmail,
    isPendingResendEmail: isLoading,
    resendEmailError: error,
  } = props;

  const { clientId } = useClientHubParams();

  const navigate = useNavigate();

  const errorMessage = error
    ? ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)
    : null;

  return (
    <ConfirmDialog
      title="Resend email"
      isVisible={isVisible}
      onCancel={onCancel}
      isLoading={isLoading}
      cancelActionText="Cancel"
      confirmActionText="Resend email"
      onConfirm={async () => {
        try {
          const { data: newEmailId } = await resendEmail({ params: { emailId } });
          void slobMessage.success("Email resent successfully");
          if (clientId) {
            navigate(RouteData.emailClient.getPath(clientId, newEmailId));
          } else {
            navigate(RouteData.email.getPath(newEmailId));
          }
          onCancel();
        } catch (_err) {
          // already handled via consumption of mutation result above
        }
      }}
    >
      <>
        <p>Are you sure you want to resend this email?</p>

        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </>
    </ConfirmDialog>
  );
}
