import type { UserRole } from "@prisma/client";

export const getRoleName = (role?: UserRole) => {
  switch (role) {
    case "SLF_OWNER":
      return "Owner";
    case "SLF_ADMIN":
      return "Admin";
    case "BROKER":
      return "Broker";
    case "BEN_ADMIN":
      return "Benefits Administrator";
    default:
      return null;
  }
};
