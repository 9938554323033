import { Badge } from "./Badge";

import type { Variant } from "./Badge";
import type { User } from "shared/types/User";

export type PopoverBadgeProps = {
  "aria-label": string;
  updatedBy: User | null | undefined;
  updatedAt: Date | null | undefined;
  variant: Variant;
  status: string;
  loading?: boolean;
  error?: unknown;
  message?: React.ReactNode;
  showStaticSpinner?: boolean;
};

// TODO: Remove this component and use Badge directly
export function PopoverBadge({
  "aria-label": ariaLabel,
  updatedBy,
  status,
  updatedAt,
  variant,
  loading,
  error,
  message,
  showStaticSpinner,
}: PopoverBadgeProps) {
  return (
    <Badge
      srOnlyLabel={ariaLabel}
      variant={variant}
      status={status}
      message={message}
      showPopover
      popoverUpdatedBy={updatedBy}
      popoverUpdatedAt={updatedAt}
      popoverLoading={loading}
      popoverError={error}
      showStaticSpinner={showStaticSpinner}
    />
  );
}
