import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { RouteData } from "../../../../../../shared/config/routeData";
import { AlertBanner } from "../../../../components/Banner/AlertBanner";
import { Body3 } from "../../../../components/Typography/Typography";
import type { ClientId } from "../../../../../../shared/types/Client";

type Props = {
  clientId: ClientId;
};

export const EIFClassBuilderNoActionNeeded = ({ clientId }: Props) => {
  return (
    <Row justify="center">
      <Col span={18}>
        <h1>Tell us about benefit eligibility and employee groups</h1>

        <p>
          In this section you’ll tell us which employees are eligible for which benefits, what their
          waiting periods will be, and how much premium you will contribute towards the cost of
          their benefits.
        </p>

        <AlertBanner
          variant="warning"
          message={
            <Body3>
              No action is needed from you for plan configuration & eligibility at this time. Your
              Sun Life implementation consultant will reach out to assist with completing this
              section.
            </Body3>
          }
        />

        <Row justify="center" className="mt-32">
          <Col>
            <Button
              type="primary"
              size="large"
              to={RouteData.clientTaskDetail.getPath(clientId, "eif-submit-company-information")}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
