import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Collapsible from "@radix-ui/react-collapsible";
import clsx from "clsx";

import { useState } from "react";
import * as styles from "./collapsePanel.module.less";
import type { ReactNode } from "react";

// Component design
// https://www.figma.com/file/CxQttnhlm3UpMfawXwzP0U/SL-US-Design-System-Library?type=design&node-id=1720-14936&mode=design&t=r3mqDPIlfrUd6XoQ-0

type CollapsePanelProps = {
  variant: "default" | "zebra" | "dashed";
  content: Content[];
  activeKey?: Array<string> | string;
  onChange?: (openKeys: string[], changedKey: string) => void;
  noPaddingContent?: boolean;
};

export type Content = {
  key: string;
  title: string | React.ReactElement;
  description: string | React.ReactElement | ReactNode;
  className?: string;
};

type ExpandedPanelControl = {
  [panelName: string]: boolean;
};

export function CollapsePanel({
  content,
  onChange,
  variant,
  activeKey,
  noPaddingContent = false,
}: CollapsePanelProps) {
  const activeList = Array.isArray(activeKey) ? activeKey : [activeKey];
  const [open, setOpen] = useState<ExpandedPanelControl>(
    content.reduce<ExpandedPanelControl>(
      (o, c) => ({ ...o, [c.key]: activeList.includes(c.key) }),
      {},
    ),
  );

  const onToggle = (c: Content) => {
    const openKeys = Object.keys(open).reduce<string[]>((m, key) => {
      if (key === c.key && open[c.key] === false) m.push(key);
      if (key !== c.key && open[key]) m.push(key);
      return m;
    }, []);
    setOpen((o) => ({ ...o, [c.key]: !o[c.key] }));
    onChange?.(openKeys, c.key);
  };

  return (
    <div className={clsx(styles.collapseContainer, getClassFromVariant(variant))}>
      {content.map((c) => (
        <Collapsible.Root
          key={c.key}
          className={styles.collapseRoot}
          open={open[c.key]}
          onOpenChange={() => onToggle(c)}
        >
          <Collapsible.Trigger asChild>
            <button className={clsx(styles.header, "body1")}>
              <div className={styles.title}>{c.title}</div>
              <FontAwesomeIcon icon={open[c.key] ? faChevronUp : faChevronDown} />
            </button>
          </Collapsible.Trigger>
          <Collapsible.Content className={clsx({ [styles.content]: !noPaddingContent }, "body5")}>
            {c.description}
          </Collapsible.Content>
        </Collapsible.Root>
      ))}
    </div>
  );
}

const getClassFromVariant = (variant: CollapsePanelProps["variant"]) => {
  switch (variant) {
    default:
    case "default":
      return styles.defaultVariant;
    case "zebra":
      return styles.zebraVariant;
    case "dashed":
      return styles.dashedVariant;
  }
};
