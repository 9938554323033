import { useEffect } from "react";
import { useBlocker } from "react-router-dom";

import { ConfirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";

import type { useFormik, FormikValues } from "formik";

type UnsavedChangesModalProps = {
  hasChanges?: boolean;
  title?: string;
  extraDescription?: string;
  customBeforeUnloadListener?: (e: BeforeUnloadEvent) => string;
} & (
  | {
      form: Pick<ReturnType<typeof useFormik>, "initialValues" | "values" | "isSubmitting">;
      isSubmitting?: never;
    }
  | {
      form?: undefined;
      isSubmitting: boolean;
    }
);

const beforeUnloadListener = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  return (e.returnValue = "");
};

const useBeforeUnload = (
  unsavedChanges: boolean,
  customListener?: (e: BeforeUnloadEvent) => string,
) => {
  // Must use browser handling for page refresh
  useEffect(() => {
    if (unsavedChanges) {
      window.addEventListener("beforeunload", customListener ?? beforeUnloadListener);
    }
    return () => {
      window.removeEventListener("beforeunload", customListener ?? beforeUnloadListener);
    };
  }, [unsavedChanges, customListener]);
};

/*
  compares form values and considers null, undefined and empty string equal
*/
export const hasChanged = (from: FormikValues, to: FormikValues): boolean => {
  const hasIt = Object.entries(to).some(([key, val]) => {
    const fromValue = from[key] ?? "";
    const toValue = val ?? "";

    if (Array.isArray(fromValue) && Array.isArray(toValue)) {
      if (fromValue.length !== toValue.length) {
        return true;
      }
      const areEqual = fromValue.every((a, i) => {
        const b = toValue[i];

        if (a != null && typeof a === "object" && b != null && typeof b === "object") {
          return !hasChanged(a, b);
        }

        return a === toValue[i];
      });
      return !areEqual;
    }

    if (fromValue != null && typeof fromValue == "object") {
      const areDifferent = hasChanged(fromValue, toValue);
      return areDifferent;
    }

    return fromValue !== toValue;
  });

  return hasIt;
};

// @see https://github.com/remix-run/react-router/tree/main/examples/navigation-blocking
export const UnsavedChangesModal = ({
  form,
  hasChanges,
  title,
  extraDescription,
  customBeforeUnloadListener,
  isSubmitting: isSubmitting_,
}: UnsavedChangesModalProps) => {
  const changes = form
    ? hasChanged(form.initialValues, form.values)
    : hasChanges // form and hasChanges are both optional, one should be provided, but if neither are, don't block navigation
    ? hasChanges
    : false;

  const isSubmitting = form ? form.isSubmitting : isSubmitting_;
  const blocker = useBlocker(changes && !isSubmitting);

  // Reset the blocker if the user cleans the form
  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (blocker.state === "blocked" && !changes) {
      blocker.reset();
    }
  }, [blocker, changes]);

  useBeforeUnload(changes, customBeforeUnloadListener);

  return (
    <>
      {blocker?.state === "blocked" ? (
        <ConfirmDialog
          title={title ? title : "You have unsaved changes"}
          isVisible={true}
          confirmActionText="Yes, leave page"
          cancelActionText="No, stay on page"
          onConfirm={() => blocker.proceed?.()}
          onCancel={() => blocker.reset?.()}
        >
          <p>Navigating away from this page with unsaved changes will delete your progress.</p>
          {extraDescription && <p>{extraDescription}</p>}
          <p>Are you sure you want to leave this page?</p>
        </ConfirmDialog>
      ) : null}
    </>
  );
};

export const getHandleFormClosing = <TValues extends FormikValues>(args: {
  formik: ReturnType<typeof useFormik<TValues>>;
  onProceed: (() => void) | undefined;
}) => {
  const { formik, onProceed } = args;

  return function handleFormClosing() {
    if (formik.dirty) {
      const changed = hasChanged(formik.initialValues, formik.values);
      if (changed) {
        const proceed = window.confirm(
          "There are unsaved changes. Are you sure you want to cancel?",
        );
        if (proceed) {
          formik.resetForm();
          onProceed?.();
        }
      }
    } else {
      onProceed?.();
    }
  };
};
