import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { ReactComponent as NewTabIcon } from "client/src/components/Icons/NewTabIcon.svg";
import { InternalControl } from "client/src/components/InternalControl/InternalControl";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import { useHubConfiguration } from "client/src/hooks/useConfig";
import clsx from "clsx";
import { useEffect } from "react";
import { getIsInternalUser, type UserData } from "shared/rbac/rbac";
import { getEIFSubStepViewMode, getPrimaryPolicy } from "shared/utils/client";
import { listFormat } from "shared/utils/format";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { primaryWebAdminContactValidation } from "shared/validation/primaryWebAdminContact";
import { getEIFSubStepMap } from "../../../../../shared/types/EIF";
import { PopoverBadge } from "../../../components/Badge/PopoverBadge";
import { HubCard } from "../../../components/HubCard/HubCard";
import { HubCardHeader } from "../../../components/HubCard/HubCardHeader";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body2, H3, Body3 } from "../../../components/Typography/Typography";
import { useToggler } from "../../../hooks/useToggler";
import { ContactCard } from "../../Contact/ContactCard";
import { PlanAdminForm } from "../../Contact/PlanAdminForm";
import * as styles from "./EIFPlanAdministrators.module.less";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type EIFPrimaryPlanAdminCardProps = {
  client: Client;
  bills: Bill[];
  status: EIFStepCompleteStatus;
  locations: Location[];
  disabled: boolean;
  onToggleEdit: (editing: boolean) => void;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
};

type HeaderProps = {
  status: EIFStepCompleteStatus;
  client: Client;
};

const Header = ({ status, client }: HeaderProps) => {
  return (
    <HubCardHeader
      title=""
      badge={
        status && (
          <PopoverBadge
            aria-label="Task Status"
            status={status}
            variant={
              (
                {
                  "Not Started": "info",
                  "In Progress": "info",
                  Completed: "success",
                } as const
              )[status]
            }
            updatedAt={client.webAdminContact?.updatedAt}
            updatedBy={client.webAdminContact?.updatedByUser}
            message={
              (status === "Not Started" || status === "In Progress") && (
                <span>
                  An email from welcome@sunlifeconnect.com will be sent to your primary plan
                  administrator within 24-48 hours. The primary plan administrator will need the
                  policy number{" "}
                  <b>{listFormat(client.policies.map((p) => p.slfPolicyNumber || ""))}</b> to
                  complete Sun Life Connect registration.
                </span>
              )
            }
          />
        )
      }
    />
  );
};

export const DataTestId = {
  PrimaryPlanAdminCard: "primary-plan-admin-card",
};

const useToggleNotify = (val: boolean, onToggle?: (val: boolean) => void) =>
  useEffect(() => {
    onToggle?.(val);
  }, [val, onToggle]);

export const EIFPrimaryPlanAdminCard = ({
  client,
  bills,
  status,
  locations,
  disabled,
  onToggleEdit,
  trackElementClicked,
  authUser,
  changeSnapshot,
  featureToggles,
}: EIFPrimaryPlanAdminCardProps) => {
  const config = useHubConfiguration();
  const primaryPolicy = getPrimaryPolicy(client);
  const urlToPrimaryTicket = config.REACT_APP_JIRA_URL + `/browse/${primaryPolicy.ticketId}`;

  const isInternalUser = getIsInternalUser(authUser);

  const [editing, toggleEditing] = useToggler();

  useToggleNotify(editing, onToggleEdit);

  const eifSubStepId = "plan-administrators";

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: getEIFSubStepMap({ eifSubStepId }),
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, bills, eifSubStepId: "plan-administrators" }),
    });
  };

  const viewMode = getEIFSubStepViewMode({ client });
  const showEditBanner = getShowEditBanner(viewMode);
  const primaryWebAdminComplete = primaryWebAdminContactValidation.getIsPrimaryWebAdminValid(
    client.webAdminContact,
  );
  const baRegisteredSLConnect = Boolean(client.baRegisteredSLConnect);

  const statusToShow: EIFStepCompleteStatus | null =
    !baRegisteredSLConnect && primaryWebAdminComplete
      ? "In Progress"
      : baRegisteredSLConnect && primaryWebAdminComplete
      ? "Completed"
      : null;

  if (status === "Not Started" || editing) {
    return (
      <HubCard data-testid={DataTestId.PrimaryPlanAdminCard}>
        <div className={clsx(disabled && styles.disabled)}>
          <H3>Primary Plan Administrator</H3>
          <p>
            This is the primary contact at the employer. They'll have <Body2>full</Body2> access to
            view all employee info, administer benefits, manage billing and edit other administrator
            accounts. Enter their details to send a registration email for Sun Life Connect.
          </p>
          <PlanAdminForm
            clientId={client.id}
            bills={bills}
            contact={client.webAdminContact ?? undefined}
            allowCancel={Boolean(client.webAdminContact)}
            newAdminType="PRIMARY_WEB_ADMIN"
            locations={locations}
            onCancel={toggleEditing}
            onUpdated={() => {
              toggleEditing();
              track("Save");
            }}
            onCreated={() => track("Save")}
            client={client}
            authUser={authUser}
            changeSnapshot={changeSnapshot}
            featureToggles={featureToggles}
          />
        </div>
      </HubCard>
    );
  } else {
    return (
      <>
        {baRegisteredSLConnect && !primaryWebAdminComplete && isInternalUser && (
          <InternalControl
            data-testid={"hi"}
            position="attached"
            buttonLabel={
              <>
                <span className="mr-8">View in Jira</span>
                <span>
                  <NewTabIcon />
                </span>
              </>
            }
            buttonType="text-only"
            buttonHref={urlToPrimaryTicket}
            buttonNewTab={true}
            extraContent={
              <AlertBanner
                variant={"warning"}
                message={
                  <Body3>
                    The <Body2>BA is registered in SL Connect</Body2> field in Jira is set to{" "}
                    <Body2>Yes</Body2>, but their information below is incomplete.
                  </Body3>
                }
              />
            }
          />
        )}
        <HubCard data-testid={DataTestId.PrimaryPlanAdminCard}>
          <StackY dist={12} className={clsx(disabled && styles.disabled)}>
            <H3>Primary Plan Administrator</H3>
            {statusToShow && <Header client={client} status={statusToShow} />}
            {statusToShow !== "Completed" && (
              <p>
                Your primary plan administrator should be the person who is responsible for and will
                have full access to employee information, administer benefits and all other
                administrator accounts. Enter their information in here to send them a registration
                email for Sun Life Connect.
              </p>
            )}
            {client.webAdminContact && (
              <ContactCard
                contact={client.webAdminContact}
                bills={bills}
                allowEdit
                showEditBanner={showEditBanner}
                onEdit={toggleEditing}
                client={client}
                authUser={authUser}
                changeSnapshot={changeSnapshot}
                featureToggles={featureToggles}
              />
            )}
          </StackY>
        </HubCard>
      </>
    );
  }
};
