import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { capitalize } from "client/src/utils/string";
import { Navigate } from "react-router-dom";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getRelevantClientAgeRuleFields } from "shared/utils/EIF/getRelevantClientAgeRuleFields";
import { getShouldAskAgeQuestionForSpouseDependentLife } from "shared/utils/EIF/getShouldAskAgeQuestionForSpouseDependentLife";
import { hasNonNullValues } from "shared/utils/utils";
import { clientValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { ageForDependentLifeBenefitsText } from "../../../../shared/types/Client";
import { getEIFSubStepMap } from "../../../../shared/types/EIF";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "../../components/Typography/Typography";

import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";

import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { Client, CoverageChangesEffectiveAgeRules } from "../../../../shared/types/Client";
import type { EIFSubStepId } from "../../../../shared/types/EIF";
import type { RadioGroupProps } from "../../components/Form/RadioGroup";

import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  changeSnapshot: DEIFChangeSnapshot;
  updateClient: UpdateClientFunc;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

export function EIFAgeRules(props: Props) {
  const { client, trackElementClicked, updateClient, changeSnapshot, authUser } = props;

  const eifSubStepId: EIFSubStepId = "age-rules";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
      }),
    });
  };

  const relevantClientFields = getRelevantClientAgeRuleFields(client);

  const formKeyProps: (keyof Client)[] = relevantClientFields;
  const relevantValidationSchema = clientValidationSchema.pick(relevantClientFields);

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: relevantValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const options: RadioGroupProps<CoverageChangesEffectiveAgeRules>["options"] = [
    {
      value: "ANNUALLY_ON_POLICY_ANNIVERSARY",
      label: (
        <StackY dist={2}>
          <Body3>Annually, on the policy anniversary</Body3>
          {client.policyAnniversaryMonth && (
            <Body5>
              Your policy anniversary is <b>{capitalize(client.policyAnniversaryMonth)} 1.</b>
            </Body5>
          )}
        </StackY>
      ),
    },
    {
      value: "IMMEDIATELY",
      label: "Immediately, as of birthday",
      content: (
        <div className="ml-32">
          <Body5 as="div">
            You will see changes to your Sun Life bill any month where an employee has a birthday
            that moves them from one age range to another. Your payroll deductions may also need to
            be adjusted accordingly.
          </Body5>
        </div>
      ),
    },
  ];

  const shouldShowDependentLifeRadioGroup = getShouldAskAgeQuestionForSpouseDependentLife(client);

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(getPropertiesToUpdate<Client>(formKeyProps)(client));
  const prefillErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, relevantValidationSchema, { prefill: false })
    : {};

  const getRelevantCriticalIllnessQuestions = () => {
    const { criticalIllnessType, criticalIllnessRateType } = client;
    if (criticalIllnessType === null || criticalIllnessRateType === null) {
      return null;
    }
    if (criticalIllnessType === "ISSUE") {
      const issuedBaseMessage =
        "Since your Critical Illness product is Issue age, spouse Dependent Critical Illness coverage will be based on the employee's age";
      if (criticalIllnessRateType === "NON_TOBACCO") {
        return (
          <HubCard>
            <H3>Critical Illness Type</H3>
            <Body5>{issuedBaseMessage}.</Body5>
          </HubCard>
        );
      }
      if (criticalIllnessRateType === "TOBACCO") {
        return (
          <HubCard>
            <H3>Critical Illness Type</H3>
            <Body5>
              {issuedBaseMessage}, and dependent tobacco status will be based on the spouse's
              tobacco status.
            </Body5>
          </HubCard>
        );
      }
    }
    if (criticalIllnessType === "ATTAINED") {
      const criticalIllnessDependentAgeQuestion = (
        <HubCard>
          <h3>Age for Dependent Critical Illness benefits</h3>
          <RadioGroup
            name="criticalIllnessDependentAge"
            label={
              <div className="mb-16">
                <Body2>
                  For spouse Dependent Critical Illness, whose age should determine changes to
                  coverages?
                </Body2>
              </div>
            }
            disabled={formik.isSubmitting}
            touched={
              !!formik.touched.criticalIllnessDependentAge ||
              !!prefillErrors.criticalIllnessDependentAge
            }
            error={
              formik.errors.criticalIllnessDependentAge || prefillErrors.criticalIllnessDependentAge
            }
            options={[
              {
                value: "SPOUSE",
                label: "Use the spouse's age",
              },
              {
                value: "EMPLOYEE",
                label: "Use the employee's age",
              },
            ]}
            direction="vertical"
            value={formik.values.criticalIllnessDependentAge}
            onChange={formik.handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={[changeSnapshot.Client.criticalIllnessDependentAge]}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "criticalIllnessDependentAge",
              client,
              formik,
            })}
          />
        </HubCard>
      );
      if (criticalIllnessRateType === "NON_TOBACCO") {
        return criticalIllnessDependentAgeQuestion;
      }
      if (criticalIllnessRateType === "TOBACCO") {
        return (
          <>
            {criticalIllnessDependentAgeQuestion}
            <HubCard>
              <h3>Dependent tobacco status for Critical Illness benefits</h3>
              <RadioGroup
                name="criticalIllnessDependentTobacco"
                label={
                  <div className="mb-16">
                    <Body2>
                      For dependent tobacco status, whose tobacco status should determine the spouse
                      tobacco status?
                    </Body2>
                  </div>
                }
                disabled={formik.isSubmitting}
                touched={
                  !!formik.touched.criticalIllnessDependentTobacco ||
                  !!prefillErrors.criticalIllnessDependentTobacco
                }
                error={
                  formik.errors.criticalIllnessDependentTobacco ||
                  prefillErrors.criticalIllnessDependentTobacco
                }
                options={[
                  {
                    value: "SPOUSE",
                    label: "Use the spouse's status",
                  },
                  {
                    value: "EMPLOYEE",
                    label: "Use the employee's status",
                  },
                ]}
                direction="vertical"
                value={formik.values.criticalIllnessDependentTobacco}
                onChange={formik.handleChange}
              />
              <EditedFieldMsg
                changeDetailInfoList={[changeSnapshot.Client.criticalIllnessDependentTobacco]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "criticalIllnessDependentTobacco",
                  client,
                  formik,
                })}
              />
            </HubCard>
          </>
        );
      }
    }
  };

  return (
    <>
      <StackY dist={40} wrap={false}>
        <form onSubmit={formik.handleSubmit}>
          <h2 className="mb-40">{subStepName}</h2>
          <PFMLBulbMessage client={client} />

          <StackY dist={32}>
            {client.ageRules?.includes("Age Reductions") && (
              <HubCard>
                <h3>Age Reductions</h3>

                <StackY dist={16}>
                  <RadioGroup<CoverageChangesEffectiveAgeRules>
                    name="ageReductionDecreaseTakeEffectWhen"
                    label={
                      <>
                        <Body2 as="p">
                          For benefits with age reductions, when do the reductions due to age take
                          effect for employees and dependents?
                        </Body2>
                      </>
                    }
                    disabled={formik.isSubmitting}
                    touched={
                      !!formik.touched.ageReductionDecreaseTakeEffectWhen ||
                      !!prefillErrors.ageReductionDecreaseTakeEffectWhen
                    }
                    error={
                      formik.errors.ageReductionDecreaseTakeEffectWhen ||
                      prefillErrors.ageReductionDecreaseTakeEffectWhen
                    }
                    options={options}
                    direction="vertical"
                    value={formik.values.ageReductionDecreaseTakeEffectWhen}
                    onChange={formik.handleChange}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.Client.ageReductionDecreaseTakeEffectWhen,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "ageReductionDecreaseTakeEffectWhen",
                      client,
                      formik,
                    })}
                  />
                </StackY>
              </HubCard>
            )}

            {client.ageRules?.includes("Age Band Rates") && (
              <HubCard>
                <h3>Age Bands</h3>

                <StackY dist={16}>
                  <RadioGroup
                    name="ageBandIncreaseTakeEffectWhen"
                    label={
                      <div className="mb-16">
                        <Body2>
                          For benefits with age banded rates, when do rate changes take effect for
                          employees and dependents?
                        </Body2>
                      </div>
                    }
                    disabled={formik.isSubmitting}
                    touched={
                      !!formik.touched.ageBandIncreaseTakeEffectWhen ||
                      !!prefillErrors.ageBandIncreaseTakeEffectWhen
                    }
                    error={
                      formik.errors.ageBandIncreaseTakeEffectWhen ||
                      prefillErrors.ageBandIncreaseTakeEffectWhen
                    }
                    options={options}
                    direction="vertical"
                    value={formik.values.ageBandIncreaseTakeEffectWhen}
                    onChange={formik.handleChange}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.ageBandIncreaseTakeEffectWhen]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "ageBandIncreaseTakeEffectWhen",
                      client,
                      formik,
                    })}
                  />
                </StackY>
              </HubCard>
            )}

            {shouldShowDependentLifeRadioGroup && (
              <HubCard>
                <h3>Age for spouse Dependent Life benefits</h3>

                <StackY dist={16}>
                  <RadioGroup
                    name="ageForDependentLifeBenefits"
                    label={
                      <div className="mb-16">
                        <Body2>
                          For spouse Dependent Life benefits, whose age should determine changes to
                          coverage?
                        </Body2>
                      </div>
                    }
                    disabled={formik.isSubmitting}
                    touched={
                      !!formik.touched.ageForDependentLifeBenefits ||
                      !!prefillErrors.ageForDependentLifeBenefits
                    }
                    error={
                      formik.errors.ageForDependentLifeBenefits ||
                      prefillErrors.ageForDependentLifeBenefits
                    }
                    options={[
                      {
                        value: "USE_THE_SPOUSE_AGE",
                        label: ageForDependentLifeBenefitsText["USE_THE_SPOUSE_AGE"],
                      },
                      {
                        value: "USE_THE_EMPLOYEE_AGE",
                        label: (
                          <StackY dist={2}>
                            <Body3>{ageForDependentLifeBenefitsText["USE_THE_EMPLOYEE_AGE"]}</Body3>
                            <Body5>
                              Spouse birthdate will still need to be included on the census.
                            </Body5>
                          </StackY>
                        ),
                      },
                    ]}
                    direction="vertical"
                    value={formik.values.ageForDependentLifeBenefits}
                    onChange={formik.handleChange}
                  />

                  <EditedFieldMsg
                    changeDetailInfoList={[changeSnapshot.Client.ageForDependentLifeBenefits]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "ageForDependentLifeBenefits",
                      client,
                      formik,
                    })}
                  />
                </StackY>
              </HubCard>
            )}

            {getRelevantCriticalIllnessQuestions()}

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </form>

        <div aria-live="assertive" className="hide:empty">
          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        </div>
      </StackY>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
