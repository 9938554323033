import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderBenefitList } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderBenefitList";
import { relevantChangesForWaitingPeriodFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/WaitingPeriods/EIFClassBuilderWaitingPeriodForm";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import {
  getEmployeeClassPlansByReadableWaitingPeriod,
  readableWaitingPeriodSorter,
} from "client/src/domain/EIF/common/utils/getEmployeeClassPlansByReadableWaitingPeriod";
import { getIsWaitingPeriodBenefitType } from "shared/types/BenefitTypes";
import { waitingPeriodNotSetForClassPlans } from "shared/types/EmployeeClassPlan";
import { getEmployeeClassWaitingPeriodsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
};

export function EIFClassBuilderItemWaitingPeriods(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser } = props;

  const employeeClassPlanIds = employeeClass.employeeClassPlans.map((ecp) => ecp.id);

  const waitingPeriodStatus = getEmployeeClassWaitingPeriodsCompletionStatus(employeeClass);

  const classPlansByReadableWaitingPeriod = getEmployeeClassPlansByReadableWaitingPeriod(
    employeeClass.employeeClassPlans,
  );

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <div data-testid="waiting-periods-class-builder-item">
      <h5>Waiting Periods</h5>

      <EditedFieldMsg
        changeDetailInfoList={relevantChangesForWaitingPeriodFields(
          employeeClassPlanIds,
          changeSnapshot,
        )}
        client={client}
        authUser={authUser}
      />

      {waitingPeriodStatus === "Not Started" && (
        <>
          {!suppressErrorsPostSigning ? (
            <Body3 as="div" redMedium>
              Please provide a waiting period for the following benefits
            </Body3>
          ) : (
            <Body3 as="div">-</Body3>
          )}

          <div className="mt-12">
            <EIFClassBuilderBenefitList
              employeeClassPlans={employeeClass.employeeClassPlans.filter((ecp) =>
                getIsWaitingPeriodBenefitType(ecp.plan.benefitType),
              )}
              spacing="default"
              variant="bold"
            />
          </div>
        </>
      )}

      {waitingPeriodStatus !== "Not Started" && (
        <StackY dist={12} className="mt-12">
          {Object.entries(classPlansByReadableWaitingPeriod)
            .sort(readableWaitingPeriodSorter)
            .map(([readableWaitingPeriod, employeeClassPlans]) => {
              const noWaitingPeriodInThisClass =
                waitingPeriodNotSetForClassPlans(employeeClassPlans);
              return (
                <StackY
                  dist={12}
                  key={`waitingPeriodSummary_${readableWaitingPeriod}`}
                  data-testid={`waitingPeriodSummary_${readableWaitingPeriod}`}
                >
                  {noWaitingPeriodInThisClass && (
                    <div className="mt-16">
                      {!suppressErrorsPostSigning ? (
                        <Body3 redMedium>
                          Please provide a waiting period for the following benefits
                        </Body3>
                      ) : (
                        <Body3>-</Body3>
                      )}
                    </div>
                  )}
                  <EIFClassBuilderBenefitList
                    variant="bold"
                    employeeClassPlans={employeeClassPlans}
                  />
                  {!noWaitingPeriodInThisClass && <Body3 as="div">{readableWaitingPeriod}</Body3>}
                </StackY>
              );
            })}
        </StackY>
      )}
    </div>
  );
}
