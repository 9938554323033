import { useSubmitCensus } from "client/src/hooks/document";
import { FieldArray, FormikProvider } from "formik";
import { Fragment, useCallback } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import { getPrimaryPolicy } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import { RouteData } from "../../../../../shared/config/routeData";
import { benAdminPlatforms } from "../../../../../shared/types/BenAdminPlatform";
import { documentSourceValidationClient } from "../../../../../shared/validation/document";

import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Button/Button";
import { FormInput } from "../../../components/Form/Input";
import { RadioGroup } from "../../../components/Form/RadioGroup";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { HorizontalDivider } from "../../../components/HorizontalDivider/HorizontalDivider";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body3 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { querySuccess } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import {
  DocumentDownloadV2,
  useDocumentDownload,
} from "../../Document/DocumentDownload/DocumentDownload";
import { DocumentDownloadLink } from "../../Document/DocumentDownloadLink/DocumentDownloadLink";
import { CensusContentFooter } from "./CensusContentFooter";
import * as styles from "./censusContents.module.less";
import type { CensusSource as CensusSourceType } from "../../../../../shared/validation/document";

import type { CensusOutletContext } from "client/src/domain/Client/Census/CensusDetailPage";

export const CensusSource = () => {
  const {
    client,
    policyId,
    trackElementClicked,
    updateDocumentMeta: { mutateAsync: updateDocumentMeta },
  } = useOutletContext<CensusOutletContext>();
  const { mutateAsync: submitCensus, isPending: censusSubmitting } = useSubmitCensus();
  const navigate = useNavigate();
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "File type selection",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const documentDownloadData = useDocumentDownload({
    clientId: client.id,
    policyId,
    categories: ["enrollment-elections"],
    track,
    taskStatus: "In Progress",
    showDownloadAllButton: false,
    showDownloadButton: false,
  });
  const documents = documentDownloadData.documents.data ?? [];

  const primaryPolicy = getPrimaryPolicy(client);

  const formik = useSlobFormik({
    initialValues: {
      policyId,
      documents: documents.map((document) => ({
        id: document.id,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions --  formik fails to extend nested types
        censusSource: document.censusSource ?? ("" as CensusSourceType),
        censusSourceOther: document.censusSourceOther || "",
        platform: document.platform ?? primaryPolicy.benAdminPlatform?.name ?? "",
      })),
    },
    validationSchema: documentSourceValidationClient,
    async onSubmit(values) {
      const { isSuccess, isError } = await updateDocumentMeta({
        data: values,
        params: {
          clientId: client.id,
        },
      });
      if (isSuccess) {
        if (noMDTFile) {
          const { isSuccess: isSubmitSuccess, isError: isSubmitError } = await submitCensus({
            params: {
              clientId: client.id,
              policyId,
            },
          });
          if (isSubmitSuccess) {
            navigate(
              RouteData.policyTaskDetail.getPath(client.id ?? "", "enrollment-elections", policyId),
            );
          }

          if (isSubmitError) {
            void slobMessage.error("Something went wrong");
          }
        } else {
          navigate(
            `${RouteData.censusStepDetail.getPath(client.id ?? "", policyId)}/validate-submit`,
          );
        }
      }
      if (isError) {
        void slobMessage.error("Something went wrong");
      }
    },
  });

  if (!documents.length) {
    return (
      <Navigate
        to={`${RouteData.censusStepDetail.getPath(client.id ?? "", policyId)}/upload-files`}
        replace
      />
    );
  }

  const noMDTFile = formik.values.documents.every((d) => d.censusSource !== "MDT");
  const submitText = noMDTFile ? "Submit" : "Next";

  const footerError =
    formik.touched.documents && formik.errors.documents?.length
      ? "Please correct the errors above."
      : "";

  const canNotValidate = (
    <div>
      <AlertBanner
        variant="info"
        message={
          <Body3>
            <strong>Thank you.</strong>
            <br />
            We can accept this format but will not be able to validate and check for any errors
            before you submit. If you’d like to take advantage of real-time validation to
            potentially save time in the onboarding process, use Sun Life’s{" "}
            <DocumentDownloadLink clientId={client.id} category="census-template" track={track}>
              custom template file
            </DocumentDownloadLink>
            .
          </Body3>
        }
      />
    </div>
  );

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.contentPanel}>
          <h5 className="mb-32">
            Please select the format of the enrollment elections file you uploaded.
          </h5>
          <FieldArray
            name="documents"
            render={() =>
              formik.values.documents.map((formikDocument, index) => {
                const documentValue = formik.values.documents?.[index];
                assertIsDefined(documentValue, "documentValue");
                const documentTouched = formik.touched.documents?.[index];
                const documentError = formik.errors.documents?.[index];
                const document = documents.find((d) => d.id === formikDocument.id);
                if (!document) return null;
                return (
                  <Fragment key={document.id}>
                    <StackY dist={32}>
                      <DocumentDownloadV2
                        {...documentDownloadData}
                        documents={querySuccess([document], documentDownloadData.documents)}
                      />
                      <RadioGroup
                        name={`documents.${index}.censusSource`}
                        label=""
                        direction="vertical"
                        value={documentValue.censusSource}
                        onChange={async (e) => {
                          track("Original source of the file selected");
                          await formik.setFieldValue(`documents.${index}.platform`, "");
                          await formik.setFieldValue(`documents.${index}.censusSourceOther`, "");
                          formik.handleChange(e);
                        }}
                        disabled={formik.isSubmitting}
                        touched={documentTouched?.censusSource}
                        error={
                          (documentError instanceof Object && documentError.censusSource) || false
                        }
                        options={[
                          { value: "MDT", label: "Sun Life provided custom template file" },
                          {
                            value: "EXPORT",
                            label: "Export from my benefits administration or payroll platform",
                            content: documentValue.censusSource === "EXPORT" && (
                              <div className={styles.platformSelector}>
                                <SlobSelect<{ label: string; value: string }>
                                  name="platform"
                                  value={documentValue.platform}
                                  options={benAdminPlatforms.map((b) => ({
                                    label: b,
                                    value: b,
                                  }))}
                                  placeholder="Select Platform"
                                  onChange={(val) => {
                                    track("Benefits adminstration or payroll platform selected");
                                    void formik.setFieldValue(
                                      `documents.${index}.platform`,
                                      val.value,
                                    );
                                  }}
                                  touched={documentTouched?.platform}
                                  error={
                                    documentError instanceof Object && documentError.platform
                                      ? "Please select which platform your file was exported from"
                                      : ""
                                  }
                                />
                              </div>
                            ),
                          },
                          { value: "ENROLLMENT_FORM", label: "Enrollment forms" },
                          {
                            value: "OTHER",
                            label: "Other",
                            content: documentValue.censusSource === "OTHER" && (
                              <div className={styles.platformSelector}>
                                <FormInput
                                  name="otherSource"
                                  value={documentValue.censusSourceOther}
                                  onChange={(e) => {
                                    void formik.setFieldValue(
                                      `documents.${index}.censusSourceOther`,
                                      e.target.value,
                                    );
                                  }}
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  label="Please describe..."
                                  maxLength={100}
                                  error={
                                    documentError instanceof Object
                                      ? documentError.censusSourceOther
                                      : ""
                                  }
                                  touched={documentTouched?.censusSourceOther}
                                />
                              </div>
                            ),
                          },
                        ]}
                      />

                      {documentValue.censusSource === "MDT" && (
                        <AlertBanner
                          variant="success"
                          message={
                            <Body3>
                              <strong>Great!</strong>
                              <br />
                              By using Sun Life’s custom template, you’ll be able to use real-time
                              validation to check the basic data and format of your file for common
                              errors before you submit it. This can help save you time and ensure
                              your data looks good.
                            </Body3>
                          }
                        />
                      )}
                      {documentValue.censusSource === "EXPORT" && canNotValidate}
                      {documentValue.censusSource === "ENROLLMENT_FORM" && canNotValidate}
                      {documentValue.censusSource === "OTHER" && canNotValidate}
                    </StackY>
                    {index < documents.length - 1 && <HorizontalDivider />}
                  </Fragment>
                );
              })
            }
          />
        </div>
        <CensusContentFooter
          client={client}
          policyId={policyId}
          previousTrack={track}
          previousBackTo={"upload-files"}
          previousDisabled={formik.isSubmitting}
          error={footerError}
        >
          <Button
            htmlType="submit"
            disabled={formik.isSubmitting || censusSubmitting}
            loading={formik.isSubmitting || censusSubmitting}
            type="primary"
            size="middle"
            onClick={() => track("Next")}
          >
            {submitText}
          </Button>
        </CensusContentFooter>
      </form>
    </FormikProvider>
  );
};
