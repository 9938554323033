import { getValidationErrors } from "shared/validation/getValidationErrors";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { ObjectSchema, AnyObject } from "yup";

export const getIsSubStepCompleted = (
  client: Client,
  validator: ObjectSchema<AnyObject>,
  featureToggles?: ClientFeatureToggles,
): boolean => {
  const validationErrors = getValidationErrors(validator, client, {
    featureToggles,
    client,
    prefill: false,
  });
  const isCompleted = validationErrors.length === 0;

  return isCompleted;
};
