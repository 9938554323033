import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { FormInput } from "client/src/components/Form/Input";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { StackX } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import pluralize from "pluralize";
import * as Yup from "yup";
import type { UpdateEmailAndSendActivationEmailFunc } from "client/src/hooks/user";
import type { FormikErrors } from "formik";
import type { UserRole } from "shared/rbac/roles";
import type { ClientId } from "shared/types/Client";
import type { UserId } from "shared/types/User";

type EmailErrorModalProps = {
  showModal: boolean;
  clientId: ClientId;
  onClose: () => void;
  updateEmailAndSendActivationEmail: UpdateEmailAndSendActivationEmailFunc;
  users: {
    userId: UserId;
    user?: {
      email: string;
      firstName?: string;
      lastName?: string;
      role: UserRole;
    };
  }[];
};

export const EmailErrorModal = ({
  showModal,
  onClose,
  users,
  clientId,
  updateEmailAndSendActivationEmail,
}: EmailErrorModalProps) => {
  const formik = useSlobFormik({
    validationSchema: Yup.object({
      users: Yup.array()
        .of(
          Yup.object({
            clientId: Yup.string().required(),
            userId: Yup.string().required(),
            email: Yup.string().emailV1("Email must be valid").required(),
          }),
        )
        .required(),
    }),
    initialValues: {
      users: users.map((user) => ({
        userId: user.userId,
        clientId,
        email: user.user?.email || "",
      })),
    },
    onSubmit: async (values) => {
      await updateEmailAndSendActivationEmail({
        data: {
          users: values.users,
        },
      });
      void slobMessage.info(`Activation ${pluralize("email", values.users.length)} sent`);
      onClose();
    },
  });

  return (
    <Modal
      maskClosable={false}
      title="Edit email address"
      open={showModal}
      footer={null}
      focusTriggerAfterClose={false}
      onCancel={onClose}
      zIndex={1001} // The drawer z-index is 1000. This is to make sure the modal is always on top of the drawer.
    >
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <Body3>
              Check to ensure the user’s email address is correct and edit if necessary.
              <br />
              Then save your changes and resend the email.
            </Body3>
          </Col>
        </Row>
        {users.map((clientUser, i) => (
          <Row
            className="mt-24"
            justify="center"
            align="middle"
            key={`${clientUser.userId}-${clientId}`}
          >
            <Col xs={24} sm={12}>
              <FormInput
                topText={`${clientUser.user?.firstName} ${clientUser.user?.lastName}`}
                name={`users[${i}].email`}
                label="Email"
                disabled={formik.isSubmitting}
                maxLength={100}
                onChange={formik.handleChange}
                touched={!!getEmailErrorFromFormik(formik.errors.users?.[i])} // the touched API is not working for array fields
                error={getEmailErrorFromFormik(formik.errors.users?.[i])}
                value={formik.values.users[i]?.email}
              />
            </Col>
          </Row>
        ))}
        {formik.status && (
          <Row justify="end" align="middle" className="mt-24">
            <Col xs={24}>
              <ErrorMessage>{formik.status}</ErrorMessage>
            </Col>
          </Row>
        )}
        <Row justify="end" align="middle" className="mt-32">
          <Col xs={12}>
            <StackX dist={24}>
              <Button
                type="text-only"
                size="middle"
                htmlType="button"
                onClick={onClose}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                Save & resend email
              </Button>
            </StackX>
          </Col>
        </Row>
      </form>
    </Modal>
  );
};

const getEmailErrorFromFormik = (
  emailError:
    | string
    | FormikErrors<{ clientId: string; userId: string; email: string }>
    | undefined,
): string => {
  if (!emailError) {
    return "";
  }

  if (typeof emailError === "string") {
    return emailError;
  }

  return emailError.email || "";
};
