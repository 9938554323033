import { Body2 } from "client/src/components/Typography/Typography";
import { ReviewTierContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/ReviewTierContributions";
import { isUsing3TierContributionAttributes } from "client/src/domain/EIF/common/utils/isUsing3TierContributionAttributes";
import {
  getIsFourTierIndividualContributionBenefitType,
  type BenefitTypeEIF,
} from "shared/types/BenefitTypes";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { EIFClassBuilderTieredContributionsEditable } from "./EIFClassBuilderTieredContributionsEditable";

import type { SectionTracker } from "../../EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type {
  SupplementalHealthBenefitType,
  TieredContributionBenefitTypes,
} from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type EIFClassBuilderTieredContributionsProps = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  benefitType: TieredContributionBenefitTypes | SupplementalHealthBenefitType;
  isLoading: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
} & (
  | {
      readonly: true;
    }
  | {
      readonly: false;
      onSave: (employeeClass: EmployeeClass) => void;
      track: SectionTracker;
      setContributionsQuery: SetContributionQuery;
    }
);

export const EIFClassBuilderTieredContributions = (
  props: EIFClassBuilderTieredContributionsProps,
) => {
  const {
    isActive,
    client,
    employeeClass,
    benefitType,
    isLoading,
    changeSnapshot,
    authUser,
    readonly,
  } = props;

  if (readonly) {
    return (
      <>
        <Body2 as="p">{benefitTypeToCoverage[benefitType]}</Body2>

        <ReviewTierContributions
          client={client}
          changeSnapshot={changeSnapshot}
          employeeClass={employeeClass}
          benefitType={benefitType}
          authUser={authUser}
        />
      </>
    );
  }

  return (
    <EIFClassBuilderTieredContributionsEditable
      isActive={isActive}
      client={client}
      employeeClass={employeeClass}
      setContributionsQuery={props.setContributionsQuery}
      benefitType={benefitType}
      onSave={props.onSave}
      isLoading={isLoading}
      track={props.track}
      changeSnapshot={changeSnapshot}
      authUser={authUser}
    />
  );
};

export const getTierContributionValuesFromEmployeeClassAndBenefitType = (
  employeeClass: EmployeeClass,
  benefitType: BenefitTypeEIF,
) => {
  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter(
    (employeeClassPlan) => {
      return employeeClassPlan.plan.benefitType === benefitType;
    },
  );

  const relevantLow = relevantEmployeeClassPlans.find(
    (classPlan) => classPlan.plan.level === "LOW" || classPlan.plan.level === "NOT_APPLICABLE",
  );
  const relevantHigh = relevantEmployeeClassPlans.find(
    (classPlan) => classPlan.plan.level === "HIGH",
  );

  const showHigh =
    relevantEmployeeClassPlans.length > 0
      ? relevantEmployeeClassPlans.some((rcp) => rcp.plan.level === "HIGH")
      : false;

  const getEmployerContribInitialValue = () => {
    if (relevantEmployeeClassPlans.every((ecp) => ecp.employerContribution === "NO")) {
      return "NO" as const;
    }

    if (relevantEmployeeClassPlans.some((ecp) => ecp.employerContribution === "YES")) {
      return "YES" as const;
    }

    if (relevantEmployeeClassPlans.some((ecp) => ecp.employerContribution === "SHARED")) {
      return "SHARED" as const;
    }

    return null;
  };

  const useIndividualRoleAttrs =
    isUsing3TierContributionAttributes(employeeClass, benefitType) ||
    getIsFourTierIndividualContributionBenefitType(benefitType);

  return {
    employerContribution: getEmployerContribInitialValue(),
    employerContributionUnit:
      relevantEmployeeClassPlans.length > 0
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
          relevantEmployeeClassPlans[0]!.employerContributionUnit
        : undefined,
    useIndividualRoleAttrs,
    eeContributionAmountLow: relevantLow?.eeContributionAmount ?? undefined,
    spouseContributionAmountLow: relevantLow?.spouseContributionAmount ?? undefined,
    childrenContributionAmountLow: relevantLow?.childrenContributionAmount ?? undefined,
    eeAndSpouseContributionAmountLow: relevantLow?.eeAndSpouseContributionAmount ?? undefined,
    eeAndChildrenContributionAmountLow: relevantLow?.eeAndChildrenContributionAmount ?? undefined,
    eeAndFamilyContributionAmountLow: relevantLow?.eeAndFamilyContributionAmount ?? undefined,
    familyContributionAmountLow: relevantLow?.familyContributionAmount ?? undefined,
    eeContributionAmountHigh: showHigh ? relevantHigh?.eeContributionAmount ?? undefined : 0,
    spouseContributionAmountHigh: showHigh
      ? relevantHigh?.spouseContributionAmount ?? undefined
      : 0,
    childrenContributionAmountHigh: showHigh
      ? relevantHigh?.childrenContributionAmount ?? undefined
      : 0,
    eeAndSpouseContributionAmountHigh: showHigh
      ? relevantHigh?.eeAndSpouseContributionAmount ?? undefined
      : 0,
    eeAndChildrenContributionAmountHigh: showHigh
      ? relevantHigh?.eeAndChildrenContributionAmount ?? undefined
      : 0,
    eeAndFamilyContributionAmountHigh: showHigh
      ? relevantHigh?.eeAndFamilyContributionAmount ?? undefined
      : 0,
    familyContributionAmountHigh: showHigh
      ? relevantHigh?.familyContributionAmount ?? undefined
      : 0,
    employerPremiumPayments: relevantLow?.employerPremiumPayments ?? undefined,
  };
};
