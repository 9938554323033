import { EIFClassBuilder } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilder";
import { EIFFLIPreferences } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFFLIPreferences";
import { EIFPlanConfigAndEligibilityIntro } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFPlanConfigAndEligibilityIntro";
import { EIFPlanConfigAndEligibilityReview } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFPlanConfigAndEligibilityReview";
import { EIFNonClassBenefitsPreferences } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsPreferences";
import { EIFPlaceholder } from "client/src/domain/EIF/common/EIFPlaceholder";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getPlanConfigCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getEIFSubStepViewMode } from "shared/utils/client";
import type { AdditionalCompensation } from "@prisma/client";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { CreateClassQuery, UpdateClassQuery } from "client/src/hooks/employeeClass";
import type {
  SetBenefitTypeAvailablePlansQuery,
  SetWaitingPeriodsQuery,
  SetContributionQuery,
} from "client/src/hooks/employeeClassPlan";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Document } from "shared/types/Document";
import type { EIFStepId, EIFPlanConfigEligibilityOnlyPFMLSubStep } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const eifStepId: EIFStepId = "plan-configuration-&-eligibility";

type Props = {
  client: Client;
  clientPlans: Plan[];
  updateClientQuery: UpdateClientQuery;
  documents: Document[];
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  classes: EmployeeClass[];
  employeeClass: EmployeeClass | undefined;
  additionalCompensations: AdditionalCompensation[];
  setBenefitTypesAvailablePlansQuery: SetBenefitTypeAvailablePlansQuery;
  setWaitingPeriodsQuery: SetWaitingPeriodsQuery;
  setContributionsQuery: SetContributionQuery;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  eifSubStepId?: EIFPlanConfigEligibilityOnlyPFMLSubStep;
  employeeClassDocumentCount: number;
  updatePlansQuery: UpdatePlansQuery;
};

export function EIFPlanConfigAndEligibility(props: Props) {
  const {
    client,
    clientPlans,
    updateClientQuery,
    documents,
    createClassQuery,
    updateClassQuery,
    classes,
    employeeClass,
    additionalCompensations,
    setBenefitTypesAvailablePlansQuery,
    setWaitingPeriodsQuery,
    setContributionsQuery,
    trackElementClicked,
    featureToggles,
    changeSnapshot,
    authUser,
    eifSubStepId,
    employeeClassDocumentCount,
    updatePlansQuery,
  } = props;

  const { mutateAsync: updateClient } = updateClientQuery;

  const viewMode = getEIFSubStepViewMode({ client });

  if (!eifSubStepId) {
    const needsClassbuilder = getIsSubStepApplicable({
      eifSubStepId: "class-builder",
      client,
      plans: clientPlans,
    });
    const needsPFMLPreferences = getIsSubStepApplicable({
      eifSubStepId: "non-class-benefits-preferences",
      client,
      featureToggles,
    });
    const needsFLIPreferences = getIsSubStepApplicable({
      eifSubStepId: "fli-preferences",
      client,
    });

    if (!needsClassbuilder && !needsPFMLPreferences && !needsFLIPreferences) {
      return (
        <Navigate
          to={RouteData.eifStepDetail.getPath(client.id, "other-contract-details")}
          replace
        />
      );
    }

    const stepCompleteStatus = getPlanConfigCompletionStatus(
      client,
      classes,
      employeeClassDocumentCount,
      clientPlans,
      featureToggles,
      changeSnapshot,
    );

    if (stepCompleteStatus === "Not Started") {
      return (
        <EIFPlanConfigAndEligibilityIntro
          client={client}
          clientPlans={clientPlans}
          eifStepId={eifStepId}
          featureToggles={featureToggles}
        />
      );
    } else {
      return (
        <EIFPlanConfigAndEligibilityReview
          client={client}
          authUser={authUser}
          classes={classes}
          clientPlans={clientPlans}
          trackElementClicked={trackElementClicked}
          viewMode={viewMode}
          changeSnapshot={changeSnapshot}
          featureToggles={featureToggles}
          updateClient={updateClient}
          steps={
            needsClassbuilder && !needsPFMLPreferences && !needsFLIPreferences
              ? "class-builder-only"
              : "all"
          }
        />
      );
    }
  }

  switch (eifSubStepId) {
    case "class-builder":
      return (
        <EIFClassBuilder
          client={client}
          clientPlans={clientPlans}
          createClassQuery={createClassQuery}
          updateClassQuery={updateClassQuery}
          classes={classes}
          employeeClass={employeeClass}
          additionalCompensations={additionalCompensations}
          setBenefitTypesAvailablePlansQuery={setBenefitTypesAvailablePlansQuery}
          setWaitingPeriodsQuery={setWaitingPeriodsQuery}
          setContributionsQuery={setContributionsQuery}
          trackElementClicked={trackElementClicked}
          changeSnapshot={changeSnapshot}
          featureToggles={featureToggles}
          authUser={authUser}
          documents={documents}
          updateClient={updateClient}
          employeeClassDocumentCount={employeeClassDocumentCount}
        />
      );

    case "non-class-benefits-preferences":
      return (
        <EIFNonClassBenefitsPreferences
          client={client}
          clientPlans={clientPlans}
          authUser={authUser}
          updatePlansQuery={updatePlansQuery}
          updateClientQuery={updateClientQuery}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          viewMode={viewMode}
          trackElementClicked={trackElementClicked}
        />
      );

    case "fli-preferences":
      return (
        <EIFFLIPreferences
          client={client}
          updateClientQuery={updateClientQuery}
          featureToggles={featureToggles}
        />
      );
    default:
      return EIFPlaceholder({ eifStepId, eifSubStepId });
  }
}
