import { Body2, Body3, H3 } from "client/src/components/Typography/Typography";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import type { PFMLStates } from "shared/types/Plan";

type Props = {
  sectionState: PFMLStates;
  isReviewOnly: boolean;
  showSectionState?: boolean;
};

export const EIFNonClassBenefitsReadOnlyContribution = (props: Props) => {
  const { sectionState, isReviewOnly, showSectionState = true } = props;

  const adviceOnlyText = !isReviewOnly ? <Body2>Advice only</Body2> : "Advice only";

  return (
    <>
      {showSectionState && isReviewOnly && <Body2>{sectionState} Paid Family Medical Leave</Body2>}
      {showSectionState && !isReviewOnly && (
        <H3>
          <EIFSectionStatusIconography status="Completed" />
          {sectionState} Paid Family Medical Leave
        </H3>
      )}
      <Body3 as="p">
        You've chosen {adviceOnlyText} for this state. Sun Life does not need to collect
        contribution information for this benefit.
      </Body3>
    </>
  );
};
