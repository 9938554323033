import pluralize from "pluralize";
import { getDaysFromToday } from "../../../../shared/utils/date";
import { formatDateFullMonthWithYear, listFormat } from "../../../../shared/utils/format";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { H3 } from "../Typography/Typography";

type ModalProps = {
  isVisible: boolean;
  onCancel: (() => Promise<void>) | (() => void);
  uniquePolicyEffective: Date[];
};

const getOffTrackDates = (dates: Date[]) => {
  return listFormat(
    dates.map((date) => {
      const days = getDaysFromToday(date);
      const multipleDates = dates.length > 1;
      return `${days} ${pluralize("day", days)} of your ${
        multipleDates ? ` ${formatDateFullMonthWithYear(date)}` : ""
      }`;
    }),
  );
};

const getPastDates = (dates: Date[]) => {
  return listFormat(
    dates.map((date) => {
      return formatDateFullMonthWithYear(date);
    }),
  );
};

export const OffTrackModal = ({ isVisible, onCancel, uniquePolicyEffective }: ModalProps) => {
  const pastDates = uniquePolicyEffective.filter((date) => getDaysFromToday(date) < 0);
  const todayDates = uniquePolicyEffective.filter((date) => getDaysFromToday(date) === 0);
  const offTrackDates = uniquePolicyEffective.filter((date) => getDaysFromToday(date) > 0);
  return (
    <Modal
      title={"You have important tasks that require action"}
      open={isVisible}
      footer={
        <div className="mt-16">
          <Button type="tertiary" size="middle" onClick={onCancel}>
            Ok
          </Button>
        </div>
      }
      onCancel={onCancel}
    >
      <H3 as="h2">Complete your tasks as soon as possible</H3>
      {Boolean(todayDates.length) && !offTrackDates.length && !pastDates.length && (
        <p>
          Today is your effective date and you still have tasks that need to be completed before Sun
          Life can fully install your plans. Review your dashboard and complete these tasks now.
        </p>
      )}
      {Boolean(todayDates.length) && Boolean(offTrackDates.length) && !pastDates.length && (
        <p>
          Today is your effective date and you are within {getOffTrackDates(offTrackDates)}{" "}
          effective {pluralize("date", offTrackDates.length)} and you still have tasks that need to
          be completed before Sun Life can fully install your plans. Review your dashboard and
          complete these tasks now.
        </p>
      )}
      {Boolean(todayDates.length) && !offTrackDates.length && Boolean(pastDates.length) && (
        <p>
          Today is your effective date and you are <i>past</i> your {getPastDates(pastDates)}{" "}
          effective {pluralize("date", pastDates.length)} and you still have tasks that need to be
          completed before Sun Life can fully install your plans. Review your dashboard and complete
          these tasks now.
        </p>
      )}
      {Boolean(offTrackDates.length) && !pastDates.length && !todayDates.length && (
        <p>
          You are within {getOffTrackDates(offTrackDates)} effective{" "}
          {pluralize("date", offTrackDates.length)} and still have tasks that need to be completed
          before Sun Life can fully install your plans. Review your dashboard and complete these
          tasks now.
        </p>
      )}
      {Boolean(pastDates.length) && !offTrackDates.length && !todayDates.length && (
        <p>
          You are past your {getPastDates(pastDates)} effective{" "}
          {pluralize("date", pastDates.length)} and still have tasks that need to be completed
          before Sun Life can fully install your plans. Review your dashboard and complete these
          tasks now.
        </p>
      )}
      {Boolean(pastDates.length) && Boolean(offTrackDates.length) && !todayDates.length && (
        <p>
          You are within {getOffTrackDates(offTrackDates)} effective{" "}
          {pluralize("date", offTrackDates.length)} and are <i>past</i> your{" "}
          {getPastDates(pastDates)} effective {pluralize("date", pastDates.length)} and still have
          tasks that need to be completed before Sun Life can fully install your plans. Review your
          dashboard and complete these tasks now.
        </p>
      )}
      {Boolean(todayDates.length) && Boolean(offTrackDates.length) && Boolean(pastDates.length) && (
        <p>
          Today is your effective date and you are within {getOffTrackDates(offTrackDates)}{" "}
          effective {pluralize("date", offTrackDates.length)} and you are <i>past</i> your{" "}
          {getPastDates(pastDates)} effective {pluralize("date", pastDates.length)} and you still
          have tasks that need to be completed before Sun Life can fully install your plans. Review
          your dashboard and complete these tasks now.
        </p>
      )}
      <H3 as="h2">Why does it matter?</H3>
      <p>
        Employees may have trouble accessing benefits or filing claims if your plans are not
        installed and ready for ongoing administration by their effective date.
      </p>
      <H3 as="h2">What should you do?</H3>
      <p>
        Complete the tasks on your dashboard as soon as possible. If you are concerned about your
        status, or want to consider changing your effective date, please reach out to your
        Implementation Consultant to discuss.
      </p>
    </Modal>
  );
};

export const FallingBehindModal = ({ isVisible, onCancel, uniquePolicyEffective }: ModalProps) => {
  return (
    <Modal
      title={"Your effective date is coming up soon"}
      open={isVisible}
      footer={
        <div className="mt-16">
          <Button type="tertiary" size="middle" onClick={onCancel}>
            Ok
          </Button>
        </div>
      }
      onCancel={onCancel}
    >
      <H3 as="h2">Complete your tasks as soon as possible</H3>
      <p>
        You are within {getOffTrackDates(uniquePolicyEffective)} effective{" "}
        {pluralize("date", uniquePolicyEffective.length)} and still have tasks that need to be
        completed before Sun Life can fully install your plans. Review your dashboard and complete
        these tasks now.
      </p>
      <H3 as="h2">Why does it matter?</H3>
      <p>
        Employees may have trouble accessing benefits or filing claims if your plans are not
        installed and ready for ongoing administration by their effective date.
      </p>
      <H3 as="h2">What should you do?</H3>
      <p>
        Complete the tasks on your dashboard as soon as possible. If you are concerned about your
        status, or want to consider changing your effective date, please reach out to your
        Implementation Consultant to discuss.
      </p>
    </Modal>
  );
};
