import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";

import { StackY } from "client/src/components/Spacing/Spacing";
import { Body1, H1Large } from "../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../i18n";

import * as styles from "./clientPage.module.less";

export const DataTestId = {
  EndOfBenefitsWrapper: "end-of-benefits-wrapper",
};

type ExplorerEndOfBenefitsProps = {
  clientName: string | null;
  customCalendlyUrl: string | undefined;
  track?: () => void;
};

export const ExplorerEndOfBenefits = ({
  clientName,
  customCalendlyUrl,
  track,
}: ExplorerEndOfBenefitsProps) => {
  const { t } = useTranslation({ keyPrefix: "EndOfBenefits" });

  return (
    <div data-testid={DataTestId.EndOfBenefitsWrapper}>
      <Row align="middle" justify="center" className={styles.container}>
        <Col>
          <H1Large>
            <strong>{t("headline")}</strong>
          </H1Large>
          <Body1>
            <StackY dist={24}>
              <Trans t={t} i18nKey="content" values={{ client: clientName }}>
                <p className={styles.endOfComponent}>paragraph1</p>
                <hr />
                <p className={styles.endOfComponent}>paragraph2</p>
              </Trans>
              <Button
                data-testid="schedule"
                type="primary"
                href={customCalendlyUrl ?? ""}
                newTab
                size="middle"
                onClick={() => track?.()}
              >
                {t("buttonText")}
              </Button>
            </StackY>
          </Body1>
        </Col>
      </Row>
    </div>
  );
};
