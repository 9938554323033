import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { TabsContext } from "./Tabs.context";
import { useTabsOnChange } from "./Tabs.hooks";
import * as styles from "./Tabs.module.less";
import type { TabsProps, TabProps } from "./Tabs.types";

export function Tabs({ children, defaultTab, onChange, ...props }: TabsProps) {
  const [tab, setTab] = useState<string | undefined>(defaultTab);
  useTabsOnChange(tab, onChange);

  return (
    <TabsContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      <div className={styles.tabs} {...props}>
        {children}
      </div>
    </TabsContext.Provider>
  );
}

export function Tab({ label, tab, error }: TabProps) {
  return (
    <TabsContext.Consumer>
      {({ tab: currentTab, setTab }) => {
        return (
          <button
            className={styles.tab}
            type="button"
            onClick={() => {
              setTab?.(tab);
            }}
            data-active={tab === currentTab}
            data-error={error}
          >
            <div className={styles.tabContent}>
              <span>{label}</span>
              {error === true && (
                <FontAwesomeIcon
                  className={styles.tabContentErrorIcon}
                  icon={faExclamationTriangle}
                />
              )}
            </div>
          </button>
        );
      }}
    </TabsContext.Consumer>
  );
}
