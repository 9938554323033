import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { BillNoSplitForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillNoSplitForm";
import { BillsList } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillsList";
import { BillStatementsForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillStatements/BillStatementsForm";
import { useState } from "react";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { assertIsDefined } from "shared/utils/utils";
import type { BillingAdministrationType } from "@prisma/client";
import type { BillNoSplitFormRef } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillNoSplitForm";
import type { BillStatementFormValues } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillStatements/BillStatementsForm";
import type { useFormik } from "formik";
import type { RefObject } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Bill, BillPreview } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BillFormValues, BillingPreferencesFormValues } from "shared/validation/bill";

type Props = {
  client: Client;
  policy: Policy;
  locations: Location[];
  admins: Contact[];
  bills: Bill[];
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  billPreviews: BillPreview[];
  formik: ReturnType<typeof useFormik<BillingPreferencesFormValues>>;
  isSubStepStarted: boolean;
  billingAdministrationType: Extract<BillingAdministrationType, "SELF" | "TPA">;
  billNoSplitFormRef: RefObject<BillNoSplitFormRef>;
  featureToggles: ClientFeatureToggles;
};

export function BillStatementsCard(props: Props) {
  const {
    client,
    policy,
    locations,
    admins,
    bills,
    changeSnapshot,
    authUser,
    billPreviews,
    formik,
    isSubStepStarted,
    billingAdministrationType,
    billNoSplitFormRef,
    featureToggles,
  } = props;

  const [viewState, setViewState] = useState<
    | { type: "list" }
    | { type: "create" }
    | {
        type: "edit";
        index: number;
        billStatementPreview: BillStatementFormValues;
      }
  >(billPreviews.length === 0 ? { type: "create" } : { type: "list" });

  if (viewState.type === "edit") {
    assertIsDefined(billPreviews[viewState.index], "billPreviews[viewState.index]");
  }

  if (formik.values.billingStructureType === "SINGLE") {
    return (
      <HubCard>
        <h3>Create Summary Statement</h3>

        <BillNoSplitForm
          ref={billNoSplitFormRef}
          client={client}
          policy={policy}
          locations={locations}
          admins={admins}
          bills={bills}
          copyModalIsOpen={false}
          toggleCopyModalIsOpen={noop}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          billPreviews={billPreviews}
          formik={formik}
          billingAdministrationType={billingAdministrationType}
          billSplitType={null}
          disabled={formik.isSubmitting}
          isSubStepStarted={isSubStepStarted}
          featureToggles={featureToggles}
        />
      </HubCard>
    );
  }

  if (formik.values.billingStructureType === "MULTIPLE") {
    return (
      <HubCard>
        {viewState.type === "list" && (
          <>
            <h3>Your statements</h3>

            {formik.values.bills.length === 0 && <p>No statements created yet.</p>}

            <StackY dist={24} wrap={false}>
              <BillsList
                bills={billPreviews}
                client={client}
                policy={policy}
                featureToggles={featureToggles}
                changeSnapshot={changeSnapshot}
                billSplitType={null}
                authUser={authUser}
                policyBillingPreferences={formik.values}
                disabled={formik.isSubmitting}
                onEdit={(index) => {
                  const billStatementPreview_ = formik.values.bills[index];
                  assertIsDefined(billStatementPreview_, "billStatementPreview_");
                  const billStatementPreview = {
                    ...billStatementPreview_,
                    billingStructureType: billStatementPreview_.billingStructureType,
                  };
                  setViewState({ type: "edit", index, billStatementPreview });
                }}
                onDelete={async (index) => {
                  const prevValues = formik.values.bills;
                  const nextValues = prevValues.filter((_, j) => j !== index);
                  await formik.setFieldValue("bills", nextValues);
                }}
                signerMode="inside"
              />

              <div>
                <Button
                  type="text-only"
                  size="middle"
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setViewState({ type: "create" });
                  }}
                >
                  Create another statement
                </Button>
              </div>
            </StackY>
          </>
        )}

        {(viewState.type === "create" || viewState.type === "edit") && (
          <>
            <h3>Create Summary Statement</h3>

            <BillStatementsForm
              client={client}
              policy={policy}
              locations={locations}
              admins={admins}
              disabled={formik.isSubmitting}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              billingAdministrationType={billingAdministrationType}
              billingStructureType={formik.values.billingStructureType}
              isSubStepStarted={isSubStepStarted}
              featureToggles={featureToggles}
              onSubmit={async (values) => {
                const index =
                  viewState.type === "edit" ? viewState.index : formik.values.bills.length;

                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
                const existingBill = formik.values.bills[index] as BillFormValues | undefined;

                const statement: BillFormValues = {
                  ...existingBill,

                  id: existingBill?.id ?? null,
                  createdAt: existingBill?.createdAt ?? null,

                  advanceOrArrears: formik.values.advanceOrArrears,
                  billingAdministrationType: formik.values.billingAdministrationType,
                  billSplitType: formik.values.billSplitType,

                  billTiming: null,

                  groupByLocationIds: null,
                  splitTags: null,
                  slfCoverages: policy.slfCoverages,
                  categorizeEmployees: null,
                  employeeCategorizationType: null,
                  categorizeByLocationIds: null,
                  categoriesByTags: null,

                  ...values,
                };

                const nextValues = formik.values.bills.slice();
                nextValues[index] = statement;
                await formik.setFieldValue(`bills`, nextValues);
                setViewState({ type: "list" });
              }}
              initialValues={viewState.type === "edit" ? viewState.billStatementPreview : undefined}
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked with assertIsDefined above
              billId={viewState.type === "edit" ? billPreviews[viewState.index]!.id : undefined}
              onCancel={() => setViewState({ type: "list" })}
            />
          </>
        )}
      </HubCard>
    );
  }

  if (formik.values.billingStructureType == null) {
    return null;
  }

  exhaustiveCheckFail(formik.values.billingStructureType);
}

const noop = () => undefined;
