import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { TextArea } from "client/src/components/Form/TextArea";
import { Modal } from "client/src/components/Modal/Modal";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { ResponseError } from "client/src/hooks/query";
import { useState } from "react";
import type { StackProps } from "client/src/components/Spacing/Spacing";
import type { SignerMode } from "shared/types/OutsideSigner";

type Props = {
  isVisible: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onDecline: (deifChangesDeclinedReason: string) => void;
  error: ResponseError | null;
  signerMode: SignerMode;
};

export function ConfirmDialogDeclineAllChanges(props: Props) {
  const { isVisible, isLoading, onCancel, onDecline, error, signerMode } = props;

  const [deifChangesDeclinedReason, setDeifChangesDeclinedReason] = useState("");

  const isMobile = !useBreakpoint().md;
  const Stack = (props: StackProps) =>
    isMobile ? <StackY {...props}></StackY> : <StackX {...props}></StackX>;

  const descriptiveTextLine1 =
    signerMode === "inside"
      ? "If any of these edits are incorrect, please note the specific items below and select to decline all changes."
      : "If any of these edits are incorrect, please describe the needed changes below and select Decline all changes.";

  const descriptiveTextLine2 =
    signerMode === "inside"
      ? "All edits to the form will show as declined, and your implementation consultant will reach out to you to resolve any issues."
      : "All edits to the form will show as declined, and your Implementation Consultant will reach out to you to resolve any issues.";

  const descriptiveTextLine3 =
    signerMode === "inside"
      ? "You’ll then have a chance to review again and accept all final changes."
      : "You’ll have a chance to review again and accept all final changes.";

  const declinedReasonPlaceholder =
    signerMode === "inside"
      ? "Please describe what was wrong here"
      : "Please describe what was wrong (optional)";

  return (
    <Modal
      title={"Are you sure you want to decline the changes?"}
      open={isVisible}
      footer={null}
      onCancel={onCancel}
      focusTriggerAfterClose={false}
      disableClose={isLoading}
      destroyOnClose
    >
      <p>{descriptiveTextLine1}</p>

      <p>{descriptiveTextLine2}</p>

      <p>{descriptiveTextLine3}</p>

      <TextArea
        name="deifChangesDeclinedReason"
        label=""
        aria-label={declinedReasonPlaceholder}
        placeholder={declinedReasonPlaceholder}
        disabled={isLoading}
        onChange={(e) => setDeifChangesDeclinedReason(e.currentTarget.value)}
        value={deifChangesDeclinedReason}
        touched={false}
        error={undefined}
        maxLength={1000}
      />

      {error && (
        <div className="mt-20">
          <ErrorMessage>
            {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
          </ErrorMessage>
        </div>
      )}

      <div className="mt-40">
        <Stack dist={16}>
          <Button
            type="primary"
            size="middle"
            onClick={() => onDecline(deifChangesDeclinedReason)}
            disabled={isLoading}
          >
            Decline all changes
          </Button>
          <Button type="default" size="middle" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
        </Stack>
      </div>
    </Modal>
  );
}
