import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { Row } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { useUpdateClient } from "client/src/hooks/client";
import { triggerError } from "client/src/hooks/generalError";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { useState, useCallback } from "react";

import { clientValidationSchema } from "shared/validation/client";
import { ReactComponent as PencilIcon } from "../../../components/Icons/PencilIcon.svg";
import { InternalControl } from "../../../components/InternalControl/InternalControl";
import { Loading } from "../../../components/Loading/Loading";
import { AddFile } from "../../../domain/Document/AddFile/AddFile";
import { DocumentDownload } from "../../../domain/Document/DocumentDownload/DocumentDownload";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { Client } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type CardCensusTemplatesProps = {
  client: Client;
  isLoading: boolean;
  category: DocumentCategory;
  censusTemplatesStatus: string;
  featureToggles: ClientFeatureToggles;
};

export const CardCensusTemplates = ({
  client,
  isLoading,
  category,
  censusTemplatesStatus,
  featureToggles,
}: CardCensusTemplatesProps) => {
  const [showInternalEditPopover, setShowInternalEditPopover] = useState(false);
  const taskName = "Submit Enrollment Elections";
  const trackElementClicked = useTrackElementClicked(client, taskName);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: censusTemplatesStatus,
        module: "Client Setup - Step 3",
        buttonLabel,
      });
    },
    [trackElementClicked, censusTemplatesStatus],
  );

  const {
    mutateAsync: updateClient,
    isPending: updateClientPending,
    error: updateClientError,
  } = useUpdateClient();

  const setupStepName = featureToggles.ONBOARD_ASO_DENTAL ? "Task Settings" : "Add census template";

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(["deductibleMaximumReportTask"]),
    updateClient,
    validationSchema: clientValidationSchema.pick(["deductibleMaximumReportTask"]),
    type: `Client Setup Steps: ${setupStepName}`,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    prefill: false,
  });

  if (updateClientError) {
    return triggerError("Error updating client information");
  }

  if (isLoading) return <Loading pageHeight={false} />;

  const showDeductibleMaximumReportTaskCheckbox =
    featureToggles.ONBOARD_ASO_DENTAL && client.allPoliciesSlfCoverages?.includes("ASO Dental");

  return (
    <>
      <p>
        When the census template for this Client is ready, you should upload it to Onboard. Use the
        upload option below or navigate to the Client’s {taskName} task page.
      </p>
      <StackY dist={24}>
        <InternalControl
          buttonIcon={<PencilIcon />}
          buttonLabel="Edit"
          position="attached"
          popoverTrigger="click"
          popoverVisible={showInternalEditPopover}
          onPopoverVisibleChange={setShowInternalEditPopover}
          onClick={() => track("Edit")}
          popoverContent={
            <AddFile
              clientId={client.id}
              category={category}
              addDocumentsLabel="Upload census template"
              replaceDocumentsLabel="Replace census template"
              deleteDocumentsLabel="Delete File"
              onClick={() => setShowInternalEditPopover(false)}
              track={track}
            />
          }
          extraContent={
            <DocumentDownload clientId={client.id} categories={[category]} track={track} />
          }
        />
        {showDeductibleMaximumReportTaskCheckbox && (
          <form onSubmit={formik.handleSubmit}>
            <StackY dist={16}>
              <div>
                <Body2 as="div">Dental ASO deductible maximum report</Body2>
                <Body3>
                  If the client would like to provide a deductible maximum report, please toggle
                  this task to be active.
                </Body3>
              </div>
              <Checkbox
                checked={formik.values.deductibleMaximumReportTask ?? false}
                onChange={formik.handleChange}
                label={"Activate deductible maximum report task"}
                name={"deductibleMaximumReportTask"}
                disabled={formik.isSubmitting}
                errorId={formik.errors.deductibleMaximumReportTask}
                onBlur={formik.handleBlur}
              />
            </StackY>
            <Row justify="end" className="mt-32">
              <Button
                type="secondary"
                htmlType="submit"
                size="middle"
                disabled={formik.isSubmitting || updateClientPending}
                loading={formik.isSubmitting}
                aria-label={formik.isSubmitting ? "Save" : undefined}
              >
                Save changes
              </Button>
            </Row>
          </form>
        )}
      </StackY>
    </>
  );
};
