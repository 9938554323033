import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useState, useCallback } from "react";
import { RouteData } from "shared/config/routeData";

import { InternalLinkButton } from "../../components/Button/InternalLinkButton";
import { StackY, StackX } from "../../components/Spacing/Spacing";
import { useCloseOnLeaveFocus } from "../../hooks/useCloseOnLeaveFocus";
import { useCloseOnPressEscape } from "../../hooks/useCloseOnPressEscape";

import * as styles from "./usersNavPopover.module.less";

type UsersNavPopoverProps = {
  showUserInternalLink: boolean;
};

export function UsersNavPopover({ showUserInternalLink }: UsersNavPopoverProps) {
  const [popoverVisible, setPopoverVisible] = useState(false);

  const visibleChangeClick = useCallback((visible: boolean) => {
    setPopoverVisible(visible);
  }, []);

  useCloseOnPressEscape({ popoverVisible, setPopoverVisible });

  const containerRef = useCloseOnLeaveFocus({ popoverVisible, setPopoverVisible });

  return (
    <span ref={containerRef}>
      <Popover
        getPopupContainer={() => {
          if (containerRef.current) {
            return containerRef.current;
          }
          throw new Error("Container ref not initialized");
        }}
        trigger={["click"]}
        placement="bottomRight"
        open={popoverVisible}
        onOpenChange={visibleChangeClick}
        overlayClassName={styles.popover}
        content={
          <div>
            <StackY dist={12}>
              <div>
                <InternalLinkButton
                  type="link-inline-bold"
                  size="middle"
                  to={RouteData.brokers.getPath()}
                  onClick={() => visibleChangeClick(false)}
                >
                  Brokers
                </InternalLinkButton>
              </div>
              {showUserInternalLink && (
                <div>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={RouteData.usersInternal.getPath()}
                    onClick={() => visibleChangeClick(false)}
                  >
                    Internal users
                  </InternalLinkButton>
                </div>
              )}
              <div>
                <InternalLinkButton
                  type="link-inline-bold"
                  size="middle"
                  to={RouteData.benAdmins.getPath()}
                  onClick={() => visibleChangeClick(false)}
                >
                  Benefits administrator
                </InternalLinkButton>
              </div>
            </StackY>
          </div>
        }
      >
        {/* @todo: FP-3498: move to NavLink component (yellow underline) */}
        <ButtonOld
          size="middle"
          type="link-inline-bold-deprecated"
          onClick={() => setPopoverVisible((prev) => !prev)}
        >
          <StackX dist={8}>
            <span>Users</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </StackX>
        </ButtonOld>
      </Popover>
    </span>
  );
}
