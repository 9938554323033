import {
  getIsLifeADDContributionBenefitType,
  getIsDisabilityContributionBenefitType,
  getIsFourTierIndividualContributionBenefitType,
  getIsSupplementalHealthBenefitType,
} from "shared/types/BenefitTypes";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { assertIsDefined } from "shared/utils/utils";
import {
  nullNotAllowedTestConfig,
  percentageValidationSchema,
  yesNoSharedRequired,
} from "shared/validation/validation";
import * as Yup from "yup";
import {
  employerContributionOptions,
  employerPremiumPayments,
  requirePremiumPayments,
  shouldHaveContributionValues,
} from "../types/EmployeeClassPlan";
import type {
  WaitingPeriodUnit,
  WaitingPeriod,
  EmployerContributionOption,
  EmployerPremiumPayments,
  TaxChoiceOption,
} from "../types/EmployeeClassPlan";
import type { BenefitTypeEIF, EmployerContributionUnit, YesNo } from "@prisma/client";
import type { ValuesForValidationSchema } from "shared/types/Helper";

export const waitingPeriodType = Yup.mixed<WaitingPeriod>()
  .oneOf<WaitingPeriod>(["NONE", "DAY_MONTH", "FOFM", "FOFM_INCLUSIVE"])
  .nullable();
export const waitingPeriodUnit = Yup.mixed<WaitingPeriodUnit>()
  .oneOf<WaitingPeriodUnit>(["MONTH", "DAY"])
  .nullable();
export const employerContributionUnit = Yup.mixed<EmployerContributionUnit>()
  .oneOf<EmployerContributionUnit>(["DOLLAR", "PERCENT"])
  .nullable();

export const employerContributionOptionsSchema = Yup.mixed<EmployerContributionOption>()
  .oneOf<EmployerContributionOption>(employerContributionOptions)
  .nullable();

export const taxChoiceSchema = Yup.array().of(Yup.mixed<TaxChoiceOption>().required()).nullable();

export type EmployeeClassPlanValidationSchema = ValuesForValidationSchema<
  typeof employeeClassPlanValidationSchema
>;

const contributionAmountValidation = Yup.number()
  .nullable()
  .typeError("Numbers only please")
  .when(["employerContribution", "$benefitType", "$prefill"], {
    is: (
      employerContribution: YesNo,
      benefitType: BenefitTypeEIF | undefined,
      prefill: boolean | undefined,
    ) => {
      assertIsDefined(benefitType, "benefitType");
      const doesNotRequireContributionValues =
        !shouldHaveContributionValues(benefitType, employerContribution) && !prefill;
      return doesNotRequireContributionValues;
    },
    then: (schema) => schema,
    otherwise: (schema) =>
      schema.when(["employerContribution", "employerContributionUnit", "$benefitType"], {
        is: (
          employerContribution: EmployerContributionOption | null,
          unit: EmployerContributionUnit,
          benefitType: BenefitTypeEIF | undefined,
        ) => {
          assertIsDefined(benefitType, "benefitType");
          const requiresPercentValidation =
            (unit === "PERCENT" && !getIsDisabilityContributionBenefitType(benefitType)) ||
            (getIsDisabilityContributionBenefitType(benefitType) &&
              employerContribution === "SHARED");
          return requiresPercentValidation;
        },
        then: (schema) => {
          return schema.when(["$prefill"], {
            is: (prefill: boolean | undefined) => !!prefill,
            then: () => percentageValidationSchema.optional().nullable(),
            otherwise: () => percentageValidationSchema,
          });
        },
        otherwise: (schema) => {
          return schema.when(["employerContribution", "$benefitType", "$prefill"], {
            is: (
              employerContribution: EmployerContributionOption | null,
              benefitType: BenefitTypeEIF | undefined,
              prefill: boolean | undefined,
            ) => {
              assertIsDefined(benefitType, "benefitType");
              const eeContributionNotRequiredForDisabilityIfEmployerContribIsYes =
                getIsDisabilityContributionBenefitType(benefitType) &&
                employerContribution === "YES";
              const doesNotRequireContributionValues =
                !!prefill || eeContributionNotRequiredForDisabilityIfEmployerContribIsYes;
              return doesNotRequireContributionValues;
            },
            then: (schema) => schema,
            otherwise: (schema) =>
              schema.required("Please enter an amount").typeError("Please enter an amount"),
          });
        },
      }),
  });

const contributionAmountValidationOldAttrs = Yup.number().when(
  ["useIndividualRoleAttrs", "$benefitType"],
  {
    is: (useIndividualRoleAttrs: boolean, benefitType: BenefitTypeEIF) => {
      assertIsDefined(useIndividualRoleAttrs, "useIndividualRoleAttrs");
      assertIsDefined(benefitType, "benefitType");
      return (
        !useIndividualRoleAttrs && !getIsFourTierIndividualContributionBenefitType(benefitType)
      );
    },
    then: (_) => contributionAmountValidation,
    otherwise: (schema) => schema.nullable(),
  },
);

const contributionAmountValidationNewAttrs = Yup.number().when(["useIndividualRoleAttrs"], {
  is: true,
  then: (_) => contributionAmountValidation,
  otherwise: (schema) => schema.nullable(),
});

const contributionAmountValidationNewFourthTierAttrs = Yup.number()
  .nullable()
  .when(["$benefitType"], {
    is: (benefitType: BenefitTypeEIF) => {
      assertIsDefined(benefitType, "benefitType");
      return getIsFourTierIndividualContributionBenefitType(benefitType);
    },
    then: (_) => contributionAmountValidation,
    otherwise: (schema) => schema.nullable(),
  });

export const employeeClassPlanValidationSchema = Yup.object({
  id: Yup.string().required(),
  employeeClassId: Yup.string().required(),
  planId: Yup.string().required(),
  waitingPeriodType: waitingPeriodType.nullable(),
  waitingPeriodDuration: Yup.number().nullable().moreThan(0),
  waitingPeriodUnit: waitingPeriodUnit.nullable(),
  employerContribution: employerContributionOptionsSchema,
  employerContributionUnit: employerContributionUnit.when(
    ["employerContribution", "$benefitType", "$prefill"],
    {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return (
          shouldHaveContributionValues(benefitType, employerContribution) &&
          !prefill &&
          !getIsLifeADDContributionBenefitType(benefitType)
        );
      },
      then: (schema) => schema.required("Please choose percent or dollars"),
    },
  ),
  eeContributionAmount: contributionAmountValidation
    .nullable()
    .when(["employerContribution", "$benefitType", "$prefill"], {
      is: (
        employerContribution: EmployerContributionOption | null,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        if (getIsLifeADDContributionBenefitType(benefitType)) {
          return employerContribution === "YES" && !prefill;
        }

        if (getIsDisabilityContributionBenefitType(benefitType)) {
          return employerContribution === "SHARED" && !prefill;
        }

        if (benefitType === "DENTAL" || benefitType === "VISION") {
          const requireDentalAndVisionContribution = employerContribution === "SHARED" && !prefill;
          return requireDentalAndVisionContribution;
        }

        return false;
      },
      then: (schema) => schema.required("Please enter an amount"),
    }),
  spouseContributionAmount: Yup.number()
    .nullable()
    .when(["eeAndSpouseContributionAmount"], {
      is: (eeAndSpouseContributionAmount: number | null | undefined) =>
        eeAndSpouseContributionAmount,
      then: (schema) => schema,
      otherwise: (_) =>
        contributionAmountValidation
          .nullable()
          .when(["employerContribution", "$benefitType", "$prefill"], {
            is: (
              employerContribution: EmployerContributionOption | null,
              benefitType: BenefitTypeEIF | undefined,
              prefill: boolean | undefined,
            ) => {
              assertIsDefined(benefitType, "benefitType");

              if (benefitType === "DENTAL" || benefitType === "VISION") {
                const requireDentalAndVisionContribution =
                  employerContribution === "SHARED" && !prefill;
                return requireDentalAndVisionContribution;
              }

              return false;
            },
            then: (schema) => schema.required("Please enter an amount"),
          })
          .when(["$benefitType"], {
            is: (benefitType: BenefitTypeEIF | undefined) => {
              assertIsDefined(benefitType, "benefitType");

              return getIsLifeADDContributionBenefitType(benefitType);
            },
            then: (schema) => schema.nullable(),
          })
          .when(["$benefitType"], {
            is: (benefitType: BenefitTypeEIF | undefined) => {
              assertIsDefined(benefitType, "benefitType");

              return getIsDisabilityContributionBenefitType(benefitType);
            },
            then: (schema) => schema.nullable(),
          }),
    }),
  childrenContributionAmount: Yup.number()
    .nullable()
    .when(["eeAndChildrenContributionAmount"], {
      is: (eeAndChildrenContributionAmount: number | null | undefined) =>
        eeAndChildrenContributionAmount,
      then: (schema) => schema,
      otherwise: (_) =>
        contributionAmountValidation
          .nullable()
          .when(["employerContribution", "$benefitType", "$prefill"], {
            is: (
              employerContribution: EmployerContributionOption | null,
              benefitType: BenefitTypeEIF | undefined,
              prefill: boolean | undefined,
            ) => {
              assertIsDefined(benefitType, "benefitType");

              if (benefitType === "DENTAL" || benefitType === "VISION") {
                const requireDentalAndVisionContribution =
                  employerContribution === "SHARED" && !prefill;
                return requireDentalAndVisionContribution;
              }

              return false;
            },
            then: (schema) => schema.required("Please enter an amount"),
          })
          .when(["$benefitType"], {
            is: (benefitType: BenefitTypeEIF | undefined) => {
              assertIsDefined(benefitType, "benefitType");

              return getIsLifeADDContributionBenefitType(benefitType);
            },
            then: (schema) => schema.nullable(),
          })
          .when(["$benefitType"], {
            is: (benefitType: BenefitTypeEIF | undefined) => {
              assertIsDefined(benefitType, "benefitType");

              return getIsDisabilityContributionBenefitType(benefitType);
            },
            then: (schema) => schema.nullable(),
          }),
    }),
  eeAndSpouseContributionAmount: Yup.number()
    .nullable()
    .when(["employerContributionUnit"], {
      is: (employerContributionUnit: EmployerContributionUnit) =>
        employerContributionUnit === "PERCENT",
      then: (_) => percentageValidationSchema.nullable(),
      otherwise: (_) => Yup.number().nullable(),
    }),
  eeAndChildrenContributionAmount: Yup.number()
    .nullable()
    .when(["employerContributionUnit"], {
      is: (employerContributionUnit: EmployerContributionUnit) =>
        employerContributionUnit === "PERCENT",
      then: (_) => percentageValidationSchema.nullable(),
      otherwise: (_) => Yup.number().nullable(),
    }),
  eeAndFamilyContributionAmount: Yup.number()
    .nullable()
    .when(["employerContributionUnit"], {
      is: (employerContributionUnit: EmployerContributionUnit) =>
        employerContributionUnit === "PERCENT",
      then: (_) => percentageValidationSchema.nullable(),
      otherwise: (_) => Yup.number().nullable(),
    }),
  familyContributionAmount: contributionAmountValidation
    .nullable()
    .when(["employerContribution", "$benefitType", "$prefill"], {
      is: (
        employerContribution: EmployerContributionOption | null,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        if (shouldHaveContributionValues(benefitType, employerContribution)) {
          return !prefill;
        }

        return false;
      },
      then: (schema) => schema.required("Please enter an amount"),
    })
    .when(["$benefitType"], {
      is: (benefitType: BenefitTypeEIF | undefined) => {
        assertIsDefined(benefitType, "benefitType");

        return !getIsFourTierIndividualContributionBenefitType(benefitType);
      },
      then: (schema) => schema.nullable(),
    }),
  employerPremiumPayments: Yup.mixed<EmployerPremiumPayments>()
    .oneOf(employerPremiumPayments)
    .nullable()
    .when(["employerContribution", "$benefitType", "$prefill"], {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return requirePremiumPayments(benefitType, employerContribution) && !prefill;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
  threeYearLookBackPercent: percentageValidationSchema
    .nullable()
    .when(["employerContribution", "employerPremiumPayments", "$prefill", "$benefitType"], {
      is: (
        employerContribution: EmployerContributionOption | null,
        employerPremiumPayments: EmployerPremiumPayments | null,
        prefill: boolean | undefined,
        benefitType: BenefitTypeEIF | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return (
          employerContribution === "SHARED" &&
          !getIsSupplementalHealthBenefitType(benefitType) &&
          employerPremiumPayments === "POST_TAX" &&
          !prefill
        );
      },
      then: (schema) => schema.required("Please enter a three-year look-back amount"),
    }),
  taxChoice: taxChoiceSchema
    .nullable()
    .test("valid-option-combinations", "Invalid option combination", (options) => {
      if (!options || options.length !== 2) {
        return true;
      }
      const referenceOption = options[0];
      const pairToTestOption = options[1];

      if (referenceOption == null || pairToTestOption == null) return false;

      const isAvailableOption =
        availableCombinationTaxChoice(referenceOption).includes(pairToTestOption);

      return isAvailableOption;
    })
    .when(["employerContribution", "$prefill"], {
      is: (employerContribution: EmployerContributionOption, prefill: boolean | undefined) =>
        employerContribution === "TAX_CHOICE" && !prefill,
      then: (schema) => {
        const errorMsg = "Please choose exactly two tax choice options";
        return schema.max(2, errorMsg).min(2, errorMsg).required(errorMsg);
      },
    }),
});

export const newEmployeeClassPlanValidationSchema = employeeClassPlanValidationSchema.omit([
  "id",
  "employerContribution",
]);

export const editContributionsForEmployeeClassPlanValidationSchema = Yup.object({
  employerContribution: yesNoSharedRequired.nullable(),
  employerContributionUnit: employerContributionUnit.when(
    ["employerContribution", "$benefitType", "$prefill"],
    {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return shouldHaveContributionValues(benefitType, employerContribution) && !prefill;
      },
      then: (schema) => schema.required("Please choose percent or dollars"),
    },
  ),
  useIndividualRoleAttrs: Yup.boolean().required(),
  eeContributionAmountLow: contributionAmountValidation,
  spouseContributionAmountLow: contributionAmountValidationNewAttrs,
  childrenContributionAmountLow: contributionAmountValidationNewAttrs,
  eeAndSpouseContributionAmountLow: contributionAmountValidationOldAttrs,
  eeAndChildrenContributionAmountLow: contributionAmountValidationOldAttrs,
  eeAndFamilyContributionAmountLow: contributionAmountValidationOldAttrs,
  eeContributionAmountHigh: contributionAmountValidation,
  spouseContributionAmountHigh: contributionAmountValidationNewAttrs,
  childrenContributionAmountHigh: contributionAmountValidationNewAttrs,
  eeAndSpouseContributionAmountHigh: contributionAmountValidationOldAttrs,
  eeAndChildrenContributionAmountHigh: contributionAmountValidationOldAttrs,
  eeAndFamilyContributionAmountHigh: contributionAmountValidationOldAttrs,
  familyContributionAmountLow: contributionAmountValidationNewFourthTierAttrs,
  familyContributionAmountHigh: contributionAmountValidationNewFourthTierAttrs,
  employerPremiumPayments: Yup.mixed<EmployerPremiumPayments>()
    .oneOf(employerPremiumPayments)
    .nullable()
    .when(["employerContribution", "$benefitType", "$prefill"], {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return requirePremiumPayments(benefitType, employerContribution) && !prefill;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
});

export type editContributionsForEmployeeClassPlanValues = ValuesForValidationSchema<
  typeof editContributionsForEmployeeClassPlanValidationSchema
>;

export const editContributionsForSuppHealthPlanValidationSchema = Yup.object({
  employerContribution: yesNoSharedRequired.nullable(),
  employerContributionUnit: employerContributionUnit.when(
    ["employerContribution", "$benefitType", "$prefill"],
    {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return shouldHaveContributionValues(benefitType, employerContribution) && !prefill;
      },
      then: (schema) => schema.required("Please choose percent or dollars"),
    },
  ),
  eeContributionAmount: contributionAmountValidation,
  spouseContributionAmount: contributionAmountValidation,
  childrenContributionAmount: contributionAmountValidation,
  familyContributionAmount: contributionAmountValidationNewFourthTierAttrs,
  employerPremiumPayments: Yup.mixed<EmployerPremiumPayments>()
    .oneOf(employerPremiumPayments)
    .defined()
    .nullable()
    .when(["employerContribution", "$benefitType", "$prefill"], {
      is: (
        employerContribution: YesNo,
        benefitType: BenefitTypeEIF | undefined,
        prefill: boolean | undefined,
      ) => {
        assertIsDefined(benefitType, "benefitType");
        return requirePremiumPayments(benefitType, employerContribution) && !prefill;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
}).concat(employeeClassPlanValidationSchema.pick(["taxChoice"]));

export type editContributionsForSuppHealthPlanValidationSchema = ValuesForValidationSchema<
  typeof editContributionsForSuppHealthPlanValidationSchema
>;

export const benefitTypesValidator = Yup.array()
  .of(Yup.mixed<BenefitTypeEIF>().required())
  .test(
    "cannot include dep life or basic ad&d without basic life",
    `You must select ${benefitTypeToCoverage["LIFE"]} if you selected ${benefitTypeToCoverage["DEP_LIFE"]} or ${benefitTypeToCoverage["BASIC_ADND"]}`,
    (benefitTypes) => {
      const isInvalid =
        !benefitTypes?.includes("LIFE") &&
        (benefitTypes?.includes("DEP_LIFE") || benefitTypes?.includes("BASIC_ADND"));
      return !isInvalid;
    },
  );

export const disabilityContributionsValidationSchema = employeeClassPlanValidationSchema.pick([
  "employerContribution",
  "eeContributionAmount",
  "employerPremiumPayments",
  "threeYearLookBackPercent",
  "taxChoice",
]);

export const voluntaryContributionsValidationSchema = employeeClassPlanValidationSchema.pick([
  "employerContribution",
  "employerPremiumPayments",
]);

export const employerContributionOptionsRequired = employerContributionOptionsSchema.test(
  nullNotAllowedTestConfig(),
);

export function availableCombinationTaxChoice(
  taxChoice: TaxChoiceOption | null,
): TaxChoiceOption[] {
  if (!taxChoice) {
    return [];
  }

  switch (taxChoice) {
    case "100_EMPLOYER": {
      return ["GROSS_UP", "100_EMPLOYEE_POST_TAX"];
    }
    case "GROSS_UP": {
      return ["100_EMPLOYER"];
    }
    case "100_EMPLOYEE_PRE_TAX": {
      return ["100_EMPLOYEE_POST_TAX"];
    }
    case "100_EMPLOYEE_POST_TAX": {
      return ["100_EMPLOYER", "100_EMPLOYEE_PRE_TAX"];
    }
    default:
      exhaustiveCheckFail(taxChoice);
  }
}
