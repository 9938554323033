import clsx from "clsx";

import { useSlobId } from "../../hooks/useSlobId";
import { InputErrorMessage } from "../Form/InputErrorMessage";
import { Checkmark } from "../Icons/Checkmark";

import * as styles from "./checkbox.module.less";
import type { InputHTMLAttributes } from "react";

export interface CheckboxChangeEvent {
  target: CheckboxChangeEventTarget;
  stopPropagation: () => void;
  preventDefault: () => void;
  nativeEvent: React.ChangeEvent<HTMLInputElement>["nativeEvent"];
}

export interface CheckboxChangeEventTarget extends CheckboxProps {
  checked: boolean;
  value: string;
}

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "content"> {
  onChange?: (e: CheckboxChangeEvent) => void;
  id?: string;
  variant?: "primary" | "secondary" | "tertiary";
  errorId?: string;
  label: React.ReactNode;
  error?: string;
  indeterminate?: boolean;
  content?: React.ReactNode;
  value?: string | number;
}

export function Checkbox(props: CheckboxProps) {
  const {
    id: providedId,
    variant = "primary",
    role,
    type = "checkbox",
    indeterminate,
    checked,
    value,
    label,
    name,
    content,
    onChange,
    disabled,
    errorId,
    error,
    ...rest
  } = props;
  const computedId = useSlobId({ prefix: name });

  const id = providedId || computedId;
  const externalErrorId = errorId;
  const internalErrorId = error && `${id}__errormessage`;
  const hasError = Boolean(externalErrorId || internalErrorId);
  const ariaErrorMessage = externalErrorId || internalErrorId;
  const ariaChecked = indeterminate ? "mixed" : checked;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }

    onChange?.({
      target: {
        ...props,
        type,
        checked: e.target.checked,
        value: e.target.value,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  };

  return (
    <div data-component="Checkbox" data-name={name}>
      <label role={role} className={clsx(styles.container, disabled && styles.disabled)}>
        <span className={styles.wrapper}>
          <input
            name={name}
            id={id}
            type="checkbox"
            value={value}
            onChange={handleChange}
            aria-checked={ariaChecked}
            aria-errormessage={ariaErrorMessage}
            aria-invalid={hasError}
            checked={checked}
            disabled={disabled}
            {...rest}
          />
          <span
            className={clsx(styles.checkbox, {
              [styles.checked]: checked === true,
              [styles.disabled]: disabled,
              [styles.error]: hasError,
            })}
          >
            {checked && <Checkmark />}
            {!checked && indeterminate && <span className={styles.dividerIcon} />}
          </span>
        </span>
        <span
          className={clsx(styles.label, {
            body3: variant === "primary",
            [styles.secondaryVariant]: variant === "secondary",
            h5: variant === "tertiary",
          })}
        >
          {label}
        </span>
      </label>

      {content && <div className={clsx("ml-32", disabled && styles.disabled)}>{content}</div>}

      <div aria-live="assertive">
        {error && <InputErrorMessage id={internalErrorId} error={error} />}
      </div>
    </div>
  );
}
