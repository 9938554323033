import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { AvoidPageBreak } from "client/src/utils/print";
import { employerPremiumPaymentsValuesRecord } from "shared/types/EmployeeClassPlan";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { voluntaryContributionsValidationSchema } from "shared/validation/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot | null;
  authUser: UserData | null;
  benefitType: "VOL_STD" | "VOL_LTD";
};

export function ReviewVoluntaryContributions(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser, benefitType } = props;

  const relevantEmployeeClassPlan = employeeClass.employeeClassPlans.find(
    (p) => p.plan.benefitType === benefitType,
  );

  const employeeClassPlanIds = relevantEmployeeClassPlan ? [relevantEmployeeClassPlan.id] : [];

  const changeDetailInfoList = relevantChangesForContributionFields(
    employeeClassPlanIds,
    changeSnapshot,
  );

  const viewMode = getEIFSubStepViewMode({ client });

  const strictErrors =
    getShowValidationErrorsInSummary(viewMode, changeSnapshot) && relevantEmployeeClassPlan
      ? getFormikErrors(relevantEmployeeClassPlan, voluntaryContributionsValidationSchema, {
          prefill: false,
          benefitType: relevantEmployeeClassPlan.plan.benefitType,
        })
      : {};

  return (
    <StackY dist={16} wrap={false}>
      <EditedFieldMsg
        changeDetailInfoList={changeDetailInfoList}
        client={client}
        authUser={authUser}
      />

      <section>
        <AvoidPageBreak>
          {relevantEmployeeClassPlan?.employerContribution !== null && (
            <>
              <StackY dist={8}>
                <Body3>
                  100% employee paid,{" "}
                  {relevantEmployeeClassPlan?.employerPremiumPayments ? (
                    employerPremiumPaymentsValuesRecord[
                      relevantEmployeeClassPlan.employerPremiumPayments
                    ]
                  ) : strictErrors.employerPremiumPayments ? (
                    <Body3 redMedium>Please provide a premium payment response</Body3>
                  ) : (
                    "-"
                  )}
                </Body3>
              </StackY>
            </>
          )}
        </AvoidPageBreak>
      </section>
    </StackY>
  );
}
