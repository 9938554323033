import { Typography } from "antd";
import { formatDate } from "shared/utils/format";

import { unique } from "shared/utils/utils";
import * as styles from "./form.module.less";

import type { ClientBaseInfoResponse } from "shared/types/Client";

const { Text } = Typography;

type AddClientInfoProps = {
  clientInfo: ClientBaseInfoResponse;
};

export const AddClientInfo = ({ clientInfo }: AddClientInfoProps) => {
  if (!clientInfo) return null;

  const uniquePolicyEffectiveDates = unique(clientInfo.policyEffective).filter((d): d is string =>
    Boolean(d),
  );

  const policyEffectiveDates = uniquePolicyEffectiveDates
    .map((d) => new Date(d))
    .sort((a, b) => a.getTime() - b.getTime())
    .map((p) => formatDate(p));

  const policyEffectiveValue = policyEffectiveDates.join(", ").replace(/, ([^,]*)$/, " and $1");

  return (
    <>
      {clientInfo?.name && (
        <div>
          <Text className={styles.clientFound}>Client Found:</Text>
          <div className={styles.infoBox}>
            <p className={styles.clientName}>{clientInfo.name}</p>
            Case ID: {clientInfo?.caseId}
            <br />
            Effective Dates: {policyEffectiveValue}
          </div>
        </div>
      )}
    </>
  );
};
