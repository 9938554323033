import { Modal } from "antd";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useListParams } from "client/src/hooks/useListParams";
import { useState } from "react";

import { SlobList } from "../../components/SlobList/SlobList";
import { BrokerUpdateForm } from "../../domain/User/Form/BrokerUpdateForm";
import { BrokerTable } from "../../domain/User/List/BrokerTable";
import { useGetBrokers } from "../../hooks/user";
import type { ClientFeatureToggles } from "shared/types/Toggles";

import type { Broker } from "shared/types/User";

export const BROKER_LIST_PAGE_SIZE = 25;

export const BrokerList = ({
  namespace,
  featureToggles,
}: {
  featureToggles?: ClientFeatureToggles;
  namespace?: string;
}) => {
  const [editState, setEditState] = useState<Broker | null>(null);

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (broker: Broker) => {
    setEditState(broker);
  };

  const listParams = useListParams({
    initialPageSize: BROKER_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "lastName",
    },
    listNamespace: namespace,
  });

  const { clientId } = useClientHubParams();

  const query = useGetBrokers({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...(clientId && { clientId }),
  });

  return (
    <>
      <SlobList
        TableComponent={BrokerTable}
        query={query}
        listParams={listParams}
        showModal={showModal}
        namespace={namespace}
        featureToggles={featureToggles}
      />
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <BrokerUpdateForm broker={editState} handleClose={hideModal} />
        </Modal>
      )}
    </>
  );
};
