import { Button } from "client/src/components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { useUpdateClient } from "client/src/hooks/client";
import { ResponseError } from "client/src/hooks/query";
import { useToggler } from "client/src/hooks/useToggler";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import * as Yup from "yup";
import type { Client } from "shared/types/Client";

type Props = {
  client: Client;
};

const Header = () => (
  <>
    <Body2>Disable due date emails</Body2> <br />
    <Body5 as="p">
      Turning this on disables due date emails for both the Broker and the Benefits Administrator.
    </Body5>
  </>
);

export function NotificationEmails(props: Props) {
  const { client } = props;

  const formKeyProps: (keyof Client)[] = ["limitDueDateEmails"];

  const [editMode, toggleEditMode] = useToggler();

  const { mutateAsync: updateClient, error } = useUpdateClient();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: Yup.object({
      limitDueDateEmails: Yup.boolean(),
    }),
    type: "Notifications",
    track: () => {
      /* noop */
    },
    formikOptions: { enableReinitialize: true },
    onSuccessCallback: toggleEditMode,
  });

  const toggleEditModeAndResetForm = () => {
    toggleEditMode();
    formik.resetForm();
  };

  return (
    <HubCard>
      {editMode ? (
        <form onSubmit={formik.handleSubmit}>
          <Header />
          <StackY dist={16} wrap={false}>
            <Checkbox
              name="limitDueDateEmails"
              checked={!!formik.values.limitDueDateEmails}
              label="Disable Due Date emails"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
            />
            <Row justify="end">
              <Col>
                <Row align="middle" gutter={24}>
                  <Col>
                    <Button
                      type="text"
                      onClick={toggleEditModeAndResetForm}
                      size="middle"
                      disabled={formik.isSubmitting}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      type="secondary"
                      size="middle"
                      disabled={formik.isSubmitting}
                      htmlType="submit"
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div aria-live="assertive" className="hide:empty">
              {error && (
                <ErrorMessage>
                  {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
                </ErrorMessage>
              )}
            </div>
          </StackY>
        </form>
      ) : (
        <Row justify="space-between" align="middle">
          <Col>
            <Header />
            <Body3>{client.limitDueDateEmails ? "On" : "Off"}</Body3>
          </Col>

          <Col>
            <Button type="text" onClick={toggleEditModeAndResetForm} size="middle">
              Edit
            </Button>
          </Col>
        </Row>
      )}
    </HubCard>
  );
}
