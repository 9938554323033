import clsx from "clsx";
import { ReactComponent as BulbIcon } from "../Icons/bulb.svg";

import * as styles from "./bulbMessage.module.less";
import type { ReactNode } from "react";

type BulbMessageProps = {
  bulbPosition?: "centered" | "top";
  tighter?: boolean;
  children: ReactNode;
};

export const BulbMessage = ({ bulbPosition = "centered", tighter, children }: BulbMessageProps) => {
  return (
    <div className={clsx(styles.bulbMessage, "stack-x-16", tighter && styles.tighter)}>
      <BulbIcon className={clsx(bulbPosition === "top" && styles.bulbIconTop)} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};
