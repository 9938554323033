import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { ReviewTierContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/ReviewTierContributions";
import { EIFClassBuilderItemEligibility } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemEligibility";
import { useToggler } from "client/src/hooks/useToggler";
import { waitingPeriodTypeToReadableType } from "shared/types/EmployeeClassPlan";
import { isQPSBasicClass } from "shared/types/QPSClass";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import * as styles from "./eifPushToQPS.module.less";
import type { AssignBenefitsToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStep2";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

type ClassMappedSummaryItemProps = {
  index: number;
  client: Client;
  employeeClasses: EmployeeClass[];
  authUser: UserData;
  mappedQPSClass: AssignBenefitsToAdminClassesMapping;
  editButtonAction: () => void;
};

export const ClassMappedSummaryItem = ({
  index,
  client,
  employeeClasses,
  mappedQPSClass,
  authUser,
  editButtonAction,
}: ClassMappedSummaryItemProps) => {
  const [hideDetails, toggleHideDetails] = useToggler();
  const employeeClass = employeeClasses.find(({ id }) => id === mappedQPSClass.employeeClassId);
  if (!employeeClass) {
    return null;
  }

  const employeeClassPlansToShow = employeeClass.employeeClassPlans.reduce<
    EmployeeClassPlanWithPlan[]
  >((acc, employeeClassPlan) => {
    if (!acc.some(({ plan }) => plan.benefitType === employeeClassPlan.plan.benefitType)) {
      acc.push(employeeClassPlan);
    }
    return acc;
  }, []);

  return (
    <HubCard>
      <Row justify="space-between">
        <Col>
          <h5>Eligible employee group {index + 1}</h5>
        </Col>
        <Col>
          <Button type="text-only" onClick={editButtonAction}>
            Edit
          </Button>
        </Col>
      </Row>

      <EIFClassBuilderItemEligibility
        client={client}
        employeeClass={employeeClass}
        changeSnapshot={null}
        authUser={authUser}
      />

      {!hideDetails &&
        employeeClassPlansToShow.map((employeeClassPlan) => {
          const benefitType = employeeClassPlan.plan.benefitType;
          const selectedQPSClasses = (
            mappedQPSClass.formValues.adminClasses[benefitType] ?? []
          ).filter(isQPSBasicClass);
          return (
            <div key={employeeClassPlan.id} data-testid="mapped-benefit-type-summary-item">
              <hr />
              <Body2 as="p">{benefitTypeToCoverage[benefitType]}</Body2>

              <Row align={selectedQPSClasses.length === 0 ? "middle" : "top"}>
                <Col span={12}>
                  <StackY dist={24}>
                    <div>
                      <Body5 as="div">Waiting period</Body5>
                      <Body3 as="div">{waitingPeriodTypeToReadableType(employeeClassPlan)}</Body3>
                    </div>

                    <div>
                      <Body5 as="div">Contributions</Body5>

                      <ReviewTierContributions
                        client={client}
                        employeeClass={employeeClass}
                        benefitType={benefitType}
                        changeSnapshot={null}
                        authUser={authUser}
                      />
                    </div>
                  </StackY>
                </Col>

                <Col span={12}>
                  {selectedQPSClasses.map((qpsClass, index) => (
                    <StackY dist={24} key={index}>
                      <div>
                        <Body5 as="div">Plan Description</Body5>
                        <Body3 as="div">{qpsClass.planDesignName}</Body3>
                      </div>
                      <div>
                        <Body5 as="div">Admin Class</Body5>
                        <Body3 as="div">{qpsClass.adminClassName}</Body3>
                      </div>
                    </StackY>
                  ))}
                  {selectedQPSClasses.length === 0 && (
                    <Body3 darkYellow>
                      <FontAwesomeIcon icon={faInfoCircle} className={styles.infoWarningIcon} /> No
                      Admin Class assigned
                    </Body3>
                  )}
                </Col>
              </Row>
            </div>
          );
        })}

      <hr />
      <Button type="text-only" onClick={toggleHideDetails}>
        {hideDetails ? "Show" : "Hide"} details
      </Button>
    </HubCard>
  );
};
