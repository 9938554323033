import { Body3, Body5, H3, H5 } from "client/src/components/Typography/Typography";
import { Table } from "client/src/domain/ExplorerPages/ClientPage/Benefit/Table/Table";
import { Trans, i18n, useTranslation } from "client/src/i18n";
import { formatCurrency, formatPercentage } from "client/src/utils/formatters";
import { type ReactNode } from "react";
import * as styles from "./ExplorerVisionPlanComparisonTable.module.less";
import { PlanSummaryLink } from "./PlanSummaryLink";
import type { TFunction } from "i18next";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

/**
 * Props for the {@link ExplorerVisionPlanComparisonTable} component.
 */
type ExplorerVisionPlanComparisonTableProps = {
  /**
   * The vision plan benefits to compare.
   */
  benefits: Array<ExplorerPageBenefit>;
};

/**
 * A table component to compare vision plan benefits.
 */
export const ExplorerVisionPlanComparisonTable = ({
  benefits,
}: ExplorerVisionPlanComparisonTableProps) => {
  const rows = useRowConfig();
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.VISION.custom" });

  return (
    <div className={styles.container}>
      <Table
        skipEmptyRows
        head={[
          {
            contents: (
              <Trans t={t} i18nKey="tableHeaders.planName">
                <H3 />
              </Trans>
            ),
          },
          ...benefits.map((benefit) => ({
            contents: <H3>{benefit.planName}</H3>,
          })),
        ]}
        body={rows.map(({ getLabel, getValue, getHeading }) => [
          {
            contents: getHeading ? getHeading(benefits) : getLabel(),
          },
          ...benefits.map((benefit) => {
            return {
              heading: getLabel(benefit),
              contents: getValue(benefit),
            };
          }),
        ])}
        footer={
          <Trans t={t} i18nKey="tableFooters.default">
            <Body3 as="p" />
            <Body3 as="p" />
            <Body3 as="p" />
          </Trans>
        }
      />
    </div>
  );
};

/**
 * The configuration for each row in the vision plan comparison table.
 */
type RowConfig = {
  getLabel: (benefit?: ExplorerPageBenefit) => ReactNode;
  getValue: (benefit: ExplorerPageBenefit) => ReactNode;
  getHeading?: (benefits: Array<ExplorerPageBenefit>) => ReactNode;
};

/**
 * A component to render the heading of a row in the vision plan comparison table.
 */
function RowHeading({ tKey }: { tKey: string }) {
  const { t: $t } = useTranslation({
    keyPrefix: "ExplorerPlanComparisonTable.VISION.custom.tableHeaders",
  });

  return (
    <Trans t={$t} i18nKey={tKey}>
      <H5 />
    </Trans>
  );
}

/**
 * Returns the configuration for each row in the vision plan comparison table.
 */
function useRowConfig(): Array<RowConfig> {
  const { t: $t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.VISION.custom" });

  return [
    {
      getLabel: () => <RowHeading tKey="carrier" />,
      getValue: (benefit) => benefit.carrier?.carrierName ?? "",
    },
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "examServices",
      valueKey: "copay",
      minProp: "visionExamServicesInNetwork",
      maxProp: "visionExamServicesOutOfNetwork",
      biWeeklyProp: "visionExamServicesBiYearly",
    }),
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "lensAllowance",
      valueKey: "copay",
      minProp: "visionLensesInNetwork",
      maxProp: "visionLensesOutOfNetwork",
      biWeeklyProp: "visionLensesBiYearly",
    }),
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "framesAllowance",
      valueKey: "off",
      minProp: "visionFramesInNetwork",
      maxProp: "visionFramesOutOfNetwork",
      biWeeklyProp: "visionFramesBiYearly",
    }),
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "framesOverAllowance",
      valueKey: "discount",
      minProp: "visionFramesOverInNetwork",
      maxProp: "visionFramesOverOutOfNetwork",
      biWeeklyProp: "visionFramesBiYearly",
    }),
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "contactLensExam",
      valueKey: "copay",
      minProp: "visionContactLensesExamInNetwork",
      maxProp: "visionContactLensesExamOutOfNetwork",
      biWeeklyProp: "visionContactLensesBiYearly",
    }),
    getRowConfigForRangeAndDuration({
      $t,
      labelKey: "contactLensAllowance",
      valueKey: "copay",
      minProp: "visionContactLensesInNetwork",
      maxProp: "visionContactLensesOutOfNetwork",
      biWeeklyProp: "visionContactLensesBiYearly",
    }),
    {
      getLabel: () => <RowHeading tKey="linkToFullPlanSummary" />,
      getValue: (benefit) =>
        // Return `undefined` to skip the cell if there is no plan summary in the whole row.
        ((i18n.language === "en" || i18n.language.startsWith("en-")) &&
          benefit.planSummaryDocumentId) ||
        ((i18n.language === "es" || i18n.language.startsWith("es-")) &&
          benefit.spanishPlanSummaryDocumentId) ? (
          <PlanSummaryLink
            benefit={benefit}
            label={$t("tableCells.downloadPlanSummary", { planName: benefit.planName })}
            showIcon
          />
        ) : undefined,
    },
  ];
}

/**
 * Props for the {@link getRowConfigForRangeAndDuration} function parameters.
 */
type RowConfigForMinUpToMaxEveryMonths = {
  $t: TFunction;
  labelKey: string;
  valueKey: "copay" | "off" | "discount";
  minProp: keyof ExplorerPageBenefit;
  maxProp: keyof ExplorerPageBenefit;
  biWeeklyProp: keyof ExplorerPageBenefit;
};

function getRowConfigForRangeAndDuration({
  $t,
  labelKey,
  valueKey,
  minProp,
  maxProp,
  biWeeklyProp,
}: RowConfigForMinUpToMaxEveryMonths): RowConfig {
  return {
    getLabel: () => <RowHeading tKey={labelKey} />,
    getValue: (benefit) => {
      const minValue = benefit[minProp];
      const maxValue = benefit[maxProp];
      const minNumericValue = typeof minValue === "number" ? minValue : 0;
      const maxNumericValue = typeof maxValue === "number" ? maxValue : 0;
      const format = valueKey === "discount" ? formatPercentage : formatCurrency;

      return (
        <Trans
          t={$t}
          i18nKey={`tableCells.${valueKey}`}
          values={{
            min: format(minNumericValue),
            max: format(maxNumericValue),
            frequency: benefit[biWeeklyProp] ? 24 : 12,
          }}
        >
          <Body3 as="div" />
          <Body3 as="div" />
          <Body5 as="div" />
        </Trans>
      );
    },
  };
}
