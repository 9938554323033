import { SlobLink } from "client/src/components/SlobLink/SlobLink";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { H3, Body3 } from "../../../../components/Typography/Typography";
import { EIFSectionStatusIconography } from "../../common/EIFSectionStatusIconography";
import type { UserRole } from "shared/rbac/roles";
import type { ClientId } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";

type Props = {
  clientId: ClientId;
  title: string;
  userRole: UserRole;
  clientPlans: Plan[];
};

export const NoEmployeeClassPlansMessage = ({ title, userRole, clientPlans, clientId }: Props) => {
  const clientPlansBenefitTypes = new Set(clientPlans.map((p) => p.benefitType));
  const isInternalUser = getIsInternalUser({ role: userRole });

  return (
    <>
      <H3 as="h2" greyMedium>
        <EIFSectionStatusIconography status="Not Started" />
        {title}
      </H3>

      <Body3 as="div">
        {clientPlansBenefitTypes.size === 0 ? (
          <>
            {isInternalUser ? (
              <>
                Please complete{" "}
                <SlobLink variant="normal" to={RouteData.clientDetail.getPath(clientId)}>
                  Configure Submit Company Information
                </SlobLink>{" "}
                to access this section.
              </>
            ) : (
              <>
                Your implementation consultant is still getting things ready. Check back later to
                complete this section.
              </>
            )}
          </>
        ) : clientPlansBenefitTypes.size === 1 ? (
          <>Please complete any previous section before proceeding to {title}</>
        ) : (
          <>
            Please complete the <b>Benefits</b> section before filling out {title}
          </>
        )}
      </Body3>
    </>
  );
};
