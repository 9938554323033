import { PasswordUpsertSchema } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { FormInput } from "../../../components/Form/Input";
import { StackY } from "../../../components/Spacing/Spacing";
import { ResponseError } from "../../../hooks/query";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useUpsertPassword } from "../../../hooks/user";
import { PasswordRequirements } from "../PasswordRequirements";

type PasswordUpsertFormProps = {
  token: string;
  clientId?: string;
  email: string;
  firstName: string;
  lastName: string;
  onSuccess: () => void;
  passwordFormLabel: string;
  submitButtonLabel: string;
  isActivation?: boolean;
  track: () => void;
};
export const PasswordUpsertForm = ({
  token,
  clientId,
  email,
  firstName,
  lastName,
  onSuccess,
  passwordFormLabel,
  submitButtonLabel,
  isActivation = false,
  track,
}: PasswordUpsertFormProps) => {
  const { mutateAsync: upsertPassword } = useUpsertPassword();

  const formik = useSlobFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
      firstName: firstName,
      lastName: lastName,
      email: email,
    },
    validationSchema: PasswordUpsertSchema,
    async onSubmit(values) {
      formik.setStatus("");

      const payload = {
        password: values.password,
        email,
        token,
        isActivation,
        clientId,
      };
      try {
        const data = await upsertPassword({ data: payload });
        if (data) {
          onSuccess();
        }
      } catch (error) {
        const errorMsg = ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2);
        formik.setStatus(errorMsg);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="mt-24">
      <FormInput
        label={passwordFormLabel}
        name="password"
        value={formik.values.password}
        onChange={(e) => {
          formik.setStatus("");
          formik.handleChange(e);
        }}
        maxLength={64}
        onBlur={formik.handleBlur}
        touched={formik.touched.password}
        error={formik.errors.password}
        isPassword={true}
      />
      <StackY dist={24}>
        <PasswordRequirements password={formik.values.password} />
        <FormInput
          label="Confirm your password"
          name="passwordConfirmation"
          value={formik.values.passwordConfirmation}
          onChange={(e) => {
            formik.setStatus("");
            formik.handleChange(e);
          }}
          maxLength={64}
          onBlur={formik.handleBlur}
          touched={formik.touched.passwordConfirmation}
          error={formik.errors.passwordConfirmation && "Passwords do not match."}
          isPassword={true}
        />
        {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
        <Button
          data-testid="password-button"
          type="primary"
          size="large"
          htmlType="submit"
          block
          loading={formik.isSubmitting}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting || formik.status}
          onClick={track}
        >
          {submitButtonLabel}
        </Button>
      </StackY>
    </form>
  );
};
