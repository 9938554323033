import { StackY } from "client/src/components/Spacing/Spacing";
import React from "react";

import { RecipientTable } from "./RecipientTable";

import type { MetadataStatus, VoidedFormInfo } from "shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";

type RecipientSummaryProps = {
  currentRoutingOrder: string;
  recipients: ReadonlyArray<RecipientWithMetadata>;
  recipientActionsCompleted: boolean;
  envelopeProgressStatus: MetadataStatus;
  voidedFormInfo: VoidedFormInfo;
  showMismatchedRecipients: boolean;
};

export const RecipientSummary: React.FC<RecipientSummaryProps> = ({
  recipients,
  currentRoutingOrder,
  recipientActionsCompleted,
  voidedFormInfo,
  envelopeProgressStatus,
  showMismatchedRecipients,
}) => {
  const noOrderEnvelope = recipients.every(
    ({ routingOrder }) => routingOrder === currentRoutingOrder,
  );

  if (envelopeProgressStatus === "declined" || envelopeProgressStatus === "voided") {
    return (
      <StackY dist={8} className="w-full">
        <RecipientTable
          variant={envelopeProgressStatus}
          recipients={recipients}
          currentRoutingOrder={currentRoutingOrder}
          voidedFormInfo={voidedFormInfo}
        />
        <RecipientTable
          variant="other-signers"
          recipients={recipients}
          currentRoutingOrder={currentRoutingOrder}
          voidedFormInfo={voidedFormInfo}
        />
      </StackY>
    );
  }

  return (
    <div>
      {(noOrderEnvelope || recipientActionsCompleted) && (
        <RecipientTable
          variant="no-order"
          recipients={recipients}
          showSubText={!recipientActionsCompleted}
          currentRoutingOrder={currentRoutingOrder}
          showMismatchedRecipients={showMismatchedRecipients}
        />
      )}
      {!noOrderEnvelope && !recipientActionsCompleted && (
        <StackY dist={8} className="w-full">
          <RecipientTable
            variant="completed"
            recipients={recipients}
            currentRoutingOrder={currentRoutingOrder}
            showMismatchedRecipients={showMismatchedRecipients}
          />
          <RecipientTable
            variant="current"
            recipients={recipients}
            currentRoutingOrder={currentRoutingOrder}
            showMismatchedRecipients={showMismatchedRecipients}
          />
          <RecipientTable
            variant="waiting"
            recipients={recipients}
            currentRoutingOrder={currentRoutingOrder}
            showMismatchedRecipients={showMismatchedRecipients}
          />
        </StackY>
      )}
    </div>
  );
};
