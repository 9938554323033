import { Modal } from "antd";
import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import * as renameItemStyles from "client/src/components/RenameItem/RenameItem.module.less";
import { StackY } from "client/src/components/Spacing/Spacing";
import { convertToResource } from "client/src/domain/Document/DocumentDownload/DocumentDownloadPresentation";
import { DocumentUpload } from "client/src/domain/Document/DocumentUpload/DocumentUpload";
import { RenameFile } from "client/src/domain/Document/RenameFile/RenameFile";
import { useToggler } from "client/src/hooks/useToggler";
import clsx from "clsx";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import {
  getBenefitClips,
  getBasicEnrollmentFlyers,
  getLinkId,
  getBeneficiaryDesignationWebLinks,
  isClientDateValidForEnrollmentDocsAutomation,
} from "shared/data/Client";

import { getCoverageOutlineLinks } from "shared/data/CoverageOutline";

import { getIsInternalUser } from "shared/rbac/rbac";
import { categoryNameByCategoryType } from "shared/types/Document";
import {
  documentBenefitTypes,
  documentBenefitTypesNames,
  isDocumentBenefitType,
} from "shared/types/DocumentBenefitType";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { formatDateWeekDayShortMonthWithYear, formatFullName } from "shared/utils/format";
import { getCanSeeBenefitSummaries } from "shared/utils/getCanSeeBenefitSummaries";
import * as Yup from "yup";
import { Anchor } from "../../components/Anchor/Anchor";
import { Back } from "../../components/Back/Back";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { ConfirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";
import { Checkbox } from "../../components/Form/Checkbox";
import { DownloadIcon } from "../../components/Icons/DownloadIcon";
import { TrashIcon } from "../../components/Icons/TrashIcon";
import { ReactComponent as UploadIcon } from "../../components/Icons/UploadIcon.svg";
import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import {
  ResourcesTable,
  getColumnTitleWithSortingIcons,
} from "../../components/SlobTable/ResourcesTable";
import { Spinner } from "../../components/Spinner/Spinner";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { Body1, Body2, Body3, Body5, Eyebrow, H5 } from "../../components/Typography/Typography";
import { useDocumentDeletionProps } from "../../domain/Document/DocumentDownload/useDocumentDeletionProps";
import { useWebLinkDeletionProps } from "../../domain/Document/DocumentDownload/useWebLinkDeletionProps";
import {
  DownloadFileTable,
  renderBenefitTypeCell,
} from "../../domain/Document/DownloadFileTable/DownloadFileTable";
import { useSlobAuth } from "../../hooks/auth";
import { useGetClientByID } from "../../hooks/client";
import { useDocumentDownloadHelper, useRenameDocument } from "../../hooks/document";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useSlobFormik } from "../../hooks/useSlobFormik";
import { useTitle } from "../../hooks/useTitle";
import { useGetWebLinks } from "../../hooks/webLink";
import { useDownloadZipFileForDocumentIds } from "../../hooks/zipped-documents";
import { track, useTrackElementClicked } from "../../utils/analytics";
import { useAmountOfItemsPerBenefitType } from "../EnrollmentResourcesPage/useAmountOfItemsPerBenefitType";
import { useAmountOfItemsPerCategory } from "../EnrollmentResourcesPage/useAmountOfItemsPerCategory";

import * as styles from "./enrollmentResourcesListingPage.module.less";

import type { ResponseError } from "../../hooks/query";
import type { ZippedDocumentRequestStatus } from "../../hooks/zipped-documents";
import type { Client, ClientId, PolicyId } from "shared/types/Client";
import type { Document, DocumentCategory, DocumentId } from "shared/types/Document";
import type { DocumentBenefitType } from "shared/types/DocumentBenefitType";
import type { SlfCoverageLink } from "shared/types/Ticket";
import type { WebLink, WebLinkCategory } from "shared/types/WebLink";
import type { AnyObject } from "yup";

type Resource = {
  id: string;
  resourceType: string;
  name: string;
  benefitTypes: DocumentBenefitType[];
  addedAt: Date;
  addedBy: string;
} & (
  | {
      type: "document";
      category: DocumentCategory;
    }
  | {
      type: "webLink";
      category: WebLinkCategory;
      url: string;
    }
  | {
      type: "slfCoverageLink-benefitClips" | "slfCoverageLink-basicEnrollmentFlyers";
      category: DocumentCategory | undefined;
      url: string;
    }
  | {
      type: "slfCoverageLink-benefitOutlines";
      category: "custom-enrollment-materials";
      url: string;
    }
  | {
      type: "slfCoverageLink-beneficiaryDesignation";
      category: "custom-enrollment-materials";
      url: string;
    }
);

export function EnrollmentResourcesListingPage() {
  useTitle("Browse Materials");

  const { clientId } = useClientHubParams(["clientId"]);

  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const {
    data: client,
    isLoading: isLoadingClient,
    error: errorClient,
  } = useGetClientByID(clientId);

  const vdrCategories = [
    "voluntary-deduction-report",
    "voluntary-deduction-report-marked",
  ] as const;

  const {
    isLoadingDocuments: isLoadingDocumentsByCategory,
    isFetchingDocuments: isFetchingDocumentsByCategoy,
    isDownloading,
    errorGetDocuments,
    errorDocumentURL,
    documents: allDocuments,
    downloadDocument,
  } = useDocumentDownloadHelper(
    clientId,
    [
      "benefit-summaries",
      "benefit-rate-sheets",
      "enrollment-forms",
      "id-cards-and-online-access",
      "custom-enrollment-materials",
      "value-added-services",
      "group-life-accelerated-benefits",
      ...vdrCategories,
    ],
    undefined,
  );

  const {
    data: webLinks,
    isLoading: isLoadingWebLinks,
    error: errorWebLinks,
  } = useGetWebLinks(clientId, [
    "benefit-summaries",
    "benefit-rate-sheets",
    "enrollment-forms",
    "id-cards-and-online-access",
    "custom-enrollment-materials",
    "value-added-services",
  ]);

  // click tracking with context for this client
  const trackElementClicked = useTrackElementClicked(client);

  const trackClick = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const downloadDocumentWrapper = (id: string | number | undefined) => {
    downloadDocument(id);
    resetRenameState();
  };

  const {
    documentDeleteConfirmDialogVisible,
    documentToDeleteName,
    openDocumentDeletionConfirmDialog,
    handleConfirmDeleteDocument,
    resetDeleteDocumentParams,
    isPendingDeleteDocument,
    errorDeleteDocument,
  } = useDocumentDeletionProps({ clientId, policyId: undefined, track: trackClick });

  const {
    webLinkDeleteConfirmDialogVisible,
    webLinkToDeleteName,
    openWebLinkDeletionConfirmDialog,
    handleConfirmDeleteWebLink,
    resetDeleteWebLinkParams,
    isPendingDeleteWebLink,
    errorDeleteWebLink,
  } = useWebLinkDeletionProps({ clientId, track: trackClick });

  const { mutateAsync: renameDocument, isPending: isPendingRenameDocument } = useRenameDocument();

  const [renameFileResetToken, resetRenameState] = useToggler();

  const renameDocumentWrapper = async (id: string, newName: string) => {
    trackClick("File rename");
    await renameDocument({
      data: { name: newName },
      params: { documentId: id, clientId },
    });
  };

  const {
    isGeneratingZippedDocument,
    errorGeneratingZippedFile,
    requestZipFile,
    zippedDocumentStatus,
    cancelRequestZipFile,
  } = useDownloadZipFileForDocumentIds(clientId);

  if (errorGetDocuments) {
    return triggerError(errorGetDocuments);
  }
  if (errorWebLinks) {
    return triggerError(errorWebLinks);
  }
  if (errorClient) {
    return triggerError(errorClient);
  }
  if (errorDeleteDocument) {
    return triggerError(errorDeleteDocument);
  }
  if (errorDeleteWebLink) {
    return triggerError(errorDeleteWebLink);
  }

  const isLoading =
    isLoadingDocumentsByCategory ||
    isLoadingClient ||
    isLoadingWebLinks ||
    isPendingDeleteDocument ||
    isPendingDeleteWebLink;

  const canSeeBenefitSummaries = getCanSeeBenefitSummaries({
    authUser,
    benefitSummaries: allDocuments?.filter((d) => d.category === "benefit-summaries"),
    alwaysShowBenefitSummaries: client?.alwaysShowBenefitSummaries ?? true,
  });

  const documents = canSeeBenefitSummaries
    ? allDocuments
    : allDocuments?.filter((d) => d.category !== "benefit-summaries");

  const isClientDateValid = client ? isClientDateValidForEnrollmentDocsAutomation(client) : false;

  return (
    <>
      <EnrollmentResourcesListingPagePresentation
        clientId={clientId}
        documents={documents}
        webLinks={webLinks}
        client={client}
        isLoading={isLoading}
        isDownloading={isDownloading}
        isClientDateValid={isClientDateValid}
        isFetchingDocuments={isFetchingDocumentsByCategoy}
        isPendingRenameDocument={isPendingRenameDocument}
        downloadDocument={downloadDocumentWrapper}
        errorDocumentURL={errorDocumentURL}
        openWebLinkDeletionConfirmDialog={openWebLinkDeletionConfirmDialog}
        openDocumentDeletionConfirmDialog={openDocumentDeletionConfirmDialog}
        isInternalUser={isInternalUser}
        renameDocument={renameDocumentWrapper}
        trackClick={trackClick}
        renameFileResetToken={renameFileResetToken}
        hideRenameDialog={resetRenameState}
        isGeneratingZippedDocument={isGeneratingZippedDocument}
        errorGeneratingZippedFile={errorGeneratingZippedFile}
        requestZipFile={requestZipFile}
        zippedDocumentStatus={zippedDocumentStatus}
        cancelRequestZipFile={cancelRequestZipFile}
      />
      <ConfirmDialog
        title={"Delete file"}
        isVisible={documentDeleteConfirmDialogVisible}
        isLoading={isPendingDeleteDocument}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={handleConfirmDeleteDocument}
        onCancel={resetDeleteDocumentParams}
      >
        <Body1 as="p">Are you sure you want to delete: {documentToDeleteName}?</Body1>
      </ConfirmDialog>

      <ConfirmDialog
        title={"Delete link"}
        isVisible={webLinkDeleteConfirmDialogVisible}
        isLoading={isPendingDeleteWebLink}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={handleConfirmDeleteWebLink}
        onCancel={resetDeleteWebLinkParams}
      >
        <Body1 as="p">Are you sure you want to delete this web link: {webLinkToDeleteName}?</Body1>
      </ConfirmDialog>
    </>
  );
}

export type EnrollmentResourcesListingPagePresentationProps = {
  clientId: ClientId;
  documents: Document[] | undefined;
  webLinks: WebLink[] | undefined;
  client: Client | undefined;
  isLoading: boolean;
  isDownloading: boolean;
  isFetchingDocuments: boolean;
  isClientDateValid: boolean;
  isPendingRenameDocument: boolean;
  downloadDocument: (id: string | number | undefined) => void;
  errorDocumentURL: ResponseError | null;
  openDocumentDeletionConfirmDialog: (id: string, name: string, category: DocumentCategory) => void;
  openWebLinkDeletionConfirmDialog: (id: string, name: string, category: WebLinkCategory) => void;
  isInternalUser: boolean;
  renameDocument: (id: string, name: string) => Promise<void> | void;
  trackClick: (buttonLabel: string) => void;
  renameFileResetToken: boolean;
  hideRenameDialog: () => void;
  isGeneratingZippedDocument: boolean;
  errorGeneratingZippedFile: boolean | ResponseError;
  requestZipFile: (docIdsToZip: DocumentId[]) => void;
  zippedDocumentStatus: ZippedDocumentRequestStatus;
  cancelRequestZipFile: () => void;
};

export function EnrollmentResourcesListingPagePresentation({
  clientId,
  documents,
  webLinks,
  client,
  isLoading,
  isDownloading,
  isFetchingDocuments,
  isPendingRenameDocument,
  isClientDateValid,
  downloadDocument,
  errorDocumentURL,
  openDocumentDeletionConfirmDialog,
  openWebLinkDeletionConfirmDialog,
  isInternalUser,
  renameDocument,
  trackClick,
  renameFileResetToken,
  hideRenameDialog,
  isGeneratingZippedDocument,
  requestZipFile,
  zippedDocumentStatus,
  cancelRequestZipFile,
}: EnrollmentResourcesListingPagePresentationProps) {
  const basicEnrollmentFlyers = client ? getBasicEnrollmentFlyers(client) : [];
  const beneficiaryDesignationWebLinks =
    isClientDateValid && client ? getBeneficiaryDesignationWebLinks(client) : [];
  const amountOfItemsPerCategory = useAmountOfItemsPerCategory(
    documents,
    webLinks,
    basicEnrollmentFlyers,
    beneficiaryDesignationWebLinks,
    client,
  );
  const amountOfItemsPerBenefitType = useAmountOfItemsPerBenefitType(
    documents,
    webLinks,
    beneficiaryDesignationWebLinks,
    client,
  );

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const documentBenefitTypesValidationSchema = Object.fromEntries(
    documentBenefitTypes.map((b) => [b, Yup.boolean().nullable()]),
  ) as Record<
    DocumentBenefitType,
    Yup.BooleanSchema<boolean | null | undefined, AnyObject, boolean | null | undefined>
  >;

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      "benefit-summaries": Yup.boolean().nullable(),
      "benefit-rate-sheets": Yup.boolean().nullable(),
      "enrollment-forms": Yup.boolean().nullable(),
      benefitClips: Yup.boolean().nullable(),
      basicEnrollmentFlyers: Yup.boolean().nullable(),
      "id-cards-and-online-access": Yup.boolean().nullable(),
      "custom-enrollment-materials": Yup.boolean().nullable(),
      "value-added-services": Yup.boolean().nullable(),
      "voluntary-deduction-report": Yup.boolean().nullable(),
      "none-benefit-type": Yup.boolean().nullable(),
      "group-life-accelerated-benefits": Yup.boolean().nullable(),
      ...documentBenefitTypesValidationSchema,
    }),
    initialValues: {
      "benefit-summaries": urlSearchParams.get("benefit-summaries") === String(true),
      "benefit-rate-sheets": urlSearchParams.get("benefit-rate-sheets") === String(true),
      "enrollment-forms": urlSearchParams.get("enrollment-forms") === String(true),
      benefitClips: urlSearchParams.get("benefitClips") === String(true),
      basicEnrollmentFlyers: urlSearchParams.get("basicEnrollmentFlyers") === String(true),
      "id-cards-and-online-access":
        urlSearchParams.get("id-cards-and-online-access") === String(true),
      "custom-enrollment-materials":
        urlSearchParams.get("custom-enrollment-materials") === String(true),
      "value-added-services": urlSearchParams.get("value-added-services") === String(true),
      "none-benefit-type": urlSearchParams.get("none-benefit-type") === String(true),
      "group-life-accelerated-benefits":
        urlSearchParams.get("group-life-accelerated-benefits") === String(true),
      "voluntary-deduction-report":
        urlSearchParams.get("voluntary-deduction-report") === String(true),
      ...Object.fromEntries(
        documentBenefitTypes.map((b) => [b, urlSearchParams.get(b) === String(true)]),
      ),
    },
    onSubmit: () => undefined,
  });

  useUpdateQueryString(clientId, formik.values);

  const values = formik.values;

  // Do not display the VDR documents with comments
  const documentsToDisplay = (documents ?? []).filter(
    ({ category }) => category !== "voluntary-deduction-report-marked",
  );

  const vdrWithComments = (documents ?? []).filter(
    ({ category }) => category === "voluntary-deduction-report-marked",
  );

  const resources = useFilteredResources(
    documentsToDisplay,
    webLinks,
    client,
    beneficiaryDesignationWebLinks,
    values,
  );

  const idsForDownloadAll = resources
    ? resources.filter((r) => r.type === "document").map((r) => r.id)
    : [];
  const noDocumentsToDownload = idsForDownloadAll.length === 0;

  const has = (category: DocumentCategory | WebLinkCategory) => {
    return Boolean((amountOfItemsPerCategory[category] ?? 0) > 0);
  };

  const isNoneBenefitTypeChecked = Boolean(formik.values["none-benefit-type"]);
  const benefitClips = client ? getBenefitClips(client) : [];

  return (
    <>
      <div className={styles.centerColumn}>
        <section className="my-32">
          <Back to={RouteData.clientTaskDetail.getPath(clientId, "enrollment-resources")}>
            Back
          </Back>

          <h1 className="mt-12">Browse Materials</h1>
        </section>
      </div>

      <div className={styles.disclaimerText}>
        <Body5>
          By accepting these files, you agree not to edit or revise the content in any way or use
          them for any other purpose.
        </Body5>
      </div>
      <div className={styles.content}>
        <section className={clsx(styles.centerColumn, styles.layout)}>
          <aside>
            <div className={clsx(styles.spaceBetween, "mb-16")}>
              <Eyebrow id="filter-by">FILTER BY</Eyebrow>

              <ButtonOld
                type="link-inline"
                size="middle"
                onClick={() => formik.setValues({})}
                disabled={isPendingRenameDocument}
              >
                Reset
              </ButtonOld>
            </div>

            <div className={styles.filters}>
              <H5 as="p">Resource Type</H5>

              {isLoading ? (
                <div data-testid="loading" className={styles.center}>
                  <Spinner size="small" />
                </div>
              ) : (
                <StackY dist={8}>
                  {has("benefit-summaries") && (
                    <Checkbox
                      label={categoryNameByCategoryType["benefit-summaries"]}
                      name="benefit-summaries"
                      checked={Boolean(formik.values["benefit-summaries"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("benefit-rate-sheets") && (
                    <Checkbox
                      label={categoryNameByCategoryType["benefit-rate-sheets"]}
                      name="benefit-rate-sheets"
                      checked={Boolean(formik.values["benefit-rate-sheets"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("enrollment-forms") && (
                    <Checkbox
                      label={categoryNameByCategoryType["enrollment-forms"]}
                      name="enrollment-forms"
                      checked={Boolean(formik.values["enrollment-forms"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {benefitClips.length > 0 && (
                    <Checkbox
                      label={"Benefit Video Clips"}
                      name="benefitClips"
                      checked={Boolean(formik.values.benefitClips)}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {basicEnrollmentFlyers.length > 0 && (
                    <Checkbox
                      label={"Basic Enrollment Flyers"}
                      name="basicEnrollmentFlyers"
                      checked={Boolean(formik.values.basicEnrollmentFlyers)}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("id-cards-and-online-access") && (
                    <Checkbox
                      label={"ID Cards and Online Access"}
                      name="id-cards-and-online-access"
                      checked={Boolean(formik.values["id-cards-and-online-access"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("custom-enrollment-materials") && (
                    <Checkbox
                      label={categoryNameByCategoryType["custom-enrollment-materials"]}
                      name="custom-enrollment-materials"
                      checked={Boolean(formik.values["custom-enrollment-materials"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("value-added-services") && (
                    <Checkbox
                      label={categoryNameByCategoryType["value-added-services"]}
                      name="value-added-services"
                      checked={Boolean(formik.values["value-added-services"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("voluntary-deduction-report") && (
                    <Checkbox
                      label={categoryNameByCategoryType["voluntary-deduction-report"]}
                      name="voluntary-deduction-report"
                      checked={Boolean(formik.values["voluntary-deduction-report"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}

                  {has("group-life-accelerated-benefits") && (
                    <Checkbox
                      label={categoryNameByCategoryType["group-life-accelerated-benefits"]}
                      name="group-life-accelerated-benefits"
                      checked={Boolean(formik.values["group-life-accelerated-benefits"])}
                      onChange={formik.handleChange}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}
                </StackY>
              )}

              <hr />

              <H5 as="p">Benefit Type</H5>

              {isLoading ? (
                <div data-testid="loading" className={styles.center}>
                  <Spinner size="small" />
                </div>
              ) : (
                <StackY dist={8}>
                  {documentBenefitTypes.map(
                    (b) =>
                      (amountOfItemsPerBenefitType[b] || false) && (
                        <Checkbox
                          key={b}
                          label={documentBenefitTypesNames[b]}
                          name={b}
                          checked={Boolean(formik.values[b])}
                          disabled={isNoneBenefitTypeChecked || isPendingRenameDocument}
                          onChange={formik.handleChange}
                          aria-describedby="filter-by"
                        />
                      ),
                  )}
                  {(amountOfItemsPerBenefitType["none-benefit-type"] || false) && (
                    <Checkbox
                      key="none-benefit-type"
                      name="none-benefit-type"
                      label="None"
                      checked={isNoneBenefitTypeChecked}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        if (isChecked) {
                          void formik.setValues(
                            Object.fromEntries(
                              documentBenefitTypes.map((benefitType) => [benefitType, false]),
                            ),
                          );
                        }
                        formik.handleChange(event);
                      }}
                      aria-describedby="filter-by"
                      disabled={isPendingRenameDocument}
                    />
                  )}
                </StackY>
              )}
            </div>
          </aside>

          <div>
            {zippedDocumentStatus === "ERROR" && (
              <div className="pb-16">
                <AlertBanner
                  variant="error"
                  message={
                    <Body3>There was an issue generating your zip file. Please try again</Body3>
                  }
                />
              </div>
            )}

            {errorDocumentURL && (
              <div className="pb-16">
                <AlertBanner
                  variant="error"
                  message={
                    <Body3>There was an issue downloading your file. Please try again</Body3>
                  }
                />
              </div>
            )}

            {isLoading ? (
              <div data-testid="loading" className={styles.center}>
                <Spinner size="large" />
              </div>
            ) : (
              <div data-testid="resource-table" className={styles.enrollmentResourceTable}>
                <ResourcesTable<Resource>
                  data-testid="added"
                  spacing="wide"
                  border="dark"
                  rowKey="id"
                  pagination={false}
                  columns={[
                    {
                      title: getColumnTitleWithSortingIcons("resourceType", "Resource Type"),
                      dataIndex: "resourceType",
                      key: "resourceType",
                      sorter: (a, b) => b.resourceType.localeCompare(a.resourceType),
                      render: (_text: unknown, record) => {
                        return (
                          <div>
                            <Body2 as="div">{record.resourceType}</Body2>

                            <Body5>
                              {record.type === "document" && isInternalUser ? (
                                <RenameFile
                                  key={renameFileResetToken.toString()}
                                  record={{ ...record, isNew: false }} // not implementing NEW badge for this page yet
                                  renameDocument={renameDocument}
                                  disabled={isPendingRenameDocument}
                                />
                              ) : (
                                record.name
                              )}
                            </Body5>
                          </div>
                        );
                      },
                    },
                    {
                      title: getColumnTitleWithSortingIcons("benefitTypes", "Benefit Types"),
                      dataIndex: "benefitTypes",
                      key: "benefitTypes",
                      sorter: (a, b) => {
                        if (
                          a.benefitTypes.length &&
                          b.benefitTypes.length &&
                          a.benefitTypes[0] &&
                          b.benefitTypes[0]
                        ) {
                          return b.benefitTypes[0].localeCompare(a.benefitTypes[0]);
                        }

                        // Sort A...Z None
                        return b.benefitTypes.length ? -1 : 1;
                      },
                      render: (_text: unknown, { benefitTypes }) =>
                        renderBenefitTypeCell(benefitTypes),
                    },
                    {
                      title: getColumnTitleWithSortingIcons("added", "Added"),
                      dataIndex: "added",
                      key: "added",
                      sorter: (a, b) => b.addedAt.getTime() - b.addedAt.getTime(),
                      render: (_text: unknown, record) => {
                        const showBy =
                          !["Basic Enrollment Flyers", "Benefit Video Clips"].includes(
                            record.resourceType,
                          ) && isInternalUser;
                        return (
                          <div>
                            <Body3 as="div">
                              {formatDateWeekDayShortMonthWithYear(record.addedAt)}
                            </Body3>
                            {showBy ? <Body5>By {record.addedBy}</Body5> : null}
                          </div>
                        );
                      },
                    },
                    {
                      width: "60px",
                      title: (
                        <Tooltip
                          title={
                            noDocumentsToDownload
                              ? "Download all unavailable when only links are listed"
                              : "Links like video clips will not be included in your download."
                          }
                          placement="bottom"
                        >
                          <span
                            className="body3"
                            style={{
                              cursor:
                                isDownloading || isGeneratingZippedDocument || noDocumentsToDownload
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                          >
                            <ButtonOld // @todo: migrate with new Tooltip
                              type="link-inline"
                              size="middle"
                              disabled={
                                isDownloading ||
                                isGeneratingZippedDocument ||
                                noDocumentsToDownload ||
                                isPendingRenameDocument
                              }
                              onClick={() => {
                                track("Download All");
                                hideRenameDialog();
                                requestZipFile(idsForDownloadAll);
                              }}
                              style={
                                isDownloading || isGeneratingZippedDocument || noDocumentsToDownload
                                  ? { pointerEvents: "none" }
                                  : {}
                              }
                            >
                              Download All
                            </ButtonOld>
                          </span>
                        </Tooltip>
                      ),
                      key: "action",
                      render: (_text: unknown, record) => {
                        const { type } = record;
                        if (type === "document") {
                          return (
                            <div className={styles.actions}>
                              <Tooltip title="Download" placement="bottom">
                                <span>
                                  <button
                                    onClick={() => {
                                      downloadDocument(record.id);
                                    }}
                                    aria-label="Download"
                                    className="btn-reset"
                                    disabled={
                                      isDownloading ||
                                      isGeneratingZippedDocument ||
                                      isFetchingDocuments ||
                                      isPendingRenameDocument
                                    }
                                  >
                                    <DownloadIcon />
                                  </button>
                                </span>
                              </Tooltip>
                              {isInternalUser && (
                                <Tooltip title="Delete" placement="bottom">
                                  <span>
                                    <button
                                      onClick={() => {
                                        hideRenameDialog();
                                        openDocumentDeletionConfirmDialog(
                                          record.id,
                                          record.name,
                                          record.category,
                                        );
                                      }}
                                      aria-label="Delete"
                                      className="btn-reset"
                                      disabled={
                                        isDownloading ||
                                        isGeneratingZippedDocument ||
                                        isFetchingDocuments ||
                                        isPendingRenameDocument
                                      }
                                    >
                                      <TrashIcon />
                                    </button>
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          );
                        } else if (
                          type === "webLink" ||
                          type === "slfCoverageLink-benefitClips" ||
                          type === "slfCoverageLink-basicEnrollmentFlyers" ||
                          type === "slfCoverageLink-benefitOutlines" ||
                          type === "slfCoverageLink-beneficiaryDesignation"
                        ) {
                          return (
                            <div className={styles.actions}>
                              <Tooltip
                                title="Download"
                                placement="bottom"
                                trigger={
                                  record.type === "slfCoverageLink-basicEnrollmentFlyers"
                                    ? "hover"
                                    : "none"
                                }
                              >
                                <span>
                                  <Anchor
                                    variant="bold"
                                    href={record.url}
                                    newTabIcon={false}
                                    target="_blank"
                                    aria-label={
                                      record.type === "slfCoverageLink-basicEnrollmentFlyers"
                                        ? "Download"
                                        : undefined
                                    }
                                  >
                                    View
                                  </Anchor>
                                </span>
                              </Tooltip>
                              {isInternalUser && type === "webLink" && (
                                <Tooltip title="Delete" placement="bottom">
                                  <span>
                                    <button
                                      onClick={() => {
                                        hideRenameDialog();
                                        openWebLinkDeletionConfirmDialog(
                                          record.id,
                                          record.name,
                                          record.category,
                                        );
                                      }}
                                      aria-label="Delete"
                                      className="btn-reset"
                                      disabled={
                                        isDownloading ||
                                        isGeneratingZippedDocument ||
                                        isFetchingDocuments ||
                                        isPendingRenameDocument
                                      }
                                    >
                                      <TrashIcon />
                                    </button>
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          );
                        } else {
                          exhaustiveCheck(type);
                          return null;
                        }
                      },
                    },
                  ]}
                  rowClassName={(record) => {
                    const className = clsx(
                      record.category === "voluntary-deduction-report" && styles.vdrDocumentRow,
                      renameItemStyles.renameButton_hoverableContainer,
                    );
                    return className;
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <ExpandableVDRMarkedDocument
                        clientId={clientId}
                        deleteDocument={openDocumentDeletionConfirmDialog}
                        downloadDocument={downloadDocument}
                        record={record}
                        renameDocument={renameDocument}
                        vdrWithComments={vdrWithComments}
                        track={trackClick}
                      />
                    ),
                    rowExpandable: (record) => record.category === "voluntary-deduction-report",
                    showExpandColumn: false,
                    defaultExpandAllRows: true,
                    expandedRowClassName: () => String(styles.vdrDocumentExpandableRow),
                  }}
                  dataSource={resources || []}
                />
              </div>
            )}
          </div>
        </section>
      </div>

      <Modal
        maskClosable={false}
        closable={false}
        title=""
        open={zippedDocumentStatus === "GENERATING" || zippedDocumentStatus === "DOWNLOADING"}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={cancelRequestZipFile}
        width={"35%"}
        bodyStyle={{ padding: "60px 150px 60px 150px" }}
      >
        <div className="text-center stack-y-16">
          <ProgressBar id="enrollment-resource-listing-page-zip-progress" bottomText={""} />

          <div>
            {zippedDocumentStatus === "GENERATING" && (
              <>
                <Body2 as="div">Generating your zip file</Body2>
                <Body5 as="div">Download will start automatically when ready</Body5>
              </>
            )}
            {zippedDocumentStatus === "DOWNLOADING" && (
              <Body2 as="div">Downloading your zip file</Body2>
            )}
          </div>

          <div>
            <Button type="text-only" size="small" onClick={cancelRequestZipFile}>
              Cancel Download
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

const noOpHandler = () => {
  return null;
};

type ExpandableVDRMarkedDocumentProps = {
  clientId: ClientId;
  record: Resource;
  vdrWithComments: Document[];
  downloadDocument: (id: string) => void;
  deleteDocument: (
    documentId: DocumentId,
    name: string,
    category: DocumentCategory,
    policyIds?: PolicyId[],
  ) => void;
  renameDocument: (id: string, name: string) => void | Promise<void>;
  track: (buttonLabel: string) => void;
};

const ExpandableVDRMarkedDocument = ({
  clientId,
  record,
  vdrWithComments,
  downloadDocument,
  deleteDocument,
  renameDocument,
  track,
}: ExpandableVDRMarkedDocumentProps) => {
  const [showUploadModal, toggleUploadModal] = useToggler();
  const selectedVdrWithComments = vdrWithComments.filter(
    ({ vdrDocumentId }) => record.id === vdrDocumentId,
  );
  const emptySet = new Set<string>();
  const resourceList = selectedVdrWithComments.map((document) =>
    convertToResource(
      {
        // convert the document to census document
        ...document,
        censusSource: null,
        censusSourceOther: null,
        platform: null,
        processStatus: null,
      },
      true,
      emptySet,
      { addedByPrefix: "added by " },
    ),
  );
  return (
    <BulbMessage>
      Need to make comments on your voluntary deduction report? Upload a version with comments here
      and your implementation consultant will be in touch with you soon.
      <Button
        size="small"
        type="text-only"
        icon={<UploadIcon />}
        iconPosition="right"
        onClick={toggleUploadModal}
      >
        Upload a file
      </Button>
      <DocumentUpload
        modalTitle={"Upload a Voluntary Deduction Report"}
        visible={showUploadModal}
        closeModal={toggleUploadModal}
        clientId={clientId}
        vdrDocumentId={record.id}
        category="voluntary-deduction-report-marked"
        onUpload={noOpHandler}
        track={track}
        singleFile={false}
      />
      <DownloadFileTable
        showHeader={false}
        resourcesList={resourceList}
        downloadDocument={downloadDocument}
        deleteDocument={deleteDocument}
        renameDocument={async (id, name) => {
          await renameDocument(id, name);
        }}
        track={track}
        deleteWebLink={noOpHandler} // this table does not have weblinks
        downloadAllAction={noOpHandler} // we don't show the download all action button
        showDownloadAllButton={false}
        withAddedBy
      />
    </BulbMessage>
  );
};

function useUpdateQueryString(
  clientId: ClientId,
  formikValues: Record<string, boolean | null | undefined>,
) {
  const pairs = Object.entries(formikValues)
    .filter(([_key, value]) => {
      return value;
    })
    .map(([key, value]) => [key, String(value)]);
  const url = new URLSearchParams(pairs);
  const queryString = url.toString();

  const navigate = useNavigate();
  const location = useLocation();

  const prevQueryString = location.search.replace("?", "");

  useEffect(() => {
    if (prevQueryString !== queryString) {
      navigate({
        pathname: RouteData.resourcesListing.getPath(clientId),
        search: queryString,
      });
    }
  }, [clientId, prevQueryString, queryString, navigate]);
}

export type ListingFilterValues = Partial<
  Record<
    | "benefit-summaries"
    | "benefit-rate-sheets"
    | "enrollment-forms"
    | "benefitClips"
    | "basicEnrollmentFlyers"
    | "id-cards-and-online-access"
    | "custom-enrollment-materials"
    | "value-added-services"
    | "none-benefit-type"
    | DocumentBenefitType,
    boolean | null | undefined
  >
>;

export function useFilteredResources(
  documents: Document[] | undefined,
  webLinks: WebLink[] | undefined,
  client: Client | undefined,
  beneficiaryDesignationWebLinks: SlfCoverageLink[] | undefined,
  values: ListingFilterValues,
) {
  const resources = useMemo(() => {
    if (documents && webLinks && client) {
      const { hasCategoriesSelected, selectedBenefitTypes, isNoneBenefitTypeSelected } =
        Object.entries(values).reduce(
          (acc, [categoryOrBenefitType, selected]) => {
            if (isDocumentBenefitType(categoryOrBenefitType) && selected) {
              acc.selectedBenefitTypes.push(categoryOrBenefitType);
            } else if (categoryOrBenefitType === "none-benefit-type" && selected) {
              acc.isNoneBenefitTypeSelected = true;
            } else if (!isDocumentBenefitType(categoryOrBenefitType) && selected) {
              acc.hasCategoriesSelected = true;
            }

            return acc;
          },
          {
            hasCategoriesSelected: false,
            isNoneBenefitTypeSelected: false,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- never type
            selectedBenefitTypes: [] as DocumentBenefitType[],
          },
        );

      const benefitClips = getBenefitClips(client);
      const basicEnrollmentFlyers = getBasicEnrollmentFlyers(client);
      const coverageOutlineLinks = getCoverageOutlineLinks(client);

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- 🐒
      const resources_ = ([] as Array<Resource>)
        .concat(
          documents.map((document) => ({
            id: document.id,
            type: "document" as const,
            name: document.name,
            category: document.category,
            resourceType: categoryNameByCategoryType[document.category],
            benefitTypes: document.benefitTypes || [],
            addedAt: document.createdAt,
            addedBy: formatFullName({
              firstName: document.createdByUser?.firstName,
              lastName: document.createdByUser?.lastName,
            }),
          })),
        )
        .concat(
          webLinks.map((link) => {
            return {
              id: link.id,
              type: "webLink",
              name: link.text,
              url: link.url,
              resourceType: categoryNameByCategoryType[link.category],
              benefitTypes: link.benefitTypes || [],
              addedAt: client.createdAt,
              addedBy: formatFullName({
                firstName: link.createdByUser?.firstName,
                lastName: link.createdByUser?.lastName,
              }),
              category: link.category,
            };
          }),
        )
        .concat(
          benefitClips.map((link) => {
            return {
              id: getLinkId(link),
              name: link.text,
              url: link.url,
              resourceType: "Benefit Video Clips",
              benefitTypes: link.benefitTypes,
              addedAt: client.createdAt,
              addedBy: "-",
              type: "slfCoverageLink-benefitClips",
              category: undefined,
            };
          }),
        )
        .concat(
          basicEnrollmentFlyers.map((link) => {
            return {
              id: getLinkId(link),
              name: link.text,
              url: link.url,
              resourceType: link.category
                ? categoryNameByCategoryType[link.category]
                : "Basic Enrollment Flyers",
              benefitTypes: link.benefitTypes,
              addedAt: client.createdAt,
              addedBy: "-",
              type: "slfCoverageLink-basicEnrollmentFlyers",
              category: link.category,
            };
          }),
        )
        .concat(
          coverageOutlineLinks.map((link) => {
            return {
              id: getLinkId(link),
              name: link.text,
              url: link.url,
              resourceType: link.category
                ? categoryNameByCategoryType[link.category]
                : "Custom Resources",
              benefitTypes: link.benefitTypes,
              addedAt: client.createdAt,
              addedBy: "-",
              type: "slfCoverageLink-benefitOutlines",
              category: "custom-enrollment-materials",
            };
          }),
        )
        .concat(
          (beneficiaryDesignationWebLinks ?? []).map((link) => {
            return {
              id: getLinkId(link),
              name: link.text,
              url: link.url,
              resourceType: link.category
                ? categoryNameByCategoryType[link.category]
                : "Custom Resources",
              benefitTypes: link.benefitTypes,
              addedAt: client.createdAt,
              addedBy: "-",
              type: "slfCoverageLink-beneficiaryDesignation",
              category: "custom-enrollment-materials",
            };
          }),
        )
        .filter((resource) => {
          const filtersActivated = Object.values(values).some((v) => v);

          if (filtersActivated) {
            type Values = keyof typeof values;

            const hasFilteredCategory =
              "category" in resource &&
              resource.category != null &&
              resource.category in values &&
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
              values[resource.category as Values];

            const slfCoveragesFiltered =
              (values.benefitClips && resource.type === "slfCoverageLink-benefitClips") ||
              (values.basicEnrollmentFlyers &&
                resource.type === "slfCoverageLink-basicEnrollmentFlyers");

            const isSelectedByCategory = hasFilteredCategory || slfCoveragesFiltered;
            const isNoneBenefitType = !resource.benefitTypes.length;

            const hasAnySelectedBenefitType = selectedBenefitTypes.length
              ? selectedBenefitTypes.some((benefitType) =>
                  resource.benefitTypes.includes(benefitType),
                )
              : true;

            if (isNoneBenefitTypeSelected) {
              return hasCategoriesSelected
                ? isSelectedByCategory && isNoneBenefitType
                : isNoneBenefitType;
            }

            return hasCategoriesSelected
              ? isSelectedByCategory && hasAnySelectedBenefitType
              : hasAnySelectedBenefitType;
          }

          return true;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      return resources_;
    }
  }, [documents, webLinks, client, beneficiaryDesignationWebLinks, values]);

  return resources;
}
