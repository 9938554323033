import {
  getOneOfValidation,
  nullNotAllowedTestConfig,
  getOneOfValidationRequired,
  percentageValidationSchema,
} from "shared/validation/validation";
import * as Yup from "yup";
import {
  sendClaimsCheckTo,
  type TerminationDate,
  pfmlPremiumContributionTypes,
  claimsCheckPayee,
  pfmlOptions,
} from "../types/Plan";
import type { PFMLPremiumContributionType } from "../types/Plan";
import type { ClaimsCheckPayee, PFMLOptions, SendClaimsCheckTo } from "@prisma/client";

import type { EmployerPremiumPayments } from "shared/types/EmployeeClassPlan";
import type { ValueOf } from "shared/types/Helper";

export const terminationDateSchema = getOneOfValidation<TerminationDate>(
  ["DATE_OF_TERM", "END_OF_MONTH"],
  "Please select a termination date",
);

export const terminationDateSchemaNullable = Yup.mixed<TerminationDate>()
  .oneOf<TerminationDate>(["DATE_OF_TERM", "END_OF_MONTH"], "Please select a termination date")
  .nullable();

export const terminationDateSchemaPrefill = terminationDateSchema.when("$prefill", {
  is: (prefill: boolean | undefined) => prefill,
  then: (schema) => schema.notRequired(),
});

export const sendClaimsChecksToValidation = Yup.mixed<SendClaimsCheckTo>()
  .oneOf<SendClaimsCheckTo>(sendClaimsCheckTo, "Please provide a response")
  .nullable();

export const claimsCheckPayeeValidation = Yup.mixed<ClaimsCheckPayee>()
  .oneOf<ClaimsCheckPayee>(claimsCheckPayee, "Please provide a response")
  .nullable()
  .when("sendClaimsCheckTo", {
    is: "EMPLOYER",
    then: (schema) => schema.required("Please provide a response"),
  })
  .when(["$prefill"], {
    is: (prefill: boolean | undefined) => prefill,
    then: (schema) => schema.notRequired(),
  });

export const planAdminPayeeContactIdValidation = Yup.string()
  .optional()
  .nullable()
  .when("claimsCheckPayee", {
    is: "PLAN_ADMINISTRATOR",
    then: (schema) => schema.required("Please select a plan admin as payee"),
  })
  .when(["$prefill"], {
    is: (prefill: boolean | undefined) => prefill,
    then: (schema) => schema.notRequired(),
  });

export const claimCheckMailingLocationContactProps = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "address1",
  "address2",
  "city",
  "state",
  "zipCode",
] as const;

export type ClaimCheckMailingLocationContactProps = ValueOf<
  typeof claimCheckMailingLocationContactProps
>;

export const someoneElsePayeeContactIdValidation = Yup.string()
  .optional()
  .nullable()
  .when("claimsCheckPayee", {
    is: "SOMEONE_ELSE",
    then: (schema) => schema.required("Please select someone else as payee"),
  })
  .when("$prefill", {
    is: (prefill: boolean | undefined) => prefill,
    then: (schema) => schema.notRequired(),
  });

export const pfmlOptionValidation = Yup.mixed<PFMLOptions>()
  .oneOf<PFMLOptions>(pfmlOptions)
  .required("Please provide a response")
  .nullable()
  .optional()
  .test(nullNotAllowedTestConfig());

export const pfmlPremiumContributionTypeValidation = Yup.mixed<PFMLPremiumContributionType>()
  .oneOf<PFMLPremiumContributionType>(pfmlPremiumContributionTypes, "Please provide a response")
  .nullable();

export const planValidationSchema = Yup.object({
  // PFML Preferences
  pfmlPremiumContributionType: getOneOfValidationRequired<PFMLPremiumContributionType>(
    pfmlPremiumContributionTypes,
  ).nullable(),
  pfmlEmployerContributionPercentage: percentageValidationSchema
    .notRequired()
    .when(["pfmlPremiumContributionType", "$prefill"], {
      is: (
        pfmlPremiumContributionType: PFMLPremiumContributionType,
        prefill: boolean | undefined,
      ) => pfmlPremiumContributionType === "SHARED" && !prefill,
      then: (_) => percentageValidationSchema.required("Please enter an amount"),
    }),
  pfmlEmployerPremiumPayments: Yup.mixed<EmployerPremiumPayments>()
    .oneOf<EmployerPremiumPayments>(["PRE_TAX", "POST_TAX"], "Please provide a response")
    .nullable()
    .when(["pfmlPremiumContributionType", "$prefill"], {
      is: (
        pfmlPremiumContributionType: PFMLPremiumContributionType,
        prefill: boolean | undefined,
      ) => pfmlPremiumContributionType === "SHARED" && !prefill,
      then: (schema) => schema.required("Please provide a response"),
    }),
  pfmlThreeYearLookBackPercent: percentageValidationSchema
    .notRequired()
    .when(["pfmlPremiumContributionType", "pfmlEmployerPremiumPayments", "$prefill"], {
      is: (
        pfmlPremiumContributionType: PFMLPremiumContributionType | null,
        pfmlEmployerPremiumPayments: EmployerPremiumPayments | null,
        prefill: boolean | undefined,
      ) =>
        pfmlPremiumContributionType === "SHARED" &&
        pfmlEmployerPremiumPayments === "POST_TAX" &&
        !prefill,
      then: (schema) => schema.required("Please enter an amount"),
    }),
});
