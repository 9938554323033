import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { InternalControl } from "client/src/components/InternalControl/InternalControl";
import { Modal } from "client/src/components/Modal/Modal";
import { ProgressBar } from "client/src/components/ProgressBar/ProgressBar";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  QPSErrorBanner,
  parseQPSSyncErrorResponse,
} from "client/src/domain/EIF/QPS/QPSErrorBanner";
import { useSyncWithQPS } from "client/src/hooks/qps";
import { useToggler } from "client/src/hooks/useToggler";
import { useMemo } from "react";
import { unique } from "shared/utils/utils";
import styles from "./syncWithQPSInternalControl.module.less";
import type { ResponseError } from "client/src/hooks/query";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type SyncWithQPSInternalControlPresentationProps = {
  client: Client;
  employeeClasses: EmployeeClass[];
  syncWithQPS: ReturnType<typeof useSyncWithQPS>["mutateAsync"];
  isPendingSyncWithQPS: boolean;
  errorSyncWithQPS: ResponseError | null;
  isSuccessSyncWithQPS: boolean;
};

export const SyncWithQPSInternalControlPresentation = ({
  client,
  employeeClasses,
  errorSyncWithQPS,
  isPendingSyncWithQPS,
  isSuccessSyncWithQPS,
  syncWithQPS,
}: SyncWithQPSInternalControlPresentationProps) => {
  const [showModal, toggleModal] = useToggler();

  const onSyncWithQPS = async () => {
    try {
      await syncWithQPS({
        params: { clientId: client.id },
      });
    } finally {
      toggleModal();
    }
  };

  const importedEmployeeClasses = useMemo(
    () => employeeClasses.filter(({ importedFromQPS }) => importedFromQPS),
    [employeeClasses],
  );
  const qpsClassesDescription = useMemo(
    () =>
      unique(
        importedEmployeeClasses.flatMap(({ qpsClasses }) =>
          qpsClasses.map((qpsClass) => `${qpsClass.planDesignName} (${qpsClass.adminClassName})`),
        ),
      ),
    [importedEmployeeClasses],
  );
  const qpsErrorDetails = parseQPSSyncErrorResponse(errorSyncWithQPS);

  return (
    <>
      <InternalControl
        buttonLabel="Push to QPS (legacy)"
        position="detached"
        onClick={toggleModal}
        extraContent={
          <>
            {isSuccessSyncWithQPS && (
              <AlertBanner
                variant="success"
                message={<Body3>Digital SmartForm successfully synced to QPS</Body3>}
              />
            )}
            {qpsErrorDetails.length > 0 && (
              <StackY dist={16}>
                {qpsErrorDetails.map((qpsSyncError, errorIndex) => (
                  <QPSErrorBanner qpsSyncError={qpsSyncError} key={errorIndex} />
                ))}
              </StackY>
            )}
          </>
        }
      />
      <Modal
        maskClosable={false}
        title="Push Digital SmartForm to QPS"
        open={showModal}
        footer={null}
        focusTriggerAfterClose={false}
        onCancel={toggleModal}
      >
        {isPendingSyncWithQPS ? (
          <div className={styles.progressBarContainer}>
            <ProgressBar id="qps-progress-bar" bottomText="" />
            <Body2 as="div">Syncing...</Body2>
            <Body5 as="div">This might take a few seconds</Body5>
          </div>
        ) : (
          <ul className="body3 mb-48">
            <li>Sold Case Information - Documents</li>
            <li>Sold Case Information - Policy Info</li>
            <li>Sold Case Information - ERISA</li>
            {qpsClassesDescription.length && (
              <li>
                Risk Approval
                <ul>
                  {qpsClassesDescription.map((qpsClassDescription, descriptionIndex) => (
                    <li key={descriptionIndex}>{qpsClassDescription}</li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        )}
        <Row justify="end">
          <Col>
            <StackX dist={8}>
              <Button
                type="text"
                onClick={toggleModal}
                size="middle"
                disabled={isPendingSyncWithQPS}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onSyncWithQPS}
                size="middle"
                disabled={isPendingSyncWithQPS}
              >
                Push
              </Button>
            </StackX>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

type SyncWithQPSInternalControlProps = {
  client: Client;
  employeeClasses: EmployeeClass[];
};

/**
 * @deprecated in case we enabled the Import QPS classes drawer
 */
export const SyncWithQPSInternalControl = ({
  client,
  employeeClasses,
}: SyncWithQPSInternalControlProps) => {
  const {
    mutateAsync: syncWithQPS,
    isPending: isPendingSyncWithQPS,
    error: errorSyncWithQPS,
    isSuccess: isSuccessSyncWithQPS,
  } = useSyncWithQPS();

  return (
    <SyncWithQPSInternalControlPresentation
      client={client}
      employeeClasses={employeeClasses}
      syncWithQPS={syncWithQPS}
      isPendingSyncWithQPS={isPendingSyncWithQPS}
      errorSyncWithQPS={errorSyncWithQPS}
      isSuccessSyncWithQPS={isSuccessSyncWithQPS}
    />
  );
};
