import { Modal } from "antd";
import { ConfirmDialogDeleteClient } from "client/src/components/ConfirmDialog/ConfirmDialogDeleteClient";
import clsx from "clsx";
import { useState } from "react";

import { RouteData } from "../../../../shared/config/routeData";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import { GoBackLink } from "../../components/Link/GoBackLink";
import { AddOrUpdateClientForm } from "../../domain/Client/Form/AddOrUpdateClientForm";

import * as styles from "./replaceClient.module.less";

type ReplaceClientProps = {
  clientId: string;
  clientName: string | null | undefined;
  handleDelete: () => Promise<void>;
};

type ClientUpdateProps = {
  clientId: string;
};

const ClientUpdate = ({ clientId }: ClientUpdateProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Modal
        destroyOnClose={true}
        open={isModalVisible}
        footer={null}
        centered={true}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      >
        <AddOrUpdateClientForm
          onClientAction={() => setIsModalVisible(false)}
          actionType="update"
          clientId={clientId}
        />
      </Modal>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          setIsModalVisible(true);
        }}
      >
        Link a new ticket
      </Button>
    </>
  );
};

export const ReplaceClient = ({ clientId, clientName, handleDelete }: ReplaceClientProps) => {
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const errorContent = (
    <>
      <div className={clsx(styles.backLink, "mb-12")}>
        <GoBackLink to={RouteData.homeInternal.getPath()}>&lt; Back to all Clients</GoBackLink>
      </div>
      <h1>This Jira ticket no longer exists for: {clientName}.</h1>
      <p>Would you like to delete the client or link a new Jira ticket?</p>
      <p className="stack-x-16">
        <Button size="large" type="primary" onClick={() => setIsDeleteDialogVisible(true)}>
          Delete client
        </Button>
        <ConfirmDialogDeleteClient
          clientName={clientName ?? ""}
          isVisible={isDeleteDialogVisible}
          onCancel={() => setIsDeleteDialogVisible(false)}
          handleDelete={handleDelete}
        />
        <ClientUpdate clientId={clientId} />
      </p>
    </>
  );

  return <LoadingError error={"error"} type="custom" childElement={errorContent} />;
};
