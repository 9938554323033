import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { Fragment } from "react";
import { getAdvanceAndArrearsCoveragesBreakdown } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";
import { getBillTuples } from "shared/utils/bill";
import { getContactHasExpectedAccess } from "shared/utils/contact";
import { formatFullName } from "shared/utils/format";
import { assertIsDefined } from "shared/utils/utils";
import * as Yup from "yup";
import type { useFormik } from "formik";
import type { Bill, BillGroupInputs, BillPreview } from "shared/types/Bill";
import type { Client, Policy } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ValueOf, ValuesForValidationSchema } from "shared/types/Helper";
import type { BillingPreferencesFormValues } from "shared/validation/bill";

const fieldsToCopy = [
  "billName",
  "groupByLocationIds",
  "splitTags",
  "contactId",
  "locationId",
  "numberOfEmployees",
  "categorizeEmployees",
  "categorizeEmployees_secondary",
] as const;

type FieldToCopy = ValueOf<typeof fieldsToCopy>;

const validationSchema = Yup.object({
  policyId: Yup.string().required("Please select a policy to copy from"),
  billId: Yup.string().required("Please select a bill to copy from"),
  fields: Yup.array()
    .of(Yup.mixed<FieldToCopy>().oneOf(fieldsToCopy).required())
    .strict()
    .min(1, "Please select at least one field to copy")
    .required(),
});

type Props = {
  open: boolean;
  onCancel: () => void;
  onCopy: (copiedValues: Partial<BillGroupInputs>) => void;
  client: Client;
  policy: Policy;
  bills: Bill[];
  admins: Contact[];
  billGroupInputs: BillGroupInputs;
  billingPreferencesValues: Pick<
    BillingPreferencesFormValues,
    "billingAdministrationType" | "billSplitType" | "advanceOrArrears"
  >;
};

export function CopySettingsFromAnotherBillModal(props: Props) {
  const {
    open,
    onCancel,
    onCopy,
    client,
    policy,
    bills,
    admins,
    billGroupInputs,
    billingPreferencesValues,
  } = props;

  const policiesToCopyFrom = getPoliciesToCopyFrom(client, policy, bills);

  const initialPolicyId = policiesToCopyFrom[0]?.id ?? "";
  const initialBillId = bills.find((bill) => bill.policyId === initialPolicyId)?.id ?? "";

  const formik = useSlobFormik({
    initialValues: {
      policyId: initialPolicyId,
      billId: initialBillId,
      fields: [],
    },
    validationSchema,
    onSubmit: (values) => {
      const { billTupleToCopyFrom } = getPolicyAndBillDetails({
        policiesToCopyFrom,
        bills,
        values,
      });
      assertIsDefined(billTupleToCopyFrom, "billTupleToCopyFrom");
      const copiedValues = copy(billTupleToCopyFrom, values.fields);
      onCopy(copiedValues);
      formik.resetForm();
    },
  });

  const { policyToCopyFrom, billTuples, billTupleToCopyFrom } = getPolicyAndBillDetails({
    policiesToCopyFrom,
    bills,
    values: formik.values,
  });

  const willOverwriteValues = formik.values.fields.some((field) => billGroupInputs[field] != null);

  return (
    <Modal
      width={780}
      title="Copy settings from another bill"
      open={open}
      footer={null}
      onCancel={onCancel}
      focusTriggerAfterClose={false}
      destroyOnClose
    >
      <form onSubmit={formik.handleSubmit}>
        {willOverwriteValues && (
          <div className="mb-32">
            <AlertBanner
              variant="warning"
              message={
                <Body3>
                  Existing responses for this bill will be replaced by if you choose to overwrite
                  those specific fields.
                </Body3>
              }
            />
          </div>
        )}

        <p id="policyId-label">Select which policy to copy from</p>

        <RadioGroup
          name="policyId"
          aria-labelledby="policyId-label"
          direction="vertical"
          disabled={false}
          touched={formik.touched.policyId}
          error={formik.errors.policyId}
          value={formik.values.policyId}
          onChange={async (e) => {
            const policyId = e.target.value;
            const billId = bills.find((bill) => bill.policyId === policyId)?.id ?? "";
            await formik.setFieldValue("billId", billId);
            await formik.setFieldValue("fields", []);
            return formik.handleChange(e);
          }}
          options={policiesToCopyFrom.map((policy) => ({
            value: policy.id,
            label: policy.primaryPolicy
              ? `Policy #${policy.slfPolicyNumber} (Primary)`
              : `Policy #${policy.slfPolicyNumber}`,
          }))}
        />

        {policyToCopyFrom && billTuples.length === 0 && (
          <p>There are no bills in this policy to copy.</p>
        )}

        {billTuples.length > 1 && (
          <>
            <p id="billId-label">Select which bill to copy from</p>

            <RadioGroup
              name="billId"
              aria-labelledby="billId-label"
              direction="vertical"
              disabled={false}
              touched={formik.touched.billId}
              error={formik.errors.billId}
              value={formik.values.billId}
              onChange={async (e) => {
                await formik.setFieldValue("fields", []);
                return formik.handleChange(e);
              }}
              options={billTuples.map((billPairs, index) => {
                if (Array.isArray(billPairs)) {
                  const [advanceBill, arrearsBill] = billPairs;
                  return {
                    value: advanceBill.id,
                    label: `"${advanceBill.billName}" and "${arrearsBill.billName}"`,
                  };
                } else {
                  const bill = billPairs;
                  return {
                    value: bill.id,
                    label: bill.billName || `Bill ${index + 1}`,
                  };
                }
              })}
            />
          </>
        )}

        {policyToCopyFrom && billTupleToCopyFrom && (
          <>
            <p>Select what you would like to copy</p>

            <StackY dist={8} wrap={false}>
              <FieldCheckboxes
                policyToCopyFrom={policyToCopyFrom}
                billTupleToCopyFrom={billTupleToCopyFrom}
                policy={policy}
                admins={admins}
                billingPreferencesValues={billingPreferencesValues}
                formik={formik}
              />
            </StackY>
          </>
        )}

        <Row justify="end" align="middle" gutter={24} className="mt-40">
          <Col>
            <Button type="text-only" size="middle" onClick={onCancel}>
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              size="middle"
              htmlType="submit"
              disabled={formik.values.fields.length === 0}
            >
              Copy
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
}

export function getPoliciesToCopyFrom(client: Client, policy: Policy, bills: Bill[]): Policy[] {
  const policyIds = new Set(bills.map((bill) => bill.policyId));

  const policiesToCopyFrom = client.policies
    .filter((p) => p.billingAdministrationType === "LIST" && p.id !== policy.id)
    .filter((p) => policyIds.has(p.id));

  return policiesToCopyFrom;
}

function getPolicyAndBillDetails(args: {
  policiesToCopyFrom: Policy[];
  bills: Bill[];
  values: ValuesForValidationSchema<typeof validationSchema>;
}) {
  const { policiesToCopyFrom, bills, values } = args;

  const policyToCopyFrom = policiesToCopyFrom.find((p) => p.id === values.policyId);

  const billsToCopyFrom = bills.filter((bill) => bill.policyId === values.policyId);

  const billTuples = getBillTuples(policyToCopyFrom?.billSplitType, billsToCopyFrom);

  const billTupleToCopyFrom = billTuples.find((tuple) =>
    Array.isArray(tuple)
      ? tuple[0].id === values.billId || tuple[1].id === values.billId
      : tuple.id === values.billId,
  );

  return {
    policyToCopyFrom,
    billTuples,
    billTupleToCopyFrom,
  };
}

function FieldCheckboxes(args: {
  policyToCopyFrom: Policy;
  billTupleToCopyFrom: BillPreview | [BillPreview, BillPreview];
  policy: Policy;
  admins: Contact[];
  billingPreferencesValues: Pick<
    BillingPreferencesFormValues,
    "billingAdministrationType" | "billSplitType" | "advanceOrArrears"
  >;
  formik: ReturnType<typeof useFormik<ValuesForValidationSchema<typeof validationSchema>>>;
}) {
  const {
    policyToCopyFrom,
    billTupleToCopyFrom,
    policy,
    admins,
    billingPreferencesValues,
    formik,
  } = args;

  const targetHasMixedTiming =
    billingPreferencesValues.billingAdministrationType === "LIST"
      ? getAdvanceAndArrearsCoveragesBreakdown(policy, billingPreferencesValues.advanceOrArrears)
          .hasMixedTiming
      : false;

  const billToCopyFrom = Array.isArray(billTupleToCopyFrom)
    ? billTupleToCopyFrom[0]
    : billTupleToCopyFrom;

  const contactToCopy = admins.find((a) => a.id === billToCopyFrom.contactId);
  const hasBillingAccess = contactToCopy
    ? getContactHasExpectedAccess(contactToCopy, [policy.id], "billingAccess")
    : true;
  const [accessEnabled, toggleAccessEnabled] = useToggler();

  const commonCheckboxes = (
    [
      ...(policyToCopyFrom.billSplitType !== "NONE" &&
      billingPreferencesValues.billSplitType !== "NONE"
        ? ([["billName", "Name of bill"]] as const)
        : []),

      ...(policyToCopyFrom.billSplitType === "LOCATION" &&
      billingPreferencesValues.billSplitType === "LOCATION"
        ? ([["groupByLocationIds", "Locations on bill"]] as const)
        : []),
      ...(policyToCopyFrom.billSplitType === "TAGS" &&
      billingPreferencesValues.billSplitType === "TAGS"
        ? ([["splitTags", "Groups on bill"]] as const)
        : []),

      ["contactId", "Administrator"],
      ...(billToCopyFrom.hasDifferentMailingAddress
        ? ([["locationId", "Billing address"]] as const)
        : []),
      ["numberOfEmployees", "# of eligible employees"] as const,
    ] as const
  ).map(([field, label]) => {
    const adminHasNoBillingAccess = field === "contactId" && !hasBillingAccess;

    return (
      <Fragment key={field}>
        <Checkbox
          id={`fields_${field}`}
          name="fields"
          label={label}
          value={field}
          disabled={
            (field === "contactId" || field === "locationId") && !hasBillingAccess && !accessEnabled
          }
          checked={formik.values.fields.includes(field)}
          onChange={formik.handleChange}
          content={
            adminHasNoBillingAccess &&
            !accessEnabled && (
              <Body3>
                To select this administrator ({formatFullName(contactToCopy)}), you must give this
                user access to this policy.
              </Body3>
            )
          }
        />

        {adminHasNoBillingAccess && (
          <div className="ml-32">
            <Button
              type="text-only"
              onClick={async () => {
                if (accessEnabled) {
                  const nextFields = formik.values.fields.filter(
                    (f) => f !== field && f !== "locationId",
                  );
                  await formik.setFieldValue("fields", nextFields);
                }
                toggleAccessEnabled();
              }}
            >
              {accessEnabled ? "Undo access change" : "Enable access"}
            </Button>
          </div>
        )}
      </Fragment>
    );
  });

  // Policy A has Advance & Arrears
  if (Array.isArray(billTupleToCopyFrom)) {
    return (
      <>
        {commonCheckboxes}

        {targetHasMixedTiming ? (
          <>
            {[
              ["categorizeEmployees", "How to sort employees (Advance)"] as const,
              ["categorizeEmployees_secondary", "How to sort employees (Arrears)"] as const,
            ].map(([value, label]) => (
              <Checkbox
                key={value}
                id={`fields_${value}`}
                name="fields"
                label={label}
                value={value}
                disabled={false}
                checked={formik.values.fields.includes(value)}
                onChange={formik.handleChange}
              />
            ))}
          </>
        ) : (
          <Row gutter={16}>
            <Col>
              <Checkbox
                id="fields_categorizeEmployees"
                name="fields"
                label="How to sort employees (Advance)"
                value="categorizeEmployees"
                disabled={formik.values.fields.includes("categorizeEmployees_secondary")}
                checked={formik.values.fields.includes("categorizeEmployees")}
                onChange={formik.handleChange}
              />
            </Col>

            <Col>
              <Body3>OR</Body3>
            </Col>

            <Col>
              <Checkbox
                id="categorizeEmployees_secondary"
                name="fields"
                label="How to sort employees (Arrears)"
                value="categorizeEmployees_secondary"
                disabled={formik.values.fields.includes("categorizeEmployees")}
                checked={formik.values.fields.includes("categorizeEmployees_secondary")}
                onChange={formik.handleChange}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
  // Policy A has Advance only or Arrears only
  else {
    const bill = billTupleToCopyFrom;

    return (
      <>
        {commonCheckboxes}

        <Checkbox
          id="fields_categorizeEmployees"
          name="fields"
          label={`How to sort employees (${bill.billTiming})`}
          value="categorizeEmployees"
          disabled={false}
          checked={formik.values.fields.includes("categorizeEmployees")}
          onChange={formik.handleChange}
        />
      </>
    );
  }
}

function copy(
  billTupleToCopyFrom: BillPreview | [BillPreview, BillPreview],
  fields: FieldToCopy[],
) {
  const fieldsSet = new Set(fields);

  const bill1 = Array.isArray(billTupleToCopyFrom) ? billTupleToCopyFrom[0] : billTupleToCopyFrom;
  const bill2 = Array.isArray(billTupleToCopyFrom) ? billTupleToCopyFrom[1] : null;

  const copiedValues: Partial<BillGroupInputs> = {
    ...(fieldsSet.has("billName") ? { billName: bill1.billName } : {}),
    ...(fieldsSet.has("groupByLocationIds")
      ? { groupByLocationIds: bill1.groupedByLocations?.map((g) => g.id) }
      : {}),
    ...(fieldsSet.has("splitTags") ? { splitTags: bill1.splitTags } : {}),
    ...(fieldsSet.has("contactId")
      ? { contactId: bill1.contactId, hasDifferentMailingAddress: bill1.hasDifferentMailingAddress }
      : {}),
    ...(fieldsSet.has("locationId")
      ? { locationId: bill1.locationId }
      : !bill1.hasDifferentMailingAddress
      ? { locationId: null }
      : {}),

    ...(fieldsSet.has("numberOfEmployees") ? { numberOfEmployees: bill1.numberOfEmployees } : {}),

    // How to sort employees
    ...(fieldsSet.has("categorizeEmployees")
      ? {
          categorizeEmployees: bill1.categorizeEmployees,
          employeeCategorizationType: bill1.employeeCategorizationType,
          categorizeByLocationIds: bill1.categoriesByLocation?.map((locs) =>
            locs.map((loc) => loc.id),
          ),
          categoriesByTags: bill1.categoriesByTags,
        }
      : {}),

    ...(fieldsSet.has("categorizeEmployees_secondary")
      ? {
          categorizeEmployees_secondary: bill2?.categorizeEmployees,
          employeeCategorizationType_secondary: bill2?.employeeCategorizationType,
          categorizeByLocationIds_secondary: bill2?.categoriesByLocation?.map((locs) =>
            locs.map((loc) => loc.id),
          ),
          categoriesByTags_secondary: bill2?.categoriesByTags,
        }
      : {}),
  };

  return copiedValues;
}
