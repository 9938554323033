import { Modal } from "antd";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useListParams } from "client/src/hooks/useListParams";
import { useState } from "react";

import { SlobList } from "../../../components/SlobList/SlobList";
import { useGetSlfInternals } from "../../../hooks/user";
import { SlfInternalCreate } from "../Form/SlfInternalCreate";
import { SlfInternalUpdate } from "../Form/SlfInternalUpdate";

import { SlfInternalTable } from "./SlfInternalTable";
import type { ClientFeatureToggles } from "shared/types/Toggles";

import type { SlfInternal } from "shared/types/User";

export const SLF_INTERNAL_USERS_LIST_PAGE_SIZE = 25;

export const SlfInternalList = ({ featureToggles }: { featureToggles?: ClientFeatureToggles }) => {
  const [editState, setEditState] = useState<SlfInternal | null>(null);

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (slfInternal: SlfInternal) => {
    setEditState(slfInternal);
  };

  const listParams = useListParams({
    initialPageSize: SLF_INTERNAL_USERS_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "lastName",
    },
  });

  const { clientId } = useClientHubParams();

  const query = useGetSlfInternals({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    ...(clientId && { clientId }),
  });

  return (
    <>
      <SlobList
        TableComponent={SlfInternalTable}
        listParams={listParams}
        query={query}
        LeftHeader={SlfInternalCreate}
        showModal={showModal}
        featureToggles={featureToggles}
      />
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <SlfInternalUpdate slfInternal={editState} handleClose={hideModal} />
        </Modal>
      )}
    </>
  );
};
