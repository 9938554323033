import { isColor } from "shared/validation/color";
import * as styles from "./ColorSwatch.module.less";

type ColorSwatchProps = {
  color: unknown;
};

export enum DataTestId {
  ColorSwatch = "ColorSwatch",
}

export function ColorSwatch({ color }: ColorSwatchProps) {
  return (
    <div
      className={styles.colorSwatch}
      style={{ backgroundColor: isColor(color) ? color : "transparent" }}
      data-testid={DataTestId.ColorSwatch}
    />
  );
}
