import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { Body2 } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import * as styles from "./Chip.module.less";
import type { InputHTMLAttributes } from "react";

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, "className" | "style" | "disabled"> & {
  name: string;
  touched: boolean | undefined;
  error?: string | string[] | undefined;
  errorId?: string;
  children: string;
} & (
    | { disabled?: false; disabledReason?: never }
    | { disabled: true; disabledReason: string }
    | { disabled?: boolean; disabledReason: string | undefined }
  );

export function Chip(props: Props) {
  const { children, touched, error, errorId, disabled, disabledReason, ...inputProps } = props;

  const { checked, onChange } = inputProps;

  const handleOnChange: typeof onChange = (e) => {
    if (disabled) {
      return;
    }
    onChange?.(e);
  };

  const sanitizedValue =
    inputProps.value != null ? String(inputProps.value).replaceAll(" ", "_") : inputProps.value;
  const id = sanitizedValue
    ? `${inputProps.name}__${sanitizedValue}__tooltip`
    : `${inputProps.name}__tooltip`;

  return (
    <SlobPopover
      id={id}
      content={disabledReason}
      placement={"bottom"}
      trigger={disabled && disabledReason ? ["hover", "focus"] : []}
      tight
    >
      {(descriptionId?: string) => (
        <label
          className={clsx(
            styles.chip,
            checked && styles.checked,
            disabled && styles.disabled,
            touched && (error || errorId) && styles.error,
          )}
        >
          <input
            aria-invalid={touched && !!error}
            aria-disabled={disabled}
            {...inputProps}
            aria-describedby={descriptionId}
            type="checkbox"
            className={styles.chipInput}
            onChange={handleOnChange}
          />

          <span className="stack-x-8">
            {!checked && (
              <FontAwesomeIcon
                icon={faPlus}
                color={disabled ? "var(--color-gray-500)" : undefined}
                className={styles.icon}
              />
            )}
            <Body2
              blueMedium={!checked && !disabled}
              white={checked && !disabled}
              greyMedium={disabled}
            >
              {children}
            </Body2>
            {checked && (
              <FontAwesomeIcon
                icon={faTimes}
                color={disabled ? "var(--color-gray-500)" : "white"}
                className={styles.icon}
              />
            )}
          </span>
        </label>
      )}
    </SlobPopover>
  );
}
