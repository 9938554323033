import { useState } from "react";
import { getIsBenAdmin, getIsBroker } from "shared/rbac/rbac";
import { FormInput } from "../Form/Input";
import { Body2 } from "../Typography/Typography";
import { ConfirmDialog } from "./ConfirmDialog";
import type { BEN_ADMIN, BROKER } from "shared/rbac/roles";
import type { BenAdmin, Broker } from "shared/types/User";

type Props = {
  role: typeof BROKER | typeof BEN_ADMIN;
  isDeleteDialogVisible: boolean;
  setIsDeleteDialogVisible: (v: boolean) => void;
  activeUser: Broker | BenAdmin | null | undefined;
  handleDelete: () => Promise<void>;
};

export function ConfirmDialogDeleteUser(props: Props) {
  const { isDeleteDialogVisible, setIsDeleteDialogVisible, role, activeUser, handleDelete } = props;

  const [deleteConfirmValue, setDeleteConfirmValue] = useState<string>("");
  const [deleteConfirmErrorMsg, setDeleteConfirmErrorMsg] = useState<string>("");
  const [deleteConfirmTouched, setDeleteConfirmTouched] = useState<boolean>(false);

  const isBroker = getIsBroker({ role });
  const userNameDoesntMatchErrorMsg = isBroker
    ? "Please enter the broker's name exactly as it is displayed to confirm"
    : "Please enter the benefits administrator's name exactly as it is displayed to confirm";

  const isNotAssignedToMultipleClients = activeUser && activeUser.clientIds.length <= 1;
  const isBenAdminAndIsNotAssignedToMultipleClients =
    getIsBenAdmin({ role }) && isNotAssignedToMultipleClients;

  return (
    <ConfirmDialog
      title={isBroker ? "Delete broker" : "Delete benefits administrator"}
      isVisible={isDeleteDialogVisible}
      onCancel={() => {
        setDeleteConfirmValue("");
        setDeleteConfirmErrorMsg("");
        setDeleteConfirmTouched(false);
        setIsDeleteDialogVisible(false);
      }}
      onConfirm={async () => {
        if (isBenAdminAndIsNotAssignedToMultipleClients) {
          await handleDelete();
        } else {
          if (deleteConfirmValue === activeUser?.fullName) {
            await handleDelete();
            setDeleteConfirmValue("");
            setDeleteConfirmErrorMsg("");
            setDeleteConfirmTouched(false);
          } else {
            setDeleteConfirmTouched(true);
            setDeleteConfirmErrorMsg(userNameDoesntMatchErrorMsg);
          }
        }
      }}
      confirmActionText="Yes"
      cancelActionText="No"
    >
      {isBenAdminAndIsNotAssignedToMultipleClients ? (
        <p>
          Are you sure you want to delete this benefits administrator?
          <br />
          <Body2>{activeUser?.fullName}</Body2>
        </p>
      ) : (
        <>
          <p>
            {isBroker
              ? "Are you sure you want to delete this broker?"
              : "Are you sure you want to delete this benefits administrator?"}
            <br />
            <Body2>{activeUser?.fullName}</Body2>
          </p>

          {isBroker ? (
            <p>
              Deleting a broker can’t be undone and will{" "}
              <Body2 redMedium>delete this broker permanently for all clients</Body2> they are
              assigned to. If you only want to remove this broker from this specific client, select
              the <Body2>Unassign from client option</Body2> instead.
            </p>
          ) : (
            <p>
              Deleting a benefits administrator can’t be undone and will{" "}
              <Body2 redMedium>
                delete this benefits administrator permanently for all clients
              </Body2>{" "}
              they are assigned to. If you only want to remove this benefits administrator from this
              specific client, select the <Body2>Unassign from client option</Body2> instead.
            </p>
          )}

          <form>
            <FormInput
              value={deleteConfirmValue}
              onChange={(e) => {
                // this error message will only display after the first attempt to delete b/c of the touched property
                e.target.value !== activeUser?.fullName
                  ? setDeleteConfirmErrorMsg(userNameDoesntMatchErrorMsg)
                  : setDeleteConfirmErrorMsg("");
                setDeleteConfirmValue(e.target.value);
              }}
              label={`Type "${activeUser?.fullName}" to confirm`}
              maxLength={activeUser?.fullName?.length ?? 0}
              name="deleteConfirmValue"
              error={deleteConfirmErrorMsg}
              touched={deleteConfirmTouched}
            />
          </form>
        </>
      )}
    </ConfirmDialog>
  );
}
