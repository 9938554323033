import * as Yup from "yup";

import { documentBenefitTypes } from "../types/DocumentBenefitType";

import type { DocumentBenefitType } from "../types/DocumentBenefitType";
import type { ValueOf } from "../types/Helper";

export const benefitTypesValidationSchema = Yup.array().of(
  Yup.mixed<DocumentBenefitType>().oneOf(documentBenefitTypes.slice()).required(),
);

export const processStatusTypes = [
  "SUBMITTED",
  "SUBMITTED_WITH_ERRORS",
  "SUBMITTED_WITH_WARNINGS",
  "SUBMITTED_WITH_ERRORS_AND_WARNINGS",
  "SUBMITTED_WITH_FAILURES",
] as const;

export type ProcessStatus = ValueOf<typeof processStatusTypes>;

export const censusSourceTypes = ["MDT", "ENROLLMENT_FORM", "EXPORT", "OTHER"] as const;

export type CensusSource = ValueOf<typeof censusSourceTypes>;

export const documentSourceValidation = Yup.object({
  policyId: Yup.string(),
  documents: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required("Document ID is required"),
        censusSource: Yup.mixed<CensusSource>()
          .oneOf(censusSourceTypes.concat())
          .required("File source is required"),
        platform: Yup.string().when("censusSource", {
          is: "EXPORT",
          then: (schema) => schema.required("Export platform is required"),
        }),
        censusSourceOther: Yup.string().when("censusSource", {
          is: "OTHER",
          then: (schema) => schema.required("File source other is required"),
        }),
      }),
    )
    .required(),
});

export const documentSourceValidationClient = Yup.object({
  policyId: Yup.string(),
  documents: Yup.array()
    .of(
      Yup.object({
        id: Yup.string().required("Document ID is required"),
        censusSource: Yup.mixed<CensusSource>()
          .oneOf(
            censusSourceTypes.concat(),
            "Please select your file's template format to continue",
          )
          .required("File source is required"),
        censusSourceOther: Yup.string().when("censusSource", {
          is: "OTHER",
          then: (schema) => schema.required("Other format description is required"),
        }),
        platform: Yup.string().when("censusSource", {
          is: "EXPORT",
          then: (schema) => schema.required("Export platform is required"),
        }),
      }),
    )
    .required(),
});

export type DocumentMeta = Yup.InferType<typeof documentSourceValidation>;
