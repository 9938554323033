import { slfCoverageNameToLongName } from "shared/types/SlfCoverages";
import { nullNotAllowedTestConfig } from "shared/validation/validation";
import * as Yup from "yup";
import type { SendMonthlyClaimsReportsTo } from "@prisma/client";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

export const monthlyClaimsReportMailingLocationSchema = Yup.object({
  id: Yup.string().optional(),
  clientId: Yup.string().defined().required(),

  sendMonthlyClaimsReportsTo: Yup.mixed<SendMonthlyClaimsReportsTo>()
    .oneOf<SendMonthlyClaimsReportsTo>(["PRIMARY_PLAN_ADMINISTRATOR", "OTHER"])
    .strict()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.test(nullNotAllowedTestConfig("Please provide a response")),
    }),

  sendMonthlyClaimsReportsToContactId: Yup.string()
    .nullable()
    .when("sendMonthlyClaimsReportsTo", {
      is: "OTHER",
      then: (schema) => schema.required("Please select a Sun Life Connect user as the recipient"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  slfCoverages: Yup.array()
    .of(Yup.mixed<SlfCoverageLongName>().oneOf(Object.values(slfCoverageNameToLongName)).required())
    .strict()
    .nullable()
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) =>
        schema
          .test(nullNotAllowedTestConfig("Please provide a response"))
          .min(1, "Select at least one benefit"),
    }),
});
