import { Col, Row } from "antd";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { contributionsTableSpan } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/EIFClassBuilderTieredContributionsTable";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { AvoidPageBreak } from "client/src/utils/print";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { rejectNullableValues } from "shared/utils/utils";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type ReviewLifeADDContributionsProps = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
};

export function ReviewLifeADDContributions(props: ReviewLifeADDContributionsProps) {
  const { client, employeeClass, changeSnapshot, authUser } = props;

  const basicLifeContribution = employeeClass.employeeClassPlans.find(
    (p) => p.plan.benefitType === "LIFE",
  );
  const basicDepLifeContribution = employeeClass.employeeClassPlans.find(
    (p) => p.plan.benefitType === "DEP_LIFE",
  );
  const basicADNDContribution = employeeClass.employeeClassPlans.find(
    (p) => p.plan.benefitType === "BASIC_ADND",
  );

  const employeeClassPlanIds = [
    basicLifeContribution?.id,
    basicDepLifeContribution?.id,
    basicADNDContribution?.id,
  ].filter(rejectNullableValues);

  const changeDetailInfoList = relevantChangesForContributionFields(
    employeeClassPlanIds,
    changeSnapshot,
  );

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  // Need to check for YES and non null contribution amounts to preserve backwards compatibility
  // when changing from YES/NO options to YES/SHARED/NO
  const basicLifeRequiresContributionAmount =
    basicLifeContribution?.employerContribution === "SHARED" ||
    (basicLifeContribution?.employerContribution === "YES" &&
      basicLifeContribution.eeContributionAmount !== null);

  const depLifeRequiresContributionAmount =
    basicDepLifeContribution?.employerContribution === "SHARED" ||
    (basicDepLifeContribution?.employerContribution === "YES" &&
      basicDepLifeContribution?.eeContributionAmount !== null);

  const addRequiresContributionAmount =
    basicADNDContribution?.employerContribution === "SHARED" ||
    (basicADNDContribution?.employerContribution === "YES" &&
      basicADNDContribution.eeContributionAmount !== null);

  return (
    <StackY dist={16} wrap={false}>
      <EditedFieldMsg
        changeDetailInfoList={changeDetailInfoList}
        client={client}
        authUser={authUser}
      />

      <section>
        <AvoidPageBreak>
          {basicLifeContribution?.employerContribution === "NO" && (
            <Body3>I am not contributing and employees pay 100%</Body3>
          )}

          {(basicLifeContribution?.employerContribution === "YES" ||
            basicLifeContribution?.employerContribution === "SHARED") && (
            <>
              {basicLifeContribution && (
                <Body3 as="div">
                  <Row>
                    <Col span={contributionsTableSpan.principal.default}>
                      Employee {benefitTypeToCoverage["LIFE"]}
                    </Col>
                    <Col span={contributionsTableSpan.secondary.default}>
                      {basicLifeContribution?.employerContribution === "YES" &&
                        basicLifeContribution.eeContributionAmount === null && (
                          <Body3>100% employer paid</Body3>
                        )}

                      {basicLifeRequiresContributionAmount &&
                        (basicLifeContribution.eeContributionAmount == null ? (
                          suppressErrorsPostSigning ? (
                            <Body3>-</Body3>
                          ) : (
                            <Body3 redMedium>Please enter an amount</Body3>
                          )
                        ) : (
                          `${basicLifeContribution.eeContributionAmount}% employer paid`
                        ))}
                    </Col>
                  </Row>
                </Body3>
              )}
              {basicDepLifeContribution && (
                <Body3 as="div">
                  <Row>
                    <Col span={contributionsTableSpan.principal.default}>
                      {benefitTypeToCoverage["DEP_LIFE"]}
                    </Col>
                    <Col span={contributionsTableSpan.secondary.default}>
                      {basicDepLifeContribution?.employerContribution === "YES" &&
                        basicDepLifeContribution.eeContributionAmount === null && (
                          <Body3>100% employer paid</Body3>
                        )}

                      {depLifeRequiresContributionAmount &&
                        (basicDepLifeContribution.eeContributionAmount == null ? (
                          suppressErrorsPostSigning ? (
                            <Body3>-</Body3>
                          ) : (
                            <Body3 redMedium>Please enter an amount</Body3>
                          )
                        ) : (
                          `${basicDepLifeContribution.eeContributionAmount}% employer paid`
                        ))}
                    </Col>
                  </Row>
                </Body3>
              )}
              {basicADNDContribution && (
                <Body3 as="div">
                  <Row>
                    <Col span={contributionsTableSpan.principal.default}>
                      Employee {benefitTypeToCoverage["BASIC_ADND"]}
                    </Col>
                    <Col span={contributionsTableSpan.secondary.default}>
                      {basicADNDContribution?.employerContribution === "YES" &&
                        basicADNDContribution?.eeContributionAmount === null && (
                          <Body3>100% employer paid</Body3>
                        )}
                      {addRequiresContributionAmount &&
                        (basicADNDContribution.eeContributionAmount == null ? (
                          suppressErrorsPostSigning ? (
                            <Body3>-</Body3>
                          ) : (
                            <Body3 redMedium>Please enter an amount</Body3>
                          )
                        ) : (
                          `${basicADNDContribution.eeContributionAmount}% employer paid`
                        ))}
                    </Col>
                  </Row>
                </Body3>
              )}
            </>
          )}
        </AvoidPageBreak>
      </section>
    </StackY>
  );
}
