import { Anchor } from "client/src/components/Anchor/Anchor";
import { H2, Body3 } from "client/src/components/Typography/Typography";

export function WhatAreDataFeeds() {
  return (
    <div>
      <H2 as="p">What do you need to know about setting up an eligibility data feed?</H2>

      <Body3 as="p">
        An eligibility data feed, commonly referred to as an EDX, EDI or file feed by insurance
        carriers, is used to maintain billing and eligibility accuracy between two systems –
        reducing duplicative and manual data entry.
      </Body3>

      <Body3 as="p">
        If you use a technology platform to manage employee benefits or various HR administrative
        tasks, you may be able to set up a data feed. This will be used after your initial
        enrollment event to pass new enrollments, terminations, and other employee changes into Sun
        Life’s systems. It can typically take between 60-90 days for Sun Life to set up a data feed.
      </Body3>

      <Anchor
        newTabIcon
        target="_blank"
        href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015687571"
      >
        Learn more about data feeds.
      </Anchor>
    </div>
  );
}
