import { Checkbox } from "client/src/components/Form/Checkbox";
import { Row, Col } from "client/src/components/Grid/Grid";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import {
  handleMaskedMonthDayChange,
  handleTaxIdChange,
} from "client/src/utils/handleMaskedInputChange";
import { capitalize } from "client/src/utils/string";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { LocationStateCodes } from "shared/types/Location";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { erisaValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { erisaFormKeyProps } from "shared/validation/otherContractDetailsFormKeys";
import { BulbMessage } from "../../components/BulbMessage/BulbMessage";
import { FormInput } from "../../components/Form/Input";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Eyebrow, Body3 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { ERISAPlanNumber } from "@prisma/client";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const getClientPropertiesToUpdate = (client: Client): Partial<Client> => {
  const fn = getPropertiesToUpdate<Client>(erisaFormKeyProps);
  const propsToUpdate = fn(client);

  return {
    ...propsToUpdate,
    taxId: propsToUpdate.taxId == null ? undefined : propsToUpdate.taxId,
    erisaPlanNumber:
      propsToUpdate.erisaPlanNumber == null ? "PN501" : propsToUpdate.erisaPlanNumber,
  };
};

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

export const formTestId = "erisa-form";

export function EIFERISA(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser, featureToggles } =
    props;

  const eifSubStepId = "erisa";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId, featureToggles }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const policyYear = client.firstPolicyEffective
    ? client.firstPolicyEffective.getUTCFullYear()
    : null;

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: erisaValidationSchema,
    type: subStepName,
    track,
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(erisaFormKeyProps)(client),
  );
  const prefillErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, erisaValidationSchema, { client, prefill: false })
    : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h1>{subStepName}</h1>
        <PFMLBulbMessage client={client} />

        <StackY dist={32}>
          <HubCard disabled={formik.isSubmitting}>
            <p>
              ERISA stands for the Employee Retirement Income Security Act of 1974, and is an
              important federal law that can impact your benefits plan. It imposes obligations on
              group life and health plans (welfare plans) as well as on retirement income plans
              (pension plans).
            </p>

            <p>
              If ERISA applies, we can provide custom ERISA plan details in your employee
              certificate.
            </p>

            <StackY dist={16}>
              <StackY dist={32} wrap={false}>
                <RadioGroup
                  name="erisaHasPlan"
                  label="Is your company's plan subject to ERISA?"
                  options={[
                    { label: "Yes", value: "YES" },
                    { label: "No", value: "NO" },
                  ]}
                  disabled={formik.isSubmitting}
                  touched={formik.touched.erisaHasPlan || !!prefillErrors.erisaHasPlan}
                  error={formik.errors.erisaHasPlan || prefillErrors.erisaHasPlan}
                  direction="vertical"
                  value={formik.values.erisaHasPlan}
                  onChange={formik.handleChange}
                />

                {formik.values.erisaHasPlan === "NO" && (
                  <StackY dist={16}>
                    <RadioGroup
                      name="erisaPlanType"
                      label="Is your company's plan a Church plan, Government plan or some other type of plan?"
                      options={[
                        { label: "Church plan", value: "CHURCH_PLAN" },
                        { label: "Government plan", value: "GOVERNMENT_PLAN" },
                        { label: "Other", value: "OTHER" },
                      ]}
                      disabled={formik.isSubmitting}
                      touched={formik.touched.erisaPlanType || !!prefillErrors.erisaPlanType}
                      error={formik.errors.erisaPlanType || prefillErrors.erisaPlanType}
                      direction="vertical"
                      value={formik.values.erisaPlanType}
                      onChange={formik.handleChange}
                    />

                    <Row>
                      <Col span={12}>
                        <FormInput
                          name="erisaPlanTypeOther"
                          maxLength={191}
                          label="Other"
                          disabled={formik.isSubmitting || formik.values.erisaPlanType !== "OTHER"}
                          touched={
                            formik.touched.erisaPlanTypeOther || !!prefillErrors.erisaPlanTypeOther
                          }
                          error={
                            formik.errors.erisaPlanTypeOther || prefillErrors.erisaPlanTypeOther
                          }
                          value={formik.values.erisaPlanTypeOther}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    </Row>
                  </StackY>
                )}

                {formik.values.erisaHasPlan === "YES" && (
                  <>
                    <RadioGroup
                      name="erisaPrefersPlanDetails"
                      label="Would you like us to include your ERISA plan details in your certificate?"
                      options={[
                        { label: "Yes", value: "YES" },
                        { label: "No", value: "NO" },
                      ]}
                      disabled={formik.isSubmitting}
                      touched={
                        formik.touched.erisaPrefersPlanDetails ||
                        !!prefillErrors.erisaPrefersPlanDetails
                      }
                      error={
                        formik.errors.erisaPrefersPlanDetails ||
                        prefillErrors.erisaPrefersPlanDetails
                      }
                      direction="vertical"
                      value={formik.values.erisaPrefersPlanDetails}
                      onChange={formik.handleChange}
                    />

                    {formik.values.erisaPrefersPlanDetails === "YES" && (
                      <>
                        <div>
                          <p className="h5" style={{ marginBottom: 12 }}>
                            Great! We’ll just need a few more details.
                          </p>

                          <p>
                            This information may be found in your annual Form 5500 filing. If you
                            had coverage with a prior carrier, you may also be able to find
                            information in the contract.
                          </p>
                        </div>

                        <section data-id="erisaPlanAdmin">
                          <Body2 as="p">
                            Should the policyholder be listed as the plan admin for ERISA purposes?{" "}
                            <br />
                            <Eyebrow>
                              This is the company or individual responsible for managing your plans.
                            </Eyebrow>
                          </Body2>

                          <RadioGroup
                            name="erisaAdministerOwnPlan"
                            label=""
                            options={[
                              {
                                label: "Yes, list the policyholder.",
                                value: "YES",
                              },
                              {
                                label:
                                  "No, I want to list someone else as plan administrator for ERISA purposes.",
                                value: "NO",
                              },
                            ]}
                            disabled={formik.isSubmitting}
                            touched={
                              formik.touched.erisaAdministerOwnPlan ||
                              !!prefillErrors.erisaAdministerOwnPlan
                            }
                            error={
                              formik.errors.erisaAdministerOwnPlan ||
                              prefillErrors.erisaAdministerOwnPlan
                            }
                            direction="vertical"
                            value={formik.values.erisaAdministerOwnPlan}
                            onChange={formik.handleChange}
                          />

                          {formik.values.erisaAdministerOwnPlan === "NO" && (
                            <StackY dist={16} wrap={false}>
                              <Row gutter={32}>
                                <Col span={24}>
                                  <FormInput
                                    name="erisaPlanAdminName"
                                    maxLength={191}
                                    label="Administrator name (company or individual)"
                                    aria-label="Administrator name (company or individual)"
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.erisaPlanAdminName ||
                                      !!prefillErrors.erisaPlanAdminName
                                    }
                                    error={
                                      formik.errors.erisaPlanAdminName ||
                                      prefillErrors.erisaPlanAdminName
                                    }
                                    value={formik.values.erisaPlanAdminName}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                              </Row>

                              <Row gutter={16}>
                                <Col span={24}>
                                  <FormInput
                                    name="erisaStreetAddress"
                                    maxLength={191}
                                    label="Street address"
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.erisaStreetAddress ||
                                      !!prefillErrors.erisaStreetAddress
                                    }
                                    error={
                                      formik.errors.erisaStreetAddress ||
                                      prefillErrors.erisaStreetAddress
                                    }
                                    value={formik.values.erisaStreetAddress}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                              </Row>

                              <Row gutter={16}>
                                <Col span={10}>
                                  <FormInput
                                    name="erisaCity"
                                    maxLength={191}
                                    label="City"
                                    disabled={formik.isSubmitting}
                                    touched={formik.touched.erisaCity || !!prefillErrors.erisaCity}
                                    error={formik.errors.erisaCity || prefillErrors.erisaCity}
                                    value={formik.values.erisaCity}
                                    onChange={formik.handleChange}
                                  />
                                </Col>

                                <Col span={8}>
                                  <SlobSelect
                                    name="erisaState"
                                    placeholder="State"
                                    options={LocationStateCodes.map((l) => ({
                                      label: l,
                                      value: l,
                                    }))}
                                    value={formik.values.erisaState}
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.erisaState || !!prefillErrors.erisaState
                                    }
                                    error={formik.errors.erisaState || prefillErrors.erisaState}
                                    onChange={({ value }) =>
                                      formik.setFieldValue("erisaState", value)
                                    }
                                  />
                                </Col>

                                <Col span={6}>
                                  <FormInput
                                    name="erisaZipCode"
                                    maxLength={20}
                                    label="Zip code"
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.erisaZipCode || !!prefillErrors.erisaZipCode
                                    }
                                    error={formik.errors.erisaZipCode || prefillErrors.erisaZipCode}
                                    value={formik.values.erisaZipCode}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                              </Row>
                            </StackY>
                          )}
                        </section>

                        <section data-id="erisaAgent">
                          <Body2 as="p">
                            Should the policyholder be listed as the ERISA agent for legal process?{" "}
                            <br />
                            <Eyebrow>
                              This is not the company who receives commission, but the company who
                              is designated by the plan administrator to accept legal notices.
                            </Eyebrow>
                          </Body2>

                          <RadioGroup
                            name="erisaHasAgentForLegalProcess"
                            label=""
                            options={[
                              { label: "Yes, list the policyholder.", value: "YES" },
                              {
                                label:
                                  "No, I want to list someone else as the ERISA agent for legal process.",
                                value: "NO",
                              },
                            ]}
                            disabled={formik.isSubmitting}
                            touched={
                              formik.touched.erisaHasAgentForLegalProcess ||
                              !!prefillErrors.erisaHasAgentForLegalProcess
                            }
                            error={
                              formik.errors.erisaHasAgentForLegalProcess ||
                              prefillErrors.erisaHasAgentForLegalProcess
                            }
                            direction="vertical"
                            value={formik.values.erisaHasAgentForLegalProcess}
                            onChange={formik.handleChange}
                          />

                          {formik.values.erisaHasAgentForLegalProcess === "NO" && (
                            <StackY dist={16} wrap={false}>
                              {formik.values.erisaAdministerOwnPlan === "NO" && (
                                <div className="ml-32">
                                  <Checkbox
                                    label="The ERISA agent is same as ERISA plan administrator"
                                    name="erisaIsLegalAgentSameAsAdmin"
                                    checked={Boolean(formik.values.erisaIsLegalAgentSameAsAdmin)}
                                    onChange={(e) =>
                                      formik.setFieldValue(e.target.name || "", e.target.checked)
                                    }
                                  />
                                </div>
                              )}

                              {(formik.values.erisaAdministerOwnPlan === "YES" ||
                                !formik.values.erisaIsLegalAgentSameAsAdmin) && (
                                <>
                                  <Row gutter={32}>
                                    <Col span={24}>
                                      <FormInput
                                        name="erisaLegalAgentName"
                                        maxLength={191}
                                        label="Agent name (company or individual)"
                                        aria-label="Agent name (company or individual)"
                                        disabled={formik.isSubmitting}
                                        touched={
                                          formik.touched.erisaLegalAgentName ||
                                          !!prefillErrors.erisaLegalAgentName
                                        }
                                        error={
                                          formik.errors.erisaLegalAgentName ||
                                          prefillErrors.erisaLegalAgentName
                                        }
                                        value={formik.values.erisaLegalAgentName}
                                        onChange={formik.handleChange}
                                      />
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={24}>
                                      <FormInput
                                        name="erisaLegalAgentStreetAddress"
                                        maxLength={191}
                                        label="Street address"
                                        disabled={formik.isSubmitting}
                                        touched={
                                          formik.touched.erisaLegalAgentStreetAddress ||
                                          !!prefillErrors.erisaLegalAgentStreetAddress
                                        }
                                        error={
                                          formik.errors.erisaLegalAgentStreetAddress ||
                                          prefillErrors.erisaLegalAgentStreetAddress
                                        }
                                        value={formik.values.erisaLegalAgentStreetAddress}
                                        onChange={formik.handleChange}
                                      />
                                    </Col>
                                  </Row>

                                  <Row gutter={16}>
                                    <Col span={10}>
                                      <FormInput
                                        name="erisaLegalAgentCity"
                                        maxLength={191}
                                        label="City"
                                        disabled={formik.isSubmitting}
                                        touched={
                                          formik.touched.erisaLegalAgentCity ||
                                          !!prefillErrors.erisaLegalAgentCity
                                        }
                                        error={
                                          formik.errors.erisaLegalAgentCity ||
                                          prefillErrors.erisaLegalAgentCity
                                        }
                                        value={formik.values.erisaLegalAgentCity}
                                        onChange={formik.handleChange}
                                      />
                                    </Col>

                                    <Col span={8}>
                                      <SlobSelect
                                        name="erisaLegalAgentState"
                                        placeholder="State"
                                        aria-label="Legal Agent State"
                                        options={LocationStateCodes.map((l) => ({
                                          label: l,
                                          value: l,
                                        }))}
                                        value={formik.values.erisaLegalAgentState}
                                        disabled={formik.isSubmitting}
                                        touched={
                                          formik.touched.erisaLegalAgentState ||
                                          !!prefillErrors.erisaLegalAgentState
                                        }
                                        error={
                                          formik.errors.erisaLegalAgentState ||
                                          prefillErrors.erisaLegalAgentState
                                        }
                                        onChange={({ value }) =>
                                          formik.setFieldValue("erisaLegalAgentState", value)
                                        }
                                      />
                                    </Col>

                                    <Col span={6}>
                                      <FormInput
                                        name="erisaLegalAgentZipCode"
                                        maxLength={10}
                                        label="Zip code"
                                        disabled={formik.isSubmitting}
                                        touched={
                                          formik.touched.erisaLegalAgentZipCode ||
                                          !!prefillErrors.erisaLegalAgentZipCode
                                        }
                                        error={
                                          formik.errors.erisaLegalAgentZipCode ||
                                          prefillErrors.erisaLegalAgentZipCode
                                        }
                                        value={formik.values.erisaLegalAgentZipCode}
                                        onChange={formik.handleChange}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </StackY>
                          )}
                        </section>

                        <section data-id="tax-id">
                          <Body2 as="p">
                            What is your company tax ID number? <br />
                            <Eyebrow>
                              This is sometimes known as an Employer Identification Number or EIN.
                            </Eyebrow>
                          </Body2>

                          <Row gutter={16}>
                            <Col span={12}>
                              <FormInput
                                name="taxId"
                                maxLength={10}
                                label="Tax ID"
                                disabled={formik.isSubmitting}
                                touched={formik.touched.taxId || !!prefillErrors.taxId}
                                error={formik.errors.taxId || prefillErrors.taxId}
                                value={formik.values.taxId}
                                onChange={handleTaxIdChange((maskedTaskId) =>
                                  formik.setFieldValue("taxId", maskedTaskId),
                                )}
                              />
                            </Col>
                          </Row>
                        </section>

                        <section data-id="erisa-plan-number">
                          <RadioGroup<ERISAPlanNumber>
                            name="erisaPlanNumber"
                            label={
                              <>
                                <Body2 as="p">
                                  What is your ERISA plan number? <br />
                                  <Eyebrow>
                                    This is PN501 unless another number is assigned by you or the
                                    plan administrator.
                                  </Eyebrow>
                                </Body2>
                              </>
                            }
                            options={[
                              { label: "PN501", value: "PN501" },
                              { label: "PN502", value: "PN502" },
                              {
                                label: "Other",
                                value: "OTHER",
                                content: formik.values.erisaPlanNumber === "OTHER" && (
                                  <div className="ml-32">
                                    <FormInput
                                      name="erisaPlanNumberOther"
                                      label="Plan Number"
                                      maxLength={5}
                                      touched={
                                        formik.touched.erisaPlanNumberOther ||
                                        !!prefillErrors.erisaPlanNumberOther
                                      }
                                      value={formik.values.erisaPlanNumberOther}
                                      error={
                                        formik.errors.erisaPlanNumberOther ||
                                        prefillErrors.erisaPlanNumberOther
                                      }
                                      disabled={formik.isSubmitting}
                                      onChange={formik.handleChange}
                                    />
                                  </div>
                                ),
                              },
                            ]}
                            disabled={formik.isSubmitting}
                            touched={
                              formik.touched.erisaPlanNumber || !!prefillErrors.erisaPlanNumber
                            }
                            error={formik.errors.erisaPlanNumber || prefillErrors.erisaPlanNumber}
                            direction="vertical"
                            value={formik.values.erisaPlanNumber}
                            onChange={formik.handleChange}
                          />
                        </section>

                        <section data-id="plan-year-end-date">
                          <Body2 as="p">
                            What is your plan year end date? <br />
                            <Eyebrow>
                              This is typically the last day of the
                              {client.policyAnniversaryMonth && policyYear
                                ? ` month before the effective date. Your effective date is ${capitalize(
                                    client.policyAnniversaryMonth,
                                  )} 1, ${policyYear}.`
                                : " fiscal year."}
                            </Eyebrow>
                          </Body2>

                          <Row gutter={16}>
                            <Col span={12}>
                              <FormInput
                                name="erisaPlanYearEndDateMMDD"
                                maxLength={5}
                                label="Month Day MM/DD"
                                aria-label="Plan year end date"
                                disabled={formik.isSubmitting}
                                touched={
                                  formik.touched.erisaPlanYearEndDateMMDD ||
                                  !!prefillErrors.erisaPlanYearEndDateMMDD
                                }
                                error={
                                  formik.errors.erisaPlanYearEndDateMMDD ||
                                  prefillErrors.erisaPlanYearEndDateMMDD
                                }
                                value={formik.values.erisaPlanYearEndDateMMDD}
                                onChange={handleMaskedMonthDayChange((maskedValue) =>
                                  formik.setFieldValue("erisaPlanYearEndDateMMDD", maskedValue),
                                )}
                              />
                            </Col>
                          </Row>
                        </section>
                      </>
                    )}
                  </>
                )}

                <BulbMessage>
                  <Body3>
                    If you had coverage with a prior carrier, you may be able to find this
                    information in the contract.
                  </Body3>
                </BulbMessage>
              </StackY>

              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.Client.erisaHasPlan,
                  changeSnapshot.Client.erisaPlanType,
                  changeSnapshot.Client.erisaPlanTypeOther,
                  changeSnapshot.Client.erisaPrefersPlanDetails,
                  changeSnapshot.Client.erisaPlanAdminName,
                  changeSnapshot.Client.erisaStreetAddress,
                  changeSnapshot.Client.erisaCity,
                  changeSnapshot.Client.erisaState,
                  changeSnapshot.Client.erisaZipCode,
                  changeSnapshot.Client.erisaPlanNumber,
                  changeSnapshot.Client.erisaPlanNumberOther,
                  changeSnapshot.Client.erisaPlanYearEndDate_deprecated,
                  changeSnapshot.Client.erisaPlanYearEndDateMMDD,
                  changeSnapshot.Client.erisaAdministerOwnPlan,
                  changeSnapshot.Client.erisaHasAgentForLegalProcess,
                  changeSnapshot.Client.erisaIsLegalAgentSameAsAdmin,
                  changeSnapshot.Client.erisaLegalAgentName,
                  changeSnapshot.Client.erisaLegalAgentStreetAddress,
                  changeSnapshot.Client.erisaLegalAgentCity,
                  changeSnapshot.Client.erisaLegalAgentState,
                  changeSnapshot.Client.erisaLegalAgentZipCode,
                  ...(formik.values.erisaPrefersPlanDetails === "YES"
                    ? [changeSnapshot.Client.taxId]
                    : []),
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={
                  getHasPendingEdit({ field: "erisaHasPlan", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanType", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanTypeOther", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPrefersPlanDetails", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanAdminName", client, formik }) ||
                  getHasPendingEdit({ field: "erisaStreetAddress", client, formik }) ||
                  getHasPendingEdit({ field: "erisaCity", client, formik }) ||
                  getHasPendingEdit({ field: "erisaState", client, formik }) ||
                  getHasPendingEdit({ field: "erisaZipCode", client, formik }) ||
                  getHasPendingEdit({ field: "erisaAdministerOwnPlan", client, formik }) ||
                  getHasPendingEdit({ field: "erisaHasAgentForLegalProcess", client, formik }) ||
                  getHasPendingEdit({ field: "erisaIsLegalAgentSameAsAdmin", client, formik }) ||
                  getHasPendingEdit({ field: "erisaLegalAgentName", client, formik }) ||
                  getHasPendingEdit({ field: "erisaLegalAgentStreetAddress", client, formik }) ||
                  getHasPendingEdit({ field: "erisaLegalAgentCity", client, formik }) ||
                  getHasPendingEdit({ field: "erisaLegalAgentState", client, formik }) ||
                  getHasPendingEdit({ field: "erisaLegalAgentZipCode", client, formik }) ||
                  getHasPendingEdit({ field: "taxId", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanNumber", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanNumberOther", client, formik }) ||
                  getHasPendingEdit({ field: "erisaPlanYearEndDate", client, formik })
                }
              />
            </StackY>
          </HubCard>

          <EIFBottomNavButtons
            previousLink={previousSubStepLink}
            previousButtonDisabled={formik.isSubmitting}
            nextButtonDisabled={formik.isSubmitting}
          />

          <AutoSaveOnNavigation formik={formik} optimistic />
        </StackY>
      </form>

      <h2 className="mt-32">What plans aren't subject to ERISA?</h2>

      <p>
        Welfare or pension plans covering church employees or government employees, cash sickness,
        or Workmen’s Compensation plans are not subject to ERISA.
      </p>
    </>
  );
}
