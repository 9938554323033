import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

import { InternalControl } from "../../components/InternalControl/InternalControl";
import { DocumentUpload } from "../../domain/Document/DocumentUpload/DocumentUpload";
import { WebLinksUpload } from "../../domain/WebLink/WebLinksUpload";
import { useToggler } from "../../hooks/useToggler";

import type { ClientId } from "../../../../shared/types/Client";

type Props = {
  clientId: ClientId;
  track: (buttonLabel: string) => void;
  extraContent?: React.ReactNode;
};

export function InternalControlUploadFilesAndLinks({ clientId, track, extraContent }: Props) {
  const [docUploadVisible, toggleDocUploadVisible] = useToggler();
  const [webLinksVisible, toggleWebLinksVisible] = useToggler();

  const uploadFiles = useCallback(() => {
    track("Upload files");
    toggleDocUploadVisible();
  }, [toggleDocUploadVisible, track]);

  const addWebLinks = useCallback(() => {
    track("Add Web Links");
    toggleWebLinksVisible();
  }, [toggleWebLinksVisible, track]);

  return (
    <>
      <InternalControl
        bodyText={"Internal User:"}
        buttonIcon={<FontAwesomeIcon icon={faFileUpload} />}
        buttonLabel="Upload files"
        onClick={uploadFiles}
        secondaryButtonIcon={<FontAwesomeIcon icon={faFileUpload} />}
        secondaryButtonLabel="Add Web links"
        secondaryOnClick={addWebLinks}
        extraContent={extraContent}
      />

      <DocumentUpload
        visible={docUploadVisible}
        closeModal={toggleDocUploadVisible}
        clientId={clientId}
        category={null}
        track={track}
      />

      <WebLinksUpload
        visible={webLinksVisible}
        handleClose={toggleWebLinksVisible}
        clientId={clientId}
        category={null}
        track={track}
      />
    </>
  );
}
