import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";

import { getIsTodayAfterNextBusinessDayOf, getNextBusinessDay } from "shared/utils/date";
import { formatDateTimeLocal } from "shared/utils/format";
import { getMostRecentUploadDate } from "shared/utils/getCanSeeBenefitSummaries";
import { Button } from "../../components/Button/Button";
import { InternalControl } from "../../components/InternalControl/InternalControl";
import { StackY } from "../../components/Spacing/Spacing";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { DocumentUpload } from "../../domain/Document/DocumentUpload/DocumentUpload";
import { WebLinksUpload } from "../../domain/WebLink/WebLinksUpload";
import { useToggler } from "../../hooks/useToggler";

import type { UpdateClientFunc } from "client/src/hooks/client";
import type { ClientId } from "shared/types/Client";
import type { Document, DocumentCategory } from "shared/types/Document";

type Props = {
  clientId: ClientId;
  documents: Document[] | undefined;
  selectedResourcesType?: DocumentCategory[];
  track: (buttonLabel: string) => void;
  updateClient: UpdateClientFunc;
  isUpdatingClient: boolean;
  alwaysShowBenefitSummaries: boolean;
};

export function InternalControlForResourcesPage({
  clientId,
  documents,
  selectedResourcesType,
  track,
  updateClient,
  isUpdatingClient,
  alwaysShowBenefitSummaries,
}: Props) {
  const [docUploadVisible, toggleDocUploadVisible] = useToggler();
  const [webLinksVisible, toggleWebLinksVisible] = useToggler();
  const [showInternalEditPopover, toggleShowInternalEditPopover] = useToggler();

  const uploadFiles = useCallback(() => {
    track("Upload files");
    toggleShowInternalEditPopover();
    toggleDocUploadVisible();
  }, [toggleShowInternalEditPopover, toggleDocUploadVisible, track]);

  const addWebLinks = useCallback(() => {
    track("Add Web Links");
    toggleShowInternalEditPopover();
    toggleWebLinksVisible();
  }, [toggleShowInternalEditPopover, toggleWebLinksVisible, track]);

  const benefitSummaries = documents?.filter((d) => d.category === "benefit-summaries") ?? [];
  const latestCreatedAt = getMostRecentUploadDate(benefitSummaries);
  const unsuppressionDateTime = latestCreatedAt ? getNextBusinessDay(latestCreatedAt) : null;
  const unsuppressionDateStr = unsuppressionDateTime
    ? formatDateTimeLocal(unsuppressionDateTime)
    : null;

  const suppressionTimerExpired = latestCreatedAt
    ? getIsTodayAfterNextBusinessDayOf(latestCreatedAt)
    : null;

  const suppressOrShowLabel =
    suppressionTimerExpired || suppressionTimerExpired == null
      ? undefined
      : alwaysShowBenefitSummaries
      ? "Suppress benefit summaries"
      : "Show benefit summaries";

  return (
    <>
      <InternalControl
        bodyText={
          unsuppressionDateTime && !suppressionTimerExpired && !alwaysShowBenefitSummaries
            ? `Internal User: Benefit Summaries are hidden until ${unsuppressionDateStr}`
            : `Internal User:`
        }
        buttonIcon={<FontAwesomeIcon icon={faFileUpload} />}
        buttonLabel="Upload"
        popoverTrigger="click"
        popoverVisible={showInternalEditPopover}
        onPopoverVisibleChange={toggleShowInternalEditPopover}
        onClick={() => track("Edit")}
        popoverContent={
          <StackY dist={8}>
            <div>
              <Button type="text" onClick={uploadFiles}>
                Upload files
              </Button>
            </div>
            <div>
              <Button type="text" onClick={addWebLinks}>
                Add Web Links
              </Button>
            </div>
          </StackY>
        }
        secondaryButtonLabel={suppressOrShowLabel}
        secondaryOnClick={async () => {
          const hideUpdating = slobMessage.loading("Updating...");
          try {
            if (suppressOrShowLabel) {
              track(suppressOrShowLabel);
            }
            await updateClient({
              params: { clientId },
              data: {
                alwaysShowBenefitSummaries: !alwaysShowBenefitSummaries,
              },
            });
            hideUpdating();
            void slobMessage.success("Updated");
          } catch (error) {
            hideUpdating();
            void slobMessage.error("An unexpected error happened");
          }
        }}
        secondaryDisabled={isUpdatingClient}
      />

      <DocumentUpload
        visible={docUploadVisible}
        closeModal={toggleDocUploadVisible}
        selectedResourcesType={selectedResourcesType}
        clientId={clientId}
        category={null}
        track={track}
      />

      <WebLinksUpload
        visible={webLinksVisible}
        handleClose={toggleWebLinksVisible}
        clientId={clientId}
        category={null}
        track={track}
      />
    </>
  );
}
