import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useTranslation } from "client/src/i18n";
import { Modal } from "../../../../components/Modal/Modal";
import { Body4 } from "../../../../components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "../../../../components/UnorderedList/UnorderedList";
import { getCSSVariableValue } from "../../../../utils/getCSSVariableValue";
import type { BenefitHighligherMapping } from "client/src/hooks/useBenefitHighlighters";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

type ExplorerHighlightersModalProps = {
  visible: boolean;
  highlightMappings: BenefitHighligherMapping[];
  onCancel: () => void;
  onHighlightClicked?: (benefitType: BenefitTypeBenEx) => void;
};

export const ExplorerHighlightersModal = ({
  visible,
  highlightMappings,
  onCancel,
  onHighlightClicked,
}: ExplorerHighlightersModalProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerHighlighters" });

  const highlighterList = (
    <UnorderedList>
      {highlightMappings.map((highlighter, index) => (
        <UnorderedListItem key={index}>
          {/* @todo: FP-3498: move to Link component with normal text margin */}
          <ButtonOld
            type="link-inline"
            size="small"
            onClick={() => onHighlightClicked?.(highlighter.benefitType)}
            style={{
              whiteSpace: "pre-line",
              verticalAlign: "text-top",
            }}
          >
            <Body4 as="div">
              <span
                style={{
                  display: "inline-block",
                  color: getCSSVariableValue("--color-tertiary-700"),
                  textAlign: "left",
                }}
              >
                {highlighter.highlightText}
              </span>
            </Body4>
          </ButtonOld>
        </UnorderedListItem>
      ))}
    </UnorderedList>
  );

  return (
    <Modal open={visible} footer={null} onCancel={() => onCancel()} title={t("modalTitle")}>
      {highlighterList}
    </Modal>
  );
};
