export const isNil = (val: unknown): val is null | undefined => val === undefined || val === null;

export const isEqual = (valueOne: unknown, valueTwo: unknown) => {
  return JSON.stringify(valueOne) === JSON.stringify(valueTwo);
};

export const areArraysEqual = (array1: unknown, array2: unknown) =>
  Array.isArray(array1) &&
  Array.isArray(array2) &&
  array1.length === array2.length &&
  JSON.stringify(array1.slice().sort()) === JSON.stringify(array2.slice().sort());

export const hasChanges = <T extends object>(
  specifiedFields: (keyof T)[],
  currentObject: T,
  comparisonObject: T,
) => specifiedFields.some((field) => currentObject[field] !== comparisonObject[field]);

export function getHasChangesByKeyList<T>(propList: (keyof T)[]) {
  function getAreEqual<T>(
    previousEntity: T,
    nextEntity: Partial<T> | undefined,
    propNames: (keyof T)[],
  ) {
    const areEqual = propNames.every(
      (propName) => nextEntity && previousEntity[propName] === nextEntity[propName],
    );
    return areEqual;
  }

  return function getHasChanges(previousEntity: T, nextEntity: Partial<T>) {
    const areEqual = getAreEqual<T>(previousEntity, nextEntity, propList);

    return { hasChanged: !areEqual, firstChange: false };
  };
}

export function assertIsDefined<T>(val: T, name: string): asserts val is NonNullable<T> {
  if (val == null) {
    throw new TypeError(`Expected '${name || "val"}' to be defined, but received ${String(val)}`);
  }
}

export function assertExistsInObject<T extends object, K extends keyof T>(
  obj: T,
  key: K,
): asserts obj is Exclude<T, K> & Required<Pick<T, K>> {
  if (!(key in obj)) {
    throw new TypeError(
      `Expected '${String(key)}' to be exist in the given object, but it does not.`,
    );
  }
}

type NonNullablePick<T, K extends keyof T> = {
  [P in K]: NonNullable<T[P]>;
};

export function assertIsDefinedInObject<T extends object, K extends keyof T>(
  obj: T,
  key: K,
): asserts obj is Exclude<T, K> & Required<NonNullablePick<T, K>> {
  assertExistsInObject(obj, key);
  assertIsDefined(obj[key], String(key));
}

export function includesCaseInsensitive(searchItem: string, list: string[]) {
  return list.findIndex((item) => item.toLocaleLowerCase() === searchItem.toLocaleLowerCase()) >= 0;
}

export function isPromise(val: unknown): val is Promise<unknown> {
  return Boolean(
    typeof val === "object" &&
      val &&
      "then" in val &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- this is needed
      typeof (val as Promise<unknown>)["then"] === "function",
  );
}

export const sanitizeValue = <T>(list: readonly T[] | T[], value: string | null): T | null => {
  value = String(value).toLowerCase();
  return list.find((el) => String(el).toLowerCase() === value) ?? null;
};

export const sanitizeArray = <T>(list: readonly T[] | T[], arr: unknown): T[] | null => {
  if (!Array.isArray(arr)) {
    return null;
  }
  const results: T[] = [];

  arr.forEach((value) => {
    const match = sanitizeValue(list, value);
    if (match != null) {
      results.push(match);
    }
  });
  return results;
};

/**
 * Ensure all items in an array are of the same type using a type guard function.
 * @param typeGuard The type guard function.
 * @param arr The array.
 * @returns The given array if all items passes the type guard function otherwise `null`.
 */
export const sanitizeTypedArray = <T>(
  typeGuard: (input: unknown) => input is T,
  arr: unknown,
): T[] | null => {
  if (!Array.isArray(arr)) {
    return null;
  }
  const sanitized = arr.filter(typeGuard);

  if (sanitized.length !== arr.length) {
    return null;
  }

  return sanitized;
};

export function removeDuplicates<T>(list: T[], key: keyof T) {
  const unique = [...new Map(list.map((item) => [item[key], item])).values()];
  return unique;
}

export function unique(array: null): typeof array;
export function unique(array: undefined): typeof array;
export function unique<T>(array: T[]): typeof array;
export function unique<T>(array: T[] | null): typeof array;
export function unique<T>(array: T[] | undefined): typeof array;
export function unique<T>(array: T[] | null | undefined): typeof array;
export function unique<T>(array: T[] | null | undefined) {
  if (array == null) return array;
  return Array.from(new Set(array));
}

export const rejectNullableValues = <T>(item: T): item is NonNullable<T> => item != null;

export function mapValues<TKey extends string, TValue, TResult>(
  obj: { [s in TKey]?: TValue },
  callback: (key: string, value: TValue) => TResult,
) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const result = Object.fromEntries(
    Object.entries(obj).map(([_key, _value]) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
      const key = _key as TKey;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
      const value = _value as TValue;
      const newValue = callback(key, value);
      return [key, newValue];
    }),
  ) as { [k in TKey]: TResult };

  return result;
}

export function getKeys<T extends object>(obj: T): Array<keyof T> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  return Object.keys(obj) as Array<keyof T>;
}

export function hasNonNullValues<T extends object>(obj: T) {
  return !Object.values(obj).every((val) => val === null);
}

export function hasALeastOneSetValue(obj: object): boolean {
  return Object.values(obj).some((value) => {
    // booleans can be false by default for some objects
    if (typeof value === "boolean") {
      return false;
    }

    // arrays can be empty by default for some objects
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    return value != null;
  });
}

type PropertyValue = string | number | boolean | Date | Array<unknown> | object | null | undefined;

export function getArePropertiesEmpty<T extends Record<string, PropertyValue>>(
  entity: T,
  keys: Array<keyof T> | ReadonlyArray<keyof T>,
) {
  const getIsEmptyValue = (value: PropertyValue) => {
    const isIt = Array.isArray(value) ? value.length === 0 : !value;
    return isIt;
  };

  const getAreAllKeysEmpty = (currentKeys: ReadonlyArray<keyof typeof entity>) => {
    const areThey = currentKeys
      .map((key) => getIsEmptyValue(entity[key]))
      .every((isEmptyValue) => isEmptyValue);
    return areThey;
  };

  const areAllKeysEmpty = getAreAllKeysEmpty(keys);
  return areAllKeysEmpty;
}

export function unslugify(slug: string): string {
  return slug
    .replace(/-/g, " ")
    .replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase());
}
