import {
  faEllipsisH,
  faExclamationTriangle,
  faCheckCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Tooltip } from "antd";
import { Button } from "client/src/components/Button/Button";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { ActionMenu } from "client/src/components/Menu/ActionMenu";
import { SlobTable } from "client/src/components/SlobTable/SlobTable";
import { TinyBadge } from "client/src/components/TinyBadge/TinyBadge";
import { Body3 } from "client/src/components/Typography/Typography";
import { useGetClientByID } from "client/src/hooks/client";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { formatShortDateTimeLocal, formatTimeLocal } from "shared/utils/format";
import { EmailErrorDrawer } from "../../domain/Client/ClientsList/ClientsTable/EmailError/EmailErrorDrawer";
import * as styles from "../../domain/Client/ClientsList/ClientsTable/Table.module.less";
import { useResendEmail } from "../../hooks/email";
import { useHubConfiguration } from "../../hooks/useConfig";
import { useCreateActivationLink, useUpdateEmailAndSendActivationEmail } from "../../hooks/user";
import { ConfirmDialogResendEmail } from "./ConfirmDialogResendEmail";
import type { InjectedEnv } from "../../../../shared/types/Env";
import type {
  CreateActivationLinkOutput,
  UpdateEmailAndActivateUserInput,
} from "../../../../shared/types/User";
import type {
  PathBodyToVariables,
  ResponseError,
  ResponseToMutationReturnType,
} from "../../hooks/query";
import type { UseMutateAsyncFunction } from "@tanstack/react-query";
import type { SlobColumnsType, SlobTableProps } from "client/src/components/SlobTable/SlobTable";
import type { Dispatch, SetStateAction } from "react";
import type { EmailType, EmailSimple } from "shared/types/Email";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const DataTestId = {
  FontAwesomeIconDanger: "font-awesome-icon-danger",
  FontAwesomeIconSuccess: "font-awesome-icon-success",
  FontAwesomeIconPending: "font-awesome-icon-pending",
};

type Props = Omit<SlobTableProps<EmailSimple>, "columns">;

const getIsRowInErrorState = (email: EmailSimple) => {
  return !!email.errorUsers.length;
};

const columns = (
  setEmailErrorForDrawer: Dispatch<SetStateAction<EmailSimple | undefined>>,
  setEmailIdToResend: Dispatch<SetStateAction<string | undefined>>,
): SlobColumnsType<EmailSimple> => [
  {
    width: 270,
    title: "Recipients",
    dataIndex: "to",
    key: "to",
    render: (recipients: string, email) => {
      const isInErrorState = getIsRowInErrorState(email);
      const erroredRecipients = email.errorUsers.map((errorUser) => errorUser.user.email);
      const emailPending = !email.statusHistory.includes("DELIVERED");

      const renderToRow = (recipient: string) => {
        if (erroredRecipients.includes(recipient)) {
          return (
            <>
              <FontAwesomeIcon
                data-testid={DataTestId.FontAwesomeIconDanger}
                icon={faExclamationTriangle}
                className={styles.dangerIcon}
              />
              <span className="sr-only">Email failed to sent to recipient</span>
            </>
          );
        } else if (emailPending) {
          return (
            <>
              <FontAwesomeIcon
                data-testid={DataTestId.FontAwesomeIconPending}
                icon={faQuestionCircle}
                className={styles.pendingIcon}
              />
              <span className="sr-only">Email delivery is pending</span>
            </>
          );
        } else {
          return (
            <>
              <FontAwesomeIcon
                data-testid={DataTestId.FontAwesomeIconSuccess}
                icon={faCheckCircle}
                className={styles.successIcon}
              />
              <span className="sr-only">Email successfully sent to recipient</span>
            </>
          );
        }
      };

      return (
        <div>
          {isInErrorState && (
            <div className="mb-4">
              <TinyBadge variant="red">
                {email.emailType === "ACCOUNT_ACTIVATION_REQUEST"
                  ? "Welcome Email Error"
                  : "Email Error"}
              </TinyBadge>
            </div>
          )}

          {recipients.split(/[,;\s]+/).map((r) => (
            <Body3 key={r} as="div">
              {renderToRow(r)} <span className={styles.wordBreak}>{r}</span>
            </Body3>
          ))}
        </div>
      );
    },
  },
  {
    title: "Subject",
    dataIndex: "subject",
    key: "subject",
    sorter: true,
    render: (subject: string) => {
      return <Body3>{subject}</Body3>;
    },
  },
  {
    width: 170,
    title: "Email Type",
    dataIndex: "emailType",
    key: "emailType",
    sorter: true,
    render: (emailType: EmailType) => {
      return (
        <Body3
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            width: "max-content",
            maxWidth: 330,
          }}
        >
          {emailType}
        </Body3>
      );
    },
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: true,
    render: (createdAt: Date) => {
      return (
        <>
          <Body3>{formatShortDateTimeLocal(createdAt)}</Body3> <br />
          <Body3>{formatTimeLocal(createdAt)}</Body3>
        </>
      );
    },
  },
  {
    width: "60px",
    title: "Action",
    key: "action",
    render: (_: unknown, email: EmailSimple) => {
      const isWelcomeEmail = email.emailType === "ACCOUNT_ACTIVATION_REQUEST";
      const hasErrors = email.errorUsers.length > 0;

      return (
        <Dropdown
          dropdownRender={() => (
            <ActionMenu
              data-testid="dropdown-action-menu"
              items={[
                ...(hasErrors
                  ? [
                      {
                        key: "email-error-help",
                        label: (
                          <ButtonOld
                            type="link-inline"
                            onClick={() => setEmailErrorForDrawer(email)}
                          >
                            Email Error Help
                          </ButtonOld>
                        ),
                      },
                    ]
                  : []),
                ...(!isWelcomeEmail
                  ? [
                      {
                        key: "resend-email",
                        label: (
                          <Tooltip
                            title={
                              hasErrors &&
                              `This user is having trouble receiving emails from us. Please use "Email error help" to troubleshoot.`
                            }
                            placement="bottom"
                          >
                            <ButtonOld
                              type="link-inline"
                              disabled={hasErrors}
                              onClick={() => setEmailIdToResend(email.id)}
                            >
                              Resend email
                            </ButtonOld>
                          </Tooltip>
                        ),
                      },
                    ]
                  : []),
                {
                  key: "view-email",
                  label: (
                    <Link
                      to={
                        email.clientId
                          ? RouteData.emailClient.getPath(email.clientId, email.id)
                          : RouteData.email.getPath(email.id)
                      }
                    >
                      View Email
                    </Link>
                  ),
                },
              ]}
            />
          )}
        >
          <Button type="tertiary" size="xtra-small" shrink aria-label="Display actions">
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </Dropdown>
      );
    },
  },
];

export function EmailTable(props: Props) {
  const [emailErrorForDrawer, setEmailErrorForDrawer] = useState<EmailSimple | undefined>(
    undefined,
  );

  const [emailIdToResend, setEmailIdToResend] = useState<string | undefined>(undefined);

  const config = useHubConfiguration();
  const { mutateAsync: createActivationLink, isPending: isLoadingCreateActivationLink } =
    useCreateActivationLink();
  const { mutateAsync: updateEmailAndSendActivationEmail } = useUpdateEmailAndSendActivationEmail();
  const {
    mutateAsync: resendEmail,
    isPending: isLoadingResendEmail,
    error: resendEmailError,
  } = useResendEmail();

  // Only fetch the client for the email error drawer if the parent component
  // did not set the client data as the initial data
  const { data: client, isFetching: isLoadingClient } = useGetClientByID(
    emailErrorForDrawer?.clientId,
    {
      initialData: props.client,
      enabled: !!emailErrorForDrawer?.clientId,
    },
  );

  return (
    <>
      <EmailTableUI
        config={config}
        createActivationLink={createActivationLink}
        updateEmailAndSendActivationEmail={updateEmailAndSendActivationEmail}
        isLoadingCreateActivationLink={isLoadingCreateActivationLink}
        emailErrorForDrawer={emailErrorForDrawer}
        setEmailErrorForDrawer={setEmailErrorForDrawer}
        emailIdToResend={emailIdToResend}
        setEmailIdToResend={setEmailIdToResend}
        {...props}
        isLoadingClient={isLoadingClient}
        client={client}
        featureToggles={props.featureToggles}
      />
      <ConfirmDialogResendEmail
        isVisible={emailIdToResend !== undefined}
        onCancel={() => {
          setEmailIdToResend(undefined);
        }}
        emailId={emailIdToResend ?? ""}
        resendEmail={resendEmail}
        isPendingResendEmail={isLoadingResendEmail}
        resendEmailError={resendEmailError}
      />
    </>
  );
}

interface UIProps extends Props {
  config: InjectedEnv;
  createActivationLink: UseMutateAsyncFunction<
    ResponseToMutationReturnType<CreateActivationLinkOutput>,
    ResponseError,
    PathBodyToVariables<"/api/users/:userId/activation-link", void, never>,
    unknown
  >;
  updateEmailAndSendActivationEmail: UseMutateAsyncFunction<
    ResponseToMutationReturnType<void>,
    ResponseError,
    PathBodyToVariables<
      "/api/users/update-email-and-send-activation-email",
      UpdateEmailAndActivateUserInput,
      never
    >,
    unknown
  >;
  isLoadingCreateActivationLink: boolean;
  setEmailErrorForDrawer: Dispatch<SetStateAction<EmailSimple | undefined>>;
  emailErrorForDrawer: EmailSimple | undefined;
  isLoadingClient: boolean;
  emailIdToResend: string | undefined;
  setEmailIdToResend: Dispatch<SetStateAction<string | undefined>>;
  featureToggles?: ClientFeatureToggles;
}
export const EmailTableUI = (props: UIProps) => {
  const {
    data,
    isLoading,
    currentPage,
    onChange,
    totalItems,
    pageSize,
    client,
    config,
    createActivationLink,
    updateEmailAndSendActivationEmail,
    isLoadingCreateActivationLink,
    setEmailErrorForDrawer,
    emailErrorForDrawer,
    setEmailIdToResend,
    featureToggles,
  } = props;

  return (
    <>
      <SlobTable
        columns={columns(setEmailErrorForDrawer, setEmailIdToResend)}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={data}
        getIsRowInErrorState={getIsRowInErrorState}
        featureToggles={featureToggles}
      />
      <EmailErrorDrawer
        createActivationLink={createActivationLink}
        updateEmailAndSendActivationEmail={updateEmailAndSendActivationEmail}
        isPendingCreateActivationLink={isLoadingCreateActivationLink}
        visible={emailErrorForDrawer !== undefined}
        clientId={emailErrorForDrawer?.clientId}
        ticketId={client?.ticketId}
        emailErrorUsers={emailErrorForDrawer?.errorUsers}
        onClose={() => setEmailErrorForDrawer(undefined)}
        jiraURL={config.REACT_APP_JIRA_URL}
        welcomeEmailError={emailErrorForDrawer?.emailType === "ACCOUNT_ACTIVATION_REQUEST"}
      />
    </>
  );
};
