import { BenefitsAdministrationDataFeedsPolicySummary } from "client/src/domain/EIF/CompanyDetails/BenefitsAdministrationDataFeedsPolicySummary";
import { EIFBenAdminAndDataFeed } from "client/src/domain/EIF/CompanyDetails/EIFBenAdminAndDataFeed";
import { WhatAreDataFeeds } from "client/src/domain/EIF/CompanyDetails/WhatAreDataFeeds";
import { EIFSubStepPolicySelection } from "client/src/domain/EIF/common/EIFSubStepPolicySelection";
import { getChangeDetailInfoListForSubStep } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getBenAdminAndDataFeedsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getEIFSubStepViewMode } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UpdatePolicyFunc } from "client/src/hooks/policy";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EIFCompanyDetailsSubStep, EIFStepId } from "shared/types/EIF";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const eifStepId: EIFStepId = "company-information";

const eifSubStepId = "benefits-administration-and-data-feed" satisfies EIFCompanyDetailsSubStep;

type Props = {
  client: Client;
  plans: Plan[];
  benAdminPlatforms: BenAdminPlatform[];
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  updateClient: UpdateClientFunc;
  updatePolicy: UpdatePolicyFunc;
};

export function EIFBenAdminAndDataFeedPolicySelection(props: Props) {
  const { plans } = props;
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    plans,
  });

  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  return (
    <EIFBenAdminAndDataFeedPolicySelectionPresentation
      {...props}
      previousSubStepLink={previousSubStepLink}
      nextSubStepLink={nextSubStepLink}
    />
  );
}

export function EIFBenAdminAndDataFeedPolicySelectionPresentation(
  props: Props & { previousSubStepLink: string | null; nextSubStepLink: string },
) {
  const { client, changeSnapshot, authUser, featureToggles, previousSubStepLink, nextSubStepLink } =
    props;

  const subStepName = getEIFSubStepMap({ eifSubStepId });

  if (client.policies.length === 1) {
    const [policy] = client.policies;
    assertIsDefined(policy, "policy");
    return <EIFBenAdminAndDataFeed {...props} policy={policy} />;
  }

  const viewMode = getEIFSubStepViewMode({ client });

  return (
    <EIFSubStepPolicySelection
      client={client}
      getChangeDetailInfoList={(policy) => {
        const changeDetailInfoList = getChangeDetailInfoListForSubStep({
          eifSubStepId,
          changeSnapshot,
          policies: [policy],
        });
        return changeDetailInfoList;
      }}
      authUser={authUser}
      viewMode={viewMode}
      eifStepId={eifStepId}
      eifSubStepId={eifSubStepId}
      previousSubStepLink={previousSubStepLink}
      nextSubStepLink={nextSubStepLink}
      getStatus={getBenAdminAndDataFeedsCompletionStatus}
      header={
        <>
          <h2 className="mb-8">{subStepName}</h2>

          <p>Tell us about how your data is currently stored.</p>
        </>
      }
      summary={(policy) => (
        <BenefitsAdministrationDataFeedsPolicySummary
          client={client}
          policy={policy}
          viewMode={viewMode}
          featureToggles={featureToggles}
          changeSnapshot={changeSnapshot}
          toggleableDetails={false}
        />
      )}
      footer={
        <>
          <hr />

          <WhatAreDataFeeds />
        </>
      }
    />
  );
}
