import { ValidationError } from "yup";
import type { AnyObject, ObjectSchema } from "yup";

export function getValidationErrors<Values>(
  schema: ObjectSchema<AnyObject>,
  values: Values,
  validationContext?: AnyObject,
): ValidationError[] {
  try {
    schema.validateSync(values, {
      abortEarly: false,
      context: validationContext,
    });
  } catch (errors) {
    if (errors instanceof ValidationError) {
      return errors.inner;
    } else {
      throw errors;
    }
  }

  return [];
}

export function extractPathAndMessage(errors: ValidationError[]) {
  return errors.map((e) => ({ path: e.path, message: e.message }));
}
