import { Dropdown, Modal, Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { ConfirmBenAdminToBrokerModal } from "client/src/domain/User/Form/ConfirmBenAdminToBrokerModal";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { BEN_ADMIN } from "shared/rbac/roles";
import { Button } from "../../components/Button/Button";
import { DropdownButton } from "../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { GoBackLink } from "../../components/Link/GoBackLink";
import { Loading } from "../../components/Loading/Loading";
import { ActionMenu } from "../../components/Menu/ActionMenu";
import { PageContent } from "../../components/PageContent/PageContent";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { BenAdminClientsList } from "../../domain/Client/ClientsList/BenAdminClientsList";
import { BenAdminUpdateForm } from "../../domain/User/Form/BenAdminUpdateForm";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import {
  useChangeBenAdminToBroker,
  useDeleteBenAdmin,
  useGetBenAdmin,
  useResetMFA,
  useResetPassword,
} from "../../hooks/user";
import type { MenuProps } from "antd";

import type { BenAdmin } from "shared/types/User";

const { Title } = Typography;

export const BenAdminDetailPage = () => {
  const [editState, setEditState] = useState<BenAdmin | null>(null);
  const { userId } = useClientHubParams(["userId"]);
  const searchParams = { userId };
  const navigate = useNavigate();

  const { data: benAdmin, isLoading: isLoadingBA, error } = useGetBenAdmin(userId);
  const { data: featureToggles, isLoading: isLoadingFT } = useGetFeatureToggles();

  const { mutateAsync: deleteBenAdmin } = useDeleteBenAdmin();
  const { mutateAsync: resetPassword } = useResetPassword();
  const { mutateAsync: resetMFA } = useResetMFA();
  const { mutateAsync: changeBenAdminToBroker } = useChangeBenAdminToBroker();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);
  const [isResetMFADialogVisible, setIsResetMFADialogVisible] = useState(false);
  const [isChangeToBrokerVisible, setIsChangeToBrokerVisible] = useState(false);

  if (error) {
    return triggerError(error);
  }

  if (isLoadingBA || isLoadingFT) {
    return <Loading />;
  }

  if (!userId || !benAdmin) {
    return triggerError("Benefits administrator not found");
  }

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (benAdmin: BenAdmin) => {
    setEditState(benAdmin);
  };

  const handleEdit = (benAdmin: BenAdmin) => {
    showModal(benAdmin);
  };

  const handleChangeToBroker = async () => {
    setIsChangeToBrokerVisible(false);
    const hide = slobMessage.loading("Changing to Broker", 0);
    try {
      await changeBenAdminToBroker({
        params: { userId: benAdmin.id },
      });
      void slobMessage.success("Changed to Broker");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleDelete = async () => {
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progress", 0);

    try {
      await deleteBenAdmin({
        params: { userId: benAdmin.id },
      });
      void slobMessage.success("Benefits Administrator deleted");
      navigate(RouteData.benAdmins.getPath());
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleReset = async () => {
    setIsResetDialogVisible(false);
    const hide = slobMessage.loading("Sending reset email", 0);
    try {
      await resetPassword({
        params: { email: benAdmin.email },
      });
      void slobMessage.success("Password email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleResetMFA = async () => {
    setIsResetMFADialogVisible(false);
    const hide = slobMessage.loading("Sending reset MFA", 0);
    try {
      await resetMFA({
        params: { email: benAdmin.email },
      });
      void slobMessage.success("MFA has been reset successfully");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const items: MenuProps["items"] = [
    { key: "edit-admin", label: "Edit Admin" },
    { key: "delete-admin", label: "Delete Admin" },
    { key: "reset-password", label: "Reset Password" },
    { key: "reset-mfa", label: "Reset MFA" },
    { key: "change-to-broker", label: "Change to Broker" },
  ];

  const menu = (
    <ActionMenu
      data-testid="dropdown-menu"
      items={items}
      onClick={({ key }) => {
        switch (key) {
          case "edit-admin":
            handleEdit(benAdmin);
            break;
          case "delete-admin":
            setIsDeleteDialogVisible(true);
            break;
          case "reset-password":
            setIsResetDialogVisible(true);
            break;
          case "reset-mfa":
            setIsResetMFADialogVisible(true);
            break;
          case "change-to-broker":
            setIsChangeToBrokerVisible(true);
            break;
        }
      }}
    />
  );

  return (
    <PageContent>
      <div className="mb-12">
        <GoBackLink to={RouteData.benAdmins.getPath()}>
          &lt; Back to Benefits Administrator List
        </GoBackLink>
      </div>
      <Row>
        <Col span={16}>
          <Title>{benAdmin.fullName}</Title>
        </Col>
        <Col flex="auto">
          <Row justify="end" gutter={8}>
            <Col>
              <Button onClick={() => setIsResetDialogVisible(true)} size="small">
                Reset Password
              </Button>
              <ConfirmDialog
                title="Reset password"
                isVisible={isResetDialogVisible}
                onCancel={() => setIsResetDialogVisible(false)}
                onConfirm={() => handleReset()}
                confirmActionText="Yes"
                cancelActionText="No"
              >
                Are you sure you want to reset password?
              </ConfirmDialog>
            </Col>
            <Col>
              <Dropdown dropdownRender={() => menu}>
                <DropdownButton />
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <BenAdminUpdateForm benAdmin={editState} handleClose={hideModal} />
        </Modal>
      )}
      <BenAdminClientsList
        extraSearchParams={searchParams}
        shouldRedirectToClient={false}
        featureToggles={featureToggles}
      />

      <ConfirmDialogDeleteUser
        role={BEN_ADMIN}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={benAdmin}
        handleDelete={handleDelete}
      />

      <ConfirmDialog
        title="Reset MFA"
        isVisible={isResetMFADialogVisible}
        onCancel={() => setIsResetMFADialogVisible(false)}
        onConfirm={() => handleResetMFA()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset MFA?
      </ConfirmDialog>

      <ConfirmBenAdminToBrokerModal
        clientName={""}
        isVisible={isChangeToBrokerVisible}
        onCancel={() => setIsChangeToBrokerVisible(false)}
        onConfirm={() => handleChangeToBroker()}
      />
    </PageContent>
  );
};
