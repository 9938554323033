import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import pluralize from "pluralize";
import { useState } from "react";
import { Link } from "react-router-dom";
import { documentCategoryToTask } from "shared/types/Document";

import { getTaskDetailRoute } from "../../../../shared/utils/client";
import { TaskCard } from "../Cards/TaskCard";
import { NewBadge } from "../NewBadge/NewBadge";
import { Eyebrow } from "../Typography/Typography";

import * as styles from "./hubSection.module.less";

import type { TaskDetail } from "../../hooks/task";
import type { Client, Policy } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

interface TaskDetailCardProps {
  homeLink: string;
  taskDetail: TaskDetail;
  newDocuments: number;
  client: Client;
  policy?: Policy;
  showPolicyInfo: boolean;
  isOnboardingComplete: boolean;
}

const TaskDetailCard = ({
  homeLink,
  taskDetail,
  newDocuments,
  client,
  policy,
  showPolicyInfo,
  isOnboardingComplete,
}: TaskDetailCardProps) => {
  const { title, description, customBannerMessage } = taskDetail;

  return (
    <Link to={homeLink}>
      <TaskCard
        headline={title}
        body={description}
        body2={
          newDocuments > 0 && (
            <div className="stack-x-8">
              <NewBadge />
              <Eyebrow>
                {newDocuments} {pluralize("document", newDocuments)} recently uploaded
              </Eyebrow>
            </div>
          )
        }
        task={taskDetail}
        client={client}
        policy={policy}
        showPolicyInfo={showPolicyInfo}
        isOnboardingComplete={isOnboardingComplete}
        bannerMessage={customBannerMessage}
      />
    </Link>
  );
};

export const HubSection = ({
  tasks,
  title,
  subTitle,
  client,
  isCollapsable = false,
  initialIsCollapsed = true,
  newDocumentsPerCategory,
  isOnboardingComplete,
}: {
  tasks: TaskDetail[];
  title: string;
  subTitle?: string;
  client: Client;
  isCollapsable?: boolean;
  initialIsCollapsed?: boolean;
  newDocumentsPerCategory: Partial<Record<DocumentCategory, number>>;
  isOnboardingComplete?: boolean;
}) => {
  const [isCollapsed, toggleCollapse] = useState(initialIsCollapsed);
  const count = tasks.length;

  const collapse = () => {
    toggleCollapse((isCollapsed) => !isCollapsed);
  };

  const shouldShowPolicyInfo = client.policies.length > 1;

  const shouldExpand = (isCollapsable && !isCollapsed) || !isCollapsable;

  return (
    <div>
      <button
        onClick={collapse}
        className={clsx(styles.header, isCollapsable ? styles.collapse : "")}
      >
        <h2 data-appcues-id="hub-section-title">
          {title} ({count})
        </h2>
        {isCollapsable && isCollapsed && (
          <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
        )}
        {isCollapsable && !isCollapsed && (
          <FontAwesomeIcon className={styles.icon} icon={faChevronUp} />
        )}
      </button>
      {subTitle}
      {shouldExpand && (
        <StackY dist={16} className="w-full mt-24">
          {tasks.map((task) => {
            const documentCategoriesForCurrentTask = Object.entries(documentCategoryToTask)
              .filter(([_documentCategory, taskId]) => {
                return taskId === task.id;
              })
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
              .map(([documentCategory]) => documentCategory as DocumentCategory);

            const newDocuments = documentCategoriesForCurrentTask.reduce((sum, category) => {
              return sum + (newDocumentsPerCategory[category] || 0);
            }, 0);

            const policy = task.policyId
              ? client.policies.find((p) => p.id === task.policyId)
              : undefined;

            return (
              <TaskDetailCard
                key={task.policyId ? `${task.id}-${task.policyId}` : task.id}
                homeLink={getTaskDetailRoute(client.id, task.id, policy?.id)}
                taskDetail={task}
                client={client}
                policy={policy}
                showPolicyInfo={Boolean(task.policyId && shouldShowPolicyInfo)}
                newDocuments={newDocuments}
                isOnboardingComplete={Boolean(isOnboardingComplete)}
              />
            );
          })}
        </StackY>
      )}
    </div>
  );
};
