import { Modal } from "antd";
import { useListParams } from "client/src/hooks/useListParams";
import { useState } from "react";

import { SlobList } from "../../components/SlobList/SlobList";
import { BenAdminUpdateForm } from "../../domain/User/Form/BenAdminUpdateForm";
import { BenAdminTable } from "../../domain/User/List/BenAdminTable";
import { useGetBenAdmins } from "../../hooks/user";

import type { Client } from "../../../../shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenAdmin } from "shared/types/User";

export const BENADMIN_LIST_PAGE_SIZE = 25;

export const BenAdminList = ({
  client,
  featureToggles,
  namespace,
}: {
  client: Client;
  featureToggles: ClientFeatureToggles | undefined;
  namespace?: string;
}) => {
  const [editState, setEditState] = useState<BenAdmin | null>(null);

  const hideModal = () => {
    setEditState(null);
  };

  const showModal = (benAdmin: BenAdmin) => {
    setEditState(benAdmin);
  };

  const listParams = useListParams({
    initialPageSize: BENADMIN_LIST_PAGE_SIZE,
    initialSort: {
      direction: "asc",
      sortBy: "lastName",
    },
    listNamespace: namespace,
  });

  const query = useGetBenAdmins({
    page: listParams.page,
    search: listParams.search ?? "",
    sort: listParams.sort,
    pageSize: listParams.pageSize,
    clientId: client.id,
  });

  return (
    <>
      <SlobList
        TableComponent={BenAdminTable}
        listParams={listParams}
        query={query}
        showModal={showModal}
        featureToggles={featureToggles}
        client={client}
        namespace={namespace}
      />
      {editState != null && (
        <Modal
          onCancel={hideModal}
          destroyOnClose={true}
          open={editState != null}
          footer={null}
          centered={true}
        >
          <BenAdminUpdateForm benAdmin={editState} handleClose={hideModal} />
        </Modal>
      )}
    </>
  );
};
