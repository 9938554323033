import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { RouteData } from "../../../../../shared/config/routeData";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import * as styles from "./censusContents.module.less";
import type { CensusStepId, Client } from "../../../../../shared/types/Client";
import type { ReactNode } from "react";

type CensusContentFooterProps = {
  client?: Client;
  policyId?: string;
  previous?: boolean;
  previousTrack?: (buttonLabel: string) => void;
  previousBackTo?: CensusStepId;
  previousDisabled?: boolean;
  error?: ReactNode;
  children: ReactNode;
};

export const CensusContentFooter = ({
  client,
  policyId,
  children,
  previousTrack,
  previousBackTo,
  previousDisabled,
  error,
}: CensusContentFooterProps) => {
  const canShowPrevious = client && policyId && previousBackTo;

  return (
    <div className={styles.footer}>
      {error && <div className={styles.error}>{error}</div>}
      {!canShowPrevious && (
        <div className={clsx(styles.footerContent, styles.singleItemFooter)}>{children}</div>
      )}

      {canShowPrevious && (
        <div className={clsx(styles.footerContent, styles.multiItemFooter)}>
          <InternalLinkButton
            type="link-inline-bold"
            size="middle"
            disabled={Boolean(previousDisabled)}
            to={`${RouteData.censusStepDetail.getPath(
              client?.id ?? "",
              policyId,
            )}/${previousBackTo}`}
            onClick={() => previousTrack?.("Previous")}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> Previous
          </InternalLinkButton>
          {children}
        </div>
      )}
    </div>
  );
};
