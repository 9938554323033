import { Body1, H3 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";

import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

type ExplorerBenefitHowMuchCoverageProps = {
  benefitType: BenefitTypeBenEx;
};

export const DataTestId = {
  HowMuchCoverage: "how-much-coverage",
};

export type ExplorerBenefitHowMuchCoverageCopyStructure = {
  header: string;
  description: string;
};

export const ExplorerBenefitHowMuchCoverage = (_props: ExplorerBenefitHowMuchCoverageProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitHowMuchCoverage" });
  return (
    <div data-testid={DataTestId.HowMuchCoverage}>
      <H3>
        <strong>{t("header")}</strong>
      </H3>
      <Trans t={t} i18nKey="description">
        <p>
          <Body1>first paragraph</Body1>
        </p>
        <p>
          <Body1>second paragraph</Body1>
        </p>
      </Trans>
    </div>
  );
};
