import { EIFPlanConfigAndEligibility } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFPlanConfigAndEligibility";
import { getIsAllowedToReviewEIF } from "client/src/domain/EIF/common/utils/getIsAllowedToReviewEIF";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import {
  isEIFPlanAdministratorsAndBillingSubStep,
  isEIFCompanyDetailsSubStep,
  isEIFOtherContractDetailsSubStep,
  isEIFPCESubStep,
} from "shared/types/EIF";
import { getEIFSubStepViewMode } from "shared/utils/client";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { EIFCompanyDetails } from "../../domain/EIF/CompanyDetails/EIFCompanyDetails";
import { EIFOtherContractDetails } from "../../domain/EIF/EIFOtherContractDetails";
import { EIFReviewAndSubmit } from "../../domain/EIF/EIFReviewAndSubmit";
import { EIFPlanAdministratorsAndBilling } from "../../domain/EIF/PlanAdministratorsAndBilling/EIFPlanAdministratorsAndBilling";
import { EIFPlaceholder } from "../../domain/EIF/common/EIFPlaceholder";
import type { CreateClassQuery, UpdateClassQuery } from "../../hooks/employeeClass";
import type {
  SetBenefitTypeAvailablePlansQuery,
  SetContributionQuery,
  SetWaitingPeriodsQuery,
} from "../../hooks/employeeClassPlan";
import type { UpdatePlansQuery } from "../../hooks/plans";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { AdditionalCompensation } from "@prisma/client";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { CreateContactQuery } from "client/src/hooks/contact";
import type { UpdatePolicyQuery } from "client/src/hooks/policy";
import type { CreateSubsidiaryQuery, UpdateSubsidiaryQuery } from "client/src/hooks/subsidiary";
import type { UserData } from "shared/rbac/rbac";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Location } from "shared/types/Location";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  classes: EmployeeClass[];
  employeeClassDocumentCount: number;
  locations: Location[];
  bills: Bill[];
  deletedBills: Bill[];
  contacts: Contact[];
  subsidiaries: Subsidiary[];
  eifStepId: EIFStepId;
  eifSubStepId?: EIFSubStepId;
  benAdminPlatforms: BenAdminPlatform[];
  documents: Document[];
  changeSnapshot: DEIFChangeSnapshot;
  billingSummaryStatementTemplates: Document[];
  updateClientQuery: UpdateClientQuery;
  updatePolicyQuery: UpdatePolicyQuery;
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  createContactQuery: CreateContactQuery;
  employeeClass: EmployeeClass | undefined;
  additionalCompensations: AdditionalCompensation[];
  setBenefitTypesAvailablePlansQuery: SetBenefitTypeAvailablePlansQuery;
  setWaitingPeriodsQuery: SetWaitingPeriodsQuery;
  setContributionsQuery: SetContributionQuery;
  updatePlansQuery: UpdatePlansQuery;
  createSubsidiaryQuery: CreateSubsidiaryQuery;
  updateSubsidiaryQuery: UpdateSubsidiaryQuery;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
};

export function EIFStepPageContents(props: Props) {
  const {
    client,
    clientPlans,
    documents,
    billingSummaryStatementTemplates,
    classes,
    employeeClassDocumentCount,
    locations,
    bills,
    deletedBills,
    contacts,
    subsidiaries,
    eifStepId,
    eifSubStepId,
    benAdminPlatforms,
    changeSnapshot,
    updateClientQuery,
    updatePolicyQuery,
    createClassQuery,
    updateClassQuery,
    createContactQuery,
    createSubsidiaryQuery,
    updateSubsidiaryQuery,
    employeeClass,
    additionalCompensations,
    setBenefitTypesAvailablePlansQuery,
    setWaitingPeriodsQuery,
    setContributionsQuery,
    updatePlansQuery,
    trackElementClicked,
    authUser,
    featureToggles,
  } = props;

  const { mutateAsync: updateClient } = updateClientQuery;
  const { mutateAsync: updatePolicy } = updatePolicyQuery;
  const { mutateAsync: createSubsidiary } = createSubsidiaryQuery;
  const { mutateAsync: updateSubsidiary } = updateSubsidiaryQuery;

  const isAllowedToReviewEIF = getIsAllowedToReviewEIF({
    client,
    authUser,
    changesSnapshot: changeSnapshot,
  });

  if (!isAllowedToReviewEIF && eifStepId !== "review-&-submit") {
    return (
      <Navigate
        to={RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information")}
        replace
      />
    );
  }

  const viewMode = getEIFSubStepViewMode({ client });

  switch (eifStepId) {
    case "company-information":
      if (!eifSubStepId || isEIFCompanyDetailsSubStep(eifSubStepId)) {
        return (
          <EIFCompanyDetails
            eifStepId={eifStepId}
            eifSubStepId={eifSubStepId}
            client={client}
            benAdminPlatforms={benAdminPlatforms}
            clientPlans={clientPlans}
            bills={bills}
            contacts={[]}
            billingSummaryStatementTemplates={[]}
            classes={classes}
            subsidiaries={subsidiaries}
            changeSnapshot={changeSnapshot}
            employeeClassDocumentCount={employeeClassDocumentCount}
            updateClient={updateClient}
            updatePolicy={updatePolicy}
            trackElementClicked={trackElementClicked}
            viewMode={viewMode}
            featureToggles={featureToggles}
            authUser={authUser}
            createSubsidiary={createSubsidiary}
            updateSubsidiary={updateSubsidiary}
          />
        );
      }
      break;
    case "plan-administrators-&-billing":
      if (!eifSubStepId || isEIFPlanAdministratorsAndBillingSubStep(eifSubStepId)) {
        return (
          <EIFPlanAdministratorsAndBilling
            eifStepId={eifStepId}
            eifSubStepId={eifSubStepId}
            client={client}
            clientPlans={clientPlans}
            locations={locations}
            bills={bills}
            deletedBills={deletedBills}
            classes={classes}
            employeeClassDocumentCount={employeeClassDocumentCount}
            contacts={contacts}
            subsidiaries={subsidiaries}
            billingSummaryStatementTemplates={billingSummaryStatementTemplates}
            updateClientQuery={updateClientQuery}
            updatePolicyQuery={updatePolicyQuery}
            updatePlansQuery={updatePlansQuery}
            createContactQuery={createContactQuery}
            trackElementClicked={trackElementClicked}
            featureToggles={featureToggles}
            changeSnapshot={changeSnapshot}
            viewMode={viewMode}
            authUser={authUser}
          />
        );
      }
      break;
    case "plan-configuration-&-eligibility": {
      if (!eifSubStepId || isEIFPCESubStep(eifSubStepId)) {
        return (
          <EIFPlanConfigAndEligibility
            client={client}
            clientPlans={clientPlans}
            updateClientQuery={updateClientQuery}
            documents={documents}
            createClassQuery={createClassQuery}
            updateClassQuery={updateClassQuery}
            classes={classes}
            employeeClass={employeeClass}
            additionalCompensations={additionalCompensations}
            setBenefitTypesAvailablePlansQuery={setBenefitTypesAvailablePlansQuery}
            setWaitingPeriodsQuery={setWaitingPeriodsQuery}
            setContributionsQuery={setContributionsQuery}
            trackElementClicked={trackElementClicked}
            changeSnapshot={changeSnapshot}
            featureToggles={featureToggles}
            authUser={authUser}
            eifSubStepId={eifSubStepId}
            employeeClassDocumentCount={employeeClassDocumentCount}
            updatePlansQuery={updatePlansQuery}
          />
        );
      }
      break;
    }
    case "other-contract-details":
      if (!eifSubStepId || isEIFOtherContractDetailsSubStep(eifSubStepId)) {
        return (
          <EIFOtherContractDetails
            eifSubStepId={eifSubStepId}
            client={client}
            clientPlans={clientPlans}
            bills={bills}
            contacts={[]}
            billingSummaryStatementTemplates={[]}
            classes={classes}
            subsidiaries={subsidiaries}
            changeSnapshot={changeSnapshot}
            employeeClassDocumentCount={employeeClassDocumentCount}
            updateClient={updateClient}
            updatePlansQuery={updatePlansQuery}
            trackElementClicked={trackElementClicked}
            viewMode={viewMode}
            featureToggles={featureToggles}
            authUser={authUser}
          />
        );
      }
      break;
    case "review-&-submit":
      return (
        <EIFReviewAndSubmit
          client={client}
          signEIF={updateClient}
          authUser={authUser}
          changesSnapshot={changeSnapshot}
          trackElementClicked={trackElementClicked}
          signerMode="inside"
        />
      );

    default:
      exhaustiveCheck(eifStepId);
      break;
  }

  if (eifSubStepId === "non-class-benefits-preferences") {
    return EIFPlaceholder({ eifStepId, eifSubStepId, clientPlans, featureToggles });
  }

  return EIFPlaceholder({ eifStepId, eifSubStepId });
}
