import { getIsBroker, getIsBenAdmin } from "shared/rbac/rbac";
import { getIsTodayAfterNextBusinessDayOf } from "shared/utils/date";
import type { UserData } from "shared/rbac/rbac";

export function getCanSeeBenefitSummaries(args: {
  authUser: Pick<UserData, "role"> | null;
  benefitSummaries: Array<{ createdAt: Date }> | undefined;
  alwaysShowBenefitSummaries: boolean;
}) {
  const { authUser, benefitSummaries, alwaysShowBenefitSummaries } = args;

  if (alwaysShowBenefitSummaries) {
    return true;
  }

  if (benefitSummaries == null || authUser == null) {
    return false;
  }

  const latestCreatedAt = getMostRecentUploadDate(benefitSummaries);
  if (!latestCreatedAt) {
    return true;
  }
  const canSeeBenefitSummaries = checkRolesAndExpirationDate(authUser, latestCreatedAt);

  return canSeeBenefitSummaries;
}

export function getMostRecentUploadDate(benefitSummaries: Array<{ createdAt: Date }>) {
  if (benefitSummaries.length === 0) {
    return null;
  }
  const benefitSummariesCreatedAts = benefitSummaries.map((d) => d.createdAt.getTime());
  const latestCreatedAtTimestamp = Math.max(...benefitSummariesCreatedAts);
  const latestCreatedAt = new Date(latestCreatedAtTimestamp);
  return latestCreatedAt;
}

function checkRolesAndExpirationDate(
  authUser: Pick<UserData, "role"> | null,
  expirationDate: Date,
) {
  const isBroker = getIsBroker(authUser);
  const isBA = getIsBenAdmin(authUser);

  if (isBroker || isBA) {
    const isTodayAfterNextBusinessDayOf = getIsTodayAfterNextBusinessDayOf(expirationDate);
    return isTodayAfterNextBusinessDayOf;
  }

  return true;
}
