import * as Sentry from "@sentry/react";
import { CensusSource } from "client/src/domain/Client/Census/CensusSource";
import { CensusUpload } from "client/src/domain/Client/Census/CensusUpload";
import { CensusValidateSubmit } from "client/src/domain/Client/Census/CensusValidateSubmit";
import { OutsideSignerPageSkeleton } from "client/src/domain/OutsideSigner/OutsideSignerPageSkeleton";
import { BenAdminDetailPage } from "client/src/pages/BenAdmins/BenAdminDetail";
import { BenAdminsPage } from "client/src/pages/BenAdmins/BenAdminsPage";
import { EIFPushToQPSPage } from "client/src/pages/EIFPushToQPSPage/EIFPushToQPSPage";
import { EIFStepPageLoading } from "client/src/pages/EIFStepPage/EIFStepPage";
import { EIFSubStepPolicyPage } from "client/src/pages/EIFStepPage/EIFSubStepPolicyPage";
import { OutsideSignerEIFSummaryPage } from "client/src/pages/OutsideSignerEIFSummaryPage/OutsideSignerEIFSummaryPage";
import { OutsideSignerPage } from "client/src/pages/OutsideSignerPage/OutsideSignerPage";

import { Suspense } from "react";
import { Navigate, createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import { RouteData } from "shared/config/routeData";
import {
  ExplorerPageConfigPage,
  PersonalBenefitsPlanPage,
  GiveFeedbackExternal,
  ExplorerPage,
} from "./BenEx.Bundle";
import {
  ClientsPage,
  BenAdminClientsPage,
  BrokerClientsPage,
  ClientDetailPage,
  SlfInternalPage,
  BrokersPage,
  BrokerDetailPage,
  AllEmailsPage,
  EmailPage,
} from "./Internal.Bundle";
import {
  WelcomePage,
  GiveFeedback,
  EnrollmentResourcesListingPage,
  TaskDetailPage,
  CensusDetailPage,
  SignedPage,
  FormPage,
  EIFStepPage,
  EIFSummaryPage,
  Hub,
} from "./Onboard.Bundle";
import { ChildPageSkeleton } from "./components/ChildPageLayout/ChildPageLayout";
import { BoundaryError } from "./components/Error/BoundaryError";
import { LegalFooter } from "./components/LegalFooter/LegalFooter";
import { Loading } from "./components/Loading/Loading";

import { SupportFooter } from "./components/SupportFooter/SupportFooter";

import { CensusValidationReportTab } from "./domain/Client/Census/CensusValidationReportTab";
import NotFoundPage from "./pages/404";
import { EIFSummarySkeleton } from "./pages/EIFSummaryPage/EIFSummaryWrapper";
import IndexPage from "./pages/IndexPage";
import ActivatePage from "./pages/Login/ActivatePage";
import ForgotPasswordPage from "./pages/Login/ForgotPasswordPage";
import LoginPage from "./pages/Login/LoginPage";
import ReActivatePage from "./pages/Login/ReActivatePage";
import ResetPage from "./pages/Login/ResetPage";
import MaintenancePage from "./pages/MaintenancePage";

import { AnalyticsPage } from "./pages/Navigation/AnalyticsPage";
import { NavBarPage } from "./pages/Navigation/NavBarPage";
import * as styles from "./pages/Navigation/navigation.module.less";
import { AuthRedirectHandler } from "./pages/Security/AuthRedirectHandler";
import TermsOfUse from "./pages/TermsOfUse";
import type { RouteObject } from "react-router-dom";

export const MainLayout = () => {
  return (
    <AuthRedirectHandler>
      <>
        <AnalyticsPage />
        <Outlet />
      </>
    </AuthRedirectHandler>
  );
};

type DefaultLayoutProps = {
  Page: React.ComponentType;
  Footer: React.ComponentType | null;
  fallback?: React.ReactNode;
  supportFooter?: boolean;
};

const DefaultLayout = ({
  Page,
  Footer,
  fallback = <Loading />,
  supportFooter = false,
}: DefaultLayoutProps) => {
  return (
    <>
      <NavBarPage />
      <Sentry.ErrorBoundary
        fallback={(props) => (
          <main>
            <BoundaryError {...props} />
          </main>
        )}
      >
        <main className={styles.content}>
          <Suspense fallback={fallback}>
            <Page />
          </Suspense>
        </main>
      </Sentry.ErrorBoundary>

      <footer>
        {supportFooter && <SupportFooter />}
        {Footer && <Footer />}
      </footer>
    </>
  );
};

export const appRoutes = (): RouteObject[] => [
  {
    path: RouteData.login.getPathTemplate(),
    element: <DefaultLayout Page={LoginPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeInternal.getPathTemplate(),
    element: <DefaultLayout Page={ClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.forgot.getPathTemplate(),
    element: <DefaultLayout Page={ForgotPasswordPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.activate.getPathTemplate(),
    element: <DefaultLayout Page={ActivatePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.reactivate.getPathTemplate(),
    element: <DefaultLayout Page={ReActivatePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.reset.getPathTemplate(),
    element: <DefaultLayout Page={ResetPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.termsOfUse.getPathTemplate(),
    element: <DefaultLayout Page={TermsOfUse} Footer={LegalFooter} />,
  },
  // #region Misc
  {
    path: RouteData.notFound.getPathTemplate(),
    element: <DefaultLayout Page={NotFoundPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.maintenance.getPathTemplate(),
    element: <DefaultLayout Page={MaintenancePage} Footer={LegalFooter} />,
  },
  // #endregion Misc

  // #region Internal Users
  {
    path: RouteData.homeInternal.getPathTemplate(),
    element: <DefaultLayout Page={ClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeBenAdmin.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeBroker.getPathTemplate(),
    element: <DefaultLayout Page={BrokerClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.clientDetail.getPathTemplate(),
    element: <DefaultLayout Page={ClientDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.usersInternal.getPathTemplate(),
    element: <DefaultLayout Page={SlfInternalPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.brokers.getPathTemplate(),
    element: <DefaultLayout Page={BrokersPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.brokerDetail.getPathTemplate(),
    element: <DefaultLayout Page={BrokerDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.allEmails.getPathTemplate(),
    element: <DefaultLayout Page={AllEmailsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.benAdmins.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.benAdminDetail.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.emails.getPathTemplate(),
    element: <DefaultLayout Page={AllEmailsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.email.getPathTemplate(),
    element: <DefaultLayout Page={EmailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.emailClient.getPathTemplate(),
    element: <DefaultLayout Page={EmailPage} Footer={LegalFooter} />,
  },
  // #endregion Internal Users

  // #region Onboard
  {
    path: RouteData.welcome.getPathTemplate(),
    element: <DefaultLayout Page={WelcomePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeHub.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <Hub />
            <GiveFeedback />
          </>
        )}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.resourcesListing.getPathTemplate(),
    element: <DefaultLayout Page={EnrollmentResourcesListingPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.clientTaskDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<ChildPageSkeleton />}
        Page={TaskDetailPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.policyTaskDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<ChildPageSkeleton />}
        Page={TaskDetailPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.censusStepDetail.getPathTemplate(),
    element: <DefaultLayout Page={CensusDetailPage} Footer={LegalFooter} />,
    children: [
      {
        id: "upload-files",
        path: "upload-files",
        element: <CensusUpload />,
      },
      {
        id: "indicate-source",
        path: "indicate-source",
        element: <CensusSource />,
      },
      {
        id: "validate-submit",
        path: "validate-submit",
        element: <CensusValidateSubmit />,
      },
    ],
  },
  {
    path: RouteData.censusValidateReportTab.getPathTemplate(),
    element: <DefaultLayout Page={CensusValidationReportTab} Footer={LegalFooter} />,
  },
  {
    path: RouteData.onboardingFormsSigned.getPathTemplate(),
    element: <DefaultLayout Page={SignedPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.onboardingFormsViewer.getPathTemplate(),
    element: <DefaultLayout Page={FormPage} Footer={null} />,
  },
  {
    path: RouteData.eifStepDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFStepPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifSubStepPolicyDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFSubStepPolicyPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifSubStepDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFStepPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifClassBuilderEdit.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFStepPageLoading />} Page={EIFStepPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifClassBuilderCreator.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFStepPageLoading />} Page={EIFStepPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifSummary.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFSummarySkeleton />} Page={EIFSummaryPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifPushToQPS.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFSummarySkeleton />}
        Page={EIFPushToQPSPage}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.outsideSigner.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<OutsideSignerPageSkeleton />}
        Page={OutsideSignerPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.outsideSignerEifSummary.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFSummarySkeleton />}
        Page={OutsideSignerEIFSummaryPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  // #endregion Onboard
  // #region Benefits Explorer
  {
    path: RouteData.editBenefitsExplorerSite.getPathTemplate(),
    element: <DefaultLayout Page={ExplorerPageConfigPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.renewBenefitsExplorerSite.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPageConfigPage isRenewal={true} />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.createSite.getPathTemplate(),
    element: <DefaultLayout Page={ExplorerPageConfigPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.personalBenefitsPlan.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <PersonalBenefitsPlanPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.benefitsExplorer.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  // #endregion Benefits Explorer

  { path: `${RouteData.login.getPathTemplate()}/callback` },
  { path: "*", element: <Navigate to={RouteData.notFound.getPathTemplate()} replace /> },
];

const getRouterRoutes = () => [
  {
    path: "/",
    element: MainLayout(),
    children: [
      { index: true, element: <IndexPage /> },

      ...appRoutes().map((onboardRoute) => ({
        id: onboardRoute.id,
        path: onboardRoute.path,
        element: onboardRoute.element,
        children: onboardRoute.children,
      })),
    ],
  },
];

export const AppRouter = () => {
  const router = createBrowserRouter(getRouterRoutes());
  return <RouterProvider router={router} />;
};
