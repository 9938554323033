/**
 * Represents the available values for orthodontic services.
 */
export enum OrthodonticServicesValue {
  None = "None",
  Children = "Children",
  Adults = "Adults",
  Both = "Both",
}

/**
 * Type guard for `OrthodonticServicesValue`.
 */
export function isOrthonoticServicesValue(value: string | null): value is OrthodonticServicesValue {
  return !!value && value in OrthodonticServicesValue;
}

/**
 * Represents a mapping of `OrthodonticServicesValue` to their corresponding labels.
 */
export const OrthodonticServicesLabel: Record<OrthodonticServicesValue, string> = {
  [OrthodonticServicesValue.None]: "No services offered",
  [OrthodonticServicesValue.Children]: "Children only",
  [OrthodonticServicesValue.Adults]: "Adults only",
  [OrthodonticServicesValue.Both]: "Both adults and children",
} as const;
