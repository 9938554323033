import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useBenefitHighlighters } from "client/src/hooks/useBenefitHighlighters";
import clsx from "clsx";
import { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Body4, H2 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { useTranslation } from "../../../i18n";
import { useExplorerTrackElementClicked } from "../../../utils/analytics";
import { ExplorerHighlightersModal } from "../Modals/ClientPage/ExplorerHighlightersModal";
import * as styles from "./clientPage.module.less";
import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

export const DataTestId = {
  HighlighersCard: "highlighers-card",
};

export type ExplorerHighlightersCopyStructure = {
  title: string;
  callToAction: string;
  modalTitle: string;
};

type ExplorerHighlightersProps = {
  explorerPage: PublicExplorerPage;
  onClick?: (benefitType: BenefitTypeBenEx) => void;
};

export const ExplorerHighlighters = ({ explorerPage, onClick }: ExplorerHighlightersProps) => {
  const [showHighlightersModal, setShowHighlightersModal] = useState(false);
  const isMobile = !useBreakpoint().md;
  const { t } = useTranslation({ keyPrefix: "ExplorerHighlighters" });

  const trackElementClicked = useExplorerTrackElementClicked(explorerPage);

  const validHighlighters = useBenefitHighlighters(explorerPage?.benefitTypeMetadata);

  if (!validHighlighters || validHighlighters.length === 0) return null;

  return (
    <>
      <div
        className={clsx(styles.highlights, isMobile && styles.mobile)}
        style={{ height: "100%" }}
        data-testid={DataTestId.HighlighersCard}
      >
        <H2>{t("title")}</H2>
        <UnorderedList>
          {validHighlighters.slice(0, 4).map((highlighter, index) => (
            <UnorderedListItem key={index}>
              <ButtonOld
                type="link-inline"
                size="small"
                onClick={() => {
                  onClick?.(highlighter.benefitType);
                  trackElementClicked({
                    module: "Benefit Highlight",
                    buttonLabel: highlighter.benefitType,
                  });
                }}
                style={{
                  whiteSpace: "pre-line",
                  verticalAlign: "text-top",
                }}
              >
                <Body4 as="div">
                  <span className={styles.highlighterText}>{highlighter.highlightText}</span>
                </Body4>
              </ButtonOld>
            </UnorderedListItem>
          ))}
        </UnorderedList>
        {validHighlighters.length > 4 && (
          <Button type="tertiary" onClick={() => setShowHighlightersModal(true)}>
            {t("callToAction")}
          </Button>
        )}
      </div>

      <ExplorerHighlightersModal
        visible={showHighlightersModal}
        highlightMappings={validHighlighters}
        onCancel={() => {
          setShowHighlightersModal(false);
        }}
        onHighlightClicked={(benefitType) => {
          setShowHighlightersModal(false);
          onClick?.(benefitType);
          trackElementClicked({
            module: "Benefit Highlight",
            buttonLabel: benefitType,
          });
        }}
      />
    </>
  );
};
