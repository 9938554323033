import clsx from "clsx";
import * as styles from "./TinyBadge.module.less";
import type { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  variant: "red" | "yellow" | "gray";
}>;

export function TinyBadge(props: Props) {
  const { children, variant } = props;
  return (
    <span
      className={clsx(
        styles.tinyBadge,
        variant === "red" && styles.red,
        variant === "yellow" && styles.yellow,
        variant === "gray" && styles.gray,
      )}
    >
      {children}
    </span>
  );
}
