import { Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";

import { Loading } from "client/src/components/Loading/Loading";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { PageContent } from "../../components/PageContent/PageContent";
import { BenAdminList } from "../../domain/BenAdmin/BenAdminList";

const { Title } = Typography;

export const BenAdminsPage = () => {
  const { data: featureToggles, isLoading } = useGetFeatureToggles();
  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContent>
      <Row>
        <Col span={15}>
          <Title>Benefits Administrators</Title>
        </Col>
      </Row>

      <BenAdminList featureToggles={featureToggles} />
    </PageContent>
  );
};
