import clsx from "clsx";

import { ProgressBar } from "../ProgressBar/ProgressBar";

import * as styles from "./progressOverlayContainer.module.less";

type ProgressBlurContainerProps = {
  id: string;
  showProgress: boolean;
  progressBarText: string;
  children?: React.ReactNode;
};

export const ProgressOverlayContainer: React.FC<ProgressBlurContainerProps> = ({
  id,
  showProgress,
  progressBarText,
  children,
}) => {
  return (
    <div className={styles.mainContainer}>
      {showProgress && (
        <div className={styles.progressBarContainer}>
          <ProgressBar id={id} bottomText={progressBarText} />
        </div>
      )}
      <div className={clsx([showProgress && styles.overlayContainer])}>{children}</div>
    </div>
  );
};
