import { allEIFSubStepKeys, type EIFStepId, type EIFSubStepId } from "shared/types/EIF";
import type { EIFSubStepsHaveChanges } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";

type EIFStepIdWithoutReview = Exclude<EIFStepId, "review-&-submit">;

export function getIsLastStepMap(
  eifStepHasChanges: Record<EIFStepIdWithoutReview, boolean>,
): Record<EIFStepIdWithoutReview, boolean> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- --
  const relevantArray = [
    "company-information",
    "plan-administrators-&-billing",
    "plan-configuration-&-eligibility",
    "other-contract-details",
  ] as EIFStepIdWithoutReview[];

  const result = __getIsLastEntryMap(relevantArray, eifStepHasChanges);
  return result;
}

export function getIsLastSubStepMap<T extends EIFSubStepId>(
  eifSubStepHasChanges: EIFSubStepsHaveChanges<T>,
) {
  const result = __getIsLastEntryMap(allEIFSubStepKeys, eifSubStepHasChanges);
  return result;
}

function __getIsLastEntryMap<T extends string>(
  relevantArray: T[],
  eifStepOrSubStepHasChanges: Record<T, boolean>,
) {
  const keys = relevantArray;

  let handled = false;
  const result = { ...eifStepOrSubStepHasChanges };
  for (const key of keys.slice().reverse()) {
    if (key in result) {
      const hasChanges = eifStepOrSubStepHasChanges[key];
      if (hasChanges && !handled) {
        handled = true;
        result[key] = true;
      } else {
        result[key] = false;
      }
    }
  }
  return result;
}
