import { faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { useClientUtils } from "client/src/domain/Client/useClientUtils";
import { useUpdateClient } from "client/src/hooks/client";
import { ResponseError } from "client/src/hooks/query";
import { useToggler } from "client/src/hooks/useToggler";
import { useTrackElementClicked } from "client/src/utils/analytics";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { useCallback } from "react";
import * as Yup from "yup";
import * as styles from "./clientDetail.module.less";
import type { LimitedClientEmailTypes } from "shared/data/Email";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const formKeyProps: (keyof Client)[] = ["limitedEmails"];

type Props = {
  client: Client;
  featureToggles: ClientFeatureToggles | undefined;
};

export const obligatoryBAEmails: Record<string, LimitedClientEmailTypes> = {
  Welcome: { emailType: "NEW_CLIENT" },
  "Activation Reminder": { emailType: "ACCOUNT_ACTIVATION_REMINDER" },
  "Activation Confirmation": { emailType: "ACCOUNT_ACTIVATION_CONFIRMATION" },
  "Password Reset": { emailType: "RESET_PASSWORD" },
  "Password Change": { emailType: "PASSWORD_CHANGE_CONFIRMATION" },
  "DocuSign Forms Are Ready To Sign": { emailType: "FORMS_ARE_READY_TO_SIGN" },
  "DocuSign Forms Need Initials": { emailType: "FORM_NEEDS_INITIALS" },
  "Review & Submit Company Information": { emailType: "COMPANY_INFORMATION_IN_REVIEW_SUBMIT" },
  "Risk Acceptance": { emailType: "RISK_ACCEPTANCE" },
  "Onboard Complete": { emailType: "PHASE_CHANGE", emailSubtype: ["ONBOARDING_COMPLETE"] },
  "Post Onboarding Survey": { emailType: "POST_ONBOARDING_SURVEY" },
};

export const optionalBAEmails: Record<string, LimitedClientEmailTypes> = {
  "Kick-Off Call Requested": { emailType: "KICKOFF_CALL" },
  "Kick-Off Call Modified": { emailType: "KICKOFF_CALL" },
  "DocuSign Forms Ready to Download": { emailType: "FORMS_ARE_READY_TO_DOWNLOAD" },
  "Enrollment Materials": { emailType: "ENROLLMENT_RESOURCES_ITEMS_ADDED" },
  "Ongoing Service Call Requested": { emailType: "ONGOING_SERVICE_CALL_REQUESTED" },
  "Ongoing Service Call Modified": { emailType: "ONGOING_SERVICE_CALL" },
  "Phase Change: Info Gathering": { emailType: "PHASE_CHANGE", emailSubtype: ["INFO_GATHERING"] },
  "Phase Change: Review and Validate": {
    emailType: "PHASE_CHANGE",
    emailSubtype: ["REVIEW_AND_VALIDATE"],
  },
  "Due Date Emails": { emailType: "UPCOMING_DATES" },
  "Dental Easy Start Flyer": { emailType: "GENERIC_DENTAL_ID_CARD" },
  "Dental Personalized ID Card": { emailType: "PERSONALIZED_DENTAL_ID_CARD" },
  "Actively At Work Reminder": { emailType: "ACTIVELY_AT_WORK_REMINDER" },
};

const EmailsList = () => (
  <>
    <div className={styles.limitedClientEmailLists}>
      <div>
        <span className={styles.iconSuccess}>
          <FontAwesomeIcon icon={faCheckCircle} />
        </span>
        <Body2>Benefits Administrators will receive:</Body2>
        <UnorderedList content="body3">
          {Object.keys(obligatoryBAEmails).map((label) => (
            <UnorderedListItem key={label}>{label}</UnorderedListItem>
          ))}
        </UnorderedList>
      </div>
      <div>
        <span className={styles.iconError}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </span>
        <Body2>Benefits Administrators won't receive:</Body2>
        <UnorderedList content="body3">
          {Object.keys(optionalBAEmails).map((label) => (
            <UnorderedListItem key={label}>{label}</UnorderedListItem>
          ))}
        </UnorderedList>
      </div>
    </div>
    <Body2>Brokers will continue to receive all emails.</Body2>
  </>
);

const Header = () => (
  <>
    <Body2>Limit client emails</Body2>
    <Body5 as="p">
      Turning this on disables most emails to the Benefits Administrator, and only sends them to the
      Broker.
    </Body5>
  </>
);

export function NotificationLimitClientEmails(props: Props) {
  const { client } = props;

  const [editMode, toggleEditMode] = useToggler();

  const { mutateAsync: updateClient, error } = useUpdateClient();

  const trackElementClicked = useTrackElementClicked();
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Manage limit client emails",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: Yup.object({
      limitedEmails: Yup.boolean(),
    }),
    type: "Limited Emails",
    track,
    formikOptions: { enableReinitialize: true },
    onSuccessCallback: toggleEditMode,
  });

  const toggleEditModeAndResetForm = () => {
    track("Cancel");
    toggleEditMode();
    formik.resetForm();
  };

  return (
    <HubCard>
      {editMode ? (
        <form onSubmit={formik.handleSubmit}>
          <Header />
          <StackY dist={16} wrap={false}>
            <Checkbox
              name="limitedEmails"
              checked={!!formik.values.limitedEmails}
              label="Limit client emails"
              disabled={formik.isSubmitting}
              onChange={formik.handleChange}
            />
            <EmailsList />

            <Row justify="end">
              <Col>
                <Row align="middle" gutter={24}>
                  <Col>
                    <Button
                      type="text"
                      onClick={toggleEditModeAndResetForm}
                      size="middle"
                      disabled={formik.isSubmitting}
                    >
                      Cancel
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      type="secondary"
                      size="middle"
                      disabled={formik.isSubmitting}
                      htmlType="submit"
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div aria-live="assertive" className="hide:empty">
              {error && (
                <ErrorMessage>
                  {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
                </ErrorMessage>
              )}
            </div>
          </StackY>
        </form>
      ) : (
        <Row justify="space-between" align="middle">
          <Col>
            <Header />
            <Body3>{client.limitedEmails ? "On" : "Off"}</Body3>
          </Col>

          <Col>
            <Button type="text" onClick={toggleEditModeAndResetForm} size="middle">
              Edit
            </Button>
          </Col>
        </Row>
      )}
    </HubCard>
  );
}
