import { ChipGroup } from "client/src/components/Chip/ChipGroup";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  type SlfCoverageLongName,
} from "shared/types/SlfCoverages";
import {
  benefitTypeToGroupRecord,
  getBenefitTypesGroupings,
} from "shared/utils/benefitTypesGroupings";
import { listFormat } from "shared/utils/format";
import { rejectNullableValues, unique } from "shared/utils/utils";
import type { ChipGroupProps } from "client/src/components/Chip/ChipGroup";
import type { BenefitTypeEIFGroup } from "shared/utils/benefitTypesGroupings";

type Props = {
  slfCoverages: SlfCoverageLongName[];
  selectedSlfCoverages: SlfCoverageLongName[] | undefined;

  name: ChipGroupProps<SlfCoverageLongName>["name"];
  touched: ChipGroupProps<SlfCoverageLongName>["touched"];
  error: ChipGroupProps<SlfCoverageLongName>["error"];
  form?: ChipGroupProps<SlfCoverageLongName>["form"];
  disabled?: boolean;

  setFieldValue: (name: string, value: unknown) => Promise<unknown>;

  getOptionProps: (args: {
    label: string;
    value: BenefitTypeEIFGroup;
    coverages: SlfCoverageLongName[];
  }) => ChipGroupProps<BenefitTypeEIFGroup>["options"][0];
};

export function EIFBenefitsChipGroup(props: Props) {
  const {
    slfCoverages,
    name,
    selectedSlfCoverages,
    touched,
    error,
    form,
    disabled,
    setFieldValue,
    getOptionProps,
  } = props;

  const benefitTypeGroupsMap = getBenefitTypesGroupings(slfCoverages.slice().sort());
  const benefitGroupsEntries = Array.from(benefitTypeGroupsMap.entries());
  const options = benefitGroupsEntries.map(([groupName, benefitTypes]) => {
    const coverages = benefitTypes.map((benefitType) => benefitTypeToCoverage[benefitType]);
    const label = listFormat(coverages);
    const value = groupName;
    const optionProps = getOptionProps({ label, value, coverages });
    optionProps["data-testid"] = `role=checkbox label=${label}`;
    if (disabled) {
      optionProps.disabled = disabled;
    }
    return optionProps;
  });

  const selectedGroupNames = unique(
    selectedSlfCoverages
      ?.map((coverage) => {
        const benefitType = coverageToBenefitType[coverage];
        return benefitTypeToGroupRecord[benefitType];
      })
      .filter(rejectNullableValues),
  );

  return (
    <ChipGroup
      name={name}
      value={selectedGroupNames}
      touched={touched}
      error={error}
      onChange={async (e) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- guaranteed
        const groupName = e.target.value as BenefitTypeEIFGroup;
        const checked = e.target.checked;

        const benefitTypesInGroup = benefitTypeGroupsMap.get(groupName) ?? [];
        const slfCoveragesInGroup = benefitTypesInGroup.map((bt) => benefitTypeToCoverage[bt]);

        const nextValues = checked
          ? slfCoveragesInGroup.concat(selectedSlfCoverages ?? []).sort()
          : selectedSlfCoverages?.filter((cov) => !slfCoveragesInGroup.includes(cov)).sort() ?? [];

        await setFieldValue(name, nextValues);
      }}
      form={form}
      options={options}
    />
  );
}
