import { EIFPushToQPSStep2 } from "client/src/domain/EIF/QPS/EIFPushToQPSStep2";
import { EIFPushToQPSStep3 } from "client/src/domain/EIF/QPS/EIFPushToQPSStep3";
import { EIFPushToQPSStepInitial } from "client/src/domain/EIF/QPS/EIFPushToQPSStepInitial";
import { useState } from "react";
import { buildEmployeeClassesToExport, type EmployeeClass } from "shared/types/EmployeeClass";
import type { AssignBenefitsToAdminClassesMapping } from "client/src/domain/EIF/QPS/EIFPushToQPSStep2";
import type { MapAndSyncQPSClassesFunc } from "client/src/hooks/qps";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type { QPSPlanDesignInfo } from "shared/types/QPSClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClasses: EmployeeClass[];
  qpsPlanDesignInfo: QPSPlanDesignInfo;
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  onGoToReview?: (mapping: AssignBenefitsToAdminClassesMapping[]) => void;
  mapAndSyncQPSClasses: MapAndSyncQPSClassesFunc;
  isPendingMapAndSyncQPSClasses: boolean;
};

/**
 * @deprecated use the new version of the page
 */
export function EIFPushToQPSPageContents(props: Props) {
  const {
    client,
    employeeClasses,
    qpsPlanDesignInfo,
    authUser,
    featureToggles,
    onGoToReview,
    isPendingMapAndSyncQPSClasses,
    mapAndSyncQPSClasses,
  } = props;

  const [employeeClassIndex, setEmployeeClassIndex] = useState<number>(0);
  const [step, goToStep] = useState<1 | 2 | 3>(1);
  const [mapping, setMapping] = useState<AssignBenefitsToAdminClassesMapping[]>([]);

  const employeeClassesToExport = buildEmployeeClassesToExport(
    client,
    employeeClasses,
    qpsPlanDesignInfo.basicClasses,
  );

  return (
    <>
      {step === 1 && (
        <EIFPushToQPSStepInitial
          client={client}
          onNext={() => goToStep(2)}
          plans={[]} // on purpose since this component will be deprecated
          employeeClasses={employeeClassesToExport}
          qpsBenefitTypeLevels={qpsPlanDesignInfo.benefitTypeLevels}
          featureToggles={featureToggles}
          hasERISAContacts={qpsPlanDesignInfo.hasERISAContacts}
          planInfoByBenefitCode={qpsPlanDesignInfo.planInfoByBenefitCode}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          missingPFMLLegalNameAndTaxId={qpsPlanDesignInfo.missingPFMLLegalNameAndTaxId ?? false}
        />
      )}

      {step === 2 && (
        <EIFPushToQPSStep2
          client={client}
          authUser={authUser}
          mapping={mapping}
          setMapping={setMapping}
          employeeClasses={employeeClassesToExport}
          qpsBasicClasses={qpsPlanDesignInfo.basicClasses}
          featureToggles={featureToggles}
          onPrevious={() => goToStep(1)}
          onNext={(mapping) => {
            setMapping(mapping);
            goToStep(3);
            onGoToReview?.(mapping);
          }}
          employeeClassIndex={employeeClassIndex}
          setEmployeeClassIndex={setEmployeeClassIndex}
        />
      )}

      {step === 3 && (
        <EIFPushToQPSStep3
          client={client}
          authUser={authUser}
          mapping={mapping}
          onPrevious={() => goToStep(2)}
          employeeClasses={employeeClassesToExport}
          mapAndSyncQPSClasses={mapAndSyncQPSClasses}
          isPendingMapAndSyncQPSClasses={isPendingMapAndSyncQPSClasses}
          setEmployeeClassIndex={setEmployeeClassIndex}
        />
      )}
    </>
  );
}
