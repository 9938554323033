import type { Contact, ContactSunLifeAccess } from "../types/Contact";
import type { PolicyId } from "shared/types/Client";

export const extractContactsByType = (contacts: Contact[] | undefined) => {
  const defaultContacts = {
    primaryWebAdminContact: null,
    webAdminContacts: [],
    dataFeedsImplementorContact: null,
    dataFeedsProductionSupportContact: null,
    dataFeedsBenAdminContact: null,
    tpaContact: null,
  };
  return (
    contacts?.reduce<{
      primaryWebAdminContact: Contact | null;
      webAdminContacts: Contact[];
      dataFeedsImplementorContact: Contact | null;
      dataFeedsProductionSupportContact: Contact | null;
      dataFeedsBenAdminContact: Contact | null;
      tpaContact: Contact | null;
    }>((obj, current) => {
      if (current.type === "PRIMARY_WEB_ADMIN") obj.primaryWebAdminContact = current;
      else if (current.type === "DATA_FEEDS_BEN_ADMIN") obj.dataFeedsBenAdminContact = current;
      else if (current.type === "DATA_FEEDS_IMPLEMENTOR") obj.dataFeedsImplementorContact = current;
      else if (current.type === "DATA_FEEDS_PRODUCTION_SUPPORT")
        obj.dataFeedsProductionSupportContact = current;
      else if (current.type === "WEB_ADMIN") obj.webAdminContacts.push(current);
      else if (current.type === "TPA") obj.tpaContact = current;
      return obj;
    }, defaultContacts) ?? defaultContacts
  );
};

export function getContactHasExpectedAccess(
  contact: Pick<
    Contact,
    | "accessesForPolicyIds"
    | "billingAccess"
    | "claimsAccess"
    | "paymentsAccess"
    | "documentsAccess"
    | "memberChangesAccess"
  >,
  policyIds: PolicyId[],
  access: ContactSunLifeAccess,
) {
  if (policyIds.length === 0) return true;

  const hasAccessToAllRequiredPolicies = policyIds.every((policyId) =>
    contact.accessesForPolicyIds.includes(policyId),
  );
  const hasExpectedAccess = Boolean(contact[access] && hasAccessToAllRequiredPolicies);

  return hasExpectedAccess;
}
