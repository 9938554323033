import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Body3 } from "client/src/components/Typography/Typography";

import { getBenefitTypesGroupings } from "shared/utils/benefitTypesGroupings";
import { getCoveragesInClientNotYetInAnyBill } from "shared/utils/getCoveragesInClientNotYetInAnyBill";
import type { BillSplitType, BillingStructureType } from "@prisma/client";
import type { Bill } from "shared/types/Bill";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";

type Props = {
  slfCoverages: SlfCoverageLongName[] | null;
  billingStructureType: BillingStructureType | null;
  billSplitType: BillSplitType | null | undefined;
  bills: Pick<Bill, "slfCoverages">[];
  showSuccess?: boolean;
};

export function MissingBillsAlert(props: Props) {
  const { slfCoverages, billingStructureType, billSplitType, bills, showSuccess } = props;

  const coveragesInClientNotYetInAnyBill = getCoveragesInClientNotYetInAnyBill(
    slfCoverages,
    billingStructureType,
    billSplitType,
    bills,
  );

  if (coveragesInClientNotYetInAnyBill == null) return null;

  const benefitGroupsMap = getBenefitTypesGroupings(coveragesInClientNotYetInAnyBill);

  if (benefitGroupsMap.size > 0) {
    return (
      <AlertBanner
        variant="warning"
        message={
          <Body3>
            You have {benefitGroupsMap.size} benefit(s) that need to be assigned to a bill.
          </Body3>
        }
      />
    );
  }

  if (showSuccess) {
    return (
      <AlertBanner
        variant="success"
        message={<Body3>All benefits have been assigned to a bill.</Body3>}
      />
    );
  }

  return null;
}
