import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { StackY } from "../../../components/Spacing/Spacing";
import { EIFCheckpointNavigation } from "../../../domain/EIF/EIFCheckpointNavigation";
import { useTitle } from "../../../hooks/useTitle";

import type { Task } from "shared/data/Tasks";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { ChangeWithDetails } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ReadyToSignOnboardingFormsMetadata } from "shared/types/OnboardingForm";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type EIFSubmitCompanyInformationProps = {
  client: Client;
  task: Task;
  bills: Bill[];
  deletedBills: Bill[];
  contacts: Contact[];
  billingSummaryStatementTemplates: Document[];
  clientPlans: Plan[];
  employeeClasses: EmployeeClass[];
  changes: ChangeWithDetails[];
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  subsidiaries: Subsidiary[];
  readyToSignOnboardingFormsMetadata: ReadyToSignOnboardingFormsMetadata[];
};

export function EIFSubmitCompanyInformation({
  client,
  task,
  bills,
  deletedBills,
  contacts,
  billingSummaryStatementTemplates,
  clientPlans,
  employeeClasses,
  changes,
  authUser,
  featureToggles,
  subsidiaries,
  readyToSignOnboardingFormsMetadata,
}: EIFSubmitCompanyInformationProps) {
  useTitle("Submit Company Information");

  return (
    <ChildPageLayout
      topNavTitle="Submit Company Information"
      aside={
        <>
          <h1>Submit Company Information</h1>

          <DueDateAlert task={task} />
          <br />

          <p>
            We'll collect important information so that we can configure everything for your company
            and create contracts.
          </p>

          <p>
            Reach out any time to your implementation consultant if you have questions about how to
            complete this information and Sun Life can help guide you. After completing it, you will
            submit it to your implementation consultant who will review it for accuracy.
          </p>
        </>
      }
      contents={
        <>
          <StackY dist={40}>
            <div>
              <EIFCheckpointNavigation
                task={task}
                client={client}
                contacts={contacts}
                bills={bills}
                deletedBills={deletedBills}
                billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                clientPlans={clientPlans}
                employeeClasses={employeeClasses}
                changes={changes}
                authUser={authUser}
                featureToggles={featureToggles}
                subsidiaries={subsidiaries}
                readyToSignOnboardingFormsMetadata={readyToSignOnboardingFormsMetadata}
              />
            </div>

            <div>
              <h3>FAQs</h3>
              <p>
                <strong>What do I do if I need help?</strong>
              </p>
              <p>
                Some questions may require some assistance if you are not familiar with a certain
                topic. Feel free to skip any steps that you don't know how to answer and be sure to
                reach out to your broker and implementation consultant for help.
              </p>
            </div>
          </StackY>
        </>
      }
    />
  );
}
