import { DateTime } from "luxon";
import { PFMLCoverages, PFMLSPDCoverages, SunAdvisorCoverages } from "shared/types/Client";
import { formatDateFullMonthWithYear, formatDateRangeUI } from "shared/utils/format";

import { isPeakSeason } from "../../../shared/utils/client";
import { getSortedDedupePoliciesEffectiveDates } from "../utils/sort";

import type { PhaseId } from "@prisma/client";
import type { ReactNode } from "react";
import type { Task, TaskId } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";

export type PhaseCopy = {
  id: Exclude<PhaseId, null>;
  title: string;
  description: ReactNode;
  importantDates: ImportantDates[];
};

type PhaseCopyDefinition = {
  id: Exclude<PhaseId, null>;
  title: string;
  description: (client: Client) => ReactNode;
  importantDates: (client: Client, policy: Policy) => ImportantDates[];
};

type TaskItem = {
  text: string;
  isApplicable: boolean;
};

type DateObject = {
  date?: Date | null;
  endDate?: Date | null;
  tasks: TaskItem[];
};

type FilteredDateObject = {
  date: Date;
  endDate?: Date;
  tasks: TaskItem[];
};

type ImportantDates = {
  dateInfo: string;
  tasks?: TaskItem[];
};

const sortAndFormatDates = (dates: DateObject[]) => {
  const filteredDates = dates
    .filter((date): date is FilteredDateObject => !!date.date)
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const formattedDates = new Map<string, ImportantDates>();
  filteredDates.forEach(({ date, endDate, tasks }: FilteredDateObject) => {
    const dateInfo = endDate ? formatDateRangeUI(date, endDate) : formatDateFullMonthWithYear(date);
    formattedDates.has(dateInfo)
      ? formattedDates.set(dateInfo, {
          dateInfo,
          tasks: formattedDates.get(dateInfo)?.tasks?.concat(...tasks),
        })
      : formattedDates.set(dateInfo, { dateInfo, tasks });
  });
  return [...formattedDates.values()];
};

const isTaskNotInReviewOrCompleted = (tasks: Task[], taskId: TaskId) => {
  const task = tasks.find((task) => task.id === taskId);
  return Boolean(task && !(task.status && ["In Review", "Done"].includes(task.status)));
};

// These tasks are coverage-specific, not policy-specific, but should only apply once per client.
// See https://maxwellhealth.atlassian.net/browse/FP-2845.
const isRelevantForPolicy = (taskId: TaskId, policy: Policy) => {
  let hasRelevantCoverage = false;
  const coverageSet = new Set(policy.slfCoverages);

  switch (taskId) {
    case "pfml":
      hasRelevantCoverage = PFMLCoverages.map((type) => coverageSet.has(type)).includes(true);
      break;
    case "pfml-spd":
      hasRelevantCoverage = PFMLSPDCoverages.map((type) => coverageSet.has(type)).includes(true);
      break;
    case "sunadvisor-spd":
      hasRelevantCoverage = SunAdvisorCoverages.map((type) => coverageSet.has(type)).includes(true);
      break;
    default:
      break;
  }

  return hasRelevantCoverage;
};

const policiesBillsAndCertsCopy = (
  policies: Policy[],
  certsStatus: Client["priorCarrierCertificationsStatus"],
) => {
  const hasBillRequired = Boolean(
    policies.filter((p) => ["PENDING", "COMPLETED"].includes(p.priorCarrierBill || "")).length,
  );
  const certsRequired = certsStatus === "PENDING" || certsStatus === "COMPLETED";

  if (hasBillRequired && certsRequired) {
    return "You’ll also provide prior carrier bills and certificates and a census file with employee information.";
  } else if (hasBillRequired) {
    return "You’ll also provide prior carrier bills and a census file with employee information.";
  } else if (certsRequired) {
    return "You’ll also provide prior carrier certificates and a census file with employee information.";
  } else {
    return "You’ll also provide a census file with employee information.";
  }
};

export const phasesCopy: { [id in PhaseId]: PhaseCopyDefinition } = {
  WELCOME: {
    id: "WELCOME",
    title: "Welcome",
    description: () => (
      <p>
        Let’s get started by understanding your unique situation – this could include your
        enrollment event, enrollment resources, or technology goals. This helps Sun Life customize
        the rest of the process to your needs. Sun Life is also preparing your required forms and
        will notify you when they’re ready.
      </p>
    ),
    importantDates: () => [],
  },
  INFO_GATHERING: {
    id: "INFO_GATHERING",
    title: "Info Gathering",
    description: (client) => {
      const billsAndCerts = policiesBillsAndCertsCopy(
        client.policies,
        client.priorCarrierCertificationsStatus,
      );
      return (
        <p>
          Complete required forms to help Sun Life understand your plan details, billing
          preferences, and contract options. {billsAndCerts} Sun Life can provide consultative
          support on any of this during and after your kickoff call.
        </p>
      );
    },
    importantDates: (client, policy) => {
      const enrollmentElections = client.isMaxwell
        ? []
        : [
            {
              text: "Send Employee Elections",
              isApplicable: isTaskNotInReviewOrCompleted(
                policy?.policyTasks || client.tasks || [],
                "enrollment-elections",
              ),
            },
          ];
      const importantDates = sortAndFormatDates([
        {
          date: client.enrollmentDateStart,
          endDate: client.enrollmentDateEnd,
          tasks: [
            {
              text: "Enrollment Event",
              isApplicable: true,
            },
          ],
        },
        {
          date: policy?.enrollmentCensusDeadline,
          tasks: [
            {
              text: "Provide Prior Carrier Bill",
              isApplicable: isTaskNotInReviewOrCompleted(
                policy?.policyTasks || client.tasks || [],
                "prior-carrier-bills",
              ),
            },
            ...enrollmentElections,
          ],
        },
        {
          date: policy?.infoGatheringDeadline,
          tasks: [
            {
              text: "Complete and Sign Onboarding Forms",
              isApplicable: isTaskNotInReviewOrCompleted(client.tasks || [], "onboarding-forms"),
            },
            {
              text: "Provide Prior Carrier Certificate",
              isApplicable:
                client?.priorCarrierCertificationsStatus === "PENDING" &&
                isTaskNotInReviewOrCompleted(client.tasks || [], "prior-carrier-certificates"),
            },
            {
              text: "Provide SunAdvisor SPD",
              isApplicable:
                isRelevantForPolicy("sunadvisor-spd", policy) &&
                isTaskNotInReviewOrCompleted(client.tasks || [], "sunadvisor-spd"),
            },
            {
              text: "Provide PFML Plan Exemption Documents",
              isApplicable:
                isRelevantForPolicy("pfml", policy) &&
                isTaskNotInReviewOrCompleted(client.tasks || [], "pfml"),
            },
            {
              text: "Provide PFML SPDs",
              isApplicable:
                isRelevantForPolicy("pfml-spd", policy) &&
                isTaskNotInReviewOrCompleted(client.tasks || [], "pfml-spd"),
            },
            {
              text: "Complete Submit Company Information",
              isApplicable: isTaskNotInReviewOrCompleted(
                client.tasks || [],
                "eif-submit-company-information",
              ),
            },
          ],
        },
      ]);
      const someApplicableTask = importantDates.some((importantDate) =>
        importantDate.tasks?.some((task) => task.isApplicable),
      );
      return someApplicableTask ? importantDates : [];
    },
  },
  REVIEW_AND_VALIDATE: {
    id: "REVIEW_AND_VALIDATE",
    title: "Review & Validate",
    description: () => (
      <>
        <p>
          Sun Life will review all the information you provided during the Info Gathering phase, and
          work with you if any clarifications are needed. This ensures that all your employees have
          the exact coverage they expect.
        </p>
        <p>
          Once this review is complete, Sun Life should have everything needed to finalize and
          approve your plans.
        </p>
      </>
    ),
    importantDates: () => [
      {
        dateInfo: `Review & Validate typically takes ${
          isPeakSeason(DateTime.local()) ? "7-8" : "5-6"
        } business days.`,
      },
    ],
  },
  PLAN_APPROVAL: {
    id: "PLAN_APPROVAL",
    title: "Plan Approval",
    description: () => (
      <p>
        Sun Life is working to approve and finalize your plans, and you will be notified when this
        is completed. Please make sure coverage with prior carriers has been cancelled if needed.
      </p>
    ),
    importantDates: () => [
      {
        dateInfo: `Plan Approval typically takes ${
          isPeakSeason(DateTime.local()) ? "5-7" : "3-5"
        } business days.`,
      },
    ],
  },
  CONTRACTS_AND_BILLING: {
    id: "CONTRACTS_AND_BILLING",
    title: "Contracts & Billing",
    description: () => (
      <p>
        Sun Life is finalizing contracts and your initial billing set-up. Once these are ready,
        you’ll be able to schedule an Ongoing Service Call to meet your Client Service team, who
        will be your main point of contact going forward.
      </p>
    ),
    importantDates: () => [
      {
        dateInfo: `Contracts & Billing typically takes ${
          isPeakSeason(DateTime.local()) ? "5-7" : "4-6"
        } business days`,
      },
    ],
  },
  ONBOARDING_COMPLETE: {
    id: "ONBOARDING_COMPLETE",
    title: "Onboarding Complete",
    description: (client) => {
      const dates = getSortedDedupePoliciesEffectiveDates(client.policies);
      const policyEffective = dates.join(", ").replace(/, ([^,]*)$/, " and $1");
      return (
        <>
          <p>
            Your benefits are now effective, and employees may begin using their coverage as of your
            effective date{dates.length > 1 ? "s" : ""} on {policyEffective}.
          </p>
          <p>
            Start using Sun Life Connect to manage your account, employees, claims, and more
            ongoing.
          </p>
        </>
      );
    },
    importantDates: () => [],
  },
  REJECTED: {
    id: "REJECTED",
    title: "Risk Rejected",
    description: () => null,
    importantDates: () => [],
  },
};

export const findPhaseCopy = (client: Client, policy: Policy): PhaseCopy | null => {
  if (!policy.phaseId) return null;

  const copy = phasesCopy[policy.phaseId];

  return {
    ...copy,
    description: copy.description(client),
    importantDates: copy.importantDates(client, policy),
  };
};

export const getPhaseLabels = (client: Client) =>
  Object.values(phasesCopy).map((phase) => ({
    id: phase.id,
    title: phase.title,
    description: phase.description(client),
  }));
