import { StackY } from "client/src/components/Spacing/Spacing";
import { H3 } from "client/src/components/Typography/Typography";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { NotificationEmails } from "client/src/pages/Clients/NotificationEmails";
import { NotificationLimitClientEmails } from "client/src/pages/Clients/NotificationLimitClientEmails";
import type { Client } from "shared/types/Client";

type Props = {
  client: Client;
};

export function NotificationSettings(props: Props) {
  const { client } = props;

  const { data: featureToggles } = useGetFeatureToggles(client.id);

  return (
    <div>
      <H3 blueMedium>Notifications</H3>
      <StackY dist={48}>
        <NotificationEmails client={client} />
        <NotificationLimitClientEmails client={client} featureToggles={featureToggles} />
      </StackY>
    </div>
  );
}
