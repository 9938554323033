import { Checkbox } from "client/src/components/Form/Checkbox";
import { TextArea } from "client/src/components/Form/TextArea";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { classEmploymentTypes, displayEmploymentTypeName } from "shared/types/EmployeeClass";
import { getEmployeeClassDetailsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { hasALeastOneSetValue, hasNonNullValues } from "shared/utils/utils";
import { employeeClassValidationSchema } from "shared/validation/employeeClass";
import { Button } from "../../../../components/Button/Button";
import { InternalLinkButton } from "../../../../components/Button/InternalLinkButton";
import { ErrorMessage } from "../../../../components/Error/ErrorMessage";
import { FormInput } from "../../../../components/Form/Input";
import { InputErrorMessage } from "../../../../components/Form/InputErrorMessage";
import { ReactComponent as InfoOutlineIcon } from "../../../../components/Icons/InfoOutlineIcon.svg";
import { StackY } from "../../../../components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "../../../../components/Typography/Typography";
import { slobMessage } from "../../../../components/slobMessage/slobMessage";
import { getFormikErrors, useSlobFormik } from "../../../../hooks/useSlobFormik";
import { hasFormikErrors } from "../../../../utils/hasFormikErrors";
import { EIFSectionStatusIconography } from "../../common/EIFSectionStatusIconography";
import { EIFPlanConfigAndEligibilitySectionHeader } from "../EIFPlanConfigAndEligibilitySectionHeader";
import * as styles from "./eifClassBuilder.module.less";
import type { CreateClassQuery, UpdateClassQuery } from "../../../../hooks/employeeClass";
import type { SectionTracker } from "./EIFClassBuilderCreator";
import type { ChangeEvent } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  isActive: boolean;
  singleClassBuilderMode: boolean;
  client: Client;
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  employeeClass: EmployeeClass | undefined;
  onSave: (employeeClass: EmployeeClass) => void;
  isLoading: boolean;
  track: SectionTracker;
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
};

const propertiesToUpdate = [
  "numberOfEmployees",
  "employmentTypes",
  "minimumWeeklyHours",
  "minimumWeeklyHoursAreNotCalculatedWeekly",
  "minimumHoursDetails",
] as const;

const validationSchema = employeeClassValidationSchema.pick(propertiesToUpdate);

function getFirstError(errors: string | string[] | undefined): string | undefined {
  return Array.isArray(errors) ? errors[0] : errors;
}

export function EIFClassBuilderDetails(props: Props) {
  const {
    isActive,
    singleClassBuilderMode,
    client,
    createClassQuery: { mutateAsync: createClass },
    updateClassQuery: { mutateAsync: updateClass },
    employeeClass,
    onSave,
    isLoading,
    track,
    changeSnapshot,
    authUser,
  } = props;

  const location = useLocation();

  const formik = useSlobFormik({
    validationSchema,
    validationContext: { prefill: true },
    initialValues: {
      numberOfEmployees: employeeClass?.numberOfEmployees || null,
      minimumWeeklyHours: employeeClass?.minimumWeeklyHours || null,
      minimumWeeklyHoursAreNotCalculatedWeekly: Boolean(
        employeeClass?.minimumWeeklyHoursAreNotCalculatedWeekly,
      ),
      minimumHoursDetails: employeeClass?.minimumHoursDetails || undefined,
      employmentTypes: employeeClass?.employmentTypes || [],
    },
    onSubmit: async () => {
      const hasALeastOneValue = hasALeastOneSetValue(formik.values);
      if (!hasALeastOneValue) {
        return;
      }

      const creating = !employeeClass;
      const { isSuccess, data } = creating
        ? await createClass({
            params: { clientId: client.id },
            data: { ...formik.values, clientId: client.id },
          })
        : await updateClass({
            data: { ...formik.values, clientId: client.id, id: employeeClass.id },
            params: { clientId: client.id, employeeClassId: employeeClass.id },
          });

      if (isSuccess) {
        track("Save");
        void slobMessage.success(employeeClass ? "Class updated" : "Class created");
        onSave(data);
      }
    },
  });

  const errorKeys = hasFormikErrors(formik);

  const inactiveAndEmpty =
    !isActive && !employeeClass?.numberOfEmployees && !employeeClass?.minimumWeeklyHours;

  const status = getEmployeeClassDetailsCompletionStatus(employeeClass);

  const changeLogEmployeeClassId = employeeClass?.id ?? "";
  const changeDetailInfoList = [
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.numberOfEmployees,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.minimumWeeklyHours,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.minimumHoursDetails,
  ];

  const haveEverSavedForm =
    employeeClass &&
    hasNonNullValues(getPropertiesToUpdate<EmployeeClass>([...propertiesToUpdate])(employeeClass));

  const strictErrors =
    haveEverSavedForm || formik.submitCount > 0
      ? getFormikErrors(formik.values, validationSchema, {
          prefill: false,
        })
      : {};

  if (!isActive) {
    return (
      <EIFPlanConfigAndEligibilitySectionHeader
        status={status}
        isLoading={isLoading}
        location={location}
        inactiveAndEmpty={inactiveAndEmpty}
        changeDetailInfoList={changeDetailInfoList}
        section="Details"
        client={client}
        authUser={authUser}
      >
        <StackY dist={4}>
          {employeeClass?.numberOfEmployees && (
            <Body3 as="div">{employeeClass.numberOfEmployees} eligible employees</Body3>
          )}

          {strictErrors.numberOfEmployees && (
            <Body3 redMedium>{strictErrors.numberOfEmployees}</Body3>
          )}

          {employeeClass?.employmentTypes && (
            <Body3 as="div">
              {employeeClass.employmentTypes
                ?.map((item) => displayEmploymentTypeName[item])
                .join(", ")}
            </Body3>
          )}

          {strictErrors.employmentTypes && <Body3 redMedium>{strictErrors.employmentTypes}</Body3>}

          {employeeClass?.minimumWeeklyHoursAreNotCalculatedWeekly ? (
            <div className="mt-12">
              <Body5>Eligible hours</Body5>
              <Body3 as="div">{employeeClass.minimumHoursDetails}</Body3>
            </div>
          ) : (
            <>
              {employeeClass?.minimumWeeklyHours && (
                <Body3 as="div">{employeeClass.minimumWeeklyHours} minimum weekly hours</Body3>
              )}
            </>
          )}

          {strictErrors.minimumHoursDetails && (
            <Body3 redMedium>{strictErrors.minimumHoursDetails}</Body3>
          )}

          {strictErrors.minimumWeeklyHours && (
            <Body3 redMedium>{strictErrors.minimumWeeklyHours}</Body3>
          )}
        </StackY>
      </EIFPlanConfigAndEligibilitySectionHeader>
    );
  }

  const howManyEmployeesQuestion = singleClassBuilderMode
    ? "How many eligible employees do you have?"
    : "How many eligible employees are in this group?";

  const minimumWeeklyHoursQuestion = singleClassBuilderMode
    ? "What are the minimum weekly hours they need to work to be eligible for benefits?"
    : "What are the minimum weekly hours this group needs to work to be eligible for the selected benefits?";

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const {
      target: { name, value },
    } = e;
    // Formik casts empty string values to undefined internally
    // before validating. Because of that, we have to convert here
    // to null in that case so that it is captured as a missing value
    // by our validation schema. We can't update the validation schema
    // to disallow undefined values because it would break partial updates
    // to the Class entity. There are several ways of solving this, this is
    // just one of them.
    return formik.setFieldValue(name, value === "" ? null : value);
  }

  return (
    <>
      <div className="mb-24">
        <H3 as="h2">
          <EIFSectionStatusIconography status={status} />
          Details
        </H3>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32}>
          <div>
            <p className="mb-16">
              <Body2>{howManyEmployeesQuestion}</Body2>
            </p>

            <StackY dist={8}>
              <div className={styles.detailsSectionInputContainer}>
                <FormInput
                  name="numberOfEmployees"
                  label="Total # of eligible employees"
                  disabled={formik.isSubmitting}
                  onChange={handleOnChange}
                  value={formik.values.numberOfEmployees ?? ""}
                  maxLength={4}
                  touched={formik.touched.numberOfEmployees || !!strictErrors.numberOfEmployees}
                  error={formik.errors.numberOfEmployees || strictErrors.numberOfEmployees}
                />
              </div>

              <Body5 as="div">
                Sun Life will compare this with your census after your enrollment event. It helps
                ensure that any minimum enrollment percentage and participation requirements have
                been met.
              </Body5>
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.numberOfEmployees ?? null,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "numberOfEmployees",
                  variant: "number",
                  client,
                  formik,
                })}
              />
            </StackY>
          </div>

          <div>
            <p className="mb-16">
              <Body2>What type of employment are these employees?</Body2>
              <Tooltip title="Employees who do not typically work at least 20 hours a week are usually part-time.">
                <InfoOutlineIcon className={styles.tooltipIcon} />
              </Tooltip>
            </p>
            {classEmploymentTypes.map((employmentType) => (
              <StackY dist={16} key={employmentType}>
                <Checkbox
                  key={`employmentTypes_${employmentType}`}
                  name="employmentTypes"
                  value={employmentType}
                  label={displayEmploymentTypeName[employmentType]}
                  checked={!!formik.values.employmentTypes?.includes(employmentType)}
                  errorId={
                    formik.touched.employmentTypes || !!strictErrors.employmentTypes
                      ? formik.errors.employmentTypes ?? strictErrors.employmentTypes
                      : undefined
                  }
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                />
                <EditedFieldMsg
                  changeDetailInfoList={[
                    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.employmentTypes ?? null,
                  ]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={
                    getHasPendingEdit({ field: "employmentType", client, formik }) ||
                    getHasPendingEdit({ field: "employmentTypes", client, formik })
                  }
                />
              </StackY>
            ))}
            {(formik.touched.employmentTypes || !!strictErrors.employmentTypes) &&
              (formik.errors.employmentTypes ?? strictErrors.employmentTypes) && (
                <div aria-live="assertive">
                  <InputErrorMessage
                    error={getFirstError(
                      formik.errors.employmentTypes ?? strictErrors.employmentTypes,
                    )}
                  />
                </div>
              )}
          </div>

          <div>
            <p className="mb-16">
              <Body2>{minimumWeeklyHoursQuestion}</Body2>
              <Tooltip
                placement="bottom"
                title={
                  <UnorderedList color="white" markerColor="white" content="body5" tighter noMargin>
                    <UnorderedListItem>
                      Employer paid products typically require a minimum of 30 hours a week.
                    </UnorderedListItem>
                    <UnorderedListItem>
                      Employee paid products typically require a minimum of 20 hours a week.
                    </UnorderedListItem>
                  </UnorderedList>
                }
              >
                <InfoOutlineIcon className={styles.tooltipIcon} />
              </Tooltip>
            </p>

            <StackY dist={16}>
              <StackY dist={8}>
                <div className={styles.detailsSectionInputContainer}>
                  <FormInput
                    name="minimumWeeklyHours"
                    label="Minimum weekly hours"
                    disabled={Boolean(
                      formik.isSubmitting || formik.values.minimumWeeklyHoursAreNotCalculatedWeekly,
                    )}
                    onChange={handleOnChange}
                    value={formik.values.minimumWeeklyHours ?? ""}
                    touched={formik.touched.minimumWeeklyHours || !!strictErrors.minimumWeeklyHours}
                    error={formik.errors.minimumWeeklyHours || strictErrors.minimumWeeklyHours}
                    maxLength={4}
                  />
                </div>
                <EditedFieldMsg
                  changeDetailInfoList={[
                    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.minimumWeeklyHours ??
                      null,
                  ]}
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({
                    field: "minimumWeeklyHours",
                    variant: "number",
                    client,
                    formik,
                  })}
                />
              </StackY>

              <StackY dist={16}>
                <StackY dist={8}>
                  <Checkbox
                    name="minimumWeeklyHoursAreNotCalculatedWeekly"
                    label="Hours for this group of employees is not calculated weekly"
                    checked={Boolean(formik.values.minimumWeeklyHoursAreNotCalculatedWeekly)}
                    onChange={async (e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        await formik.setFieldValue("minimumWeeklyHours", null);
                      }
                      formik.handleChange(e);
                    }}
                    disabled={formik.isSubmitting}
                  />

                  <Body5 as="div">
                    If you’re not sure what to enter for weekly hours because you track time in some
                    other way for this group of eligible employees (i.e. bi-weekly, etc.) your
                    implementation consultant can discuss with you!
                  </Body5>
                </StackY>

                {formik.values.minimumWeeklyHoursAreNotCalculatedWeekly && (
                  <StackY dist={8}>
                    <TextArea
                      name="minimumHoursDetails"
                      label=""
                      aria-label="Minimum hours details"
                      placeholder="Tell us a bit more"
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      value={formik.values.minimumHoursDetails}
                      touched={
                        formik.touched.minimumHoursDetails || !!strictErrors.minimumHoursDetails
                      }
                      error={formik.errors.minimumHoursDetails || strictErrors.minimumHoursDetails}
                      maxLength={1000}
                    />
                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.EmployeeClass[changeLogEmployeeClassId]
                          ?.minimumHoursDetails ?? null,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={getHasPendingEdit({
                        field: "minimumHoursDetails",
                        client,
                        formik,
                      })}
                    />
                  </StackY>
                )}
              </StackY>
            </StackY>
          </div>

          {errorKeys.length > 0 && (
            <div className="mb-24">
              <Row justify="end">
                <Col>
                  <ErrorMessage size="large">
                    {errorKeys.length > 1
                      ? "Please correct the errors above."
                      : "Please correct the error above."}
                  </ErrorMessage>
                </Col>
              </Row>
            </div>
          )}

          {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}

          <Row justify="end">
            <Col>
              <Row align="middle" gutter={28}>
                <Col>
                  <InternalLinkButton
                    type="link-inline-bold"
                    size="middle"
                    to={location.pathname}
                    disabled={formik.isSubmitting}
                    onClick={() => flushSync(formik.resetForm)}
                  >
                    Cancel
                  </InternalLinkButton>
                </Col>

                <Col>
                  <Button
                    htmlType="submit"
                    type="secondary"
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                    size="middle"
                  >
                    Save & Continue
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
