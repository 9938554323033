import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { RouteData } from "shared/config/routeData";
import { Body3, H2 } from "../../../components/Typography/Typography";
import type { Client } from "shared/types/Client";
import type { EIFStepId } from "shared/types/EIF";

type EIFCompanyDetailsIntroductionProps = {
  client: Client;
  eifStepId: EIFStepId;
};

export const EIFCompanyDetailsIntroduction = ({
  client,
  eifStepId,
}: EIFCompanyDetailsIntroductionProps) => {
  return (
    <>
      <H2>Company details</H2>
      <Body3 as="p">
        In this step you’ll tell us some details about your company, like if you have a benefits
        administration platform, your Tax ID (EIN), and if you have current plans insured by Sun
        Life.
      </Body3>
      <Body3 as="p">
        These details will help us set you up in our system and get started with any next steps if
        needed.
      </Body3>
      <Row justify="end">
        <Col>
          <Button
            to={RouteData.eifSubStepDetail.getPath(
              client.id,
              eifStepId,
              "benefits-administration-and-data-feed",
            )}
            type="primary"
            size="large"
          >
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};
