import * as Yup from "yup";
import { BEN_ADMIN, BROKER, SLF_ADMIN, SLF_OWNER, type UserRole } from "../rbac/roles";
import { mergeSchema } from "./validation";
import "./email";

export const UserRoleSchema = Yup.mixed<UserRole>()
  .oneOf([SLF_OWNER, SLF_ADMIN, BEN_ADMIN, BROKER])
  .required();

export const UserInfoValidationSchema = Yup.object({
  firstName: Yup.string().required("Please provide a response"),
  lastName: Yup.string().required("Please provide a response"),
  email: Yup.string()
    .emailV1("Please enter a valid email address")
    .required("Please provide a response"),
});

export const SlfInternalCreateValidationSchema = UserInfoValidationSchema.clone().shape({
  role: UserRoleSchema.required(),
  sendWelcomeEmail: Yup.boolean().required(),
  phoneNumber: Yup.string().nullable().default(null),
});

export const SlfInternalUpdateValidationSchema = UserInfoValidationSchema.clone().shape({
  id: Yup.string().required(),
  role: UserRoleSchema.required(),
  phoneNumber: Yup.string().nullable().default(null),
});

export const BenAdminCreateValidationSchema = UserInfoValidationSchema.clone().shape({
    clientIds: Yup.array(Yup.string().required()).required().min(1),
    sendWelcomeEmail: Yup.boolean().required(),
  }),
  BrokerCreateValidationSchema = UserInfoValidationSchema.clone().shape({
    clientIds: Yup.array(Yup.string().required()).required().min(1),
    sendWelcomeEmail: Yup.boolean().required(),
  });

export const BenAdminUpdateValidationSchema = UserInfoValidationSchema.clone().shape({
    id: Yup.string().required(),
    clientIds: Yup.array(Yup.string().required()).optional(),
  }),
  BrokerUpdateValidationSchema = UserInfoValidationSchema.clone().shape({
    id: Yup.string().required(),
    clientIds: Yup.array(Yup.string().required()).optional(),
  });

export const passwordLengthRequirementMin = Yup.string().min(
  9,
  "Must be at least 9 characters long",
);
export const passwordLengthRequirementMax = Yup.string().max(
  64,
  "Must be a maximum of 64 characters",
);

export const passwordNumberRequirement = Yup.string().matches(/\d/, "Must contain a number");
export const passwordUppercaseRequirement = Yup.string().matches(
  /[A-Z]/,
  "Must contain at least one uppercase",
);
export const passwordLowercaseRequirement = Yup.string().matches(
  /[a-z]/,
  "Must contain at least one lowercase",
);
export const passwordCharacterRequirements = Yup.string().matches(
  /[!@#$%^&*()_+~?^]/,
  "Must contain at least one special character",
);

export const passwordEmailRequirement = Yup.string().test({
  name: "password-requirements-email",
  exclusive: false,
  message: "Must not contain email address linked to account",
  params: {},
  test: (value: string | undefined, _context: { parent: { email: string } }) => {
    if (!value) {
      return true;
    }
    const email = _context.parent.email;
    if (!email?.includes("@")) {
      return true;
    }
    const emailId = email.split("@")[0];
    if (
      value.toLowerCase().includes(email.toLowerCase()) ||
      (emailId && value.toLowerCase().includes(emailId.toLowerCase()))
    ) {
      return false;
    }
    return true;
  },
});
export const passwordNameRequirement = Yup.string().test({
  name: "password-requirements-firstname",
  exclusive: false,
  message: "Must not contain first or last name linked to account",
  params: {},
  test: (
    value: string | undefined,
    _context: { parent: { firstName: string; lastName: string } },
  ) => {
    if (!value) {
      return true;
    }
    const { firstName, lastName } = _context.parent;
    if (!firstName || !lastName) {
      return true;
    }
    if (
      value.toLowerCase().includes(firstName.toLowerCase()) ||
      value.toLowerCase().includes(lastName.toLowerCase())
    ) {
      return false;
    }
    return true;
  },
});

export const passwordValidator = mergeSchema(
  passwordLengthRequirementMin,
  passwordLengthRequirementMax,
  passwordNumberRequirement,
  passwordUppercaseRequirement,
  passwordLowercaseRequirement,
  passwordCharacterRequirements,
  passwordEmailRequirement,
  passwordNameRequirement,
).required("Password value required");
export const PasswordUpsertSchema = Yup.object({
  password: passwordValidator,
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")])
    .required(),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string(),
}).required();

export type PasswordUpsertData = Yup.InferType<typeof PasswordUpsertSchema>;

export type PasswordUpsertRequest = {
  email: string;
  token: string;
  isActivation: boolean;
  clientId?: string;
  password: string;
};

export const ForgotPasswordValidator = Yup.object({
  email: Yup.string()
    .emailV1("Please enter a valid email address")
    .required("Please provide a response"),
}).required();

export const UserIdSchema = Yup.object({ userId: Yup.string().required() }).required();
export const ClientIdSchema = Yup.object({ clientId: Yup.string() });

export const userIdValidator = {
  params: UserIdSchema,
};

export const activationEmailValidator = {
  params: UserIdSchema,
  query: ClientIdSchema,
};
