import { DeleteOutlined } from "@ant-design/icons";
import clsx from "clsx";
import React, { useState } from "react";

import { Button } from "../../../components/Button/Button";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { Body3 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useDeleteOnboardingForm } from "../../../hooks/onboardingForms";
import { ResponseError } from "../../../hooks/query";

import * as styles from "./modal.module.less";

import type { ClientId } from "shared/types/Client";
import type { OnboardingFormId } from "shared/types/OnboardingForm";

type DeleteOnboardingFormModalProps = {
  onSuccess: () => unknown;
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  onClick: () => void;
  track: (buttonLabel: string) => void;
};

export const DeleteOnboardingFormModal: React.FC<DeleteOnboardingFormModalProps> = ({
  clientId,
  onboardingFormId,
  onSuccess,
  onClick,
  track,
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { mutateAsync: deleteOnboardingForm, isPending } = useDeleteOnboardingForm(
    clientId,
    onboardingFormId,
  );

  const assignButtonClickAction = () => {
    setModalVisible(true);
    onClick();
    track("Delete");
  };

  const deleteAction = async () => {
    try {
      await deleteOnboardingForm({});
      void slobMessage.success("Successfully deleted the form");
      onSuccess();
      setModalVisible(false);
    } catch (error) {
      const errorMsg = ResponseError.getUserFacingErrorMessage(
        error,
        "An error trying to delete the form",
      );
      setErrorMsg(errorMsg);
    }
  };

  const cancelAction = () => {
    setModalVisible(false);
    track("Cancel Delete DocuSign Envelope");
  };

  return (
    <>
      <Button type="text" onClick={assignButtonClickAction}>
        <div className={clsx(["stack-x-12", styles.popoverLinkRow, "redMedium"])}>
          <DeleteOutlined />
          <Body3 redMedium>Delete</Body3>
        </div>
      </Button>
      <ConfirmDialog
        title="Delete DocuSign Envelope"
        isVisible={isModalVisible}
        onCancel={cancelAction}
        onConfirm={deleteAction}
        confirmActionText="Delete"
        cancelActionText="Cancel"
        isLoading={isPending}
      >
        <Body3 as="p">Are you sure you want to delete this DocuSign envelope?</Body3>
        {errorMsg && (
          <Body3 redMedium as="p">
            {errorMsg}
          </Body3>
        )}
      </ConfirmDialog>
    </>
  );
};
