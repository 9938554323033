import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";

import { assertIsDefined } from "shared/utils/utils";
import { PlanTypeData } from "../../../../../../shared/data/ExplorerBenefit";
import { Badge } from "../../../../components/Badge/Badge";
import { Body1, H1, H2 } from "../../../../components/Typography/Typography";
import { useEmployeeLocation } from "../../../../hooks/useSlobLocalStorage";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey, copyKeySwitch } from "../../utils";

import * as styles from "./benefit.module.less";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

import type { ContentFlag, ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  HowToUsePlan: "benefit-how-to-use-plan",
};

type StepCopyStrucutre = { header: string; description: string; "AD&D"?: string };
export type ExplorerBenefitHowToUsePlanCopyStructure = {
  header_one: string;
  header_other: string;
  pillStatus: string;
  DHMO_TX: string;
  DHMO_FL: string;
} & {
  [key in Exclude<BenefitTypeBenEx, "FINANCIAL" | "OTHER">]: {
    [key in PlanType]?: StepCopyStrucutre[];
  } & {
    default: StepCopyStrucutre[];
  };
};

type ExplorerBenefitHowToUsePlanProps = {
  benefitType: BenefitTypeBenEx;
  carrierId: number | null;
  contentFlags?: ContentFlag[];
  plans: ExplorerPageBenefit[];
};

export const ExplorerBenefitHowToUsePlan = ({
  benefitType,
  contentFlags = [],
  plans,
}: ExplorerBenefitHowToUsePlanProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitHowToUsePlan" });
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();
  assertIsDefined(plans[0], "plans[0]");

  const condensedPlanTypes =
    benefitType === "DENTAL"
      ? plans.reduce<PlanType[]>((acc, { planType }) => {
          const adjustedType = planType === "ASO_PPO" ? "PPO" : planType;
          if (adjustedType && !acc.includes(adjustedType)) acc.push(adjustedType);
          return acc;
        }, [])
      : [plans[0].planType ?? "OTHER"];

  const includesADD = contentFlags.includes("AD&D");

  const breakpoint = useBreakpoint();

  if (!condensedPlanTypes.length) return null;

  return (
    <div data-testid={DataTestId.HowToUsePlan}>
      {condensedPlanTypes.map((planType, i) => {
        const key = copyKeySwitch(t, benefitType, planType);

        const steps = t(copyKey(benefitType, key), {
          returnObjects: true,
          defaultValue: [],
        });

        if (!steps || !Array.isArray(steps)) {
          return null;
        }

        const stepCount = steps.length;

        return (
          <div key={`how-to-${benefitType}-${key}-${i}`} className={i > 0 ? "mt-40" : undefined}>
            <H2>
              {t("header", {
                count: condensedPlanTypes.length,
                planType: t(`${planType}_${employeePrepaidAndChatLocation}`, {
                  defaultValue: PlanTypeData[planType].displayName,
                }),
              })}
            </H2>
            {breakpoint.md ? (
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <div className={styles.columnContainer}>
                    {[...Array(stepCount)].map((_, index) => (
                      <div
                        className={styles.hrBorder}
                        style={{ width: `calc(96%/${stepCount})` }}
                        key={`how-to-${benefitType}-step-${index}`}
                      >
                        <Body1>
                          <div>
                            <div className="mb-12">
                              <Badge
                                srOnlyLabel="Status"
                                status={t("pillStatus", { step: index + 1 }) ?? undefined}
                              />
                            </div>
                            <H1>
                              <Trans
                                t={t}
                                i18nKey={copyKey(benefitType, key, index.toString(), "header")}
                              />
                            </H1>
                          </div>
                        </Body1>
                      </div>
                    ))}
                  </div>
                  <div className={clsx(styles.columnContainer, "mt-24")}>
                    {[...Array(stepCount)].map((_, index) => (
                      <div
                        style={{ width: `calc(96%/${stepCount})` }}
                        key={`how-to-${benefitType}-step-${index}`}
                      >
                        <Body1>
                          <Trans
                            t={t}
                            i18nKey={copyKey(benefitType, key, index.toString(), "description")}
                            values={{
                              add: includesADD
                                ? t(copyKey(benefitType, key, index.toString(), "AD&D"))
                                : undefined,
                            }}
                          >
                            <span style={{ fontWeight: 500 }}></span>
                          </Trans>
                        </Body1>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row gutter={[24, 24]}>
                {[...Array(stepCount)].map((_, index) => (
                  <Col xs={{ span: 24 }} md={{ span: 24 / stepCount }} key={String(index)}>
                    <Body1>
                      <div>
                        <div className="mb-12">
                          <Badge
                            srOnlyLabel="Status"
                            status={t("pillStatus", { step: index + 1 }) ?? undefined}
                          />
                        </div>
                        <H1>
                          <Trans
                            t={t}
                            i18nKey={copyKey(benefitType, key, index.toString(), "header")}
                          />
                        </H1>
                      </div>
                      <hr />

                      <Trans
                        t={t}
                        i18nKey={copyKey(benefitType, key, index.toString(), "description")}
                        values={{
                          add: includesADD
                            ? t(copyKey(benefitType, key, index.toString(), "AD&D"))
                            : undefined,
                        }}
                      >
                        <span style={{ fontWeight: 500 }}></span>
                      </Trans>
                    </Body1>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        );
      })}
    </div>
  );
};
