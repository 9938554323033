import { Button } from "client/src/components/Button/Button";
import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import type { Policy } from "shared/types/Client";

type Props = {
  policy: Policy;
};

export function BenefitsInThisPolicy(props: Props) {
  const { policy } = props;

  return (
    <SlobPopover
      id={policy.id}
      placement="bottom"
      trigger={["click"]}
      content={
        policy.slfCoverages == null || policy.slfCoverages.length === 0 ? (
          <Body3>No coverages in this policy</Body3>
        ) : (
          <StackY dist={8}>
            {policy.slfCoverages?.map((cov) => (
              <Body3 key={cov}>{cov}</Body3>
            ))}
          </StackY>
        )
      }
      variant="secondary"
    >
      <Button type="text-only">Benefits in this policy</Button>
    </SlobPopover>
  );
}
