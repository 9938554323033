import { getEmployeeClassPlansByBenefitType } from "client/src/domain/EIF/common/utils/getEmployeeClassPlansByBenefitType";
import {
  getIsBenefitSupportedInClassBuilder,
  getIsDHMOBenefitType,
} from "shared/types/BenefitTypes";
import {
  benefitTypeToCoverage,
  coverageToBenefitType,
  type SlfCoverageLongName,
} from "shared/types/SlfCoverages";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";

export function getCoveragesInClientNotYetInAnyClass(
  allPoliciesSlfCoverages: SlfCoverageLongName[] | null,
  classes: EmployeeClass[],
  clientPlans: Plan[],
) {
  const classCoverages = new Set(
    classes.flatMap((empClass) => Object.keys(getEmployeeClassPlansByBenefitType(empClass)) || []),
  );
  // DHMO breaks the pattern of other benefit types because there is only one DHMO designation
  // within slfCoverages, but there can then be many state DHMO plans at the Plan level
  // so we need to account for these different state level plans in this logic.
  const DHMOCoverages = clientPlans
    .map((plan) => plan.benefitType)
    .filter((benefitType) => getIsDHMOBenefitType(benefitType))
    .map((bt) => benefitTypeToCoverage[bt]);
  const slfCoveragesPlusDHMO = [...(allPoliciesSlfCoverages || []), ...DHMOCoverages];
  const coveragesInClientNotAssociatedToClass = slfCoveragesPlusDHMO
    .filter((c) => getIsBenefitSupportedInClassBuilder(coverageToBenefitType[c], clientPlans))
    .filter((c) => !classCoverages.has(coverageToBenefitType[c]));

  return coveragesInClientNotAssociatedToClass;
}
