import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { RouteData } from "../../../shared/config/routeData";
import { getIsBenAdmin } from "../../../shared/rbac/rbac";
import { Anchor } from "../components/Anchor/Anchor";
import { Button } from "../components/Button/Button";
import { GenericErrorCopy2 } from "../components/Error/ErrorMessage";
import { LoadingError } from "../components/Error/LoadingError";
import { Checkbox } from "../components/Form/Checkbox";
import { HubCard } from "../components/HubCard/HubCard";
import * as activationCardStyles from "../components/HubCard/activationCard.module.less";
import { Loading } from "../components/Loading/Loading";
import { StackY } from "../components/Spacing/Spacing";
import { H2, H3, H5, Body2 } from "../components/Typography/Typography";
import { useSlobAuth } from "../hooks/auth";
import { ResponseError } from "../hooks/query";
import { useTitle } from "../hooks/useTitle";
import { useSetTermsOfUse } from "../hooks/user";
import { useTrackElementClicked } from "../utils/analytics";

import * as styles from "./termsOfUse.module.less";

export const TermsOfUse = () => {
  useTitle("Terms of Use");
  const { authUser, isLoading: isLoadingAuth } = useSlobAuth();
  const { isPending: isPendingTou, mutateAsync: setTou } = useSetTermsOfUse();
  const [isTouChecked, setIsTouChecked] = useState(false);
  const [isEcChecked, setIsEcChecked] = useState(false);
  const trackElementClicked = useTrackElementClicked();
  const [requestError, setRequestError] = useState<JSX.Element | string | undefined>();

  const navigate = useNavigate();

  if (isLoadingAuth) {
    return <Loading />;
  }

  if (authUser?.termsOfUseDate) {
    return <Navigate to={RouteData.index.getPathTemplate()} replace />;
  }

  const requireEc = getIsBenAdmin(authUser);
  const allowToContinue = isTouChecked && (!requireEc || isEcChecked);

  const handleSubmitClick = async () => {
    setRequestError("");
    trackElementClicked({ module: "Terms of Use", buttonLabel: "Accept and Continue" });
    if (allowToContinue) {
      try {
        await setTou({});
        navigate(RouteData.index.getPathTemplate());
        // Fix for https://maxwellhealth.atlassian.net/browse/FP-2691
        return (window.location.href = RouteData.index.getPathTemplate());
      } catch (error) {
        if (ResponseError.isResponseError(error) && error.status === 409) {
          navigate(RouteData.index.getPathTemplate());
          return (window.location.href = RouteData.index.getPathTemplate());
        }
        const message = ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2);
        setRequestError(message);
      }
    } else {
      setRequestError(`Unexpected user type`);
    }
  };

  return (
    <div className={activationCardStyles.background}>
      <HubCard>
        <H2 as="h1">Terms of Use</H2>
        <StackY dist={24}>
          <p className="mb-0">
            To create your Sun Life Onboard account, you'll need to agree to the{" "}
            <Anchor
              href="https://www.sunlife.com/sl/pslf-united-states/en/legal/?vgnLocale=en_CA#conditions"
              target="_blank"
              newTabIcon
              onClick={() =>
                trackElementClicked({
                  module: "Terms of Use",
                  buttonLabel: "Terms of Use redirect page",
                })
              }
            >
              Terms of Use
            </Anchor>
            .
          </p>
          {requireEc ? (
            <>
              <H2>Electronic Consent and Disclosure</H2>
              <p>
                In addition when you create an account, you'll also need to review and agree to the
                following Electronic Consent and Disclosure:
              </p>
              <div className={styles.electronicConsentCard}>
                <H3 style={{ textAlign: "center" }}>
                  Client and Consumer Electronic Consent and Disclosure
                </H3>
                <H5 as="p" style={{ textAlign: "center" }}>
                  IMPORTANT NOTICE - PLEASE READ CAREFULLY AND RETAIN A COPY FOR FUTURE REFERENCE
                </H5>
                <p>
                  This Client and Consumer Electronic Consent and Disclosure ("Consent") applies to
                  the following members of the Sun Life Financial group of companies:
                </p>
                <p>
                  Sun Life Assurance Company of Canada, Sun Life and Health Insurance Company
                  (U.S.), Denticare of Alabama, Inc., United Dental Care of Arizona, Inc., UDC
                  Dental California, Inc., United Dental Care of Colorado, Inc., Union Security
                  DentalCare of Georgia, Inc., United Dental Care of Michigan, Inc., United Dental
                  Care of Missouri, Inc., Union Security DentalCare of New Jersey, Inc., United
                  Dental Care of New Mexico, Inc., UDC Ohio, Inc., United Dental Care of Texas,
                  Inc., and United Dental Care of Utah, Inc.
                  <br />
                  (collectively the "Company" "we" or "us").
                </p>
                <p>
                  The Company provides you with the option without charge to receive in electronic
                  format information relating to your application for, and administration of, your
                  group insurance policy and coverage(s) that would otherwise be sent to you in
                  paper form by mail or otherwise. To receive information electronically, you must
                  provide the Company with your agreement to the following terms and conditions. If
                  you click the "Agree" checkbox, you will be providing your consent to: (i) have
                  the information described in Consent delivered to you electronically, (ii) execute
                  electronically the documents that are described in this Consent, and (iii) all of
                  the terms and conditions set forth below.
                </p>
                <p>
                  If you do <Body2>not</Body2> want to have the information described in this
                  Consent delivered to you in electronic format, if you do not want to execute
                  electronically the documents described in this Consent, or if you do not agree
                  with all of the terms and conditions of this Consent, you should not check the
                  "Agree" checkbox. Please read the following terms and conditions carefully.
                </p>
                <H5 as="h4">Scope and Duration of this Consent</H5>
                <p>
                  By clicking the "Agree" checkbox you agree to all of the terms and conditions of
                  this Consent, including your agreement to receive the following communication
                  ("Communications") electronically:
                </p>
                <p>
                  (a)documents and information related to your group insurance policy and
                  coverage(s) including, but not limited to, enrollment form, application form,
                  Authorization for Release and Disclosure of Health Related Information,
                  declarations page, policy documents, certificate, claim form, claim adjudication,
                  explanation of benefits, membership, billing, evidence of insurability, premium
                  payment, notices, and correspondence, and
                </p>
                <p>
                  (b)information that the Company is required by law to provide or make available to
                  you in writing.
                </p>
                <p>
                  You also agree to complete and execute electronically certain of the
                  Communications that the Company permits you to submit electronically to the
                  Company.
                </p>
                <p>
                  Even though you have provided the Company with this Consent, the Company may at
                  its option: (a) deliver Communications to you on paper and (b) require that
                  certain Communications and other information from you be delivered to the Company
                  on paper.
                </p>
                <H5 as="h4">Requesting Copies</H5>
                <p>
                  You may obtain paper copies of Communications at any time and without charge by
                  contacting us in a manner provided below (or such other manner as may be permitted
                  in the future).
                </p>
                <H5 as="h4">Maintaining Copies of Communications</H5>
                <p>
                  You agree to print or save this Consent and all Communications, and to keep
                  printed or electronic copies for your records. If you have any trouble with
                  printing or saving, you should contact the Company.
                </p>
                <H5 as="h4">Withdrawal of Your Consent/Termination of this Consent</H5>
                <p>
                  This Consent will become effective when you click the "Agree" checkbox and will
                  remain in effect unless terminated by the Company in its sole discretion or until
                  you withdraw your consent (as described below), whichever comes first.
                </p>
                <p>
                  If at any time you would like to terminate this Consent and cease receiving
                  Communications in electronic format, you must notify the Company in writing at the
                  below address or telephone number. Your withdrawal of your consent to receive
                  Communications in electronic format will be made with respect to all
                  Communications and will not be made with respect to only some Communications. Your
                  withdrawal of your consent to receive Communications in electronic format will
                  become effective only after the Company has a reasonable period of time to process
                  your withdrawal. Thereafter, all Communications will be provided to you on paper
                  by mail.
                </p>
                <p>
                  The Company may, at its option, treat your providing us with an invalid e-mail
                  address, or the subsequent malfunction of a previously valid electronic address,
                  as a withdrawal and termination of your consent to receive Communications in
                  electronic format.
                </p>
                <p>
                  The Company reserves the right, in its sole discretion, to discontinue providing
                  Communications in electronic format, or to terminate or change the terms and
                  conditions on which the Company provides electronic Communications. We will
                  provide you with notice of any such termination or change as required by law.
                </p>
                <H5 as="h4">Changes to Your Contact Information</H5>
                <p>
                  It is your responsibility to promptly notify the Company of any changes to your
                  e-mail address and all other contact information.
                </p>
                <p>
                  You may inform us of any such changes by contacting us at the telephone number or
                  address provided below (or such other address as may be provided to you in the
                  future). Such change will become effective only after we have a reasonable period
                  of time to process the change. Please do not send confidential or personal
                  information to us via email.
                </p>
                <p>
                  You may also verify your email address on record with the Company or make changes
                  to your email address by logging in to your account and selecting the My Profile
                  tab located in the upper-left hand corner.
                </p>
                <H5 as="h4">Contacting the Company</H5>
                <address>
                  <Body2>Mail:</Body2>
                  <p className="mb-0">One Sun Life Park</p>
                  <p>Wellesley Hills, MA 02481</p>
                  <Body2>Telephone:</Body2>
                  <p>(800) 247-6875</p>
                  <Body2>Email:</Body2>
                  <p>myclaimsdocuments@sunlife.com</p>
                </address>
                <H5 as="h4">Systems Requirements</H5>
                <p>
                  To access, view and retain the Communications sent or made available to you
                  electronically by the Company you must have access to (i) an up-to-date device
                  (e.g., computer, smart phone, tablet, etc.) suitable for connecting to the
                  Internet; (ii) an up-to-date Internet browser; (iii) a valid e-mail account and
                  software to access it; (iv) added the domain @sunlife.com to your e-mail account's
                  list of "safe senders;" (v) electronic storage capacity to retain Our
                  Communication(s) and/or a printer; and (vi) software that enables you to view
                  files in Portable Document Format. You may be able to download the most recent
                  version of Adobe Reader by{" "}
                  <ButtonOld
                    type="link-inline"
                    size="middle"
                    href="https://get.adobe.com/reader/"
                    target="blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackElementClicked({
                        module: "Terms of Use",
                        buttonLabel: "Get Adobe Reader",
                      })
                    }
                  >
                    clicking here
                  </ButtonOld>
                  . If you cannot download the most recent version of Adobe Reader, please call your
                  manufacturer to find out how to download software that is functionally equivalent.
                </p>
                <p>
                  Please contact the Company at the telephone number above if you have any trouble
                  with printing or saving your Communications.
                </p>
                <p>PRINT OR SAVE A COPY OF THIS CONSENT AND DISCLOSURE NOW FOR FUTURE REFERENCE</p>
                <Body2 as="p">
                  I have CAREFULLY read this Consent and accept its provisions voluntarily and with
                  full knowledge and understanding of its terms and conditions. I have computer
                  hardware and software that meets the minimum hardware and software requirements
                  described above. I have successfully printed or saved a copy of this Consent and
                  Disclosure.
                </Body2>
                <Body2 as="p">
                  By clicking the "Agree" checkbox, I agree to receive electronic Communications in
                  accordance with the terms described above.
                </Body2>
              </div>
            </>
          ) : null}
          <Checkbox
            checked={isTouChecked}
            name="termsCheckbox"
            label="I agree to the terms and conditions of the Terms of Use"
            onChange={(e) => setIsTouChecked(e.target.checked)}
          />
        </StackY>
        <StackY dist={16} className={requireEc ? undefined : "mt-32"}>
          {requireEc ? (
            <Checkbox
              checked={isEcChecked}
              name="ecCheckbox"
              label="I agree to the terms and conditions of the Electronic Consent and Disclosure"
              onChange={(e) => setIsEcChecked(e.target.checked)}
            />
          ) : null}
          {requestError && <LoadingError type="component" title={requestError} />}
          <Button
            loading={isPendingTou}
            disabled={!allowToContinue}
            type="primary"
            onClick={handleSubmitClick}
            size="large"
          >
            Accept and Continue
          </Button>
        </StackY>
      </HubCard>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default TermsOfUse;
