import { EIFInternalEditBanner } from "client/src/domain/EIF/common/EIFInternalEditBanner";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PrintHidden, PrintOnly } from "client/src/utils/print";
import { useNavigate } from "react-router-dom";
import { getIsInternalUser } from "shared/rbac/rbac";
import type { NavigateFunction } from "react-router-dom";
import type { UserData } from "shared/rbac/rbac";
import type { ChangeDetailInfo } from "shared/types/Change";
import type { Client } from "shared/types/Client";

export type ChangeDetailInfoList = (ChangeDetailInfo | null | undefined)[];

type EditBannerProps = {
  "data-testid"?: string;
  navigateLink?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  changeDetailInfoList: ChangeDetailInfoList;
  authUser: UserData | null;
  client: Client;
  isAdd?: boolean;
};

type EditBannerPresentationProps = EditBannerProps & {
  navigate: NavigateFunction;
};

export const EditBannerPresentation = ({
  "data-testid": dataTestId,
  navigateLink,
  onEdit,
  onDelete,
  changeDetailInfoList,
  authUser,
  client,
  navigate,
  isAdd,
}: EditBannerPresentationProps) => {
  const isInternalUser = getIsInternalUser(authUser);

  const editedFieldMsg = (
    <EditedFieldMsg
      changeDetailInfoList={changeDetailInfoList}
      withAlertBanner={true}
      data-testid={dataTestId}
      authUser={authUser}
      client={client}
      isAdd={isAdd}
    />
  );

  return isInternalUser ? (
    <>
      <PrintHidden>
        <EIFInternalEditBanner
          data-testid={dataTestId}
          navigateLink={navigateLink}
          onEdit={onEdit}
          onDelete={onDelete}
          changeDetailInfoList={changeDetailInfoList}
          navigate={navigate}
          authUser={authUser}
          client={client}
          isAdd={isAdd}
        />
      </PrintHidden>

      <PrintOnly>{editedFieldMsg}</PrintOnly>
    </>
  ) : (
    editedFieldMsg
  );
};

export const EditBanner = (args: EditBannerProps) => {
  const navigate = useNavigate();
  return <EditBannerPresentation {...args} navigate={navigate} />;
};
