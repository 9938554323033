import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Anchor } from "../../../components/Anchor/Anchor";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { OrderedList, OrderedListItem } from "../../../components/OrderedList/OrderedList";
import { StackY } from "../../../components/Spacing/Spacing";
import { Body5, H5 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { AddFilesCard } from "../../../domain/Document/AddFilesCard/AddFilesCard";
import { StoredSecurelyText } from "../../../domain/Document/StoredSecurelyText";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";

import type { Task } from "../../../../../shared/data/Tasks";
import type { Client, Policy } from "../../../../../shared/types/Client";

export type SunAdvisorSPDProps = {
  client: Client;
  task: Task;
  policy: Policy;
};

export function SunAdvisorSPD({ client, task, policy }: SunAdvisorSPDProps) {
  useTitle("Submit Summary Plan Description for SunAdvisor");
  const trackElementClicked = useTrackElementClicked(client, "SunAdvisor SPD");

  const hasSunAdvisorAdvicePlus =
    policy.slfCoverages?.includes("SunAdvisor (ASO Short Term Disability)") ?? false;

  const sunAdvisorAdvicePlusPreFundingAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(policy.sunAdvisorAdvicePlusPreFundingAmount || 0);

  const trackDownloadSunLifeSPD = () => {
    trackElementClicked({ buttonLabel: `Sun Life's NY SPD` });
  };

  const policyIncludesNYSitus = policy.issuedStateSitus?.includes("NY");

  const footerLinkContent = (track: (buttonLabel: string) => void) => {
    if (policyIncludesNYSitus) {
      return (
        <>
          {"or download our "}
          <Anchor
            href="/ASO SPD Template (NY).doc"
            onClick={() => {
              track("Download NY ASO SPD Template after initial upload");
            }}
          >
            Sun Life's NY SPD
          </Anchor>
        </>
      );
    } else {
      return (
        <>
          {"or download "}
          <Anchor
            href="/ASO SunAdvisor Master Plan Template.ERISA.FINAL (002).docx"
            onClick={() => {
              track("Download ASO ERISA SPD Template after initial upload");
            }}
          >
            Sun Life's ERISA SPD template
          </Anchor>{" "}
          or our{" "}
          <Anchor
            href="/ASO SunAdvisor Master Plan Template.NON_ERISA.FINAL (002).docx"
            onClick={() => {
              track("Download ASO Non-ERISA SPD Template after initial upload");
            }}
          >
            NON-ERISA SPD template
          </Anchor>
        </>
      );
    }
  };

  return (
    <ChildPageLayout
      onClickBack={() => trackElementClicked({ buttonLabel: "Back to Home" })}
      topNavTitle="Submit Summary Plan Description for SunAdvisor"
      aside={
        <>
          <h1>Submit Summary Plan Description for SunAdvisor</h1>

          <DueDateAlert task={task} />
          <br />

          <p>
            Send us your Summary Plan Description document for SunAdvisor - your Administrative
            Services Only (ASO) Short Term Disability (STD) benefit.
          </p>

          <p>
            After uploading, Sun Life will review your description document and reach out to you
            with any questions. If anything else is needed, your Implementation Consultant will work
            with you.
          </p>
        </>
      }
      contents={
        <>
          <StackY dist={40}>
            <div>
              <h2>What is a Summary Plan Description (SPD)?</h2>

              <p>
                An SPD is a document that tells your employees what the plan provides and how it
                operates. It’s very important so that we can accurately process SunAdvisor claims.
              </p>

              <p>
                Sun Life requires that you complete our SPD Template and submit it for your
                Implementation Consultant to review.
              </p>
              <AddFilesCard
                clientId={client.id}
                hideButton={task.status !== "Not Started"}
                readonly={task.status === "Done"}
                taskStatus={task.status}
                category="summary-plan-description"
                title={<H5 as="h3">Upload your SPD for SunAdvisor</H5>}
                singleFile
                remainingTasks={false}
                taskCompletedTitle="Your SPD file has been submitted!"
                message="Your Implementation Consultant will review it and contact you with any questions. You can continue to add more files if needed."
                actionRequiredMessage={null}
                description={
                  <>
                    {task.status === "Not Started" && (
                      <OrderedList>
                        {policyIncludesNYSitus ? (
                          <OrderedListItem>
                            Please download{" "}
                            <Anchor
                              href="/ASO SPD Template (NY).doc"
                              onClick={trackDownloadSunLifeSPD}
                            >
                              Sun Life's NY SPD
                            </Anchor>{" "}
                            template to get started.
                          </OrderedListItem>
                        ) : (
                          <OrderedListItem>
                            Please download{" "}
                            <Anchor href="/ASO SunAdvisor Master Plan Template.ERISA.FINAL (002).docx">
                              Sun Life's ERISA SPD template
                            </Anchor>
                            , or our{" "}
                            <Anchor href="/ASO SunAdvisor Master Plan Template.NON_ERISA.FINAL (002).docx">
                              NON-ERISA SPD template
                            </Anchor>
                            , to get started.
                          </OrderedListItem>
                        )}

                        <OrderedListItem>Add your information to the template.</OrderedListItem>

                        <OrderedListItem>
                          Upload the completed template to Sun Life Onboard for review.
                        </OrderedListItem>
                      </OrderedList>
                    )}
                  </>
                }
                footer={
                  task.status === "Done" || task.status === "In Review"
                    ? (toggleVisible, track) => (
                        <div className={`text-center`}>
                          <Body5 as="p">
                            Missed something?{" "}
                            <ButtonOld
                              type="link-inline"
                              size="small"
                              onClick={() => {
                                track("Add more files");
                                toggleVisible();
                              }}
                            >
                              Add more files
                            </ButtonOld>{" "}
                            {footerLinkContent(track)}
                          </Body5>

                          <StoredSecurelyText track={track} />
                        </div>
                      )
                    : undefined
                }
                trackElementClicked={trackElementClicked}
              />

              {hasSunAdvisorAdvicePlus && (
                <>
                  <h3 className="mt-24">For SunAdvisor AdvicePlus</h3>

                  <p>
                    Advice Plus requires a pre-funding amount to be received prior to any claim
                    payments.
                  </p>

                  <p>A check can be sent to:</p>

                  <address className="body3">
                    Sun Life of Canada <br />
                    P.O. Box 7247-0381 <br />
                    Philadelphia PA 19170-0381
                  </address>

                  <p>
                    The pre-funding amount is:{" "}
                    <strong>{sunAdvisorAdvicePlusPreFundingAmount}</strong>
                  </p>

                  <Body5 as="div">
                    Please indicate on the memo line of the check that this is an ASO pre-funding
                    payment for policy {policy.slfPolicyNumber || "-"} for {client.name}.
                  </Body5>
                </>
              )}
            </div>

            <div>
              <h3>Tips for completing this task</h3>

              <p>
                <strong>Be mindful of the due date</strong> <br />
                Providing your SunAdvisor SPD is important so that we can ensure that your plan
                designs are setup correctly as a part of your onboarding journey, and accurately
                process claims. A delay in completing this task may delay your benefits from being
                installed prior to your effective date.
              </p>

              <p>
                <strong>Use the customized template file provided for you above</strong> <br />
                It is best to send your SunAdvisor SPD to our team formatted into the customized
                template file provided to you above. Utilizing this format enables our team to
                quickly identify if we have any questions.
              </p>

              <h3>FAQs</h3>

              <p>
                <strong>What is SunAdvisor?</strong> <br />
                SunAdvisor is Sun LIfe’s Administrative Services Only (ASO) Short Term Disability
                (STD) offering.
              </p>
              <p>
                Contrary to a fully-insured Short Term Disability offering where the insurance
                company administers the plan and also funds the claims, SunAdvisor is an offering in
                which you are responsible for funding the claims and the insurance company is
                responsible for ensuring the plan is administered correctly, the plan rules are
                followed, and claims are approved for payment only when appropriate.
              </p>

              <p>
                <strong>What are SunAdvisor’s offerings?</strong> <br />
                There are three SunAdvisor offerings, each of which provides the employer an
                incremental level of additional support and advice from Sun Life. The SunAdvisor
                offerings are:
              </p>

              <UnorderedList>
                <UnorderedListItem>Full service Advice to Pay</UnorderedListItem>
                <UnorderedListItem>
                  Full Service Advice to Pay Benefit Calculation
                </UnorderedListItem>
                <UnorderedListItem>Advice Plus</UnorderedListItem>
              </UnorderedList>
            </div>
          </StackY>
        </>
      }
    />
  );
}
