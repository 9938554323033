import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Card } from "client/src/components/Cards/Card";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Body3, Body7, Body8 } from "client/src/components/Typography/Typography";
import { useTranslation } from "client/src/i18n";
import { useZendeskDepartmentStatus } from "client/src/utils/zendesk";

import { useState } from "react";
import styles from "./contactUs.module.less";

export function ContactUs() {
  const { t } = useTranslation({ keyPrefix: "ExplorerContactUs" });
  const [open, setOpen] = useState(false);
  const zendeskStatus = useZendeskDepartmentStatus();

  if (zendeskStatus === "online") return null;

  return (
    <>
      {open ? (
        <div className={styles.card}>
          <Card padding="none">
            <div className={styles.heading}>
              <Row justify="space-between">
                <Col />
                <Col>
                  <Body3>{t("how_to_contact_us")}</Body3>
                </Col>
                <Col>
                  <Button type="text-only" onClick={() => setOpen(false)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </Button>
                </Col>
              </Row>
            </div>
            <div className={styles.content}>
              <Body8>{t("we_are_here_to_help")}</Body8>
              <ol>
                <li>
                  <Button
                    type="text-only-light"
                    href="mailto:support@benefitsexplorer.zendesk.com"
                    newTab
                  >
                    {t("send_message_by_email")}
                  </Button>
                </li>
                <li>
                  <Button type="text-only-light" href="https://calendly.com/slfappointment" newTab>
                    {t("schedule_appointment")}
                  </Button>
                </li>
                <li>
                  <Body7>{t("chat_with_counselor")}</Body7>
                </li>
              </ol>
            </div>
          </Card>
        </div>
      ) : (
        <Button type="primary" size="middle" shrink rounded onClick={() => setOpen(true)}>
          {t("contact_us")}
        </Button>
      )}
    </>
  );
}
