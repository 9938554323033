import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";

import { ReactComponent as WellnessIcon } from "../../../../components/Icons/WellnessIcon.svg";
import { Body1, Body2, H3 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey } from "../../utils";

import * as styles from "./benefit.module.less";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  Wellness: "wellness",
};

export type ExplorerBenefitWellnessCopyStructure = {
  [benefitType in Extract<
    BenefitTypeBenEx,
    "CANCER" | "HOSPITAL_INDEMNITY" | "CRITICAL_ILLNESS" | "ACCIDENT"
  >]: {
    header: string;
    description: string;
    showTotal: boolean;
    familyMembers: number;
    amountCaption: string;
    familyMembersCaption: string;
    totalAmountCaption: string;
  };
};

type ExplorerBenefitWellnessProps = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
};

export const ExplorerBenefitWellness = ({ plans, benefitType }: ExplorerBenefitWellnessProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitWellness" });
  const screens = useBreakpoint();

  const wellnessAmount = plans.reduce<number | null>((amt, val) => {
    if (val.wellnessAmount !== null && val.wellnessAmount > 0) {
      return val.wellnessAmount;
    }
    return null;
  }, null);

  if (wellnessAmount === null) {
    return null;
  }

  const totalAmount: number = t(copyKey(benefitType, "showTotal"))
    ? +t(copyKey(benefitType, "familyMembers")) * wellnessAmount
    : 0;

  return (
    <div data-testid={DataTestId.Wellness} className={styles.wellness}>
      <div className={styles.imageContainer}>
        <WellnessIcon className={styles.icon} />
      </div>
      <Row justify="center" align="stretch" className="m-24" style={{ textAlign: "center" }}>
        <Col span={24} md={20}>
          <H3>
            <strong>
              <Trans
                t={t}
                i18nKey={copyKey(benefitType, "header")}
                values={{ amount: `$${wellnessAmount}` }}
              ></Trans>
            </strong>
          </H3>
          <Trans
            t={t}
            i18nKey={copyKey(benefitType, "description")}
            values={{ amount: `$${wellnessAmount}` }}
          >
            <p>
              <Body1>first paragraph</Body1>
            </p>
            <p>
              <Body2>second paragraph</Body2>
              <Body1>third paragaph</Body1>
            </p>
          </Trans>

          {t(copyKey(benefitType, "showTotal")) && !screens.xs && (
            <>
              <Row justify="center" className={styles.totalAmount} gutter={10}>
                <Col span="4">${wellnessAmount}</Col>
                <Col span="2">X</Col>
                <Col span="2">{t(copyKey(benefitType, "familyMembers"))}</Col>
                <Col span="2">=</Col>
                <Col span="4">${totalAmount}</Col>
              </Row>
              <Row justify="center" className={styles.captions}>
                <Col span="4">{t(copyKey(benefitType, "amountCaption"))}</Col>
                <Col span="2">&nbsp;</Col>
                <Col span="2">{t(copyKey(benefitType, "familyMembersCaption"))}</Col>
                <Col span="2">&nbsp;</Col>
                <Col span="4">{t(copyKey(benefitType, "totalAmountCaption"))}</Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
