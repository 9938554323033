import { ColorSwatch } from "client/src/components/ColorSwatch/ColorSwatch";
import { FormInput } from "client/src/components/Form/Input";
import { Col, Row } from "client/src/components/Grid/Grid";
import * as styles from "./ColorPicker.module.less";
import type { InputProps } from "client/src/components/Form/Input";

type ColorPickerProps = Omit<InputProps, "maxLength"> & {
  label: NonNullable<React.ReactNode>;
};

export function ColorPicker(props: ColorPickerProps) {
  const { topText, ...inputProps } = props;

  return (
    <Col>
      <Row>
        <span className={`body2 ${styles.topText}`}>{topText}</span>
      </Row>
      <Row gutter={8}>
        <Col xs={18}>
          <FormInput {...inputProps} maxLength={7} />
        </Col>
        <Col xs={6}>
          <ColorSwatch color={inputProps.value} />
        </Col>
      </Row>
    </Col>
  );
}
