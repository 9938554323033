import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSummaryDescriptionPlanPFMLLinks } from "client/src/pages/TaskDetail/SummaryPlanDescriptionForPFML/SummaryPlanDescriptionforPFML";
import { RouteData } from "../../../../../shared/config/routeData";
import { listFormat } from "../../../../../shared/utils/format";
import { Anchor } from "../../../components/Anchor/Anchor";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { SlobLink } from "../../../components/SlobLink/SlobLink";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { useTitle } from "../../../hooks/useTitle";
import { useToggler } from "../../../hooks/useToggler";
import { useTrackElementClicked } from "../../../utils/analytics";

import { PFMLUploadCard } from "./PFMLUploadCard";
import * as styles from "./SubmitPlanExemptionDocumentsForPFML.module.less";

import type { PolicyTask } from "../../../../../shared/data/Tasks";
import type { Client } from "../../../../../shared/types/Client";

export type SubmitPlanExemptionDocumentsForPFMLProps = {
  client: Client;
  task: PolicyTask;
};

export function SubmitPlanExemptionDocumentsForPFML({
  client,
  task,
}: SubmitPlanExemptionDocumentsForPFMLProps) {
  useTitle("Submit Plan Exemption Documents for PFML");

  const trackElementClicked = useTrackElementClicked(client, "PFML Plan Exemption Document");

  const [connecticutVisible, toggleConnecticutVisible] = useToggler();
  const [massachusettsVisible, toggleMassachusettsVisible] = useToggler();
  const [washingtonVisible, toggleWashingtonVisible] = useToggler();
  const [coloradoVisible, toggleColoradoVisible] = useToggler();
  const [oregonVisible, toggleOregonVisible] = useToggler();

  const { id: clientId, allPoliciesSlfCoverages } = client;

  const slfCoveragesSet = new Set(allPoliciesSlfCoverages);

  const ctASO = slfCoveragesSet.has("ASO CT PFML");
  const ctFullyInsured = slfCoveragesSet.has("CT PFML Fully Ins");
  const showConnecticut = ctASO || ctFullyInsured;

  const maASO = slfCoveragesSet.has("ASO MA PFML");
  const maFullyInsured = slfCoveragesSet.has("MA PFML Fully Ins");
  const showMassachusetts = maASO || maFullyInsured;

  // Washington only has ASO not Fully Insured for PFML
  const showWashington = slfCoveragesSet.has("ASO WA PFML");

  const coASO = slfCoveragesSet.has("ASO CO PFML");
  const coFullyInsured = slfCoveragesSet.has("CO PFML Fully Ins");
  const showColorado = coASO || coFullyInsured;

  const orASO = slfCoveragesSet.has("ASO OR PFML");
  const orFullyInsured = slfCoveragesSet.has("OR PFML Fully Ins");
  const showOregon = orASO || orFullyInsured;

  const listStates = [];
  if (showColorado) listStates.push("Colorado");
  if (showConnecticut) listStates.push("Connecticut");
  if (showMassachusetts) listStates.push("Massachusetts");
  if (showOregon) listStates.push("Oregon");
  if (showWashington) listStates.push("Washington");

  const listStatesFormatted = listFormat(listStates);

  const listStatesForSPDDocument = [];
  if (slfCoveragesSet.has("ASO CO PFML")) listStatesForSPDDocument.push("Colorado");
  if (slfCoveragesSet.has("ASO CT PFML")) listStatesForSPDDocument.push("Connecticut");
  if (slfCoveragesSet.has("ASO MA PFML")) listStatesForSPDDocument.push("Massachusetts");
  if (slfCoveragesSet.has("ASO OR PFML")) listStatesForSPDDocument.push("Oregon");
  if (slfCoveragesSet.has("ASO WA PFML")) listStatesForSPDDocument.push("Washington");

  const listStatesForSPDDocumentFormatted = listFormat(listStatesForSPDDocument);
  const showSummaryPlanDescriptionDocument = Boolean(listStatesForSPDDocument.length);

  const trackAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    trackElementClicked({
      buttonLabel: event.currentTarget.text,
    });
  };

  return (
    <ChildPageLayout
      topNavTitle="Submit Plan Exemption Documents for PFML"
      onClickBack={() => trackElementClicked({ buttonLabel: "Back to Home" })}
      aside={
        <>
          <h1>Submit Plan Exemption Documents for PFML</h1>

          <DueDateAlert task={task} />
          <br />

          <p>
            Provide us your Private Plan Exemption Documents for all states you'll be offering a
            Paid Family Medical Leave Plan.
          </p>

          <p>
            After uploading, Sun Life will review your documents and reach out to you with any
            questions. Make sure approvals for all states are uploaded. If anything else is needed,
            your Implementation Consultant will work with you on how to re-submit.
          </p>
        </>
      }
      contents={
        <>
          <h2>What is a Private Plan Exemption Document?</h2>

          <p>
            A Private Plan Exemption Document is a formal approval that allows you to opt out of a
            State-administered Paid Family and Medical Leave (PFML) and offer your own, private PFML
            plan. This document must be submitted to Sun Life as part of the onboarding process.
          </p>

          <p>
            Once you have applied for and received an approved exemption from your respective
            state(s), you must submit your plan exemption to Sun Life. See the helpful tips below
            for more information about obtaining an approval from your respective state(s).
          </p>

          <div className="mb-40">
            <PFMLUploadCard
              client={client}
              task={task}
              stateList={listStatesFormatted}
              trackElementClicked={trackElementClicked}
            />
          </div>

          <h3>Tips for completing this task</h3>

          <p>
            <strong>
              Don't forget to get your Private Plan Exemption Document approved by{" "}
              {listStatesFormatted}.
            </strong>
          </p>

          <p>
            With Paid Family and Medical Leave in {listStatesFormatted}, we will need a copy of your
            Private Plan Exemption Approval to ensure that your exemption has been approved.
          </p>

          {showColorado && (
            <>
              <p>
                <Toggler
                  visible={coloradoVisible}
                  onClick={toggleColoradoVisible}
                  ariaControls="Colorado"
                >
                  In Colorado
                </Toggler>
              </p>

              {coloradoVisible && (
                <div id="Colorado">
                  <p>
                    With Colorado Paid Family and Medical Leave, we will need a copy of your Private
                    Plan Approval to ensure that your exemption has been approved. If you have not
                    done so already, apply to Colorado via the{" "}
                    <Anchor
                      href="https://cpfmlgcp.my.site.com/s/login/"
                      target="_blank"
                      onClick={trackAnchorClick}
                    >
                      MyFAMLI+ Employer
                    </Anchor>{" "}
                    portal.{" "}
                  </p>
                  {coFullyInsured && (
                    <p>
                      Please review our{" "}
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/9a7cec8d-6053-4c6b-b247-b05e015f4fc0/completing-your-co-pfml-private-plan-application-original-file.pdf"
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        Completing your CO PFML Private Plan Application
                      </Anchor>{" "}
                      guide for application instructions and links to important documentation to
                      obtain your fully insured Colorado PFML plan approval.
                    </p>
                  )}
                  {coASO && (
                    <p>
                      You will need to submit your{" "}
                      <Anchor
                        href={getSummaryDescriptionPlanPFMLLinks["colorado"]}
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        CO PFML Summary Plan Description
                      </Anchor>{" "}
                      to obtain your employer administered Colorado PFML plan approval.
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {showConnecticut && (
            <>
              <p>
                <Toggler
                  visible={connecticutVisible}
                  onClick={toggleConnecticutVisible}
                  ariaControls="Connecticut"
                >
                  In Connecticut
                </Toggler>
              </p>

              {connecticutVisible && (
                <div id="Connecticut">
                  <p>
                    To qualify for a private plan with Connecticut Paid Family and Medical Leave,
                    you must be:{" "}
                  </p>

                  <UnorderedList>
                    <UnorderedListItem>
                      Approved through a majority vote held for all Connecticut employees.
                    </UnorderedListItem>
                    <UnorderedListItem>
                      Approved by CT Paid Leave Authority for a private plan.
                    </UnorderedListItem>
                  </UnorderedList>

                  <p>
                    We will need a copy of your Private Plan Approval to ensure that your exemption
                    has been approved. If you have not done so already, apply via the{" "}
                    <Anchor
                      href="https://ctpaidleave.org/s/i-want-to-apply-for-exemption?language=en_US"
                      target="_blank"
                      onClick={trackAnchorClick}
                    >
                      Connecticut Paid Leave Authority
                    </Anchor>{" "}
                    portal.
                  </p>
                  {ctFullyInsured && (
                    <p>
                      You will also need to submit the{" "}
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/04ac1deb-0428-40fe-ae33-b03000d5ce66/ctpfml-sample-policy-original-file.pdf"
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        CT PFML Sample Policy
                      </Anchor>{" "}
                      and{" "}
                      <Anchor
                        href={
                          "https://p1.aprimocdn.net/sunlife/e947e8e1-08ad-44ff-8e1b-b03000d889ae/ctpfml-plain-language-guide-original-file.docx"
                        }
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        CT PFML Plain Language Guide
                      </Anchor>{" "}
                      to obtain your fully insured Connecticut PFML plan approval.
                    </p>
                  )}
                  {ctASO && (
                    <p>
                      You will also be required to submit your{" "}
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/0395d548-359d-471a-86f6-b06600e6f795/pfmltp-1899-ct-pfml-aso-spd-template-original-file.doc"
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        CT PFML Summary Plan Description
                      </Anchor>{" "}
                      and{" "}
                      <Anchor
                        href={
                          "https://p1.aprimocdn.net/sunlife/e947e8e1-08ad-44ff-8e1b-b03000d889ae/ctpfml-plain-language-guide-original-file.docx"
                        }
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        CT PFML Plain Language Guide
                      </Anchor>{" "}
                      to obtain your employer administered Connecticut PFML plan approval.
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {showMassachusetts && (
            <>
              <p>
                <Toggler
                  visible={massachusettsVisible}
                  onClick={toggleMassachusettsVisible}
                  ariaControls="Massachusetts"
                >
                  In Massachusetts
                </Toggler>
              </p>

              {massachusettsVisible && (
                <>
                  <div id="Massachusetts">
                    <p>
                      With Massachusetts Paid Family and Medical Leave, we will need a copy of your
                      Private Plan Approval to ensure that your exemption has been approved. If you
                      have not done so already, apply to Massachusetts via the{" "}
                      <Anchor
                        href="https://www.mass.gov/guides/employers-guide-to-paid-family-and-medical-leave#-step-4:-apply-for-an-exemption-if-you-have-a-qualifying-private-plan-"
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        {" "}
                        MassTax Connect
                      </Anchor>{" "}
                      portal.
                    </p>
                    {maFullyInsured && (
                      <p>
                        You will need to submit a{" "}
                        <Anchor
                          href="https://p1.aprimocdn.net/sunlife/1da3c33c-ed1f-4c04-81b8-afef014f2e14/gamsfm-10211-ma-pfml-confirmation-of-insurance-original-file.pdf"
                          target="_blank"
                          onClick={trackAnchorClick}
                        >
                          MA PFML Confirmation of Insurance
                        </Anchor>{" "}
                        to obtain your fully insured Massachusetts PFML plan approval.{" "}
                      </p>
                    )}
                    {maASO && (
                      <div id="Massachusetts">
                        <p>
                          You will need to submit your{" "}
                          <Anchor
                            href={
                              "https://p1.aprimocdn.net/sunlife/7b0f7c39-bb9b-402f-8dd6-b06600e6f9d2/pfmltp-1900-ma-pfml-aso-spd-template-original-file.docx"
                            }
                            target="_blank"
                            onClick={trackAnchorClick}
                          >
                            MA PFML Summary Plan Description
                          </Anchor>{" "}
                          and{" "}
                          <Anchor
                            href={
                              "https://www.mass.gov/doc/pfml-self-insured-idd-revised-842021/download"
                            }
                            target="_blank"
                            onClick={trackAnchorClick}
                          >
                            MA PFML Self-Insurance Declaration
                          </Anchor>{" "}
                          to obtain your employer administered Massachusetts PFML plan approval.
                        </p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}

          {showOregon && (
            <>
              <p>
                <Toggler
                  visible={oregonVisible}
                  onClick={toggleOregonVisible}
                  ariaControls="Oregon"
                >
                  In Oregon
                </Toggler>
              </p>

              {oregonVisible && (
                <div id="Oregon">
                  <p>
                    With Oregon Paid Family and Medical Leave, we will need a copy of your
                    Equivalent Plan Approval to ensure that your exemption has been approved. If you
                    have not done so already, apply to Paid Leave Oregon via the{" "}
                    <Anchor
                      href="https://frances.oregon.gov/_/"
                      target="_blank"
                      onClick={trackAnchorClick}
                    >
                      Frances Online
                    </Anchor>{" "}
                    portal.{" "}
                  </p>
                  {orFullyInsured && (
                    <p>
                      Please review our{" "}
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/678cad61-efff-41fb-abc3-b02001403450/completing-your-or-pfml-equivalent-plan-application-original-file.pdf"
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        Completing your OR PFML Equivalent Plan Application
                      </Anchor>{" "}
                      guide for application instructions and links to important documentation to
                      obtain your fully insured Oregon PFML plan approval.
                    </p>
                  )}
                  {orASO && (
                    <p>
                      You will need to submit your{" "}
                      <Anchor
                        href={getSummaryDescriptionPlanPFMLLinks["oregon"]}
                        target="_blank"
                        onClick={trackAnchorClick}
                      >
                        OR PFML Summary Plan Description
                      </Anchor>{" "}
                      to obtain your employer administered Oregon PFML plan approval.
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {showWashington && (
            <>
              <p>
                <Toggler
                  visible={washingtonVisible}
                  onClick={toggleWashingtonVisible}
                  ariaControls="Washington"
                >
                  In Washington
                </Toggler>
              </p>

              {washingtonVisible && (
                <div id="Washington">
                  <p>
                    With Washington Paid Family and Medical Leave, we will need a copy of your
                    Voluntary Plan Approval to ensure that your exemption has been approved. If you
                    have not done so already, apply to Washington via the{" "}
                    <Anchor
                      href="https://paidleave.wa.gov/voluntary-plans/"
                      target="_blank"
                      onClick={trackAnchorClick}
                    >
                      WA Paid Leave
                    </Anchor>{" "}
                    portal.
                  </p>
                  <p>
                    You will need to submit your{" "}
                    <Anchor
                      href="https://p1.aprimocdn.net/sunlife/2a0bdfca-0987-4b1b-9ccd-b06600e6fbc1/pfmltp-1898-wa-pfml-aso-spd-template-original-file.docx"
                      target="_blank"
                      onClick={trackAnchorClick}
                    >
                      WA PFML Summary Plan Description
                    </Anchor>{" "}
                    to obtain your employer administered Washington PFML plan approval.
                  </p>
                </div>
              )}
            </>
          )}

          {showSummaryPlanDescriptionDocument && (
            <>
              <p>
                <strong>
                  Don't forget to complete the Summary Plan Description Documents (SPD) for{" "}
                  {listStatesForSPDDocumentFormatted} too.
                </strong>
              </p>

              <p>
                Since your PFML for {listStatesForSPDDocumentFormatted} is an Administrative
                Services Only (ASO) benefit, an SPD is a another document needed in addition to
                submitting a Private Plan Exemption Document.{" "}
                <SlobLink
                  variant="normal"
                  to={RouteData.policyTaskDetail.getPath(clientId, "pfml-spd", task.policyId)}
                >
                  Submit SPD Documents for {listStatesForSPDDocumentFormatted}
                </SlobLink>
                .
              </p>
            </>
          )}

          <h3>FAQs</h3>

          <p>
            <strong>What is Paid Family and Medical Leave or PFML?</strong>
          </p>
          <p>
            The Paid Family and Medical Leave Act (PFMLA) offers your employees the opportunity to
            take time to attend to personal and family health needs without worrying about lost
            income.
          </p>

          <p>
            This could be when an employee is out of work due to their own medical condition and/or
            to care for a family member, bond with a new child, or certain other leave reasons.
          </p>

          <p>
            <strong>How does PFML help me and my employees?</strong>
          </p>
          <p>
            In the event that your employees get sick or for some other reason need to leave work,
            they will continue to get paid through the PFML benefit fund. This will ensure that you
            are able to allow your employees to take time off of work, and still get paid, and it is
            a benefit to you financially.
          </p>

          <p>
            <strong>What is the difference between a fully insured plan versus an ASO plan?</strong>
          </p>
          <p>
            You have the option to offer a fully-insured PFML plan or an ASO PFML plan. With an ASO
            plan, you will be responsible for funding the claims, and with a fully insured plan, the
            insurance company will be responsible for funding the claims.
          </p>

          <p>
            <strong>Are there extra steps with an ASO plan?</strong>
          </p>
          <p>
            As a part of your onboarding, you must provide the summary plan document that conforms
            to the State template in which your plan is offered. If your SPD varies from the State
            template, then the SPD template must be marked up and submitted to Sun Life legal for
            approval, which will be coordinated by your Implementation Consultant. When legal
            approves the marked up SPD, the submission be considered final.
          </p>

          <p>
            <strong>Which states does Sun Life offer PFML in?</strong>
          </p>

          <p>Currently, Sun Life offers PFML in the following states:</p>

          <UnorderedList>
            <UnorderedListItem>Colorado</UnorderedListItem>
            <UnorderedListItem>Connecticut</UnorderedListItem>
            <UnorderedListItem>Massachusetts</UnorderedListItem>
            <UnorderedListItem>Oregon</UnorderedListItem>
            <UnorderedListItem>Washington state</UnorderedListItem>
          </UnorderedList>
        </>
      }
    />
  );
}

type TogglerProps = {
  children: string;
  onClick: () => void;
  visible: boolean;
  ariaControls: string;
};

function Toggler({ children, onClick, visible, ariaControls }: TogglerProps) {
  return (
    <button
      onClick={onClick}
      className={styles.toggleButton}
      aria-expanded={visible}
      aria-controls={ariaControls}
    >
      {children}{" "}
      {visible ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
    </button>
  );
}
