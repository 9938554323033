import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getShowValidationErrorsInSummary } from "shared/utils/client";
import { monthlyClaimsReportMailingLocationSchema } from "shared/validation/monthlyClaimsReportMailingLocation";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode } from "shared/types/Client";
import type { MonthlyClaimsReportMailingLocationInput } from "shared/types/MonthlyClaimsReportMailingLocation";

export const getPrefillErrorsForMonthlyClaimsReportsAndEOBs = (
  formValues: MonthlyClaimsReportMailingLocationInput,
  viewMode: EIFSubStepViewMode,
  changeSnapshot: DEIFChangeSnapshot | null,
) => {
  const showValidationErrorsInSummary = getShowValidationErrorsInSummary(viewMode, changeSnapshot);
  if (!showValidationErrorsInSummary) {
    return {};
  }

  const prefillErrors = getFormikErrors(formValues, monthlyClaimsReportMailingLocationSchema, {
    prefill: false,
  });
  return prefillErrors;
};
