import type { Client } from "shared/types/Client";

export function getEIFIsPendingSignatureOrICEditApproval(client: Client) {
  const eifTask = client.tasks?.find((task) => task.id === "eif-submit-company-information");
  const eifTaskIsPendingSignature =
    client.eifSignedAt == null && eifTask?.statusId === "eif-steps-completed";
  const eifTaskIsPendingICEditApproval =
    client.eifSignedAt != null && client.deifChangesReadyForReviewAt != null;

  const eifIsPendingSignatureOrICEditApproval =
    eifTaskIsPendingSignature || eifTaskIsPendingICEditApproval;

  return eifIsPendingSignatureOrICEditApproval;
}
