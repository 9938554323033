import clsx from "clsx";
import { Circle } from "./Circle";
import * as styles from "./circle.module.less";
import type { HTMLAttributes } from "react";

export const OverlappingCircles = ({
  side,
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement> & { side: "left" | "right" }) => (
  <div
    {...rest}
    className={clsx(styles.circlesMask, side === "left" ? styles.left : styles.right, className)}
  >
    <div className={clsx(styles.bigCirclePosition, side === "left" ? styles.left : styles.right)}>
      <Circle variant="blue" />
    </div>
    <div className={clsx(styles.thinCirclePosition, side === "left" ? styles.left : styles.right)}>
      <Circle variant="blue" thin />
    </div>
  </div>
);
