import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { genericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { ResponseError } from "client/src/hooks/query";
import { isQPSSyncErrorDetail, type QPSSyncErrorDetail } from "shared/types/QPSClass";

type QPSErrorBannerProps = {
  qpsSyncError: QPSSyncErrorDetail;
};

export const QPSErrorBanner = ({ qpsSyncError }: QPSErrorBannerProps) => {
  if (qpsSyncError.qpsErrorDetail) {
    const { warnings, errors } = qpsSyncError.qpsErrorDetail;

    return (
      <StackY dist={8}>
        <Body2>{qpsSyncError.section}</Body2>
        {warnings?.length && (
          <AlertBanner
            variant="warning"
            message={
              <UnorderedList markerColor="gray">
                {warnings.map((errorDetail, msgErrorIndex) => (
                  <UnorderedListItem key={msgErrorIndex}>
                    {errorDetail.message} (Warning Code: {errorDetail.code})
                  </UnorderedListItem>
                ))}
              </UnorderedList>
            }
          />
        )}
        {errors?.length && (
          <AlertBanner
            variant="error"
            message={
              <UnorderedList markerColor="gray">
                {errors.map((errorDetail, msgErrorIndex) => (
                  <UnorderedListItem key={msgErrorIndex}>
                    {errorDetail.message} (Error Code: {errorDetail.code})
                  </UnorderedListItem>
                ))}
              </UnorderedList>
            }
          />
        )}
        {!warnings?.length && !errors?.length && (
          <AlertBanner
            variant="error"
            message={`${qpsSyncError.qpsErrorDetail.message} (Error Code: ${qpsSyncError.qpsErrorDetail.code})`}
          />
        )}
      </StackY>
    );
  }

  if (qpsSyncError.onboardValidation) {
    const { warnings } = qpsSyncError.onboardValidation;

    return (
      <StackY dist={8}>
        <Body2>{qpsSyncError.section}</Body2>
        {warnings?.length && (
          <AlertBanner
            variant="warning"
            message={
              <UnorderedList markerColor="gray">
                {warnings.map((warningMsg, msgIndex) => (
                  <UnorderedListItem key={msgIndex}>{warningMsg}</UnorderedListItem>
                ))}
              </UnorderedList>
            }
          />
        )}
      </StackY>
    );
  }

  return qpsSyncError.generalError ? (
    <StackY dist={8}>
      <Body2>{qpsSyncError.section}</Body2>
      <AlertBanner variant="error" message={qpsSyncError.generalError} />
    </StackY>
  ) : null;
};

export const parseQPSSyncErrorResponse = (
  qpsSyncError: ResponseError | null,
): QPSSyncErrorDetail[] => {
  if (!qpsSyncError) {
    return [];
  }

  const defaultError = [
    {
      section: "",
      generalError: genericErrorCopy2,
    },
  ];

  return ResponseError.dataHasErrorDetails(qpsSyncError.data) &&
    Array.isArray(qpsSyncError.data.errorDetails)
    ? qpsSyncError.data.errorDetails.filter(isQPSSyncErrorDetail)
    : defaultError;
};
