import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { FormInput } from "client/src/components/Form/Input";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { ContributionsCTA } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/ContributionsCTA";
import { ReviewLifeADDContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/ReviewLifeADDContributions";
import { getLifeADDContributionsHeader } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/getLifeADDContributionsHeader";
import { hasAtLeastOneContributionSelected } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/hasAtLeastOneContributionSelected";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { ResponseError } from "client/src/hooks/query";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import {
  lifeADDContributionBenefitTypes,
  benefitTypesSorter,
  getIsLifeADDContributionBenefitType,
} from "shared/types/BenefitTypes";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getEmployeeClassContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { rejectNullableValues } from "shared/utils/utils";

import { employerContributionOptionsRequired } from "shared/validation/employeeClassPlan";
import { percentageValidationSchema } from "shared/validation/validation";
import * as Yup from "yup";
import type { RadioChangeEvent } from "antd";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type {
  EmployeeClassPlanContributions,
  EmployeeClassPlanWithPlan,
  EmployerContributionOption,
} from "shared/types/EmployeeClassPlan";

type EIFClassBuilderLifeADDContributionsEditableProps = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  setContributionsQuery: SetContributionQuery;
  onSave: (employeeClass: EmployeeClass) => void;
  track: SectionTracker;
  isLoading: boolean;
};

export function EIFClassBuilderLifeADDContributionsEditable(
  props: EIFClassBuilderLifeADDContributionsEditableProps,
) {
  const {
    isActive,
    employeeClass,
    client,
    changeSnapshot,
    authUser,
    setContributionsQuery,
    setContributionsQuery: { mutateAsync: setContributions },
    onSave,
    track,
    isLoading,
  } = props;

  const header = getLifeADDContributionsHeader(employeeClass);
  const status = getEmployeeClassContributionsCompletionStatus(
    employeeClass,
    lifeADDContributionBenefitTypes,
  );

  const location = useLocation();
  const ctaBasePathname = location.pathname;

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans
    .filter((p) => getIsLifeADDContributionBenefitType(p.plan.benefitType))
    .sort((a, b) => benefitTypesSorter(a.plan.benefitType, b.plan.benefitType));

  const percentValidationSchema = (required: boolean) =>
    required
      ? percentageValidationSchema.when(["employerContribution", "$prefill"], {
          is: (
            employerContribution: EmployerContributionOption | null,
            prefill: boolean | undefined,
          ) => employerContribution === "YES" && !prefill,
          then: (schema) => schema.required("Please enter an amount"),
          otherwise: (schema) => schema.optional().nullable(),
        })
      : Yup.number().optional().nullable();

  const basicPlan = relevantEmployeeClassPlans.find((p) => p.plan.benefitType === "LIFE");
  const depPlan = relevantEmployeeClassPlans.find((p) => p.plan.benefitType === "DEP_LIFE");
  const adndPlan = relevantEmployeeClassPlans.find((p) => p.plan.benefitType === "BASIC_ADND");

  const basicContributionValidationSchema = Yup.object({
    employerContribution: employerContributionOptionsRequired,
    eeContributionAmount_LIFE: percentValidationSchema(!!basicPlan),
    eeContributionAmount_DEP_LIFE: percentValidationSchema(!!depPlan),
    eeContributionAmount_BASIC_ADND: percentValidationSchema(!!adndPlan),
  });

  const getEmployerContributionInitialValue = (
    basicPlan: EmployeeClassPlanWithPlan | undefined,
    depPlan: EmployeeClassPlanWithPlan | undefined,
    adndPlan: EmployeeClassPlanWithPlan | undefined,
  ): EmployerContributionOption | null => {
    if (!basicPlan || basicPlan.employerContribution === null) {
      return null;
    }

    if (basicPlan.employerContribution === "NO") {
      return "NO";
    }

    const all100Percent = basicPlan
      ? basicPlan.eeContributionAmount === 100
      : (depPlan ? depPlan.eeContributionAmount === 100 : true) &&
        (adndPlan ? adndPlan.eeContributionAmount === 100 : true);

    return all100Percent ? "YES" : "SHARED";
  };

  const basicContributionInitialValues = {
    employerContribution: getEmployerContributionInitialValue(basicPlan, depPlan, adndPlan),
    eeContributionAmount_LIFE: basicPlan?.eeContributionAmount || null,
    eeContributionAmount_DEP_LIFE: depPlan?.eeContributionAmount || null,
    eeContributionAmount_BASIC_ADND: adndPlan?.eeContributionAmount || null,
  };

  const formik = useSlobFormik({
    validationSchema: basicContributionValidationSchema,
    initialValues: basicContributionInitialValues,
    validationContext: { prefill: true },
    onSubmit: async () => {
      const contributions: EmployeeClassPlanContributions[] = relevantEmployeeClassPlans
        .map((ecp) => {
          if (getIsLifeADDContributionBenefitType(ecp.plan.benefitType)) {
            return {
              employeeClassPlanId: ecp.id,
              employerContribution: formik.values.employerContribution || null,
              employerContributionUnit: "PERCENT" as const,
              eeContributionAmount:
                {
                  LIFE:
                    formik.values.employerContribution === "YES"
                      ? 100
                      : formik.values.eeContributionAmount_LIFE,
                  DEP_LIFE:
                    formik.values.employerContribution === "YES"
                      ? 100
                      : formik.values.eeContributionAmount_DEP_LIFE,
                  BASIC_ADND:
                    formik.values.employerContribution === "YES"
                      ? 100
                      : formik.values.eeContributionAmount_BASIC_ADND,
                }[ecp.plan.benefitType] || null,
              spouseContributionAmount: null,
              childrenContributionAmount: null,
              eeAndSpouseContributionAmount: null,
              eeAndChildrenContributionAmount: null,
              eeAndFamilyContributionAmount: null,
              familyContributionAmount: null,
              employerPremiumPayments: null,
              threeYearLookBackPercent: null,
              taxChoice: null,
            };
          }
          return null;
        })
        .filter(rejectNullableValues);

      const { isSuccess, data } = await setContributions({
        params: {
          clientId: client.id,
          employeeClassId: employeeClass.id,
        },
        data: {
          employeeClassId: employeeClass.id,
          contributions,
        },
      });

      if (isSuccess) {
        track("Save");
        void slobMessage.success("Updated Contributions");
        onSave(data.employeeClass);
      }
    },
  });

  const strictErrors =
    status !== "Not Started"
      ? getFormikErrors(formik.values, basicContributionValidationSchema, {
          prefill: false,
        })
      : {};

  // Need a custom handler to clear out contribution values
  // when switching from YES -> SHARED otherwise the contribution values
  // will be pre-populated to 100% resulting in bad UX
  const handleChange = (e: RadioChangeEvent) => {
    if (
      e.target.name === "employerContribution" &&
      e.target.value === "SHARED" &&
      formik.values.eeContributionAmount_LIFE === 100 &&
      formik.values.eeContributionAmount_DEP_LIFE === 100 &&
      formik.values.eeContributionAmount_BASIC_ADND === 100
    ) {
      void formik.setValues({
        ...formik.values,
        eeContributionAmount_LIFE: null,
        eeContributionAmount_DEP_LIFE: null,
        eeContributionAmount_BASIC_ADND: null,
      });
    }
    formik.handleChange(e);
  };

  if (!isActive) {
    const atLeastOneContributionSelected = hasAtLeastOneContributionSelected(
      relevantEmployeeClassPlans,
    );

    return (
      <>
        <Row justify="start" wrap={false} gutter={0}>
          <Col>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              <EIFSectionStatusIconography status={status} />
            </H3>
          </Col>

          <Col flex={1}>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              Contributions - {header}
            </H3>
          </Col>

          <Col xs={4}>
            <ContributionsCTA
              employeeClass={employeeClass}
              ctaBasePathname={ctaBasePathname}
              benefitTypes={lifeADDContributionBenefitTypes}
              relevantEmployeeClassPlans={relevantEmployeeClassPlans}
              setContributionsQuery={setContributionsQuery}
              isLoading={isLoading}
            />
          </Col>
        </Row>

        <ReviewLifeADDContributions
          client={client}
          employeeClass={employeeClass}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
        />
      </>
    );
  }

  return (
    <>
      <Row justify="start" wrap={false} gutter={0}>
        <Col>
          <H3 as="h2">
            <EIFSectionStatusIconography status={status} />
          </H3>
        </Col>
        <Col flex={1}>
          <H3 as="h2">Contributions - {header}</H3>
        </Col>
      </Row>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24}>
          <RadioGroup
            name="employerContribution"
            label="Will you contribute towards the premium for this eligible employee group?"
            disabled={formik.isSubmitting}
            error={formik.errors.employerContribution}
            touched={formik.touched.employerContribution}
            options={[
              {
                value: "YES",
                label: (
                  <>
                    <Body3 as="div">Yes, we are paying 100% of the premium</Body3>
                    <Body5>The employer pays the entire premium</Body5>
                  </>
                ),
              },
              {
                value: "SHARED",
                label: (
                  <>
                    <Body3 as="div">Yes, we are paying part of the premium</Body3>
                    <Body5>
                      The employer pays part of the premium and teh employee pays part of the
                      premium
                    </Body5>
                  </>
                ),
              },
              {
                value: "NO",
                label: (
                  <>
                    <Body3 as="div">No, the employee pays 100% of the premium</Body3>
                    <Body5>The employee pays the entire premium</Body5>
                  </>
                ),
              },
            ]}
            direction="vertical"
            value={formik.values.employerContribution}
            onChange={handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={relevantChangesForContributionFields(
              relevantEmployeeClassPlans.map((p) => p.id),
              changeSnapshot,
              ["employerContribution"],
            )}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "employerContribution",
              client,
              formik,
            })}
          />

          {formik.values.employerContribution === "SHARED" && (
            <StackY dist={16} wrap={false}>
              <Row justify="space-between">
                <Col>
                  <Body2>What percent will you be contributing?</Body2>
                </Col>
              </Row>

              <HubCard>
                <StackY dist={8} wrap={false}>
                  {relevantEmployeeClassPlans
                    .map((ecp) => ecp.plan.benefitType)
                    .filter(getIsLifeADDContributionBenefitType)
                    .map((bt) => (
                      <Row justify="space-between" align="middle" gutter={[16, 8]} key={bt}>
                        <Col span={12}>
                          <Body3>{benefitTypeToCoverage[bt]}</Body3>
                        </Col>
                        <Col span={12}>
                          <FormInput
                            name={
                              {
                                LIFE: "eeContributionAmount_LIFE",
                                DEP_LIFE: "eeContributionAmount_DEP_LIFE",
                                BASIC_ADND: "eeContributionAmount_BASIC_ADND",
                              }[bt]
                            }
                            suffix="%"
                            maxLength={6}
                            label="Employer contribution percent"
                            touched={
                              {
                                LIFE:
                                  formik.touched.eeContributionAmount_LIFE ||
                                  !!strictErrors.eeContributionAmount_LIFE,
                                DEP_LIFE:
                                  formik.touched.eeContributionAmount_DEP_LIFE ||
                                  !!strictErrors.eeContributionAmount_DEP_LIFE,
                                BASIC_ADND:
                                  formik.touched.eeContributionAmount_BASIC_ADND ||
                                  !!strictErrors.eeContributionAmount_BASIC_ADND,
                              }[bt]
                            }
                            error={
                              {
                                LIFE:
                                  formik.errors.eeContributionAmount_LIFE ||
                                  strictErrors.eeContributionAmount_LIFE,
                                DEP_LIFE:
                                  formik.errors.eeContributionAmount_DEP_LIFE ||
                                  strictErrors.eeContributionAmount_DEP_LIFE,
                                BASIC_ADND:
                                  formik.errors.eeContributionAmount_BASIC_ADND ||
                                  strictErrors.eeContributionAmount_BASIC_ADND,
                              }[bt]
                            }
                            value={
                              {
                                LIFE: formik.values.eeContributionAmount_LIFE,
                                DEP_LIFE: formik.values.eeContributionAmount_DEP_LIFE,
                                BASIC_ADND: formik.values.eeContributionAmount_BASIC_ADND,
                              }[bt]
                            }
                            onChange={formik.handleChange}
                            disabled={formik.isSubmitting}
                            aria-label={`Employer contribution percent for ${benefitTypeToCoverage[bt]}`}
                          />
                          <EditedFieldMsg
                            changeDetailInfoList={
                              {
                                LIFE: basicPlan
                                  ? [
                                      changeSnapshot.EmployeeClassPlan[basicPlan.id]
                                        ?.eeContributionAmount,
                                    ]
                                  : [],
                                DEP_LIFE: depPlan
                                  ? [
                                      changeSnapshot.EmployeeClassPlan[depPlan.id]
                                        ?.eeContributionAmount,
                                    ]
                                  : [],
                                BASIC_ADND: adndPlan
                                  ? [
                                      changeSnapshot.EmployeeClassPlan[adndPlan.id]
                                        ?.eeContributionAmount,
                                    ]
                                  : [],
                              }[bt]
                            }
                            client={client}
                            authUser={authUser}
                            hasPendingEdit={getHasPendingEdit({
                              field: {
                                LIFE: "eeContributionAmount_LIFE",
                                DEP_LIFE: "eeContributionAmount_DEP_LIFE",
                                BASIC_ADND: "eeContributionAmount_BASIC_ADND",
                              }[bt],
                              client,
                              formik,
                            })}
                          />
                        </Col>
                      </Row>
                    ))}
                </StackY>
              </HubCard>
            </StackY>
          )}

          {setContributionsQuery.isError && (
            <div aria-live="assertive" className="mt-24 hide:empty">
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(
                  setContributionsQuery.error,
                  GenericErrorCopy2,
                )}
              </ErrorMessage>
            </div>
          )}

          <Row justify="end" align="middle" gutter={28}>
            <Col>
              <InternalLinkButton
                type="link-inline-bold"
                size="middle"
                to={location.pathname}
                disabled={formik.isSubmitting}
                onClick={() => flushSync(formik.resetForm)}
              >
                Cancel
              </InternalLinkButton>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="secondary"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                size="middle"
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
