import { Dropdown } from "antd";
import { Button } from "client/src/components/Button/Button";
import { useState } from "react";
import { RouteData } from "shared/config/routeData";
import { BROKER } from "shared/rbac/roles";
import { formatShortDateTimeLocal } from "shared/utils/format";
import { isActivated } from "shared/utils/user";

import { DropdownButton } from "../../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { ActionMenu } from "../../../components/Menu/ActionMenu";
import { SlobTable } from "../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../components/Table/ActionButtons";
import { Tooltip } from "../../../components/Tooltip/Tooltip";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import {
  useDeleteBroker,
  useResetMFA,
  useResetPassword,
  useSendBrokerWelcomeEmail,
  useUnassignBrokerToClient,
} from "../../../hooks/user";

import type { SlobColumnsType, SlobTableProps } from "../../../components/SlobTable/SlobTable";
import type { MenuProps } from "antd";
import type { Broker } from "shared/types/User";

export type BrokerTableProps = Omit<SlobTableProps<Broker>, "columns">;
export type BrokerTableHookProps = {
  handleDelete: () => Promise<void>;
  handleReset: () => Promise<void>;
  handleResetMFA: () => Promise<void>;
  handleSendWelcomeEmail: () => Promise<void>;
  handleUnassignFromClient: () => Promise<void>;
  handleEdit: (broker: Broker) => void;
  setActiveBroker: (broker?: Broker) => void;
  activeBroker?: Broker;
  isDeleteDialogVisible: boolean;
  setIsDeleteDialogVisible: (value: boolean) => void;
  isResetDialogVisible: boolean;
  isResetMFADialogVisible: boolean;
  setIsResetMFADialogVisible: (value: boolean) => void;
  setIsResetDialogVisible: (value: boolean) => void;
  isWelcomeEmailDialogVisible: boolean;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  isUnassignDialogVisible: boolean;
  setIsUnassignDialogVisible: (value: boolean) => void;
};

export const useBrokerTableProps = ({
  clientId,
  data,
  showModal,
}: {
  clientId?: string;
  showModal?: (value: Broker) => void;
  data?: Broker[];
}): BrokerTableHookProps => {
  const { mutateAsync: deleteBroker } = useDeleteBroker();
  const { mutateAsync: resetPassword } = useResetPassword();
  const { mutateAsync: resetMFA } = useResetMFA();
  const { mutateAsync: sendWelcomeEmail } = useSendBrokerWelcomeEmail();
  const { mutateAsync: unassignClient } = useUnassignBrokerToClient();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);
  const [isResetMFADialogVisible, setIsResetMFADialogVisible] = useState(false);

  const [isWelcomeEmailDialogVisible, setIsWelcomeEmailDialogVisible] = useState(false);
  const [isUnassignDialogVisible, setIsUnassignDialogVisible] = useState(false);

  const [activeBroker, setActiveBroker] = useState<Broker>();

  const handleDelete = async () => {
    if (!activeBroker) return;
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progress", 0);
    try {
      await deleteBroker({
        params: { userId: activeBroker.id },
      });
      void slobMessage.success("Broker deleted");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleReset = async () => {
    if (!activeBroker) return;
    setIsResetDialogVisible(false);
    const hide = slobMessage.loading("Sending reset email", 0);
    try {
      await resetPassword({
        params: { email: activeBroker.email },
      });
      void slobMessage.success("Password email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleResetMFA = async () => {
    if (!activeBroker) return;
    setIsResetMFADialogVisible(false);
    const hide = slobMessage.loading("Sending reset MFA", 0);
    try {
      await resetMFA({
        params: { email: activeBroker.email },
      });
      void slobMessage.success("MFA has been reset successfully");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleSendWelcomeEmail = async () => {
    if (!activeBroker) return;
    setIsWelcomeEmailDialogVisible(false);
    const hide = slobMessage.loading("Sending welcome email", 0);
    try {
      await sendWelcomeEmail({
        params: { userId: activeBroker.id },
        query: { clientId },
      });
      void slobMessage.success("Welcome email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleUnassignFromClient = async () => {
    if (!activeBroker) return;
    setIsUnassignDialogVisible(false);
    if (!clientId) return;
    const hide = slobMessage.loading("Removing from client", 0);
    try {
      await unassignClient({
        data: {},
        params: { userId: activeBroker.id, clientId },
      });
      void slobMessage.success("Removed from client");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleEdit = (broker: Broker) => {
    const payload = data?.find((b) => b.id === broker.id);
    if (!payload || !showModal) {
      return;
    }
    showModal(payload);
  };

  return {
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
    handleUnassignFromClient,
    handleEdit,
    setActiveBroker,
    activeBroker,
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    isResetMFADialogVisible,
    setIsResetMFADialogVisible,
    setIsResetDialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    isUnassignDialogVisible,
    setIsUnassignDialogVisible,
  };
};

export type BrokerTableActionMenuProps = {
  broker: Broker;
  showModal?: (broker: Broker) => void;
  handleEdit: (broker: Broker) => void;
  setActiveBroker: (broker?: Broker) => void;
  setIsDeleteDialogVisible: (value: boolean) => void;
  setIsResetDialogVisible: (value: boolean) => void;
  setIsResetMFADialogVisible: (value: boolean) => void;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  setIsUnassignDialogVisible: (value: boolean) => void;
  clientId?: string;
};

export const BrokerTableActionMenu = ({
  broker,
  showModal,
  handleEdit,
  setActiveBroker,
  setIsDeleteDialogVisible,
  setIsResetDialogVisible,
  setIsResetMFADialogVisible,
  setIsWelcomeEmailDialogVisible,
  setIsUnassignDialogVisible,
  clientId,
}: BrokerTableActionMenuProps) => {
  const menuItems: MenuProps["items"] = showModal
    ? [{ key: "edit-broker", label: "Edit Broker" }]
    : [];
  menuItems.push(
    { key: "delete-broker", label: "Delete Broker" },
    { key: "reset-password", label: "Reset Password" },
    { key: "reset-mfa", label: "Reset MFA" },
  );
  if (clientId)
    menuItems.push(
      {
        key: "send-welcome-email",
        disabled: isActivated(broker),
        label: (
          <Tooltip
            title={
              isActivated(broker) &&
              "This user has activated their account. A welcome email can no longer be sent."
            }
            placement="bottom"
          >
            Send Welcome Email
          </Tooltip>
        ),
      },
      {
        key: "unassign",
        label: "Unassign from Client",
      },
    );
  const menu = (
    <ActionMenu
      data-testid="dropdown-menu"
      items={menuItems}
      onClick={({ key }) => {
        switch (key) {
          case "edit-broker":
            handleEdit(broker);
            break;
          case "delete-broker":
            setActiveBroker(broker);
            setIsDeleteDialogVisible(true);
            break;
          case "reset-password":
            setActiveBroker(broker);
            setIsResetDialogVisible(true);
            break;
          case "reset-mfa":
            setActiveBroker(broker);
            setIsResetMFADialogVisible(true);
            break;
          case "send-welcome-email":
            setActiveBroker(broker);
            setIsWelcomeEmailDialogVisible(true);
            break;
          case "unassign":
            setActiveBroker(broker);
            setIsUnassignDialogVisible(true);
            break;
        }
      }}
    />
  );
  return (
    <ActionButtons>
      <Button type="secondary" size="xtra-small" to={RouteData.brokerDetail.getPath(broker.id)}>
        View Broker
      </Button>
      <Dropdown dropdownRender={() => menu} placement="bottomLeft">
        <DropdownButton size="xtra-small" />
      </Dropdown>
    </ActionButtons>
  );
};

export const BrokerTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  showModal,
  clientId,
  featureToggles,
}: BrokerTableProps) => {
  const {
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
    handleUnassignFromClient,
    handleEdit,
    setActiveBroker,
    activeBroker,
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    setIsResetDialogVisible,
    setIsResetMFADialogVisible,
    isResetMFADialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    isUnassignDialogVisible,
    setIsUnassignDialogVisible,
  } = useBrokerTableProps({ clientId, data, showModal });

  return (
    <BrokerTableRender
      handleDelete={handleDelete}
      handleReset={handleReset}
      handleResetMFA={handleResetMFA}
      handleSendWelcomeEmail={handleSendWelcomeEmail}
      handleUnassignFromClient={handleUnassignFromClient}
      handleEdit={handleEdit}
      setActiveBroker={setActiveBroker}
      activeBroker={activeBroker}
      isDeleteDialogVisible={isDeleteDialogVisible}
      setIsDeleteDialogVisible={setIsDeleteDialogVisible}
      isResetDialogVisible={isResetDialogVisible}
      setIsResetDialogVisible={setIsResetDialogVisible}
      isResetMFADialogVisible={isResetMFADialogVisible}
      setIsResetMFADialogVisible={setIsResetMFADialogVisible}
      isWelcomeEmailDialogVisible={isWelcomeEmailDialogVisible}
      setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
      isUnassignDialogVisible={isUnassignDialogVisible}
      setIsUnassignDialogVisible={setIsUnassignDialogVisible}
      showModal={showModal}
      clientId={clientId}
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      featureToggles={featureToggles}
    />
  );
};

export const BrokerTableRender = ({
  handleDelete,
  handleReset,
  handleResetMFA,
  handleSendWelcomeEmail,
  handleUnassignFromClient,
  handleEdit,
  setActiveBroker,
  activeBroker,
  isDeleteDialogVisible,
  setIsDeleteDialogVisible,
  isResetDialogVisible,
  setIsResetDialogVisible,
  isResetMFADialogVisible,
  setIsResetMFADialogVisible,
  isWelcomeEmailDialogVisible,
  setIsWelcomeEmailDialogVisible,
  isUnassignDialogVisible,
  setIsUnassignDialogVisible,
  showModal,
  clientId,
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
}: BrokerTableProps & BrokerTableHookProps) => {
  const columns: SlobColumnsType<Broker> = [
    {
      title: "Broker",
      dataIndex: "lastName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      width: 190,
    },
    {
      title: "Email",
      dataIndex: "email",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "Welcome Email",
      dataIndex: "welcomeEmailSentDate",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (welcomeEmailSentDate: Date) =>
        welcomeEmailSentDate ? formatShortDateTimeLocal(welcomeEmailSentDate) : "-",
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (lastLogin: Date) => (lastLogin ? formatShortDateTimeLocal(lastLogin) : "-"),
    },
    {
      render: (broker: Broker) => {
        return (
          <BrokerTableActionMenu
            broker={broker}
            showModal={showModal}
            handleEdit={handleEdit}
            setActiveBroker={setActiveBroker}
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            setIsResetDialogVisible={setIsResetDialogVisible}
            setIsResetMFADialogVisible={setIsResetMFADialogVisible}
            setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
            setIsUnassignDialogVisible={setIsUnassignDialogVisible}
            clientId={clientId}
          />
        );
      },
    },
  ];

  return (
    <>
      <SlobTable
        columns={columns}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={data?.map((d) => {
          // Use single table dataIndex for name for accurate backend sorting/filtering/pagination
          return { ...d, lastName: d.firstName + " " + d.lastName };
        })}
        featureToggles={featureToggles}
      />

      <ConfirmDialogDeleteUser
        role={BROKER}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={activeBroker}
        handleDelete={handleDelete}
      />

      <ConfirmDialog
        title="Reset password"
        isVisible={isResetDialogVisible}
        onCancel={() => setIsResetDialogVisible(false)}
        onConfirm={() => handleReset()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset password?
      </ConfirmDialog>

      <ConfirmDialog
        title="Reset MFA"
        isVisible={isResetMFADialogVisible}
        onCancel={() => setIsResetMFADialogVisible(false)}
        onConfirm={() => handleResetMFA()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset MFA?
      </ConfirmDialog>

      <ConfirmDialog
        title="Send welcome email"
        isVisible={isWelcomeEmailDialogVisible}
        onCancel={() => setIsWelcomeEmailDialogVisible(false)}
        onConfirm={() => handleSendWelcomeEmail()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to send a welcome email?
      </ConfirmDialog>

      <ConfirmDialog
        title="Unassign from Client"
        isVisible={isUnassignDialogVisible}
        onCancel={() => setIsUnassignDialogVisible(false)}
        onConfirm={() => handleUnassignFromClient()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to unassign from a client?
      </ConfirmDialog>
    </>
  );
};
