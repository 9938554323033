import { Menu } from "antd";

import * as styles from "./menu.module.less";
import type { MenuProps } from "antd";

export const ActionMenu = (props: MenuProps) => {
  return (
    <Menu className={styles.actionMenu} {...props}>
      {props.children}
    </Menu>
  );
};
