import { BEN_ADMIN, BROKER, type UserRole } from "../rbac/roles";
import type {
  SlfInternalCreateValidationSchema,
  BenAdminCreateValidationSchema,
  BenAdminUpdateValidationSchema,
  BrokerCreateValidationSchema,
  BrokerUpdateValidationSchema,
  UserInfoValidationSchema,
} from "../validation/user";
import type { UserData } from "shared/rbac/rbac";
import type { ClientId } from "shared/types/Client";
import type { ClientUser } from "shared/types/ClientUser";
import type * as Yup from "yup";

export type UserId = string;

export type User = {
  id: UserId;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
};

// Some of the codebase needs the values in SharedUserInfo to be null, other places need it as null AND undefined.
// Some future effort could disentangle that but it's used in 100s of places
type SharedUserInfo = {
  welcomeEmailSentDate: Date | null;
  termsOfUseDate: Date | null;
  firstLogin: Date | null;
  firstPasswordSet: Date | null;
  lastLogin: Date | null;
  lastPasswordSet: Date | null;
  fullName: string | null;
};

export type DomainUser = User &
  SharedUserInfo & {
    phoneNumber: string | null;
    role: UserRole;
    oktaId: string | null;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
    deletedAt: Date | null;
    deletedBy: string | null;
    clientIds: string[] | ClientId[];
    fullName: string;
    loginCount: number;
    userInfoUpdatedAt: Date;
    clientUser?: Pick<ClientUser, "clientId" | "importedFromClientId" | "welcomeFlowStatus">[];
  };

export type SimplifiedDomainUser = User &
  Pick<DomainUser, "phoneNumber" | "termsOfUseDate" | "clientIds" | "role">;

export type DomainUserOption = { id: DomainUser["id"]; name: string };

export type SlfImplementationConsultant = User;

export type BaseRoleUser = User &
  Partial<SharedUserInfo> & {
    role: UserRole;
  };
export type SlfInternal = BaseRoleUser & {
  phoneNumber: string | null;
};

export type BenAdmin = BaseRoleUser & {
  clientUser?: DomainUser["clientUser"];
  clientIds: ClientId[];
};
export type Broker = BaseRoleUser & {
  clientUser?: DomainUser["clientUser"];
  clientIds: ClientId[];
};

export type SlfInternalCreate = Yup.InferType<typeof SlfInternalCreateValidationSchema> & {
  welcomeEmailSentDate?: Date | null;
  termsOfUseDate?: Date | null;
};

export type BenAdminCreate = Yup.InferType<typeof BenAdminCreateValidationSchema>;
export type BenAdminUpdate = Yup.InferType<typeof BenAdminUpdateValidationSchema>;
export type BrokerCreate = Yup.InferType<typeof BrokerCreateValidationSchema>;
export type BrokerUpdate = Yup.InferType<typeof BrokerUpdateValidationSchema>;

export type UserCreateInput = Yup.InferType<typeof UserInfoValidationSchema> & {
  role: UserRole;
  phoneNumber?: string | null;
};

export type UserUpdateInput = Partial<Yup.InferType<typeof UserInfoValidationSchema>> & {
  id: UserId;
  role?: UserRole;
  phoneNumber?: string | null;
};

export type UserTableQuery = {
  limit?: number;
  offset?: number;
  search?: string;
  sortBy?: "lastName" | "email" | "lastLogin" | "welcomeEmailSentDate" | "role" | "fullName";
  sortDirection?: "asc" | "desc";
  clientId?: ClientId;
};

export type CreateActivationLinkOutput = {
  activationLink: string;
};

export type UpdateEmailAndActivateUserInput = {
  users: {
    email: string;
    userId: string;
    clientId: string;
  }[];
};

export const authUserToDomainUser = (authUser: UserData): DomainUser => ({
  id: authUser.id ?? "",
  firstName: authUser.name,
  lastName: "",
  email: authUser.email,
  role: authUser.role,
  fullName: authUser.name,
  clientIds: authUser.clientIds,

  // These fields are not available in the authUser object
  oktaId: null,
  firstLogin: null,
  phoneNumber: null,
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null,
  createdBy: "",
  updatedBy: "",
  deletedBy: null,
  firstPasswordSet: null,
  lastLogin: null,
  lastPasswordSet: null,
  loginCount: 0,
  termsOfUseDate: null,
  userInfoUpdatedAt: new Date(),
  welcomeEmailSentDate: null,
  clientUser: [],
});

export const isUser = (user: unknown): user is User => {
  return (
    user != null &&
    typeof user === "object" &&
    "id" in user &&
    "firstName" in user &&
    "lastName" in user &&
    "email" in user
  );
};

export const roleToPretty = (role: UserRole | undefined) => {
  switch (role) {
    case BEN_ADMIN:
      return "Benefits Administrator";
    case BROKER:
      return "Broker";
    default:
      return "User";
  }
};
