import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { UnorderedListItem } from "client/src/components/UnorderedList/UnorderedList";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { AvoidPageBreak } from "client/src/utils/print";
import {
  displayTaxChoiceOptionsTypeName,
  employerPremiumPaymentsValuesRecord,
} from "shared/types/EmployeeClassPlan";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { disabilityContributionsValidationSchema } from "shared/validation/employeeClassPlan";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DisabilityContributionBenefitType } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";
import type { ValuesForValidationSchema } from "shared/types/Helper";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot | null;
  authUser: UserData | null;
  benefitType: DisabilityContributionBenefitType;
};

export function ReviewDisabilityContributions(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser, benefitType } = props;

  const relevantEmployeeClassPlan = employeeClass.employeeClassPlans.find(
    (p) => p.plan.benefitType === benefitType,
  );

  const employeeClassPlanIds = relevantEmployeeClassPlan ? [relevantEmployeeClassPlan.id] : [];

  const changeDetailInfoList = relevantChangesForContributionFields(
    employeeClassPlanIds,
    changeSnapshot,
  );

  const viewMode = getEIFSubStepViewMode({ client });

  const strictErrors =
    getShowValidationErrorsInSummary(viewMode, changeSnapshot) && relevantEmployeeClassPlan
      ? getFormikErrors(relevantEmployeeClassPlan, disabilityContributionsValidationSchema, {
          prefill: false,
          benefitType: relevantEmployeeClassPlan.plan.benefitType,
        })
      : {};

  return (
    <StackY dist={16} wrap={false}>
      <EditedFieldMsg
        changeDetailInfoList={changeDetailInfoList}
        client={client}
        authUser={authUser}
      />

      <section>
        <AvoidPageBreak>
          {relevantEmployeeClassPlan && (
            <>
              {relevantEmployeeClassPlan.employerContribution === "YES" && (
                <StackY dist={8}>
                  <Body3>The employer is paying 100% of the premium</Body3>
                </StackY>
              )}

              {relevantEmployeeClassPlan.employerContribution === "GROSS_UP" && (
                <StackY dist={8}>
                  <Body3>
                    Gross Up: The employer adds the premium to the employee's W2 as taxable income
                  </Body3>
                </StackY>
              )}

              {relevantEmployeeClassPlan.employerContribution === "SHARED" && (
                <StackY dist={8}>
                  <Body3>Shared contributions by employer and employee</Body3>
                  {relevantEmployeeClassPlan.eeContributionAmount && (
                    <Body3>
                      {`Contribution amount: ${relevantEmployeeClassPlan.eeContributionAmount}%`}
                    </Body3>
                  )}
                  {!relevantEmployeeClassPlan.eeContributionAmount &&
                    strictErrors.eeContributionAmount && (
                      <Body3 redMedium>{strictErrors.eeContributionAmount}</Body3>
                    )}
                  {!relevantEmployeeClassPlan.eeContributionAmount &&
                    !strictErrors.eeContributionAmount && <Body3>-</Body3>}

                  {relevantEmployeeClassPlan.employerPremiumPayments && (
                    <Body3>
                      {`Employees’ premium payments: ${
                        employerPremiumPaymentsValuesRecord[
                          relevantEmployeeClassPlan.employerPremiumPayments
                        ]
                      }`}
                    </Body3>
                  )}
                  {!relevantEmployeeClassPlan.employerPremiumPayments &&
                    strictErrors.employerPremiumPayments && (
                      <Body3 redMedium>{strictErrors.employerPremiumPayments}</Body3>
                    )}
                  {!relevantEmployeeClassPlan.employerPremiumPayments &&
                    !strictErrors.employerPremiumPayments && <Body3>-</Body3>}

                  {/* ------------------------------- */}

                  {relevantEmployeeClassPlan.employerPremiumPayments === "POST_TAX" && (
                    <>
                      {relevantEmployeeClassPlan.threeYearLookBackPercent && (
                        <Body3>
                          {`Three-Year Look-Back percentage: ${relevantEmployeeClassPlan.threeYearLookBackPercent}%`}
                        </Body3>
                      )}
                      {!relevantEmployeeClassPlan.threeYearLookBackPercent &&
                        strictErrors.threeYearLookBackPercent && (
                          <Body3 redMedium>{strictErrors.threeYearLookBackPercent}</Body3>
                        )}
                      {!relevantEmployeeClassPlan.threeYearLookBackPercent &&
                        !strictErrors.threeYearLookBackPercent && <Body3>-</Body3>}
                    </>
                  )}
                </StackY>
              )}

              {relevantEmployeeClassPlan.employerContribution === "NO" && (
                <StackY dist={8}>
                  <Body3>100% employee paid</Body3>
                  {relevantEmployeeClassPlan.employerPremiumPayments && (
                    <Body3>
                      {`Employees’ premium payments: ${
                        employerPremiumPaymentsValuesRecord[
                          relevantEmployeeClassPlan.employerPremiumPayments
                        ]
                      }`}
                    </Body3>
                  )}
                  {!relevantEmployeeClassPlan.employerPremiumPayments &&
                    strictErrors.employerPremiumPayments && (
                      <Body3 redMedium>{strictErrors.employerPremiumPayments}</Body3>
                    )}
                  {!relevantEmployeeClassPlan.employerPremiumPayments &&
                    !strictErrors.employerPremiumPayments && <Body3>-</Body3>}
                </StackY>
              )}

              {relevantEmployeeClassPlan.employerContribution === "TAX_CHOICE" && (
                <StackY dist={8}>
                  <Body3>Tax Choice</Body3>
                  {getTaxChoiceSelectionText(relevantEmployeeClassPlan, strictErrors)}
                </StackY>
              )}
            </>
          )}
        </AvoidPageBreak>
      </section>
    </StackY>
  );
}

const getTaxChoiceSelectionText = (
  employeeClassPlan: EmployeeClassPlanWithPlan,
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof disabilityContributionsValidationSchema>
  >,
) => {
  if (employeeClassPlan.taxChoice === null || employeeClassPlan.taxChoice.length === 0) {
    return strictErrors.taxChoice ? (
      <Body3 redMedium>{strictErrors.taxChoice}</Body3>
    ) : (
      <Body3>-</Body3>
    );
  }

  if (employeeClassPlan.taxChoice.length === 1) {
    return (
      <>
        <div className="ml-8">
          <UnorderedListItem>
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
              displayTaxChoiceOptionsTypeName[employeeClassPlan.taxChoice[0]!]
            }
          </UnorderedListItem>
        </div>
        <Body3 as="div" redMedium>
          {strictErrors.taxChoice}
        </Body3>
      </>
    );
  }

  return (
    <div className="ml-8">
      {employeeClassPlan.taxChoice.map((choice) => (
        <div key={choice}>
          <UnorderedListItem>{displayTaxChoiceOptionsTypeName[choice]}</UnorderedListItem>
        </div>
      ))}
    </div>
  );
};
