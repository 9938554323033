import clsx from "clsx";
import * as styles from "./Ribbon.module.less";

type RibbonProps = {
  text: string | string[];
  position: "top-left" | "top-right";
};

export const Ribbon = ({ text, position }: RibbonProps) => {
  const className = clsx(
    styles.ribbonContainer,
    position === "top-left" && styles.ribbonTopLeft,
    position === "top-right" && styles.ribbonTopRight,
  );
  const texts = Array.isArray(text) ? text : [text];
  return (
    <div className={className}>
      <div className={styles.ribbons}>
        {texts.map((text, i) => (
          <div key={`Ribbon-${i}`} className={styles.ribbon}>
            {text}
          </div>
        ))}
      </div>
    </div>
  );
};
