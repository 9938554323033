import { unique } from "shared/utils/utils";

export function getPropertiesToUpdate<T extends object>(
  keys: Array<keyof T> | ReadonlyArray<keyof T>,
): (entity: T) => Partial<T> {
  const uniqueKeys = unique(keys.slice());

  return (entity: T) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- required
    return Object.assign({}, ...uniqueKeys.map((k) => (k in entity ? { [k]: entity[k] } : {})));
  };
}
