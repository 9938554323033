import { faInfoCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { BenefitsInThisPolicy } from "client/src/domain/Client/BenefitsInThisPolicy";
import clsx from "clsx";
import pluralize from "pluralize";

import { infoGatheringClientTasks, infoGatheringPolicyTasks } from "shared/data/Tasks";
import { getDaysFromDates } from "shared/utils/date";
import { formatDateFullMonthWithYear } from "shared/utils/format";
import { getUniquePolicyEffectiveDates } from "shared/utils/policy";
import { findPhaseCopy, getPhaseLabels } from "../../hooks/phase";
import { useToggler } from "../../hooks/useToggler";
import { useTrackElementViewed } from "../../utils/analytics";
import { Body4, Body5 } from "../Typography/Typography";

import { OffTrackModal, FallingBehindModal } from "./OffTrackModal";
import { Timeline, TimelineLabel } from "./Timeline";
import * as styles from "./progressTracker.module.less";
import type { TaskStatus } from "shared/data/Tasks";

import type { Policy, Client } from "shared/types/Client";

const eligibleTaskStatus: TaskStatus[] = ["Not Started", "In Progress", "NIGO"];

const isEligibleOnTrackPhase = (client: Client, policy: Policy) =>
  policy.phaseId !== "ONBOARDING_COMPLETE" &&
  (client.tasks?.some(
    (task) =>
      task.dueDate &&
      infoGatheringClientTasks.includes(task.id) &&
      eligibleTaskStatus.includes(task.status),
  ) ||
    policy.policyTasks?.some(
      (task) =>
        task.dueDate &&
        infoGatheringPolicyTasks.includes(task.id) &&
        eligibleTaskStatus.includes(task.status),
    ));

export const getTimelineTheme = (client: Client, policy: Policy) => {
  if (policy.ticketStatus === "Live") return "success";
  if (policy.daysToPolicyEffective === null || !isEligibleOnTrackPhase(client, policy)) {
    return "default";
  }
  /* https://maxwellhealth.atlassian.net/browse/FP-3112 */
  if (
    policy.jiraCreatedAt &&
    policy.policyEffective &&
    getDaysFromDates(policy.policyEffective, policy.jiraCreatedAt) < 21
  ) {
    return "default";
  }
  if (policy.daysToPolicyEffective >= 20 && policy.daysToPolicyEffective <= 30) return "warning";
  if (policy.daysToPolicyEffective < 20) return "danger";
  return "default";
};

const OffTrackInfoLine = ({
  policies,
  theme,
}: {
  policies: Policy[];
  theme: ReturnType<typeof getTimelineTheme>;
}) => {
  const [showModal, setShowModal] = useToggler();
  if (!policies.length) return null;
  const isOffTrack = theme === "danger";
  const isFallingBehind = theme === "warning";
  const offTrackDates = getUniquePolicyEffectiveDates({
    policies,
    format: formatDateFullMonthWithYear,
  });
  const fallingBehindDates = getUniquePolicyEffectiveDates({
    policies,
    format: formatDateFullMonthWithYear,
  });
  return (
    <>
      <p>
        {isFallingBehind && (
          <>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className={clsx(styles.icon, styles.warningIcon)}
            />
            Don’t wait! Your {fallingBehindDates.formattedDates} effective{" "}
            {pluralize("date", fallingBehindDates.dates.length)}{" "}
            {pluralize("is", fallingBehindDates.dates.length)} approaching and you have tasks that
            are due.
          </>
        )}
        {isOffTrack && (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className={clsx(styles.icon, styles.dangerIcon)}
            />
            Take action now! You have important tasks that are due for your benefits effective{" "}
            {offTrackDates.formattedDates}.
          </>
        )}{" "}
        <Button type="text-only" onClick={setShowModal}>
          Learn more
        </Button>
      </p>
      {isOffTrack && (
        <OffTrackModal
          onCancel={setShowModal}
          isVisible={showModal}
          uniquePolicyEffective={offTrackDates.dates}
        />
      )}
      {isFallingBehind && (
        <FallingBehindModal
          onCancel={setShowModal}
          isVisible={showModal}
          uniquePolicyEffective={offTrackDates.dates}
        />
      )}
    </>
  );
};

const OffTrackInfo = ({ client }: { client: Client }) => {
  const riskTrackPolicies: Policy[] = [];
  const offTrackPolicies: Policy[] = [];
  client.policies.forEach((policy) => {
    const policyTheme = getTimelineTheme(client, policy);
    if (policyTheme === "warning" && policy.policyEffective) riskTrackPolicies.push(policy);
    if (policyTheme === "danger" && policy.policyEffective) offTrackPolicies.push(policy);
  });

  if (!riskTrackPolicies.length && !offTrackPolicies.length) return null;

  return (
    <div className={styles.offTrackContainer}>
      <OffTrackInfoLine policies={riskTrackPolicies} theme="warning" />
      <OffTrackInfoLine policies={offTrackPolicies} theme="danger" />
    </div>
  );
};

export const TrackProgressTracker = ({
  client,
  theme,
}: {
  client: Client;
  theme: ReturnType<typeof getTimelineTheme>;
}) => {
  const getStateFromTheme = (theme: ReturnType<typeof getTimelineTheme>) => {
    if (theme === "danger") return "OFF_TRACK";
    if (theme === "warning") return "AT_RISK";
    return "ON_TRACK";
  };
  const trackViewedRef = useTrackElementViewed(
    {
      clientId: client.id,
      clientName: client.name ?? undefined,
      module: "Phase Tracker",
      moduleState: getStateFromTheme(theme),
    },
    window.innerHeight * 0.2,
  );

  return (
    <div
      ref={(elem) => {
        // https://github.com/facebook/react/issues/29196
        trackViewedRef.current = elem;
      }}
    />
  );
};

interface Props {
  client: Client;
  blankTimeline?: boolean;
  showOffTrack?: boolean;
}

export const ProgressTrackerPolicies = ({
  client,
  blankTimeline = false,
  showOffTrack = true,
}: Props) => {
  const phaseLabels = getPhaseLabels(client).filter((val) => val.id !== "REJECTED");
  const phaseIds = phaseLabels.map((l) => l.id);

  return (
    <>
      {client.policies.map((policy) => (
        <div key={policy.id} className={styles.trackerMultiPolicy}>
          <div className={styles.label}>
            <Body4 as="div">
              {policy.policyEffective ? formatDateFullMonthWithYear(policy.policyEffective) : ""}
            </Body4>
            <StackY dist={8} wrap={false}>
              <Body5 as="div">Policy #{policy.slfPolicyNumber}</Body5>
              <BenefitsInThisPolicy policy={policy} />
            </StackY>
          </div>
          <div className={styles.timeline}>
            <Timeline
              items={phaseIds}
              currentItem={blankTimeline ? undefined : findPhaseCopy(client, policy)?.id}
              isComplete={policy.ticketStatus === "Live" && !blankTimeline}
              theme={showOffTrack ? getTimelineTheme(client, policy) : "default"}
            />
            {showOffTrack && (
              <TrackProgressTracker client={client} theme={getTimelineTheme(client, policy)} />
            )}
          </div>
        </div>
      ))}
      <div className={styles.trackerMultiPolicy}>
        <div className={styles.label} />
        <div className={styles.timeline}>
          <TimelineLabel labels={phaseLabels} />
        </div>
      </div>
      {showOffTrack && <OffTrackInfo client={client} />}
    </>
  );
};
