import { Row, Col } from "client/src/components/Grid/Grid";
import { ActivelyAtWorkCard } from "client/src/domain/Client/ActivelyAtWorkCard";
import { useUpdateClient } from "client/src/hooks/client";
import { useGetDocuments } from "client/src/hooks/document";
import pluralize from "pluralize";
import { useCallback } from "react";
import { RouteData } from "shared/config/routeData";
import { getIsLifeCoverageEIF } from "shared/types/SlfCoverages";
import { isAddCoverageClient } from "shared/utils/client";
import { formatDateFullMonthWithYear } from "shared/utils/format";

import { getIsBroker, getIsBenAdmin, getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { effectiveDateSiblings } from "../../../../../shared/utils/policy";
import { Anchor } from "../../../components/Anchor/Anchor";
import { taskStatusToLabel } from "../../../components/Badge/Badge";
import { ChildPageLayout } from "../../../components/ChildPageLayout/ChildPageLayout";
import { DueDateAlert } from "../../../components/DueDate/DueDateAlert";
import { ReactComponent as DentalProtection } from "../../../components/Icons/DentalProtection.svg";
import { SlobLink } from "../../../components/SlobLink/SlobLink";
import { StackY } from "../../../components/Spacing/Spacing";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { CensusCard } from "../../../domain/Client/CensusCard";
import { useSlobAuth } from "../../../hooks/auth";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";
import { getShouldDisplayDueDate } from "../../../utils/getShouldDisplayDueDate";

import * as styles from "./censusPage.module.less";

import type { PolicyTask } from "shared/data/Tasks";
import type { Client, Policy } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type CensusPageProps = {
  client: Client;
  policyTask: PolicyTask;
  policy: Policy;
  featureToggles: ClientFeatureToggles;
};

export function CensusPage({ client, policyTask, policy }: CensusPageProps) {
  const pageTitle = "Submit Enrollment Elections";
  const usingDEIF = client.digitalEIF;

  useTitle(pageTitle);
  const trackElementClicked = useTrackElementClicked(client, pageTitle, policy.id);

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: policyTask.status ? taskStatusToLabel[policyTask.status] : "Not Started",
        buttonLabel,
      });
    },
    [trackElementClicked, policyTask],
  );

  const { mutateAsync: updateClient } = useUpdateClient();

  const {
    isFetching: isFetchingDocuments,
    data: documents,
    error: errorFetchingDocuments,
  } = useGetDocuments({
    clientId: client.id,
    categories: ["actively-at-work", "census-template", "enrollment-elections"],
  });

  const activelyAtWorkDocuments = documents?.filter((doc) => doc.category === "actively-at-work");
  const { authUser } = useSlobAuth();

  const shouldDisplayDueDate = getShouldDisplayDueDate(policyTask);

  const hasLifeCoverage =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsLifeCoverageEIF(coverage)) ?? false;

  const hasDHMO = client.allPoliciesSlfCoverages?.includes("Dental (DHMO)") ?? false;
  const dhmoTipId = "dhmo_tip_1";

  const policyEffective = policy.policyEffective
    ? formatDateFullMonthWithYear(policy.policyEffective)
    : "";

  const policyNumbers = policy.slfPolicyNumber ? [`#${policy.slfPolicyNumber}`] : [];

  if (policy.policyEffective) {
    const siblings = effectiveDateSiblings(policy, client);
    const extraPolicies = siblings.filter((p) => p.id !== policy.id);
    extraPolicies.forEach((p) => {
      if (p.slfPolicyNumber) {
        policyNumbers.push(`#${p.slfPolicyNumber}`);
      }
    });
  }

  const subTitle =
    !policyEffective || client.policies.length < 2
      ? undefined
      : `For benefits effective ${policyEffective}${
          policyNumbers.length
            ? `, ${pluralize("policy", policyNumbers.length)} ${policyNumbers.join(" & ")}`
            : ""
        }`;

  const isAddCoverage = Boolean(isAddCoverageClient(client));

  return (
    <ChildPageLayout
      topNavTitle={pageTitle}
      task={policyTask}
      aside={
        <>
          <h1>{pageTitle}</h1>

          {shouldDisplayDueDate && (
            <>
              <DueDateAlert task={policyTask} />
              <br />
            </>
          )}
          <p>
            Enter your employee enrollment elections into your custom census template and upload
            that file here to send it to Sun Life securely. This will be used to set up your Billing
            with Sun Life.
          </p>
          {isAddCoverage ? (
            <>
              <p>
                This census should only be updated with new coverage, as you do not need to update
                coverage that is remaining the same. If you are planning on going through open
                enrollment, reach out to your IC for your next steps.
              </p>
              <p>
                Take a few extra minutes to review and ensure that all required information is
                accurate and doesn't require updating. You'll want to make sure the information is
                formatted correctly so follow the instructions provided by your Implementation
                Consultant during your Kickoff Call as well as what is noted in the required columns
                of the template.
              </p>
            </>
          ) : (
            <p>
              Take a few extra minutes to review and ensure that all required information is
              included and in good order. You'll want to make sure the information is formatted
              correctly so follow the instructions provided by your Implementation Consultant during
              your Kickoff Call as well as what is noted in the required columns of the template.
            </p>
          )}
          <p>
            Sun Life will review the file you upload and if the information in your census is in
            good order, will confirm next steps. If anything else is needed, your Implementation
            Consultant will work with you on how to re-submit.
          </p>
        </>
      }
      contents={
        <>
          <StackY dist={40}>
            <CensusCard
              client={client}
              subTitle={subTitle}
              dhmoTipId={dhmoTipId}
              hasDHMO={hasDHMO}
              task={policyTask}
              policy={policy}
              isUserMFA={authUser?.hasMfa ?? false}
              isInternalUser={getIsInternalUser(authUser)}
              isBrokerOrBA={getIsBroker(authUser) || getIsBenAdmin(authUser)}
              trackElementClicked={trackElementClicked}
              track={track}
              errorFetchingDocuments={errorFetchingDocuments}
              documents={documents}
            />

            {hasLifeCoverage && (
              <ActivelyAtWorkCard
                client={client}
                trackElementClicked={trackElementClicked}
                documents={activelyAtWorkDocuments}
                isFetchingDocuments={isFetchingDocuments}
                updateClient={updateClient}
              />
            )}

            <div>
              <h3>Top tips for completing this task</h3>

              <StackY dist={24}>
                {isAddCoverage && (
                  <p>
                    <b className="body2">
                      You only need to include the enrollments for each new line of coverage.
                    </b>
                  </p>
                )}
                <p>
                  <b className="body2">Be mindful of the due date</b>
                  <br />A delay in completing this task may delay your benefits from being installed
                  prior to your effective date. This could impact your employees’ ability to access
                  coverage, too.
                </p>

                <p>
                  <b className="body2">Use the customized template file provided for you above</b>
                  <br />
                  It is best to send your census data to our team formatted into the customized
                  template file provided to you above. Utilizing this format enables our team to
                  quickly identify if we have any questions so we can keep your timeline on track.
                  Enrolling your employees timely and accurately ensures that your bill is delivered
                  on time and your employees can access coverage on their effective date.
                </p>

                <p>
                  <b className="body2">
                    For more tips on how to complete your customized template file, please see this{" "}
                    <Anchor
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403008426131"
                      target="_blank"
                      variant="bodyText"
                      onClick={() => track("Help Center article")}
                    >
                      Help Center article here.
                    </Anchor>
                  </b>
                </p>

                {hasDHMO && (
                  <>
                    <p id={dhmoTipId} style={{ scrollMarginTop: 90 }}>
                      <b className="body2">
                        Be sure to include dental office facility IDs, for Dental (DHMO - Prepaid)
                      </b>
                      <br />
                      Finding an in-network dentist is important with DHMO - prepaid dental plans.
                      You must include an in-network dentist facility ID # for each employee and
                      family member enrolling in this plan. Dental offices use this to add members
                      to their patient roster each month. Only members listed on the roster can use
                      plan benefits for dental services.
                    </p>

                    <p>
                      Employees will be delayed in using their dental plan benefits until they have
                      an assigned dental office ID.
                    </p>

                    <p>
                      <b className="body2">Find a dentist and facility ID for your employees</b>
                      <br />
                      Sun Life’s Dental DHMO - Prepaid plan requires employees to choose an
                      in-network dentist and note the facility ID at enrollment. You can find a
                      dental facility ID # for an employee using the instructions below or share
                      these steps with employees:
                    </p>

                    <Row gutter={24} align="middle">
                      <Col>
                        <DentalProtection />
                      </Col>

                      <Col className={styles.dhmoListColumn}>
                        <UnorderedList>
                          <UnorderedListItem>
                            <Anchor
                              href="https://www.sunlife.com/us/Resources/Tools/Find+a+dentist/"
                              target="_blank"
                            >
                              Visit Sun Life's <b>Find a Dentist</b> website
                            </Anchor>
                          </UnorderedListItem>
                          <UnorderedListItem>
                            Under <b>DHMO or Prepaid Dental Plan</b> section, select the state and
                            choose the plan network.
                          </UnorderedListItem>
                          <UnorderedListItem>
                            Use the <b>Find a Dentist search</b> to view the listing of available
                            dentists and facility IDs.
                          </UnorderedListItem>
                        </UnorderedList>
                      </Col>
                    </Row>

                    <p>
                      <Anchor
                        href="https://p1.aprimocdn.net/sunlife/ac6432c8-442f-4a7c-8ea5-b01800e4c9d7/gdfl-7896-mh-mh-dhmo-prepaid-dentist-selection-original-file.pdf"
                        target="_blank"
                      >
                        More details on finding an in-network dentist
                      </Anchor>
                    </p>
                  </>
                )}
              </StackY>
            </div>

            <div>
              <h3>FAQs</h3>

              <StackY dist={24}>
                <p>
                  <b className="body2">Can I automate this and set up a data feed?</b> <br />
                  Your first census upload with Sun Life needs to be done using the customized
                  template file provided to you above. However, automation can be set up for ongoing
                  service with Sun Life after your onboarding has been completed. You can request a
                  data feed on your{" "}
                  <SlobLink
                    variant="normal"
                    to={
                      usingDEIF
                        ? RouteData.eifSubStepDetail.getPath(
                            client.id,
                            "company-information",
                            "benefits-administration-and-data-feed",
                          )
                        : RouteData.clientTaskDetail.getPath(client.id, "company-profile")
                    }
                    onClick={() => track("Company Profile page")}
                  >
                    {usingDEIF ? "Company Details page." : "Company Profile page."}
                  </SlobLink>
                </p>

                <p>
                  <b className="body2">Can I upload more than one file?</b> <br />
                  We encourage you to consolidate your census information onto the customized
                  template file provided. This makes the process easier and straightforward for both
                  you and your broker as well as Sun Life. If multiple files are provided, this will
                  impact your overall timeline as it will take some time for Sun Life to review each
                  file and consolidate on your behalf onto the customized template file.{" "}
                  <Anchor
                    href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4830223463059"
                    target="_blank"
                  >
                    Learn more about completing your template.
                  </Anchor>
                </p>

                <p>
                  <b className="body2">
                    What if I have changes to information after I’ve uploaded my census file?
                  </b>{" "}
                  <br />
                  If you have new enrollments, changes or realize you missed submitting something
                  with your upload, please reach out to your Implementation Consultant to notify and
                  discuss with them before uploading another document here.
                </p>

                {hasDHMO && (
                  <p>
                    <b>What do my employees need to know about Dental (DHMO - Prepaid) plans?</b>
                    <br />
                    Employees can use their Sun Life account to access details about their dental
                    plan, including finding a dentist, the plan setup and dental ID card.{" "}
                    <Anchor
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403020995603-What-do-my-employees-need-to-know-about-dental-"
                      target="_blank"
                    >
                      What my employees need to know about dental.
                    </Anchor>
                  </p>
                )}
              </StackY>
            </div>
          </StackY>
        </>
      }
    />
  );
}
