import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { FormInput } from "client/src/components/Form/Input";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobSelectMultiple } from "client/src/components/MultiSelect/SlobSelectMultiple";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { ContactCard } from "client/src/domain/Contact/ContactCard";
import { ContactDropdown } from "client/src/domain/Contact/ContactDropdown";
import { ClaimSentToHomeAddressMsg } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/claimsCheckMailingLocations";
import { getCoverageWithPolicyNumbersLabel } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getCoverageWithPolicyNumbersLabel";
import { getTagLabelRenderForBenefitChip } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/getTagLabelRenderForBenefitChip";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useUpdateContact, type CreateContactInput } from "client/src/hooks/contact";
import { triggerError } from "client/src/hooks/generalError";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { getLocationStateCode, LocationStateData } from "shared/data/LocationState";
import { type BenefitTypeEIF } from "shared/types/BenefitTypes";
import { type Client } from "shared/types/Client";
import { LocationStateCodes, getIsLocationStateCode } from "shared/types/Location";
import { sendClaimsCheckTo } from "shared/types/Plan";
import { extractFullName } from "shared/utils/format";
import { getClaimsCheckMailingLocationsInitialFormValues } from "shared/utils/plan";
import { assertIsDefined } from "shared/utils/utils";
import { maskPhoneNumber } from "shared/validation/contact";
import { locationStateCodeValidation, zipCodeValidation } from "shared/validation/location";
import {
  claimsCheckPayeeValidation,
  planAdminPayeeContactIdValidation,
} from "shared/validation/plan";
import {
  nullNotAllowedTestConfig,
  undefinedNotAllowedTestConfig,
} from "shared/validation/validation";
import * as Yup from "yup";
import type { ClaimsCheckPayee, SendClaimsCheckTo } from "@prisma/client";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { CreateContactQuery } from "client/src/hooks/contact";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { FormikErrors } from "formik";
import type { Dispatch, SetStateAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Contact } from "shared/types/Contact";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { ClaimCheckMailingLocationContactProps } from "shared/validation/plan";

export const claimsCheckMailingLocationValidationSchema = Yup.object({
  sendClaimsCheckTo: Yup.mixed<SendClaimsCheckTo>()
    .oneOf<SendClaimsCheckTo>(sendClaimsCheckTo, "Please provide a response")
    .nullable()
    .test(nullNotAllowedTestConfig())
    .test(undefinedNotAllowedTestConfig()),
  selectedBenefitTypes: Yup.array()
    .of(Yup.mixed<BenefitTypeEIF>().required())
    .min(1, "Please select at least one benefit")
    .required(),
  claimsCheckPayee: claimsCheckPayeeValidation,
  planAdminPayeeContactId: planAdminPayeeContactIdValidation,
  someoneElsePayeeContactId: Yup.string().optional().nullable(), // optional since we don't have a contact id yet when we start submitting
  fullName: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.required("Full name is required").nullable(),
    })
    .nullable(),
  firstName: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.required("First name is required").nullable(),
    })
    .nullable(),
  lastName: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.required("Last name is required").nullable(),
    })
    .nullable(),
  email: Yup.string()
    .trim()
    .emailV1("Email must be in a valid format i.e. - example@example.com")
    .nullable()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.trim().required("Email is required"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  phoneNumber: Yup.string()
    .transform((val: string | undefined) => (val ? val : null))
    .nullable()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) =>
        schema
          .required("Phone number is required")
          .typeError("Phone number is required")
          .matches(/^\d{3}-\d{3}-\d{4}$/, "Phone number needs to be in the format ###-###-####"),
    }),
  address1: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.trim().nullable().required("Address is required"),
    })
    .nullable(),
  address2: Yup.string()
    .optional()
    .when(["$prefill"], {
      is: (prefill: boolean) => !!prefill,
      then: (schema) => schema.notRequired(),
    })
    .nullable(),
  city: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) => schema.trim().nullable().required("City is required"),
    })
    .nullable(),
  state: locationStateCodeValidation
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (schema) =>
        schema.nullable().test({
          name: "state",
          message: "State is required",
          test: (val) => Boolean(getIsLocationStateCode(val ?? "")),
        }),
      otherwise: (_) => Yup.string().optional().nullable(),
    })
    .nullable(),
  zipCode: Yup.string()
    .optional()
    .when(["claimsCheckPayee", "$prefill"], {
      is: (claimsCheckPayee: ClaimsCheckPayee, prefill: boolean) =>
        claimsCheckPayee === "SOMEONE_ELSE" && !prefill,
      then: (_) => zipCodeValidation.nullable().required("Zip code is required"),
    })
    .nullable(),
});

type Props = {
  client: Client;
  clientPlans: Plan[];
  contacts: Contact[];
  relevantPlans: Plan[];
  initialPlans: Plan[];
  updateClient: UpdateClientFunc;
  updatePlansQuery: UpdatePlansQuery;
  createContactQuery: CreateContactQuery;
  editingType?: string;
  setEditPreferences: Dispatch<SetStateAction<boolean>>;
  setEditingType: Dispatch<SetStateAction<string | null>>;
  index: number;
  authUser: UserData | null;
  changeSnapshot: DEIFChangeSnapshot;
  hasLTD?: boolean;
  hasFLI?: boolean;
  featureToggles: ClientFeatureToggles;
  claimsCheckPrefillErrors: FormikErrors<
    Pick<
      Plan,
      | "sendClaimsCheckTo"
      | "claimsCheckPayee"
      | "planAdminPayeeContactId"
      | "someoneElsePayeeContactId"
    > &
      Pick<Contact, ClaimCheckMailingLocationContactProps>
  >;
};

export const EIFClaimsCheckMailingLocationSelection = ({
  client,
  clientPlans,
  contacts,
  relevantPlans,
  initialPlans,
  updateClient,
  updatePlansQuery,
  createContactQuery,
  setEditPreferences,
  setEditingType,
  index,
  authUser,
  changeSnapshot,
  featureToggles,
  claimsCheckPrefillErrors,
}: Props) => {
  const claimsCheckToFieldName = "sendClaimsCheckTo";
  const claimsCheckPayeeFieldName = "claimsCheckPayee";
  const planAdminPayeeContactIdFieldName = "planAdminPayeeContactId";
  const someoneElsePayeeContactIdFieldName = "someoneElsePayeeContactId";
  const selectablePlans = relevantPlans.filter((p) => p.sendClaimsCheckTo === null);
  const initialBenefitTypes = initialPlans.map((plan) => plan.benefitType);

  const {
    mutateAsync: createContact,
    isError: isErrorCreatingContact,
    error: errorCreatingContact,
  } = createContactQuery;

  const {
    mutateAsync: updateContact,
    isError: isErrorUpdatingContact,
    error: errorUpdatingContact,
  } = useUpdateContact();

  const planAdminContacts = contacts.filter(
    (contact) => contact.type === "PRIMARY_WEB_ADMIN" || contact.type === "WEB_ADMIN",
  );

  const planForInitialValues =
    initialPlans.length > 0
      ? getClaimsCheckMailingLocationsInitialFormValues(initialPlans, contacts)[0]
      : null;

  const planForInitialValuesUsesSomeoneElse =
    planForInitialValues?.someoneElsePayeeContactId !== null;

  const formik = useSlobFormik({
    validationSchema: claimsCheckMailingLocationValidationSchema,
    initialValues: {
      sendClaimsCheckTo: planForInitialValues?.sendClaimsCheckTo,
      selectedBenefitTypes:
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- we don't sparsely populate arrays
        relevantPlans.length === 1 ? [relevantPlans[0]!.benefitType] : initialBenefitTypes,
      claimsCheckPayee: planForInitialValues?.claimsCheckPayee,
      planAdminPayeeContactId: planForInitialValues?.planAdminPayeeContactId,
      someoneElsePayeeContactId: planForInitialValues?.someoneElsePayeeContactId,
      fullName:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.fullName
          : "",
      address1:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.address1
          : "",
      address2:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.address2
          : null,
      city:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.city
          : "",
      firstName:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.firstName
          : "",
      lastName:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.lastName
          : "",
      email:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.email
          : null,
      phoneNumber:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.phoneNumber
          : "",
      state:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.state
          : null,
      zipCode:
        planForInitialValuesUsesSomeoneElse && planForInitialValues
          ? planForInitialValues.zipCode
          : "",
    },
    onSubmit: async (values) => {
      if (client.claimsCheckPreferencesDefaults !== "EDITED_DEFAULTS") {
        await updateClient({
          params: { clientId: client.id },
          data: {
            claimsCheckPreferencesDefaults: "EDITED_DEFAULTS",
          },
          query: { prefill: true },
        });
      }

      const planAdminPayeeContactId =
        values.claimsCheckPayee === "PLAN_ADMINISTRATOR" ? values.planAdminPayeeContactId : null;

      const claimsCheckGoingToSomeoneElse = values.claimsCheckPayee === "SOMEONE_ELSE";
      let someoneElsePayeeContactId = claimsCheckGoingToSomeoneElse
        ? values.someoneElsePayeeContactId
        : null;

      if (claimsCheckGoingToSomeoneElse) {
        let payload: CreateContactInput = {
          policyId: null,
          firstName: values.firstName ?? null,
          lastName: values.lastName ?? null,
          email: values.email ?? null,
          phoneNumber: values.phoneNumber ?? null,
          type: "CLAIMS_CHECK_PAYEE" as const,
          accessesForPolicyIds: [],
          location: {
            name: null,
            address1: values.address1 ?? null,
            address2: values.address2 ?? null,
            city: values.city ?? null,
            state: values.state ?? null,
            zipCode: values.zipCode ?? null,
            locationType: null,
          },
        };

        if (!someoneElsePayeeContactId) {
          const { data: createdContact } = await createContact({
            params: { clientId: client.id },
            data: payload,
          });

          someoneElsePayeeContactId = createdContact.id;
        } else {
          const contactToUpdate = contacts.filter((c) => c.id === someoneElsePayeeContactId)[0];
          assertIsDefined(contactToUpdate, "contactToUpdate");

          if (contactToUpdate.location === null) {
            payload = {
              policyId: null,
              firstName: values.firstName ?? null,
              lastName: values.lastName ?? null,
              email: values.email ?? null,
              phoneNumber: values.phoneNumber ?? null,
              type: "CLAIMS_CHECK_PAYEE" as const,
              accessesForPolicyIds: [],
              address1: values.address1 ?? null,
              address2: values.address2 ?? null,
              city: values.city ?? null,
              state: values.state ?? null,
              zipCode: values.zipCode ?? null,
              location: undefined,
            };
          }

          await updateContact({
            data: payload,
            params: { clientId: client.id, contactId: someoneElsePayeeContactId },
          });
        }
      }

      const plansToAddOrUpdate = [...selectablePlans, ...initialPlans]
        .filter((plan) => values.selectedBenefitTypes.includes(plan.benefitType))
        .map((plan) => {
          return {
            id: plan.id,
            clientId: client.id,
            sendClaimsCheckTo: values.sendClaimsCheckTo,
            claimsCheckPayee:
              values.sendClaimsCheckTo === "EMPLOYEE_HOME" ? null : values.claimsCheckPayee,
            planAdminPayeeContactId:
              values.sendClaimsCheckTo === "EMPLOYEE_HOME" ? null : planAdminPayeeContactId,
            someoneElsePayeeContactId:
              values.sendClaimsCheckTo === "EMPLOYEE_HOME" ? null : someoneElsePayeeContactId,
          };
        });

      const plansToRemove = initialPlans
        .filter((plan) => !plansToAddOrUpdate.some((p) => p.id === plan.id))
        .map((plan) => {
          return {
            id: plan.id,
            clientId: client.id,
            sendClaimsCheckTo: null,
            claimsCheckPayee: null,
            planAdminPayeeContactId: null,
            someoneElsePayeeContactId: null,
          };
        });

      await updatePlansQuery.mutateAsync({
        params: { clientId: client.id },
        data: { plans: [...plansToAddOrUpdate, ...plansToRemove] },
      });
      setEditPreferences(false);
      setEditingType(null);
    },
    validationContext: { prefill: true },
  });

  const planAdminPayeeContact =
    formik.values[claimsCheckPayeeFieldName] === "PLAN_ADMINISTRATOR"
      ? contacts.find((contact) => contact.id === formik.values[planAdminPayeeContactIdFieldName])
      : undefined;

  const [planAdminContactCardVisible, togglePlanAdminContactCardVisible] = useToggler(
    !!planAdminPayeeContact,
  );

  const someoneElsePayeeContact =
    formik.values[claimsCheckPayeeFieldName] === "SOMEONE_ELSE"
      ? contacts.find((contact) => contact.id === formik.values[someoneElsePayeeContactIdFieldName])
      : undefined;

  const disableSaveButton =
    formik.values.sendClaimsCheckTo === undefined &&
    formik.values.selectedBenefitTypes.length === 0;

  const [someoneElseContactCardVisible, toggleSomeoneElseContactCardVisible] = useToggler(
    !!someoneElsePayeeContact,
  );

  if (isErrorCreatingContact) {
    triggerError(errorCreatingContact);
  }

  if (isErrorUpdatingContact) {
    triggerError(errorUpdatingContact);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={16}>
          <h5>Mailing Location {index + 1}</h5>
          <RadioGroup<SendClaimsCheckTo>
            name="sendClaimsCheckTo"
            touched={
              formik.touched.sendClaimsCheckTo || !!claimsCheckPrefillErrors.sendClaimsCheckTo
            }
            error={formik.errors.sendClaimsCheckTo || claimsCheckPrefillErrors.sendClaimsCheckTo}
            value={formik.values.sendClaimsCheckTo}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            direction="vertical"
            label={<Body2>Where should Sun Life send employee claims checks?</Body2>}
            options={[
              {
                value: "EMPLOYEE_HOME",
                label: (
                  <StackX dist={12} className="pt-4">
                    <Body3>To the employee at their home address</Body3>
                    <Body5>Most Common</Body5>
                  </StackX>
                ),
                contentSpacing: "tight",
              },
              {
                value: "EMPLOYER",
                label: <Body3>To the employer with a salary continuation plan</Body3>,
                contentSpacing: "tight",
              },
            ]}
          />
          <div className="ml-32">
            <BulbMessage bulbPosition="top" tighter>
              This option is only available if you:
              <UnorderedList markerColor="black" tighter>
                <UnorderedListItem>have a salary continuation plan</UnorderedListItem>
                <UnorderedListItem>are setting up claims reimbursement</UnorderedListItem>
                <UnorderedListItem>pay a salary continuation to your employees</UnorderedListItem>
              </UnorderedList>
            </BulbMessage>
          </div>
          {formik.values[claimsCheckToFieldName] === "EMPLOYER" && (
            <div className="ml-32 stack-y-8">
              <RadioGroup<ClaimsCheckPayee>
                name="claimsCheckPayee"
                touched={
                  formik.touched.claimsCheckPayee || !!claimsCheckPrefillErrors.claimsCheckPayee
                }
                error={formik.errors.claimsCheckPayee || claimsCheckPrefillErrors.claimsCheckPayee}
                value={formik.values.claimsCheckPayee}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                direction="vertical"
                label={
                  <Body2>
                    Claims will be made payable and mailed to the following contact at the employer
                    for the length of the salary continuation plan:
                  </Body2>
                }
                options={[
                  {
                    value: "PLAN_ADMINISTRATOR",
                    label: "To a plan administrator",
                    contentSpacing: "tight",
                    content: (
                      <>
                        {formik.values[claimsCheckPayeeFieldName] === "PLAN_ADMINISTRATOR" && (
                          <div className="ml-32 stack-y-16">
                            {planAdminPayeeContact && planAdminContactCardVisible ? (
                              <ContactCard
                                client={client}
                                bills={[]} // Bill access not relevant in this step
                                authUser={authUser}
                                contact={planAdminPayeeContact}
                                changeSnapshot={changeSnapshot}
                                allowEdit={false}
                                allowRemove
                                onRemove={async () => {
                                  await formik.setFieldValue(
                                    planAdminPayeeContactIdFieldName,
                                    undefined,
                                  );
                                  togglePlanAdminContactCardVisible();
                                }}
                                disabled={formik.isSubmitting}
                                featureToggles={featureToggles}
                              />
                            ) : (
                              <>
                                <ContactDropdown
                                  client={client}
                                  id={planAdminPayeeContactIdFieldName}
                                  name={planAdminPayeeContactIdFieldName}
                                  label="" // @todo: is this accessible?
                                  aria-label={planAdminPayeeContactIdFieldName}
                                  placeholder="Select a user*"
                                  disabled={formik.isSubmitting}
                                  loading={formik.isSubmitting}
                                  value={planAdminPayeeContact?.id}
                                  touched={
                                    formik.touched[planAdminPayeeContactIdFieldName] ||
                                    !!claimsCheckPrefillErrors[planAdminPayeeContactIdFieldName]
                                  }
                                  error={
                                    formik.errors[planAdminPayeeContactIdFieldName] ||
                                    claimsCheckPrefillErrors[planAdminPayeeContactIdFieldName]
                                  }
                                  onChange={async (event) => {
                                    // @todo: do we need to check for billing access on the slfc user?
                                    await formik.setFieldValue(
                                      planAdminPayeeContactIdFieldName,
                                      event.value,
                                    );
                                    togglePlanAdminContactCardVisible();
                                  }}
                                  contacts={planAdminContacts}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </>
                    ),
                  },
                  {
                    value: "SOMEONE_ELSE",
                    label: "To someone else at the employer as the payee",
                    contentSpacing: "tight",
                    content: (
                      <>
                        {formik.values[claimsCheckPayeeFieldName] === "SOMEONE_ELSE" && (
                          <div className="ml-32 stack-y-16">
                            {someoneElsePayeeContact &&
                            someoneElseContactCardVisible &&
                            !claimsCheckPrefillErrors ? (
                              <ContactCard
                                client={client}
                                bills={[]} // Bill access not relevant in this step
                                authUser={authUser}
                                contact={someoneElsePayeeContact}
                                changeSnapshot={changeSnapshot}
                                allowEdit={false}
                                allowRemove
                                onRemove={async () => {
                                  await formik.setFieldValue(
                                    someoneElsePayeeContactIdFieldName,
                                    undefined,
                                  );
                                  toggleSomeoneElseContactCardVisible();
                                }}
                                disabled={formik.isSubmitting}
                                featureToggles={featureToggles}
                              />
                            ) : (
                              <StackY dist={24} wrap={false}>
                                <FormInput
                                  name="fullName"
                                  label="Payee name (as it should be written on the check)"
                                  disabled={formik.isSubmitting}
                                  onChange={async (event) => {
                                    const { name, value } = event.target;
                                    const { firstName, lastName } = extractFullName(value);
                                    await formik.setFieldValue(name, value);
                                    await formik.setFieldValue("firstName", firstName);
                                    await formik.setFieldValue("lastName", lastName);
                                  }}
                                  touched={
                                    formik.touched.fullName ||
                                    !!(
                                      claimsCheckPrefillErrors.firstName ||
                                      claimsCheckPrefillErrors.lastName
                                    )
                                  }
                                  value={formik.values.fullName}
                                  error={
                                    formik.errors.fullName ||
                                    formik.errors.firstName ||
                                    formik.errors.lastName ||
                                    claimsCheckPrefillErrors.firstName ||
                                    claimsCheckPrefillErrors.lastName
                                  }
                                  maxLength={191}
                                />

                                <Row gutter={24}>
                                  <Col span={12}>
                                    <FormInput
                                      name="email"
                                      label="Email address"
                                      disabled={formik.isSubmitting}
                                      onChange={formik.handleChange}
                                      touched={
                                        formik.touched.email || !!claimsCheckPrefillErrors.email
                                      }
                                      value={formik.values.email}
                                      error={formik.errors.email || claimsCheckPrefillErrors.email}
                                      maxLength={191}
                                    />
                                  </Col>
                                  <Col span={12}>
                                    <FormInput
                                      name="phoneNumber"
                                      label="Phone number"
                                      disabled={formik.isSubmitting}
                                      onChange={async (e) => {
                                        const { value } = e.currentTarget;
                                        const maskedPhoneNumber = maskPhoneNumber(value);
                                        await formik.setFieldValue(
                                          "phoneNumber",
                                          maskedPhoneNumber || null,
                                        );
                                      }}
                                      touched={
                                        formik.touched.phoneNumber ||
                                        !!claimsCheckPrefillErrors.phoneNumber
                                      }
                                      value={formik.values.phoneNumber}
                                      error={
                                        formik.errors.phoneNumber ||
                                        claimsCheckPrefillErrors.phoneNumber
                                      }
                                      maxLength={12}
                                    />
                                  </Col>
                                </Row>

                                <FormInput
                                  name="address1"
                                  label="Address 1"
                                  disabled={formik.isSubmitting}
                                  onChange={formik.handleChange}
                                  touched={
                                    formik.touched.address1 || !!claimsCheckPrefillErrors.address1
                                  }
                                  value={formik.values.address1}
                                  error={
                                    formik.errors.address1 || claimsCheckPrefillErrors.address1
                                  }
                                  maxLength={191}
                                />

                                <FormInput
                                  name="address2"
                                  label="Address 2 (optional)"
                                  disabled={formik.isSubmitting}
                                  onChange={formik.handleChange}
                                  touched={
                                    formik.touched.address2 || !!claimsCheckPrefillErrors.address2
                                  }
                                  value={formik.values.address2}
                                  error={
                                    formik.errors.address2 || claimsCheckPrefillErrors.address2
                                  }
                                  maxLength={191}
                                />

                                <Row gutter={24}>
                                  <Col span={11}>
                                    <FormInput
                                      name="city"
                                      label="City"
                                      disabled={formik.isSubmitting}
                                      onChange={formik.handleChange}
                                      touched={
                                        formik.touched.city || !!claimsCheckPrefillErrors.city
                                      }
                                      value={formik.values.city}
                                      error={formik.errors.city || claimsCheckPrefillErrors.city}
                                      maxLength={191}
                                    />
                                  </Col>
                                  <Col span={9}>
                                    <SlobSelect
                                      name="state"
                                      value={formik.values.state || null}
                                      options={LocationStateCodes.map((stateCode) => ({
                                        label: LocationStateData[stateCode].displayName,
                                        value: stateCode,
                                      }))}
                                      placeholder="State"
                                      onChange={(event) =>
                                        formik.setFieldValue("state", event.value)
                                      }
                                      disabled={formik.isSubmitting}
                                      touched={
                                        formik.touched.state || !!claimsCheckPrefillErrors.state
                                      }
                                      error={formik.errors.state || claimsCheckPrefillErrors.state}
                                      onSearch={async (val) => {
                                        // TB-6250: support browser autofill for state
                                        const stateCode = getLocationStateCode(val);
                                        if (stateCode) {
                                          await formik.setFieldValue("state", stateCode);
                                        }
                                      }}
                                    />
                                  </Col>
                                  <Col span={4}>
                                    <FormInput
                                      name="zipCode"
                                      label="Zip"
                                      disabled={formik.isSubmitting}
                                      onChange={formik.handleChange}
                                      touched={
                                        formik.touched.zipCode || !!claimsCheckPrefillErrors.zipCode
                                      }
                                      value={formik.values.zipCode}
                                      error={
                                        formik.errors.zipCode || claimsCheckPrefillErrors.zipCode
                                      }
                                      maxLength={191}
                                    />
                                  </Col>
                                </Row>
                              </StackY>
                            )}
                          </div>
                        )}
                      </>
                    ),
                  },
                ]}
              />
            </div>
          )}

          {relevantPlans.length > 1 && (
            <>
              <label
                htmlFor="claimsCheckBenefitSelection"
                className="mb-16"
                style={{ display: "block" }}
              >
                <Body2>This check mailing location will apply to:</Body2>
              </label>
              <SlobSelectMultiple
                placeholder="Select benefits"
                id={"claimsCheckBenefitSelection"}
                name={"claimsCheckBenefitSelection"}
                maxTagTextLength={50}
                touched={formik.touched.selectedBenefitTypes}
                error={
                  Array.isArray(formik.errors.selectedBenefitTypes)
                    ? formik.errors.selectedBenefitTypes[0]
                    : formik.errors.selectedBenefitTypes
                }
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                options={[...selectablePlans, ...initialPlans].map((plan) => {
                  const label = getCoverageWithPolicyNumbersLabel({
                    client,
                    benefitType: plan.benefitType,
                  });

                  return {
                    value: plan.benefitType,
                    label,
                  };
                })}
                value={formik.values.selectedBenefitTypes.map((selectedBenefitType) => ({
                  value: selectedBenefitType,
                  label: getCoverageWithPolicyNumbersLabel({
                    client,
                    benefitType: selectedBenefitType,
                  }),
                }))}
                onChange={(val) => {
                  if (val) {
                    void formik.setFieldValue(
                      "selectedBenefitTypes",
                      val.map((v) => v.value),
                    );
                  }
                }}
                tagLabelRender={getTagLabelRenderForBenefitChip}
              />
            </>
          )}

          <ClaimSentToHomeAddressMsg client={client} clientPlans={clientPlans} variant="dimmed" />

          <Row justify="end">
            <Col>
              <Button
                type="text"
                size="small"
                disabled={formik.isSubmitting}
                onClick={() => {
                  setEditPreferences(false);
                  setEditingType(null);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="secondary"
                size="small"
                disabled={formik.isSubmitting || disableSaveButton}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};
