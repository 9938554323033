import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import * as Yup from "yup";
import { FormInput } from "../Form/Input";
import { Body2 } from "../Typography/Typography";
import { ConfirmDialog } from "./ConfirmDialog";

type Props = {
  explorerPageName: string;
  isVisible: boolean;
  onCancel: () => void;
  handleDelete: () => Promise<void>;
};

export const ConfirmDialogDeleteExplorerPage = ({
  explorerPageName,
  isVisible,
  handleDelete,
  onCancel,
}: Props) => {
  const formik = useSlobFormik({
    validationSchema: Yup.object({
      explorerPageNameToDelete: Yup.string()
        .required()
        .test(
          "should match the site name",
          "Please enter the site's name exactly as it is displayed to confirm",
          (value) => value === explorerPageName,
        ),
    }),
    initialValues: { explorerPageNameToDelete: "" },
    onSubmit: handleDelete,
  });

  return (
    <ConfirmDialog
      title="Delete site"
      isVisible={isVisible}
      onCancel={onCancel}
      onConfirm={formik.submitForm}
      isLoading={formik.isSubmitting}
      confirmActionText={"Delete"}
      cancelActionText={"Cancel"}
    >
      <>
        <p>
          Are you sure you want to delete this site?
          <br />
          <Body2>{explorerPageName}</Body2>
        </p>

        <p>
          Deleting a site can’t be undone and will{" "}
          <Body2 redMedium>delete all data and documents associated with this site.</Body2>
        </p>

        <form>
          <FormInput
            name="explorerPageNameToDelete"
            value={formik.values.explorerPageNameToDelete}
            onChange={formik.handleChange}
            label={`Type "${explorerPageName}" to confirm`}
            maxLength={explorerPageName.length}
            error={formik.errors.explorerPageNameToDelete}
            touched={formik.touched.explorerPageNameToDelete}
          />
        </form>
      </>
    </ConfirmDialog>
  );
};
