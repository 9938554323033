import { ProgressModal } from "client/src/components/Modal/ProgressModal";
import clsx from "clsx";
import { useCallback } from "react";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { Button } from "../../../components/Button/Button";
import { Card } from "../../../components/Cards/Card";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import { DownloadIcon } from "../../../components/Icons/DownloadIcon";
import { StackY } from "../../../components/Spacing/Spacing";
import { useSlobAuth } from "../../../hooks/auth";
import { useGetClientByID } from "../../../hooks/client";
import { useGetCensusValidations } from "../../../hooks/document";
import { triggerError } from "../../../hooks/generalError";
import { useClientHubParams } from "../../../hooks/useClientHubParams";
import { useTrackElementClicked } from "../../../utils/analytics";
import {
  DocumentDownloadV2,
  useDocumentDownload,
} from "../../Document/DocumentDownload/DocumentDownload";

import {
  generateFile,
  getValidationResultData,
  ValidationResultList,
} from "./CensusValidateSubmit";
import * as styles from "./censusDetail.module.less";

export const CensusValidationReportTab = () => {
  const { clientId, policyId, documentId } = useClientHubParams([
    "clientId",
    "policyId",
    "documentId",
  ]);

  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientByID(clientId);

  const trackElementClicked = useTrackElementClicked(
    client,
    "Submit Enrollment Elections",
    policyId,
  );

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "File validation report",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const { authUser } = useSlobAuth();

  const documentDownloadData = useDocumentDownload({
    clientId,
    policyId,
    documentId,
    categories: ["enrollment-elections"],
    track,
    taskStatus: "In Progress",
    showDownloadButton: getIsInternalUser(authUser),
    showDownloadAllButton: false,
  });

  const document = (documentDownloadData.documents.data || []).find(
    (d) => d.censusSource === "MDT",
  );

  const isValidDocument =
    document?.censusSource === "MDT" &&
    (document?.processStatus === "SUBMITTED_WITH_ERRORS" ||
      document?.processStatus === "SUBMITTED_WITH_WARNINGS" ||
      document?.processStatus === "SUBMITTED_WITH_ERRORS_AND_WARNINGS");

  const {
    data: censusValidations,
    isLoading: isLoadingCensusValidation,
    error: errorCensusValidation,
  } = useGetCensusValidations(client?.id, document?.id);

  const downloadReport = useCallback(() => {
    track("Download validation report");
    if (document && censusValidations) void generateFile(document, censusValidations.validations);
    return false;
  }, [track, censusValidations, document]);

  if (document?.processStatus === "SUBMITTED_WITH_FAILURES" && client) {
    return (
      <Navigate
        to={RouteData.policyTaskDetail.getPath(client.id, "enrollment-elections", policyId)}
        replace
      />
    );
  }

  const isLoading = isLoadingClient || isLoadingCensusValidation;
  const isError = isErrorClient || errorCensusValidation;

  if (isError) {
    return triggerError(errorClient || errorCensusValidation);
  }

  if (!isLoadingClient && !client?.policies.find((policy) => policy.id === policyId)) {
    return triggerError("Policy ID is required for this task");
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  if (isLoading) {
    return (
      <div className={clsx(styles.content, styles.validationReportTab)}>
        <div className={styles.layout}>
          <ProgressModal
            progressText="Reviewing your file..."
            body="Depending on the size of your file, this could take up to 1 minute."
          />
        </div>
      </div>
    );
  }

  if (document && !isValidDocument) {
    return <LoadingError type="page" title={genericErrorCopy2} />;
  }

  return (
    <div className={clsx(styles.content, styles.validationReportTab)}>
      <div className={styles.layout}>
        <div className={clsx(styles.reportTitle, "mb-32")}>
          <h2>File validation results</h2>
          {censusValidations && (
            <Button
              type="secondary"
              icon={<DownloadIcon />}
              iconPosition="right"
              onClick={downloadReport}
            >
              Download report
            </Button>
          )}
        </div>
        {censusValidations && client && document && (
          <Card>
            <StackY dist={32}>
              <DocumentDownloadV2 {...documentDownloadData} readonly={true} />
              <ValidationResultList
                validationResult={getValidationResultData(censusValidations.validations)}
              />
            </StackY>
          </Card>
        )}
      </div>
    </div>
  );
};
