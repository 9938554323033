import { getIsPFMLBenefitType, getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import {
  StatutoryBenefitTypeToCoverage,
  pfmlBenefitTypeToRelevantStateLabel,
  pfmlContributionSections,
  pfmlStateToContributionSection,
  pfmlStateToRelevantBenefitTypesMap,
  statutoryContributionSection,
} from "shared/types/Plan";
import { getIsStatutoryCoverage } from "shared/types/SlfCoverages";
import { assertIsDefined, unique } from "shared/utils/utils";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { planValidationSchema } from "shared/validation/plan";
import type { BenefitTypeEIF } from "@prisma/client";
import type { PFMLBenefitType, StatutoryBenefitType } from "shared/types/BenefitTypes";
import type { Client } from "shared/types/Client";
import type {
  PFMLContributionSections,
  PFMLStates,
  PFMLStatutoryContributionSections,
  Plan,
  StatutoryContributionLabels,
  StatutoryContributionSections,
} from "shared/types/Plan";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

export function getPFMLClientPlansForState(state: PFMLStates, pfmlClientPlans: Plan[]): Plan[] {
  const relevantPFMLBenefitTypes = pfmlStateToRelevantBenefitTypesMap[state];
  const relevantPFMLClientPlans = pfmlClientPlans.filter((clientPlan) => {
    return relevantPFMLBenefitTypes.includes(clientPlan.benefitType);
  });

  return relevantPFMLClientPlans;
}

const isNonClassBenefitPlanComplete = (plan: Plan) => {
  const isStarted = plan.pfmlPremiumContributionType !== null;
  const noErrors =
    getValidationErrors(planValidationSchema, plan, {
      prefill: false,
    }).length === 0;
  return isStarted && noErrors;
};

export function getPFMLContributionCompletionStatusForState(
  state: PFMLStates,
  pfmlClientPlans: Plan[],
): EIFStepCompleteStatus {
  const plans = getPFMLClientPlansForState(state, pfmlClientPlans);
  const nonAdviceOnlyClientPlans = getNonAdviceOnlyClientPlans(plans);

  const notStarted = plans.every((plan) => plan.pfmlPremiumContributionType === null);

  if (notStarted) {
    return "Not Started";
  }

  const completed = nonAdviceOnlyClientPlans.every(isNonClassBenefitPlanComplete);
  return completed ? "Completed" : "In Progress";
}

export function getStatutoryContributionCompletionStatus(
  client: Client,
  plans: Plan[],
): EIFStepCompleteStatus {
  const clientHasStatutoryCoverages =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsStatutoryCoverage(coverage)) ?? false;

  if (plans.length === 0 && !clientHasStatutoryCoverages) {
    return "Completed";
  }

  const nonReadOnlyPlans = getNonReadOnlyStatutoryClientPlans(plans);
  const notStarted = nonReadOnlyPlans.every((plan) => plan.pfmlPremiumContributionType === null);

  if (notStarted) {
    return "Not Started";
  }

  const completed =
    nonReadOnlyPlans.length > 0 && nonReadOnlyPlans.every(isNonClassBenefitPlanComplete);
  return completed ? "Completed" : "In Progress";
}

export function getPFMLContributionCompletionStatusForClient(
  pfmlClientPlans: Plan[],
  client: Client,
): EIFStepCompleteStatus {
  if (getPFMLOnlyHasAdviceOnly(pfmlClientPlans) && client.pfmlPreferencesStepViewed !== null) {
    return "Completed";
  }

  const nonAdviceOnlyClientPlans = getNonAdviceOnlyClientPlans(pfmlClientPlans);

  const notStarted = nonAdviceOnlyClientPlans.every(
    (plan) => plan.pfmlPremiumContributionType === null,
  );

  if (notStarted) {
    return "Not Started";
  }

  const completed =
    nonAdviceOnlyClientPlans.length > 0 &&
    nonAdviceOnlyClientPlans.every(isNonClassBenefitPlanComplete);
  const status = completed ? "Completed" : "In Progress";

  return status;
}

export function getPFMLOnlyHasAdviceOnly(pfmlClientPlans: Plan[]): boolean {
  const isAdviceOnly = (currentValue: Plan) => currentValue.pfmlOption === "ADVICE_ONLY";
  const onlyHasAdviceOnly = pfmlClientPlans.every(isAdviceOnly);

  return onlyHasAdviceOnly;
}

export function getStatutoryOnlyHasReadOnly(statutoryClientPlans: Plan[]): boolean {
  const isAdviceOnly = (currentValue: Plan) =>
    currentValue.benefitType === "TDB" || currentValue.benefitType === "PFL";
  const onlyHasAdviceOnly = statutoryClientPlans.every(isAdviceOnly);

  return onlyHasAdviceOnly;
}

export function getNonAdviceOnlyClientPlans(clientPlans: Plan[]): Plan[] {
  const nonAdviceOnlyClientPlans = clientPlans.filter((clientPlan) => {
    return clientPlan.pfmlOption !== "ADVICE_ONLY";
  });

  return nonAdviceOnlyClientPlans;
}

export function getNonReadOnlyStatutoryClientPlans(plans: Plan[]): Plan[] {
  const nonAdviceOnlyClientPlans = plans.filter((plan) => {
    return plan.benefitType !== "TDB" && plan.benefitType !== "PFL";
  });

  return nonAdviceOnlyClientPlans;
}

export function getRelevantPFMLBenefitTypes(pfmlClientPlans: Plan[]): PFMLBenefitType[] {
  const relevantPFMLBenefitTypes = pfmlClientPlans
    .map((clientPlan) => {
      return clientPlan.benefitType;
    })
    .filter(getIsPFMLBenefitType);

  return relevantPFMLBenefitTypes;
}

export function getRelevantStatutoryBenefitTypes(pfmlClientPlans: Plan[]): StatutoryBenefitType[] {
  const relevantStatutoryBenefitTypes = pfmlClientPlans
    .map((clientPlan) => {
      return clientPlan.benefitType;
    })
    .filter(getIsStatutoryBenefitType);

  return relevantStatutoryBenefitTypes;
}

export const getIsPFMLSectionType = (
  sectionType: PFMLStatutoryContributionSections,
): sectionType is PFMLContributionSections => {
  const isPFMLSectionType = pfmlContributionSections.includes(sectionType);
  return isPFMLSectionType;
};

export const getIsStatutorySectionType = (
  sectionType: BenefitTypeEIF,
): sectionType is StatutoryContributionSections => {
  const isStatutoryBenefitType = statutoryContributionSection.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    sectionType as StatutoryContributionSections,
  );
  return isStatutoryBenefitType;
};

export type ContributionSection = {
  sectionType: PFMLStatutoryContributionSections;
  label: PFMLStates | StatutoryContributionLabels;
  state?: PFMLStates;
  plans: Plan[];
  readOnly: boolean;
  status: EIFStepCompleteStatus;
  isPFML: boolean;
};

export const getUniquePFMLSections = (clientPlans: Plan[]) => {
  const pfmlClientPlans = clientPlans.filter((clientPlan) =>
    getIsPFMLBenefitType(clientPlan.benefitType),
  );

  const relevantPFMLBenefitTypes = pfmlClientPlans
    .map((clientPlan) => {
      return clientPlan.benefitType;
    })
    .filter(getIsPFMLBenefitType);

  const relevantPFMLStates = unique(
    relevantPFMLBenefitTypes.map((relevantPFMLBenefitType) => {
      return pfmlBenefitTypeToRelevantStateLabel[relevantPFMLBenefitType];
    }),
  );

  const uniqueSectionPFML: ContributionSection[] = unique(
    relevantPFMLStates.map((pfmlState) => {
      const plans = getPFMLClientPlansForState(pfmlState, pfmlClientPlans);
      return {
        sectionType: pfmlStateToContributionSection[pfmlState],
        label: pfmlState,
        state: pfmlState,
        plans,
        readOnly: getPFMLOnlyHasAdviceOnly(plans),
        status: getPFMLContributionCompletionStatusForState(pfmlState, pfmlClientPlans),
        isPFML: true,
      };
    }),
  );

  return uniqueSectionPFML;
};

export const getUniqueStatutorySections = (client: Client, clientPlans: Plan[]) => {
  const statutoryClientPlans = clientPlans.filter((clientPlan) =>
    getIsStatutoryBenefitType(clientPlan.benefitType),
  );
  const relevantStatutoryBenefitTypes = unique(
    statutoryClientPlans
      .map((clientPlan) => clientPlan.benefitType)
      .filter(getIsStatutorySectionType),
  );

  const uniqueSectionStatutory: ContributionSection[] = unique(
    relevantStatutoryBenefitTypes.map((benefitType) => {
      const coverage = StatutoryBenefitTypeToCoverage[benefitType];
      const [plan] = statutoryClientPlans.filter(
        (clientPlan) => clientPlan.benefitType === benefitType,
      );
      assertIsDefined(plan, "plan");
      return {
        sectionType: benefitType,
        label: coverage,
        plans: [plan],
        readOnly: getIsStatutorySectionReadOnly(plan),
        status: getStatutoryContributionCompletionStatus(client, [plan]),
        isPFML: false,
      };
    }),
  );

  return uniqueSectionStatutory;
};

export const getUniquePFMLAndStatutorySections = (client: Client, clientPlans: Plan[]) => {
  const uniqueSectionPFML = getUniquePFMLSections(clientPlans);
  const uniqueSectionStatutory = getUniqueStatutorySections(client, clientPlans);

  const uniqueSections = [...uniqueSectionPFML, ...uniqueSectionStatutory];
  return uniqueSections;
};

export const getIsStatutorySectionReadOnly = (clientPlan: Plan): boolean => {
  const isReadOnly = clientPlan.benefitType === "TDB" || clientPlan.benefitType === "PFL";

  return isReadOnly;
};
