import { StackY } from "client/src/components/Spacing/Spacing";
import { EIFClassBuilderDisabilityContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Disability/EIFClassBuilderDisabilityContributions";
import { EIFClassBuilderLifeADDContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/EIFClassBuilderLifeADDContributions";
import { EIFClassBuilderVoluntaryContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Voluntary/EIFClassBuilderVoluntaryContributions";
import { getURLSearchParamsFromObject } from "client/src/utils/url";
import { Navigate } from "react-router";
import { RouteData } from "shared/config/routeData";
import {
  lifeADDContributionBenefitTypes,
  benefitTypesSorter,
  dhmoBenefitTypes,
  getIsLifeADDContributionBenefitType,
  getIsDisabilityContributionBenefitType,
  getIsSupplementalHealthBenefitType,
  getIsTieredContributionBenefitType,
} from "shared/types/BenefitTypes";
import { benefitTypeToCoverage, getIsCoverageSupportedInEIF } from "shared/types/SlfCoverages";
import { getEmployeeClassContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { unique } from "shared/utils/utils";
import { EIFClassBuilderTieredContributions } from "./Tiered/EIFClassBuilderTieredContributions";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, ClientId } from "shared/types/Client";
import type { EmployeeClass, EmployeeClassId } from "shared/types/EmployeeClass";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";
import type { CoverageSupportedInEIF } from "shared/types/SlfCoverages";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFClassBuilderSection } from "shared/utils/EIF/getEIFStepCompleteStatus";

type EIFClassBuilderContributionsProps = {
  isActive: boolean;
  employeeClass: EmployeeClass;
  client: Client;
  isLoading: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  activeBenefitType?: BenefitTypeEIF | null;
  featureToggles: ClientFeatureToggles;
  selectedEmployeeClassPlans?: EmployeeClassPlanWithPlan[];
} & (
  | {
      readonly: true;
    }
  | {
      readonly: false;
      goToNextSection: (employeeClass: EmployeeClass) => void;
      sectionTracker: SectionTracker;
      setContributionsQuery: SetContributionQuery;
    }
);

const getPlanBenefitTypes = (
  employeeClassPlans: EmployeeClassPlanWithPlan[],
  benefitTypesThatNeedContributions: BenefitTypeEIF[],
) => {
  return unique(
    employeeClassPlans
      .map((p) => p.plan.benefitType)
      .filter((bt) => benefitTypesThatNeedContributions.includes(bt)),
  ).sort(benefitTypesSorter);
};

export const EIFClassBuilderContributions = ({
  isActive,
  employeeClass,
  client,
  isLoading,
  changeSnapshot,
  authUser,
  activeBenefitType,
  featureToggles,
  selectedEmployeeClassPlans = [],
  ...rest
}: EIFClassBuilderContributionsProps) => {
  const benefitTypesThatNeedContributions: BenefitTypeEIF[] = [
    "DENTAL",
    "DENTAL_ASO",
    "VISION",
    "SHORT_TERM_DISABILITY",
    "LONG_TERM_DISABILITY",
    "LIFE",
    "ACCIDENT",
    "CANCER",
    "CRITICAL_ILLNESS",
    "HOSPITAL_INDEMNITY",
    ...dhmoBenefitTypes,
    "VOL_STD",
    "VOL_LTD",
  ];

  // If we do not select a list of `EmployeeClassPlans` the default option will
  // use all the `EmployeeClassPlans` from the `EmployeeClass`
  const planBenefitTypes = selectedEmployeeClassPlans.length
    ? getPlanBenefitTypes(selectedEmployeeClassPlans, benefitTypesThatNeedContributions)
    : getPlanBenefitTypes(employeeClass.employeeClassPlans, benefitTypesThatNeedContributions);

  if (isActive && activeBenefitType == null) {
    const firstBenefitTypeThatIsNotCompleted = getFirstBenefitTypeThatIsNotCompleted(
      employeeClass,
      planBenefitTypes,
    );

    // Go to the first benefit type that is not completed yet.
    // If all are completed, just go to the first one.
    const benefitTypeToNavigateTo = firstBenefitTypeThatIsNotCompleted || planBenefitTypes[0];
    if (benefitTypeToNavigateTo) {
      const nextPath = getPathForBenefit(client.id, employeeClass.id, benefitTypeToNavigateTo);
      return <Navigate to={nextPath} replace />;
    }
  }

  const getOnSave = () => {
    return function onSave(employeeClass: EmployeeClass) {
      if (rest.readonly) return;

      return rest.goToNextSection(employeeClass);
    };
  };

  return (
    <StackY dist={32}>
      {planBenefitTypes.map((benefitType) => {
        const isCurrentBenefitTypeActive = isActive && activeBenefitType === benefitType;
        const isTieredContributionBenefitType = getIsTieredContributionBenefitType(benefitType);
        const isSuppHealthBenefitType = getIsSupplementalHealthBenefitType(benefitType);
        if (isTieredContributionBenefitType || isSuppHealthBenefitType) {
          return (
            <div key={benefitType}>
              <EIFClassBuilderTieredContributions
                isActive={isCurrentBenefitTypeActive}
                benefitType={benefitType}
                client={client}
                employeeClass={employeeClass}
                isLoading={isLoading}
                changeSnapshot={changeSnapshot}
                authUser={authUser}
                {...(rest.readonly
                  ? { readonly: true }
                  : {
                      readonly: false,
                      onSave: getOnSave(),
                      track: rest.sectionTracker,
                      setContributionsQuery: rest.setContributionsQuery,
                    })}
              />
            </div>
          );
        }

        const isDisabilityContributionBenefitType =
          getIsDisabilityContributionBenefitType(benefitType);
        if (isDisabilityContributionBenefitType) {
          return (
            <EIFClassBuilderDisabilityContributions
              key={benefitType}
              isActive={isCurrentBenefitTypeActive}
              benefitType={benefitType}
              client={client}
              employeeClass={employeeClass}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              isLoading={isLoading}
              {...(rest.readonly
                ? { readonly: true }
                : {
                    readonly: false,
                    onSave: getOnSave(),
                    track: rest.sectionTracker,
                    setContributionsQuery: rest.setContributionsQuery,
                  })}
            />
          );
        }

        const isLifeADDContributionBenefitType = getIsLifeADDContributionBenefitType(benefitType);
        if (isLifeADDContributionBenefitType) {
          return (
            <EIFClassBuilderLifeADDContributions
              key={benefitType}
              isActive={isCurrentBenefitTypeActive}
              benefitType={benefitType}
              client={client}
              employeeClass={employeeClass}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              isLoading={isLoading}
              {...(rest.readonly
                ? { readonly: true }
                : {
                    readonly: false,
                    onSave: getOnSave(),
                    track: rest.sectionTracker,
                    setContributionsQuery: rest.setContributionsQuery,
                  })}
            />
          );
        }

        const isVoluntarySTDorLTD = benefitType === "VOL_STD" || benefitType === "VOL_LTD";
        if (isVoluntarySTDorLTD) {
          return (
            <EIFClassBuilderVoluntaryContributions
              key={benefitType}
              isActive={isCurrentBenefitTypeActive}
              benefitType={benefitType}
              client={client}
              employeeClass={employeeClass}
              changeSnapshot={changeSnapshot}
              authUser={authUser}
              isLoading={isLoading}
              {...(rest.readonly
                ? { readonly: true }
                : {
                    readonly: false,
                    onSave: getOnSave(),
                    track: rest.sectionTracker,
                    setContributionsQuery: rest.setContributionsQuery,
                  })}
            />
          );
        }

        return null;
      })}
    </StackY>
  );
};

function getPathForBenefit(
  clientId: ClientId,
  employeeClassId: EmployeeClassId,
  benefitType: BenefitTypeEIF,
) {
  const section: EIFClassBuilderSection = "Contributions";
  const urlSearchParams = getURLSearchParamsFromObject({
    section,
    benefitType,
  });
  const nextPath =
    RouteData.eifClassBuilderEdit.getPath(
      clientId,
      "plan-configuration-&-eligibility",
      "class-builder",
      employeeClassId,
    ) + `?${urlSearchParams.toString()}`;
  return nextPath;
}

export function getFirstBenefitTypeThatIsNotCompleted(
  employeeClass: EmployeeClass,
  planBenefitTypes: BenefitTypeEIF[],
) {
  const areCoverageContributionsCompleted: Record<CoverageSupportedInEIF, () => boolean> = {
    Dental: () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["DENTAL"]) === "Completed",
    Vision: () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["VISION"]) === "Completed",
    "Short Term Disability": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["SHORT_TERM_DISABILITY"]) ===
      "Completed",
    "Long Term Disability": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["LONG_TERM_DISABILITY"]) ===
      "Completed",
    "Basic Life": () =>
      getEmployeeClassContributionsCompletionStatus(
        employeeClass,
        lifeADDContributionBenefitTypes,
      ) === "Completed",
    Accident: () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["ACCIDENT"]) === "Completed",
    Cancer: () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["CANCER"]) === "Completed",
    "Critical Illness": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["CRITICAL_ILLNESS"]) ===
      "Completed",
    "Hospital Indemnity": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["HOSPITAL_INDEMNITY"]) ===
      "Completed",
    "Dental Prepaid Alabama": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["AL_DHMO"]) === "Completed",
    "Dental Prepaid Arizona": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["AZ_DHMO"]) === "Completed",
    "Dental Prepaid California": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["CA_DHMO"]) === "Completed",
    "Dental Prepaid Colorado": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["CO_DHMO"]) === "Completed",
    "Dental Prepaid Florida": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["FL_DHMO"]) === "Completed",
    "Dental Prepaid Georgia": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["GA_DHMO"]) === "Completed",
    "Dental Prepaid Illinois": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["IL_DHMO"]) === "Completed",
    "Dental Prepaid Kansas": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["KS_DHMO"]) === "Completed",
    "Dental Prepaid Kentucky": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["KY_DHMO"]) === "Completed",
    "Dental Prepaid Michigan": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["MI_DHMO"]) === "Completed",
    "Dental Prepaid Missouri": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["MO_DHMO"]) === "Completed",
    "Dental Prepaid Nebraska": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["NE_DHMO"]) === "Completed",
    "Dental Prepaid New Jersey": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["NJ_DHMO"]) === "Completed",
    "Dental Prepaid New Mexico": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["NM_DHMO"]) === "Completed",
    "Dental Prepaid New York": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["NY_DHMO"]) === "Completed",
    "Dental Prepaid Ohio": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["OH_DHMO"]) === "Completed",
    "Dental Prepaid Oklahoma": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["OK_DHMO"]) === "Completed",
    "Dental Prepaid Pennsylvania": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["PA_DHMO"]) === "Completed",
    "Dental Prepaid Tennessee": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["TN_DHMO"]) === "Completed",
    "Dental Prepaid Texas": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["TX_DHMO"]) === "Completed",
    "Dental Prepaid Utah": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["UT_DHMO"]) === "Completed",
    "Dental Prepaid Wisconsin": () =>
      getEmployeeClassContributionsCompletionStatus(employeeClass, ["WI_DHMO"]) === "Completed",

    // Until https://maxwellhealth.atlassian.net/browse/TB-6583 is done
    "ASO Dental": () => true,

    // The following coverages don't need contributions
    "Dependent Basic Life": () => true,
    "Basic AD&D": () => true,
    "Voluntary Short Term Disability": () => true,
    "Voluntary Long Term Disability": () => true,
    "Voluntary Life": () => true,
    "Voluntary AD&D": () => true,
    "Stand-alone Vol AD&D": () => true,
    "ASO CT PFML": () => true,
    "CT PFML Fully Ins": () => true,
    "ASO MA PFML": () => true,
    "MA PFML Fully Ins": () => true,
    "ASO WA PFML": () => true,
    "ASO CO PFML": () => true,
    "CO PFML Fully Ins": () => true,
    "ASO OR PFML": () => true,
    "OR PFML Fully Ins": () => true,
    "Dental (DHMO)": () => true,
    "Family Leave Insurance": () => true,
    "New York Paid Family Leave": () => true,
    "New York Disability": () => true,
    "New Jersey Temporary Disability": () => true,
    "Hawaii Temporary Disability": () => true,
  };

  const firstBenefitTypeThatIsNotCompleted = planBenefitTypes.find((benefitType) => {
    const coverage = benefitTypeToCoverage[benefitType];
    const isSupported = getIsCoverageSupportedInEIF(coverage);
    if (isSupported) {
      const completed = areCoverageContributionsCompleted[coverage]();
      return !completed;
    }
    return false;
  });

  return firstBenefitTypeThatIsNotCompleted;
}
