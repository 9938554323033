import { getIsWaitingPeriodBenefitType } from "shared/types/BenefitTypes";
import {
  waitingPeriodNotSetForClassPlans,
  waitingPeriodTypeToReadableType,
} from "shared/types/EmployeeClassPlan";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

export const getEmployeeClassPlansByReadableWaitingPeriod = (
  employeeClassPlans: EmployeeClassPlanWithPlan[],
) => {
  return employeeClassPlans.reduce<Record<string, EmployeeClassPlanWithPlan[]>>(function (
    map,
    employeeClassPlan,
  ) {
    const isWaitingPeriodBenefitType = getIsWaitingPeriodBenefitType(
      employeeClassPlan.plan.benefitType,
    );
    if (isWaitingPeriodBenefitType) {
      const key = waitingPeriodTypeToReadableType(employeeClassPlan);
      if (!map[key]) {
        map[key] = [employeeClassPlan];
      } else {
        map[key].push(employeeClassPlan);
      }
    }
    return map;
  },
  {});
};

export const readableWaitingPeriodSorter = (
  [readableWaitingPeriodA, employeeClassPlansA]: [string, EmployeeClassPlanWithPlan[]],
  [readableWaitingPeriodB, _employeeClassPlansB]: [string, EmployeeClassPlanWithPlan[]],
) => {
  // always put un-selected waiting periods last
  if (waitingPeriodNotSetForClassPlans(employeeClassPlansA)) return 1;
  else return readableWaitingPeriodA.localeCompare(readableWaitingPeriodB);
};
