import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { FlatCard } from "client/src/components/Cards/FlatCard/FlatCard";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getContactLocation } from "client/src/utils/getContactLocations";
import {
  getContactSunLifeAccesses,
  locationManagementTypeMap,
  relevantChangesForContactFields,
  sunLifeAccessMap,
} from "shared/types/Contact";
import {
  getIsMultiPolicyMode,
  getShowValidationErrorsInSummary,
  getEIFSubStepViewMode,
} from "shared/utils/client";
import { listFormat } from "shared/utils/format";
import { formatContactLocation, formatLocation } from "shared/utils/location";
import { contactWithAddressValidationSchema } from "shared/validation/contact";
import { getValidationErrors } from "shared/validation/getValidationErrors";
import { locationInputValidation } from "shared/validation/location";
import { Body3, Body5, H5 } from "../../components/Typography/Typography";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type ContactCardProps = {
  contact: Contact;
  disabled?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  allowRemove?: boolean;
  showSunLifeConnectAccess?: boolean;
  showLocationAccess?: boolean;
  navigateLink?: string;
  onEdit?: () => void;
  onDelete?: () => void;
  onRemove?: () => void;
  showEditBanner?: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
  bills: Bill[];
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const DataTestId = {
  ContactCard: "contact-card",
};

const formatContactSunLifeAccesses = (contact: Contact): string | null => {
  const accesses = getContactSunLifeAccesses(contact);
  const texts = accesses.reduce<string[]>((texts, access) => {
    const text = sunLifeAccessMap.get(access);
    if (text) {
      return [...texts, text];
    }
    return texts;
  }, []);

  const result = texts.length > 0 ? listFormat(texts) : null;
  return result;
};

export const ContactCard = ({
  contact,
  disabled = false,
  allowDelete = false,
  onDelete,
  allowEdit = false,
  allowRemove = false,
  showSunLifeConnectAccess = false,
  showLocationAccess = false,
  onEdit,
  onRemove,
  showEditBanner = false,
  changeSnapshot,
  client,
  bills,
  authUser,
  navigateLink,
}: ContactCardProps) => {
  const viewMode = getEIFSubStepViewMode({ client });

  const contactChangeDetailInfoList = relevantChangesForContactFields([contact.id], changeSnapshot);

  const contactErrors = getValidationErrors(
    contactWithAddressValidationSchema.omit(["city", "state", "zipCode", "address1"]),
    contact,
    { prefill: false, bills },
  );
  const isCompleted = contactErrors.length === 0;

  const contactLocationErrors = contact?.location
    ? getShowValidationErrorsInSummary(viewMode, changeSnapshot)
      ? getValidationErrors(locationInputValidation, contact.location, {
          prefill: false,
        })
      : []
    : [];

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const e = getShowValidationErrorsInSummary(viewMode, changeSnapshot)
    ? getFormikErrors(
        contact,
        contactWithAddressValidationSchema.omit([
          "city",
          "state",
          "zipCode",
          "address1",
          "location",
        ]),
        { prefill: false, bills },
      )
    : {};

  const hasErrorsToDisplay =
    Object.keys(e).length > 0 || contactLocationErrors.length > 0 || !contact.location;

  const accessError =
    e.claimsAccess ||
    e.billingAccess ||
    e.paymentsAccess ||
    e.documentsAccess ||
    e.memberChangesAccess;

  const showDefaultEditCta = allowEdit && !showEditBanner;
  const showDefaultDeleteCta = allowDelete && !showEditBanner;
  const showDefaultRemoveCta = allowRemove && !showEditBanner;

  return (
    <FlatCard data-testid={DataTestId.ContactCard} disabled={disabled}>
      <StackY dist={8}>
        {showEditBanner && (
          <EditBanner
            data-testid={`internal-control-plan-contact-card-${contact.id}`}
            onEdit={onEdit}
            onDelete={onDelete}
            changeDetailInfoList={contactChangeDetailInfoList}
            client={client}
            authUser={authUser}
            navigateLink={navigateLink}
          />
        )}
        <Row wrap={false} gutter={9}>
          <Col>
            <Row>
              <StackX dist={8}>
                <H5 redMedium={e.firstName ? true : false}>
                  {contact.firstName ? contact.firstName : e.firstName ? e.firstName : "-"}
                </H5>
                <H5 redMedium={e.lastName ? true : false}>
                  {contact.lastName ? contact.lastName : e.lastName ? e.lastName : "-"}
                </H5>
              </StackX>
            </Row>
          </Col>
          <Col flex="1 1 auto"></Col>
          {showDefaultEditCta && (
            <Col flex="0 1 auto">
              <Button
                type="text-only"
                size="middle"
                onClick={onEdit}
                data-testid="contact-card-edit-btn"
              >
                Edit
              </Button>
            </Col>
          )}
          {showDefaultDeleteCta && (
            <Col flex="0 1 auto">
              <Button
                type="text-only"
                size="middle"
                onClick={onDelete}
                data-testid="contact-card-delete-btn"
              >
                Delete
              </Button>
            </Col>
          )}
          {showDefaultRemoveCta && (
            <Col flex="0 1 auto">
              <Button
                type="text-only"
                size="middle"
                onClick={onRemove}
                data-testid="contact-card-remove-btn"
              >
                Remove
              </Button>
            </Col>
          )}
        </Row>
      </StackY>
      <StackY dist={16}>
        {!isCompleted && hasErrorsToDisplay && (
          <AlertBanner
            variant="error"
            message={
              <Body3>
                Click <b>Edit</b> to complete this section.
              </Body3>
            }
          />
        )}
        <Row gutter={[32, 18]}>
          <Col>
            <Body5 as="div">Job title</Body5>
            {contact.title && <Body3>{contact.title}</Body3>}
            {e.title && <Body3 redMedium>{e.title}</Body3>}
            {!contact.title && !e.title && <Body3>-</Body3>}
          </Col>
          <Col>
            <Body5 as="div">Email address</Body5>
            {contact.email && <Body3>{contact.email}</Body3>}
            {e.email && <Body3 redMedium>{e.email}</Body3>}
            {!contact.email && !e.email && <Body3>-</Body3>}
          </Col>
          <Col>
            <Body5 as="div">Phone</Body5>
            {contact.phoneNumber && <Body3>{contact.phoneNumber}</Body3>}
            {e.phoneNumber && <Body3 redMedium>{e.phoneNumber}</Body3>}
            {!contact.phoneNumber && !e.phoneNumber && <Body3>-</Body3>}
          </Col>
          {!contact.location && !contact.address1 && (
            <Col span={24}>
              <Body5 as="div">Location</Body5>
              <Body3 as="div" redMedium>
                Please provide a response
              </Body3>
            </Col>
          )}
          {contact.location && (
            <Col span={24}>
              <Body5 as="div">Location</Body5>
              {getContactLocation(contact, contactLocationErrors ? contactLocationErrors : [])}
            </Col>
          )}
          {!contact.location && contact.address1 && (
            <Col span={24}>
              <Body5 as="div">Location</Body5>
              <Body3 as="div">{formatContactLocation(contact)}</Body3>
            </Col>
          )}
          {showSunLifeConnectAccess && (
            <Col span={24}>
              <Body5 as="div">Sun Life Connect account functions</Body5>
              {isMultiPolicyMode ? (
                <>
                  <Body3 as="div">
                    Policies:{" "}
                    {client.policies
                      .filter((p) => contact.accessesForPolicyIds.includes(p.id))
                      .map((p) =>
                        p.primaryPolicy
                          ? `#${p.slfPolicyNumber} (Primary)`
                          : `#${p.slfPolicyNumber}`,
                      )
                      .join(", ")}
                    {e.accessesForPolicyIds && <Body3 redMedium>{e.accessesForPolicyIds}</Body3>}
                  </Body3>
                  <Body3 as="div">
                    Access: {formatContactSunLifeAccesses(contact)}{" "}
                    {accessError && <Body3 redMedium>{accessError}</Body3>}
                    {!accessError &&
                      !contact.paymentsAccess &&
                      !contact.claimsAccess &&
                      !contact.billingAccess &&
                      !contact.memberChangesAccess &&
                      !contact.documentsAccess && <Body3>-</Body3>}
                  </Body3>
                </>
              ) : (
                <Body3 as="div">
                  {formatContactSunLifeAccesses(contact)}{" "}
                  {accessError && <Body3 redMedium>{accessError}</Body3>}
                  {!accessError &&
                    !contact.paymentsAccess &&
                    !contact.claimsAccess &&
                    !contact.billingAccess &&
                    !contact.memberChangesAccess &&
                    !contact.documentsAccess && <Body3>-</Body3>}
                </Body3>
              )}
            </Col>
          )}
          {showLocationAccess && (
            <Col span={24}>
              <Body5 as="div">Location access</Body5>
              {contact.locationManagementType === "ALL" && (
                <Body3 as="div">
                  {locationManagementTypeMap.get(contact.locationManagementType)}
                </Body3>
              )}
              {contact.locationManagementType === "LIMITED" &&
                contact.managedLocations?.map((manageLocation) => (
                  <Body3 as="div" key={manageLocation.id}>
                    {formatLocation(manageLocation)}
                  </Body3>
                ))}
              {e.locationManagementType && <Body3 redMedium>{e.locationManagementType}</Body3>}
              {!contact.locationManagementType && !e.locationManagementType && <Body3>-</Body3>}
            </Col>
          )}
        </Row>
      </StackY>
    </FlatCard>
  );
};
