import { Row, Col } from "client/src/components/Grid/Grid";
import { GoBackLink } from "client/src/components/Link/GoBackLink";
import { Loading } from "client/src/components/Loading/Loading";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { EmailsList } from "client/src/pages/EmailsPage/EmailsList";
import { RouteData } from "shared/config/routeData";
import { useGetClientByID } from "../../hooks/client";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useTitle } from "../../hooks/useTitle";
import { ClientEmailsWithErrorsAlert } from "../Clients/EmailErrorsAlert";

export function AllEmailsPage() {
  const { clientId } = useClientHubParams();

  useTitle("Emails");

  const EMAIL_LIST_PAGE_SIZE = 25;

  const { data: client, isLoading: isClientLoading } = useGetClientByID(clientId, {
    enabled: !!clientId,
  });
  const { data: featureToggles, isLoading: isFeatureTogglesLoading } =
    useGetFeatureToggles(clientId);

  if (isClientLoading || isFeatureTogglesLoading) {
    return <Loading />;
  }

  return (
    <>
      <PageContent>
        <Row align="middle" className="mb-12">
          <Col flex={1}>
            <GoBackLink to={RouteData.homeInternal.getPath()}>&lt; Back to all Clients</GoBackLink>
          </Col>

          <Col>
            <ClientEmailsWithErrorsAlert clientId={clientId} />
          </Col>
        </Row>

        <EmailsList
          client={client}
          initialPageSize={EMAIL_LIST_PAGE_SIZE}
          featureToggles={featureToggles}
        />
      </PageContent>
    </>
  );
}
