import { billTimings } from "shared/types/Bill";
import { billingSummaryStatementTypes, billPayrollCycles } from "shared/types/Client";
import { WhoSubmitsClaimsValues } from "shared/types/Policy";
import { getIsHIorNJ } from "shared/types/SlfCoverages";
import { assertIsDefined } from "shared/utils/utils";
import { billDetailsSpec } from "shared/validation/bill";
import { dataFeedsContactValidationSchema } from "shared/validation/client";
import { contactTypeValidationSchema, phoneNumberValidation } from "shared/validation/contact";
import { locationStateCodeValidation } from "shared/validation/location";
import { yesNoNotSureNullablePrefill } from "shared/validation/validation";
import * as Yup from "yup";
import type {
  BillingAdministrationType,
  BillingSummaryStatementType,
  BillTiming,
  WhoSubmitsClaims,
  YesNoNotSure,
} from "@prisma/client";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { BillPayrollCycle, Policy } from "shared/types/Client";

const isStatutoryHIorNJPolicy = (policy: Policy | undefined) => {
  assertIsDefined(policy, "policy");
  const isHIorNJ = getIsHIorNJ(policy);
  return isHIorNJ;
};

export const tpaContactValidationSchemaNonNullable = Yup.object({
  id: Yup.string().nullable().optional(),
  policyId: Yup.string().defined().nullable(),
  type: contactTypeValidationSchema,
  firstName: Yup.string().defined().nullable(),
  lastName: Yup.string().defined().nullable(),

  tpaFirmName: Yup.string()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Firm name is required"),
    }),
  fullName: Yup.string()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Contact name is required"),
    }),
  email: Yup.string()
    .transform((value: unknown) =>
      typeof value === "string" ? value.trim().toLocaleLowerCase() : value,
    )
    .emailV1("Email must be in a valid format i.e. - example@example.com")
    .defined()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Email is required"),
    }),
  phoneNumber: phoneNumberValidation
    .notRequired()
    .transform((val: string | undefined) => (val ? val : null))
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Phone number is required"),
    }),
  title: Yup.string()
    .trim()
    .notRequired()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Title is required"),
    }),

  address1: Yup.string()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Address is required"),
    }),
  address2: Yup.string().trim().nullable().optional(),
  city: Yup.string()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("City is required"),
    }),
  state: locationStateCodeValidation
    .nullable()
    .default(undefined)
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("State is required"),
    }),
  zipCode: Yup.string()
    .nullable()
    .when("$prefill", {
      is: (prefill: boolean | undefined) => !prefill,
      then: (schema) => schema.required("Zip code is required"),
    }),
});

const tpaContactValidationSchema = tpaContactValidationSchemaNonNullable
  .nullable()
  .when("billingAdministrationType", {
    is: "TPA",
    then: (schema) =>
      schema.transform((value: Record<string, unknown> | null) => {
        value = value || {};
        value.firstName = value.firstName ?? "";
        value.lastName = value.lastName ?? "";
        value.policyId = value.policyId ?? "";
        value.email = value.email ?? "";
        value.type = "TPA";
        return value;
      }),
    otherwise: (schema) =>
      schema
        .nullable()
        .optional()
        .default(undefined)
        .transform(() => null),
  });

export const policyValidationSchema = Yup.object({
  connecticutUseSLFSPDForPFML: Yup.boolean(),
  massachusettsUseSLFSPDForPFML: Yup.boolean(),
  washingtonUseSLFSPDForPFML: Yup.boolean(),
  coloradoUseSLFSPDForPFML: Yup.boolean(),
  oregonUseSLFSPDForPFML: Yup.boolean(),

  // Benefits administration & data feeds
  hasBenAdminPlatform: yesNoNotSureNullablePrefill,
  benAdminPlatformId: Yup.number()
    .nullable()
    .when(["hasBenAdminPlatform", "$prefill"], {
      is: (hasBenAdminPlatform: YesNoNotSure, prefill: boolean | undefined) =>
        hasBenAdminPlatform === "YES" && !prefill,
      then: (schema) => schema.required("Please provide a response"),
    }),
  benAdminPlatformOtherName: Yup.string()
    .nullable()
    .when(["benAdminPlatform", "$prefill"], {
      is: (benAdminPlatform: BenAdminPlatform | null | undefined, prefill: boolean) => {
        return benAdminPlatform?.name === "Other" && !prefill;
      },
      then: (schema) => schema.required("Please provide a response"),
    }),
  benAdminPlatform: Yup.mixed<BenAdminPlatform>().nullable(),
  dataFeeds: Yup.mixed<YesNoNotSure>()
    .oneOf<YesNoNotSure>(["YES", "NO", "NOT_SURE"])
    .nullable()
    .when("hasBenAdminPlatform", {
      is: (hasBenAdminPlatform: YesNoNotSure) => hasBenAdminPlatform === "YES",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$prefill", {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  dataFeedsBenAdminContact: dataFeedsContactValidationSchema,
  dataFeedsProductionSupportContact: dataFeedsContactValidationSchema,
  dataFeedsImplementorContact: dataFeedsContactValidationSchema,

  // Billing Preferences
  billingAdministrationType: billDetailsSpec.billingAdministrationType.optional().test({
    name: "dbl-pfl-validation",
    message: "List bill administration type is not allowed for DBL and PFL",
    test: (value, context) => {
      const policy: Policy | undefined = context.options.context?.policy;
      assertIsDefined(policy, "policy");

      const isDBLorPFL =
        policy.slfCoverages?.some(
          (coverage) =>
            coverage === "New York Disability" || coverage === "New York Paid Family Leave",
        ) ?? false;

      const isInvalid = isDBLorPFL && value === "LIST";
      return !isInvalid;
    },
  }),
  tpaContact: tpaContactValidationSchema,
  billingStructureType: billDetailsSpec.billingStructureType.optional(),
  billingSummaryStatementType: Yup.mixed<BillingSummaryStatementType>()
    .oneOf(billingSummaryStatementTypes, "Please provide a response")
    .nullable()
    .when("billingAdministrationType", {
      is: (val: BillingAdministrationType | null) => val === "SELF" || val === "TPA",
      then: (schema) => schema.required("Please provide a response"),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  billPayrollCycles: Yup.array()
    .of(Yup.mixed<BillPayrollCycle>().oneOf(billPayrollCycles.slice()).required())
    .strict()
    .nullable()
    .when("billingAdministrationType", {
      is: "LIST",
      then: (schema) =>
        schema
          .min(1, "Please provide a response")
          .required("Please select at least one payroll cycle"),
    })
    .when("$policy", {
      is: isStatutoryHIorNJPolicy,
      then: (schema) => schema.notRequired().min(0),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired().min(0),
    }),
  billPayrollCyclesOther: Yup.string()
    .nullable()
    .when("billPayrollCycles", {
      is: (billPayrollCycles: BillPayrollCycle[]) => {
        const isRequired = billPayrollCycles?.includes("OTHER") ?? false;
        return isRequired;
      },
      then: (schema) => schema.nullable().required("Please provide a response"),
    })
    .when("$policy", {
      is: isStatutoryHIorNJPolicy,
      then: (schema) => schema.notRequired(),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),
  billPayrollCyclesExplanation: Yup.string()
    .nullable()
    .when("billPayrollCycles", {
      is: (billPayrollCycles: BillPayrollCycle[]) => {
        const twoOrMore = billPayrollCycles && billPayrollCycles.length > 1;
        return twoOrMore;
      },
      then: (schema) => schema.required("Please provide a response"),
    })
    .when("$policy", {
      is: isStatutoryHIorNJPolicy,
      then: (schema) => schema.notRequired(),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  billSplitType: billDetailsSpec.billSplitType.optional().test({
    name: "tdi-tdb-validation",
    message: "You can't split by benefit for TDI (HI) or TDB (NJ)",
    test: (value, context) => {
      const policy: Policy | undefined = context.options.context?.policy;
      assertIsDefined(policy, "policy");

      const isHIorNJ = getIsHIorNJ(policy);

      const isInvalid = isHIorNJ && value === "BENEFIT";
      return !isInvalid;
    },
  }),
  whoSubmitsClaims: Yup.mixed<WhoSubmitsClaims>()
    .oneOf(WhoSubmitsClaimsValues, "Please provide a response")
    .nullable()
    .when("billingAdministrationType", {
      is: (val: BillingAdministrationType | null) => val === "TPA",
      then: (schema) => schema.required("Please provide a response"),
      otherwise: (schema) => schema.transform(() => null),
    })
    .when(["$prefill"], {
      is: (prefill: boolean | undefined) => prefill,
      then: (schema) => schema.notRequired(),
    }),

  advanceOrArrears: billDetailsSpec.advanceOrArrears.optional(),
});

export const benefitsAdministrationValidationSchema = policyValidationSchema.pick([
  "hasBenAdminPlatform",
  "benAdminPlatformId",
  "benAdminPlatform",
  "benAdminPlatformOtherName",
]);

export const benAdminAndDataFeedsValidationSchema = policyValidationSchema.pick([
  "hasBenAdminPlatform",
  "benAdminPlatformId",
  "benAdminPlatformOtherName",
  "benAdminPlatform",
  "dataFeeds",
  "dataFeedsBenAdminContact",
  "dataFeedsProductionSupportContact",
  "dataFeedsImplementorContact",
]);

export const billingPreferencesClientSchema = policyValidationSchema
  .pick([
    "tpaContact",
    "billingSummaryStatementType",
    "billPayrollCycles",
    "billPayrollCyclesOther",
    "billPayrollCyclesExplanation",
    "whoSubmitsClaims",
  ])
  .shape({
    billingAdministrationType: billDetailsSpec.billingAdministrationType,
    billingStructureType: billDetailsSpec.billingStructureType,
    billSplitType: billDetailsSpec.billSplitType,
    advanceOrArrears: billDetailsSpec.advanceOrArrears,
  });

export const billingPreferencesSchema = billingPreferencesClientSchema.concat(
  Yup.object({
    bills: Yup.array()
      .of(
        Yup.object({
          policyId: billDetailsSpec.policyId,

          advanceOrArrears: billDetailsSpec.advanceOrArrears,

          billingAdministrationType: billDetailsSpec.billingAdministrationType,
          billingStructureType: billDetailsSpec.billingStructureType,
          billSplitType: billDetailsSpec.billSplitType,

          id: Yup.string().defined().nullable(),
          createdAt: Yup.date().defined().nullable(),
          billTiming: Yup.mixed<BillTiming>()
            .oneOf(billTimings, "Missing bill timing")
            .defined()
            .nullable(),

          groupByLocationIds: billDetailsSpec.groupByLocationIds,
          splitTags: billDetailsSpec.splitTags,
          slfCoverages: billDetailsSpec.slfCoverages,

          billName: billDetailsSpec.billName,

          contactId: billDetailsSpec.contactId,
          hasDifferentMailingAddress: billDetailsSpec.hasDifferentMailingAddress,
          locationId: billDetailsSpec.locationId,
          numberOfEmployees: billDetailsSpec.numberOfEmployees,

          categorizeEmployees: billDetailsSpec.categorizeEmployees,
          employeeCategorizationType: billDetailsSpec.employeeCategorizationType,
          categorizeByLocationIds: billDetailsSpec.categorizeByLocationIds,
          categoriesByTags: billDetailsSpec.categoriesByTags,
        }),
      )
      .when("billingAdministrationType", {
        is: (billingAdministrationType: BillingAdministrationType | null) =>
          billingAdministrationType == null || billingAdministrationType === "LIST",
        then: (schema) =>
          schema.required("Please add at least one bill").min(1, "Please add at least one bill"),
      })
      .when("billingAdministrationType", {
        is: (billingAdministrationType: BillingAdministrationType | null) =>
          billingAdministrationType === "SELF" || billingAdministrationType === "TPA",
        then: (schema) =>
          schema
            .required("Please add at least one statement")
            .min(1, "Please add at least one statement"),
      })
      .required("Please add at least one bill")
      .when(["$prefill"], {
        is: (prefill: boolean | undefined) => prefill,
        then: (schema) => schema.notRequired().min(0),
      }),
  }),
);
