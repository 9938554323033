import { Button } from "client/src/components/Button/Button";
import { compact } from "lodash";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";

import { Ribbon } from "../../../../components/Ribbon/Ribbon";
import { SlobTable } from "../../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../../components/Table/ActionButtons";

import { Tooltip } from "../../../../components/Tooltip/Tooltip";
import { useSlobAuth } from "../../../../hooks/auth";
import { getPoliciesEffectiveDates } from "../../../../utils/sort";
import {
  CompactProgressTrackerPolicy,
  comparePolicyByPhaseScore,
  getPolicyWithHigherPhaseScore,
} from "./CompactProgressTrackerPolicy";
import { MultiCellValue } from "./MultiCellValue";
import * as styles from "./Table.module.less";

import type { SlobTableProps, SlobColumnsType } from "../../../../components/SlobTable/SlobTable";
import type { Client } from "shared/types/Client";

const buildColumns = (isInternalUser: boolean) => {
  const columns: SlobColumnsType<Client> = [
    {
      width: "30%",
      title: "Client",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      render(value: string, client) {
        const ribbonTexts = compact([client.isTest && "Test", !client.isActive && "Draft"]);
        return (
          <div className={styles.flexCell}>
            {value}
            <Ribbon position="top-left" text={ribbonTexts} />
          </div>
        );
      },
    },
    {
      title: "Effective Date",
      dataIndex: "firstPolicyEffective",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      className: MultiCellValue.tdClassName,
      render: (_, client: Client) => {
        const dates = getPoliciesEffectiveDates(client.policies);
        return (
          <MultiCellValue>
            {dates.map((date, i) => (
              <div key={`${date}_${i}`}>{date}</div>
            ))}
          </MultiCellValue>
        );
      },
    },
    {
      title: "Onboarding Phase",
      sorter: (aClient, bClient) => {
        const aPolicy = getPolicyWithHigherPhaseScore(aClient.policies);
        const bPolicy = getPolicyWithHigherPhaseScore(bClient.policies);
        return comparePolicyByPhaseScore(aPolicy?.phaseId, bPolicy?.phaseId);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      render: (client: Client) => {
        return client.policies.map((policy) => {
          return (
            <div key={policy.slfPolicyNumber}>
              {`Policy #${policy.slfPolicyNumber}`}
              <CompactProgressTrackerPolicy key={policy.id} client={client} policy={policy} />
            </div>
          );
        });
      },
    },
    {
      width: 50,
      render: (client: Client) => {
        const disabled = !client.isActive && !isInternalUser;
        return (
          <ActionButtons>
            <Tooltip
              title={
                disabled
                  ? "This client is currently unavailable to view as it is in draft mode."
                  : null
              }
              placement="top"
            >
              <Button
                type="secondary"
                size="xtra-small"
                disabled={disabled}
                to={RouteData.homeHub.getPath(client.id)}
              >
                View Portal
              </Button>
            </Tooltip>
          </ActionButtons>
        );
      },
    },
  ];
  return columns;
};

export type BrokerClientsTableV2Props = Omit<SlobTableProps<Client>, "columns">;

export const BrokerClientsTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
}: BrokerClientsTableV2Props) => {
  const { authUser } = useSlobAuth();
  const isInternalUser = getIsInternalUser(authUser);

  const columns = buildColumns(isInternalUser);

  return (
    <SlobTable
      columns={columns}
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      featureToggles={featureToggles}
    />
  );
};
