import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H2 } from "client/src/components/Typography/Typography";
import { EIFNonClassBenefitsContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributions";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { Navigate, useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsInternalUser } from "shared/rbac/rbac";
import { getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import { getEIFSubStepMap } from "shared/types/EIF";
import { coverageToBenefitType, getIsPFMLCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import {
  getRelevantPFMLBenefitTypes,
  getRelevantStatutoryBenefitTypes,
} from "shared/utils/EIF/nonClassBenefitsPreferences";
import { areArraysEqual } from "../../../../../../shared/utils/utils";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { UpdatePlansQuery } from "client/src/hooks/plans";
import type { TrackElementClickedFunc } from "client/src/utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { EIFSubStepId } from "shared/types/EIF";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  authUser: UserData;
  updatePlansQuery: UpdatePlansQuery;
  updateClientQuery: UpdateClientQuery;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  viewMode: EIFSubStepViewMode;
  trackElementClicked: TrackElementClickedFunc;
};

export function EIFNonClassBenefitsPreferences(props: Props) {
  const {
    client,
    clientPlans,
    authUser,
    updatePlansQuery,
    updateClientQuery,
    changeSnapshot,
    viewMode,
    featureToggles,
    trackElementClicked,
  } = props;

  const {
    mutateAsync: updateClient,
    isPending: isUpdatingClient,
    error: updateClientError,
  } = updateClientQuery;

  const eifSubStepId: EIFSubStepId = "non-class-benefits-preferences";
  const subStepName = getEIFSubStepMap({ eifSubStepId, clientPlans, featureToggles });
  const navigate = useNavigate();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const clientHasPFMLBenefitType =
    client.allPoliciesSlfCoverages?.some((coverage) => getIsPFMLCoverage(coverage)) ?? false;

  const clientHasStatutoryBenefitType =
    client.allPoliciesSlfCoverages?.some((coverage) =>
      getIsStatutoryBenefitType(coverageToBenefitType[coverage]),
    ) ?? false;

  const clientPFMLBenefitTypes = client.allPoliciesSlfCoverages
    ?.filter((coverage) => getIsPFMLCoverage(coverage))
    .map((coverage) => coverageToBenefitType[coverage]);

  const pfmlClientPlanBenefitTypes = getRelevantPFMLBenefitTypes(clientPlans);

  const clientStatutoryBenefitTypes = client.allPoliciesSlfCoverages
    ?.filter((coverage) => getIsStatutoryBenefitType(coverageToBenefitType[coverage]))
    .map((coverage) => coverageToBenefitType[coverage]);

  const statutoryClientPlanBenefitTypes = getRelevantStatutoryBenefitTypes(clientPlans);

  const isInternalUser = getIsInternalUser({ role: authUser.role });
  const clientPlanForEveryPFMLAndStatutoryCoverage =
    areArraysEqual(clientPFMLBenefitTypes, pfmlClientPlanBenefitTypes) &&
    areArraysEqual(clientStatutoryBenefitTypes, statutoryClientPlanBenefitTypes);

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
    featureToggles,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const needsClassbuilder = getIsSubStepApplicable({
    eifSubStepId: "class-builder",
    client,
    plans: clientPlans,
  });

  return (
    <>
      <StackY dist={40}>
        <h2>{subStepName}</h2>

        <StackY dist={32}>
          {needsClassbuilder && (
            <>
              <Body3 as="p">
                These are benefits mandated by some states that the employer is required to offer
                their employees who work in that state.
              </Body3>
              <Body3 as="p">
                These benefits are configured separately from the rest of your benefits because they
                apply to all eligible employees in the same way. Employees who do not have other
                benefits may still receive these benefits.
              </Body3>
            </>
          )}
          <HorizontalDivider />
          <StackY dist={32}>
            <div className="mb-24">
              <H2>Eligibility</H2>
            </div>
            <HubCard>
              <Body5 as="p">All employees are eligible under applicable state law.</Body5>
            </HubCard>
          </StackY>
          <StackY dist={32}>
            <div className="mb-24">
              <H2>Waiting Periods</H2>
            </div>
            <HubCard>
              <Body5 as="p">There is no waiting period for eligible employees.</Body5>
            </HubCard>
          </StackY>
          <h2>Contributions</h2>
          <Body3 as="p">
            Employee contribution shall not exceed the maximum amount specified by each state's
            respective statutory benefits laws.
          </Body3>
        </StackY>

        <StackY dist={1}>
          {!clientPlanForEveryPFMLAndStatutoryCoverage && (
            <>
              {isInternalUser ? (
                <div>
                  <DashedBanner variant="warning">
                    <Body2 darkYellow>Internal user:</Body2>{" "}
                    <Body3 darkYellow>
                      Please complete{" "}
                      <Anchor href={RouteData.clientDetail.getPath(client.id)} newTabIcon={true}>
                        Configure Submit Company Information
                      </Anchor>{" "}
                      to access this section.
                    </Body3>
                  </DashedBanner>
                </div>
              ) : (
                <div className="mb-32">
                  <AlertBanner
                    variant="info"
                    message={
                      <Body3>
                        Your implementation consultant is still getting things ready. Check back
                        later to complete this section.
                      </Body3>
                    }
                  />
                </div>
              )}
            </>
          )}

          {((clientHasPFMLBenefitType && pfmlClientPlanBenefitTypes.length !== 0) ||
            (featureToggles.ONBOARD_DSF_STATUTORY &&
              clientHasStatutoryBenefitType &&
              statutoryClientPlanBenefitTypes.length !== 0)) && (
            <HubCard>
              <EIFNonClassBenefitsContributions
                client={client}
                clientPlans={clientPlans}
                updatePlansQuery={updatePlansQuery}
                clientPlanForEveryPFMLAndStatutoryCoverage={
                  clientPlanForEveryPFMLAndStatutoryCoverage
                }
                authUser={authUser}
                changeSnapshot={changeSnapshot}
                viewMode={viewMode}
                featureToggles={featureToggles}
                trackElementClicked={trackElementClicked}
              />
              {updateClientError && <ErrorMessage>{GenericErrorCopy2}</ErrorMessage>}
            </HubCard>
          )}
        </StackY>

        <Row justify="space-between" align="middle">
          <Col>
            {previousSubStepLink && (
              <InternalLinkButton
                to={previousSubStepLink}
                type="link-inline-bold"
                size="middle"
                disabled={isUpdatingClient || updatePlansQuery.isPending}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
                Previous
              </InternalLinkButton>
            )}
          </Col>
          <Col>
            <Row align="middle" gutter={24}>
              <Col>
                <Button
                  type="primary"
                  size="large"
                  disabled={isUpdatingClient || updatePlansQuery.isPending}
                  onClick={async () => {
                    let allowNavigation = true;
                    if (!client.pfmlPreferencesStepViewed) {
                      const { isSuccess } = await updateClient({
                        params: {
                          clientId: client.id,
                        },
                        data: {
                          pfmlPreferencesStepViewed: true,
                        },
                      });
                      allowNavigation = isSuccess;
                    }
                    if (allowNavigation) {
                      navigate(nextSubStepLink);
                    }
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </StackY>
    </>
  );
}
