import { Portal } from "@reach/portal";

import { GiveFeedbackInputPicker } from "./GiveFeedbackInputPicker";

export function GiveFeedback() {
  return (
    <Portal type="give-feedback-portal">
      <GiveFeedbackInputPicker />
    </Portal>
  );
}
