import axios from "axios";
import { useState } from "react";

import { useDocumentDownloadHelperById } from "./document";
import { useSlobMutation } from "./query";

import type { ClientId } from "shared/types/Client";
import type { DocumentId } from "shared/types/Document";
import type { BasicZippedDocument } from "shared/types/ZippedDocument";

export type ZippedDocumentRequestStatus = "IDLE" | "GENERATING" | "DOWNLOADING" | "ERROR";

export const useDownloadZipFileForDocumentIds = (clientId: ClientId) => {
  const [controller, setController] = useState(new AbortController());

  const { isDownloading, isFetchingDocumentURL, errorDocumentURL, downloadDocument } =
    useDocumentDownloadHelperById(clientId, controller.signal);

  const query = useSlobMutation<
    { documentIds: DocumentId[] },
    BasicZippedDocument,
    "/api/clients/:clientId/zipped-documents/zip-document-request"
  >(
    {
      method: "post",
      path: "/api/clients/:clientId/zipped-documents/zip-document-request",
      map: (item) => {
        if (item.documentId) {
          downloadDocument(item.documentId);
        }

        return item;
      },
    },
    { signal: controller.signal },
  );

  const isGeneratingZippedDocument = query.isPending || isDownloading || isFetchingDocumentURL;

  const errorGeneratingZippedFile =
    (query.error instanceof axios.CanceledError ? false : query.error) ||
    (errorDocumentURL instanceof axios.CanceledError ? false : errorDocumentURL) ||
    false;

  const zippedDocumentStatus: ZippedDocumentRequestStatus = query.isPending
    ? "GENERATING"
    : isDownloading || isFetchingDocumentURL
    ? "DOWNLOADING"
    : errorGeneratingZippedFile
    ? "ERROR"
    : "IDLE";

  const requestZipFile = async (documentIds: DocumentId[]) => {
    await query.mutateAsync({ params: { clientId }, data: { documentIds } });
  };

  const cancelRequestZipFile = () => {
    controller.abort();
    setController(new AbortController());
  };

  return {
    isGeneratingZippedDocument,
    errorGeneratingZippedFile,
    zippedDocumentStatus,
    requestZipFile,
    cancelRequestZipFile,
  };
};
