import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

export const hasAtLeastOneContributionSelected = (
  employeeClassPlans: EmployeeClassPlanWithPlan[],
): boolean => {
  const atLeastOneContributionSelected = employeeClassPlans.some(
    (employeeClassPlan) => employeeClassPlan.employerContribution != null,
  );
  return atLeastOneContributionSelected;
};
