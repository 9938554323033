import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { EmployeeClass } from "shared/types/EmployeeClass";

export function isUsing3TierContributionAttributes(
  employeeClass: EmployeeClass,
  benefitType: BenefitTypeEIF,
) {
  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans
    .filter((plan) => plan.employerContribution === "YES" || plan.employerContribution === "SHARED")
    .filter((ecp) => ecp.plan.benefitType === benefitType);

  if (relevantEmployeeClassPlans.length === 0) return true;
  const isUsing3TierProps = relevantEmployeeClassPlans.some((plan) => {
    const {
      // employee prop
      eeContributionAmount,

      // 3 tier props (new props)
      spouseContributionAmount,
      childrenContributionAmount,

      // 4 tier props (legacy props)
      eeAndChildrenContributionAmount,
      eeAndSpouseContributionAmount,
      eeAndFamilyContributionAmount,
    } = plan;

    const tier3values = [spouseContributionAmount, childrenContributionAmount];
    const tier4values = [
      eeAndChildrenContributionAmount,
      eeAndSpouseContributionAmount,
      eeAndFamilyContributionAmount,
    ];
    const allValues = [...tier3values, ...tier4values];

    // if all value are null or zero, default to using 3 tiers
    const allValuesAreNull = allValues.every((value) => value == null);
    const allValuesAreZero = allValues.every((value) => value === 0);
    if (allValuesAreNull || allValuesAreZero) {
      return true;
    }

    const isUsing3Tier = tier3values.some((v) => v != null && v > 0);
    if (isUsing3Tier) {
      return true;
    }

    // Is possible for QPS that we set only the EE value and set all dependents to 0
    const hasEEContributionWithout4TiersValues =
      (eeContributionAmount ?? 0) > 0 && !tier4values.some((value) => value != null && value > 0);

    if (hasEEContributionWithout4TiersValues) {
      return true;
    }
    return false;
  });
  return isUsing3TierProps;
}
