import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { DashedBanner } from "client/src/components/DashedBanner/DashedBanner";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { TextArea } from "client/src/components/Form/TextArea";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { TagList } from "client/src/components/TagList/TagList";
import { Body2, Body3, Body5, Body6, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EIFClassBuilderEarningsReview } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderEarningsReview";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { handleMaskedMonthDayChange } from "client/src/utils/handleMaskedInputChange";
import { hasFormikError } from "client/src/utils/hasFormikError";
import React from "react";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { getIsInternalUser } from "shared/rbac/rbac";
import { getSalaryBasedClassPlans } from "shared/types/BenefitTypes";
import {
  additionalCompensationsMostCommon,
  additionalCompensationsOther,
  averagedOverValuesRecord,
  earningsTimeFramesValuesRecord,
  earningsTypesValuesRecord,
  incomeTypesPartnershipValuesRecord,
  incomeTypesSCorporationValuesRecord,
  numberOfYearsValuesRecord,
  numberOfYearsValues,
  organizationTypesValues,
  currentEarningsValuesRecord,
  organizationTypesValuesRecord,
  incomeTypesPartnershipValues,
  incomeTypesSCorporationValues,
  averagedOverValues,
  employeeClassEarningsFields,
  rejectNonStandardAdditionalCompensationNames,
  additionalCompensationsNames,
} from "shared/types/EmployeeClass";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getEmployeeClassEarningsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import {
  assertIsDefined,
  getArePropertiesEmpty,
  hasNonNullValues,
  unique,
} from "shared/utils/utils";
import { earningsValidationSchema } from "shared/validation/employeeClass";
import { EIFPlanConfigAndEligibilitySectionHeader } from "../EIFPlanConfigAndEligibilitySectionHeader";
import type {
  AdditionalCompensation,
  AveragedOver,
  CurrentEarnings,
  EarningsTimeFrame,
  EarningsType,
  IncomeTypePartnership,
  IncomeTypeSCorporation,
  NumberOfYears,
  OrganizationType,
} from "@prisma/client";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { UpdateClassQuery } from "client/src/hooks/employeeClass";
import type { FormikErrors } from "formik";
import type { ChangeEvent } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot, ChangeDetailInfo } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type {
  EmployeeClass,
  AveragedOverLabel,
  NumberOfYearsLabel,
  IncomeTypePartnershipLabel,
  IncomeTypeSCorporationLabel,
  EmployeeClassId,
} from "shared/types/EmployeeClass";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const propertiesToCheckForValues: ReadonlyArray<
  keyof ValuesForValidationSchema<typeof earningsValidationSchema>
> = [
  "customEarnings",
  "customEarningsDescription",
  "earningsType",
  "earningsTimeFrame",
  "currentEarnings",
  "includeFirstDayOfTheMonth",
  "numberOfYears",
  "frozenEarningsAsOf",
  "additionalCompensations",
  "averagedOverComissions",
  "averagedOverBonuses",
  "averagedOverOvertimePay",
  "organizationType",
  "incomeTypePartnership",
  "incomeTypeSCorporation",
  "scheduleK1PaidByPolicyholder",
  "earningsPaidToShareholdersCompany",

  // Intentionally not including this one as it defaults to true
  // "additionalCompensationsAveragedWithEarnings"
] as const;

type Props = {
  isActive: boolean;
  client: Client;
  classes: EmployeeClass[];
  employeeClass: EmployeeClass;
  additionalCompensations: AdditionalCompensation[];
  changeSnapshot: DEIFChangeSnapshot;
  updateClassQuery: UpdateClassQuery;
  featureToggles: ClientFeatureToggles;
  onSave: (employeeClass: EmployeeClass) => void;
  track: SectionTracker;
  isLoading: boolean;
  authUser: UserData | null;
};

export function EIFClassBuilderEarnings(props: Props) {
  const {
    isActive,
    classes,
    employeeClass,
    additionalCompensations,
    updateClassQuery: { mutateAsync: updateClass },
    onSave,
    track,
    isLoading,
    client,
    authUser,
    changeSnapshot,
  } = props;

  const location = useLocation();

  const status = getEmployeeClassEarningsCompletionStatus(client, employeeClass);
  const changeDetailInfoList: (ChangeDetailInfo | undefined)[] = [];

  const [showMoreAdditionalCompensations, toggleShowMoreAdditionalCompensations] = useToggler();

  const isInternalUser = getIsInternalUser(authUser);

  const formik = useSlobFormik({
    validationSchema: earningsValidationSchema,
    initialValues: {
      customEarnings: employeeClass.customEarnings,
      customEarningsDescription: employeeClass.customEarningsDescription,
      earningsType: employeeClass.earningsType,
      earningsTimeFrame: employeeClass.earningsTimeFrame,
      currentEarnings: employeeClass.currentEarnings,
      includeFirstDayOfTheMonth: employeeClass.includeFirstDayOfTheMonth,
      numberOfYears: employeeClass.numberOfYears,
      frozenEarningsAsOf: employeeClass.frozenEarningsAsOf,
      additionalCompensations: employeeClass.additionalCompensations,
      averagedOverComissions: employeeClass.averagedOverComissions,
      averagedOverBonuses: employeeClass.averagedOverBonuses,
      averagedOverOvertimePay: employeeClass.averagedOverOvertimePay,
      additionalCompensationsAveragedWithEarnings:
        employeeClass.additionalCompensationsAveragedWithEarnings ?? true,
      organizationType: employeeClass.organizationType,
      incomeTypePartnership: employeeClass.incomeTypePartnership,
      incomeTypeSCorporation: employeeClass.incomeTypeSCorporation,
      scheduleK1PaidByPolicyholder: employeeClass.scheduleK1PaidByPolicyholder,
      earningsPaidToShareholdersCompany: employeeClass.earningsPaidToShareholdersCompany,
    },
    validationContext: { client, prefill: true },
    onSubmit: async () => {
      const { isSuccess, data } = await updateClass({
        params: { clientId: client.id, employeeClassId: employeeClass.id },
        data: { ...formik.values, clientId: client.id, id: employeeClass.id },
      });

      if (isSuccess) {
        track("Save");
        void slobMessage.success("Updated Earnings");
        onSave(data);
      }
    },
  });

  async function handleMonthDayChange(e: ChangeEvent<HTMLInputElement>) {
    const { name } = e.currentTarget;
    await handleMaskedMonthDayChange((maskedValue) => formik.setFieldValue(name, maskedValue))(e);
  }

  const hideEarningsSectionButtons = !getIsInternalUser(authUser) && employeeClass?.customEarnings;

  const arePropertiesEmpty = getArePropertiesEmpty(formik.values, propertiesToCheckForValues);

  const haveEverSavedForm =
    employeeClass &&
    hasNonNullValues(
      getPropertiesToUpdate<EmployeeClass>(propertiesToCheckForValues)(employeeClass),
    );

  const prefillErrors =
    haveEverSavedForm || formik.submitCount > 0
      ? getFormikErrors(formik.values, earningsValidationSchema, { client, prefill: false })
      : {};

  if (!isActive) {
    const inactiveAndEmpty = !isActive && getIsEarningsEmpty(employeeClass);

    return (
      <>
        <EIFPlanConfigAndEligibilitySectionHeader
          status={status}
          isLoading={isLoading}
          location={location}
          inactiveAndEmpty={inactiveAndEmpty}
          changeDetailInfoList={changeDetailInfoList}
          section="Earnings definition"
          client={client}
          authUser={authUser}
          hideActionButtons={hideEarningsSectionButtons}
        >
          <EIFClassBuilderEarningsReview
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            client={client}
            authUser={authUser}
            prefillErrors={prefillErrors}
          />
        </EIFPlanConfigAndEligibilitySectionHeader>
      </>
    );
  }

  const salaryBasedClassPlans = getSalaryBasedClassPlans(employeeClass);

  const additionalCompensationsErrorId = hasFormikError(formik, "additionalCompensations")
    ? "additionalCompensations__errormessage"
    : undefined;

  const additionalCompenstionErrorElement = (
    <div aria-live="assertive" className="hide:empty">
      {(hasFormikError(formik, "additionalCompensations") ||
        prefillErrors.additionalCompensations) && (
        <InputErrorMessage
          id={additionalCompensationsErrorId}
          error={
            formik.errors.additionalCompensations
              ? Array.isArray(formik.errors.additionalCompensations)
                ? formik.errors.additionalCompensations[0]
                : formik.errors.additionalCompensations
              : Array.isArray(prefillErrors.additionalCompensations)
              ? prefillErrors.additionalCompensations[0]
              : prefillErrors.additionalCompensations
          }
        />
      )}
    </div>
  );

  const additionalCompensationsMostCommonSelected = additionalCompensationsMostCommon.some((v) =>
    formik.values.additionalCompensations?.includes(v),
  );

  const hasMoreThanOneOrganizationType = classes.some(
    (iterateeClass) =>
      formik.values.organizationType &&
      iterateeClass.organizationType &&
      iterateeClass.id !== employeeClass.id &&
      iterateeClass.organizationType !== formik.values.organizationType,
  );

  const partnershipOrganizationType = organizationTypesValues[0];
  const sCorporationOrganizationType = organizationTypesValues[1];
  const solePropertierOrganizationType = organizationTypesValues[2];
  assertIsDefined(partnershipOrganizationType, "partnershipOrganizationType");
  assertIsDefined(sCorporationOrganizationType, "sCorporationOrganizationType");
  assertIsDefined(solePropertierOrganizationType, "solePropertierOrganizationType");

  return (
    <>
      <div className="mb-24">
        <H3 as="h2">
          <EIFSectionStatusIconography status={status} />
          Earnings definition
        </H3>
      </div>

      <Body2 as="p">
        This definition of earnings applies to the following salary-based benefits:
      </Body2>

      <div className="mb-20">
        {salaryBasedClassPlans.map((classPlan) => {
          const coverage = benefitTypeToCoverage[classPlan.plan.benefitType];
          return (
            <Body3 as="div" key={classPlan.id}>
              {coverage}
            </Body3>
          );
        })}
      </div>

      <Body2 as="p">
        How will you define earnings used for salary-based benefits for this eligible employee
        group?
      </Body2>
      <Body3 as="p">
        Claim payouts and premiums for salary-based benefits are calculated based on how an
        employee’s earnings are defined.
      </Body3>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32} wrap={false}>
          {isInternalUser && (
            <>
              <DashedBanner variant="warning">
                <StackY dist={16} wrap={false}>
                  <Body2 darkYellow>Internal user:</Body2>

                  <Checkbox
                    name="customEarnings"
                    label="Custom earnings definition"
                    disabled={formik.isSubmitting}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.resetForm();
                      } else {
                        void formik.setFieldValue("customEarningsDescription", null);
                      }
                      formik.handleChange(e);
                    }}
                    checked={!!formik.values.customEarnings}
                    content={
                      <div>
                        <Body5 as="p">
                          If your Client asks for a non-standard option, you’ll need to confirm the
                          special language. You can then enter the details here.
                        </Body5>
                        <Body5 as="p">
                          Keep in mind that underwriting must approve any deviations from our normal
                          earnings prior to sending to the Special Language team.
                        </Body5>
                        <Body5 as="p">
                          Refer to the{" "}
                          <Anchor
                            target="_blank"
                            href="https://sunlifefinancial.sharepoint.com/:w:/r/sites/HR/EntTr/_layouts/15/Doc.aspx?sourcedoc=%7B7926D328-9E87-4EB5-A8DF-E62C8F55798C%7D&file=Special%20language%20questions.docx&action=default&mobileredirect=true&cid=e5143d5a-b364-408f-92d0-f3e3337be183"
                          >
                            Special Language Questions
                          </Anchor>{" "}
                          job aid for more detail.
                        </Body5>
                      </div>
                    }
                  />

                  <TextArea
                    aria-label="Custom earnings description"
                    name="customEarningsDescription"
                    placeholder="Describe custom options here"
                    maxLength={2500}
                    value={formik.values.customEarningsDescription}
                    disabled={!formik.values.customEarnings || formik.isSubmitting}
                    onChange={formik.handleChange}
                    touched={
                      formik.touched.customEarningsDescription ||
                      !!prefillErrors.customEarningsDescription
                    }
                    error={
                      formik.errors.customEarningsDescription ||
                      prefillErrors.customEarningsDescription
                    }
                  />
                </StackY>
              </DashedBanner>
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.EmployeeClass[employeeClass.id]?.customEarnings,
                  changeSnapshot.EmployeeClass[employeeClass.id]?.customEarningsDescription,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={
                  getHasPendingEdit({
                    field: "customEarnings",
                    client,
                    formik,
                  }) ||
                  getHasPendingEdit({
                    field: "customEarningsDescription",
                    client,
                    formik,
                  })
                }
              />
            </>
          )}

          {!formik.values.customEarnings && (
            <>
              <RadioGroup<EarningsType>
                name="earningsType"
                disabled={formik.isSubmitting}
                touched={formik.touched.earningsType || !!prefillErrors.earningsType}
                error={formik.errors.earningsType || prefillErrors.earningsType}
                direction="vertical"
                value={formik.values.earningsType}
                onChange={formik.handleChange}
                label={<Body2 as="p">Earnings type</Body2>}
                options={[
                  {
                    value: "GROSS_EARNINGS",
                    label: (
                      <StackX dist={12} className="pt-4">
                        <Body3>{earningsTypesValuesRecord["GROSS_EARNINGS"]}</Body3>
                        <Body5>Most common</Body5>
                      </StackX>
                    ),
                    contentSpacing: "tight",
                    content: (
                      <div className="ml-32">
                        <Body5 as="div">
                          This includes employee pre-tax contributions to a qualified deferred
                          compensation plan, 401(k) plan, Section 125 plan, health savings account,
                          or flexible spending account. This excludes commissions, bonuses, overtime
                          pay and extra compensation unless you select otherwise.
                        </Body5>
                      </div>
                    ),
                  },
                  {
                    value: "W2_EARNINGS",
                    label: earningsTypesValuesRecord["W2_EARNINGS"],
                    contentSpacing: "tight",
                    content: (
                      <div className="ml-32">
                        <Body5 as="div">
                          This <strong>excludes</strong> employee pre-tax contributions to a
                          qualified deferred compensation plan, 401(k) plan, Section 125 plan,
                          health savings account, or flexible spending account, but will include
                          overtime pay, commissions, bonuses and any other income that is reported
                          on the employee’s W-2 as “salary, wages and tips.”
                        </Body5>
                      </div>
                    ),
                  },
                  {
                    value: "PARTNERS_OWNERS_SHAREHOLDERS",
                    label: earningsTypesValuesRecord["PARTNERS_OWNERS_SHAREHOLDERS"],
                    contentSpacing: "tight",
                    content: (
                      <div className="ml-32">
                        <Body5 as="div">
                          If gross earnings or W-2 earnings will not apply to a partner, owner, or
                          shareholder, select this option and provide details about the organization
                          type.
                        </Body5>
                      </div>
                    ),
                  },
                ]}
              />
              <EditedFieldMsg
                changeDetailInfoList={[
                  changeSnapshot.EmployeeClass[employeeClass.id]?.earningsType,
                ]}
                client={client}
                authUser={authUser}
                hasPendingEdit={getHasPendingEdit({
                  field: "earningsType",
                  client,
                  formik,
                })}
              />

              {formik.values.earningsType === "GROSS_EARNINGS" && (
                <>
                  <RadioGroup<EarningsTimeFrame>
                    name="earningsTimeFrame"
                    disabled={formik.isSubmitting}
                    touched={formik.touched.earningsTimeFrame || !!prefillErrors.earningsTimeFrame}
                    error={formik.errors.earningsTimeFrame || prefillErrors.earningsTimeFrame}
                    direction="vertical"
                    value={formik.values.earningsTimeFrame}
                    onChange={formik.handleChange}
                    label={<Body2 as="p">Earnings time frame</Body2>}
                    options={[
                      {
                        value: "CURRENT_EARNINGS",
                        label: (
                          <StackX dist={12} className="pt-4">
                            <Body3>{earningsTimeFramesValuesRecord["CURRENT_EARNINGS"]}</Body3>
                            <Body5>Most common</Body5>
                          </StackX>
                        ),
                        contentSpacing: "tight",
                        content: (
                          <div className="ml-32 stack-y-16">
                            <Body5 as="div">The current wage or salary for your employee.</Body5>

                            {formik.values.earningsTimeFrame === "CURRENT_EARNINGS" && (
                              <>
                                <RadioGroup<CurrentEarnings>
                                  name="currentEarnings"
                                  disabled={formik.isSubmitting}
                                  touched={
                                    formik.touched.currentEarnings ||
                                    !!prefillErrors.currentEarnings
                                  }
                                  error={
                                    formik.errors.currentEarnings || prefillErrors.currentEarnings
                                  }
                                  direction="vertical"
                                  value={formik.values.currentEarnings}
                                  onChange={formik.handleChange}
                                  label={null}
                                  aria-label="Current earnings"
                                  options={[
                                    {
                                      value: "IMMEDIATELY",
                                      label: (
                                        <StackX dist={12} className="pt-4">
                                          <Body3>
                                            {currentEarningsValuesRecord["IMMEDIATELY"]}
                                          </Body3>
                                          <Body5>Most common</Body5>
                                        </StackX>
                                      ),
                                      contentSpacing: "tight",
                                      content: (
                                        <div className="ml-32">
                                          <Body5 as="div">
                                            Salary changes take effect immediately.
                                          </Body5>
                                        </div>
                                      ),
                                    },
                                    {
                                      value: "FIRST_OF_THE_FOLLOWING_MONTH",
                                      label:
                                        currentEarningsValuesRecord["FIRST_OF_THE_FOLLOWING_MONTH"],
                                      contentSpacing: "tight",
                                      content: (
                                        <div className="ml-32 stack-y-16">
                                          <Body5 as="div">
                                            Salary changes take effect on the first day of the next
                                            month after the change.
                                          </Body5>

                                          {formik.values.currentEarnings ===
                                            "FIRST_OF_THE_FOLLOWING_MONTH" && (
                                            <>
                                              <Checkbox
                                                name="includeFirstDayOfTheMonth"
                                                label={
                                                  <>
                                                    <Body3>Include first day of the month</Body3>
                                                    <br />
                                                    <Body5>
                                                      Changes that occur on the first of the month
                                                      will take effect the same day. Otherwise,
                                                      changes will take effect on the first day of
                                                      the next month.
                                                    </Body5>
                                                  </>
                                                }
                                                disabled={formik.isSubmitting}
                                                checked={!!formik.values.includeFirstDayOfTheMonth}
                                                onChange={formik.handleChange}
                                              />
                                              <EditedFieldMsg
                                                changeDetailInfoList={[
                                                  changeSnapshot.EmployeeClass[employeeClass.id]
                                                    ?.includeFirstDayOfTheMonth,
                                                ]}
                                                client={client}
                                                authUser={authUser}
                                                hasPendingEdit={getHasPendingEdit({
                                                  field: "includeFirstDayOfTheMonth",
                                                  client,
                                                  formik,
                                                })}
                                              />
                                            </>
                                          )}
                                        </div>
                                      ),
                                    },
                                  ]}
                                />
                                <EditedFieldMsg
                                  changeDetailInfoList={[
                                    changeSnapshot.EmployeeClass[employeeClass.id]?.currentEarnings,
                                  ]}
                                  client={client}
                                  authUser={authUser}
                                  hasPendingEdit={getHasPendingEdit({
                                    field: "currentEarnings",
                                    client,
                                    formik,
                                  })}
                                />
                              </>
                            )}
                          </div>
                        ),
                      },
                      {
                        value: "PRIOR_CALENDAR_YEAR_EARNINGS",
                        label: earningsTimeFramesValuesRecord["PRIOR_CALENDAR_YEAR_EARNINGS"],
                        contentSpacing: "tight",
                        content: (
                          <div className="ml-32 stack-y-16">
                            <Body5 as="div">
                              Calculations for a claim will be based on the average earnings over
                              the entire calendar year(s) for the number of prior years you select.
                              Changes in earnings during the year will be reflected on January 1st
                              of the following year.
                            </Body5>

                            {formik.values.earningsTimeFrame === "PRIOR_CALENDAR_YEAR_EARNINGS" && (
                              <NumberOfYearsDropdown
                                formik={formik}
                                prefillErrors={prefillErrors}
                              />
                            )}
                            <EditedFieldMsg
                              changeDetailInfoList={[
                                changeSnapshot.EmployeeClass[employeeClass.id]?.numberOfYears,
                              ]}
                              client={client}
                              authUser={authUser}
                              hasPendingEdit={getHasPendingEdit({
                                field: "numberOfYears",
                                client,
                                formik,
                              })}
                            />
                          </div>
                        ),
                      },
                      {
                        value: "FROZEN_EARNINGS",
                        label: earningsTimeFramesValuesRecord["FROZEN_EARNINGS"],
                        contentSpacing: "tight",
                        content: (
                          <div className="ml-32 stack-y-16">
                            <Body5 as="div">
                              Calculations for a claim will be based on the most recent salary as of
                              the freeze date you select. Changes in earnings during the year will
                              not be reflected until the next freeze date.
                            </Body5>

                            {formik.values.earningsTimeFrame === "FROZEN_EARNINGS" && (
                              <>
                                <div style={{ width: 178 }}>
                                  <StackY dist={8} wrap={false}>
                                    <Body3 id="frozenEarningsAsOf__description">
                                      Freeze earnings as of:
                                    </Body3>

                                    <FormInput
                                      name="frozenEarningsAsOf"
                                      aria-describedby="frozenEarningsAsOf__description"
                                      label="MM / DD"
                                      disabled={formik.isSubmitting}
                                      onChange={handleMonthDayChange}
                                      touched={
                                        formik.touched.earningsTimeFrame ||
                                        formik.touched.frozenEarningsAsOf ||
                                        !!prefillErrors.earningsTimeFrame ||
                                        !!prefillErrors.frozenEarningsAsOf
                                      }
                                      value={formik.values.frozenEarningsAsOf ?? ""}
                                      error={
                                        formik.errors.frozenEarningsAsOf ||
                                        prefillErrors.frozenEarningsAsOf
                                      }
                                      maxLength={5}
                                    />
                                  </StackY>
                                </div>
                                <EditedFieldMsg
                                  changeDetailInfoList={[
                                    changeSnapshot.EmployeeClass[employeeClass.id]
                                      ?.frozenEarningsAsOf,
                                  ]}
                                  client={client}
                                  authUser={authUser}
                                  hasPendingEdit={getHasPendingEdit({
                                    field: "frozenEarningsAsOf",
                                    client,
                                    formik,
                                  })}
                                />
                              </>
                            )}
                          </div>
                        ),
                      },
                    ]}
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.EmployeeClass[employeeClass.id]?.earningsTimeFrame,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={getHasPendingEdit({
                      field: "earningsTimeFrame",
                      client,
                      formik,
                    })}
                  />

                  <div>
                    <Body2>Additional compensation</Body2>

                    <p>
                      Include additional compensation in addition to salary. We will take the
                      average over a time period to determine the value of any additional
                      compensation to use for claim payouts.
                    </p>

                    <StackY dist={16} wrap={false}>
                      {additionalCompensationsMostCommon.map((compensation) => (
                        <React.Fragment key={compensation}>
                          <Checkbox
                            name="additionalCompensations"
                            value={compensation}
                            label={compensation}
                            disabled={formik.isSubmitting}
                            checked={
                              formik.values.additionalCompensations?.includes(compensation) ?? false
                            }
                            onChange={formik.handleChange}
                            errorId={additionalCompensationsErrorId}
                          />

                          {!!formik.values.additionalCompensations?.includes(compensation) && (
                            <div className="ml-32">
                              <AveragedOverDropdown
                                aria-label={`Averaged over for ${compensation}`}
                                name={
                                  (
                                    {
                                      Commissions: "averagedOverComissions",
                                      Bonuses: "averagedOverBonuses",
                                      "Overtime pay": "averagedOverOvertimePay",
                                    } as const
                                  )[compensation]
                                }
                                formik={formik}
                                prefillErrors={prefillErrors}
                                disabled={
                                  !formik.values.additionalCompensations?.includes(compensation)
                                }
                              />
                            </div>
                          )}
                        </React.Fragment>
                      ))}

                      {additionalCompenstionErrorElement}

                      <Checkbox
                        name="additionalCompensationsAveragedWithEarnings"
                        label={
                          <StackX dist={12}>
                            <Body3>Average additional compensation together with earnings</Body3>
                            <Body5>Most common</Body5>
                          </StackX>
                        }
                        disabled={!additionalCompensationsMostCommonSelected || formik.isSubmitting}
                        checked={!!formik.values.additionalCompensationsAveragedWithEarnings}
                        onChange={formik.handleChange}
                        errorId={undefined}
                      />

                      <div>
                        <Button
                          size="middle"
                          type="text-only"
                          icon={
                            <FontAwesomeIcon
                              icon={showMoreAdditionalCompensations ? faChevronUp : faChevronDown}
                            />
                          }
                          iconPosition="right"
                          onClick={toggleShowMoreAdditionalCompensations}
                        >
                          {showMoreAdditionalCompensations ? "Show less" : "Show more"}
                        </Button>
                      </div>

                      {showMoreAdditionalCompensations && (
                        <div>
                          <p>Other additional compensation:</p>

                          <StackY dist={16} wrap={false}>
                            {additionalCompensationsOther.map((compensation) => (
                              <Checkbox
                                key={compensation}
                                name="additionalCompensations"
                                value={compensation}
                                label={compensation}
                                disabled={formik.isSubmitting}
                                checked={
                                  formik.values.additionalCompensations?.includes(compensation) ??
                                  false
                                }
                                onChange={formik.handleChange}
                                errorId={additionalCompensationsErrorId}
                              />
                            ))}

                            <div>
                              <Body6 as="div">
                                Add additional compensation types you’d like to include in your
                                definition of earnings, for example “tips”. Select ‘X’ to remove.
                              </Body6>

                              <TagList
                                name="additionalCompensations"
                                placeholder="Add compensation type and press enter"
                                touched={
                                  formik.touched.additionalCompensations ||
                                  !!prefillErrors.additionalCompensations
                                }
                                value={formik.values.additionalCompensations?.filter(
                                  (v) => !rejectNonStandardAdditionalCompensationNames(v),
                                )}
                                options={additionalCompensations.map((ac) => ({
                                  label: ac.name,
                                  value: ac.name,
                                }))}
                                disabled={formik.isSubmitting}
                                onChange={async (event) => {
                                  const { name, value } = event.target;
                                  const prevValues = formik.values.additionalCompensations ?? [];
                                  const prevValuesWithoutNonStandardNames = prevValues.filter(
                                    rejectNonStandardAdditionalCompensationNames,
                                  );
                                  const valuesNormalized = value.map((compensationName) => {
                                    const standardCompensationName =
                                      additionalCompensationsNames.find(
                                        (adc) =>
                                          adc.toLocaleLowerCase().trim() ===
                                          compensationName.toLocaleLowerCase().trim(),
                                      );
                                    return standardCompensationName || compensationName;
                                  });
                                  const nextValues = unique(
                                    prevValuesWithoutNonStandardNames.concat(valuesNormalized),
                                  );
                                  await formik.setFieldValue(name, nextValues);
                                }}
                                errorId={additionalCompensationsErrorId}
                              />
                            </div>

                            {additionalCompenstionErrorElement}
                          </StackY>
                        </div>
                      )}
                      <EditedFieldMsg
                        changeDetailInfoList={[
                          changeSnapshot.EmployeeClass[employeeClass.id]?.additionalCompensations,
                          changeSnapshot.EmployeeClass[employeeClass.id]?.averagedOverComissions,
                          changeSnapshot.EmployeeClass[employeeClass.id]?.averagedOverBonuses,
                          changeSnapshot.EmployeeClass[employeeClass.id]?.averagedOverOvertimePay,
                          changeSnapshot.EmployeeClass[employeeClass.id]
                            ?.additionalCompensationsAveragedWithEarnings,
                        ]}
                        client={client}
                        authUser={authUser}
                        hasPendingEdit={
                          getHasPendingEdit({
                            field: "additionalCompensations",
                            client,
                            formik,
                          }) ||
                          getHasPendingEdit({
                            field: "averagedOverComissions",
                            client,
                            formik,
                          }) ||
                          getHasPendingEdit({
                            field: "averagedOverBonuses",
                            client,
                            formik,
                          }) ||
                          getHasPendingEdit({
                            field: "averagedOverOvertimePay",
                            client,
                            formik,
                          }) ||
                          getHasPendingEdit({
                            field: "additionalCompensationsAveragedWithEarnings",
                            client,
                            formik,
                          })
                        }
                      />
                    </StackY>
                  </div>
                </>
              )}

              {formik.values.earningsType === "W2_EARNINGS" && (
                <StackY dist={32} wrap={false}>
                  <BulbMessage>
                    <Body3>Salary changes for W-2 earnings take effect on January 1st.</Body3>
                  </BulbMessage>

                  <div>
                    <Body2 as="p">
                      Average the earnings over the following number of prior calendar years
                    </Body2>

                    <NumberOfYearsDropdown formik={formik} prefillErrors={prefillErrors} />
                  </div>
                </StackY>
              )}

              {formik.values.earningsType === "PARTNERS_OWNERS_SHAREHOLDERS" && (
                <StackY dist={32} wrap={false}>
                  <BulbMessage>
                    <Body3>
                      Salary changes for earnings for partners, owners and/or shareholders take
                      effect on January 1st.
                    </Body3>
                  </BulbMessage>

                  <RadioGroup<OrganizationType>
                    name="organizationType"
                    disabled={formik.isSubmitting}
                    touched={formik.touched.organizationType || !!prefillErrors.organizationType}
                    error={formik.errors.organizationType || prefillErrors.organizationType}
                    direction="vertical"
                    value={formik.values.organizationType}
                    onChange={formik.handleChange}
                    label="Form used to report earnings"
                    options={[
                      {
                        value: partnershipOrganizationType,
                        label: organizationTypesValuesRecord[partnershipOrganizationType],
                        contentSpacing: "tight",
                        content: (
                          <>
                            {formik.values.organizationType === partnershipOrganizationType && (
                              <div className="ml-32 stack-y-16">
                                <SlobSelect<{
                                  label: IncomeTypePartnershipLabel | "";
                                  value: IncomeTypePartnership | "";
                                }>
                                  name="incomeTypePartnership"
                                  placeholder="Income type"
                                  options={incomeTypesPartnershipValues.map((item) => ({
                                    value: item,
                                    label: incomeTypesPartnershipValuesRecord[item],
                                  }))}
                                  value={formik.values.incomeTypePartnership}
                                  onChange={({ value }) =>
                                    formik.setFieldValue("incomeTypePartnership", value)
                                  }
                                  disabled={formik.isSubmitting}
                                  showRequired={false}
                                  loading={formik.isSubmitting}
                                  touched={
                                    formik.touched.incomeTypePartnership ||
                                    !!prefillErrors.incomeTypePartnership
                                  }
                                  error={
                                    formik.errors.incomeTypePartnership ||
                                    prefillErrors.incomeTypePartnership
                                  }
                                />
                                {client.healthcareProfessionalsSegment && (
                                  <RadioGroup
                                    name="scheduleK1PaidByPolicyholder"
                                    label={
                                      <>
                                        <div className="pt-8">
                                          <Body2>
                                            Is the Schedule K-1 paid by the Policyholder to the
                                            Physician's own company?
                                          </Body2>
                                        </div>
                                      </>
                                    }
                                    value={formik.values.scheduleK1PaidByPolicyholder}
                                    direction="vertical"
                                    onChange={formik.handleChange}
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.scheduleK1PaidByPolicyholder ||
                                      !!prefillErrors.scheduleK1PaidByPolicyholder
                                    }
                                    error={
                                      formik.errors.scheduleK1PaidByPolicyholder ||
                                      prefillErrors.scheduleK1PaidByPolicyholder
                                    }
                                    options={[
                                      { value: "YES", label: "Yes" },
                                      { value: "NO", label: "No" },
                                    ]}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        ),
                      },
                      {
                        value: sCorporationOrganizationType,
                        label: organizationTypesValuesRecord[sCorporationOrganizationType],
                        contentSpacing: "tight",
                        content: (
                          <>
                            {formik.values.organizationType === sCorporationOrganizationType && (
                              <div className="ml-32 stack-y-16">
                                <SlobSelect<{
                                  label: IncomeTypeSCorporationLabel | "";
                                  value: IncomeTypeSCorporation | "";
                                }>
                                  name="incomeTypeSCorporation"
                                  placeholder="Income type"
                                  options={incomeTypesSCorporationValues.map((item) => ({
                                    value: item,
                                    label: incomeTypesSCorporationValuesRecord[item],
                                  }))}
                                  value={formik.values.incomeTypeSCorporation}
                                  onChange={({ value }) =>
                                    formik.setFieldValue("incomeTypeSCorporation", value)
                                  }
                                  disabled={formik.isSubmitting}
                                  showRequired={false}
                                  loading={formik.isSubmitting}
                                  touched={
                                    formik.touched.incomeTypeSCorporation ||
                                    !!prefillErrors.incomeTypeSCorporation
                                  }
                                  error={
                                    formik.errors.incomeTypeSCorporation ||
                                    prefillErrors.incomeTypeSCorporation
                                  }
                                />
                                {client.healthcareProfessionalsSegment && (
                                  <RadioGroup
                                    name="earningsPaidToShareholdersCompany"
                                    label={
                                      <>
                                        <div className="pt-8">
                                          <Body2>
                                            Are earnings paid to the Shareholders associated
                                            company?
                                          </Body2>
                                        </div>
                                      </>
                                    }
                                    value={formik.values.earningsPaidToShareholdersCompany}
                                    direction="vertical"
                                    onChange={formik.handleChange}
                                    disabled={formik.isSubmitting}
                                    touched={
                                      formik.touched.earningsPaidToShareholdersCompany ||
                                      !!prefillErrors.earningsPaidToShareholdersCompany
                                    }
                                    error={
                                      formik.errors.earningsPaidToShareholdersCompany ||
                                      prefillErrors.earningsPaidToShareholdersCompany
                                    }
                                    options={[
                                      { value: "YES", label: "Yes" },
                                      { value: "NO", label: "No" },
                                    ]}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        ),
                      },
                      {
                        value: solePropertierOrganizationType,
                        label: organizationTypesValuesRecord[solePropertierOrganizationType],
                      },
                    ]}
                  />
                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.EmployeeClass[employeeClass.id]?.organizationType,
                      changeSnapshot.EmployeeClass[employeeClass.id]?.incomeTypePartnership,
                      changeSnapshot.EmployeeClass[employeeClass.id]?.incomeTypeSCorporation,
                      changeSnapshot.EmployeeClass[employeeClass.id]?.scheduleK1PaidByPolicyholder,
                      changeSnapshot.EmployeeClass[employeeClass.id]
                        ?.earningsPaidToShareholdersCompany,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={
                      getHasPendingEdit({
                        field: "organizationType",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "incomeTypePartnership",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "incomeTypeSCorporation",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "scheduleK1PaidByPolicyholder",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "earningsPaidToShareholdersCompany",
                        client,
                        formik,
                      })
                    }
                  />

                  {hasMoreThanOneOrganizationType && (
                    <AlertBanner
                      variant="warning"
                      message={
                        <Body3>
                          Your form used to report earnings option must match other eligible
                          employee groups that have partner, owner and/or shareholder earnings
                          selected. Currently, another eligible employee group has a different
                          option selected. Be sure to check that they match.
                        </Body3>
                      }
                    />
                  )}
                </StackY>
              )}
            </>
          )}

          <Body5 as="p">Need custom options? Let your Implementation Consultant know.</Body5>

          <div aria-live="assertive" className="hide:empty">
            {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
          </div>
        </StackY>

        <Row justify="end">
          <Col>
            <Row align="middle" gutter={28}>
              <Col>
                <InternalLinkButton
                  type="link-inline-bold"
                  size="middle"
                  to={location.pathname}
                  disabled={formik.isSubmitting}
                  onClick={() => flushSync(formik.resetForm)}
                >
                  Cancel
                </InternalLinkButton>
              </Col>

              <Col>
                <Button
                  htmlType="submit"
                  type="secondary"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || arePropertiesEmpty}
                  size="middle"
                >
                  Save & Continue
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}

type AveragedOverDropdownProps = {
  "aria-label": string;
  name: keyof typeof earningsValidationSchema.fields;
  formik: ReturnType<typeof useSlobFormik<typeof earningsValidationSchema>>;
  prefillErrors: FormikErrors<ValuesForValidationSchema<typeof earningsValidationSchema>>;
  disabled: boolean;
};

function AveragedOverDropdown(props: AveragedOverDropdownProps) {
  const { name, formik, prefillErrors, disabled } = props;

  return (
    <div style={{ width: 300 }}>
      <SlobSelect<{ label: AveragedOverLabel | ""; value: AveragedOver | "" }>
        name={name}
        aria-label={props["aria-label"]}
        placeholder="Averaged over"
        options={averagedOverValues.map((item) => ({
          value: item,
          label: averagedOverValuesRecord[item],
        }))}
        value={
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
          formik.values[name] as AveragedOver
        }
        onChange={({ value }) => formik.setFieldValue(name, value)}
        disabled={disabled || formik.isSubmitting}
        showRequired={false}
        loading={formik.isSubmitting}
        touched={formik.touched[name] || !!prefillErrors[name]}
        error={formik.errors[name] || prefillErrors[name]}
      />
    </div>
  );
}

type NumberOfYearsDropdownProps = {
  formik: ReturnType<typeof useSlobFormik<typeof earningsValidationSchema>>;
  prefillErrors: FormikErrors<ValuesForValidationSchema<typeof earningsValidationSchema>>;
};

function NumberOfYearsDropdown(props: NumberOfYearsDropdownProps) {
  const { formik, prefillErrors } = props;

  return (
    <div style={{ width: 300 }}>
      <SlobSelect<{
        label: NumberOfYearsLabel | "";
        value: NumberOfYears | "";
      }>
        name="numberOfYears"
        placeholder="Number of years"
        options={numberOfYearsValues.map((item) => ({
          value: item,
          label: numberOfYearsValuesRecord[item],
        }))}
        value={formik.values.numberOfYears}
        onChange={({ value }) => formik.setFieldValue("numberOfYears", value)}
        disabled={formik.isSubmitting}
        showRequired={false}
        loading={formik.isSubmitting}
        touched={formik.touched.numberOfYears || !!prefillErrors.numberOfYears}
        error={formik.errors.numberOfYears || prefillErrors.numberOfYears}
      />
    </div>
  );
}

function getIsEarningsEmpty(employeeClass: EmployeeClass) {
  const isEmpty = employeeClassEarningsFields.every((field) => employeeClass[field] == null);

  return isEmpty;
}

export const relevantChangesForEarningsFields = (
  employeeClassId: EmployeeClassId,
  changeSnapshot: DEIFChangeSnapshot,
) => {
  if (!changeSnapshot) return [];

  if (!changeSnapshot.EmployeeClass[employeeClassId]) return [];

  const changeDetailRecords = employeeClassEarningsFields.map((fieldName) => {
    const changeDetailRecord = changeSnapshot.EmployeeClass[employeeClassId]?.[fieldName];
    return changeDetailRecord;
  });

  return changeDetailRecords;
};
