import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SegmentedButton } from "client/src/components/SegmentedButton/SegmentedButton";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import clsx from "clsx";

import { useState } from "react";
import { Body1, Body3, Body5, H3 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";
import { getCSSVariableValue } from "../../../../utils/getCSSVariableValue";
import { copyKey, copyKeyExists } from "../../utils";

import * as styles from "./benefit.module.less";

export const DataTestId = {
  PlanComparisonTableWVClient: "plan-comparison-table-wv-client",
};

type TableCopy = {
  heading: string;
  tableHeading: string;
  description?: string;
  tableHeaders: string[];
  planMessage?: string;
  0: string[];
  1: string[];
  2: string[];
  3: string[];
};

export type ExplorerPlanComparisonTableWVClientDentalCopyStructure = {
  IN_NETWORK: TableCopy;
  OUT_NETWORK: TableCopy;
};

export const ExplorerPlanComparisonTableWVClientDental = () => {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTableWVClientDental" });
  const screens = useBreakpoint();

  const [networkInOutToggle, setNetworkInOutToggle] = useState<"IN_NETWORK" | "OUT_NETWORK">(
    "IN_NETWORK",
  );

  const baseKey = [networkInOutToggle];

  const rowCount = t<string, string[]>(copyKey(...baseKey, "tableHeaders"), {
    defaultValue: [],
    returnObjects: true,
  }).length;
  const descriptionCopy = t(copyKey(...baseKey, "description"), { defaultValue: null });

  return (
    <>
      <H3>
        <strong>{t(copyKey(...baseKey, "heading"))}</strong>
      </H3>

      <Row
        style={{
          margin: !screens.md ? "0 -35px" : undefined,
        }}
        className={clsx(styles.wvDentalTableInOutNetworkColor, "p-16")}
        align="middle"
        gutter={[0, 8]}
      >
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          <Body1>
            <i id="networkDescription">{t(copyKey(...baseKey, "tableHeading"))}</i>{" "}
            <Tooltip
              title={
                <>
                  <div>
                    In-Network: A dentist who participates in your plan's dental network and whose
                    charges are based on amounts negotiated by the network.{" "}
                  </div>
                  <div>
                    {" "}
                    Out-of-Network: A dentist not in your PPO network—you may incur higher
                    out-of-pocket costs
                  </div>
                </>
              }
              placement="bottom"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: getCSSVariableValue("--color-tertiary-900") }}
              />
            </Tooltip>
          </Body1>
        </Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          style={{ display: "flex", justifyContent: !screens.md ? "center" : "flex-end" }}
        >
          <SegmentedButton<typeof networkInOutToggle>
            variant={screens.md ? "default" : "small"}
            name="network"
            aria-labelledby="networkDescription"
            value={networkInOutToggle}
            options={[
              { value: "IN_NETWORK", label: "In-Network" },
              { value: "OUT_NETWORK", label: "Out-of-Network" },
            ]}
            onClick={(e) => setNetworkInOutToggle(e.currentTarget.value)}
          />
        </Col>
      </Row>

      {descriptionCopy && (
        <Row className="mb-12">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Body1>{descriptionCopy}</Body1>
          </Col>
        </Row>
      )}
      <div
        className={styles.planTable}
        data-testid={DataTestId.PlanComparisonTableWVClient}
        style={{ margin: !screens.md ? "0 -35px" : undefined }}
      >
        <div className={styles.rows}>
          {[...Array(rowCount)].map((_, rowIndex) => (
            <TableRow
              key={`row-${rowIndex}`}
              isHeader={rowIndex === 0}
              valueLabel={
                <Trans t={t} i18nKey={copyKey(...baseKey, "tableHeaders", rowIndex.toString())}>
                  <Body5></Body5>
                  <div className={screens.md ? "pl-24" : ""}></div>
                </Trans>
              }
              values={[...Array(4)].map((_, colIndex) => (
                <Trans t={t} i18nKey={copyKey(...baseKey, colIndex, rowIndex)}>
                  <Body5></Body5>
                </Trans>
              ))}
            />
          ))}
          {copyKeyExists(t, ...baseKey, "planMessage") && (
            <Row align="middle" justify="center" style={{ backgroundColor: "white" }}>
              <Col
                className={clsx(styles.tableCell, "p-24")}
                style={{ textAlign: "center", width: "100%" }}
              >
                <Trans t={t} i18nKey={copyKey(...baseKey, "planMessage")}>
                  <H3></H3>
                  <p></p>
                  <p className="body5"></p>
                </Trans>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

type TableRowProps = {
  valueLabel: string | React.ReactElement;
  values: (string | React.ReactElement)[];
  isHeader?: boolean;
};
const TableRow = ({ valueLabel, values, isHeader = false }: TableRowProps) => {
  const screens = useBreakpoint();

  const columns = screens.md ? [valueLabel, ...values] : values;

  return (
    <Row align="stretch" wrap={false}>
      {columns.map((col, i) => (
        <Col
          key={`col-${i}`}
          style={{
            position: screens.md && i === 0 ? "sticky" : "unset",
            left: 0,
            right: 0,
            display: "inline-block",
            zIndex: i === 0 ? 1 : 0,
            backgroundColor: screens.md && i === 0 ? getCSSVariableValue("--color-gray-100") : "",
          }}
          className={clsx(styles.tableCell, "p-24", !screens.md ? styles.fixedWidthCell : null)}
          span={Math.ceil(24 / columns.length)}
          md={i === 0 ? 24 - 4 * values.length : 4}
          lg={i === 0 ? 24 - 4 * values.length : 4}
        >
          <Row
            align="middle"
            justify={i === 0 || screens.md === false ? "start" : "center"}
            style={{ height: "100%" }}
          >
            <Col style={{ textAlign: i === 0 || !screens.md ? "left" : "center" }}>
              {isHeader ? (
                <Body1 style={{ marginBottom: "0" }}>{col}</Body1>
              ) : screens.md ? (
                i === 0 ? (
                  <Body1 style={{ marginBottom: "0" }}>{col}</Body1>
                ) : (
                  <Body3>{col}</Body3>
                )
              ) : (
                <>
                  <Body1 as="div">{valueLabel}</Body1>
                  <Body3>{col}</Body3>
                </>
              )}
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};
