import { HubCard } from "client/src/components/HubCard/HubCard";
import type { SignerMode } from "shared/types/OutsideSigner";

interface EIFSubStepSummaryWrapperProps {
  signerMode: SignerMode;
  children: React.ReactNode;
}

export const EIFSubStepSummaryWrapper = ({
  signerMode,
  children,
  ...rest
}: EIFSubStepSummaryWrapperProps) => {
  return signerMode === "outside" ? (
    <div {...rest}>{children}</div>
  ) : (
    <HubCard printMode {...rest}>
      {children}
    </HubCard>
  );
};
