import type { SVGAttributes } from "react";

type Props = SVGAttributes<SVGElement>;

export function TrashIcon(props: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={20} {...props}>
      <path
        d="M13.55 20a1.9 1.9 0 001.872-1.758L16.25 5h.625c.345 0 .625-.28.625-.625V3.75a.625.625 0 00-.625-.625h-3.219L12.328.91C12.027.408 11.306 0 10.72 0H6.78c-.586 0-1.307.408-1.608.91L3.844 3.125H.625A.625.625 0 000 3.75v.625C0 4.72.28 5 .625 5h.625l.828 13.242A1.9 1.9 0 003.949 20h9.602zM11.47 3.125H6.032l.75-1.25h3.938l.75 1.25zm2.082 15H3.951L3.13 5h11.241l-.82 13.125z"
        fill="currentColor"
      />
    </svg>
  );
}
