import clsx from "clsx";
import { useState, useCallback } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";
import { RouteData } from "../../../../../shared/config/routeData";
import { Button } from "../../../components/Button/Button";
import { StackY } from "../../../components/Spacing/Spacing";
import {
  DocumentDownloadV2,
  useDocumentDownload,
} from "../../Document/DocumentDownload/DocumentDownload";
import { DocumentDownloadLink } from "../../Document/DocumentDownloadLink/DocumentDownloadLink";
import { DocumentUploadContent } from "../../Document/DocumentUpload/DocumentUpload";
import { CensusContentFooter } from "./CensusContentFooter";
import * as styles from "./censusContents.module.less";

import type { CensusOutletContext } from "client/src/domain/Client/Census/CensusDetailPage";

export const CensusUpload = () => {
  const navigate = useNavigate();
  const [isUploading, toggleIsUploading] = useState(false);

  const { client, policyId, trackElementClicked } = useOutletContext<CensusOutletContext>();

  const { id: clientId, policies } = client;
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "File upload",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const documentDownloadData = useDocumentDownload({
    clientId: client.id,
    policyId,
    categories: ["enrollment-elections"],
    track,
    taskStatus: "In Progress",
    showDownloadAllButton: false,
    showDownloadButton: false,
  });

  const handleNext = () => {
    track("Next");
    navigate(`${RouteData.censusStepDetail.getPath(client.id ?? "", policyId)}/indicate-source`);
  };

  return (
    <>
      <div className={styles.contentPanel}>
        <h5>
          Use the custom template file provided by Sun Life to take advantage of built-in validation
          and help keep your timeline on track
        </h5>
        <p className={clsx("body5", "mb-40")}>
          Need your customized template from Sun Life?{" "}
          <DocumentDownloadLink clientId={clientId} category={"census-template"} track={track}>
            Download it here.
          </DocumentDownloadLink>
        </p>
        <StackY dist={32}>
          <DocumentUploadContent
            clientId={clientId}
            autoUpload={true}
            showCancelButton={false}
            category={"enrollment-elections"}
            policyId={policyId}
            policies={policies}
            multiPolicyFiles={false}
            uploadProps={undefined}
            track={track}
            toggleUploading={toggleIsUploading}
          />
          <DocumentDownloadV2 {...documentDownloadData} />
        </StackY>
      </div>
      <CensusContentFooter>
        <Button
          type="primary"
          size="middle"
          disabled={documentDownloadData.documents.data?.length === 0}
          onClick={handleNext}
          loading={isUploading}
        >
          Next
        </Button>
      </CensusContentFooter>
    </>
  );
};
