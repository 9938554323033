import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsCoverageNotIncludedInSection125Plan } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { hasNonNullValues } from "shared/utils/utils";
import { section125ValidationSchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import {
  haveEverSavedSection125FormKeyProps,
  section125FormKeyProps,
} from "shared/validation/otherContractDetailsFormKeys";
import { Checkbox } from "../../components/Form/Checkbox";
import { InputErrorMessage } from "../../components/Form/InputErrorMessage";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../components/Typography/Typography";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { useSlobId } from "../../hooks/useSlobId";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { hasFormikError } from "../../utils/hasFormikError";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";

const eifSubStepId = "section-125";
const subStepName = getEIFSubStepMap({ eifSubStepId });

export const formTestId = "section125-form";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
};

export function EIFSection125(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, eifSubStepId }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(section125FormKeyProps),
    updateClient,
    validationSchema: section125ValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const hasDentalCoverage = client.allPoliciesSlfCoverages?.includes("Dental") ?? false;
  const hasVisionCoverage = client.allPoliciesSlfCoverages?.includes("Vision") ?? false;
  const hasDHMOCoverage = client.allPoliciesSlfCoverages?.includes("Dental (DHMO)") ?? false;

  const clientPlansNotCoveredIn125 =
    client.allPoliciesSlfCoverages?.filter(getIsCoverageNotIncludedInSection125Plan) ?? [];
  const hasPlansNotCoveredIn125 = clientPlansNotCoveredIn125.length > 0;

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(haveEverSavedSection125FormKeyProps)(client),
  );
  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, section125ValidationSchema, { prefill: false })
    : {};

  const dentalOrVisionErrorId = useSlobId({ prefix: "dental-or-vision-error" });
  const hasDentalOrVisionError =
    hasFormikError(formik, "hasSection125Dental") ||
    hasFormikError(formik, "hasSection125Vision") ||
    hasFormikError(formik, "hasSection125DHMO") ||
    strictErrors.hasSection125Dental ||
    strictErrors.hasSection125Vision ||
    strictErrors.hasSection125DHMO;

  const benefitsNotIncludedErrorId = useSlobId({ prefix: "benefits-not-included-error" });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>

        <StackY dist={40} wrap={false}>
          <StackY dist={32} wrap={false}>
            <HubCard>
              <p>
                A Section 125 plan (also known as a Cafeteria plan) must meet the requirements of
                internal revenue code Section 125, which permits employees to choose from at least
                one taxable benefit, such as cash, and one qualified benefit.
              </p>

              <p>
                Contributions to Section 125 plans are withheld on a pretax basis, thereby lowering
                taxable income.
              </p>

              <StackY dist={32} wrap={false}>
                <StackY dist={16} wrap={false}>
                  <StackY dist={32} wrap={false}>
                    <RadioGroup
                      name="hasSection125Plan"
                      label="Do you offer a Section 125 plan?"
                      direction="vertical"
                      disabled={formik.isSubmitting}
                      touched={formik.touched.hasSection125Plan || !!strictErrors.hasSection125Plan}
                      error={formik.errors.hasSection125Plan || strictErrors.hasSection125Plan}
                      options={[
                        { value: "YES", label: "Yes" },
                        { value: "NO", label: "No" },
                      ]}
                      value={formik.values.hasSection125Plan}
                      onChange={async (e) => {
                        const currentValue = e.target.value;
                        if (currentValue === "NO") {
                          await formik.setFieldValue("hasSection125Dental", false);
                          await formik.setFieldValue("hasSection125Vision", false);
                        }
                        if (currentValue === "YES") {
                          if (!hasDentalCoverage) {
                            await formik.setFieldValue("hasSection125Dental", false);
                          }
                          if (!hasVisionCoverage) {
                            await formik.setFieldValue("hasSection125Vision", false);
                          }
                        }
                        formik.handleChange(e);
                      }}
                    />

                    {(hasDentalCoverage || hasVisionCoverage || hasDHMOCoverage) &&
                      formik.values.hasSection125Plan === "YES" && (
                        <StackY dist={16}>
                          <Body2 as="div">
                            Which Sun Life benefits are included in your Section 125 plan?
                          </Body2>

                          {hasDentalCoverage && (
                            <div>
                              <Checkbox
                                label="Dental"
                                name="hasSection125Dental"
                                checked={Boolean(formik.values.hasSection125Dental)}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                errorId={hasDentalOrVisionError ? dentalOrVisionErrorId : undefined}
                              />
                            </div>
                          )}

                          {hasVisionCoverage && (
                            <div>
                              <Checkbox
                                label="Vision"
                                name="hasSection125Vision"
                                checked={Boolean(formik.values.hasSection125Vision)}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                errorId={hasDentalOrVisionError ? dentalOrVisionErrorId : undefined}
                              />
                            </div>
                          )}

                          {hasDHMOCoverage && (
                            <div>
                              <Checkbox
                                label="Dental (DHMO)"
                                name="hasSection125DHMO"
                                checked={Boolean(formik.values.hasSection125DHMO)}
                                onChange={formik.handleChange}
                                disabled={formik.isSubmitting}
                                errorId={hasDentalOrVisionError ? dentalOrVisionErrorId : undefined}
                              />
                            </div>
                          )}

                          <div aria-live="assertive">
                            {hasDentalOrVisionError && (
                              <InputErrorMessage
                                id={dentalOrVisionErrorId}
                                error={
                                  formik.errors.hasSection125Dental ||
                                  formik.errors.hasSection125Vision ||
                                  formik.errors.hasSection125DHMO ||
                                  strictErrors.hasSection125Dental ||
                                  strictErrors.hasSection125Vision ||
                                  strictErrors.hasSection125DHMO
                                }
                              />
                            )}
                          </div>
                        </StackY>
                      )}
                  </StackY>

                  <EditedFieldMsg
                    changeDetailInfoList={[
                      changeSnapshot.Client.hasSection125Plan,
                      changeSnapshot.Client.hasSection125Dental,
                      changeSnapshot.Client.hasSection125Vision,
                    ]}
                    client={client}
                    authUser={authUser}
                    hasPendingEdit={
                      getHasPendingEdit({
                        field: "hasSection125Plan",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "hasSection125Dental",
                        client,
                        formik,
                      }) ||
                      getHasPendingEdit({
                        field: "hasSection125Vision",
                        client,
                        formik,
                      })
                    }
                  />
                </StackY>

                {formik.values.hasSection125Plan === "YES" && hasPlansNotCoveredIn125 && (
                  <StackY dist={16} wrap={false}>
                    {hasDentalCoverage || hasVisionCoverage ? (
                      <StackY dist={8}>
                        <Body3 as="div">
                          You also have the following benefits with Sun Life, but they are not
                          typically included in a Section 125 plan and require additional
                          underwriting approval.
                        </Body3>
                        <Body2>Which additional benefits should be included?</Body2>
                      </StackY>
                    ) : (
                      <StackY dist={8}>
                        <Body2 as="div">
                          You have the following benefits with Sun Life, but they are not typically
                          included in a Section 125 plan and require additional underwriting
                          approval.
                        </Body2>
                        <Body2>Which benefits should be included?</Body2>
                      </StackY>
                    )}

                    <Checkbox
                      name="hasAdditionalCoverageIncludedIn125Plan"
                      label="Some of these are included in my Section 125 plan"
                      checked={Boolean(formik.values.hasAdditionalCoverageIncludedIn125Plan)}
                      onChange={async (event) => {
                        formik.handleChange(event);
                        if (event.target.checked === false) {
                          await formik.setFieldValue(
                            "additonalCoverageIncludedInSection125Plan",
                            [],
                          );
                        }
                      }}
                      disabled={formik.isSubmitting}
                    />

                    {clientPlansNotCoveredIn125.map((coverage) => (
                      <div className="ml-32" key={coverage}>
                        <Checkbox
                          name="additonalCoverageIncludedInSection125Plan"
                          label={coverage}
                          value={coverage}
                          checked={
                            formik.values.additonalCoverageIncludedInSection125Plan?.includes(
                              coverage,
                            ) ?? false
                          }
                          onChange={formik.handleChange}
                          disabled={
                            !formik.values.hasAdditionalCoverageIncludedIn125Plan ||
                            formik.isSubmitting
                          }
                          errorId={
                            hasFormikError(formik, "additonalCoverageIncludedInSection125Plan") ||
                            strictErrors.additonalCoverageIncludedInSection125Plan
                              ? benefitsNotIncludedErrorId
                              : undefined
                          }
                        />
                      </div>
                    ))}

                    <AlertBanner
                      variant="warning"
                      message={
                        <Body3>
                          These benefits require additional underwriting approval if included in
                          your Section 125 plan.
                        </Body3>
                      }
                    />

                    <div aria-live="assertive" className="ml-32 hide:empty">
                      {(hasFormikError(formik, "additonalCoverageIncludedInSection125Plan") ||
                        strictErrors.additonalCoverageIncludedInSection125Plan) && (
                        <ErrorMessage id={benefitsNotIncludedErrorId}>
                          {formik.errors.additonalCoverageIncludedInSection125Plan ??
                            strictErrors.additonalCoverageIncludedInSection125Plan ??
                            ""}
                        </ErrorMessage>
                      )}
                    </div>

                    <EditedFieldMsg
                      changeDetailInfoList={[
                        changeSnapshot.Client.hasAdditionalCoverageIncludedIn125Plan,
                        changeSnapshot.Client.additonalCoverageIncludedInSection125Plan,
                      ]}
                      client={client}
                      authUser={authUser}
                      hasPendingEdit={
                        getHasPendingEdit({
                          field: "hasAdditionalCoverageIncludedIn125Plan",
                          client,
                          formik,
                        }) ||
                        getHasPendingEdit({
                          field: "additonalCoverageIncludedInSection125Plan",
                          client,
                          formik,
                        })
                      }
                    />
                  </StackY>
                )}
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>

          <hr />

          <div>
            <h2>What benefits can be included in a Section 125 plan?</h2>

            <p>
              Dental and Vision are the Sun Life benefits most commonly included in a Section 125
              plan. Benefits should be employee paid and offered on a pre-tax basis to be eligible.
              While less common, Life and Disability plans can be included with additional
              underwriting approval.
            </p>
          </div>
          <div>
            <h2>What should you know about a Section 125 plan?</h2>

            <p>
              Employees will be allocated the same amount of money whether they choose to receive it
              as qualified benefits on a pre-tax basis or receive it as a part of their taxable
              salary. If an employee opts to receive it as part of their taxable salary, they can
              receive it as cash or another taxable benefit like supplemental disability or life
              insurance.
            </p>
          </div>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
