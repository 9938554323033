import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { Row, Col } from "client/src/components/Grid/Grid";
import { noop } from "lodash";
import { formatDateWeekDayShortMonthWithYear, formatFullName } from "shared/utils/format";
import { Anchor } from "../../../../components/Anchor/Anchor";
import { LoadingError } from "../../../../components/Error/LoadingError";
import { DownloadIcon } from "../../../../components/Icons/DownloadIcon";
import { TrashIcon } from "../../../../components/Icons/TrashIcon";
import { Body3, Body5 } from "../../../../components/Typography/Typography";
import { useDocumentDownloadHelperById } from "../../../../hooks/document";
import { AddFilesProvider } from "../../../Document/AddFilesProvider/AddFilesProvider";
import { DocumentUploadSingle } from "../../../Document/DocumentUploadSingle/DocumentUploadSingle";
import type { FC } from "react";
import type { Client, PolicyId } from "shared/types/Client";

export type AddSummaryStatementFileProps = {
  clientId: Client["id"];
  policyId: PolicyId;
  disabled: boolean;
};

export const AddSummaryStatementFile: FC<AddSummaryStatementFileProps> = ({
  clientId,
  policyId,
  disabled: outerDisabled,
}) => {
  const { downloadDocument } = useDocumentDownloadHelperById(clientId);

  return (
    <AddFilesProvider
      clientId={clientId}
      policyId={policyId}
      category={"billing-summary-statement-template"}
      children={({ documents, documentsResult, promptDeleteDocument, deleteDocumentResult }) => {
        const disabled = outerDisabled || documentsResult.isFetching;

        if (documentsResult.isError || deleteDocumentResult.isError) {
          return <LoadingError type="component" />;
        }

        const document = documents[0];

        return (
          <Row gutter={20} align="middle">
            {document ? (
              <>
                <Col flex={2}>
                  <Body3>{document.name}</Body3>
                </Col>
                <Col flex={5}>
                  <Body3>
                    {document.createdByUser && formatFullName(document.createdByUser)}
                    {" on "}
                    {formatDateWeekDayShortMonthWithYear(document.createdAt)}
                  </Body3>
                </Col>
                <Col flex="0 0 auto">
                  <ButtonOld
                    type="link-inline"
                    icon={<DownloadIcon />}
                    onClick={() => downloadDocument(document.id)}
                    disabled={disabled}
                  />
                </Col>
                <Col flex="0 0 auto">
                  <ButtonOld
                    type="link-inline"
                    icon={<TrashIcon />}
                    onClick={() => promptDeleteDocument(document)}
                    disabled={disabled}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <DocumentUploadSingle
                    clientId={clientId}
                    policyId={policyId}
                    category={"billing-summary-statement-template"}
                    onClick={noop}
                    track={noop}
                    buttonLabel={"Upload a file"}
                    buttonType="secondary"
                    buttonSize="middle"
                    disabled={disabled}
                  />
                </Col>
                <Col>
                  <Body5>
                    {"Stored securely using "}
                    <Anchor
                      newTabIcon
                      target="_blank"
                      href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403015569555"
                      disabled={disabled}
                    >
                      encryption standards
                    </Anchor>
                  </Body5>
                </Col>
              </>
            )}
          </Row>
        );
      }}
    />
  );
};
