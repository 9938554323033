import { Col, Row } from "client/src/components/Grid/Grid";
import { PageContent } from "client/src/components/PageContent/PageContent";
import * as styles from "./OutsideSignerPage.module.less";

export const OutsideSignerRevoked = () => {
  return (
    <div className={styles.centerContent}>
      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={24}>
            <div className="text-center">
              <h1>This link has expired</h1>
            </div>
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};
