import { faExclamationTriangle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import clsx from "clsx";
import { times } from "lodash";
import { formatDateFullMonth, formatDateFullMonthWithYear } from "../../../../shared/utils/format";
import { getUniquePolicyEffectiveDates } from "../../../../shared/utils/policy";
import { useSlobId } from "../../hooks/useSlobId";
import { useToggler } from "../../hooks/useToggler";
import { FallingBehindModal, OffTrackModal } from "../ProgressTracker/OffTrackModal";
import { Tooltip } from "../Tooltip/Tooltip";
import { Body3 } from "../Typography/Typography";
import * as styles from "./ProgressPhaseTracker.module.less";
import type { Policy } from "../../../../shared/types/Client";
import type { TimelineProps } from "../ProgressTracker/Timeline";
import type { FC } from "react";

const numberOfProgressSeparator = 5; // This value should be synced with its counterpart in the stylesheet.

export const DataTestId = {
  FontAwesomeIcon: "font-awesome-icon",
};

export type ProgressPhaseTrackerProps = {
  percentage?: number;
  theme?: TimelineProps["theme"];
  text?: string;
  policy?: Policy;
};

export const ProgressPhaseTracker = ({
  percentage = 0,
  theme,
  text,
  policy,
}: ProgressPhaseTrackerProps) => {
  const controlId = useSlobId({ prefix: "progress-phase-tracker-" });
  const isOffTrack = theme === "danger";
  const isAtRisk = theme === "warning";
  const [showModal, setShowModal] = useToggler();
  const policies = policy ? [policy] : [];
  const offTrackDates = getUniquePolicyEffectiveDates({
    policies,
    format: formatDateFullMonthWithYear,
  });
  const fallingBehindDates = getUniquePolicyEffectiveDates({
    policies,
    format: formatDateFullMonth,
  });
  return (
    <>
      <div
        className={clsx(styles.container, {
          [styles.themeDefault]: theme === "default",
          [styles.themeSuccess]: theme === "success",
          [styles.themeWarning]: theme === "warning",
          [styles.themeDanger]: theme === "danger",
        })}
      >
        <div className={styles.progressWithIconWrapper}>
          <div className={styles.progressContainer}>
            {times(numberOfProgressSeparator, (index) => (
              <div key={index} className={styles.progressSeparator} />
            ))}
            <progress
              max={100}
              value={percentage}
              id={controlId}
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={percentage}
            />{" "}
          </div>
          {isAtRisk && (
            <AtRiskIcon
              showModal={showModal}
              setShowModal={setShowModal}
              fallingBehindDates={fallingBehindDates}
            />
          )}
          {isOffTrack && (
            <OffTrackIcon
              showModal={showModal}
              setShowModal={setShowModal}
              offTrackDates={offTrackDates}
            />
          )}
        </div>
        <label htmlFor={controlId} className={styles.footer}>
          {text}
        </label>
      </div>
    </>
  );
};

type AtRiskIconProps = {
  showModal: boolean;
  setShowModal: () => void;
  fallingBehindDates: { formattedDates: string; dates: Date[] };
};

const AtRiskIcon: FC<AtRiskIconProps> = (props) => (
  <>
    {!props.showModal && (
      <Tooltip
        placement="top"
        title={
          <>
            <Body3 as="p" white>
              Don't wait!
            </Body3>
            <Body3 as="p" white>
              Your effective date is approaching and you have tasks that are due.
            </Body3>

            <Button onClick={props.setShowModal} type="text-only" className={styles.buttonColor}>
              Learn more
            </Button>
          </>
        }
        trigger="hover"
      >
        <FontAwesomeIcon
          data-testid={DataTestId.FontAwesomeIcon}
          icon={faInfoCircle}
          className={styles.warningIcon}
        />
      </Tooltip>
    )}
    <FallingBehindModal
      onCancel={props.setShowModal}
      isVisible={props.showModal}
      uniquePolicyEffective={props.fallingBehindDates.dates}
    />
  </>
);

type OffTrackIconProps = {
  showModal: boolean;
  setShowModal: () => void;
  offTrackDates: { formattedDates: string; dates: Date[] };
};

const OffTrackIcon: FC<OffTrackIconProps> = (props) => (
  <>
    {!props.showModal && (
      <Tooltip
        placement="top"
        title={
          <>
            <Body3 as="p" white>
              Take action now!
            </Body3>
            <Body3 as="p" white>
              You have important tasks that are due before your effective date.
            </Body3>

            <Button onClick={props.setShowModal} type="text-only" className={styles.buttonColor}>
              Learn more
            </Button>
          </>
        }
        trigger="hover"
      >
        <FontAwesomeIcon
          data-testid={DataTestId.FontAwesomeIcon}
          icon={faExclamationTriangle}
          className={styles.dangerIcon}
        />
      </Tooltip>
    )}
    <OffTrackModal
      onCancel={props.setShowModal}
      isVisible={props.showModal}
      uniquePolicyEffective={props.offTrackDates.dates}
    />
  </>
);
