import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { getEmployeeClassContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";

type ContributionsCtaWrapperProps = {
  employeeClass: EmployeeClass;
  ctaBasePathname: string;
  benefitTypes: BenefitTypeEIF[];
  relevantEmployeeClassPlans: EmployeeClassPlanWithPlan[];
  setContributionsQuery: SetContributionQuery;
  isLoading: boolean;
};

export const ContributionsCTA = (props: ContributionsCtaWrapperProps) => {
  const {
    employeeClass,
    ctaBasePathname,
    benefitTypes,
    relevantEmployeeClassPlans,
    setContributionsQuery,
    isLoading,
  } = props;

  const status = getEmployeeClassContributionsCompletionStatus(employeeClass, benefitTypes);

  const [benefitType] = benefitTypes;

  const editPath = ctaBasePathname + `?section=Contributions&benefitType=${benefitType}`;

  return (
    <Row gutter={8} justify="end">
      {status !== "Not Started" ? (
        <>
          <Col>
            <InternalLinkButton
              to={editPath}
              type="link-inline-bold"
              size="middle"
              disabled={isLoading}
            >
              Edit
            </InternalLinkButton>
          </Col>

          <Col>
            <Button
              type="text-only"
              size="middle"
              disabled={isLoading}
              onClick={async () => {
                await setContributionsQuery.mutateAsync({
                  params: {
                    clientId: employeeClass.clientId,
                    employeeClassId: employeeClass.id,
                  },
                  data: {
                    employeeClassId: employeeClass.id,
                    contributions: relevantEmployeeClassPlans.map((ecp) => ({
                      employeeClassPlanId: ecp.id,
                      employerContribution: null,
                      employerContributionUnit: null,
                      eeContributionAmount: null,
                      spouseContributionAmount: null,
                      childrenContributionAmount: null,
                      eeAndSpouseContributionAmount: null,
                      eeAndChildrenContributionAmount: null,
                      eeAndFamilyContributionAmount: null,
                      familyContributionAmount: null,
                      employerPremiumPayments: null,
                      threeYearLookBackPercent: null,
                      taxChoice: null,
                    })),
                  },
                });
              }}
            >
              Delete
            </Button>
          </Col>
        </>
      ) : (
        <Col>
          <Button to={editPath} size="small" shrink disabled={isLoading}>
            Start
          </Button>
        </Col>
      )}
    </Row>
  );
};
