import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetBills } from "client/src/hooks/bill";
import { useChangeSnapshot, useGetClientDEIFChanges } from "client/src/hooks/changeLogs";
import { useGetClientByID } from "client/src/hooks/client";
import { useGetContacts } from "client/src/hooks/contact";
import { useGetDocumentCount, useGetDocuments } from "client/src/hooks/document";
import { useGetClasses } from "client/src/hooks/employeeClass";
import { useGetPlansByClientId } from "client/src/hooks/plans";
import { useGetSubsidiariesByClientId } from "client/src/hooks/subsidiary";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { RouteData } from "shared/config/routeData";
import {
  eifStepIds,
  type EIFStepId,
  eifStepNavigation,
  isEIFSubStepId,
  eifStepIdMap,
} from "shared/types/EIF";
import { getEIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import {
  getAtLeastOneSubStepIsApplicable,
  getIsSubStepApplicable,
} from "shared/utils/EIF/getIsSubStepApplicable";
import { assertIsDefined } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  eifStepId: EIFStepId;
};

export const EIFStepBottomNavLinks = ({ eifStepId }: Props) => {
  const { clientId } = useClientHubParams(["clientId"]);

  const { data: client } = useGetClientByID(clientId);
  const { data: clientPlans } = useGetPlansByClientId(clientId);
  const { data: bills } = useGetBills(clientId);
  const { data: contacts } = useGetContacts(clientId);
  const { data: billingSummaryStatementTemplates } = useGetDocuments({
    clientId,
    categories: ["billing-summary-statement-template"],
  });
  const { data: employeeClasses } = useGetClasses(clientId);
  const { data: changes } = useGetClientDEIFChanges(clientId);
  const { data: subsidiaries } = useGetSubsidiariesByClientId(clientId);

  const featureToggles = useFeatureToggles(clientId);

  // This component expects these to already be in
  // the React Query cache at the time it is rendered,
  // and it should not have to deal with loading or error states.
  // That is already handled by an earlier component in the tree.
  assertIsDefined(client, "client");
  assertIsDefined(clientPlans, "clientPlans");
  assertIsDefined(bills, "bills");
  assertIsDefined(contacts, "contacts");
  assertIsDefined(billingSummaryStatementTemplates, "billingSummaryStatementTemplates");
  assertIsDefined(employeeClasses, "employeeClasses");
  assertIsDefined(changes, "changes");
  assertIsDefined(subsidiaries, "subsidiaries");

  const changeSnapshot = useChangeSnapshot(changes);

  const needEmployeeClassDocumentCount = client.allowClientSelfServicePlanConfig === "NO";
  const { data: employeeClassDocumentCountData } = useGetDocumentCount(
    needEmployeeClassDocumentCount ? clientId : "",
    ["employee-classes"],
  );
  if (needEmployeeClassDocumentCount) {
    assertIsDefined(employeeClassDocumentCountData, "employeeClassDocumentCountData");
  }
  const employeeClassDocumentCount = employeeClassDocumentCountData?.count ?? 0;

  const { previous, next } = getPreviousAndNextStepsNavData({
    eifStepId,
    data: {
      client,
      clientPlans,
      bills,
      contacts,
      billingSummaryStatementTemplates,
      featureToggles,
      employeeClasses,
      employeeClassDocumentCount,
      changeSnapshot,
      subsidiaries,
    },
  });

  return (
    <Row justify="space-between" align="middle">
      <Col>
        {previous && (
          <Button to={previous.to} type="text-only" size="middle">
            <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
            {previous.label}
          </Button>
        )}
      </Col>

      <Col>
        {next ? (
          <Button to={next.to} type="text-only" size="middle">
            {next.label}
            <FontAwesomeIcon icon={faChevronRight} className="ml-8" />
          </Button>
        ) : (
          <Button
            type="primary"
            size="middle"
            to={RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information")}
          >
            Close
          </Button>
        )}
      </Col>
    </Row>
  );
};

export function getPreviousAndNextStepsNavData(args: {
  eifStepId: EIFStepId;
  data: {
    client: Client;
    clientPlans: Plan[];
    bills: Bill[];
    contacts: Contact[];
    billingSummaryStatementTemplates: Document[];
    employeeClasses: EmployeeClass[];
    employeeClassDocumentCount: number;
    changeSnapshot: DEIFChangeSnapshot;
    featureToggles: ClientFeatureToggles;
    subsidiaries: Subsidiary[];
  };
}) {
  const { eifStepId, data } = args;

  const index = eifStepIds.indexOf(eifStepId);

  const previousStepId =
    eifStepIds
      .slice(0, index)
      .reverse()
      .find((eifStepId) => {
        const atLeastOneSubStepIsApplicable = getAtLeastOneSubStepIsApplicable(
          eifStepId,
          data.client,
          data.clientPlans,
          data.featureToggles,
        );
        return atLeastOneSubStepIsApplicable;
      }) ?? null;

  const nextStepId =
    eifStepIds.slice(index + 1).find((eifStepId) => {
      const atLeastOneSubStepIsApplicable = getAtLeastOneSubStepIsApplicable(
        eifStepId,
        data.client,
        data.clientPlans,
        data.featureToggles,
      );
      return atLeastOneSubStepIsApplicable;
    }) ?? null;

  const previousWithoutLabel = getFirstIncompleteSubStepInStep({ eifStepId: previousStepId, data });
  const nextWithoutLabel = getFirstIncompleteSubStepInStep({ eifStepId: nextStepId, data });

  const previous = previousWithoutLabel
    ? {
        ...previousWithoutLabel,
        label: `Previous section`,
      }
    : null;
  const next = nextWithoutLabel
    ? {
        ...nextWithoutLabel,
        label: `Continue to ${eifStepIdMap[nextWithoutLabel.eifStepId]}`,
      }
    : null;

  return { previous, next };
}

function getFirstIncompleteSubStepInStep(args: {
  eifStepId: EIFStepId | null;
  data: {
    client: Client;
    clientPlans: Plan[];
    bills: Bill[];
    contacts: Contact[];
    billingSummaryStatementTemplates: Document[];
    employeeClasses: EmployeeClass[];
    employeeClassDocumentCount: number;
    changeSnapshot: DEIFChangeSnapshot;
    featureToggles: ClientFeatureToggles;
    subsidiaries: Subsidiary[];
  };
}) {
  const { eifStepId, data } = args;

  if (eifStepId == null) return null;

  if (eifStepId === "review-&-submit") {
    const reviewSectionStatus = getEIFStepCompleteStatus(
      eifStepId,
      data.client,
      data.clientPlans,
      data.bills,
      data.contacts,
      data.billingSummaryStatementTemplates,
      data.employeeClasses,
      data.employeeClassDocumentCount,
      data.changeSnapshot,
      data.featureToggles,
      data.subsidiaries,
    );
    if (reviewSectionStatus === "Not Started") {
      return null;
    }
  }

  const subStepsMap = eifStepNavigation[eifStepId];
  const subStepIds = Object.keys(subStepsMap).filter(isEIFSubStepId);

  const firstIncompleteSubStepId = subStepIds.find((eifSubStepId) => {
    const subStepStatus = getEIFSubStepStatus({
      ...data,
      eifSubStepId,
    });

    const isCompleted = subStepStatus === "Completed";

    const isApplicable = getIsSubStepApplicable({
      ...data,
      plans: data.clientPlans,
      eifSubStepId,
    });

    return !isCompleted && isApplicable;
  });

  const to = firstIncompleteSubStepId
    ? RouteData.eifSubStepDetail.getPath(data.client.id, eifStepId, firstIncompleteSubStepId)
    : RouteData.eifStepDetail.getPath(data.client.id, eifStepId);

  return {
    eifStepId,
    eifSubStepId: firstIncompleteSubStepId,
    to,
  };
}
