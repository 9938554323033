import { eifStepIdMap } from "shared/types/EIF";
import { StackY } from "../../../../components/Spacing/Spacing";
import { AvoidPageBreak } from "../../../../utils/print";
import { EIFClassBuilderItem } from "../ClassBuilder/EIFClassBuilderItem";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  clientPlans: Plan[];
  employeeClasses: EmployeeClass[];
  singleClassBuilderMode: boolean;
  client: Client;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  signerMode: SignerMode;
};

export function EIFClassListingExpanded({
  clientPlans,
  employeeClasses,
  singleClassBuilderMode,
  client,
  authUser,
  featureToggles,
  changeSnapshot,
  signerMode,
}: Props) {
  return (
    <>
      {signerMode === "inside" && <h2>{eifStepIdMap["plan-configuration-&-eligibility"]}</h2>}

      <StackY dist={32}>
        {employeeClasses.map((employeeClass, index) => (
          <AvoidPageBreak key={employeeClass.id}>
            <EIFClassBuilderItem
              index={index + 1}
              clientPlans={clientPlans}
              employeeClass={employeeClass}
              singleClassBuilderMode={singleClassBuilderMode}
              readonly={true}
              toggleableDetails={false}
              expanded={true}
              viewMode="review-page"
              changeSnapshot={changeSnapshot}
              client={client}
              authUser={authUser}
              featureToggles={featureToggles}
              signerMode={signerMode}
            />
          </AvoidPageBreak>
        ))}
      </StackY>
    </>
  );
}
