import { useState, useEffect, createRef } from "react";

import type { ExplorerPage as DomainExplorerPage } from "../../../../shared/types/ExplorerPage";
import type { ExplorerPageBenefit } from "../../../../shared/types/ExplorerPageBenefit";
import type { LocationStateCode } from "../../../../shared/types/Location";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

type PlansBenefitMapping = {
  benefitType: BenefitTypeBenEx;
  plans: ExplorerPageBenefit[];
  ref: React.RefObject<HTMLDivElement>;
  carrierId: number | null;
};

const supportedBenefits: ReadonlyArray<BenefitTypeBenEx> = [
  "MEDICAL",
  "DENTAL",
  "VISION",
  "ACCIDENT",
  "HOSPITAL_INDEMNITY",
  "LIFE",
  "CRITICAL_ILLNESS",
  "CANCER",
  "FINANCIAL",
  "SHORT_TERM_DISABILITY",
  "LONG_TERM_DISABILITY",
  "GAP",
  "OTHER",
];

export const useBenefitsData = (
  explorerPage: DomainExplorerPage | undefined,
  location: LocationStateCode | null,
): PlansBenefitMapping[] | undefined => {
  const [benefitsData, setBenefitsData] = useState<PlansBenefitMapping[] | undefined>(undefined);

  useEffect(() => {
    setBenefitsData(
      explorerPage
        ? explorerPage.benefits?.reduce<PlansBenefitMapping[]>((acc, val) => {
            if (!supportedBenefits.includes(val.benefitType)) return acc;

            const mapping = acc.find((mapping) => mapping.benefitType === val.benefitType);

            const validPlan = !val.prepaidSitusState || val.prepaidSitusState === location;
            if (!validPlan) return acc;

            if (!mapping)
              acc.push({
                benefitType: val.benefitType,
                plans: [val],
                ref: createRef<HTMLDivElement>(),
                carrierId: val.carrierId,
              });
            else mapping.plans.push(val);

            return acc;
          }, []) ?? []
        : undefined,
    );
  }, [explorerPage, location]);

  return benefitsData;
};
