import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { getEIFSubStepMap } from "shared/types/EIF";
import type { EIFSubStepViewMode } from "shared/types/Client";
import type { EIFSubStepId } from "shared/types/EIF";

type EIFSubStepHeaderProps = {
  eifSubStepId: Exclude<EIFSubStepId, "non-class-benefits-preferences">;
  viewMode: EIFSubStepViewMode;
  editPath: string;
  subStepStatus: "Completed" | "Not Started" | "In Progress";
  showRedEditBanner: boolean;
  headingLevel?: "h3" | "h5";
};

export function EIFSubStepHeader(props: EIFSubStepHeaderProps) {
  const {
    eifSubStepId,
    viewMode,
    editPath,
    subStepStatus,
    showRedEditBanner,
    headingLevel = "h5",
  } = props;

  return (
    <>
      <Row align="middle">
        <Col flex={1}>
          {headingLevel === "h3" ? (
            <h3 className="m-0">
              {getEIFSubStepMap({
                eifSubStepId,
              })}
            </h3>
          ) : (
            <h5 className="m-0">{getEIFSubStepMap({ eifSubStepId })}</h5>
          )}
        </Col>

        {viewMode === "pre-sign" && subStepStatus !== "Not Started" && (
          <Col>
            <InternalLinkButton to={editPath} type="link-inline-bold" size="middle">
              Edit
            </InternalLinkButton>
          </Col>
        )}

        {viewMode === "pre-sign" && subStepStatus === "Not Started" && (
          <Col>
            <Button to={editPath} type="secondary" size="small">
              Start
            </Button>
          </Col>
        )}
      </Row>

      {subStepStatus !== "Not Started" && showRedEditBanner && (
        <AlertBanner
          variant="error"
          message={
            <Body3>
              Click <Body2>Edit</Body2> to complete this section.
            </Body3>
          }
        />
      )}
    </>
  );
}
