import { Table } from "antd";
import clsx from "clsx";

import * as styles from "./table.module.less";

import type { TableProps } from "antd";

type LightTableProps<RecordType> = TableProps<RecordType>;

export function LightTable<RecordType extends Record<string, unknown>>(
  props: LightTableProps<RecordType>,
) {
  const { className, ...rest } = props;
  return <Table className={clsx(styles.lightTable, className)} pagination={false} {...rest} />;
}
