import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "client/src/components/UnorderedList/UnorderedList";
import { EIFMonthlyClaimsReportsAndEOBsForm } from "client/src/domain/EIF/PlanAdministratorsAndBilling/EIFMonthlyClaimsReportsAndEOBsForm";
import {
  useCreateMonthlyClaimsReportMailingLocation,
  useUpdateMonthlyClaimsReportMailingLocation,
} from "client/src/hooks/monthlyClaimsReportMailingLocation";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import * as Yup from "yup";
import type { UpdateClientQuery } from "client/src/hooks/client";
import type { DispatchWithoutAction } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { PFMLStates, Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  relevantPlans: Plan[];
  claimReportsAndEOBsNotManagedBySLF: PFMLStates[];
  adminContacts: Contact[];
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
  updateClientQuery: UpdateClientQuery;
  changeSnapshot: DEIFChangeSnapshot;
  onSave: () => void;
  editPreferences: boolean;
  toggleEditPreferences: DispatchWithoutAction;
};

export function EIFMonthlyClaimsReportsAndEOBsCreate(props: Props) {
  const {
    client,
    relevantPlans,
    claimReportsAndEOBsNotManagedBySLF,
    adminContacts,
    authUser,
    featureToggles,
    updateClientQuery,
    changeSnapshot,
    onSave,
    editPreferences,
    toggleEditPreferences,
  } = props;

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();
  const [confirmAcceptDefaultsVisible, toggleConfirmAcceptDefaultsVisible] = useToggler();

  const createQuery = useCreateMonthlyClaimsReportMailingLocation();
  const updateQuery = useUpdateMonthlyClaimsReportMailingLocation();

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      acceptedDefaults: Yup.boolean().nullable(),
    }),
    initialValues: {
      acceptedDefaults: client.monthlyClaimsReportsAndEOBsDefaults === "ACCEPTED_DEFAULTS",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.acceptedDefaults) {
        if (client.monthlyClaimsReportsAndEOBsDefaults !== "ACCEPTED_DEFAULTS") {
          await updateClientQuery.mutateAsync({
            params: { clientId: client.id },
            data: {
              monthlyClaimsReportsAndEOBsDefaults: "ACCEPTED_DEFAULTS",
            },
          });
        }
      } else {
        if (client.monthlyClaimsReportsAndEOBsDefaults !== "EDITED_DEFAULTS") {
          await updateClientQuery.mutateAsync({
            params: { clientId: client.id },
            data: {
              monthlyClaimsReportsAndEOBsDefaults: "EDITED_DEFAULTS",
            },
          });
        }
      }
    },
  });

  const toggleEditPreferencesOrListView = () => {
    // If there's at least one plan that was already configured,
    // when clicking "Edit preferences", let's go back to the list view
    // instead of the "create" view, so that they can see what's already configured

    const atLeastOnePlanIsSetup =
      client.monthlyClaimsReportMailingLocations &&
      client.monthlyClaimsReportMailingLocations.length > 0;

    if (!editPreferences && atLeastOnePlanIsSetup) {
      onSave();
    } else {
      toggleEditPreferences();
    }
  };

  const isSubmitting =
    formik.isSubmitting ||
    updateClientQuery.isPending ||
    createQuery.isPending ||
    updateQuery.isPending;

  return (
    <>
      <StackY dist={32} wrap={false}>
        <HubCard>
          <StackY dist={16}>
            {!editPreferences ? (
              <>
                <p>
                  Monthly claims reports and Explanation of Benefits (EOB) statements are sent to
                  the Primary Plan Administrator by default.
                </p>
                <p>
                  Accept the default settings to continue or change your settings by editing
                  preferences below.
                </p>
                <Body2>This applies to:</Body2>
                <UnorderedList markerColor="gray">
                  {relevantPlans.map((plan) => (
                    <UnorderedListItem key={plan.id}>
                      {benefitTypeToCoverage[plan.benefitType]}
                    </UnorderedListItem>
                  ))}
                </UnorderedList>

                {client.monthlyClaimsReportsAndEOBsDefaults === "ACCEPTED_DEFAULTS" ? (
                  <AlertBanner
                    variant="success"
                    message={
                      <>
                        <Body3>
                          You have accepted the default. Claims reports and EOBs will be sent to the
                          Primary Plan Administrator.{" "}
                          <Button
                            type="text-only"
                            size="middle"
                            onClick={async () => {
                              await formik.setFieldValue("acceptedDefaults", false);
                              toggleEditPreferencesOrListView();
                            }}
                            disabled={isSubmitting}
                          >
                            Edit preferences
                          </Button>
                        </Body3>
                      </>
                    }
                  />
                ) : (
                  <Row justify="end">
                    <Col>
                      <Row align="middle" gutter={24}>
                        <Col>
                          <Button
                            type="text-only"
                            size="middle"
                            disabled={isSubmitting}
                            onClick={async () => {
                              await formik.setFieldValue("acceptedDefaults", false);
                              toggleEditPreferencesOrListView();
                            }}
                          >
                            Edit preferences
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            type="secondary"
                            size="middle"
                            disabled={isSubmitting}
                            onClick={async () => {
                              await formik.setFieldValue("acceptedDefaults", true);
                              await formik.submitForm();
                            }}
                          >
                            Accept default
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <EIFMonthlyClaimsReportsAndEOBsForm
                client={client}
                relevantPlans={relevantPlans}
                monthlyClaimsReportMailingLocation={null}
                claimReportsAndEOBsNotManagedBySLF={claimReportsAndEOBsNotManagedBySLF}
                adminContacts={adminContacts}
                authUser={authUser}
                featureToggles={featureToggles}
                updateClient={updateClientQuery.mutateAsync}
                changeSnapshot={changeSnapshot}
                onSave={onSave}
                onCancel={toggleEditPreferencesOrListView}
                prefillErrors={{}}
              />
            )}

            <div aria-live="assertive" className="hide:empty mt-20">
              {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
            </div>
          </StackY>
        </HubCard>

        <Row justify="space-between" align="middle">
          <Col>
            {previousSubStepLink && (
              <InternalLinkButton
                to={previousSubStepLink}
                type="link-inline-bold"
                size="middle"
                disabled={isSubmitting}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="mr-8" />
                Previous
              </InternalLinkButton>
            )}
          </Col>

          <Col>
            <Row align="middle" gutter={24}>
              {editPreferences && (
                <Col>
                  <Button
                    type="text-only"
                    size="middle"
                    disabled={isSubmitting}
                    onClick={toggleConfirmAcceptDefaultsVisible}
                  >
                    Accept default
                  </Button>
                </Col>
              )}

              <Col>
                <Button
                  to={nextSubStepLink}
                  type="primary"
                  size="middle"
                  disabled={
                    client.monthlyClaimsReportsAndEOBsDefaults !== "ACCEPTED_DEFAULTS" ||
                    editPreferences
                  }
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </StackY>

      <ConfirmDialog
        isVisible={confirmAcceptDefaultsVisible}
        isLoading={formik.isSubmitting}
        title="Reset preferences to default"
        confirmActionText="Accept default"
        onConfirm={async () => {
          await formik.setFieldValue("acceptedDefaults", true);
          await formik.submitForm();
          toggleEditPreferencesOrListView();
          toggleConfirmAcceptDefaultsVisible();
        }}
        onCancel={toggleConfirmAcceptDefaultsVisible}
      >
        <p>
          Monthly claims reports and explanation of benefits (EOB) statements are sent to the
          Primary Plan Administrator by default.
        </p>

        <p>If you accept the default, any preference changes you have made will be lost.</p>
      </ConfirmDialog>
    </>
  );
}
