import {
  passwordCharacterRequirements,
  passwordLengthRequirementMin,
} from "shared/validation/user";

import {
  passwordUppercaseRequirement,
  passwordLowercaseRequirement,
  passwordNumberRequirement,
} from "../../../../shared/validation/user";

import * as styles from "./passwordRequirements.module.less";

type PasswordRequirementsProps = {
  password: string;
};
export const PasswordRequirements = ({ password }: PasswordRequirementsProps) => {
  const hasLength = passwordLengthRequirementMin.isValidSync(password);

  const hasUppercase = passwordUppercaseRequirement.isValidSync(password);
  const hasLowercase = passwordLowercaseRequirement.isValidSync(password);
  const hasNumber = passwordNumberRequirement.isValidSync(password);
  const hasCharacter = passwordCharacterRequirements.isValidSync(password);
  const charReqsMet = hasUppercase && hasLowercase && hasNumber && hasCharacter;

  return (
    <div className="body5 mt-12">
      <div className="mb-8">Password must:</div>
      <ul className={styles.list}>
        <li className={`${hasLength ? styles.success : ""}`}>Be at least 8 characters</li>
        <li className={`${charReqsMet ? styles.success : ""}`}>
          Contain:
          <ul className={styles.nestedList}>
            <li>An uppercase character</li>
            <li>A lowercase character</li>
            <li>A number</li>
            <li>A special character</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
