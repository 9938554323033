import { getLinkId } from "shared/data/Client";
import type { Client } from "shared/types/Client";
import type { LocationStateCode } from "shared/types/Location";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { SlfCoverageLink } from "shared/types/Ticket";

export const getCoverageOutlineLinks = (client: Client): SlfCoverageLink[] => {
  if (!client.policies) {
    return [];
  }
  const linksByLinkKey = new Map<string, SlfCoverageLink>();
  const filteredPolicies = client.policies.filter(
    (policy) => policy.issuedStateSitus && policy.slfCoverages,
  );
  for (const policy of filteredPolicies) {
    for (const situsState of policy.issuedStateSitus ?? []) {
      for (const slfCoverage of policy.slfCoverages ?? []) {
        const outline = getCoverageOutline(situsState, slfCoverage);
        if (outline) {
          const link = outline.link;
          const linkKey = getLinkId(link);
          if (!linksByLinkKey.has(linkKey)) {
            linksByLinkKey.set(linkKey, link);
          }
        }
      }
    }
  }
  return [...linksByLinkKey.values()];
};

function getCoverageOutline(
  situsState: LocationStateCode,
  slfCoverage: SlfCoverageLongName,
): CoverageOutline | null {
  const outline = CoverageOutlines.find(
    (co) => co.situsState === situsState && co.slfCoverage === slfCoverage,
  );
  return outline ? outline : null;
}

export type CoverageOutline = {
  slfCoverage: SlfCoverageLongName;
  situsState: LocationStateCode;
  link: SlfCoverageLink;
};

export const CoverageOutlines: CoverageOutline[] = [
  {
    slfCoverage: "Accident",
    situsState: "CA",
    link: {
      text: "Outline of Coverage - CA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33287`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "ID",
    link: {
      text: "Outline of Coverage - ID",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33288`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "MA",
    link: {
      text: "Outline of Coverage - MA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33289`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "ME",
    link: {
      text: "Outline of Coverage - ME",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33290`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33291`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "NJ",
    link: {
      text: "Outline of Coverage - NJ",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33293`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "TN",
    link: {
      text: "Outline of Coverage - TN",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33295`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "UT",
    link: {
      text: "Outline of Coverage - UT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33296`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33297`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "WA",
    link: {
      text: "Outline of Coverage - WA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33298`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "NY",
    link: {
      text: "Outline of Coverage - NY",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33294`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Accident",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33292`,
      category: "custom-enrollment-materials",
      benefitTypes: ["ACCIDENT"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "CA",
    link: {
      text: "Outline of Coverage - CA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33299`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "CT",
    link: {
      text: "Outline of Coverage - CT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33300`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "ID",
    link: {
      text: "Outline of Coverage - ID",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33301`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "MA",
    link: {
      text: "Outline of Coverage - MA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33302`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "ME",
    link: {
      text: "Outline of Coverage - ME",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33303`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33304`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33304`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33305`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "NJ",
    link: {
      text: "Outline of Coverage - NJ",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33306`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "TN",
    link: {
      text: "Outline of Coverage - TN",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33307`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "UT",
    link: {
      text: "Outline of Coverage - UT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33308`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Cancer",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33309`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CANCER"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "ID",
    link: {
      text: "Outline of Coverage - ID",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33311`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "MA",
    link: {
      text: "Outline of Coverage - MA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33312`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "ME",
    link: {
      text: "Outline of Coverage - ME",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33313`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33314`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33316`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "NJ",
    link: {
      text: "Outline of Coverage - NJ",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33319`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "TN",
    link: {
      text: "Outline of Coverage - TN",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33315`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "UT",
    link: {
      text: "Outline of Coverage - UT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33320`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33321`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "NY",
    link: {
      text: "Outline of Coverage - NY",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33318`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Critical Illness",
    situsState: "CA",
    link: {
      text: "Outline of Coverage - CA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33310`,
      category: "custom-enrollment-materials",
      benefitTypes: ["CRITICAL_ILLNESS"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "CA",
    link: {
      text: "Outline of Coverage - CA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33322`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "ID",
    link: {
      text: "Outline of Coverage - ID",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33323`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "ME",
    link: {
      text: "Outline of Coverage - ME",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33324`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33325`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33326`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "UT",
    link: {
      text: "Outline of Coverage - UT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33328`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33329`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "WA",
    link: {
      text: "Outline of Coverage - WA",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33330`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Hospital Indemnity",
    situsState: "NY",
    link: {
      text: "Outline of Coverage - NY",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33327`,
      category: "custom-enrollment-materials",
      benefitTypes: ["HOSPITAL_INDEMNITY"],
    },
  },
  {
    slfCoverage: "Long Term Disability",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33286`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_LTD"],
    },
  },
  {
    slfCoverage: "Voluntary Long Term Disability",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33286`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_LTD"],
    },
  },
  {
    slfCoverage: "Short Term Disability",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33284`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_STD"],
    },
  },
  {
    slfCoverage: "Voluntary Short Term Disability",
    situsState: "VT",
    link: {
      text: "Outline of Coverage - VT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33284`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_STD"],
    },
  },
  {
    slfCoverage: "Long Term Disability",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28653`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_LTD"],
    },
  },
  {
    slfCoverage: "Voluntary Long Term Disability",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28653`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_LTD"],
    },
  },
  {
    slfCoverage: "Short Term Disability",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28653`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_STD"],
    },
  },
  {
    slfCoverage: "Voluntary Short Term Disability",
    situsState: "NH",
    link: {
      text: "Outline of Coverage - NH",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28653`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_STD"],
    },
  },
  {
    slfCoverage: "Long Term Disability",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28654`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_LTD"],
    },
  },
  {
    slfCoverage: "Voluntary Long Term Disability",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28654`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_LTD"],
    },
  },
  {
    slfCoverage: "Short Term Disability",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=33285`,
      category: "custom-enrollment-materials",
      benefitTypes: ["BASIC_STD"],
    },
  },
  {
    slfCoverage: "Voluntary Short Term Disability",
    situsState: "MT",
    link: {
      text: "Outline of Coverage - MT",
      url: `http://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=28654`,
      category: "custom-enrollment-materials",
      benefitTypes: ["VOL_STD"],
    },
  },
];
