import type { ClientId, PolicyId } from "./Client";
import type { Contact, ContactId } from "./Contact";
import type { Location, LocationId } from "./Location";
import type { User } from "./User";
import type {
  EmployeeCategorizationType,
  YesNo,
  BillTiming,
  Location as DBLocation,
  Bill as DBBill,
  User as DBUser,
  BillLocation as DBBillLocation,
  BillSplitType,
  BillingAdministrationType,
  BillingStructureType,
} from "@prisma/client";
import type { ExpectedDBContact } from "server/domain/contact/storage";
import type { Pretty } from "shared/types/Helper";
import type { SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { AdvanceOrArrearsSelection } from "shared/utils/EIF/getAdvanceAndArrearsCoveragesBreakdown";

export type BillId = string;

export type Bill = {
  id: BillId;
  clientId: ClientId;
  policyId: PolicyId | null;

  billTiming: BillTiming | null;
  contactId: ContactId | null;
  contact?: Contact | null;
  locationId: LocationId | null;
  location?: Location | null;
  hasDifferentMailingAddress: boolean;
  splitTags: string[] | null;
  billName: string | null;
  numberOfEmployees: number | null;
  /**
   * @deprecated
   * This is from a time where Bills were associated with a single benefit type.
   * With the Life & Disability support, we now want to be able to associate Bills
   * with more than one benefit type.
   * So we are going to use the newly added `slfCoverages` property instead.
   */
  benefit_deprecated: SlfCoverageLongName | null;
  slfCoverages: SlfCoverageLongName[] | null;

  groupedByLocations?: Location[] | null;
  categorizeEmployees: YesNo | null;
  employeeCategorizationType: EmployeeCategorizationType | null;
  categoriesByLocation?: Location[][] | null;
  categoriesByTags: string[][] | null;

  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  updatedByUser?: User | null;
  deletedAt?: Date | null;
  deletedBy?: string | null;
};

export type BillGroup = {
  policyId: PolicyId;
  advanceOrArrears: AdvanceOrArrearsSelection;
  billingAdministrationType: BillingAdministrationType | null | undefined;
  billingStructureType: BillingStructureType | null | undefined;
  billSplitType: BillSplitType | null | undefined;
  billName: string | null;
  groupByLocationIds: LocationId[] | null;
  splitTags: string[] | null;
  slfCoverages: SlfCoverageLongName[] | null;
  contactId: ContactId | null;
  hasDifferentMailingAddress: boolean;
  locationId: LocationId | null;
  numberOfEmployees: number | null;
  categorizeEmployees: YesNo | null;
  employeeCategorizationType: EmployeeCategorizationType | null;
  categorizeByLocationIds: string[][] | null;
  categoriesByTags: string[][] | null;
  categorizeEmployees_secondary: YesNo | null;
  employeeCategorizationType_secondary: EmployeeCategorizationType | null;
  categorizeByLocationIds_secondary: string[][] | null;
  categoriesByTags_secondary: string[][] | null;
};

export type BillGroupInputs = Pick<
  BillGroup,
  | "billName"
  | "groupByLocationIds"
  | "splitTags"
  | "contactId"
  | "hasDifferentMailingAddress"
  | "locationId"
  | "numberOfEmployees"
  | "categorizeEmployees"
  | "employeeCategorizationType"
  | "categorizeByLocationIds"
  | "categoriesByTags"
  | "categorizeEmployees_secondary"
  | "employeeCategorizationType_secondary"
  | "categorizeByLocationIds_secondary"
  | "categoriesByTags_secondary"
>;

export type BillPreview = Pick<
  Bill,
  | "policyId"
  | "billName"
  | "billTiming"
  | "groupedByLocations"
  | "splitTags"
  | "slfCoverages"
  | "contactId"
  | "contact"
  | "hasDifferentMailingAddress"
  | "locationId"
  | "location"
  | "numberOfEmployees"
  | "categorizeEmployees"
  | "employeeCategorizationType"
  | "categoriesByLocation"
  | "categoriesByTags"
> & { id: BillId | undefined; createdAt: Date | undefined };

export type BillInput = Pretty<
  Pick<Bill, "contactId" | "hasDifferentMailingAddress"> &
    Pick<
      Partial<Bill>,
      | "policyId"
      | "billTiming"
      | "locationId"
      | "splitTags"
      | "billName"
      | "numberOfEmployees"
      | "benefit_deprecated"
      | "slfCoverages"
      | "categorizeEmployees"
      | "employeeCategorizationType"
      | "categoriesByTags"
    > & {
      id?: BillId | null | undefined;
      groupByLocationIds?: string[] | null;
      categorizeByLocationIds?: string[][] | null;
    }
>;

export type ExpectedDBBill = DBBill & {
  contact?: ExpectedDBContact | null;
  location: DBLocation | null;
  updatedByUser?: DBUser | null;
  billLocations?: (DBBillLocation & {
    location: DBLocation;
  })[];
};

export const employeeCategorizationTypes: EmployeeCategorizationType[] = ["LOCATION", "TAGS"];

export const categorizeEmployees: YesNo[] = ["YES", "NO"];

export const billTimings: BillTiming[] = ["Advance", "Arrears"];
