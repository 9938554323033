import { Button } from "client/src/components/Button/Button";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body5, Body3 } from "client/src/components/Typography/Typography";
import { TPAContactCard } from "client/src/domain/Contact/TPAContactCard";
import { BillsList } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/BillSeparation/BillsList";
import { MissingBillsAlert } from "client/src/domain/EIF/PlanAdministratorsAndBilling/Bill/MissingBillsAlert";
import {
  getBillsHaveChanges,
  useGetChangesHasDeletedBills,
} from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import {
  billingAdministrationTypeLabels,
  billingStructureTypeLabels,
  billingPayrollCycleTypeLabels,
} from "shared/types/Client";
import { getIsHIorNJ } from "shared/types/SlfCoverages";
import { getBillingPreferencesCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getBillingPreferencesInitialFormValues, getStatementsForPolicy } from "shared/utils/bill";
import { getIsMultiPolicyMode, getShowValidationErrorsInSummary } from "shared/utils/client";
import { listFormat } from "shared/utils/format";
import { billingPreferencesSchema } from "shared/validation/policy";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, EIFSubStepViewMode, Policy } from "shared/types/Client";
import type { Document } from "shared/types/Document";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  policy: Policy;
  bills: Bill[];
  billingSummaryStatementTemplates: Document[];
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  viewMode: EIFSubStepViewMode;
  toggleableDetails: boolean;
  signerMode: SignerMode;
};

export function BillingPreferencesPolicySummary(props: Props) {
  const {
    client,
    policy,
    bills: allBills,
    billingSummaryStatementTemplates: allBillingSummaryStatementTemplates,
    changeSnapshot,
    featureToggles,
    authUser,
    toggleableDetails,
    viewMode,
    signerMode,
  } = props;

  const [showDetails, toggleShowDetails] = useToggler();

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const bills = allBills.filter((b) => b.policyId === policy.id);

  const billingSummaryStatementTemplates = getStatementsForPolicy(
    allBillingSummaryStatementTemplates,
    policy,
  );

  const policyStatus = getBillingPreferencesCompletionStatus({
    policy,
    bills,
    billingSummaryStatementTemplates,
  });

  const anyDeletedBills = useGetChangesHasDeletedBills(changeSnapshot, client, policy);

  if (policyStatus === "Not Started") {
    return null;
  }

  const { formValues } = getBillingPreferencesInitialFormValues(
    policy.slfCoverages,
    policy.id,
    policy,
    bills,
  );

  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const e = !suppressErrorsPostSigning
    ? getFormikErrors(formValues, billingPreferencesSchema, { policy, prefill: false })
    : {};

  const billsHaveChanges = getBillsHaveChanges(bills, changeSnapshot);
  const showBillsHeader =
    signerMode === "inside" || (signerMode === "outside" && (billsHaveChanges || anyDeletedBills));

  const isHIorNJ = getIsHIorNJ(policy);

  return (
    <StackY dist={24} wrap={false}>
      <div>
        <Body5 as="div">Administration type</Body5>
        {policy.billingAdministrationType && (
          <Body3 as="div">
            {billingAdministrationTypeLabels[policy.billingAdministrationType]}
          </Body3>
        )}

        {e.billingAdministrationType && <Body3 redMedium>{e.billingAdministrationType}</Body3>}
        {!policy.billingAdministrationType && !e.billingAdministrationType && <Body3>-</Body3>}
      </div>

      {(policy.billingAdministrationType === "SELF" ||
        policy.billingAdministrationType === "TPA") && (
        <div>
          <Body5 as="div">Structure type</Body5>
          {policy.billingStructureType && (
            <Body3 as="div">{billingStructureTypeLabels[policy.billingStructureType]}</Body3>
          )}

          {e.billingStructureType && <Body3 redMedium>{e.billingStructureType}</Body3>}
          {!policy.billingStructureType && !e.billingStructureType && <Body3>-</Body3>}
        </div>
      )}

      {policy.billingAdministrationType === "LIST" && !isHIorNJ && (
        <div>
          <Body5 as="div">Payroll cycle</Body5>
          {policy.billPayrollCycles && (
            <Body3 as="div">
              {listFormat(
                policy.billPayrollCycles.map((cycle) => {
                  return cycle === "OTHER"
                    ? policy.billPayrollCyclesOther
                      ? `Other: ${policy.billPayrollCyclesOther}`
                      : "Other"
                    : billingPayrollCycleTypeLabels[cycle];
                }),
              )}
            </Body3>
          )}

          {e.billPayrollCycles && (
            <Body3 redMedium as="div">
              {e.billPayrollCycles}
            </Body3>
          )}
          {e.billPayrollCyclesOther && (
            <Body3 redMedium as="div">
              {e.billPayrollCyclesOther}
            </Body3>
          )}
          {(!policy.billPayrollCycles || policy.billPayrollCycles.length === 0) &&
            !e.billPayrollCycles &&
            !e.billPayrollCyclesOther && <Body3 as="div">-</Body3>}
        </div>
      )}

      {policy.billingAdministrationType === "LIST" &&
        policy.billPayrollCycles &&
        policy.billPayrollCycles.length > 1 && (
          <div>
            <Body5 as="div">What employees are on which payroll cycle?</Body5>
            {policy.billPayrollCyclesExplanation && (
              <Body3 as="div">{policy.billPayrollCyclesExplanation}</Body3>
            )}

            {e.billPayrollCyclesExplanation && (
              <Body3 redMedium>{e.billPayrollCyclesExplanation}</Body3>
            )}

            {!policy.billPayrollCyclesExplanation && !e.billPayrollCyclesExplanation && (
              <Body3 redMedium>-</Body3>
            )}
          </div>
        )}

      {policy.billingAdministrationType === "TPA" && policy.tpaContact && (
        <TPAContactCard
          contact={policy.tpaContact}
          client={client}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
        />
      )}

      {((toggleableDetails && showDetails) || !toggleableDetails || !isMultiPolicyMode) && (
        <>
          {showBillsHeader && (
            <h5>
              {policy.billingAdministrationType == null ||
              policy.billingAdministrationType === "LIST"
                ? bills.length === 1
                  ? "Your Bill"
                  : "Your Bills"
                : bills.length === 1
                ? "Your Statement"
                : "Your Statements"}
            </h5>
          )}

          <EIFEntityDeletedBy
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            client={client}
            entity="Bill"
            pluralizeWord="bill"
            policyId={policy.id}
          />

          {!suppressErrorsPostSigning && (
            <MissingBillsAlert
              slfCoverages={policy.slfCoverages}
              billSplitType={policy.billSplitType}
              billingStructureType={policy.billingStructureType}
              bills={bills}
            />
          )}

          {bills.length === 0 ? (
            <Body3 as="div">
              {policy.billingAdministrationType == null ||
              policy.billingAdministrationType === "LIST"
                ? "No bills created"
                : "No statements created"}
            </Body3>
          ) : (
            <BillsList
              bills={bills}
              client={client}
              policy={policy}
              featureToggles={featureToggles}
              changeSnapshot={changeSnapshot}
              billSplitType={policy.billSplitType}
              policyBillingPreferences={policy}
              authUser={authUser}
              signerMode={signerMode}
              expanded
            />
          )}
        </>
      )}

      {isMultiPolicyMode && toggleableDetails && (
        <StackY dist={32} wrap={false}>
          <hr className="m-0" />

          <Button type="text-only" onClick={toggleShowDetails}>
            {showDetails ? "Hide details" : "Show details"}
          </Button>
        </StackY>
      )}
    </StackY>
  );
}
