import { getIn } from "formik";
import { useCallback } from "react";
import { contactInputValidationSchema } from "shared/validation/contact";
import * as Yup from "yup";

import { contactToContactInput, newContactInput } from "../../../../shared/types/Contact";
import { Badge, taskStatusToLabel, taskStatusToVariant } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { LoadingError } from "../../components/Error/LoadingError";
import { FormInput } from "../../components/Form/Input";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body2 } from "../../components/Typography/Typography";

import { UnsavedChangesModal, getHandleFormClosing } from "./UnsavedChangesModal";
import { useClientUtils } from "./useClientUtils";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { Dispatch, SetStateAction } from "react";
import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";

const getClientPropertiesToUpdate = (client: Client) => {
  const { webAdminContact } = client;
  return {
    webAdminContact: webAdminContact ? contactToContactInput(webAdminContact) : newContactInput(),
  };
};

export type SunLifeConnectFormProps = {
  client: Client;
  task: Task;
  toggleEditing?: () => void;
  updateClient: UpdateClientFunc;
  trackElementClicked: (options: ElementClickedOptions) => void;
  isInitialSave: boolean;
  onInitialSave: Dispatch<SetStateAction<boolean>>;
};

export function SunLifeConnectForm({
  client,
  task,
  toggleEditing,
  updateClient,
  trackElementClicked,
  isInitialSave,
  onInitialSave,
}: SunLifeConnectFormProps) {
  const statusLabel = task.status ? taskStatusToLabel[task.status] : undefined;
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        moduleState: statusLabel,
        buttonLabel,
      });
    },
    [trackElementClicked, statusLabel],
  );
  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: Yup.object({
      webAdminContact: contactInputValidationSchema
        .pick(["policyId", "firstName", "lastName", "email", "type"])
        .required(),
    }),
    type: "Sun Life Connect",
    track,
    onSuccessCallback: () => {
      if (isInitialSave) {
        onInitialSave(true);
      }
      toggleEditing?.();
    },
  });

  const handleCancel = getHandleFormClosing({ formik, onProceed: toggleEditing });

  const headerText = "Activate Sun Life Connect for your company";

  const badge =
    task.status && client.webAdminContact?.updatedByUser ? (
      <PopoverBadge
        aria-label="Task Status"
        variant={taskStatusToVariant[task.status]}
        status={taskStatusToLabel[task.status]}
        updatedAt={client.webAdminContact?.updatedAt}
        updatedBy={client.webAdminContact?.updatedByUser}
      />
    ) : task.status ? (
      <Badge
        srOnlyLabel="Task Status"
        status={taskStatusToLabel[task.status]}
        variant={taskStatusToVariant[task.status]}
      />
    ) : null;

  return (
    <>
      <UnsavedChangesModal form={formik} />
      <HubCard>
        <HubCardHeader title={headerText} badge={badge} hasDivider={true} />
        <div>
          <Body2>Who will be your Primary Web Administrator for Sun Life Connect?</Body2>
          <p className="body3">
            Your Primary Web Administrator should be the person who is responsible for and will have{" "}
            <strong>full</strong> access to employee information, administer benefits and all other
            administrator accounts. Enter their information in here to send them a registration
            email for Sun Life Connect.
          </p>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            //set contact type
            await formik.setValues({
              ...formik.values,
              webAdminContact: formik.values.webAdminContact && {
                ...formik.values.webAdminContact,
                type: "PRIMARY_WEB_ADMIN",
              },
            });
            void formik.submitForm();
          }}
        >
          <StackY dist={16} className="w-full">
            <div style={{ width: "50%" }}>
              <StackY dist={16}>
                <FormInput
                  label="First Name"
                  name="webAdminContact.firstName"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- ts confused by concat
                  value={formik.values.webAdminContact?.firstName}
                  onChange={formik.handleChange}
                  maxLength={191}
                  onBlur={formik.handleBlur}
                  touched={getIn(formik.touched.webAdminContact, "firstName")}
                  error={getIn(formik.errors.webAdminContact, "firstName")}
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
                <FormInput
                  label="Last Name"
                  name="webAdminContact.lastName"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- ts confused by concat
                  value={formik.values.webAdminContact?.lastName}
                  onChange={formik.handleChange}
                  maxLength={191}
                  onBlur={formik.handleBlur}
                  touched={getIn(formik.touched.webAdminContact, "lastName")}
                  error={getIn(formik.errors.webAdminContact, "lastName")}
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
                <FormInput
                  label="Email Address"
                  name="webAdminContact.email"
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- ts confused by concat
                  value={formik.values.webAdminContact?.email}
                  onChange={(e) => {
                    formik.setStatus("");
                    formik.handleChange(e);
                  }}
                  maxLength={191}
                  onBlur={formik.handleBlur}
                  touched={getIn(formik.touched.webAdminContact, "email")}
                  error={getIn(formik.errors.webAdminContact, "email")}
                  showRequired={true}
                  disabled={formik.isSubmitting}
                />
              </StackY>
            </div>
          </StackY>

          {formik.status && <LoadingError type="component" title={formik.status} />}

          <StackX dist={8} className="mt-32">
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
              loading={formik.isSubmitting}
              aria-label={formik.isSubmitting ? "Save" : undefined}
            >
              {formik.isSubmitting ? "" : "Save and Send Email"}
            </Button>
            {toggleEditing && (
              <Button
                size="middle"
                type="text"
                onClick={handleCancel}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            )}
          </StackX>
        </form>
      </HubCard>
    </>
  );
}
