import { Row, Col } from "client/src/components/Grid/Grid";

import { isSunlifeCarrier } from "../../../../../../shared/types/ExplorerPageBenefit";
import { Body5, H3 } from "../../../../components/Typography/Typography";
import { useClientHubParams } from "../../../../hooks/useClientHubParams";
import { useHubConfiguration } from "../../../../hooks/useConfig";
import { useTranslation } from "../../../../i18n";
import { useVidyardRerenderer } from "../../../../utils/vidyard";
import { copyKey } from "../../utils";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";

import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  BenefitVideo: "benefit-video",
};

type ExplorerBenefitVideoProps = {
  benefitType: BenefitTypeBenEx;
  alternateName: string;
  plans: ExplorerPageBenefit[];
};

export type ExplorerBenefitVideoCopyStructure = {
  [benefit in Exclude<BenefitTypeBenEx, "OTHER" | "FINANCIAL" | "MEDICAL">]: {
    videoID: string;
    videoID_DHMO?: string;
    name: string;
    name_PPO?: string;
    watchTime: string;
  };
};

export const ExplorerBenefitVideo = ({
  benefitType,
  alternateName,
  plans,
}: ExplorerBenefitVideoProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitVideo" });
  const { explorerPageId } = useClientHubParams(["explorerPageId"]);

  const { ENV } = useHubConfiguration();

  // Hide videos for Dental ASO_PPO plan and dual choice which includes ASO_PPO plan
  const showDentalVideo = plans.some((p) => p.planType === "ASO_PPO");

  const showVideoPlans = plans.reduce<ExplorerPageBenefit[]>((acc, val) => {
    if (!isSunlifeCarrier(val.carrierId)) return acc;
    else acc.push(val);
    return acc;
  }, []);

  const showVideo = showVideoPlans.length === 0 ? true : showDentalVideo ? true : false;

  const offering = plans.reduce<PlanType | "MULTI_CHOICE" | null>((acc, { planType }) => {
    const effectivePlanType = planType === "INDEMNITY" ? "PPO" : planType;
    if (acc && acc !== effectivePlanType) {
      return "MULTI_CHOICE";
    }
    return effectivePlanType;
  }, null);

  const videoID = t(copyKey(benefitType, "videoID"), { context: offering });
  const anonymousId = window.analytics?.user?.().anonymousId();

  // render DOM player after the language is toggled
  useVidyardRerenderer();

  return (
    <div data-testid={DataTestId.BenefitVideo}>
      {!showVideo && (
        <>
          <div style={{ position: "relative" }} key={videoID}>
            <img
              style={{ maxWidth: "100%" }}
              className="vidyard-player-embed"
              src={`https://play.vidyard.com/${videoID}.jpg`}
              data-uuid={videoID}
              data-v="4"
              data-type="inline"
              data-vydata={encodeURIComponent(
                JSON.stringify({ explorerPageId, ENV, anonymousId, benefitType }),
              )}
              alt={`${benefitType} video`}
            />
          </div>
          <Row justify="center" className="mt-12">
            <Col style={{ textAlign: "center" }}>
              <Body5>{t(copyKey(benefitType, "watchTime"))}</Body5>
              <H3 style={{ marginBottom: "0" }}>
                {t(copyKey(benefitType, "name"), {
                  alternateName: alternateName.toLowerCase(),
                  context: offering,
                })}
              </H3>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
