import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";
import { getDefaultBillNames } from "client/src/domain/EIF/PlanAdministratorsAndBilling/utils/billing";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { getEIFSubStepMap } from "../../../../../shared/types/EIF";
import { Anchor } from "../../../components/Anchor/Anchor";
import { AlertBanner } from "../../../components/Banner/AlertBanner";
import { Button } from "../../../components/Button/Button";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { HorizontalDivider } from "../../../components/HorizontalDivider/HorizontalDivider";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackX, StackY } from "../../../components/Spacing/Spacing";
import { Body1, Body2, Body3, Eyebrow, H2, H3 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { useDeleteContact } from "../../../hooks/contact";
import { useGetEIFPreviousAndNextLink } from "../../../hooks/useGetEIFPreviousAndNextLink";
import { ContactCard } from "../../Contact/ContactCard";
import { PlanAdminForm } from "../../Contact/PlanAdminForm";
import * as styles from "./EIFPlanAdministrators.module.less";
import { EIFPrimaryPlanAdminCard } from "./EIFPrimaryPlanAdminCard";
import type { TrackElementClickedFunc } from "../../../utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { EIFSubStepViewMode, Client } from "shared/types/Client";
import type { Location } from "shared/types/Location";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

export const DataTestId = {
  PlanAdministrators: "plan-administrators",
};

type EIFPlanAdministratorsProps = {
  client: Client;
  locations: Location[];
  bills: Bill[];
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  viewMode: EIFSubStepViewMode;
  authUser: UserData;
  featureToggles: ClientFeatureToggles;
};

export const EIFPlanAdministrators = ({
  client,
  locations,
  bills,
  changeSnapshot,
  trackElementClicked,
  viewMode,
  authUser,
  featureToggles,
}: EIFPlanAdministratorsProps) => {
  const {
    mutateAsync: deleteContact,
    isPending: isDeletingContact,
    error: deleteContactError,
  } = useDeleteContact();

  const eifSubStepId = "plan-administrators";

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: getEIFSubStepMap({ eifSubStepId }),
      buttonLabel,
      moduleState: getEIFSubStepStatus({ client, bills, eifSubStepId: "plan-administrators" }),
    });
  };

  const [contactToDeleteBills, setContactToDeleteBills] = useState<Bill[]>([]);

  const [creatingAdditionalAdmin, setCreatingAdditionalAdmin] = useState(false);
  const [editingContactId, setEditingContactId] = useState<string | null>(null);
  const [editingPrimaryAdmin, setEditingPrimaryAdmin] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contactToDelete, setContactToDelete] = useState<string | number>();

  const { nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const onDeleteContact = async () => {
    if (contactToDelete) {
      await deleteContact({ params: { clientId: client.id, contactId: contactToDelete } });
    }
    setShowDeleteModal(false);
  };

  const status: EIFStepCompleteStatus = !client.webAdminContact
    ? "Not Started"
    : !client.baRegisteredSLConnect
    ? "In Progress"
    : "Completed";

  const getBillName = createBillNameGetter(bills);

  const canDeleteContact = contactToDeleteBills.length === 0;

  const showEditBanner = getShowEditBanner(viewMode);

  const primaryWebAdminLocationId = client.webAdminContact?.locationId ?? undefined;

  return (
    <div data-testid={DataTestId.PlanAdministrators}>
      <H2>Plan Administrators</H2>
      <Body3 as="p">
        Tell us about the people at your company responsible for plan administration and bill
        payment.
      </Body3>
      <Body3 as="p">
        The people you add here will be set up with a separate login to
        <Body2> Sun Life Connect</Body2> – the site you’ll use to manage your account, employees,
        claims, billing and more for Sun life benefits.{" "}
        <Anchor
          newTabIcon
          target="_blank"
          href="https://onboard-help.sunlifeconnect.com/hc/en-us/articles/4403016014099-Manage-your-Sun-Life-benefits-on-Sun-Life-Connect"
        >
          Learn more about Sun Life Connect
        </Anchor>
      </Body3>

      <StackY dist={32}>
        <EIFPrimaryPlanAdminCard
          client={client}
          bills={bills}
          status={status}
          locations={locations}
          disabled={creatingAdditionalAdmin || Boolean(editingContactId)}
          onToggleEdit={setEditingPrimaryAdmin}
          trackElementClicked={trackElementClicked}
          authUser={authUser}
          changeSnapshot={changeSnapshot}
          featureToggles={featureToggles}
        />

        <EIFEntityDeletedBy
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          client={client}
          entity="Contact"
          pluralizeWord="Additional Sun Life Connect user"
        />
        {Boolean(client.additionalWebAdminContacts?.length) && (
          <HubCard>
            <div
              className={clsx(
                (creatingAdditionalAdmin || editingContactId || editingPrimaryAdmin) &&
                  styles.disabled,
              )}
            >
              <H3>Additional Sun Life Connect users</H3>
            </div>

            <StackY dist={24}>
              <div
                className={clsx(
                  (creatingAdditionalAdmin || editingContactId || editingPrimaryAdmin) &&
                    styles.disabled,
                )}
              >
                <AlertBanner
                  variant="info"
                  message={
                    <Body3>
                      Your Implementation Consultant will review the additional administrators
                      before sending activation emails for Sun Life Connect from
                      welcome@sunlifeconnect.com.
                    </Body3>
                  }
                />
              </div>
              {client.additionalWebAdminContacts?.map((contact) =>
                contact.id !== editingContactId ? (
                  <StackY dist={8} key={contact.id} data-testid="additional-web-admin-card">
                    <ContactCard
                      contact={contact}
                      bills={bills}
                      disabled={
                        creatingAdditionalAdmin || Boolean(editingContactId) || editingPrimaryAdmin
                      }
                      allowEdit
                      onEdit={() => setEditingContactId(contact.id)}
                      showSunLifeConnectAccess={true}
                      showLocationAccess={true}
                      allowDelete
                      onDelete={() => {
                        setContactToDelete(contact.id);
                        setContactToDeleteBills(
                          bills.filter((bill) => bill.contactId === contact.id) ?? [],
                        );
                        setShowDeleteModal(true);
                      }}
                      changeSnapshot={changeSnapshot}
                      showEditBanner={showEditBanner}
                      client={client}
                      authUser={authUser}
                      featureToggles={featureToggles}
                    />
                  </StackY>
                ) : (
                  <div key={contact.id} className={styles.editingContactWrapper}>
                    <HorizontalDivider />
                    <div className="px-32">
                      <H2>Edit Administrator</H2>
                      <PlanAdminForm
                        clientId={client.id}
                        newAdminType={"WEB_ADMIN"}
                        contact={contact}
                        locations={locations}
                        changeSnapshot={changeSnapshot}
                        allowCancel
                        onCancel={() => setEditingContactId(null)}
                        onUpdated={() => {
                          setEditingContactId(null);
                          track("Save");
                        }}
                        bills={bills}
                        client={client}
                        authUser={authUser}
                        readOnlyLocationId={primaryWebAdminLocationId}
                        featureToggles={featureToggles}
                      />
                    </div>
                    <HorizontalDivider />
                  </div>
                ),
              )}
            </StackY>
          </HubCard>
        )}

        {status !== "Not Started" && (
          <>
            {creatingAdditionalAdmin ? (
              <HubCard>
                <H3>Add another Sun Life Connect user</H3>
                <PlanAdminForm
                  clientId={client.id}
                  bills={bills}
                  locations={locations}
                  changeSnapshot={changeSnapshot}
                  newAdminType={"WEB_ADMIN"}
                  allowCancel
                  onCancel={() => setCreatingAdditionalAdmin(false)}
                  onCreated={() => {
                    setCreatingAdditionalAdmin(false);
                    track("Save");
                  }}
                  client={client}
                  authUser={authUser}
                  readOnlyLocationId={primaryWebAdminLocationId}
                  featureToggles={featureToggles}
                />
              </HubCard>
            ) : (
              <div>
                <Button
                  type="text-only"
                  size="middle"
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  onClick={() => setCreatingAdditionalAdmin(true)}
                  disabled={Boolean(editingContactId) || editingPrimaryAdmin}
                >
                  Create another Sun Life Connect user
                </Button>
                <HorizontalDivider />
              </div>
            )}
          </>
        )}
      </StackY>

      <Row justify="end" align="middle" className="mt-32">
        <Col>
          <StackX dist={24}>
            {(creatingAdditionalAdmin || Boolean(editingContactId) || editingPrimaryAdmin) && (
              <Eyebrow>Save your changes to continue.</Eyebrow>
            )}
            <Button
              type="primary"
              size="large"
              to={nextSubStepLink}
              disabled={creatingAdditionalAdmin || Boolean(editingContactId) || editingPrimaryAdmin}
            >
              Next
            </Button>
          </StackX>
        </Col>
      </Row>

      <ConfirmDialog
        title={canDeleteContact ? "Delete contact" : "Cannot delete this administrator"}
        isVisible={showDeleteModal}
        isLoading={isDeletingContact}
        confirmActionText={canDeleteContact ? "Delete" : undefined}
        cancelActionText={canDeleteContact ? "Cancel" : "Close"}
        onConfirm={onDeleteContact}
        onCancel={() => setShowDeleteModal(false)}
      >
        {canDeleteContact ? (
          <>
            <Body1 as="p">Are you sure you want to delete the contact?</Body1>
            {deleteContactError && <ErrorMessage>{GenericErrorCopy2}</ErrorMessage>}
          </>
        ) : (
          <>
            <Body1 as="p">
              This administrator is set as a billing contact in your billing preferences.
            </Body1>

            <Body1 as="p">
              In order to delete, you must first select another contact to replace them for the
              following bills:
            </Body1>
            <UnorderedList>
              {contactToDeleteBills.map((bill) => (
                <UnorderedListItem key={bill.id}>
                  <Link
                    to={RouteData.eifSubStepDetail.getPath(
                      client.id,
                      "plan-administrators-&-billing",
                      "billing-preferences",
                    )}
                  >
                    {getBillName(bill)}
                  </Link>
                </UnorderedListItem>
              ))}
            </UnorderedList>
          </>
        )}
      </ConfirmDialog>
    </div>
  );
};

function createBillNameGetter(bills: Bill[]) {
  const defaultBillNames = getDefaultBillNames(bills);

  return function getBillName(bill: Bill) {
    if (bill.billName) {
      const billName = `${bill.billName} - ${bill.billTiming} bill`;
      return billName;
    }

    const index = bills.indexOf(bill);
    const billName = defaultBillNames[index];
    return billName;
  };
}
