import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { H3 } from "client/src/components/Typography/Typography";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import type { PropsWithChildren } from "react";
import type { Location } from "react-router-dom";
import type { EIFStepCompleteStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";

type Props = PropsWithChildren<{
  sectionLabel: string;
  sectionQS: string;
  location: Location;
  inactiveAndEmpty: boolean;
  disabled: boolean | undefined;
  status: EIFStepCompleteStatus;
  isPFML: boolean;
}>;

export const EIFNonClassBenefitsContributionsCTA = (props: Props) => {
  const {
    sectionLabel,
    sectionQS,
    location,
    inactiveAndEmpty,
    disabled,
    children,
    status,
    isPFML,
  } = props;
  const label = isPFML ? `${sectionLabel} Paid Family Medical Leave` : sectionLabel;

  const button = (
    <>
      {status !== "Not Started" ? (
        <Button
          to={location.pathname + `?section=${sectionQS}`}
          type="text-only"
          size="middle"
          disabled={disabled}
        >
          Edit
        </Button>
      ) : (
        <Button
          to={location.pathname + `?section=${sectionQS}`}
          size="small"
          disabled={disabled}
          shrink
        >
          Start
        </Button>
      )}
    </>
  );

  return (
    <StackY dist={16} wrap={false}>
      <Row align="middle">
        <Col flex={1}>
          <H3 as="h2" greyMedium={inactiveAndEmpty}>
            <EIFSectionStatusIconography status={status} />
            {label}
          </H3>
        </Col>

        <Col>{button}</Col>
      </Row>

      {children}
    </StackY>
  );
};
