import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getTerminationOfInsuranceRelevantPlans } from "shared/utils/EIF/getTerminationOfInsuranceRelevantPlans";
import { hasNonNullValues, unique } from "shared/utils/utils";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { terminationDateSchema } from "shared/validation/plan";
import * as Yup from "yup";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { Body3, Body5 } from "../../components/Typography/Typography";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getFormikErrors, useSlobFormik } from "../../hooks/useSlobFormik";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { UpdatePlansQuery } from "../../hooks/plans";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { ChangeDetailInfo, DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";

type Props = {
  client: Client;
  clientPlans: Plan[];
  changeSnapshot: DEIFChangeSnapshot;
  updatePlansQuery: UpdatePlansQuery;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
};

export function EIFTerminationOfInsurance(props: Props) {
  const { client, clientPlans, trackElementClicked, updatePlansQuery, changeSnapshot, authUser } =
    props;

  const eifSubStepId = "termination-of-insurance";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const relevantPlans = getTerminationOfInsuranceRelevantPlans(clientPlans);
  const benefitTypes: BenefitTypeEIF[] = unique(relevantPlans.map((p) => p.benefitType));

  const initialValues = relevantPlans.reduce((acc, plan) => {
    const updated = Object.assign(acc, {
      [`${plan.benefitType.toLowerCase()}TerminationDate`]: plan.terminationDate,
    });
    return updated;
  }, {});

  const validationSchema = Yup.object(
    Object.fromEntries(
      benefitTypes.map((benefitType) => [
        `${benefitType.toLowerCase()}TerminationDate`,
        terminationDateSchema,
      ]),
    ),
  );

  const navigate = useNavigateIfMounted();

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
        clientPlans,
      }),
    });
  };

  const formik = useSlobFormik({
    validationSchema: validationSchema,
    validationContext: { prefill: true },
    initialValues,
    onSubmit: async () => {
      void slobMessage.success(`${subStepName} Updating`);

      const plans = relevantPlans.map((relevantPlan) => {
        return {
          id: relevantPlan.id,
          terminationDate:
            formik.values[`${relevantPlan.benefitType.toLowerCase()}TerminationDate`],
        };
      });

      const { isSuccess } = await updatePlansQuery.mutateAsync({
        params: { clientId: client.id },
        data: { plans },
      });

      if (isSuccess) {
        void slobMessage.success(`${subStepName} Updated`);
        track("Save");
        navigate(nextSubStepLink);
      }
    },
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
    plans: clientPlans,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(formik.initialValues);

  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, validationSchema, {
        prefill: false,
      })
    : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={"form"}>
        <h2 className="mb-40">{subStepName}</h2>

        <StackY dist={64} wrap={false}>
          <StackY dist={40} wrap={false}>
            <HubCard>
              <StackY dist={16} wrap={false}>
                <p>
                  This determines when an employee's coverage ends after they are no longer eligible
                  or their employment has ended.
                </p>
                {benefitTypes.map((benefitType) => {
                  const fieldName = `${benefitType.toLowerCase()}TerminationDate`;
                  const changeDetailInfoListForBenefitType = clientPlans
                    .filter((cp) => {
                      return cp.benefitType === benefitType;
                    })
                    .map((pbt) => changeSnapshot.Plan[pbt.id]?.terminationDate)
                    .filter((c): c is ChangeDetailInfo => c != null);
                  return (
                    <StackY key={benefitType} dist={16} wrap={false}>
                      <RadioGroup
                        name={fieldName}
                        label={`${benefitTypeToCoverage[benefitType]} termination:`}
                        disabled={formik.isSubmitting}
                        touched={formik.touched[fieldName] || !!strictErrors[fieldName]}
                        error={formik.errors[fieldName] || strictErrors[fieldName]}
                        options={[
                          {
                            value: "DATE_OF_TERM",
                            label: (
                              <StackX dist={8} wrap={false}>
                                <Body3>Date Of Termination</Body3>
                                <Body5>Most common</Body5>
                              </StackX>
                            ),
                          },
                          { value: "END_OF_MONTH", label: "End of the month" },
                        ]}
                        direction="vertical"
                        value={formik.values[fieldName]}
                        onChange={formik.handleChange}
                      />
                      <EditedFieldMsg
                        changeDetailInfoList={changeDetailInfoListForBenefitType}
                        client={client}
                        authUser={authUser}
                        hasPendingEdit={getHasPendingEdit({ field: fieldName, client, formik })}
                      />
                    </StackY>
                  );
                })}
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
