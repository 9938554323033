import { DeleteFileButton } from "client/src/domain/Document/DeleteFileButton/DeleteFileButton";
import { useGetDocumentDownloadUrl } from "client/src/hooks/document";
import { useUploadAndSaveFile, useVidyardPlayer } from "client/src/utils/vidyard";

import * as styles from "./medicalVideo.module.less";

export const DataTestId = {
  MedicalVideoRenderWrapper: "medical-video-render-wrapper",
};

interface MedicalVideoProps {
  docId: string;
  vidyardId: string | null;
  clientId: string;
  videoTitle: string | null;
  onChange: (vidyardUuid: string | null, vidyardId: number | null) => void;
  onDelete?: () => Promise<void>;
}

export const MedicalVideo = ({
  docId,
  vidyardId,
  clientId,
  videoTitle,
  onChange,
  onDelete,
}: MedicalVideoProps) => {
  const { data: documentURL } = useGetDocumentDownloadUrl(clientId, docId);
  const fileExt = documentURL?.filename.split(".")[1];

  const { uuid } = useUploadAndSaveFile(
    vidyardId,
    docId ?? null,
    fileExt ?? null,
    videoTitle,
    clientId,
    onChange,
  );

  const videoId = uuid ?? vidyardId;

  useVidyardPlayer(!!videoId);

  return videoId ? (
    <>
      <div className={styles.videoContainer} data-testid={DataTestId.MedicalVideoRenderWrapper}>
        <img
          style={{ maxWidth: "100%" }}
          className="vidyard-player-embed"
          src={`https://play.vidyard.com/${videoId}.jpg`}
          data-uuid={videoId}
          data-v="4"
          data-type="inline"
          alt={`Medical video`}
        />
      </div>
      <div className="m-12">
        <DeleteFileButton onClick={() => onDelete?.()} />
      </div>
    </>
  ) : null;
};
