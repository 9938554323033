import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import clsx from "clsx";
import { useMemo } from "react";
import { getPrimaryPolicy, isAddCoverageClient } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";

import { benefitsAdministrationValidationSchema } from "shared/validation/policy";
import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { GenericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import * as styles from "../../components/Error/error.module.less";
import { FormInput } from "../../components/Form/Input";
import { SlobSelect } from "../../components/Form/SlobSelect";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import * as cardStyles from "./BenefitsAdministrationForm.module.less";

import { UnsavedChangesModal, getHandleFormClosing } from "./UnsavedChangesModal";
import * as profileModulesStyles from "./profileModules.module.less";
import type { BenAdminPlatform } from "../../../../shared/types/BenAdminPlatform";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { UpdatePolicyQuery } from "client/src/hooks/policy";
import type { Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";

export const BenefitsAdministrationFormDataTestId = {
  hubCard: "ben-admin-form-hub-card",
};

export type BenefitsAdministrationFormProps = {
  client: Client;
  benAdminPlatforms: BenAdminPlatform[];
  editing: boolean;
  disabled: boolean;
  updatePolicyQuery: UpdatePolicyQuery;
  toggleActiveSection: () => void;
  trackElementClicked: (options: ElementClickedOptions) => void;
  onFinalSubmit?: Dispatch<SetStateAction<boolean>>;
};

export function BenefitsAdministrationForm({
  client,
  benAdminPlatforms,
  editing,
  disabled,
  updatePolicyQuery,
  toggleActiveSection,
  onFinalSubmit,
}: BenefitsAdministrationFormProps) {
  const benAdminPlatformsMap = useMemo(
    () => new Map(benAdminPlatforms.map((b) => [b.name, b])),
    [benAdminPlatforms],
  );
  const otherPlatform = benAdminPlatformsMap.get("Other");
  const noPlatform = benAdminPlatformsMap.get("I don’t use a platform");
  const notSurePlatform = benAdminPlatformsMap.get("I'm not sure");

  assertIsDefined(otherPlatform, "otherPlatform");
  assertIsDefined(noPlatform, "noPlatform");
  assertIsDefined(notSurePlatform, "notSurePlatform");

  const otherId = otherPlatform.id;

  const policy = getPrimaryPolicy(client);

  const updatedAt = policy.benAdminPlatformUpdatedAt;
  const updatedBy = policy.benAdminPlatformUpdatedByUser;

  const status =
    policy.benAdminPlatformId == null ||
    (policy.benAdminPlatformId === otherId && !policy.benAdminPlatformOtherName)
      ? "not-started"
      : "completed";

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      hasBenAdminPlatform: policy.hasBenAdminPlatform,
      benAdminPlatformId: policy.benAdminPlatformId,
      benAdminPlatform: policy.benAdminPlatform,
      benAdminPlatformOtherName: policy.benAdminPlatformOtherName,
    },
    validationSchema: benefitsAdministrationValidationSchema,
    onSubmit: async (values) => {
      await updatePolicyQuery.mutateAsync({
        params: { clientId: client.id, policyId: policy.id },
        data: values,
        query: { prefill: true },
      });

      if (status === "not-started") onFinalSubmit?.(true);
      if (editing) toggleActiveSection();

      await slobMessage.success(`Company Profile updated`);
    },
  });

  const handleCancel = getHandleFormClosing({ formik, onProceed: toggleActiveSection });

  const onClickNoPlatform = async () => {
    await formik.setValues({
      hasBenAdminPlatform: "NO",
      benAdminPlatformId: noPlatform.id,
      benAdminPlatform: noPlatform,
      benAdminPlatformOtherName: null,
    });
    await formik.submitForm();
  };

  return (
    <HubCard data-testid={BenefitsAdministrationFormDataTestId.hubCard} disabled={disabled}>
      <HubCardHeader
        title="Benefits Administration"
        description={
          <p>
            {isAddCoverageClient(client)
              ? `Click edit if you would like to change your benefits administration platform.`
              : `Tell us about your benefits administration platform. If you don’t use one but would like
            to learn more, Sun Life can help consult on that.`}
          </p>
        }
        badge={
          <>
            {status === "not-started" && (
              <Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />
            )}
            {status === "completed" && (
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="Completed"
                updatedBy={updatedBy}
                updatedAt={updatedAt}
              />
            )}
          </>
        }
        hasDivider={true}
      />

      {editing ? (
        <>
          <UnsavedChangesModal form={formik} />

          <form onSubmit={formik.handleSubmit}>
            <StackY dist={32}>
              <div>
                <div
                  className={clsx(cardStyles.dropdown, cardStyles.flex, cardStyles.alignItemsEnd)}
                >
                  <SlobSelect
                    name="benAdminPlatformId"
                    label="What platform do you use?"
                    placeholder="Select platform"
                    disabled={formik.isSubmitting}
                    loading={formik.isSubmitting}
                    touched={formik.touched.benAdminPlatformId}
                    showRequired={true}
                    error={formik.errors.benAdminPlatformId}
                    options={benAdminPlatforms.map((b) => {
                      return {
                        label: b.name,
                        value: b.id,
                      };
                    })}
                    value={formik.values.benAdminPlatformId}
                    onChange={async (event) => {
                      const benAdminPlatform = benAdminPlatforms.find((b) => b.id === event.value);
                      await formik.setFieldValue("benAdminPlatformId", event.value);
                      await formik.setFieldValue("benAdminPlatform", benAdminPlatform);

                      if (benAdminPlatform?.name === "I'm not sure") {
                        await formik.setFieldValue("hasBenAdminPlatform", "NOT_SURE");
                      } else if (benAdminPlatform?.name === "I don’t use a platform") {
                        await formik.setFieldValue("hasBenAdminPlatform", "NO");
                      } else {
                        await formik.setFieldValue("hasBenAdminPlatform", "YES");
                      }
                    }}
                  />
                  <div className="mb-4 ml-8">
                    <Button
                      size="middle"
                      type="text"
                      onClick={onClickNoPlatform}
                      disabled={disabled || formik.isSubmitting}
                    >
                      I don't use a platform
                    </Button>
                  </div>
                </div>
              </div>

              {formik.values.benAdminPlatformId && formik.values.benAdminPlatformId === otherId && (
                <div
                  className={clsx({
                    [styles.error]:
                      formik.touched.benAdminPlatformId && formik.errors.benAdminPlatformOtherName,
                  })}
                >
                  <FormInput
                    name="benAdminPlatformOtherName"
                    label="Name of the benefits administration platform you use"
                    maxLength={191}
                    touched={formik.touched.benAdminPlatformOtherName}
                    aria-describedby="platformDescription"
                    aria-invalid={Boolean(
                      formik.touched.benAdminPlatformId && formik.errors.benAdminPlatformOtherName,
                    )}
                    aria-errormessage="benAdminPlatformOtherName--errormessage"
                    disabled={formik.isSubmitting}
                    value={formik.values.benAdminPlatformOtherName ?? ""}
                    onChange={formik.handleChange}
                    error={formik.errors.benAdminPlatformOtherName}
                  />
                </div>
              )}

              {formik.status && <LoadingError type="component" title={GenericErrorCopy2} />}

              <StackX dist={8}>
                <Button
                  size="middle"
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  aria-label={formik.isSubmitting ? "Save" : undefined}
                >
                  {formik.isSubmitting ? "" : "Save"}
                </Button>
                <Button
                  size="middle"
                  type="text"
                  onClick={handleCancel}
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
              </StackX>
            </StackY>
          </form>
        </>
      ) : (
        <>
          {status === "not-started" ? (
            <StackX dist={8} className="mt-32">
              <Button
                size="middle"
                type="primary"
                onClick={toggleActiveSection}
                disabled={disabled}
              >
                Add Details
              </Button>

              <Button size="middle" type="text" onClick={onClickNoPlatform} disabled={disabled}>
                I don't use a platform
              </Button>
            </StackX>
          ) : (
            <>
              <StackY dist={32}>
                <table className="w-full">
                  <tbody>
                    <tr>
                      <th align="left" className={clsx(profileModulesStyles.tableHeader, "body2")}>
                        Platform
                      </th>
                      <td data-testid="benAdminPlatformValue" className="body3">
                        {policy.benAdminPlatform?.name === "Other"
                          ? policy.benAdminPlatformOtherName || policy.benAdminPlatform?.name
                          : policy.benAdminPlatform?.name}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button
                  size="middle"
                  type="default"
                  onClick={toggleActiveSection}
                  disabled={disabled}
                >
                  Edit
                </Button>
              </StackY>
            </>
          )}
        </>
      )}
    </HubCard>
  );
}
