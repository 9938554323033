import type { ReactNode } from "react";
import type { Task, TaskId } from "shared/data/Tasks";

export type TaskDetail = Task & TaskCopy;

interface TaskCopy {
  title: string;
  description: string;
  maxwellTitle?: string;
  customBannerMessage?: ReactNode | null;
}

interface TasksToTaskDetailsArgs {
  tasks: Task[];
  isMaxwellClient?: boolean;
  usingDEIF?: boolean;
  isAddCoverageClient?: boolean;
}

export function tasksToTaskDetails({
  tasks,
  isMaxwellClient = false,
  usingDEIF = false,
  isAddCoverageClient = false,
}: TasksToTaskDetailsArgs): TaskDetail[] {
  return tasks.map((task) => {
    const detailedTask = {
      ...task,
      title: tasksCopy[task.id]["title"],
      description: tasksCopy[task.id]["description"],
    };
    if (isMaxwellClient) {
      detailedTask.title = tasksCopy[task.id]["maxwellTitle"] ?? detailedTask.title;
    }
    if (usingDEIF) {
      const overrides = taskCopyDEIFOverrides(task.id, isAddCoverageClient);
      if (overrides) return { ...detailedTask, ...overrides };
    }

    return detailedTask;
  });
}

const taskCopyDEIFOverrides = (
  taskId: TaskId,
  isAddCoverageClient: boolean,
): TaskCopy | undefined => {
  if (taskId === "company-profile") {
    if (isAddCoverageClient) {
      return {
        title: "Enrollment Event",
        description: "If you're holding an enrollment event for your employees, let our team know.",
      };
    } else {
      return {
        title: "Complete Enrollment Event & Goals",
        description: "Complete some basic details to help us learn more about your needs.",
      };
    }
  }
};

export const tasksCopy: Record<TaskId, TaskCopy> = {
  "ongoing-service-call": {
    title: "Schedule Ongoing Service Call",
    description: "Get prepared for ongoing service. Meet your new Sun Life Client Service Team.",
  },
  "kickoff-call": {
    title: "Schedule Kickoff Call",
    description: `Set up your call with your Implementation Consultant which will cover key materials and dates for onboarding.`,
  },
  "prior-carrier-bills": {
    title: "Submit Prior Carrier Bills",
    description:
      "Having your prior carrier bills enables our team to set up your plans to match how they were being administered with your previous carrier.",
  },
  "onboarding-forms": {
    title: "Complete and Sign Onboarding Forms",
    description:
      "Use DocuSign to complete and sign your onboarding forms with Sun Life. The basic information you provide will help our team get you set up in Sun Life systems.",
  },
  "prior-carrier-certificates": {
    title: "Submit Prior Carrier Certificates",
    description:
      "Your prior carrier certificates are statements of coverage issued by your carrier and serve as verification that your business is insured.",
  },
  "enrollment-elections": {
    title: "Submit Enrollment Elections",
    maxwellTitle: "Submit Census Information",
    description:
      "View and download your custom template, fill it in with employee information and upload the completed census here.",
  },
  "sun-life-connect": {
    title: "Register for Sun Life Connect",
    description:
      "Once your benefits are effective, registration for Sun Life Connect is required to access your bill, opt-in for ePay, manage your employees, view claims information and more.",
  },
  "enrollment-resources": {
    title: "Employee & Employer Resources",
    description:
      "Review documents and educational resources shared with you throughout the onboarding process, as well as give your employees the knowledge they need to make smart decisions about their enrollments.",
  },
  "company-profile": {
    title: "Complete Company Profile",
    description: "Complete your profile to help us learn more about your needs.",
  },
  pfml: {
    title: "Submit Plan Exemption Documents for PFML",
    description:
      "Submit your Plan Exemption Documents to offer your own private, Paid Family and Medical Leave (PFML) plan to your employees. Make sure approvals for all states are submitted.",
  },
  "pfml-spd": {
    title: "Submit Summary Plan Description for PFML",
    description:
      "Submit your Summary Plan Description documents, in addition to your Plan Exemption Documents, to offer your own private, Paid Family and Medical Leave (PFML) plan to your employees. Make sure approvals for all states are submitted.",
  },
  "sunadvisor-spd": {
    title: "Submit Summary Plan Description for SunAdvisor",
    description:
      "Submit your Summary Plan Description document so that we can accurately process SunAdvisor claims.",
  },
  "eif-submit-company-information": {
    title: "Submit Company Information",
    description:
      "Tell us about your company, configure billing preferences, plan configuration and employee eligibility, and provide additional contract details.",
  },
  "dental-rollover": {
    title: "Submit Banked Dental Rollover Amounts",
    description:
      "Provide a report with current banked amounts to be rolled over from your prior plan.",
  },
  "deductible-maximum-report": {
    title: "Deductible Maximum Report",
    description: "This is to transfer your information over to Sun Life",
  },
};
