import { StackY } from "client/src/components/Spacing/Spacing";
import { useCallback } from "react";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import { Body2 } from "../Typography/Typography";
import type { SlobListFilterComponentProps } from "../SlobList/SlobList";
import type { ClientTableFilters } from "shared/types/Client";

export const EBRFilterDataTestId = {
  ebrFilterWrapper: "ebr-filter-wrapper",
};

type EBRFilterValues = Pick<ClientTableFilters, "ebrEmails">;

export type EBRFilterProps = {
  ebrEmails?: string[];
} & Omit<SlobListFilterComponentProps<EBRFilterValues>, "onFiltersReset">;

export const EBRFilter = ({ ebrEmails, filters, onFiltersChanged }: EBRFilterProps) => {
  const onFilterChange = useCallback(
    (ebrEmails: string[]) => {
      onFiltersChanged({
        ebrEmails,
      });
    },
    [onFiltersChanged],
  );

  return (
    <StackY dist={16} data-testid={EBRFilterDataTestId.ebrFilterWrapper}>
      <Body2>Employee Benefits Representative</Body2>
      <MultiSelect
        selectedIds={filters?.ebrEmails ?? []}
        onChange={onFilterChange}
        placeholder="Filter by EBR email"
        items={
          ebrEmails?.map((email) => {
            return {
              value: email,
              id: email,
            };
          }) ?? []
        }
      />
    </StackY>
  );
};
