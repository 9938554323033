import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { addNamespaceToParams, getURLSearchParamsFromObject } from "client/src/utils/url";

import { type ComponentType } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { assertIsDefined } from "shared/utils/utils";
import { triggerError } from "../../hooks/generalError";
import { ResponseError } from "../../hooks/query";
import { useClientHubParams } from "../../hooks/useClientHubParams";

import * as styles from "./slobList.module.less";
import type { Client } from "../../../../shared/types/Client";

import type { TableQueryParams } from "../../hooks/slobTable";
import type { SlobTableProps } from "../SlobTable/SlobTable";
import type { UseQueryResult } from "@tanstack/react-query";
import type { TableProps } from "antd";
import type { useListParams } from "client/src/hooks/useListParams";
import type { To } from "react-router-dom";
import type { ValueOf } from "shared/types/Helper";

import type { ClientFeatureToggles } from "shared/types/Toggles";

export const SortDirections = ["asc", "desc"] as const;
export type SortDirection = ValueOf<typeof SortDirections>;
export type Sort = { sortBy: string; direction: SortDirection };

type TableDisplayOverrideParams = {
  page: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: SortDirection;
  search?: string;
};

type UseGetData<
  Data,
  ExtraSearchParams extends Record<string, unknown> = Record<string, unknown>,
> = (query: TableQueryParams & ExtraSearchParams) => UseQueryResult<{
  data: Data[];
  meta: {
    count: number;
    countByError?: Record<string, number>;
    countWithErrors?: number;
  };
}>;

export type SlobListFilterComponentProps<FilterParams> = {
  filters: FilterParams | undefined;
  onFiltersChanged: (filters: FilterParams) => void;
  onFiltersReset: () => void;
};

export type SlobListProps<
  Data,
  ExtraSearchParams extends Record<string, unknown> = Record<string, unknown>,
  FilterParams extends Partial<ExtraSearchParams> = ExtraSearchParams,
> = {
  TableComponent: ComponentType<Omit<SlobTableProps<Data>, "columns">>;
  query: ReturnType<UseGetData<Data, ExtraSearchParams>>;
  listParams: ReturnType<typeof useListParams>;
  showSearch?: boolean;
  shouldRedirectToClient?: boolean;
  LeftHeader?: ComponentType<{ clearFilter: () => void }>;
  renderSubHeader?: (
    data: ReturnType<UseGetData<Data, ExtraSearchParams>>["data"] | undefined,
  ) => React.ReactNode;
  showModal?: (value: Data) => void;
  searchPlaceholder?: string;
  featureToggles?: ClientFeatureToggles | undefined;
  filter?: FilterParams;
  renderFilterComponent?: () => React.ReactNode;
  onGetDataError?: (err: ResponseError) => void;
  client?: Client;
  namespace?: string;
};

export const SlobList = <
  Data,
  ExtraSearchParams extends Record<string, unknown> = Record<string, unknown>,
  FilterParams extends Partial<ExtraSearchParams> = ExtraSearchParams,
>({
  TableComponent,
  query,
  filter,
  listParams,
  LeftHeader,
  renderSubHeader,
  showSearch = true,
  showModal,
  searchPlaceholder = "Search by name",
  renderFilterComponent,
  featureToggles,
  onGetDataError,
  shouldRedirectToClient,
  client,
  namespace,
}: SlobListProps<Data, ExtraSearchParams, FilterParams>) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useClientHubParams();

  const { data: dataResponse, error, isLoading, isPlaceholderData } = query;

  const { page, pageSize, sort, search, notForThisList } = listParams;

  const combinedParams = {
    ...filter,
    page,
    pageSize,
    sortBy: sort.sortBy,
    sortDirection: sort.direction,
    search,
  };

  const refreshTableDisplay = (overrides: TableDisplayOverrideParams) => {
    const namespacedParamsObject = addNamespaceToParams(
      { ...combinedParams, ...overrides },
      namespace,
    );
    const search = getURLSearchParamsFromObject({
      ...namespacedParamsObject,
      ...notForThisList, // preserve other query string params in case used by other lists on same page
    }).toString();
    const to: To = {
      pathname: location.pathname,
      search,
    };
    navigate(to);
  };

  const onSearch = (text: string) => {
    refreshTableDisplay({ page: 1, search: text });
  };

  const clearSearch = () => onSearch("");

  const onTableChange: Exclude<TableProps<Data>["onChange"], undefined> = (
    pagination,
    _filters,
    sorter,
    _extra,
  ) => {
    const firstSorter = Array.isArray(sorter) ? sorter[0] : sorter;
    assertIsDefined(firstSorter, "firstSorter");
    const newSort: Sort | undefined =
      typeof firstSorter.order === "string" && typeof firstSorter.field === "string"
        ? {
            sortBy: firstSorter.field,
            direction: firstSorter.order === "descend" ? "desc" : "asc",
          }
        : undefined;
    refreshTableDisplay({
      page: pagination.current ?? 1,
      pageSize: pagination.pageSize ?? pageSize,
      sortBy: newSort?.sortBy,
      sortDirection: newSort?.direction,
    });
  };

  if (error) {
    if (ResponseError.isResponseError(error)) {
      onGetDataError?.(error);
    }
    triggerError(error);
  }

  return (
    <>
      <div className={styles.navBar}>
        <div className={styles.menu}>{LeftHeader && <LeftHeader clearFilter={clearSearch} />}</div>
        <div className={styles.menu}>
          {showSearch && (
            <AutoComplete
              className={styles.searchInput}
              value={listParams.searchText}
              onSearch={onSearch}
            >
              <Input
                type="search"
                placeholder={searchPlaceholder}
                aria-label={searchPlaceholder}
                suffix={<SearchOutlined className={styles.searchIcon} />}
              />
            </AutoComplete>
          )}
          {renderFilterComponent?.()}
        </div>
      </div>
      {renderSubHeader && <div className={styles.subHeader}>{renderSubHeader(dataResponse)}</div>}
      <Row>
        <Col flex="auto">
          <TableComponent
            isLoading={isLoading || isPlaceholderData}
            data={dataResponse?.data}
            currentPage={page}
            onChange={onTableChange}
            totalItems={dataResponse?.meta.count ?? 0}
            pageSize={pageSize}
            showModal={showModal}
            clientId={clientId}
            client={client}
            shouldRedirectToClient={shouldRedirectToClient}
            featureToggles={featureToggles}
          />
        </Col>
      </Row>
    </>
  );
};
