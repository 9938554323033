import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { useState } from "react";

import { FormInputNumber } from "../../../../components/Form/InputNumber";
import { Body1, Body3, H2 } from "../../../../components/Typography/Typography";
import { useTranslation } from "../../../../i18n";
import { formatCurrency } from "../../../../utils/formatters";
import { getCSSVariableValue } from "../../../../utils/getCSSVariableValue";

import * as styles from "./benefit.module.less";

export const DataTestId = {
  CoverageCalculatorWrapper: "coverage-calc-wrapper",
};

export type ExplorerBenefitCoverageCalculatorCopyStrucutre = {
  header: string;
  "3_years": string;
  "5_years": string;
  description: string;
};

type ExplorerBenefitCoverageCalculatorProps = {
  track?: (value: number) => void;
};

export const ExplorerBenefitCoverageCalculator = ({
  track,
}: ExplorerBenefitCoverageCalculatorProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitCoverageCalculator" });
  const screens = useBreakpoint();

  const [salary, setSalary] = useState(35000);

  return (
    <div className={styles.coverageCalculator} data-testid={DataTestId.CoverageCalculatorWrapper}>
      <div className={styles.header}>
        <H2>{t("header")}</H2>
      </div>
      <Row align="middle" justify="center" className="my-32">
        <Col span={24} sm={10}>
          <FormInputNumber
            value={salary}
            maxLength={10}
            label="Your Salary"
            name="salary"
            onChange={(value) => {
              if (value || value === 0) setSalary(value);
            }}
            onBlur={() => track?.(salary)}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={1000}
            touched={undefined}
            error={undefined}
          />
        </Col>
        <Col
          span={24}
          sm={4}
          style={{
            height: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: screens.xs ? "row" : "column",
          }}
        >
          <div className={clsx(styles.connectorUp, screens.xs ? styles.mobile : null)}>
            <div className={clsx(styles.connector, screens.xs ? styles.mobile : null)} />
          </div>
          <div className={clsx(styles.connectorDown, screens.xs ? styles.mobile : null)}>
            <div className={clsx(styles.connector, screens.xs ? styles.mobile : null)} />
          </div>
        </Col>

        <Col span={24} sm={10}>
          {screens.xs ? (
            <>
              <Row>
                <Col span={12}>
                  <Body1>{t("3_years")}</Body1>
                </Col>
                <Col span={12}>
                  <Body1>{t("5_years")}</Body1>
                </Col>
              </Row>
              <div
                style={{
                  backgroundColor: getCSSVariableValue("--color-secondary-200"),
                  height: "1px",
                  margin: "16px 0",
                  width: "100%",
                }}
              />
              <Row>
                <Col span={12}>
                  <Body1>
                    <strong className={styles.coverageText}>{formatCurrency(salary * 3)}</strong>
                  </Body1>
                </Col>
                <Col span={12}>
                  <Body1>
                    <strong className={styles.coverageText}>{formatCurrency(salary * 5)}</strong>
                  </Body1>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mb-16" gutter={8}>
                <Col>
                  <Body1>
                    {t("3_years")}
                    {!screens.xs && " ="}
                  </Body1>
                </Col>
                <Col>
                  <Body1>
                    <strong className={styles.coverageText}>{formatCurrency(salary * 3)}</strong>
                  </Body1>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col>
                  <Body1>
                    {t("5_years")}
                    {!screens.xs && " ="}
                  </Body1>
                </Col>
                <Col>
                  <Body1>
                    <strong className={styles.coverageText}>{formatCurrency(salary * 5)}</strong>
                  </Body1>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Body3>{t("description")}</Body3>
    </div>
  );
};
