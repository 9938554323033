import React from "react";

import type { SVGAttributes } from "react";

type CheckmarkProps = SVGAttributes<SVGElement>;

export const Checkmark: React.FC<CheckmarkProps> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={11} viewBox="0 0 14 11" {...props}>
      <path
        d="M5.365 11a.863.863 0 00.614-.26l7.768-7.962a.908.908 0 000-1.26L12.52.26a.87.87 0 00-.615-.26.867.867 0 00-.615.26L5.365 6.343 2.71 3.61a.867.867 0 00-.615-.26.866.866 0 00-.614.26L.253 4.87a.906.906 0 000 1.259l4.498 4.61c.163.167.388.26.614.26z"
        fill="#004C6C"
        fillRule="nonzero"
      />
    </svg>
  );
};
