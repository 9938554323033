import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobPopover } from "client/src/components/SlobPopover/SlobPopover";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { PFMLBulbMessage } from "client/src/domain/EIF/common/PFMLBulbMessage";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { getFormikErrors, type FormikTypeFromValidator } from "client/src/hooks/useSlobFormik";
import { handleMaskedMonthDayChange } from "client/src/utils/handleMaskedInputChange";
import { capitalize } from "client/src/utils/string";
import { Navigate } from "react-router-dom";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getAnnualEnrollmentRequired } from "shared/utils/EIF/getAnnualEnrollmentRequired";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getExceeds30Days } from "shared/utils/date";
import { hasNonNullValues } from "shared/utils/utils";
import { changesDuringAnnualEnrollmentValidationSchema } from "shared/validation/client";

import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import {
  changesDuringEnrollmentFormKeyProps,
  haveEverSavedChangesDuringEnrollmentFormKeyProps,
} from "shared/validation/otherContractDetailsFormKeys";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { FormInput } from "../../components/Form/Input";
import { RadioGroup } from "../../components/Form/RadioGroup";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "../../components/Typography/Typography";

import { UnorderedList, UnorderedListItem } from "../../components/UnorderedList/UnorderedList";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { RadioChangeEvent } from "antd";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormikErrors } from "formik";
import type { ChangeEvent } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const formTestId = "form";

export function EIFChangesDuringAnnualEnrollment(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser, featureToggles } =
    props;

  const eifSubStepId = "changes-during-annual-enrollment";
  const subStepName = getEIFSubStepMap({ eifSubStepId });

  const hasAnnualEnrollmentRequired = getAnnualEnrollmentRequired(client);

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId,
        featureToggles,
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(changesDuringEnrollmentFormKeyProps),
    updateClient,
    validationSchema: changesDuringAnnualEnrollmentValidationSchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: () => {
      if (nextSubStepLink) {
        navigate(nextSubStepLink);
      }
    },
    prefill: true,
  });

  const isApplicable = getIsSubStepApplicable({
    eifSubStepId,
    client,
  });
  if (!isApplicable) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  const haveEverSavedForm = hasNonNullValues(
    getPropertiesToUpdate<Client>(haveEverSavedChangesDuringEnrollmentFormKeyProps)(client),
  );
  const strictErrors = haveEverSavedForm
    ? getFormikErrors(formik.values, changesDuringAnnualEnrollmentValidationSchema, {
        prefill: false,
      })
    : {};

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>
        <PFMLBulbMessage client={client} />
        <StackY dist={40}>
          <StackY dist={32}>
            <HubCard>
              {hasAnnualEnrollmentRequired ? (
                <YesOpenEnrollmentQuestions
                  formik={formik}
                  changeSnapshot={changeSnapshot}
                  client={client}
                  authUser={authUser}
                  strictErrors={strictErrors}
                />
              ) : (
                <MaybeOpenEnrollmentQuestions
                  formik={formik}
                  changeSnapshot={changeSnapshot}
                  client={client}
                  authUser={authUser}
                  strictErrors={strictErrors}
                />
              )}
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting}
            />
          </StackY>

          <hr />
        </StackY>
        <div>
          <h2>What is a qualifying event?</h2>

          <p>
            A qualifying event is a significant, life changing situation that enables you to make
            changes to your benefits. Qualifying events may include:
          </p>

          <UnorderedList>
            <UnorderedListItem>Marriage</UnorderedListItem>
            <UnorderedListItem>Birth / Adoption</UnorderedListItem>
            <UnorderedListItem>Death</UnorderedListItem>
          </UnorderedList>
        </div>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}

type QuestionProps = {
  formik: FormikTypeFromValidator<typeof changesDuringAnnualEnrollmentValidationSchema>;
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
  authUser: UserData | null;
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof changesDuringAnnualEnrollmentValidationSchema>
  >;
};

const YesOpenEnrollmentQuestions = (props: QuestionProps) => {
  const { formik, changeSnapshot, client, authUser, strictErrors } = props;

  const clearAdministerYourAEPDates = async () => {
    await formik.setFieldValue("whenDoYouAdministerYourAEPStartDate", null);
    await formik.setFieldValue("whenDoYouAdministerYourAEPEndDate", null);
  };

  async function handleMonthDayChange(e: ChangeEvent<HTMLInputElement>) {
    const { name } = e.currentTarget;
    await handleMaskedMonthDayChange((maskedValue) => formik.setFieldValue(name, maskedValue))(e);
  }

  const exceeds30days =
    formik.values.whenDoYouAdministerYourAEPStartDate &&
    formik.values.whenDoYouAdministerYourAEPEndDate
      ? getExceeds30Days(
          formik.values.whenDoYouAdministerYourAEPStartDate,
          formik.values.whenDoYouAdministerYourAEPEndDate,
        )
      : null;

  return (
    <StackY dist={32}>
      <StackY dist={16}>
        <RadioGroup
          name="whenDoYouAdministerYourAEP"
          label={<Body2 as="p">When do you administer your annual enrollment period? </Body2>}
          disabled={formik.isSubmitting}
          touched={
            formik.touched.whenDoYouAdministerYourAEP || !!strictErrors.whenDoYouAdministerYourAEP
          }
          error={
            formik.errors.whenDoYouAdministerYourAEP || strictErrors.whenDoYouAdministerYourAEP
          }
          options={[
            {
              value: "CALENDAR_MONTH_PRIOR_POLICY_ANNIVERSARY",
              label: (
                <StackY dist={2}>
                  <div>
                    <Body3>On the calendar month prior to the policy anniversary</Body3>
                    <span className="ml-12">
                      <Body5>Most common</Body5>
                    </span>
                  </div>
                  {client.policyAnniversaryMonth && (
                    <Body5>
                      Your policy anniversary is{" "}
                      <b>{capitalize(client.policyAnniversaryMonth)} 1.</b>
                    </Body5>
                  )}
                </StackY>
              ),
            },
            {
              value: "CUSTOM",
              label: "Custom start and end date (please specify)",
              contentSpacing: "tight",
              content: (
                <div className="ml-32">
                  <Body5>Not to exceed 30 days</Body5>
                </div>
              ),
            },
          ]}
          direction="vertical"
          value={formik.values.whenDoYouAdministerYourAEP}
          onChange={async (event) => {
            formik.handleChange(event);
            await clearAdministerYourAEPDates();
          }}
        />

        {formik.values.whenDoYouAdministerYourAEP === "CUSTOM" && (
          <>
            <Row>
              <Col xs={7}>
                <FormInput
                  name="whenDoYouAdministerYourAEPStartDate"
                  label="Start Date MM / DD"
                  disabled={formik.isSubmitting}
                  onChange={handleMonthDayChange}
                  touched={
                    formik.touched.whenDoYouAdministerYourAEPStartDate ||
                    !!strictErrors.whenDoYouAdministerYourAEPStartDate
                  }
                  value={formik.values.whenDoYouAdministerYourAEPStartDate ?? ""}
                  error={
                    formik.errors.whenDoYouAdministerYourAEPStartDate ||
                    strictErrors.whenDoYouAdministerYourAEPStartDate
                  }
                  maxLength={5}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={7}>
                <FormInput
                  name="whenDoYouAdministerYourAEPEndDate"
                  label="End Date MM / DD"
                  disabled={formik.isSubmitting}
                  onChange={handleMonthDayChange}
                  touched={
                    formik.touched.whenDoYouAdministerYourAEPEndDate ||
                    !!strictErrors.whenDoYouAdministerYourAEPEndDate
                  }
                  value={formik.values.whenDoYouAdministerYourAEPEndDate ?? ""}
                  error={
                    formik.errors.whenDoYouAdministerYourAEPEndDate ||
                    strictErrors.whenDoYouAdministerYourAEPEndDate
                  }
                  maxLength={5}
                />
              </Col>
            </Row>

            {exceeds30days && (
              <AlertBanner
                variant="warning"
                message={
                  <Body3>
                    An enollment period of greater than 30 days requires approval by Sun Life.
                    Please update to 30 days or less, otherwise your IC will discuss next steps with
                    you
                  </Body3>
                }
              />
            )}
          </>
        )}

        <EditedFieldMsg
          changeDetailInfoList={[
            changeSnapshot.Client.whenDoYouAdministerYourAEP,
            changeSnapshot.Client.whenDoYouAdministerYourAEPStartDate,
            changeSnapshot.Client.whenDoYouAdministerYourAEPEndDate,
          ]}
          client={client}
          authUser={authUser}
          hasPendingEdit={
            getHasPendingEdit({
              field: "whenDoYouAdministerYourAEP",
              client,
              formik,
            }) ||
            getHasPendingEdit({
              field: "whenDoYouAdministerYourAEPStartDate",
              client,
              formik,
            }) ||
            getHasPendingEdit({
              field: "whenDoYouAdministerYourAEPEndDate",
              client,
              formik,
            })
          }
        />
      </StackY>

      <StackY dist={16}>
        <RadioGroup
          name="whenDoAEPTakeEffect"
          label={<Body2 as="p">When do annual enrollment changes take effect?</Body2>}
          disabled={formik.isSubmitting}
          touched={formik.touched.whenDoAEPTakeEffect || !!strictErrors.whenDoAEPTakeEffect}
          error={formik.errors.whenDoAEPTakeEffect || strictErrors.whenDoAEPTakeEffect}
          fullWidth
          options={[
            {
              value: "ANNUALLY",
              label: (
                <StackY dist={2}>
                  <div>
                    <Body3>Annually, on the policy anniversary</Body3>
                    <span className="ml-12">
                      <Body5>Most common</Body5>
                    </span>
                  </div>
                  {client.policyAnniversaryMonth && (
                    <Body5>
                      Your policy anniversary is{" "}
                      <b>{capitalize(client.policyAnniversaryMonth)} 1.</b>
                    </Body5>
                  )}
                </StackY>
              ),
            },
            {
              value: "OTHER",
              label: "Other",
              content: formik.values.whenDoAEPTakeEffect === "OTHER" && (
                <div className="ml-32">
                  <FormInput
                    name="whenDoAEPTakeEffectOther"
                    label="Please explain"
                    disabled={formik.isSubmitting}
                    onChange={formik.handleChange}
                    touched={
                      formik.touched.whenDoAEPTakeEffectOther ||
                      !!strictErrors.whenDoAEPTakeEffectOther
                    }
                    value={formik.values.whenDoAEPTakeEffectOther}
                    error={
                      formik.errors.whenDoAEPTakeEffectOther ||
                      strictErrors.whenDoAEPTakeEffectOther
                    }
                    maxLength={1000}
                  />
                </div>
              ),
            },
          ]}
          direction="vertical"
          value={formik.values.whenDoAEPTakeEffect}
          onChange={async (event) => {
            formik.handleChange(event);
            await formik.setFieldValue("whenDoAEPTakeEffectOther", null);
          }}
        />

        <EditedFieldMsg
          changeDetailInfoList={[
            changeSnapshot.Client.whenDoAEPTakeEffect,
            changeSnapshot.Client.whenDoAEPTakeEffectOther,
          ]}
          client={client}
          authUser={authUser}
          hasPendingEdit={
            getHasPendingEdit({
              field: "whenDoAEPTakeEffect",
              client,
              formik,
            }) ||
            getHasPendingEdit({
              field: "whenDoAEPTakeEffectOther",
              client,
              formik,
            })
          }
        />
      </StackY>

      <StackY dist={16}>
        <RadioGroup
          name="whenDecreasesInsuranceTakeEffect"
          label={
            <>
              <Body2 as="p">When do decreases in insurance take effect?</Body2>
              <p className="mb-0">
                This applies to a reduction in coverage outside of annual enrollment or a qualifying
                event. For example, if an employee reduces coverage from employee + family to
                employee only.
              </p>
            </>
          }
          disabled={formik.isSubmitting}
          touched={
            formik.touched.whenDecreasesInsuranceTakeEffect ||
            !!strictErrors.whenDecreasesInsuranceTakeEffect
          }
          error={
            formik.errors.whenDecreasesInsuranceTakeEffect ||
            strictErrors.whenDecreasesInsuranceTakeEffect
          }
          options={[
            { value: "IMMEDIATELY", label: "Immediately" },
            { value: "AT_ANNUAL_ENROLLMENT", label: "At annual enrollment" },
          ]}
          direction="vertical"
          value={formik.values.whenDecreasesInsuranceTakeEffect}
          onChange={formik.handleChange}
        />

        <EditedFieldMsg
          changeDetailInfoList={[changeSnapshot.Client.whenDecreasesInsuranceTakeEffect]}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({
            field: "whenDecreasesInsuranceTakeEffect",
            client,
            formik,
          })}
        />
      </StackY>
    </StackY>
  );
};

const MaybeOpenEnrollmentQuestions = (props: QuestionProps) => {
  const { formik, changeSnapshot, client, authUser, strictErrors } = props;

  const handleFOFMInclusiveChange = async (event: CheckboxChangeEvent) => {
    await formik.setFieldValue(
      "whenDoChangesToBenefitsTakeEffect",
      event.target.checked ? "FOFM_INCLUSIVE" : "FOFM",
    );
  };

  const handleChangesOnlyAllowedDuringAEOnChange = async (event: RadioChangeEvent) => {
    const value = event.target.value;

    await formik.setFieldValue("changesOnlyAllowedDuringAE", value);
    await formik.setFieldValue(
      "whenDoChangesToBenefitsTakeEffect",
      value === "YES" ? null : formik.values.whenDoChangesToBenefitsTakeEffect,
    );

    // If "NO" is answered, the questions regarding having an annual enrollment, which appear
    // in the "YesOpenEnrollmentQuestions" component, are not relevant.
    // Resetting them to null is necessary if they started down the "YES" path and later switched to "NO"
    if (value === "NO") {
      await formik.setFieldValue("whenDoYouAdministerYourAEP", null);
      await formik.setFieldValue("whenDoAEPTakeEffect", null);
      await formik.setFieldValue("whenDecreasesInsuranceTakeEffect", null);
    }
  };

  return (
    <StackY dist={32}>
      <StackY dist={16}>
        <RadioGroup
          name="changesOnlyAllowedDuringAE"
          label={
            <>
              <Body2 as="p">
                Are changes to benefits only allowed during your annual enrollment period?{" "}
                <SlobPopover
                  id="more-info"
                  title={null}
                  content={
                    <>
                      An enrollment period is a period of time, not exceeding 30 days or occuring
                      more than once in any 12 month period, where your eligible employees can make
                      changes to their insurance.
                    </>
                  }
                  placement="top"
                  trigger={["click", "hover"]}
                >
                  <button className="btn-reset" style={{ color: "var(--color-gray-600)" }}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="sr-only">Learn more about domestic partnership</span>
                  </button>
                </SlobPopover>
              </Body2>
              <StackY dist={16}>
                <p className="mb-0">
                  Sun Life needs to know this because it effects the way we process any future
                  changes.
                </p>
                <p className="mb-0">This excludes a qualifying event.</p>
              </StackY>
            </>
          }
          disabled={formik.isSubmitting}
          touched={
            formik.touched.changesOnlyAllowedDuringAE || !!strictErrors.changesOnlyAllowedDuringAE
          }
          error={
            formik.errors.changesOnlyAllowedDuringAE || strictErrors.changesOnlyAllowedDuringAE
          }
          options={[
            {
              value: "YES",
              label: (
                <>
                  <Body3>Yes</Body3>
                  <span className="ml-12">
                    <Body5>Most common</Body5>
                  </span>
                </>
              ),
            },
            {
              value: "NO",
              label: "No",
            },
          ]}
          direction="vertical"
          value={formik.values.changesOnlyAllowedDuringAE}
          onChange={handleChangesOnlyAllowedDuringAEOnChange}
        />

        <EditedFieldMsg
          changeDetailInfoList={[changeSnapshot.Client.changesOnlyAllowedDuringAE]}
          client={client}
          authUser={authUser}
          hasPendingEdit={getHasPendingEdit({
            field: "changesOnlyAllowedDuringAE",
            client,
            formik,
          })}
        />
      </StackY>

      {formik.values.changesOnlyAllowedDuringAE === "NO" && (
        <StackY dist={16}>
          <RadioGroup
            name="whenDoChangesToBenefitsTakeEffect"
            label={<Body2 as="p">When do changes to benefits take effect?</Body2>}
            disabled={formik.isSubmitting}
            touched={
              formik.touched.whenDoChangesToBenefitsTakeEffect ||
              !!strictErrors.whenDoChangesToBenefitsTakeEffect
            }
            error={
              formik.errors.whenDoChangesToBenefitsTakeEffect ||
              strictErrors.whenDoChangesToBenefitsTakeEffect
            }
            fullWidth
            options={[
              {
                value: "IMMEDIATELY",
                label: "Immediately",
              },
              {
                value:
                  formik.values.whenDoChangesToBenefitsTakeEffect === "FOFM_INCLUSIVE"
                    ? "FOFM_INCLUSIVE"
                    : "FOFM",
                label: "First of the following month",
                contentSpacing: "tight",
                content: (
                  <div className="ml-32 stack-y-16">
                    <Body5 as="div">
                      Changes will take effect on the first day of the next month.
                    </Body5>

                    {(formik.values.whenDoChangesToBenefitsTakeEffect === "FOFM" ||
                      formik.values.whenDoChangesToBenefitsTakeEffect === "FOFM_INCLUSIVE") && (
                      <Checkbox
                        name="firstOfTheFollowingMonthIncludesFirstDay"
                        label={
                          <>
                            <div className="mb-4">Include first day of the month</div>
                            <Body5>
                              Changes that occur on the first of the month will take effect the same
                              day. Otherwise, changes will take effect on the first day of the next
                              month.
                            </Body5>
                          </>
                        }
                        checked={
                          formik.values.whenDoChangesToBenefitsTakeEffect === "FOFM_INCLUSIVE"
                        }
                        onChange={handleFOFMInclusiveChange}
                        disabled={formik.isSubmitting}
                      />
                    )}
                  </div>
                ),
              },
            ]}
            direction="vertical"
            value={formik.values.whenDoChangesToBenefitsTakeEffect}
            onChange={formik.handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={[changeSnapshot.Client.whenDoChangesToBenefitsTakeEffect]}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "whenDoChangesToBenefitsTakeEffect",
              client,
              formik,
            })}
          />
        </StackY>
      )}

      {formik.values.changesOnlyAllowedDuringAE === "YES" && (
        <YesOpenEnrollmentQuestions
          formik={formik}
          changeSnapshot={changeSnapshot}
          client={client}
          authUser={authUser}
          strictErrors={strictErrors}
        />
      )}
    </StackY>
  );
};
