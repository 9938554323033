import { StackY } from "client/src/components/Spacing/Spacing";
import { Checkbox } from "../Form/Checkbox";
import { Body2 } from "../Typography/Typography";
import type { ClientTableFilters } from "../../../../shared/types/Client";
import type { SlobListFilterComponentProps } from "../SlobList/SlobList";

export const DataTestId = {
  clientTypeFilterWrapper: "client-type-filter-wrapper",
};

type ClientTypeFilterValues = Pick<ClientTableFilters, "isOnboard" | "isBenefitsExplorer">;

export type ClientTypeFilterProps = Omit<
  SlobListFilterComponentProps<ClientTypeFilterValues>,
  "onFiltersReset"
>;

export const ClientTypeFilter = ({ filters, onFiltersChanged }: ClientTypeFilterProps) => {
  return (
    <StackY dist={16} data-testid={DataTestId.clientTypeFilterWrapper}>
      <Body2>Client type</Body2>
      <Checkbox
        name="isOnboard"
        value={"Onboard"}
        label={"Onboard"}
        checked={filters?.isOnboard ?? false}
        errorId={undefined}
        onChange={(e) => {
          onFiltersChanged({
            isOnboard: e.target.checked,
          });
        }}
        disabled={false}
      />
      <Checkbox
        name="isBenefitsExplorer"
        value={"Benefits Explorer"}
        label={"Benefits Explorer"}
        checked={filters?.isBenefitsExplorer ?? false}
        errorId={undefined}
        onChange={(e) => {
          onFiltersChanged({
            isBenefitsExplorer: e.target.checked,
          });
        }}
        disabled={false}
      />
    </StackY>
  );
};
