import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { Button } from "../Button/Button";

import * as styles from "./scrollTopButton.module.less";

export const DataTestId = {
  Wrapper: "scroll-top-wrapper",
};

type ScrollTopButtonProps = {
  buttonText: string;
};

export const ScrollTopButton = ({ buttonText }: ScrollTopButtonProps) => {
  const screens = useBreakpoint();
  const showButton = useShowButton();
  if (!showButton) return null;
  return (
    <div
      className={clsx(styles.scrollTopWrapper, !screens.md ? styles.mobile : null)}
      data-testid={DataTestId.Wrapper}
    >
      <Button
        type="secondary"
        size="small"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        {buttonText}
        <FontAwesomeIcon style={{ marginLeft: "5px" }} icon={faArrowUp} />
      </Button>
    </div>
  );
};

const useShowButton = () => {
  const [showButton, setShowButton] = useState(scrollPosition() > 100);
  useEffect(() => {
    const callback = () => {
      setShowButton(scrollPosition() > 100);
    };
    window.addEventListener("scroll", callback, { passive: true });
    return () => window.removeEventListener("scroll", callback);
  }, []);
  return showButton;
};

export const scrollPosition = () => window.scrollY;
