import { useEffect } from "react";

const defaultTitlePrefix = "Sun Life Onboard";

export function useTitle(title: string, skipPrefix = false) {
  useEffect(() => {
    if (title.startsWith(defaultTitlePrefix) || skipPrefix) {
      document.title = title;
    } else {
      document.title = `${defaultTitlePrefix} - ${title}`;
    }

    return () => {
      document.title = defaultTitlePrefix;
    };
  }, [skipPrefix, title]);
}
