import { getCustomBannerMessageForNIGOReasons } from "../../components/Badge/getCustomBannerMessageForNIGOReasons";
import type { NIGOReason } from "shared/types/Client";

export const eifNIGOMessage = (
  <>
    Some Information is missing for the Submit Company Information task. Please reach out to your
    Implementation Consultant for more details.
  </>
);

type BillsAndCertificateTaskId = "prior-carrier-certificates" | "prior-carrier-bills";

const billAndCertificateTitleMap: Record<BillsAndCertificateTaskId, string> = {
  "prior-carrier-bills": "Prior carrier bill(s)",
  "prior-carrier-certificates": "Prior carrier certification(s)",
};

export const getBillsAndCertificatesNIGOMessage = (
  taskId: BillsAndCertificateTaskId,
) => `${billAndCertificateTitleMap[taskId]} are needed from you to complete this task. Please reach out
    to your Implementation Consultant for more details.`;

export function getCensusEnrollmentElectionsBadgeMessage(
  nigoReasons: NIGOReason[] | null | undefined,
) {
  const record: Partial<Record<NIGOReason, string>> = {
    "Census Missing Information":
      "Some enrollment or census information is missing from the file(s) you provided. Please reach out to your Implementation Consultant for more details.",
    "Awaiting Census":
      "Your enrollment elections have not yet been uploaded to Sun Life Onboard. Please provide your completed employee enrollment and census information in Onboard.",
  };

  const customBannerMessage = getCustomBannerMessageForNIGOReasons(record, nigoReasons);

  return customBannerMessage;
}
