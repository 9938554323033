import { Row, Col } from "client/src/components/Grid/Grid";
import { useFormik } from "formik";
import { useEffect } from "react";
import { object, string } from "yup";
import { ConfirmDialog } from "../../../../components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, genericErrorCopy2 } from "../../../../components/Error/ErrorMessage";
import { SlobSelect } from "../../../../components/Form/SlobSelect";
import { Body1 } from "../../../../components/Typography/Typography";
import { slobMessage } from "../../../../components/slobMessage/slobMessage";
import {
  useGetExplorerPagesByPageOwner,
  usePutExplorerPagesOwnership,
} from "../../../../hooks/ExplorerPage/useExplorerPages";
import { useGetAllSlfUsers } from "../../../../hooks/user";
import type { ConfirmDialogProps } from "../../../../components/ConfirmDialog/ConfirmDialog";
import type { SlfInternal } from "shared/types/User";

export type ConfirmDeleteUserDialogProps = Pick<
  ConfirmDialogProps,
  "isVisible" | "onCancel" | "onConfirm"
> & {
  activeUser: SlfInternal | undefined;
};

export const ConfirmDeleteUserDialog: React.FC<ConfirmDeleteUserDialogProps> = ({
  isVisible,
  activeUser,
  onCancel,
  onConfirm,
}) => {
  const form = useFormik({
    initialValues: { newPageOwnerId: "" },
    onSubmit: async ({ newPageOwnerId }) => {
      if (activeUser) {
        const hideLoading = slobMessage.loading("Page ownership transfer in progress", 0);
        try {
          await putExplorerPageOwnership.mutateAsync({
            params: { currentPageOwnerId: activeUser.id, newPageOwnerId },
          });
          void slobMessage.success("Page ownership transferred");
        } catch {
          void slobMessage.error(genericErrorCopy2);
        } finally {
          hideLoading();
        }
      }
    },
    validationSchema: object({
      newPageOwnerId: string().required().label("New Page Owner"),
    }),
  });
  const enabled = isVisible && !!activeUser;
  const putExplorerPageOwnership = usePutExplorerPagesOwnership();
  const explorerPages = useGetExplorerPagesByPageOwner(
    { pageOwnerId: activeUser?.id },
    { enabled },
  );

  const slfUsers = useGetAllSlfUsers({ enabled });
  const slfInternalUsers = slfUsers.data ?? [];

  const { resetForm } = form;
  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- Encapsulation will create confusion.
  useEffect(() => {
    if (enabled) resetForm();
  }, [enabled, resetForm]);

  const isOwnerOfAnyExplorerPages = explorerPages.data ? explorerPages.data.data.length > 0 : false;
  const newOwners = slfInternalUsers.filter(({ id }) => id !== activeUser?.id);
  const newOwnersOptions =
    newOwners?.map((newOwner) => ({
      label: newOwner.name,
      value: newOwner.id,
    })) ?? [];
  const isLoading =
    explorerPages.isLoading ||
    explorerPages.isRefetching ||
    slfUsers.isLoading ||
    slfUsers.isRefetching;
  const isError = explorerPages.isError || slfUsers.isError || putExplorerPageOwnership.isError;
  const isReady = !isLoading && !isError;

  const onConfirmWrapper = async () => {
    if (isOwnerOfAnyExplorerPages) {
      const isValid = Object.values(await form.validateForm()).length === 0;
      if (isValid) {
        await form.submitForm();
        await onConfirm();
      }
    } else {
      await onConfirm();
    }
  };

  return (
    <ConfirmDialog
      title={isError ? "Cannot delete user" : "Delete user"}
      confirmActionText={isError || isLoading ? undefined : "Yes"}
      cancelActionText={isLoading ? "Loading..." : isError ? "Ok" : "No"}
      isVisible={isVisible}
      isLoading={isLoading || putExplorerPageOwnership.isPending}
      onCancel={onCancel}
      onConfirm={onConfirmWrapper}
    >
      {isError && (
        <Body1 as="p">
          <ErrorMessage>
            An error is preventing this User: {activeUser?.fullName} to be deleted. Please, try
            again later.
          </ErrorMessage>
        </Body1>
      )}
      {isReady && (
        <>
          <Body1 as="p">Are you sure you want to delete this User: {activeUser?.fullName}?</Body1>
          {isOwnerOfAnyExplorerPages && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Body1 as="p">Transfer Benefits Explorer Page(s) Ownership to:</Body1>
              </Col>
              <Col span={12}>
                <SlobSelect
                  name="newPageOwnerId"
                  placeholder="New Page Owner"
                  options={newOwnersOptions}
                  value={form.values.newPageOwnerId}
                  touched
                  disabled={putExplorerPageOwnership.isPending}
                  error={form.errors.newPageOwnerId}
                  onChange={async (option) => {
                    await form.setFieldValue("newPageOwnerId", option.value);
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </ConfirmDialog>
  );
};
