import {
  copyKeyExists,
  copyKeySwitch,
  copyKeySwitchCustom,
} from "client/src/domain/ExplorerPages/utils";
import { useTranslation } from "client/src/i18n";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type {
  ExplorerPageBenefit,
  ContentFlag,
  BenefitPlanSummaryDocument,
} from "shared/types/ExplorerPageBenefit";

export type PlanTablePlan = {
  planTypeKey: string | number;
  contentFlags: ContentFlag[] | null;
  planName: string;
  premium: string | null;
  outOfPocketCosts: string | null;
  linkedHDHPPlanName: string | null;
  financialFSAMoneyRollover: boolean | null;
  financialFSAMoneyRolloverAmount: number | null;
  financialFSAMoneyRolloverDate: Date | null;
  planSummaryDocumentId: string | null;
  planSummaryDocument: BenefitPlanSummaryDocument | null;
};

export function usePlanTablePlans(
  benefits: ExplorerPageBenefit[],
  benefitType: BenefitTypeBenEx,
  baseKey: (string | number)[],
): PlanTablePlan[] {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable" });

  return benefits.reduce<PlanTablePlan[]>(
    (
      acc,
      {
        planType,
        contentFlags,
        planName,
        premium,
        outOfPocketCosts,
        linkedHDHPPlanName,
        financialFSAMoneyRollover,
        financialFSAMoneyRolloverAmount,
        financialFSAMoneyRolloverDate,
        planSummaryDocumentId,
        planSummaryDocument,
      },
    ) => {
      //try in this order: HDHP value, then planType value, then default value
      const planTypeKey = copyKeySwitch(
        t,
        ...baseKey,
        contentFlags?.includes("HDHP") || (linkedHDHPPlanName && planType === "HSA")
          ? copyKeySwitchCustom(t, [...baseKey, `${planType}_HDHP`], planType)
          : planType,
      );
      //consolidate plans into one per plan type
      //special handling for medical and dental
      if (
        !acc.find((v) => v.planTypeKey === planTypeKey) &&
        copyKeyExists(t, ...baseKey, planTypeKey) &&
        benefitType !== "MEDICAL" &&
        benefitType !== "DENTAL"
      )
        acc.push({
          planTypeKey,
          contentFlags,
          planName,
          premium,
          outOfPocketCosts,
          linkedHDHPPlanName,
          financialFSAMoneyRollover,
          financialFSAMoneyRolloverAmount,
          financialFSAMoneyRolloverDate,
          planSummaryDocumentId,
          planSummaryDocument: planSummaryDocument ?? null,
        });
      else if (benefitType === "DENTAL") {
        if (
          acc.find((v) => v.planTypeKey !== planTypeKey && planTypeKey !== "DHMO") &&
          copyKeyExists(t, ...baseKey, planTypeKey)
        )
          return acc;
        else if (
          !acc.find((v) => v.planTypeKey === planTypeKey) &&
          copyKeyExists(t, ...baseKey, planTypeKey)
        )
          acc.push({
            planTypeKey,
            contentFlags,
            planName,
            premium,
            outOfPocketCosts,
            linkedHDHPPlanName,
            financialFSAMoneyRollover,
            financialFSAMoneyRolloverAmount,
            financialFSAMoneyRolloverDate,
            planSummaryDocumentId,
            planSummaryDocument: planSummaryDocument ?? null,
          });
      } else if (copyKeyExists(t, ...baseKey, planTypeKey) && benefitType === "MEDICAL")
        acc.push({
          planTypeKey,
          contentFlags,
          planName,
          premium,
          outOfPocketCosts,
          linkedHDHPPlanName,
          financialFSAMoneyRollover,
          financialFSAMoneyRolloverAmount,
          financialFSAMoneyRolloverDate,
          planSummaryDocumentId,
          planSummaryDocument: planSummaryDocument ?? null,
        });

      return acc;
    },
    [],
  );
}
