import { hasFormikError } from "./hasFormikError";

import type { FormikValues, FormikErrors, FormikTouched } from "formik";

export function hasFormikErrors<Values extends FormikValues = FormikValues>(formik: {
  values: Values;
  errors: FormikErrors<Values>;
  touched: FormikTouched<Values>;
}): (keyof Values)[] {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- helps
  const hasError = Object.keys(formik.values).filter((value) =>
    hasFormikError(formik, value),
  ) as (keyof Values)[];
  return hasError;
}
