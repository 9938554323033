import { formatFullName } from "shared/utils/format";
import type { Client } from "shared/types/Client";

export function getAuthorizedSignerFullName(client: Client) {
  const { authorizedSignerUser, outsideSigner } = client;
  const signerFullName = authorizedSignerUser
    ? formatFullName(authorizedSignerUser)
    : outsideSigner
    ? formatFullName(outsideSigner)
    : null;
  return signerFullName;
}

export function getEIFSignedByFullName(client: Client) {
  const { eifSignedByUser, eifOutsideSignedByUser } = client;
  const signerFullName = eifSignedByUser
    ? formatFullName(eifSignedByUser)
    : eifOutsideSignedByUser
    ? formatFullName(eifOutsideSignedByUser)
    : null;
  return signerFullName;
}
