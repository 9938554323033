import { SearchOutlined } from "@ant-design/icons";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Dropdown, Input } from "antd";
import { useState } from "react";

import { Checkbox } from "../Form/Checkbox";
import * as styles from "./multiSelect.module.less";

type Data = {
  id: string;
  value: string;
  displayValue?: string;
};

export type MultiSelectProps = {
  placeholder: string;
  items: Data[];
  selectedIds: Data["id"][];
  onChange: (ids: Data["id"][]) => void;
  additionalOptions?: Data[];
};

export const MultiSelect = ({
  additionalOptions,
  placeholder,
  items,
  selectedIds,
  onChange,
}: MultiSelectProps) => {
  const [internalSelectedIds, setInternalSelectedIds] = useState(selectedIds);
  const [filterTerm, setFilterTerm] = useState("");

  const filteredItems = [
    ...(additionalOptions ?? []),
    ...(filterTerm
      ? items.filter(
          (item) =>
            internalSelectedIds.includes(item.id) ||
            item.value.toLowerCase().includes(filterTerm.toLowerCase()),
        )
      : items),
  ];

  const toggleItem = (item: Data): void => {
    const selected = internalSelectedIds.includes(item.id);
    const updatedIds = selected
      ? internalSelectedIds.filter((id) => id !== item.id)
      : [...internalSelectedIds, item.id];
    setInternalSelectedIds(updatedIds);
    onChange(updatedIds);
  };

  const clearAllItems = (): void => {
    setInternalSelectedIds([]);
    onChange([]);
  };

  const filter = (term: string): void => {
    setFilterTerm(term);
  };

  const firstSelectedItem = filteredItems.find((item) => internalSelectedIds.includes(item.id));
  const remainingSelectedValueCount = internalSelectedIds.length - 1;

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => (
        <MultiSelect.Dropdown
          filter={filter}
          filterTerm={filterTerm}
          items={filteredItems}
          selectedIds={internalSelectedIds}
          onToggleItem={toggleItem}
          onClearAllItems={clearAllItems}
        />
      )}
    >
      <div className={styles.container}>
        {firstSelectedItem ? (
          <>
            <span className={styles.selectedValue}>
              <span className={styles.selectedValueContent}>{firstSelectedItem.value}</span>
              <button
                className={styles.selectedValueClose}
                onClick={(event) => {
                  toggleItem(firstSelectedItem);
                  event.stopPropagation(); // Avoid closing the dropdown.
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </span>
            {remainingSelectedValueCount > 0 && (
              <span className={styles.remainingSelectedValue}>+{remainingSelectedValueCount}</span>
            )}
          </>
        ) : (
          <>
            <span className={styles.placeholder}>{placeholder}</span>
            <span className={styles.chevron}>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </>
        )}
      </div>
    </Dropdown>
  );
};

type MultiSelectDropdownProps = {
  filter: (term: string) => void;
  filterTerm: string;
  items: Data[];
  selectedIds: Data["id"][];
  onToggleItem: (item: Data) => void;
  onClearAllItems: () => void;
};

MultiSelect.Dropdown = ({
  filter,
  filterTerm,
  items,
  selectedIds,
  onToggleItem,
  onClearAllItems,
}: MultiSelectDropdownProps) => {
  return (
    <div className={styles.dropdownContainer}>
      <header>
        <Input
          className={styles.search}
          type="search"
          suffix={<SearchOutlined />}
          placeholder="Search by name"
          value={filterTerm}
          onChange={(event) => filter(event.target.value)}
          onKeyDown={(e) => e.stopPropagation()} //resolves antd bug https://github.com/ant-design/ant-design/issues/15392
        />
      </header>
      {items.length > 0 && (
        <>
          <Divider className={styles.divider} />
          <div className={styles.items}>
            {items.map((item) => (
              <Checkbox
                key={item.id}
                checked={selectedIds.includes(item.id)}
                onChange={() => onToggleItem(item)}
                label={item.displayValue ?? item.value}
                name={`checked-item-${item.id}`}
              />
            ))}
          </div>
          <Divider className={styles.divider} />
          <footer>
            <button className={styles.clearAll} onClick={onClearAllItems}>
              Clear all
            </button>
          </footer>
        </>
      )}
    </div>
  );
};
