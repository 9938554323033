import { Card } from "antd";

import { Button } from "../../../components/Button/Button";
import { Body1, H2 } from "../../../components/Typography/Typography";
import { useTranslation } from "../../../i18n";
import { openZendeskChat, useZendeskDepartmentStatus } from "../../../utils/zendesk";

import * as styles from "./clientPage.module.less";

export const DataTestId = {
  NeedGuidanceCard: "needguidance-card",
};

export const ExplorerNeedGuidance = () => {
  const { t } = useTranslation({ keyPrefix: "ExplorerNeedGuidance" });
  const zendeskStatus = useZendeskDepartmentStatus();
  return (
    <>
      <Card
        className={styles.highlights}
        style={{ height: "100%" }}
        data-testid={DataTestId.NeedGuidanceCard}
      >
        <H2>{t("heading")}</H2>
        <Body1>{t("content")}</Body1>
        <div style={{ marginTop: "1rem" }}>
          <Button
            type="tertiary"
            disabled={zendeskStatus !== "online"}
            onClick={() => {
              openZendeskChat();
              return;
            }}
          >
            {t("callToAction")}
          </Button>
        </div>
      </Card>
    </>
  );
};
