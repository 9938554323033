import { FormInputNumber } from "client/src/components/Form/InputNumber";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import {
  fieldErrorMsg,
  type ExplorerBenefitModalPresentationProps,
} from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/ExplorerBenefitModalPresentation";
import { formatCurrency, formatPercentage } from "client/src/utils/formatters";
import { hasFormikError } from "client/src/utils/hasFormikError";
import * as styles from "./ExplorerBenefitModalPresentation.module.less";
import { FORM_FIELDS_DOLLAR_MAX } from "./FormFields";

type Props = Pick<ExplorerBenefitModalPresentationProps, "formik">;

export const VisionModalFormFields = ({ formik }: Props) => {
  return (
    <StackY dist={32}>
      <RadioGroup
        name="visionExamServicesBiYearly"
        label="Exam services"
        direction="vertical"
        value={formik.values.visionExamServicesBiYearly}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        touched={formik.touched.visionExamServicesBiYearly}
        error={
          hasFormikError(formik, "visionExamServicesBiYearly")
            ? fieldErrorMsg("exam services frequency", true)
            : false
        }
        options={[
          { label: "Once each 12 months", value: false },
          { label: "Once each 24 months", value: true },
        ]}
      />
      <StackX dist={16} className={styles.stackXItemsFlexGrow}>
        <FormInputNumber
          label="$ covered in network"
          value={formik.values.visionExamServicesInNetwork ?? 0}
          maxLength={8}
          max={FORM_FIELDS_DOLLAR_MAX}
          name="visionExamServicesInNetwork"
          touched={formik.touched.visionExamServicesInNetwork}
          onChange={async (val) =>
            await formik.setFieldValue("visionExamServicesInNetwork", val ?? null)
          }
          error={
            hasFormikError(formik, "visionExamServicesInNetwork")
              ? fieldErrorMsg("exam services in network")
              : undefined
          }
          disabled={formik.isSubmitting}
          formatter={formatCurrency}
          parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
          step={100}
          min={0}
        />
        <FormInputNumber
          label="$ covered out of network"
          value={formik.values.visionExamServicesOutOfNetwork ?? 0}
          maxLength={8}
          max={FORM_FIELDS_DOLLAR_MAX}
          name="visionExamServicesOutOfNetwork"
          touched={formik.touched.visionExamServicesOutOfNetwork}
          onChange={async (val) =>
            await formik.setFieldValue("visionExamServicesOutOfNetwork", val ?? null)
          }
          error={
            hasFormikError(formik, "visionExamServicesOutOfNetwork")
              ? fieldErrorMsg("exam services out of network")
              : undefined
          }
          disabled={formik.isSubmitting}
          formatter={formatCurrency}
          parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
          step={100}
          min={0}
        />
      </StackX>
      <RadioGroup
        name="visionLensesBiYearly"
        label="Lenses"
        direction="vertical"
        value={formik.values.visionLensesBiYearly}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        touched={formik.touched.visionLensesBiYearly}
        error={
          hasFormikError(formik, "visionLensesBiYearly")
            ? fieldErrorMsg("lenses frequency", true)
            : false
        }
        options={[
          { label: "Once each 12 months", value: false },
          { label: "Once each 24 months", value: true },
        ]}
      />
      <StackX dist={16} className={styles.stackXItemsFlexGrow}>
        <FormInputNumber
          label="$ covered in network"
          value={formik.values.visionLensesInNetwork ?? 0}
          maxLength={8}
          max={FORM_FIELDS_DOLLAR_MAX}
          name="visionLensesInNetwork"
          touched={formik.touched.visionLensesInNetwork}
          onChange={async (val) => await formik.setFieldValue("visionLensesInNetwork", val ?? null)}
          error={
            hasFormikError(formik, "visionLensesInNetwork")
              ? fieldErrorMsg("lenses in network")
              : undefined
          }
          disabled={formik.isSubmitting}
          formatter={formatCurrency}
          parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
          step={100}
          min={0}
        />
        <FormInputNumber
          label="$ covered out of network"
          value={formik.values.visionLensesOutOfNetwork ?? 0}
          maxLength={8}
          max={FORM_FIELDS_DOLLAR_MAX}
          name="visionLensesOutOfNetwork"
          touched={formik.touched.visionLensesOutOfNetwork}
          onChange={async (val) =>
            await formik.setFieldValue("visionLensesOutOfNetwork", val ?? null)
          }
          error={
            hasFormikError(formik, "visionLensesOutOfNetwork")
              ? fieldErrorMsg("lenses out of network")
              : undefined
          }
          disabled={formik.isSubmitting}
          formatter={formatCurrency}
          parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
          step={100}
          min={0}
        />
      </StackX>
      <RadioGroup
        name="visionFramesBiYearly"
        label="Frames"
        direction="vertical"
        value={formik.values.visionFramesBiYearly}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        touched={formik.touched.visionFramesBiYearly}
        error={
          hasFormikError(formik, "visionFramesBiYearly")
            ? fieldErrorMsg("frames frequency", true)
            : false
        }
        options={[
          { label: "Once each 12 months", value: false },
          { label: "Once each 24 months", value: true },
        ]}
      />
      <StackY dist={12}>
        <StackX dist={16} className={styles.stackXItemsFlexGrow}>
          <FormInputNumber
            label="$ covered in network"
            value={formik.values.visionFramesInNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionFramesInNetwork"
            touched={formik.touched.visionFramesInNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionFramesInNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionFramesInNetwork")
                ? fieldErrorMsg("frames in network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
          <FormInputNumber
            label="$ covered out of network"
            value={formik.values.visionFramesOutOfNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionFramesOutOfNetwork"
            touched={formik.touched.visionFramesOutOfNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionFramesOutOfNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionFramesOutOfNetwork")
                ? fieldErrorMsg("frames out of network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
        </StackX>
        <Body3>Anything over this amount is covered at:</Body3>
        <StackX dist={16} className={styles.stackXItemsFlexGrow}>
          <FormInputNumber
            label="% covered in network"
            value={formik.values.visionFramesOverInNetwork ?? 0}
            maxLength={4}
            name="visionFramesOverInNetwork"
            touched={formik.touched.visionFramesOverInNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionFramesOverInNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionFramesOverInNetwork")
                ? fieldErrorMsg("frames over in network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={(value) => (value != null ? formatPercentage(value) : "")}
            parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
            step={1}
            min={0}
            max={100}
          />
          <FormInputNumber
            label="% covered out of network"
            value={formik.values.visionFramesOverOutOfNetwork ?? 0}
            maxLength={4}
            name="visionFramesOverOutOfNetwork"
            touched={formik.touched.visionFramesOverOutOfNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionFramesOverOutOfNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionFramesOverOutOfNetwork")
                ? fieldErrorMsg("frames over out of network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={(value) => (value != null ? formatPercentage(value) : "")}
            parser={(value) => +(value?.replace(/%\s?/g, "") ?? "")}
            step={1}
            min={0}
            max={100}
          />
        </StackX>
      </StackY>
      <RadioGroup
        name="visionContactLensesBiYearly"
        label="Contact lenses"
        direction="vertical"
        value={formik.values.visionContactLensesBiYearly}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        touched={formik.touched.visionContactLensesBiYearly}
        error={
          hasFormikError(formik, "visionContactLensesBiYearly")
            ? fieldErrorMsg("contact lenses frequency", true)
            : false
        }
        options={[
          { label: "Once each 12 months", value: false },
          { label: "Once each 24 months", value: true },
        ]}
      />
      <StackY dist={12}>
        <Body3>Contact lens exam</Body3>
        <StackX dist={16} className={styles.stackXItemsFlexGrow}>
          <FormInputNumber
            label="$ covered in network"
            value={formik.values.visionContactLensesExamInNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionContactLensesExamInNetwork"
            touched={formik.touched.visionContactLensesExamInNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionContactLensesExamInNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionContactLensesExamInNetwork")
                ? fieldErrorMsg("contact lens exam in network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
          <FormInputNumber
            label="$ covered out of network"
            value={formik.values.visionContactLensesExamOutOfNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionContactLensesExamOutOfNetwork"
            touched={formik.touched.visionContactLensesExamOutOfNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionContactLensesExamOutOfNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionContactLensesExamOutOfNetwork")
                ? fieldErrorMsg("contact lens exam out of network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
        </StackX>
        <Body3>Contact lenses</Body3>
        <StackX dist={16} className={styles.stackXItemsFlexGrow}>
          <FormInputNumber
            label="$ covered in network"
            value={formik.values.visionContactLensesInNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionContactLensesInNetwork"
            touched={formik.touched.visionContactLensesInNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionContactLensesInNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionContactLensesInNetwork")
                ? fieldErrorMsg("contact lenses in network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
          <FormInputNumber
            label="$ covered out of network"
            value={formik.values.visionContactLensesOutOfNetwork ?? 0}
            maxLength={8}
            max={FORM_FIELDS_DOLLAR_MAX}
            name="visionContactLensesOutOfNetwork"
            touched={formik.touched.visionContactLensesOutOfNetwork}
            onChange={async (val) =>
              await formik.setFieldValue("visionContactLensesOutOfNetwork", val ?? null)
            }
            error={
              hasFormikError(formik, "visionContactLensesOutOfNetwork")
                ? fieldErrorMsg("contact lenses out of network")
                : undefined
            }
            disabled={formik.isSubmitting}
            formatter={formatCurrency}
            parser={(value) => +(value?.replace(/\$\s?|(,*)/g, "") ?? "")}
            step={100}
            min={0}
          />
        </StackX>
      </StackY>
    </StackY>
  );
};
