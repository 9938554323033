import { useUpdatePolicy } from "client/src/hooks/policy";
import { getIsInternalUser } from "../../../../../shared/rbac/rbac";
import { ChildPageSkeleton } from "../../../components/ChildPageLayout/ChildPageLayout";
import { GenericErrorCopy } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import { useGetBenAdminPlatforms } from "../../../hooks/benAdminPlatforms";
import { useUpdateClient } from "../../../hooks/client";
import { triggerError } from "../../../hooks/generalError";
import { useTitle } from "../../../hooks/useTitle";
import { useTrackElementClicked } from "../../../utils/analytics";

import { CompanyProfilePagePresentation } from "./CompanyProfilePagePresentation";

import type { UserData } from "../../../../../shared/rbac/rbac";
import type { Task } from "shared/data/Tasks";
import type { Client } from "shared/types/Client";

export type CompanyProfileProps = {
  client: Client;
  task: Task;
  userData: UserData | null;
};

export function CompanyProfilePage({ client, task, userData }: CompanyProfileProps): JSX.Element {
  useTitle("Company Profile");
  const trackElementClicked = useTrackElementClicked(client, "Company Profile");

  const {
    isLoading: isLoadingBenAdminPlatforms,
    error: errorBenAdminPlatforms,
    data: benAdminPlatforms,
  } = useGetBenAdminPlatforms();

  const updateClientQuery = useUpdateClient();
  const updatePolicyQuery = useUpdatePolicy();
  const isInternalUser = getIsInternalUser(userData);

  if (isLoadingBenAdminPlatforms) {
    return <ChildPageSkeleton />;
  }

  if (errorBenAdminPlatforms) {
    return (
      <LoadingError type="component" error={errorBenAdminPlatforms} title={GenericErrorCopy} />
    );
  }

  if (!benAdminPlatforms) {
    return triggerError("Could not load ben admin platforms");
  }

  return (
    <CompanyProfilePagePresentation
      benAdminPlatforms={benAdminPlatforms}
      client={client}
      isInternalUser={isInternalUser}
      task={task}
      trackElementClicked={trackElementClicked}
      updateClientQuery={updateClientQuery}
      updatePolicyQuery={updatePolicyQuery}
    />
  );
}
