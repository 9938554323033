import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ContactUs } from "client/src/components/ContactUs/ContactUs";
import clsx from "clsx";
import { useEffect } from "react";
import { useClientHubParams } from "../../hooks/useClientHubParams";

import { useToggler } from "../../hooks/useToggler";
import { useTranslation } from "../../i18n";
import { useSendExplorerFeedbackForm, useSendNativeFeedbackForm } from "../../utils/analytics";
import { setZendeskWebWidgetVisibility, zendeskWebWidgetIsVisible } from "../../utils/zendesk";
import { Button } from "../Button/Button";
import { GiveFeedbackForm } from "../GiveFeedbackForm/GiveFeedbackForm";

import * as styles from "./giveFeedbackInputPicker.module.less";

export function GiveFeedbackInputPicker({
  variant = "native",
  position = "left",
}: {
  variant?: "native" | "explorer";
  position?: "left" | "right";
}) {
  const { explorerPageId } = useClientHubParams();
  const sendNativeFeedbackForm = useSendNativeFeedbackForm();
  const sendExplorerFeedbackForm = useSendExplorerFeedbackForm(explorerPageId);

  const [nativeFeedbackFormVisible, toggleNativeFeedbackFormVisible] = useToggler();
  const isMobile = !useBreakpoint().md;

  useToggleZendesk(nativeFeedbackFormVisible, variant === "explorer");

  const { t } = useTranslation({ keyPrefix: "ExplorerGiveFeedback" });

  return (
    <div
      className={clsx(styles.giveFeedbackButton, isMobile && styles.mobile)}
      style={{
        left: position === "left" ? 0 : undefined,
        right: position === "right" ? 0 : undefined,
        marginBottom: variant === "explorer" && !isMobile ? "15px" : undefined,
      }}
    >
      {variant === "explorer" ? (
        <div className="mr-8">
          <ContactUs />
        </div>
      ) : null}

      {nativeFeedbackFormVisible ? (
        <GiveFeedbackForm
          sendNativeFeedbackForm={sendNativeFeedbackForm}
          sendExplorerFeedbackForm={sendExplorerFeedbackForm}
          onClose={toggleNativeFeedbackFormVisible}
          position={position}
          variant={variant === "explorer" ? "explorer" : "onboard"}
          title={variant === "explorer" ? t("feedbackBtnText") ?? undefined : "Give Feedback"}
        />
      ) : (
        <Button
          onClick={toggleNativeFeedbackFormVisible}
          type={variant === "native" ? "primary" : "secondary"}
          size={variant === "explorer" ? "middle" : "small"}
          shrink
          rounded
        >
          {variant === "explorer" ? t("feedbackBtnText") : "Give Feedback"}
        </Button>
      )}
    </div>
  );
}

const useToggleZendesk = (nativeFeedbackFormVisible: boolean, enabled = true) =>
  useEffect(() => {
    if (!enabled) return;
    const wasVisible = zendeskWebWidgetIsVisible();
    if (nativeFeedbackFormVisible) {
      setZendeskWebWidgetVisibility(false);
    }

    return () => setZendeskWebWidgetVisibility(wasVisible);
  }, [nativeFeedbackFormVisible, enabled]);
