import { BenefitTypeBenExData } from "shared/types/BenefitTypes";
import { PlanTypeData } from "../../../../shared/data/ExplorerBenefit";
import { Button } from "../../components/Button/Button";
import { Checkbox } from "../../components/Form/Checkbox";
import { SlobTable } from "../../components/SlobTable/SlobTable";

import { MultiCellValue } from "../Client/ClientsList/ClientsTable/MultiCellValue";
import * as styles from "./ExplorerPageForm/explorerPageForm.module.less";

import type { Carrier } from "../../../../shared/types/Carrier";
import type { Document } from "../../../../shared/types/Document";
import type { Pretty } from "../../../../shared/types/Helper";
import type { explorerPageBenefitInputValidation } from "../../../../shared/validation/explorerPageBenefit";
import type { SlobColumnsType } from "../../components/SlobTable/SlobTable";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { PlanType } from "shared/types/ExplorerPageBenefit";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { InferType } from "yup";

export type ExplorerPageBenefitTableProps = {
  benefits: (Pretty<InferType<typeof explorerPageBenefitInputValidation>> & {
    hasError?: boolean;
  })[];
  carriers: Carrier[];
  planSummaryDocuments: Document[];
  disabled?: boolean;
  handleFormChange: (e: unknown) => void;
  handleBenefitEdit: (index: number) => void;
  featureToggles?: ClientFeatureToggles;
};
export const ExplorerPageBenefitTable = ({
  benefits,
  carriers,
  planSummaryDocuments,
  disabled = false,
  handleFormChange,
  handleBenefitEdit,
  featureToggles,
}: ExplorerPageBenefitTableProps) => {
  const columns: SlobColumnsType<
    Pretty<InferType<typeof explorerPageBenefitInputValidation>> & {
      id: string;
      hasError?: boolean;
    }
  > = [
    {
      title: "Carrier",
      dataIndex: "carrierId",
      sorter: true,
      render: (carrierId) =>
        carriers.filter((c) => c.id === carrierId)[0]?.carrierName ?? "No carrier selected",
    },
    {
      title: "Benefit Type",
      dataIndex: "benefitType",
      sorter: true,
      render: (value: BenefitTypeBenEx) => value && BenefitTypeBenExData[value].displayName,
    },
    {
      title: "Plan Name",
      dataIndex: "planName",
      sorter: true,
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
      sorter: true,
      render: (value: PlanType | null) => value && PlanTypeData[value].displayName,
    },
    {
      title: "Summary Document",
      dataIndex: "planSummaryDocumentId",
      sorter: false,
      render: (_, benefit) => {
        const docs = planSummaryDocuments.filter(
          (doc) =>
            doc.id === benefit.planSummaryDocumentId ||
            doc.id === benefit.spanishPlanSummaryDocumentId,
        );
        return (
          <MultiCellValue>
            {docs.length > 0
              ? docs.map((d) => (
                  <div key={`${d.id}`}>
                    {d.name} {d.language === "ES" ? "(Spanish)" : "(English)"}
                  </div>
                ))
              : "No Summary Document"}
          </MultiCellValue>
        );
      },
    },
    {
      title: "New Benefit",
      dataIndex: "isNew",
      sorter: true,
      render: (value, _, index) => (
        <Checkbox
          checked={value}
          label=""
          name={`benefits[${index}].isNew`}
          disabled={disabled}
          onChange={handleFormChange}
        />
      ),
    },
    {
      render: (_value, benefit, index) => (
        <div className={styles.actionButtons}>
          <Button
            data-testid="edit"
            size="xtra-small"
            type="secondary"
            disabled={disabled}
            onClick={() => handleBenefitEdit(index)}
          >
            {benefit.hasError ? "Needs Review" : "Edit"}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.benefitsTable}>
      <SlobTable
        columns={columns}
        isLoading={false}
        currentPage={1}
        totalItems={benefits?.length ?? 0}
        pageSize={benefits?.length ?? 0}
        onChange={() => null}
        data={benefits?.map((benefit, index) => ({ ...benefit, id: `${index}` }))}
        getIsRowInErrorState={getIsRowInErrorState}
        featureToggles={featureToggles}
      />
    </div>
  );
};

const getIsRowInErrorState = (benefit: ExplorerPageBenefitTableProps["benefits"][number]) => {
  return !!benefit.hasError;
};
