import { keepPreviousData, useQueryClient } from "@tanstack/react-query";

import { compareQueryKey, useSlobMutation, useSlobQuery } from "../query";
import { getTableQueryArguments } from "../slobTable";

import {
  jsonExplorerPageToExplorerPage,
  jsonPublicExplorerPageToPublicExplorerPage,
} from "./utils";

import type { ClientId } from "../../../../shared/types/Client";
import type { Pretty } from "../../../../shared/types/Helper";
import type { explorerPageInputValidation } from "../../../../shared/validation/explorerPage";
import type { ResponseError } from "../query";
import type { TableQueryParams } from "../slobTable";
import type { QueryClient, QueryKey, UseQueryOptions } from "@tanstack/react-query";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type {
  DemoEnrollmentStatus,
  ExplorerPage,
  ExplorerPageId,
  PublicExplorerPage,
} from "shared/types/ExplorerPage";
import type { InferType } from "yup";

export const invalidateGetExplorerPages = async (
  queryClient: QueryClient,
  clientId: string | number,
) => {
  await queryClient.invalidateQueries({
    predicate: compareQueryKey(["get", `/api/clients/${clientId}/explorer-pages`]),
  });
};
export const useGetExplorerPages = ({
  clientId,
  ...query
}: TableQueryParams<{ clientId: ClientId }>) => {
  const params = getTableQueryArguments(query);
  return useSlobQuery<{ data: ExplorerPage[]; meta: { count: number } }>({
    method: "get",
    path: `/api/clients/${clientId}/explorer-pages?${params}`,
    map: (response) => ({ ...response, data: response.data.map(jsonExplorerPageToExplorerPage) }),
  });
};

export const useGetAssociatedExplorerPages = ({
  clientId,
  associationId,
}: {
  clientId: ClientId;
  associationId?: ExplorerPage["associationId"];
}) => {
  return useSlobQuery<PublicExplorerPage[]>({
    method: "get",
    path: `/api/clients/${clientId}/explorer-pages/association/${associationId}`,
    map: (response) => response.map(jsonPublicExplorerPageToPublicExplorerPage),
    options: {
      enabled: !!associationId,
    },
  });
};

export const useGetExplorerPagesByPageOwner = (
  { pageOwnerId }: { pageOwnerId?: string },
  options?: Omit<
    UseQueryOptions<
      {
        data: ExplorerPage[];
        meta: {
          count: number;
        };
      },
      ResponseError,
      {
        data: ExplorerPage[];
        meta: {
          count: number;
        };
      },
      QueryKey
    >,
    "queryKey"
  >,
) => {
  return useSlobQuery<{ data: ExplorerPage[]; meta: { count: number } }>({
    method: "get",
    path: `/api/explorer-pages/by-owner/${pageOwnerId}`,
    map: (response) => ({ ...response, data: response.data.map(jsonExplorerPageToExplorerPage) }),
    options,
  });
};

export const usePutExplorerPagesOwnership = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "put",
    path: `/api/explorer-pages/transfer/:currentPageOwnerId/:newPageOwnerId`,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/explorer-pages`]),
        });
      },
    },
  });
};

export const useGetExplorerPagesDocumentsByBenefits = ({
  clientId,
  associationId,
  benefitTypes,
}: {
  clientId: ClientId;
  associationId?: ExplorerPage["associationId"];
  benefitTypes: BenefitTypeBenEx[];
}) => {
  const params = new URLSearchParams();
  for (const benefitType of benefitTypes) {
    params.append("benefitTypes[]", benefitType);
  }
  const qs = params.toString();
  return useSlobQuery<ExplorerPage[]>({
    method: "get",
    path: `/api/clients/${clientId}/explorer-pages/documents/benefittypes/${associationId}?${qs}`,
    map: (response) => response.map(jsonExplorerPageToExplorerPage),
    options: {
      enabled: !!associationId,
    },
  });
};

export const useDeleteExplorerPage = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `api/clients/:clientId/explorer-pages/:explorerPageId`,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients`]),
        });
      },
    },
  });
};

export const useGetExplorerPageById = (clientId: ClientId, explorerPageId: ExplorerPageId) =>
  useSlobQuery<ExplorerPage>({
    method: "get",
    path: `/api/clients/${clientId}/explorer-pages/${explorerPageId}`,
    map: jsonExplorerPageToExplorerPage,
    options: {
      enabled: !!explorerPageId,
    },
  });

export const useCreateExplorerPage = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Pretty<InferType<typeof explorerPageInputValidation>>,
    ExplorerPage,
    "/api/clients/:clientId/explorer-pages"
  >({
    method: "post",
    path: `/api/clients/:clientId/explorer-pages`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients/${clientId}/explorer-pages`]),
        });
      },
    },
  });
};

export const useUpdateExplorerPage = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Pretty<InferType<typeof explorerPageInputValidation>>,
    ExplorerPage,
    "/api/clients/:clientId/explorer-pages/:explorerPageId"
  >({
    method: "put",
    path: `/api/clients/:clientId/explorer-pages/:explorerPageId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients/${clientId}/explorer-pages`]),
        });
      },
    },
  });
};

export const useGetPublicExplorerPageById = (
  explorerPageId: ExplorerPageId,
  demoEnrollmentStatus?: DemoEnrollmentStatus,
) =>
  useSlobQuery<PublicExplorerPage>({
    method: "get",
    path: `/api/explorer-pages/${explorerPageId}${
      demoEnrollmentStatus
        ? "?" +
          new URLSearchParams({
            demoEnrollmentStatus: demoEnrollmentStatus,
          }).toString()
        : ""
    }`,
    map: jsonPublicExplorerPageToPublicExplorerPage,
    options: {
      enabled: !!explorerPageId,
      placeholderData: keepPreviousData,
    },
  });
