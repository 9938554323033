import type { ClientId } from "./Client";
import type { EmailStatus, EmailStatusHistory } from "./EmailStatusHistory";
import type { EmailProvider, Prisma } from "@prisma/client";
import type { DocumentId } from "shared/types/Document";
import type { ValueOf } from "shared/types/Helper";
import type { DomainUser, UserId } from "shared/types/User";

export type EmailId = string;
export type EmailUserId = string;

export interface Email {
  id: EmailId;
  clientId: ClientId | null;
  to: string;
  cc: string | null;
  bcc: string | null;
  from: string;
  fromName: string | null;
  subject: string;
  previewText: string;
  body: string;
  emailType: EmailType;
  templateVariables: Prisma.JsonValue;
  resent: boolean;
  metadata: Prisma.JsonValue | null;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  deletedAt: Date | null;
  deletedBy: string | null;

  clientName: string | null | undefined;
  attachmentIds: DocumentId[];
}

type ErrorUsers = Array<{
  userId: UserId;
  user: DomainUser;
}>;

export type EmailWithStatusHistory = Email & {
  sentWith: EmailProvider;
  statusHistory: EmailStatusHistory[];
  errorUsers: ErrorUsers;
};

export type EmailSimple = {
  id: EmailId;
  clientId: ClientId | null;
  to: string;
  subject: string;
  previewText: string;
  emailType: EmailType;
  createdAt: Date;
  clientName: string | null | undefined;
  statusHistory: EmailStatus[];
  errorUsers: ErrorUsers;
};

export const EmailTypes = [
  "ACTIVITY_LOG",
  "UPCOMING_DATES",
  "PHASE_CHANGE",
  "RESET_PASSWORD",
  "ACCOUNT_ACTIVATION_REQUEST",
  "ACCOUNT_ACTIVATION_REMINDER",
  "ACCOUNT_ACTIVATION_CONFIRMATION",
  "EMAIL_CHANGE_CONFIRMATION",
  "PASSWORD_CHANGE_CONFIRMATION",
  "NEW_CLIENT",
  "POST_ONBOARDING_SURVEY",
  "ONGOING_SERVICE_CALL",
  "EXPLORER_PERSONAL_BENEFITS_PLAN",
  "RISK_ACCEPTANCE",
  "GENERIC_DENTAL_ID_CARD",
  "PERSONALIZED_DENTAL_ID_CARD",
  "EXPLORER_LEAD_GENERATION_REQUEST",
  "KICKOFF_CALL",
  "KICKOFF_CALL_DECLINED",
  "BENEFIT_SUMMARY_ADDED",
  "COMPANY_INFORMATION_IN_REVIEW_SUBMIT",
  "EIF_BA_REVIEWS_AND_SUBMITS",
  "FORM_HAS_BEEN_REASSIGNED",
  "FORM_NEEDS_INITIALS",
  "FORMS_ARE_READY_TO_SIGN",
  "FORMS_ARE_READY_TO_DOWNLOAD",
  "VDR_READY_TO_REVIEW",
  "ENROLLMENT_RESOURCES_ITEMS_ADDED",
  "DENTAL_ROLLOVER_READY",
  "DENTAL_ROLLOVER_UPDATED",
  "PRIOR_CARRIER_CERTS_READY",
  "PRIOR_CARRIER_BILLS_READY",
  "FORM_HAS_BEEN_SIGNED_BY_SOMEONE",
  "FORM_HAS_BEEN_SIGNED_BY_EVERYONE_BUT_IC",
  "DOCUSIGN_FORM_VOIDED",
  "DOCUSIGN_FORM_DECLINED_BY_SIGNER",
  "PFML_EXEMPTION_DOCUMENT_READY_TO_REVIEW",
  "ENROLLMENT_ELECTIONS_TO_REVIEW",
  "SUNADVISOR_SPD_UPLOADED",
  "PFML_SPD_UPLOADED_WITH_COMMENTS",
  "SUN_LIFE_CONNECT_ADMIN_REVIEW",
  "COMPANY_PROFILE_EDX_COMPLETED",
  "COMPANY_PROFILE_EDX_EDITED",
  "ONGOING_SERVICE_CALL_REQUESTED",
  "ONGOING_SERVICE_CALL_DECLINED",
  "VDR_COMMENTS_ADDED",
  "ENROLLMENT_RESOURCES_REQUESTED",
  "ENROLLMENT_RESOURCES_REQUEST_MODIFIED",
  "EIF_CHANGES_ACCEPTED",
  "EIF_CHANGES_DECLINED",
  "EIF_CHANGES_MADE",
  "EIF_CHANGES_MADE_OUTSIDE_SIGNER",
  "ACTIVELY_AT_WORK_REMINDER",
  "ACTIVELY_AT_WORK_DOCUMENT_UPLOADED",
  "ACTIVELY_AT_WORK_DOCUMENT_UPDATED",
  "EIF_INITIAL_OUTSIDE_EMAIL",
  "EIF_REMINDER_OUTSIDE_SIGNER",
  "ASO_DENTAL_DOCUMENT_UPLOADED",
] as const;

export type EmailType = ValueOf<typeof EmailTypes>;

export type EmailTableFilters = {
  errorsOnly?: boolean;
};

export const isEmailTableFilters = (
  filters: Record<string, unknown>,
): filters is EmailTableFilters => {
  const props: Record<keyof EmailTableFilters, null> = {
    errorsOnly: null,
  };

  const isIt = Object.keys(props).every((prop) => filters[prop] == null || prop in filters);

  return isIt;
};
