import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { RouteData } from "shared/config/routeData";

import { getIsInternalUser, getIsBroker, getIsBenAdmin } from "../../../../../shared/rbac/rbac";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import { Body5 } from "../../../components/Typography/Typography";
import { useSlobAuth } from "../../../hooks/auth";
import { useGetClientByID, useGetUserClients } from "../../../hooks/client";
import { PrintHidden } from "../../../utils/print";

import * as styles from "./userBanner.module.less";

type UserBannerProps = {
  clientId: string;
};

export function UserBanner({ clientId }: UserBannerProps) {
  const { authUser, isLoading: isLoadingAuth } = useSlobAuth();
  const { isLoading: isLoadingClient, data: client } = useGetClientByID(clientId);

  const ready = authUser && !isLoadingAuth && !isLoadingClient && clientId != null;
  const isInternalUser = getIsInternalUser(authUser);
  const isBroker = getIsBroker(authUser);
  const isBA = getIsBenAdmin(authUser);

  const { data: userClients } = useGetUserClients({
    userId: isBA ? authUser?.id || "" : "",
    pageSize: 1,
    page: 1,
    search: "",
    completedSetup: true,
    ...(isBA
      ? {
          phaseIds: [
            "WELCOME",
            "INFO_GATHERING",
            "REVIEW_AND_VALIDATE",
            "PLAN_APPROVAL",
            "CONTRACTS_AND_BILLING",
            "ONBOARDING_COMPLETE",
          ],
        }
      : {}),
  });

  if (!ready || !client || !(isInternalUser || isBroker || isBA)) {
    return null;
  }

  if (isBA) {
    if (userClients == null || userClients.meta.count === 1) {
      return null;
    }
  }

  return (
    <PrintHidden>
      <div
        className={clsx(styles.userBanner, {
          [styles.internalUser]: isInternalUser,
          [styles.broker]: !isInternalUser && isBroker,
        })}
      >
        <span className={styles.text}>
          {isInternalUser ? (
            <Body5 colorPrimary>You’re viewing {client.name} as an internal user. </Body5>
          ) : (
            isBroker && <Body5 colorPrimary>You’re viewing {client.name} as a broker. </Body5>
          )}

          <InternalLinkButton
            type="link-inline-bold"
            size="small"
            to={
              isInternalUser
                ? RouteData.homeInternal.getPath()
                : isBroker
                ? RouteData.homeBroker.getPath()
                : isBA
                ? RouteData.homeBenAdmin.getPath()
                : RouteData.index.getPath()
            }
          >
            See all clients <FontAwesomeIcon icon={faChevronCircleRight} />
          </InternalLinkButton>

          {isInternalUser && (
            <InternalLinkButton
              className={styles.manageLink}
              type="link-inline-bold"
              size="small"
              to={RouteData.clientDetail.getPath(clientId)}
            >
              Manage client <FontAwesomeIcon icon={faChevronCircleRight} />
            </InternalLinkButton>
          )}
        </span>
      </div>
    </PrintHidden>
  );
}
