import { Body3 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/EIFClassBuilderContributions";
import { EIFClassBuilderBenefitList } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderBenefitList";
import { contributionBenefitTypes, getIsContributionBenefitType } from "shared/types/BenefitTypes";
import { getEmployeeClassContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  employeeClass: EmployeeClass;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export function EIFClassBuilderItemContributions(props: Props) {
  const { client, employeeClass, changeSnapshot, authUser, featureToggles } = props;

  const contributionStatus = getEmployeeClassContributionsCompletionStatus(
    employeeClass,
    contributionBenefitTypes,
  );

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter((ecp) =>
    getIsContributionBenefitType(ecp.plan.benefitType),
  );

  const plansWithContributionValues = relevantEmployeeClassPlans.filter(
    (employeeClassPlan) => employeeClassPlan.employerContribution != null,
  );

  const plansWithoutContributionValues = relevantEmployeeClassPlans.filter(
    (employeeClassPlan) => employeeClassPlan.employerContribution === null,
  );

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <div data-testid="contributions-class-builder-item">
      <h5>Contributions</h5>

      {contributionStatus === "Not Started" &&
        employeeClass.employeeClassPlans.length === 0 &&
        !suppressErrorsPostSigning && (
          <div className="mt-16">
            <Body3 as="div" redMedium>
              Please enter contributions for the following benefits
            </Body3>
          </div>
        )}

      {contributionStatus === "Not Started" &&
        employeeClass.employeeClassPlans.length === 0 &&
        suppressErrorsPostSigning && (
          <div className="mt-16">
            <Body3 as="div">-</Body3>
          </div>
        )}

      {plansWithContributionValues.length > 0 && (
        <EIFClassBuilderContributions
          isActive={false}
          employeeClass={employeeClass}
          client={client}
          isLoading={false}
          changeSnapshot={changeSnapshot}
          authUser={authUser}
          featureToggles={featureToggles}
          selectedEmployeeClassPlans={plansWithContributionValues}
          readonly
        />
      )}

      {plansWithoutContributionValues.length > 0 && (
        <div className="mt-16">
          {!suppressErrorsPostSigning ? (
            <Body3 as="div" redMedium>
              Please enter contributions for the following benefits
            </Body3>
          ) : (
            <Body3 as="div">-</Body3>
          )}

          <div className="mt-12">
            <EIFClassBuilderBenefitList
              employeeClassPlans={plansWithoutContributionValues}
              spacing="default"
              variant="bold"
            />
          </div>
        </div>
      )}
    </div>
  );
}
