import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import { Badge } from "client/src/components/Badge/Badge";
import { Button } from "client/src/components/Button/Button";
import { ConfirmDialogDeleteExplorerPage } from "client/src/components/ConfirmDialog/ConfirmDialogDeleteExplorerPage";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { ColorPicker } from "client/src/components/Form/ColorPicker";
import { DateRange } from "client/src/components/Form/DateRange";
import { FormInput } from "client/src/components/Form/Input";
import { FormInputNumber } from "client/src/components/Form/InputNumber";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { Loading } from "client/src/components/Loading/Loading";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Spinner } from "client/src/components/Spinner/Spinner";
import { Tab, Tabs } from "client/src/components/Tabs/Tabs";
import { TagList } from "client/src/components/TagList/TagList";
import { Body3, H3 } from "client/src/components/Typography/Typography";
import { UnsavedChangesModal } from "client/src/domain/Client/UnsavedChangesModal";
import { AddMedicalVideo } from "client/src/domain/ExplorerPages/MedicalVideo/AddMedicalVideo";
import { MedicalVideo } from "client/src/domain/ExplorerPages/MedicalVideo/MedicalVideo";
import { useDeleteDocumentFromVidyard } from "client/src/hooks/document";
import { hasFormikError } from "client/src/utils/hasFormikError";
import { hasFormikErrors } from "client/src/utils/hasFormikErrors";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useState } from "react";
import { LocationStateData } from "shared/data/LocationState";
import { BenefitTypeBenExData } from "shared/types/BenefitTypes";
import { LocationStateCodes, Territories } from "shared/types/Location";
import { AddClientLogo } from "../ClientLogo/AddClientLogo";
import { ExplorerPageBenefitTable } from "../ExplorerPageBenefitTable";
import { ExplorerBenefitModal } from "../Modals/ExplorerBenefitModal/ExplorerBenefitModal";
import { urlForExplorerPage } from "../utils";

import * as styles from "./explorerPageForm.module.less";

import type { useExplorerPageFormState } from "./useExplorerPageFormState";
import type { FormikTypeFromValidator } from "client/src/hooks/useSlobFormik";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { explorerPageInputValidation } from "shared/validation/explorerPage";

export const DataTestId = {
  ExplorerPageForm: "explorer-page-form",
  CustomBrandingFormSection: "custom-branding-form-section",
};

export const PAGE_CONFIGURATION_TABS = [
  { tab: "benefits", label: "Benefits" },
  { tab: "highlights", label: "Highlights" },
  { tab: "videos", label: "Videos" },
] satisfies { tab: string; label: string }[];

export const ExplorerPageFormPresentation = (
  props: ReturnType<typeof useExplorerPageFormState>,
) => {
  const {
    formik,
    data: {
      explorerPageId,
      explorerPageVanitySlug,
      explorerPageName,
      client,
      benAdminPlatforms,
      carriers,
      planSummaryDocuments,
      spanishPlanSummaryDocuments,
      featureToggles,
      isRenewal,
      slobUsers,
      planSummaryDocumentsByBenefitType,
      spanishPlanSummaryDocumentsByBenefitType,
      pageCreationDate,
    },
    state: {
      isLoading,
      isSaving,
      isDeleting,
      updatingPage,
      editingBenefitIndex,
      showBenefitModal,
      showSpanishHighlights,
      showDeleteConfirmation,
      useExternalFormsLink,
      hasSpanishHighlightText,
      availableHighlights,
      disabled,
    },
    formActions: {
      onAddBenefit,
      onEditBenefit,
      setShowSpanishHighlights,
      onCancel,
      onDelete,
      onDeletePageConfirm,
      onDeleteCancel,
      onHighlightOrderChange,
    },
    benefitActions: { onSaveBenefit, onDeleteBenefit, onCancelBenefit },
  } = props;

  const [pageConfigurationTab, setPageConfigurationTab] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- defined as const at top of file
    PAGE_CONFIGURATION_TABS[0]!.tab,
  );

  const clearEnrollmentDates = useCallback(() => {
    void formik.setFieldValue("openEnrollmentStart", null);
    void formik.setFieldValue("openEnrollmentEnd", null);
  }, [formik]);

  const clearSiteAvailabilityDates = useCallback(() => {
    void formik.setFieldValue("startsAt", null);
    void formik.setFieldValue("expiresAt", null);
  }, [formik]);

  const { mutateAsync: deleteFromVidyard, isPending: deletingFromVidyard } =
    useDeleteDocumentFromVidyard();

  if (isLoading) {
    return <Loading />;
  }

  const editingBenefit =
    formik.values.benefits && editingBenefitIndex !== null
      ? formik.values.benefits[editingBenefitIndex]
      : null;

  const combinedPlanSummaryDocuments =
    planSummaryDocuments && spanishPlanSummaryDocuments
      ? [...planSummaryDocuments, ...spanishPlanSummaryDocuments]
      : [];

  const hdhpPlans = formik.values.benefits?.filter((benefit) =>
    benefit.contentFlags?.includes("HSA compatible"),
  );
  const defaultLinkedHDHPPlanName =
    hdhpPlans?.length === 1 && hdhpPlans[0] ? hdhpPlans[0].planName : null;

  const customBranding =
    Boolean(featureToggles?.BENEX_CUSTOM_BRANDING) && client.benefitsExplorerCustomBranding;

  const handleVidyardDelete = async () => {
    await deleteFromVidyard({
      params: {
        vidyardId: Number(formik.values.vidyardId),
        clientId: client.id,
      },
    });
    await formik.setFieldValue("medicalVideo", "NO_VIDEO");
    await formik.setFieldValue("medicalVideoDocumentId", undefined);
    await formik.setFieldValue("vidyardId", undefined);
    await formik.setFieldValue("vidyardUploadId", undefined);
  };

  const explorerPageSlug =
    !!formik.values.vanitySlug && formik.values.vanitySlug.length > 0
      ? formik.values.vanitySlug
      : null;

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={DataTestId.ExplorerPageForm}>
        <StackY dist={8} split={<Divider type="horizontal" />} className={clsx(styles.formWrapper)}>
          <Row>
            <Col span={11}>
              <StackY dist={24} style={{ width: "85%" }}>
                <FormInput
                  label="Site Name"
                  topText="Site Name"
                  value={formik.values.name || ""}
                  maxLength={191}
                  name="name"
                  touched={formik.touched.name}
                  showRequired={true}
                  onChange={formik.handleChange}
                  error={formik.errors.name}
                  disabled={formik.isSubmitting || disabled}
                />
                <DateRange
                  name="datesOfEnrollment"
                  topText="Enrollment Event Date Range"
                  disabled={formik.isSubmitting || disabled}
                  placeholder={["Start date*", "End date*"]}
                  errorValue={
                    hasFormikError(formik, "openEnrollmentStart") ||
                    hasFormikError(formik, "openEnrollmentEnd")
                      ? fieldErrorMsg("openEnrollmentStart", true, true)
                      : ""
                  }
                  value={[
                    formik.values.openEnrollmentStart
                      ? moment.utc(formik.values.openEnrollmentStart)
                      : null,
                    formik.values.openEnrollmentEnd
                      ? moment.utc(formik.values.openEnrollmentEnd)
                      : null,
                  ]}
                  onChange={(values) => {
                    if (values) {
                      const [startDateMoment, endDateMoment] = values;
                      if (startDateMoment && endDateMoment) {
                        const startDate = moment.utc({
                          year: startDateMoment.year(),
                          month: startDateMoment.month(),
                          date: startDateMoment.date(),
                        });
                        const endDate = moment.utc({
                          year: endDateMoment.year(),
                          month: endDateMoment.month(),
                          date: endDateMoment.date(),
                        });
                        void formik.setFieldValue("openEnrollmentStart", startDate.toDate());
                        void formik.setFieldValue("openEnrollmentEnd", endDate.toDate());
                      }
                    } else {
                      clearEnrollmentDates();
                    }
                  }}
                />
                <DateRange
                  name="datesOfSiteAvailability"
                  topText="Site Availability Date Range"
                  disabled={formik.isSubmitting || disabled}
                  placeholder={["Start date*", "End date*"]}
                  disabledDate={(date) => date.isBefore(moment().subtract(1, "day"))}
                  errorValue={
                    hasFormikError(formik, "startsAt") || hasFormikError(formik, "expiresAt")
                      ? fieldErrorMsg("expiresAt", true, true)
                      : ""
                  }
                  value={[
                    formik.values.startsAt ? moment.utc(formik.values.startsAt) : null,
                    formik.values.expiresAt ? moment.utc(formik.values.expiresAt) : null,
                  ]}
                  onChange={(values) => {
                    if (values) {
                      const [startAtMoment, expiresAtMoment] = values;
                      if (startAtMoment && expiresAtMoment) {
                        const startAtDate = moment.utc({
                          year: startAtMoment.year(),
                          month: startAtMoment.month(),
                          date: startAtMoment.date(),
                        });
                        const expiresAtDate = moment.utc({
                          year: expiresAtMoment.year(),
                          month: expiresAtMoment.month(),
                          date: expiresAtMoment.date(),
                        });
                        void formik.setFieldValue("startsAt", startAtDate.toDate());
                        void formik.setFieldValue("expiresAt", expiresAtDate.toDate());
                      }
                    } else {
                      clearSiteAvailabilityDates();
                    }
                  }}
                />
                <FormInput
                  name="planYear"
                  label="Plan Year"
                  topText="Plan Year"
                  value={formik.values.planYear ?? null}
                  maxLength={9}
                  showRequired={true}
                  touched={formik.touched.planYear}
                  aria-invalid={Boolean(formik.errors.planYear)}
                  aria-errormessage="planYear--errormessage"
                  disabled={formik.isSubmitting}
                  error={hasFormikError(formik, "planYear") ? formik.errors.planYear : undefined}
                  onChange={formik.handleChange}
                />
                <SlobSelect
                  name="employerState"
                  showRequired={true}
                  value={formik.values.employerState || null}
                  options={LocationStateCodes.map((stateCode) => ({
                    label: LocationStateData[stateCode].displayName,
                    value: stateCode,
                  }))}
                  label="Situs State"
                  placeholder="Select state"
                  onChange={(val) => {
                    void formik.setFieldValue("employerState", val.value);
                  }}
                  error={
                    hasFormikError(formik, "employerState")
                      ? fieldErrorMsg("employerState", true)
                      : undefined
                  }
                  touched={true}
                  fillWidth
                  disabled={disabled}
                />

                <SlobSelect
                  name="benAdminPlatform"
                  fillWidth
                  showRequired={false}
                  value={formik.values.benAdminPlatformId}
                  error={
                    hasFormikError(formik, "benAdminPlatformId")
                      ? fieldErrorMsg("benAdminPlatformId", true)
                      : undefined
                  }
                  options={benAdminPlatforms.map((platform) => ({
                    label: platform.name,
                    value: platform.id,
                  }))}
                  label="Benefits Administration Platform"
                  placeholder="Select platform"
                  onChange={(val) => {
                    void formik.setFieldValue("benAdminPlatformId", val?.value ?? null);
                    if (!val) {
                      void formik.setFieldValue("benAdminPlatformUrl", null);
                    }
                  }}
                  touched={true}
                  allowClear
                  disabled={disabled}
                />
                {formik.values.benAdminPlatformId && (
                  <FormInput
                    name="benAdminPlatformUrl"
                    label={
                      !useExternalFormsLink
                        ? "Benefits Administration Platform Url"
                        : "External Forms Site Url"
                    }
                    topText={
                      !useExternalFormsLink
                        ? "Benefits Administration Platform Url"
                        : "External Forms Site Url"
                    }
                    showRequired={!useExternalFormsLink ? true : false}
                    maxLength={191}
                    touched={formik.touched.benAdminPlatformUrl}
                    aria-describedby="benAdminPlatformUrl"
                    aria-invalid={Boolean(
                      formik.touched.benAdminPlatformId && formik.errors.benAdminPlatformUrl,
                    )}
                    aria-errormessage="benAdminPlatformUrl--errormessage"
                    disabled={formik.isSubmitting || disabled}
                    value={formik.values.benAdminPlatformUrl ?? ""}
                    onChange={formik.handleChange}
                    error={
                      hasFormikError(formik, "benAdminPlatformUrl")
                        ? fieldErrorMsg("benAdminPlatformUrl")
                        : undefined
                    }
                  />
                )}
                <FormInput
                  name="notificationLink"
                  label="Text Notifications URL"
                  topText="Text Notifications URL"
                  maxLength={191}
                  touched={formik.touched.benAdminPlatformUrl}
                  aria-describedby="notificationUrl"
                  aria-invalid={Boolean(formik.errors.notificationLink)}
                  aria-errormessage="notificationUrl--errormessage"
                  disabled={formik.isSubmitting || disabled}
                  value={formik.values.notificationLink ?? ""}
                  onChange={formik.handleChange}
                  error={
                    hasFormikError(formik, "notificationLink")
                      ? fieldErrorMsg("notificationLink")
                      : undefined
                  }
                />
                <FormInput
                  name="customCalendlyLink"
                  label="Custom Calendly Link"
                  topText="Custom Calendly Link"
                  maxLength={191}
                  touched={formik.touched.customCalendlyLink}
                  error={formik.errors.customCalendlyLink}
                  aria-describedby="customCalendlyLink"
                  aria-invalid={Boolean(formik.errors.customCalendlyLink)}
                  aria-errormessage="customCalendlyLink--errormessage"
                  disabled={formik.isSubmitting || disabled}
                  value={formik.values.customCalendlyLink ?? ""}
                  onChange={formik.handleChange}
                />
                <FormInput
                  name="customClientName"
                  label="Custom Client Name"
                  topText="Custom Client Name"
                  maxLength={191}
                  touched={formik.touched.customClientName}
                  error={formik.errors.customClientName}
                  aria-describedby="customClientName"
                  aria-invalid={Boolean(formik.errors.customClientName)}
                  aria-errormessage="customClientName--errormessage"
                  disabled={formik.isSubmitting || disabled}
                  value={formik.values.customClientName ?? ""}
                  onChange={formik.handleChange}
                />

                <FormInputNumber
                  label="Employee Count"
                  topText="Employee Count"
                  value={formik.values.employeeCount ?? undefined}
                  maxLength={6}
                  min={1}
                  name="employeeCount"
                  touched={formik.touched.employeeCount}
                  onChange={async (val) => await formik.setFieldValue("employeeCount", val || null)}
                  disabled={formik.isSubmitting || disabled}
                  precision={0}
                  error={
                    hasFormikError(formik, "employeeCount")
                      ? fieldErrorMsg("employeeCount")
                      : undefined
                  }
                />
                <Checkbox
                  label="This client has Employees that live in NY"
                  name="hasEmployeesInNY"
                  checked={formik.values.hasEmployeesInNY}
                  onChange={formik.handleChange}
                  disabled={disabled}
                />
                <Checkbox
                  label="Show benefit count"
                  name="showBenefitCount"
                  checked={formik.values.showBenefitCount}
                  onChange={formik.handleChange}
                  disabled={disabled}
                />
                {featureToggles?.BENEX_VISION_PLAN_COMPARISON && (
                  <Checkbox
                    label="Show Generic Vision Plan Comparison Table"
                    name="showLegacyVisionPlanTable"
                    checked={formik.values.showLegacyVisionPlanTable}
                    onChange={formik.handleChange}
                    disabled={disabled}
                  />
                )}
                {featureToggles?.BENEX_DENTAL_PLAN_COMPARISON && (
                  <Checkbox
                    label="Show Generic Dental Plan Comparison Table"
                    name="showLegacyDentalPlanTable"
                    checked={formik.values.showLegacyDentalPlanTable}
                    onChange={formik.handleChange}
                    disabled={disabled}
                  />
                )}
              </StackY>
            </Col>
            <Col span={2}>
              <Row justify="center" style={{ height: "100%" }}>
                <Col>
                  <Divider type="vertical" style={{ height: "100%" }} />
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <StackY dist={24}>
                <AddClientLogo
                  clientId={client.id}
                  logoDocumentId={formik.values.logoDocumentId ?? null}
                  dragEnabled={!showBenefitModal}
                  disabled={disabled}
                  onChange={(docId) => void formik.setFieldValue("logoDocumentId", docId)}
                />
                <Checkbox
                  checked={formik.values.hideSunLifeLogo}
                  onChange={formik.handleChange}
                  label="Hide Sun Life logo in header"
                  name="hideSunLifeLogo"
                  disabled={disabled}
                />
                {customBranding && (
                  <>
                    <Checkbox
                      checked={formik.values.customBranding}
                      onChange={formik.handleChange}
                      label="Apply custom branding to this site"
                      name="customBranding"
                      disabled={disabled}
                    />
                    {formik.values.customBranding && (
                      <ExplorerPageCustomBrandingForm formik={formik} disabled={disabled} />
                    )}
                  </>
                )}
              </StackY>
            </Col>
          </Row>
          <StackY dist={16}>
            {featureToggles?.BENEX_CONFIG_TABS && (
              <Tabs
                defaultTab={pageConfigurationTab}
                onChange={(key) => setPageConfigurationTab(key)}
              >
                {PAGE_CONFIGURATION_TABS.filter(({ tab }) => {
                  if (tab === "videos") {
                    return formik.values.benefits?.find((ben) => ben.benefitType === "MEDICAL");
                  }
                  return true;
                }).map(({ tab, label }) => {
                  let error = false;
                  switch (tab) {
                    case "benefits":
                      error = hasFormikError(formik, "benefits");
                      break;
                    case "videos":
                      error =
                        hasFormikError(formik, "medicalVideo") ||
                        hasFormikError(formik, "medicalVideoDocumentId") ||
                        hasFormikError(formik, "medicalVideoEnglishTitle") ||
                        hasFormikError(formik, "medicalVideoSpanishTitle");
                      break;
                  }
                  return <Tab key={tab} tab={tab} label={label} error={error} />;
                })}
              </Tabs>
            )}

            {/* Benefits */}
            <Row
              hidden={!!featureToggles?.BENEX_CONFIG_TABS && pageConfigurationTab !== "benefits"}
            >
              <Col span={24}>
                <Row
                  justify={featureToggles?.BENEX_CONFIG_TABS ? "end" : "space-between"}
                  style={{ paddingBottom: "14px" }}
                >
                  {!featureToggles?.BENEX_CONFIG_TABS && (
                    <Col>
                      <H3>Explorer Benefits</H3>
                    </Col>
                  )}
                  {/* Line up the Add Benefit button with the Edit buttons in the table, otherwise it looks odd */}
                  <Col style={{ paddingRight: "14px" }}>
                    <Button onClick={onAddBenefit} disabled={disabled}>
                      Add Benefit
                    </Button>
                  </Col>
                </Row>
                <ExplorerPageBenefitTable
                  benefits={
                    formik.values.benefits?.map((b, i) => ({
                      ...b,
                      hasError: !!formik.errors.benefits?.[i],
                    })) ?? []
                  }
                  carriers={carriers}
                  planSummaryDocuments={combinedPlanSummaryDocuments}
                  disabled={disabled}
                  handleFormChange={formik.handleChange}
                  handleBenefitEdit={onEditBenefit}
                  featureToggles={featureToggles}
                />
              </Col>
            </Row>

            {/* Highlights */}
            <StackY
              dist={24}
              hidden={featureToggles?.BENEX_CONFIG_TABS && pageConfigurationTab !== "highlights"}
              style={{ width: "100%" }}
            >
              {availableHighlights && featureToggles?.BENEFIT_EXPLORER_SPANISH && (
                <div>
                  {!featureToggles.BENEX_CONFIG_TABS && <H3>Benefit Highlights</H3>}
                  <RadioGroup
                    name="spanishHighlights"
                    disabled={isSaving || disabled}
                    value={showSpanishHighlights}
                    onChange={(v) => {
                      setShowSpanishHighlights(v.target.value);
                    }}
                    options={[
                      { label: "English only Highlights", value: false },
                      { label: "English and Spanish Highlights", value: true },
                    ]}
                    error={false}
                    direction="vertical"
                    label={null}
                    aria-label="Language highlights"
                  />
                  {!showSpanishHighlights && hasSpanishHighlightText && (
                    <Badge
                      srOnlyLabel="spanishTextWarning"
                      variant="warning"
                      style={{ padding: "8px" }}
                      className="mt-16"
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={styles.locationModalIcon}
                        style={{ marginTop: "0" }}
                      />
                      <Body3>
                        The Spanish highlights you previously entered will be deleted when you save
                        your changes below. If you want to keep your Spanish highlights, switch back
                        to "English and Spanish Highlights".
                      </Body3>
                    </Badge>
                  )}
                </div>
              )}
              {availableHighlights?.map((benefitType, benefitIndex) => (
                <Row
                  key={`benefitTypeMetadata.${benefitType}`}
                  style={{ width: "100%" }}
                  align="top"
                  gutter={24}
                >
                  <Col flex={1}>
                    <FormInput
                      label={showSpanishHighlights ? "English Highlight Text" : "Highlight Text"}
                      topText={`${BenefitTypeBenExData[benefitType].displayName} Highlight`}
                      value={
                        formik.values.benefitTypeMetadata
                          ? formik.values.benefitTypeMetadata[benefitType]?.highlighterText ?? ""
                          : ""
                      }
                      name={`benefitTypeMetadata.${benefitType}.highlighterText`}
                      maxLength={1000}
                      touched={formik.touched.benefitTypeMetadata}
                      onChange={(e) => {
                        void formik.setFieldValue(
                          `benefitTypeMetadata.${benefitType}.highlighterText`,
                          e.target.value || undefined,
                        );
                      }}
                      error={
                        hasFormikError(formik, "benefitTypeMetadata")
                          ? fieldErrorMsg("benefitTypeMetadata")
                          : undefined
                      }
                      disabled={formik.isSubmitting || disabled}
                    />
                    {showSpanishHighlights && (
                      <div className="pt-16">
                        <FormInput
                          label="Spanish Highlight Text"
                          value={
                            formik.values.benefitTypeMetadata
                              ? formik.values.benefitTypeMetadata[benefitType]
                                  ?.spanishHighlighterText ?? ""
                              : ""
                          }
                          name={`benefitTypeMetadata.${benefitType}.spanishHighlighterText`}
                          maxLength={1000}
                          touched={formik.touched.benefitTypeMetadata}
                          onChange={(e) => {
                            void formik.setFieldValue(
                              `benefitTypeMetadata.${benefitType}.spanishHighlighterText`,
                              e.target.value || undefined,
                            );
                          }}
                          error={
                            hasFormikError(formik, "benefitTypeMetadata")
                              ? fieldErrorMsg("benefitTypeMetadata")
                              : undefined
                          }
                          disabled={formik.isSubmitting || disabled}
                        />
                      </div>
                    )}
                  </Col>
                  <Col span={3} style={{ paddingTop: "43px" }}>
                    <SlobSelect
                      name={`${benefitType}-Order`}
                      placeholder="Order"
                      disabled={disabled}
                      options={availableHighlights.map((_, i) => ({
                        label: (i + 1).toString(),
                        value: i + 1,
                      }))}
                      value={
                        formik.values.benefitTypeMetadata?.[benefitType]?.highlighterOrder ??
                        benefitIndex + 1
                      }
                      onChange={async (val) => {
                        const existingValue =
                          formik.values.benefitTypeMetadata?.[benefitType]?.highlighterOrder ??
                          benefitIndex + 1;
                        // why aren't we using formik.setFieldValue here?
                        formik.values.benefitTypeMetadata = {
                          ...formik.values.benefitTypeMetadata,
                        };
                        formik.values.benefitTypeMetadata[benefitType] = {
                          ...formik.values.benefitTypeMetadata[benefitType],
                          highlighterOrder: val.value + (val.value < existingValue ? -0.1 : 0.1),
                        };
                        await onHighlightOrderChange();
                      }}
                      touched={undefined}
                      error={undefined}
                    />
                  </Col>
                </Row>
              ))}
            </StackY>

            {/* Videos */}
            {formik.values.benefits?.find((ben) => ben.benefitType === "MEDICAL") && (
              <StackY
                dist={16}
                hidden={featureToggles?.BENEX_CONFIG_TABS && pageConfigurationTab !== "videos"}
              >
                <RadioGroup
                  name="medicalVideo"
                  label={!featureToggles?.BENEX_CONFIG_TABS && "Custom Medical Video"}
                  disabled={formik.isSubmitting}
                  error={undefined}
                  options={[
                    {
                      value: "NO_VIDEO",
                      label: (
                        <StackX dist={4} style={{ display: "flex", alignItems: "center" }}>
                          <Body3>No Medical Video</Body3>{" "}
                          {deletingFromVidyard && (
                            <div style={{ position: "relative", top: "3px" }}>
                              <Spinner size="xxsmall" />
                            </div>
                          )}
                        </StackX>
                      ),
                    },
                    { value: "ENGLISH_VIDEO", label: "English Medical Video" },
                  ]}
                  direction="vertical"
                  value={formik.values.medicalVideo}
                  onChange={async (e) => {
                    formik.handleChange(e);
                    if (
                      e.target.value === "NO_VIDEO" &&
                      formik.values.medicalVideoDocumentId != null
                    ) {
                      try {
                        await handleVidyardDelete();
                      } catch {
                        // Switch back to ENGLISH_VIDEO if vidyard delete fails
                        void formik.setFieldValue("medicalVideo", "ENGLISH_VIDEO");
                      }
                    }
                  }}
                />
                {formik.values.medicalVideo === "ENGLISH_VIDEO" && (
                  <Row>
                    <Col span={12}>
                      {formik.values.medicalVideoDocumentId && !isSaving ? (
                        <MedicalVideo
                          docId={formik.values.medicalVideoDocumentId}
                          vidyardId={formik.values.vidyardUploadId ?? null}
                          clientId={client.id}
                          videoTitle={formik.values.medicalVideoEnglishTitle ?? null}
                          onChange={(vidyardUuid, vidyardId) => {
                            void formik.setFieldValue("vidyardUploadId", vidyardUuid);
                            void formik.setFieldValue("vidyardId", vidyardId);
                          }}
                          onDelete={() => handleVidyardDelete()}
                        />
                      ) : (
                        <AddMedicalVideo
                          clientId={client.id}
                          dragEnabled={!showBenefitModal}
                          disabled={disabled}
                          uploadButtonDisabled={
                            formik.values.medicalVideoEnglishTitle == null ||
                            formik.values.medicalVideoEnglishTitle.length === 0
                          }
                          onChange={(docId) =>
                            void formik.setFieldValue("medicalVideoDocumentId", docId)
                          }
                        />
                      )}
                      {hasFormikError(formik, "medicalVideoDocumentId") && (
                        <ErrorMessage>{fieldErrorMsg("medicalVideoDocumentId", true)}</ErrorMessage>
                      )}
                    </Col>
                    <Col span={10} offset={2}>
                      <StackY dist={16}>
                        <FormInput
                          name="medicalVideoEnglishTitle"
                          label="Video Title"
                          topText="English Title"
                          maxLength={191}
                          touched={formik.touched.medicalVideoEnglishTitle}
                          aria-invalid={Boolean(formik.errors.medicalVideoEnglishTitle)}
                          disabled={formik.isSubmitting}
                          value={formik.values.medicalVideoEnglishTitle ?? ""}
                          onChange={formik.handleChange}
                          error={
                            hasFormikError(formik, "medicalVideoEnglishTitle")
                              ? fieldErrorMsg("medicalVideoEnglishTitle")
                              : undefined
                          }
                        />
                      </StackY>
                    </Col>
                  </Row>
                )}
              </StackY>
            )}
          </StackY>

          <StackY dist={16} style={{ width: "55%" }}>
            <SlobSelect
              name="pageOwner"
              fillWidth
              showRequired={false}
              value={formik.values.pageOwner}
              error={
                hasFormikError(formik, "pageOwner") ? fieldErrorMsg("pageOwner", true) : undefined
              }
              options={slobUsers.map((user) => ({
                label: user.name,
                value: user.id,
              }))}
              label="Page Owner"
              onChange={(val) => {
                void formik.setFieldValue("pageOwner", val?.value ?? null);
                if (!val) {
                  void formik.setFieldValue("pageOwner", null);
                }
              }}
              touched={true}
              allowClear
              disabled={formik.isSubmitting}
              placeholder={"Page Owner"}
            />
            <TagList
              name="policies"
              placeholder="Add a policy number and press enter"
              value={formik.values.policies ?? []}
              onChange={formik.handleChange}
              touched={formik.touched.policies}
              error={formik.errors.policies}
              disabled={formik.isSubmitting}
              topText="Policy Number(s)"
            />
            <SlobSelect
              name="territory"
              value={formik.values.territory || null}
              options={Territories.map((terr) => ({
                label: terr,
                value: terr,
              }))}
              label="Territory"
              placeholder="Select territory"
              onChange={(val) => {
                void formik.setFieldValue("territory", val.value);
              }}
              error={
                hasFormikError(formik, "territory") ? fieldErrorMsg("territory", true) : undefined
              }
              touched={true}
              fillWidth
              disabled={disabled}
            />
            <Checkbox
              label="Maintenance Mode"
              name="maintenanceMode"
              checked={formik.values.maintenanceMode}
              onChange={formik.handleChange}
            />
          </StackY>

          {featureToggles?.BENEX_VANITY_URL && (
            <StackY dist={16} style={{ width: "55%" }}>
              <FormInput
                label="Vanity URL"
                topText="Vanity URL"
                value={formik.values.vanitySlug || ""}
                maxLength={191}
                name="vanitySlug"
                touched={formik.touched.vanitySlug}
                onChange={formik.handleChange}
                error={formik.errors.vanitySlug}
                disabled={formik.isSubmitting || disabled}
              />
            </StackY>
          )}

          {explorerPageId && (
            <Row align="bottom" gutter={30}>
              <Col flex={1}>
                <FormInput
                  label="Site URL"
                  topText="Site URL"
                  value={urlForExplorerPage(explorerPageSlug ?? explorerPageId, !!explorerPageSlug)}
                  maxLength={1000}
                  name="siteURL"
                  disabled
                  touched={undefined}
                  error={undefined}
                />
              </Col>
              <Col>
                <div className="mb-8">
                  <Button
                    onClick={async () => {
                      if ("clipboard" in navigator) {
                        return await navigator.clipboard.writeText(
                          urlForExplorerPage(explorerPageVanitySlug ?? explorerPageId),
                        );
                      } else {
                        return document.execCommand(
                          "copy",
                          true,
                          urlForExplorerPage(explorerPageVanitySlug ?? explorerPageId),
                        );
                      }
                    }}
                  >
                    Copy URL
                  </Button>
                </div>
              </Col>
            </Row>
          )}

          <StackY dist={16} style={{ width: "100%" }}>
            {statusMessage(formik, updatingPage) && (
              <Body3 redMedium>{statusMessage(formik, updatingPage)}</Body3>
            )}
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col>
                <StackX dist={16}>
                  <Button
                    type="primary"
                    size="middle"
                    htmlType="submit"
                    loading={isSaving}
                    disabled={isDeleting}
                  >
                    {updatingPage && !isRenewal ? "Update Site" : "Create Site"}
                  </Button>
                  <Button size="middle" disabled={isSaving || isDeleting} onClick={onCancel}>
                    Cancel
                  </Button>
                </StackX>
              </Col>
              {updatingPage && !isRenewal && (
                <Col>
                  <Button
                    type="danger"
                    size="middle"
                    loading={isDeleting}
                    disabled={isSaving || disabled}
                    onClick={onDelete}
                  >
                    Delete Site
                  </Button>
                </Col>
              )}
            </Row>
          </StackY>
        </StackY>
      </form>
      <ExplorerBenefitModal
        clientId={client.id}
        visible={showBenefitModal}
        planSummaryDocuments={planSummaryDocuments}
        spanishPlanSummaryDocuments={spanishPlanSummaryDocuments}
        onSave={onSaveBenefit}
        onDelete={onDeleteBenefit}
        onCancel={onCancelBenefit}
        featureToggle={featureToggles}
        carriers={carriers}
        benefit={editingBenefit}
        employerState={formik.values.employerState || null}
        defaultLinkedHDHPPlanName={defaultLinkedHDHPPlanName}
        planSummaryDocumentsByBenefitType={planSummaryDocumentsByBenefitType}
        spanishPlanSummaryDocumentsByBenefitType={spanishPlanSummaryDocumentsByBenefitType}
        pageCreationDate={pageCreationDate}
        isRenewal={isRenewal}
        hideNewVisionPlanFields={formik.values.showLegacyVisionPlanTable}
        hideNewDentalPlanFields={formik.values.showLegacyDentalPlanTable}
      />

      <ConfirmDialogDeleteExplorerPage
        explorerPageName={explorerPageName ?? ""} // The name will always be present when deleting.
        isVisible={showDeleteConfirmation}
        handleDelete={onDeletePageConfirm}
        onCancel={onDeleteCancel}
      />

      <UnsavedChangesModal form={formik} />
    </>
  );
};

type FieldName = Exclude<
  // TODO: Check why this is returning `undefined`.
  keyof ValuesForValidationSchema<typeof explorerPageInputValidation>,
  undefined
>;

const errorNameForField: Partial<Record<FieldName, string>> = {
  name: "site name",
  kioskMode: "kiosk mode",
  openEnrollmentEnd: "enrollment dates",
  openEnrollmentStart: "enrollment dates",
  startsAt: "start date",
  expiresAt: "site availability dates",
  planYear: "plan year",
  benefits: "benefits",
  maintenanceMode: "maintenance mode",
  benefitTypeMetadata: "highlight",
  employerState: "situs state",
  benAdminPlatformId: "platform",
  benAdminPlatformUrl: "platform url",
  notificationLink: "notification url",
  employeeCount: "valid employee count",
  hasEmployeesInNY: "employees live in NY",
  showBenefitCount: "show benefit count",
  hideSunLifeLogo: "hide sun life logo",
  pageOwner: "page owner",
  policies: "policy number(s)",
  medicalVideo: "medical video",
  medicalVideoEnglishTitle: "english title",
  medicalVideoSpanishTitle: "spanish title",
  medicalVideoDocumentId: "medical video",
  customThemeBrandColor: "primary brand color",
  customThemeContrastingColor: "contrasting brand color",
};

const fieldErrorMsg = (fieldName: FieldName, isSelector = false, plural = false) =>
  `Please ${isSelector ? "select" : "enter"}${!plural ? " a " : " "}${
    errorNameForField[fieldName]
  }.`;

const statusMessage = (
  formik: FormikTypeFromValidator<typeof explorerPageInputValidation>,
  updating = false,
) => {
  const errorKeys = hasFormikErrors(formik);
  if (errorKeys.length > 1) {
    return updating
      ? "Please correct all errors highlighted in red above before updating the site."
      : "Please correct all errors highlighted in red above before creating the site.";
  }

  const field = errorKeys[0];
  if (errorKeys.length === 1 && field) {
    return updating
      ? `Please complete the ${errorNameForField[field]} field highlighted in red above before updating the site.`
      : `Please complete the ${errorNameForField[field]} field highlighted in red above before creating the site.`;
  }
  return null;
};

/**
 * Custom branding form for the Benefits Explorer Page form.
 */
function ExplorerPageCustomBrandingForm({
  formik,
  disabled,
}: {
  formik: ReturnType<typeof useExplorerPageFormState>["formik"];
  disabled: boolean;
}) {
  return (
    <StackY dist={16} data-testid={DataTestId.CustomBrandingFormSection}>
      <ColorPicker
        name="customThemeBrandColor"
        topText="Primary brand color (Hex code)"
        label="Primary brand color"
        value={formik.values.customThemeBrandColor ?? ""}
        touched={formik.touched.customThemeBrandColor}
        error={formik.errors.customThemeBrandColor}
        disabled={formik.isSubmitting || disabled}
        onChange={formik.handleChange}
      />
      <ColorPicker
        name="customThemeContrastingColor"
        topText="Contrasting brand color (Hex code)"
        label="Contrasting brand color"
        value={formik.values.customThemeContrastingColor ?? ""}
        touched={formik.touched.customThemeContrastingColor}
        error={formik.errors.customThemeContrastingColor}
        disabled={formik.isSubmitting || disabled}
        onChange={formik.handleChange}
      />
    </StackY>
  );
}
