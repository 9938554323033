import { useState } from "react";

import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Body3 } from "../../components/Typography/Typography";
import { useTrackAllClicks } from "../../utils/analytics";
import { useHideZendeskWidget } from "../../utils/zendesk";
import { LoginWindow } from "./LoginWindow";

import * as styles from "./login.module.less";

type LoginPageProps = {
  email?: string;
  initialLogin?: boolean;
};

export const LoginPage = ({ email, initialLogin = false }: LoginPageProps) => {
  const [showBanner] = useState(initialLogin);
  const passiveTracker = useTrackAllClicks();
  useHideZendeskWidget();

  return (
    <div className={styles.authBackground} ref={passiveTracker}>
      {initialLogin && showBanner && (
        <div className={styles.banner}>
          <AlertBanner
            variant="success"
            message={<Body3>Your account is activated! Please sign in to continue.</Body3>}
          />
        </div>
      )}
      <LoginWindow emailToPopulate={email} />
    </div>
  );
};

// eslint-disable-next-line import/no-default-export -- default required for code splitting with React.lazy
export default LoginPage;
