import { useMemo } from "react";

import { getBenefitClips, getBasicEnrollmentFlyers } from "shared/data/Client";
import { getCoverageOutlineLinks } from "shared/data/CoverageOutline";
import type { Client } from "shared/types/Client";
import type { Document } from "shared/types/Document";
import type { DocumentBenefitType } from "shared/types/DocumentBenefitType";
import type { SlfCoverageLink } from "shared/types/Ticket";
import type { WebLink } from "shared/types/WebLink";

type AmountPerBenefitTypes = Partial<Record<DocumentBenefitType | "none-benefit-type", number>>;

export function useAmountOfItemsPerBenefitType(
  documents: Document[] | undefined,
  webLinks: WebLink[] | undefined,
  beneficiaryDesignationWebLinks: SlfCoverageLink[] | undefined,
  client: Client | undefined,
) {
  const amountOfItemsPerBenefitType = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
    const itemsWithBenefitTypes = ([] as { benefitTypes: DocumentBenefitType[] | null }[])
      .concat(documents ?? [])
      .concat(webLinks ?? [])
      .concat(beneficiaryDesignationWebLinks ?? [])
      .concat(client ? getBenefitClips(client) : [])
      .concat(client ? getBasicEnrollmentFlyers(client) : [])
      .concat(client ? getCoverageOutlineLinks(client) : []);

    const amountOfItemsPerBenefitType = itemsWithBenefitTypes.reduce<AmountPerBenefitTypes>(
      (prev, current) => {
        if (!current.benefitTypes?.length) {
          prev["none-benefit-type"] = (prev["none-benefit-type"] || 0) + 1;
        }

        if (current.benefitTypes) {
          for (const benefitType of current.benefitTypes) {
            prev[benefitType] = (prev[benefitType] || 0) + 1;
          }
        }

        return prev;
      },
      {},
    );

    return amountOfItemsPerBenefitType;
  }, [documents, webLinks, beneficiaryDesignationWebLinks, client]);

  return amountOfItemsPerBenefitType;
}
