import { Body3 } from "client/src/components/Typography/Typography";
import type { CensusValidationsFailure } from "shared/types/Clover";

export type ValidationFailureData = {
  type?: "warning" | "error";
  content?: React.ReactNode;
};
const unmatchedColumns = (
  <>
    The file you uploaded doesn't have the column headers expected in a Sun Life provided custom
    template file.
    <br />
    <br />
    As a result, we aren't able to validate the file's values.
  </>
);
export const validationError: Record<CensusValidationsFailure, ValidationFailureData> = {
  PASSWORD_PROTECTED: {
    type: "warning",
    content: (
      <>
        This file appears to be password protected so we’re not able to validate your data. Please
        remove the password and use Onboard to securely upload your revised file.
        <br />
        <br />
        If you choose to submit this version, you’ll need to provide your Implementation Consultant
        with the password to review your file.
      </>
    ),
  },
  NO_MEMBER_DATA_SHEET: {
    type: "warning",
    content: (
      <>
        This file doesn’t include a spreadsheet tab named “Member Data” so we’re not able to
        validate it.
        <br />
        <br />
        Please rename the sheet in your file that has your census and elections to “Member Data”.
        Then upload your revised file.
      </>
    ),
  },
  NO_EMPLOYEE_DATA: {
    type: "warning",
    content: (
      <>
        This file doesn’t have any data for us to review.
        <br />
        <br />
        Please review the file you uploaded to make sure it included completed census and election
        information. Then upload your revised file.
      </>
    ),
  },
  UNMATCHED_COLUMNS_ALL: {
    type: "warning",
    content: unmatchedColumns,
  },
  UNMATCHED_COLUMNS_SOME: {
    type: "warning",
    content: unmatchedColumns,
  },
  UNKNOWN: {
    type: "warning",
    content: (
      <Body3 as="div">
        The file you added doesn’t seem to be in the format of a Sun Life provided custom template
        file. As a result, we weren’t able to run the real-time validation tool on it.
        <br />
        <br />
        We will review your file and let you know if we need additional information from you.
      </Body3>
    ),
  },
};
