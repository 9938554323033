import type { ChangeEvent } from "react";

export const handleMaskedInputChange = (
  maskingFn: (value: string) => string,
  setFieldValue: (maskedTaskId: string) => Promise<unknown>,
) => {
  return async (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const input = e.currentTarget;
    const selectionStart = input.selectionStart || 0;
    const maskedValue = maskingFn(value);
    await setFieldValue(maskedValue);

    const delta = maskedValue.length - value.length;
    const start = selectionStart + delta;
    const end = start;
    input.setSelectionRange(start, end);
  };
};

export const handleTaxIdChange = (setFieldValue: (maskedTaskId: string) => Promise<unknown>) => {
  return handleMaskedInputChange(maskTaxId, setFieldValue);
};

export const handleMaskedMonthDayChange = (
  setFieldValue: (maskedTaskId: string) => Promise<unknown>,
) => {
  return handleMaskedInputChange(maskMonthDay, setFieldValue);
};

const maskTaxId = (taxId: string) => {
  let maskedTaxId = taxId
    .split("")
    .filter((char, index) => (index === 2 && char === "-") || /[0-9]/.test(char))
    .join("");

  if (maskedTaxId.length > 2 && maskedTaxId[2] !== "-") {
    maskedTaxId = maskedTaxId.slice(0, 2) + "-" + maskedTaxId.slice(2);
  }

  maskedTaxId = maskedTaxId.slice(0, 10);

  return maskedTaxId;
};

const maskMonthDay = (monthDay: string) => {
  let maskedMonthDay = monthDay
    .split("")
    .filter((char, index) => ((index === 1 || index === 2) && char === "/") || /[0-9]/.test(char))
    .join("");

  if (maskedMonthDay.length > 2 && !maskedMonthDay.includes("/")) {
    maskedMonthDay = maskedMonthDay.slice(0, 2) + "/" + maskedMonthDay.slice(2);
  }

  maskedMonthDay = maskedMonthDay.slice(0, 5);

  return maskedMonthDay;
};
