import { EIFClassBuilderCreator } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import { EIFClassBuilderModeSetup } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderModeSetup";
import { EIFPlanConfigAndEligibilityReview } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFPlanConfigAndEligibilityReview";
import { useGetEIFPreviousAndNextLink } from "client/src/hooks/useGetEIFPreviousAndNextLink";
import { useToggler } from "client/src/hooks/useToggler";

import { Navigate, useMatch } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { getEIFSubStepViewMode } from "shared/utils/client";
import type { CreateClassQuery, UpdateClassQuery } from "../../../../hooks/employeeClass";
import type {
  SetContributionQuery,
  SetBenefitTypeAvailablePlansQuery,
  SetWaitingPeriodsQuery,
} from "../../../../hooks/employeeClassPlan";
import type { TrackElementClickedFunc } from "../../../../utils/analytics";
import type { AdditionalCompensation } from "@prisma/client";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Document } from "shared/types/Document";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  clientPlans: Plan[];
  createClassQuery: CreateClassQuery;
  updateClassQuery: UpdateClassQuery;
  classes: EmployeeClass[];
  employeeClass: EmployeeClass | undefined;
  additionalCompensations: AdditionalCompensation[];
  setBenefitTypesAvailablePlansQuery: SetBenefitTypeAvailablePlansQuery;
  setWaitingPeriodsQuery: SetWaitingPeriodsQuery;
  setContributionsQuery: SetContributionQuery;
  trackElementClicked: TrackElementClickedFunc;
  featureToggles: ClientFeatureToggles;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData;
  updateClient: UpdateClientFunc;
  documents: Document[];
  employeeClassDocumentCount: number;
};

export function EIFClassBuilder(props: Props) {
  const {
    client,
    clientPlans,
    createClassQuery,
    updateClassQuery,
    classes,
    employeeClass,
    additionalCompensations,
    setBenefitTypesAvailablePlansQuery,
    setWaitingPeriodsQuery,
    setContributionsQuery,
    trackElementClicked,
    featureToggles,
    changeSnapshot,
    authUser,
    updateClient,
    documents,
    employeeClassDocumentCount,
  } = props;

  const [isSubmittingIntro, toggleIsSubmittingIntro] = useToggler();

  const showIntroductionContent = classes.length === 0 || isSubmittingIntro;
  const isEditing = useMatch(RouteData.eifClassBuilderEdit.getPathTemplate());

  const viewMode = getEIFSubStepViewMode({ client });

  const { nextSubStepLink } = useGetEIFPreviousAndNextLink();
  const needsClassbuilder = getIsSubStepApplicable({
    eifSubStepId: "class-builder",
    client,
    plans: clientPlans,
  });
  if (!needsClassbuilder) {
    return <Navigate to={nextSubStepLink} replace />;
  }

  if (!isEditing && !showIntroductionContent) {
    return (
      <EIFPlanConfigAndEligibilityReview
        client={client}
        authUser={authUser}
        classes={classes}
        clientPlans={clientPlans}
        trackElementClicked={trackElementClicked}
        viewMode={viewMode}
        changeSnapshot={changeSnapshot}
        featureToggles={featureToggles}
        updateClient={updateClient}
        steps="class-builder-only"
      />
    );
  }

  if (!isEditing && showIntroductionContent) {
    return (
      <EIFClassBuilderModeSetup
        client={client}
        userRole={authUser.role}
        updateClient={updateClient}
        clientPlans={clientPlans}
        documents={documents}
        createClassQuery={createClassQuery}
        trackElementClicked={trackElementClicked}
        toggleIsSubmittingIntro={toggleIsSubmittingIntro}
        employeeClasses={classes}
        employeeClassDocumentCount={employeeClassDocumentCount}
        featureToggles={featureToggles}
        changeSnapshot={changeSnapshot}
      />
    );
  }

  return (
    <EIFClassBuilderCreator
      client={client}
      clientPlans={clientPlans}
      createClassQuery={createClassQuery}
      updateClassQuery={updateClassQuery}
      classes={classes}
      employeeClass={employeeClass}
      additionalCompensations={additionalCompensations}
      setBenefitTypesAvailablePlansQuery={setBenefitTypesAvailablePlansQuery}
      setWaitingPeriodsQuery={setWaitingPeriodsQuery}
      setContributionsQuery={setContributionsQuery}
      trackElementClicked={trackElementClicked}
      employeeClassDocumentCount={employeeClassDocumentCount}
      featureToggles={featureToggles}
      changeSnapshot={changeSnapshot}
      authUser={authUser}
    />
  );
}
