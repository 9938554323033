import { URLSearchParams } from "url";
const paramsToSegment = {
  sgmButtonLabel: "buttonLabel",
  sgmModule: "module",
  sgmClientId: "clientID",
  sgmClientName: "clientName",
  sgmJiraTicket: "jiraTicket",
  sgmEBREmailAddress: "ebrEmailAddress",
} as const;
type Keys = keyof typeof paramsToSegment;
type Values = (typeof paramsToSegment)[Keys];

export const createExternalTrackQuery = (data: Partial<Record<Values, string>>): string => {
  const result = new URLSearchParams();
  Object.entries(paramsToSegment).forEach(([key, val]) => {
    if (data[val]) {
      result.set(key, String(data[val]));
    }
  });

  return result.toString();
};

export const extractSearchParams = (url: URL) => {
  const newUrl = new URL(url);
  const result: Partial<Record<Values, string>> = {};
  let key: Keys;
  for (key in paramsToSegment) {
    const value = url.searchParams.get(key);
    if (value) {
      result[paramsToSegment[key]] = decodeURIComponent(value);
      newUrl.searchParams.delete(key);
    }
  }
  const searchParamsFound = url.search !== newUrl.search;
  const params = searchParamsFound ? result : null;
  return { newUrl, params };
};
