import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { TrashIcon } from "client/src/components/Icons/TrashIcon";
import { SlobDrawer } from "client/src/components/SlobDrawer/SlobDrawer";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import * as setupCardStyles from "client/src/pages/Clients/SetupCards/setupCards.module.less";
import { useState } from "react";
import { dhmoBenefitTypes } from "shared/types/BenefitTypes";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import type { DHMOBenefitType } from "shared/types/BenefitTypes";

type Props = {
  formikSelectedStates: DHMOBenefitType[];
  formikSetFieldValue: (name: string, value: unknown) => Promise<unknown>;
  disabled: boolean;
  formikError?: string;
};

export const DHMOSettings = ({
  formikSelectedStates,
  formikSetFieldValue,
  disabled,
  formikError,
}: Props) => {
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const [selectedDrawerStates, setSelectedDrawerStates] =
    useState<DHMOBenefitType[]>(formikSelectedStates);

  const drawerBody = (
    <StackY dist={12}>
      <Body2>Please select all DHMO plans that apply to this client</Body2>
      {dhmoBenefitTypes.map((dhmoBenefitType) => {
        const slfCoverageLongName = benefitTypeToCoverage[dhmoBenefitType];
        return (
          <Checkbox
            key={dhmoBenefitType}
            name={"DHMOBenefitType"}
            value={slfCoverageLongName}
            label={<>{slfCoverageLongName}</>}
            disabled={disabled}
            checked={selectedDrawerStates?.includes(dhmoBenefitType) ?? false}
            onChange={() => {
              setSelectedDrawerStates((prevState) => {
                const included = prevState.includes(dhmoBenefitType);
                return included
                  ? prevState.filter((selectedState) => selectedState !== dhmoBenefitType)
                  : prevState.concat(dhmoBenefitType);
              });
            }}
          />
        );
      })}
      <Row justify="end" className="mb-32">
        <Col>
          <StackX dist={24}>
            <Button
              type="text"
              size="middle"
              loading={disabled}
              disabled={disabled}
              onClick={() => {
                setDrawerVisible(false);
                setSelectedDrawerStates(formikSelectedStates);
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              loading={disabled}
              disabled={disabled}
              onClick={() => {
                void formikSetFieldValue("DHMOBenefitTypes", selectedDrawerStates);
                setDrawerVisible(false);
              }}
            >
              Save changes
            </Button>
          </StackX>
        </Col>
      </Row>
    </StackY>
  );

  return (
    <>
      <Body2 as="p">Dental Prepaid / DHMO plan(s)</Body2>
      <StackY dist={8}>
        <Button
          type="text-only"
          size="middle"
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          onClick={() => {
            setDrawerVisible(true);
          }}
          disabled={disabled}
        >
          Add DHMO plan(s)
        </Button>
        <div aria-live="assertive">
          {formikError && <InputErrorMessage id={formikError} error={formikError} />}
        </div>
        {formikSelectedStates.length > 0 && (
          <StackY dist={8} className={setupCardStyles.descriptionContainer}>
            {formikSelectedStates.sort().map((dhmoBenefitType) => (
              <Row key={dhmoBenefitType}>
                <Col>
                  <Body3>{benefitTypeToCoverage[dhmoBenefitType]}</Body3>
                </Col>
                <Col>
                  <Button
                    type="danger-text-only"
                    size="middle"
                    icon={<TrashIcon />}
                    onClick={async () => {
                      const newStateList = formikSelectedStates.filter((selectedState) => {
                        return selectedState !== dhmoBenefitType;
                      });
                      await formikSetFieldValue("DHMOBenefitTypes", newStateList);
                      setSelectedDrawerStates(newStateList);
                    }}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            ))}
          </StackY>
        )}
      </StackY>

      <SlobDrawer
        width={750}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        title={"Set DHMO Plans"}
        children={drawerBody}
      />
    </>
  );
};
