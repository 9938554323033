import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { InternalLinkButton } from "client/src/components/Button/InternalLinkButton";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { getTierContributionValuesFromEmployeeClassAndBenefitType } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/EIFClassBuilderTieredContributions";
import { ReviewTierContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/ReviewTierContributions";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import { isUsing3TierContributionAttributes } from "client/src/domain/EIF/common/utils/isUsing3TierContributionAttributes";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { ResponseError } from "client/src/hooks/query";
import { getFormikErrors, useSlobFormik } from "client/src/hooks/useSlobFormik";
import { getPropertiesToUpdate } from "client/src/utils/getPropertiesToUpdate";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import {
  getIsFourTierIndividualContributionBenefitType,
  type BenefitTypeEIF,
  getIsSupplementalHealthBenefitType,
  getIsThreeTierContributionBenefitType,
  getIsDHMOBenefitType,
} from "shared/types/BenefitTypes";
import {
  type EmployeeClassPlanWithPlan,
  type EmployerContributionUnit,
  shouldHaveContributionValues,
  contributionFields,
} from "shared/types/EmployeeClassPlan";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getEmployeeClassTieredContributionsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import { hasNonNullValues } from "shared/utils/utils";
import { editContributionsForEmployeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";
import { ContributionsCTA } from "../ContributionsCTA";
import { hasAtLeastOneContributionSelected } from "../hasAtLeastOneContributionSelected";
import { relevantChangesForContributionFields } from "../relevantChangesForContributionFields";
import { EIFClassBuilderTieredContributionsForm } from "./EIFClassBuilderTieredContributionsForm";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass, YesNoShared } from "shared/types/EmployeeClass";
import type { EmployerPremiumPayments } from "shared/types/EmployeeClassPlan";

type EIFClassBuilderTieredContributionsEditableProps = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  setContributionsQuery: SetContributionQuery;
  benefitType: BenefitTypeEIF;
  onSave: (employeeClass: EmployeeClass) => void;
  isLoading: boolean;
  track: SectionTracker;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
};

export const EIFClassBuilderTieredContributionsEditable = (
  props: EIFClassBuilderTieredContributionsEditableProps,
) => {
  const {
    isActive,
    client,
    employeeClass,
    setContributionsQuery,
    setContributionsQuery: { mutateAsync: setContributions },
    benefitType,
    onSave,
    isLoading,
    track,
    changeSnapshot,
    authUser,
  } = props;

  const location = useLocation();
  const ctaBasePathname = location.pathname;

  const employeeClassUpdatedToPreserveBackwardsCompatability =
    updateEmployeeClassToPreserveBackwardsCompatability(employeeClass, benefitType);

  const relevantEmployeeClassPlans =
    employeeClassUpdatedToPreserveBackwardsCompatability.employeeClassPlans.filter(
      (employeeClassPlan) => {
        return employeeClassPlan.plan.benefitType === benefitType;
      },
    );
  const employeeClassPlanIds = relevantEmployeeClassPlans.map((ecp) => ecp.id);

  const initialValues = getTierContributionValuesFromEmployeeClassAndBenefitType(
    employeeClassUpdatedToPreserveBackwardsCompatability,
    benefitType,
  );

  const showHigh =
    relevantEmployeeClassPlans.length > 0
      ? relevantEmployeeClassPlans.some((rcp) => rcp.plan.level === "HIGH")
      : false;

  const useIndividualRoleAttrs =
    isUsing3TierContributionAttributes(employeeClass, benefitType) ||
    getIsFourTierIndividualContributionBenefitType(benefitType);

  const formik = useSlobFormik({
    validationSchema: editContributionsForEmployeeClassPlanValidationSchema,
    validateOnChange: false,
    validationContext: { benefitType, prefill: true },
    initialValues,
    onSubmit: async () => {
      if (
        formik.values.employerContribution === null ||
        formik.values.employerContribution === undefined
      ) {
        formik.setErrors({ employerContribution: "Please select a contribution" });
        return;
      }
      if (!employeeClass || !relevantEmployeeClassPlans) {
        void slobMessage.error("Error saving contributions, please refresh and try again");
        return;
      }

      const { isSuccess, data } = await setContributions({
        params: {
          clientId: client.id,
          employeeClassId: employeeClass.id,
        },
        data: {
          employeeClassId: employeeClass.id,
          contributions: populateContributionsFromForm(relevantEmployeeClassPlans, formik.values),
        },
      });

      if (isSuccess) {
        track("Save");
        void slobMessage.success("Updated Contributions");
        onSave(data.employeeClass);
      }
    },
  });

  const status = getEmployeeClassTieredContributionsCompletionStatus(employeeClass, [benefitType]);

  const fieldsToCheckForNull = contributionFields;

  const haveEverSavedForm =
    relevantEmployeeClassPlans[0] &&
    hasNonNullValues(
      getPropertiesToUpdate<EmployeeClassPlanWithPlan>(fieldsToCheckForNull)(
        relevantEmployeeClassPlans[0],
      ),
    ) &&
    (relevantEmployeeClassPlans[1]
      ? hasNonNullValues(
          getPropertiesToUpdate<EmployeeClassPlanWithPlan>(fieldsToCheckForNull)(
            relevantEmployeeClassPlans[1],
          ),
        )
      : true);

  const strictErrors =
    status !== "Not Started" && haveEverSavedForm
      ? getFormikErrors(formik.values, editContributionsForEmployeeClassPlanValidationSchema, {
          prefill: false,
          benefitType,
        })
      : {};

  if (!isActive) {
    const atLeastOneContributionSelected = hasAtLeastOneContributionSelected(
      relevantEmployeeClassPlans,
    );

    return (
      <>
        <Row justify="space-between">
          <Col>
            <H3 as="h2" greyMedium={!atLeastOneContributionSelected}>
              <EIFSectionStatusIconography status={status} />
              Contributions - {benefitTypeToCoverage[benefitType]}
            </H3>
          </Col>
          <Col>
            <ContributionsCTA
              employeeClass={employeeClass}
              ctaBasePathname={ctaBasePathname}
              benefitTypes={[benefitType]}
              relevantEmployeeClassPlans={relevantEmployeeClassPlans}
              setContributionsQuery={setContributionsQuery}
              isLoading={isLoading}
            />
          </Col>
        </Row>

        {strictErrors && Object.keys(strictErrors).length > 0 && (
          <Row gutter={[32, 18]} className="mb-16">
            <Col flex="1">
              <AlertBanner
                variant="error"
                message={
                  <Body3>
                    Click <Body2>Edit</Body2> to complete this section.
                  </Body3>
                }
              />
            </Col>
          </Row>
        )}

        <ReviewTierContributions
          client={client}
          authUser={authUser}
          employeeClass={employeeClass}
          benefitType={benefitType}
          changeSnapshot={changeSnapshot}
        />
      </>
    );
  }

  const needsContributionValues = shouldHaveContributionValues(
    benefitType,
    formik.values.employerContribution,
  );

  const premiumPaymentsOnly =
    getIsSupplementalHealthBenefitType(benefitType) && formik.values.employerContribution === "NO";

  const employerContributionOptions = [
    {
      value: "YES",
      label: (
        <>
          <Body3 as="div">Yes, we are paying 100% of the premium</Body3>
          <Body5>The employer pays the entire premium</Body5>
        </>
      ),
    },
    {
      value: "SHARED",
      label: (
        <>
          <Body3 as="div">Yes, we are paying part of the premium</Body3>
          <Body5>
            The employer pays part of the premium and the employee pays part of the premium
          </Body5>
        </>
      ),
    },
    {
      value: "NO",
      label: (
        <>
          <Body3 as="div">No, the employee pays 100% of the premium</Body3>
          <Body5>The employee pays the entire premium</Body5>
        </>
      ),
    },
  ];

  return (
    <>
      <H3 as="h2">
        <EIFSectionStatusIconography status={status} />
        Contributions - {benefitTypeToCoverage[benefitType]}
      </H3>

      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24}>
          <RadioGroup
            name="employerContribution"
            label="Will you contribute towards the premium for this eligible employee group?"
            disabled={formik.isSubmitting}
            error={formik.errors.employerContribution || strictErrors.employerContribution}
            touched={formik.touched.employerContribution || !!strictErrors.employerContribution}
            options={employerContributionOptions}
            direction="vertical"
            value={formik.values.employerContribution}
            onChange={formik.handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={relevantChangesForContributionFields(
              employeeClassPlanIds,
              changeSnapshot,
              ["employerContribution"],
            )}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "employerContribution",
              client,
              formik,
            })}
          />

          {(needsContributionValues || premiumPaymentsOnly) && <HorizontalDivider />}

          {(needsContributionValues || premiumPaymentsOnly) && (
            <EIFClassBuilderTieredContributionsForm
              key={benefitType}
              formik={formik}
              isActive={true}
              benefitType={benefitType}
              showHigh={showHigh}
              useIndividualRoleAttrs={useIndividualRoleAttrs}
              changeSnapshot={changeSnapshot}
              employeeClassPlans={relevantEmployeeClassPlans}
              client={client}
              authUser={authUser}
              strictErrors={strictErrors}
              premiumPaymentsOnly={premiumPaymentsOnly}
            />
          )}

          {setContributionsQuery.isError && (
            <div aria-live="assertive" className="mt-24 hide:empty">
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(
                  setContributionsQuery.error,
                  GenericErrorCopy2,
                )}
              </ErrorMessage>
            </div>
          )}

          <Row justify="end" align="middle" gutter={28}>
            <Col>
              <InternalLinkButton
                type="link-inline-bold"
                size="middle"
                to={location.pathname}
                disabled={formik.isSubmitting}
                onClick={() => flushSync(formik.resetForm)}
              >
                Cancel
              </InternalLinkButton>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="secondary"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting || formik.values.employerContribution === null}
                size="middle"
              >
                Save & Continue
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
};

const populateContributionsFromForm = (
  relevantEmployeeClassPlans: EmployeeClassPlanWithPlan[],
  formValues: {
    employerContribution?: YesNoShared | null;
    employerContributionUnit?: EmployerContributionUnit | null;
    eeContributionAmountLow?: number | null;
    spouseContributionAmountLow?: number | null;
    childrenContributionAmountLow?: number | null;
    eeAndSpouseContributionAmountLow?: number | null;
    eeAndChildrenContributionAmountLow?: number | null;
    eeAndFamilyContributionAmountLow?: number | null;
    familyContributionAmountLow?: number | null;
    eeContributionAmountHigh?: number | null;
    spouseContributionAmountHigh?: number | null;
    childrenContributionAmountHigh?: number | null;
    eeAndSpouseContributionAmountHigh?: number | null;
    eeAndChildrenContributionAmountHigh?: number | null;
    eeAndFamilyContributionAmountHigh?: number | null;
    familyContributionAmountHigh?: number | null;
    employerPremiumPayments?: EmployerPremiumPayments | null;
  },
) => {
  const result = relevantEmployeeClassPlans.map((ecp) => {
    const employerContribution = formValues.employerContribution ?? null;
    const employerContributionUnit = formValues.employerContributionUnit ?? null;

    let eeContributionAmount: number | null = null;
    let eeAndSpouseContributionAmount: number | null = null;
    let eeAndChildrenContributionAmount: number | null = null;
    let eeAndFamilyContributionAmount: number | null = null;
    let spouseContributionAmount: number | null = null;
    let childrenContributionAmount: number | null = null;
    let familyContributionAmount: number | null = null;
    let employerPremiumPayments: EmployerPremiumPayments | null = null;

    if (shouldHaveContributionValues(ecp.plan.benefitType, employerContribution)) {
      if (ecp.plan.level === "LOW" || ecp.plan.level === "NOT_APPLICABLE") {
        eeContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.eeContributionAmountLow ?? null;

        spouseContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.spouseContributionAmountLow ?? null;

        childrenContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.childrenContributionAmountLow ?? null;

        eeAndSpouseContributionAmount =
          formValues.eeAndSpouseContributionAmountLow != null
            ? formValues.eeAndSpouseContributionAmountLow
            : null;

        eeAndChildrenContributionAmount =
          formValues.eeAndChildrenContributionAmountLow != null
            ? formValues.eeAndChildrenContributionAmountLow
            : null;
        eeAndFamilyContributionAmount =
          formValues.eeAndFamilyContributionAmountLow != null
            ? formValues.eeAndFamilyContributionAmountLow
            : null;
        familyContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.familyContributionAmountLow ?? null;
        employerPremiumPayments =
          formValues.employerContribution === "YES" ||
          !getIsSupplementalHealthBenefitType(ecp.plan.benefitType)
            ? null
            : formValues.employerPremiumPayments
            ? formValues.employerPremiumPayments
            : null;
      } else if (ecp.plan.level === "HIGH") {
        eeContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.eeContributionAmountHigh ?? null;
        spouseContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.spouseContributionAmountHigh ?? null;
        childrenContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.childrenContributionAmountHigh ?? null;
        eeAndSpouseContributionAmount =
          formValues.eeAndSpouseContributionAmountHigh != null
            ? formValues.eeAndSpouseContributionAmountHigh
            : null;
        eeAndChildrenContributionAmount =
          formValues.eeAndChildrenContributionAmountHigh != null
            ? formValues.eeAndChildrenContributionAmountHigh
            : null;
        eeAndFamilyContributionAmount =
          formValues.eeAndFamilyContributionAmountHigh != null
            ? formValues.eeAndFamilyContributionAmountHigh
            : null;
        familyContributionAmount =
          formValues.employerContribution !== "SHARED"
            ? null
            : formValues.familyContributionAmountHigh ?? null;
      }
    }

    if (
      getIsSupplementalHealthBenefitType(ecp.plan.benefitType) &&
      formValues.employerContribution === "NO"
    ) {
      employerPremiumPayments = formValues.employerPremiumPayments
        ? formValues.employerPremiumPayments
        : null;
    }

    return {
      employeeClassPlanId: ecp.id,
      employerContribution,
      employerContributionUnit,
      eeContributionAmount,
      spouseContributionAmount,
      childrenContributionAmount,
      eeAndSpouseContributionAmount,
      eeAndChildrenContributionAmount,
      eeAndFamilyContributionAmount,
      familyContributionAmount,
      employerPremiumPayments,
      threeYearLookBackPercent: null,
      taxChoice: null,
    };
  });
  return result;
};

const updateEmployeeClassToPreserveBackwardsCompatability = (
  employeeClass: EmployeeClass,
  benefitType: BenefitTypeEIF,
) => {
  // FOR SUPP HEALTH:
  // Originally (prior to TB-6219) for Supp Health Plans employerContribution options were:
  // Employees will pay some or all of the premium (allowed for tier selection) -- (employerContribution = NO)
  // The Employer pays 100% of the premium (no other tier/selections available) -- (employerContribution = YES)

  // FOR DENTAL AND VISION
  // Originally (prior to TB-6220) for Dental/Vision employerContribution options were:
  // Employees will pay some or all of the premium (allowed for tier selection) -- (employerContribution = YES)
  // Employees pay 100% of the premium -- (employerContribution = NO)
  // NOTE: This is similar to Supp Health, but actually reversed in terms fo the `employerContribution` values

  // After TB-6219 Supp Health now has three employerContribution options:
  // Yes, we are paying 100% of the premium -- (employerContribution = YES)
  // Yes, we are paying part of the premium -- (employerContribution = SHARED)
  // No, the employee pays 100% of the premium -- (employerContribution = NO)

  // After TB-6220 Dental/Vision now has the same three employerContribution options as Supp Health (listed above)

  // FOR SUPP HEALTH:
  // The YES option is unchanged, but the legacy NO option can now map to NO or SHARED depending on the tier selections made
  // A legacy NO with 0 value in all tiers should map to a new NO (with all tiers being null)
  // A legacy NO with non-0 values in any tiers should map to a new YES (with tiers filled out)

  // FOR DENTAL/VISION
  // Took the Supp Health logic and flipped it (due to the fact that employerContribution = YES means the opposite thing for Supp Health compared to Vision in the prior two tiered context)

  const updatedEmployeeClassPlans = employeeClass.employeeClassPlans.map((ecp) => {
    if (ecp.plan.benefitType !== benefitType) {
      return ecp;
    }

    if (getIsSupplementalHealthBenefitType(benefitType)) {
      if (ecp.employerContribution === "YES") {
        return ecp;
      }

      if (ecp.employerContribution === "NO") {
        const tiersToCheck = [
          "eeContributionAmount",
          "spouseContributionAmount",
          "childrenContributionAmount",
        ] as const;
        const allZeroTierValues = tiersToCheck.every((tier) => ecp[tier] === 0);
        const allNullTierValues = tiersToCheck.every((tier) => ecp[tier] === null);

        if (allZeroTierValues) {
          ecp.employerContribution = "NO";
          ecp.employerContributionUnit = null;
          ecp.eeContributionAmount = null;
          ecp.spouseContributionAmount = null;
          ecp.childrenContributionAmount = null;
          ecp.familyContributionAmount = null;
        }

        if (!allZeroTierValues && !allNullTierValues) {
          ecp.employerContribution = "SHARED";
        }
      }
    }

    if (getIsThreeTierContributionBenefitType(benefitType) || getIsDHMOBenefitType(benefitType)) {
      if (ecp.employerContribution === "NO") {
        return ecp;
      }

      if (ecp.employerContribution === "YES") {
        const tiersToCheck = [
          "eeContributionAmount",
          "spouseContributionAmount",
          "childrenContributionAmount",
        ] as const;
        const allZeroTierValues = tiersToCheck.every((tier) => ecp[tier] === 0);
        const allNullTierValues = tiersToCheck.every((tier) => ecp[tier] === null);

        if (allZeroTierValues) {
          ecp.employerContribution = "NO";
          ecp.employerContributionUnit = null;
          ecp.eeContributionAmount = null;
          ecp.spouseContributionAmount = null;
          ecp.childrenContributionAmount = null;
          ecp.familyContributionAmount = null;
        }

        if (!allZeroTierValues && !allNullTierValues) {
          ecp.employerContribution = "SHARED";
        }
      }
    }

    return ecp;
  });

  const employeeClassWithUpatedEmployeeClassPlans = {
    ...employeeClass,
    employeeClassPlans: updatedEmployeeClassPlans,
  };
  return employeeClassWithUpatedEmployeeClassPlans;
};
