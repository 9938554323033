import { Body3, H3, H5 } from "client/src/components/Typography/Typography";
import { PlanSummaryLink } from "client/src/domain/ExplorerPages/ClientPage/Benefit/PlanSummaryLink";
import { Table } from "client/src/domain/ExplorerPages/ClientPage/Benefit/Table/Table";
import {
  OrthodonticServicesValue,
  isOrthonoticServicesValue,
} from "client/src/domain/ExplorerPages/Modals/ExplorerBenefitModal/OrthodonticServices";
import { copyKeyExists } from "client/src/domain/ExplorerPages/utils";
import { Trans, i18n, useTranslation } from "client/src/i18n";
import { formatCurrency } from "client/src/utils/formatters";
import { type ReactNode } from "react";
import * as styles from "./ExplorerDentalPlanComparisonTable.module.less";
import type { ExplorerPageBenefit } from "shared/types/ExplorerPageBenefit";

/**
 * Props for the {@link ExplorerDentalPlanComparisonTable} component.
 */
type ExplorerDentalPlanComparisonTableProps = {
  /**
   * The dental plan benefits to compare.
   */
  benefits: Array<ExplorerPageBenefit>;
};

/**
 * A table component to compare dental plan benefits.
 */
export const ExplorerDentalPlanComparisonTable = (
  props: ExplorerDentalPlanComparisonTableProps,
) => {
  const { benefits } = props;

  const rows = useRowConfig();
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.DENTAL.custom" });

  return (
    <div className={styles.container}>
      <Table
        skipEmptyRows
        head={[
          {
            contents: (
              <Trans t={t} i18nKey="tableHeaders.planName">
                <H3 />
              </Trans>
            ),
          },
          ...benefits.map((benefit) => ({
            contents: <H3>{benefit.planName}</H3>,
          })),
        ]}
        body={rows.map(({ getLabel, getValue }, rowIndex) => [
          {
            contents: getLabel(benefits),
          },
          ...benefits.map((benefit) => {
            const dhmoRowIndex = rows.findIndex((row) => row.getValue(benefit) === "DHMO");
            const isImmediatelyPrecedingDHMO = dhmoRowIndex > -1 && dhmoRowIndex === rowIndex - 1;
            const isPrecedingDHMO = dhmoRowIndex > -1 && dhmoRowIndex < rowIndex;
            const isLastRow = rowIndex === rows.length - 1;

            // Skip values after a DHMO value but not preceded by it, except last row.
            if (isPrecedingDHMO && !isImmediatelyPrecedingDHMO && !isLastRow) return undefined;

            return {
              heading: getLabel(benefits),
              contents: isImmediatelyPrecedingDHMO ? (
                <div className={styles.dhmoRowSpan}>
                  <Trans t={t} i18nKey="tableCells.dhmo">
                    <Body3 />
                    <PlanSummaryLink
                      benefit={benefit}
                      label={t("tableCells.downloadPlanSummary", { planName: benefit.planName })}
                    />
                    <Body3 />
                  </Trans>
                </div>
              ) : (
                getValue(benefit)
              ),
              cellProps: {
                rowSpan: isImmediatelyPrecedingDHMO ? rows.length - rowIndex - 1 : undefined,
              },
            };
          }),
        ])}
        footer={
          <Trans t={t} i18nKey="tableFooters.default">
            <Body3 as="p" />
            <Body3 as="p" />
          </Trans>
        }
      />
    </div>
  );
};

export type RowConfig = {
  getLabel: (benefits: ExplorerPageBenefit[]) => ReactNode;
  getValue: (benefit: ExplorerPageBenefit) => ReactNode;
};

function useRowConfig(): Array<RowConfig> {
  const { t } = useTranslation({ keyPrefix: "ExplorerPlanComparisonTable.DENTAL.custom" });

  const rows: Array<RowConfig> = [
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.carrier">
          <H5 />
        </Trans>
      ),
      getValue: (benefit) => benefit.carrier?.carrierName ?? "",
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.planType">
          <H5 />
        </Trans>
      ),
      getValue: (benefit) => benefit.planType ?? "",
    },
    {
      getLabel: (benefits) => (
        <Trans
          t={t}
          i18nKey={
            benefits
              .filter((benefit) => benefit.planType !== "DHMO" && benefit.planType !== "DCA")
              .every((benefit) => benefit.planYearDeductibleOutOfNetworkNA) &&
            copyKeyExists(t, "tableHeaders.individualPlanYearDeductibleNA")
              ? "tableHeaders.individualPlanYearDeductibleNA"
              : "tableHeaders.individualPlanYearDeductible"
          }
        >
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: ({
        planYearDeductibleIndividualInNetwork: pydIndIn,
        planYearDeductibleIndividualOutOfNetwork: pydIndOut,
        planYearDeductibleOutOfNetworkNA: pydOutNA,
      }) =>
        [
          formatCurrency(pydIndIn ?? 0),
          ...(pydOutNA !== true ? [formatCurrency(pydIndOut ?? 0)] : []),
        ].join(" / "),
    },
    {
      getLabel: (benefits) => (
        <Trans
          t={t}
          i18nKey={
            benefits
              .filter((benefit) => benefit.planType !== "DHMO" && benefit.planType !== "DCA")
              .every((benefit) => benefit.planYearDeductibleOutOfNetworkNA) &&
            copyKeyExists(t, "tableHeaders.familyPlanYearDeductibleNA")
              ? "tableHeaders.familyPlanYearDeductibleNA"
              : "tableHeaders.familyPlanYearDeductible"
          }
        >
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: ({
        planYearDeductibleFamilyInNetwork: pydFamIn,
        planYearDeductibleFamilyOutOfNetwork: pydFamOut,
        planYearDeductibleOutOfNetworkNA: pydOutNA,
      }) =>
        [
          formatCurrency(pydFamIn ?? 0),
          ...(pydOutNA !== true ? [formatCurrency(pydFamOut ?? 0)] : []),
        ].join(" / "),
    },
    {
      getLabel: (benefits) => (
        <Trans
          t={t}
          i18nKey={
            benefits
              .filter((benefit) => benefit.planType !== "DHMO" && benefit.planType !== "DCA")
              .every((benefit) => benefit.planYearMaximumOutOfNetworkNA) &&
            copyKeyExists(t, "tableHeaders.planYearMaximumNA")
              ? "tableHeaders.planYearMaximumNA"
              : "tableHeaders.planYearMaximum"
          }
        >
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: ({
        planYearMaximumIndividualInNetwork: pymIndIn,
        planYearMaximumIndividualOutOfNetwork: pymIndOut,
        planYearMaximumOutOfNetworkNA: pymOutNA,
      }) =>
        [
          formatCurrency(pymIndIn ?? 0),
          ...(pymOutNA !== true ? [formatCurrency(pymIndOut ?? 0)] : []),
        ].join(" / "),
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.orthodonticServices">
          <H5 />
        </Trans>
      ),
      getValue: (benefit) => {
        const orthodonticServicesValue = isOrthonoticServicesValue(
          benefit.dentalOrthodonticServices,
        )
          ? benefit.dentalOrthodonticServices
          : OrthodonticServicesValue.None;
        return t(`tableCells.orthodonticServices.${orthodonticServicesValue}`);
      },
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.lifetimeOrthodonticMaximum">
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: ({
        dentalOrthodonticServices,
        dentalOrthodonticMaximumAdult,
        dentalOrthodonticMaximumChild,
      }) =>
        getOrthoMaxValue(
          dentalOrthodonticServices,
          dentalOrthodonticMaximumAdult,
          dentalOrthodonticMaximumChild,
        ),
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.type1Care">
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: (benefit) =>
        `${benefit.dentalTypeOneInNetwork ?? 0}% / ${benefit.dentalTypeOneOutOfNetwork ?? 0}%`,
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.type2Care">
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: (benefit) =>
        `${benefit.dentalTypeTwoInNetwork ?? 0}% / ${benefit.dentalTypeTwoOutOfNetwork ?? 0}%`,
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.type3Care">
          <H5 />
          <Body3 />
        </Trans>
      ),
      getValue: (benefit) =>
        `${benefit.dentalTypeThreeInNetwork ?? 0}% / ${benefit.dentalTypeThreeOutOfNetwork ?? 0}%`,
    },
    {
      getLabel: () => (
        <Trans t={t} i18nKey="tableHeaders.linkToFullPlanSummary">
          <H5 />
        </Trans>
      ),
      getValue: (benefit) => {
        const language =
          i18n.language === "en" || i18n.language.startsWith("en-")
            ? "en"
            : i18n.language === "es" || i18n.language.startsWith("es-")
            ? "es"
            : undefined;

        // Return `undefined` to skip the cell if there is no plan summary in the whole row.
        return (language === "en" && benefit.planSummaryDocumentId) ||
          (language === "es" && benefit.spanishPlanSummaryDocumentId) ? (
          <PlanSummaryLink
            benefit={benefit}
            label={t("tableCells.downloadPlanSummary", { planName: benefit.planName })}
            showIcon
          />
        ) : undefined;
      },
    },
  ];

  return rows;
}

function getOrthoMaxValue(
  orthoService: string | null,
  adultMax: number | null,
  childMax: number | null,
) {
  if (isOrthonoticServicesValue(orthoService)) {
    const adultMaxFormatted = formatCurrency(adultMax);
    const childMaxFormatted = formatCurrency(childMax);

    if (orthoService === OrthodonticServicesValue.Both) {
      return `${adultMaxFormatted} /
        ${childMaxFormatted}`;
    } else if (orthoService === OrthodonticServicesValue.Adults) {
      return `${adultMaxFormatted}`;
    } else if (orthoService === OrthodonticServicesValue.Children) {
      return `${childMaxFormatted}`;
    }
  }
  return "N/A";
}
