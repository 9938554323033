import { Anchor } from "client/src/components/Anchor/Anchor";
import { useCallback } from "react";

import { Button } from "../../../components/Button/Button";
import { ErrorMessage, genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useUpdateClient } from "../../../hooks/client";
import { useTrackElementClicked } from "../../../utils/analytics";

import * as styles from "./setupCards.module.less";

import type { Client, ClientSetupStepId } from "shared/types/Client";
import type { OnboardingFormWithMetadata } from "shared/types/OnboardingForm";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type CardCompleteSetupProps = {
  client: Client;
  status: string;
  docuSignEnvelopeData: readonly OnboardingFormWithMetadata[];
  featureToggles: ClientFeatureToggles | undefined;
  refetchData?: (stepId: ClientSetupStepId) => Promise<void>;
};

export const CardCompleteSetup = ({
  client,
  status,
  docuSignEnvelopeData,
  refetchData,
}: CardCompleteSetupProps) => {
  const trackElementClicked = useTrackElementClicked(client);
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Client Setup - Step 8",
        buttonLabel,
        moduleState: status,
      });
    },
    [trackElementClicked, status],
  );

  const docusignClick = useCallback(() => {
    track("Sign in to DocuSign / View Status");
  }, [track]);

  const envelopeUrlsWithDuplicateRecipients: string[] = [];

  docuSignEnvelopeData.forEach((form) => {
    const recipientEmails = form.metadata.recipients.map((r) => r.email);
    const recipientEmailsSet = new Set(recipientEmails);
    if (recipientEmails.length !== recipientEmailsSet.size) {
      envelopeUrlsWithDuplicateRecipients.push(form.metadata.manageEnvelopeUrl);
    }
  });

  const { mutateAsync: updateClient, isPending } = useUpdateClient();

  const handleCompleteSetupClick = async () => {
    try {
      await updateClient({
        params: { clientId: client.id },
        data: { completedSetup: true },
      });
      track("Completed setup");
      void slobMessage.success("Client setup complete!");
      if (refetchData) await refetchData("CONFIRM_SETUP");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    }
  };

  return (
    <>
      {client.completedSetup && <p>Setup completed.</p>}
      {!client.completedSetup && (
        <>
          <div className={`${styles.completeSetup} mb-20`}>
            <p>
              If you’ve completed all the steps above, you can mark this Client setup as complete
              and return to the Manage Client view.
            </p>
            <Button
              type="secondary"
              action
              onClick={handleCompleteSetupClick}
              loading={isPending}
              disabled={envelopeUrlsWithDuplicateRecipients.length > 0}
            >
              Complete setup
            </Button>
          </div>
          <ErrorMessage>
            {envelopeUrlsWithDuplicateRecipients.length > 0 ? (
              <span>
                The form{envelopeUrlsWithDuplicateRecipients.length > 1 ? "s" : null} added in the
                "Add DocuSign Envelope" step above{" "}
                {envelopeUrlsWithDuplicateRecipients.length > 1 ? "have" : "has"} duplicate
                recipient email addresses. Please fix in DocuSign before proceeding:
                <br></br>
                {envelopeUrlsWithDuplicateRecipients.map((form, index) => {
                  return (
                    <div key={`DocuSignForm-${index + 1}`}>
                      <Anchor
                        href={envelopeUrlsWithDuplicateRecipients[index]}
                        target="_blank"
                        onClick={docusignClick}
                        red={true}
                      >
                        DocuSign Form #{index + 1}
                      </Anchor>
                      <br></br>
                    </div>
                  );
                })}
              </span>
            ) : null}
          </ErrorMessage>
        </>
      )}
    </>
  );
};
