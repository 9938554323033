import type { OnboardJWT } from "server/types/Auth";
import type { UserData } from "shared/rbac/rbac";
import type { ClientId } from "shared/types/Client";
import type { UserId } from "shared/types/User";

export type OutsideSignerId = string;

export type OutsideSigner = {
  id: OutsideSignerId;
  firstName: string;
  lastName: string;
  email: string;
  clientId: ClientId;
  tokenIssuedAt: Date | null;

  createdAt: Date;
  createdBy: UserId;
  updatedAt: Date;
  updatedBy: UserId;
  deletedAt: Date | null;
  deletedBy: UserId | null;
};

export type OutsideSignerActionId = (typeof OutsideSignerActionIdValues)[number];
export const OutsideSignerActionIdValues = ["review-&-submit", "review-&-submit-updates"] as const;

export const isOutsideSignerActionId = (
  outsideSignerActionId: string,
): outsideSignerActionId is OutsideSignerActionId => {
  const isIt = OutsideSignerActionIdValues.includes(
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    outsideSignerActionId as OutsideSignerActionId,
  );
  return isIt;
};

export type SignerMode = "inside" | "outside";
export type OutsideSignerJWTSignOption = "DEEP_LINK" | "AUTHN";
export type OutsideSignerJWTSetting = {
  secretToken: string;
  /** expressed in seconds or a string describing a time span [zeit/ms](https://github.com/zeit/ms.js).
   * Eg: 60, "2 days", "10h", "7d"
   */
  expiresIn: string | number;
};

export const outsideSignerToAuthUser = (outsideSigner: OutsideSigner): UserData => ({
  role: "BEN_ADMIN",
  id: outsideSigner.id,
  userId: outsideSigner.id,
  email: outsideSigner.email,
  name: `${outsideSigner.firstName} ${outsideSigner.lastName}`,
  phoneNumber: null,
  clientIds: [outsideSigner.clientId],
  hasMfa: false,
});

export type JWTDecodeFailureReason = "TOKEN_EXPIRED" | "TOKEN_INVALID";
export type OutsideSignerTokenStatus = "VALID" | "EXPIRED" | "REVOKED";
export const ReviewICEditsActionValues = ["accept", "decline"] as const;
export type ReviewICEditsAction = (typeof ReviewICEditsActionValues)[number];

export type DeepLinkLoginOutput = {
  authToken: string | null;
  redirectUrl: string;
  tokenStatus: OutsideSignerTokenStatus;
};

export type OutsideSignerAuthUser = {
  authUser: UserData;
  tokenStatus: OutsideSignerTokenStatus;
};

export type OutsideSignerAuthnJWT = OnboardJWT & {
  deepLinkStatus: OutsideSignerTokenStatus;
};

export const isOutsideSignerAuthnJWT = (
  jwtData: string | object,
): jwtData is OutsideSignerAuthnJWT => {
  return typeof jwtData === "object"
    ? "exp" in jwtData && "sub" in jwtData && "iat" in jwtData && "deepLinkStatus" in jwtData
    : false;
};

export type ResendOutsideSignerEmailOutput = {
  emailSent: boolean;
};
