import { faCheckCircle, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { ReactComponent as UserHeadsetIcon } from "client/src/components/Icons/UserHeadset.svg";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Eyebrow } from "client/src/components/Typography/Typography";
import { useGetDocumentCount } from "client/src/hooks/document";
import { findPhaseCopy } from "client/src/hooks/phase";
import clsx from "clsx";
import pluralize from "pluralize";
import { Fragment } from "react";
import { RouteData } from "shared/config/routeData";
import { EnrollmentResourcesCategories } from "shared/types/Document";
import * as styles from "./hub.module.less";
import type { PhaseCopy } from "client/src/hooks/phase";
import type { Client } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

interface SupportInformationV2Props {
  client: Client;
  showExpertRequestMsg: boolean;
  videoClipsCount: number;
  flyersCount: number;
}

export const SupportInformationV2 = ({
  client,
  showExpertRequestMsg,
  videoClipsCount,
  flyersCount,
}: SupportInformationV2Props) => {
  const documentCategoriesToGet: DocumentCategory[] = EnrollmentResourcesCategories;

  const { isLoading, isFetching, isError, data } = useGetDocumentCount(
    client.id,
    documentCategoriesToGet,
  );

  const videoClipsAndFlyersCount = videoClipsCount + flyersCount;
  const count =
    isLoading || isFetching
      ? "-"
      : isError || data == null
      ? "?"
      : data.count + videoClipsAndFlyersCount;

  // send completed ones to the bottom of the list
  const sortedPolicies = client.policies.slice().sort((a, b) => {
    if (a.phaseId === "ONBOARDING_COMPLETE") return 1;
    if (b.phaseId === "ONBOARDING_COMPLETE") return -1;
    return 0;
  });
  const phaseCopies = sortedPolicies.reduce(
    (pv: Record<string, PhaseCopy | undefined>, cv) => ({
      ...pv,
      [cv.id]: findPhaseCopy(client, cv) || undefined,
    }),
    {},
  );

  const getCurrentPhaseAndDates = (policyId: string) => {
    const currentPhase = phaseCopies[policyId];
    return currentPhase?.importantDates.map((importantDate) => {
      if (importantDate.tasks?.every((task) => !task.isApplicable)) return null; // don't show empty date
      return (
        <Fragment key={`fragment_${importantDate.dateInfo}`}>
          <Fragment>
            <p className="body2">{importantDate.dateInfo}</p>
            {importantDate.tasks?.length && (
              <ul>
                {importantDate.tasks.map(
                  (task, index) => task.isApplicable && <li key={index}>{task.text}</li>,
                )}
              </ul>
            )}
          </Fragment>
        </Fragment>
      );
    });
  };

  const hideBottomPanel =
    sortedPolicies.length === 0 ||
    sortedPolicies.every((policy) =>
      phaseCopies[policy.id]?.importantDates.every((importantDate) =>
        importantDate.tasks?.every((task) => !task.isApplicable),
      ),
    );

  return (
    <div className={clsx(styles.supportInformation)}>
      <div className={styles.arc} />
      <div className={styles.topIcon}>
        <img
          alt="Enrollment Resources Icon"
          src="/enrollment-resources-icon/SL_illustrative icon_RGB_Business_doc check.png"
          srcSet="/enrollment-resources-icon/SL_illustrative icon_RGB_Business_doc check@2x.png 2x"
          height={100}
          width={100}
          aria-hidden={true}
        />
      </div>
      <div className={styles.informationContainer}>
        <div className={styles.topPanel}>
          <StackY dist={24}>
            <h2 className={"h5"}>Employee & Employer Resources</h2>
            <p className={clsx("mb-0", styles.leftText)}>
              Review documents and educational resources shared with you throughout the onboarding
              process, as well as give your employees the knowledge they need to make smart
              decisions about their enrollments.
            </p>

            <div className={clsx(styles.leftText, styles.iconRow)}>
              <FontAwesomeIcon icon={faFileAlt} className={styles.fileIcon} />
              <Eyebrow>
                {count} {pluralize("resources", typeof count === "number" ? count : 0)} available
              </Eyebrow>
            </div>
            <div className={clsx(styles.leftText, styles.iconRow)}>
              {showExpertRequestMsg ? (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} className={styles.checkCircleIcon} />
                  <Eyebrow greenMedium> Expert help requested</Eyebrow>
                </>
              ) : (
                <>
                  <UserHeadsetIcon />
                  <Eyebrow> Expert help available</Eyebrow>
                </>
              )}
            </div>
            <Button
              to={RouteData.clientTaskDetail.getPath(client.id, "enrollment-resources")}
              type="primary"
              size="middle"
              data-appcues-id="get-resources-link"
            >
              View Now
            </Button>
          </StackY>
        </div>
        {!hideBottomPanel && (
          <div className={styles.bottomPanel}>
            {sortedPolicies.map((policy, index) => {
              const currentPhaseAndDates = getCurrentPhaseAndDates(policy.id);
              if (currentPhaseAndDates == null || currentPhaseAndDates.length === 0) return null;

              const hidePolicyNumber = sortedPolicies.length === 0;
              return (
                <div key={policy.id}>
                  <div className={styles.policyImportantDates}>
                    <div>
                      <p className={clsx("eyebrow", styles.subHeadingTitle)}>Important Dates</p>
                      {hidePolicyNumber !== true && (
                        <p className={clsx("eyebrow", styles.subHeadingDescription)}>
                          #{policy.slfPolicyNumber}
                        </p>
                      )}
                    </div>
                    <div>{currentPhaseAndDates}</div>
                  </div>
                  {sortedPolicies.length > 1 && index < sortedPolicies.length - 1 && <hr />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
