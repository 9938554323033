import { Typography } from "antd";
import { Link } from "react-router-dom";

import type { PropsWithChildren } from "react";
import type { LinkProps } from "react-router-dom";

type GoBackLinkProps = {
  to: LinkProps["to"];
};

export const GoBackLink = ({ to, children }: PropsWithChildren<GoBackLinkProps>) => {
  return (
    <Typography.Text underline>
      <Link to={to}>{children}</Link>
    </Typography.Text>
  );
};
