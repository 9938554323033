import { useState } from "react";

import { useDeleteDocument } from "../../../hooks/document";

import type { ClientId, PolicyId } from "../../../../../shared/types/Client";
import type { DocumentId, DocumentCategory } from "../../../../../shared/types/Document";

export function useDocumentDeletionProps({
  clientId,
  policyId,
  track,
  onDelete,
}: {
  clientId: ClientId;
  policyId?: string;
  track: (buttonLabel: string) => void;
  onDelete?: () => void;
}) {
  const emptyDeleteParams = {
    isVisible: false,
    docIdToDelete: null,
    name: null,
    category: null,
    policyIds: null,
  };

  const [deleteDocumentParams, setDeleteDocumentParams] = useState<{
    isVisible: boolean;
    docIdToDelete: DocumentId | null;
    name: string | null;
    category: DocumentCategory | null;
    policyIds: PolicyId[] | null;
  }>(emptyDeleteParams);

  const {
    mutateAsync: deleteDocument,
    isPending: isPendingDeleteDocument,
    error: errorDeleteDocument,
  } = useDeleteDocument(deleteDocumentParams.category, policyId);

  const openDocumentDeletionConfirmDialog = (
    documentId: DocumentId,
    name: string,
    category: DocumentCategory,
    policyIds?: PolicyId[],
  ) => {
    track("File delete");
    setDeleteDocumentParams({
      isVisible: true,
      docIdToDelete: documentId,
      name,
      category,
      policyIds: policyIds || null,
    });
  };

  const handleConfirmDeleteDocument = async () => {
    track("File delete confirmed");
    if (deleteDocumentParams.docIdToDelete) {
      try {
        await deleteDocument({
          params: { documentId: deleteDocumentParams.docIdToDelete, clientId },
        });
        onDelete?.();
      } finally {
        setDeleteDocumentParams(emptyDeleteParams);
      }
    }
  };

  const resetDeleteDocumentParams = () => {
    track("File delete canceled");
    setDeleteDocumentParams(emptyDeleteParams);
  };

  const documentDeleteConfirmDialogVisible = deleteDocumentParams.isVisible;
  const documentToDeleteName = deleteDocumentParams.name || "";
  const documentPolicyIds = deleteDocumentParams.policyIds;

  return {
    documentDeleteConfirmDialogVisible,
    documentToDeleteName,
    documentPolicyIds,
    openDocumentDeletionConfirmDialog,
    handleConfirmDeleteDocument,
    resetDeleteDocumentParams,
    isPendingDeleteDocument,
    errorDeleteDocument,
  };
}
