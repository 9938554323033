import { Button } from "client/src/components/Button/Button";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3, H3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { EIFClassBuilderBenefitList } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderBenefitList";
import { NoEmployeeClassPlansMessage } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/NoEmployeeClassPlansMessage";
import {
  EIFClassBuilderWaitingPeriodForm,
  relevantChangesForWaitingPeriodFields,
} from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/WaitingPeriods/EIFClassBuilderWaitingPeriodForm";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";

import {
  getEmployeeClassPlansByReadableWaitingPeriod,
  readableWaitingPeriodSorter,
} from "client/src/domain/EIF/common/utils/getEmployeeClassPlansByReadableWaitingPeriod";
import { useToggler } from "client/src/hooks/useToggler";
import { getURLSearchParamsFromObject } from "client/src/utils/url";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { RouteData } from "shared/config/routeData";
import { BEN_ADMIN } from "shared/rbac/roles";
import { getIsWaitingPeriodBenefitType, type BenefitTypeEIF } from "shared/types/BenefitTypes";
import { waitingPeriodNotSetForClassPlans } from "shared/types/EmployeeClassPlan";
import { getEmployeeClassWaitingPeriodsCompletionStatus } from "shared/utils/EIF/getEIFStepCompleteStatus";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetWaitingPeriodsQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type EIFClassBuilderWaitingPeriodsProps = {
  isActive: boolean;
  activeBenefitTypes: BenefitTypeEIF[] | null;
  client: Client;
  clientPlans: Plan[];
  employeeClass?: EmployeeClass;
  setWaitingPeriodsQuery?: SetWaitingPeriodsQuery;
  isLoading: boolean;
  track?: SectionTracker;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export function EIFClassBuilderWaitingPeriods(props: EIFClassBuilderWaitingPeriodsProps) {
  const {
    isActive,
    activeBenefitTypes,
    client,
    clientPlans,
    employeeClass,
    setWaitingPeriodsQuery,
    isLoading,
    track,
    changeSnapshot,
    authUser,
    featureToggles,
  } = props;

  let benefitTypesEditing =
    isActive && activeBenefitTypes
      ? activeBenefitTypes.filter(getIsWaitingPeriodBenefitType).sort()
      : null;

  const setWaitingPeriods = setWaitingPeriodsQuery
    ? setWaitingPeriodsQuery.mutateAsync
    : setWaitingPeriodsQuery;

  const employeeClassPlans = employeeClass?.employeeClassPlans.filter((ecp) =>
    getIsWaitingPeriodBenefitType(ecp.plan.benefitType),
  );

  const employeeClassPlanIds = employeeClassPlans?.map((ecp) => ecp.id) ?? [];

  const location = useLocation();
  const navigate = useNavigate();
  const [isEditing, toggleIsEditing] = useToggler();

  const noEmployeeClassPlans =
    !employeeClass || !employeeClassPlans || employeeClassPlans.length === 0;

  if (noEmployeeClassPlans) {
    return (
      <NoEmployeeClassPlansMessage
        title="Waiting Periods"
        userRole={authUser?.role ?? BEN_ADMIN}
        clientPlans={clientPlans}
        clientId={client.id}
      />
    );
  }

  const plansWithWaitingPeriod = employeeClassPlans.filter(
    ({ waitingPeriodType }) => waitingPeriodType !== null,
  );
  const hasPlansWithWaitingPeriod = plansWithWaitingPeriod.length > 0;

  const status = getEmployeeClassWaitingPeriodsCompletionStatus(employeeClass);
  const allBenefitTypesForClass = [
    ...new Set(employeeClassPlans.map((employeeClassPlan) => employeeClassPlan.plan.benefitType)),
  ]
    .filter(getIsWaitingPeriodBenefitType)
    .sort();

  if (isActive && benefitTypesEditing === null) {
    // if auto-advance from prior section, we should edit benefit types with no waiting period set
    benefitTypesEditing = [
      ...new Set(
        employeeClassPlans
          .filter(({ waitingPeriodType }) => waitingPeriodType === null)
          .map((employeeClassPlan) => employeeClassPlan.plan.benefitType)
          .filter(getIsWaitingPeriodBenefitType),
      ),
    ].sort();
  }

  const benefitTypesWithWaitingPeriods = [
    ...new Set(
      plansWithWaitingPeriod.map((employeeClassPlan) => employeeClassPlan.plan.benefitType),
    ),
  ];
  const benefitTypesWithWaitingPeriodPlusActiveBenefitTypes = [
    ...new Set([...benefitTypesWithWaitingPeriods, ...(benefitTypesEditing ?? [])]),
  ].sort();
  const endOfSetWaitingPeriodsSection =
    benefitTypesWithWaitingPeriodPlusActiveBenefitTypes.length === allBenefitTypesForClass.length &&
    benefitTypesWithWaitingPeriodPlusActiveBenefitTypes.every(
      (benefitType, i) => benefitType === allBenefitTypesForClass[i],
    );

  const showStartButton = !isActive && !hasPlansWithWaitingPeriod;
  const showSummaries = !isActive && hasPlansWithWaitingPeriod;

  const editingWaitingPeriod = isActive && !!benefitTypesEditing && benefitTypesEditing.length > 0;

  const classPlansByReadableWaitingPeriod =
    getEmployeeClassPlansByReadableWaitingPeriod(employeeClassPlans);

  const waitingPeriodOnSaveCallback = (employeeClass: EmployeeClass) => {
    const nextPath = RouteData.eifClassBuilderEdit.getPath(
      client.id,
      "plan-configuration-&-eligibility",
      "class-builder",
      employeeClass.id,
    );
    return navigate(nextPath);
  };

  const startSearchParamsObject = {
    section: "Waiting Periods",
    benefitTypes: allBenefitTypesForClass,
  };
  const startUrlSearchParamsString =
    getURLSearchParamsFromObject(startSearchParamsObject).toString();

  return (
    <>
      {/* waiting periods section header */}
      <Row>
        <Col flex={1}>
          <StackY dist={16} className="mb-16">
            <H3 as="h2" greyMedium={!isActive && status === "Not Started"}>
              <EIFSectionStatusIconography status={status} />
              Waiting Periods
            </H3>
            <EditedFieldMsg
              changeDetailInfoList={relevantChangesForWaitingPeriodFields(
                employeeClassPlanIds,
                changeSnapshot,
              )}
              client={client}
              authUser={authUser}
            />
          </StackY>
        </Col>
        {showStartButton && (
          <Col>
            <Button
              to={`${location.pathname}?${startUrlSearchParamsString}`}
              size="small"
              shrink
              disabled={isEditing || isLoading}
            >
              Start
            </Button>
          </Col>
        )}
      </Row>
      {/* summaries and editing per benefit types */}
      {(showSummaries || editingWaitingPeriod) && (
        <StackY dist={12}>
          {Object.entries(classPlansByReadableWaitingPeriod)
            .sort(readableWaitingPeriodSorter)
            .map(([readableWaitingPeriod, employeeClassPlans], index) => {
              const planIds = employeeClassPlans.map(
                (employeeClassPlan) => employeeClassPlan.plan.id,
              );

              const benefitTypes = [
                ...new Set(
                  employeeClassPlans.map(
                    (employeeClassPlan) => employeeClassPlan.plan?.benefitType,
                  ),
                ),
              ].sort();

              const editingTheseBenefits =
                editingWaitingPeriod &&
                benefitTypesEditing?.length === benefitTypes?.length &&
                benefitTypesEditing?.every((benefitType, i) => benefitType === benefitTypes?.[i]);

              const showSummaryForTheseBenefits = !editingTheseBenefits;

              const searchParamsObject = {
                section: "Waiting Periods",
                benefitTypes: benefitTypes,
              };
              const urlSearchParamsString =
                getURLSearchParamsFromObject(searchParamsObject).toString();

              return (
                <Fragment key={`waitingPeriodSummary_${readableWaitingPeriod}`}>
                  {index !== 0 && <HorizontalDivider></HorizontalDivider>}
                  {!editingTheseBenefits &&
                    waitingPeriodNotSetForClassPlans(employeeClassPlans) &&
                    !isEditing && (
                      <Row>
                        <InputErrorMessage
                          error={"Please provide a waiting period for the following benefits"}
                        />
                      </Row>
                    )}
                  <Row justify="space-between">
                    {editingTheseBenefits && (
                      <EIFClassBuilderWaitingPeriodForm
                        client={client}
                        employeeClassPlans={employeeClassPlans ?? []}
                        employeeClass={employeeClass}
                        isActive={isActive}
                        onSave={waitingPeriodOnSaveCallback}
                        isLoading={isLoading}
                        toggleIsSubmitting={toggleIsEditing}
                        setWaitingPeriodsQuery={setWaitingPeriodsQuery}
                        activeBenefitTypes={benefitTypesEditing}
                        endOfSetWaitingPeriodsSection={endOfSetWaitingPeriodsSection}
                        location={location}
                        track={track}
                        changeSnapshot={changeSnapshot}
                        authUser={authUser}
                        featureToggles={featureToggles}
                      />
                    )}
                    {showSummaryForTheseBenefits && (
                      <>
                        <Col>
                          <StackY dist={12}>
                            <EIFClassBuilderBenefitList
                              variant="bold"
                              employeeClassPlans={employeeClassPlans ?? []}
                            />
                            {!waitingPeriodNotSetForClassPlans(employeeClassPlans) && (
                              <Body3 as="div">{readableWaitingPeriod}</Body3>
                            )}
                          </StackY>
                        </Col>
                        <Col>
                          <Row gutter={8}>
                            <Col>
                              <Button
                                to={`${location.pathname}?${urlSearchParamsString}`}
                                type="text-only"
                                size="middle"
                                disabled={isEditing || isLoading}
                              >
                                Edit
                              </Button>
                            </Col>
                            <Col>
                              {!waitingPeriodNotSetForClassPlans(employeeClassPlans) && (
                                <Button
                                  type="text-only"
                                  size="middle"
                                  onClick={async () => {
                                    if (setWaitingPeriods) {
                                      toggleIsEditing();
                                      const { isSuccess } = await setWaitingPeriods({
                                        params: {
                                          clientId: client.id,
                                          employeeClassId: employeeClass.id,
                                        },
                                        data: {
                                          classId: employeeClass.id,
                                          planIds: planIds,
                                          waitingPeriodType: null,
                                          waitingPeriodDuration: null,
                                          waitingPeriodUnit: null,
                                        },
                                      });
                                      if (isSuccess) {
                                        void slobMessage.success("Deleted Waiting Periods");
                                      }
                                      toggleIsEditing();
                                    }
                                  }}
                                  disabled={isEditing || isLoading}
                                >
                                  Delete
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                </Fragment>
              );
            })}
        </StackY>
      )}
    </>
  );
}
