import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { SlobPopover } from "../SlobPopover/SlobPopover";
import { Body5 } from "../Typography/Typography";

import { getItemStatus } from "./ProgressTracker";
import * as styles from "./timeline.module.less";

import type { ReactNode } from "react";

export type ProgressItem = {
  id: string;
  title: string;
  description?: ReactNode;
};

export const TimelineLabel = ({ labels }: { labels: ProgressItem[] }) => (
  <ol className={clsx(styles.list)}>
    {labels.map((label, index) => {
      const placement = !index
        ? "bottomLeft"
        : index >= labels.length - 2
        ? "bottomRight"
        : "bottom";
      return (
        <li key={label.id} className={styles.item}>
          <Body5>
            {label.title}

            {label.description && (
              <SlobPopover
                id={label.id}
                title={label.title}
                content={label.description}
                placement={placement}
                trigger={["click", "hover"]}
              >
                <button
                  className="btn-reset"
                  aria-label={`Learn more about the ${label.title} phase.`}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </button>
              </SlobPopover>
            )}
          </Body5>
        </li>
      );
    })}
  </ol>
);

export type TimelineProps = {
  items: string[];
  currentItem?: string;
  isComplete: boolean;
  theme?: "default" | "warning" | "danger" | "success";
};

export const Timeline = ({ items, currentItem, isComplete, theme = "default" }: TimelineProps) => {
  const currentItemIndex = items.findIndex((item) => item === currentItem);
  const considerCurrent = !isComplete;

  return (
    <ol className={clsx(styles.list)}>
      {items.map((item, index) => {
        const currentStatus = getItemStatus(index, currentItemIndex, items.length - 1);
        const themeStyle = isComplete ? "success" : theme;

        return (
          <li
            key={item}
            className={clsx(styles.item, styles[`__${themeStyle}`], {
              [styles.completed]: isComplete,
              [styles.itemPast]: considerCurrent && currentStatus === "past",
              [styles.itemCurrent]: considerCurrent && currentStatus === "current",
              [styles.itemFuture]: considerCurrent && currentStatus === "future",
            })}
          >
            {currentStatus === "past" || isComplete ? <FontAwesomeIcon icon={faCheck} /> : ""}
          </li>
        );
      })}
    </ol>
  );
};
