import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "antd";
import { getTagRenderFunction } from "client/src/components/TagList/tagRender";
import clsx from "clsx";
import { useCallback } from "react";
import { useSlobId } from "../../hooks/useSlobId";
import { InputErrorMessage } from "../Form/InputErrorMessage";
import * as slobSelectStyles from "../Form/SlobSelect.module.less";
import * as styles from "./SlobSelectMultiple.module.less";
import type { SelectProps } from "antd";
import type { LabeledValue } from "antd/lib/select";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

export interface SlobCustomTagProps<TValue> extends CustomTagProps {
  value: TValue;
}

export type TagLabelRender<TValue> = (props: SlobCustomTagProps<TValue>) => React.ReactNode;

export type SlobSelectMultipleProps<T extends LabeledValue> = Pick<
  SelectProps<T[]>,
  | "id"
  | "className"
  | "disabled"
  | "onChange"
  | "maxTagTextLength"
  | "open"
  | "onBlur"
  | "dropdownRender"
> & {
  options: T[];
  value: T[];
  name: string;
  placeholder: string;
  touched?: boolean;
  error?: string;
  errorId?: string;
  hasExternalErrorStyles?: boolean;
  "aria-label"?: string;
  tagLabelRender?: TagLabelRender<T["value"]>;
};

export const SlobSelectMultiple = <T extends LabeledValue>({
  options,
  value,
  id: providedId,
  name,
  className,
  touched,
  error,
  errorId,
  hasExternalErrorStyles,
  placeholder,
  disabled,
  onChange,
  maxTagTextLength,
  open,
  onBlur,
  dropdownRender,
  "aria-label": ariaLabel,
  tagLabelRender,
}: SlobSelectMultipleProps<T>) => {
  const computedId = useSlobId({ prefix: name });
  const id = providedId || computedId;
  const externalErrorId = errorId;
  const internalErrorId = touched && Boolean(error) ? `${id}__errormessage` : undefined;
  const hasError = Boolean(externalErrorId || internalErrorId);
  const ariaErrorMessage = [externalErrorId, internalErrorId].join(" ").trim();

  const onBlurWrapper: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.target.name = name;
      onBlur?.(e);
    },
    [name, onBlur],
  );

  return (
    <div
      data-component="<SlobSelectMultiple />"
      data-name={name}
      data-testid="slob-select-multiple-component"
    >
      <Select<T[]>
        id={id}
        aria-invalid={hasError}
        aria-errormessage={ariaErrorMessage}
        aria-label={ariaLabel}
        mode="multiple"
        placeholder={placeholder}
        disabled={disabled}
        className={clsx(
          styles.formMultiSelect,
          hasExternalErrorStyles && styles.hasExternalErrorStyles,
          className,
        )}
        options={options}
        value={value}
        optionLabelProp="label"
        labelInValue
        onChange={onChange}
        tagRender={getTagRenderFunction(tagLabelRender)}
        menuItemSelectedIcon={null}
        showArrow
        showSearch={false}
        popupClassName={slobSelectStyles.selectPopup}
        maxTagTextLength={maxTagTextLength}
        open={open}
        suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="2x" />}
        onBlur={onBlurWrapper}
        dropdownRender={dropdownRender}
      />

      <div aria-live="assertive">
        {touched && Boolean(error) && <InputErrorMessage id={internalErrorId} error={error} />}
      </div>
    </div>
  );
};
