import { Col, Row } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { getIsInternalUser } from "shared/rbac/rbac";
import {
  getAuthorizedSignerFullName,
  getEIFSignedByFullName,
} from "shared/utils/EIF/getAuthorizedSignerFullName";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import * as Yup from "yup";
import { RouteData } from "../../../../shared/config/routeData";
import * as hubHeaderStyles from "../../../src/components/HubHeader/hubHeader.module.less";
import { Badge, taskStatusToVariant } from "../../components/Badge/Badge";
import { AlertBanner } from "../../components/Banner/AlertBanner";
import { Button } from "../../components/Button/Button";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { Checkbox } from "../../components/Form/Checkbox";
import { HubCard } from "../../components/HubCard/HubCard";
import { ReactComponent as AwardIcon } from "../../components/Icons/AwardIcon.svg";
import { SlobLink } from "../../components/SlobLink/SlobLink";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, Body5, H2, H3 } from "../../components/Typography/Typography";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../hooks/useSlobFormik";

import { useSlobId } from "../../hooks/useSlobId";
import { DownloadEIFSummaryPDFLink } from "../../pages/EIFSummaryPage/DownloadEIFSummaryPDFLink";
import { EIFSigningAgreement } from "./EIFSigningAgreement";
import * as styles from "./eifReviewAndSubmit.module.less";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { OutsideSignEIFFunc } from "client/src/hooks/outsideSigner";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { OutsideSignerTokenStatus, SignerMode } from "shared/types/OutsideSigner";

type Props = {
  client: Client;
  authUser: UserData;
  signEIF: UpdateClientFunc | OutsideSignEIFFunc;
  changesSnapshot: DEIFChangeSnapshot | null;
  trackElementClicked: TrackElementClickedFunc;
  signerMode: SignerMode;
  tokenStatus?: OutsideSignerTokenStatus | null;
};

export function EIFReviewAndSubmit(props: Props) {
  const {
    client,
    signEIF: signEIFFunc,
    authUser,
    changesSnapshot,
    signerMode,
    tokenStatus,
  } = props;

  const errorId = useSlobId({ prefix: "eifTermsAccepted_errormessage" });

  const isInternalUser = getIsInternalUser(authUser);
  const insideSignerMode = signerMode === "inside";
  const outsideSignerMode = signerMode === "outside";

  const navigate = useNavigate();

  const formik = useSlobFormik({
    validationSchema: Yup.object({
      eifTermsAccepted: Yup.boolean()
        .required()
        .test("must be true", "Please correct the errors above.", (v) => Boolean(v)),
    }),
    initialValues: {
      eifTermsAccepted: client.eifSignedAt != null,
    },
    onSubmit: async () => {
      const { isSuccess } = await signEIFFunc({
        params: { clientId: client.id },
        data: { eifSignedAt: new Date() },
      });

      if (isSuccess) {
        props.trackElementClicked({
          module: "review-&-submit",
          buttonLabel: "Sign & submit for review",
        });
        void slobMessage.success("Company Information has been submitted for review");
        if (!outsideSignerMode) {
          navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"));
        }
      }
    },
  });

  const authenticatedUserIsNotAuthorizedSigner = insideSignerMode
    ? authUser.id !== client.authorizedSignerUserId
    : outsideSignerMode && client.outsideSigner
    ? authUser.id !== client.outsideSigner.id
    : true;

  const signerFullName = getAuthorizedSignerFullName(client) || "-";
  const signedByFullName = getEIFSignedByFullName(client) || "-";

  const alreadySigned = client.eifSignedAt ? true : false;

  const alreadySignedMessage = alreadySigned ? (
    <>
      Your company information has been submitted by <strong>{signedByFullName}</strong>, on behalf
      of {client.name}. Sun Life will review your information and let you know if anything else is
      required from you. Revisions can only be made by speaking with your implementation consultant.
    </>
  ) : undefined;
  const inReviewVariant = taskStatusToVariant["In Review"];

  const eifEditState = getEIFEditState({ client, changesSnapshot });

  const downloadSummaryElement = insideSignerMode ? (
    <p>
      Need a copy of your company information? <DownloadEIFSummaryPDFLink clientId={client.id} />
      {(isInternalUser || eifEditState === "no-edits" || eifEditState === "changes-accepted") && (
        <>
          {" or "}
          <SlobLink to={RouteData.eifSummary.getPath(client.id)} variant="boldSmall">
            View Online
          </SlobLink>
        </>
      )}
    </p>
  ) : (
    <Body3>
      Need a copy of your company information? <DownloadEIFSummaryPDFLink clientId={client.id} />
    </Body3>
  );

  if (alreadySigned && outsideSignerMode) {
    return (
      <StackY dist={24}>
        <Col className={hubHeaderStyles.textCenter}>
          <StackY dist={16}>
            <AwardIcon />
            <H2>Thanks for submitting</H2>
          </StackY>
        </Col>
        <HubCard>
          <H3>What's next</H3>
          <Body3 as="div">
            Your Implementation Consultant will review and let you know if anything else is required
            from you. After submitting, revisions can only be made by contacting your Implementation
            Consultant.
          </Body3>
        </HubCard>
        {tokenStatus === "VALID" && <AlertBanner variant="info" message={downloadSummaryElement} />}
      </StackY>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} className={styles.container}>
      {insideSignerMode && (
        <>
          <Row justify="center">{downloadSummaryElement}</Row>

          <p>
            Before submitting your company information to Sun Life for review, please acknowledge
            that the information you have completed is true and accurate.
          </p>

          <p>
            When you’re ready to submit your company information, click the “Submit for Review”
            button below. Your Implementation Consultant will review and let you know if anything
            else is required from you.
          </p>

          <p>
            After submitting, revisions can only be made by contacting your implementation
            consultant.
          </p>
        </>
      )}

      {outsideSignerMode && (
        <>
          <p>
            Your Company Information Summary is ready for your review. Please click{" "}
            <Body2>Download PDF</Body2> and make sure that it is accurate.
          </p>

          <p>
            If it is correct and you're ready to submit your company information, click the{" "}
            <Body2>Sign & submit for review</Body2> button below.
          </p>

          <p>
            Your company's Sun Life contact will let you know if anything else is required from you.
          </p>
        </>
      )}

      {outsideSignerMode && (
        <div className="mb-40">
          <AlertBanner variant="info" message={downloadSummaryElement} />
        </div>
      )}

      {authenticatedUserIsNotAuthorizedSigner && (
        <div className="mb-40">
          <AlertBanner
            variant="info"
            message={
              <Body3>
                Note: Only {signerFullName} can sign on behalf of {client.name}. If you have any
                questions please reach out to your implementation consultant.
              </Body3>
            }
          />
        </div>
      )}

      <HubCard>
        {alreadySigned && (
          <div className="mb-40">
            <Badge
              srOnlyLabel="Task Status"
              variant={inReviewVariant}
              status={"In Review"}
              message={alreadySignedMessage}
              showStaticSpinner={false}
            />
          </div>
        )}

        <Checkbox
          name="eifTermsAccepted"
          errorId={
            formik.touched.eifTermsAccepted && formik.errors.eifTermsAccepted
              ? errorId
              : formik.status
              ? errorId
              : undefined
          }
          label={
            <span className={styles.ml20}>
              I, {signerFullName}, Agree on behalf of {client.name}
            </span>
          }
          variant="tertiary"
          checked={formik.values.eifTermsAccepted}
          onChange={formik.handleChange}
          disabled={authenticatedUserIsNotAuthorizedSigner || formik.isSubmitting || alreadySigned}
          content={
            <div className={clsx("mt-16", styles.ml20)}>
              <p>
                By clicking the “I Agree” box above, the Employer above has read, understands and
                agrees that:
              </p>

              <EIFSigningAgreement />
            </div>
          }
        />
      </HubCard>

      {alreadySigned ? (
        <div className="mt-40">
          <StackY dist={24}>
            <Row justify="center">
              <Button
                type="primary"
                size="large"
                to={RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information")}
              >
                Close
              </Button>
            </Row>
            <Row justify="center">{downloadSummaryElement}</Row>
          </StackY>
        </div>
      ) : (
        <>
          <p className="my-32">
            I agree, and it is my intent, to sign this Company Information form electronically. I
            understand that by clicking on the “I Agree” checkbox above and the Sign & Submit For
            Review button below, I will be applying my electronic signature to this form and that I
            will be bound with the same force and effect as if I had signed this form on paper by
            hand.
          </p>

          <div className={styles.center}>
            <StackY dist={24}>
              <div aria-live="assertive" id={errorId}>
                <ErrorMessage size="large">
                  {formik.touched.eifTermsAccepted && formik.errors.eifTermsAccepted
                    ? formik.errors.eifTermsAccepted
                    : formik.status}
                </ErrorMessage>
              </div>

              <div>
                {authenticatedUserIsNotAuthorizedSigner ? (
                  <Button
                    type="primary"
                    size="large"
                    to={RouteData.clientTaskDetail.getPath(
                      client.id,
                      "eif-submit-company-information",
                    )}
                  >
                    Close
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={authenticatedUserIsNotAuthorizedSigner}
                    loading={formik.isSubmitting}
                  >
                    Sign & submit for review
                  </Button>
                )}
              </div>

              {downloadSummaryElement}

              <Body5 as="div">
                After submitting, revisions can only be made by contacting your company's Sun Life
                contact.
              </Body5>
            </StackY>
          </div>
        </>
      )}
    </form>
  );
}
