import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";

import { Body2, Body3, Eyebrow } from "client/src/components/Typography/Typography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { AutoSaveOnNavigation } from "client/src/hooks/AutoSaveOnNavigation";
import { useNavigateIfMounted } from "client/src/hooks/useNavigateIfMounted";
import { useToggler } from "client/src/hooks/useToggler";
import { capitalize } from "client/src/utils/string";
import { useMemo } from "react";
import { monthsOfTheYear } from "shared/types/Client";
import { getEIFSubStepMap } from "shared/types/EIF";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { confirmPolicyAnniversarySchema } from "shared/validation/client";
import { getEIFSubStepStatus } from "shared/validation/getEIFSubStepStatus";
import { HubCard } from "../../components/HubCard/HubCard";
import { StackX, StackY } from "../../components/Spacing/Spacing";
import { useGetEIFPreviousAndNextLink } from "../../hooks/useGetEIFPreviousAndNextLink";
import { getPropertiesToUpdate } from "../../utils/getPropertiesToUpdate";
import { useClientUtils } from "../Client/useClientUtils";
import { EIFBottomNavButtons } from "./EIFBottomNavButtons";
import type { TrackElementClickedFunc } from "../../utils/analytics";
import type { LabeledValue } from "antd/lib/select";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

const formKeyProps: (keyof Client)[] = ["policyAnniversaryMonth"];

type Props = {
  client: Client;
  updateClient: UpdateClientFunc;
  changeSnapshot: DEIFChangeSnapshot;
  trackElementClicked: TrackElementClickedFunc;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
};

export const formTestId = "confirm-policy-anniversary-form";

export function EIFConfirmPolicyAnniversary(props: Props) {
  const { client, updateClient, changeSnapshot, trackElementClicked, authUser } = props;

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const eifSubStepId = "confirm-policy-anniversary";
  const subStepName = getEIFSubStepMap({ eifSubStepId });
  const [isEditModeActive, toggleEditMode] = useToggler(!client.policyAnniversaryMonth);

  const track = (buttonLabel: string) => {
    trackElementClicked({
      module: subStepName,
      buttonLabel,
      moduleState: getEIFSubStepStatus({
        client,
        eifSubStepId: "confirm-policy-anniversary",
      }),
    });
  };

  const navigate = useNavigateIfMounted();
  const { previousSubStepLink, nextSubStepLink } = useGetEIFPreviousAndNextLink();

  const navigateToNextStep = () => {
    if (nextSubStepLink) {
      navigate(nextSubStepLink);
    }
  };

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate: getPropertiesToUpdate<Client>(formKeyProps),
    updateClient,
    validationSchema: confirmPolicyAnniversarySchema,
    type: subStepName,
    track,
    formikOptions: {
      enableReinitialize: true,
    },
    onSuccessCallback: toggleEditMode,
  });

  const policyAnniversaryMonthOptions: LabeledValue[] = useMemo(
    () =>
      monthsOfTheYear.map((month) => ({
        label: capitalize(month),
        key: month,
        value: month,
      })),
    [],
  );

  const policyAnniversaryDefaultText = isMultiPolicyMode
    ? "By default, we’ve set all your policy anniversaries to the first day of the same month as your main policy’s effective date."
    : "By default, we’ve set your policy anniversary to the first day of the same month as your policy’s effective date.";

  const policyAnniversaryEditText = isMultiPolicyMode
    ? "If you prefer, you can edit the policy anniversaries to be the first day of a different month."
    : "If you prefer, you can edit the policy anniversary to be the first day of a different month.";

  return (
    <>
      <form onSubmit={formik.handleSubmit} data-testid={formTestId}>
        <h2 className="mb-40">{subStepName}</h2>

        <StackY dist={40}>
          <StackY dist={32}>
            <HubCard>
              <StackY dist={8}>
                <Body2 as="p">{policyAnniversaryDefaultText} </Body2>
                <Body3 as="p">{policyAnniversaryEditText}</Body3>
                {!isEditModeActive && formik.values.policyAnniversaryMonth ? (
                  <div>
                    <p>
                      <Eyebrow>Policy anniversary</Eyebrow>
                      <br />
                      <Body3>{capitalize(formik.values.policyAnniversaryMonth)} 1</Body3>
                    </p>

                    <Button type="text-only" onClick={toggleEditMode}>
                      Edit
                    </Button>
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col xs={12}>
                        <SlobSelect
                          name="policyAnniversaryMonth"
                          onChange={async (event) => {
                            await formik.setFieldValue("policyAnniversaryMonth", event.value);
                          }}
                          options={policyAnniversaryMonthOptions}
                          placeholder="Policy Anniversary"
                          value={formik.values.policyAnniversaryMonth}
                          touched={formik.touched.policyAnniversaryMonth}
                          error={formik.errors.policyAnniversaryMonth}
                          disabled={formik.isSubmitting}
                        />
                      </Col>
                    </Row>

                    <Row justify="end">
                      <Col>
                        <StackX dist={24}>
                          <Button
                            type="text-only"
                            onClick={toggleEditMode}
                            disabled={formik.isSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="secondary"
                            htmlType="submit"
                            disabled={formik.isSubmitting}
                            loading={formik.isSubmitting}
                          >
                            Save
                          </Button>
                        </StackX>
                      </Col>
                    </Row>
                  </>
                )}

                <EditedFieldMsg
                  changeDetailInfoList={[changeSnapshot.Client.policyAnniversaryMonth]}
                  client={client}
                  authUser={authUser}
                />

                <div aria-live="assertive" className="hide:empty">
                  {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
                </div>
              </StackY>
            </HubCard>

            <EIFBottomNavButtons
              previousLink={previousSubStepLink}
              previousButtonDisabled={formik.isSubmitting}
              nextButtonDisabled={formik.isSubmitting || isEditModeActive}
              onSubmitClick={navigateToNextStep}
            />
          </StackY>
        </StackY>
      </form>

      <AutoSaveOnNavigation formik={formik} optimistic />
    </>
  );
}
