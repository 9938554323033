import { useGetClientByID } from "client/src/hooks/client";
import { useGetPlansByClientId } from "client/src/hooks/plans";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { RouteData } from "shared/config/routeData";
import { eifStepNavigation } from "shared/types/EIF";
import { getIsSubStepApplicable } from "shared/utils/EIF/getIsSubStepApplicable";
import { assertIsDefined } from "shared/utils/utils";
import { useClientHubParams } from "./useClientHubParams";
import type { EIFSubStepId } from "shared/types/EIF";

export function useGetEIFPreviousAndNextLink() {
  const { clientId, eifStepId, eifSubStepId } = useClientHubParams([
    "clientId",
    "eifStepId",
    "eifSubStepId",
  ]);

  const { data: client } = useGetClientByID(clientId);
  const { data: plans } = useGetPlansByClientId(clientId);
  const { data: featureToggles } = useGetFeatureToggles(clientId);

  assertIsDefined(client, "client");
  assertIsDefined(plans, "plans");
  assertIsDefined(featureToggles, "featureToggles");

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
  const eifSubStepIdList = Object.keys(eifStepNavigation[eifStepId]) as Array<EIFSubStepId>;
  const eifSubStepIdIndex = eifSubStepIdList.indexOf(eifSubStepId);

  let previousSubStepId: EIFSubStepId | null = null;
  for (let index = eifSubStepIdIndex - 1; index >= 0 && previousSubStepId == null; index--) {
    const currentEIFSubStepId = eifSubStepIdList[index];
    assertIsDefined(currentEIFSubStepId, "currentEIFSubStepId");
    const isApplicable = getIsSubStepApplicable({
      eifSubStepId: currentEIFSubStepId,
      client,
      plans,
      featureToggles,
    });
    if (isApplicable) {
      previousSubStepId = currentEIFSubStepId;
    }
  }

  let nextSubStepId: EIFSubStepId | null = null;
  for (
    let index = eifSubStepIdIndex + 1;
    index < eifSubStepIdList.length && nextSubStepId == null;
    index++
  ) {
    const currentEIFSubStepId = eifSubStepIdList[index];
    assertIsDefined(currentEIFSubStepId, "currentEIFSubStepId");
    const isApplicable = getIsSubStepApplicable({
      eifSubStepId: currentEIFSubStepId,
      client,
      plans,
      featureToggles,
    });
    if (isApplicable) {
      nextSubStepId = currentEIFSubStepId;
    }
  }

  const previousSubStepLink = previousSubStepId
    ? RouteData.eifSubStepDetail.getPath(clientId, eifStepId, previousSubStepId)
    : null;
  const nextSubStepLink = nextSubStepId
    ? RouteData.eifSubStepDetail.getPath(clientId, eifStepId, nextSubStepId)
    : RouteData.eifStepDetail.getPath(clientId, eifStepId);

  return { previousSubStepLink, nextSubStepLink };
}
