import clsx from "clsx";
import * as styles from "./FlatCard.module.less";
import type { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  disabled?: boolean;
  "data-testid"?: string;
}>;

export function FlatCard(props: Props) {
  const { disabled, "data-testid": dataTestiD, children } = props;

  return (
    <div data-testid={dataTestiD} className={clsx(styles.FlatCard, disabled && styles.disabled)}>
      {children}
    </div>
  );
}
