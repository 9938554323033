import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { EIFClassBuilderItemBenefits } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemBenefits";
import { EIFClassBuilderItemContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemContributions";
import { EIFClassBuilderItemEarnings } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemEarnings";
import { EIFClassBuilderItemWaitingPeriods } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderItemWaitingPeriods";
import { relevantChangesForWaitingPeriodFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/WaitingPeriods/EIFClassBuilderWaitingPeriodForm";
import { EIFSubStepSummaryWrapper } from "client/src/domain/EIF/common/EIFSubStepSummaryWrapper";
import { EditBanner } from "client/src/domain/EIF/common/EditBanner";
import { getAvailableEIFClassBuilderSections } from "client/src/domain/EIF/common/utils/getAvailableEIFClassBuilderSections";
import { getBenefitChangeDetailInfoList } from "client/src/domain/EIF/common/utils/getBenefitChangeDetailInfoList";

import { getChangeListDetailInfoIsEmpty } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getShowEditBanner } from "client/src/domain/EIF/common/utils/getShowEditBanner";

import { RouteData } from "shared/config/routeData";
import { wasEntityAddedInLatestICEdit } from "shared/utils/EIF/changeLog";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";

import { InternalLinkButton } from "../../../../components/Button/InternalLinkButton";
import { StackY } from "../../../../components/Spacing/Spacing";
import { useToggler } from "../../../../hooks/useToggler";
import { PrintHidden } from "../../../../utils/print";
import * as styles from "../EIFPlanConfigAndEligibilityReview.module.less";

import { relevantChangesForContributionFields } from "./Contributions/relevantChangesForContributionFields";

import { relevantChangesForEarningsFields } from "./EIFClassBuilderEarnings";

import { EIFClassBuilderItemEligibility } from "./EIFClassBuilderItemEligibility";

import { EIFEmployeeClassDuplicateModal } from "./EIFEmployeeClassDuplicateModal";
import { EIFMissingInformationAlertBanner } from "./EIFMissingInformationAlertBanner";
import type { ChangeDetailInfoList } from "client/src/domain/EIF/common/EditBanner";
import type { DuplicateEmployeeClassQuery } from "client/src/hooks/employeeClass";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, ClientId, EIFSubStepViewMode } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type ItemProps = {
  client: Client;
  clientPlans: Plan[];
  employeeClass: EmployeeClass;
  singleClassBuilderMode: boolean;
  featureToggles: ClientFeatureToggles;
  authUser: UserData | null;
  changeSnapshot: DEIFChangeSnapshot;
};

type DetailsItemProps = ItemProps & {
  changeSnapshot: DEIFChangeSnapshot;
  featureToggles: ClientFeatureToggles;
};

const EIFClassBuilderBasicItem = ({
  client,
  clientPlans,
  employeeClass,
  singleClassBuilderMode,
  authUser,
  changeSnapshot,
}: ItemProps) => {
  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <>
      <StackY dist={16}>
        {!suppressErrorsPostSigning && (
          <EIFMissingInformationAlertBanner
            client={client}
            clientPlans={clientPlans}
            employeeClass={employeeClass}
            singleClassBuilderMode={singleClassBuilderMode}
          />
        )}

        <EIFClassBuilderItemEligibility
          client={client}
          employeeClass={employeeClass}
          changeSnapshot={null}
          authUser={authUser}
          hideTitle
        />
      </StackY>
    </>
  );
};

const EIFClassBuilderDetailsItem = ({
  clientPlans,
  employeeClass,
  featureToggles,
  singleClassBuilderMode,
  client,
  changeSnapshot,
  authUser,
}: DetailsItemProps) => {
  const clientPlansBenefitTypes = new Set(clientPlans.map((p) => p.benefitType));

  const availableSections = getAvailableEIFClassBuilderSections(
    singleClassBuilderMode,
    clientPlansBenefitTypes,
    employeeClass,
  );

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  return (
    <StackY dist={24} wrap={false}>
      {!suppressErrorsPostSigning && (
        <EIFMissingInformationAlertBanner
          client={client}
          clientPlans={clientPlans}
          employeeClass={employeeClass}
          singleClassBuilderMode={singleClassBuilderMode}
        />
      )}

      <EIFClassBuilderItemEligibility
        client={client}
        employeeClass={employeeClass}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
      />

      <PrintHidden children={<hr />} />

      <EIFClassBuilderItemBenefits
        client={client}
        employeeClass={employeeClass}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        featureToggles={featureToggles}
      />

      <PrintHidden children={<hr />} />

      <EIFClassBuilderItemWaitingPeriods
        client={client}
        employeeClass={employeeClass}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
      />

      {availableSections.includes("Contributions") && (
        <>
          <PrintHidden children={<hr />} />

          <EIFClassBuilderItemContributions
            client={client}
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            featureToggles={featureToggles}
          />
        </>
      )}

      {availableSections.includes("Earnings definition") && (
        <>
          <PrintHidden children={<hr />} />

          <EIFClassBuilderItemEarnings
            client={client}
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
            featureToggles={featureToggles}
          />
        </>
      )}
    </StackY>
  );
};

type EIFClassBuilderItemProps = {
  index: number;
  employeeClass: EmployeeClass;
  singleClassBuilderMode: boolean;
  toggleableDetails: boolean;
  expanded: boolean;
  clientPlans: Plan[];
  onDelete?: () => void;
  viewMode: EIFSubStepViewMode;
  changeSnapshot: DEIFChangeSnapshot;
  client: Client;
  authUser: UserData | null;
  featureToggles: ClientFeatureToggles;
  signerMode: SignerMode;
} & (
  | {
      readonly: true;
    }
  | {
      readonly: false;
      clientId: ClientId;
      duplicateEmployeeClassQuery: DuplicateEmployeeClassQuery;
    }
);

export const EIFClassBuilderItem = ({
  index,
  employeeClass,
  singleClassBuilderMode,
  toggleableDetails,
  expanded,
  clientPlans,
  onDelete,
  viewMode,
  changeSnapshot,
  client,
  authUser,
  signerMode,
  ...rest
}: EIFClassBuilderItemProps) => {
  const [showDetails, toggleShowDetails] = useToggler(expanded);
  const [isVisibleDuplicateModal, toggleIsVisibleDuplicateModal] = useToggler();

  const showEditBanner = getShowEditBanner(viewMode);
  const changeDetailInfoListForClass = getEmployeeClassChangeDetailInfoList(
    employeeClass,
    changeSnapshot,
  );
  const noChangesForClass = getChangeListDetailInfoIsEmpty(changeDetailInfoListForClass);

  if (signerMode === "outside" && noChangesForClass) {
    return null;
  }

  return (
    <>
      <EIFSubStepSummaryWrapper signerMode={signerMode}>
        <StackY dist={16} wrap={false}>
          {showEditBanner && (
            <EditBanner
              data-testid="edit-banner-class-builder-item"
              navigateLink={RouteData.eifClassBuilderEdit.getPath(
                employeeClass.clientId,
                "plan-configuration-&-eligibility",
                "class-builder",
                employeeClass.id,
              )}
              onDelete={onDelete}
              changeDetailInfoList={changeDetailInfoListForClass}
              client={client}
              authUser={authUser}
              isAdd={wasEntityAddedInLatestICEdit(employeeClass, client)}
            />
          )}

          <Row justify="space-between">
            <Col>
              <h5 style={{ margin: 0 }}>
                {singleClassBuilderMode
                  ? "All Full-time Employees"
                  : employeeClass.groupName
                  ? employeeClass.groupName
                  : `Eligible employee group ${index}`}
              </h5>
            </Col>
            {!rest.readonly && (
              <Col>
                <div className={styles.buttonGroup}>
                  {!singleClassBuilderMode && (
                    <Button type="text-only" onClick={toggleIsVisibleDuplicateModal}>
                      Duplicate
                    </Button>
                  )}

                  <InternalLinkButton
                    to={RouteData.eifClassBuilderEdit.getPath(
                      rest.clientId,
                      "plan-configuration-&-eligibility",
                      "class-builder",
                      employeeClass.id,
                    )}
                    type="link-inline-bold"
                  >
                    Edit
                  </InternalLinkButton>

                  <Button type="text-only" onClick={onDelete}>
                    Delete
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col xs={24} className="stack-y-32">
              {showDetails ? (
                <EIFClassBuilderDetailsItem
                  clientPlans={clientPlans}
                  employeeClass={employeeClass}
                  singleClassBuilderMode={singleClassBuilderMode}
                  featureToggles={rest.featureToggles}
                  client={client}
                  changeSnapshot={changeSnapshot}
                  authUser={authUser}
                />
              ) : (
                <EIFClassBuilderBasicItem
                  client={client}
                  clientPlans={clientPlans}
                  employeeClass={employeeClass}
                  authUser={authUser}
                  singleClassBuilderMode={singleClassBuilderMode}
                  featureToggles={rest.featureToggles}
                  changeSnapshot={changeSnapshot}
                />
              )}

              {toggleableDetails && (
                <>
                  <hr className="m-0" />

                  <Button onClick={toggleShowDetails} type="text-only">
                    {showDetails ? "Hide details" : "Show details"}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </StackY>
      </EIFSubStepSummaryWrapper>

      {!rest.readonly && !singleClassBuilderMode && (
        <EIFEmployeeClassDuplicateModal
          isVisible={isVisibleDuplicateModal}
          onCancel={toggleIsVisibleDuplicateModal}
          employeeClass={employeeClass}
          clientPlans={clientPlans}
          duplicateEmployeeClassQuery={rest.duplicateEmployeeClassQuery}
        />
      )}
    </>
  );
};

const getEmployeeClassChangeDetailInfoList = (
  employeeClass: EmployeeClass,
  changeSnapshot: DEIFChangeSnapshot,
): ChangeDetailInfoList => {
  if (!changeSnapshot) return [];

  const changeLogEmployeeClassId = employeeClass.id;
  const employeeClassPlanIds = employeeClass.employeeClassPlans.map((ecp) => ecp.id);
  const benefitChangeDetailInfoList = getBenefitChangeDetailInfoList(
    changeSnapshot,
    employeeClass.id,
  );
  const contributionChanges = relevantChangesForContributionFields(
    employeeClassPlanIds,
    changeSnapshot,
  );
  const waitingPeriodChanges = relevantChangesForWaitingPeriodFields(
    employeeClassPlanIds,
    changeSnapshot,
  );

  const earningsChanges = relevantChangesForEarningsFields(
    changeLogEmployeeClassId,
    changeSnapshot,
  );

  return [
    // Eligibility
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.jobTitles ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.compensationTypes ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.employmentTypes ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.otherAttributes ?? null,

    // Details
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.numberOfEmployees ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.minimumWeeklyHours ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]
      ?.minimumWeeklyHoursAreNotCalculatedWeekly ?? null,
    changeSnapshot.EmployeeClass[changeLogEmployeeClassId]?.minimumHoursDetails ?? null,

    // Benefits
    ...benefitChangeDetailInfoList,

    // Contribution
    ...contributionChanges,

    // Waiting Periods
    ...waitingPeriodChanges,

    // Earnings
    ...earningsChanges,
  ];
};
