import { Button } from "client/src/components/Button/Button";
import { Row, Col } from "client/src/components/Grid/Grid";
import { PFMLOptionDescription } from "client/src/pages/Clients/SetupCards/PFMLSettings";
import { shouldShowAgeRuleQuestions } from "client/src/utils/getShouldShowAgeQuestions";
import { useMemo, useState } from "react";
import {
  getIsDHMOBenefitType,
  type BenefitTypeEIF,
  getIsPFMLASOBenefitType,
} from "shared/types/BenefitTypes";
import { ageRules as ageRulesDefinition } from "shared/types/Client";
import { ageRelatedSlfCoverages, benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { formatFullName } from "shared/utils/format";
import { pfmlASOBenefitTypeToStateLabel, type Plan } from "../../../../shared/types/Plan";
import { Loading } from "../../components/Loading/Loading";
import { StackY } from "../../components/Spacing/Spacing";
import { Body2, Body3, H3 } from "../../components/Typography/Typography";
import { useGetClientByID } from "../../hooks/client";
import { triggerError } from "../../hooks/generalError";
import { useGetPlansByClientId } from "../../hooks/plans";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useGetBenAdmins, useGetBrokers } from "../../hooks/user";
import { BENADMIN_LIST_PAGE_SIZE } from "../../pages/Clients/BenAdminList";
import { CardConfigureSubmitCompanyInformation } from "../../pages/Clients/SetupCards/CardConfigureSubmitCompanyInformation";
import * as styles from "./submitCompanyInformationSettings.module.less";
import type { Client } from "../../../../shared/types/Client";
import type { BenAdmin, Broker } from "../../../../shared/types/User";
import type { DHMOBenefitType, PFMLASOBenefitType } from "shared/types/BenefitTypes";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type PlanSummary = { [key in BenefitTypeEIF]: "Single plan" | "Low & high plan" };

type PresentationProps = {
  client: Client;
  plans: Plan[];
  benAdmins: BenAdmin[];
  brokers: Broker[];
  featureToggles: ClientFeatureToggles;
  showDeductibleMaximumReportTaskInfo: boolean;
};

export const SubmitCompanyInformationSettingsPresentation = ({
  client,
  plans,
  benAdmins,
  brokers,
  featureToggles,
  showDeductibleMaximumReportTaskInfo,
}: PresentationProps) => {
  const { authorizedSignerUserId, allowClientSelfServicePlanConfig, allBenefitsNonContributory } =
    client;
  const [editMode, setEditMode] = useState<boolean>(false);

  const dentalsAndVisionPlanSummary = useMemo(
    () =>
      plans.reduce<Partial<PlanSummary>>((acc, plan) => {
        const relatedBenefitTypes: BenefitTypeEIF[] = ["DENTAL", "VISION", "DENTAL_ASO"];
        if (!relatedBenefitTypes.includes(plan.benefitType)) {
          return acc;
        }
        if (plan.benefitType in acc && plan.level === "HIGH") {
          acc[plan.benefitType] = "Low & high plan";
        } else if (!(plan.benefitType in acc)) {
          acc[plan.benefitType] = plan.level === "HIGH" ? "Low & high plan" : "Single plan";
        }

        return acc;
      }, {}),
    [plans],
  );

  const salaryBasedBenefits = plans.filter((plan) => plan.salaryBased === true);

  const DHMOPlans = plans.filter((plan): plan is Plan<DHMOBenefitType> =>
    getIsDHMOBenefitType(plan.benefitType),
  );

  const PFMLASOPlans = plans.filter((plan): plan is Plan<PFMLASOBenefitType> =>
    getIsPFMLASOBenefitType(plan.benefitType),
  );

  const authorizedSignerUser = useMemo(
    () => [...benAdmins, ...brokers].find(({ id }) => id === authorizedSignerUserId),
    [benAdmins, brokers, authorizedSignerUserId],
  );

  const allCoverages = client.allPoliciesSlfCoverages ?? [];
  const showAgeRules = ageRelatedSlfCoverages.some((coverage) => {
    return allCoverages.includes(coverage);
  });

  return (
    <div>
      <H3 blueMedium>Submit Company Information settings</H3>

      <div className={styles.summaryContainer}>
        {editMode ? (
          <div data-testid="editModePlanConfiguration">
            <CardConfigureSubmitCompanyInformation
              benAdmins={benAdmins}
              brokers={brokers}
              client={client}
              plans={plans}
              featureToggles={featureToggles}
              showLivePortalWarning
              onSave={() => setEditMode(false)}
            />
          </div>
        ) : (
          <Row>
            <Col xs={20}>
              <StackY dist={16}>
                <div data-testid="allowClientSelfServicePlanConfigContainer">
                  <p className="body2 mb-0">
                    Allow client to self service for plan configuration &amp; eligibility:
                  </p>
                  <Body3>{allowClientSelfServicePlanConfig === "YES" ? "Yes" : "No"}</Body3>
                </div>

                {Object.entries(dentalsAndVisionPlanSummary).map(
                  ([_benefitType, planLevelDescription]) => {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
                    const benefitType = _benefitType as BenefitTypeEIF;
                    return (
                      <div key={benefitType} data-testid={`plan_${benefitType}_container`}>
                        <p className="body2 mb-0">{benefitTypeToCoverage[benefitType]}</p>
                        <Body3>{planLevelDescription}</Body3>
                      </div>
                    );
                  },
                )}

                {allBenefitsNonContributory !== null && (
                  <div data-testid="allBenefitsNonContributoryConfigContainer">
                    <p className="body2 mb-0">Non-contributory benefits</p>
                    <Body3>
                      {allBenefitsNonContributory === "YES"
                        ? "All benefits are non-contributory"
                        : "No, some benefits are contributory"}
                    </Body3>
                  </div>
                )}

                {client.criticalIllnessType && (
                  <div data-testid="criticalIllnessTypeConfigContainer">
                    <p className="body2 mb-0">Critical Illness Rate</p>
                    <Body3>
                      {client.criticalIllnessType === "ATTAINED" ? "Attained" : "Issue"}
                    </Body3>
                  </div>
                )}

                {client.criticalIllnessRateType && (
                  <div data-testid="criticalIllnessRateTypeConfigContainer">
                    <p className="body2 mb-0">Critical Illness Rate Type</p>
                    <Body3>
                      {client.criticalIllnessRateType === "TOBACCO" ? "Tobacco" : "Non-tobacco"}
                    </Body3>
                  </div>
                )}

                {showAgeRules &&
                  ageRulesDefinition.map(
                    (ageRuleValue) =>
                      shouldShowAgeRuleQuestions(allCoverages, ageRuleValue) && (
                        <div
                          data-testid={`ageRulesConfigContainer_${ageRuleValue}`}
                          key={`ageRulesSummary_${ageRuleValue}`}
                        >
                          <p className="body2 mb-0">{ageRuleValue}</p>
                          <div>
                            <Body3>{client.ageRules?.includes(ageRuleValue) ? "Yes" : "No"}</Body3>
                          </div>
                        </div>
                      ),
                  )}

                {client.changesForDependentLifeBenefitsUseAge && (
                  <div data-testid="changesForDependentLifeBenefitsUseAgeConfigContainer">
                    <p className="body2 mb-0">Dependent Basic Life benefits</p>
                    <Body3>
                      {client.changesForDependentLifeBenefitsUseAge === "YES"
                        ? "Dependent basic life has age reductions and/or term age"
                        : "Dependent basic life does not have age reductions and/or term age"}
                    </Body3>
                  </div>
                )}

                {salaryBasedBenefits.length > 0 && (
                  <div data-testid="salaryBasedBenefitsConfigContainer">
                    <p className="body2 mb-0">Salary-based Benefits</p>
                    {salaryBasedBenefits.map((salaryBasedBenefit) => {
                      return (
                        <div key={salaryBasedBenefit.benefitType}>
                          <Body3>{benefitTypeToCoverage[salaryBasedBenefit.benefitType]}</Body3>
                        </div>
                      );
                    })}
                  </div>
                )}

                {DHMOPlans.length > 0 && (
                  <div data-testid="DHMOPlansConfigContainer">
                    <p className="body2 mb-0">Dental prepaid / DHMO plan(s)</p>
                    {DHMOPlans.map((DHMOPlan) => {
                      return (
                        <div key={DHMOPlan.benefitType}>
                          <Body3>{benefitTypeToCoverage[DHMOPlan.benefitType]}</Body3>
                        </div>
                      );
                    })}
                  </div>
                )}

                {PFMLASOPlans.length > 0 && (
                  <div data-testid="PFMLASOPlansConfigContainer">
                    <p className="body2 mb-0">PFML Plans</p>
                    <StackY dist={24}>
                      {PFMLASOPlans.map((PFMLPlan) => {
                        return (
                          <div key={PFMLPlan.benefitType}>
                            <p className="body3 mb-0">
                              {pfmlASOBenefitTypeToStateLabel[PFMLPlan.benefitType]}
                            </p>
                            {PFMLOptionDescription({ pfmlOption: PFMLPlan.pfmlOption })}
                          </div>
                        );
                      })}
                    </StackY>
                  </div>
                )}

                <div data-testid="authorizedSignerConfigContainer">
                  <p className="body2 mb-0">Authorized Signer</p>
                  {authorizedSignerUser ? (
                    <Body3>{formatFullName(authorizedSignerUser)}</Body3>
                  ) : client.outsideSigner ? (
                    <Body3>{formatFullName(client.outsideSigner)} (outside signer)</Body3>
                  ) : (
                    <Body2 redMedium>No signer selected.</Body2>
                  )}
                </div>

                {showDeductibleMaximumReportTaskInfo && (
                  <div data-testid="transferTreatmentConfigContainer">
                    <p className="body2 mb-0">Dental ASO Transfer treatment</p>
                    <Body3>{client.deductibleMaximumReportTask ? "Yes" : "No"}</Body3>
                  </div>
                )}
              </StackY>
            </Col>

            <Col xs={4}>
              <Row justify="end">
                <Col>
                  <Button type="text-only" onClick={() => setEditMode(true)}>
                    <Body2 blue>Edit</Body2>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export const SubmitCompanyInformationSettings = ({
  featureToggles,
}: {
  featureToggles: ClientFeatureToggles;
}) => {
  const { clientId } = useClientHubParams(["clientId"]);
  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientByID(clientId);

  const benAdminQueryParams = {
    page: 1,
    pageSize: BENADMIN_LIST_PAGE_SIZE,
    search: "",
    clientId,
  };

  const {
    data: benAdmins,
    isLoading: isLoadingBenAdmins,
    isError: isErrorBenAdmin,
    error: errorBenAdmin,
  } = useGetBenAdmins(benAdminQueryParams);

  const {
    data: brokers,
    isLoading: isLoadingBrokers,
    isError: isErrorBroker,
    error: errorBroker,
  } = useGetBrokers(benAdminQueryParams);

  const {
    data: plans,
    isLoading: isLoadingPlans,
    isError: isErrorPlan,
    error: errorPlan,
  } = useGetPlansByClientId(clientId);

  if (isLoadingClient || isLoadingBenAdmins || isLoadingBrokers || isLoadingPlans) {
    return <Loading />;
  }

  if (isErrorClient) {
    triggerError(errorClient);
  }

  if (isErrorBenAdmin) {
    triggerError(errorBenAdmin);
  }

  if (isErrorBroker) {
    triggerError(errorBroker);
  }

  if (isErrorPlan) {
    triggerError(errorPlan);
  }

  const showDeductibleMaximumReportTaskInfo =
    (featureToggles.ONBOARD_ASO_DENTAL &&
      client?.allPoliciesSlfCoverages?.includes("ASO Dental")) ??
    false;

  return (
    <>
      {client && benAdmins && plans && brokers && (
        <SubmitCompanyInformationSettingsPresentation
          benAdmins={benAdmins.data}
          brokers={brokers.data}
          client={client}
          plans={plans}
          featureToggles={featureToggles}
          showDeductibleMaximumReportTaskInfo={showDeductibleMaximumReportTaskInfo}
        />
      )}
    </>
  );
};
