import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import * as styles from "./tagRender.module.less";
import type { LabeledValue } from "antd/lib/select";
import type {
  SlobCustomTagProps,
  TagLabelRender,
} from "client/src/components/MultiSelect/SlobSelectMultiple";

export type TagRender<T extends LabeledValue["value"]> = (
  props: SlobCustomTagProps<T>,
) => React.ReactElement;

export function getTagRenderFunction<T extends LabeledValue["value"]>(
  labelRender?: TagLabelRender<T>,
): TagRender<T> {
  return (props: SlobCustomTagProps<T>) => {
    return tagRender(props, labelRender);
  };
}

export function tagRender<T extends LabeledValue["value"]>(
  props: SlobCustomTagProps<T>,
  labelRender?: TagLabelRender<T>,
): React.ReactElement {
  const { onClose, closable } = props;

  const label = labelRender ? labelRender(props) : props.label;

  return (
    <span className={clsx(styles.tag, !closable && styles.tagDisabled)}>
      <span className={styles.tagContent}>{label}</span>

      {closable && (
        <button className={styles.tagRemove} onClick={onClose} title="Remove tag" type="button">
          <FontAwesomeIcon icon={faTimes} />
          <span className="sr-only">Remove tag</span>
        </button>
      )}
    </span>
  );
}
