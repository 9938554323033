import { Tooltip as AntTooltip } from "antd";

import clsx from "clsx";
import * as styles from "./tooltip.module.less";

import type { AbstractTooltipProps, RenderFunction } from "antd/lib/tooltip";

type SlobAbstractTooltipProps = Omit<AbstractTooltipProps, "className" | "overlayClassName"> & {
  large?: boolean;
};

interface TooltipPropsWithOverlay extends SlobAbstractTooltipProps {
  title?: React.ReactNode | RenderFunction;
  overlay: React.ReactNode | RenderFunction;
}
interface TooltipPropsWithTitle extends SlobAbstractTooltipProps {
  title: React.ReactNode | RenderFunction;
  overlay?: React.ReactNode | RenderFunction;
}

type Props = TooltipPropsWithOverlay | TooltipPropsWithTitle;

export function Tooltip(props: Props) {
  return (
    <AntTooltip overlayClassName={clsx(styles.tooltip, props.large && styles.large)} {...props} />
  );
}
