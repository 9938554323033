import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body5, Body3 } from "client/src/components/Typography/Typography";
import { formatFullName } from "shared/utils/format";
import { formatLocation } from "shared/utils/location";
import type { BillSplitType } from "@prisma/client";
import type { FormikErrors } from "formik";
import type { Bill } from "shared/types/Bill";
import type { BillFormValues } from "shared/validation/bill";

type Props = {
  bill: Pick<
    Bill,
    | "hasDifferentMailingAddress"
    | "location"
    | "numberOfEmployees"
    | "groupedByLocations"
    | "splitTags"
    | "contact"
  >;
  prefillErrors: FormikErrors<
    Pick<
      BillFormValues,
      | "contactId"
      | "locationId"
      | "numberOfEmployees"
      | "groupByLocationIds"
      | "splitTags"
      | "slfCoverages"
    >
  >;
  billSplitType: BillSplitType | null | undefined;
  showDetails: boolean;
};

export function BillSharedProps(props: Props) {
  const { bill, prefillErrors: e, billSplitType, showDetails } = props;

  const showPrefillErrors = Object.keys(e).length > 0;

  return (
    <StackY dist={24} wrap={false}>
      <div>
        <Body5 as="div">Billing Address</Body5>
        {bill.hasDifferentMailingAddress ? (
          bill.location && <Body3 as="div">{formatLocation(bill.location)}</Body3>
        ) : bill.contact?.location ? (
          <Body3 as="div">{formatLocation(bill.contact.location)}</Body3>
        ) : (
          !showPrefillErrors && <Body3 as="div">-</Body3>
        )}

        {bill.hasDifferentMailingAddress
          ? e.locationId && <Body3 redMedium>{e.locationId}</Body3>
          : e.contactId && <Body3 redMedium>{e.contactId}</Body3>}
      </div>

      {billSplitType === "LOCATION" && (
        <>
          <div>
            <Body5 as="div">Location(s) on this bill</Body5>

            {bill.groupedByLocations?.map((loc) => (
              <Body3 as="div" key={loc.id}>
                {formatLocation(loc)}
              </Body3>
            ))}

            {!bill.groupedByLocations?.length && !showPrefillErrors && <Body3 as="div">-</Body3>}

            {e.groupByLocationIds && <Body3 redMedium>{e.groupByLocationIds}</Body3>}
          </div>
        </>
      )}

      {billSplitType === "TAGS" && (
        <>
          <div>
            <Body5 as="div">Groups included in this bill</Body5>
            {bill.splitTags && <Body3 as="div">{bill.splitTags.join(", ")}</Body3>}

            {!bill.splitTags?.length && !showPrefillErrors && <Body3 as="div">-</Body3>}

            {e.splitTags && <Body3 redMedium>{e.splitTags}</Body3>}
          </div>
        </>
      )}

      {showDetails && (
        <>
          <Row gutter={32}>
            <Col>
              <Body5 as="div">Administrator</Body5>
              {bill.contact ? (
                <Body3 as="div">{formatFullName(bill.contact)}</Body3>
              ) : (
                !showPrefillErrors && <Body3 as="div">-</Body3>
              )}

              {e.contactId && <Body3 redMedium>{e.contactId}</Body3>}
            </Col>

            <Col>
              <Body5 as="div">Email address</Body5>
              {bill.contact?.email ? (
                <Body3 as="div">{bill.contact.email}</Body3>
              ) : (
                !showPrefillErrors && <Body3 as="div">-</Body3>
              )}

              {e.contactId && <Body3 redMedium>{e.contactId}</Body3>}
            </Col>
          </Row>

          <div>
            <Body5 as="div"># of employees</Body5>
            {bill.numberOfEmployees ? (
              <Body3 as="div">{bill.numberOfEmployees}</Body3>
            ) : (
              !showPrefillErrors && <Body3 as="div">-</Body3>
            )}

            {e.numberOfEmployees && <Body3 redMedium>{e.numberOfEmployees}</Body3>}
          </div>
        </>
      )}
    </StackY>
  );
}
