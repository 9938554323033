import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { CollapsePanel } from "client/src/components/Collapse/CollapsePanel";
import { Body3, Body2, Body4 } from "client/src/components/Typography/Typography";
import { ClientErrorsDrawer } from "client/src/domain/Client/ClientErrors/ClientErrorsDrawer";
import { clientErrorLabel, clientErrorWarningTypes } from "client/src/hooks/clientError";
import { useHubConfiguration } from "client/src/hooks/useConfig";
import pluralize from "pluralize";
import { useState } from "react";
import { unique } from "shared/utils/utils";
import { ReactComponent as WarningInfo } from "../../../components/Icons/WarningInfo.svg";
import * as style from "./ClientInternalErrorBanner.module.less";
import * as styleBanner from "./clientErrorsBanner.module.less";
import type { ClientError, ClientErrorType } from "@prisma/client";
import type { Client } from "shared/types/Client";

type SelectedClientError = {
  errorType: ClientErrorType;
  data: ClientError[];
  client: Client;
};

type ClientInternalErrorBannerProps = {
  client: Client;
};

export const ClientInternalErrorBanner = ({ client }: ClientInternalErrorBannerProps) => {
  const config = useHubConfiguration();
  const [selectedClientErrors, setSelectedClientErrors] = useState<SelectedClientError>();

  const clientAlertErrors =
    client.clientErrors?.filter((e) => !clientErrorWarningTypes.includes(e.errorType)) || [];
  const clientAlertErrorsTypes = unique(clientAlertErrors.map((i) => i.errorType));
  const clientAlertWarnings =
    client.clientErrors?.filter((e) => clientErrorWarningTypes.includes(e.errorType)) || [];
  const clientAlertWarningTypes = unique(clientAlertWarnings.map((i) => i.errorType));

  const totalAlerts = clientAlertErrorsTypes.length + clientAlertWarningTypes.length;

  if (!totalAlerts) return null;

  return (
    <>
      <ClientErrorsDrawer
        jiraURL={config.REACT_APP_JIRA_URL}
        data={selectedClientErrors}
        onClose={() => setSelectedClientErrors(undefined)}
      />
      <CollapsePanel
        variant="dashed"
        noPaddingContent
        content={[
          {
            key: "test",
            title: (
              <>
                <Body2 darkYellow>Internal user:</Body2>
                <Body4> {totalAlerts} </Body4>
                <Body3>client {pluralize("issue", totalAlerts)}</Body3>
              </>
            ),
            description: (
              <div className={style.wrapper}>
                {clientAlertErrorsTypes.map((error) => (
                  <div className={style.row} key={error}>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      className={styleBanner.iconError}
                    />
                    <span className="ml-8">
                      <Body3>{clientErrorLabel[error]}</Body3>
                    </span>
                    <div className={style.takeActionButton}>
                      <Button
                        type="secondary"
                        size="xtra-small"
                        onClick={() =>
                          setSelectedClientErrors({
                            errorType: error,
                            data: clientAlertErrors.filter((e) => e.errorType === error),
                            client,
                          })
                        }
                      >
                        Take action
                      </Button>
                    </div>
                  </div>
                ))}
                {clientAlertWarningTypes.map((warning) => (
                  <div className={style.row} key={warning}>
                    <WarningInfo width={16} height={16} />
                    <span className="ml-8">
                      <Body3>{clientErrorLabel[warning]}</Body3>
                    </span>
                    <div className={style.takeActionButton}>
                      <Button
                        type="secondary"
                        size="xtra-small"
                        onClick={() =>
                          setSelectedClientErrors({
                            errorType: warning,
                            data: clientAlertWarnings.filter((e) => e.errorType === warning),
                            client,
                          })
                        }
                      >
                        Take action
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
      />
    </>
  );
};
