import { Row, Col } from "client/src/components/Grid/Grid";
import { assertIsDefined } from "shared/utils/utils";
import { PlanTypeData } from "../../../../../../shared/data/ExplorerBenefit";
import { isSunlifeCarrier } from "../../../../../../shared/types/ExplorerPageBenefit";
import { CollapsePanel } from "../../../../components/Collapse/CollapsePanel";
import { Body1, Body3, H2 } from "../../../../components/Typography/Typography";
import {
  UnorderedList,
  UnorderedListItem,
} from "../../../../components/UnorderedList/UnorderedList";
import { useEmployeeLocation } from "../../../../hooks/useSlobLocalStorage";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey, copyKeyExists, copyKeySwitch } from "../../utils";

import type { Content } from "../../../../components/Collapse/CollapsePanel";
import type { CopySwitch } from "../../utils";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  faqWrapper: "faq-wrapper",
  faqQuestion: "faq-question",
};

type FAQCopyStructure = {
  question: string;
  answer: string;
};
export type ExplorerBenefitFaqCopyStructure = {
  heading: string;
  DHMO_TX: string;
  DHMO_FL: string;
  dentalClaimsNumber: string;
  MEC_MESSAGE: string;
} & {
  [key in Exclude<BenefitTypeBenEx, "OTHER" | "FINANCIAL">]: CopySwitch<
    "Sun Life",
    CopySwitch<PlanType | "MEC_COMBINED", FAQCopyStructure[]>
  >;
};

type ExplorerBenefitFaqProps = {
  benefitType: BenefitTypeBenEx;
  alternateName: string;
  plans: ExplorerPageBenefit[];
  largeClientFeatureToggle: boolean;
};

export const ExplorerBenefitFaq = ({
  benefitType,
  alternateName,
  plans,
  largeClientFeatureToggle,
}: ExplorerBenefitFaqProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitFaq" });
  const [employeePrepaidAndChatLocation] = useEmployeeLocation();

  const planTypes = plans.reduce<PlanType[]>((acc, { planType }) => {
    const adjustedType = planType === "ASO_PPO" ? "PPO" : planType;
    if (adjustedType && !acc.includes(adjustedType)) acc.push(adjustedType);
    return acc;
  }, []);

  const hasSunLifePlans = plans.find((p) => isSunlifeCarrier(p.carrierId));

  const carrierKey = copyKeySwitch(t, benefitType, hasSunLifePlans ? "Sun Life" : null);

  const hasMECCombinedPlan = planTypes.length > 1 && planTypes.find((p) => p === "MEC");

  const variant = hasMECCombinedPlan
    ? "MEC_COMBINED"
    : planTypes.length === 1 && copyKeyExists(t, benefitType, carrierKey, planTypes[0])
    ? planTypes[0]
    : "default";
  assertIsDefined(variant, "variant");

  const baseKey = [benefitType, carrierKey, variant];

  const localizedPlanName =
    variant !== "default" && variant !== "MEC_COMBINED"
      ? t(`${variant}_${employeePrepaidAndChatLocation}`, {
          defaultValue: PlanTypeData[variant].displayName,
        })
      : variant;

  const faq = t(copyKey(...baseKey), {
    returnObjects: true,
    defaultValue: [],
  });

  if (!faq || !Array.isArray(faq)) {
    return null;
  }
  const faqCount = faq.length;

  const numberCopy = !largeClientFeatureToggle ? t("dentalClaimsNumber") : "";
  const amountCopy = !largeClientFeatureToggle ? "$300" : "$500";

  return (
    <div data-testid={DataTestId.faqWrapper}>
      <Row wrap={false} gutter={9} justify="space-between">
        <Col>
          <H2>{t("heading")}</H2>
        </Col>
        {hasMECCombinedPlan && (
          <Col>
            <Body3>{t("MEC_MESSAGE")}</Body3>
          </Col>
        )}
      </Row>

      <CollapsePanel
        variant="zebra"
        content={[...Array(faqCount)].map<Content>((_, i) => ({
          key: i.toString(),
          title: (
            <div className="p-12" data-testid={DataTestId.faqQuestion}>
              <Body1>
                {t(copyKey(...baseKey, i.toString(), "question"), {
                  planName: localizedPlanName,
                })}
              </Body1>
            </div>
          ),
          description: (
            <div className="p-12">
              <Body3>
                <Trans
                  t={t}
                  i18nKey={copyKey(...baseKey, i.toString(), "answer")}
                  values={{
                    alternateName: `${alternateName}`,
                    numberCopy: `${numberCopy}`,
                    amountCopy: `${amountCopy}`,
                  }}
                >
                  <UnorderedList>
                    <UnorderedListItem>item</UnorderedListItem>
                  </UnorderedList>
                </Trans>
              </Body3>
            </div>
          ),
        }))}
      />
    </div>
  );
};
