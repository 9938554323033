import { Typography } from "antd";
import { Ribbon } from "client/src/components/Ribbon/Ribbon";
import { listFormat } from "shared/utils/format";
import type { Client } from "shared/types/Client";

const { Title } = Typography;

export function EmailViewHeader({ client }: { client: Client | undefined }) {
  const slfPolicyNumbers =
    client?.policies
      .map((p) => p.slfPolicyNumber)
      .filter((p): p is string => p != null)
      .map((n) => "#" + n) ?? [];

  const slfPolicyNumbersList = listFormat(slfPolicyNumbers, {
    type: "conjunction",
    style: "narrow",
  });

  const ribbonTexts = client
    ? [client.isTest && "Test", !client.isActive && "Draft"].filter((s): s is string => !!s)
    : [];

  return (
    <>
      {client ? (
        <div>
          <Title style={{ margin: 0 }}>{client.name}</Title>
          {client.caseId && (
            <Title level={2} style={{ margin: 0 }}>
              Case ID: {client.caseId}
            </Title>
          )}
          {slfPolicyNumbers.length > 0 && (
            <Title level={3} style={{ margin: 0 }}>
              Policy Number(s): {slfPolicyNumbersList}
            </Title>
          )}

          <Ribbon position="top-left" text={ribbonTexts} />
        </div>
      ) : (
        <Title style={{ margin: 0 }}>Emails</Title>
      )}
    </>
  );
}
