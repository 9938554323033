import type {
  BillLocation,
  ContactLocation,
  MonthlyClaimsReportMailingLocation,
} from "@prisma/client";
import type { Bill, BillId } from "shared/types/Bill";
import type { Client, ClientId, Policy, PolicyId } from "shared/types/Client";
import type { Contact, ContactId } from "shared/types/Contact";
import type { EmployeeClass, EmployeeClassId } from "shared/types/EmployeeClass";
import type { EmployeeClassPlan, EmployeeClassPlanId } from "shared/types/EmployeeClassPlan";
import type { Location, LocationId } from "shared/types/Location";
import type { Plan, PlanId } from "shared/types/Plan";
import type { Subsidiary, SubsidiaryId } from "shared/types/Subsidiary";
import type { User, UserId } from "shared/types/User";

type ChangeLogId = string;
type ChangeDetailId = string;
type ChangeType = "CREATE" | "UPDATE" | "DELETE";
type ChangeStatus = "pending" | "accepted" | "declined";

export type ChangeWithDetails = {
  id: ChangeLogId;
  entity: string;
  entityId: string;
  changeType: ChangeType;
  correlationId: string;
  clientId: ClientId;
  createdBy: UserId;
  createdAt: Date;
  createdByUser: User;
  changeDetails: ChangeDetail[];
  metadata?: ChangeMetadata;
};

export type ChangeDetail = {
  id: ChangeDetailId;
  status: ChangeStatus;
  attributeKey: string;
  value: string | null;
  previousValue: string | null;
  createdAt: Date;
};

export type ChangeMetadata = {
  parentId: string;
};

export const deifChangeSnapshotEntities = [
  "Client",
  "Policy",
  "EmployeeClass",
  "EmployeeClassPlan",
  "Bill",
  "BillLocation",
  "Contact",
  "Location",
  "ContactLocation",
  "Plan",
  "MonthlyClaimsReportMailingLocation",
  "Subsidiary",
] as const;
export type DEIFSnapshotEntity = (typeof deifChangeSnapshotEntities)[number];

export type ChangeDetailInfo = {
  status: ChangeStatus;
  currentValue: {
    value: string | string[] | null;
    user: User | null;
    date: Date;
  };
  previousValue: {
    value: string | string[] | null;
    date: Date;
  };
};

type ChangeDetailRecord<Keys extends string> = Partial<Record<Keys, ChangeDetailInfo>>;

export type ChangeDetailRecordWithMetadata<Keys extends string> = {
  metadata?: ChangeMetadata;
} & ChangeDetailRecord<Keys>;

export type DEIFChangeSnapshot = {
  Client: ChangeDetailRecord<keyof Client>;
  Policy: Partial<Record<PolicyId, ChangeDetailRecordWithMetadata<keyof Policy>>>;
  EmployeeClass: Partial<
    Record<EmployeeClassId, ChangeDetailRecordWithMetadata<keyof EmployeeClass>>
  >;
  EmployeeClassPlan: Partial<
    Record<EmployeeClassPlanId, ChangeDetailRecordWithMetadata<keyof EmployeeClassPlan>>
  >;
  Contact: Partial<Record<ContactId, ChangeDetailRecordWithMetadata<keyof Contact>>>;
  Bill: Partial<Record<BillId, ChangeDetailRecordWithMetadata<keyof Bill>>>;
  Location: Partial<Record<LocationId, ChangeDetailRecordWithMetadata<keyof Location>>>;
  BillLocation: Partial<Record<string, ChangeDetailRecordWithMetadata<keyof BillLocation>>>;
  ContactLocation: Partial<Record<string, ChangeDetailRecordWithMetadata<keyof ContactLocation>>>;
  Plan: Partial<Record<PlanId, ChangeDetailRecordWithMetadata<keyof Plan>>>;
  MonthlyClaimsReportMailingLocation: Partial<
    Record<string, ChangeDetailRecordWithMetadata<keyof MonthlyClaimsReportMailingLocation>>
  >;
  Subsidiary: Partial<Record<SubsidiaryId, ChangeDetailRecordWithMetadata<keyof Subsidiary>>>;
};

export function isDEIFChangeSnapshotEntity(entity: string): entity is DEIFSnapshotEntity {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
  return deifChangeSnapshotEntities.includes(entity as DEIFSnapshotEntity);
}

export function isChangeDetailInfo(
  value: ChangeMetadata | ChangeDetailInfo | undefined | null | unknown,
): value is ChangeDetailInfo {
  return (
    value != null &&
    typeof value === "object" &&
    "currentValue" in value &&
    "previousValue" in value
  );
}
