import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { WelcomeSingleStep } from "client/src/pages/Welcome/WelcomeSingleStep";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { Loading } from "../../components/Loading/Loading";
import { useGetClientByID, useWelcomeFlow } from "../../hooks/client";
import { triggerError } from "../../hooks/generalError";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useTitle } from "../../hooks/useTitle";

export function WelcomePage() {
  useTitle("Welcome");

  const { clientId } = useClientHubParams(["clientId"]);

  const { data, error: errorFlow, isLoading: isLoadingFlow } = useWelcomeFlow(clientId ?? "");

  const {
    data: featureToggles,
    isLoading: isLoadingFeatureToggles,
    error: errorFeatureToggles,
  } = useGetFeatureToggles(clientId);

  const {
    isLoading: isLoadingClient,
    error: errorClient,
    data: clientData,
  } = useGetClientByID(clientId);

  const isLoading = isLoadingFlow || isLoadingClient || isLoadingFeatureToggles;

  if (isLoading) {
    return <Loading />;
  }

  const error = errorFlow || errorClient || errorFeatureToggles;

  if (!data || error || !clientId || !clientData || !featureToggles) {
    return triggerError(error ?? "Couldn't get welcome flow");
  }

  if (data.welcomeFlowStatus === "COMPLETED") {
    return <Navigate to={RouteData.homeHub.getPath(clientId)} />;
  }

  return (
    <>
      <WelcomeSingleStep client={clientData} />
    </>
  );
}
