import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { compareQueryKey, useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import { useMemo } from "react";
import type { UserData } from "shared/rbac/rbac";
import type { Client } from "shared/types/Client";
import type {
  OutsideSignerAuthUser,
  ReviewICEditsAction,
  ResendOutsideSignerEmailOutput,
} from "shared/types/OutsideSigner";

export type OutsideSignEIFQuery = Pick<
  ReturnType<typeof useOutsideSignEIF>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export type OutsideSignEIFFunc = OutsideSignEIFQuery["mutateAsync"];

export type OutsideSignEIFInput = {
  eifSignedAt: Date;
};

export const useOutsideSignEIF = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<OutsideSignEIFInput, Client, `/api/outside-signer/:clientId/sign-eif`>({
    method: "post",
    path: `/api/outside-signer/:clientId/sign-eif`,
    options: {
      async onSuccess({ data: client }, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/bills`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/contacts`]),
          }),
          invalidateDEIFChanges(queryClient, client.id),
        ]);

        queryClient.setQueryData(["get", `/api/clients/${clientId}`], client);
      },
    },
  });
};

type ReviewICEditsActionDecline = {
  reviewICEditsAction: Extract<ReviewICEditsAction, "decline">;
  declineReason: string;
};

type ReviewICEditsActionAccept = {
  reviewICEditsAction: Extract<ReviewICEditsAction, "accept">;
};

type OutsideSignerReviewICEditsInput = ReviewICEditsActionAccept | ReviewICEditsActionDecline;

export const useOutsideSignerReviewICEdits = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    OutsideSignerReviewICEditsInput,
    Client,
    `/api/outside-signer/:clientId/review-ic-edits`
  >({
    method: "post",
    path: `/api/outside-signer/:clientId/review-ic-edits`,
    options: {
      async onSuccess({ data: client }, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/bills`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/contacts`]),
          }),
          invalidateDEIFChanges(queryClient, client.id),
        ]);
      },
    },
  });
};

const useGetOutsideSignerAuth = () =>
  useSlobQuery<OutsideSignerAuthUser>({
    method: "get",
    path: "/api/outside-signer/authenticated",
    map: (data) => {
      const authUser: UserData = {
        ...data.authUser,
        termsOfUseDate: null,
      };

      return {
        ...data,
        authUser,
      };
    },
    options: {
      staleTime: 0,
      gcTime: 0,
      refetchOnWindowFocus: "always",
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  });

export const useOutsideSignerAuth = () => {
  const auth = useGetOutsideSignerAuth();

  return useMemo(() => {
    const authUser = auth?.data?.authUser ?? null;
    const tokenStatus = auth?.data?.tokenStatus ?? null;
    return {
      isLoading: auth.isLoading,
      authUser,
      tokenStatus,
    };
  }, [auth.isLoading, auth?.data]);
};

export const useResendOutsideSignerEmail = () => {
  return useSlobMutation<
    void,
    ResendOutsideSignerEmailOutput,
    `/api/outside-signer/:clientId/resend-email`
  >({
    method: "post",
    path: `/api/outside-signer/:clientId/resend-email`,
  });
};
