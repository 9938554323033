import React from "react";

type ActiveSection =
  | "EnrollmentEventForm"
  | "BenefitsAdministrationForm"
  | "DataFeedsForm"
  | "SetYourGoals";

export function useToggleActiveSection() {
  const [activeSection, toggleActiveSection] = React.useReducer<
    React.Reducer<ActiveSection | null, ActiveSection>
  >((state, action) => {
    return state == null ? action : null;
  }, null);

  type CompanyProfileSectionStatus = "idle" | "editing" | "disabled";

  const getStatus = (section: ActiveSection): CompanyProfileSectionStatus => {
    const status =
      activeSection == null ? "idle" : activeSection === section ? "editing" : "disabled";
    return status;
  };

  const statusEnrollmentEventForm = getStatus("EnrollmentEventForm");
  const statusBenefitsAdministrationForm = getStatus("BenefitsAdministrationForm");
  const statusDataFeedsForm = getStatus("DataFeedsForm");
  const statusSetYourGoals = getStatus("SetYourGoals");

  const toggleEnrollmentEventForm = React.useCallback(
    () => toggleActiveSection("EnrollmentEventForm"),
    [],
  );
  const toggleBenefitsAdministrationForm = React.useCallback(
    () => toggleActiveSection("BenefitsAdministrationForm"),
    [],
  );
  const toggleDataFeedsForm = React.useCallback(() => toggleActiveSection("DataFeedsForm"), []);
  const toggleSetYourGoals = React.useCallback(() => toggleActiveSection("SetYourGoals"), []);

  return {
    statusEnrollmentEventForm,
    statusBenefitsAdministrationForm,
    statusDataFeedsForm,
    statusSetYourGoals,
    toggleEnrollmentEventForm,
    toggleBenefitsAdministrationForm,
    toggleDataFeedsForm,
    toggleSetYourGoals,
  };
}
