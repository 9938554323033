import {
  slfCoverageNameToLongName,
  slfCoverageLongNameToShortName,
  DentalCoverageNames,
  beneficiaryDesignationCoverages,
} from "shared/types/SlfCoverages";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { unique } from "shared/utils/utils";
import {
  beneficiaryDesignationCoveragesToDocumentBenefitType,
  type DocumentBenefitType,
} from "../types/DocumentBenefitType";
import type { Client, ClientInput, ClientType } from "../types/Client";
import type { SlfCoverageLink } from "../types/Ticket";
import type { SlfCoverageName } from "shared/types/SlfCoverages";

type GetClientTypeOptions = {
  useHealthcareProfessionalsSegment: boolean;
};

export const getClientType = (
  client: Client,
  options: GetClientTypeOptions,
): Exclude<ClientType, null> => {
  if (client.employeesNumber == null || isNaN(client.employeesNumber)) {
    return "U100";
  }
  // https://maxwellhealth.atlassian.net/browse/FP-3769
  if (options.useHealthcareProfessionalsSegment && client.healthcareProfessionalsSegment) {
    return "HEALTHPRO";
  }

  if (client.employeesNumber >= 100) {
    return "O100";
  }

  const hasMVPtier = client.mvpBrokerTier;
  if (hasMVPtier) {
    return "U100MVP";
  }

  return "U100";
};
export type ClientSetupStatus = "Needs Review";

export function hasEnrollmentEventSectionChanged(
  prevClient: Client,
  nextClient: Partial<ClientInput> | undefined,
): { hasChanged: boolean; firstChange: boolean } {
  const firstTimeChanged = prevClient.enrollmentEventUpdatedAt === null;
  const areEqual =
    prevClient.upcomingEnrollment === nextClient?.upcomingEnrollment &&
    prevClient.enrollmentDateStart?.getTime() === nextClient?.enrollmentDateStart?.getTime() &&
    prevClient.enrollmentDateEnd?.getTime() === nextClient?.enrollmentDateEnd?.getTime() &&
    prevClient.dontKnowEnrollmentEventDates === nextClient?.dontKnowEnrollmentEventDates;
  return { hasChanged: !areEqual, firstChange: firstTimeChanged };
}

export function hasSetYourGoalsSectionChanged(
  prevClient: Client,
  nextClient: Partial<ClientInput> | undefined,
): { hasChanged: boolean; firstChange: boolean } {
  const propsToCheck: (keyof ClientInput)[] = [
    "enrollmentSupport",
    "streamlinedTechnology",
    "valueAddedServices",
    "billing",
    "ongoingService",
    "additionalSupport",
    "otherTopicsToPayAttentionTo",
    "otherTopicsToPayAttentionToText",
    "productVariety",
    "recommendationFromBroker",
    "qualityCoverage",
    "digitalCapabilities",
    "service",
    "cost",
    "claimsManagement",
    "mostImportantOther",
    "mostImportantOtherText",
  ];

  const firstTimeChanged = prevClient.setYourGoalsUpdatedAt === null;
  const areDifferent = propsToCheck.some((prop) => {
    const prev = prevClient[prop];
    const next = nextClient?.[prop];

    if (typeof prev === "string" || typeof next === "string") {
      return prevClient[prop] !== nextClient?.[prop];
    } else {
      return Boolean(prev) !== Boolean(next);
    }
  });

  return { hasChanged: areDifferent, firstChange: firstTimeChanged };
}

export const CLM_EMAIL = "clm@sunlife.com";
export const CLIENT_SERVICE_EMAIL = "clientservices@sunlife.com";

export type OngoingServiceTeamContact = {
  role: "Client Relationship Executive" | "Client Loyalty Manager" | "Client Services Support Team";
  contacts: Array<{
    name?: string;
    phoneNumber?: string;
    email: string;
  }>;
};

function getCreCssEmailAddressEmailContacts(
  creCssEmailAddress: string | null | undefined,
  creCssName: string | null | undefined,
): OngoingServiceTeamContact | null {
  if (!creCssEmailAddress) return null;
  const emails = creCssEmailAddress
    .split(/[,;\s]/)
    .map((e) => e.trim())
    .filter(Boolean);
  const names: string[] = creCssName
    ? creCssName
        .split(/[,;]/)
        .map((e) => e.trim())
        .filter(Boolean)
    : [];
  const contacts = emails.map((email, index) => ({
    name: names[index],
    email,
  }));
  return { role: "Client Relationship Executive", contacts };
}

type GetOngoingServiceTeamContactsOptions = {
  useHealthcareProfessionalsSegment: boolean;
};

export function getOngoingServiceTeamContacts(
  client: Client,
  options: GetOngoingServiceTeamContactsOptions,
): {
  contacts: OngoingServiceTeamContact | null;
  isCRE: boolean;
  clientType: ClientType | undefined;
} {
  if (client.employeesNumber == null) {
    return { contacts: null, isCRE: false, clientType: undefined };
  }
  const type = getClientType(client, {
    useHealthcareProfessionalsSegment: options.useHealthcareProfessionalsSegment,
  });
  switch (type) {
    case "O100":
    case "HEALTHPRO":
      if (!client.creCssEmailAddress)
        return { contacts: null, isCRE: false, clientType: undefined };
      return {
        contacts: getCreCssEmailAddressEmailContacts(client.creCssEmailAddress, client.creCssName),
        isCRE: true,
        clientType: type,
      };
    case "U100MVP":
      return {
        contacts: { role: "Client Loyalty Manager" as const, contacts: [{ email: CLM_EMAIL }] },
        isCRE: false,
        clientType: type,
      };
    case "U100":
      return {
        contacts: {
          role: "Client Services Support Team" as const,
          contacts: [
            {
              email: CLIENT_SERVICE_EMAIL,
              phoneNumber: "800-247-6875",
            },
          ],
        },
        isCRE: false,
        clientType: type,
      };
    default:
      exhaustiveCheck(type);
      break;
  }
  return { contacts: null, isCRE: false, clientType: undefined };
}

const getDentalPlanTipsFlyer = (slfCoverageName: SlfCoverageName[] | null): SlfCoverageLink => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const benefitTypes = ([] as DocumentBenefitType[])
    .concat(slfCoverageName?.includes("Dental") ? "DENTAL" : [])
    .concat(slfCoverageName?.includes("ASO Dental") ? "DENTAL_ASO" : [])
    .concat(slfCoverageName?.includes("Dental (DHMO)") ? "DENTAL_DHMO" : []);

  return {
    text: "Tips for Using Your Dental Plan",
    url: `https://p1.aprimocdn.net/sunlife/e30f7f0a-4cb2-4dca-a07a-afa10163fdeb/gdfl-6866-np-dental-tips-for-members-original-file.pdf`,
    benefitTypes,
  };
};

const getSlfCoverageLinks = (
  slfCoverages: SlfCoverageName[] | null,
  coverageToLinks: Map<SlfCoverageName, SlfCoverageLink[]>,
  addDentalPlanTip = false,
): SlfCoverageLink[] => {
  const coverageLinks: SlfCoverageLink[] = [];
  let currentIndex = 0;
  let lastDentalIndex = -1;
  slfCoverages?.forEach((slfCoverage) => {
    const linksForCoverage = coverageToLinks.get(slfCoverage);
    if (linksForCoverage) {
      linksForCoverage.forEach((linkForCoverage) => {
        if (DentalCoverageNames.includes(slfCoverage)) {
          lastDentalIndex = currentIndex;
        }
        coverageLinks.push(linkForCoverage);
        currentIndex += 1;
      });
    }
  });
  if (lastDentalIndex >= 0 && addDentalPlanTip) {
    const DentalPlanTipsFlyer = getDentalPlanTipsFlyer(slfCoverages);
    coverageLinks.splice(lastDentalIndex + 1, 0, DentalPlanTipsFlyer);
  }
  const coverageLinksSorted = coverageLinks.sort((a, b) => a.text.localeCompare(b.text));
  return coverageLinksSorted;
};

const SlfCoverageToBenefitClips: Map<SlfCoverageName, SlfCoverageLink[]> = new Map([
  [
    "VSTD",
    [
      {
        text: slfCoverageNameToLongName["VSTD"],
        url: `https://www.sunlife.com/whybuyshorttermdisability`,
        benefitTypes: ["VOL_STD"],
      },
    ],
  ],
  [
    "VLTD",
    [
      {
        text: slfCoverageNameToLongName["VLTD"],
        url: `https://www.sunlife.com/whybuylongtermdisability`,
        benefitTypes: ["VOL_LTD"],
      },
    ],
  ],
  [
    "Vol Life",
    [
      {
        text: slfCoverageNameToLongName["Vol Life"],
        url: `https://www.sunlife.com/whybuylife`,
        benefitTypes: ["VOL_LIFE"],
      },
    ],
  ],
  [
    "Accident",
    [
      {
        text: slfCoverageNameToLongName["Accident"],
        url: `https://www.sunlife.com/whybuyaccident`,
        benefitTypes: ["ACCIDENT"],
      },
    ],
  ],
  [
    "CI",
    [
      {
        text: slfCoverageNameToLongName["CI"],
        url: `https://www.sunlife.com/whybuycriticalillness`,
        benefitTypes: ["CRITICAL_ILLNESS"],
      },
    ],
  ],
  [
    "HI",
    [
      {
        text: slfCoverageNameToLongName["HI"],
        url: `https://www.sunlife.com/whybuyhospitalindemnity`,
        benefitTypes: ["HOSPITAL_INDEMNITY"],
      },
    ],
  ],
  [
    "Cancer",
    [
      {
        text: slfCoverageNameToLongName["Cancer"],
        url: `https://www.sunlife.com/whybuycancer`,
        benefitTypes: ["CANCER"],
      },
    ],
  ],
  [
    "Vision",
    [
      {
        text: slfCoverageNameToLongName["Vision"],
        url: `https://www.sunlife.com/whybuyvision`,
        benefitTypes: ["VISION"],
      },
    ],
  ],
  [
    "Dental",
    [
      {
        text: "Why Buy Dental Insurance",
        url: `https://www.sunlife.com/whybuydental`,
        benefitTypes: ["DENTAL"],
      },
      {
        text: "How to find your dental ID card",
        url: `https://share.vidyard.com/watch/FfWX1YHtjvKDUJ4DdwPn5x`,
        benefitTypes: ["DENTAL"],
      },
      {
        text: "Find a dentist demo",
        url: `https://share.vidyard.com/watch/jdctRJ68H7r2XAQGfsf9qD`,
        benefitTypes: ["DENTAL"],
      },
    ],
  ],
  [
    "Dental (DHMO)",
    [
      {
        text: "Why Buy Pre-Paid DHMO Dental",
        url: `https://www.sunlife.com/whybuyprepaiddental`,
        benefitTypes: ["DENTAL_DHMO"],
      },
      {
        text: "How to find your dental ID card",
        url: `https://share.vidyard.com/watch/FfWX1YHtjvKDUJ4DdwPn5x`,
        benefitTypes: ["DENTAL_DHMO"],
      },
      {
        text: "Find a dentist demo",
        url: `https://share.vidyard.com/watch/jdctRJ68H7r2XAQGfsf9qD`,
        benefitTypes: ["DENTAL_DHMO"],
      },
    ],
  ],
  [
    "ASO Dental",
    [
      {
        text: "How to find your dental ID card",
        url: `https://share.vidyard.com/watch/FfWX1YHtjvKDUJ4DdwPn5x`,
        benefitTypes: ["DENTAL_ASO"],
      },
      {
        text: "Find a dentist demo",
        url: `https://share.vidyard.com/watch/jdctRJ68H7r2XAQGfsf9qD`,
        benefitTypes: ["DENTAL_ASO"],
      },
    ],
  ],
]);

export const getBenefitClips = (client: Client) => {
  const slfCoverages = client.allPoliciesSlfCoverages ?? [];
  const slfCoveragesShortName = slfCoverages.map((ln) => slfCoverageLongNameToShortName[ln]);
  return getSlfCoverageLinks(slfCoveragesShortName || [], SlfCoverageToBenefitClips);
};

const SlfCoverageToBasicEnrollmentFlyers: Map<SlfCoverageName, SlfCoverageLink[]> = new Map([
  [
    "VSTD",
    [
      {
        text: slfCoverageNameToLongName["VSTD"],
        url: `https://p1.aprimocdn.net/sunlife/b439b941-3917-4fc5-b4a7-afc70122f883/gvstdin-ee-4438-voluntary-short-term-disability-why-buy-original-file.pdf`,
        benefitTypes: ["VOL_STD"],
      },
    ],
  ],
  [
    "VLTD",
    [
      {
        text: slfCoverageNameToLongName["VLTD"],
        url: `https://p1.aprimocdn.net/sunlife/eca52cae-bb2f-4348-baf7-afc701228b3b/gvltdin-ee-4441oe-ltd-why-buy-original-file.pdf`,
        benefitTypes: ["VOL_LTD"],
      },
    ],
  ],
  [
    "Vol Life",
    [
      {
        text: slfCoverageNameToLongName["Vol Life"],
        url: `https://p1.aprimocdn.net/sunlife/e9b1f81b-7c33-40a9-a304-afc70122266d/golin-ee-4429-optional-life-why-buy-no-vas-original-file.pdf`,
        benefitTypes: ["VOL_LIFE"],
      },
    ],
  ],
  [
    "Vol AD&D",
    [
      {
        text: slfCoverageNameToLongName["Vol AD&D"],
        url: `https://p1.aprimocdn.net/sunlife/ab0a14a7-c899-46a9-82c5-afc701224767/gvaddin-ee-4432-accidental-death-dismemberment-why-buy-original-file.pdf`,
        benefitTypes: ["VOL_AD&D"],
      },
    ],
  ],
  [
    "Stand-alone Vol AD&D",
    [
      {
        text: slfCoverageNameToLongName["Stand-alone Vol AD&D"],
        url: `https://p1.aprimocdn.net/sunlife/ab0a14a7-c899-46a9-82c5-afc701224767/gvaddin-ee-4432-accidental-death-dismemberment-why-buy-original-file.pdf`,
        benefitTypes: ["STAND_ALONE_VOL_AD&D"],
      },
      {
        text: "Beneficiary Designation",
        url: `https://p1.aprimocdn.net/sunlife/ab0a14a7-c899-46a9-82c5-afc701224767/gvaddin-ee-4432-accidental-death-dismemberment-why-buy-original-file.pdf`,
        benefitTypes: ["STAND_ALONE_VOL_AD&D"],
      },
    ],
  ],
  [
    "Accident",
    [
      {
        text: slfCoverageNameToLongName["Accident"],
        url: `https://p1.aprimocdn.net/sunlife/9bfbaa56-9c43-4273-ba67-afc701224588/gvacin-ee-4904-accident-insurance-why-buy-original-file.pdf`,
        benefitTypes: ["ACCIDENT"],
      },
    ],
  ],
  [
    "CI",
    [
      {
        text: slfCoverageNameToLongName["CI"],
        url: `https://p1.aprimocdn.net/sunlife/7d8ceff1-a9b1-4dbd-b9d6-afc0015f9b06/gciin-ee-4421ci-wellness-recurrence-original-file.pdf`,
        benefitTypes: ["CRITICAL_ILLNESS"],
      },
    ],
  ],
  [
    "HI",
    [
      {
        text: slfCoverageNameToLongName["HI"],
        url: `https://p1.aprimocdn.net/sunlife/c4a22f88-347f-4a9d-88d2-afc7012320c4/hiin-ee-9750-hi-why-buy-original-file.pdf`,
        benefitTypes: ["HOSPITAL_INDEMNITY"],
      },
    ],
  ],
  [
    "Cancer",
    [
      {
        text: slfCoverageNameToLongName["Cancer"],
        url: `https://p1.aprimocdn.net/sunlife/7f445c17-94ab-4129-bb55-afc70121fc1e/gciin-ee-4417-cancer-why-buy-original-file.pdf`,
        benefitTypes: ["CANCER"],
      },
    ],
  ],
  [
    "Dental",
    [
      {
        text: slfCoverageNameToLongName["Dental"],
        url: `https://p1.aprimocdn.net/sunlife/f2ff1afb-d4b3-4db6-be3e-afa10160db59/gdin-ee-6781-dental-why-buy-original-file.pdf`,
        benefitTypes: ["DENTAL"],
      },
    ],
  ],
  [
    "Dental (DHMO)",
    [
      {
        text: slfCoverageNameToLongName["Dental (DHMO)"],
        url: `https://p1.aprimocdn.net/sunlife/0022d075-52fc-417a-ad6c-afa101640f4a/gdin-ee-6782-prepaid-dhmo-dental-why-buy-original-file.pdf`,
        benefitTypes: ["DENTAL_DHMO"],
      },
    ],
  ],
  [
    "ASO Dental",
    [
      {
        text: slfCoverageNameToLongName["ASO Dental"],
        url: `https://p1.aprimocdn.net/sunlife/cd949f0c-e712-4efc-a4f4-afa10160e2a7/gdin-ee-9551-aso-dental-why-buy-original-file.pdf`,
        benefitTypes: ["DENTAL_ASO"],
      },
    ],
  ],
  [
    "Vision",
    [
      {
        text: slfCoverageNameToLongName["Vision"],
        url: `https://p1.aprimocdn.net/sunlife/d270ccb8-be37-4fe5-b363-afa101503b62/gvisin-ee-6783-vision-why-buy-original-file.pdf`,
        benefitTypes: ["VISION"],
      },
      {
        text: "VSP Flyer",
        url: `https://p1.aprimocdn.net/sunlife/0277b127-5d97-4e0e-9343-afa101503ebf/gvispc-6195-vision-plan-infocard-original-file.pdf`,
        benefitTypes: ["VISION"],
        category: "id-cards-and-online-access",
      },
    ],
  ],
]);

const EOIRequiredBasicEnrollmentFlyers: SlfCoverageLink[] = [
  {
    text: "EOI Online Submission",
    url: `https://p1.aprimocdn.net/sunlife/0d10760d-09d6-4c71-854b-afcc010c2a76/gmpho-7054h2-submitting-eoi-online-h2-original-file.pdf`,
    benefitTypes: [],
  },
  {
    text: "Employee EOI Guide",
    url: `https://p1.aprimocdn.net/sunlife/a2c9d9e3-03d6-42cf-87b5-afcc010c0881/gmpfl-5303h2-use-our-online-evidence-of-insurability-application-h2-fillable-original-file.pdf`,
    benefitTypes: [],
  },
];

export const getBasicEnrollmentFlyers = (client: Client): SlfCoverageLink[] => {
  const slfCoverages = client.allPoliciesSlfCoverages ?? [];
  const slfCoveragesShortName = slfCoverages.map((ln) => slfCoverageLongNameToShortName[ln]);
  const basicEnrollmentFlyersUnsorted = getSlfCoverageLinks(
    slfCoveragesShortName,
    SlfCoverageToBasicEnrollmentFlyers,
    true,
  );
  if (client.eoiRequired) {
    basicEnrollmentFlyersUnsorted.push(...EOIRequiredBasicEnrollmentFlyers);
  }

  basicEnrollmentFlyersUnsorted.push({
    benefitTypes: [],
    category: "id-cards-and-online-access",
    text: "Why create a Sun Life Account?",
    url: "https://p1.aprimocdn.net/sunlife/f3125cca-7582-4ae4-81d5-b06d013cccc5/shmpfl-1905-sun-life-account-creation-original-file.pdf",
  });

  const basicEnrollmentFlyers = basicEnrollmentFlyersUnsorted.sort((a, b) =>
    a.text.localeCompare(b.text),
  );
  return basicEnrollmentFlyers;
};

export function getLinkId(link: SlfCoverageLink) {
  const category = link.category || "no-category";
  const id = `${link.text}---${link.url}---${link.benefitTypes.join(",")}---${category}`;
  return id;
}

export const isClientDateValidForEnrollmentDocsAutomation = (client: Client) => {
  const referenceDate = new Date("2023-12-14").getTime();
  const clientCreationDate = client.createdAt.getTime();

  return referenceDate <= clientCreationDate;
};

const beneficiaryDesignationNYUrl =
  "https://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=26454";
const beneficiaryDesignationNonNYUrl =
  "https://forms.sunlife-usa.com/onlineordering/get_file.cfm?form_id=504";

export const getBeneficiaryDesignationWebLinks = (client: Client): SlfCoverageLink[] => {
  const slfCoverageLinks: SlfCoverageLink[] = [];

  for (const policy of client.policies) {
    const selectedBeneficiaryDesignationCoverages = beneficiaryDesignationCoverages.filter(
      (coverage) => policy.slfCoverages?.includes(coverage),
    );

    const documentBenefitTypes = unique(
      selectedBeneficiaryDesignationCoverages.map(
        (coverage) => beneficiaryDesignationCoveragesToDocumentBenefitType[coverage],
      ),
    );

    if (selectedBeneficiaryDesignationCoverages.length) {
      slfCoverageLinks.push({
        category: "custom-enrollment-materials",
        benefitTypes: documentBenefitTypes,
        text: "Beneficiary Designation",
        url: policy.issuedStateSitus?.includes("NY")
          ? beneficiaryDesignationNYUrl
          : beneficiaryDesignationNonNYUrl,
      });
    }
  }

  return slfCoverageLinks;
};
