import { formatDateFullMonthWithYear, formatFullName } from "shared/utils/format";
import type { Client } from "shared/types/Client";
import type { SignerMode } from "shared/types/OutsideSigner";

export function getICEditsAcceptedAndDeclinedBy(client: Client) {
  const acceptedBy = client.deifChangesAcceptedByUser
    ? formatFullName(client.deifChangesAcceptedByUser)
    : client.deifChangesOutsideAcceptedByUser
    ? formatFullName(client.deifChangesOutsideAcceptedByUser)
    : "unknown user";
  const acceptedByEmail = client.deifChangesAcceptedByUser
    ? client.deifChangesAcceptedByUser.email
    : client.deifChangesOutsideAcceptedByUser
    ? client.deifChangesOutsideAcceptedByUser.email
    : "unknown user";
  const acceptedByUserType: SignerMode | null = client.deifChangesAcceptedByUser
    ? "inside"
    : client.deifChangesOutsideAcceptedByUser
    ? "outside"
    : null;
  const acceptedAt = client.deifChangesAcceptedAt
    ? formatDateFullMonthWithYear(client.deifChangesAcceptedAt)
    : "unknown date";

  const declinedBy = client.deifChangesDeclinedByUser
    ? formatFullName(client.deifChangesDeclinedByUser)
    : client.deifChangesOutsideDeclinedByUser
    ? formatFullName(client.deifChangesOutsideDeclinedByUser)
    : "unknown user";
  const declinedByEmail = client.deifChangesDeclinedByUser
    ? client.deifChangesDeclinedByUser.email
    : client.deifChangesOutsideDeclinedByUser
    ? client.deifChangesOutsideDeclinedByUser.email
    : "unknown user";
  const declinedByUserType: SignerMode | null = client.deifChangesDeclinedByUser
    ? "inside"
    : client.deifChangesOutsideDeclinedByUser
    ? "outside"
    : null;
  const declinedAt = client.deifChangesDeclinedAt
    ? formatDateFullMonthWithYear(client.deifChangesDeclinedAt)
    : "unknown date";

  return {
    acceptedBy,
    acceptedByEmail,
    acceptedByUserType,
    acceptedAt,
    declinedBy,
    declinedByEmail,
    declinedByUserType,
    declinedAt,
  };
}
