import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import {
  ErrorMessage,
  GenericErrorCopy2,
  genericErrorCopy2,
} from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { Row, Col } from "client/src/components/Grid/Grid";
import { SlobDrawer } from "client/src/components/SlobDrawer/SlobDrawer";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Tooltip } from "client/src/components/Tooltip/Tooltip";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useGetClasses, useDeleteClass } from "client/src/hooks/employeeClass";
import {
  useCreateEmployeeClassFromQPSBasicClasses,
  useGetQPSPlanDesignInfo,
} from "client/src/hooks/qps";
import { ResponseError } from "client/src/hooks/query";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { useToggler } from "client/src/hooks/useToggler";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { eligibilityEmployeeDefinitionsValuesRecord } from "shared/types/QPSClass";
import * as Yup from "yup";
import { RouteData } from "../../../../../shared/config/routeData";
import {
  prefillCompanyInformation,
  type Client,
  type ClientSetupStepId,
} from "../../../../../shared/types/Client";
import { Button } from "../../../components/Button/Button";
import { Body1, Body2, Body3, Body5 } from "../../../components/Typography/Typography";
import { UnorderedList, UnorderedListItem } from "../../../components/UnorderedList/UnorderedList";
import { useClientUtils } from "../../../domain/Client/useClientUtils";
import { useUpdateClient } from "../../../hooks/client";
import * as styles from "./cardPrefillCompanyInformation.module.less";
import type { EmployeeClass, EmployeeClassId } from "shared/types/EmployeeClass";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type ImportedClassItemProps = {
  employeeClass: EmployeeClass;
  index: number;
  deleteButtonHandler: (employeeClassId: EmployeeClassId) => void;
};

const ImportedClassItem: React.FC<ImportedClassItemProps> = ({
  employeeClass,
  index,
  deleteButtonHandler,
}) => {
  return (
    <div
      className={styles.importedEmployeeClassContainer}
      data-testid="imported-class-item-container"
    >
      <Row justify="space-between" align="top">
        <Col>
          <h5>Eligible employee group {index + 1}</h5>
        </Col>
        <Col>
          <Button type="text-only" onClick={() => deleteButtonHandler(employeeClass.id)}>
            <Body2 blueMedium>Remove</Body2>
          </Button>
        </Col>
      </Row>
      <Body3 as="p">{employeeClass.groupName}</Body3>
      <StackY dist={4}>
        {employeeClass.qpsClasses.map((qpsClass) => (
          <Body3 key={qpsClass.id}>
            {qpsClass.planDesignName} ({qpsClass.adminClassName})
          </Body3>
        ))}
      </StackY>
    </div>
  );
};

type CardPrefillCompanyInformationProps = {
  client: Client;
  featureToggles: ClientFeatureToggles;
  refetchData?: (stepId: ClientSetupStepId) => Promise<void>;
};

export const CardPrefillCompanyInformation = ({
  client,
  featureToggles,
  refetchData,
}: CardPrefillCompanyInformationProps) => {
  const navigate = useNavigate();
  const [showDeleteModal, toggleDeleteModal] = useToggler();
  const [classIdToDelete, setClassIdToDelete] = useState<EmployeeClassId | null>(null);
  const [showQPSBuilderDrawer, toggleQPSBuilderDrawer] = useToggler();
  const { mutateAsync: updateClient } = useUpdateClient();
  const { mutateAsync: createEmployeeClassesFromQPS } = useCreateEmployeeClassFromQPSBasicClasses();

  const {
    data: qpsPlanDesignInfo,
    refetch: getQPSBasicClasses,
    isFetching: isLoadingQPSBasicClasses,
    error: errorQPSBasicClasses,
  } = useGetQPSPlanDesignInfo(client.id, { enabled: false });

  const qpsBasicClasses = qpsPlanDesignInfo?.basicClasses ?? [];

  const { data: employeeClasses, isLoading: isLoadingEmployeeClasses } = useGetClasses(client.id);

  const {
    mutateAsync: deleteEmployeeClass,
    isPending: isPendingDeleteEmployeeClass,
    error: errorDeleteEmployeeClass,
  } = useDeleteClass();

  const { formik } = useClientUtils({
    client,
    updateClient,
    getClientPropertiesToUpdate: ({ didPrefillCompanyInformation, prefillCompanyInformation }) => ({
      // remove didPrefillCompanyInformation on ONBOARD_PREFILL_IN_PROGRESS removal
      didPrefillCompanyInformation,
      prefillCompanyInformation,
    }),
    validationSchema: Yup.object({
      // remove didPrefillCompanyInformation on ONBOARD_PREFILL_IN_PROGRESS removal
      didPrefillCompanyInformation: Yup.boolean().required(),
      prefillCompanyInformation: Yup.string().oneOf(prefillCompanyInformation),
    }),
    type: "Pre-fill Company Information",
    onSuccessCallback: (client) => {
      if (
        featureToggles?.ONBOARD_PREFILL_IN_PROGRESS &&
        client.prefillCompanyInformation === "In Progress"
      )
        navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"));
      if (!featureToggles?.ONBOARD_PREFILL_IN_PROGRESS)
        navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"));
    },
    track: () => null, //TODO: analytics
  });

  const selectedQPSClassesIndex: number[] = [];
  const qpsClassesFormik = useSlobFormik({
    initialValues: {
      groupName: "",
      selectedQPSClassesIndex,
    },
    validationSchema: Yup.object({
      groupName: Yup.string().required("This field is required"),
      selectedQPSClassesIndex: Yup.array(Yup.number().required())
        .min(1, "Select a least one class")
        .required("Select a least one class"),
    }),
    onSubmit: async (values) => {
      const selectedQPSBasicClasses = values.selectedQPSClassesIndex.map(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- yolo
        (index) => qpsBasicClasses[index - 1]!,
      );
      const { data: createdEmployeeClass, isError } = await createEmployeeClassesFromQPS({
        params: {
          clientId: client.id,
        },
        data: {
          groupName: values.groupName,
          qpsBasicClasses: selectedQPSBasicClasses,
        },
      });

      if (isError) {
        void slobMessage.error(genericErrorCopy2);
        return;
      }

      void slobMessage.success(`Created the ${createdEmployeeClass.groupName} class`);
      toggleQPSBuilderDrawer();
      qpsClassesFormik.resetForm();
    },
  });

  const onClickMarkComplete = async () => {
    await formik.setValues({
      // remove didPrefillCompanyInformation on ONBOARD_PREFILL_IN_PROGRESS removal
      didPrefillCompanyInformation: true,
      prefillCompanyInformation: "Done",
    });
    await formik.submitForm();
    if (refetchData) {
      await refetchData("PREFILL_COMPANY");
    }
  };

  const onClickPrefillClient = async () => {
    if (
      !client.didPrefillCompanyInformation &&
      client.prefillCompanyInformation === "Not Started"
    ) {
      await formik.setValues({
        // remove didPrefillCompanyInformation on ONBOARD_PREFILL_IN_PROGRESS removal
        didPrefillCompanyInformation: false,
        prefillCompanyInformation: "In Progress",
      });
      await formik.submitForm();
      if (refetchData) {
        await refetchData("PREFILL_COMPANY");
      }
    } else {
      navigate(RouteData.clientTaskDetail.getPath(client.id, "eif-submit-company-information"));
    }
  };

  const addEmployeeGroupHandler = async () => {
    const hasData = qpsBasicClasses.length > 0;
    if (hasData) {
      toggleQPSBuilderDrawer();
      return;
    }

    const { isSuccess } = await getQPSBasicClasses();
    if (isSuccess) {
      toggleQPSBuilderDrawer();
    }
  };

  const deleteButtonHandler = (employeeClassId: EmployeeClassId) => {
    setClassIdToDelete(employeeClassId);
    toggleDeleteModal();
  };

  const importedFromQPSClasses = (employeeClasses || []).filter(
    ({ importedFromQPS }) => importedFromQPS,
  );

  const isMarkAsCompleteDisabled =
    !formik.isSubmitting && client.prefillCompanyInformation === "Done";

  return (
    <>
      {featureToggles?.ONBOARD_CLASS_IMPORT && (
        <div className="mb-32">
          <Body2 as="p">
            Would you like to pre-build employee eligibility groups using QPS data?
          </Body2>

          {importedFromQPSClasses.map((employeeClass, index) => (
            <ImportedClassItem
              key={employeeClass.id}
              employeeClass={employeeClass}
              index={index}
              deleteButtonHandler={deleteButtonHandler}
            />
          ))}

          <Button
            type="tertiary"
            icon={<FontAwesomeIcon icon={faUserPlus} />}
            onClick={addEmployeeGroupHandler}
            loading={isLoadingQPSBasicClasses || isLoadingEmployeeClasses}
          >
            Add an employee group
          </Button>
          <div aria-live="assertive" className="hide:empty">
            {errorQPSBasicClasses && (
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(errorQPSBasicClasses, GenericErrorCopy2)}
              </ErrorMessage>
            )}
          </div>
        </div>
      )}
      <Body3 as="p">
        If you’d like to pre-fill anything in the Submit Company Information step now is a great
        time to do so.
      </Body3>
      <UnorderedList>
        <UnorderedListItem>
          This will allow you to update and edit fields prior to the client and broker having
          Onboard access.
        </UnorderedListItem>
        <UnorderedListItem>
          Notifications for changes and edits will not be sent during pre-fill as long as you have
          not completed the <strong>Confirm setup is complete</strong> step below.
        </UnorderedListItem>
      </UnorderedList>
      <Row justify="end" gutter={12}>
        {!featureToggles?.ONBOARD_PREFILL_IN_PROGRESS && (
          <Col className="mt-12">
            <Button
              type="secondary"
              size="middle"
              onClick={async () => {
                await formik.setValues({
                  didPrefillCompanyInformation: true,
                  prefillCompanyInformation: "Done",
                });
                await formik.submitForm();
                if (refetchData) {
                  await refetchData("PREFILL_COMPANY");
                }
              }}
              loading={formik.isSubmitting}
            >
              Pre-fill in client portal
            </Button>
          </Col>
        )}
        {featureToggles?.ONBOARD_PREFILL_IN_PROGRESS && (
          <>
            <Col className="mt-12">
              <Tooltip
                title={isMarkAsCompleteDisabled ? "Step already completed" : ""}
                placement="top"
              >
                <Button
                  type="text"
                  size="middle"
                  onClick={onClickMarkComplete}
                  disabled={isMarkAsCompleteDisabled}
                  loading={
                    formik.isSubmitting && formik.values.prefillCompanyInformation === "Done"
                  }
                >
                  Mark as complete
                </Button>
              </Tooltip>
            </Col>
            <Col className="mt-12">
              <Button
                type="secondary"
                size="middle"
                onClick={onClickPrefillClient}
                loading={
                  formik.isSubmitting && formik.values.prefillCompanyInformation === "In Progress"
                }
              >
                Pre-fill in client portal
              </Button>
            </Col>
          </>
        )}
      </Row>
      <SlobDrawer
        width={750}
        visible={showQPSBuilderDrawer}
        title="Benefit definitions"
        onClose={toggleQPSBuilderDrawer}
        children={
          <>
            <Body2 as="p">
              Use the following benefit definitions to create an eligible employee groups.
            </Body2>

            <Body3 as="p">Benefit definitions for case {client.caseId}</Body3>
            <form onSubmit={qpsClassesFormik.handleSubmit}>
              {qpsBasicClasses.map((qpsBasicClass, index) => (
                <div className={styles.qpsClassContainer} key={index}>
                  <Row justify={"space-between"}>
                    <Col>
                      <h2 id={`qps-draft-class-name-${index + 1}`}>
                        {qpsBasicClass.planDesignName}
                      </h2>
                    </Col>
                    <Col>
                      <Checkbox
                        name="selectedQPSClassesIndex"
                        label=""
                        value={index + 1}
                        aria-labelledby={`qps-draft-class-name-${index + 1}`}
                        checked={
                          !!qpsClassesFormik.values.selectedQPSClassesIndex.includes(index + 1)
                        }
                        onChange={qpsClassesFormik.handleChange}
                        disabled={qpsClassesFormik.isSubmitting}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Body5>Rating Class</Body5>
                      <br />
                      <Body3>{qpsBasicClass.ratingClassName}</Body3>
                    </Col>
                    <Col xs={12}>
                      <Body5>Admin Class</Body5>
                      <br />
                      <Body3>{qpsBasicClass.adminClassName}</Body3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Body5>Eligibility</Body5>
                      <br />
                      <Body3>
                        {qpsBasicClass.eligibleEmployeeDefinition
                          ? eligibilityEmployeeDefinitionsValuesRecord[
                              qpsBasicClass.eligibleEmployeeDefinition
                            ]
                          : "N/A"}
                      </Body3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Body5>Total eligible employees</Body5>
                      <br />
                      <Body3>{qpsBasicClass.employeeClass.numberOfEmployees}</Body3>
                    </Col>
                    <Col xs={12}>
                      <Body5>Minimum weekly hours</Body5>
                      <br />
                      <Body3>{qpsBasicClass.employeeClass.minimumWeeklyHours}</Body3>
                    </Col>
                  </Row>
                </div>
              ))}
              <div aria-live="assertive" className="mb-24">
                {qpsClassesFormik.errors.selectedQPSClassesIndex && !qpsClassesFormik.isValid && (
                  <ErrorMessage>{qpsClassesFormik.errors.selectedQPSClassesIndex}</ErrorMessage>
                )}
              </div>

              <Body3 as="p">
                Give this group a name to help the Benefits Administrator identify it. This will
                display in the Eligibility’s “Other” section in the portal and can be edited after
                the fact.
              </Body3>

              <div className="mb-24">
                <FormInput
                  name="groupName"
                  maxLength={100}
                  label="Group Name"
                  aria-label="Group Name"
                  touched={qpsClassesFormik.touched.groupName}
                  value={qpsClassesFormik.values.groupName}
                  error={qpsClassesFormik.errors.groupName}
                  onChange={qpsClassesFormik.handleChange}
                />
              </div>

              <Row justify="end" className="mb-32">
                <Col>
                  <StackX dist={24}>
                    <Button
                      size="middle"
                      type="text"
                      onClick={toggleQPSBuilderDrawer}
                      disabled={qpsClassesFormik.isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="middle"
                      type="primary"
                      htmlType="submit"
                      disabled={qpsClassesFormik.isSubmitting || !qpsClassesFormik.isValid}
                    >
                      Create group
                    </Button>
                  </StackX>
                </Col>
              </Row>
            </form>
          </>
        }
      />
      <ConfirmDialog
        title={"Delete class"}
        isVisible={showDeleteModal}
        isLoading={isPendingDeleteEmployeeClass}
        confirmActionText={"Delete"}
        cancelActionText={"Cancel"}
        onConfirm={async () => {
          if (classIdToDelete) {
            const { isSuccess } = await deleteEmployeeClass({
              params: { clientId: client.id, employeeClassId: classIdToDelete },
            });
            if (isSuccess) {
              toggleDeleteModal();
            }
          }
        }}
        onCancel={() => {
          setClassIdToDelete(null);
          toggleDeleteModal();
        }}
      >
        <Body1 as="p">Are you sure you want to delete this class?</Body1>

        <div aria-live="assertive" className="hide:empty">
          {errorDeleteEmployeeClass && (
            <ErrorMessage>
              {ResponseError.getUserFacingErrorMessage(errorDeleteEmployeeClass, GenericErrorCopy2)}
            </ErrorMessage>
          )}
        </div>
      </ConfirmDialog>
    </>
  );
};
