import { useState } from "react";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../hooks/useSlobFormik";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormikConfig } from "formik";
import type { Client } from "shared/types/Client";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { AnyObject, ObjectSchema } from "yup";

export const useClientUtils = <Schema extends ObjectSchema<AnyObject>>({
  client,
  getClientPropertiesToUpdate,
  updateClient,
  validationSchema,
  validationContext,
  type,
  formikOptions,
  track,
  onSuccessCallback = () => null,
  prefill,
}: {
  client: Client;
  getClientPropertiesToUpdate: (client: Client) => ValuesForValidationSchema<Schema>;
  updateClient: UpdateClientFunc;
  validationSchema: Schema;
  validationContext?: AnyObject;
  type: string;
  formikOptions?: Omit<
    FormikConfig<ValuesForValidationSchema<Schema>>,
    "validationSchema" | "initialValues" | "onSubmit"
  >;
  track: (buttonLabel: string) => void;
  onSuccessCallback?: (updatedClient: Client) => void;
  prefill?: boolean;
}) => {
  const [initialValues, setInitialValues] = useState(getClientPropertiesToUpdate(client));

  if (prefill) {
    validationContext = { ...validationContext, prefill };
  }

  const formik = useSlobFormik({
    ...formikOptions,
    validationSchema,
    validationContext,
    initialValues,
    async onSubmit(values, _formikHelpers) {
      const hide = slobMessage.loading(`${type} updating...`, 0);
      track("Save");
      try {
        const { isSuccess, data } = await updateClient({
          data: values,
          params: { clientId: client.id },
          ...(prefill && { query: { prefill } }),
        });

        if (isSuccess) {
          void slobMessage.success(`${type} updated`);
          const nextValues = getClientPropertiesToUpdate(data);
          await formik.setValues(nextValues);
          await formik.setTouched({});
          setInitialValues(nextValues);
          onSuccessCallback(data);
        }
      } catch (error) {
        void slobMessage.error(`Failed to save ${type}`);
        throw error;
      } finally {
        hide();
      }
    },
  });

  return { formik };
};
