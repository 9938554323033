import { StackY } from "client/src/components/Spacing/Spacing";
import React from "react";

import { FormInput } from "../../../components/Form/Input";
import { RadioGroup } from "../../../components/Form/RadioGroup";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { hasFormikError } from "../../../utils/hasFormikError";

import * as styles from "./reassignRecipientForm.module.less";

import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type { BasicUserInfo } from "../Modal/ReassignRecipientModal";
import type { LabeledValue } from "antd/lib/select";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";
import type { reassignRecipientValidationV2 } from "shared/validation/onboardingForm";

type ReassignRecipientFormProps = {
  formik: FormikTypeFromValidator<typeof reassignRecipientValidationV2>;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
  slobUsers: BasicUserInfo[];
  setSearchParam: (searchParam: string) => void;
};

export const ReassignRecipientFormV2: React.FC<ReassignRecipientFormProps> = ({
  formik,
  onboardingFormRecipients,
  setSearchParam,
  slobUsers,
}) => {
  const reassignAvailableRecipients = onboardingFormRecipients.reduce<Array<LabeledValue>>(
    (recipients, recipient) => {
      if (["CREATED", "SENT", "DELIVERED"].includes(recipient.status)) {
        recipients.push({
          label: `${recipient.name} - ${recipient.roleName}`,
          value: recipient.id,
        });
      }
      return recipients;
    },
    [],
  );

  const previousSignerOnChange = (labeledValue: LabeledValue) => {
    void formik.setFieldValue("previousRecipientId", labeledValue.value);
  };

  const newSignerOnboardIdOptions = slobUsers.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const newSignerOnboardIdOnChange = (labeledValue: LabeledValue) => {
    void formik.setFieldValue("newSignerOnboardId", labeledValue.value);
  };

  return (
    <div className={styles.reassignForm}>
      <StackY dist={16} className="w-full">
        <h3>Choose from an existing signer</h3>
        <SlobSelect
          placeholder="Previous Signer"
          name="previousRecipientId"
          value={formik.values.previousRecipientId}
          options={reassignAvailableRecipients}
          onChange={previousSignerOnChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.previousRecipientId}
          error={formik.errors.previousRecipientId}
        />
        <h3>Reassign to a new signer</h3>
        <RadioGroup
          onChange={formik.handleChange}
          value={formik.values.newSignerType}
          label=""
          disabled={false}
          touched={formik.touched.newSignerType}
          error={hasFormikError(formik, "newSignerType") && "Signer type is required"}
          name="newSignerType"
          direction="vertical"
          fullWidth
          options={[
            {
              label: "Choose from an existing Onboard user",
              value: "EXISTING_ONBOARD_USER",
              content: (
                <SlobSelect
                  placeholder="New Signer's Name"
                  name="newSignerOnboardId"
                  value={formik.values.newSignerOnboardId}
                  options={newSignerOnboardIdOptions}
                  onChange={newSignerOnboardIdOnChange}
                  onInputKeyDown={(event) => setSearchParam(event.currentTarget.value)}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.newSignerOnboardId}
                  error={formik.errors.newSignerOnboardId}
                />
              ),
            },
            {
              label: "Or, choose a new user",
              value: "NEW_OUTSIDE_USER",
              content: (
                <>
                  <FormInput
                    label="New Signer's name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={100}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                  />
                  <FormInput
                    label="New Signer's email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    maxLength={191}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                  />
                </>
              ),
            },
          ]}
        />
        {formik.values.newSignerType === "NEW_OUTSIDE_USER" && (
          <p className="body3">
            A new signer is considered an outsider signer and will receive an email from DocuSign to
            access and sign the form. You will still be able to track their form signing progress
            within Onboard on this page.
          </p>
        )}
      </StackY>
    </div>
  );
};
