import {
  getIsDHMOBenefitType,
  getIsSupplementalHealthBenefitType,
  getIsPFMLBenefitType,
  type BenefitTypeEIF,
} from "shared/types/BenefitTypes";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { swapKeyValues } from "shared/utils/swapKeyValues";
import type { BillingAdministrationType } from "@prisma/client";
import type {
  OnboardValidationWarning,
  QPSAdministrationType,
  QPSValidationError,
} from "server/types/QPS";
import type { YesNoNotSure } from "shared/types/Client";
import type { CreateEmployeeClassInput, EmployeeClassId } from "shared/types/EmployeeClass";
import type { CreateEmployeeClassPlanDataInput } from "shared/types/EmployeeClassPlan";
import type { ValueOf } from "shared/types/Helper";
import type { PFMLStatutoryContributionSections, PlanId } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const qpsBenefitCodes = [
  "DENTALPPO",
  "DENTALPREPD",
  "VISIONMAJOR",
  "VISIONBUYUP",
  "BASICLFE",
  "DEPBASICLFE",
  "EMPADD",
  "DEPADD",
  "BASICSTD",
  "BASICLTD",
  "EMPCRTCLILL",
  "SPSCRTCLILL",
  "CHLDCRTCLILL",
  "HOSPITAL",
  "ACCIDENT",
  "CANCER",
  "MAPFML",
  "WAPFML",
  "CTPFML",
  "COPFML",
  "ORPFML",
] as const;
export type QPSBenefitCode = ValueOf<typeof qpsBenefitCodes>;

export type QPSBenefitTypesForCompensationMsg = Extract<
  BenefitTypeEIF,
  | "LIFE"
  | "DEP_LIFE"
  | "BASIC_ADND"
  | "BASIC_DEP_ADND"
  | "LONG_TERM_DISABILITY"
  | "SHORT_TERM_DISABILITY"
>;

export const qpsBenefitTypesForCompensationMsg: QPSBenefitTypesForCompensationMsg[] = [
  "LIFE",
  "DEP_LIFE",
  "BASIC_ADND",
  "BASIC_DEP_ADND",
  "LONG_TERM_DISABILITY",
  "SHORT_TERM_DISABILITY",
];

export const getIsQPSBenefitTypeForCompensationMsg = (
  s: string,
): s is QPSBenefitTypesForCompensationMsg => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = qpsBenefitTypesForCompensationMsg.includes(s as QPSBenefitTypesForCompensationMsg);
  return isIt;
};

export const getIsQPSBenefitCode = (s: string): s is QPSBenefitCode => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isIt = qpsBenefitCodes.includes(s as QPSBenefitCode);
  return isIt;
};

export type QPSClassId = string;

export type QPSClass = {
  id: QPSClassId;
  employeeClassId: string | null;
  nonClassPlanId: string | null;
  benefitCode: QPSBenefitCode;
  planDesignSeqNumber: number;
  ratingGroupNumber: number;
  ratingClassNumber: number;
  adminClassNumber: number;
  planDesignName: string;
  ratingClassName: string;
  adminClassName: string;
  eligibleEmployeeDefinition: EligibilityEmployeeDefinition | null;
  benefitStateCode: string | null;

  // Misc
  createdAt: Date;
  createdBy: string | null;
  updatedAt: Date;
  updatedBy: string | null;
  deletedAt: Date | null;
  deletedBy: string | null;
};

// storage input
type TimestampColumn =
  | "createdAt"
  | "createdBy"
  | "updatedAt"
  | "updatedBy"
  | "deletedAt"
  | "deletedBy";

export type CreateQPSClass = Omit<QPSClass, "id" | TimestampColumn>;

export type QPSBasicClass = {
  benefitCode: QPSBenefitCode;
  planDesignSeqNumber: number;
  ratingGroupNumber: number;
  ratingClassNumber: number;
  adminClassNumber: number;
  planDesignName: string;
  ratingClassName: string;
  adminClassName: string;
  eligibleEmployeeDefinition: EligibilityEmployeeDefinition | null;
  benefitStateCode: string | null;
};

export type QPSBasicClassInput = QPSBasicClass & {
  employeeClass: Omit<CreateEmployeeClassInput, "employeeClassPlans" | "qpsClasses">;
  employeeClassPlan: CreateEmployeeClassPlanDataInput[];
};

export type CreateEmployeeClassFromQPSInput = {
  groupName: string;
  qpsBasicClasses: QPSBasicClassInput[];
};

export type QPSClassMapInput = {
  employeeClassId: EmployeeClassId;
  qpsBasicClasses: QPSBasicClass[];
};

export type QPSNonClassMapInput = {
  nonClassPlanId: PlanId;
  qpsBasicClasses: QPSBasicClass[];
};

export type MapAndSyncQPSClassesInput = {
  employeeClassesMap: QPSClassMapInput[];
  nonClassMap: QPSNonClassMapInput[];
};

export type QPSClassIdFields = Pick<
  QPSClass,
  "planDesignSeqNumber" | "benefitCode" | "ratingClassNumber" | "adminClassNumber"
>;

export const getPlanDesignId = (qpsClassIdFields: QPSClassIdFields) =>
  `${qpsClassIdFields.planDesignSeqNumber}_${qpsClassIdFields.benefitCode}`;

export const getRatingClassId = (qpsClassIdFields: QPSClassIdFields) =>
  `${qpsClassIdFields.planDesignSeqNumber}_${qpsClassIdFields.benefitCode}_${qpsClassIdFields.ratingClassNumber}`;

// Unique identifier using all the number combinations of the Plan Design, Rating and Admin class
export const getAdminClassId = (qpsClassIdFields: QPSClassIdFields) =>
  `${qpsClassIdFields.planDesignSeqNumber}_${qpsClassIdFields.benefitCode}_${qpsClassIdFields.ratingClassNumber}_${qpsClassIdFields.adminClassNumber}`;

export type QPSSyncErrorDetail = {
  section: string;
  generalError?: string;
  qpsErrorDetail?: QPSValidationError;
  onboardValidation?: OnboardValidationWarning;
};

export const isQPSSyncErrorDetail = (item: unknown): item is QPSSyncErrorDetail => {
  return item != null && typeof item === "object" && "section" in item;
};

export const eligibilityEmployeeDefinitionsLabels = [
  "All Full-Time United States Employees working in the United States",
  "All Full-Time United States Salaried Employees working in the United States",
  "All Full-Time United States Hourly Employees working in the United States",
  "All Full-Time United States Union Employees working in the United States",
  "All Full-Time United States Non-Union Employees working in the United States",
  "All United States Employees working in the United States",
] as const;
export type EligibilityEmployeeDefinitionLabel = ValueOf<
  typeof eligibilityEmployeeDefinitionsLabels
>;

export const eligibilityEmployeeDefinitions = [
  "ALL_FT_US_EMPLOYEES_IN_US",
  "ALL_FT_US_SALARIED_EMPLOYEES_IN_US",
  "ALL_FT_US_HOURLY_EMPLOYEES_IN_US",
  "ALL_FT_US_UNION_EMPLOYEES_IN_US",
  "ALL_FT_US_NON_UNION_EMPLOYEES_IN_US",
  "ALL_US_EMPLOYEES_IN_US",
] as const;
export type EligibilityEmployeeDefinition = ValueOf<typeof eligibilityEmployeeDefinitions>;

export const eligibilityEmployeeDefinitionsValuesRecord: Record<
  EligibilityEmployeeDefinition,
  EligibilityEmployeeDefinitionLabel
> = {
  ALL_FT_US_EMPLOYEES_IN_US: "All Full-Time United States Employees working in the United States",
  ALL_FT_US_SALARIED_EMPLOYEES_IN_US:
    "All Full-Time United States Salaried Employees working in the United States",
  ALL_FT_US_HOURLY_EMPLOYEES_IN_US:
    "All Full-Time United States Hourly Employees working in the United States",
  ALL_FT_US_UNION_EMPLOYEES_IN_US:
    "All Full-Time United States Union Employees working in the United States",
  ALL_FT_US_NON_UNION_EMPLOYEES_IN_US:
    "All Full-Time United States Non-Union Employees working in the United States",
  ALL_US_EMPLOYEES_IN_US: "All United States Employees working in the United States",
};

export const eligibilityEmployeeDefinitionsLabelToValue: Record<
  EligibilityEmployeeDefinitionLabel,
  EligibilityEmployeeDefinition
> = swapKeyValues(eligibilityEmployeeDefinitionsValuesRecord);

// TODO: continue to all the category code map
export const categoryCodeToBenefitType: Record<QPSBenefitCode, BenefitTypeEIF[]> = {
  DENTALPPO: ["DENTAL"],
  DENTALPREPD: ["DENTAL_DHMO"],
  VISIONBUYUP: ["VISION"],
  VISIONMAJOR: ["VISION"],
  BASICLFE: ["LIFE"],
  DEPBASICLFE: ["DEP_LIFE"],
  EMPADD: ["BASIC_ADND"],
  DEPADD: ["BASIC_DEP_ADND"],
  BASICSTD: ["SHORT_TERM_DISABILITY"],
  BASICLTD: ["LONG_TERM_DISABILITY"],
  ACCIDENT: ["ACCIDENT"],
  CANCER: ["CANCER"],
  EMPCRTCLILL: ["CRITICAL_ILLNESS"],
  SPSCRTCLILL: ["CRITICAL_ILLNESS"],
  CHLDCRTCLILL: ["CRITICAL_ILLNESS"],
  HOSPITAL: ["HOSPITAL_INDEMNITY"],
  MAPFML: ["PFML_MA_FULLY_INS", "PFML_MA_ASO"],
  COPFML: ["PFML_CO_FULLY_INS", "PFML_CO_ASO"],
  CTPFML: ["PFML_CT_FULLY_INS", "PFML_CT_ASO"],
  ORPFML: ["PFML_OR_FULLY_INS", "PFML_OR_ASO"],
  WAPFML: ["PFML_WA_ASO"],
};

const benefitTypeToCategoryCode: Record<BenefitTypeEIF, QPSBenefitCode | null> = {
  DENTAL: "DENTALPPO",
  DENTAL_DHMO: "DENTALPREPD",
  VISION: "VISIONMAJOR",
  LIFE: "BASICLFE",
  DEP_LIFE: "DEPBASICLFE",
  BASIC_ADND: "EMPADD",
  BASIC_DEP_ADND: "DEPADD",
  SHORT_TERM_DISABILITY: "BASICSTD",
  LONG_TERM_DISABILITY: "BASICLTD",
  AL_DHMO: "DENTALPREPD",
  AZ_DHMO: "DENTALPREPD",
  CA_DHMO: "DENTALPREPD",
  CO_DHMO: "DENTALPREPD",
  FL_DHMO: "DENTALPREPD",
  GA_DHMO: "DENTALPREPD",
  IL_DHMO: "DENTALPREPD",
  KS_DHMO: "DENTALPREPD",
  KY_DHMO: "DENTALPREPD",
  MI_DHMO: "DENTALPREPD",
  MO_DHMO: "DENTALPREPD",
  NE_DHMO: "DENTALPREPD",
  NJ_DHMO: "DENTALPREPD",
  NM_DHMO: "DENTALPREPD",
  NY_DHMO: "DENTALPREPD",
  OH_DHMO: "DENTALPREPD",
  OK_DHMO: "DENTALPREPD",
  PA_DHMO: "DENTALPREPD",
  TN_DHMO: "DENTALPREPD",
  TX_DHMO: "DENTALPREPD",
  UT_DHMO: "DENTALPREPD",
  WI_DHMO: "DENTALPREPD",
  PFML_CO_ASO: "COPFML",
  PFML_MA_ASO: "MAPFML",
  PFML_CT_ASO: "CTPFML",
  PFML_OR_ASO: "ORPFML",
  PFML_WA_ASO: "WAPFML",
  PFML_MA_FULLY_INS: "MAPFML",
  PFML_CO_FULLY_INS: "COPFML",
  PFML_CT_FULLY_INS: "CTPFML",
  PFML_OR_FULLY_INS: "ORPFML",
  MEDICAL: null,
  ACCIDENT: null,
  CRITICAL_ILLNESS: null,
  HOSPITAL_INDEMNITY: null,
  CANCER: null,
  ISSUE_AGE_STD: null,
  GAP: null,
  FINANCIAL: null,
  OTHER: null,
  VOL_LIFE: null,
  VOL_STD: null,
  VOL_LTD: null,
  VOL_ADND: null,
  STANDALONE_VOL_ADND: null,
  DENTAL_ASO: null,
  SPOUSE_VOL_LIFE: null,
  CHILDREN_VOL_LIFE: null,
  SPOUSE_VOL_ADND: null,
  CHILDREN_VOL_ADND: null,
  STANDALONE_SPOUSE_VOL_ADND: null,
  STANDALONE_CHILDREN_VOL_ADND: null,
  STANDALONE_FAMILY_VOL_ADND: null,
  FLI: null,
  DBL: null,
  PFL: null,
  TDB: null,
  TDI: null,
};

export const categoryCodeToPFMLSection: Record<
  QPSBenefitCode,
  PFMLStatutoryContributionSections | null
> = {
  MAPFML: "PFML_MA",
  WAPFML: "PFML_WA",
  CTPFML: "PFML_CT",
  COPFML: "PFML_CO",
  ORPFML: "PFML_OR",
  DENTALPPO: null,
  DENTALPREPD: null,
  VISIONMAJOR: null,
  VISIONBUYUP: null,
  BASICLFE: null,
  DEPBASICLFE: null,
  EMPADD: null,
  DEPADD: null,
  BASICSTD: null,
  BASICLTD: null,
  ACCIDENT: null,
  CANCER: null,
  EMPCRTCLILL: null,
  SPSCRTCLILL: null,
  CHLDCRTCLILL: null,
  HOSPITAL: null,
};

export const getQPSBenefitCodeFromBenefitType = (
  benefitType: BenefitTypeEIF,
): QPSBenefitCode | null => {
  return benefitTypeToCategoryCode[benefitType] ?? null;
};

export const isQPSBasicClass = (item: unknown): item is QPSBasicClass => {
  return (
    item != null &&
    typeof item === "object" &&
    "benefitCode" in item &&
    "planDesignSeqNumber" in item &&
    "ratingClassNumber" in item
  );
};

export const isQPSNonClassMapInput = (item: unknown): item is QPSNonClassMapInput => {
  return (
    item != null &&
    typeof item === "object" &&
    "nonClassPlanId" in item &&
    "qpsBasicClasses" in item &&
    Array.isArray(item.qpsBasicClasses) &&
    item.qpsBasicClasses.every(isQPSBasicClass)
  );
};

export const planInfoSelectedBenefitCodes = ["BASICLFE", "BASICLTD", "BASICSTD"] as const;

export type PlanInfoSelectedBenefitCodes = ValueOf<typeof planInfoSelectedBenefitCodes>;

export const planInfoSelectedBenefitCodesToBenefitType: Record<
  PlanInfoSelectedBenefitCodes,
  BenefitTypeEIF
> = {
  BASICLFE: "LIFE",
  BASICLTD: "LONG_TERM_DISABILITY",
  BASICSTD: "SHORT_TERM_DISABILITY",
};

export type BasicPlanInfo = {
  hasNonSupportedBasicLifePlan: boolean | null;
  administrationType: QPSAdministrationType | null;
};

export type QPSBenefitTypeHasHighPlanMap = Partial<Record<BenefitTypeEIF, boolean>>;
export type QPSPlanInfoByBenefitCode = Record<PlanInfoSelectedBenefitCodes, BasicPlanInfo>;

export type QPSPlanDesignInfo = {
  hasERISAContacts: boolean;
  missingPFMLLegalNameAndTaxId: boolean | null;
  benefitTypeLevels: QPSBenefitTypeHasHighPlanMap;
  basicClasses: QPSBasicClassInput[];
  planInfoByBenefitCode: QPSPlanInfoByBenefitCode;
};

export const administrationTypeToQPSReqId: Record<QPSAdministrationType, number> = {
  "Home Office": 841,
  "Self Admin+": 842,
  "TPA+": 843,
  "Self Admin": 844,
  TPA: 845,
};

export const qpsReqIdToAdministrationType = swapKeyValues(administrationTypeToQPSReqId);

export const getBasicBenefitsBillingPreferenceToQPSValue = (
  billingAdministrationType: BillingAdministrationType,
  dataFeeds: YesNoNotSure | null,
): { valueId: number; text: string } => {
  switch (billingAdministrationType) {
    case "LIST": {
      return {
        valueId: administrationTypeToQPSReqId["Home Office"],
        text: "Home Office",
      };
    }
    case "SELF": {
      return dataFeeds === "YES"
        ? {
            valueId: administrationTypeToQPSReqId["Self Admin+"],
            text: "Self Admin+",
          }
        : {
            valueId: administrationTypeToQPSReqId["Self Admin"],
            text: "Self Admin",
          };
    }
    case "TPA": {
      return dataFeeds === "YES"
        ? {
            valueId: administrationTypeToQPSReqId["TPA+"],
            text: "TPA+",
          }
        : {
            valueId: administrationTypeToQPSReqId["TPA"],
            text: "TPA",
          };
    }
    default:
      exhaustiveCheckFail(billingAdministrationType);
  }
};

export const isBenefitMappingToQPSSupported = (
  benefitType: BenefitTypeEIF,
  featureToggles: ClientFeatureToggles,
): boolean => {
  if (
    benefitType === "DENTAL" ||
    benefitType === "VISION" ||
    benefitType === "LIFE" ||
    benefitType === "DEP_LIFE" ||
    benefitType === "BASIC_ADND" ||
    benefitType === "BASIC_DEP_ADND" ||
    benefitType === "SHORT_TERM_DISABILITY" ||
    benefitType === "LONG_TERM_DISABILITY"
  )
    return true;

  if (
    (featureToggles.ONBOARD_DHMO_API && getIsDHMOBenefitType(benefitType)) ||
    benefitType === "DENTAL_DHMO"
  ) {
    return true;
  }

  if (featureToggles.ONBOARD_API_SUPPHEALTH && getIsSupplementalHealthBenefitType(benefitType)) {
    return true;
  }

  if (featureToggles.ONBOARD_API_PFML && getIsPFMLBenefitType(benefitType)) {
    return true;
  }

  return false;
};
