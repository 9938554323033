import { Input, Form } from "antd";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { clientValidationSchema, getSetYourGoalsQuestionStatuses } from "shared/validation/client";

import { Badge } from "../../components/Badge/Badge";
import { PopoverBadge } from "../../components/Badge/PopoverBadge";
import { Button } from "../../components/Button/Button";
import { ErrorMessage } from "../../components/Error/ErrorMessage";
import { LoadingError } from "../../components/Error/LoadingError";
import { Checkbox } from "../../components/Form/Checkbox";
import { InputErrorMessage } from "../../components/Form/InputErrorMessage";
import { HubCard } from "../../components/HubCard/HubCard";
import { HubCardHeader } from "../../components/HubCard/HubCardHeader";

import { UnsavedChangesModal, getHandleFormClosing } from "./UnsavedChangesModal";
import * as profileModulesStyles from "./profileModules.module.less";
import { useClientUtils } from "./useClientUtils";

import type { ElementClickedOptions } from "../../utils/analytics";
import type { CheckboxChangeEvent } from "client/src/components/Form/Checkbox";
import type { UpdateClientFunc } from "client/src/hooks/client";
import type { FormEventHandler, Dispatch, SetStateAction } from "react";
import type { Client } from "shared/types/Client";

const setYourGoalsValidationSchema = clientValidationSchema.pick([
  "enrollmentSupport",
  "streamlinedTechnology",
  "valueAddedServices",
  "billing",
  "ongoingService",
  "additionalSupport",
  "otherTopicsToPayAttentionTo",
  "otherTopicsToPayAttentionToText",
  "productVariety",
  "recommendationFromBroker",
  "qualityCoverage",
  "digitalCapabilities",
  "service",
  "cost",
  "claimsManagement",
  "mostImportantOther",
  "mostImportantOtherText",
]);

function getClientPropertiesToUpdate(client: Client) {
  const {
    enrollmentSupport,
    streamlinedTechnology,
    valueAddedServices,
    billing,
    ongoingService,
    additionalSupport,
    otherTopicsToPayAttentionTo,
    otherTopicsToPayAttentionToText,
    productVariety,
    recommendationFromBroker,
    qualityCoverage,
    digitalCapabilities,
    service,
    cost,
    claimsManagement,
    mostImportantOther,
    mostImportantOtherText,
  } = client;
  return {
    enrollmentSupport,
    streamlinedTechnology,
    valueAddedServices,
    billing,
    ongoingService,
    additionalSupport,
    otherTopicsToPayAttentionTo,
    otherTopicsToPayAttentionToText,
    productVariety,
    recommendationFromBroker,
    qualityCoverage,
    digitalCapabilities,
    service,
    cost,
    claimsManagement,
    mostImportantOther,
    mostImportantOtherText,
  };
}

export type SetYourGoalsProps = {
  client: Client;
  editing: boolean;
  disabled: boolean;
  updateClient: UpdateClientFunc;
  toggleActiveSection: () => void;
  trackElementClicked: (options: ElementClickedOptions) => void;
  onFinalSubmit?: Dispatch<SetStateAction<boolean>>;
};

export function SetYourGoals({
  client,
  editing,
  disabled,
  updateClient,
  toggleActiveSection,
  trackElementClicked,
  onFinalSubmit,
}: SetYourGoalsProps) {
  const updatedAt = client.setYourGoalsUpdatedAt;
  const updatedBy = client.setYourGoalsUpdatedByUser;

  const status = updatedAt == null ? "not-started" : "completed";

  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Set Your Goals",
        moduleState: updatedAt == null ? "Not Started" : "Completed",
        buttonLabel,
      });
    },
    [trackElementClicked, updatedAt],
  );

  const { formik } = useClientUtils({
    client,
    getClientPropertiesToUpdate,
    updateClient,
    validationSchema: setYourGoalsValidationSchema,
    type: "Company Profile",
    track,
    formikOptions: { enableReinitialize: true },
    onSuccessCallback() {
      if (status === "not-started") onFinalSubmit?.(true);
      toggleActiveSection();
    },
  });

  const handleCancel = getHandleFormClosing({ formik, onProceed: toggleActiveSection });

  const handleCancelWrapper = () => {
    // clears out any validation errors so they are not shown if you reopen the form
    setFirstQuestionStatus(null);
    setSecondQuestionStatus(null);
    formik.resetForm();
    void handleCancel();
  };

  const defineBooleanValuesAndToggleEditing = async () => {
    await formik.setValues({
      ...formik.values,
      enrollmentSupport: Boolean(formik.values.enrollmentSupport),
      streamlinedTechnology: Boolean(formik.values.streamlinedTechnology),
      valueAddedServices: Boolean(formik.values.valueAddedServices),
      billing: Boolean(formik.values.billing),
      ongoingService: Boolean(formik.values.ongoingService),
      additionalSupport: Boolean(formik.values.additionalSupport),
      otherTopicsToPayAttentionTo: Boolean(formik.values.otherTopicsToPayAttentionTo),
      productVariety: Boolean(formik.values.productVariety),
      recommendationFromBroker: Boolean(formik.values.recommendationFromBroker),
      qualityCoverage: Boolean(formik.values.qualityCoverage),
      digitalCapabilities: Boolean(formik.values.digitalCapabilities),
      service: Boolean(formik.values.service),
      cost: Boolean(formik.values.cost),
      claimsManagement: Boolean(formik.values.claimsManagement),
      mostImportantOther: Boolean(formik.values.mostImportantOther),
    });
    toggleActiveSection();
  };

  const addDetailsClick = async () => {
    track("Add Details");
    await defineBooleanValuesAndToggleEditing();
  };
  const editClick = async () => {
    track("Edit");
    await defineBooleanValuesAndToggleEditing();
  };

  const [firstQuestionStatus, setFirstQuestionStatus] = useState<string | null>(null);
  const [secondQuestionStatus, setSecondQuestionStatus] = useState<string | null>(null);
  const [triedToSubmit, setTriedToSubmit] = useState<boolean>(false);

  const setQuestionStatuses = (e?: CheckboxChangeEvent) => {
    const props = e ? { ...formik.values, [e.target.name ?? ""]: e.target.checked } : formik.values;
    const { firstQuestionAnswered, secondQuestionAnswered } =
      getSetYourGoalsQuestionStatuses(props);

    setFirstQuestionStatus(firstQuestionAnswered ? null : "Please select at least one response");
    setSecondQuestionStatus(secondQuestionAnswered ? null : "Please select at least one response");

    return { firstQuestionAnswered, secondQuestionAnswered };
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (triedToSubmit) {
      setQuestionStatuses(e);
    }
    formik.handleChange(e);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    track("Save");
    setTriedToSubmit(true);
    const { firstQuestionAnswered, secondQuestionAnswered } = setQuestionStatuses();

    if (firstQuestionAnswered && secondQuestionAnswered) {
      formik.handleSubmit(e);
    }
  };

  const firstQuestionErrorId = "topics-to-pay-attention-to-question__errormessage";
  const secondQuestionErrorId = "most-important-thing-question__errormessage";

  const errorsToCorrect = 0 + (firstQuestionStatus ? 1 : 0) + (secondQuestionStatus ? 1 : 0);

  return (
    <HubCard disabled={disabled}>
      <HubCardHeader
        title="Set Your Goals"
        description={
          <p>
            Tell Sun Life about your goals and we’ll follow up with expertise and other resources to
            help you achieve them.
          </p>
        }
        badge={
          <>
            {status === "not-started" && (
              <Badge srOnlyLabel="Task Status" variant="info" status="Not Started" />
            )}
            {status === "completed" && (
              <PopoverBadge
                aria-label="Task Status"
                variant="success"
                status="Completed"
                updatedBy={updatedBy}
                updatedAt={updatedAt}
              />
            )}
          </>
        }
        hasDivider={true}
      />

      {editing ? (
        <>
          <UnsavedChangesModal form={formik} />
          <form onSubmit={handleSubmit}>
            <p id="topics-to-pay-attention-to-question">
              <strong>
                Which of the following is a top priority for you during onboarding? Please pick the
                topic that is most applicable to your team's immediate goals.
              </strong>
            </p>

            <StackY dist={24} className="w-full">
              <StackY dist={8} className="w-full">
                <Checkbox
                  label={
                    <>
                      <strong>Enrollment Support</strong>
                      <br /> My employee's Enrollment Event is a top priority for me. I would like
                      extra support from Sun Life to ensure my employees understand and feel
                      confident in their benefit selection.
                    </>
                  }
                  name="enrollmentSupport"
                  checked={Boolean(formik.values.enrollmentSupport)}
                  onChange={handleCheckboxChange}
                  disabled={formik.isSubmitting}
                  errorId={firstQuestionStatus ? firstQuestionErrorId : undefined}
                />

                <Checkbox
                  label={
                    <>
                      <strong>Streamlined Technology</strong>
                      <br />I would like to tap into Sun Life's additional resources and
                      capabilities to streamline my day-to-day work and optimize my more manual
                      efforts today (EDX Connectivity, Digital Enrollment Tools, Benefits
                      Administration Platforms).
                    </>
                  }
                  name="streamlinedTechnology"
                  checked={Boolean(formik.values.streamlinedTechnology)}
                  onChange={handleCheckboxChange}
                  disabled={formik.isSubmitting}
                  errorId={firstQuestionStatus ? firstQuestionErrorId : undefined}
                />

                <Checkbox
                  label={
                    <>
                      <strong>Value-added services</strong>
                      <br />
                      I’d like extra help understanding ways to improve my current benefits offering
                      with value-added perks that are offered with Sun Life benefits (Travel
                      Assistance, Will Prep, EAP).
                    </>
                  }
                  name="valueAddedServices"
                  checked={Boolean(formik.values.valueAddedServices)}
                  onChange={handleCheckboxChange}
                  disabled={formik.isSubmitting}
                  errorId={firstQuestionStatus ? firstQuestionErrorId : undefined}
                />

                <Checkbox
                  label={
                    <>
                      <strong>Billing</strong>
                      <br />I want added support in understanding how to set up the optimal billing
                      structure for me and my team based on our size, priorities, and lines of
                      coverage.
                    </>
                  }
                  name="billing"
                  checked={Boolean(formik.values.billing)}
                  onChange={handleCheckboxChange}
                  disabled={formik.isSubmitting}
                  errorId={firstQuestionStatus ? firstQuestionErrorId : undefined}
                />

                <Checkbox
                  label={<strong>Other</strong>}
                  name="otherTopicsToPayAttentionTo"
                  checked={Boolean(formik.values.otherTopicsToPayAttentionTo)}
                  onChange={handleCheckboxChange}
                  disabled={formik.isSubmitting}
                  errorId={firstQuestionStatus ? firstQuestionErrorId : undefined}
                />

                {Boolean(formik.values.otherTopicsToPayAttentionTo) && (
                  <Form.Item
                    validateStatus={
                      formik.errors.otherTopicsToPayAttentionToText ? "error" : "success"
                    }
                    help={formik.errors.otherTopicsToPayAttentionToText}
                  >
                    <Input.TextArea
                      id="otherTopicsToPayAttentionToText"
                      name="otherTopicsToPayAttentionToText"
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          void formik.setFieldValue("otherTopicsToPayAttentionTo", true);
                        } else {
                          void formik.setFieldValue("otherTopicsToPayAttentionTo", false);
                        }
                        formik.handleChange(e);
                      }}
                      disabled={formik.isSubmitting}
                      value={formik.values.otherTopicsToPayAttentionToText || undefined}
                      maxLength={1000}
                      rows={6}
                      aria-labelledby="topics-to-pay-attention-to-question"
                    />
                  </Form.Item>
                )}

                {firstQuestionStatus && (
                  <InputErrorMessage error={firstQuestionStatus} id={firstQuestionErrorId} />
                )}
              </StackY>

              <div>
                <p id="most-important-thing-question">
                  <strong>What was your primary reason(s) for selecting Sun Life?</strong>
                </p>

                <StackY dist={8} className="w-full">
                  <Checkbox
                    label="Product Variety"
                    name="productVariety"
                    checked={Boolean(formik.values.productVariety)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Quality Coverage"
                    name="qualityCoverage"
                    checked={Boolean(formik.values.qualityCoverage)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Digital Capabilities"
                    name="digitalCapabilities"
                    checked={Boolean(formik.values.digitalCapabilities)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Service"
                    name="service"
                    checked={Boolean(formik.values.service)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Cost"
                    name="cost"
                    checked={Boolean(formik.values.cost)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Claims Management"
                    name="claimsManagement"
                    checked={Boolean(formik.values.claimsManagement)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  <Checkbox
                    label="Other"
                    name="mostImportantOther"
                    checked={Boolean(formik.values.mostImportantOther)}
                    onChange={handleCheckboxChange}
                    disabled={formik.isSubmitting}
                    errorId={secondQuestionStatus ? secondQuestionErrorId : undefined}
                  />

                  {Boolean(formik.values.mostImportantOther) && (
                    <Form.Item
                      validateStatus={formik.errors.mostImportantOtherText ? "error" : "success"}
                      help={formik.errors.mostImportantOtherText}
                      className="mb-0"
                    >
                      <Input.TextArea
                        id="mostImportantOtherText"
                        name="mostImportantOtherText"
                        onChange={(e) => {
                          if (e.currentTarget.value) {
                            void formik.setFieldValue("mostImportantOther", true);
                          } else {
                            void formik.setFieldValue("mostImportantOther", false);
                          }
                          formik.handleChange(e);
                        }}
                        disabled={formik.isSubmitting}
                        value={formik.values.mostImportantOtherText || undefined}
                        maxLength={1000}
                        rows={6}
                        aria-labelledby="most-important-thing-question"
                      />
                    </Form.Item>
                  )}

                  {secondQuestionStatus && (
                    <InputErrorMessage error={secondQuestionStatus} id={secondQuestionErrorId} />
                  )}
                </StackY>
              </div>

              {errorsToCorrect > 0 && (
                <ErrorMessage>
                  {errorsToCorrect > 1
                    ? "Please correct the errors above"
                    : "Please correct the error above"}
                </ErrorMessage>
              )}

              {formik.status && <LoadingError type="component" title={formik.status} />}
            </StackY>
            <StackX dist={8} className="mt-32">
              <Button
                size="middle"
                type="primary"
                htmlType="submit"
                disabled={formik.isSubmitting}
                loading={formik.isSubmitting}
                aria-label={formik.isSubmitting ? "Save" : undefined}
              >
                {formik.isSubmitting ? "" : "Save"}
              </Button>
              <Button
                size="middle"
                type="text"
                onClick={handleCancelWrapper}
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>
            </StackX>
          </form>
        </>
      ) : (
        <>
          {status === "not-started" ? (
            <div className="mt-32">
              <Button size="middle" type="primary" onClick={addDetailsClick} disabled={disabled}>
                Add Details
              </Button>
            </div>
          ) : (
            <>
              <StackY dist={16} className="w-full">
                <table className={clsx("w-full", profileModulesStyles.tableRowSpacing)}>
                  <tbody>
                    <tr>
                      <th
                        align="left"
                        scope="row"
                        className={clsx(profileModulesStyles.tableHeader, "body2")}
                      >
                        Which of the following is a top priority for you during onboarding? Please
                        pick the topic that is most applicable to your team's immediate goals.
                      </th>

                      <td
                        className={clsx("body3", profileModulesStyles.breakWord)}
                        data-testid="topPriorityValue"
                      >
                        {[
                          client.enrollmentSupport ? "Enrollment Support" : "",
                          client.streamlinedTechnology ? "Streamlined Technology" : "",
                          client.valueAddedServices ? "Value-added Services" : "",
                          client.billing ? "Billing" : "",
                          client.otherTopicsToPayAttentionTo
                            ? client.otherTopicsToPayAttentionToText
                              ? `Other: ${client.otherTopicsToPayAttentionToText}`
                              : "Other"
                            : "",
                        ]
                          .filter(Boolean)
                          .join(", ") || "-"}
                      </td>
                    </tr>

                    <tr>
                      <th
                        align="left"
                        scope="row"
                        className={clsx(profileModulesStyles.tableHeader, "body2")}
                      >
                        What was your primary reason(s) for selecting Sun Life?
                      </th>

                      <td
                        className={clsx("body3", profileModulesStyles.breakWord)}
                        data-testid="primaryReasonValue"
                      >
                        {[
                          client.productVariety ? "Product Variety" : "",
                          client.recommendationFromBroker ? " Recommendation from Broker" : "",
                          client.qualityCoverage ? "Quality Coverage" : "",
                          client.digitalCapabilities ? "Digital Capabilities" : "",
                          client.service ? "Service" : "",
                          client.cost ? "Cost" : "",
                          client.claimsManagement ? "Claims Management" : "",
                          client.mostImportantOther
                            ? client.mostImportantOtherText
                              ? `Other: ${client.mostImportantOtherText}`
                              : "Other"
                            : "",
                        ]
                          .filter(Boolean)
                          .join(", ") || "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Button size="middle" type="default" onClick={editClick} disabled={disabled}>
                  Edit
                </Button>
              </StackY>
            </>
          )}
        </>
      )}
    </HubCard>
  );
}
