import { getIsAllowedToReviewEIF } from "client/src/domain/EIF/common/utils/getIsAllowedToReviewEIF";
import { useSlobAuth } from "client/src/hooks/auth";
import { useChangeSnapshot, useGetClientDEIFChanges } from "client/src/hooks/changeLogs";
import { useGetContacts } from "client/src/hooks/contact";
import { useGetDocuments } from "client/src/hooks/document";
import { useGetSubsidiariesByClientId } from "client/src/hooks/subsidiary";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import { useGetBills, useGetDeletedBills } from "../../hooks/bill";
import { useGetClientByID } from "../../hooks/client";
import { useGetClasses } from "../../hooks/employeeClass";
import { triggerError } from "../../hooks/generalError";
import { useGetPlansByClientId } from "../../hooks/plans";
import { useClientHubParams } from "../../hooks/useClientHubParams";
import { useTitle } from "../../hooks/useTitle";
import { EIFSummary } from "./EIFSummary";

import { EIFSummarySkeleton } from "./EIFSummaryWrapper";

export function EIFSummaryPage() {
  const { clientId } = useClientHubParams(["clientId"]);
  const { authUser } = useSlobAuth();

  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
    isSuccess: isSuccessClient,
  } = useGetClientByID(clientId);

  const {
    data: bills,
    isLoading: isLoadingBills,
    isError: isErrorBills,
    error: errorBills,
    isSuccess: isSuccessBills,
  } = useGetBills(clientId);

  const {
    data: deletedBills,
    isLoading: isLoadingDeletedBills,
    isError: isErrorDeletedBills,
    error: errorDeletedBills,
    isSuccess: isSuccessDeletedBills,
  } = useGetDeletedBills(clientId);

  const {
    data: clientPlans,
    isLoading: isLoadingClientPlans,
    isError: isErrorClientPlans,
    error: errorClientPlans,
    isSuccess: isSuccessClientPlans,
  } = useGetPlansByClientId(clientId);

  const {
    data: employeeClasses,
    isLoading: isLoadingClasses,
    isError: isErrorClasses,
    error: errorClasses,
    isSuccess: isSuccessClasses,
  } = useGetClasses(clientId);

  const {
    data: featureToggles,
    isLoading: isLoadingFeatureToggles,
    isSuccess: isSuccessFeatureToggles,
    isError: isErrorFeatureToggles,
    error: errorFeatureToggles,
  } = useGetFeatureToggles(clientId);

  const {
    data: changes,
    isLoading: isLoadingChangeLogs,
    isSuccess: isSuccessChangeLogs,
    isError: isErrorChangeLogs,
    error: errorChangeLogs,
  } = useGetClientDEIFChanges(clientId);

  const {
    data: billingSummaryStatementTemplates,
    isLoading: isLoadingBillingSummaryStatementTemplates,
    isError: isErrorBillingSummaryStatementTemplates,
    error: errorBillingSummaryStatementTemplates,
    isSuccess: isSuccessBillingSummaryStatementTemplates,
  } = useGetDocuments({ clientId, categories: ["billing-summary-statement-template"] });

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    error: errorContacts,
    isSuccess: isSuccessContacts,
  } = useGetContacts(clientId);

  const {
    data: subsidiaries,
    isLoading: isLoadingSubsidiaries,
    isError: isErrorSubsidiaries,
    error: errorSubsidiaries,
    isSuccess: isSuccessSubsidiaries,
  } = useGetSubsidiariesByClientId(clientId);

  useTitle(client ? `${client.name} - Company Information Summary` : "Company Information Summary");

  const isLoading =
    isLoadingClient ||
    isLoadingBills ||
    isLoadingDeletedBills ||
    isLoadingClientPlans ||
    isLoadingClasses ||
    isLoadingChangeLogs ||
    isLoadingFeatureToggles ||
    isLoadingBillingSummaryStatementTemplates ||
    isLoadingContacts ||
    isLoadingSubsidiaries;

  const isSuccess =
    isSuccessClient &&
    isSuccessBills &&
    isSuccessDeletedBills &&
    isSuccessClientPlans &&
    isSuccessClasses &&
    isSuccessChangeLogs &&
    isSuccessFeatureToggles &&
    isSuccessBillingSummaryStatementTemplates &&
    isSuccessContacts &&
    isSuccessSubsidiaries;

  if (isErrorClient) {
    triggerError(errorClient);
  }
  if (isErrorBills) {
    triggerError(errorBills);
  }
  if (isErrorDeletedBills) {
    triggerError(errorDeletedBills);
  }
  if (isErrorClientPlans) {
    triggerError(errorClientPlans);
  }
  if (isErrorClasses) {
    triggerError(errorClasses);
  }
  if (isErrorFeatureToggles) {
    triggerError(errorFeatureToggles);
  }
  if (isErrorChangeLogs) {
    triggerError(errorChangeLogs);
  }
  if (isErrorBillingSummaryStatementTemplates) {
    triggerError(errorBillingSummaryStatementTemplates);
  }
  if (isErrorContacts) {
    triggerError(errorContacts);
  }
  if (isErrorSubsidiaries) {
    triggerError(errorSubsidiaries);
  }

  const changeSnapshot = useChangeSnapshot(changes);

  if (isLoading) {
    return <EIFSummarySkeleton />;
  }

  if (client?.isArchived) {
    return <Navigate to={RouteData.notFound.getPath()} replace />;
  }

  if (
    isSuccess &&
    client &&
    bills &&
    deletedBills &&
    clientPlans &&
    employeeClasses &&
    featureToggles &&
    changes &&
    changeSnapshot &&
    authUser &&
    contacts &&
    subsidiaries
  ) {
    const isAllowedToReviewEIF = getIsAllowedToReviewEIF({
      client,
      authUser,
      changesSnapshot: changeSnapshot,
    });

    if (!isAllowedToReviewEIF) {
      return (
        <Navigate
          to={RouteData.clientTaskDetail.getPath(clientId, "eif-submit-company-information")}
          replace
        />
      );
    }

    return (
      <EIFSummary
        client={client}
        contacts={contacts}
        bills={bills}
        deletedBills={deletedBills}
        billingSummaryStatementTemplates={billingSummaryStatementTemplates ?? []}
        clientPlans={clientPlans}
        subsidiaries={subsidiaries}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        employeeClasses={employeeClasses}
        featureToggles={featureToggles}
        signerMode="inside"
      />
    );
  }

  return null;
}
