import { Typography } from "antd";

import { StackY } from "client/src/components/Spacing/Spacing";
import { SLF_ADMIN, SLF_OWNER } from "shared/rbac/roles";
import { Button } from "../../../components/Button/Button";
import { Checkbox } from "../../../components/Form/Checkbox";
import { FormInput } from "../../../components/Form/Input";
import { RadioGroup } from "../../../components/Form/RadioGroup";
import { H2 } from "../../../components/Typography/Typography";
import { hasFormikError } from "../../../utils/hasFormikError";

import * as styles from "./form.module.less";

import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type { SlfInternalCreateValidationSchema } from "shared/validation/user";

const { Text } = Typography;
type SlfInternalCreateFormProps = {
  formik: FormikTypeFromValidator<typeof SlfInternalCreateValidationSchema>;
  clientError: string;
};
export const SlfInternalCreateForm = ({ formik, clientError }: SlfInternalCreateFormProps) => {
  return (
    <div className={styles.modalContainer}>
      <H2 as="h1">Add Internal User</H2>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={24}>
          <FormInput
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            maxLength={191}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            error={formik.errors.firstName}
            showRequired={true}
          />
          <FormInput
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            maxLength={191}
            onBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            error={formik.errors.lastName}
            showRequired={true}
          />
          <FormInput
            label="Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber ?? ""}
            onChange={formik.handleChange}
            maxLength={191}
            onBlur={formik.handleBlur}
            touched={formik.touched.phoneNumber}
            error={formik.errors.phoneNumber}
          />
          <FormInput
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={(e) => {
              formik.setStatus("");
              formik.handleChange(e);
            }}
            maxLength={191}
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            error={formik.errors.email}
            showRequired={true}
          />
          <RadioGroup
            name="role"
            label="Role"
            value={formik.values.role}
            direction="vertical"
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            touched={formik.touched.role}
            error={hasFormikError(formik, "role") && "Required."}
            options={[
              {
                value: SLF_OWNER,
                label: "Owner - can view and manage all clients, brokers, and internal users",
              },
              { value: SLF_ADMIN, label: "Admin - can view and manage all clients and brokers" },
            ]}
          />
          <Checkbox
            label="Send Welcome Email"
            name="sendWelcomeEmail"
            checked={formik.values.sendWelcomeEmail ?? false}
            onChange={formik.handleChange}
          />
          {clientError && (
            <Text type="danger" className={styles.errorMessage}>
              {clientError}
            </Text>
          )}
          <div className={styles.footer}>
            <Button
              type="primary"
              htmlType="submit"
              loading={formik.isSubmitting}
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            >
              Add User
            </Button>
          </div>
        </StackY>
      </form>
    </div>
  );
};
