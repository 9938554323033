import { useQueryClient } from "@tanstack/react-query";
import { jsonClientPoliciesToClientPolicies } from "client/src/hooks/client";
import { compareQueryKey, useSlobMutation } from "client/src/hooks/query";
import type { ResponseError } from "client/src/hooks/query";
import type { Policy } from "shared/types/Client";

export type UpdatePolicyQuery = Pick<
  ReturnType<typeof useUpdateDentalRollover>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

type DentalRolloverInput = { noDentalRollover: boolean };

export const useUpdateDentalRollover = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    DentalRolloverInput,
    Policy,
    `/api/clients/:clientId/policy-task/:policyId/dental-rollover`,
    ResponseError,
    unknown,
    { prefill?: boolean }
  >({
    method: "put",
    path: `/api/clients/:clientId/policy-task/:policyId/dental-rollover`,
    map: jsonClientPoliciesToClientPolicies,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
        });
      },
    },
  });
};
