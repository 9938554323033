import { StackY } from "client/src/components/Spacing/Spacing";
import React from "react";

import { Checkbox } from "../../../components/Form/Checkbox";
import { SlobSelect } from "../../../components/Form/SlobSelect";
import { Body3, Eyebrow } from "../../../components/Typography/Typography";

import * as styles from "./reassignRecipientForm.module.less";

import type { FormikTypeFromValidator } from "../../../hooks/useSlobFormik";
import type { LabeledValue } from "antd/lib/select";
import type { RecipientWithMetadata } from "shared/types/OnboardingFormRecipient";
import type { updateSignerTypeValidator } from "shared/validation/onboardingForm";

type UpdateSignerTypeFormProps = {
  formik: FormikTypeFromValidator<typeof updateSignerTypeValidator>;
  onboardingFormRecipients: ReadonlyArray<RecipientWithMetadata>;
};

export const UpdateSignerTypeForm: React.FC<UpdateSignerTypeFormProps> = ({
  formik,
  onboardingFormRecipients,
}) => {
  const recipientsAvailableToUpdate = onboardingFormRecipients.reduce<Array<LabeledValue>>(
    (recipients, recipient) => {
      const status = recipient.status;
      if (status === "CREATED" || status === "DELIVERED" || status === "SENT") {
        recipients.push({
          label: `${recipient.name} - ${recipient.roleName}`,
          value: recipient.id,
        });
      }
      return recipients;
    },
    [],
  );

  const selectedRecipient = onboardingFormRecipients.find(
    ({ id }) => formik.values.recipientId === id,
  );

  const recipientIdOnChange = (labeledValue: LabeledValue) => {
    void formik.setFieldValue("recipientId", labeledValue.value);
  };

  return (
    <div className={styles.reassignForm}>
      <StackY dist={16} className="w-full">
        <Body3 as="p">
          You can change a signer from an inside signer to an outside signer, or the opposite.
        </Body3>
        <h3>Choose from an existing signer</h3>
        <SlobSelect
          placeholder="Signer to update"
          name="recipientId"
          value={formik.values.recipientId}
          options={recipientsAvailableToUpdate}
          onChange={recipientIdOnChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.recipientId}
          error={formik.errors.recipientId}
        />
        {selectedRecipient && (
          <>
            <Eyebrow>
              {selectedRecipient.name} is currently an{" "}
              {selectedRecipient.docusignClientUserId ? "inside" : "outside"} signer
            </Eyebrow>
            <h3>Update the signer type</h3>
            <Checkbox
              label={
                selectedRecipient.docusignClientUserId
                  ? "Update to an outside signer"
                  : "Update to an inside signer"
              }
              name="updateSigner"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={!!formik.values.updateSigner}
              error={formik.errors.updateSigner}
            />
            {selectedRecipient.docusignClientUserId ? (
              <Body3>
                Outside signers will receive an email from DocuSign to sign their form outside of
                Onboard. You will still be able to track their form signing progress within Onboard
                on this page.
              </Body3>
            ) : (
              <>
                <Body3 as="p">
                  Inside signers will be able to access their Onboard forms directly through Sun
                  Life Onboard. You will still be able to track their form signing progress within
                  Onboard on this page.
                </Body3>
                <Body3 as="p">
                  Before you update this person to be an inside signer, make sure you have added
                  them to Onboard first. For help with this, you can visit the Manage Client page.
                </Body3>
              </>
            )}
          </>
        )}
      </StackY>
    </div>
  );
};
