import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import clsx from "clsx";
import React from "react";

import { formatShortDateTimeLocal } from "../../../../shared/utils/format";
import { ReactComponent as InfoOutlineIcon } from "../../components/Icons/InfoOutlineIcon.svg";
import { ProgressCircle } from "../../components/Icons/ProgressCircle";

import { Body5 } from "../../components/Typography/Typography";
import * as styles from "./onboardingFormTile.module.less";
import * as outsideSignerStyles from "./outsideSigner.module.less";

import type { RecipientMetadataActionType } from "shared/types/OnboardingFormRecipient";

export const DataTestId = {
  EmailMismatchIcon: "email-mismatch-icon",
  OutsideSignerIcon: "outside-signer-icon",
};

export const recipientStatusToProgressStatus = (status: RecipientMetadataActionType | "voided") => {
  switch (status) {
    case "completed":
      return "completed";
    case "started":
    case "edited":
      return "in-progress";
    case "declined":
    case "voided":
      return "declined";
    default:
      return "not-started";
  }
};

type RecipientRowProps = {
  name: string;
  roleName: string;
  action: RecipientMetadataActionType | "voided";
  date: Date | null;
  reason: string | null;
  isOutsideSigner?: boolean | null;
  isEmailMismatch?: boolean | null;
};

export const RecipientRow: React.FC<RecipientRowProps> = ({
  name,
  roleName,
  action,
  date,
  reason,
  isOutsideSigner,
  isEmailMismatch,
}) => {
  const formattedDate = date ? formatShortDateTimeLocal(date) : null;

  return (
    <Row className={styles.recipientRow}>
      <Col span={8}>
        <div className={styles.progressCircleCell}>
          <ProgressCircle variant={recipientStatusToProgressStatus(action)} />
          <span className="body4">{name}</span>
        </div>
      </Col>
      <Col span={7}>
        <span className="body3">{roleName}</span>
        {isOutsideSigner && (
          <span className={outsideSignerStyles.outsideSigner}>
            <span className="body5">Outside signer</span>
            <span className={outsideSignerStyles.infoIcon}>
              <Tooltip
                title="Outside signers will receive an email from DocuSign to sign their form outside of Onboard. You will still be able to track their form signing progress within Onboard"
                placement="top"
                arrowPointAtCenter
                overlayClassName={outsideSignerStyles.toolTip}
              >
                <InfoOutlineIcon data-testid={DataTestId.OutsideSignerIcon} />
              </Tooltip>
            </span>
          </span>
        )}
        {isEmailMismatch && (
          <span className={outsideSignerStyles.outsideSigner}>
            <Body5 redMedium>Email mismatch</Body5>
            <span className={outsideSignerStyles.warnIcon}>
              <Tooltip
                title={`Email for ${name} does not match across Onboard and DocuSign. Please review and update the email address.`}
                placement="top"
                arrowPointAtCenter
                overlayClassName={outsideSignerStyles.toolTip}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  data-testid={DataTestId.EmailMismatchIcon}
                />
              </Tooltip>
            </span>
          </span>
        )}
      </Col>
      <Col span={9}>
        {action !== "none" && formattedDate != null && (
          <span className="body3">
            <span className={styles.capitalize}>{action}</span> on {formattedDate}
          </span>
        )}
      </Col>
      {reason && (
        <p className={clsx(["body3", styles.reasonBox])}>
          <i>"{reason}"</i>
        </p>
      )}
    </Row>
  );
};
