import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useState, useCallback, useEffect } from "react";

import { Button } from "../../../components/Button/Button";
import { Spinner } from "../../../components/Spinner/Spinner";
import { Body3 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { ResponseError } from "../../../hooks/query";
import { useDebouncedValue } from "../../../hooks/useDebouncedValue";
import { useFocusControl } from "../../../hooks/useFocusControl";
import { useGetBrokers, useAssignBrokerToClient } from "../../../hooks/user";

import * as styles from "./BrokerForm.module.less";

import type { ClientId } from "shared/types/Client";
import type { Broker } from "shared/types/User";

type BrokerSearchOptionsProps = {
  search: string;
  onAssign: (broker: Broker) => void;
  isInputFocus: boolean;
  clientId: ClientId;
};

export const BrokerSearchOptions = ({
  search,
  onAssign,
  isInputFocus,
  clientId,
}: BrokerSearchOptionsProps) => {
  const { shouldHide, setShouldHide, wrapperRef } = useFocusControl();

  const {
    data: response,
    isFetching,
    isFetched,
    isError,
  } = useGetBrokers({
    page: 1,
    pageSize: 5,
    search: search,
    sort: {
      sortBy: "lastName",
      direction: "asc",
    },
  });

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- disable
  useEffect(() => {
    if (search) setShouldHide(false);
  }, [search, setShouldHide]);

  const searchResult = response?.data || [];

  const doneFetch = !isFetching && isFetched;

  return (
    <div
      ref={wrapperRef}
      className={styles.searchFloatingContainer}
      style={{ display: shouldHide && !isInputFocus ? "none" : "" }}
    >
      {isFetching && !isError && <p>Searching...</p>}
      {doneFetch && !searchResult?.length && !isError && <p>No Brokers found</p>}
      {isError && (
        <Body3 redMedium as="p">
          Problem searching brokers
        </Body3>
      )}
      {doneFetch &&
        searchResult.map((broker: Broker) => (
          <div className={styles.searchResultRow} key={broker.id}>
            <div className={styles.info}>
              <div className={styles.brokerName}>{broker.fullName}</div>
              <em>{broker.email}</em>
            </div>
            <Button
              type="primary"
              disabled={broker.clientIds.includes(clientId)}
              onClick={() => onAssign(broker)}
            >
              Assign
            </Button>
          </div>
        ))}
    </div>
  );
};

type BrokerSearchAndAssignProps = {
  onAssign: () => void;
  onProcess: (status: boolean) => void;
  disabled: boolean;
  onError: (error: string) => void;
  onChange?: () => void;
  clientId: ClientId;
  sendWelcomeEmail: boolean;
};

export const BrokerSearchAndAssign = ({
  onAssign,
  onError,
  onProcess,
  disabled,
  clientId,
  onChange,
  sendWelcomeEmail,
}: BrokerSearchAndAssignProps) => {
  const [searchText, setSearchText] = useState("");
  const [isAssigning, setIsAssigning] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  const { mutateAsync: assignClient } = useAssignBrokerToClient();

  const onSearch = ({ text }: { text: string }) => {
    setSearchText(text.toLowerCase());
  };

  const search = useDebouncedValue(searchText, 300);

  const toggleIsAssigning = useCallback(
    (status: boolean) => {
      setIsAssigning(status);
      onProcess(status);
    },
    [onProcess],
  );

  const assignBroker = useCallback(
    async (broker: Broker) => {
      toggleIsAssigning(true);
      try {
        onError("");
        await assignClient({
          params: { userId: broker.id, clientId },
          data: { sendWelcomeEmail },
        });
        onAssign();
        onChange?.();
        void slobMessage.success("Successfully assigned Broker");
      } catch (error) {
        const responseError = ResponseError.getUserFacingErrorMessage(
          error,
          "Problem assigning Broker",
        );
        toggleIsAssigning(false);
        onError(responseError);
      }
    },
    [assignClient, clientId, onAssign, onError, toggleIsAssigning, sendWelcomeEmail, onChange],
  );

  return (
    <>
      <div className={styles.searchContainer}>
        <Input
          name="searchText"
          type="search"
          role="combobox"
          className={styles.searchInput}
          disabled={isAssigning || disabled}
          placeholder="Search by name or email"
          aria-label="Search by name or email"
          autoComplete="none"
          value={searchText}
          onChange={(e) => onSearch({ text: e.target.value })}
          onFocus={() => setIsInputFocus(true)}
          onBlur={() => setIsInputFocus(false)}
          suffix={<SearchOutlined style={{ fontSize: "0.85rem" }} />}
        />
        {!isAssigning && search?.length >= 2 && (
          <BrokerSearchOptions
            search={search}
            onAssign={assignBroker}
            isInputFocus={isInputFocus}
            clientId={clientId}
          />
        )}
      </div>
      {isAssigning && (
        <div className={styles.assignStatus}>
          <Spinner size="small" /> Assigning Broker
        </div>
      )}
    </>
  );
};
