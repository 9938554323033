import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Col, Row } from "client/src/components/Grid/Grid";
import { Modal } from "client/src/components/Modal/Modal";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { useSlobFormik } from "client/src/hooks/useSlobFormik";
import { assertIsDefined } from "shared/utils/utils";
import * as Yup from "yup";
import type { Client, Policy, PolicyId } from "shared/types/Client";
import type { ValueOf, ValuesForValidationSchema } from "shared/types/Helper";
import type { benAdminAndDataFeedsValidationSchema } from "shared/validation/policy";

const fieldsToCopy = [
  "hasBenAdminPlatform",
  "dataFeeds",
  "dataFeedsBenAdminContact",
  "dataFeedsProductionSupportContact",
  "dataFeedsImplementorContact",
] as const;

type FieldToCopy = ValueOf<typeof fieldsToCopy>;

type FormValues = Partial<
  Pick<
    ValuesForValidationSchema<typeof benAdminAndDataFeedsValidationSchema>,
    | "hasBenAdminPlatform"
    | "benAdminPlatform"
    | "benAdminPlatformId"
    | "benAdminPlatformOtherName"
    | "dataFeeds"
    | "dataFeedsBenAdminContact"
    | "dataFeedsImplementorContact"
    | "dataFeedsProductionSupportContact"
  >
>;

const validationSchema = Yup.object({
  policyId: Yup.string().required("Please select a policy to copy from"),
  fields: Yup.array()
    .of(Yup.mixed<FieldToCopy>().oneOf(fieldsToCopy).required())
    .strict()
    .min(1, "Please select at least one field to copy")
    .required(),
});

type Props = {
  open: boolean;
  onCancel: () => void;
  onCopy: (copiedValues: FormValues) => void;
  client: Client;
  policy: Policy;
  formValues: FormValues;
};

export function CopyBenAdminAndDataFeedsFromAnotherPolicyModal(props: Props) {
  const { open, onCancel, onCopy, client, policy, formValues } = props;

  const policiesToCopyFrom = getPoliciesToCopyBenAdminAndDataFeedsFrom(client, policy);

  const initialPolicyId = policiesToCopyFrom[0]?.id ?? "";

  const formik = useSlobFormik({
    validationSchema,
    initialValues: {
      policyId: initialPolicyId,
      fields: [],
    },
    onSubmit: (values) => {
      const policyToCopyFrom = policiesToCopyFrom.find((p) => p.id === values.policyId);
      assertIsDefined(policyToCopyFrom, "policyToCopyFrom");
      const copiedValues = copy(policyToCopyFrom, policy.id, values.fields);
      onCopy(copiedValues);
    },
  });

  const policyToCopyFrom = policiesToCopyFrom.find((p) => p.id === formik.values.policyId);

  const willOverwriteValues = formik.values.fields.some((field) => formValues[field] != null);

  return (
    <Modal
      width={780}
      title="Copy from another policy"
      open={open}
      footer={null}
      onCancel={onCancel}
      focusTriggerAfterClose={false}
      destroyOnClose
    >
      <form onSubmit={formik.handleSubmit}>
        {willOverwriteValues && (
          <div className="mb-32">
            <AlertBanner
              variant="warning"
              message={
                <Body3>
                  Existing responses for this policy will be replaced by if you choose to overwrite
                  those specific fields.
                </Body3>
              }
            />
          </div>
        )}

        {policiesToCopyFrom.length === 0 ? (
          <p>There are no policies to copy from at the moment.</p>
        ) : (
          <>
            <p id="policyId-label">Select which policy to copy from</p>

            <RadioGroup
              name="policyId"
              aria-labelledby="policyId-label"
              direction="vertical"
              disabled={false}
              touched={formik.touched.policyId}
              error={formik.errors.policyId}
              value={formik.values.policyId}
              onChange={async (e) => {
                await formik.setFieldValue("fields", []);
                return formik.handleChange(e);
              }}
              options={policiesToCopyFrom.map((policy) => ({
                value: policy.id,
                label: policy.primaryPolicy
                  ? `Policy #${policy.slfPolicyNumber} (Primary)`
                  : `Policy #${policy.slfPolicyNumber}`,
              }))}
            />

            {policyToCopyFrom && (
              <>
                <p>Select what you would like to apply</p>

                <StackY dist={8} wrap={false}>
                  <Checkbox
                    id="fields_hasBenAdminPlatform"
                    name="fields"
                    label="Benefits administration platform"
                    value="hasBenAdminPlatform"
                    checked={formik.values.fields.includes("hasBenAdminPlatform")}
                    onChange={async (e) => {
                      await formik.setFieldValue(
                        "fields",
                        e.target.checked ? ["hasBenAdminPlatform"] : [],
                      );
                    }}
                  />

                  {policyToCopyFrom.hasBenAdminPlatform === "YES" && (
                    <div className="ml-32 stack-y-8">
                      <Checkbox
                        id="fields_dataFeeds"
                        name="fields"
                        label="Data feed set up"
                        value="dataFeeds"
                        checked={formik.values.fields.includes("dataFeeds")}
                        disabled={!formik.values.fields.includes("hasBenAdminPlatform")}
                        onChange={async (e) => {
                          await formik.setFieldValue(
                            "fields",
                            e.target.checked
                              ? ["hasBenAdminPlatform", "dataFeeds"]
                              : ["hasBenAdminPlatform"],
                          );
                        }}
                      />

                      {policyToCopyFrom.dataFeeds === "YES" && (
                        <div className="ml-32 stack-y-8">
                          <Checkbox
                            id="fields_dataFeedsBenAdminContact"
                            name="fields"
                            label="Benefits administration contact details"
                            value="dataFeedsBenAdminContact"
                            checked={formik.values.fields.includes("dataFeedsBenAdminContact")}
                            disabled={!formik.values.fields.includes("dataFeeds")}
                            onChange={formik.handleChange}
                          />

                          <Checkbox
                            id="fields_dataFeedsProductionSupportContact"
                            name="fields"
                            label="Data feed support contact details"
                            value="dataFeedsProductionSupportContact"
                            checked={formik.values.fields.includes(
                              "dataFeedsProductionSupportContact",
                            )}
                            disabled={!formik.values.fields.includes("dataFeeds")}
                            onChange={formik.handleChange}
                          />

                          <Checkbox
                            id="fields_dataFeedsImplementorContact"
                            name="fields"
                            label="Data feed implementation contact details"
                            value="dataFeedsImplementorContact"
                            checked={formik.values.fields.includes("dataFeedsImplementorContact")}
                            disabled={!formik.values.fields.includes("dataFeeds")}
                            onChange={formik.handleChange}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </StackY>
              </>
            )}
          </>
        )}

        <Row justify="end" align="middle" gutter={24} className="mt-40">
          <Col>
            <Button type="text-only" size="middle" onClick={onCancel}>
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              type="primary"
              size="middle"
              htmlType="submit"
              disabled={formik.values.fields.length === 0}
            >
              Copy
            </Button>
          </Col>
        </Row>
      </form>
    </Modal>
  );
}

export function getPoliciesToCopyBenAdminAndDataFeedsFrom(
  client: Client,
  policy: Policy,
): Policy[] {
  const policiesToCopyFrom = client.policies.filter(
    (p) => p.hasBenAdminPlatform !== null && p.id !== policy.id,
  );

  return policiesToCopyFrom;
}

export function copy(policyToCopyFrom: Policy, policyId: PolicyId, fields: FieldToCopy[]) {
  const fieldsSet = new Set(fields);

  const getTargetContact = (fromContact: FormValues["dataFeedsBenAdminContact"]) => {
    const targetContact = fromContact
      ? {
          policyId,
          type: fromContact.type,
          firstName: fromContact.firstName,
          lastName: fromContact.lastName,
          email: fromContact.email,
        }
      : null;
    return targetContact;
  };

  const copiedValues = {
    ...(fieldsSet.has("hasBenAdminPlatform")
      ? {
          hasBenAdminPlatform: policyToCopyFrom.hasBenAdminPlatform,
          benAdminPlatform: policyToCopyFrom.benAdminPlatform,
          benAdminPlatformId: policyToCopyFrom.benAdminPlatformId,
          benAdminPlatformOtherName: policyToCopyFrom.benAdminPlatformOtherName,
        }
      : {}),

    dataFeeds: fieldsSet.has("dataFeeds") ? policyToCopyFrom.dataFeeds : null,

    dataFeedsBenAdminContact: fieldsSet.has("dataFeedsBenAdminContact")
      ? getTargetContact(policyToCopyFrom.dataFeedsBenAdminContact)
      : null,

    dataFeedsProductionSupportContact: fieldsSet.has("dataFeedsProductionSupportContact")
      ? getTargetContact(policyToCopyFrom.dataFeedsProductionSupportContact)
      : null,

    dataFeedsImplementorContact: fieldsSet.has("dataFeedsImplementorContact")
      ? getTargetContact(policyToCopyFrom.dataFeedsImplementorContact)
      : null,
  };

  return copiedValues;
}
