import clsx from "clsx";

import { TrackerCircle } from "../Icons/TrackerCircle";
import { SlobPopover } from "../SlobPopover/SlobPopover";

import * as styles from "./progressTracker.module.less";

import type { PhaseId } from "@prisma/client";
import type { ReactNode } from "react";

type ProgressItem = {
  id: PhaseId;
  title: string;
  description?: ReactNode;
};

export type ItemStatus = "past" | "current" | "future";

export const getItemStatus = (
  index: number,
  currentIndex: number,
  lastIndex: number,
): ItemStatus => {
  if (index < currentIndex || currentIndex === lastIndex) return "past"; // final phase is automatically "past" i.e. completed
  if (index > currentIndex) return "future";
  return "current";
};

export const ProgressTracker = ({
  items,
  currentItem,
  isOnboardingComplete,
}: {
  items: ProgressItem[];
  currentItem?: PhaseId;
  isOnboardingComplete: boolean;
}) => {
  const currentItemIndex = items.findIndex((item) => item.id === currentItem);

  return (
    <ol
      className={clsx(
        styles.list,
        isOnboardingComplete ? styles.list__completed : styles.list__inProgress,
      )}
    >
      {items.map((item, index) => {
        const currentStatus = getItemStatus(index, currentItemIndex, items.length - 1);
        const placement = !index
          ? "bottomLeft"
          : index === items.length - 1
          ? "bottomRight"
          : "bottom";

        const shouldHavePopover = item.description && index !== currentItemIndex;

        const children = (
          <>
            <TrackerCircle
              aria-hidden={true}
              status={currentStatus}
              className={styles.trackerCircle}
            />
            <span className={clsx(styles.itemTitle, "body5")}>
              {currentStatus === "past" && <span className="sr-only">Completed Phase: </span>}
              {currentStatus === "current" && <span className="sr-only">Current Phase: </span>}
              {currentStatus === "future" && <span className="sr-only">Future Phase: </span>}

              {item.title}
            </span>
          </>
        );

        return (
          <li
            key={item.title}
            className={clsx(styles.item, {
              [styles.itemPast]: currentStatus === "past",
              [styles.itemCurrent]: currentStatus === "current",
              [styles.itemFuture]: currentStatus === "future",
            })}
          >
            {shouldHavePopover ? (
              <SlobPopover
                id={item.id}
                title={item.title}
                content={item.description}
                placement={placement}
                trigger={["hover", "click"]}
              >
                <button className="btn-reset">
                  {children}

                  <span className="sr-only">. Learn more about the {item.title} phase.</span>
                </button>
              </SlobPopover>
            ) : (
              children
            )}
          </li>
        );
      })}
    </ol>
  );
};
