import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Body1, Body3 } from "client/src/components/Typography/Typography";
import { useElementSize } from "client/src/hooks/elementSize";
import clsx from "clsx";
import React, { useRef } from "react";

import * as styles from "./Table.module.less";
import type { CSSProperties } from "react";

/**
 * The header cell in the table.
 */
type HeaderCell = {
  /**
   * The contents of the header cell.
   */
  contents: React.ReactNode;
};

/**
 * The data cell in the table.
 */
type DataCell = {
  /**
   * Heading of the data cell.
   */
  heading: React.ReactNode;
  /**
   * Contents of the data cell.
   */
  contents: React.ReactNode;
  /**
   * Additional props to pass to the cell.
   */
  cellProps?: React.TdHTMLAttributes<HTMLTableCellElement>;
};
/**
 * Check if the cell is a data cell.
 */
function isDataCell(cell: HeaderCell | DataCell): cell is DataCell {
  return "heading" in cell;
}

/**
 * Props for the {@link Table} component.
 */
interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  /**
   * The header of the table.
   */
  head: HeaderCell[];
  /**
   * The body of the table.
   */
  body: [HeaderCell, ...(DataCell | undefined)[]][];
  /**
   * The faux footer of the table.
   */
  footer?: React.ReactNode;
  /**
   * Whether to skip rows that are empty. When true rows that are empty will not be rendered. Empty check is done by checking if all cells contents are falsy.
   */
  skipEmptyRows?: boolean;
}

/**
 * A responsive table component useful for comparing benefits.
 */
export function Table(props: TableProps) {
  const { head, body, footer, skipEmptyRows, ...tableProps } = props;
  const breakpoints = useBreakpoint();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerSize = useElementSize(containerRef.current);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Needed to pass css variable
  const containerStyle = { "--container-width": `${containerSize.width}px` } as CSSProperties;
  const containerClassName = clsx(styles.container, breakpoints);

  return (
    <div className={containerClassName} ref={containerRef} style={containerStyle}>
      <table {...tableProps}>
        <thead>
          <tr>
            {head.map((header, index) => (
              <th key={index}>{header.contents}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((row, rowIndex) => {
            if (skipEmptyRows && row.slice(1).every((cell) => !cell?.contents)) return undefined;

            return (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => {
                  if (!cell) return undefined;

                  if (isDataCell(cell)) {
                    const { children, ...cellProps } = cell.cellProps ?? {};
                    return (
                      <td key={cellIndex} {...cellProps}>
                        <>
                          <Body1 as="header">{cell.heading}</Body1>
                          <Body3>{cell.contents}</Body3>
                        </>
                      </td>
                    );
                  }

                  return <th key={cellIndex}>{cell.contents}</th>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <footer>{footer}</footer>
    </div>
  );
}
