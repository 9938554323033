import * as Sentry from "@sentry/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";

import { assertIsDefined } from "shared/utils/utils";
import { Button } from "../../components/Button/Button";
import { genericErrorCopy2 } from "../../components/Error/ErrorMessage";
import { Tooltip } from "../../components/Tooltip/Tooltip";
import { slobMessage } from "../../components/slobMessage/slobMessage";
import { capitalize } from "../../utils/string";

import { DownloadFormModal } from "./Modal/DownloadFormModal";
import type { ClientId } from "../../../../shared/types/Client";
import type { MetadataStatus, OnboardingFormId } from "../../../../shared/types/OnboardingForm";
import type {
  OnboardingFormDocumentId,
  OnboardingFormDocumentMetadata,
} from "../../../../shared/types/OnboardingFormDocument";
import type { RecipientWithMetadata } from "../../../../shared/types/OnboardingFormRecipient";
import type {
  AsyncCreateRecipientEmbeddedView,
  useGetOnboardingFormLockStatus,
} from "../../hooks/onboardingForms";
import type { FormPageLocationState } from "../../pages/OnboardingForms/FormPage";

const getTooltipDisableMsg = (status: MetadataStatus): string => {
  if (status === "draft") {
    return "You'll be notified when this form is ready for your review";
  }

  if (["voided", "declined"].includes(status)) {
    return `${capitalize(status)} form cannot viewed or signed`;
  }

  return "Waiting for other recipients";
};

export type OnboardingFormActionButtonProps = {
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  currentRoutingOrder: string;
  currentRecipients: RecipientWithMetadata[];
  recipientActionsCompleted: boolean;
  envelopeProgressStatus: MetadataStatus;
  downloadDocument: () => Promise<unknown>;
  showMultipleRecipientModal: () => void;
  createRecipientEmbeddedView: AsyncCreateRecipientEmbeddedView;
  isCreatingRecipientEmbeddedView: boolean;
  showRecipientChangedToOutsideSignerModal: () => void;
  downloadIndividualDocument: (documentId: OnboardingFormDocumentId) => void;
  showDownloadErrorMsg: boolean;
  formDocuments: ReadonlyArray<OnboardingFormDocumentMetadata>;
  isDownloading: boolean;
  isGettingFormLockStatus: boolean;
  getOnboardingFormLockStatus: ReturnType<typeof useGetOnboardingFormLockStatus>["refetch"];
  isInternalUser: boolean;
  isMismatchedRecipient: boolean;
  track: (label: string) => void;
};

export const OnboardingFormActionButton: React.FC<OnboardingFormActionButtonProps> = ({
  clientId,
  onboardingFormId,
  currentRecipients,
  envelopeProgressStatus,
  recipientActionsCompleted,
  downloadDocument,
  downloadIndividualDocument,
  showMultipleRecipientModal,
  showDownloadErrorMsg,
  formDocuments,
  isDownloading,
  isGettingFormLockStatus,
  isInternalUser,
  isMismatchedRecipient,
  currentRoutingOrder,
  getOnboardingFormLockStatus,
  createRecipientEmbeddedView,
  showRecipientChangedToOutsideSignerModal,
  isCreatingRecipientEmbeddedView,
  track,
}) => {
  const navigate = useNavigate();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [currentRecipient] = currentRecipients;
  const [disableViewEditForm, setDisableViewEditForm] = useState<boolean>(false);

  const showMultipleRecipientModalClick = useCallback(() => {
    track("View Form");

    showMultipleRecipientModal();
  }, [track, showMultipleRecipientModal]);

  const currentRecipientDownloadClick = useCallback(async () => {
    track("Download");

    if (formDocuments.length) {
      setShowDownloadModal(true);
      return;
    }

    await downloadDocument();
  }, [track, downloadDocument, formDocuments]);

  const currentRecipientViewClick = useCallback(() => {
    track("View Form");
  }, [track]);

  const currentRecipientEditClick = useCallback(() => {
    track(currentRecipient?.status === "COMPLETED" ? "View Form" : "View / Edit Form");
  }, [track, currentRecipient]);

  const viewEditClickTracked = async () => {
    currentRecipientEditClick();

    try {
      const result = await getOnboardingFormLockStatus();
      const formLockStatus = result.data?.isLocked;
      if (formLockStatus) {
        setDisableViewEditForm(true);
        return;
      }

      assertIsDefined(currentRecipient, "currentRecipient");
      const { data: recipientEmbeddedView } = await createRecipientEmbeddedView({
        params: {
          clientId,
          onboardingFormId,
          recipientId: currentRecipient.id,
        },
      });

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_REASSIGNED") {
        showRecipientChangedToOutsideSignerModal();
        return;
      }

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_DOWNLOAD_FORM") {
        await downloadDocument();
        return;
      }

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_CREATED") {
        const locationState: FormPageLocationState = {
          trigger: "onboardingFormTileClick",
          recipientViewUrl: recipientEmbeddedView.url,
        };

        navigate(
          RouteData.onboardingFormsViewer.getPath(clientId, onboardingFormId, currentRecipient.id),
          { state: locationState },
        );
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
      Sentry.captureException(error);
    }
  };

  const otherRecipientViewClick = useCallback(() => {
    track(recipientActionsCompleted ? "Download" : "View Form");
  }, [track, recipientActionsCompleted]);

  const isDraftOrVoidedOrDeclined = ["draft", "voided", "declined"].includes(
    envelopeProgressStatus,
  );

  const canOpenOrSign =
    currentRecipient?.routingOrder === currentRoutingOrder ||
    currentRecipient?.status === "COMPLETED";
  const disableFormButton = !canOpenOrSign || isDraftOrVoidedOrDeclined;

  const showDownloadButton =
    recipientActionsCompleted || (!recipientActionsCompleted && isInternalUser);

  if (!recipientActionsCompleted && currentRecipients.length > 1 && !disableFormButton) {
    return (
      <span className="mt-16">
        <Button type="primary" onClick={showMultipleRecipientModalClick} size="middle">
          {currentRecipients.every(({ status }) => status === "COMPLETED")
            ? "View Form"
            : "View / Edit Form"}
        </Button>
      </span>
    );
  }

  if (!recipientActionsCompleted && currentRecipient && !disableFormButton) {
    return (
      <span className="mt-16">
        <Button
          type="primary"
          size="middle"
          onClick={viewEditClickTracked}
          loading={isGettingFormLockStatus || isCreatingRecipientEmbeddedView}
          disabled={disableViewEditForm}
        >
          {currentRecipient.status === "COMPLETED" ? "View Form" : "View / Edit Form"}
        </Button>
      </span>
    );
  }

  if (!recipientActionsCompleted && currentRecipient && disableFormButton) {
    return (
      <Tooltip placement="top" title={getTooltipDisableMsg(envelopeProgressStatus)} trigger="hover">
        <span className="mt-16">
          <Button type="primary" to="#" disabled size="middle" onClick={currentRecipientViewClick}>
            View Form
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (showDownloadButton) {
    return (
      <>
        <span className="mt-16">
          <Button
            type="primary"
            loading={isDownloading}
            onClick={currentRecipientDownloadClick}
            size="middle"
          >
            Download
          </Button>
        </span>
        <DownloadFormModal
          isVisible={showDownloadModal}
          downloadCombinedDocument={downloadDocument}
          downloadIndividualDocument={downloadIndividualDocument}
          formDocuments={formDocuments}
          showDownloadErrorMsg={showDownloadErrorMsg}
          isDownloading={isDownloading}
          onClose={() => setShowDownloadModal(false)}
        />
      </>
    );
  }

  if (isMismatchedRecipient) {
    return (
      <Tooltip
        placement="top"
        title="Your Onboard and DocuSign email address may not match. Please contact your IC to update to sign the form."
      >
        <span className="mt-16">
          <Button type="primary" to="#" disabled size="middle" onClick={otherRecipientViewClick}>
            View Form
          </Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      data-testid="notAccessTooltip"
      placement="top"
      title="You do not have access to this form"
    >
      <span className="mt-16">
        <Button type="primary" to="#" disabled size="middle" onClick={otherRecipientViewClick}>
          {/* @todo: recipientActionsCompleted will always be false here, so can remove ternary */}
          {recipientActionsCompleted ? "Download" : "View Form"}
        </Button>
      </span>
    </Tooltip>
  );
};
