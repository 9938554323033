import { Body2, Body3, Body5, H3 } from "client/src/components/Typography/Typography";
import { EIFSectionStatusIconography } from "client/src/domain/EIF/common/EIFSectionStatusIconography";
import type { PFMLStatutoryContributionSections } from "shared/types/Plan";

type Props = {
  sectionType: PFMLStatutoryContributionSections;
  label: string;
  isReviewOnly: boolean;
};

export const EIFStatutoryReadOnlyContribution = (props: Props) => {
  const { sectionType, label, isReviewOnly } = props;

  if (sectionType === "PFL") {
    const notText = !isReviewOnly ? <Body2>not</Body2> : "not";
    return (
      <>
        {isReviewOnly && (
          <>
            <Body2>{label}</Body2>
            <br />
          </>
        )}
        {!isReviewOnly && (
          <H3>
            <EIFSectionStatusIconography status="Completed" />
            {label}
          </H3>
        )}

        <Body3>NY PFL is {notText} employer paid. Employees contribute 100%.</Body3>
        <br />
        {!isReviewOnly && (
          <Body5>
            If your situation requires something different, please contact your Implementation
            Consultant.
          </Body5>
        )}
      </>
    );
  } else if (sectionType === "TDB") {
    return (
      <>
        {isReviewOnly && <Body2>{label}</Body2>}
        {!isReviewOnly && (
          <H3>
            <EIFSectionStatusIconography status="Completed" />
            {label}
          </H3>
        )}

        <Body3 as="p">
          NJ TDB is 100% employer paid. There is no employee contribution required.
        </Body3>
      </>
    );
  }
};
