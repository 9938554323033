import { Button } from "client/src/components/Button/Button";
import clsx from "clsx";

import { isToday, isDateInThePast, getDaysFromToday } from "../../../../shared/utils/date";
import { formatDateFullMonthWithYear } from "../../../../shared/utils/format";
import { useToggler } from "../../hooks/useToggler";
import { getShouldDisplayDueDate } from "../../utils/getShouldDisplayDueDate";
import { AlertBanner } from "../Banner/AlertBanner";
import { ReactComponent as CalendarIcon } from "../Icons/CalendarIcon.svg";
import { Body3, Eyebrow } from "../Typography/Typography";

import { DueSoonModal } from "./DueSoonModal";
import { PastDueModal } from "./PastDueModal";
import * as styles from "./dueDateAlert.module.less";

import type { Task } from "../../../../shared/data/Tasks";

type DueDateProps = {
  task: Task;
};

export function DueDateAlert({ task }: DueDateProps) {
  const { dueDate } = task;
  const [dueSoonVisible, toggleDueSoonVisible] = useToggler();
  const [pastDueVisible, togglePastDueVisible] = useToggler();

  const shouldDisplayDueDate = getShouldDisplayDueDate(task);

  if (!shouldDisplayDueDate || !dueDate) return null;

  const daysFromToday = getDaysFromToday(dueDate);
  const todayDueDate = isToday(dueDate);
  const pastDueDate = isDateInThePast(dueDate) && !todayDueDate;

  if (daysFromToday > 5) {
    return (
      <div>
        <CalendarIcon className={clsx(styles.yellowIcon, "mr-8")} />
        <Eyebrow white>Due by {formatDateFullMonthWithYear(dueDate)}</Eyebrow>
      </div>
    );
  }

  return (
    <>
      <AlertBanner
        icon={<CalendarIcon className="mt-2" />}
        variant={pastDueDate || todayDueDate ? "error" : "warning"}
        message={
          <div
            className={clsx(
              styles.dueDateAlert,
              pastDueDate && styles.past,
              todayDueDate && styles.today,
            )}
          >
            <Eyebrow>
              <strong className={styles.dueDateText}>
                {todayDueDate ? (
                  "This task is due today"
                ) : (
                  <>
                    {pastDueDate ? `Past Due: Due ` : `Due Soon: Due `}
                    by {formatDateFullMonthWithYear(dueDate)}
                  </>
                )}
              </strong>
            </Eyebrow>
            <br />
            {todayDueDate ? (
              <Body3 as="div">
                Complete it on time to stay on track. <br />
              </Body3>
            ) : pastDueDate ? (
              <Body3 as="div">
                This task is past due and may put your effective date at risk. <br />
              </Body3>
            ) : (
              <Body3 as="div">
                This task is due soon. Complete it on time to stay on track. <br />
              </Body3>
            )}

            <Button
              type="text-only"
              onClick={pastDueDate ? togglePastDueVisible : toggleDueSoonVisible}
            >
              Learn more
            </Button>
          </div>
        }
      />

      <DueSoonModal
        dueDate={dueDate}
        isToday={todayDueDate}
        open={dueSoonVisible}
        onOk={toggleDueSoonVisible}
      />

      <PastDueModal dueDate={dueDate} open={pastDueVisible} onOk={togglePastDueVisible} />
    </>
  );
}
