import { Body2 } from "client/src/components/Typography/Typography";
import { EIFClassBuilderLifeADDContributionsEditable } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/EIFClassBuilderLifeADDContributionsEditable";
import { ReviewLifeADDContributions } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/ReviewLifeADDContributions";
import { getLifeADDContributionsHeader } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/LifeADD/getLifeADDContributionsHeader";
import type { SectionTracker } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassBuilderCreator";
import type { SetContributionQuery } from "client/src/hooks/employeeClassPlan";
import type { UserData } from "shared/rbac/rbac";
import type { BenefitTypeEIF } from "shared/types/BenefitTypes";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";

type EIFClassBuilderBasicContributionProps = {
  isActive: boolean;
  client: Client;
  employeeClass: EmployeeClass;
  benefitType: BenefitTypeEIF;
  changeSnapshot: DEIFChangeSnapshot;
  authUser: UserData | null;
  isLoading: boolean;
} & (
  | {
      readonly: true;
    }
  | {
      readonly: false;
      onSave: (employeeClass: EmployeeClass) => void;
      track: SectionTracker;
      setContributionsQuery: SetContributionQuery;
    }
);

export function EIFClassBuilderLifeADDContributions(props: EIFClassBuilderBasicContributionProps) {
  const {
    isActive,
    client,
    benefitType,
    employeeClass,
    changeSnapshot,
    authUser,
    readonly,
    isLoading,
  } = props;

  if (benefitType === "LIFE") {
    const header = getLifeADDContributionsHeader(employeeClass);

    if (readonly) {
      return (
        <>
          <Body2 as="p">{header}</Body2>

          <ReviewLifeADDContributions
            client={client}
            employeeClass={employeeClass}
            changeSnapshot={changeSnapshot}
            authUser={authUser}
          />
        </>
      );
    }

    return (
      <EIFClassBuilderLifeADDContributionsEditable
        isActive={isActive}
        employeeClass={employeeClass}
        client={client}
        changeSnapshot={changeSnapshot}
        authUser={authUser}
        setContributionsQuery={props.setContributionsQuery}
        onSave={props.onSave}
        track={props.track}
        isLoading={isLoading}
      />
    );
  }

  // DEP_LIFE and BASIC_ADND are handled in LIFE, so we don't render anything here
  return null;
}
