import { Typography } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { Loading } from "../../components/Loading/Loading";

import { PageContent } from "../../components/PageContent/PageContent";
import { ClientsList } from "../../domain/Client/ClientsList/ClientsList";
import { useGetImplementationConsultants } from "../../hooks/ImplementationConsultant";
import { useSlobAuth } from "../../hooks/auth";
import { triggerError } from "../../hooks/generalError";
import { useHideZendeskWidget } from "../../utils/zendesk";

const { Title } = Typography;

export const ClientsPage = () => {
  useHideZendeskWidget();
  const { isLoading: isLoadingAuth, authUser } = useSlobAuth();
  const { data: featureToggles, isLoading: isLoadingFT } = useGetFeatureToggles();

  const {
    data: icData,
    isLoading: isLoadingICs,
    isError: isErrorICs,
    error: errorICs,
  } = useGetImplementationConsultants();
  const implementationConsultants = icData?.data;

  const isLoading = isLoadingAuth || !authUser?.userId || isLoadingICs || isLoadingFT;
  const hasData = authUser?.userId && implementationConsultants;

  if (isLoading || !hasData) return <Loading />;

  if (isErrorICs) {
    triggerError(errorICs);
  }

  return (
    <PageContent>
      <Row align="middle" className="mb-24">
        <Col flex={1}>
          <Title style={{ margin: 0 }}>Clients</Title>
        </Col>
      </Row>

      <ClientsList
        userId={authUser.userId}
        implementationConsultants={implementationConsultants}
        featureToggles={featureToggles}
      />
    </PageContent>
  );
};
