import { NewBadge } from "client/src/components/NewBadge/NewBadge";
import { RenameItem } from "client/src/components/RenameItem/RenameItem";

type Props = {
  record: {
    id: string;
    name: string;
    isNew: boolean;
    policyIds?: string[];
  };
  renameDocument: (id: string, name: string) => Promise<void> | void;
  disabled?: boolean;
  customContent?: React.ReactNode;
};

export function RenameFile(props: Props) {
  const { record, renameDocument, disabled, customContent } = props;

  const documentNameWithoutExtension = record.name.split(".").slice(0, -1).join(".");
  const extensionLength = record.name.length - documentNameWithoutExtension.length;
  const maxLengthWithoutExtension = 256 - extensionLength;

  return (
    <RenameItem
      visible="hover"
      label="Rename file"
      description={
        (record.policyIds?.length || 0) > 1 && <p>This file will be edited for all policies.</p>
      }
      initialNewName={documentNameWithoutExtension}
      maxLength={maxLengthWithoutExtension}
      disabled={disabled}
      onRename={(newName) => renameDocument(record.id, newName)}
    >
      {customContent || (
        <span className="stack-x-8">
          {record.isNew && <NewBadge />}
          <span>{record.name}</span>
        </span>
      )}
    </RenameItem>
  );
}
