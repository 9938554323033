import { rejectNullableValues } from "shared/utils/utils";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { PFMLContributions, Plan } from "shared/types/Plan";

export function getNonClassBenefitsContributionsDetailInfoList(
  changeSnapshot: DEIFChangeSnapshot,
  plans: Plan[],
  contributionFields: (keyof PFMLContributions)[] = [
    "pfmlPremiumContributionType",
    "pfmlEmployerContributionPercentage",
    "pfmlEmployerPremiumPayments",
    "pfmlThreeYearLookBackPercent",
  ],
) {
  const planIds = plans.map((plan) => {
    return plan.id;
  });

  if (!changeSnapshot) return [];

  const pfmlContributionsDetailInfoList = planIds
    .flatMap((id) => {
      if (!changeSnapshot.Plan[id]) return [];

      const changeDetailRecords = contributionFields.map((fieldName) => {
        const changeDetailRecord = changeSnapshot.Plan[id]?.[fieldName];
        return changeDetailRecord;
      });

      return changeDetailRecords;
    })
    .filter(rejectNullableValues);

  return pfmlContributionsDetailInfoList;
}
