import { useCallback } from "react";

import { MultiSelect } from "../MultiSelect/MultiSelect";
import type { SlobListFilterComponentProps } from "../SlobList/SlobList";
import type { ClientTableFilters } from "shared/types/Client";
import type { SlfImplementationConsultant } from "shared/types/User";

export const DataTestId = {
  implementationConsultantFilterWrapper: "implementation-consultant-wrapper",
};

type ImplementationConsultantFilterValues = Pick<ClientTableFilters, "ic" | "noic">;

export type ImplementationConsultantFilterProps = {
  implementationConsultants?: SlfImplementationConsultant[];
} & Omit<SlobListFilterComponentProps<ImplementationConsultantFilterValues>, "onFiltersReset">;

export const ImplementationConsultantFilter = ({
  filters,
  onFiltersChanged,
  implementationConsultants,
}: ImplementationConsultantFilterProps) => {
  const selectedIds = (filters?.ic ?? []).concat(filters?.noic ? ["noic"] : []);

  const onFilterChange = useCallback(
    (implementationConsultantIds: string[]) => {
      onFiltersChanged({
        ...filters,
        ic: implementationConsultantIds.filter((id) => id !== "noic"),
        noic: !!implementationConsultantIds.find((id) => id === "noic"),
      });
    },
    [filters, onFiltersChanged],
  );

  const additionalOptions = [
    {
      value: "No IC Assigned",
      id: "noic",
    },
  ];

  return (
    <div data-testid={DataTestId.implementationConsultantFilterWrapper}>
      <MultiSelect
        selectedIds={selectedIds}
        onChange={onFilterChange}
        placeholder="Filter by Imp. Consultant"
        items={
          implementationConsultants?.map((ic) => {
            return {
              value: `${ic.firstName} ${ic.lastName}`,
              id: ic.id,
            };
          }) ?? []
        }
        additionalOptions={additionalOptions}
      />
    </div>
  );
};
