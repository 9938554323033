import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "client/src/components/Grid/Grid";
import { useState } from "react";

import { RouteData } from "../../../../../shared/config/routeData";
import { InternalLinkButton } from "../../../components/Button/InternalLinkButton";
import { ExplorerFeedbackForm } from "../../../components/GiveFeedbackForm/ExplorerFeedbackForm";
import { PageContent } from "../../../components/PageContent/PageContent";
import { H2 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useFavoritePlans } from "../../../hooks/useSlobLocalStorage";
import { useTranslation } from "../../../i18n";
import {
  useExplorerTrackElementClicked,
  useSendExplorerFeedbackForm,
} from "../../../utils/analytics";

import { ExplorerCountdown } from "./ExplorerCountdown";
import { ExplorerEndOfBenefits } from "./ExplorerEndOfBenefits";
import * as styles from "./clientPage.module.less";

import type { PublicExplorerPage } from "../../../../../shared/types/ExplorerPage";

export const DataTestId = {
  ExplorerEndOfScrollWrapper: "explorer-end-of-scroll-wrapper",
};

type ExplorerEndOfScrollProps = {
  explorerPageId: string;
  explorerPage: PublicExplorerPage;
  customCalendlyUrl: string | undefined;
};

export const ExplorerEndOfScroll = ({
  explorerPageId,
  explorerPage,
  customCalendlyUrl,
}: ExplorerEndOfScrollProps) => {
  const personalBenefitsPlanLink = RouteData.personalBenefitsPlan.getPath(explorerPageId);
  const [favoritePlans] = useFavoritePlans(explorerPageId, explorerPage.benefits);
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);

  const sendExplorerFeedbackForm = useSendExplorerFeedbackForm(explorerPageId);

  const { t } = useTranslation({ keyPrefix: "EndFavoritePlans" });
  const trackElementClicked = useExplorerTrackElementClicked(explorerPage);

  return (
    <div data-testid={DataTestId.ExplorerEndOfScrollWrapper}>
      {favoritePlans.length > 0 && (
        <div className={styles.favPlanSection}>
          <PageContent style={{ paddingTop: 0 }}>
            <Row justify="center">
              <Col>
                <H2>
                  {t("headline", { count: favoritePlans.length, plansCount: favoritePlans.length })}
                </H2>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <FontAwesomeIcon icon={faHeartSolid} className={styles.favIcon} />
              </Col>
              <Col>
                <InternalLinkButton size="large" type="link-inline" to={personalBenefitsPlanLink}>
                  {t("buttonText")}
                </InternalLinkButton>
              </Col>
            </Row>
            {!hasSubmittedFeedback && (
              <Row justify="center" className="mt-56">
                <Col span={24} md={12}>
                  <ExplorerFeedbackForm
                    centered
                    sendExplorerFeedbackForm={sendExplorerFeedbackForm}
                    onSubmit={() => {
                      void slobMessage.success(t("feedbackSuccess"));
                      setHasSubmittedFeedback(true);
                    }}
                    showCancelButton={false}
                  />
                </Col>
              </Row>
            )}
          </PageContent>
        </div>
      )}
      <PageContent>
        <Row justify="center" align="stretch" className="mt-48" gutter={[30, 30]}>
          <Col span={24} md={12}>
            <ExplorerEndOfBenefits
              clientName={
                explorerPage.customClientName
                  ? explorerPage.customClientName
                  : explorerPage.clientName
              }
              customCalendlyUrl={customCalendlyUrl}
              track={() =>
                trackElementClicked({
                  module: "Explorer End of Scroll",
                  buttonLabel: "Schedule Now",
                })
              }
            />
          </Col>

          <Col span={24} md={12}>
            <ExplorerCountdown explorerPage={explorerPage} />
          </Col>
        </Row>
      </PageContent>
    </div>
  );
};
