import { Dropdown } from "antd";
import { Button } from "client/src/components/Button/Button";
import { useState } from "react";
import { RouteData } from "shared/config/routeData";
import { BEN_ADMIN } from "shared/rbac/roles";
import { formatShortDateTimeLocal } from "shared/utils/format";
import { isActivated } from "shared/utils/user";

import { DropdownButton } from "../../../components/Button/DropdownButton";
import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { ConfirmDialogDeleteUser } from "../../../components/ConfirmDialog/ConfirmDialogDeleteUser";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { ActionMenu } from "../../../components/Menu/ActionMenu";
import { SlobTable } from "../../../components/SlobTable/SlobTable";
import { ActionButtons } from "../../../components/Table/ActionButtons";
import { Tooltip } from "../../../components/Tooltip/Tooltip";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import {
  useChangeBenAdminToBroker,
  useDeleteBenAdmin,
  useResetMFA,
  useResetPassword,
  useSendBenAdminWelcomeEmail,
  useUnassignBenAdminToClient,
} from "../../../hooks/user";

import { ConfirmBenAdminToBrokerModal } from "../Form/ConfirmBenAdminToBrokerModal";
import type { SlobColumnsType, SlobTableProps } from "../../../components/SlobTable/SlobTable";
import type { MenuProps } from "antd";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenAdmin } from "shared/types/User";

type BenAdminActionKeys =
  | "edit"
  | "delete"
  | "reset-password"
  | "send-welcome-email"
  | "unassign"
  | "change-to-broker";

export type BenAdminTableProps = Omit<SlobTableProps<BenAdmin>, "columns"> & {
  hideActionButtonByKey?: BenAdminActionKeys[];
};

export type BenAdminTableHookProps = {
  handleDelete: () => Promise<void>;
  handleReset: () => Promise<void>;
  handleResetMFA: () => Promise<void>;
  handleSendWelcomeEmail: () => Promise<void>;
  handleUnassignFromClient: () => Promise<void>;
  handleChangeToBroker: () => Promise<void>;
  handleEdit: (benAdmin: BenAdmin) => void;
  setActiveBenAdmin: (benAdmin?: BenAdmin) => void;
  activeBenAdmin?: BenAdmin;
  isDeleteDialogVisible: boolean;
  setIsDeleteDialogVisible: (value: boolean) => void;
  isResetDialogVisible: boolean;
  setIsResetDialogVisible: (value: boolean) => void;
  isResetMFADialogVisible: boolean;
  setIsResetMFADialogVisible: (value: boolean) => void;
  isWelcomeEmailDialogVisible: boolean;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  isChangeToBrokerVisible: boolean;
  setIsChangeToBrokerVisible: (value: boolean) => void;
  isUnassignDialogVisible: boolean;
  setIsUnassignDialogVisible: (value: boolean) => void;
};

export const useBenAdminTableProps = ({
  clientId,
  data,
  showModal,
}: {
  clientId?: string;
  showModal?: (value: BenAdmin) => void;
  data?: BenAdmin[];
}): BenAdminTableHookProps => {
  const { mutateAsync: deleteBenAdmin } = useDeleteBenAdmin();
  const { mutateAsync: resetPassword } = useResetPassword();
  const { mutateAsync: resetMFA } = useResetMFA();
  const { mutateAsync: sendWelcomeEmail } = useSendBenAdminWelcomeEmail();
  const { mutateAsync: unassignClient } = useUnassignBenAdminToClient();
  const { mutateAsync: changeBenAdminToBroker } = useChangeBenAdminToBroker();

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [isResetDialogVisible, setIsResetDialogVisible] = useState(false);
  const [isResetMFADialogVisible, setIsResetMFADialogVisible] = useState(false);
  const [isWelcomeEmailDialogVisible, setIsWelcomeEmailDialogVisible] = useState(false);
  const [isChangeToBrokerVisible, setIsChangeToBrokerVisible] = useState(false);
  const [isUnassignDialogVisible, setIsUnassignDialogVisible] = useState(false);
  const [activeBenAdmin, setActiveBenAdmin] = useState<BenAdmin>();

  const handleDelete = async () => {
    if (!activeBenAdmin) return;
    setIsDeleteDialogVisible(false);
    const hide = slobMessage.loading("Delete in progress", 0);

    try {
      await deleteBenAdmin({
        params: { userId: activeBenAdmin.id },
      });
      void slobMessage.success("Benefits Administrator deleted");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleReset = async () => {
    if (!activeBenAdmin) return;
    setIsResetDialogVisible(false);
    const hide = slobMessage.loading("Sending reset email", 0);
    try {
      await resetPassword({
        params: { email: activeBenAdmin.email },
      });
      void slobMessage.success("Password email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleResetMFA = async () => {
    if (!activeBenAdmin) return;
    setIsResetMFADialogVisible(false);
    const hide = slobMessage.loading("Sending reset MFA", 0);
    try {
      await resetMFA({
        params: { email: activeBenAdmin.email },
      });
      void slobMessage.success("MFA has been reset successfully");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleSendWelcomeEmail = async () => {
    if (!activeBenAdmin) return;
    setIsWelcomeEmailDialogVisible(false);
    const hide = slobMessage.loading("Sending welcome email", 0);
    try {
      await sendWelcomeEmail({
        params: { userId: activeBenAdmin.id },
        query: { clientId },
      });
      void slobMessage.success("Welcome email sent");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleUnassignFromClient = async () => {
    if (!activeBenAdmin) return;
    setIsUnassignDialogVisible(false);
    if (!clientId) return;
    const hide = slobMessage.loading("Removing from client", 0);
    try {
      await unassignClient({
        data: {},
        params: { userId: activeBenAdmin.id, clientId },
      });
      void slobMessage.success("Removed from client");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleChangeToBroker = async () => {
    if (!activeBenAdmin) return;
    setIsChangeToBrokerVisible(false);
    const hide = slobMessage.loading("Changing to Broker", 0);
    try {
      await changeBenAdminToBroker({
        params: { userId: activeBenAdmin.id },
      });
      void slobMessage.success("Changed to Broker");
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  const handleEdit = (benAdmin: BenAdmin) => {
    const payload = data?.find((b) => b.id === benAdmin.id);
    if (!payload || !showModal) {
      return;
    }
    showModal(payload);
  };

  return {
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
    handleUnassignFromClient,
    handleChangeToBroker,
    handleEdit,
    setActiveBenAdmin,
    activeBenAdmin,
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    setIsResetDialogVisible,
    isResetMFADialogVisible,
    setIsResetMFADialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    isChangeToBrokerVisible,
    setIsChangeToBrokerVisible,
    isUnassignDialogVisible,
    setIsUnassignDialogVisible,
  };
};

export type BenAdminTableActionMenuProps = {
  benAdmin: BenAdmin;
  showModal?: (BenAdmin: BenAdmin) => void;
  handleEdit: (BenAdmin: BenAdmin) => void;
  setActiveBenAdmin: (BenAdmin?: BenAdmin) => void;
  setIsDeleteDialogVisible: (value: boolean) => void;
  setIsResetDialogVisible: (value: boolean) => void;
  setIsResetMFADialogVisible: (value: boolean) => void;
  setIsWelcomeEmailDialogVisible: (value: boolean) => void;
  setIsUnassignDialogVisible: (value: boolean) => void;
  setIsChangeToBrokerVisible: (value: boolean) => void;
  featureToggles: ClientFeatureToggles | undefined;
  hideActionButtonByKey?: BenAdminActionKeys[];
  clientId: string | undefined;
};

export const BenAdminTableActionMenu = ({
  benAdmin,
  showModal,
  handleEdit,
  setActiveBenAdmin,
  setIsDeleteDialogVisible,
  setIsResetDialogVisible,
  setIsResetMFADialogVisible,
  setIsWelcomeEmailDialogVisible,
  setIsUnassignDialogVisible,
  setIsChangeToBrokerVisible,
  hideActionButtonByKey = [],
}: BenAdminTableActionMenuProps) => {
  const items: MenuProps["items"] = [
    ...(showModal ? [{ key: "edit-admin", label: "Edit Admin" }] : []),
    { key: "delete-admin", label: "Delete Admin" },
    { key: "reset-password", label: "Reset Password" },
    { key: "reset-mfa", label: "Reset MFA" },
    {
      key: "send-welcome-email",
      disabled: isActivated(benAdmin),
      label: (
        <Tooltip
          title={
            isActivated(benAdmin) &&
            "This user has activated their account. A welcome email can no longer be sent."
          }
          placement="bottom"
        >
          Send Welcome Email
        </Tooltip>
      ),
    },
    {
      key: "unassign",
      label: "Unassign from Client",
    },
    { key: "change-to-broker", label: "Change to Broker" },
  ].filter(({ key }) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
    return !hideActionButtonByKey.includes(key as BenAdminActionKeys);
  });
  const menu = (
    <ActionMenu
      data-testid="dropdown-menu"
      items={items}
      onClick={({ key }) => {
        switch (key) {
          case "edit-admin":
            handleEdit(benAdmin);
            break;
          case "delete-admin":
            setActiveBenAdmin(benAdmin);
            setIsDeleteDialogVisible(true);
            break;
          case "reset-password":
            setActiveBenAdmin(benAdmin);
            setIsResetDialogVisible(true);
            break;
          case "reset-mfa":
            setActiveBenAdmin(benAdmin);
            setIsResetMFADialogVisible(true);
            break;
          case "send-welcome-email":
            setActiveBenAdmin(benAdmin);
            setIsWelcomeEmailDialogVisible(true);
            break;
          case "unassign":
            setActiveBenAdmin(benAdmin);
            setIsUnassignDialogVisible(true);
            break;
          case "change-to-broker":
            setActiveBenAdmin(benAdmin);
            setIsChangeToBrokerVisible(true);
            break;
        }
      }}
    />
  );
  return (
    <ActionButtons>
      <Button type="secondary" size="xtra-small" to={RouteData.benAdminDetail.getPath(benAdmin.id)}>
        View BA
      </Button>

      <Dropdown dropdownRender={() => menu} placement="bottomLeft">
        <DropdownButton size="xtra-small" />
      </Dropdown>
    </ActionButtons>
  );
};

export const BenAdminTable = ({
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  showModal,
  clientId,
  client,
  featureToggles,
  hideActionButtonByKey,
}: BenAdminTableProps) => {
  const {
    handleDelete,
    handleReset,
    handleResetMFA,
    handleSendWelcomeEmail,
    handleUnassignFromClient,
    handleChangeToBroker,
    handleEdit,
    setActiveBenAdmin,
    activeBenAdmin,
    isDeleteDialogVisible,
    setIsDeleteDialogVisible,
    isResetDialogVisible,
    setIsResetMFADialogVisible,
    isResetMFADialogVisible,
    setIsResetDialogVisible,
    isWelcomeEmailDialogVisible,
    setIsWelcomeEmailDialogVisible,
    isUnassignDialogVisible,
    setIsUnassignDialogVisible,
    isChangeToBrokerVisible,
    setIsChangeToBrokerVisible,
  } = useBenAdminTableProps({ clientId, data, showModal });

  return (
    <BenAdminTableRender
      handleDelete={handleDelete}
      handleReset={handleReset}
      handleResetMFA={handleResetMFA}
      handleSendWelcomeEmail={handleSendWelcomeEmail}
      handleUnassignFromClient={handleUnassignFromClient}
      handleChangeToBroker={handleChangeToBroker}
      handleEdit={handleEdit}
      setActiveBenAdmin={setActiveBenAdmin}
      activeBenAdmin={activeBenAdmin}
      isDeleteDialogVisible={isDeleteDialogVisible}
      setIsDeleteDialogVisible={setIsDeleteDialogVisible}
      isResetDialogVisible={isResetDialogVisible}
      isResetMFADialogVisible={isResetMFADialogVisible}
      setIsResetMFADialogVisible={setIsResetMFADialogVisible}
      setIsResetDialogVisible={setIsResetDialogVisible}
      isWelcomeEmailDialogVisible={isWelcomeEmailDialogVisible}
      setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
      isUnassignDialogVisible={isUnassignDialogVisible}
      setIsUnassignDialogVisible={setIsUnassignDialogVisible}
      isChangeToBrokerVisible={isChangeToBrokerVisible}
      setIsChangeToBrokerVisible={setIsChangeToBrokerVisible}
      showModal={showModal}
      isLoading={isLoading}
      currentPage={currentPage}
      onChange={onChange}
      totalItems={totalItems}
      pageSize={pageSize}
      data={data}
      featureToggles={featureToggles}
      client={client}
      hideActionButtonByKey={hideActionButtonByKey}
    />
  );
};
export const BenAdminTableRender = ({
  handleDelete,
  handleReset,
  handleResetMFA,
  handleSendWelcomeEmail,
  handleUnassignFromClient,
  handleChangeToBroker,
  handleEdit,
  setActiveBenAdmin,
  activeBenAdmin,
  isDeleteDialogVisible,
  setIsDeleteDialogVisible,
  isResetDialogVisible,
  setIsResetDialogVisible,
  isResetMFADialogVisible,
  setIsResetMFADialogVisible,
  isWelcomeEmailDialogVisible,
  setIsWelcomeEmailDialogVisible,
  isUnassignDialogVisible,
  setIsUnassignDialogVisible,
  isChangeToBrokerVisible,
  setIsChangeToBrokerVisible,
  showModal,
  isLoading,
  currentPage,
  onChange,
  totalItems,
  pageSize,
  data,
  featureToggles,
  client,
  hideActionButtonByKey,
}: BenAdminTableProps & BenAdminTableHookProps) => {
  const columns: SlobColumnsType<BenAdmin> = [
    {
      title: "Benefits Administrator",
      dataIndex: "lastName",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
      width: 190,
    },
    {
      title: "Email",
      dataIndex: "email",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: true,
    },
    {
      title: "Welcome Email",
      dataIndex: "welcomeEmailSentDate",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (welcomeEmailSentDate: Date | null) =>
        welcomeEmailSentDate ? formatShortDateTimeLocal(welcomeEmailSentDate) : "-",
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (lastLogin: Date | null) => (lastLogin ? formatShortDateTimeLocal(lastLogin) : "-"),
    },
    {
      render: (benAdmin: BenAdmin) => {
        return (
          <BenAdminTableActionMenu
            benAdmin={benAdmin}
            showModal={showModal}
            handleEdit={handleEdit}
            setActiveBenAdmin={setActiveBenAdmin}
            setIsDeleteDialogVisible={setIsDeleteDialogVisible}
            setIsResetDialogVisible={setIsResetDialogVisible}
            setIsResetMFADialogVisible={setIsResetMFADialogVisible}
            setIsWelcomeEmailDialogVisible={setIsWelcomeEmailDialogVisible}
            setIsUnassignDialogVisible={setIsUnassignDialogVisible}
            setIsChangeToBrokerVisible={setIsChangeToBrokerVisible}
            featureToggles={featureToggles}
            hideActionButtonByKey={hideActionButtonByKey}
            clientId={client?.id}
          />
        );
      },
    },
  ];

  return (
    <>
      <SlobTable
        columns={columns}
        isLoading={isLoading}
        currentPage={currentPage}
        onChange={onChange}
        totalItems={totalItems}
        pageSize={pageSize}
        data={data?.map((d) => {
          // Use single table dataIndex for name for accurate backend sorting/filtering/pagination
          return { ...d, lastName: d.firstName + " " + d.lastName };
        })}
        featureToggles={featureToggles}
      />

      <ConfirmDialogDeleteUser
        role={BEN_ADMIN}
        isDeleteDialogVisible={isDeleteDialogVisible}
        setIsDeleteDialogVisible={setIsDeleteDialogVisible}
        activeUser={activeBenAdmin}
        handleDelete={handleDelete}
      />

      <ConfirmDialog
        title={"Reset password"}
        isVisible={isResetDialogVisible}
        onCancel={() => setIsResetDialogVisible(false)}
        onConfirm={() => handleReset()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset password?
      </ConfirmDialog>

      <ConfirmDialog
        title="Reset MFA"
        isVisible={isResetMFADialogVisible}
        onCancel={() => setIsResetMFADialogVisible(false)}
        onConfirm={() => handleResetMFA()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to reset MFA?
      </ConfirmDialog>

      <ConfirmDialog
        title={"Send welcome email"}
        isVisible={isWelcomeEmailDialogVisible}
        onCancel={() => setIsWelcomeEmailDialogVisible(false)}
        onConfirm={() => handleSendWelcomeEmail()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to send a welcome email?
      </ConfirmDialog>

      <ConfirmDialog
        title="Unassign from Client"
        isVisible={isUnassignDialogVisible}
        onCancel={() => setIsUnassignDialogVisible(false)}
        onConfirm={() => handleUnassignFromClient()}
        confirmActionText="Yes"
        cancelActionText="No"
      >
        Are you sure you want to unassign from a client?
      </ConfirmDialog>
      <ConfirmBenAdminToBrokerModal
        clientName={client?.name || ""}
        isVisible={isChangeToBrokerVisible}
        onCancel={() => setIsChangeToBrokerVisible(false)}
        onConfirm={() => handleChangeToBroker()}
      />
    </>
  );
};
