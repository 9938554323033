import { BulbMessage } from "client/src/components/BulbMessage/BulbMessage";
import { Body3 } from "client/src/components/Typography/Typography";
import { getIsPFMLCoverage } from "shared/types/SlfCoverages";
import type { Client } from "shared/types/Client";

type Props = {
  client: Client;
};

export const PFMLBulbMessage = ({ client }: Props) => {
  const hasPFML = client.allPoliciesSlfCoverages?.some((coverage) => getIsPFMLCoverage(coverage));

  if (hasPFML) {
    return (
      <div className="mb-20">
        <BulbMessage>
          <Body3>
            PFML benefit rules are determined by state guidelines and will not be impacted by your
            selections.
          </Body3>
        </BulbMessage>
      </div>
    );
  }

  return null;
};
