import { Button } from "client/src/components/Button/Button";
import { ConfirmDialog } from "client/src/components/ConfirmDialog/ConfirmDialog";
import { ErrorMessage, GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { ConfirmDialogDeclineAllChanges } from "client/src/domain/EIF/ConfirmDialogDeclineAllChanges";
import { EIFFLIPreferencesReview } from "client/src/domain/EIF/PlanConfigAndEligibility/EIFFLIPreferencesReview";
import { EIFNonClassBenefitsContributionsReview } from "client/src/domain/EIF/PlanConfigAndEligibility/nonClassBenefitsPreferences/EIFNonClassBenefitsContributionsReview";
import { EIFEntityDeletedBy } from "client/src/domain/EIF/common/EIFEntityDeletedBy";
import {
  anyChangesForSubSteps,
  getEIFSubStepsHaveChanges,
} from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import { getIsAllowedToAcceptOrDeclineEIF } from "client/src/domain/EIF/common/utils/getIsAllowedToReviewEIF";
import {
  getIsLastStepMap,
  getIsLastSubStepMap,
} from "client/src/domain/EIF/common/utils/getIsLastStepOrSubStepMap";
import { shouldShowDivider } from "client/src/domain/EIF/common/utils/getShouldShowHorizontalDivider";
import { useOutsideSignerReviewICEdits } from "client/src/hooks/outsideSigner";
import { ResponseError } from "client/src/hooks/query";
import { useToggler } from "client/src/hooks/useToggler";
import pluralize from "pluralize";
import React from "react";
import { type EIFPlanConfigEligibilityOnlyPFMLSubStep } from "shared/types/EIF";
import { getEIFSignedByFullName } from "shared/utils/EIF/getAuthorizedSignerFullName";
import { getEIFEditState } from "shared/utils/EIF/getEIFEditState";
import {
  getAtLeastOneSubStepIsApplicable,
  getIsSubStepApplicable,
} from "shared/utils/EIF/getIsSubStepApplicable";
import { formatDateTimeLocal } from "shared/utils/format";
import { Anchor } from "../../components/Anchor/Anchor";
import { StackY, StackX } from "../../components/Spacing/Spacing";
import { Body2, Body3 } from "../../components/Typography/Typography";
import {
  EIFCompanyDetailsReview,
  ShowAllSubSteps as ShowAllCompanyDetailsSubSteps,
} from "../../domain/EIF/CompanyDetails/EIFCompanyDetailsReview";
import {
  EIFOtherContractDetailsReview,
  ShowAllSubSteps as ShowAllOtherContractDetailsSubSteps,
} from "../../domain/EIF/EIFOtherContractDetailsReview";
import { EIFSigningAgreement } from "../../domain/EIF/EIFSigningAgreement";
import {
  EIFPlanAdministrationAndBillingReview,
  ShowAllSubSteps as ShowAllPlanAdminAndBillingSubSteps,
} from "../../domain/EIF/PlanAdministratorsAndBilling/EIFPlanAdministrationAndBillingReview";
import { EIFClassListingExpanded } from "../../domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFClassListingExpanded";
import { getImplementationContactInfo } from "../../utils/getImplementationContactInfo";
import { PrintHidden, AvoidPageBreak } from "../../utils/print";
import { DownloadEIFSummaryPDFLink } from "./DownloadEIFSummaryPDFLink";
import { EIFSummaryWrapper } from "./EIFSummaryWrapper";
import type { EIFSubStepsHaveChanges } from "client/src/domain/EIF/common/utils/getChangeDetailInfoList";
import type { UserData } from "shared/rbac/rbac";
import type { Bill } from "shared/types/Bill";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { Contact } from "shared/types/Contact";
import type { Document } from "shared/types/Document";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Props = {
  client: Client;
  contacts: Contact[];
  bills: Bill[];
  deletedBills: Bill[];
  billingSummaryStatementTemplates: Document[];
  clientPlans: Plan[];
  subsidiaries: Subsidiary[];
  authUser: UserData;
  changeSnapshot: DEIFChangeSnapshot;
  employeeClasses: EmployeeClass[];
  featureToggles: ClientFeatureToggles;
  signerMode: SignerMode;
};

const ShowAllPlanConfigAndEligibilitySubSteps: EIFSubStepsHaveChanges<EIFPlanConfigEligibilityOnlyPFMLSubStep> =
  {
    "class-builder": true,
    "fli-preferences": true,
    "non-class-benefits-preferences": true,
  } as const;

export function EIFSummary({
  client,
  contacts,
  bills,
  deletedBills,
  billingSummaryStatementTemplates,
  clientPlans,
  subsidiaries,
  employeeClasses,
  changeSnapshot,
  featureToggles,
  authUser,
  signerMode,
}: Props) {
  const [isVisibleAcceptChanges, toggleIsVisibleAcceptChanges] = useToggler();
  const [isVisibleDeclineChanges, toggleIsVisibleDeclineChanges] = useToggler();

  const { mutateAsync: reviewICEdits, isPending, error } = useOutsideSignerReviewICEdits();

  const acceptChanges = async () => {
    const { isSuccess } = await reviewICEdits({
      params: { clientId: client.id },
      data: { reviewICEditsAction: "accept" },
    });
    if (isSuccess) {
      toggleIsVisibleAcceptChanges();
      window.scrollTo(0, 0);
    }
  };
  const declineChanges = async (deifChangesDeclinedReason: string) => {
    const { isSuccess } = await reviewICEdits({
      params: { clientId: client.id },
      data: { reviewICEditsAction: "decline", declineReason: deifChangesDeclinedReason },
    });
    if (isSuccess) {
      toggleIsVisibleDeclineChanges();
      window.scrollTo(0, 0);
    }
  };

  const {
    implementationConsultantFullName,
    implementationConsultantPhone,
    implementationConsultantEmail,
  } = getImplementationContactInfo(client);
  const [phoneNoExtension] = implementationConsultantPhone
    ? implementationConsultantPhone.split(" ")
    : [""];

  const signedByFullName = getEIFSignedByFullName(client) || "-";

  const signedAt = client.eifSignedAt ? formatDateTimeLocal(client.eifSignedAt) : "-";

  const singleClassBuilderMode = client.needsEmployeeClasses === "NO";

  const needsPFMLPreferences = getIsSubStepApplicable({
    eifSubStepId: "non-class-benefits-preferences",
    client,
    featureToggles,
  });

  const needsFLIPreferences = getIsSubStepApplicable({
    eifSubStepId: "fli-preferences",
    client,
  });

  const subStepsHaveChangesByStep = getEIFSubStepsHaveChanges({
    client,
    bills,
    deletedBills,
    changeSnapshot,
    clientPlans,
    contacts,
    featureToggles,
    subsidiaries,
  });

  const showCompanyDetails =
    signerMode === "outside"
      ? anyChangesForSubSteps(subStepsHaveChangesByStep["company-information"])
      : true;
  const showSubStepsForCompanyDetails =
    signerMode === "outside"
      ? subStepsHaveChangesByStep["company-information"]
      : ShowAllCompanyDetailsSubSteps(clientPlans);

  const showPlanAdminAndBilling =
    signerMode === "outside"
      ? anyChangesForSubSteps(subStepsHaveChangesByStep["plan-administrators-&-billing"])
      : true;
  const showSubStepsForPlanAdminAndBilling =
    signerMode === "outside"
      ? subStepsHaveChangesByStep["plan-administrators-&-billing"]
      : ShowAllPlanAdminAndBillingSubSteps;

  const showPlanConfigAndEligibility =
    signerMode === "outside"
      ? anyChangesForSubSteps(subStepsHaveChangesByStep["plan-configuration-&-eligibility"])
      : true;
  const showSubStepsForPlanConfigAndEligibility =
    signerMode === "outside"
      ? subStepsHaveChangesByStep["plan-configuration-&-eligibility"]
      : ShowAllPlanConfigAndEligibilitySubSteps;

  const otherContractDetailsIsApplicable = getAtLeastOneSubStepIsApplicable(
    "other-contract-details",
    client,
    clientPlans,
    featureToggles,
  );

  const showOtherContractDetails = otherContractDetailsIsApplicable
    ? signerMode === "outside"
      ? anyChangesForSubSteps(subStepsHaveChangesByStep["other-contract-details"])
      : true
    : false;
  const showSubStepsForOtherContractDetails =
    signerMode === "outside"
      ? subStepsHaveChangesByStep["other-contract-details"]
      : ShowAllOtherContractDetailsSubSteps;

  const isAllowedToAcceptOrDeclineEIF =
    signerMode === "outside" &&
    getIsAllowedToAcceptOrDeclineEIF({
      client,
      authUser,
      changesSnapshot: changeSnapshot,
    });

  const eifEditState = getEIFEditState({ client, changesSnapshot: changeSnapshot });

  const eifStepHasChanges = {
    "company-information": showCompanyDetails,
    "plan-administrators-&-billing": showPlanAdminAndBilling,
    "plan-configuration-&-eligibility": showPlanConfigAndEligibility,
    "other-contract-details": showOtherContractDetails,
  };

  const isLastPlanConfigSubStep = getIsLastSubStepMap(showSubStepsForPlanConfigAndEligibility);
  const isLastStepMap = getIsLastStepMap(eifStepHasChanges);

  const HRSpacerForInside = signerMode === "inside" && (
    <div>
      <hr />
    </div>
  );

  return (
    <EIFSummaryWrapper signerMode={signerMode} eifEditState={eifEditState}>
      <div data-testid="generateEIFSummaryPagePDF">
        {signerMode === "inside" && (
          <>
            <PrintHidden>
              <p>
                <DownloadEIFSummaryPDFLink clientId={client.id} />
              </p>
            </PrintHidden>

            <StackY dist={8}>
              <Body2 as="div">{client.name}</Body2>

              <div>
                <Body2>Policy {pluralize("Number", client.policies.length)}:</Body2>{" "}
                <Body3>
                  {client.policies
                    .map((p) => `#${p.slfPolicyNumber}` + (p.primaryPolicy ? " (Primary)" : ""))
                    .join(", ")}
                </Body3>
              </div>

              <div>
                <Body2>Implementation consultant:</Body2>{" "}
                <Body3>{implementationConsultantFullName}</Body3>{" "}
                {implementationConsultantEmail && (
                  <Anchor variant="bold" href={`mailto:${implementationConsultantEmail}`}>
                    {implementationConsultantEmail}
                  </Anchor>
                )}{" "}
                {implementationConsultantPhone && (
                  <Anchor variant="bold" href={`tel:${phoneNoExtension}`}>
                    {implementationConsultantPhone}
                  </Anchor>
                )}
              </div>

              <div>
                <Body2>Signing benefits administrator: </Body2> <Body3>{signedByFullName}</Body3>
              </div>

              <div>
                <Body2>Signed at: </Body2> <Body3>{signedAt}</Body3>
              </div>
            </StackY>

            <hr />
          </>
        )}
        <EIFStepSummaryWrapper signerMode={signerMode}>
          <StackY dist={32}>
            {showCompanyDetails && (
              <>
                <AvoidPageBreak>
                  <EIFCompanyDetailsReview
                    client={client}
                    subsidiaries={subsidiaries}
                    changeSnapshot={changeSnapshot}
                    viewMode="review-page"
                    authUser={authUser}
                    featureToggles={featureToggles}
                    signerMode={signerMode}
                    showSubSteps={showSubStepsForCompanyDetails}
                    isLastStep={isLastStepMap["company-information"]}
                    plans={clientPlans}
                  />
                </AvoidPageBreak>

                {HRSpacerForInside}
              </>
            )}

            {showPlanAdminAndBilling && (
              <>
                <AvoidPageBreak>
                  <EIFPlanAdministrationAndBillingReview
                    client={client}
                    clientPlans={clientPlans}
                    contacts={contacts}
                    bills={bills}
                    deletedBills={deletedBills}
                    billingSummaryStatementTemplates={billingSummaryStatementTemplates}
                    changeSnapshot={changeSnapshot}
                    featureToggles={featureToggles}
                    viewMode="review-page"
                    authUser={authUser}
                    signerMode={signerMode}
                    showSubSteps={showSubStepsForPlanAdminAndBilling}
                    isLastStep={isLastStepMap["plan-administrators-&-billing"]}
                  />
                </AvoidPageBreak>

                {HRSpacerForInside}
              </>
            )}

            {showPlanConfigAndEligibility && (
              <>
                {showSubStepsForPlanConfigAndEligibility["class-builder"] && (
                  <AvoidPageBreak>
                    <>
                      {signerMode === "outside" && (
                        <EIFEntityDeletedBy
                          changeSnapshot={changeSnapshot}
                          authUser={authUser}
                          client={client}
                          entity="EmployeeClass"
                          prefixWord="Eligibility"
                          pluralizeWord="Group"
                          changeSummaryVariant={true}
                        />
                      )}

                      <EIFClassListingExpanded
                        clientPlans={clientPlans}
                        employeeClasses={employeeClasses}
                        singleClassBuilderMode={singleClassBuilderMode}
                        client={client}
                        authUser={authUser}
                        featureToggles={featureToggles}
                        changeSnapshot={changeSnapshot}
                        signerMode={signerMode}
                      />
                      {signerMode === "outside" &&
                        shouldShowDivider(
                          "class-builder",
                          isLastPlanConfigSubStep,
                          isLastStepMap["plan-configuration-&-eligibility"],
                        ) && (
                          <div>
                            <hr />
                          </div>
                        )}
                    </>
                  </AvoidPageBreak>
                )}

                {needsPFMLPreferences &&
                  showSubStepsForPlanConfigAndEligibility["non-class-benefits-preferences"] && (
                    <AvoidPageBreak>
                      <EIFNonClassBenefitsContributionsReview
                        client={client}
                        clientPlans={clientPlans}
                        isReviewOnly={true}
                        authUser={authUser}
                        changeSnapshot={changeSnapshot}
                        viewMode="review-page"
                        readonly
                        signerMode={signerMode}
                        featureToggles={featureToggles}
                      />
                      {signerMode === "outside" &&
                        shouldShowDivider(
                          "non-class-benefits-preferences",
                          isLastPlanConfigSubStep,
                          isLastStepMap["plan-configuration-&-eligibility"],
                        ) && (
                          <div>
                            <hr />
                          </div>
                        )}
                    </AvoidPageBreak>
                  )}

                {needsFLIPreferences &&
                  showSubStepsForPlanConfigAndEligibility["fli-preferences"] && (
                    <AvoidPageBreak>
                      <EIFFLIPreferencesReview
                        client={client}
                        readonly
                        signerMode={signerMode}
                        featureToggles={featureToggles}
                      />
                      {signerMode === "outside" &&
                        shouldShowDivider(
                          "fli-preferences",
                          isLastPlanConfigSubStep,
                          isLastStepMap["plan-configuration-&-eligibility"],
                        ) && (
                          <div>
                            <hr />
                          </div>
                        )}
                    </AvoidPageBreak>
                  )}

                {HRSpacerForInside}
              </>
            )}

            {showOtherContractDetails && (
              <>
                <div>
                  <EIFOtherContractDetailsReview
                    client={client}
                    clientPlans={clientPlans}
                    changeSnapshot={changeSnapshot}
                    viewMode="review-page"
                    featureToggles={featureToggles}
                    authUser={authUser}
                    signerMode={signerMode}
                    showSubSteps={showSubStepsForOtherContractDetails}
                    isLastStep={isLastStepMap["other-contract-details"]}
                  />
                </div>

                {HRSpacerForInside}
              </>
            )}

            {signerMode === "inside" && (
              <div>
                <p>The Employer above has read, understands and agrees that:</p>

                <EIFSigningAgreement />
              </div>
            )}

            {isAllowedToAcceptOrDeclineEIF && (
              <div className="mt-16">
                <StackY dist={16}>
                  <StackX dist={16} style={{ justifyContent: "flex-end" }}>
                    <Button size="middle" type="text" onClick={toggleIsVisibleDeclineChanges}>
                      Decline changes
                    </Button>
                    <Button size="middle" type="primary" onClick={toggleIsVisibleAcceptChanges}>
                      Accept changes
                    </Button>
                  </StackX>
                </StackY>
              </div>
            )}
          </StackY>
        </EIFStepSummaryWrapper>
      </div>
      {isAllowedToAcceptOrDeclineEIF && (
        <div data-testid="acceptDeclineOutsideSigner">
          <ConfirmDialog
            isVisible={isVisibleAcceptChanges}
            isLoading={isPending}
            title="Are you sure you are ready to accept the changes?"
            confirmActionText="Accept all changes"
            onConfirm={acceptChanges}
            onCancel={toggleIsVisibleAcceptChanges}
          >
            <p>Your Implementation Consultant will be notified that you’ve accepted the changes.</p>

            {error && (
              <ErrorMessage>
                {ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2)}
              </ErrorMessage>
            )}
          </ConfirmDialog>

          <ConfirmDialogDeclineAllChanges
            isVisible={isVisibleDeclineChanges}
            isLoading={isPending}
            onCancel={toggleIsVisibleDeclineChanges}
            onDecline={declineChanges}
            error={error}
            signerMode="outside"
          />
        </div>
      )}
    </EIFSummaryWrapper>
  );
}

interface EIFStepSummaryWrapperProps {
  signerMode: SignerMode;
  children: React.ReactNode;
}

const EIFStepSummaryWrapper = ({ signerMode, children, ...rest }: EIFStepSummaryWrapperProps) => {
  return signerMode === "inside" ? (
    <div {...rest}>{children}</div>
  ) : (
    <HubCard printMode {...rest}>
      {children}
    </HubCard>
  );
};
