import { Skeleton } from "antd";
import { Row, Col } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { NavigationHeader } from "client/src/components/NavigationHeader/NavigationHeader";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { StackY } from "client/src/components/Spacing/Spacing";
import { getCanPushToQPS } from "client/src/domain/EIF/PushToQPSButton";
import { useSlobAuth } from "client/src/hooks/auth";
import { useChangeSnapshot, useGetClientDEIFChanges } from "client/src/hooks/changeLogs";
import { useGetClientByID } from "client/src/hooks/client";
import { useGetClasses } from "client/src/hooks/employeeClass";
import { triggerError } from "client/src/hooks/generalError";
import { useGetPlansByClientId } from "client/src/hooks/plans";
import { useGetQPSPlanDesignInfo, useMapAndSyncQPSClasses } from "client/src/hooks/qps";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useGetFeatureToggles } from "client/src/hooks/useFeatureToggles";
import { EIFPushToQPSPageContents } from "client/src/pages/EIFPushToQPSPage/EIFPushToQPSPageContents";
import { EIFPushToQPSPageContentsV2 } from "client/src/pages/EIFPushToQPSPage/EIFPushToQPSPageContentsV2";
import { Navigate } from "react-router-dom";
import { RouteData } from "shared/config/routeData";
import * as styles from "./EIFPushToQPSPage.module.less";

export function EIFPushToQPSPage() {
  const { clientId } = useClientHubParams(["clientId"]);
  const { authUser } = useSlobAuth();

  const {
    data: client,
    isLoading: isLoadingClient,
    isError: isErrorClient,
    error: errorClient,
    isSuccess: isSuccessClient,
  } = useGetClientByID(clientId);
  const {
    data: plans,
    isLoading: isLoadingPlans,
    isError: isErrorPlans,
    error: errorPlans,
    isSuccess: isSuccessPlans,
  } = useGetPlansByClientId(clientId);
  const {
    data: employeeClasses,
    isLoading: isLoadingClasses,
    isError: isErrorClasses,
    error: errorClasses,
    isSuccess: isSuccessClasses,
  } = useGetClasses(clientId);
  const {
    data: featureToggles,
    isLoading: isLoadingFeatureToggles,
    isSuccess: isSuccessFeatureToggles,
    isError: isErrorFeatureToggles,
    error: errorFeatureToggles,
  } = useGetFeatureToggles(clientId);
  const {
    data: qpsPlanDesignInfo,
    isLoading: isLoadingQPSPlanDesignInfo,
    isSuccess: isSuccessQPSPlanDesignInfo,
    isError: isErrorQPSBasicClasses,
    error: errorQPSPlanDesignInfo,
  } = useGetQPSPlanDesignInfo(clientId, { enabled: true });
  const { mutateAsync: mapAndSyncQPSClasses, isPending: isPendingMapAndSyncQPSClasses } =
    useMapAndSyncQPSClasses();
  const {
    data: changes,
    isInitialLoading: isLoadingChanges,
    isSuccess: isSuccessChanges,
    isError: isErrorChanges,
    error: errorChanges,
  } = useGetClientDEIFChanges(clientId);

  const isLoading =
    isLoadingClasses ||
    isLoadingClient ||
    isLoadingPlans ||
    isLoadingFeatureToggles ||
    isLoadingQPSPlanDesignInfo ||
    isLoadingChanges;
  const isSuccess =
    isSuccessClasses ||
    isSuccessPlans ||
    isSuccessClient ||
    isSuccessFeatureToggles ||
    isSuccessQPSPlanDesignInfo ||
    isSuccessChanges;

  if (isErrorClient) {
    triggerError(errorClient);
  }
  if (isErrorClasses) {
    triggerError(errorClasses);
  }
  if (isErrorFeatureToggles) {
    triggerError(errorFeatureToggles);
  }
  if (isErrorQPSBasicClasses) {
    triggerError(errorQPSPlanDesignInfo);
  }
  if (isErrorChanges) {
    triggerError(errorChanges);
  }
  if (isErrorPlans) {
    triggerError(errorPlans);
  }

  const changesSnapshot = useChangeSnapshot(changes);

  const canPush =
    client && authUser && changes && changesSnapshot && employeeClasses && plans && featureToggles
      ? getCanPushToQPS({
          client,
          authUser,
          changesSnapshot,
          plans,
          employeeClasses,
          featureToggles,
        })
      : true;

  if (!canPush) {
    return (
      <Navigate
        to={RouteData.clientTaskDetail.getPath(clientId, "eif-submit-company-information")}
        replace
      />
    );
  }

  const isQPSMultiSelectAdminClassFTON = Boolean(
    featureToggles?.ONBOARD_QPS_MULTI_SELECT_ADMIN_CLASS,
  );

  return (
    <div className={styles.EIFPushToQPSPage}>
      <NavigationHeader
        backTo={RouteData.clientTaskDetail.getPath(clientId, "eif-submit-company-information")}
        title="Submit Company Information"
      />

      <PageContent>
        <Row justify="center">
          <Col span={18}>
            {isLoading && <EIFPushToQPSPageSkeleton />}

            {isSuccess &&
              client &&
              employeeClasses &&
              featureToggles &&
              qpsPlanDesignInfo &&
              plans &&
              authUser &&
              (isQPSMultiSelectAdminClassFTON ? (
                <EIFPushToQPSPageContentsV2
                  client={client}
                  employeeClasses={employeeClasses}
                  qpsPlanDesignInfo={qpsPlanDesignInfo}
                  authUser={authUser}
                  plans={plans}
                  featureToggles={featureToggles}
                  mapAndSyncQPSClasses={mapAndSyncQPSClasses}
                  isPendingMapAndSyncQPSClasses={isPendingMapAndSyncQPSClasses}
                />
              ) : (
                <EIFPushToQPSPageContents
                  client={client}
                  employeeClasses={employeeClasses}
                  qpsPlanDesignInfo={qpsPlanDesignInfo}
                  authUser={authUser}
                  featureToggles={featureToggles}
                  mapAndSyncQPSClasses={mapAndSyncQPSClasses}
                  isPendingMapAndSyncQPSClasses={isPendingMapAndSyncQPSClasses}
                />
              ))}
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}

function EIFPushToQPSPageSkeleton() {
  return (
    <>
      <StackY dist={32}>
        <Skeleton active paragraph={{ rows: 3 }} />

        <HubCard>
          <Skeleton active title={false} paragraph={{ rows: 3 }} />
        </HubCard>

        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      </StackY>
    </>
  );
}
