import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { RouteData } from "../../../../shared/config/routeData";
import { NavBar } from "../../components/NavBar/NavBar";
import type { logOutType } from "./NavBarPage";

type BasicPageProps = {
  logOut?: logOutType;
};

export const BasicNav = ({ logOut }: BasicPageProps) => {
  const handleLogOut = async () => {
    await logOut?.({
      data: {},
    });
  };
  return (
    <NavBar
      homeLink={RouteData.index.getPathTemplate()}
      rightLinks={
        <>
          {logOut && (
            <ButtonOld size="middle" type="link-inline-bold-deprecated" onClick={handleLogOut}>
              Sign Out
            </ButtonOld>
          )}
        </>
      }
    />
  );
};
