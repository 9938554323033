import { FormInput } from "client/src/components/Form/Input";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import {
  getIsFourTierIndividualContributionBenefitType,
  type BenefitTypeEIF,
} from "shared/types/BenefitTypes";
import * as styles from "./eifClassBuilderContributions.module.less";
import type { FormikTypeFromValidator } from "client/src/hooks/useSlobFormik";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { editContributionsForEmployeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";

type ContributionBuilderFormik = FormikTypeFromValidator<
  typeof editContributionsForEmployeeClassPlanValidationSchema
>;

type EIFClassBuilderContributionTableProps = {
  planLevel: "Low" | "High";
  useIndividualRoleAttrs: boolean;
  formik: ContributionBuilderFormik;
  changeSnapshot: DEIFChangeSnapshot;
  employeeClassPlanId: string;
  authUser: UserData | null;
  client: Client;
  benefitType?: BenefitTypeEIF;
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof editContributionsForEmployeeClassPlanValidationSchema>
  >;
};

type ContributionBuilderProperty = keyof ContributionBuilderFormik["values"];

export const EIFContributionByPlanLevelForm = ({
  planLevel,
  useIndividualRoleAttrs,
  formik,
  changeSnapshot,
  employeeClassPlanId,
  authUser,
  client,
  benefitType,
  strictErrors,
}: EIFClassBuilderContributionTableProps) => {
  const isFourTierIndividualBenefitType =
    benefitType && getIsFourTierIndividualContributionBenefitType(benefitType);

  const employeeFieldName: ContributionBuilderProperty = `eeContributionAmount${planLevel}`;
  const spouseFieldName: ContributionBuilderProperty = useIndividualRoleAttrs
    ? `spouseContributionAmount${planLevel}`
    : `eeAndSpouseContributionAmount${planLevel}`;
  const childrenFieldName: ContributionBuilderProperty = useIndividualRoleAttrs
    ? `childrenContributionAmount${planLevel}`
    : `eeAndChildrenContributionAmount${planLevel}`;
  const familyFieldName: ContributionBuilderProperty = isFourTierIndividualBenefitType
    ? `familyContributionAmount${planLevel}`
    : `eeAndFamilyContributionAmount${planLevel}`;

  const spouseDisplayValue = useIndividualRoleAttrs ? "Spouse" : "Employee + Spouse";
  const spouseAriaLabel = useIndividualRoleAttrs ? "Spouse" : "Employee And Spouse";
  const childrenDisplayValue = useIndividualRoleAttrs ? "Child(ren)" : "Employee + Children";
  const childrenAriaLabel = useIndividualRoleAttrs ? "Child(ren)" : "Employee And Children";

  return (
    <div className={styles.contributionsTable}>
      <Row justify="space-between" align="middle" gutter={[16, 8]}>
        <Col span={12}>
          <Body3>Employee</Body3>
        </Col>
        <Col span={12}>
          <StackY dist={8}>
            <FormInput
              name={employeeFieldName}
              prefix={formik.values.employerContributionUnit === "DOLLAR" ? "$" : <span />}
              suffix={formik.values.employerContributionUnit === "PERCENT" ? "%" : <span />}
              maxLength={6}
              label={`Employer contribution ${
                formik.values.employerContributionUnit === "PERCENT" ? " percent" : "amount"
              }`}
              touched={formik.touched[employeeFieldName] || !!strictErrors[employeeFieldName]}
              disabled={formik.isSubmitting}
              error={formik.errors[employeeFieldName] || strictErrors[employeeFieldName]}
              value={formik.values[employeeFieldName]}
              onChange={formik.handleChange}
              aria-label={`Employer contribution amount for Employee for ${planLevel} plan`}
            />
            <EditedFieldMsg
              changeDetailInfoList={
                employeeClassPlanId
                  ? [changeSnapshot.EmployeeClassPlan[employeeClassPlanId]?.eeContributionAmount]
                  : []
              }
              client={client}
              authUser={authUser}
              hasPendingEdit={getHasPendingEdit({ field: employeeFieldName, client, formik })}
            />
          </StackY>
        </Col>
        <Col span={12}>
          <Body3>{spouseDisplayValue}</Body3>
        </Col>
        <Col span={12}>
          <StackY dist={8}>
            <FormInput
              name={spouseFieldName}
              prefix={formik.values.employerContributionUnit === "DOLLAR" ? "$" : <span />}
              suffix={formik.values.employerContributionUnit === "PERCENT" ? "%" : <span />}
              maxLength={6}
              label={`Employer contribution ${
                formik.values.employerContributionUnit === "PERCENT" ? " percent" : "amount"
              }`}
              touched={formik.touched[spouseFieldName] || !!strictErrors[spouseFieldName]}
              disabled={formik.isSubmitting}
              error={formik.errors[spouseFieldName] || strictErrors[spouseFieldName]}
              value={formik.values[spouseFieldName]}
              onChange={formik.handleChange}
              aria-label={`Employer contribution amount for ${spouseAriaLabel} for ${planLevel} plan`}
            />
            <EditedFieldMsg
              changeDetailInfoList={
                employeeClassPlanId
                  ? [
                      changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                        ?.eeAndSpouseContributionAmount,
                      changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                        ?.spouseContributionAmount,
                    ]
                  : []
              }
              client={client}
              authUser={authUser}
              hasPendingEdit={getHasPendingEdit({ field: spouseFieldName, client, formik })}
            />
          </StackY>
        </Col>
        <Col span={12}>
          <Body3>{childrenDisplayValue}</Body3>
        </Col>
        <Col span={12}>
          <StackY dist={8}>
            <FormInput
              name={childrenFieldName}
              prefix={formik.values.employerContributionUnit === "DOLLAR" ? "$" : <span />}
              suffix={formik.values.employerContributionUnit === "PERCENT" ? "%" : <span />}
              maxLength={6}
              label={`Employer contribution ${
                formik.values.employerContributionUnit === "PERCENT" ? " percent" : "amount"
              }`}
              touched={formik.touched[childrenFieldName] || !!strictErrors[childrenFieldName]}
              disabled={formik.isSubmitting}
              error={formik.errors[childrenFieldName] || strictErrors[childrenFieldName]}
              value={formik.values[childrenFieldName]}
              onChange={formik.handleChange}
              aria-label={`Employer contribution amount for ${childrenAriaLabel} for ${planLevel} plan`}
            />
            <EditedFieldMsg
              changeDetailInfoList={
                employeeClassPlanId
                  ? [
                      changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                        ?.eeAndChildrenContributionAmount,
                      changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                        ?.childrenContributionAmount,
                    ]
                  : []
              }
              client={client}
              authUser={authUser}
              hasPendingEdit={getHasPendingEdit({ field: childrenFieldName, client, formik })}
            />
          </StackY>
        </Col>
        {(!useIndividualRoleAttrs || isFourTierIndividualBenefitType) && (
          <>
            <Col span={12}>
              <Body3>Family</Body3>
            </Col>
            <Col span={12}>
              <StackY dist={8}>
                <FormInput
                  name={familyFieldName}
                  prefix={formik.values.employerContributionUnit === "DOLLAR" ? "$" : <span />}
                  suffix={formik.values.employerContributionUnit === "PERCENT" ? "%" : <span />}
                  maxLength={6}
                  label={`Employer contribution ${
                    formik.values.employerContributionUnit === "PERCENT" ? " percent" : "amount"
                  }`}
                  touched={formik.touched[familyFieldName] || !!strictErrors[familyFieldName]}
                  disabled={formik.isSubmitting}
                  error={formik.errors[familyFieldName] || strictErrors[familyFieldName]}
                  value={formik.values[familyFieldName]}
                  onChange={formik.handleChange}
                  aria-label={`Employer contribution amount for Family for ${planLevel} plan`}
                />
                <EditedFieldMsg
                  changeDetailInfoList={
                    employeeClassPlanId
                      ? [
                          changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                            ?.eeAndFamilyContributionAmount,
                          changeSnapshot.EmployeeClassPlan[employeeClassPlanId]
                            ?.familyContributionAmount,
                        ]
                      : []
                  }
                  client={client}
                  authUser={authUser}
                  hasPendingEdit={getHasPendingEdit({ field: familyFieldName, client, formik })}
                />
              </StackY>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
