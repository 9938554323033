import { StackY } from "client/src/components/Spacing/Spacing";
import { Checkbox } from "../Form/Checkbox";
import { Body2 } from "../Typography/Typography";
import type { ClientTableFilters } from "../../../../shared/types/Client";
import type { SlobListFilterComponentProps } from "../SlobList/SlobList";

export const DataTestId = "implementation-type-filter-wrapper";

type ImplementationTypeFilterValues = Pick<ClientTableFilters, "isNewIssue" | "isAddCoverage">;

export type ImplementationTypeFilterProps = Omit<
  SlobListFilterComponentProps<ImplementationTypeFilterValues>,
  "onFiltersReset"
>;

export const ImplementationTypeFilter = ({
  filters,
  onFiltersChanged,
}: ImplementationTypeFilterProps) => {
  return (
    <StackY dist={16} data-testid={DataTestId}>
      <Body2>Implementation type</Body2>
      <Checkbox
        name="isNewIssue"
        value={"New Issue"}
        label={"New Issue"}
        checked={filters?.isNewIssue ?? false}
        errorId={undefined}
        onChange={(e) => {
          onFiltersChanged({
            isNewIssue: e.target.checked,
          });
        }}
        disabled={false}
      />
      <Checkbox
        name="isAddCoverage"
        value={"Add Coverage"}
        label={"Add Coverage"}
        checked={filters?.isAddCoverage ?? false}
        errorId={undefined}
        onChange={(e) => {
          onFiltersChanged({
            isAddCoverage: e.target.checked,
          });
        }}
        disabled={false}
      />
    </StackY>
  );
};
