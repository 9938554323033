import { getEIFSubStepMap } from "shared/types/EIF";
import { HubCard } from "../../../components/HubCard/HubCard";
import { StackY } from "../../../components/Spacing/Spacing";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";
import type { Plan } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export type EIFPlaceHolderProps = { eifStepId: EIFStepId } & (
  | {
      eifSubStepId: Exclude<EIFSubStepId, "non-class-benefits-preferences"> | undefined;
    }
  | {
      eifSubStepId: "non-class-benefits-preferences";
      clientPlans: Plan[];
      featureToggles: ClientFeatureToggles;
    }
);

export const EIFPlaceholder = (props: EIFPlaceHolderProps) => {
  const { eifStepId, eifSubStepId } = props;
  let title = "";

  switch (eifSubStepId) {
    case "non-class-benefits-preferences": {
      title = eifSubStepId
        ? getEIFSubStepMap({
            eifSubStepId,
            clientPlans: props.clientPlans,
            featureToggles: props.featureToggles,
          })
        : eifStepId;
      break;
    }
    default:
      title = eifSubStepId
        ? getEIFSubStepMap({
            eifSubStepId,
          })
        : eifStepId;
      break;
  }

  return (
    <div data-testid="eif-placeholder">
      <h1>{title}</h1>

      <StackY dist={24}>
        <HubCard>
          <p className="mb-0">
            Show content for{" "}
            <strong>
              {eifStepId} / {eifSubStepId}
            </strong>
          </p>
        </HubCard>
      </StackY>
    </div>
  );
};
