import { Typography } from "antd";
import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { StackY } from "client/src/components/Spacing/Spacing";
import { useState } from "react";
import { BenAdminCreateValidationSchema } from "shared/validation/user";

import { Button } from "../../../components/Button/Button";
import { Checkbox } from "../../../components/Form/Checkbox";
import { H2 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import { useCreateBenAdmin } from "../../../hooks/user";
import { BenAdminSearchAndAssign } from "./BenAdminSearchAndAssign";

import { UserForm } from "./UserForm";
import * as styles from "./form.module.less";

import type { ClientId } from "shared/types/Client";

const { Text } = Typography;

type BenAdminCreateFormProps = {
  handleClose: () => void;
  clientId: ClientId;
  showWelcomeEmail?: boolean;
  onChange?: () => void;
  sendWelcomeEmail: boolean;
};

export const BenAdminCreateForm = ({
  handleClose,
  clientId,
  showWelcomeEmail = true,
  onChange,
  sendWelcomeEmail,
}: BenAdminCreateFormProps) => {
  const [assignBAError, setAssignBAError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { mutateAsync } = useCreateBenAdmin();

  const formik = useSlobFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      sendWelcomeEmail: sendWelcomeEmail ?? false,
      clientIds: [clientId],
    },
    validationSchema: BenAdminCreateValidationSchema,
    async onSubmit(values) {
      const { isError, isSuccess } = await mutateAsync({
        data: values,
      });
      if (isError) {
        throw new Error();
      } else if (isSuccess) {
        void slobMessage.success("Successfully created Benefits Administrator");
        onChange?.();
        handleClose();
      }
    },
  });

  return (
    <div className={styles.modalContainer}>
      <H2 as="h1">Add Benefits Administrator</H2>
      <h3>Assign existing Benefits Administrator</h3>
      <BenAdminSearchAndAssign
        onAssign={() => {
          handleClose();
        }}
        disabled={formik.isSubmitting}
        onError={setAssignBAError}
        onProcess={setIsProcessing}
        onChange={onChange}
        clientId={clientId}
        sendWelcomeEmail={sendWelcomeEmail}
      />
      <HorizontalDivider />
      <h3>Add new Benefit Administrator</h3>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={16}>
          <UserForm formik={formik} />
          {showWelcomeEmail && (
            <>
              <span className={styles.tip}>
                Tip: Send a welcome email to provide an overview and account activation link to get
                started.
              </span>
              <Checkbox
                label="Send welcome email"
                name="sendWelcomeEmail"
                checked={formik.values.sendWelcomeEmail}
                onChange={formik.handleChange}
              />
            </>
          )}
          {assignBAError && (
            <Text type="danger" className={styles.errorMessage}>
              {assignBAError}
            </Text>
          )}
          <div className={styles.footer}>
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              loading={formik.isSubmitting}
              disabled={isProcessing || !formik.dirty || !formik.isValid || formik.isSubmitting}
            >
              Add Benefits Administrator
            </Button>
          </div>
          <div className={styles.errorMessage}>
            {formik.status && <Text type="danger">{formik.status}</Text>}
          </div>
        </StackY>
      </form>
    </div>
  );
};
