import { ButtonOld } from "client/src/components/Button/ButtonOld";
import { useEffect } from "react";

import { ConfirmDialog } from "../../../components/ConfirmDialog/ConfirmDialog";
import { genericErrorCopy2 } from "../../../components/Error/ErrorMessage";
import { LoadingError } from "../../../components/Error/LoadingError";
import { Body1 } from "../../../components/Typography/Typography";
import { slobMessage } from "../../../components/slobMessage/slobMessage";
import { useDocumentDownloadHelper } from "../../../hooks/document";
import { useToggler } from "../../../hooks/useToggler";

import { useDownloadZipFileForDocumentIds } from "../../../hooks/zipped-documents";
import type { ButtonSize } from "../../../components/Button/Button";
import type { ReactNode } from "react";
import type { ClientId } from "shared/types/Client";
import type { DocumentCategory } from "shared/types/Document";

type DocumentDownloadLinkProps = {
  clientId: ClientId;
  category: DocumentCategory;
  policyId?: string;
  children: ReactNode;
  size?: ButtonSize;
  track: (buttonLabel: string) => void;
};

export const DocumentDownloadLink = ({
  clientId,
  category,
  policyId,
  children,
  size = "middle",
  track,
}: DocumentDownloadLinkProps) => {
  const {
    isLoadingDocuments,
    isDownloading: isDownloadingSingleDocument,
    errorGetDocuments,
    errorDocumentURL,
    documents,
    downloadDocument,
  } = useDocumentDownloadHelper(clientId, [category], policyId);

  const { isGeneratingZippedDocument, errorGeneratingZippedFile, requestZipFile } =
    useDownloadZipFileForDocumentIds(clientId);

  const [noDocumentsModalIsVisible, toggleNoDocumentsModalIsVisible] = useToggler();

  const isDownloading = isDownloadingSingleDocument || isGeneratingZippedDocument;

  useToastError(Boolean(!isDownloading && (errorDocumentURL || errorGeneratingZippedFile)));

  if (errorGetDocuments || (documents == null && !(isLoadingDocuments || isDownloading))) {
    return <LoadingError type="component" error={errorGetDocuments} title={children} />;
  }

  return (
    <>
      <ButtonOld
        type="link-inline"
        size={size}
        disabled={isDownloading}
        loading={isLoadingDocuments}
        onClick={async () => {
          track(String(children));

          if (documents) {
            if (documents.length === 0) {
              toggleNoDocumentsModalIsVisible();
            } else if (documents.length === 1 && documents[0]) {
              const [singleDocument] = documents;
              downloadDocument(singleDocument.id);
            } else {
              const documentIds = documents.map((d) => d.id);
              await requestZipFile(documentIds);
            }
          }
        }}
      >
        {children}
      </ButtonOld>

      <ConfirmDialog
        isVisible={noDocumentsModalIsVisible}
        cancelActionText="Close"
        onConfirm={toggleNoDocumentsModalIsVisible}
        onCancel={toggleNoDocumentsModalIsVisible}
      >
        <Body1 as="p">Your Implementation Consultant has not yet uploaded this document.</Body1>
      </ConfirmDialog>
    </>
  );
};

function useToastError(shouldToast: boolean) {
  useEffect(() => {
    if (shouldToast) {
      const hide = slobMessage.error(genericErrorCopy2);

      return () => {
        hide();
      };
    }
  }, [shouldToast]);
}
