import * as styles from "./table.module.less";

import type { ReactNode } from "react";

export const ActionButtons = (props: unknown & { children: NonNullable<ReactNode> }) => {
  return (
    <div {...props} className={styles.actionButtons}>
      {props.children}
    </div>
  );
};
