import { Button } from "client/src/components/Button/Button";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackX, StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3, Body5 } from "client/src/components/Typography/Typography";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EIFContributionByPlanLevelForm } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/EIFContributionByPlanLevelForm";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getHasPendingEdit } from "client/src/domain/EIF/common/utils/getHasPendingEdit";
import {
  getIsSupplementalHealthBenefitType,
  type BenefitTypeEIF,
  getIsDHMOBenefitType,
} from "shared/types/BenefitTypes";
import type { FormikTypeFromValidator } from "client/src/hooks/useSlobFormik";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClassPlanWithPlan } from "shared/types/EmployeeClassPlan";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { editContributionsForEmployeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";

type EIFClassBuilderTieredContributionsFormProps = {
  isActive: boolean;
  benefitType?: BenefitTypeEIF;
  formik: FormikTypeFromValidator<typeof editContributionsForEmployeeClassPlanValidationSchema>;
  showHigh: boolean;
  useIndividualRoleAttrs: boolean;
  changeSnapshot: DEIFChangeSnapshot;
  employeeClassPlans: EmployeeClassPlanWithPlan[];
  client: Client;
  authUser: UserData | null;
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof editContributionsForEmployeeClassPlanValidationSchema>
  >;
  premiumPaymentsOnly: boolean;
};

export const EIFClassBuilderTieredContributionsForm = (
  props: EIFClassBuilderTieredContributionsFormProps,
) => {
  const {
    formik,
    benefitType,
    showHigh,
    useIndividualRoleAttrs,
    changeSnapshot,
    employeeClassPlans,
    client,
    authUser,
    strictErrors,
    premiumPaymentsOnly,
  } = props;

  const isSupplementalHealthBenefit =
    benefitType && getIsSupplementalHealthBenefitType(benefitType);

  const isDHMOBenefitType = benefitType && getIsDHMOBenefitType(benefitType);

  const [relevantLowEmployeeClassPlanId]: (string | undefined)[] = employeeClassPlans
    .map((ecp) => {
      const planBenefitType = ecp.plan.benefitType;
      const level = ecp.plan.level;

      return planBenefitType === benefitType && (level === "LOW" || level === "NOT_APPLICABLE")
        ? ecp.id
        : undefined;
    })
    .filter(Boolean);

  const [relevantHighEmployeeClassPlanId]: (string | undefined)[] = showHigh
    ? employeeClassPlans
        .map((ecp) => {
          const planBenefitType = ecp.plan.benefitType;
          const level = ecp.plan.level;

          return planBenefitType === benefitType && level === "HIGH" ? ecp.id : undefined;
        })
        .filter(Boolean)
    : [undefined];

  const apply100ToAllPerAttrs = useIndividualRoleAttrs
    ? {
        spouseContributionAmountLow: 100,
        childrenContributionAmountLow: 100,
        spouseContributionAmountHigh: showHigh ? 100 : formik.values.spouseContributionAmountHigh,
        childrenContributionAmountHigh: showHigh
          ? 100
          : formik.values.childrenContributionAmountHigh,
      }
    : {
        eeAndFamilyContributionAmountLow: 100,
        eeAndSpouseContributionAmountLow: 100,
        eeAndChildrenContributionAmountLow: 100,
        eeAndFamilyContributionAmountHigh: showHigh
          ? 100
          : formik.values.eeAndFamilyContributionAmountHigh,
        eeAndSpouseContributionAmountHigh: showHigh
          ? 100
          : formik.values.eeAndSpouseContributionAmountHigh,
        eeAndChildrenContributionAmountHigh: showHigh
          ? 100
          : formik.values.eeAndChildrenContributionAmountHigh,
      };

  const apply100ToAll = async () => {
    await formik.setValues({
      ...formik.values,
      eeContributionAmountLow: 100,
      eeContributionAmountHigh: showHigh ? 100 : formik.values.eeContributionAmountHigh,
      ...apply100ToAllPerAttrs,
    });
  };

  return (
    <StackY dist={24} wrap={false}>
      {!premiumPaymentsOnly && (
        <RadioGroup
          name="employerContributionUnit"
          label="Type"
          aria-label="Type"
          disabled={formik.isSubmitting}
          error={formik.errors.employerContributionUnit || strictErrors.employerContributionUnit}
          touched={
            formik.touched.employerContributionUnit || !!strictErrors.employerContributionUnit
          }
          options={[
            {
              value: "PERCENT",
              label: "Percent",
            },
            {
              value: "DOLLAR",
              label: "Dollar",
            },
          ]}
          direction="vertical"
          value={formik.values.employerContributionUnit}
          onChange={formik.handleChange}
        />
      )}
      <EditedFieldMsg
        changeDetailInfoList={[
          relevantLowEmployeeClassPlanId
            ? changeSnapshot.EmployeeClassPlan[relevantLowEmployeeClassPlanId]
                ?.employerContributionUnit
            : null,
          relevantHighEmployeeClassPlanId
            ? changeSnapshot.EmployeeClassPlan[relevantHighEmployeeClassPlanId]
                ?.employerContributionUnit
            : undefined,
        ]}
        client={client}
        authUser={authUser}
        hasPendingEdit={getHasPendingEdit({ field: "employerContributionUnit", client, formik })}
      />

      <div>
        {!premiumPaymentsOnly && (
          <Row justify="space-between">
            <Col>
              {isSupplementalHealthBenefit || isDHMOBenefitType ? (
                <Body2 as="p">How much are you contributing?</Body2>
              ) : (
                <Body2 as="p">Low plan</Body2>
              )}
            </Col>
            <Col>
              {formik.values.employerContributionUnit === "PERCENT" && (
                <Button type="text-only" onClick={apply100ToAll}>
                  Apply 100% to all
                </Button>
              )}
            </Col>
          </Row>
        )}

        {relevantLowEmployeeClassPlanId && !premiumPaymentsOnly && (
          <EIFContributionByPlanLevelForm
            planLevel="Low"
            useIndividualRoleAttrs={useIndividualRoleAttrs}
            formik={formik}
            changeSnapshot={changeSnapshot}
            employeeClassPlanId={relevantLowEmployeeClassPlanId}
            client={client}
            authUser={authUser}
            benefitType={benefitType}
            strictErrors={strictErrors}
          />
        )}
      </div>

      {showHigh && relevantHighEmployeeClassPlanId && !premiumPaymentsOnly && (
        <div>
          <Body2 as="p">High plan</Body2>

          <EIFContributionByPlanLevelForm
            planLevel="High"
            useIndividualRoleAttrs={useIndividualRoleAttrs}
            formik={formik}
            changeSnapshot={changeSnapshot}
            employeeClassPlanId={relevantHighEmployeeClassPlanId}
            client={client}
            authUser={authUser}
            strictErrors={strictErrors}
          />
        </div>
      )}

      {(isSupplementalHealthBenefit || premiumPaymentsOnly) && (
        <>
          <RadioGroup
            name="employerPremiumPayments"
            label="Are your employees' premium payments made pre-tax or post-tax?"
            aria-label="Are your employees' premium payments made pre-tax or post-tax?"
            disabled={formik.isSubmitting}
            error={formik.errors.employerPremiumPayments || strictErrors.employerPremiumPayments}
            touched={
              formik.touched.employerPremiumPayments || !!strictErrors.employerPremiumPayments
            }
            options={[
              {
                value: "PRE_TAX",
                label: "Pre-tax",
              },
              {
                value: "POST_TAX",
                label: (
                  <StackX dist={12} className="pt-4">
                    <Body3>Post-tax</Body3>
                    <Body5>Most Common</Body5>
                  </StackX>
                ),
              },
            ]}
            direction="vertical"
            value={formik.values.employerPremiumPayments}
            onChange={formik.handleChange}
          />
          <EditedFieldMsg
            changeDetailInfoList={relevantChangesForContributionFields(
              employeeClassPlans.map((ecp) => ecp.id),
              changeSnapshot,
              ["employerPremiumPayments"],
            )}
            client={client}
            authUser={authUser}
            hasPendingEdit={getHasPendingEdit({
              field: "employerPremiumPayments",
              client,
              formik,
            })}
          />
        </>
      )}
    </StackY>
  );
};
