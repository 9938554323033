import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { ErrorMessage } from "client/src/components/Error/ErrorMessage";
import { Checkbox } from "client/src/components/Form/Checkbox";
import { FormInput } from "client/src/components/Form/Input";
import { InputErrorMessage } from "client/src/components/Form/InputErrorMessage";
import { RadioGroup } from "client/src/components/Form/RadioGroup";
import { SlobSelect } from "client/src/components/Form/SlobSelect";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSetupClientPlanConfiguration } from "client/src/hooks/client";
import { useSlobId } from "client/src/hooks/useSlobId";
import { DHMOSettings } from "client/src/pages/Clients/SetupCards/DHMOSettings";
import { PFMLSettings } from "client/src/pages/Clients/SetupCards/PFMLSettings";
import { shouldShowAgeRuleQuestions } from "client/src/utils/getShouldShowAgeQuestions";
import { capitalize } from "client/src/utils/string";
import { getIn } from "formik";
import { useMemo } from "react";
import {
  type BenefitTypeEIF,
  getIsPFMLBenefitType,
  isLifeAndDisabilityBenefitType,
  dhmoBenefitTypes,
  getIsDHMOBenefitType,
} from "shared/types/BenefitTypes";
import { ageRules as ageRulesDefinition } from "shared/types/Client";
import { getCurrentPFMLSelection, type PlanSetupInput } from "shared/types/EIF";
import {
  salaryBasedCoverages,
  ageRelatedSlfCoverages,
  benefitTypeToCoverage,
  getIsSalaryBasedCoverage,
  lifeAndDisabilityCoverages,
  coverageToBenefitType,
  pfmlCoverages,
  getIsPFMLASOCoverage,
  getIsLifeCoverageEIF,
  getIsDisabilityCoverage,
  dentalVisionCoverage,
  supplementalHealthCoverages,
} from "shared/types/SlfCoverages";
import { getEIFIsPendingSignatureOrICEditApproval } from "shared/utils/EIF/outsideSigner";
import { getIsMultiPolicyMode } from "shared/utils/client";
import { exhaustiveCheck } from "shared/utils/exhaustiveCheck";
import { formatFullName, listFormat } from "shared/utils/format";
import { rejectNullableValues } from "shared/utils/utils";
import {
  ageRuleSchema,
  authorizedSignerUserIdValidationSchema,
  criticalIllnessRateTypeSchema,
  criticalIllnessRateTypeSchemaWithNull,
  criticalIllnessTypeSchema,
  criticalIllnessTypeSchemaWithNull,
  outsideSignerValidationSchema,
} from "shared/validation/client";
import { pfmlOptionValidation } from "shared/validation/plan";
import {
  yesNoRequired,
  yesNoNullable,
  nullNotAllowedTestConfig,
} from "shared/validation/validation";
import * as Yup from "yup";
import { Body2, Body3, Body5, Eyebrow } from "../../../components/Typography/Typography";
import { useSlobFormik } from "../../../hooks/useSlobFormik";
import type { LabeledValue } from "antd/lib/select";
import type { SlobDefaultOptionType } from "client/src/components/Form/SlobSelect";
import type { SetupClientPlanConfigurationQuery } from "client/src/hooks/client";
import type { DHMOBenefitType } from "shared/types/BenefitTypes";
import type { AgeRules, Client } from "shared/types/Client";
import type { Plan } from "shared/types/Plan";
import type { SalaryBasedCoverage, SlfCoverageLongName } from "shared/types/SlfCoverages";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { BenAdmin, Broker } from "shared/types/User";

import "shared/validation/email";

type LowOrLowAndHigh = "LOW" | "LOW_&_HIGH";
const lowOrLowAndHighSchema = Yup.mixed<LowOrLowAndHigh>()
  .oneOf<LowOrLowAndHigh>(["LOW", "LOW_&_HIGH"], "Please provide a response")
  .nullable()
  .test(nullNotAllowedTestConfig());

const plansToLowOrLowAndHigh = (highPlan?: Plan, lowPlan?: Plan): LowOrLowAndHigh | null => {
  if (highPlan) {
    return "LOW_&_HIGH";
  }
  if (lowPlan) {
    return "LOW";
  }
  return null;
};

const getAgeRuleApplyText = (ageRule: AgeRules) => {
  switch (ageRule) {
    case "Age Reductions":
      return "Do age reductions apply?";
    case "Age Band Rates":
      return "Do age band rates apply?";
    default:
      exhaustiveCheck(ageRule);
      return "";
  }
};

export const getAgeRulesDynamicQuestion = (
  coverages: readonly SlfCoverageLongName[],
  ageRule: AgeRules,
  isMultiPolicy: boolean,
) => {
  const hasLife = coverages.some((coverage) => getIsLifeCoverageEIF(coverage));
  const hasDisability = coverages.some((coverage) => getIsDisabilityCoverage(coverage));
  const hasCriticalIllness = coverages.includes("Critical Illness");

  const benefits = new Array<string>()
    .concat(hasLife ? "Life" : [])
    .concat(hasDisability && ageRule === "Age Band Rates" ? "Disability" : [])
    .concat(hasCriticalIllness ? "Critical Illness" : []);

  if (benefits.length === 0) {
    throw new Error(
      "No age related benefits found for this client. Cannot construct dynamic question text",
    );
  }

  const benefitsDescription = listFormat(benefits);
  const dynamicQuestion = getAgeRuleApplyText(ageRule);
  const result = `${
    isMultiPolicy ? "You have a policy with" : "This policy has"
  } ${benefitsDescription} benefits. ${dynamicQuestion}`;
  return result;
};

type PresentationProps = {
  client: Client;
  plans: Plan[];
  benAdmins: BenAdmin[];
  brokers: Broker[];
  showLivePortalWarning?: boolean;
  savePlanConfiguration: SetupClientPlanConfigurationQuery["mutateAsync"];
  featureToggles: ClientFeatureToggles;
  onSave?: () => void;
};

export const CardConfigureSubmitCompanyInformationPresentation = ({
  client,
  plans,
  benAdmins,
  brokers,
  showLivePortalWarning,
  savePlanConfiguration,
  featureToggles,
  onSave,
}: PresentationProps) => {
  const {
    allowClientSelfServicePlanConfig,
    allowClientToViewPlanConfig,
    authorizedSignerUserId,
    outsideSigner,
    ageRules,
    allBenefitsNonContributory,
    brokerAsAuthorizedSigner,
  } = client;

  const isMultiPolicyMode = getIsMultiPolicyMode(client);

  const allCoverages = client.allPoliciesSlfCoverages ?? [];

  const slfCoverageHasDental = allCoverages.includes("Dental");
  const slfCoverageHasVision = allCoverages.includes("Vision");
  const slfCoverageHasASODental =
    featureToggles.ONBOARD_ASO_DENTAL && allCoverages.includes("ASO Dental");
  const slfCoverageThatCouldBeSalaryBased = salaryBasedCoverages.filter((coverage) =>
    allCoverages.includes(coverage),
  );

  const hasDMHMOCoverage = allCoverages.some((coverage) => coverage === "Dental (DHMO)");

  const nonBeneficiaryContribution: SlfCoverageLongName[] = [
    ...dentalVisionCoverage,
    ...supplementalHealthCoverages,
  ];
  const showBenefitsNonContributory = !allCoverages.some((coverage) =>
    nonBeneficiaryContribution.includes(coverage),
  );

  const showCriticalIllnessQuestions = allCoverages.some(
    (coverage) => coverage === "Critical Illness",
  );

  const showAgeRules = ageRelatedSlfCoverages.some((coverage) => {
    return allCoverages.includes(coverage);
  });

  const showDependentBasicLifeAgeQuestion =
    allCoverages.includes("Dependent Basic Life") &&
    !allCoverages.includes("Spouse Voluntary Life");

  const getPlanByBenefitTypeAndLevel = useMemo(
    () => getPlanByBenefitTypeAndLevelForPlans(plans),
    [plans],
  );

  const highDentalPlan = getPlanByBenefitTypeAndLevel("DENTAL", "HIGH");
  const lowDentalPlan = getPlanByBenefitTypeAndLevel("DENTAL", "LOW");
  const highVisionPlan = getPlanByBenefitTypeAndLevel("VISION", "HIGH");
  const lowVisionPlan = getPlanByBenefitTypeAndLevel("VISION", "LOW");
  const lowASODentalPlan = getPlanByBenefitTypeAndLevel("DENTAL_ASO", "LOW");
  const highASODentalPlan = getPlanByBenefitTypeAndLevel("DENTAL_ASO", "HIGH");

  const initialDentalPlanValue = plansToLowOrLowAndHigh(highDentalPlan, lowDentalPlan);
  const initialVisionPlanValue = plansToLowOrLowAndHigh(highVisionPlan, lowVisionPlan);
  const initialASODentalPlanValue = plansToLowOrLowAndHigh(highASODentalPlan, lowASODentalPlan);

  const initialSalaryBasedCoverages = plans
    .filter((p) => p.salaryBased)
    .map((p) => benefitTypeToCoverage[p.benefitType])
    .filter(getIsSalaryBasedCoverage);

  const { availablePFMLBenefitTypes, currentPFMLSelection: initialPlanPFMLSelection } =
    getCurrentPFMLSelection(plans, allCoverages);

  const initialDHMOBenefitTypes = plans
    .map((plan) => plan.benefitType)
    .filter((benefitType): benefitType is DHMOBenefitType => getIsDHMOBenefitType(benefitType));

  const pfmlSelectionFieldsSchema = Object.fromEntries(
    availablePFMLBenefitTypes.map((pfmlASOBenefitType) => [
      pfmlASOBenefitType,
      pfmlOptionValidation,
    ]),
  );

  const cardPlanValidationSchema = Yup.object({
    allowClientSelfServicePlanConfig: yesNoRequired,
    allowClientToViewPlanConfig: Yup.boolean().required(),
    dentalPlan: lowOrLowAndHighSchema,
    visionPlan: lowOrLowAndHighSchema,
    asoDentalPlan: lowOrLowAndHighSchema,
    authorizedSignerUserId: authorizedSignerUserIdValidationSchema,
    outsideSigner: outsideSignerValidationSchema,
    ageRules: ageRuleSchema,
    salaryBasedCoverages: Yup.array()
      .of(Yup.mixed<SalaryBasedCoverage>().oneOf(salaryBasedCoverages.slice()).required())
      .strict()
      .required(),
    planPFMLSelection: Yup.object().shape(pfmlSelectionFieldsSchema),
    allBenefitsNonContributory: showBenefitsNonContributory ? yesNoRequired : yesNoNullable,
    DHMOBenefitTypes: hasDMHMOCoverage
      ? Yup.array()
          .of(Yup.mixed<DHMOBenefitType>().oneOf(dhmoBenefitTypes.slice()).required())
          .min(1, "Please add the client's Prepaid Dental / DHMO plan(s)")
      : Yup.array().of(Yup.mixed<DHMOBenefitType>().oneOf(dhmoBenefitTypes.slice()).required()),
    criticalIllnessType: showCriticalIllnessQuestions
      ? criticalIllnessTypeSchema
      : criticalIllnessTypeSchemaWithNull,
    criticalIllnessRateType: showCriticalIllnessQuestions
      ? criticalIllnessRateTypeSchema
      : criticalIllnessRateTypeSchemaWithNull,
    changesForDependentLifeBenefitsUseAge: showDependentBasicLifeAgeQuestion
      ? yesNoRequired
      : yesNoNullable,
  });

  const formik = useSlobFormik({
    enableReinitialize: true,
    initialValues: {
      allowClientSelfServicePlanConfig,
      allowClientToViewPlanConfig: allowClientToViewPlanConfig ?? false,
      ...(slfCoverageHasDental && { dentalPlan: initialDentalPlanValue }),
      ...(slfCoverageHasVision && { visionPlan: initialVisionPlanValue }),
      ...(slfCoverageHasASODental && { asoDentalPlan: initialASODentalPlanValue }),
      ageRules: ageRules ?? (showAgeRules ? [] : null),
      salaryBasedCoverages: initialSalaryBasedCoverages,
      planPFMLSelection: initialPlanPFMLSelection,
      allBenefitsNonContributory,
      DHMOBenefitTypes: initialDHMOBenefitTypes,
      criticalIllnessType: client.criticalIllnessType,
      criticalIllnessRateType: client.criticalIllnessRateType,
      changesForDependentLifeBenefitsUseAge: client.changesForDependentLifeBenefitsUseAge,
      authorizedSignerUserId:
        authorizedSignerUserId || (outsideSigner != null ? "OUTSIDE_SIGNER" : ""),
      outsideSigner: outsideSigner ?? null,
    },
    validationSchema: cardPlanValidationSchema,
    validationContext: { benAdmins, brokers, brokerAsAuthorizedSigner },
    onSubmit: async (values) => {
      const plans: PlanSetupInput[] = [];
      if (slfCoverageHasDental) {
        plans.push({
          id: lowDentalPlan ? lowDentalPlan.id : undefined,
          benefitType: "DENTAL",
          level: "LOW",
          salaryBased: false,
          pfmlOption: null,
        });

        if (values.dentalPlan === "LOW_&_HIGH") {
          plans.push({
            id: highDentalPlan ? highDentalPlan.id : undefined,
            benefitType: "DENTAL",
            level: "HIGH",
            salaryBased: false,
            pfmlOption: null,
          });
        }
      }

      if (slfCoverageHasASODental) {
        plans.push({
          id: lowASODentalPlan ? lowASODentalPlan.id : undefined,
          benefitType: "DENTAL_ASO",
          level: "LOW",
          salaryBased: false,
          pfmlOption: null,
        });

        if (values.asoDentalPlan === "LOW_&_HIGH") {
          plans.push({
            id: highASODentalPlan ? highASODentalPlan.id : undefined,
            benefitType: "DENTAL_ASO",
            level: "HIGH",
            salaryBased: false,
            pfmlOption: null,
          });
        }
      }

      if (slfCoverageHasVision) {
        plans.push({
          id: lowVisionPlan ? lowVisionPlan.id : undefined,
          benefitType: "VISION",
          level: "LOW",
          salaryBased: false,
          pfmlOption: null,
        });

        if (values.visionPlan === "LOW_&_HIGH") {
          plans.push({
            id: highVisionPlan ? highVisionPlan.id : undefined,
            benefitType: "VISION",
            level: "HIGH",
            salaryBased: false,
            pfmlOption: null,
          });
        }
      }

      const clientLifeAndDisabilityCoverages = lifeAndDisabilityCoverages.filter((coverage) => {
        return client.allPoliciesSlfCoverages?.includes(coverage);
      });

      const lifeAndDisabilityPlans = clientLifeAndDisabilityCoverages
        .map((coverage) => {
          const isSalaryBasedCoverage = getIsSalaryBasedCoverage(coverage);
          const benefitType = coverageToBenefitType[coverage];
          if (!isLifeAndDisabilityBenefitType(benefitType)) {
            return null;
          }
          const level = "NOT_APPLICABLE";
          const relevantExistingPlan = getPlanByBenefitTypeAndLevel(benefitType, level);
          const plan: PlanSetupInput = {
            id: relevantExistingPlan ? relevantExistingPlan.id : undefined,
            benefitType,
            level,
            salaryBased: isSalaryBasedCoverage
              ? values.salaryBasedCoverages.includes(coverage)
              : false,
            pfmlOption: null,
          };
          return plan;
        })
        .filter(rejectNullableValues);

      plans.push(...lifeAndDisabilityPlans);

      const clientPFMLCoverages = pfmlCoverages.filter((coverage) => {
        return client.allPoliciesSlfCoverages?.includes(coverage);
      });
      const pfmlPlans = clientPFMLCoverages
        .map((coverage) => {
          const isPFMLASOCoverage = getIsPFMLASOCoverage(coverage);
          const benefitType = coverageToBenefitType[coverage];
          if (!getIsPFMLBenefitType(benefitType)) {
            return null;
          }
          const level = "NOT_APPLICABLE";
          const relevantExistingPlan = getPlanByBenefitTypeAndLevel(benefitType, level);
          const pfmlOptionValue = values.planPFMLSelection?.[benefitType]
            ? values.planPFMLSelection[benefitType]
            : null;
          const plan: PlanSetupInput = {
            id: relevantExistingPlan ? relevantExistingPlan.id : undefined,
            benefitType,
            level,
            salaryBased: false,
            pfmlOption: isPFMLASOCoverage && pfmlOptionValue ? pfmlOptionValue : null,
          };
          return plan;
        })
        .filter(rejectNullableValues);

      plans.push(...pfmlPlans);

      if (hasDMHMOCoverage && values.DHMOBenefitTypes && values.DHMOBenefitTypes.length > 0) {
        const dhmoPlans = values.DHMOBenefitTypes.map((DHMOStateBenefitType: DHMOBenefitType) => {
          const level = "NOT_APPLICABLE";
          const relevantExistingPlan = getPlanByBenefitTypeAndLevel(DHMOStateBenefitType, level);
          const plan: PlanSetupInput = {
            id: relevantExistingPlan ? relevantExistingPlan.id : undefined,
            benefitType: DHMOStateBenefitType,
            level,
            salaryBased: false,
            pfmlOption: null,
          };
          return plan;
        }).filter(rejectNullableValues);

        plans.push(...dhmoPlans);
      }

      const hasFLICoverage = allCoverages.some((coverage) => coverage === "Family Leave Insurance");
      if (hasFLICoverage) {
        const fliPlan = getPlanByBenefitTypeAndLevel("FLI", "NOT_APPLICABLE");
        const isSalaryBasedCoverage = getIsSalaryBasedCoverage("Family Leave Insurance");
        plans.push({
          id: fliPlan ? fliPlan.id : undefined,
          benefitType: "FLI",
          level: "NOT_APPLICABLE",
          salaryBased: isSalaryBasedCoverage
            ? values.salaryBasedCoverages.includes("Family Leave Insurance") ?? false
            : false,
          pfmlOption: null,
        });
      }

      const { isSuccess } = await savePlanConfiguration({
        data: {
          plans,
          allowClientSelfServicePlanConfig: values.allowClientSelfServicePlanConfig,
          allowClientToViewPlanConfig: values.allowClientToViewPlanConfig,
          authorizedSignerUserId:
            values.authorizedSignerUserId === "OUTSIDE_SIGNER"
              ? null
              : values.authorizedSignerUserId,
          outsideSigner: values.outsideSigner,
          ageRules: values.ageRules ?? null,
          allBenefitsNonContributory: values.allBenefitsNonContributory,
          criticalIllnessType: values.criticalIllnessType,
          criticalIllnessRateType: values.criticalIllnessRateType,
          changesForDependentLifeBenefitsUseAge: values.changesForDependentLifeBenefitsUseAge,
        },
      });

      if (isSuccess) {
        void slobMessage.success("Submit Company Information configured successfully");

        if (onSave) {
          onSave();
        }
      }
    },
  });

  const checkboxesErrorIdSalaryBasedCoverages = useSlobId({
    prefix: "salaryBasedCoveragesErrorId",
  });

  const relevantRole = client.brokerAsAuthorizedSigner ? brokers : benAdmins;

  const authorizedSignerOptions: SlobDefaultOptionType[] = relevantRole
    .map(
      (user): SlobDefaultOptionType => ({
        label: formatFullName(user),
        value: user.id,
      }),
    )
    .concat(
      relevantRole.length > 0
        ? [
            {
              label: <hr />,
              options: [{ label: "Outside signer", value: "OUTSIDE_SIGNER" }],
            },
          ]
        : [{ label: "Outside signer", value: "OUTSIDE_SIGNER" }],
    );

  const authorizedSignerOnChange = (labeledValue: LabeledValue) =>
    formik.setFieldValue("authorizedSignerUserId", labeledValue.value);

  const eifIsPendingSignatureOrICEditApproval = getEIFIsPendingSignatureOrICEditApproval(client);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <StackY dist={32}>
          {showLivePortalWarning && (
            <AlertBanner
              variant="warning"
              message={
                <Body3>
                  You are editing this task for a live portal. This client may have already
                  completed information or could be actively editing information.
                </Body3>
              }
            />
          )}

          <RadioGroup
            direction="vertical"
            label={
              <>
                <Body2 as="p">
                  Allow client to self service for plan configuration &amp; eligibility:
                </Body2>
                <Body3 as="p">
                  If No is selected, benefits administrators and brokers will see a message that the
                  IC is configuring things. They will have a read-only view of any data you’ve
                  entered for plan configuration &amp; eligibility.
                </Body3>
              </>
            }
            value={formik.values.allowClientSelfServicePlanConfig}
            disabled={formik.isSubmitting}
            name="allowClientSelfServicePlanConfig"
            onChange={async (event) => {
              if (event.target.value === "YES") {
                await formik.setFieldValue("allowClientToViewPlanConfig", false);
              }

              formik.handleChange(event);
            }}
            error={formik.errors.allowClientSelfServicePlanConfig}
            touched={formik.touched.allowClientSelfServicePlanConfig}
            options={[
              {
                label: "Yes",
                value: "YES",
              },
              {
                label: "No",
                value: "NO",
                content: (
                  <>
                    {formik.values.allowClientSelfServicePlanConfig === "NO" && (
                      <div className="pl-32">
                        <Checkbox
                          name="allowClientToViewPlanConfig"
                          label="Allow client to view plan configuration & eligibility"
                          onChange={formik.handleChange}
                          checked={!!formik.values.allowClientToViewPlanConfig}
                          content={
                            <Eyebrow>
                              Turn this on when you are ready for a client to view, but not edit.
                              This will prevent them from accidentally seeing work in progress as
                              you prefill this section.
                            </Eyebrow>
                          }
                        />
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          />

          {slfCoverageHasDental && (
            <RadioGroup
              direction="vertical"
              label={<Body2>Dental</Body2>}
              value={formik.values.dentalPlan}
              disabled={formik.isSubmitting}
              name="dentalPlan"
              onChange={formik.handleChange}
              error={formik.errors.dentalPlan}
              touched={formik.touched.dentalPlan}
              options={[
                {
                  label: "Single plan",
                  value: "LOW",
                },
                {
                  label: "Low and high plan",
                  value: "LOW_&_HIGH",
                },
              ]}
            />
          )}

          {slfCoverageHasASODental && (
            <RadioGroup
              direction="vertical"
              label={<Body2>Dental ASO</Body2>}
              value={formik.values.asoDentalPlan}
              disabled={formik.isSubmitting}
              name="asoDentalPlan"
              onChange={formik.handleChange}
              error={formik.errors.asoDentalPlan}
              touched={formik.touched.asoDentalPlan}
              options={[
                {
                  label: "Single plan",
                  value: "LOW",
                },
                {
                  label: "Low and high plan",
                  value: "LOW_&_HIGH",
                },
              ]}
            />
          )}

          {slfCoverageHasVision && (
            <RadioGroup
              direction="vertical"
              label={<Body2>Vision</Body2>}
              value={formik.values.visionPlan}
              disabled={formik.isSubmitting}
              name="visionPlan"
              onChange={formik.handleChange}
              error={formik.errors.visionPlan}
              touched={formik.touched.visionPlan}
              options={[
                {
                  label: "Single plan",
                  value: "LOW",
                },
                {
                  label: "Low and high plan",
                  value: "LOW_&_HIGH",
                },
              ]}
            />
          )}

          {showBenefitsNonContributory && (
            <RadioGroup
              direction="vertical"
              label={
                <>
                  <Body2 as="p">Benefit Contributions</Body2>
                  <Body3 as="div">
                    Are all benefits non-contributory? If yes, we don't need to ask the Client about
                    changes during annual enrollment.
                  </Body3>
                </>
              }
              value={formik.values.allBenefitsNonContributory}
              disabled={formik.isSubmitting}
              name="allBenefitsNonContributory"
              onChange={formik.handleChange}
              error={formik.errors.allBenefitsNonContributory}
              touched={formik.touched.allBenefitsNonContributory}
              options={[
                {
                  label: "Yes, all benefits are non-contributory",
                  value: "YES",
                },
                {
                  label: "No, some benefits are contributory",
                  value: "NO",
                },
              ]}
            />
          )}

          {showCriticalIllnessQuestions && (
            <>
              <RadioGroup
                direction="vertical"
                label={
                  <>
                    <Body2 as="div">
                      Is Critical Illness coverage based on issue or attained age?
                    </Body2>
                  </>
                }
                value={formik.values.criticalIllnessType}
                disabled={formik.isSubmitting}
                name="criticalIllnessType"
                onChange={formik.handleChange}
                error={formik.errors.criticalIllnessType}
                touched={formik.touched.criticalIllnessType}
                options={[
                  {
                    label: (
                      <>
                        <Body3 as="div">Issue</Body3>
                        {formik.values.criticalIllnessType === "ISSUE" && (
                          <Body5 as="div">
                            Spouse Dependent Critical Illness coverage will be based on the
                            employee's age, and dependent tobacco status will be based on the
                            spouse's tobacco status.
                          </Body5>
                        )}
                      </>
                    ),
                    value: "ISSUE",
                  },
                  {
                    label: "Attained",
                    value: "ATTAINED",
                  },
                ]}
              />
              <RadioGroup
                direction="vertical"
                label={
                  <>
                    <Body2 as="div">What is the Critical Illness rate type?</Body2>
                  </>
                }
                value={formik.values.criticalIllnessRateType}
                disabled={formik.isSubmitting}
                name="criticalIllnessRateType"
                onChange={formik.handleChange}
                error={formik.errors.criticalIllnessRateType}
                touched={formik.touched.criticalIllnessRateType}
                options={[
                  {
                    label: "Tobacco",
                    value: "TOBACCO",
                  },
                  {
                    label: "Non-tobacco",
                    value: "NON_TOBACCO",
                  },
                ]}
              />
            </>
          )}

          {showAgeRules && (
            <>
              {ageRulesDefinition.map(
                (ageRuleValue) =>
                  shouldShowAgeRuleQuestions(allCoverages, ageRuleValue) && (
                    <RadioGroup
                      key={`ageRules_${ageRuleValue}`}
                      direction="vertical"
                      label={
                        <>
                          <Body2 as="p">{capitalize(ageRuleValue)}</Body2>
                          <Body3 as="div">
                            {getAgeRulesDynamicQuestion(
                              allCoverages,
                              ageRuleValue,
                              Boolean(isMultiPolicyMode),
                            )}
                          </Body3>
                        </>
                      }
                      value={formik.values.ageRules?.includes(ageRuleValue) ? "YES" : "NO"}
                      disabled={formik.isSubmitting}
                      name="ageRules"
                      onChange={async (event) => {
                        const shouldAdd = event.target.value === "YES";
                        const ageRulesSet = new Set(formik.values.ageRules);
                        shouldAdd
                          ? ageRulesSet.add(ageRuleValue)
                          : ageRulesSet.delete(ageRuleValue);
                        await formik.setFieldValue("ageRules", Array.from(ageRulesSet));
                      }}
                      error={formik.errors.ageRules}
                      touched={formik.touched.ageRules}
                      options={[
                        {
                          label: "Yes",
                          value: "YES",
                        },
                        {
                          label: "No",
                          value: "NO",
                        },
                      ]}
                    />
                  ),
              )}
            </>
          )}

          {showDependentBasicLifeAgeQuestion && (
            <div>
              <RadioGroup
                direction="vertical"
                label={
                  <>
                    <Body2 as="p">Dependent Basic Life benefits</Body2>

                    <Body3 as="div">
                      This policy has dependent basic life benefits. Does dependent basic life have
                      age reductions and/or term age?
                    </Body3>
                  </>
                }
                value={formik.values.changesForDependentLifeBenefitsUseAge}
                disabled={formik.isSubmitting}
                name="changesForDependentLifeBenefitsUseAge"
                onChange={formik.handleChange}
                error={formik.errors.changesForDependentLifeBenefitsUseAge}
                touched={formik.touched.changesForDependentLifeBenefitsUseAge}
                options={[
                  {
                    label: "Yes",
                    value: "YES",
                  },
                  {
                    label: "No",
                    value: "NO",
                  },
                ]}
              />
            </div>
          )}

          {slfCoverageThatCouldBeSalaryBased.length > 0 && (
            <div>
              <Body2 as="p">Salary-based benefits</Body2>

              <Body3 as="p">
                Please select which benefits are salary-based from the following list of benefits
                included on this policy.
              </Body3>

              <StackY dist={16} wrap={false}>
                {slfCoverageThatCouldBeSalaryBased.map((coverage) => (
                  <Checkbox
                    key={coverage}
                    name="salaryBasedCoverages"
                    value={coverage}
                    label={coverage}
                    disabled={formik.isSubmitting}
                    checked={formik.values.salaryBasedCoverages.includes(coverage)}
                    onChange={formik.handleChange}
                    errorId={
                      formik.touched.salaryBasedCoverages && formik.errors.salaryBasedCoverages
                        ? checkboxesErrorIdSalaryBasedCoverages
                        : undefined
                    }
                  />
                ))}
              </StackY>

              <div aria-live="assertive">
                {formik.touched.salaryBasedCoverages && formik.errors.salaryBasedCoverages && (
                  <InputErrorMessage
                    id={checkboxesErrorIdSalaryBasedCoverages}
                    error={formik.errors.salaryBasedCoverages?.toString()}
                  />
                )}
              </div>
            </div>
          )}

          {hasDMHMOCoverage && (
            <DHMOSettings
              formikSetFieldValue={formik.setFieldValue}
              disabled={formik.isSubmitting}
              formikSelectedStates={formik.values.DHMOBenefitTypes ?? []}
              formikError={formik.errors.DHMOBenefitTypes}
            />
          )}

          <PFMLSettings
            client={client}
            disabled={formik.isSubmitting}
            touched={getTouchedPlanPFMLOptionSelection(formik.touched.planPFMLSelection)}
            error={getErrorsPlanPFMLOptionSelection(formik.errors.planPFMLSelection)}
            value={formik.values.planPFMLSelection}
            onChange={formik.handleChange}
          />

          <Row gutter={[24, 24]} data-testid="authorized-signer">
            {authorizedSignerOptions.length ? (
              <>
                <Col xs={12}>
                  <SlobSelect
                    label="Authorized signer"
                    name="authorizedSignerUserId"
                    placeholder="Select the authorized signer"
                    value={formik.values.authorizedSignerUserId}
                    options={authorizedSignerOptions}
                    onChange={authorizedSignerOnChange}
                    touched={formik.touched.authorizedSignerUserId}
                    error={formik.errors.authorizedSignerUserId}
                    disabled={formik.isSubmitting}
                  />
                </Col>
                <Col span={12}></Col>

                {formik.values.authorizedSignerUserId === "OUTSIDE_SIGNER" && (
                  <>
                    <Col span={12}>
                      <FormInput
                        label="First name"
                        name="outsideSigner.firstName"
                        value={formik.values.outsideSigner?.firstName ?? ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={191}
                        touched={formik.touched.outsideSigner}
                        error={getIn(formik.errors, "outsideSigner.firstName")}
                        showRequired
                        disabled={formik.isSubmitting}
                      />
                    </Col>
                    <Col span={12}>
                      <FormInput
                        label="Last name"
                        name="outsideSigner.lastName"
                        value={formik.values.outsideSigner?.lastName ?? ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={191}
                        touched={formik.touched.outsideSigner}
                        error={getIn(formik.errors, "outsideSigner.lastName")}
                        showRequired
                        disabled={formik.isSubmitting}
                      />
                    </Col>
                    <Col span={12}>
                      <FormInput
                        label="Email"
                        name="outsideSigner.email"
                        value={formik.values.outsideSigner?.email ?? ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={191}
                        touched={formik.touched.outsideSigner}
                        error={getIn(formik.errors, "outsideSigner.email")}
                        showRequired
                        disabled={formik.isSubmitting}
                      />
                    </Col>
                    <Col span={12}></Col>

                    {formik.values.outsideSigner?.email !==
                      formik.initialValues.outsideSigner?.email &&
                      eifIsPendingSignatureOrICEditApproval && (
                        <Col span={24}>
                          <AlertBanner
                            variant="warning"
                            message={
                              <Row
                                align="middle"
                                justify="space-between"
                                className="w-full"
                                gutter={16}
                                wrap={false}
                              >
                                <Col>
                                  {client.eifSignedAt == null ? (
                                    <Body3>
                                      Updating the signer will resend an email to review the Digital
                                      Smart Form.
                                    </Body3>
                                  ) : (
                                    <Body3>
                                      Updating the signer will resend an email to review the Digital
                                      Smart Form updates.
                                    </Body3>
                                  )}
                                </Col>

                                <Col>
                                  <Button
                                    type="text-only"
                                    htmlType="button"
                                    disabled={formik.isSubmitting}
                                    onClick={async () => {
                                      await formik.setFieldValue(
                                        "outsideSigner.email",
                                        formik.initialValues.outsideSigner?.email,
                                      );
                                    }}
                                    icon={<FontAwesomeIcon icon={faUndo} />}
                                    iconPosition="right"
                                  >
                                    Undo changes
                                  </Button>
                                </Col>
                              </Row>
                            }
                          />
                        </Col>
                      )}
                  </>
                )}
              </>
            ) : (
              <Col xs={24}>
                <Body2 as="p">Authorized signer</Body2>
                <Body2 as="p" redMedium>
                  No benefits administrators found. Add one in step 2 first.
                </Body2>
              </Col>
            )}
          </Row>

          <div aria-live="assertive">
            {formik.status && <ErrorMessage>{formik.status}</ErrorMessage>}
          </div>

          <Row justify="end">
            <Col className="mt-12 stack-x-12">
              {client.completedSetup && (
                <Button
                  type="text"
                  htmlType="button"
                  size="middle"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  onClick={onSave}
                >
                  Cancel
                </Button>
              )}

              <Button
                type="secondary"
                htmlType="submit"
                size="middle"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                data-testid="configure-company-info-save-button"
              >
                Save changes
              </Button>
            </Col>
          </Row>
        </StackY>
      </form>
    </>
  );
};

type Props = {
  client: Client;
  plans: Plan[];
  benAdmins: BenAdmin[];
  brokers: Broker[];
  onSave: () => void;
  featureToggles: ClientFeatureToggles;
  showLivePortalWarning?: boolean;
};

export const CardConfigureSubmitCompanyInformation = ({
  client,
  plans,
  benAdmins,
  brokers,
  showLivePortalWarning,
  featureToggles,
  onSave,
}: Props) => {
  const { mutateAsync: savePlanConfiguration } = useSetupClientPlanConfiguration(client.id);

  return (
    <CardConfigureSubmitCompanyInformationPresentation
      client={client}
      plans={plans}
      benAdmins={benAdmins}
      brokers={brokers}
      showLivePortalWarning={showLivePortalWarning}
      savePlanConfiguration={savePlanConfiguration}
      featureToggles={featureToggles}
      onSave={onSave}
    />
  );
};

const getPlanByBenefitTypeAndLevelForPlans =
  (plans: Plan[]) =>
  (benefitType: BenefitTypeEIF, level: "HIGH" | "LOW" | "NOT_APPLICABLE"): Plan | undefined => {
    return plans.find((plan) => plan.benefitType === benefitType && plan.level === level);
  };

// TS set the value to be a single boolean but we get an object in the runtime
const getTouchedPlanPFMLOptionSelection = (touched: unknown): Record<string, boolean> | null => {
  if (typeof touched === "object") {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
    return touched as Record<string, boolean>;
  }

  return null;
};

// TS set the value to be a string | string[] but we get an object in the runtime
const getErrorsPlanPFMLOptionSelection = (
  errors: unknown,
): Record<string, string | string[]> | null => {
  if (typeof errors === "object") {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- required
    return errors as Record<string, string | string[]>;
  }

  return null;
};
