import { StackY } from "client/src/components/Spacing/Spacing";
import { getTierContributionValuesFromEmployeeClassAndBenefitType } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/Tiered/EIFClassBuilderTieredContributions";
import { relevantChangesForContributionFields } from "client/src/domain/EIF/PlanConfigAndEligibility/ClassBuilder/Contributions/relevantChangesForContributionFields";
import { EditedFieldMsg } from "client/src/domain/EIF/common/EditedFieldMsg";
import { getFormikErrors } from "client/src/hooks/useSlobFormik";
import { getIsSupplementalHealthBenefitType, type BenefitTypeEIF } from "shared/types/BenefitTypes";
import {
  employerPremiumPaymentsValuesRecord,
  type EmployeeClassPlanWithPlan,
  type PlanContributions,
  shouldHaveContributionValues,
  requirePremiumPayments,
} from "shared/types/EmployeeClassPlan";
import { benefitTypeToCoverage } from "shared/types/SlfCoverages";
import { getShowValidationErrorsInSummary, getEIFSubStepViewMode } from "shared/utils/client";
import { assertIsDefined } from "shared/utils/utils";
import { editContributionsForEmployeeClassPlanValidationSchema } from "shared/validation/employeeClassPlan";
import { Body3 } from "../../../../../../components/Typography/Typography";
import { AvoidPageBreak } from "../../../../../../utils/print";
import { hasAtLeastOneContributionSelected } from "../hasAtLeastOneContributionSelected";
import { ContributionsTable } from "./EIFClassBuilderTieredContributionsTable";
import type { FormikErrors } from "formik";
import type { UserData } from "shared/rbac/rbac";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client } from "shared/types/Client";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { ValuesForValidationSchema } from "shared/types/Helper";

type ReviewTierContributionsProps = {
  client: Client;
  employeeClass: EmployeeClass;
  benefitType: BenefitTypeEIF;
  changeSnapshot: DEIFChangeSnapshot | null;
  authUser: UserData | null;
};

export const ReviewTierContributions = (props: ReviewTierContributionsProps) => {
  const { client, employeeClass, benefitType, changeSnapshot, authUser } = props;

  const employeeClassPlanIds = employeeClass.employeeClassPlans
    .filter((ecp) => ecp.plan.benefitType === benefitType)
    .map((ecp) => ecp.id);

  const changeDetailInfoList = relevantChangesForContributionFields(
    employeeClassPlanIds,
    changeSnapshot,
  );

  const contributionTierValuesInFormikFormat =
    getTierContributionValuesFromEmployeeClassAndBenefitType(employeeClass, benefitType);

  const viewMode = getEIFSubStepViewMode({ client });
  const suppressErrorsPostSigning = !getShowValidationErrorsInSummary(viewMode, changeSnapshot);

  const strictErrors = !suppressErrorsPostSigning
    ? getFormikErrors(
        contributionTierValuesInFormikFormat,
        editContributionsForEmployeeClassPlanValidationSchema,
        {
          prefill: false,
          benefitType,
        },
      )
    : {};

  return (
    <StackY dist={16} wrap={false}>
      <EditedFieldMsg
        changeDetailInfoList={changeDetailInfoList}
        client={client}
        authUser={authUser}
      />

      <ReviewTierContributionsValues
        employeeClass={employeeClass}
        benefitType={benefitType}
        strictErrors={strictErrors}
      />
    </StackY>
  );
};

type ReviewTierContributionsValuesProps = {
  employeeClass: EmployeeClass;
  benefitType: BenefitTypeEIF;
  strictErrors: FormikErrors<
    ValuesForValidationSchema<typeof editContributionsForEmployeeClassPlanValidationSchema>
  >;
};

const ReviewTierContributionsValues = (props: ReviewTierContributionsValuesProps) => {
  const { employeeClass, benefitType, strictErrors } = props;

  const relevantEmployeeClassPlans = employeeClass.employeeClassPlans.filter(
    (ecp) => ecp.plan.benefitType === benefitType,
  );

  assertIsDefined(relevantEmployeeClassPlans[0], "relevantEmployeeClassPlans[0]");

  const tiersNeededForReviewSuppHealth =
    getIsSupplementalHealthBenefitType(benefitType) &&
    (relevantEmployeeClassPlans[0].employerContribution === "YES" ||
      relevantEmployeeClassPlans[0].employerContribution === "SHARED");

  relevantEmployeeClassPlans.every((ecp) =>
    shouldHaveContributionValues(ecp.plan.benefitType, ecp.employerContribution),
  );

  const tiersNeededForReviewNonSuppHealthWithUpdatedContributionsFT =
    !getIsSupplementalHealthBenefitType(benefitType) &&
    (relevantEmployeeClassPlans[0].employerContribution === "YES" ||
      relevantEmployeeClassPlans[0].employerContribution === "SHARED");

  const reviewTiersShouldBeShown =
    tiersNeededForReviewSuppHealth || tiersNeededForReviewNonSuppHealthWithUpdatedContributionsFT;

  const atLeastOneContributionSelected = hasAtLeastOneContributionSelected(
    relevantEmployeeClassPlans,
  );

  const benefitTypeFormatted = benefitTypeToCoverage[benefitType];

  if (!atLeastOneContributionSelected || !reviewTiersShouldBeShown) {
    const contributionMessage = atLeastOneContributionSelected
      ? "I am not contributing and employees pay 100%"
      : null;

    return (
      <section>
        <Body3>{contributionMessage}</Body3>
        {requirePremiumPayments(
          benefitType,
          relevantEmployeeClassPlans[0].employerContribution,
        ) && (
          <section>
            {relevantEmployeeClassPlans[0].employerPremiumPayments && (
              <Body3>
                Employee premium payments are{" "}
                {employerPremiumPaymentsValuesRecord[
                  relevantEmployeeClassPlans[0].employerPremiumPayments
                ].toLowerCase()}
              </Body3>
            )}
            {strictErrors.employerPremiumPayments && (
              <Body3 redMedium>{strictErrors.employerPremiumPayments}</Body3>
            )}
            {!relevantEmployeeClassPlans[0].employerPremiumPayments &&
              !strictErrors.employerPremiumPayments && <Body3>Employee premium payments are</Body3>}
          </section>
        )}
      </section>
    );
  }

  const contributions = getHighLowContributions(relevantEmployeeClassPlans);

  if (contributions.high === undefined && contributions.low === undefined) {
    return (
      <section>
        <Body3>No contributions selected yet for {benefitTypeFormatted}</Body3>
      </section>
    );
  }

  return (
    <section>
      <AvoidPageBreak>
        <StackY dist={16}>
          {reviewTiersShouldBeShown && (
            <>
              {strictErrors.employerContributionUnit && (
                <Body3 redMedium>{strictErrors.employerContributionUnit}</Body3>
              )}
              <ContributionsTable
                employeeClass={employeeClass}
                lowPlanContributions={contributions.low}
                highPlanContributions={contributions.high}
                benefitType={benefitType}
                strictErrors={strictErrors}
              />
            </>
          )}

          {requirePremiumPayments(
            benefitType,
            relevantEmployeeClassPlans[0].employerContribution,
          ) && (
            <section>
              {relevantEmployeeClassPlans[0].employerPremiumPayments && (
                <Body3>
                  Employee premium payments are{" "}
                  {employerPremiumPaymentsValuesRecord[
                    relevantEmployeeClassPlans[0].employerPremiumPayments
                  ].toLowerCase()}
                </Body3>
              )}
              {strictErrors.employerPremiumPayments && (
                <Body3 redMedium>{strictErrors.employerPremiumPayments}</Body3>
              )}
              {!relevantEmployeeClassPlans[0].employerPremiumPayments &&
                !strictErrors.employerPremiumPayments && (
                  <Body3>Employee premium payments are -</Body3>
                )}
            </section>
          )}
        </StackY>
      </AvoidPageBreak>
    </section>
  );
};

function getHighLowContributions(relevantEmployeeClassPlans: EmployeeClassPlanWithPlan[]) {
  const contributions = relevantEmployeeClassPlans.reduce<{
    high: PlanContributions | undefined;
    low: PlanContributions | undefined;
  }>(
    (acc, employeeClassPlan) => {
      if (!employeeClassPlan.employerContribution) {
        return acc;
      }
      if (employeeClassPlan.plan.level === "HIGH" && !acc.high) {
        const values = {
          employerContribution: employeeClassPlan.employerContribution,
          employerContributionUnit: employeeClassPlan.employerContributionUnit,
          eeContributionAmount: employeeClassPlan.eeContributionAmount,
          spouseContributionAmount: employeeClassPlan.spouseContributionAmount,
          childrenContributionAmount: employeeClassPlan.childrenContributionAmount,
          eeAndSpouseContributionAmount: employeeClassPlan.eeAndSpouseContributionAmount,
          eeAndChildrenContributionAmount: employeeClassPlan.eeAndChildrenContributionAmount,
          eeAndFamilyContributionAmount: employeeClassPlan.eeAndFamilyContributionAmount,
          familyContributionAmount: employeeClassPlan.familyContributionAmount,
          employerPremiumPayments: null,
          threeYearLookBackPercent: null,
          taxChoice: null,
        };
        return { high: values, low: acc.low };
      }

      const isSupplementalHealthPlan = getIsSupplementalHealthBenefitType(
        employeeClassPlan.plan.benefitType,
      );
      if (
        (employeeClassPlan.plan.level === "LOW" ||
          employeeClassPlan.plan.level === "NOT_APPLICABLE") &&
        !acc.low
      ) {
        const values = {
          employerContribution: employeeClassPlan.employerContribution,
          employerContributionUnit: employeeClassPlan.employerContributionUnit,
          eeContributionAmount: employeeClassPlan.eeContributionAmount,
          spouseContributionAmount: employeeClassPlan.spouseContributionAmount,
          childrenContributionAmount: employeeClassPlan.childrenContributionAmount,
          eeAndSpouseContributionAmount: employeeClassPlan.eeAndSpouseContributionAmount,
          eeAndChildrenContributionAmount: employeeClassPlan.eeAndChildrenContributionAmount,
          eeAndFamilyContributionAmount: employeeClassPlan.eeAndFamilyContributionAmount,
          familyContributionAmount: employeeClassPlan.familyContributionAmount,
          employerPremiumPayments: isSupplementalHealthPlan
            ? employeeClassPlan.employerPremiumPayments
            : null,
          threeYearLookBackPercent: null,
          taxChoice: null,
        };
        return { high: acc.high, low: values };
      }
      return acc;
    },
    { high: undefined, low: undefined },
  );
  return contributions;
}
