import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { Badge } from "../../../../components/Badge/Badge";
import { Body1, H1, H1ExtraLarge, H2 } from "../../../../components/Typography/Typography";
import { Trans, useTranslation } from "../../../../i18n";
import { copyKey } from "../../utils";

import type {
  ExplorerBenefitTypeMetadata,
  Language,
} from "../../../../../../shared/types/ExplorerPage";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ContentFlag, ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";

export const DataTestId = {
  BenefitOverview: "benefit-overview",
};

export type ExplorerBenefitOverviewCopyStrucutre = {
  [benefitType in BenefitTypeBenEx]: {
    header: string;
    header_one?: string;
    subHeader: string;
    overview: string;
    overview_DHMO?: string;
    overview_one?: string;
    "AD&D"?: string;
  };
};

type ExplorerBenefitOverviewProps = {
  benefitType: BenefitTypeBenEx;
  metadata?: ExplorerBenefitTypeMetadata;
  plans: ExplorerPageBenefit[];
  clientName: string;
  alternateName: string;
  contentFlags?: ContentFlag[];
  language: Language;
};

export const ExplorerBenefitOverview = ({
  benefitType,
  metadata,
  plans,
  clientName,
  alternateName,
  contentFlags = [],
  language,
}: ExplorerBenefitOverviewProps) => {
  const { t } = useTranslation({ keyPrefix: "ExplorerBenefitOverview" });
  const includesADD = contentFlags.includes("AD&D");

  const planTypes = plans.reduce<PlanType[]>((acc, val) => {
    if (val.planType && !acc.includes(val.planType)) acc.push(val.planType);
    return acc;
  }, []);
  const isDHMO = planTypes.length === 1 && planTypes[0] === "DHMO";

  const highlightText =
    language === "es"
      ? metadata?.[benefitType]?.spanishHighlighterText
      : metadata?.[benefitType]?.highlighterText;
  const isMobile = !useBreakpoint().md;

  const header = (
    <Trans
      t={t}
      i18nKey={copyKey(benefitType, "header")}
      values={{
        alternateName,
        add: includesADD ? t(copyKey(benefitType, "AD&D")) : undefined,
      }}
      count={plans.length}
    ></Trans>
  );

  return (
    <div data-testid={DataTestId.BenefitOverview}>
      {isMobile ? (
        <H1
          style={{
            marginBottom: "32px",
          }}
        >
          {header}
        </H1>
      ) : (
        <H1ExtraLarge
          style={{
            marginBottom: "32px",
          }}
        >
          {header}
        </H1ExtraLarge>
      )}
      <H2>{t(copyKey(benefitType, "subHeader"))}</H2>
      <Body1>
        {t(copyKey(benefitType, isDHMO ? "overview_DHMO" : "overview"), {
          clientName,
          count: plans.length,
          alternateName,
        })}
      </Body1>
      {!!highlightText && (
        <div className="my-16">
          <Badge srOnlyLabel="Warning" message={highlightText} variant="warning" />
        </div>
      )}
    </div>
  );
};
