import { formatFullName } from "shared/utils/format";
import type { Location, LocationStateCode } from "../types/Location";
import type { LocationManagementType } from "@prisma/client";
import type { Contact } from "shared/types/Contact";

type LocationAddrssFields = Pick<
  Location,
  "name" | "address1" | "address2" | "city" | "state" | "zipCode"
>;

export const formatLocation = (location: LocationAddrssFields, includeName = true): string => {
  const { address1, address2, city, state, zipCode } = location;
  const name = includeName ? location.name : null;
  return includeName
    ? `${name ?? "-"}: ${address1 ?? "-"} ${address2 ? address2 + " " : ""}${city ?? "-"}, ${
        state ?? "-"
      } ${zipCode ?? "-"}`
    : `${address1 ?? "-"} ${address2 ? address2 + " " : ""}${city ?? "-"}, ${state ?? "-"} ${
        zipCode ?? "-"
      }`;
};

type ContactAddressFields = Pick<
  Contact,
  "firstName" | "lastName" | "address1" | "address2" | "city" | "state" | "zipCode"
>;

export const formatContactLocation = (
  contactLocationFields: ContactAddressFields,
  includeName = true,
): string => {
  const { firstName, lastName, address2 } = contactLocationFields;
  const name = formatFullName({ firstName, lastName });
  const address1 = contactLocationFields.address1 || "";
  const city = contactLocationFields.city || "";
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- doesn't matter
  const state = (contactLocationFields.state || "") as LocationStateCode;
  const zipCode = contactLocationFields.zipCode || "";
  const location = { name, address1, address2, city, state, zipCode };
  const result = formatLocation(location, includeName);
  return result;
};

export const formatLocationAccess = (locationManagementType: LocationManagementType) => {
  return locationManagementType === "ALL"
    ? "All locations"
    : locationManagementType === "LIMITED"
    ? "Limited locations"
    : null;
};
