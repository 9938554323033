import * as Sentry from "@sentry/react";
import { Button } from "client/src/components/Button/Button";
import { genericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { Row, Col } from "client/src/components/Grid/Grid";
import { StackY } from "client/src/components/Spacing/Spacing";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteData } from "../../../../../shared/config/routeData";

import { ProgressCircle } from "../../../components/Icons/ProgressCircle";
import { Modal } from "../../../components/Modal/Modal";
import { Body3 } from "../../../components/Typography/Typography";
import { recipientStatusToProgressStatus } from "../RecipientRow";
import * as styles from "../onboardingFormTile.module.less";
import type { ClientId } from "../../../../../shared/types/Client";
import type { OnboardingFormId } from "../../../../../shared/types/OnboardingForm";
import type { RecipientWithMetadata } from "../../../../../shared/types/OnboardingFormRecipient";
import type {
  OnboardingFormDownloadDocumentFunc,
  AsyncCreateRecipientEmbeddedView,
  useGetOnboardingFormLockStatus,
} from "client/src/hooks/onboardingForms";
import type { FormPageLocationState } from "client/src/pages/OnboardingForms/FormPage";

type MultipleRecipientModalProps = {
  clientId: ClientId;
  onboardingFormId: OnboardingFormId;
  recipients: ReadonlyArray<RecipientWithMetadata>;
  currentUserEmail: string;
  isVisible: boolean;
  isGettingFormLockStatus: boolean;
  getOnboardingFormLockStatus: ReturnType<typeof useGetOnboardingFormLockStatus>["refetch"];
  createRecipientEmbeddedView: AsyncCreateRecipientEmbeddedView;
  showRecipientChangedToOutsideSignerModal: () => void;
  downloadDocument: OnboardingFormDownloadDocumentFunc;
  isCreatingRecipientEmbeddedView: boolean;
  onClose: () => void;
};

export const MultipleRecipientModal = ({
  clientId,
  onboardingFormId,
  recipients,
  currentUserEmail,
  isVisible,
  createRecipientEmbeddedView,
  isGettingFormLockStatus,
  showRecipientChangedToOutsideSignerModal,
  isCreatingRecipientEmbeddedView,
  getOnboardingFormLockStatus,
  downloadDocument,
  onClose,
}: MultipleRecipientModalProps) => {
  const navigate = useNavigate();

  const sameUserRecipients = recipients.filter(
    ({ email }) => email.toLowerCase() === currentUserEmail.toLowerCase(),
  );
  const [disableViewEditForm, setDisableViewEditForm] = useState<boolean>(false);

  const viewEditFormAction = async (recipientId: string) => {
    try {
      const { data: formLockStatusResponse } = await getOnboardingFormLockStatus();
      const formLockStatus = formLockStatusResponse?.isLocked;
      if (formLockStatus) {
        setDisableViewEditForm(true);
        return;
      }

      const { data: recipientEmbeddedView } = await createRecipientEmbeddedView({
        params: {
          clientId,
          onboardingFormId,
          recipientId,
        },
      });

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_REASSIGNED") {
        showRecipientChangedToOutsideSignerModal();
        return;
      }

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_DOWNLOAD_FORM") {
        await downloadDocument();
        return;
      }

      if (recipientEmbeddedView.type === "EMBEDDED_VIEW_CREATED") {
        const locationState: FormPageLocationState = {
          trigger: "onboardingFormTileClick",
          recipientViewUrl: recipientEmbeddedView.url,
        };

        navigate(RouteData.onboardingFormsViewer.getPath(clientId, onboardingFormId, recipientId), {
          state: locationState,
        });
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
      Sentry.captureException(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Select a Recipient"
      open={isVisible}
      footer={null}
      focusTriggerAfterClose={false}
      onCancel={onClose}
    >
      <Body3 as="p">
        This form contains more than one recipient for you.
        <br /> Please select the one.
      </Body3>

      <StackY dist={16}>
        {sameUserRecipients.map((recipient) => (
          <Row key={recipient.id}>
            <Col span={8}>
              <div className={styles.progressCircleCell}>
                <ProgressCircle
                  variant={recipientStatusToProgressStatus(recipient.metadata.lastAction.action)}
                />
                <span className="body4">{recipient.name}</span>
              </div>
            </Col>
            <Col span={7}>
              <span className="body3">{recipient.roleName}</span>
            </Col>
            <Col span={9}>
              <Button
                type="text-only"
                disabled={
                  disableViewEditForm || isGettingFormLockStatus || isCreatingRecipientEmbeddedView
                }
                onClick={() => viewEditFormAction(recipient.id)}
              >
                {recipient.metadata.lastAction.action === "completed"
                  ? "View Form"
                  : "View / Edit Form"}
              </Button>
            </Col>
          </Row>
        ))}
      </StackY>
    </Modal>
  );
};
