import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { Button } from "client/src/components/Button/Button";
import { genericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { OrderedList, OrderedListItem } from "client/src/components/OrderedList/OrderedList";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useSyncClient } from "client/src/hooks/client";
import type { ClientError } from "@prisma/client";
import type { FC } from "react";
import type { Client } from "shared/types/Client";

type JiraSyncDetailsProps = {
  data: ClientError[];
  client: Client;
  jiraURL: string;
  onClose: () => void;
};

export const JiraSyncDetails: FC<JiraSyncDetailsProps> = ({ data, client, jiraURL, onClose }) => {
  const clientId = client.id;

  const errors = data.map((item) => item.description);

  const { mutateAsync: syncClient, isPending: isUpdateClientLoading } = useSyncClient();

  const handleSyncClient = async () => {
    const hide = slobMessage.loading("Update in progess", 0);

    try {
      const { isError, isSuccess } = await syncClient({ params: { clientId }, data: {} });

      if (isSuccess) {
        void slobMessage.success("Client Updated");
        onClose();
      } else if (isError) {
        void slobMessage.error(genericErrorCopy2);
      }
    } catch (error) {
      void slobMessage.error(genericErrorCopy2);
    } finally {
      hide();
    }
  };

  return (
    <StackY dist={24}>
      <Body3>The following error is preventing the client portal from syncing to Jira:</Body3>

      {errors.map((error, idx) => (
        <StackY dist={12} key={idx}>
          <AlertBanner variant="error" message={<Body3>{error}</Body3>} />
        </StackY>
      ))}
      <OrderedList>
        <OrderedListItem>
          <StackY dist={8}>
            <Body3>Go to the Jira ticket and ensure all fields are correctly filled out.</Body3>
            <Button href={`${jiraURL}/browse/${client.ticketId}`} newTab>
              Go to Jira ticket
            </Button>
          </StackY>
        </OrderedListItem>
        <br />
        <OrderedListItem>
          <StackY dist={8}>
            <Body3>Once the ticket is updated, sync the client portal to Jira.</Body3>
            <Button
              disabled={isUpdateClientLoading}
              loading={isUpdateClientLoading}
              onClick={handleSyncClient}
            >
              Sync with Jira
            </Button>
          </StackY>
        </OrderedListItem>
      </OrderedList>

      <Body2>
        If this troubleshooting step does not resolve the issue, please submit a Jira ticket to
        engineering for follow up.
      </Body2>
      <Body3>This issue will be resolved once the client portal is able to sync to Jira</Body3>
    </StackY>
  );
};
